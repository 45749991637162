import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Sidebar from '../global/sidebarOriginal';
import '../../App.css';
import WindDirectionIndicatorLarge from '../global/windArrowLarge';
import { RotatingLines } from 'react-loader-spinner'
import { Context } from '../context/context';
import SidebarPro from '../global/sidebar';
import BottomNav from '../global/bottomNav';
import Footer from '../global/footer';

const WeatherForecast = () => {

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const weatherStation = useContext(Context).weatherStation;
    const waveBuoy = useContext(Context).waveBuoy;

    const [showLoader, setShowLoader] = useState(true);
    const [mobile, setMobile] = useState(window.innerWidth < 600);
    const [weatherForecasts, setWeatherForecasts] = useState({
        howeSound: {
          'name': 'Howe Sound',
          'url': '/howeSound_forecast',
          'coordinates': {lat: 49.1, lng: -123.9},
          'forecast': [],
          'windSpeed': weatherStation.howeSound.windSpeed[0],
          'waveHeight': '~',
          'windDirection': weatherStation.howeSound.windDirection[0],
        },
        SOGSouthNanaimo: {
          'name': 'Strait of Georgia South Nanaimo',
          'url': '/SOGSouthNanaimo_forecast',
          'coordinates': {lat: 49.500960, lng: -123.308224},
          'forecast': [],
          'windSpeed': waveBuoy.georgiaStrait.windSpeed[0],
          'waveHeight': waveBuoy.georgiaStrait.waveHeight[0],
          'windDirection': waveBuoy.georgiaStrait.windDirection[0],
        },
        SOGNorthNanaimo: {
          'name': 'Strait of Georgia North Nanaimo',
          'url': '/SOGNorthNanaimo_forecast',
          'coordinates': {lat: 49.3, lng: -123.9},
          'forecast': [],
          'windSpeed': waveBuoy.halibutBank.windSpeed[0],
          'waveHeight': waveBuoy.halibutBank.waveHeight[0],
          'windDirection': waveBuoy.halibutBank.windDirection[0],
        },
        haroStrait: {
          'name': 'Haro Strait',
          'url': '/haroStrait_forecast',
          'coordinates': {lat: 48.7, lng: -123.3},
          'forecast': [],
          'windSpeed': weatherStation.kelpReefs.windSpeed[0],
          'waveHeight': '~',
          'windDirection': weatherStation.kelpReefs.windDirection[0],
        },
        JDFEast: {
          'name': 'Juan de Fuca Strait East',
          'url': '/JDFEast_forecast',
          'coordinates': {lat: 48.3, lng: -123.3},
          'forecast': [],
          'windSpeed': weatherStation.usaStation2.windSpeed[0],
          'waveHeight': '~',
          'windDirection': weatherStation.usaStation2.windDirection[0],
        },
        JDFCentral: {
          'name': 'Juan de Fuca Strait Central',
          'url': '/JDFCentral_forecast',
          'coordinates': {lat: 48.5, lng: -123.3},
          'forecast': [],
          'windSpeed': weatherStation.raceRocksLightstation.windSpeed[0],
          'waveHeight': '~',
          'windDirection': weatherStation.raceRocksLightstation.windDirection[0]
        },
        JDFWest: {
          'name': 'Juan de Fuca Strait West',
          'url': '/JDFWest_forecast',
          'coordinates': {lat: 48.7, lng: -123.3},
          'forecast': [],
          'windSpeed': '~',
          'waveHeight': '~',
          'windDirection': '~'
        },
      });

    // Function to get weather forecasts
    const fetchDataForForecast = async () => {
        try {
            for (const [key, value] of Object.entries(weatherForecasts)) {
                const response = await axios.get(API_BASE_URL + value.url);
                const forecastText = response.data.rows[0];
                setWeatherForecasts(prevState => ({
                    ...prevState,
                    [key]: { ...prevState[key], forecast: forecastText }
                }));
            }
        } catch (error) {
            console.log(`Error fetching data: ${error.message}`);
        }
        setShowLoader(false);
    };

        useEffect(() => {
            fetchDataForForecast();
        }
        , []);
    

    return (
    <div className="weather-forecast-page">
       {mobile ? <BottomNav /> : <SidebarPro />}
        <div className="weather-forecast-container">
            <p className='page-title'>
                Weather Forecast
            </p>
            {showLoader && (
                <div className='loading-spinner'>
                    <RotatingLines
                        visible={true}
                        strokeColor='grey'
                        height="36"
                        width="36"
                        strokeWidth="3"
                        animationDuration="0.75"
                    />
                </div>
            )}
            {Object.keys(weatherForecasts).map((key, index) => {
                return (
                    <div key={index} className="weather-forecast">
                        <div className='weather-forecast-info'>
                            <div className='forecast-wind-wave-window'>
                                <div className='forecast-container forecast-wind-container'>
                                    <h1>{weatherForecasts[key].windSpeed}</h1>
                                    <h5>knots</h5>
                                </div>
                                <div className='forecast-container'>
                                    <h1>{weatherForecasts[key].waveHeight}</h1>
                                    <h5>feet</h5>
                                </div>
                            </div>
                            <div>
                                <WindDirectionIndicatorLarge direction={weatherForecasts[key].windDirection} />
                            </div>
                        </div>
                        <div>
                            <h3 className='mobile-wrap'>{weatherForecasts[key].name}</h3>
                            <p>{weatherForecasts[key].forecast.date}</p>
                            <p>{weatherForecasts[key].forecast.timePeriod}</p>
                            <p style={{fontWeight: 600, color: 'rgba(245,0,0,.8)'}}>{weatherForecasts[key].forecast.warning}</p>
                            <p>{weatherForecasts[key].forecast.forecast}</p>
                        </div>
                    </div>
                );
            })
            }
        </div>
        <Footer />
    </div>
    );
    }

export default WeatherForecast;