import React from 'react';

const BouncingMarker = () => (
    <svg style={{zIndex: 999999}} width="60px" height="60px" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="20" fill="#856ea3" />
    <circle cx="50" cy="50" r="10" fill="none" stroke="#856ea3" strokeWidth="22">
      <animate
        attributeName="r"
        from="10"
        to="45"
        dur="2s"
        begin="0s"
        repeatCount="indefinite"
        fill="freeze"
      />
      <animate
        attributeName="opacity"
        from="1"
        to="0"
        dur="2s"
        begin="0s"
        repeatCount="indefinite"
        fill="freeze"
      />
    </circle>
    <circle cx="50" cy="50" r="10" fill="none" stroke="#856ea3" strokeWidth="22">
      <animate
        attributeName="r"
        from="10"
        to="45"
        dur="2s"
        begin="0.75s"
        repeatCount="indefinite"
        fill="freeze"
      />
      <animate
        attributeName="opacity"
        from="1"
        to="0"
        dur="2s"
        begin="0.75s"
        repeatCount="indefinite"
        fill="freeze"
      />
    </circle>
  </svg>
);

export default BouncingMarker;
