const fishSpeciesArray = [
    {"species_id":4, "species_type":"Salmon", "species_name":"Chum salmon"},
    {"species_id":14, "species_type":"Salmon", "species_name":"Chinook salmon (wild)"},
    {"species_id":25, "species_type":"Salmon", "species_name":"Chinook salmon (hatchery)"},
    {"species_id":24, "species_type":"Salmon", "species_name":"Coho salmon (hatchery)"},
    {"species_id":104, "species_type":"Salmon", "species_name":"Coho salmon (wild)"},
    {"species_id":114, "species_type":"Other Finfish", "species_name":"Herring"},
    {"species_id":124, "species_type":"Other Finfish", "species_name":"Eulachon"},
    {"species_id":134, "species_type":"Salmon", "species_name":"Sockeye salmon"},
    {"species_id":144, "species_type":"Salmon", "species_name":"Pink salmon"},
    {"species_id":154, "species_type":"Other Finfish", "species_name":"Pacific sardine"},
    {"species_id":164, "species_type":"Other Finfish", "species_name":"Other finfish species"},
    {"species_id":174, "species_type":"Other Finfish", "species_name":"Pacific sand lance"},
    {"species_id":184, "species_type":"Other Finfish", "species_name":"Northern anchovy"},
    {"species_id":194, "species_type":"Other Finfish", "species_name":"Mackerel"},
    {"species_id":204, "species_type":"Other Finfish", "species_name":"Surf smelt"},
    {"species_id":214, "species_type":"Other Finfish", "species_name":"Codfish"},
    {"species_id":224, "species_type":"Other Finfish", "species_name":"Tuna, Albacore"},
    {"species_id":234, "species_type":"Other Finfish", "species_name":"Tuna, All other species"},
    {"species_id":244, "species_type":"Other Finfish", "species_name":"Greenling"},
    {"species_id":254, "species_type":"Other Finfish", "species_name":"Lingcod"},
    {"species_id":264, "species_type":"Other Finfish", "species_name":"Halibut"},
    {"species_id":274, "species_type":"Other Finfish", "species_name":"Perch"},
    {"species_id":284, "species_type":"Other Finfish", "species_name":"Rockfish, All species combined"},
    {"species_id":294, "species_type":"Other Finfish", "species_name":"Rockfish, Yelloweye"},
    {"species_id":304, "species_type":"Other Finfish", "species_name":"Shark, Basking"},
    {"species_id":314, "species_type":"Other Finfish", "species_name":"Sablefish"},
    {"species_id":324, "species_type":"Other Finfish", "species_name":"Shark, All other species"},
    {"species_id":334, "species_type":"Other Finfish", "species_name":"Sculpin"},
    {"species_id":344, "species_type":"Other Finfish", "species_name":"Shark, Blue"},
    {"species_id":354, "species_type":"Other Finfish", "species_name":"Shark, Brown Cat"},
    {"species_id":364, "species_type":"Other Finfish", "species_name":"Shark, Bluntnose"},
    {"species_id":374, "species_type":"Other Finfish", "species_name":"Shark, Great White"},
    {"species_id":384, "species_type":"Other Finfish", "species_name":"Shark, Salmon"},
    {"species_id":394, "species_type":"Other Finfish", "species_name":"Shark, Sixgill"},
    {"species_id":404, "species_type":"Other Finfish", "species_name":"Shark, Tope"},
    {"species_id":414, "species_type":"Other Finfish", "species_name":"Spiny dogfish"},
    {"species_id":424, "species_type":"Other Finfish", "species_name":"Sole/Flounder"},
    {"species_id":434, "species_type":"Other Finfish", "species_name":"Skate"},
    {"species_id":444, "species_type":"Other Finfish", "species_name":"Sturgeon"},
    {"species_id":454, "species_type":"Other Finfish", "species_name":"Wolf eel"},
    {"species_id":464, "species_type":"Other Finfish", "species_name":"Trout (hatchery)"},
    {"species_id":474, "species_type":"Other Finfish", "species_name":"Trout (wild)"},
    {"species_id":484, "species_type":"Crab", "species_name":"Box and Puget Sound King crab"},
    {"species_id":704, "species_type":"Crab", "species_name":"King crab"},
    {"species_id":714, "species_type":"Crab", "species_name":"Other crab"},
    {"species_id":724, "species_type":"Crab", "species_name":"Dungeness crab"},
    {"species_id":814, "species_type":"Crab", "species_name":"Red Rock crab"},
    {"species_id":964, "species_type":"Crab", "species_name":"Shore crab"},
    // {"species_id":974, "species_type":"Salmon", "species_name":"Coho salmon (hatchery and wild combined)"},
    {"species_id":984, "species_type":"Other Finfish", "species_name":"Trout (hatchery and wild combined)"},
    {"species_id":1004, "species_type":"Invertebrates", "species_name":"Abalone"},
    {"species_id":1014, "species_type":"Invertebrates", "species_name":"Barnacles, Goose"},
    {"species_id":1024, "species_type":"Invertebrates", "species_name":"Barnacles, Giant"},
    {"species_id":1064, "species_type":"Invertebrates", "species_name":"Limpet"},
    {"species_id":1084, "species_type":"Invertebrates", "species_name":"Octopus"},
    {"species_id":1104, "species_type":"Invertebrates", "species_name":"Sand Dollar"},
    {"species_id":1114, "species_type":"Invertebrates", "species_name":"Other shellfish species"},
    {"species_id":1134, "species_type":"Invertebrates", "species_name":"Sea Cucumber"},
    {"species_id":1164, "species_type":"Invertebrates", "species_name":"Squid, All other species"},
    {"species_id":1174, "species_type":"Invertebrates", "species_name":"Shrimp, Ghost"},
    {"species_id":1184, "species_type":"Invertebrates", "species_name":"Sea Urchin"},
    {"species_id":1224, "species_type":"Invertebrates", "species_name":"Shrimp/Prawn"},
    {"species_id":1244, "species_type":"Invertebrates", "species_name":"Squid, Opal"},
    {"species_id":1264, "species_type":"Invertebrates", "species_name":"Starfish sea star"}
    ]
    
    export default fishSpeciesArray;