// Function to convert time to PST and format it
export const transformTime = (updateTime) => {
  // Check if updateTime is a valid date string or Date object
  if (!updateTime || isNaN(new Date(updateTime).getTime())) {
    console.warn("Invalid time object provided:", updateTime);
    return "N/A"; // Return early if the input is invalid
  }

  try {
    // Parse the input time string into a Date object
    const pstTime = new Date(updateTime);

    // Create a formatter for the "America/Los_Angeles" time zone
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/Los_Angeles',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, // Use 24-hour time format
    });

    // Format the date and time in the desired time zone
    const pstTimeFormatted = formatter.format(pstTime);

    return pstTimeFormatted;
  } catch (error) {
    console.error("Error transforming time:", error.message);
    return "Invalid time object"; // Return a default message in case of an unexpected error
  }
};


          // convert time to PST hours only
    export const transformTimeHours = (updateTime) => {
      const pstTime = new Date(updateTime);
      
      // Create a formatter for the "America/Los_Angeles" time zone
      const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
        // year: 'numeric',
        // month: 'short',
        // day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        // second: '2-digit',
        hour12: false,
      });
      
      // Format the date and time in the desired time zone
      const pstTimeFormatted = formatter.format(pstTime);
      
      return pstTimeFormatted;
    }