export const fishingAreaDataArray = [
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.8474843213,
                "lng": -130.7072205585
            },
            {
                "lat": 53.8474774042,
                "lng": -130.796264888
            },
            {
                "lat": 53.6344722546,
                "lng": -130.6971594202
            },
            {
                "lat": 53.6344727311,
                "lng": -130.5651011805
            },
            {
                "lat": 53.6344724943,
                "lng": -130.5650337611
            },
            {
                "lat": 53.6275980338,
                "lng": -130.5407565467
            },
            {
                "lat": 53.6272664201,
                "lng": -130.5402374272
            },
            {
                "lat": 53.6241649817,
                "lng": -130.5384219707
            },
            {
                "lat": 53.6215895671,
                "lng": -130.53788807
            },
            {
                "lat": 53.6180042106,
                "lng": -130.5371402579
            },
            {
                "lat": 53.6179587374,
                "lng": -130.5371258343
            },
            {
                "lat": 53.6153407748,
                "lng": -130.5324618696
            },
            {
                "lat": 53.6157423686,
                "lng": -130.5271519492
            },
            {
                "lat": 53.6222802014,
                "lng": -130.5233825066
            },
            {
                "lat": 53.6236910305,
                "lng": -130.5070097815
            },
            {
                "lat": 53.6200451139,
                "lng": -130.4812226252
            },
            {
                "lat": 53.6136849901,
                "lng": -130.4586247363
            },
            {
                "lat": 53.6176876493,
                "lng": -130.4524903148
            },
            {
                "lat": 53.6245237256,
                "lng": -130.4467529078
            },
            {
                "lat": 53.6291197044,
                "lng": -130.4508730251
            },
            {
                "lat": 53.629657456,
                "lng": -130.4529642086
            },
            {
                "lat": 53.6308282902,
                "lng": -130.4573116457
            },
            {
                "lat": 53.634398791,
                "lng": -130.467290946
            },
            {
                "lat": 53.6376793568,
                "lng": -130.4665736315
            },
            {
                "lat": 53.7881893617,
                "lng": -130.5527710022
            },
            {
                "lat": 53.7892149343,
                "lng": -130.5510623023
            },
            {
                "lat": 53.7892224426,
                "lng": -130.5509973705
            },
            {
                "lat": 53.7893373626,
                "lng": -130.5499942196
            },
            {
                "lat": 53.7893426705,
                "lng": -130.5499281019
            },
            {
                "lat": 53.7895166962,
                "lng": -130.5477203673
            },
            {
                "lat": 53.7901884294,
                "lng": -130.5461653223
            },
            {
                "lat": 53.7918737488,
                "lng": -130.5466676047
            },
            {
                "lat": 53.7927096389,
                "lng": -130.5469123122
            },
            {
                "lat": 53.7938005977,
                "lng": -130.550437216
            },
            {
                "lat": 53.79324772,
                "lng": -130.5558846477
            },
            {
                "lat": 53.7960744428,
                "lng": -130.5652216401
            },
            {
                "lat": 53.7981420694,
                "lng": -130.574255469
            },
            {
                "lat": 53.7958635024,
                "lng": -130.5829385152
            },
            {
                "lat": 53.804790182,
                "lng": -130.6180324958
            },
            {
                "lat": 53.8080790294,
                "lng": -130.620978072
            },
            {
                "lat": 53.8113179316,
                "lng": -130.6235867763
            },
            {
                "lat": 53.8129964492,
                "lng": -130.6291258507
            },
            {
                "lat": 53.8143686641,
                "lng": -130.6350918172
            },
            {
                "lat": 53.8120114798,
                "lng": -130.6387544768
            },
            {
                "lat": 53.8108484686,
                "lng": -130.641836831
            },
            {
                "lat": 53.8474843213,
                "lng": -130.7072205585
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 98,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 5-11",
            "LABEL": "5-11",
            "FISHERY_AREA_ID": 2015,
            "OBJECTID": 98,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 324800913.9646,
            "FEATURE_LENGTH_M": 83567.0066,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.71865784243695,
            "lng": -130.55808789200657
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.8200035796,
                "lng": -130.5043031589
            },
            {
                "lat": 53.8257135906,
                "lng": -130.5192867824
            },
            {
                "lat": 53.8292805022,
                "lng": -130.5299982894
            },
            {
                "lat": 53.834754493,
                "lng": -130.5373687293
            },
            {
                "lat": 53.841938331,
                "lng": -130.5538634501
            },
            {
                "lat": 53.8493926557,
                "lng": -130.5650168398
            },
            {
                "lat": 53.850391419,
                "lng": -130.5660406625
            },
            {
                "lat": 53.8555414148,
                "lng": -130.588534766
            },
            {
                "lat": 53.8503910913,
                "lng": -130.5661083849
            },
            {
                "lat": 53.8493923286,
                "lng": -130.5650845605
            },
            {
                "lat": 53.8464123943,
                "lng": -130.56645862
            },
            {
                "lat": 53.8418078881,
                "lng": -130.5766211055
            },
            {
                "lat": 53.8327450157,
                "lng": -130.5905830697
            },
            {
                "lat": 53.8248486311,
                "lng": -130.6071087283
            },
            {
                "lat": 53.8177599794,
                "lng": -130.626334652
            },
            {
                "lat": 53.8143686641,
                "lng": -130.6350918172
            },
            {
                "lat": 53.8129964492,
                "lng": -130.6291258507
            },
            {
                "lat": 53.8113179316,
                "lng": -130.6235867763
            },
            {
                "lat": 53.8080790294,
                "lng": -130.620978072
            },
            {
                "lat": 53.804790182,
                "lng": -130.6180324958
            },
            {
                "lat": 53.7958635024,
                "lng": -130.5829385152
            },
            {
                "lat": 53.7981420694,
                "lng": -130.574255469
            },
            {
                "lat": 53.7960744428,
                "lng": -130.5652216401
            },
            {
                "lat": 53.79324772,
                "lng": -130.5558846477
            },
            {
                "lat": 53.7938005977,
                "lng": -130.550437216
            },
            {
                "lat": 53.7927096389,
                "lng": -130.5469123122
            },
            {
                "lat": 53.7918737488,
                "lng": -130.5466676047
            },
            {
                "lat": 53.7901884294,
                "lng": -130.5461653223
            },
            {
                "lat": 53.7895166962,
                "lng": -130.5477203673
            },
            {
                "lat": 53.7895164502,
                "lng": -130.5476526821
            },
            {
                "lat": 53.790188182,
                "lng": -130.5460976512
            },
            {
                "lat": 53.791874065,
                "lng": -130.5465999924
            },
            {
                "lat": 53.7927099559,
                "lng": -130.5468446834
            },
            {
                "lat": 53.7960775043,
                "lng": -130.544433604
            },
            {
                "lat": 53.7971802196,
                "lng": -130.5402369009
            },
            {
                "lat": 53.7985990701,
                "lng": -130.5373381505
            },
            {
                "lat": 53.80096856,
                "lng": -130.5366509744
            },
            {
                "lat": 53.8000148042,
                "lng": -130.5423730932
            },
            {
                "lat": 53.8012046179,
                "lng": -130.5490258945
            },
            {
                "lat": 53.8066786409,
                "lng": -130.547836902
            },
            {
                "lat": 53.8131025348,
                "lng": -130.5421294907
            },
            {
                "lat": 53.8190499593,
                "lng": -130.5302279126
            },
            {
                "lat": 53.8190502376,
                "lng": -130.5211946423
            },
            {
                "lat": 53.8185726322,
                "lng": -130.5123909015
            },
            {
                "lat": 53.8169741351,
                "lng": -130.5062100383
            },
            {
                "lat": 53.8200035796,
                "lng": -130.5043031589
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 99,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 99,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 24496368.8068,
            "FEATURE_LENGTH_M": 28833.6211,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.81380668619563,
            "lng": -130.55885383866737
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.7103391044,
                "lng": -132.9891961492
            },
            {
                "lat": 53.7103542779,
                "lng": -133.0868990188
            },
            {
                "lat": 53.6903956345,
                "lng": -133.0773475536
            },
            {
                "lat": 53.6903958972,
                "lng": -133.0314025301
            },
            {
                "lat": 53.6903991363,
                "lng": -132.9801638139
            },
            {
                "lat": 53.7103391044,
                "lng": -132.9891961492
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 100,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 92,
            "MANAGEMENT_AREA_NAME": "Subarea 2-92",
            "LABEL": "2-92",
            "FISHERY_AREA_ID": 1982,
            "OBJECTID": 100,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 14293187.823,
            "FEATURE_LENGTH_M": 17455.0864,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.70037052578334,
            "lng": -133.02570086913335
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.7138360975,
                "lng": -132.985443464
            },
            {
                "lat": 53.7128490401,
                "lng": -132.9870604451
            },
            {
                "lat": 53.7116011056,
                "lng": -132.9882968924
            },
            {
                "lat": 53.7109337051,
                "lng": -132.9891815082
            },
            {
                "lat": 53.7103391044,
                "lng": -132.9891961492
            },
            {
                "lat": 53.6903991363,
                "lng": -132.9801638139
            },
            {
                "lat": 53.6918102099,
                "lng": -132.9775395007
            },
            {
                "lat": 53.6921992284,
                "lng": -132.9772481346
            },
            {
                "lat": 53.6932796532,
                "lng": -132.9764405191
            },
            {
                "lat": 53.6951477167,
                "lng": -132.9746245835
            },
            {
                "lat": 53.6957663738,
                "lng": -132.9714963154
            },
            {
                "lat": 53.695781296,
                "lng": -132.9714044238
            },
            {
                "lat": 53.6970213242,
                "lng": -132.9676363941
            },
            {
                "lat": 53.6986077417,
                "lng": -132.9669035623
            },
            {
                "lat": 53.7076301788,
                "lng": -132.9669043992
            },
            {
                "lat": 53.7084458696,
                "lng": -132.9678953207
            },
            {
                "lat": 53.7096740227,
                "lng": -132.968307767
            },
            {
                "lat": 53.7131188107,
                "lng": -132.9721212745
            },
            {
                "lat": 53.715740679,
                "lng": -132.9775849912
            },
            {
                "lat": 53.7152634116,
                "lng": -132.9825897419
            },
            {
                "lat": 53.7138360975,
                "lng": -132.985443464
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 101,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 96,
            "MANAGEMENT_AREA_NAME": "Subarea 2-96",
            "LABEL": "2-96",
            "FISHERY_AREA_ID": 2543,
            "OBJECTID": 101,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2707646.2398,
            "FEATURE_LENGTH_M": 6885.2799,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.704441942990485,
            "lng": -132.9773086983238
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.7096740227,
                "lng": -132.968307767
            },
            {
                "lat": 53.7084458696,
                "lng": -132.9678953207
            },
            {
                "lat": 53.7076301788,
                "lng": -132.9669043992
            },
            {
                "lat": 53.6986077417,
                "lng": -132.9669035623
            },
            {
                "lat": 53.6970213242,
                "lng": -132.9676363941
            },
            {
                "lat": 53.6937134949,
                "lng": -132.9638667137
            },
            {
                "lat": 53.6921919502,
                "lng": -132.9605104801
            },
            {
                "lat": 53.6921049745,
                "lng": -132.9587858208
            },
            {
                "lat": 53.6922226606,
                "lng": -132.9567254914
            },
            {
                "lat": 53.6922224603,
                "lng": -132.9463656449
            },
            {
                "lat": 53.692356417,
                "lng": -132.9452517243
            },
            {
                "lat": 53.691230692,
                "lng": -132.9448095553
            },
            {
                "lat": 53.6889421521,
                "lng": -132.9434205854
            },
            {
                "lat": 53.6879851117,
                "lng": -132.941101297
            },
            {
                "lat": 53.6851200079,
                "lng": -132.9355775665
            },
            {
                "lat": 53.6853945031,
                "lng": -132.932097877
            },
            {
                "lat": 53.6905788818,
                "lng": -132.9260261756
            },
            {
                "lat": 53.6965103108,
                "lng": -132.9226834222
            },
            {
                "lat": 53.701149292,
                "lng": -132.9223484935
            },
            {
                "lat": 53.7066041563,
                "lng": -132.9279322866
            },
            {
                "lat": 53.7113796294,
                "lng": -132.9408878065
            },
            {
                "lat": 53.7132189862,
                "lng": -132.9561763238
            },
            {
                "lat": 53.7137637915,
                "lng": -132.9664619266
            },
            {
                "lat": 53.7126078452,
                "lng": -132.9678335822
            },
            {
                "lat": 53.7096740227,
                "lng": -132.968307767
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 102,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 95,
            "MANAGEMENT_AREA_NAME": "Subarea 2-95",
            "LABEL": "2-95",
            "FISHERY_AREA_ID": 1985,
            "OBJECTID": 102,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 6612974.2808,
            "FEATURE_LENGTH_M": 10306.3316,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.698814019088,
            "lng": -132.950592719348
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.8198925714,
                "lng": -130.5001530282
            },
            {
                "lat": 53.8200035796,
                "lng": -130.5043031589
            },
            {
                "lat": 53.8169741351,
                "lng": -130.5062100383
            },
            {
                "lat": 53.8185726322,
                "lng": -130.5123909015
            },
            {
                "lat": 53.8190502376,
                "lng": -130.5211946423
            },
            {
                "lat": 53.8190499593,
                "lng": -130.5302279126
            },
            {
                "lat": 53.8131025348,
                "lng": -130.5421294907
            },
            {
                "lat": 53.8066786409,
                "lng": -130.547836902
            },
            {
                "lat": 53.8012046179,
                "lng": -130.5490258945
            },
            {
                "lat": 53.8000148042,
                "lng": -130.5423730932
            },
            {
                "lat": 53.80096856,
                "lng": -130.5366509744
            },
            {
                "lat": 53.7985990701,
                "lng": -130.5373381505
            },
            {
                "lat": 53.7971802196,
                "lng": -130.5402369009
            },
            {
                "lat": 53.7960775043,
                "lng": -130.544433604
            },
            {
                "lat": 53.7927099559,
                "lng": -130.5468446834
            },
            {
                "lat": 53.791874065,
                "lng": -130.5465999924
            },
            {
                "lat": 53.790188182,
                "lng": -130.5460976512
            },
            {
                "lat": 53.7895164502,
                "lng": -130.5476526821
            },
            {
                "lat": 53.7895166962,
                "lng": -130.5477203673
            },
            {
                "lat": 53.7893426705,
                "lng": -130.5499281019
            },
            {
                "lat": 53.7893373626,
                "lng": -130.5499942196
            },
            {
                "lat": 53.7892224426,
                "lng": -130.5509973705
            },
            {
                "lat": 53.7881896457,
                "lng": -130.5527043372
            },
            {
                "lat": 53.637679628,
                "lng": -130.4665062677
            },
            {
                "lat": 53.6737903837,
                "lng": -130.4087530831
            },
            {
                "lat": 53.6739801104,
                "lng": -130.4082334711
            },
            {
                "lat": 53.6746181477,
                "lng": -130.4075169953
            },
            {
                "lat": 53.6754374796,
                "lng": -130.406631525
            },
            {
                "lat": 53.6765014864,
                "lng": -130.4047390377
            },
            {
                "lat": 53.6765014525,
                "lng": -130.399032569
            },
            {
                "lat": 53.6803014811,
                "lng": -130.3975980777
            },
            {
                "lat": 53.689182802,
                "lng": -130.3869472987
            },
            {
                "lat": 53.6960825485,
                "lng": -130.3731375974
            },
            {
                "lat": 53.7091712223,
                "lng": -130.3591001197
            },
            {
                "lat": 53.7246358583,
                "lng": -130.3379214033
            },
            {
                "lat": 53.730587011,
                "lng": -130.2929538255
            },
            {
                "lat": 53.7341542925,
                "lng": -130.2720186446
            },
            {
                "lat": 53.7510494562,
                "lng": -130.2636867501
            },
            {
                "lat": 53.7655639691,
                "lng": -130.2998506057
            },
            {
                "lat": 53.7746047811,
                "lng": -130.3055727706
            },
            {
                "lat": 53.7793649508,
                "lng": -130.3036647843
            },
            {
                "lat": 53.7831730264,
                "lng": -130.2948602461
            },
            {
                "lat": 53.790309593,
                "lng": -130.2929536767
            },
            {
                "lat": 53.7931055251,
                "lng": -130.29876757
            },
            {
                "lat": 53.7935715099,
                "lng": -130.3062430278
            },
            {
                "lat": 53.7952233779,
                "lng": -130.3127439813
            },
            {
                "lat": 53.7960892198,
                "lng": -130.31286606
            },
            {
                "lat": 53.7884711485,
                "lng": -130.3954015442
            },
            {
                "lat": 53.7915037446,
                "lng": -130.4095308012
            },
            {
                "lat": 53.7885433085,
                "lng": -130.4108578437
            },
            {
                "lat": 53.788543312,
                "lng": -130.4156041455
            },
            {
                "lat": 53.7898448097,
                "lng": -130.4233249034
            },
            {
                "lat": 53.7916981378,
                "lng": -130.4298405785
            },
            {
                "lat": 53.7961582772,
                "lng": -130.4322818804
            },
            {
                "lat": 53.7969892453,
                "lng": -130.4330135456
            },
            {
                "lat": 53.8198925714,
                "lng": -130.5001530282
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 103,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 5-10",
            "LABEL": "5-10",
            "FISHERY_AREA_ID": 2014,
            "OBJECTID": 103,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 197447755.0624,
            "FEATURE_LENGTH_M": 66283.691,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.76899268582143,
            "lng": -130.43777413851248
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.6903958972,
                "lng": -133.0314025301
            },
            {
                "lat": 53.6903956345,
                "lng": -133.0773475536
            },
            {
                "lat": 53.5478513339,
                "lng": -133.0090482226
            },
            {
                "lat": 53.5627742517,
                "lng": -132.9930884629
            },
            {
                "lat": 53.5621913862,
                "lng": -132.9926145921
            },
            {
                "lat": 53.5616684164,
                "lng": -132.9913790923
            },
            {
                "lat": 53.5612832308,
                "lng": -132.9900973067
            },
            {
                "lat": 53.5606113989,
                "lng": -132.987609192
            },
            {
                "lat": 53.5602307931,
                "lng": -132.9851831453
            },
            {
                "lat": 53.5604670832,
                "lng": -132.9837639396
            },
            {
                "lat": 53.561417098,
                "lng": -132.9816134519
            },
            {
                "lat": 53.5609433371,
                "lng": -132.9782871628
            },
            {
                "lat": 53.56077555,
                "lng": -132.9756933761
            },
            {
                "lat": 53.5618894794,
                "lng": -132.975403201
            },
            {
                "lat": 53.5627439264,
                "lng": -132.9719687971
            },
            {
                "lat": 53.5637171477,
                "lng": -132.968368767
            },
            {
                "lat": 53.5688550313,
                "lng": -132.9672239592
            },
            {
                "lat": 53.5735392596,
                "lng": -132.9692845745
            },
            {
                "lat": 53.5774231647,
                "lng": -132.9749909573
            },
            {
                "lat": 53.5862964174,
                "lng": -132.9784544099
            },
            {
                "lat": 53.5996743717,
                "lng": -132.9701229476
            },
            {
                "lat": 53.602169576,
                "lng": -132.9671322731
            },
            {
                "lat": 53.6036605493,
                "lng": -132.9651646929
            },
            {
                "lat": 53.6063262898,
                "lng": -132.9659880812
            },
            {
                "lat": 53.6371616263,
                "lng": -132.9710839917
            },
            {
                "lat": 53.6412698578,
                "lng": -132.9717715645
            },
            {
                "lat": 53.6407892537,
                "lng": -132.9707496044
            },
            {
                "lat": 53.6403699084,
                "lng": -132.9696807956
            },
            {
                "lat": 53.64042701,
                "lng": -132.9689629449
            },
            {
                "lat": 53.6411857248,
                "lng": -132.9679110109
            },
            {
                "lat": 53.6438144171,
                "lng": -132.9661868697
            },
            {
                "lat": 53.6455921199,
                "lng": -132.9649355287
            },
            {
                "lat": 53.6459006178,
                "lng": -132.9638824942
            },
            {
                "lat": 53.6459544315,
                "lng": -132.9628440581
            },
            {
                "lat": 53.6459727141,
                "lng": -132.9619901297
            },
            {
                "lat": 53.6459765925,
                "lng": -132.9612575832
            },
            {
                "lat": 53.6460611787,
                "lng": -132.9594882234
            },
            {
                "lat": 53.6463964663,
                "lng": -132.9554439835
            },
            {
                "lat": 53.6471555688,
                "lng": -132.9519959649
            },
            {
                "lat": 53.6474910609,
                "lng": -132.9509120893
            },
            {
                "lat": 53.6477436687,
                "lng": -132.9495696565
            },
            {
                "lat": 53.6482465306,
                "lng": -132.9482108104
            },
            {
                "lat": 53.6501813491,
                "lng": -132.9453573475
            },
            {
                "lat": 53.6693840625,
                "lng": -132.9467934968
            },
            {
                "lat": 53.6753303269,
                "lng": -132.9532168631
            },
            {
                "lat": 53.6748581914,
                "lng": -132.9710537335
            },
            {
                "lat": 53.670574088,
                "lng": -132.9900970126
            },
            {
                "lat": 53.6696206884,
                "lng": -132.9967495024
            },
            {
                "lat": 53.6715235991,
                "lng": -133.0041342192
            },
            {
                "lat": 53.671672528,
                "lng": -133.0086671475
            },
            {
                "lat": 53.6792911787,
                "lng": -133.0083160351
            },
            {
                "lat": 53.6813121748,
                "lng": -133.0103609849
            },
            {
                "lat": 53.6841198931,
                "lng": -133.0142675537
            },
            {
                "lat": 53.6856613098,
                "lng": -133.0180661962
            },
            {
                "lat": 53.6879231708,
                "lng": -133.0227053241
            },
            {
                "lat": 53.6894107905,
                "lng": -133.0250694958
            },
            {
                "lat": 53.6898501404,
                "lng": -133.0268251799
            },
            {
                "lat": 53.6897392333,
                "lng": -133.028931353
            },
            {
                "lat": 53.6903958972,
                "lng": -133.0314025301
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 104,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 88,
            "MANAGEMENT_AREA_NAME": "Subarea 2-88",
            "LABEL": "2-88",
            "FISHERY_AREA_ID": 1978,
            "OBJECTID": 104,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 72301815.6052,
            "FEATURE_LENGTH_M": 44452.6452,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.62840098295253,
            "lng": -132.98254444013224
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.6918102099,
                "lng": -132.9775395007
            },
            {
                "lat": 53.6903991363,
                "lng": -132.9801638139
            },
            {
                "lat": 53.6903958972,
                "lng": -133.0314025301
            },
            {
                "lat": 53.6897392333,
                "lng": -133.028931353
            },
            {
                "lat": 53.6898501404,
                "lng": -133.0268251799
            },
            {
                "lat": 53.6894107905,
                "lng": -133.0250694958
            },
            {
                "lat": 53.6879231708,
                "lng": -133.0227053241
            },
            {
                "lat": 53.6856613098,
                "lng": -133.0180661962
            },
            {
                "lat": 53.6841198931,
                "lng": -133.0142675537
            },
            {
                "lat": 53.6813121748,
                "lng": -133.0103609849
            },
            {
                "lat": 53.6792911787,
                "lng": -133.0083160351
            },
            {
                "lat": 53.6776926041,
                "lng": -133.0012057422
            },
            {
                "lat": 53.6758465401,
                "lng": -132.9951627867
            },
            {
                "lat": 53.6742245886,
                "lng": -132.9925083331
            },
            {
                "lat": 53.6741557597,
                "lng": -132.9923862504
            },
            {
                "lat": 53.6733317937,
                "lng": -132.9902803559
            },
            {
                "lat": 53.673076564,
                "lng": -132.9879764187
            },
            {
                "lat": 53.6754378331,
                "lng": -132.9855650949
            },
            {
                "lat": 53.6786157704,
                "lng": -132.9855203179
            },
            {
                "lat": 53.6805157834,
                "lng": -132.9866946461
            },
            {
                "lat": 53.6811173502,
                "lng": -132.9872443976
            },
            {
                "lat": 53.6826668691,
                "lng": -132.9860679534
            },
            {
                "lat": 53.6822583175,
                "lng": -132.9836120554
            },
            {
                "lat": 53.6817474013,
                "lng": -132.9785915833
            },
            {
                "lat": 53.6824109573,
                "lng": -132.9762876847
            },
            {
                "lat": 53.6841049985,
                "lng": -132.975814568
            },
            {
                "lat": 53.6877974489,
                "lng": -132.9759209508
            },
            {
                "lat": 53.6902084192,
                "lng": -132.9769433126
            },
            {
                "lat": 53.6918102099,
                "lng": -132.9775395007
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 105,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 93,
            "MANAGEMENT_AREA_NAME": "Subarea 2-93",
            "LABEL": "2-93",
            "FISHERY_AREA_ID": 1983,
            "OBJECTID": 105,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 3650274.8958,
            "FEATURE_LENGTH_M": 10073.77,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.68299766702758,
            "lng": -132.9958265489586
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.691230692,
                "lng": -132.9448095553
            },
            {
                "lat": 53.692356417,
                "lng": -132.9452517243
            },
            {
                "lat": 53.6922224603,
                "lng": -132.9463656449
            },
            {
                "lat": 53.6922226606,
                "lng": -132.9567254914
            },
            {
                "lat": 53.6921049745,
                "lng": -132.9587858208
            },
            {
                "lat": 53.6921919502,
                "lng": -132.9605104801
            },
            {
                "lat": 53.6912876987,
                "lng": -132.9624793862
            },
            {
                "lat": 53.6898802599,
                "lng": -132.9684457253
            },
            {
                "lat": 53.6891479557,
                "lng": -132.9725487599
            },
            {
                "lat": 53.688586819,
                "lng": -132.9751437807
            },
            {
                "lat": 53.6874049572,
                "lng": -132.9752499834
            },
            {
                "lat": 53.6845352866,
                "lng": -132.9736782771
            },
            {
                "lat": 53.6815527921,
                "lng": -132.9711463411
            },
            {
                "lat": 53.6805375982,
                "lng": -132.9669186469
            },
            {
                "lat": 53.6808205786,
                "lng": -132.9589236245
            },
            {
                "lat": 53.6807053352,
                "lng": -132.9520572062
            },
            {
                "lat": 53.681270335,
                "lng": -132.9457092762
            },
            {
                "lat": 53.6823385764,
                "lng": -132.9430540438
            },
            {
                "lat": 53.684986096,
                "lng": -132.9444730494
            },
            {
                "lat": 53.6884189425,
                "lng": -132.9445801015
            },
            {
                "lat": 53.691230692,
                "lng": -132.9448095553
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 106,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 94,
            "MANAGEMENT_AREA_NAME": "Subarea 2-94",
            "LABEL": "2-94",
            "FISHERY_AREA_ID": 1984,
            "OBJECTID": 106,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2247391.0688,
            "FEATURE_LENGTH_M": 5953.1942,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.687382527509534,
            "lng": -132.9576984035381
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.637679628,
                "lng": -130.4665062677
            },
            {
                "lat": 53.7881896457,
                "lng": -130.5527043372
            },
            {
                "lat": 53.7892224426,
                "lng": -130.5509973705
            },
            {
                "lat": 53.7892149343,
                "lng": -130.5510623023
            },
            {
                "lat": 53.7881893617,
                "lng": -130.5527710022
            },
            {
                "lat": 53.6376793568,
                "lng": -130.4665736315
            },
            {
                "lat": 53.634398791,
                "lng": -130.467290946
            },
            {
                "lat": 53.6308282902,
                "lng": -130.4573116457
            },
            {
                "lat": 53.629657456,
                "lng": -130.4529642086
            },
            {
                "lat": 53.6291197044,
                "lng": -130.4508730251
            },
            {
                "lat": 53.6245237256,
                "lng": -130.4467529078
            },
            {
                "lat": 53.6176876493,
                "lng": -130.4524903148
            },
            {
                "lat": 53.6136849901,
                "lng": -130.4586247363
            },
            {
                "lat": 53.6200451139,
                "lng": -130.4812226252
            },
            {
                "lat": 53.6236910305,
                "lng": -130.5070097815
            },
            {
                "lat": 53.6222802014,
                "lng": -130.5233825066
            },
            {
                "lat": 53.6157423686,
                "lng": -130.5271519492
            },
            {
                "lat": 53.6153407748,
                "lng": -130.5324618696
            },
            {
                "lat": 53.6179587374,
                "lng": -130.5371258343
            },
            {
                "lat": 53.6115305933,
                "lng": -130.5350953836
            },
            {
                "lat": 53.6096453997,
                "lng": -130.5321965789
            },
            {
                "lat": 53.6076353075,
                "lng": -130.5264897301
            },
            {
                "lat": 53.6024021089,
                "lng": -130.5184023798
            },
            {
                "lat": 53.5836036354,
                "lng": -130.5164949389
            },
            {
                "lat": 53.5781248196,
                "lng": -130.5171806567
            },
            {
                "lat": 53.5628999445,
                "lng": -130.5191040441
            },
            {
                "lat": 53.5555229194,
                "lng": -130.5095977574
            },
            {
                "lat": 53.5536159875,
                "lng": -130.5055079399
            },
            {
                "lat": 53.5433879241,
                "lng": -130.4836577238
            },
            {
                "lat": 53.5386308157,
                "lng": -130.4781793259
            },
            {
                "lat": 53.5296820643,
                "lng": -130.4790494737
            },
            {
                "lat": 53.5276835657,
                "lng": -130.4745486608
            },
            {
                "lat": 53.52411643,
                "lng": -130.4519499813
            },
            {
                "lat": 53.5348241788,
                "lng": -130.4079289918
            },
            {
                "lat": 53.5389859757,
                "lng": -130.376999209
            },
            {
                "lat": 53.5282783692,
                "lng": -130.3508301332
            },
            {
                "lat": 53.5205457378,
                "lng": -130.3109742986
            },
            {
                "lat": 53.5169757123,
                "lng": -130.2925265001
            },
            {
                "lat": 53.5021058992,
                "lng": -130.2943119654
            },
            {
                "lat": 53.4979404137,
                "lng": -130.319289568
            },
            {
                "lat": 53.50091614,
                "lng": -130.3549802224
            },
            {
                "lat": 53.4943733922,
                "lng": -130.3787837014
            },
            {
                "lat": 53.4812396957,
                "lng": -130.3912362027
            },
            {
                "lat": 53.4698181332,
                "lng": -130.3936006747
            },
            {
                "lat": 53.4627188537,
                "lng": -130.3989565576
            },
            {
                "lat": 53.4642032012,
                "lng": -130.3898621662
            },
            {
                "lat": 53.4387431681,
                "lng": -130.3477481909
            },
            {
                "lat": 53.4225614847,
                "lng": -130.3267978879
            },
            {
                "lat": 53.4059068431,
                "lng": -130.3108676115
            },
            {
                "lat": 53.4130443674,
                "lng": -130.275650714
            },
            {
                "lat": 53.4163773265,
                "lng": -130.230911307
            },
            {
                "lat": 53.378779988,
                "lng": -130.1754763788
            },
            {
                "lat": 53.3637883283,
                "lng": -130.1550142072
            },
            {
                "lat": 53.3609345921,
                "lng": -130.1331177514
            },
            {
                "lat": 53.353321335,
                "lng": -130.1102752805
            },
            {
                "lat": 53.3338086311,
                "lng": -130.0955196376
            },
            {
                "lat": 53.2988318472,
                "lng": -130.0795743076
            },
            {
                "lat": 53.2767024739,
                "lng": -130.0555415319
            },
            {
                "lat": 53.2609978601,
                "lng": -130.0305636655
            },
            {
                "lat": 53.2548103376,
                "lng": -130.0101013628
            },
            {
                "lat": 53.2329220051,
                "lng": -129.998672698
            },
            {
                "lat": 53.2205471315,
                "lng": -129.9922478756
            },
            {
                "lat": 53.2093621039,
                "lng": -129.9753569718
            },
            {
                "lat": 53.1915166289,
                "lng": -129.9601291094
            },
            {
                "lat": 53.1800952944,
                "lng": -129.9558415006
            },
            {
                "lat": 53.1727177864,
                "lng": -129.9529880601
            },
            {
                "lat": 53.1676678221,
                "lng": -129.9492185978
            },
            {
                "lat": 53.1725468918,
                "lng": -129.9441064793
            },
            {
                "lat": 53.1768307103,
                "lng": -129.9322055642
            },
            {
                "lat": 53.1803968463,
                "lng": -129.9176940387
            },
            {
                "lat": 53.1806375262,
                "lng": -129.8943788472
            },
            {
                "lat": 53.1892009954,
                "lng": -129.8791504174
            },
            {
                "lat": 53.1970558957,
                "lng": -129.8667750749
            },
            {
                "lat": 53.1956820669,
                "lng": -129.8291009778
            },
            {
                "lat": 53.1889648464,
                "lng": -129.8089603158
            },
            {
                "lat": 53.2028425351,
                "lng": -129.7852024433
            },
            {
                "lat": 53.2111699743,
                "lng": -129.7680673499
            },
            {
                "lat": 53.2230686509,
                "lng": -129.778533847
            },
            {
                "lat": 53.2513845924,
                "lng": -129.808273555
            },
            {
                "lat": 53.2809024099,
                "lng": -129.8306877916
            },
            {
                "lat": 53.4590833712,
                "lng": -130.0427091641
            },
            {
                "lat": 53.4662475663,
                "lng": -130.0562136285
            },
            {
                "lat": 53.503250231,
                "lng": -130.1127316294
            },
            {
                "lat": 53.5149077457,
                "lng": -130.1562805753
            },
            {
                "lat": 53.5222852154,
                "lng": -130.1872094801
            },
            {
                "lat": 53.5306121919,
                "lng": -130.2029105528
            },
            {
                "lat": 53.5427476401,
                "lng": -130.2105254537
            },
            {
                "lat": 53.5682109085,
                "lng": -130.2945252702
            },
            {
                "lat": 53.5858153522,
                "lng": -130.3478245411
            },
            {
                "lat": 53.5972367945,
                "lng": -130.3780369635
            },
            {
                "lat": 53.6160356185,
                "lng": -130.4325264604
            },
            {
                "lat": 53.6245234317,
                "lng": -130.446685491
            },
            {
                "lat": 53.6291194038,
                "lng": -130.4508055999
            },
            {
                "lat": 53.629657165,
                "lng": -130.4528967986
            },
            {
                "lat": 53.6308285567,
                "lng": -130.4572442778
            },
            {
                "lat": 53.6343990631,
                "lng": -130.4672235724
            },
            {
                "lat": 53.637679628,
                "lng": -130.4665062677
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 107,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 107,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 727954177.1765,
            "FEATURE_LENGTH_M": 214103.8671,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.47075569691959,
            "lng": -130.27289298045983
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.7888829968,
                "lng": -130.2440803392
            },
            {
                "lat": 53.7890777015,
                "lng": -130.2568674426
            },
            {
                "lat": 53.7916344225,
                "lng": -130.2660978709
            },
            {
                "lat": 53.7939533813,
                "lng": -130.2707816683
            },
            {
                "lat": 53.7949298054,
                "lng": -130.2900392326
            },
            {
                "lat": 53.7931055251,
                "lng": -130.29876757
            },
            {
                "lat": 53.790309593,
                "lng": -130.2929536767
            },
            {
                "lat": 53.7831730264,
                "lng": -130.2948602461
            },
            {
                "lat": 53.7793649508,
                "lng": -130.3036647843
            },
            {
                "lat": 53.7746047811,
                "lng": -130.3055727706
            },
            {
                "lat": 53.7655639691,
                "lng": -130.2998506057
            },
            {
                "lat": 53.7510494562,
                "lng": -130.2636867501
            },
            {
                "lat": 53.7341542925,
                "lng": -130.2720186446
            },
            {
                "lat": 53.730587011,
                "lng": -130.2929538255
            },
            {
                "lat": 53.7246358583,
                "lng": -130.3379214033
            },
            {
                "lat": 53.7091712223,
                "lng": -130.3591001197
            },
            {
                "lat": 53.6960825485,
                "lng": -130.3731375974
            },
            {
                "lat": 53.689182802,
                "lng": -130.3869472987
            },
            {
                "lat": 53.6803014811,
                "lng": -130.3975980777
            },
            {
                "lat": 53.6765014525,
                "lng": -130.399032569
            },
            {
                "lat": 53.670471328,
                "lng": -130.3986519785
            },
            {
                "lat": 53.642749402,
                "lng": -130.31433065
            },
            {
                "lat": 53.6297527578,
                "lng": -130.3086695855
            },
            {
                "lat": 53.6217878253,
                "lng": -130.2699588181
            },
            {
                "lat": 53.6140097459,
                "lng": -130.2532804855
            },
            {
                "lat": 53.6106755786,
                "lng": -130.226791259
            },
            {
                "lat": 53.6030807802,
                "lng": -130.2025305132
            },
            {
                "lat": 53.5921520223,
                "lng": -130.1801143352
            },
            {
                "lat": 53.5954090423,
                "lng": -130.1703338569
            },
            {
                "lat": 53.5928420085,
                "lng": -130.1625363894
            },
            {
                "lat": 53.5841865318,
                "lng": -130.1547393288
            },
            {
                "lat": 53.5791856532,
                "lng": -130.1454775409
            },
            {
                "lat": 53.5791853973,
                "lng": -130.1315916962
            },
            {
                "lat": 53.5738138606,
                "lng": -130.1152807001
            },
            {
                "lat": 53.5693706043,
                "lng": -130.1006472277
            },
            {
                "lat": 53.5677034276,
                "lng": -130.0880586347
            },
            {
                "lat": 53.5674778219,
                "lng": -130.0853886101
            },
            {
                "lat": 53.5673330507,
                "lng": -130.0836180097
            },
            {
                "lat": 53.5767781342,
                "lng": -130.0952757981
            },
            {
                "lat": 53.5825198596,
                "lng": -130.1054686199
            },
            {
                "lat": 53.5958564304,
                "lng": -130.1101076387
            },
            {
                "lat": 53.6054882697,
                "lng": -130.115662583
            },
            {
                "lat": 53.6110460076,
                "lng": -130.1310265389
            },
            {
                "lat": 53.6206782532,
                "lng": -130.1345517199
            },
            {
                "lat": 53.6319773178,
                "lng": -130.1277002932
            },
            {
                "lat": 53.6374313636,
                "lng": -130.1288452621
            },
            {
                "lat": 53.6513325427,
                "lng": -130.1390229345
            },
            {
                "lat": 53.6695864942,
                "lng": -130.1384583274
            },
            {
                "lat": 53.6898080857,
                "lng": -130.1515509825
            },
            {
                "lat": 53.6931571114,
                "lng": -130.1696320344
            },
            {
                "lat": 53.6957589191,
                "lng": -130.1836696247
            },
            {
                "lat": 53.6981385146,
                "lng": -130.2181701527
            },
            {
                "lat": 53.7171747059,
                "lng": -130.2407838641
            },
            {
                "lat": 53.7335927227,
                "lng": -130.2410122541
            },
            {
                "lat": 53.757384959,
                "lng": -130.2288816615
            },
            {
                "lat": 53.7718234935,
                "lng": -130.2326352193
            },
            {
                "lat": 53.7755516472,
                "lng": -130.2371819941
            },
            {
                "lat": 53.7888829968,
                "lng": -130.2440803392
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 108,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 108,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 196849989.065,
            "FEATURE_LENGTH_M": 80005.3787,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.67761070594138,
            "lng": -130.22364913715177
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.7946203805,
                "lng": -130.2106936241
            },
            {
                "lat": 53.7924802577,
                "lng": -130.2178962034
            },
            {
                "lat": 53.7925187413,
                "lng": -130.2187650809
            },
            {
                "lat": 53.7925144237,
                "lng": -130.2423403413
            },
            {
                "lat": 53.7888829968,
                "lng": -130.2440803392
            },
            {
                "lat": 53.7755516472,
                "lng": -130.2371819941
            },
            {
                "lat": 53.7718234935,
                "lng": -130.2326352193
            },
            {
                "lat": 53.757384959,
                "lng": -130.2288816615
            },
            {
                "lat": 53.7335927227,
                "lng": -130.2410122541
            },
            {
                "lat": 53.7171747059,
                "lng": -130.2407838641
            },
            {
                "lat": 53.6981385146,
                "lng": -130.2181701527
            },
            {
                "lat": 53.6957589191,
                "lng": -130.1836696247
            },
            {
                "lat": 53.6931571114,
                "lng": -130.1696320344
            },
            {
                "lat": 53.6898080857,
                "lng": -130.1515509825
            },
            {
                "lat": 53.6695864942,
                "lng": -130.1384583274
            },
            {
                "lat": 53.6513325427,
                "lng": -130.1390229345
            },
            {
                "lat": 53.6374313636,
                "lng": -130.1288452621
            },
            {
                "lat": 53.6319773178,
                "lng": -130.1277002932
            },
            {
                "lat": 53.6206782532,
                "lng": -130.1345517199
            },
            {
                "lat": 53.6110460076,
                "lng": -130.1310265389
            },
            {
                "lat": 53.6054882697,
                "lng": -130.115662583
            },
            {
                "lat": 53.5958564304,
                "lng": -130.1101076387
            },
            {
                "lat": 53.5825198596,
                "lng": -130.1054686199
            },
            {
                "lat": 53.5767781342,
                "lng": -130.0952757981
            },
            {
                "lat": 53.5673330507,
                "lng": -130.0836180097
            },
            {
                "lat": 53.564128447,
                "lng": -130.0751955055
            },
            {
                "lat": 53.5611875905,
                "lng": -130.0751954165
            },
            {
                "lat": 53.5526275365,
                "lng": -130.0898282114
            },
            {
                "lat": 53.5497778587,
                "lng": -130.0960843587
            },
            {
                "lat": 53.5479464124,
                "lng": -130.0978238533
            },
            {
                "lat": 53.5068895353,
                "lng": -130.0267034128
            },
            {
                "lat": 53.5648954902,
                "lng": -130.0092170169
            },
            {
                "lat": 53.5673100119,
                "lng": -130.0072939337
            },
            {
                "lat": 53.5698886542,
                "lng": -130.0066984818
            },
            {
                "lat": 53.5770260068,
                "lng": -130.0059810523
            },
            {
                "lat": 53.5827376256,
                "lng": -130.0171664209
            },
            {
                "lat": 53.6001086649,
                "lng": -130.0361937847
            },
            {
                "lat": 53.6136699786,
                "lng": -130.0526125365
            },
            {
                "lat": 53.6224091259,
                "lng": -130.060286664
            },
            {
                "lat": 53.6237411314,
                "lng": -130.0605782233
            },
            {
                "lat": 53.6233521321,
                "lng": -130.0626824985
            },
            {
                "lat": 53.6232836544,
                "lng": -130.0628816947
            },
            {
                "lat": 53.623015703,
                "lng": -130.0635690403
            },
            {
                "lat": 53.6227946308,
                "lng": -130.0641480533
            },
            {
                "lat": 53.6227948615,
                "lng": -130.0644383588
            },
            {
                "lat": 53.6229707619,
                "lng": -130.064742886
            },
            {
                "lat": 53.6307528992,
                "lng": -130.0727086012
            },
            {
                "lat": 53.6330833751,
                "lng": -130.0720968173
            },
            {
                "lat": 53.6362351115,
                "lng": -130.0725404699
            },
            {
                "lat": 53.6431345951,
                "lng": -130.0749201694
            },
            {
                "lat": 53.6616937631,
                "lng": -130.07730101
            },
            {
                "lat": 53.6750185237,
                "lng": -130.0773005619
            },
            {
                "lat": 53.6992910871,
                "lng": -130.0720665878
            },
            {
                "lat": 53.7085685201,
                "lng": -130.0680240528
            },
            {
                "lat": 53.7211797064,
                "lng": -130.067550761
            },
            {
                "lat": 53.7292705283,
                "lng": -130.0761109124
            },
            {
                "lat": 53.7309372918,
                "lng": -130.0908657242
            },
            {
                "lat": 53.7254636116,
                "lng": -130.105620932
            },
            {
                "lat": 53.7180862207,
                "lng": -130.1227564698
            },
            {
                "lat": 53.7185628544,
                "lng": -130.1415403841
            },
            {
                "lat": 53.7223436724,
                "lng": -130.1554108767
            },
            {
                "lat": 53.7670854191,
                "lng": -130.1958013659
            },
            {
                "lat": 53.7842373491,
                "lng": -130.197463469
            },
            {
                "lat": 53.7946203805,
                "lng": -130.2106936241
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 109,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 5-14",
            "LABEL": "5-14",
            "FISHERY_AREA_ID": 2408,
            "OBJECTID": 109,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 150419568.8335,
            "FEATURE_LENGTH_M": 84625.0514,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.66071180321249,
            "lng": -130.11558008273593
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.6501813491,
                "lng": -132.9453573475
            },
            {
                "lat": 53.6482465306,
                "lng": -132.9482108104
            },
            {
                "lat": 53.6477436687,
                "lng": -132.9495696565
            },
            {
                "lat": 53.6474910609,
                "lng": -132.9509120893
            },
            {
                "lat": 53.6471555688,
                "lng": -132.9519959649
            },
            {
                "lat": 53.6463964663,
                "lng": -132.9554439835
            },
            {
                "lat": 53.6460611787,
                "lng": -132.9594882234
            },
            {
                "lat": 53.6459765925,
                "lng": -132.9612575832
            },
            {
                "lat": 53.6459727141,
                "lng": -132.9619901297
            },
            {
                "lat": 53.6459544315,
                "lng": -132.9628440581
            },
            {
                "lat": 53.6459006178,
                "lng": -132.9638824942
            },
            {
                "lat": 53.6455921199,
                "lng": -132.9649355287
            },
            {
                "lat": 53.6438144171,
                "lng": -132.9661868697
            },
            {
                "lat": 53.6411857248,
                "lng": -132.9679110109
            },
            {
                "lat": 53.64042701,
                "lng": -132.9689629449
            },
            {
                "lat": 53.6403699084,
                "lng": -132.9696807956
            },
            {
                "lat": 53.6407892537,
                "lng": -132.9707496044
            },
            {
                "lat": 53.6412698578,
                "lng": -132.9717715645
            },
            {
                "lat": 53.6371616263,
                "lng": -132.9710839917
            },
            {
                "lat": 53.6063262898,
                "lng": -132.9659880812
            },
            {
                "lat": 53.6120760678,
                "lng": -132.9477840908
            },
            {
                "lat": 53.6120798182,
                "lng": -132.9201353479
            },
            {
                "lat": 53.6150355033,
                "lng": -132.9176017122
            },
            {
                "lat": 53.6199229451,
                "lng": -132.9188076728
            },
            {
                "lat": 53.6262090968,
                "lng": -132.9202420712
            },
            {
                "lat": 53.6351243079,
                "lng": -132.9238427174
            },
            {
                "lat": 53.6423606691,
                "lng": -132.9269404451
            },
            {
                "lat": 53.6479112763,
                "lng": -132.9301295425
            },
            {
                "lat": 53.6498449212,
                "lng": -132.9371947128
            },
            {
                "lat": 53.6501813491,
                "lng": -132.9453573475
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 110,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 89,
            "MANAGEMENT_AREA_NAME": "Subarea 2-89",
            "LABEL": "2-89",
            "FISHERY_AREA_ID": 1979,
            "OBJECTID": 110,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 12622738.2231,
            "FEATURE_LENGTH_M": 14600.6876,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.63882541138666,
            "lng": -132.95054194641665
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.6150355033,
                "lng": -132.9176017122
            },
            {
                "lat": 53.6120798182,
                "lng": -132.9201353479
            },
            {
                "lat": 53.6120760678,
                "lng": -132.9477840908
            },
            {
                "lat": 53.6063262898,
                "lng": -132.9659880812
            },
            {
                "lat": 53.6036605493,
                "lng": -132.9651646929
            },
            {
                "lat": 53.5989761749,
                "lng": -132.9523305782
            },
            {
                "lat": 53.5918353009,
                "lng": -132.9411473037
            },
            {
                "lat": 53.58565148,
                "lng": -132.9294888367
            },
            {
                "lat": 53.5816032329,
                "lng": -132.9168852198
            },
            {
                "lat": 53.5785101616,
                "lng": -132.9059288598
            },
            {
                "lat": 53.5787502718,
                "lng": -132.8971261344
            },
            {
                "lat": 53.5851752359,
                "lng": -132.8911896607
            },
            {
                "lat": 53.5901714972,
                "lng": -132.8895114295
            },
            {
                "lat": 53.5938307991,
                "lng": -132.8900609038
            },
            {
                "lat": 53.6019854429,
                "lng": -132.8901206746
            },
            {
                "lat": 53.6073030939,
                "lng": -132.8897550607
            },
            {
                "lat": 53.6086116382,
                "lng": -132.8999944635
            },
            {
                "lat": 53.6117063704,
                "lng": -132.9080815801
            },
            {
                "lat": 53.6150355033,
                "lng": -132.9176017122
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 111,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 90,
            "MANAGEMENT_AREA_NAME": "Subarea 2-90",
            "LABEL": "2-90",
            "FISHERY_AREA_ID": 1980,
            "OBJECTID": 111,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 12694723.4015,
            "FEATURE_LENGTH_M": 14663.6941,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.59885918060001,
            "lng": -132.91767875487895
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.6073030939,
                "lng": -132.8897550607
            },
            {
                "lat": 53.6019854429,
                "lng": -132.8901206746
            },
            {
                "lat": 53.5938307991,
                "lng": -132.8900609038
            },
            {
                "lat": 53.5901714972,
                "lng": -132.8895114295
            },
            {
                "lat": 53.5854116191,
                "lng": -132.8700105612
            },
            {
                "lat": 53.5801774241,
                "lng": -132.8552543164
            },
            {
                "lat": 53.5792275537,
                "lng": -132.8409725602
            },
            {
                "lat": 53.5844613836,
                "lng": -132.8278962243
            },
            {
                "lat": 53.5939782851,
                "lng": -132.8181305024
            },
            {
                "lat": 53.5980227356,
                "lng": -132.8136140334
            },
            {
                "lat": 53.6018062619,
                "lng": -132.8132785082
            },
            {
                "lat": 53.6025125705,
                "lng": -132.8132168676
            },
            {
                "lat": 53.6106337727,
                "lng": -132.8169402493
            },
            {
                "lat": 53.6103977463,
                "lng": -132.8259885802
            },
            {
                "lat": 53.6058772375,
                "lng": -132.8343193554
            },
            {
                "lat": 53.6023440021,
                "lng": -132.8378448038
            },
            {
                "lat": 53.6015932807,
                "lng": -132.8385920503
            },
            {
                "lat": 53.6011155777,
                "lng": -132.8526308664
            },
            {
                "lat": 53.6021998266,
                "lng": -132.8586730793
            },
            {
                "lat": 53.6044500126,
                "lng": -132.8712000485
            },
            {
                "lat": 53.6063498536,
                "lng": -132.8842767337
            },
            {
                "lat": 53.6073030939,
                "lng": -132.8897550607
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 112,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 91,
            "MANAGEMENT_AREA_NAME": "Subarea 2-91",
            "LABEL": "2-91",
            "FISHERY_AREA_ID": 1981,
            "OBJECTID": 112,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 11280425.9775,
            "FEATURE_LENGTH_M": 15034.5875,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.59868877592728,
            "lng": -132.85100193045
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.6561629841,
                "lng": -129.0770566456
            },
            {
                "lat": 53.6749031616,
                "lng": -129.0799553134
            },
            {
                "lat": 53.6866112689,
                "lng": -129.0798188107
            },
            {
                "lat": 53.6893573099,
                "lng": -129.0786739763
            },
            {
                "lat": 53.6926115185,
                "lng": -129.0769501679
            },
            {
                "lat": 53.6941909647,
                "lng": -129.0778347394
            },
            {
                "lat": 53.694469166,
                "lng": -129.0792691982
            },
            {
                "lat": 53.6930080833,
                "lng": -129.0828087501
            },
            {
                "lat": 53.691631102,
                "lng": -129.0853420861
            },
            {
                "lat": 53.7539597903,
                "lng": -129.0308684121
            },
            {
                "lat": 53.7517167378,
                "lng": -129.0679628803
            },
            {
                "lat": 53.7411155429,
                "lng": -129.0778191806
            },
            {
                "lat": 53.6908720974,
                "lng": -129.2615055788
            },
            {
                "lat": 53.6831061037,
                "lng": -129.3426973328
            },
            {
                "lat": 53.6688313584,
                "lng": -129.3771964113
            },
            {
                "lat": 53.621238116,
                "lng": -129.3712466013
            },
            {
                "lat": 53.5462877815,
                "lng": -129.3700556715
            },
            {
                "lat": 53.5265156123,
                "lng": -129.3533632202
            },
            {
                "lat": 53.5062224916,
                "lng": -129.325454422
            },
            {
                "lat": 53.485233843,
                "lng": -129.2845616758
            },
            {
                "lat": 53.4664148821,
                "lng": -129.2700953837
            },
            {
                "lat": 53.4425350425,
                "lng": -129.2679140511
            },
            {
                "lat": 53.4269749573,
                "lng": -129.2751624816
            },
            {
                "lat": 53.4207114825,
                "lng": -129.2849571959
            },
            {
                "lat": 53.4128605611,
                "lng": -129.2979585469
            },
            {
                "lat": 53.3871687994,
                "lng": -129.2958070483
            },
            {
                "lat": 53.3708651311,
                "lng": -129.2846839478
            },
            {
                "lat": 53.3655890043,
                "lng": -129.2759696214
            },
            {
                "lat": 53.3646357066,
                "lng": -129.2701258794
            },
            {
                "lat": 53.3670383865,
                "lng": -129.2616581993
            },
            {
                "lat": 53.3671119815,
                "lng": -129.2614283202
            },
            {
                "lat": 53.3695980754,
                "lng": -129.2580408373
            },
            {
                "lat": 53.3701325619,
                "lng": -129.2493905652
            },
            {
                "lat": 53.3665654467,
                "lng": -129.2389223211
            },
            {
                "lat": 53.360369941,
                "lng": -129.2337952038
            },
            {
                "lat": 53.3589476585,
                "lng": -129.2278438593
            },
            {
                "lat": 53.3818359294,
                "lng": -129.1653592577
            },
            {
                "lat": 53.3861768263,
                "lng": -129.1653596376
            },
            {
                "lat": 53.3917079987,
                "lng": -129.1642456001
            },
            {
                "lat": 53.4020352475,
                "lng": -129.163512682
            },
            {
                "lat": 53.4265325876,
                "lng": -129.1599730449
            },
            {
                "lat": 53.4916724939,
                "lng": -129.1369019602
            },
            {
                "lat": 53.4986224177,
                "lng": -129.1313325095
            },
            {
                "lat": 53.635303292,
                "lng": -129.0914915984
            },
            {
                "lat": 53.6561629841,
                "lng": -129.0770566456
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 113,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 6-2",
            "LABEL": "6-2",
            "FISHERY_AREA_ID": 2410,
            "OBJECTID": 113,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 533677693.1623,
            "FEATURE_LENGTH_M": 115460.537,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.52523587621111,
            "lng": -129.20198727717116
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.56077555,
                "lng": -132.9756933761
            },
            {
                "lat": 53.5609433371,
                "lng": -132.9782871628
            },
            {
                "lat": 53.561417098,
                "lng": -132.9816134519
            },
            {
                "lat": 53.5604670832,
                "lng": -132.9837639396
            },
            {
                "lat": 53.5602307931,
                "lng": -132.9851831453
            },
            {
                "lat": 53.5606113989,
                "lng": -132.987609192
            },
            {
                "lat": 53.5612832308,
                "lng": -132.9900973067
            },
            {
                "lat": 53.5616684164,
                "lng": -132.9913790923
            },
            {
                "lat": 53.5621913862,
                "lng": -132.9926145921
            },
            {
                "lat": 53.5627742517,
                "lng": -132.9930884629
            },
            {
                "lat": 53.5478513339,
                "lng": -133.0090482226
            },
            {
                "lat": 53.5462913339,
                "lng": -133.0082698736
            },
            {
                "lat": 53.5462225739,
                "lng": -133.0074774495
            },
            {
                "lat": 53.5459860316,
                "lng": -133.0048680725
            },
            {
                "lat": 53.5437930739,
                "lng": -132.9957736339
            },
            {
                "lat": 53.5406193854,
                "lng": -132.9883419659
            },
            {
                "lat": 53.5351371953,
                "lng": -132.9806211113
            },
            {
                "lat": 53.5275958667,
                "lng": -132.9637455936
            },
            {
                "lat": 53.5259358854,
                "lng": -132.957107752
            },
            {
                "lat": 53.5247464439,
                "lng": -132.9562832893
            },
            {
                "lat": 53.52410474,
                "lng": -132.9559477696
            },
            {
                "lat": 53.5210500494,
                "lng": -132.9460744821
            },
            {
                "lat": 53.5213621462,
                "lng": -132.945587072
            },
            {
                "lat": 53.5213622224,
                "lng": -132.9450070389
            },
            {
                "lat": 53.5210605012,
                "lng": -132.9422294818
            },
            {
                "lat": 53.5233070521,
                "lng": -132.94105481
            },
            {
                "lat": 53.5302469159,
                "lng": -132.933318825
            },
            {
                "lat": 53.5350072076,
                "lng": -132.9245147914
            },
            {
                "lat": 53.5442854307,
                "lng": -132.9147483606
            },
            {
                "lat": 53.554279513,
                "lng": -132.9045259712
            },
            {
                "lat": 53.5628468402,
                "lng": -132.8952484332
            },
            {
                "lat": 53.5683169666,
                "lng": -132.904052299
            },
            {
                "lat": 53.569507724,
                "lng": -132.9252167865
            },
            {
                "lat": 53.5661773414,
                "lng": -132.9428260661
            },
            {
                "lat": 53.5616567237,
                "lng": -132.9597310945
            },
            {
                "lat": 53.5604669662,
                "lng": -132.9709171103
            },
            {
                "lat": 53.56077555,
                "lng": -132.9756933761
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 114,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 87,
            "MANAGEMENT_AREA_NAME": "Subarea 2-87",
            "LABEL": "2-87",
            "FISHERY_AREA_ID": 1977,
            "OBJECTID": 114,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 22310348.6955,
            "FEATURE_LENGTH_M": 20224.2961,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.54709069080812,
            "lng": -132.96371785011348
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.6765014525,
                "lng": -130.399032569
            },
            {
                "lat": 53.6765014864,
                "lng": -130.4047390377
            },
            {
                "lat": 53.6754374796,
                "lng": -130.406631525
            },
            {
                "lat": 53.6746181477,
                "lng": -130.4075169953
            },
            {
                "lat": 53.6739801104,
                "lng": -130.4082334711
            },
            {
                "lat": 53.6737903837,
                "lng": -130.4087530831
            },
            {
                "lat": 53.637679628,
                "lng": -130.4665062677
            },
            {
                "lat": 53.6343990631,
                "lng": -130.4672235724
            },
            {
                "lat": 53.6308285567,
                "lng": -130.4572442778
            },
            {
                "lat": 53.629657165,
                "lng": -130.4528967986
            },
            {
                "lat": 53.6291194038,
                "lng": -130.4508055999
            },
            {
                "lat": 53.6245234317,
                "lng": -130.446685491
            },
            {
                "lat": 53.6160356185,
                "lng": -130.4325264604
            },
            {
                "lat": 53.5972367945,
                "lng": -130.3780369635
            },
            {
                "lat": 53.5858153522,
                "lng": -130.3478245411
            },
            {
                "lat": 53.5682109085,
                "lng": -130.2945252702
            },
            {
                "lat": 53.5427476401,
                "lng": -130.2105254537
            },
            {
                "lat": 53.5306121919,
                "lng": -130.2029105528
            },
            {
                "lat": 53.5222852154,
                "lng": -130.1872094801
            },
            {
                "lat": 53.5149077457,
                "lng": -130.1562805753
            },
            {
                "lat": 53.503250231,
                "lng": -130.1127316294
            },
            {
                "lat": 53.5150372366,
                "lng": -130.109283451
            },
            {
                "lat": 53.5479464124,
                "lng": -130.0978238533
            },
            {
                "lat": 53.5497778587,
                "lng": -130.0960843587
            },
            {
                "lat": 53.5526275365,
                "lng": -130.0898282114
            },
            {
                "lat": 53.5611875905,
                "lng": -130.0751954165
            },
            {
                "lat": 53.564128447,
                "lng": -130.0751955055
            },
            {
                "lat": 53.5673330507,
                "lng": -130.0836180097
            },
            {
                "lat": 53.5674778219,
                "lng": -130.0853886101
            },
            {
                "lat": 53.5677034276,
                "lng": -130.0880586347
            },
            {
                "lat": 53.5693706043,
                "lng": -130.1006472277
            },
            {
                "lat": 53.5738138606,
                "lng": -130.1152807001
            },
            {
                "lat": 53.5791853973,
                "lng": -130.1315916962
            },
            {
                "lat": 53.5791856532,
                "lng": -130.1454775409
            },
            {
                "lat": 53.5841865318,
                "lng": -130.1547393288
            },
            {
                "lat": 53.5928420085,
                "lng": -130.1625363894
            },
            {
                "lat": 53.5954090423,
                "lng": -130.1703338569
            },
            {
                "lat": 53.5921520223,
                "lng": -130.1801143352
            },
            {
                "lat": 53.6030807802,
                "lng": -130.2025305132
            },
            {
                "lat": 53.6106755786,
                "lng": -130.226791259
            },
            {
                "lat": 53.6140097459,
                "lng": -130.2532804855
            },
            {
                "lat": 53.6217878253,
                "lng": -130.2699588181
            },
            {
                "lat": 53.6297527578,
                "lng": -130.3086695855
            },
            {
                "lat": 53.642749402,
                "lng": -130.31433065
            },
            {
                "lat": 53.670471328,
                "lng": -130.3986519785
            },
            {
                "lat": 53.6765014525,
                "lng": -130.399032569
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 115,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 5-13",
            "LABEL": "5-13",
            "FISHERY_AREA_ID": 2017,
            "OBJECTID": 115,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 166735785.036,
            "FEATURE_LENGTH_M": 68835.6921,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.59883763867174,
            "lng": -130.25724527391304
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.5210500494,
                "lng": -132.9460744821
            },
            {
                "lat": 53.52410474,
                "lng": -132.9559477696
            },
            {
                "lat": 53.5247464439,
                "lng": -132.9562832893
            },
            {
                "lat": 53.5259358854,
                "lng": -132.957107752
            },
            {
                "lat": 53.5275958667,
                "lng": -132.9637455936
            },
            {
                "lat": 53.5351371953,
                "lng": -132.9806211113
            },
            {
                "lat": 53.5406193854,
                "lng": -132.9883419659
            },
            {
                "lat": 53.5437930739,
                "lng": -132.9957736339
            },
            {
                "lat": 53.5459860316,
                "lng": -133.0048680725
            },
            {
                "lat": 53.5462225739,
                "lng": -133.0074774495
            },
            {
                "lat": 53.4683532698,
                "lng": -132.9327694922
            },
            {
                "lat": 53.4683534731,
                "lng": -132.8994300159
            },
            {
                "lat": 53.5210500494,
                "lng": -132.9460744821
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 116,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 86,
            "MANAGEMENT_AREA_NAME": "Subarea 2-86",
            "LABEL": "2-86",
            "FISHERY_AREA_ID": 1976,
            "OBJECTID": 116,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 17396234.2875,
            "FEATURE_LENGTH_M": 23902.6561,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.52253446444617,
            "lng": -132.9641934699923
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.5181734833,
                "lng": -132.745682504
            },
            {
                "lat": 53.5163725339,
                "lng": -132.7401282141
            },
            {
                "lat": 53.5172654158,
                "lng": -132.7345420448
            },
            {
                "lat": 53.5217440155,
                "lng": -132.7255851424
            },
            {
                "lat": 53.5329288223,
                "lng": -132.7239079984
            },
            {
                "lat": 53.543159564,
                "lng": -132.7272488591
            },
            {
                "lat": 53.545681227,
                "lng": -132.7337026836
            },
            {
                "lat": 53.5462456757,
                "lng": -132.7351534626
            },
            {
                "lat": 53.5286451416,
                "lng": -132.7519835288
            },
            {
                "lat": 53.5274539915,
                "lng": -132.7543646716
            },
            {
                "lat": 53.5250742987,
                "lng": -132.761505661
            },
            {
                "lat": 53.5224575074,
                "lng": -132.7629392777
            },
            {
                "lat": 53.5209698412,
                "lng": -132.7593228527
            },
            {
                "lat": 53.5209698877,
                "lng": -132.7485810266
            },
            {
                "lat": 53.519603972,
                "lng": -132.746155123
            },
            {
                "lat": 53.5181734833,
                "lng": -132.745682504
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 117,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 84,
            "MANAGEMENT_AREA_NAME": "Subarea 2-84",
            "LABEL": "2-84",
            "FISHERY_AREA_ID": 1974,
            "OBJECTID": 117,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4625641.9946,
            "FEATURE_LENGTH_M": 9316.6183,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.52655742880624,
            "lng": -132.74353034715
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.6330833751,
                "lng": -130.0720968173
            },
            {
                "lat": 53.6307528992,
                "lng": -130.0727086012
            },
            {
                "lat": 53.6229707619,
                "lng": -130.064742886
            },
            {
                "lat": 53.6227948615,
                "lng": -130.0644383588
            },
            {
                "lat": 53.6227946308,
                "lng": -130.0641480533
            },
            {
                "lat": 53.623015703,
                "lng": -130.0635690403
            },
            {
                "lat": 53.6232836544,
                "lng": -130.0628816947
            },
            {
                "lat": 53.6233521321,
                "lng": -130.0626824985
            },
            {
                "lat": 53.6237411314,
                "lng": -130.0605782233
            },
            {
                "lat": 53.6234966382,
                "lng": -130.0474698061
            },
            {
                "lat": 53.6230204804,
                "lng": -130.0455928664
            },
            {
                "lat": 53.6234930291,
                "lng": -130.0108486794
            },
            {
                "lat": 53.6273001979,
                "lng": -129.9880069963
            },
            {
                "lat": 53.6363451927,
                "lng": -129.9613485955
            },
            {
                "lat": 53.6377715735,
                "lng": -129.9487457998
            },
            {
                "lat": 53.6425330802,
                "lng": -129.9380344928
            },
            {
                "lat": 53.6389615309,
                "lng": -129.9266195895
            },
            {
                "lat": 53.6306342578,
                "lng": -129.9111487995
            },
            {
                "lat": 53.6234932648,
                "lng": -129.890213724
            },
            {
                "lat": 53.6168333421,
                "lng": -129.8592676729
            },
            {
                "lat": 53.6127890563,
                "lng": -129.8342894369
            },
            {
                "lat": 53.6208760648,
                "lng": -129.8300022748
            },
            {
                "lat": 53.6284908681,
                "lng": -129.8328710342
            },
            {
                "lat": 53.6361045729,
                "lng": -129.8430946607
            },
            {
                "lat": 53.6415782247,
                "lng": -129.8538061426
            },
            {
                "lat": 53.6401520239,
                "lng": -129.8716426532
            },
            {
                "lat": 53.6465761838,
                "lng": -129.9002078827
            },
            {
                "lat": 53.6538388935,
                "lng": -129.9132692536
            },
            {
                "lat": 53.6572837079,
                "lng": -129.9190067072
            },
            {
                "lat": 53.6637074621,
                "lng": -129.9344635791
            },
            {
                "lat": 53.6546669164,
                "lng": -129.9908598471
            },
            {
                "lat": 53.6460985028,
                "lng": -130.030364313
            },
            {
                "lat": 53.6387251353,
                "lng": -130.0589143741
            },
            {
                "lat": 53.6330833751,
                "lng": -130.0720968173
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 118,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 15,
            "MANAGEMENT_AREA_NAME": "Subarea 5-15",
            "LABEL": "5-15",
            "FISHERY_AREA_ID": 2018,
            "OBJECTID": 118,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 38961065.3582,
            "FEATURE_LENGTH_M": 37677.4671,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.63304831542943,
            "lng": -129.97058918153238
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.6344727311,
                "lng": -130.5651011805
            },
            {
                "lat": 53.6344722546,
                "lng": -130.6971594202
            },
            {
                "lat": 53.4992291271,
                "lng": -130.6342465629
            },
            {
                "lat": 53.4992405756,
                "lng": -130.6338655171
            },
            {
                "lat": 53.4993631083,
                "lng": -130.633514719
            },
            {
                "lat": 53.4994853441,
                "lng": -130.633056021
            },
            {
                "lat": 53.4994656494,
                "lng": -130.6322485203
            },
            {
                "lat": 53.4989169958,
                "lng": -130.6313163686
            },
            {
                "lat": 53.4981383258,
                "lng": -130.6283725808
            },
            {
                "lat": 53.4929161611,
                "lng": -130.6252444182
            },
            {
                "lat": 53.4761728164,
                "lng": -130.6013496592
            },
            {
                "lat": 53.4757307204,
                "lng": -130.5957032472
            },
            {
                "lat": 53.4738995467,
                "lng": -130.5914304514
            },
            {
                "lat": 53.4680103172,
                "lng": -130.587020808
            },
            {
                "lat": 53.4674071254,
                "lng": -130.5869283982
            },
            {
                "lat": 53.4664188963,
                "lng": -130.5874330872
            },
            {
                "lat": 53.3881458535,
                "lng": -130.4250944146
            },
            {
                "lat": 53.3881494045,
                "lng": -130.3114013683
            },
            {
                "lat": 53.4059068431,
                "lng": -130.3108676115
            },
            {
                "lat": 53.4225614847,
                "lng": -130.3267978879
            },
            {
                "lat": 53.4387431681,
                "lng": -130.3477481909
            },
            {
                "lat": 53.4642032012,
                "lng": -130.3898621662
            },
            {
                "lat": 53.4627188537,
                "lng": -130.3989565576
            },
            {
                "lat": 53.4598204883,
                "lng": -130.4039914701
            },
            {
                "lat": 53.4582590502,
                "lng": -130.4074392607
            },
            {
                "lat": 53.4698212187,
                "lng": -130.438247774
            },
            {
                "lat": 53.4857181031,
                "lng": -130.4585875409
            },
            {
                "lat": 53.5156435175,
                "lng": -130.4874266916
            },
            {
                "lat": 53.5161930114,
                "lng": -130.48733596
            },
            {
                "lat": 53.5165747509,
                "lng": -130.487258497
            },
            {
                "lat": 53.5168799612,
                "lng": -130.4867241737
            },
            {
                "lat": 53.5171242598,
                "lng": -130.4858397038
            },
            {
                "lat": 53.5276835657,
                "lng": -130.4745486608
            },
            {
                "lat": 53.5296820643,
                "lng": -130.4790494737
            },
            {
                "lat": 53.5386308157,
                "lng": -130.4781793259
            },
            {
                "lat": 53.5433879241,
                "lng": -130.4836577238
            },
            {
                "lat": 53.5536159875,
                "lng": -130.5055079399
            },
            {
                "lat": 53.5555229194,
                "lng": -130.5095977574
            },
            {
                "lat": 53.5628999445,
                "lng": -130.5191040441
            },
            {
                "lat": 53.5781248196,
                "lng": -130.5171806567
            },
            {
                "lat": 53.5836036354,
                "lng": -130.5164949389
            },
            {
                "lat": 53.6024021089,
                "lng": -130.5184023798
            },
            {
                "lat": 53.6076353075,
                "lng": -130.5264897301
            },
            {
                "lat": 53.6096453997,
                "lng": -130.5321965789
            },
            {
                "lat": 53.6115305933,
                "lng": -130.5350953836
            },
            {
                "lat": 53.6179587374,
                "lng": -130.5371258343
            },
            {
                "lat": 53.6180044541,
                "lng": -130.5372076497
            },
            {
                "lat": 53.6215898192,
                "lng": -130.5379554685
            },
            {
                "lat": 53.6241646701,
                "lng": -130.5384893122
            },
            {
                "lat": 53.6272661075,
                "lng": -130.5403047737
            },
            {
                "lat": 53.6275977203,
                "lng": -130.5408239089
            },
            {
                "lat": 53.6344727311,
                "lng": -130.5651011805
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 119,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 20,
            "MANAGEMENT_AREA_NAME": "Subarea 5-20",
            "LABEL": "5-20",
            "FISHERY_AREA_ID": 2023,
            "OBJECTID": 119,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 290427504.4213,
            "FEATURE_LENGTH_M": 87554.1231,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.52471581135576,
            "lng": -130.51750159519227
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.160152709,
                "lng": -132.949722282
            },
            {
                "lat": 54.1552852934,
                "lng": -132.9578250674
            },
            {
                "lat": 54.1589932369,
                "lng": -132.9985952024
            },
            {
                "lat": 54.1733590445,
                "lng": -133.0199128657
            },
            {
                "lat": 54.1735196145,
                "lng": -133.0202488647
            },
            {
                "lat": 54.1662096795,
                "lng": -133.0196380662
            },
            {
                "lat": 54.1519360801,
                "lng": -133.0356902636
            },
            {
                "lat": 54.1418230439,
                "lng": -133.0511630381
            },
            {
                "lat": 54.1275449242,
                "lng": -133.0642554123
            },
            {
                "lat": 54.113270104,
                "lng": -133.0725702676
            },
            {
                "lat": 54.09899131,
                "lng": -133.0565178882
            },
            {
                "lat": 54.0716282395,
                "lng": -133.045807035
            },
            {
                "lat": 54.0329631654,
                "lng": -133.0416414589
            },
            {
                "lat": 54.0085716335,
                "lng": -133.0535424137
            },
            {
                "lat": 53.9948920047,
                "lng": -133.0743560613
            },
            {
                "lat": 53.9913215734,
                "lng": -133.0987551237
            },
            {
                "lat": 53.9901923216,
                "lng": -133.1028902435
            },
            {
                "lat": 53.9310834403,
                "lng": -133.1075442528
            },
            {
                "lat": 53.9032819974,
                "lng": -133.1249240465
            },
            {
                "lat": 53.8824615626,
                "lng": -133.1273047119
            },
            {
                "lat": 53.867806002,
                "lng": -133.1125332447
            },
            {
                "lat": 53.8646733678,
                "lng": -133.1044613311
            },
            {
                "lat": 53.8582491734,
                "lng": -133.0968480977
            },
            {
                "lat": 53.8473048724,
                "lng": -133.0923312839
            },
            {
                "lat": 53.8354075405,
                "lng": -133.0868533818
            },
            {
                "lat": 53.8320766404,
                "lng": -133.0792392669
            },
            {
                "lat": 53.8280290645,
                "lng": -133.0747220687
            },
            {
                "lat": 53.8237496328,
                "lng": -133.0737614555
            },
            {
                "lat": 53.8166086858,
                "lng": -133.0754243825
            },
            {
                "lat": 53.8137556508,
                "lng": -133.0816192254
            },
            {
                "lat": 53.8111385217,
                "lng": -133.090423666
            },
            {
                "lat": 53.8070909029,
                "lng": -133.1039885848
            },
            {
                "lat": 53.8044735278,
                "lng": -133.1154023148
            },
            {
                "lat": 53.8020510807,
                "lng": -133.1177214748
            },
            {
                "lat": 53.7908134614,
                "lng": -133.1186073106
            },
            {
                "lat": 53.7892450548,
                "lng": -133.1161195394
            },
            {
                "lat": 53.7852022371,
                "lng": -133.114455464
            },
            {
                "lat": 53.7820169525,
                "lng": -133.1143029251
            },
            {
                "lat": 53.7798923366,
                "lng": -133.1140902302
            },
            {
                "lat": 53.7800869944,
                "lng": -133.1123196283
            },
            {
                "lat": 53.7791322624,
                "lng": -133.1073150586
            },
            {
                "lat": 53.7774698528,
                "lng": -133.1001739503
            },
            {
                "lat": 53.7765163716,
                "lng": -133.0901794924
            },
            {
                "lat": 53.7774696168,
                "lng": -133.0799562769
            },
            {
                "lat": 53.7760390579,
                "lng": -133.0673364766
            },
            {
                "lat": 53.7731859849,
                "lng": -133.0594932371
            },
            {
                "lat": 53.7705655133,
                "lng": -133.0571140684
            },
            {
                "lat": 53.7672112215,
                "lng": -133.0577691877
            },
            {
                "lat": 53.7631764719,
                "lng": -133.0541840424
            },
            {
                "lat": 53.7613300228,
                "lng": -133.0500338243
            },
            {
                "lat": 53.7580224615,
                "lng": -133.0437009518
            },
            {
                "lat": 53.7542646371,
                "lng": -133.0397180675
            },
            {
                "lat": 53.7505115367,
                "lng": -133.0379792865
            },
            {
                "lat": 53.752136979,
                "lng": -133.0369263004
            },
            {
                "lat": 53.7568433377,
                "lng": -133.0360258518
            },
            {
                "lat": 53.7614407507,
                "lng": -133.0324864557
            },
            {
                "lat": 53.7667082872,
                "lng": -133.0282286735
            },
            {
                "lat": 53.769287183,
                "lng": -133.0254363545
            },
            {
                "lat": 53.7702370432,
                "lng": -133.0188747393
            },
            {
                "lat": 53.7709809792,
                "lng": -133.0151979735
            },
            {
                "lat": 53.772903176,
                "lng": -133.012878412
            },
            {
                "lat": 53.7734338881,
                "lng": -133.0092160039
            },
            {
                "lat": 53.7729717597,
                "lng": -133.0041961009
            },
            {
                "lat": 53.7732242985,
                "lng": -133.0023799043
            },
            {
                "lat": 53.7750053178,
                "lng": -132.9930410198
            },
            {
                "lat": 53.7754058032,
                "lng": -132.9885712158
            },
            {
                "lat": 53.7732961752,
                "lng": -132.9871974761
            },
            {
                "lat": 53.7689317788,
                "lng": -132.9864650255
            },
            {
                "lat": 53.7660250491,
                "lng": -132.9872744777
            },
            {
                "lat": 53.762321924,
                "lng": -132.9916224975
            },
            {
                "lat": 53.7606430681,
                "lng": -132.9974513639
            },
            {
                "lat": 53.7605394826,
                "lng": -132.9978177898
            },
            {
                "lat": 53.7597538246,
                "lng": -133.0002436348
            },
            {
                "lat": 53.7578735116,
                "lng": -133.0011292484
            },
            {
                "lat": 53.752834096,
                "lng": -133.0061333231
            },
            {
                "lat": 53.7538109516,
                "lng": -133.0031740254
            },
            {
                "lat": 53.7540470497,
                "lng": -132.9979246408
            },
            {
                "lat": 53.7492902361,
                "lng": -132.9912718012
            },
            {
                "lat": 53.7414358881,
                "lng": -132.9869847789
            },
            {
                "lat": 53.7297778406,
                "lng": -132.9879464068
            },
            {
                "lat": 53.7183567425,
                "lng": -132.9857946912
            },
            {
                "lat": 53.7138360975,
                "lng": -132.985443464
            },
            {
                "lat": 53.7152634116,
                "lng": -132.9825897419
            },
            {
                "lat": 53.715740679,
                "lng": -132.9775849912
            },
            {
                "lat": 53.7131188107,
                "lng": -132.9721212745
            },
            {
                "lat": 53.7096740227,
                "lng": -132.968307767
            },
            {
                "lat": 53.7126078452,
                "lng": -132.9678335822
            },
            {
                "lat": 53.7137637915,
                "lng": -132.9664619266
            },
            {
                "lat": 53.7132189862,
                "lng": -132.9561763238
            },
            {
                "lat": 53.7113796294,
                "lng": -132.9408878065
            },
            {
                "lat": 53.7066041563,
                "lng": -132.9279322866
            },
            {
                "lat": 53.701149292,
                "lng": -132.9223484935
            },
            {
                "lat": 53.6965103108,
                "lng": -132.9226834222
            },
            {
                "lat": 53.6905788818,
                "lng": -132.9260261756
            },
            {
                "lat": 53.6853945031,
                "lng": -132.932097877
            },
            {
                "lat": 53.6851200079,
                "lng": -132.9355775665
            },
            {
                "lat": 53.6879851117,
                "lng": -132.941101297
            },
            {
                "lat": 53.6889421521,
                "lng": -132.9434205854
            },
            {
                "lat": 53.691230692,
                "lng": -132.9448095553
            },
            {
                "lat": 53.6884189425,
                "lng": -132.9445801015
            },
            {
                "lat": 53.684986096,
                "lng": -132.9444730494
            },
            {
                "lat": 53.6823385764,
                "lng": -132.9430540438
            },
            {
                "lat": 53.681270335,
                "lng": -132.9457092762
            },
            {
                "lat": 53.6807053352,
                "lng": -132.9520572062
            },
            {
                "lat": 53.6808205786,
                "lng": -132.9589236245
            },
            {
                "lat": 53.6805375982,
                "lng": -132.9669186469
            },
            {
                "lat": 53.6815527921,
                "lng": -132.9711463411
            },
            {
                "lat": 53.6845352866,
                "lng": -132.9736782771
            },
            {
                "lat": 53.6874049572,
                "lng": -132.9752499834
            },
            {
                "lat": 53.688586819,
                "lng": -132.9751437807
            },
            {
                "lat": 53.6891479557,
                "lng": -132.9725487599
            },
            {
                "lat": 53.6898802599,
                "lng": -132.9684457253
            },
            {
                "lat": 53.6912876987,
                "lng": -132.9624793862
            },
            {
                "lat": 53.6921919502,
                "lng": -132.9605104801
            },
            {
                "lat": 53.6937134949,
                "lng": -132.9638667137
            },
            {
                "lat": 53.6970213242,
                "lng": -132.9676363941
            },
            {
                "lat": 53.695781296,
                "lng": -132.9714044238
            },
            {
                "lat": 53.6957663738,
                "lng": -132.9714963154
            },
            {
                "lat": 53.6951477167,
                "lng": -132.9746245835
            },
            {
                "lat": 53.6932796532,
                "lng": -132.9764405191
            },
            {
                "lat": 53.6921992284,
                "lng": -132.9772481346
            },
            {
                "lat": 53.6918102099,
                "lng": -132.9775395007
            },
            {
                "lat": 53.6902084192,
                "lng": -132.9769433126
            },
            {
                "lat": 53.6877974489,
                "lng": -132.9759209508
            },
            {
                "lat": 53.6841049985,
                "lng": -132.975814568
            },
            {
                "lat": 53.6824109573,
                "lng": -132.9762876847
            },
            {
                "lat": 53.6817474013,
                "lng": -132.9785915833
            },
            {
                "lat": 53.6822583175,
                "lng": -132.9836120554
            },
            {
                "lat": 53.6826668691,
                "lng": -132.9860679534
            },
            {
                "lat": 53.6811173502,
                "lng": -132.9872443976
            },
            {
                "lat": 53.6805157834,
                "lng": -132.9866946461
            },
            {
                "lat": 53.6786157704,
                "lng": -132.9855203179
            },
            {
                "lat": 53.6754378331,
                "lng": -132.9855650949
            },
            {
                "lat": 53.673076564,
                "lng": -132.9879764187
            },
            {
                "lat": 53.6733317937,
                "lng": -132.9902803559
            },
            {
                "lat": 53.6741557597,
                "lng": -132.9923862504
            },
            {
                "lat": 53.6742245886,
                "lng": -132.9925083331
            },
            {
                "lat": 53.6758465401,
                "lng": -132.9951627867
            },
            {
                "lat": 53.6776926041,
                "lng": -133.0012057422
            },
            {
                "lat": 53.6792911787,
                "lng": -133.0083160351
            },
            {
                "lat": 53.671672528,
                "lng": -133.0086671475
            },
            {
                "lat": 53.6715235991,
                "lng": -133.0041342192
            },
            {
                "lat": 53.6696206884,
                "lng": -132.9967495024
            },
            {
                "lat": 53.670574088,
                "lng": -132.9900970126
            },
            {
                "lat": 53.6748581914,
                "lng": -132.9710537335
            },
            {
                "lat": 53.6753303269,
                "lng": -132.9532168631
            },
            {
                "lat": 53.6693840625,
                "lng": -132.9467934968
            },
            {
                "lat": 53.6501813491,
                "lng": -132.9453573475
            },
            {
                "lat": 53.6498449212,
                "lng": -132.9371947128
            },
            {
                "lat": 53.6479112763,
                "lng": -132.9301295425
            },
            {
                "lat": 53.6423606691,
                "lng": -132.9269404451
            },
            {
                "lat": 53.6351243079,
                "lng": -132.9238427174
            },
            {
                "lat": 53.6262090968,
                "lng": -132.9202420712
            },
            {
                "lat": 53.6199229451,
                "lng": -132.9188076728
            },
            {
                "lat": 53.6150355033,
                "lng": -132.9176017122
            },
            {
                "lat": 53.6117063704,
                "lng": -132.9080815801
            },
            {
                "lat": 53.6086116382,
                "lng": -132.8999944635
            },
            {
                "lat": 53.6073030939,
                "lng": -132.8897550607
            },
            {
                "lat": 53.6063498536,
                "lng": -132.8842767337
            },
            {
                "lat": 53.6044500126,
                "lng": -132.8712000485
            },
            {
                "lat": 53.6021998266,
                "lng": -132.8586730793
            },
            {
                "lat": 53.6011155777,
                "lng": -132.8526308664
            },
            {
                "lat": 53.6015932807,
                "lng": -132.8385920503
            },
            {
                "lat": 53.6023440021,
                "lng": -132.8378448038
            },
            {
                "lat": 53.6058772375,
                "lng": -132.8343193554
            },
            {
                "lat": 53.6103977463,
                "lng": -132.8259885802
            },
            {
                "lat": 53.6106337727,
                "lng": -132.8169402493
            },
            {
                "lat": 53.6025125705,
                "lng": -132.8132168676
            },
            {
                "lat": 53.6018062619,
                "lng": -132.8132785082
            },
            {
                "lat": 53.5980227356,
                "lng": -132.8136140334
            },
            {
                "lat": 53.5939782851,
                "lng": -132.8181305024
            },
            {
                "lat": 53.5844613836,
                "lng": -132.8278962243
            },
            {
                "lat": 53.5792275537,
                "lng": -132.8409725602
            },
            {
                "lat": 53.5801774241,
                "lng": -132.8552543164
            },
            {
                "lat": 53.5854116191,
                "lng": -132.8700105612
            },
            {
                "lat": 53.5901714972,
                "lng": -132.8895114295
            },
            {
                "lat": 53.5851752359,
                "lng": -132.8911896607
            },
            {
                "lat": 53.5787502718,
                "lng": -132.8971261344
            },
            {
                "lat": 53.5785101616,
                "lng": -132.9059288598
            },
            {
                "lat": 53.5816032329,
                "lng": -132.9168852198
            },
            {
                "lat": 53.58565148,
                "lng": -132.9294888367
            },
            {
                "lat": 53.5918353009,
                "lng": -132.9411473037
            },
            {
                "lat": 53.5989761749,
                "lng": -132.9523305782
            },
            {
                "lat": 53.6036605493,
                "lng": -132.9651646929
            },
            {
                "lat": 53.602169576,
                "lng": -132.9671322731
            },
            {
                "lat": 53.5996743717,
                "lng": -132.9701229476
            },
            {
                "lat": 53.5862964174,
                "lng": -132.9784544099
            },
            {
                "lat": 53.5774231647,
                "lng": -132.9749909573
            },
            {
                "lat": 53.5735392596,
                "lng": -132.9692845745
            },
            {
                "lat": 53.5688550313,
                "lng": -132.9672239592
            },
            {
                "lat": 53.5637171477,
                "lng": -132.968368767
            },
            {
                "lat": 53.5627439264,
                "lng": -132.9719687971
            },
            {
                "lat": 53.5618894794,
                "lng": -132.975403201
            },
            {
                "lat": 53.56077555,
                "lng": -132.9756933761
            },
            {
                "lat": 53.5604669662,
                "lng": -132.9709171103
            },
            {
                "lat": 53.5616567237,
                "lng": -132.9597310945
            },
            {
                "lat": 53.5661773414,
                "lng": -132.9428260661
            },
            {
                "lat": 53.569507724,
                "lng": -132.9252167865
            },
            {
                "lat": 53.5683169666,
                "lng": -132.904052299
            },
            {
                "lat": 53.5628468402,
                "lng": -132.8952484332
            },
            {
                "lat": 53.554279513,
                "lng": -132.9045259712
            },
            {
                "lat": 53.5442854307,
                "lng": -132.9147483606
            },
            {
                "lat": 53.5350072076,
                "lng": -132.9245147914
            },
            {
                "lat": 53.5302469159,
                "lng": -132.933318825
            },
            {
                "lat": 53.5233070521,
                "lng": -132.94105481
            },
            {
                "lat": 53.5210605012,
                "lng": -132.9422294818
            },
            {
                "lat": 53.5211256243,
                "lng": -132.9168847826
            },
            {
                "lat": 53.5226323645,
                "lng": -132.8959496422
            },
            {
                "lat": 53.523823097,
                "lng": -132.8716893991
            },
            {
                "lat": 53.5227473129,
                "lng": -132.8602609077
            },
            {
                "lat": 53.5239407341,
                "lng": -132.8516841997
            },
            {
                "lat": 53.5228229126,
                "lng": -132.84190416
            },
            {
                "lat": 53.5213284625,
                "lng": -132.8392183866
            },
            {
                "lat": 53.5202106503,
                "lng": -132.8372046906
            },
            {
                "lat": 53.5173758694,
                "lng": -132.83459456
            },
            {
                "lat": 53.5143466703,
                "lng": -132.8354189652
            },
            {
                "lat": 53.5124469231,
                "lng": -132.8359380797
            },
            {
                "lat": 53.5067028829,
                "lng": -132.8393713442
            },
            {
                "lat": 53.5008089859,
                "lng": -132.8442232066
            },
            {
                "lat": 53.4973758094,
                "lng": -132.8497467186
            },
            {
                "lat": 53.4950638254,
                "lng": -132.8622594763
            },
            {
                "lat": 53.4935153013,
                "lng": -132.8698421825
            },
            {
                "lat": 53.4918521436,
                "lng": -132.8772281827
            },
            {
                "lat": 53.4920883794,
                "lng": -132.881745335
            },
            {
                "lat": 53.4847109444,
                "lng": -132.8829343041
            },
            {
                "lat": 53.4787632008,
                "lng": -132.8898315338
            },
            {
                "lat": 53.47385438,
                "lng": -132.8917690919
            },
            {
                "lat": 53.4725538698,
                "lng": -132.8922728666
            },
            {
                "lat": 53.4698984195,
                "lng": -132.8939663614
            },
            {
                "lat": 53.4694441463,
                "lng": -132.8900754545
            },
            {
                "lat": 53.4697153421,
                "lng": -132.8866575685
            },
            {
                "lat": 53.469627268,
                "lng": -132.8814237969
            },
            {
                "lat": 53.4705282712,
                "lng": -132.8737479851
            },
            {
                "lat": 53.4728737882,
                "lng": -132.8631893058
            },
            {
                "lat": 53.474135534,
                "lng": -132.8550720569
            },
            {
                "lat": 53.4745864267,
                "lng": -132.8437962776
            },
            {
                "lat": 53.4741743556,
                "lng": -132.83477845
            },
            {
                "lat": 53.473560563,
                "lng": -132.8314660493
            },
            {
                "lat": 53.47594033,
                "lng": -132.8314664083
            },
            {
                "lat": 53.4795074751,
                "lng": -132.8283699961
            },
            {
                "lat": 53.4818877298,
                "lng": -132.8231356308
            },
            {
                "lat": 53.4852186876,
                "lng": -132.8112329605
            },
            {
                "lat": 53.4885514274,
                "lng": -132.7948153823
            },
            {
                "lat": 53.4887887638,
                "lng": -132.7783961852
            },
            {
                "lat": 53.488006735,
                "lng": -132.7723090438
            },
            {
                "lat": 53.4903139872,
                "lng": -132.7652437769
            },
            {
                "lat": 53.4937856953,
                "lng": -132.7617499474
            },
            {
                "lat": 53.4971161766,
                "lng": -132.766509949
            },
            {
                "lat": 53.5030671524,
                "lng": -132.7684168494
            },
            {
                "lat": 53.5121081814,
                "lng": -132.7684168064
            },
            {
                "lat": 53.5175822051,
                "lng": -132.7665101992
            },
            {
                "lat": 53.5224575074,
                "lng": -132.7629392777
            },
            {
                "lat": 53.5250742987,
                "lng": -132.761505661
            },
            {
                "lat": 53.5274539915,
                "lng": -132.7543646716
            },
            {
                "lat": 53.5286451416,
                "lng": -132.7519835288
            },
            {
                "lat": 53.5462456757,
                "lng": -132.7351534626
            },
            {
                "lat": 53.545681227,
                "lng": -132.7337026836
            },
            {
                "lat": 53.543159564,
                "lng": -132.7272488591
            },
            {
                "lat": 53.5329288223,
                "lng": -132.7239079984
            },
            {
                "lat": 53.5217440155,
                "lng": -132.7255851424
            },
            {
                "lat": 53.5172654158,
                "lng": -132.7345420448
            },
            {
                "lat": 53.5163725339,
                "lng": -132.7401282141
            },
            {
                "lat": 53.5181734833,
                "lng": -132.745682504
            },
            {
                "lat": 53.5153199525,
                "lng": -132.7425996112
            },
            {
                "lat": 53.5117498569,
                "lng": -132.7395013804
            },
            {
                "lat": 53.5062760133,
                "lng": -132.7393192346
            },
            {
                "lat": 53.5035750003,
                "lng": -132.739791308
            },
            {
                "lat": 53.5005676894,
                "lng": -132.7420049895
            },
            {
                "lat": 53.5029451691,
                "lng": -132.736526093
            },
            {
                "lat": 53.5038986995,
                "lng": -132.7317663343
            },
            {
                "lat": 53.5022315237,
                "lng": -132.728912239
            },
            {
                "lat": 53.4977108239,
                "lng": -132.7255857008
            },
            {
                "lat": 53.4927134455,
                "lng": -132.7255853403
            },
            {
                "lat": 53.4815330281,
                "lng": -132.7301028302
            },
            {
                "lat": 53.4734418166,
                "lng": -132.7386620117
            },
            {
                "lat": 53.4693940976,
                "lng": -132.7505641832
            },
            {
                "lat": 53.4672544402,
                "lng": -132.7596129224
            },
            {
                "lat": 53.4653513739,
                "lng": -132.7684166131
            },
            {
                "lat": 53.4643974836,
                "lng": -132.7720946091
            },
            {
                "lat": 53.4629705148,
                "lng": -132.7656710301
            },
            {
                "lat": 53.4613080628,
                "lng": -132.7561487226
            },
            {
                "lat": 53.458927494,
                "lng": -132.7440183324
            },
            {
                "lat": 53.4546433189,
                "lng": -132.728560989
            },
            {
                "lat": 53.4513123971,
                "lng": -132.7164148907
            },
            {
                "lat": 53.4496723504,
                "lng": -132.7106465563
            },
            {
                "lat": 53.4482425759,
                "lng": -132.7070004139
            },
            {
                "lat": 53.4467924045,
                "lng": -132.7052298731
            },
            {
                "lat": 53.4513124646,
                "lng": -132.6985780011
            },
            {
                "lat": 53.4579738266,
                "lng": -132.6883398833
            },
            {
                "lat": 53.4653511675,
                "lng": -132.6797793862
            },
            {
                "lat": 53.4712987334,
                "lng": -132.6793058804
            },
            {
                "lat": 53.4810569147,
                "lng": -132.680724563
            },
            {
                "lat": 53.4915235188,
                "lng": -132.6793057336
            },
            {
                "lat": 53.5008053361,
                "lng": -132.6783450783
            },
            {
                "lat": 53.5040861772,
                "lng": -132.6741178439
            },
            {
                "lat": 53.5029216844,
                "lng": -132.6676023276
            },
            {
                "lat": 53.4969973827,
                "lng": -132.6626431867
            },
            {
                "lat": 53.4841500023,
                "lng": -132.6535954803
            },
            {
                "lat": 53.47463155,
                "lng": -132.6481318242
            },
            {
                "lat": 53.4689213794,
                "lng": -132.6514595016
            },
            {
                "lat": 53.4605911283,
                "lng": -132.6564634783
            },
            {
                "lat": 53.4565468046,
                "lng": -132.6557462787
            },
            {
                "lat": 53.448933061,
                "lng": -132.6566916084
            },
            {
                "lat": 53.4434579333,
                "lng": -132.6602637615
            },
            {
                "lat": 53.4408419589,
                "lng": -132.6652678861
            },
            {
                "lat": 53.4366800898,
                "lng": -132.6683501144
            },
            {
                "lat": 53.435725458,
                "lng": -132.6643068291
            },
            {
                "lat": 53.4335862874,
                "lng": -132.6581276355
            },
            {
                "lat": 53.4328722134,
                "lng": -132.6490785795
            },
            {
                "lat": 53.4350133349,
                "lng": -132.6357574578
            },
            {
                "lat": 53.4364391654,
                "lng": -132.6226650519
            },
            {
                "lat": 53.4342997564,
                "lng": -132.6043396952
            },
            {
                "lat": 53.4314428618,
                "lng": -132.5705564618
            },
            {
                "lat": 53.4276348665,
                "lng": -132.5458066923
            },
            {
                "lat": 53.422638089,
                "lng": -132.525345286
            },
            {
                "lat": 53.4142680271,
                "lng": -132.5170137493
            },
            {
                "lat": 53.4022479509,
                "lng": -132.5135653972
            },
            {
                "lat": 53.3919975518,
                "lng": -132.5120847048
            },
            {
                "lat": 53.381237677,
                "lng": -132.5111848548
            },
            {
                "lat": 53.3786204912,
                "lng": -132.5014349036
            },
            {
                "lat": 53.3764796674,
                "lng": -132.4916833428
            },
            {
                "lat": 53.3714831141,
                "lng": -132.4795380839
            },
            {
                "lat": 53.3638684067,
                "lng": -132.4612271811
            },
            {
                "lat": 53.3595840095,
                "lng": -132.4502716175
            },
            {
                "lat": 53.3542023197,
                "lng": -132.4425502224
            },
            {
                "lat": 53.3540613038,
                "lng": -132.4407039089
            },
            {
                "lat": 53.3529277981,
                "lng": -132.4339290197
            },
            {
                "lat": 53.3484070557,
                "lng": -132.4261632252
            },
            {
                "lat": 53.3424721613,
                "lng": -132.4188076539
            },
            {
                "lat": 53.334983231,
                "lng": -132.4123081026
            },
            {
                "lat": 53.3228877776,
                "lng": -132.4077917393
            },
            {
                "lat": 53.3181300613,
                "lng": -132.4115749841
            },
            {
                "lat": 53.31774455,
                "lng": -132.4118806374
            },
            {
                "lat": 53.3119542907,
                "lng": -132.4132994437
            },
            {
                "lat": 53.2988130527,
                "lng": -132.4144286199
            },
            {
                "lat": 53.2883566946,
                "lng": -132.4205014958
            },
            {
                "lat": 53.2862357637,
                "lng": -132.4279940865
            },
            {
                "lat": 53.2880748497,
                "lng": -132.4376066675
            },
            {
                "lat": 53.2990947451,
                "lng": -132.4456485399
            },
            {
                "lat": 53.3106804372,
                "lng": -132.4458010738
            },
            {
                "lat": 53.3179969854,
                "lng": -132.4493101384
            },
            {
                "lat": 53.3126407828,
                "lng": -132.451690099
            },
            {
                "lat": 53.3060982654,
                "lng": -132.4629828079
            },
            {
                "lat": 53.3132356986,
                "lng": -132.4891653558
            },
            {
                "lat": 53.3310812696,
                "lng": -132.5123587369
            },
            {
                "lat": 53.3405990574,
                "lng": -132.5266421257
            },
            {
                "lat": 53.3481860268,
                "lng": -132.5291140003
            },
            {
                "lat": 53.3511277415,
                "lng": -132.5197139928
            },
            {
                "lat": 53.3535573669,
                "lng": -132.5165707397
            },
            {
                "lat": 53.3546636658,
                "lng": -132.5211179035
            },
            {
                "lat": 53.3645168205,
                "lng": -132.6269990548
            },
            {
                "lat": 53.3643156191,
                "lng": -132.6310117555
            },
            {
                "lat": 53.3637010017,
                "lng": -132.6376648738
            },
            {
                "lat": 53.3627319278,
                "lng": -132.643035614
            },
            {
                "lat": 53.3622896998,
                "lng": -132.6510468076
            },
            {
                "lat": 53.3623770243,
                "lng": -132.6530308286
            },
            {
                "lat": 53.3651548418,
                "lng": -132.6634218062
            },
            {
                "lat": 53.367091921,
                "lng": -132.6751405808
            },
            {
                "lat": 53.3652420131,
                "lng": -132.6762392916
            },
            {
                "lat": 53.3636550886,
                "lng": -132.6816096609
            },
            {
                "lat": 53.3623350467,
                "lng": -132.6883088955
            },
            {
                "lat": 53.3631708327,
                "lng": -132.6941686269
            },
            {
                "lat": 53.3670466149,
                "lng": -132.6979520832
            },
            {
                "lat": 53.3675759833,
                "lng": -132.7001952284
            },
            {
                "lat": 53.371200275,
                "lng": -132.7059168929
            },
            {
                "lat": 53.3719975048,
                "lng": -132.7080376862
            },
            {
                "lat": 53.3727263337,
                "lng": -132.712112796
            },
            {
                "lat": 53.3735575224,
                "lng": -132.7217397697
            },
            {
                "lat": 53.3685610809,
                "lng": -132.7148437127
            },
            {
                "lat": 53.3640404823,
                "lng": -132.7055670439
            },
            {
                "lat": 53.3590429462,
                "lng": -132.7057950387
            },
            {
                "lat": 53.3566625462,
                "lng": -132.7098392235
            },
            {
                "lat": 53.3554760091,
                "lng": -132.7157904634
            },
            {
                "lat": 53.3500023883,
                "lng": -132.723175641
            },
            {
                "lat": 53.3435781675,
                "lng": -132.7315058435
            },
            {
                "lat": 53.3395303497,
                "lng": -132.7353064552
            },
            {
                "lat": 53.3380007292,
                "lng": -132.7380823347
            },
            {
                "lat": 53.3364337966,
                "lng": -132.7381287653
            },
            {
                "lat": 53.33431636,
                "lng": -132.7402960646
            },
            {
                "lat": 53.3318752124,
                "lng": -132.7452239578
            },
            {
                "lat": 53.3302152743,
                "lng": -132.7490381326
            },
            {
                "lat": 53.3281407858,
                "lng": -132.7512965223
            },
            {
                "lat": 53.3255888109,
                "lng": -132.7586218615
            },
            {
                "lat": 53.319873713,
                "lng": -132.7633970752
            },
            {
                "lat": 53.3219030607,
                "lng": -132.756806071
            },
            {
                "lat": 53.3248748399,
                "lng": -132.7449037406
            },
            {
                "lat": 53.3284456061,
                "lng": -132.7294472996
            },
            {
                "lat": 53.3308260168,
                "lng": -132.7217099262
            },
            {
                "lat": 53.3308263725,
                "lng": -132.7157588844
            },
            {
                "lat": 53.3326075719,
                "lng": -132.7008823588
            },
            {
                "lat": 53.3320157376,
                "lng": -132.6884002719
            },
            {
                "lat": 53.3302302593,
                "lng": -132.6764991065
            },
            {
                "lat": 53.3296351872,
                "lng": -132.653305459
            },
            {
                "lat": 53.3289066189,
                "lng": -132.638747673
            },
            {
                "lat": 53.3300976526,
                "lng": -132.6304169489
            },
            {
                "lat": 53.3364598344,
                "lng": -132.6147159979
            },
            {
                "lat": 53.3398440513,
                "lng": -132.5833127777
            },
            {
                "lat": 53.3372723337,
                "lng": -132.5565191004
            },
            {
                "lat": 53.3342932092,
                "lng": -132.5498812578
            },
            {
                "lat": 53.3256343277,
                "lng": -132.5494844086
            },
            {
                "lat": 53.3102029872,
                "lng": -132.5481267244
            },
            {
                "lat": 53.2988350482,
                "lng": -132.5501555034
            },
            {
                "lat": 53.2945056348,
                "lng": -132.5555725036
            },
            {
                "lat": 53.2955893857,
                "lng": -132.5628810439
            },
            {
                "lat": 53.3018154948,
                "lng": -132.5731660379
            },
            {
                "lat": 53.3104745868,
                "lng": -132.5804748359
            },
            {
                "lat": 53.3149413286,
                "lng": -132.5934602683
            },
            {
                "lat": 53.3125036236,
                "lng": -132.6059117775
            },
            {
                "lat": 53.3127750058,
                "lng": -132.6217495015
            },
            {
                "lat": 53.3131825928,
                "lng": -132.6294706465
            },
            {
                "lat": 53.3142011912,
                "lng": -132.6353450835
            },
            {
                "lat": 53.313289471,
                "lng": -132.6372078215
            },
            {
                "lat": 53.3105849859,
                "lng": -132.6348265208
            },
            {
                "lat": 53.3048588725,
                "lng": -132.6316367945
            },
            {
                "lat": 53.2963449997,
                "lng": -132.628143725
            },
            {
                "lat": 53.2891043984,
                "lng": -132.6245581072
            },
            {
                "lat": 53.2860833697,
                "lng": -132.626785173
            },
            {
                "lat": 53.2854648561,
                "lng": -132.6295319056
            },
            {
                "lat": 53.2844929122,
                "lng": -132.6338657101
            },
            {
                "lat": 53.2826616918,
                "lng": -132.6434941865
            },
            {
                "lat": 53.2812995261,
                "lng": -132.6571044007
            },
            {
                "lat": 53.2822041657,
                "lng": -132.6719366901
            },
            {
                "lat": 53.2807851445,
                "lng": -132.687560417
            },
            {
                "lat": 53.2832261419,
                "lng": -132.7029876107
            },
            {
                "lat": 53.2789494454,
                "lng": -132.7019196408
            },
            {
                "lat": 53.2717361246,
                "lng": -132.702728855
            },
            {
                "lat": 53.2659493442,
                "lng": -132.7063750338
            },
            {
                "lat": 53.2586893273,
                "lng": -132.7133024558
            },
            {
                "lat": 53.2560423557,
                "lng": -132.7148892638
            },
            {
                "lat": 53.2570570978,
                "lng": -132.7130120656
            },
            {
                "lat": 53.2601129661,
                "lng": -132.7079156485
            },
            {
                "lat": 53.2631679229,
                "lng": -132.6994331334
            },
            {
                "lat": 53.2648698174,
                "lng": -132.6912834249
            },
            {
                "lat": 53.2652053756,
                "lng": -132.6831360546
            },
            {
                "lat": 53.2638477755,
                "lng": -132.6658182289
            },
            {
                "lat": 53.2604517875,
                "lng": -132.6529088249
            },
            {
                "lat": 53.2580756777,
                "lng": -132.6383056888
            },
            {
                "lat": 53.2594332814,
                "lng": -132.6247255406
            },
            {
                "lat": 53.2635077787,
                "lng": -132.6040037328
            },
            {
                "lat": 53.2713205948,
                "lng": -132.5703885971
            },
            {
                "lat": 53.2794686406,
                "lng": -132.5462806067
            },
            {
                "lat": 53.2882995549,
                "lng": -132.5150450121
            },
            {
                "lat": 53.2886395839,
                "lng": -132.4987335186
            },
            {
                "lat": 53.2855836617,
                "lng": -132.4888920287
            },
            {
                "lat": 53.2713205426,
                "lng": -132.4919434508
            },
            {
                "lat": 53.2594333639,
                "lng": -132.5079035846
            },
            {
                "lat": 53.2475472154,
                "lng": -132.5296479252
            },
            {
                "lat": 53.2370222761,
                "lng": -132.5428774037
            },
            {
                "lat": 53.22140168,
                "lng": -132.5418706856
            },
            {
                "lat": 53.2088810146,
                "lng": -132.5355223449
            },
            {
                "lat": 53.1984096186,
                "lng": -132.5298151961
            },
            {
                "lat": 53.1860392651,
                "lng": -132.5326692315
            },
            {
                "lat": 53.1822311318,
                "lng": -132.5388498439
            },
            {
                "lat": 53.181041452,
                "lng": -132.5481264657
            },
            {
                "lat": 53.1791381171,
                "lng": -132.5562291762
            },
            {
                "lat": 53.1779483067,
                "lng": -132.5608667871
            },
            {
                "lat": 53.1767576124,
                "lng": -132.5556334552
            },
            {
                "lat": 53.1755675139,
                "lng": -132.549209126
            },
            {
                "lat": 53.1698576117,
                "lng": -132.5304102317
            },
            {
                "lat": 53.1639101455,
                "lng": -132.5168458074
            },
            {
                "lat": 53.1577224487,
                "lng": -132.5129088308
            },
            {
                "lat": 53.1655737275,
                "lng": -132.5086361699
            },
            {
                "lat": 53.1779487044,
                "lng": -132.5036315903
            },
            {
                "lat": 53.206024121,
                "lng": -132.5031586835
            },
            {
                "lat": 53.2279160227,
                "lng": -132.4905549553
            },
            {
                "lat": 53.2283936392,
                "lng": -132.4755554362
            },
            {
                "lat": 53.2217287338,
                "lng": -132.4669954639
            },
            {
                "lat": 53.2024572509,
                "lng": -132.4719846428
            },
            {
                "lat": 53.1886865497,
                "lng": -132.4719081603
            },
            {
                "lat": 53.1848483785,
                "lng": -132.4607997142
            },
            {
                "lat": 53.1810418128,
                "lng": -132.4429629183
            },
            {
                "lat": 53.1762811905,
                "lng": -132.4272616074
            },
            {
                "lat": 53.1705702266,
                "lng": -132.4227293502
            },
            {
                "lat": 53.1610529145,
                "lng": -132.4260711064
            },
            {
                "lat": 53.1577227371,
                "lng": -132.4427180642
            },
            {
                "lat": 53.1558192513,
                "lng": -132.4610445012
            },
            {
                "lat": 53.1527249835,
                "lng": -132.476516948
            },
            {
                "lat": 53.1488425551,
                "lng": -132.4830780469
            },
            {
                "lat": 53.1462017751,
                "lng": -132.4867857866
            },
            {
                "lat": 53.1450426295,
                "lng": -132.4904020007
            },
            {
                "lat": 53.1446956016,
                "lng": -132.482162188
            },
            {
                "lat": 53.144695687,
                "lng": -132.4684750037
            },
            {
                "lat": 53.1452903146,
                "lng": -132.4482573531
            },
            {
                "lat": 53.1458850942,
                "lng": -132.4280246408
            },
            {
                "lat": 53.1452902956,
                "lng": -132.4066166332
            },
            {
                "lat": 53.1442801806,
                "lng": -132.3935251579
            },
            {
                "lat": 53.1509401158,
                "lng": -132.3873444582
            },
            {
                "lat": 53.1666445117,
                "lng": -132.3747251117
            },
            {
                "lat": 53.178543224,
                "lng": -132.365921766
            },
            {
                "lat": 53.1947250705,
                "lng": -132.3514100575
            },
            {
                "lat": 53.202812319,
                "lng": -132.3352355597
            },
            {
                "lat": 53.1963884819,
                "lng": -132.3100132186
            },
            {
                "lat": 53.1819196405,
                "lng": -132.2837518233
            },
            {
                "lat": 53.2069019369,
                "lng": -132.3117066384
            },
            {
                "lat": 53.2265318116,
                "lng": -132.3337248435
            },
            {
                "lat": 53.2503284869,
                "lng": -132.3301543704
            },
            {
                "lat": 53.2586554638,
                "lng": -132.2950597971
            },
            {
                "lat": 53.2527081428,
                "lng": -132.2183233584
            },
            {
                "lat": 53.2586556434,
                "lng": -132.158234194
            },
            {
                "lat": 53.2767713232,
                "lng": -132.1167144562
            },
            {
                "lat": 53.383132837,
                "lng": -131.9745180236
            },
            {
                "lat": 53.4272993506,
                "lng": -131.9654992268
            },
            {
                "lat": 53.4629934581,
                "lng": -131.9714507723
            },
            {
                "lat": 53.5248568688,
                "lng": -131.9738314343
            },
            {
                "lat": 53.6295551813,
                "lng": -131.9512174385
            },
            {
                "lat": 53.6832198396,
                "lng": -131.9230041457
            },
            {
                "lat": 53.6985588782,
                "lng": -131.9214777464
            },
            {
                "lat": 53.799686396,
                "lng": -131.9155272802
            },
            {
                "lat": 53.8615534062,
                "lng": -131.8655548826
            },
            {
                "lat": 53.926986674,
                "lng": -131.7989348028
            },
            {
                "lat": 53.9400066236,
                "lng": -131.7891235225
            },
            {
                "lat": 54.0185961411,
                "lng": -131.7299350668
            },
            {
                "lat": 54.0866967481,
                "lng": -131.6898495322
            },
            {
                "lat": 54.0932769533,
                "lng": -131.6847079684
            },
            {
                "lat": 54.1100731895,
                "lng": -131.6776124414
            },
            {
                "lat": 54.1264914192,
                "lng": -131.6764381462
            },
            {
                "lat": 54.1305807907,
                "lng": -131.676147637
            },
            {
                "lat": 54.0950159761,
                "lng": -131.7026218268
            },
            {
                "lat": 54.0706290829,
                "lng": -131.753174298
            },
            {
                "lat": 54.0474278331,
                "lng": -131.8120735485
            },
            {
                "lat": 54.0200652646,
                "lng": -131.8870234655
            },
            {
                "lat": 54.0117381255,
                "lng": -131.9542383179
            },
            {
                "lat": 54.009357435,
                "lng": -132.0196836928
            },
            {
                "lat": 54.0199469218,
                "lng": -132.105071653
            },
            {
                "lat": 54.0186767058,
                "lng": -132.1164704838
            },
            {
                "lat": 54.006351992,
                "lng": -132.1089168878
            },
            {
                "lat": 53.9763144408,
                "lng": -132.0880125766
            },
            {
                "lat": 53.892723492,
                "lng": -132.0540618504
            },
            {
                "lat": 53.8039090781,
                "lng": -132.0527492141
            },
            {
                "lat": 53.6915857571,
                "lng": -132.0984650021
            },
            {
                "lat": 53.6288946995,
                "lng": -132.159850874
            },
            {
                "lat": 53.5975452398,
                "lng": -132.2630312058
            },
            {
                "lat": 53.5662007252,
                "lng": -132.3884127198
            },
            {
                "lat": 53.5400769845,
                "lng": -132.4785459231
            },
            {
                "lat": 53.5570562328,
                "lng": -132.5399323525
            },
            {
                "lat": 53.5897104074,
                "lng": -132.5673526599
            },
            {
                "lat": 53.6001588222,
                "lng": -132.6378782002
            },
            {
                "lat": 53.6563220811,
                "lng": -132.7175594347
            },
            {
                "lat": 53.6955028768,
                "lng": -132.6953427007
            },
            {
                "lat": 53.7124820212,
                "lng": -132.648330736
            },
            {
                "lat": 53.7281575579,
                "lng": -132.5764922672
            },
            {
                "lat": 53.7568889795,
                "lng": -132.4759208921
            },
            {
                "lat": 53.7960744775,
                "lng": -132.3361817043
            },
            {
                "lat": 53.8365637584,
                "lng": -132.2264710864
            },
            {
                "lat": 53.8652958395,
                "lng": -132.1637722068
            },
            {
                "lat": 53.9123151553,
                "lng": -132.1676943613
            },
            {
                "lat": 53.9593348686,
                "lng": -132.1820529359
            },
            {
                "lat": 53.9940755888,
                "lng": -132.2172388906
            },
            {
                "lat": 54.0097578623,
                "lng": -132.2503665157
            },
            {
                "lat": 54.0285300159,
                "lng": -132.270675323
            },
            {
                "lat": 54.0477445163,
                "lng": -132.2655943531
            },
            {
                "lat": 54.0649646486,
                "lng": -132.2536769015
            },
            {
                "lat": 54.0661542633,
                "lng": -132.2617647915
            },
            {
                "lat": 54.0754311519,
                "lng": -132.2720038042
            },
            {
                "lat": 54.0951847782,
                "lng": -132.2979431968
            },
            {
                "lat": 54.1039878981,
                "lng": -132.3141172931
            },
            {
                "lat": 54.0997051353,
                "lng": -132.3255463515
            },
            {
                "lat": 54.0944674154,
                "lng": -132.3488616266
            },
            {
                "lat": 54.0911372681,
                "lng": -132.3821715491
            },
            {
                "lat": 54.0864981246,
                "lng": -132.4184878666
            },
            {
                "lat": 54.0518761365,
                "lng": -132.456878811
            },
            {
                "lat": 54.0392649061,
                "lng": -132.4871058944
            },
            {
                "lat": 54.0342669936,
                "lng": -132.5265955137
            },
            {
                "lat": 54.0440249354,
                "lng": -132.5427850737
            },
            {
                "lat": 54.0168991429,
                "lng": -132.5401613563
            },
            {
                "lat": 53.9823945561,
                "lng": -132.5544433734
            },
            {
                "lat": 53.9633593783,
                "lng": -132.5803827729
            },
            {
                "lat": 53.9436106376,
                "lng": -132.6201163514
            },
            {
                "lat": 53.9329264648,
                "lng": -132.6470799166
            },
            {
                "lat": 53.9332080447,
                "lng": -132.650268562
            },
            {
                "lat": 53.939567686,
                "lng": -132.6788937035
            },
            {
                "lat": 53.944324772,
                "lng": -132.7031553516
            },
            {
                "lat": 53.9738310272,
                "lng": -132.7050622207
            },
            {
                "lat": 53.9881061509,
                "lng": -132.6962576726
            },
            {
                "lat": 53.9981000356,
                "lng": -132.6743624515
            },
            {
                "lat": 54.0145181048,
                "lng": -132.6510471434
            },
            {
                "lat": 54.0230861961,
                "lng": -132.6396335682
            },
            {
                "lat": 54.0297467351,
                "lng": -132.6184546054
            },
            {
                "lat": 54.0397418951,
                "lng": -132.5994111189
            },
            {
                "lat": 54.0516393895,
                "lng": -132.5865636509
            },
            {
                "lat": 54.0526546304,
                "lng": -132.5776368675
            },
            {
                "lat": 54.0651622213,
                "lng": -132.5915382265
            },
            {
                "lat": 54.0786701425,
                "lng": -132.5965579319
            },
            {
                "lat": 54.1253352868,
                "lng": -132.6367493736
            },
            {
                "lat": 54.1339719145,
                "lng": -132.6496883257
            },
            {
                "lat": 54.1423106669,
                "lng": -132.6624296687
            },
            {
                "lat": 54.1483340901,
                "lng": -132.6640624456
            },
            {
                "lat": 54.1423106429,
                "lng": -132.6730956921
            },
            {
                "lat": 54.1393015029,
                "lng": -132.6953274264
            },
            {
                "lat": 54.135746039,
                "lng": -132.719710757
            },
            {
                "lat": 54.1099962146,
                "lng": -132.8049616291
            },
            {
                "lat": 54.1175122505,
                "lng": -132.822204695
            },
            {
                "lat": 54.1228519412,
                "lng": -132.8514865587
            },
            {
                "lat": 54.1314237069,
                "lng": -132.8878640845
            },
            {
                "lat": 54.1390684448,
                "lng": -132.9207613267
            },
            {
                "lat": 54.1494936363,
                "lng": -132.9427641026
            },
            {
                "lat": 54.160152709,
                "lng": -132.949722282
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 54,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 1,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4891607094.716,
            "FEATURE_LENGTH_M": 805662.5931,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.57956510795513,
            "lng": -132.7054074956747
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.2483329114,
                "lng": -131.5116725774
            },
            {
                "lat": 54.1500013545,
                "lng": -131.6666712768
            },
            {
                "lat": 54.1305807907,
                "lng": -131.676147637
            },
            {
                "lat": 54.1264914192,
                "lng": -131.6764381462
            },
            {
                "lat": 54.1100731895,
                "lng": -131.6776124414
            },
            {
                "lat": 54.0932769533,
                "lng": -131.6847079684
            },
            {
                "lat": 54.0866967481,
                "lng": -131.6898495322
            },
            {
                "lat": 54.0185961411,
                "lng": -131.7299350668
            },
            {
                "lat": 53.9400066236,
                "lng": -131.7891235225
            },
            {
                "lat": 53.926986674,
                "lng": -131.7989348028
            },
            {
                "lat": 53.8615534062,
                "lng": -131.8655548826
            },
            {
                "lat": 53.799686396,
                "lng": -131.9155272802
            },
            {
                "lat": 53.6985588782,
                "lng": -131.9214777464
            },
            {
                "lat": 53.6832198396,
                "lng": -131.9230041457
            },
            {
                "lat": 53.6295551813,
                "lng": -131.9512174385
            },
            {
                "lat": 53.5248568688,
                "lng": -131.9738314343
            },
            {
                "lat": 53.4629934581,
                "lng": -131.9714507723
            },
            {
                "lat": 53.4272993506,
                "lng": -131.9654992268
            },
            {
                "lat": 53.4309040026,
                "lng": -131.9106593908
            },
            {
                "lat": 53.2605404104,
                "lng": -131.7705388815
            },
            {
                "lat": 53.3666680692,
                "lng": -131.1999964036
            },
            {
                "lat": 53.8666687304,
                "lng": -131.3749999863
            },
            {
                "lat": 54.2483329114,
                "lng": -131.5116725774
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 55,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 102,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 102-1",
            "LABEL": "102-1",
            "FISHERY_AREA_ID": 2296,
            "OBJECTID": 2,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 3362079473.2938,
            "FEATURE_LENGTH_M": 264583.0744,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.83008175253043,
            "lng": -131.74593578860433
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.1666685414,
                "lng": -131.3000034141
            },
            {
                "lat": 54.2483329114,
                "lng": -131.5116725774
            },
            {
                "lat": 53.8666687304,
                "lng": -131.3749999863
            },
            {
                "lat": 53.8666682474,
                "lng": -131.3000030003
            },
            {
                "lat": 54.1666685414,
                "lng": -131.3000034141
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 56,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 104,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 104-5",
            "LABEL": "104-5",
            "FISHERY_AREA_ID": 2303,
            "OBJECTID": 3,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 335187039.5971,
            "FEATURE_LENGTH_M": 98441.5077,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.0630013944,
            "lng": -131.35733647844
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.1567495949,
                "lng": -132.6524192672
            },
            {
                "lat": 54.1705775411,
                "lng": -132.9440308615
            },
            {
                "lat": 54.1675650655,
                "lng": -132.9443819633
            },
            {
                "lat": 54.160152709,
                "lng": -132.949722282
            },
            {
                "lat": 54.1494936363,
                "lng": -132.9427641026
            },
            {
                "lat": 54.1390684448,
                "lng": -132.9207613267
            },
            {
                "lat": 54.1314237069,
                "lng": -132.8878640845
            },
            {
                "lat": 54.1228519412,
                "lng": -132.8514865587
            },
            {
                "lat": 54.1175122505,
                "lng": -132.822204695
            },
            {
                "lat": 54.1099962146,
                "lng": -132.8049616291
            },
            {
                "lat": 54.135746039,
                "lng": -132.719710757
            },
            {
                "lat": 54.1393015029,
                "lng": -132.6953274264
            },
            {
                "lat": 54.1423106429,
                "lng": -132.6730956921
            },
            {
                "lat": 54.1483340901,
                "lng": -132.6640624456
            },
            {
                "lat": 54.153484531,
                "lng": -132.66098075
            },
            {
                "lat": 54.1567495949,
                "lng": -132.6524192672
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 57,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 1,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 1-7",
            "LABEL": "1-7",
            "FISHERY_AREA_ID": 1891,
            "OBJECTID": 4,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 71325521.1105,
            "FEATURE_LENGTH_M": 43233.079,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.143832344100005,
            "lng": -132.79913706930625
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.1567495949,
                "lng": -132.6524192672
            },
            {
                "lat": 54.153484531,
                "lng": -132.66098075
            },
            {
                "lat": 54.1483340901,
                "lng": -132.6640624456
            },
            {
                "lat": 54.1423106669,
                "lng": -132.6624296687
            },
            {
                "lat": 54.1339719145,
                "lng": -132.6496883257
            },
            {
                "lat": 54.1253352868,
                "lng": -132.6367493736
            },
            {
                "lat": 54.0786701425,
                "lng": -132.5965579319
            },
            {
                "lat": 54.0651622213,
                "lng": -132.5915382265
            },
            {
                "lat": 54.0526546304,
                "lng": -132.5776368675
            },
            {
                "lat": 54.0514869378,
                "lng": -132.5731513539
            },
            {
                "lat": 54.0510294522,
                "lng": -132.5713804634
            },
            {
                "lat": 54.0471646822,
                "lng": -132.5694272061
            },
            {
                "lat": 54.0501704261,
                "lng": -132.5455168823
            },
            {
                "lat": 54.0440249354,
                "lng": -132.5427850737
            },
            {
                "lat": 54.0342669936,
                "lng": -132.5265955137
            },
            {
                "lat": 54.0392649061,
                "lng": -132.4871058944
            },
            {
                "lat": 54.0518761365,
                "lng": -132.456878811
            },
            {
                "lat": 54.0864981246,
                "lng": -132.4184878666
            },
            {
                "lat": 54.0911372681,
                "lng": -132.3821715491
            },
            {
                "lat": 54.0944674154,
                "lng": -132.3488616266
            },
            {
                "lat": 54.0997051353,
                "lng": -132.3255463515
            },
            {
                "lat": 54.1039878981,
                "lng": -132.3141172931
            },
            {
                "lat": 54.1111257825,
                "lng": -132.3176879588
            },
            {
                "lat": 54.1148801626,
                "lng": -132.3192596987
            },
            {
                "lat": 54.1567495949,
                "lng": -132.6524192672
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 58,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 1,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 1-3",
            "LABEL": "1-3",
            "FISHERY_AREA_ID": 1887,
            "OBJECTID": 5,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 153633431.533,
            "FEATURE_LENGTH_M": 58536.6263,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.091380357192,
            "lng": -132.521738226672
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.2161366911,
                "lng": -130.7647704927
            },
            {
                "lat": 54.2352787644,
                "lng": -130.9766694883
            },
            {
                "lat": 54.0000002942,
                "lng": -130.8672182377
            },
            {
                "lat": 53.9997251497,
                "lng": -130.8670814948
            },
            {
                "lat": 53.9997217767,
                "lng": -130.7923192294
            },
            {
                "lat": 53.9997227477,
                "lng": -130.7922522906
            },
            {
                "lat": 54.000967657,
                "lng": -130.6698206772
            },
            {
                "lat": 54.0009685601,
                "lng": -130.6697537336
            },
            {
                "lat": 54.0008964947,
                "lng": -130.6695712912
            },
            {
                "lat": 54.0004878763,
                "lng": -130.6693260074
            },
            {
                "lat": 53.999896752,
                "lng": -130.6688082713
            },
            {
                "lat": 53.9985461403,
                "lng": -130.6660605848
            },
            {
                "lat": 53.9958684612,
                "lng": -130.6649315518
            },
            {
                "lat": 53.9931911707,
                "lng": -130.6650696165
            },
            {
                "lat": 53.9913564658,
                "lng": -130.6621253593
            },
            {
                "lat": 53.9867054238,
                "lng": -130.6598661669
            },
            {
                "lat": 53.9854390449,
                "lng": -130.6560520441
            },
            {
                "lat": 53.9848745184,
                "lng": -130.649155606
            },
            {
                "lat": 53.9796599664,
                "lng": -130.6487276131
            },
            {
                "lat": 53.9735981179,
                "lng": -130.6535187663
            },
            {
                "lat": 53.9679603435,
                "lng": -130.6512599266
            },
            {
                "lat": 53.9675364016,
                "lng": -130.6450655351
            },
            {
                "lat": 53.9702185446,
                "lng": -130.636184095
            },
            {
                "lat": 53.9796598619,
                "lng": -130.6309663557
            },
            {
                "lat": 53.9893841559,
                "lng": -130.6275942209
            },
            {
                "lat": 53.9989700481,
                "lng": -130.6250459187
            },
            {
                "lat": 54.0040432626,
                "lng": -130.6161654648
            },
            {
                "lat": 54.0167277226,
                "lng": -130.601089704
            },
            {
                "lat": 54.027156129,
                "lng": -130.5981289381
            },
            {
                "lat": 54.0318073352,
                "lng": -130.5988315784
            },
            {
                "lat": 54.0342333262,
                "lng": -130.6005101315
            },
            {
                "lat": 54.0541497979,
                "lng": -130.6257326564
            },
            {
                "lat": 54.0542264461,
                "lng": -130.6251524114
            },
            {
                "lat": 54.0544010569,
                "lng": -130.6247106536
            },
            {
                "lat": 54.0546034506,
                "lng": -130.6244810734
            },
            {
                "lat": 54.054935253,
                "lng": -130.6239935862
            },
            {
                "lat": 54.0563239205,
                "lng": -130.6230622429
            },
            {
                "lat": 54.0577166693,
                "lng": -130.6216277793
            },
            {
                "lat": 54.0614162425,
                "lng": -130.6164100263
            },
            {
                "lat": 54.063506928,
                "lng": -130.6098322049
            },
            {
                "lat": 54.0633277335,
                "lng": -130.6045827937
            },
            {
                "lat": 54.0629123337,
                "lng": -130.5990904158
            },
            {
                "lat": 54.0612984182,
                "lng": -130.5950783075
            },
            {
                "lat": 54.0587468287,
                "lng": -130.5914458175
            },
            {
                "lat": 54.0592995062,
                "lng": -130.5873409703
            },
            {
                "lat": 54.0592382584,
                "lng": -130.5864710189
            },
            {
                "lat": 54.0593906932,
                "lng": -130.5856631226
            },
            {
                "lat": 54.0596199572,
                "lng": -130.5849452505
            },
            {
                "lat": 54.0671997163,
                "lng": -130.569549467
            },
            {
                "lat": 54.0670286644,
                "lng": -130.5705262488
            },
            {
                "lat": 54.0667757242,
                "lng": -130.5714421732
            },
            {
                "lat": 54.066677245,
                "lng": -130.572799142
            },
            {
                "lat": 54.0679205372,
                "lng": -130.5735467035
            },
            {
                "lat": 54.0738027243,
                "lng": -130.5765228876
            },
            {
                "lat": 54.099216209,
                "lng": -130.6033478606
            },
            {
                "lat": 54.1050149749,
                "lng": -130.6190183326
            },
            {
                "lat": 54.1086692564,
                "lng": -130.6260986712
            },
            {
                "lat": 54.1144376683,
                "lng": -130.6264344861
            },
            {
                "lat": 54.1162071683,
                "lng": -130.6259767219
            },
            {
                "lat": 54.1170046839,
                "lng": -130.6255195443
            },
            {
                "lat": 54.1177483712,
                "lng": -130.6250611317
            },
            {
                "lat": 54.1188928458,
                "lng": -130.6248934622
            },
            {
                "lat": 54.1203155077,
                "lng": -130.6256252399
            },
            {
                "lat": 54.1302994089,
                "lng": -130.6361840507
            },
            {
                "lat": 54.129989638,
                "lng": -130.6372531792
            },
            {
                "lat": 54.1308368149,
                "lng": -130.6388548148
            },
            {
                "lat": 54.1314959147,
                "lng": -130.640121195
            },
            {
                "lat": 54.1331897611,
                "lng": -130.6445930172
            },
            {
                "lat": 54.1398195185,
                "lng": -130.6508944331
            },
            {
                "lat": 54.1489559249,
                "lng": -130.6706696029
            },
            {
                "lat": 54.1560977525,
                "lng": -130.6918490421
            },
            {
                "lat": 54.1708489978,
                "lng": -130.7299193019
            },
            {
                "lat": 54.1891704158,
                "lng": -130.7532353287
            },
            {
                "lat": 54.2015452254,
                "lng": -130.7570504953
            },
            {
                "lat": 54.2047002546,
                "lng": -130.7566985006
            },
            {
                "lat": 54.2058792229,
                "lng": -130.7554012242
            },
            {
                "lat": 54.206333213,
                "lng": -130.754958817
            },
            {
                "lat": 54.2065966382,
                "lng": -130.7546243786
            },
            {
                "lat": 54.206749206,
                "lng": -130.7542116717
            },
            {
                "lat": 54.210101641,
                "lng": -130.7527461802
            },
            {
                "lat": 54.2136720146,
                "lng": -130.7630922754
            },
            {
                "lat": 54.2161366911,
                "lng": -130.7647704927
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 59,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea  4-2",
            "LABEL": "4-2",
            "FISHERY_AREA_ID": 2395,
            "OBJECTID": 6,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 440898585.5145,
            "FEATURE_LENGTH_M": 97570.0788,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.07420953962438,
            "lng": -130.65898842435243
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.235223545,
                "lng": -130.3777484658
            },
            {
                "lat": 54.234653765,
                "lng": -130.3347472601
            },
            {
                "lat": 54.234420999,
                "lng": -130.3342432307
            },
            {
                "lat": 54.2342641018,
                "lng": -130.3339080041
            },
            {
                "lat": 54.2340846394,
                "lng": -130.3334663962
            },
            {
                "lat": 54.2328379506,
                "lng": -130.3312226842
            },
            {
                "lat": 54.2313566969,
                "lng": -130.325549845
            },
            {
                "lat": 54.2313741035,
                "lng": -130.3255651525
            },
            {
                "lat": 54.2328565957,
                "lng": -130.3312188673
            },
            {
                "lat": 54.2341032936,
                "lng": -130.3334625811
            },
            {
                "lat": 54.2342827219,
                "lng": -130.3339051409
            },
            {
                "lat": 54.2344396097,
                "lng": -130.334240382
            },
            {
                "lat": 54.2346723846,
                "lng": -130.3347444125
            },
            {
                "lat": 54.2352405542,
                "lng": -130.377743525
            },
            {
                "lat": 54.235223545,
                "lng": -130.3777484658
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 60,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 7,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 6778.9002,
            "FEATURE_LENGTH_M": 7018.2996,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.23393563372667,
            "lng": -130.34130096088
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.2313566969,
                "lng": -130.325549845
            },
            {
                "lat": 54.2328379506,
                "lng": -130.3312226842
            },
            {
                "lat": 54.2340846394,
                "lng": -130.3334663962
            },
            {
                "lat": 54.2342641018,
                "lng": -130.3339080041
            },
            {
                "lat": 54.234420999,
                "lng": -130.3342432307
            },
            {
                "lat": 54.234653765,
                "lng": -130.3347472601
            },
            {
                "lat": 54.235223545,
                "lng": -130.3777484658
            },
            {
                "lat": 54.1017861619,
                "lng": -130.4146219555
            },
            {
                "lat": 54.1017737662,
                "lng": -130.4146388772
            },
            {
                "lat": 54.0981437597,
                "lng": -130.4195666108
            },
            {
                "lat": 54.0887410283,
                "lng": -130.4187129509
            },
            {
                "lat": 54.0825000783,
                "lng": -130.4116175875
            },
            {
                "lat": 54.0817676677,
                "lng": -130.4037900914
            },
            {
                "lat": 54.079005148,
                "lng": -130.3913532551
            },
            {
                "lat": 54.06228584,
                "lng": -130.3651393243
            },
            {
                "lat": 54.042655812,
                "lng": -130.3330196432
            },
            {
                "lat": 54.0212433998,
                "lng": -130.3092153537
            },
            {
                "lat": 54.0045848044,
                "lng": -130.306836111
            },
            {
                "lat": 53.9962571795,
                "lng": -130.2872129021
            },
            {
                "lat": 53.9830013152,
                "lng": -130.2703826637
            },
            {
                "lat": 53.97865326,
                "lng": -130.2682151325
            },
            {
                "lat": 53.9707867381,
                "lng": -130.2679104708
            },
            {
                "lat": 53.9632872586,
                "lng": -130.2710232898
            },
            {
                "lat": 53.9568284202,
                "lng": -130.2750360935
            },
            {
                "lat": 53.9531170875,
                "lng": -130.2757830224
            },
            {
                "lat": 53.949481549,
                "lng": -130.2727317074
            },
            {
                "lat": 53.9477006608,
                "lng": -130.2679871027
            },
            {
                "lat": 53.9465296334,
                "lng": -130.2645371688
            },
            {
                "lat": 53.9443697307,
                "lng": -130.2617765934
            },
            {
                "lat": 53.9379682166,
                "lng": -130.2488366057
            },
            {
                "lat": 53.936763459,
                "lng": -130.2460748215
            },
            {
                "lat": 53.9329446521,
                "lng": -130.2421082282
            },
            {
                "lat": 53.9369808066,
                "lng": -130.1632960473
            },
            {
                "lat": 53.9384375254,
                "lng": -130.1025916073
            },
            {
                "lat": 53.9393545591,
                "lng": -130.1021173289
            },
            {
                "lat": 53.9393722839,
                "lng": -130.1021076104
            },
            {
                "lat": 53.9399218306,
                "lng": -130.0973479908
            },
            {
                "lat": 53.9440080173,
                "lng": -130.0817529727
            },
            {
                "lat": 53.9564130765,
                "lng": -130.0798007659
            },
            {
                "lat": 53.9716900576,
                "lng": -130.0658688816
            },
            {
                "lat": 53.9915121605,
                "lng": -130.0657165738
            },
            {
                "lat": 54.01496065,
                "lng": -130.0679904115
            },
            {
                "lat": 54.0376243859,
                "lng": -130.0684133157
            },
            {
                "lat": 54.0355834953,
                "lng": -130.0917349969
            },
            {
                "lat": 54.0355836568,
                "lng": -130.1398463283
            },
            {
                "lat": 54.0357548842,
                "lng": -130.1467592732
            },
            {
                "lat": 54.0345000055,
                "lng": -130.1580506476
            },
            {
                "lat": 54.0416417407,
                "lng": -130.1646894191
            },
            {
                "lat": 54.0416558802,
                "lng": -130.1647023414
            },
            {
                "lat": 54.0585174219,
                "lng": -130.1636385589
            },
            {
                "lat": 54.0585359636,
                "lng": -130.1636375564
            },
            {
                "lat": 54.0689742725,
                "lng": -130.164565518
            },
            {
                "lat": 54.0689893616,
                "lng": -130.1645670372
            },
            {
                "lat": 54.0893309445,
                "lng": -130.1467506882
            },
            {
                "lat": 54.0893471217,
                "lng": -130.1467369605
            },
            {
                "lat": 54.1366238868,
                "lng": -130.1295688081
            },
            {
                "lat": 54.1366415145,
                "lng": -130.1295619342
            },
            {
                "lat": 54.1484830421,
                "lng": -130.1294705272
            },
            {
                "lat": 54.1485015596,
                "lng": -130.1294704579
            },
            {
                "lat": 54.1678480829,
                "lng": -130.1286593863
            },
            {
                "lat": 54.1678649188,
                "lng": -130.1286591483
            },
            {
                "lat": 54.1822202621,
                "lng": -130.1528740436
            },
            {
                "lat": 54.2026863618,
                "lng": -130.1883205389
            },
            {
                "lat": 54.2074429765,
                "lng": -130.2211573403
            },
            {
                "lat": 54.2055395483,
                "lng": -130.2380485132
            },
            {
                "lat": 54.2057758675,
                "lng": -130.2585105252
            },
            {
                "lat": 54.2091099988,
                "lng": -130.2749288326
            },
            {
                "lat": 54.213389493,
                "lng": -130.2823163257
            },
            {
                "lat": 54.2133709422,
                "lng": -130.2823172777
            },
            {
                "lat": 54.210567697,
                "lng": -130.2866519371
            },
            {
                "lat": 54.2105629725,
                "lng": -130.2914178479
            },
            {
                "lat": 54.2097769609,
                "lng": -130.2912927174
            },
            {
                "lat": 54.2097593127,
                "lng": -130.2912841361
            },
            {
                "lat": 54.2086388825,
                "lng": -130.2907643019
            },
            {
                "lat": 54.2086253795,
                "lng": -130.2907494243
            },
            {
                "lat": 54.2075528382,
                "lng": -130.2895735241
            },
            {
                "lat": 54.2075387806,
                "lng": -130.2895585896
            },
            {
                "lat": 54.2061152879,
                "lng": -130.2892642047
            },
            {
                "lat": 54.2060977618,
                "lng": -130.2892681544
            },
            {
                "lat": 54.2034903998,
                "lng": -130.2898666672
            },
            {
                "lat": 54.2034758457,
                "lng": -130.2898815018
            },
            {
                "lat": 54.2012370132,
                "lng": -130.2921298364
            },
            {
                "lat": 54.2012300461,
                "lng": -130.2921522018
            },
            {
                "lat": 54.2002638379,
                "lng": -130.2951514717
            },
            {
                "lat": 54.200919919,
                "lng": -130.2978511842
            },
            {
                "lat": 54.2016023521,
                "lng": -130.3006287991
            },
            {
                "lat": 54.2016523951,
                "lng": -130.3015903767
            },
            {
                "lat": 54.2016220158,
                "lng": -130.3023674868
            },
            {
                "lat": 54.2025257071,
                "lng": -130.3085637781
            },
            {
                "lat": 54.2025434904,
                "lng": -130.3085685308
            },
            {
                "lat": 54.2042616346,
                "lng": -130.3090165501
            },
            {
                "lat": 54.2042799813,
                "lng": -130.3090213621
            },
            {
                "lat": 54.2125579267,
                "lng": -130.3088859213
            },
            {
                "lat": 54.2125736448,
                "lng": -130.3088856398
            },
            {
                "lat": 54.2209053104,
                "lng": -130.3162658789
            },
            {
                "lat": 54.2313566969,
                "lng": -130.325549845
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 61,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea  4-12",
            "LABEL": "4-12",
            "FISHERY_AREA_ID": 2404,
            "OBJECTID": 8,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 469422542.5185,
            "FEATURE_LENGTH_M": 103847.9495,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.10630146845832,
            "lng": -130.24814335218028
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.7911185747,
                "lng": -133.1249693127
            },
            {
                "lat": 53.7911186721,
                "lng": -134.4190063792
            },
            {
                "lat": 53.7911185822,
                "lng": -135.7130432566
            },
            {
                "lat": 53.791118304,
                "lng": -137.007080354
            },
            {
                "lat": 53.4777529271,
                "lng": -138.7586511038
            },
            {
                "lat": 53.3057816731,
                "lng": -138.7022549051
            },
            {
                "lat": 53.1037975723,
                "lng": -138.6125487819
            },
            {
                "lat": 53.0446317908,
                "lng": -138.5798337617
            },
            {
                "lat": 52.9597781631,
                "lng": -138.5424343066
            },
            {
                "lat": 52.8125346108,
                "lng": -138.4615940702
            },
            {
                "lat": 52.8126184866,
                "lng": -137.4257867693
            },
            {
                "lat": 52.8127025766,
                "lng": -136.3899792925
            },
            {
                "lat": 52.8127860175,
                "lng": -135.3541715694
            },
            {
                "lat": 52.8128698417,
                "lng": -134.3183642119
            },
            {
                "lat": 52.8129537461,
                "lng": -133.282556832
            },
            {
                "lat": 52.8130376406,
                "lng": -132.2467491876
            },
            {
                "lat": 52.9265334363,
                "lng": -132.3867945115
            },
            {
                "lat": 53.0194244945,
                "lng": -132.5014192371
            },
            {
                "lat": 53.0453758122,
                "lng": -132.5334633195
            },
            {
                "lat": 53.0454480107,
                "lng": -132.5329734711
            },
            {
                "lat": 53.0462802275,
                "lng": -132.5322115558
            },
            {
                "lat": 53.0474515202,
                "lng": -132.5274666146
            },
            {
                "lat": 53.047446736,
                "lng": -132.5234221481
            },
            {
                "lat": 53.050541111,
                "lng": -132.5208130089
            },
            {
                "lat": 53.0550649738,
                "lng": -132.5186620137
            },
            {
                "lat": 53.0572050378,
                "lng": -132.5136726476
            },
            {
                "lat": 53.0583958198,
                "lng": -132.5003508291
            },
            {
                "lat": 53.0657695149,
                "lng": -132.495819195
            },
            {
                "lat": 53.0731466853,
                "lng": -132.4965360249
            },
            {
                "lat": 53.0764774864,
                "lng": -132.5027312232
            },
            {
                "lat": 53.0764773976,
                "lng": -132.5081933673
            },
            {
                "lat": 53.0752911282,
                "lng": -132.5208135953
            },
            {
                "lat": 53.0731466013,
                "lng": -132.5365138616
            },
            {
                "lat": 53.073387753,
                "lng": -132.5472254371
            },
            {
                "lat": 53.0800477503,
                "lng": -132.5534056612
            },
            {
                "lat": 53.085521585,
                "lng": -132.5576937869
            },
            {
                "lat": 53.094158561,
                "lng": -132.5660404809
            },
            {
                "lat": 53.0955356516,
                "lng": -132.5749357145
            },
            {
                "lat": 53.1520156336,
                "lng": -132.6292875928
            },
            {
                "lat": 53.253463573,
                "lng": -132.7266235054
            },
            {
                "lat": 53.3155478649,
                "lng": -132.7861786247
            },
            {
                "lat": 53.4330936162,
                "lng": -132.8989558723
            },
            {
                "lat": 53.4683532698,
                "lng": -132.9327694922
            },
            {
                "lat": 53.5462225739,
                "lng": -133.0074774495
            },
            {
                "lat": 53.5462913339,
                "lng": -133.0082698736
            },
            {
                "lat": 53.5478513339,
                "lng": -133.0090482226
            },
            {
                "lat": 53.6903956345,
                "lng": -133.0773475536
            },
            {
                "lat": 53.7103542779,
                "lng": -133.0868990188
            },
            {
                "lat": 53.7475315579,
                "lng": -133.1047206753
            },
            {
                "lat": 53.778545425,
                "lng": -133.119582759
            },
            {
                "lat": 53.7911185747,
                "lng": -133.1249693127
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 62,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 142,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 142-2",
            "LABEL": "142-2",
            "FISHERY_AREA_ID": 2347,
            "OBJECTID": 9,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 40962222193.947,
            "FEATURE_LENGTH_M": 1000997.1645,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.215581002801954,
            "lng": -133.82161395591956
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.213389493,
                "lng": -130.2823163257
            },
            {
                "lat": 54.210567697,
                "lng": -130.2866519371
            },
            {
                "lat": 54.2133709422,
                "lng": -130.2823172777
            },
            {
                "lat": 54.213389493,
                "lng": -130.2823163257
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 63,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 10,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 282.2959,
            "FEATURE_LENGTH_M": 846.115,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.212679406300005,
            "lng": -130.28340046655
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.0000002942,
                "lng": -130.8672182377
            },
            {
                "lat": 54.1666685414,
                "lng": -131.3000034141
            },
            {
                "lat": 53.8666682474,
                "lng": -131.3000030003
            },
            {
                "lat": 53.8666648701,
                "lng": -130.8052588244
            },
            {
                "lat": 53.9997252545,
                "lng": -130.8671485631
            },
            {
                "lat": 53.9997251497,
                "lng": -130.8670814948
            },
            {
                "lat": 54.0000002942,
                "lng": -130.8672182377
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 64,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 104,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 104-3",
            "LABEL": "104-3",
            "FISHERY_AREA_ID": 2301,
            "OBJECTID": 11,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 715108089.8714,
            "FEATURE_LENGTH_M": 115193.0203,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.98563609307143,
            "lng": -130.98199025315714
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.1500013545,
                "lng": -131.6666712768
            },
            {
                "lat": 54.1148801626,
                "lng": -132.3192596987
            },
            {
                "lat": 54.1111257825,
                "lng": -132.3176879588
            },
            {
                "lat": 54.1039878981,
                "lng": -132.3141172931
            },
            {
                "lat": 54.0951847782,
                "lng": -132.2979431968
            },
            {
                "lat": 54.0754311519,
                "lng": -132.2720038042
            },
            {
                "lat": 54.0661542633,
                "lng": -132.2617647915
            },
            {
                "lat": 54.0649646486,
                "lng": -132.2536769015
            },
            {
                "lat": 54.0764316742,
                "lng": -132.2431793332
            },
            {
                "lat": 54.0453568048,
                "lng": -132.1976012491
            },
            {
                "lat": 54.0457080771,
                "lng": -132.1976462413
            },
            {
                "lat": 54.0459292876,
                "lng": -132.1978157939
            },
            {
                "lat": 54.0463601967,
                "lng": -132.1981051564
            },
            {
                "lat": 54.046817729,
                "lng": -132.1985329803
            },
            {
                "lat": 54.047290117,
                "lng": -132.1987152444
            },
            {
                "lat": 54.0480343051,
                "lng": -132.1988374397
            },
            {
                "lat": 54.0485953519,
                "lng": -132.1987602334
            },
            {
                "lat": 54.0490527404,
                "lng": -132.1985781428
            },
            {
                "lat": 54.0494115053,
                "lng": -132.198028758
            },
            {
                "lat": 54.049885145,
                "lng": -132.19731122
            },
            {
                "lat": 54.0502544383,
                "lng": -132.1968085298
            },
            {
                "lat": 54.0505642122,
                "lng": -132.1962277636
            },
            {
                "lat": 54.0508154509,
                "lng": -132.1954950858
            },
            {
                "lat": 54.0510670178,
                "lng": -132.1947778177
            },
            {
                "lat": 54.0512778087,
                "lng": -132.194000639
            },
            {
                "lat": 54.0513950094,
                "lng": -132.1932833811
            },
            {
                "lat": 54.0514673294,
                "lng": -132.1923673003
            },
            {
                "lat": 54.0515137738,
                "lng": -132.1915280109
            },
            {
                "lat": 54.0515208867,
                "lng": -132.1909950132
            },
            {
                "lat": 54.0514035396,
                "lng": -132.1901705772
            },
            {
                "lat": 54.0510096908,
                "lng": -132.1892240489
            },
            {
                "lat": 54.0497128948,
                "lng": -132.1885217337
            },
            {
                "lat": 54.0472529184,
                "lng": -132.1880189113
            },
            {
                "lat": 54.044478738,
                "lng": -132.1866604728
            },
            {
                "lat": 54.0372694755,
                "lng": -132.1690209791
            },
            {
                "lat": 54.0353738441,
                "lng": -132.1564934792
            },
            {
                "lat": 54.0291097124,
                "lng": -132.1415095979
            },
            {
                "lat": 54.0243683415,
                "lng": -132.1291806963
            },
            {
                "lat": 54.0186767058,
                "lng": -132.1164704838
            },
            {
                "lat": 54.0199469218,
                "lng": -132.105071653
            },
            {
                "lat": 54.009357435,
                "lng": -132.0196836928
            },
            {
                "lat": 54.0117381255,
                "lng": -131.9542383179
            },
            {
                "lat": 54.0200652646,
                "lng": -131.8870234655
            },
            {
                "lat": 54.0474278331,
                "lng": -131.8120735485
            },
            {
                "lat": 54.0706290829,
                "lng": -131.753174298
            },
            {
                "lat": 54.0950159761,
                "lng": -131.7026218268
            },
            {
                "lat": 54.1305807907,
                "lng": -131.676147637
            },
            {
                "lat": 54.1500013545,
                "lng": -131.6666712768
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 65,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 1,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 1-5",
            "LABEL": "1-5",
            "FISHERY_AREA_ID": 1889,
            "OBJECTID": 12,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 414545075.3794,
            "FEATURE_LENGTH_M": 99882.9303,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.05799786554374,
            "lng": -132.1271603531625
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.2025434904,
                "lng": -130.3085685308
            },
            {
                "lat": 54.2025257071,
                "lng": -130.3085637781
            },
            {
                "lat": 54.2016220158,
                "lng": -130.3023674868
            },
            {
                "lat": 54.2016523951,
                "lng": -130.3015903767
            },
            {
                "lat": 54.2016023521,
                "lng": -130.3006287991
            },
            {
                "lat": 54.200919919,
                "lng": -130.2978511842
            },
            {
                "lat": 54.2002638379,
                "lng": -130.2951514717
            },
            {
                "lat": 54.2012300461,
                "lng": -130.2921522018
            },
            {
                "lat": 54.2002824821,
                "lng": -130.2951476415
            },
            {
                "lat": 54.2009385632,
                "lng": -130.2978473552
            },
            {
                "lat": 54.2016209964,
                "lng": -130.3006249713
            },
            {
                "lat": 54.2016710395,
                "lng": -130.3015865493
            },
            {
                "lat": 54.2016406349,
                "lng": -130.3023646269
            },
            {
                "lat": 54.2025434904,
                "lng": -130.3085685308
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 66,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 13,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2018.303,
            "FEATURE_LENGTH_M": 2278.7302,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.20150406928572,
            "lng": -130.30092953601428
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.1678480829,
                "lng": -130.1286593863
            },
            {
                "lat": 54.1485015596,
                "lng": -130.1294704579
            },
            {
                "lat": 54.1484830421,
                "lng": -130.1294705272
            },
            {
                "lat": 54.1366415145,
                "lng": -130.1295619342
            },
            {
                "lat": 54.1366238868,
                "lng": -130.1295688081
            },
            {
                "lat": 54.0893471217,
                "lng": -130.1467369605
            },
            {
                "lat": 54.0893309445,
                "lng": -130.1467506882
            },
            {
                "lat": 54.0689893616,
                "lng": -130.1645670372
            },
            {
                "lat": 54.0689742725,
                "lng": -130.164565518
            },
            {
                "lat": 54.0585359636,
                "lng": -130.1636375564
            },
            {
                "lat": 54.0585174219,
                "lng": -130.1636385589
            },
            {
                "lat": 54.0416558802,
                "lng": -130.1647023414
            },
            {
                "lat": 54.0416417407,
                "lng": -130.1646894191
            },
            {
                "lat": 54.0345186461,
                "lng": -130.1580467711
            },
            {
                "lat": 54.0357735334,
                "lng": -130.1467553924
            },
            {
                "lat": 54.0356023058,
                "lng": -130.1398424443
            },
            {
                "lat": 54.0356021012,
                "lng": -130.091732053
            },
            {
                "lat": 54.0376423404,
                "lng": -130.068413164
            },
            {
                "lat": 54.0817374965,
                "lng": -130.0496981189
            },
            {
                "lat": 54.1237987366,
                "lng": -130.0078880944
            },
            {
                "lat": 54.143341072,
                "lng": -130.0102843791
            },
            {
                "lat": 54.1691900889,
                "lng": -130.0295406144
            },
            {
                "lat": 54.1972929372,
                "lng": -130.0325925575
            },
            {
                "lat": 54.1928295356,
                "lng": -130.0566857253
            },
            {
                "lat": 54.1963994885,
                "lng": -130.0894013314
            },
            {
                "lat": 54.1731982766,
                "lng": -130.1215214608
            },
            {
                "lat": 54.1678480829,
                "lng": -130.1286593863
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 67,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 15,
            "MANAGEMENT_AREA_NAME": "Subarea 4-15",
            "LABEL": "4-15",
            "FISHERY_AREA_ID": 2407,
            "OBJECTID": 14,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 121691236.5342,
            "FEATURE_LENGTH_M": 48154.2588,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.10666168275184,
            "lng": -130.1132252106037
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.0186767058,
                "lng": -132.1164704838
            },
            {
                "lat": 54.0243683415,
                "lng": -132.1291806963
            },
            {
                "lat": 54.0291097124,
                "lng": -132.1415095979
            },
            {
                "lat": 54.0353738441,
                "lng": -132.1564934792
            },
            {
                "lat": 54.0372694755,
                "lng": -132.1690209791
            },
            {
                "lat": 54.044478738,
                "lng": -132.1866604728
            },
            {
                "lat": 54.0472529184,
                "lng": -132.1880189113
            },
            {
                "lat": 54.0497128948,
                "lng": -132.1885217337
            },
            {
                "lat": 54.0510096908,
                "lng": -132.1892240489
            },
            {
                "lat": 54.0514035396,
                "lng": -132.1901705772
            },
            {
                "lat": 54.0515208867,
                "lng": -132.1909950132
            },
            {
                "lat": 54.0515137738,
                "lng": -132.1915280109
            },
            {
                "lat": 54.0514673294,
                "lng": -132.1923673003
            },
            {
                "lat": 54.0513950094,
                "lng": -132.1932833811
            },
            {
                "lat": 54.0512778087,
                "lng": -132.194000639
            },
            {
                "lat": 54.0510670178,
                "lng": -132.1947778177
            },
            {
                "lat": 54.0508154509,
                "lng": -132.1954950858
            },
            {
                "lat": 54.0505642122,
                "lng": -132.1962277636
            },
            {
                "lat": 54.0502544383,
                "lng": -132.1968085298
            },
            {
                "lat": 54.049885145,
                "lng": -132.19731122
            },
            {
                "lat": 54.0494115053,
                "lng": -132.198028758
            },
            {
                "lat": 54.0490527404,
                "lng": -132.1985781428
            },
            {
                "lat": 54.0485953519,
                "lng": -132.1987602334
            },
            {
                "lat": 54.0480343051,
                "lng": -132.1988374397
            },
            {
                "lat": 54.047290117,
                "lng": -132.1987152444
            },
            {
                "lat": 54.046817729,
                "lng": -132.1985329803
            },
            {
                "lat": 54.0463601967,
                "lng": -132.1981051564
            },
            {
                "lat": 54.0459292876,
                "lng": -132.1978157939
            },
            {
                "lat": 54.0457080771,
                "lng": -132.1976462413
            },
            {
                "lat": 54.0453568048,
                "lng": -132.1976012491
            },
            {
                "lat": 54.0764316742,
                "lng": -132.2431793332
            },
            {
                "lat": 54.0649646486,
                "lng": -132.2536769015
            },
            {
                "lat": 54.0477445163,
                "lng": -132.2655943531
            },
            {
                "lat": 54.0285300159,
                "lng": -132.270675323
            },
            {
                "lat": 54.0097578623,
                "lng": -132.2503665157
            },
            {
                "lat": 53.9940755888,
                "lng": -132.2172388906
            },
            {
                "lat": 53.9593348686,
                "lng": -132.1820529359
            },
            {
                "lat": 53.9123151553,
                "lng": -132.1676943613
            },
            {
                "lat": 53.8652958395,
                "lng": -132.1637722068
            },
            {
                "lat": 53.8365637584,
                "lng": -132.2264710864
            },
            {
                "lat": 53.7960744775,
                "lng": -132.3361817043
            },
            {
                "lat": 53.7568889795,
                "lng": -132.4759208921
            },
            {
                "lat": 53.7281575579,
                "lng": -132.5764922672
            },
            {
                "lat": 53.7124820212,
                "lng": -132.648330736
            },
            {
                "lat": 53.6955028768,
                "lng": -132.6953427007
            },
            {
                "lat": 53.6563220811,
                "lng": -132.7175594347
            },
            {
                "lat": 53.6001588222,
                "lng": -132.6378782002
            },
            {
                "lat": 53.5897104074,
                "lng": -132.5673526599
            },
            {
                "lat": 53.5570562328,
                "lng": -132.5399323525
            },
            {
                "lat": 53.5400769845,
                "lng": -132.4785459231
            },
            {
                "lat": 53.5662007252,
                "lng": -132.3884127198
            },
            {
                "lat": 53.5975452398,
                "lng": -132.2630312058
            },
            {
                "lat": 53.6288946995,
                "lng": -132.159850874
            },
            {
                "lat": 53.6915857571,
                "lng": -132.0984650021
            },
            {
                "lat": 53.8039090781,
                "lng": -132.0527492141
            },
            {
                "lat": 53.892723492,
                "lng": -132.0540618504
            },
            {
                "lat": 53.9763144408,
                "lng": -132.0880125766
            },
            {
                "lat": 54.006351992,
                "lng": -132.1089168878
            },
            {
                "lat": 54.0186767058,
                "lng": -132.1164704838
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 68,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 1,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 1-6",
            "LABEL": "1-6",
            "FISHERY_AREA_ID": 1890,
            "OBJECTID": 15,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1036865903.9217,
            "FEATURE_LENGTH_M": 172898.0809,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.93187490757798,
            "lng": -132.2512697724322
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.0440249354,
                "lng": -132.5427850737
            },
            {
                "lat": 54.0501704261,
                "lng": -132.5455168823
            },
            {
                "lat": 54.0471646822,
                "lng": -132.5694272061
            },
            {
                "lat": 54.0510294522,
                "lng": -132.5713804634
            },
            {
                "lat": 54.0514869378,
                "lng": -132.5731513539
            },
            {
                "lat": 54.0526546304,
                "lng": -132.5776368675
            },
            {
                "lat": 54.0516393895,
                "lng": -132.5865636509
            },
            {
                "lat": 54.0397418951,
                "lng": -132.5994111189
            },
            {
                "lat": 54.0297467351,
                "lng": -132.6184546054
            },
            {
                "lat": 54.0230861961,
                "lng": -132.6396335682
            },
            {
                "lat": 54.0145181048,
                "lng": -132.6510471434
            },
            {
                "lat": 53.9981000356,
                "lng": -132.6743624515
            },
            {
                "lat": 53.9881061509,
                "lng": -132.6962576726
            },
            {
                "lat": 53.9738310272,
                "lng": -132.7050622207
            },
            {
                "lat": 53.944324772,
                "lng": -132.7031553516
            },
            {
                "lat": 53.939567686,
                "lng": -132.6788937035
            },
            {
                "lat": 53.9332080447,
                "lng": -132.650268562
            },
            {
                "lat": 53.9329264648,
                "lng": -132.6470799166
            },
            {
                "lat": 53.9436106376,
                "lng": -132.6201163514
            },
            {
                "lat": 53.9633593783,
                "lng": -132.5803827729
            },
            {
                "lat": 53.9823945561,
                "lng": -132.5544433734
            },
            {
                "lat": 54.0168991429,
                "lng": -132.5401613563
            },
            {
                "lat": 54.0440249354,
                "lng": -132.5427850737
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 69,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 1,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 1-4",
            "LABEL": "1-4",
            "FISHERY_AREA_ID": 1888,
            "OBJECTID": 16,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 86521867.7199,
            "FEATURE_LENGTH_M": 38157.6252,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.0050267920087,
            "lng": -132.61165116260435
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.1017737662,
                "lng": -130.4146388772
            },
            {
                "lat": 54.0981594086,
                "lng": -130.4195682842
            },
            {
                "lat": 54.0981437597,
                "lng": -130.4195666108
            },
            {
                "lat": 54.1017737662,
                "lng": -130.4146388772
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 70,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 17,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 302.8594,
            "FEATURE_LENGTH_M": 1034.8571,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.09996267517499,
            "lng": -130.41710316235
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.0981437597,
                "lng": -130.4195666108
            },
            {
                "lat": 54.092025113,
                "lng": -130.4253230747
            },
            {
                "lat": 54.089332389,
                "lng": -130.4325410014
            },
            {
                "lat": 54.0812605239,
                "lng": -130.437316192
            },
            {
                "lat": 54.0758784948,
                "lng": -130.4367069963
            },
            {
                "lat": 54.0698817302,
                "lng": -130.4269102268
            },
            {
                "lat": 54.0591156203,
                "lng": -130.4231268201
            },
            {
                "lat": 54.0507967883,
                "lng": -130.4255681583
            },
            {
                "lat": 54.0489618482,
                "lng": -130.4348600861
            },
            {
                "lat": 54.0489620499,
                "lng": -130.44441163
            },
            {
                "lat": 54.0552021916,
                "lng": -130.4519953182
            },
            {
                "lat": 54.0654792516,
                "lng": -130.4581137615
            },
            {
                "lat": 54.0690271599,
                "lng": -130.4652095466
            },
            {
                "lat": 54.0658455238,
                "lng": -130.4738922944
            },
            {
                "lat": 54.0588722806,
                "lng": -130.49848943
            },
            {
                "lat": 54.0563008157,
                "lng": -130.5103450144
            },
            {
                "lat": 54.0530016388,
                "lng": -130.5147546883
            },
            {
                "lat": 54.0451690301,
                "lng": -130.518050213
            },
            {
                "lat": 54.0410123392,
                "lng": -130.5236815101
            },
            {
                "lat": 54.0403973528,
                "lng": -130.5315091311
            },
            {
                "lat": 54.0434649943,
                "lng": -130.5442961056
            },
            {
                "lat": 54.0358502082,
                "lng": -130.5416714925
            },
            {
                "lat": 54.0270458145,
                "lng": -130.5480958473
            },
            {
                "lat": 54.0213355523,
                "lng": -130.5642852748
            },
            {
                "lat": 54.0215720997,
                "lng": -130.5768893708
            },
            {
                "lat": 54.0253785997,
                "lng": -130.5935521187
            },
            {
                "lat": 54.027156129,
                "lng": -130.5981289381
            },
            {
                "lat": 54.0167277226,
                "lng": -130.601089704
            },
            {
                "lat": 54.0040432626,
                "lng": -130.6161654648
            },
            {
                "lat": 53.9989700481,
                "lng": -130.6250459187
            },
            {
                "lat": 53.9893841559,
                "lng": -130.6275942209
            },
            {
                "lat": 53.9796598619,
                "lng": -130.6309663557
            },
            {
                "lat": 53.9702185446,
                "lng": -130.636184095
            },
            {
                "lat": 53.9675364016,
                "lng": -130.6450655351
            },
            {
                "lat": 53.9679603435,
                "lng": -130.6512599266
            },
            {
                "lat": 53.9735981179,
                "lng": -130.6535187663
            },
            {
                "lat": 53.9796599664,
                "lng": -130.6487276131
            },
            {
                "lat": 53.9848745184,
                "lng": -130.649155606
            },
            {
                "lat": 53.9854390449,
                "lng": -130.6560520441
            },
            {
                "lat": 53.9867054238,
                "lng": -130.6598661669
            },
            {
                "lat": 53.9913564658,
                "lng": -130.6621253593
            },
            {
                "lat": 53.9931911707,
                "lng": -130.6650696165
            },
            {
                "lat": 53.9958684612,
                "lng": -130.6649315518
            },
            {
                "lat": 53.9985461403,
                "lng": -130.6660605848
            },
            {
                "lat": 53.999896752,
                "lng": -130.6688082713
            },
            {
                "lat": 54.0004878763,
                "lng": -130.6693260074
            },
            {
                "lat": 54.0008964947,
                "lng": -130.6695712912
            },
            {
                "lat": 54.0009685601,
                "lng": -130.6697537336
            },
            {
                "lat": 54.000967657,
                "lng": -130.6698206772
            },
            {
                "lat": 54.0008967003,
                "lng": -130.6696383596
            },
            {
                "lat": 54.0004880538,
                "lng": -130.6693940374
            },
            {
                "lat": 53.9998963671,
                "lng": -130.6688762217
            },
            {
                "lat": 53.9985463112,
                "lng": -130.6661285954
            },
            {
                "lat": 53.9958686417,
                "lng": -130.664999559
            },
            {
                "lat": 53.9931908245,
                "lng": -130.66513661
            },
            {
                "lat": 53.9913561212,
                "lng": -130.6621923499
            },
            {
                "lat": 53.9867056066,
                "lng": -130.6599341743
            },
            {
                "lat": 53.9805064688,
                "lng": -130.6604988367
            },
            {
                "lat": 53.9675364552,
                "lng": -130.6673954198
            },
            {
                "lat": 53.9606632899,
                "lng": -130.673300585
            },
            {
                "lat": 53.9556659434,
                "lng": -130.6794949087
            },
            {
                "lat": 53.9480507145,
                "lng": -130.6885286642
            },
            {
                "lat": 53.9444802877,
                "lng": -130.6920993607
            },
            {
                "lat": 53.9425971871,
                "lng": -130.6973018646
            },
            {
                "lat": 53.9373432894,
                "lng": -130.7111431319
            },
            {
                "lat": 53.9287758544,
                "lng": -130.7137512091
            },
            {
                "lat": 53.9125979119,
                "lng": -130.7199465419
            },
            {
                "lat": 53.9090274704,
                "lng": -130.7125619508
            },
            {
                "lat": 53.9048575942,
                "lng": -130.7095868271
            },
            {
                "lat": 53.9007000526,
                "lng": -130.7066108331
            },
            {
                "lat": 53.8921319096,
                "lng": -130.7063811164
            },
            {
                "lat": 53.8815463171,
                "lng": -130.708624854
            },
            {
                "lat": 53.8697660868,
                "lng": -130.7130350048
            },
            {
                "lat": 53.8660817348,
                "lng": -130.7079829172
            },
            {
                "lat": 53.8543016902,
                "lng": -130.6957918982
            },
            {
                "lat": 53.8513530272,
                "lng": -130.6978982012
            },
            {
                "lat": 53.848064437,
                "lng": -130.7023229075
            },
            {
                "lat": 53.8477056293,
                "lng": -130.6990262754
            },
            {
                "lat": 53.8489337484,
                "lng": -130.6950748804
            },
            {
                "lat": 53.8527869845,
                "lng": -130.6630321659
            },
            {
                "lat": 53.8495403361,
                "lng": -130.6539056518
            },
            {
                "lat": 53.8516814041,
                "lng": -130.639868464
            },
            {
                "lat": 53.8556903229,
                "lng": -130.6389072153
            },
            {
                "lat": 53.8576313019,
                "lng": -130.6384499811
            },
            {
                "lat": 53.8588219181,
                "lng": -130.6339172965
            },
            {
                "lat": 53.8572770756,
                "lng": -130.6256923755
            },
            {
                "lat": 53.8583224344,
                "lng": -130.6170104385
            },
            {
                "lat": 53.8583211181,
                "lng": -130.6169425331
            },
            {
                "lat": 53.8633423992,
                "lng": -130.6289830107
            },
            {
                "lat": 53.8683391369,
                "lng": -130.6425322515
            },
            {
                "lat": 53.8735728762,
                "lng": -130.6587220156
            },
            {
                "lat": 53.8799967006,
                "lng": -130.6770479224
            },
            {
                "lat": 53.8857084439,
                "lng": -130.694656479
            },
            {
                "lat": 53.88975464,
                "lng": -130.7020260807
            },
            {
                "lat": 53.8933220287,
                "lng": -130.7029726297
            },
            {
                "lat": 53.8985557222,
                "lng": -130.7029730188
            },
            {
                "lat": 53.9027865616,
                "lng": -130.7041479075
            },
            {
                "lat": 53.909801584,
                "lng": -130.7071075627
            },
            {
                "lat": 53.9157443378,
                "lng": -130.707260587
            },
            {
                "lat": 53.9188456532,
                "lng": -130.7036748537
            },
            {
                "lat": 53.9222148002,
                "lng": -130.6947022434
            },
            {
                "lat": 53.9226721224,
                "lng": -130.6901393243
            },
            {
                "lat": 53.9261590148,
                "lng": -130.6925048904
            },
            {
                "lat": 53.9311557605,
                "lng": -130.6925049069
            },
            {
                "lat": 53.9340134363,
                "lng": -130.6863255214
            },
            {
                "lat": 53.9409102704,
                "lng": -130.6903689651
            },
            {
                "lat": 53.9444806489,
                "lng": -130.6920324459
            },
            {
                "lat": 53.9480510737,
                "lng": -130.6884617436
            },
            {
                "lat": 53.9556657433,
                "lng": -130.6794279133
            },
            {
                "lat": 53.9606636407,
                "lng": -130.673233644
            },
            {
                "lat": 53.9612163775,
                "lng": -130.6678771537
            },
            {
                "lat": 53.9582893956,
                "lng": -130.6603850212
            },
            {
                "lat": 53.9452440274,
                "lng": -130.645934871
            },
            {
                "lat": 53.9420277201,
                "lng": -130.6398620006
            },
            {
                "lat": 53.9469799392,
                "lng": -130.6371925327
            },
            {
                "lat": 53.9524535937,
                "lng": -130.6445614017
            },
            {
                "lat": 53.95887731,
                "lng": -130.6495664598
            },
            {
                "lat": 53.9624445896,
                "lng": -130.6478881643
            },
            {
                "lat": 53.9633981844,
                "lng": -130.6424249458
            },
            {
                "lat": 53.9610174577,
                "lng": -130.6298065226
            },
            {
                "lat": 53.9562607512,
                "lng": -130.6238556067
            },
            {
                "lat": 53.9546972901,
                "lng": -130.6209719517
            },
            {
                "lat": 53.9531676762,
                "lng": -130.6086273041
            },
            {
                "lat": 53.9476922802,
                "lng": -130.6026911616
            },
            {
                "lat": 53.9417459437,
                "lng": -130.598633406
            },
            {
                "lat": 53.9383463282,
                "lng": -130.5903471691
            },
            {
                "lat": 53.9357948586,
                "lng": -130.5841219799
            },
            {
                "lat": 53.9319881383,
                "lng": -130.5719908481
            },
            {
                "lat": 53.9253276458,
                "lng": -130.5760349648
            },
            {
                "lat": 53.9231834462,
                "lng": -130.5791327804
            },
            {
                "lat": 53.9224697712,
                "lng": -130.5730595978
            },
            {
                "lat": 53.9110484547,
                "lng": -130.5630650863
            },
            {
                "lat": 53.9065280806,
                "lng": -130.5575862014
            },
            {
                "lat": 53.901054229,
                "lng": -130.5540313428
            },
            {
                "lat": 53.9013099535,
                "lng": -130.548233411
            },
            {
                "lat": 53.9078362506,
                "lng": -130.5316618229
            },
            {
                "lat": 53.9023663337,
                "lng": -130.5152435813
            },
            {
                "lat": 53.8985560282,
                "lng": -130.4985817245
            },
            {
                "lat": 53.901889707,
                "lng": -130.4831088564
            },
            {
                "lat": 53.9204490877,
                "lng": -130.4714507401
            },
            {
                "lat": 53.9349633809,
                "lng": -130.4640801801
            },
            {
                "lat": 53.9518586449,
                "lng": -130.4666437625
            },
            {
                "lat": 53.9585188742,
                "lng": -130.4676510014
            },
            {
                "lat": 53.9751782109,
                "lng": -130.4647973438
            },
            {
                "lat": 53.9877891691,
                "lng": -130.4500427846
            },
            {
                "lat": 53.9925459565,
                "lng": -130.4345698927
            },
            {
                "lat": 53.9913563736,
                "lng": -130.4238742738
            },
            {
                "lat": 53.9869536361,
                "lng": -130.4100027993
            },
            {
                "lat": 54.0002632436,
                "lng": -130.3885190637
            },
            {
                "lat": 54.0163427209,
                "lng": -130.4098353458
            },
            {
                "lat": 54.0341878265,
                "lng": -130.4236294699
            },
            {
                "lat": 54.0403752108,
                "lng": -130.4157717965
            },
            {
                "lat": 54.0401344488,
                "lng": -130.4026946275
            },
            {
                "lat": 54.0270502247,
                "lng": -130.383895466
            },
            {
                "lat": 54.0128779421,
                "lng": -130.3681631717
            },
            {
                "lat": 54.0119475989,
                "lng": -130.3642731963
            },
            {
                "lat": 54.0087277029,
                "lng": -130.3477171937
            },
            {
                "lat": 53.9975440205,
                "lng": -130.3360597628
            },
            {
                "lat": 53.969940272,
                "lng": -130.3327336833
            },
            {
                "lat": 53.9601861324,
                "lng": -130.3324893951
            },
            {
                "lat": 53.9554249355,
                "lng": -130.3372496492
            },
            {
                "lat": 53.9520953983,
                "lng": -130.3534392933
            },
            {
                "lat": 53.9544757466,
                "lng": -130.376282551
            },
            {
                "lat": 53.9635164843,
                "lng": -130.4055479404
            },
            {
                "lat": 53.9646493999,
                "lng": -130.4149169996
            },
            {
                "lat": 53.9521184156,
                "lng": -130.4283296676
            },
            {
                "lat": 53.940197735,
                "lng": -130.4281463742
            },
            {
                "lat": 53.921875126,
                "lng": -130.4357597264
            },
            {
                "lat": 53.8857084269,
                "lng": -130.4588474316
            },
            {
                "lat": 53.8747641298,
                "lng": -130.4716948464
            },
            {
                "lat": 53.8673853641,
                "lng": -130.4907384323
            },
            {
                "lat": 53.8704754093,
                "lng": -130.5038301219
            },
            {
                "lat": 53.8659057201,
                "lng": -130.4971924982
            },
            {
                "lat": 53.8554345576,
                "lng": -130.4845882614
            },
            {
                "lat": 53.8468662864,
                "lng": -130.480301023
            },
            {
                "lat": 53.8444902599,
                "lng": -130.4838706779
            },
            {
                "lat": 53.8456802466,
                "lng": -130.4686425956
            },
            {
                "lat": 53.8494869355,
                "lng": -130.4577033306
            },
            {
                "lat": 53.8680455456,
                "lng": -130.4484245493
            },
            {
                "lat": 53.8723296073,
                "lng": -130.4346161539
            },
            {
                "lat": 53.862335512,
                "lng": -130.4253383175
            },
            {
                "lat": 53.8406834925,
                "lng": -130.4067840114
            },
            {
                "lat": 53.8317264969,
                "lng": -130.3987570085
            },
            {
                "lat": 53.8305621876,
                "lng": -130.3787239983
            },
            {
                "lat": 53.8337826149,
                "lng": -130.3715660257
            },
            {
                "lat": 53.8378251713,
                "lng": -130.3599084556
            },
            {
                "lat": 53.8360409036,
                "lng": -130.3534849898
            },
            {
                "lat": 53.8457873105,
                "lng": -130.3582457745
            },
            {
                "lat": 53.8591122096,
                "lng": -130.354446769
            },
            {
                "lat": 53.8743402887,
                "lng": -130.3456420427
            },
            {
                "lat": 53.890285774,
                "lng": -130.3394469769
            },
            {
                "lat": 53.8959956494,
                "lng": -130.3227994942
            },
            {
                "lat": 53.9019437317,
                "lng": -130.3044740521
            },
            {
                "lat": 53.9143184008,
                "lng": -130.28710903
            },
            {
                "lat": 53.9276431335,
                "lng": -130.2756809478
            },
            {
                "lat": 53.9363944714,
                "lng": -130.2694856071
            },
            {
                "lat": 53.9443511107,
                "lng": -130.261779469
            },
            {
                "lat": 53.9379495725,
                "lng": -130.2488404325
            },
            {
                "lat": 53.9367448061,
                "lng": -130.2460786485
            },
            {
                "lat": 53.932926033,
                "lng": -130.2421110966
            },
            {
                "lat": 53.9369621895,
                "lng": -130.1632989513
            },
            {
                "lat": 53.9384198422,
                "lng": -130.1026003661
            },
            {
                "lat": 53.9384375254,
                "lng": -130.1025916073
            },
            {
                "lat": 53.9369808066,
                "lng": -130.1632960473
            },
            {
                "lat": 53.9329446521,
                "lng": -130.2421082282
            },
            {
                "lat": 53.936763459,
                "lng": -130.2460748215
            },
            {
                "lat": 53.9379682166,
                "lng": -130.2488366057
            },
            {
                "lat": 53.9443697307,
                "lng": -130.2617765934
            },
            {
                "lat": 53.9465296334,
                "lng": -130.2645371688
            },
            {
                "lat": 53.9477006608,
                "lng": -130.2679871027
            },
            {
                "lat": 53.949481549,
                "lng": -130.2727317074
            },
            {
                "lat": 53.9531170875,
                "lng": -130.2757830224
            },
            {
                "lat": 53.9568284202,
                "lng": -130.2750360935
            },
            {
                "lat": 53.9632872586,
                "lng": -130.2710232898
            },
            {
                "lat": 53.9707867381,
                "lng": -130.2679104708
            },
            {
                "lat": 53.97865326,
                "lng": -130.2682151325
            },
            {
                "lat": 53.9830013152,
                "lng": -130.2703826637
            },
            {
                "lat": 53.9962571795,
                "lng": -130.2872129021
            },
            {
                "lat": 54.0045848044,
                "lng": -130.306836111
            },
            {
                "lat": 54.0212433998,
                "lng": -130.3092153537
            },
            {
                "lat": 54.042655812,
                "lng": -130.3330196432
            },
            {
                "lat": 54.06228584,
                "lng": -130.3651393243
            },
            {
                "lat": 54.079005148,
                "lng": -130.3913532551
            },
            {
                "lat": 54.0817676677,
                "lng": -130.4037900914
            },
            {
                "lat": 54.0825000783,
                "lng": -130.4116175875
            },
            {
                "lat": 54.0887410283,
                "lng": -130.4187129509
            },
            {
                "lat": 54.0981437597,
                "lng": -130.4195666108
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 71,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 18,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 361158905.7193,
            "FEATURE_LENGTH_M": 224560.1517,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.952521522514104,
            "lng": -130.5192796973225
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.0434649943,
                "lng": -130.5442961056
            },
            {
                "lat": 54.0563927203,
                "lng": -130.5519562394
            },
            {
                "lat": 54.056709661,
                "lng": -130.5520783742
            },
            {
                "lat": 54.057064724,
                "lng": -130.5522162292
            },
            {
                "lat": 54.0571330348,
                "lng": -130.5523838896
            },
            {
                "lat": 54.0671997163,
                "lng": -130.569549467
            },
            {
                "lat": 54.0596199572,
                "lng": -130.5849452505
            },
            {
                "lat": 54.0593906932,
                "lng": -130.5856631226
            },
            {
                "lat": 54.0592382584,
                "lng": -130.5864710189
            },
            {
                "lat": 54.0592995062,
                "lng": -130.5873409703
            },
            {
                "lat": 54.0587468287,
                "lng": -130.5914458175
            },
            {
                "lat": 54.0612984182,
                "lng": -130.5950783075
            },
            {
                "lat": 54.0629123337,
                "lng": -130.5990904158
            },
            {
                "lat": 54.0633277335,
                "lng": -130.6045827937
            },
            {
                "lat": 54.063506928,
                "lng": -130.6098322049
            },
            {
                "lat": 54.0614162425,
                "lng": -130.6164100263
            },
            {
                "lat": 54.0577166693,
                "lng": -130.6216277793
            },
            {
                "lat": 54.0563239205,
                "lng": -130.6230622429
            },
            {
                "lat": 54.054935253,
                "lng": -130.6239935862
            },
            {
                "lat": 54.0546034506,
                "lng": -130.6244810734
            },
            {
                "lat": 54.0544010569,
                "lng": -130.6247106536
            },
            {
                "lat": 54.0542264461,
                "lng": -130.6251524114
            },
            {
                "lat": 54.0541497979,
                "lng": -130.6257326564
            },
            {
                "lat": 54.0342333262,
                "lng": -130.6005101315
            },
            {
                "lat": 54.0318073352,
                "lng": -130.5988315784
            },
            {
                "lat": 54.027156129,
                "lng": -130.5981289381
            },
            {
                "lat": 54.0253785997,
                "lng": -130.5935521187
            },
            {
                "lat": 54.0215720997,
                "lng": -130.5768893708
            },
            {
                "lat": 54.0213355523,
                "lng": -130.5642852748
            },
            {
                "lat": 54.0270458145,
                "lng": -130.5480958473
            },
            {
                "lat": 54.0358502082,
                "lng": -130.5416714925
            },
            {
                "lat": 54.0434649943,
                "lng": -130.5442961056
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 72,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea  4-4",
            "LABEL": "4-4",
            "FISHERY_AREA_ID": 2397,
            "OBJECTID": 19,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 18003235.8782,
            "FEATURE_LENGTH_M": 16978.1363,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.05002882511562,
            "lng": -130.58807379668437
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.6561629841,
                "lng": -129.0770566456
            },
            {
                "lat": 53.6615410413,
                "lng": -129.0578760343
            },
            {
                "lat": 53.677166707,
                "lng": -129.0021202883
            },
            {
                "lat": 53.665245435,
                "lng": -128.9385531333
            },
            {
                "lat": 53.6334574475,
                "lng": -128.9181055294
            },
            {
                "lat": 53.5916103511,
                "lng": -128.9227447859
            },
            {
                "lat": 53.5729896665,
                "lng": -128.9035188943
            },
            {
                "lat": 53.5620341056,
                "lng": -128.8937226255
            },
            {
                "lat": 53.5600050097,
                "lng": -128.8901052653
            },
            {
                "lat": 53.5691192096,
                "lng": -128.8167718163
            },
            {
                "lat": 53.5650514686,
                "lng": -128.7684480181
            },
            {
                "lat": 53.5636066323,
                "lng": -128.7400659049
            },
            {
                "lat": 53.6046980776,
                "lng": -128.7724455811
            },
            {
                "lat": 53.6439587272,
                "lng": -128.7962491195
            },
            {
                "lat": 53.6737066747,
                "lng": -128.7641139994
            },
            {
                "lat": 53.7498467103,
                "lng": -128.756988603
            },
            {
                "lat": 53.7605548403,
                "lng": -128.6855932523
            },
            {
                "lat": 53.7807807013,
                "lng": -128.4678801432
            },
            {
                "lat": 53.8450244824,
                "lng": -128.4405064186
            },
            {
                "lat": 53.9306865833,
                "lng": -128.4892893738
            },
            {
                "lat": 54.0544162984,
                "lng": -128.541640946
            },
            {
                "lat": 54.0984387416,
                "lng": -128.603500694
            },
            {
                "lat": 54.0639343195,
                "lng": -128.786727425
            },
            {
                "lat": 53.9842221438,
                "lng": -128.8985598119
            },
            {
                "lat": 53.9342583027,
                "lng": -129.046081013
            },
            {
                "lat": 53.840266691,
                "lng": -129.1543576054
            },
            {
                "lat": 53.7641219287,
                "lng": -129.1210333154
            },
            {
                "lat": 53.7517167378,
                "lng": -129.0679628803
            },
            {
                "lat": 53.7539597903,
                "lng": -129.0308684121
            },
            {
                "lat": 53.691631102,
                "lng": -129.0853420861
            },
            {
                "lat": 53.6930080833,
                "lng": -129.0828087501
            },
            {
                "lat": 53.694469166,
                "lng": -129.0792691982
            },
            {
                "lat": 53.6941909647,
                "lng": -129.0778347394
            },
            {
                "lat": 53.6926115185,
                "lng": -129.0769501679
            },
            {
                "lat": 53.6893573099,
                "lng": -129.0786739763
            },
            {
                "lat": 53.6866112689,
                "lng": -129.0798188107
            },
            {
                "lat": 53.6749031616,
                "lng": -129.0799553134
            },
            {
                "lat": 53.6561629841,
                "lng": -129.0770566456
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 73,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 6-1",
            "LABEL": "6-1",
            "FISHERY_AREA_ID": 2409,
            "OBJECTID": 20,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1522188565.5303,
            "FEATURE_LENGTH_M": 187713.9093,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.72856650968948,
            "lng": -128.8965946637605
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.8960877766,
                "lng": -130.5566563339
            },
            {
                "lat": 53.8705521519,
                "lng": -130.5156713285
            },
            {
                "lat": 53.8700678614,
                "lng": -130.5142363812
            },
            {
                "lat": 53.8702007574,
                "lng": -130.5128484367
            },
            {
                "lat": 53.8707734218,
                "lng": -130.5117640907
            },
            {
                "lat": 53.8704754093,
                "lng": -130.5038301219
            },
            {
                "lat": 53.8673853641,
                "lng": -130.4907384323
            },
            {
                "lat": 53.8747641298,
                "lng": -130.4716948464
            },
            {
                "lat": 53.8857084269,
                "lng": -130.4588474316
            },
            {
                "lat": 53.921875126,
                "lng": -130.4357597264
            },
            {
                "lat": 53.940197735,
                "lng": -130.4281463742
            },
            {
                "lat": 53.9521184156,
                "lng": -130.4283296676
            },
            {
                "lat": 53.9646493999,
                "lng": -130.4149169996
            },
            {
                "lat": 53.9635164843,
                "lng": -130.4055479404
            },
            {
                "lat": 53.9544757466,
                "lng": -130.376282551
            },
            {
                "lat": 53.9520953983,
                "lng": -130.3534392933
            },
            {
                "lat": 53.9554249355,
                "lng": -130.3372496492
            },
            {
                "lat": 53.9601861324,
                "lng": -130.3324893951
            },
            {
                "lat": 53.969940272,
                "lng": -130.3327336833
            },
            {
                "lat": 53.9975440205,
                "lng": -130.3360597628
            },
            {
                "lat": 54.0087277029,
                "lng": -130.3477171937
            },
            {
                "lat": 54.0119475989,
                "lng": -130.3642731963
            },
            {
                "lat": 54.0128779421,
                "lng": -130.3681631717
            },
            {
                "lat": 54.0270502247,
                "lng": -130.383895466
            },
            {
                "lat": 54.0401344488,
                "lng": -130.4026946275
            },
            {
                "lat": 54.0403752108,
                "lng": -130.4157717965
            },
            {
                "lat": 54.0341878265,
                "lng": -130.4236294699
            },
            {
                "lat": 54.0163427209,
                "lng": -130.4098353458
            },
            {
                "lat": 54.0002632436,
                "lng": -130.3885190637
            },
            {
                "lat": 53.9869536361,
                "lng": -130.4100027993
            },
            {
                "lat": 53.9913563736,
                "lng": -130.4238742738
            },
            {
                "lat": 53.9925459565,
                "lng": -130.4345698927
            },
            {
                "lat": 53.9877891691,
                "lng": -130.4500427846
            },
            {
                "lat": 53.9751782109,
                "lng": -130.4647973438
            },
            {
                "lat": 53.9585188742,
                "lng": -130.4676510014
            },
            {
                "lat": 53.9518586449,
                "lng": -130.4666437625
            },
            {
                "lat": 53.9349633809,
                "lng": -130.4640801801
            },
            {
                "lat": 53.9204490877,
                "lng": -130.4714507401
            },
            {
                "lat": 53.901889707,
                "lng": -130.4831088564
            },
            {
                "lat": 53.8985560282,
                "lng": -130.4985817245
            },
            {
                "lat": 53.9023663337,
                "lng": -130.5152435813
            },
            {
                "lat": 53.9078362506,
                "lng": -130.5316618229
            },
            {
                "lat": 53.9013099535,
                "lng": -130.548233411
            },
            {
                "lat": 53.901054229,
                "lng": -130.5540313428
            },
            {
                "lat": 53.8979456225,
                "lng": -130.5553278575
            },
            {
                "lat": 53.8966641481,
                "lng": -130.5561215639
            },
            {
                "lat": 53.8960877766,
                "lng": -130.5566563339
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 74,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 5-9",
            "LABEL": "5-9",
            "FISHERY_AREA_ID": 2013,
            "OBJECTID": 21,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 79292505.2154,
            "FEATURE_LENGTH_M": 61917.6458,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.94475040994894,
            "lng": -130.44837917127015
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.0376423404,
                "lng": -130.068413164
            },
            {
                "lat": 54.0356021012,
                "lng": -130.091732053
            },
            {
                "lat": 54.0356023058,
                "lng": -130.1398424443
            },
            {
                "lat": 54.0357735334,
                "lng": -130.1467553924
            },
            {
                "lat": 54.0345186461,
                "lng": -130.1580467711
            },
            {
                "lat": 54.0416417407,
                "lng": -130.1646894191
            },
            {
                "lat": 54.0345000055,
                "lng": -130.1580506476
            },
            {
                "lat": 54.0357548842,
                "lng": -130.1467592732
            },
            {
                "lat": 54.0355836568,
                "lng": -130.1398463283
            },
            {
                "lat": 54.0355834953,
                "lng": -130.0917349969
            },
            {
                "lat": 54.0376243859,
                "lng": -130.0684133157
            },
            {
                "lat": 54.0376423404,
                "lng": -130.068413164
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 75,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 22,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 12618.331,
            "FEATURE_LENGTH_M": 13590.0819,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.03645578630833,
            "lng": -130.12022474746666
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.8666648701,
                "lng": -130.8052588244
            },
            {
                "lat": 53.8666647774,
                "lng": -130.8051910289
            },
            {
                "lat": 53.9997251497,
                "lng": -130.8670814948
            },
            {
                "lat": 53.9997252545,
                "lng": -130.8671485631
            },
            {
                "lat": 53.8666648701,
                "lng": -130.8052588244
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 76,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 23,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 65549.5901,
            "FEATURE_LENGTH_M": 30796.0985,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.91988898436,
            "lng": -130.82998774712001
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.000967657,
                "lng": -130.6698206772
            },
            {
                "lat": 53.9997227477,
                "lng": -130.7922522906
            },
            {
                "lat": 53.9997217767,
                "lng": -130.7923192294
            },
            {
                "lat": 53.9997251497,
                "lng": -130.8670814948
            },
            {
                "lat": 53.8666647774,
                "lng": -130.8051910289
            },
            {
                "lat": 53.8474774042,
                "lng": -130.796264888
            },
            {
                "lat": 53.8474843213,
                "lng": -130.7072205585
            },
            {
                "lat": 53.848064437,
                "lng": -130.7023229075
            },
            {
                "lat": 53.8513530272,
                "lng": -130.6978982012
            },
            {
                "lat": 53.8543016902,
                "lng": -130.6957918982
            },
            {
                "lat": 53.8660817348,
                "lng": -130.7079829172
            },
            {
                "lat": 53.8697660868,
                "lng": -130.7130350048
            },
            {
                "lat": 53.8815463171,
                "lng": -130.708624854
            },
            {
                "lat": 53.8921319096,
                "lng": -130.7063811164
            },
            {
                "lat": 53.9007000526,
                "lng": -130.7066108331
            },
            {
                "lat": 53.9048575942,
                "lng": -130.7095868271
            },
            {
                "lat": 53.9090274704,
                "lng": -130.7125619508
            },
            {
                "lat": 53.9125979119,
                "lng": -130.7199465419
            },
            {
                "lat": 53.9287758544,
                "lng": -130.7137512091
            },
            {
                "lat": 53.9373432894,
                "lng": -130.7111431319
            },
            {
                "lat": 53.9425971871,
                "lng": -130.6973018646
            },
            {
                "lat": 53.9444802877,
                "lng": -130.6920993607
            },
            {
                "lat": 53.9480507145,
                "lng": -130.6885286642
            },
            {
                "lat": 53.9556659434,
                "lng": -130.6794949087
            },
            {
                "lat": 53.9606632899,
                "lng": -130.673300585
            },
            {
                "lat": 53.9675364552,
                "lng": -130.6673954198
            },
            {
                "lat": 53.9805064688,
                "lng": -130.6604988367
            },
            {
                "lat": 53.9867056066,
                "lng": -130.6599341743
            },
            {
                "lat": 53.9913561212,
                "lng": -130.6621923499
            },
            {
                "lat": 53.9931908245,
                "lng": -130.66513661
            },
            {
                "lat": 53.9958686417,
                "lng": -130.664999559
            },
            {
                "lat": 53.9985463112,
                "lng": -130.6661285954
            },
            {
                "lat": 53.9998963671,
                "lng": -130.6688762217
            },
            {
                "lat": 54.0004880538,
                "lng": -130.6693940374
            },
            {
                "lat": 54.0008967003,
                "lng": -130.6696383596
            },
            {
                "lat": 54.000967657,
                "lng": -130.6698206772
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 77,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea  4-3",
            "LABEL": "4-3",
            "FISHERY_AREA_ID": 2396,
            "OBJECTID": 24,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 153053872.2365,
            "FEATURE_LENGTH_M": 56063.7384,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.93849243998889,
            "lng": -130.70529243846667
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.9364045875,
                "lng": -130.6361539022
            },
            {
                "lat": 53.9239658911,
                "lng": -130.5843649425
            },
            {
                "lat": 53.9231834462,
                "lng": -130.5791327804
            },
            {
                "lat": 53.9253276458,
                "lng": -130.5760349648
            },
            {
                "lat": 53.9319881383,
                "lng": -130.5719908481
            },
            {
                "lat": 53.9357948586,
                "lng": -130.5841219799
            },
            {
                "lat": 53.9383463282,
                "lng": -130.5903471691
            },
            {
                "lat": 53.9417459437,
                "lng": -130.598633406
            },
            {
                "lat": 53.9476922802,
                "lng": -130.6026911616
            },
            {
                "lat": 53.9531676762,
                "lng": -130.6086273041
            },
            {
                "lat": 53.9546972901,
                "lng": -130.6209719517
            },
            {
                "lat": 53.9562607512,
                "lng": -130.6238556067
            },
            {
                "lat": 53.9610174577,
                "lng": -130.6298065226
            },
            {
                "lat": 53.9633981844,
                "lng": -130.6424249458
            },
            {
                "lat": 53.9624445896,
                "lng": -130.6478881643
            },
            {
                "lat": 53.95887731,
                "lng": -130.6495664598
            },
            {
                "lat": 53.9524535937,
                "lng": -130.6445614017
            },
            {
                "lat": 53.9469799392,
                "lng": -130.6371925327
            },
            {
                "lat": 53.9420277201,
                "lng": -130.6398620006
            },
            {
                "lat": 53.9364045875,
                "lng": -130.6361539022
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 78,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 5-6",
            "LABEL": "5-6",
            "FISHERY_AREA_ID": 2010,
            "OBJECTID": 25,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 9578046.6745,
            "FEATURE_LENGTH_M": 14836.6355,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.944608910964995,
            "lng": -130.61521909733997
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.9364045875,
                "lng": -130.6361539022
            },
            {
                "lat": 53.9420277201,
                "lng": -130.6398620006
            },
            {
                "lat": 53.9452440274,
                "lng": -130.645934871
            },
            {
                "lat": 53.9582893956,
                "lng": -130.6603850212
            },
            {
                "lat": 53.9612163775,
                "lng": -130.6678771537
            },
            {
                "lat": 53.9606636407,
                "lng": -130.673233644
            },
            {
                "lat": 53.9556657433,
                "lng": -130.6794279133
            },
            {
                "lat": 53.9480510737,
                "lng": -130.6884617436
            },
            {
                "lat": 53.9444806489,
                "lng": -130.6920324459
            },
            {
                "lat": 53.9409102704,
                "lng": -130.6903689651
            },
            {
                "lat": 53.9340134363,
                "lng": -130.6863255214
            },
            {
                "lat": 53.9311557605,
                "lng": -130.6925049069
            },
            {
                "lat": 53.9261590148,
                "lng": -130.6925048904
            },
            {
                "lat": 53.9226721224,
                "lng": -130.6901393243
            },
            {
                "lat": 53.9215851745,
                "lng": -130.6861419098
            },
            {
                "lat": 53.9193000675,
                "lng": -130.6870577686
            },
            {
                "lat": 53.9178736531,
                "lng": -130.6864311561
            },
            {
                "lat": 53.9171335015,
                "lng": -130.6855771486
            },
            {
                "lat": 53.9171332534,
                "lng": -130.6838527256
            },
            {
                "lat": 53.9174730491,
                "lng": -130.6821437248
            },
            {
                "lat": 53.9177168471,
                "lng": -130.6814887268
            },
            {
                "lat": 53.9176713504,
                "lng": -130.6807246245
            },
            {
                "lat": 53.9364045875,
                "lng": -130.6361539022
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 79,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 5-7",
            "LABEL": "5-7",
            "FISHERY_AREA_ID": 2011,
            "OBJECTID": 26,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 10685816.8788,
            "FEATURE_LENGTH_M": 13534.7902,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.93431501318261,
            "lng": -130.67586017350433
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.9364045875,
                "lng": -130.6361539022
            },
            {
                "lat": 53.9176713504,
                "lng": -130.6807246245
            },
            {
                "lat": 53.9177168471,
                "lng": -130.6814887268
            },
            {
                "lat": 53.9174730491,
                "lng": -130.6821437248
            },
            {
                "lat": 53.9171332534,
                "lng": -130.6838527256
            },
            {
                "lat": 53.9171335015,
                "lng": -130.6855771486
            },
            {
                "lat": 53.9178736531,
                "lng": -130.6864311561
            },
            {
                "lat": 53.9193000675,
                "lng": -130.6870577686
            },
            {
                "lat": 53.9215851745,
                "lng": -130.6861419098
            },
            {
                "lat": 53.9226721224,
                "lng": -130.6901393243
            },
            {
                "lat": 53.9222148002,
                "lng": -130.6947022434
            },
            {
                "lat": 53.9188456532,
                "lng": -130.7036748537
            },
            {
                "lat": 53.9157443378,
                "lng": -130.707260587
            },
            {
                "lat": 53.909801584,
                "lng": -130.7071075627
            },
            {
                "lat": 53.9027865616,
                "lng": -130.7041479075
            },
            {
                "lat": 53.8985557222,
                "lng": -130.7029730188
            },
            {
                "lat": 53.8993500032,
                "lng": -130.6997379144
            },
            {
                "lat": 53.8993449806,
                "lng": -130.6626281882
            },
            {
                "lat": 53.8992426516,
                "lng": -130.6578213523
            },
            {
                "lat": 53.8997872677,
                "lng": -130.6559447926
            },
            {
                "lat": 53.9033580257,
                "lng": -130.6516573879
            },
            {
                "lat": 53.9090695455,
                "lng": -130.6440433678
            },
            {
                "lat": 53.9117966805,
                "lng": -130.6360165785
            },
            {
                "lat": 53.9150155242,
                "lng": -130.6325835549
            },
            {
                "lat": 53.9176749501,
                "lng": -130.6336670465
            },
            {
                "lat": 53.9194373838,
                "lng": -130.6335302026
            },
            {
                "lat": 53.920608303,
                "lng": -130.6330720902
            },
            {
                "lat": 53.9364045875,
                "lng": -130.6361539022
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 80,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 5-8",
            "LABEL": "5-8",
            "FISHERY_AREA_ID": 2012,
            "OBJECTID": 27,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 12103614.8368,
            "FEATURE_LENGTH_M": 15960.9763,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.91442864888929,
            "lng": -130.6713011986607
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.9231834462,
                "lng": -130.5791327804
            },
            {
                "lat": 53.9239658911,
                "lng": -130.5843649425
            },
            {
                "lat": 53.9364045875,
                "lng": -130.6361539022
            },
            {
                "lat": 53.920608303,
                "lng": -130.6330720902
            },
            {
                "lat": 53.9194373838,
                "lng": -130.6335302026
            },
            {
                "lat": 53.9176749501,
                "lng": -130.6336670465
            },
            {
                "lat": 53.9150155242,
                "lng": -130.6325835549
            },
            {
                "lat": 53.9141121889,
                "lng": -130.629470197
            },
            {
                "lat": 53.9138411153,
                "lng": -130.6226646948
            },
            {
                "lat": 53.9128422044,
                "lng": -130.6184085017
            },
            {
                "lat": 53.9084395385,
                "lng": -130.6144718366
            },
            {
                "lat": 53.9022519445,
                "lng": -130.6047206518
            },
            {
                "lat": 53.8962974375,
                "lng": -130.5918734106
            },
            {
                "lat": 53.8960798141,
                "lng": -130.5868082124
            },
            {
                "lat": 53.8960877766,
                "lng": -130.5566563339
            },
            {
                "lat": 53.8966641481,
                "lng": -130.5561215639
            },
            {
                "lat": 53.8979456225,
                "lng": -130.5553278575
            },
            {
                "lat": 53.901054229,
                "lng": -130.5540313428
            },
            {
                "lat": 53.9065280806,
                "lng": -130.5575862014
            },
            {
                "lat": 53.9110484547,
                "lng": -130.5630650863
            },
            {
                "lat": 53.9224697712,
                "lng": -130.5730595978
            },
            {
                "lat": 53.9231834462,
                "lng": -130.5791327804
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 81,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 5-5",
            "LABEL": "5-5",
            "FISHERY_AREA_ID": 2009,
            "OBJECTID": 28,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 13317005.9979,
            "FEATURE_LENGTH_M": 15978.1422,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.91159708445455,
            "lng": -130.59526830855455
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.932926033,
                "lng": -130.2421110966
            },
            {
                "lat": 53.9367448061,
                "lng": -130.2460786485
            },
            {
                "lat": 53.9379495725,
                "lng": -130.2488404325
            },
            {
                "lat": 53.9443511107,
                "lng": -130.261779469
            },
            {
                "lat": 53.9363944714,
                "lng": -130.2694856071
            },
            {
                "lat": 53.9276431335,
                "lng": -130.2756809478
            },
            {
                "lat": 53.9143184008,
                "lng": -130.28710903
            },
            {
                "lat": 53.9019437317,
                "lng": -130.3044740521
            },
            {
                "lat": 53.8959956494,
                "lng": -130.3227994942
            },
            {
                "lat": 53.890285774,
                "lng": -130.3394469769
            },
            {
                "lat": 53.8743402887,
                "lng": -130.3456420427
            },
            {
                "lat": 53.8591122096,
                "lng": -130.354446769
            },
            {
                "lat": 53.8457873105,
                "lng": -130.3582457745
            },
            {
                "lat": 53.8360409036,
                "lng": -130.3534849898
            },
            {
                "lat": 53.831520608,
                "lng": -130.3482514589
            },
            {
                "lat": 53.830570372,
                "lng": -130.3439630015
            },
            {
                "lat": 53.8283990613,
                "lng": -130.3414611332
            },
            {
                "lat": 53.7960892198,
                "lng": -130.31286606
            },
            {
                "lat": 53.7952233779,
                "lng": -130.3127439813
            },
            {
                "lat": 53.7935715099,
                "lng": -130.3062430278
            },
            {
                "lat": 53.7931055251,
                "lng": -130.29876757
            },
            {
                "lat": 53.7949298054,
                "lng": -130.2900392326
            },
            {
                "lat": 53.7939533813,
                "lng": -130.2707816683
            },
            {
                "lat": 53.7916344225,
                "lng": -130.2660978709
            },
            {
                "lat": 53.7890777015,
                "lng": -130.2568674426
            },
            {
                "lat": 53.7888829968,
                "lng": -130.2440803392
            },
            {
                "lat": 53.7925144237,
                "lng": -130.2423403413
            },
            {
                "lat": 53.7925187413,
                "lng": -130.2187650809
            },
            {
                "lat": 53.7924802577,
                "lng": -130.2178962034
            },
            {
                "lat": 53.7946203805,
                "lng": -130.2106936241
            },
            {
                "lat": 53.7990986855,
                "lng": -130.1786805309
            },
            {
                "lat": 53.8057600281,
                "lng": -130.1632082486
            },
            {
                "lat": 53.8176577812,
                "lng": -130.1608279113
            },
            {
                "lat": 53.8264620899,
                "lng": -130.1698757004
            },
            {
                "lat": 53.8302151295,
                "lng": -130.2055212347
            },
            {
                "lat": 53.8308217392,
                "lng": -130.2359305085
            },
            {
                "lat": 53.8364182178,
                "lng": -130.2627114676
            },
            {
                "lat": 53.8451954796,
                "lng": -130.2677003445
            },
            {
                "lat": 53.8625905532,
                "lng": -130.254989038
            },
            {
                "lat": 53.8828653833,
                "lng": -130.2301787591
            },
            {
                "lat": 53.89842219,
                "lng": -130.2106174114
            },
            {
                "lat": 53.9067876564,
                "lng": -130.2036900521
            },
            {
                "lat": 53.9108354784,
                "lng": -130.2096101451
            },
            {
                "lat": 53.932926033,
                "lng": -130.2421110966
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 82,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 5-2",
            "LABEL": "5-2",
            "FISHERY_AREA_ID": 2006,
            "OBJECTID": 29,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 112039216.1476,
            "FEATURE_LENGTH_M": 54072.0218,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.85043140057499,
            "lng": -130.26561672307952
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.9393545591,
                "lng": -130.1021173289
            },
            {
                "lat": 53.9384375254,
                "lng": -130.1025916073
            },
            {
                "lat": 53.9384198422,
                "lng": -130.1026003661
            },
            {
                "lat": 53.9369621895,
                "lng": -130.1632989513
            },
            {
                "lat": 53.932926033,
                "lng": -130.2421110966
            },
            {
                "lat": 53.9108354784,
                "lng": -130.2096101451
            },
            {
                "lat": 53.9067876564,
                "lng": -130.2036900521
            },
            {
                "lat": 53.8999669828,
                "lng": -130.1924290591
            },
            {
                "lat": 53.8869517916,
                "lng": -130.1667943847
            },
            {
                "lat": 53.8771168228,
                "lng": -130.1342619551
            },
            {
                "lat": 53.8686449983,
                "lng": -130.1165618424
            },
            {
                "lat": 53.8548779879,
                "lng": -130.0950780334
            },
            {
                "lat": 53.8386878238,
                "lng": -130.0699606294
            },
            {
                "lat": 53.8435287351,
                "lng": -130.0657203297
            },
            {
                "lat": 53.8474612581,
                "lng": -130.0669402505
            },
            {
                "lat": 53.8501852659,
                "lng": -130.0646666073
            },
            {
                "lat": 53.8535156968,
                "lng": -130.0660252182
            },
            {
                "lat": 53.8547251876,
                "lng": -130.068145764
            },
            {
                "lat": 53.8561862712,
                "lng": -130.0688477302
            },
            {
                "lat": 53.872596141,
                "lng": -130.0507500835
            },
            {
                "lat": 53.8721503754,
                "lng": -130.0500949206
            },
            {
                "lat": 53.8719897278,
                "lng": -130.0494847416
            },
            {
                "lat": 53.871669472,
                "lng": -130.0430292134
            },
            {
                "lat": 53.884376656,
                "lng": -130.0321356969
            },
            {
                "lat": 53.920234621,
                "lng": -130.0640561135
            },
            {
                "lat": 53.9439894021,
                "lng": -130.0817559138
            },
            {
                "lat": 53.9399032235,
                "lng": -130.0973509406
            },
            {
                "lat": 53.9393545591,
                "lng": -130.1021173289
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 83,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 5-1",
            "LABEL": "5-1",
            "FISHERY_AREA_ID": 2005,
            "OBJECTID": 30,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 75656860.9096,
            "FEATURE_LENGTH_M": 40122.114,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.89113701013571,
            "lng": -130.1025795108643
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.9150155242,
                "lng": -130.6325835549
            },
            {
                "lat": 53.9117966805,
                "lng": -130.6360165785
            },
            {
                "lat": 53.9090695455,
                "lng": -130.6440433678
            },
            {
                "lat": 53.9033580257,
                "lng": -130.6516573879
            },
            {
                "lat": 53.8997872677,
                "lng": -130.6559447926
            },
            {
                "lat": 53.8989598316,
                "lng": -130.6536860506
            },
            {
                "lat": 53.8950539756,
                "lng": -130.6416324753
            },
            {
                "lat": 53.8930469432,
                "lng": -130.6274869326
            },
            {
                "lat": 53.8902403299,
                "lng": -130.6118461911
            },
            {
                "lat": 53.8899385758,
                "lng": -130.5986016163
            },
            {
                "lat": 53.8962974375,
                "lng": -130.5918734106
            },
            {
                "lat": 53.9022519445,
                "lng": -130.6047206518
            },
            {
                "lat": 53.9084395385,
                "lng": -130.6144718366
            },
            {
                "lat": 53.9128422044,
                "lng": -130.6184085017
            },
            {
                "lat": 53.9138411153,
                "lng": -130.6226646948
            },
            {
                "lat": 53.9141121889,
                "lng": -130.629470197
            },
            {
                "lat": 53.9150155242,
                "lng": -130.6325835549
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 84,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 31,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 6552868.3449,
            "FEATURE_LENGTH_M": 10680.7478,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.90406274429412,
            "lng": -130.62751128205883
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.8997872677,
                "lng": -130.6559447926
            },
            {
                "lat": 53.8992426516,
                "lng": -130.6578213523
            },
            {
                "lat": 53.8993449806,
                "lng": -130.6626281882
            },
            {
                "lat": 53.8993500032,
                "lng": -130.6997379144
            },
            {
                "lat": 53.8985557222,
                "lng": -130.7029730188
            },
            {
                "lat": 53.8933220287,
                "lng": -130.7029726297
            },
            {
                "lat": 53.88975464,
                "lng": -130.7020260807
            },
            {
                "lat": 53.8857084439,
                "lng": -130.694656479
            },
            {
                "lat": 53.8799967006,
                "lng": -130.6770479224
            },
            {
                "lat": 53.8735728762,
                "lng": -130.6587220156
            },
            {
                "lat": 53.8683391369,
                "lng": -130.6425322515
            },
            {
                "lat": 53.8633423992,
                "lng": -130.6289830107
            },
            {
                "lat": 53.8583211181,
                "lng": -130.6169425331
            },
            {
                "lat": 53.8582802584,
                "lng": -130.6142645438
            },
            {
                "lat": 53.8587383822,
                "lng": -130.6120514011
            },
            {
                "lat": 53.859035098,
                "lng": -130.6110754529
            },
            {
                "lat": 53.8590283967,
                "lng": -130.6110308037
            },
            {
                "lat": 53.8590257365,
                "lng": -130.611012366
            },
            {
                "lat": 53.8555548501,
                "lng": -130.5885944582
            },
            {
                "lat": 53.8555414148,
                "lng": -130.588534766
            },
            {
                "lat": 53.850391419,
                "lng": -130.5660406625
            },
            {
                "lat": 53.8493926557,
                "lng": -130.5650168398
            },
            {
                "lat": 53.841938331,
                "lng": -130.5538634501
            },
            {
                "lat": 53.834754493,
                "lng": -130.5373687293
            },
            {
                "lat": 53.8292805022,
                "lng": -130.5299982894
            },
            {
                "lat": 53.8257135906,
                "lng": -130.5192867824
            },
            {
                "lat": 53.8200035796,
                "lng": -130.5043031589
            },
            {
                "lat": 53.8198925714,
                "lng": -130.5001530282
            },
            {
                "lat": 53.8378032481,
                "lng": -130.5034331517
            },
            {
                "lat": 53.840683494,
                "lng": -130.4995733876
            },
            {
                "lat": 53.8433003501,
                "lng": -130.4931488802
            },
            {
                "lat": 53.8444902599,
                "lng": -130.4838706779
            },
            {
                "lat": 53.8468662864,
                "lng": -130.480301023
            },
            {
                "lat": 53.8554345576,
                "lng": -130.4845882614
            },
            {
                "lat": 53.8659057201,
                "lng": -130.4971924982
            },
            {
                "lat": 53.8704754093,
                "lng": -130.5038301219
            },
            {
                "lat": 53.8707734218,
                "lng": -130.5117640907
            },
            {
                "lat": 53.8702007574,
                "lng": -130.5128484367
            },
            {
                "lat": 53.8700678614,
                "lng": -130.5142363812
            },
            {
                "lat": 53.8705521519,
                "lng": -130.5156713285
            },
            {
                "lat": 53.8960877766,
                "lng": -130.5566563339
            },
            {
                "lat": 53.8960798141,
                "lng": -130.5868082124
            },
            {
                "lat": 53.8962974375,
                "lng": -130.5918734106
            },
            {
                "lat": 53.8899385758,
                "lng": -130.5986016163
            },
            {
                "lat": 53.8902403299,
                "lng": -130.6118461911
            },
            {
                "lat": 53.8930469432,
                "lng": -130.6274869326
            },
            {
                "lat": 53.8950539756,
                "lng": -130.6416324753
            },
            {
                "lat": 53.8989598316,
                "lng": -130.6536860506
            },
            {
                "lat": 53.8997872677,
                "lng": -130.6559447926
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 85,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 5-4",
            "LABEL": "5-4",
            "FISHERY_AREA_ID": 2008,
            "OBJECTID": 32,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 54729105.4249,
            "FEATURE_LENGTH_M": 38709.2545,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.86790315751224,
            "lng": -130.5865423913408
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.7798923366,
                "lng": -133.1140902302
            },
            {
                "lat": 53.7792475297,
                "lng": -133.1155544469
            },
            {
                "lat": 53.7789647258,
                "lng": -133.116988883
            },
            {
                "lat": 53.7788655933,
                "lng": -133.1185904312
            },
            {
                "lat": 53.778545425,
                "lng": -133.119582759
            },
            {
                "lat": 53.7475315579,
                "lng": -133.1047206753
            },
            {
                "lat": 53.7475430513,
                "lng": -133.03778064
            },
            {
                "lat": 53.7505115367,
                "lng": -133.0379792865
            },
            {
                "lat": 53.7542646371,
                "lng": -133.0397180675
            },
            {
                "lat": 53.7580224615,
                "lng": -133.0437009518
            },
            {
                "lat": 53.7613300228,
                "lng": -133.0500338243
            },
            {
                "lat": 53.7631764719,
                "lng": -133.0541840424
            },
            {
                "lat": 53.7672112215,
                "lng": -133.0577691877
            },
            {
                "lat": 53.7705655133,
                "lng": -133.0571140684
            },
            {
                "lat": 53.7731859849,
                "lng": -133.0594932371
            },
            {
                "lat": 53.7760390579,
                "lng": -133.0673364766
            },
            {
                "lat": 53.7774696168,
                "lng": -133.0799562769
            },
            {
                "lat": 53.7765163716,
                "lng": -133.0901794924
            },
            {
                "lat": 53.7774698528,
                "lng": -133.1001739503
            },
            {
                "lat": 53.7791322624,
                "lng": -133.1073150586
            },
            {
                "lat": 53.7800869944,
                "lng": -133.1123196283
            },
            {
                "lat": 53.7798923366,
                "lng": -133.1140902302
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 86,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 98,
            "MANAGEMENT_AREA_NAME": "Subarea 2-98",
            "LABEL": "2-98",
            "FISHERY_AREA_ID": 1987,
            "OBJECTID": 33,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 13543816.3135,
            "FEATURE_LENGTH_M": 15542.6874,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.76979384371818,
            "lng": -133.0817578111182
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.8666682474,
                "lng": -131.3000030003
            },
            {
                "lat": 53.8666687304,
                "lng": -131.3749999863
            },
            {
                "lat": 53.3666680692,
                "lng": -131.1999964036
            },
            {
                "lat": 53.4664188963,
                "lng": -130.5874330872
            },
            {
                "lat": 53.4674071254,
                "lng": -130.5869283982
            },
            {
                "lat": 53.4680103172,
                "lng": -130.587020808
            },
            {
                "lat": 53.4738995467,
                "lng": -130.5914304514
            },
            {
                "lat": 53.4757307204,
                "lng": -130.5957032472
            },
            {
                "lat": 53.4761728164,
                "lng": -130.6013496592
            },
            {
                "lat": 53.4929161611,
                "lng": -130.6252444182
            },
            {
                "lat": 53.4981383258,
                "lng": -130.6283725808
            },
            {
                "lat": 53.4989169958,
                "lng": -130.6313163686
            },
            {
                "lat": 53.4994656494,
                "lng": -130.6322485203
            },
            {
                "lat": 53.4994853441,
                "lng": -130.633056021
            },
            {
                "lat": 53.4993631083,
                "lng": -130.633514719
            },
            {
                "lat": 53.4992405756,
                "lng": -130.6338655171
            },
            {
                "lat": 53.4992291271,
                "lng": -130.6342465629
            },
            {
                "lat": 53.6344722546,
                "lng": -130.6971594202
            },
            {
                "lat": 53.634472408,
                "lng": -130.6972268393
            },
            {
                "lat": 53.8474775394,
                "lng": -130.7963317098
            },
            {
                "lat": 53.8666648701,
                "lng": -130.8052588244
            },
            {
                "lat": 53.8666682474,
                "lng": -131.3000030003
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 87,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 105,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 105-1",
            "LABEL": "105-1",
            "FISHERY_AREA_ID": 2304,
            "OBJECTID": 34,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1986913067.3339,
            "FEATURE_LENGTH_M": 184140.966,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.58018886709545,
            "lng": -130.76239588833184
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 55.7967833428,
                "lng": -130.131180272
            },
            {
                "lat": 55.7967836465,
                "lng": -130.1089477389
            },
            {
                "lat": 55.7953522838,
                "lng": -130.085251233
            },
            {
                "lat": 55.8077321901,
                "lng": -130.0630945525
            },
            {
                "lat": 55.8279763387,
                "lng": -130.0431667432
            },
            {
                "lat": 55.8753621536,
                "lng": -129.9904787528
            },
            {
                "lat": 55.9523853926,
                "lng": -129.9269867888
            },
            {
                "lat": 55.9826089127,
                "lng": -129.9368583273
            },
            {
                "lat": 55.9826084769,
                "lng": -129.9928128022
            },
            {
                "lat": 55.9468072754,
                "lng": -130.0124671242
            },
            {
                "lat": 55.9298585599,
                "lng": -130.0150757852
            },
            {
                "lat": 55.9184727278,
                "lng": -130.016800502
            },
            {
                "lat": 55.9122237039,
                "lng": -130.0177770523
            },
            {
                "lat": 55.9119714874,
                "lng": -130.0178065984
            },
            {
                "lat": 55.911971913,
                "lng": -130.016662669
            },
            {
                "lat": 55.9119719059,
                "lng": -130.003922497
            },
            {
                "lat": 55.9074713963,
                "lng": -130.0039221821
            },
            {
                "lat": 55.8217513496,
                "lng": -130.0836342642
            },
            {
                "lat": 55.8056946831,
                "lng": -130.1249238988
            },
            {
                "lat": 55.7967833428,
                "lng": -130.131180272
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 1,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 16,
            "MANAGEMENT_AREA_NAME": "Subarea 3-16",
            "LABEL": "3-16",
            "FISHERY_AREA_ID": 2002,
            "OBJECTID": 35,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 68200734.7436,
            "FEATURE_LENGTH_M": 54474.5499,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 55.87962855414001,
            "lng": -130.036147502795
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 55.7953522838,
                "lng": -130.085251233
            },
            {
                "lat": 55.7967836465,
                "lng": -130.1089477389
            },
            {
                "lat": 55.7967833428,
                "lng": -130.131180272
            },
            {
                "lat": 55.7663352902,
                "lng": -130.1525579376
            },
            {
                "lat": 55.7149436409,
                "lng": -130.1478879299
            },
            {
                "lat": 55.6829456076,
                "lng": -130.1117702062
            },
            {
                "lat": 55.5810812071,
                "lng": -130.1280212469
            },
            {
                "lat": 55.4980007533,
                "lng": -130.0899509697
            },
            {
                "lat": 55.4517513817,
                "lng": -130.0413055811
            },
            {
                "lat": 55.3379442072,
                "lng": -130.0211330163
            },
            {
                "lat": 55.300388426,
                "lng": -129.976699934
            },
            {
                "lat": 55.2980466613,
                "lng": -129.9763789758
            },
            {
                "lat": 55.2980463081,
                "lng": -129.9589545582
            },
            {
                "lat": 55.3204685094,
                "lng": -129.9387201944
            },
            {
                "lat": 55.3531069384,
                "lng": -129.9664611352
            },
            {
                "lat": 55.371341498,
                "lng": -129.9822234357
            },
            {
                "lat": 55.4854970774,
                "lng": -130.0162203278
            },
            {
                "lat": 55.5174788542,
                "lng": -130.0436410576
            },
            {
                "lat": 55.5489539875,
                "lng": -130.0681151506
            },
            {
                "lat": 55.5760007988,
                "lng": -130.0851442603
            },
            {
                "lat": 55.6049071834,
                "lng": -130.0781401265
            },
            {
                "lat": 55.6382484648,
                "lng": -130.0723110623
            },
            {
                "lat": 55.6746216013,
                "lng": -130.0741731366
            },
            {
                "lat": 55.7014311761,
                "lng": -130.0828097035
            },
            {
                "lat": 55.7210157672,
                "lng": -130.0935360072
            },
            {
                "lat": 55.7403685105,
                "lng": -130.1009985393
            },
            {
                "lat": 55.7582626251,
                "lng": -130.0974738983
            },
            {
                "lat": 55.7953522838,
                "lng": -130.085251233
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 2,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 15,
            "MANAGEMENT_AREA_NAME": "Subarea 3-15",
            "LABEL": "3-15",
            "FISHERY_AREA_ID": 2001,
            "OBJECTID": 36,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 167722608.1918,
            "FEATURE_LENGTH_M": 120554.0225,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 55.575909215442856,
            "lng": -130.06125924528212
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 55.1023556876,
                "lng": -130.0039221714
            },
            {
                "lat": 55.0932462042,
                "lng": -129.9912873451
            },
            {
                "lat": 55.0861546162,
                "lng": -129.9634547945
            },
            {
                "lat": 55.086024783,
                "lng": -129.962814936
            },
            {
                "lat": 55.0815622742,
                "lng": -129.9553987291
            },
            {
                "lat": 55.0893404405,
                "lng": -129.9349218613
            },
            {
                "lat": 55.12622088,
                "lng": -129.8778073274
            },
            {
                "lat": 55.1940344914,
                "lng": -129.8111868677
            },
            {
                "lat": 55.2773166661,
                "lng": -129.7100673081
            },
            {
                "lat": 55.3522722928,
                "lng": -129.6208342764
            },
            {
                "lat": 55.4177058892,
                "lng": -129.4851983729
            },
            {
                "lat": 55.4724318838,
                "lng": -129.4185786789
            },
            {
                "lat": 55.5105050452,
                "lng": -129.436432562
            },
            {
                "lat": 55.5105055816,
                "lng": -129.5161439462
            },
            {
                "lat": 55.5295410261,
                "lng": -129.655334595
            },
            {
                "lat": 55.5842667638,
                "lng": -129.7112571208
            },
            {
                "lat": 55.6318590391,
                "lng": -129.7564699999
            },
            {
                "lat": 55.6497042689,
                "lng": -129.7826382881
            },
            {
                "lat": 55.653271194,
                "lng": -129.8088073249
            },
            {
                "lat": 55.631858822,
                "lng": -129.8290402723
            },
            {
                "lat": 55.5569039723,
                "lng": -129.8290403795
            },
            {
                "lat": 55.4736211423,
                "lng": -129.8254698798
            },
            {
                "lat": 55.4319837109,
                "lng": -129.8587798041
            },
            {
                "lat": 55.3558390517,
                "lng": -129.8623507018
            },
            {
                "lat": 55.2951628047,
                "lng": -129.8587797797
            },
            {
                "lat": 55.2511449203,
                "lng": -129.8873283838
            },
            {
                "lat": 55.1809496823,
                "lng": -129.9147041008
            },
            {
                "lat": 55.1642918936,
                "lng": -129.9420625932
            },
            {
                "lat": 55.1250306141,
                "lng": -129.9837033584
            },
            {
                "lat": 55.1023556876,
                "lng": -130.0039221714
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 3,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 3-14",
            "LABEL": "3-14",
            "FISHERY_AREA_ID": 2000,
            "OBJECTID": 37,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 781393281.7501,
            "FEATURE_LENGTH_M": 160080.7371,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 55.33391537765,
            "lng": -129.80659126434998
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 55.3204685094,
                "lng": -129.9387201944
            },
            {
                "lat": 55.2980463081,
                "lng": -129.9589545582
            },
            {
                "lat": 55.2980466613,
                "lng": -129.9763789758
            },
            {
                "lat": 55.2819449845,
                "lng": -129.9741976447
            },
            {
                "lat": 55.1926122786,
                "lng": -130.1028604127
            },
            {
                "lat": 55.1428612347,
                "lng": -130.1461639064
            },
            {
                "lat": 55.1246374482,
                "lng": -130.1524653551
            },
            {
                "lat": 55.0912790088,
                "lng": -130.1809237597
            },
            {
                "lat": 55.0628628585,
                "lng": -130.1875769166
            },
            {
                "lat": 55.034100514,
                "lng": -130.2153319641
            },
            {
                "lat": 55.0341000917,
                "lng": -130.18844589
            },
            {
                "lat": 55.0803227282,
                "lng": -130.1656644331
            },
            {
                "lat": 55.0802337053,
                "lng": -130.1577602698
            },
            {
                "lat": 55.1186756559,
                "lng": -130.1206354524
            },
            {
                "lat": 55.2815324807,
                "lng": -129.9280858363
            },
            {
                "lat": 55.300647458,
                "lng": -129.9268341736
            },
            {
                "lat": 55.3204685094,
                "lng": -129.9387201944
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 4,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 3-13",
            "LABEL": "3-13",
            "FISHERY_AREA_ID": 1999,
            "OBJECTID": 38,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 89445811.6268,
            "FEATURE_LENGTH_M": 76741.2701,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 55.180167084429414,
            "lng": -130.07410117278235
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 55.0815622742,
                "lng": -129.9553987291
            },
            {
                "lat": 55.086024783,
                "lng": -129.962814936
            },
            {
                "lat": 55.0861546162,
                "lng": -129.9634547945
            },
            {
                "lat": 55.0932462042,
                "lng": -129.9912873451
            },
            {
                "lat": 55.1023556876,
                "lng": -130.0039221714
            },
            {
                "lat": 55.079990341,
                "lng": -130.0241553652
            },
            {
                "lat": 55.0540547602,
                "lng": -130.0365303848
            },
            {
                "lat": 55.0451966518,
                "lng": -130.0404656031
            },
            {
                "lat": 55.0281189632,
                "lng": -130.0491330514
            },
            {
                "lat": 55.0052760571,
                "lng": -130.0755463048
            },
            {
                "lat": 54.999935752,
                "lng": -130.0872047978
            },
            {
                "lat": 54.9923895919,
                "lng": -130.0949853927
            },
            {
                "lat": 54.9902734466,
                "lng": -130.0989989118
            },
            {
                "lat": 54.9844361796,
                "lng": -130.1040347969
            },
            {
                "lat": 54.9832494225,
                "lng": -130.1045075968
            },
            {
                "lat": 54.9608307943,
                "lng": -130.1534876482
            },
            {
                "lat": 54.9611930173,
                "lng": -130.1548919816
            },
            {
                "lat": 54.96019058,
                "lng": -130.1584474959
            },
            {
                "lat": 54.9553899091,
                "lng": -130.1622315452
            },
            {
                "lat": 54.9450913293,
                "lng": -130.173889777
            },
            {
                "lat": 54.9374772374,
                "lng": -130.1836540223
            },
            {
                "lat": 54.9122538019,
                "lng": -130.2124475864
            },
            {
                "lat": 54.8948828105,
                "lng": -130.2288667822
            },
            {
                "lat": 54.8787037666,
                "lng": -130.2486120566
            },
            {
                "lat": 54.8644261508,
                "lng": -130.2619320816
            },
            {
                "lat": 54.856811144,
                "lng": -130.275497262
            },
            {
                "lat": 54.84919703,
                "lng": -130.2807306921
            },
            {
                "lat": 54.8423309459,
                "lng": -130.2817694764
            },
            {
                "lat": 54.8390083837,
                "lng": -130.2800137385
            },
            {
                "lat": 54.8352473392,
                "lng": -130.2792214374
            },
            {
                "lat": 54.8332971771,
                "lng": -130.2795864585
            },
            {
                "lat": 54.8331411614,
                "lng": -130.2795563312
            },
            {
                "lat": 54.832019586,
                "lng": -130.2794185494
            },
            {
                "lat": 54.8407973755,
                "lng": -130.1762544596
            },
            {
                "lat": 54.8414194426,
                "lng": -130.1760712356
            },
            {
                "lat": 54.8417703833,
                "lng": -130.1754145304
            },
            {
                "lat": 54.8428649039,
                "lng": -130.1743320013
            },
            {
                "lat": 54.8453588216,
                "lng": -130.1716762698
            },
            {
                "lat": 54.8509866962,
                "lng": -130.1676337173
            },
            {
                "lat": 54.8711397672,
                "lng": -130.1319275971
            },
            {
                "lat": 54.8973843928,
                "lng": -130.0968320338
            },
            {
                "lat": 54.9074971374,
                "lng": -130.0664972004
            },
            {
                "lat": 54.9270593796,
                "lng": -130.0492553274
            },
            {
                "lat": 54.9502561046,
                "lng": -130.0192107848
            },
            {
                "lat": 54.9573972672,
                "lng": -129.9936221001
            },
            {
                "lat": 54.9579926874,
                "lng": -129.9626928098
            },
            {
                "lat": 54.9573862927,
                "lng": -129.9597773319
            },
            {
                "lat": 54.9569738383,
                "lng": -129.9574579939
            },
            {
                "lat": 54.9622007986,
                "lng": -129.9507148257
            },
            {
                "lat": 54.9622011435,
                "lng": -129.9486688121
            },
            {
                "lat": 54.9906774445,
                "lng": -129.9528190565
            },
            {
                "lat": 54.9922487784,
                "lng": -129.951309484
            },
            {
                "lat": 54.998382938,
                "lng": -129.9491578121
            },
            {
                "lat": 55.0098072815,
                "lng": -129.9710549198
            },
            {
                "lat": 55.016468201,
                "lng": -129.9798576192
            },
            {
                "lat": 55.0326498373,
                "lng": -129.9748531403
            },
            {
                "lat": 55.0592993946,
                "lng": -129.9636689839
            },
            {
                "lat": 55.0815622742,
                "lng": -129.9553987291
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 5,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 3-12",
            "LABEL": "3-12",
            "FISHERY_AREA_ID": 2544,
            "OBJECTID": 39,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 216475517.5466,
            "FEATURE_LENGTH_M": 82124.3598,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.95095757719827,
            "lng": -130.09729058413444
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 55.0802337053,
                "lng": -130.1577602698
            },
            {
                "lat": 55.0803227282,
                "lng": -130.1656644331
            },
            {
                "lat": 55.0341000917,
                "lng": -130.18844589
            },
            {
                "lat": 55.0320694219,
                "lng": -130.189513632
            },
            {
                "lat": 55.0285997178,
                "lng": -130.1916345835
            },
            {
                "lat": 55.0192786867,
                "lng": -130.1888882806
            },
            {
                "lat": 55.005718435,
                "lng": -130.182693081
            },
            {
                "lat": 54.9881101888,
                "lng": -130.1689002775
            },
            {
                "lat": 54.9721643102,
                "lng": -130.1672365154
            },
            {
                "lat": 54.9553899091,
                "lng": -130.1622315452
            },
            {
                "lat": 54.96019058,
                "lng": -130.1584474959
            },
            {
                "lat": 54.9611930173,
                "lng": -130.1548919816
            },
            {
                "lat": 54.9608307943,
                "lng": -130.1534876482
            },
            {
                "lat": 54.9832494225,
                "lng": -130.1045075968
            },
            {
                "lat": 54.9844361796,
                "lng": -130.1040347969
            },
            {
                "lat": 54.9902734466,
                "lng": -130.0989989118
            },
            {
                "lat": 54.9967802894,
                "lng": -130.1064755764
            },
            {
                "lat": 55.0184326104,
                "lng": -130.1200404359
            },
            {
                "lat": 55.0358005714,
                "lng": -130.1255193092
            },
            {
                "lat": 55.0479360737,
                "lng": -130.1240848022
            },
            {
                "lat": 55.0567389422,
                "lng": -130.1288446173
            },
            {
                "lat": 55.0660212454,
                "lng": -130.1426540446
            },
            {
                "lat": 55.0757750804,
                "lng": -130.1495512958
            },
            {
                "lat": 55.0802337053,
                "lng": -130.1577602698
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 6,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 17,
            "MANAGEMENT_AREA_NAME": "Subarea 3-17",
            "LABEL": "3-17",
            "FISHERY_AREA_ID": 2003,
            "OBJECTID": 40,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 44974227.2837,
            "FEATURE_LENGTH_M": 31821.9672,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 55.01724496471667,
            "lng": -130.14967780377083
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 55.0341000917,
                "lng": -130.18844589
            },
            {
                "lat": 55.034100514,
                "lng": -130.2153319641
            },
            {
                "lat": 54.9739721758,
                "lng": -130.2733608458
            },
            {
                "lat": 54.9177506708,
                "lng": -130.3459779364
            },
            {
                "lat": 54.8733898521,
                "lng": -130.4227447082
            },
            {
                "lat": 54.8513073771,
                "lng": -130.454421613
            },
            {
                "lat": 54.8366936815,
                "lng": -130.4802858383
            },
            {
                "lat": 54.7921684818,
                "lng": -130.5682225309
            },
            {
                "lat": 54.7834807817,
                "lng": -130.6241608
            },
            {
                "lat": 54.7757800753,
                "lng": -130.6241610561
            },
            {
                "lat": 54.7713850399,
                "lng": -130.6239928472
            },
            {
                "lat": 54.768116244,
                "lng": -130.6181946166
            },
            {
                "lat": 54.7606315027,
                "lng": -130.6039434857
            },
            {
                "lat": 54.7547294996,
                "lng": -130.6036527497
            },
            {
                "lat": 54.7483059989,
                "lng": -130.5986479539
            },
            {
                "lat": 54.7406926912,
                "lng": -130.5938867918
            },
            {
                "lat": 54.7375988379,
                "lng": -130.5881805568
            },
            {
                "lat": 54.7361717908,
                "lng": -130.5803220107
            },
            {
                "lat": 54.7435460427,
                "lng": -130.5698541229
            },
            {
                "lat": 54.7525900848,
                "lng": -130.5610513346
            },
            {
                "lat": 54.7625814002,
                "lng": -130.5724795973
            },
            {
                "lat": 54.7695921793,
                "lng": -130.5603488452
            },
            {
                "lat": 54.8050696517,
                "lng": -130.5178076407
            },
            {
                "lat": 54.8068810303,
                "lng": -130.5136103848
            },
            {
                "lat": 54.8111076707,
                "lng": -130.5065769319
            },
            {
                "lat": 54.8178516142,
                "lng": -130.4982599697
            },
            {
                "lat": 54.8238381613,
                "lng": -130.4944143463
            },
            {
                "lat": 54.8250732835,
                "lng": -130.4934694863
            },
            {
                "lat": 54.8259740938,
                "lng": -130.492737718
            },
            {
                "lat": 54.8279071898,
                "lng": -130.4897916754
            },
            {
                "lat": 54.8283460113,
                "lng": -130.4883119887
            },
            {
                "lat": 54.8280637512,
                "lng": -130.4847260319
            },
            {
                "lat": 54.8280181051,
                "lng": -130.4841308127
            },
            {
                "lat": 54.8281023177,
                "lng": -130.4796903438
            },
            {
                "lat": 54.8224939495,
                "lng": -130.4662321713
            },
            {
                "lat": 54.8122067416,
                "lng": -130.4582208068
            },
            {
                "lat": 54.8064877341,
                "lng": -130.4486994285
            },
            {
                "lat": 54.7950550042,
                "lng": -130.4380185647
            },
            {
                "lat": 54.7885737945,
                "lng": -130.4254459569
            },
            {
                "lat": 54.7790454258,
                "lng": -130.4189601003
            },
            {
                "lat": 54.777522417,
                "lng": -130.4094381674
            },
            {
                "lat": 54.7850076666,
                "lng": -130.405639673
            },
            {
                "lat": 54.7947622379,
                "lng": -130.4096828096
            },
            {
                "lat": 54.8195077249,
                "lng": -130.4294437194
            },
            {
                "lat": 54.8340228997,
                "lng": -130.4415738896
            },
            {
                "lat": 54.8449700554,
                "lng": -130.4432374546
            },
            {
                "lat": 54.8561523118,
                "lng": -130.422058216
            },
            {
                "lat": 54.8656685681,
                "lng": -130.4066015663
            },
            {
                "lat": 54.8692665462,
                "lng": -130.4046628435
            },
            {
                "lat": 54.9304350993,
                "lng": -130.2787622757
            },
            {
                "lat": 55.027659346,
                "lng": -130.1940609437
            },
            {
                "lat": 55.0285997178,
                "lng": -130.1916345835
            },
            {
                "lat": 55.0320694219,
                "lng": -130.189513632
            },
            {
                "lat": 55.0341000917,
                "lng": -130.18844589
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 7,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 3-11",
            "LABEL": "3-11",
            "FISHERY_AREA_ID": 1998,
            "OBJECTID": 41,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 85029206.4496,
            "FEATURE_LENGTH_M": 103507.7503,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.83478749348889,
            "lng": -130.45713940959627
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.9553899091,
                "lng": -130.1622315452
            },
            {
                "lat": 54.9721643102,
                "lng": -130.1672365154
            },
            {
                "lat": 54.9881101888,
                "lng": -130.1689002775
            },
            {
                "lat": 55.005718435,
                "lng": -130.182693081
            },
            {
                "lat": 55.0192786867,
                "lng": -130.1888882806
            },
            {
                "lat": 55.0285997178,
                "lng": -130.1916345835
            },
            {
                "lat": 55.027659346,
                "lng": -130.1940609437
            },
            {
                "lat": 54.9304350993,
                "lng": -130.2787622757
            },
            {
                "lat": 54.8692665462,
                "lng": -130.4046628435
            },
            {
                "lat": 54.8656685681,
                "lng": -130.4066015663
            },
            {
                "lat": 54.8561523118,
                "lng": -130.422058216
            },
            {
                "lat": 54.8449700554,
                "lng": -130.4432374546
            },
            {
                "lat": 54.8340228997,
                "lng": -130.4415738896
            },
            {
                "lat": 54.8195077249,
                "lng": -130.4294437194
            },
            {
                "lat": 54.7947622379,
                "lng": -130.4096828096
            },
            {
                "lat": 54.7850076666,
                "lng": -130.405639673
            },
            {
                "lat": 54.777522417,
                "lng": -130.4094381674
            },
            {
                "lat": 54.7958145412,
                "lng": -130.3884734361
            },
            {
                "lat": 54.8141094965,
                "lng": -130.3713230246
            },
            {
                "lat": 54.8244020869,
                "lng": -130.3537896185
            },
            {
                "lat": 54.828212768,
                "lng": -130.3274843664
            },
            {
                "lat": 54.8312721583,
                "lng": -130.3176423255
            },
            {
                "lat": 54.8350747046,
                "lng": -130.3053890148
            },
            {
                "lat": 54.8411713834,
                "lng": -130.2859500121
            },
            {
                "lat": 54.8423309459,
                "lng": -130.2817694764
            },
            {
                "lat": 54.84919703,
                "lng": -130.2807306921
            },
            {
                "lat": 54.856811144,
                "lng": -130.275497262
            },
            {
                "lat": 54.8644261508,
                "lng": -130.2619320816
            },
            {
                "lat": 54.8787037666,
                "lng": -130.2486120566
            },
            {
                "lat": 54.8948828105,
                "lng": -130.2288667822
            },
            {
                "lat": 54.9122538019,
                "lng": -130.2124475864
            },
            {
                "lat": 54.9374772374,
                "lng": -130.1836540223
            },
            {
                "lat": 54.9450913293,
                "lng": -130.173889777
            },
            {
                "lat": 54.9553899091,
                "lng": -130.1622315452
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 8,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 42,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 149664324.3564,
            "FEATURE_LENGTH_M": 69437.5163,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.88473109955586,
            "lng": -130.29313026240587
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.9622011435,
                "lng": -129.9486688121
            },
            {
                "lat": 54.9622007986,
                "lng": -129.9507148257
            },
            {
                "lat": 54.9569738383,
                "lng": -129.9574579939
            },
            {
                "lat": 54.9469373952,
                "lng": -129.9697106491
            },
            {
                "lat": 54.9390831671,
                "lng": -129.9873205194
            },
            {
                "lat": 54.9324225084,
                "lng": -130.0004120642
            },
            {
                "lat": 54.9240957369,
                "lng": -130.0087426781
            },
            {
                "lat": 54.9140997513,
                "lng": -130.0101615213
            },
            {
                "lat": 54.9053608321,
                "lng": -130.0056144009
            },
            {
                "lat": 54.9041060173,
                "lng": -129.9854117082
            },
            {
                "lat": 54.9112432417,
                "lng": -129.9599610026
            },
            {
                "lat": 54.9231409489,
                "lng": -129.9428252207
            },
            {
                "lat": 54.9286162907,
                "lng": -129.937592359
            },
            {
                "lat": 54.9317097295,
                "lng": -129.9387821845
            },
            {
                "lat": 54.9367071177,
                "lng": -129.9406891746
            },
            {
                "lat": 54.9390985402,
                "lng": -129.940048323
            },
            {
                "lat": 54.9622011435,
                "lng": -129.9486688121
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 9,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 18,
            "MANAGEMENT_AREA_NAME": "Subarea 3-18",
            "LABEL": "3-18",
            "FISHERY_AREA_ID": 2004,
            "OBJECTID": 43,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 17141551.3931,
            "FEATURE_LENGTH_M": 17340.1662,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.9341293059353,
            "lng": -129.96663424996473
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.9074971374,
                "lng": -130.0664972004
            },
            {
                "lat": 54.8973843928,
                "lng": -130.0968320338
            },
            {
                "lat": 54.8711397672,
                "lng": -130.1319275971
            },
            {
                "lat": 54.8509866962,
                "lng": -130.1676337173
            },
            {
                "lat": 54.8453588216,
                "lng": -130.1716762698
            },
            {
                "lat": 54.8428649039,
                "lng": -130.1743320013
            },
            {
                "lat": 54.8417703833,
                "lng": -130.1754145304
            },
            {
                "lat": 54.8414194426,
                "lng": -130.1760712356
            },
            {
                "lat": 54.8407973755,
                "lng": -130.1762544596
            },
            {
                "lat": 54.8012805881,
                "lng": -130.2149802352
            },
            {
                "lat": 54.7928281261,
                "lng": -130.1753543873
            },
            {
                "lat": 54.7938200354,
                "lng": -130.1701203704
            },
            {
                "lat": 54.8057748711,
                "lng": -130.1575172125
            },
            {
                "lat": 54.817671869,
                "lng": -130.1450200155
            },
            {
                "lat": 54.8248093185,
                "lng": -130.1212314595
            },
            {
                "lat": 54.8402788635,
                "lng": -130.0885160757
            },
            {
                "lat": 54.8527683964,
                "lng": -130.0629272158
            },
            {
                "lat": 54.8860810053,
                "lng": -130.041519183
            },
            {
                "lat": 54.8991700262,
                "lng": -130.0498506539
            },
            {
                "lat": 54.9074971374,
                "lng": -130.0664972004
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 10,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 3-9",
            "LABEL": "3-9",
            "FISHERY_AREA_ID": 1996,
            "OBJECTID": 44,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 55096529.3502,
            "FEATURE_LENGTH_M": 35176.5328,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.848059957875,
            "lng": -130.13150865272502
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.8407973755,
                "lng": -130.1762544596
            },
            {
                "lat": 54.832019586,
                "lng": -130.2794185494
            },
            {
                "lat": 54.8331411614,
                "lng": -130.2795563312
            },
            {
                "lat": 54.8332971771,
                "lng": -130.2795864585
            },
            {
                "lat": 54.8352473392,
                "lng": -130.2792214374
            },
            {
                "lat": 54.8390083837,
                "lng": -130.2800137385
            },
            {
                "lat": 54.8423309459,
                "lng": -130.2817694764
            },
            {
                "lat": 54.8411713834,
                "lng": -130.2859500121
            },
            {
                "lat": 54.8350747046,
                "lng": -130.3053890148
            },
            {
                "lat": 54.8312721583,
                "lng": -130.3176423255
            },
            {
                "lat": 54.828212768,
                "lng": -130.3274843664
            },
            {
                "lat": 54.8244020869,
                "lng": -130.3537896185
            },
            {
                "lat": 54.8141094965,
                "lng": -130.3713230246
            },
            {
                "lat": 54.7958145412,
                "lng": -130.3884734361
            },
            {
                "lat": 54.777522417,
                "lng": -130.4094381674
            },
            {
                "lat": 54.7790454258,
                "lng": -130.4189601003
            },
            {
                "lat": 54.7885737945,
                "lng": -130.4254459569
            },
            {
                "lat": 54.7950550042,
                "lng": -130.4380185647
            },
            {
                "lat": 54.8064877341,
                "lng": -130.4486994285
            },
            {
                "lat": 54.8122067416,
                "lng": -130.4582208068
            },
            {
                "lat": 54.8224939495,
                "lng": -130.4662321713
            },
            {
                "lat": 54.8281023177,
                "lng": -130.4796903438
            },
            {
                "lat": 54.8280181051,
                "lng": -130.4841308127
            },
            {
                "lat": 54.8280637512,
                "lng": -130.4847260319
            },
            {
                "lat": 54.8283460113,
                "lng": -130.4883119887
            },
            {
                "lat": 54.8279071898,
                "lng": -130.4897916754
            },
            {
                "lat": 54.8259740938,
                "lng": -130.492737718
            },
            {
                "lat": 54.8250732835,
                "lng": -130.4934694863
            },
            {
                "lat": 54.8238381613,
                "lng": -130.4944143463
            },
            {
                "lat": 54.8186606504,
                "lng": -130.4938050716
            },
            {
                "lat": 54.8128164275,
                "lng": -130.4924326086
            },
            {
                "lat": 54.8076088796,
                "lng": -130.4904941693
            },
            {
                "lat": 54.7914931772,
                "lng": -130.477172945
            },
            {
                "lat": 54.7774539992,
                "lng": -130.4605246121
            },
            {
                "lat": 54.7674597707,
                "lng": -130.4529117988
            },
            {
                "lat": 54.758655255,
                "lng": -130.4598076011
            },
            {
                "lat": 54.7477111615,
                "lng": -130.4562379582
            },
            {
                "lat": 54.7384295024,
                "lng": -130.463378626
            },
            {
                "lat": 54.7248687478,
                "lng": -130.4719397376
            },
            {
                "lat": 54.7179951413,
                "lng": -130.4795376921
            },
            {
                "lat": 54.7115816407,
                "lng": -130.4806052322
            },
            {
                "lat": 54.7064331067,
                "lng": -130.4792329914
            },
            {
                "lat": 54.7051459054,
                "lng": -130.4784688865
            },
            {
                "lat": 54.7047423659,
                "lng": -130.4780270461
            },
            {
                "lat": 54.7043540884,
                "lng": -130.477874273
            },
            {
                "lat": 54.64855971,
                "lng": -130.4463352846
            },
            {
                "lat": 54.6483347986,
                "lng": -130.4464109468
            },
            {
                "lat": 54.6480100934,
                "lng": -130.4466702955
            },
            {
                "lat": 54.647430505,
                "lng": -130.4465633068
            },
            {
                "lat": 54.6462638191,
                "lng": -130.4460304605
            },
            {
                "lat": 54.6437797205,
                "lng": -130.444015617
            },
            {
                "lat": 54.6416429148,
                "lng": -130.4400022095
            },
            {
                "lat": 54.6389731867,
                "lng": -130.4372860226
            },
            {
                "lat": 54.6378509774,
                "lng": -130.4401096932
            },
            {
                "lat": 54.6359262163,
                "lng": -130.4424130461
            },
            {
                "lat": 54.6350182866,
                "lng": -130.4443368738
            },
            {
                "lat": 54.6335938263,
                "lng": -130.444321694
            },
            {
                "lat": 54.6326187684,
                "lng": -130.4443214542
            },
            {
                "lat": 54.6320916001,
                "lng": -130.4435425025
            },
            {
                "lat": 54.6322328079,
                "lng": -130.4432068434
            },
            {
                "lat": 54.6322780729,
                "lng": -130.4428558777
            },
            {
                "lat": 54.6321794844,
                "lng": -130.4420779304
            },
            {
                "lat": 54.6321292714,
                "lng": -130.4404913574
            },
            {
                "lat": 54.6324273612,
                "lng": -130.4356997559
            },
            {
                "lat": 54.6320310681,
                "lng": -130.4314569592
            },
            {
                "lat": 54.6297599654,
                "lng": -130.4259645577
            },
            {
                "lat": 54.6277349564,
                "lng": -130.4221348293
            },
            {
                "lat": 54.6263502488,
                "lng": -130.4194948349
            },
            {
                "lat": 54.6239311471,
                "lng": -130.4140170893
            },
            {
                "lat": 54.6219058049,
                "lng": -130.4080362187
            },
            {
                "lat": 54.6213108497,
                "lng": -130.4048310896
            },
            {
                "lat": 54.6241915138,
                "lng": -130.40469392
            },
            {
                "lat": 54.629637779,
                "lng": -130.3894044499
            },
            {
                "lat": 54.6317788957,
                "lng": -130.3868257757
            },
            {
                "lat": 54.6357236453,
                "lng": -130.3804323585
            },
            {
                "lat": 54.6276748516,
                "lng": -130.3651422677
            },
            {
                "lat": 54.619087648,
                "lng": -130.3495793952
            },
            {
                "lat": 54.6182819569,
                "lng": -130.3391108585
            },
            {
                "lat": 54.6292841784,
                "lng": -130.328918631
            },
            {
                "lat": 54.642700316,
                "lng": -130.301544028
            },
            {
                "lat": 54.6569217246,
                "lng": -130.2849124764
            },
            {
                "lat": 54.6681934644,
                "lng": -130.2825018192
            },
            {
                "lat": 54.6751702357,
                "lng": -130.2983248529
            },
            {
                "lat": 54.6652413689,
                "lng": -130.3412627225
            },
            {
                "lat": 54.681877299,
                "lng": -130.3455508367
            },
            {
                "lat": 54.6953343258,
                "lng": -130.3445589085
            },
            {
                "lat": 54.7058950194,
                "lng": -130.3415839102
            },
            {
                "lat": 54.7409203568,
                "lng": -130.2950898617
            },
            {
                "lat": 54.7583926702,
                "lng": -130.2507779962
            },
            {
                "lat": 54.7873849957,
                "lng": -130.2334291771
            },
            {
                "lat": 54.8012805881,
                "lng": -130.2149802352
            },
            {
                "lat": 54.8407973755,
                "lng": -130.1762544596
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 11,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 3-7",
            "LABEL": "3-7",
            "FISHERY_AREA_ID": 2393,
            "OBJECTID": 45,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 251324288.5835,
            "FEATURE_LENGTH_M": 94483.9868,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.72465383236955,
            "lng": -130.39965344335764
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.8238381613,
                "lng": -130.4944143463
            },
            {
                "lat": 54.8178516142,
                "lng": -130.4982599697
            },
            {
                "lat": 54.8111076707,
                "lng": -130.5065769319
            },
            {
                "lat": 54.8068810303,
                "lng": -130.5136103848
            },
            {
                "lat": 54.8050696517,
                "lng": -130.5178076407
            },
            {
                "lat": 54.7695921793,
                "lng": -130.5603488452
            },
            {
                "lat": 54.7625814002,
                "lng": -130.5724795973
            },
            {
                "lat": 54.7525900848,
                "lng": -130.5610513346
            },
            {
                "lat": 54.7435460427,
                "lng": -130.5698541229
            },
            {
                "lat": 54.7361717908,
                "lng": -130.5803220107
            },
            {
                "lat": 54.7375988379,
                "lng": -130.5881805568
            },
            {
                "lat": 54.7406926912,
                "lng": -130.5938867918
            },
            {
                "lat": 54.7483059989,
                "lng": -130.5986479539
            },
            {
                "lat": 54.7547294996,
                "lng": -130.6036527497
            },
            {
                "lat": 54.7606315027,
                "lng": -130.6039434857
            },
            {
                "lat": 54.768116244,
                "lng": -130.6181946166
            },
            {
                "lat": 54.7713850399,
                "lng": -130.6239928472
            },
            {
                "lat": 54.7630569129,
                "lng": -130.6254272532
            },
            {
                "lat": 54.7578246394,
                "lng": -130.6199493917
            },
            {
                "lat": 54.7511591938,
                "lng": -130.6106721821
            },
            {
                "lat": 54.7444997278,
                "lng": -130.6032867631
            },
            {
                "lat": 54.7411689639,
                "lng": -130.5992433306
            },
            {
                "lat": 54.7335510659,
                "lng": -130.594726107
            },
            {
                "lat": 54.7283175427,
                "lng": -130.5885318902
            },
            {
                "lat": 54.7252235491,
                "lng": -130.5785526278
            },
            {
                "lat": 54.7233198268,
                "lng": -130.5702212937
            },
            {
                "lat": 54.7176098162,
                "lng": -130.5571285019
            },
            {
                "lat": 54.7159460012,
                "lng": -130.5442817193
            },
            {
                "lat": 54.7180863077,
                "lng": -130.5371405815
            },
            {
                "lat": 54.7264147204,
                "lng": -130.534287244
            },
            {
                "lat": 54.7294625072,
                "lng": -130.529297695
            },
            {
                "lat": 54.7321248784,
                "lng": -130.5152589394
            },
            {
                "lat": 54.7306969807,
                "lng": -130.504547289
            },
            {
                "lat": 54.7257003433,
                "lng": -130.4962153476
            },
            {
                "lat": 54.7145193001,
                "lng": -130.4852753484
            },
            {
                "lat": 54.7115816407,
                "lng": -130.4806052322
            },
            {
                "lat": 54.7179951413,
                "lng": -130.4795376921
            },
            {
                "lat": 54.7248687478,
                "lng": -130.4719397376
            },
            {
                "lat": 54.7384295024,
                "lng": -130.463378626
            },
            {
                "lat": 54.7477111615,
                "lng": -130.4562379582
            },
            {
                "lat": 54.758655255,
                "lng": -130.4598076011
            },
            {
                "lat": 54.7674597707,
                "lng": -130.4529117988
            },
            {
                "lat": 54.7774539992,
                "lng": -130.4605246121
            },
            {
                "lat": 54.7914931772,
                "lng": -130.477172945
            },
            {
                "lat": 54.8076088796,
                "lng": -130.4904941693
            },
            {
                "lat": 54.8128164275,
                "lng": -130.4924326086
            },
            {
                "lat": 54.8186606504,
                "lng": -130.4938050716
            },
            {
                "lat": 54.8238381613,
                "lng": -130.4944143463
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 12,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 46,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 58682970.5793,
            "FEATURE_LENGTH_M": 45818.5636,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.755998838172935,
            "lng": -130.53901104354588
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.7938200354,
                "lng": -130.1701203704
            },
            {
                "lat": 54.7928281261,
                "lng": -130.1753543873
            },
            {
                "lat": 54.8012805881,
                "lng": -130.2149802352
            },
            {
                "lat": 54.7873849957,
                "lng": -130.2334291771
            },
            {
                "lat": 54.7583926702,
                "lng": -130.2507779962
            },
            {
                "lat": 54.7409203568,
                "lng": -130.2950898617
            },
            {
                "lat": 54.7058950194,
                "lng": -130.3415839102
            },
            {
                "lat": 54.6953343258,
                "lng": -130.3445589085
            },
            {
                "lat": 54.681877299,
                "lng": -130.3455508367
            },
            {
                "lat": 54.6840253707,
                "lng": -130.3133535884
            },
            {
                "lat": 54.6883160799,
                "lng": -130.2661287517
            },
            {
                "lat": 54.6971738743,
                "lng": -130.2454677678
            },
            {
                "lat": 54.7111282017,
                "lng": -130.2344659594
            },
            {
                "lat": 54.7132754043,
                "lng": -130.2290947558
            },
            {
                "lat": 54.7164810003,
                "lng": -130.2288519858
            },
            {
                "lat": 54.7264865966,
                "lng": -130.2174984345
            },
            {
                "lat": 54.7278482958,
                "lng": -130.201233227
            },
            {
                "lat": 54.737185758,
                "lng": -130.1901101754
            },
            {
                "lat": 54.7581253177,
                "lng": -130.1793970579
            },
            {
                "lat": 54.7681204542,
                "lng": -130.1767880928
            },
            {
                "lat": 54.7643129359,
                "lng": -130.156799971
            },
            {
                "lat": 54.7626459558,
                "lng": -130.1275328131
            },
            {
                "lat": 54.7664531371,
                "lng": -130.1172940016
            },
            {
                "lat": 54.7754976272,
                "lng": -130.1172939516
            },
            {
                "lat": 54.7840617841,
                "lng": -130.1213541415
            },
            {
                "lat": 54.79096239,
                "lng": -130.1349029842
            },
            {
                "lat": 54.7885828708,
                "lng": -130.155853105
            },
            {
                "lat": 54.7919150425,
                "lng": -130.1675112743
            },
            {
                "lat": 54.7938200354,
                "lng": -130.1701203704
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 13,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 3-8",
            "LABEL": "3-8",
            "FISHERY_AREA_ID": 1995,
            "OBJECTID": 47,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 78690464.8206,
            "FEATURE_LENGTH_M": 45689.4399,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.74841901892414,
            "lng": -130.2111206238793
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.7713850399,
                "lng": -130.6239928472
            },
            {
                "lat": 54.7757800753,
                "lng": -130.6241610561
            },
            {
                "lat": 54.7834807817,
                "lng": -130.6241608
            },
            {
                "lat": 54.7827484565,
                "lng": -130.6288908767
            },
            {
                "lat": 54.7630840864,
                "lng": -130.6590879166
            },
            {
                "lat": 54.7381089531,
                "lng": -130.6257478726
            },
            {
                "lat": 54.7247512087,
                "lng": -130.6286626544
            },
            {
                "lat": 54.7074696613,
                "lng": -130.6156457704
            },
            {
                "lat": 54.703906593,
                "lng": -130.7785802368
            },
            {
                "lat": 54.6268461398,
                "lng": -130.7785801911
            },
            {
                "lat": 54.6049155665,
                "lng": -130.5374756557
            },
            {
                "lat": 54.6427915234,
                "lng": -130.4543308426
            },
            {
                "lat": 54.6426047144,
                "lng": -130.453048919
            },
            {
                "lat": 54.6443671067,
                "lng": -130.4497221873
            },
            {
                "lat": 54.647430505,
                "lng": -130.4465633068
            },
            {
                "lat": 54.6480100934,
                "lng": -130.4466702955
            },
            {
                "lat": 54.6483347986,
                "lng": -130.4464109468
            },
            {
                "lat": 54.64855971,
                "lng": -130.4463352846
            },
            {
                "lat": 54.7043540884,
                "lng": -130.477874273
            },
            {
                "lat": 54.7047423659,
                "lng": -130.4780270461
            },
            {
                "lat": 54.7051459054,
                "lng": -130.4784688865
            },
            {
                "lat": 54.7064331067,
                "lng": -130.4792329914
            },
            {
                "lat": 54.7115816407,
                "lng": -130.4806052322
            },
            {
                "lat": 54.7145193001,
                "lng": -130.4852753484
            },
            {
                "lat": 54.7257003433,
                "lng": -130.4962153476
            },
            {
                "lat": 54.7306969807,
                "lng": -130.504547289
            },
            {
                "lat": 54.7321248784,
                "lng": -130.5152589394
            },
            {
                "lat": 54.7294625072,
                "lng": -130.529297695
            },
            {
                "lat": 54.7264147204,
                "lng": -130.534287244
            },
            {
                "lat": 54.7180863077,
                "lng": -130.5371405815
            },
            {
                "lat": 54.7159460012,
                "lng": -130.5442817193
            },
            {
                "lat": 54.7176098162,
                "lng": -130.5571285019
            },
            {
                "lat": 54.7233198268,
                "lng": -130.5702212937
            },
            {
                "lat": 54.7252235491,
                "lng": -130.5785526278
            },
            {
                "lat": 54.7283175427,
                "lng": -130.5885318902
            },
            {
                "lat": 54.7335510659,
                "lng": -130.594726107
            },
            {
                "lat": 54.7411689639,
                "lng": -130.5992433306
            },
            {
                "lat": 54.7444997278,
                "lng": -130.6032867631
            },
            {
                "lat": 54.7511591938,
                "lng": -130.6106721821
            },
            {
                "lat": 54.7578246394,
                "lng": -130.6199493917
            },
            {
                "lat": 54.7630569129,
                "lng": -130.6254272532
            },
            {
                "lat": 54.7713850399,
                "lng": -130.6239928472
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 14,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 3-3",
            "LABEL": "3-3",
            "FISHERY_AREA_ID": 1991,
            "OBJECTID": 48,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 231581491.5146,
            "FEATURE_LENGTH_M": 76585.0229,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.71397379614763,
            "lng": -130.55667410576427
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.6166682405,
                "lng": -132.7268835935
            },
            {
                "lat": 54.6625527735,
                "lng": -132.6825558182
            },
            {
                "lat": 54.5174750602,
                "lng": -133.2338865344
            },
            {
                "lat": 54.4958113722,
                "lng": -133.3027791796
            },
            {
                "lat": 54.4180330771,
                "lng": -133.5661317186
            },
            {
                "lat": 54.3499795605,
                "lng": -133.7939298792
            },
            {
                "lat": 54.3491482386,
                "lng": -133.7978205589
            },
            {
                "lat": 54.3433148328,
                "lng": -133.8189241554
            },
            {
                "lat": 54.2494503149,
                "lng": -134.229644634
            },
            {
                "lat": 54.2494431566,
                "lng": -133.0800018402
            },
            {
                "lat": 54.4000018204,
                "lng": -132.9356232431
            },
            {
                "lat": 54.6166682405,
                "lng": -132.7268835935
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 15,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 101,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 101-3",
            "LABEL": "101-3",
            "FISHERY_AREA_ID": 2288,
            "OBJECTID": 49,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1659065969.9449,
            "FEATURE_LENGTH_M": 237801.2738,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.43904555731666,
            "lng": -133.32458872905002
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.6625527735,
                "lng": -132.6825558182
            },
            {
                "lat": 54.6166682405,
                "lng": -132.7268835935
            },
            {
                "lat": 54.6166679504,
                "lng": -131.9999999345
            },
            {
                "lat": 54.6771887769,
                "lng": -132.0000000248
            },
            {
                "lat": 54.6625527735,
                "lng": -132.6825558182
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 16,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 101,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 101-4",
            "LABEL": "101-4",
            "FISHERY_AREA_ID": 2289,
            "OBJECTID": 50,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 267966463.6846,
            "FEATURE_LENGTH_M": 103395.8043,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.64712610296,
            "lng": -132.41839903783998
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.6771887769,
                "lng": -132.0000000248
            },
            {
                "lat": 54.6166679504,
                "lng": -131.9999999345
            },
            {
                "lat": 54.616667892,
                "lng": -131.3000024264
            },
            {
                "lat": 54.6925010561,
                "lng": -131.3000026503
            },
            {
                "lat": 54.6771887769,
                "lng": -132.0000000248
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 17,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 101,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 101-8",
            "LABEL": "101-8",
            "FISHERY_AREA_ID": 2293,
            "OBJECTID": 51,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 342911163.1563,
            "FEATURE_LENGTH_M": 105372.7483,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.65604289046,
            "lng": -131.72000101216
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.6925010561,
                "lng": -131.3000026503
            },
            {
                "lat": 54.616667892,
                "lng": -131.3000024264
            },
            {
                "lat": 54.4483258291,
                "lng": -131.3000026782
            },
            {
                "lat": 54.4483269295,
                "lng": -131.0931579365
            },
            {
                "lat": 54.6166682196,
                "lng": -131.183333739
            },
            {
                "lat": 54.6950594625,
                "lng": -131.1833482058
            },
            {
                "lat": 54.6925010561,
                "lng": -131.3000026503
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 18,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 103,
            "MANAGEMENT_SUBAREA": 103,
            "MANAGEMENT_AREA_NAME": "Subarea 103",
            "LABEL": "103",
            "FISHERY_AREA_ID": 2614,
            "OBJECTID": 52,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 260937828.5383,
            "FEATURE_LENGTH_M": 76564.885,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.601435777842866,
            "lng": -131.2371214695
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.6950594625,
                "lng": -131.1833482058
            },
            {
                "lat": 54.6166682196,
                "lng": -131.183333739
            },
            {
                "lat": 54.4483269295,
                "lng": -131.0931579365
            },
            {
                "lat": 54.4483254978,
                "lng": -130.987671355
            },
            {
                "lat": 54.4574502692,
                "lng": -130.9802858393
            },
            {
                "lat": 54.4574508978,
                "lng": -130.958557318
            },
            {
                "lat": 54.4635260825,
                "lng": -130.9585411792
            },
            {
                "lat": 54.4685730686,
                "lng": -130.9545149
            },
            {
                "lat": 54.4686013991,
                "lng": -130.952102676
            },
            {
                "lat": 54.4686013947,
                "lng": -130.9491424502
            },
            {
                "lat": 54.4706689227,
                "lng": -130.9462159426
            },
            {
                "lat": 54.4739718089,
                "lng": -130.9385066222
            },
            {
                "lat": 54.4755860085,
                "lng": -130.9392857415
            },
            {
                "lat": 54.4816323903,
                "lng": -130.9445797435
            },
            {
                "lat": 54.4941248228,
                "lng": -130.9457694604
            },
            {
                "lat": 54.5173269175,
                "lng": -130.9475405141
            },
            {
                "lat": 54.5387417681,
                "lng": -130.9487302765
            },
            {
                "lat": 54.555396248,
                "lng": -130.9344636129
            },
            {
                "lat": 54.5720520706,
                "lng": -130.9112547231
            },
            {
                "lat": 54.5791926499,
                "lng": -130.868423574
            },
            {
                "lat": 54.5881147811,
                "lng": -130.8339238633
            },
            {
                "lat": 54.6025930899,
                "lng": -130.8043366322
            },
            {
                "lat": 54.6068537445,
                "lng": -130.7906182686
            },
            {
                "lat": 54.6120879711,
                "lng": -130.7794349279
            },
            {
                "lat": 54.6202542709,
                "lng": -130.7785801104
            },
            {
                "lat": 54.6232448909,
                "lng": -130.7750551239
            },
            {
                "lat": 54.6246298016,
                "lng": -130.7761236294
            },
            {
                "lat": 54.625186792,
                "lng": -130.7777857351
            },
            {
                "lat": 54.6255643998,
                "lng": -130.778320124
            },
            {
                "lat": 54.6268461398,
                "lng": -130.7785801911
            },
            {
                "lat": 54.703906593,
                "lng": -130.7785802368
            },
            {
                "lat": 54.6950594625,
                "lng": -131.1833482058
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 19,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 3-1",
            "LABEL": "3-1",
            "FISHERY_AREA_ID": 2392,
            "OBJECTID": 53,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 512417403.1698,
            "FEATURE_LENGTH_M": 98323.5013,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.55330058642813,
            "lng": -130.91750352682186
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.6166679504,
                "lng": -131.9999999345
            },
            {
                "lat": 54.6166682405,
                "lng": -132.7268835935
            },
            {
                "lat": 54.4000018204,
                "lng": -132.9356232431
            },
            {
                "lat": 54.4000014937,
                "lng": -131.9999999324
            },
            {
                "lat": 54.6166679504,
                "lng": -131.9999999345
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 20,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 101,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 101-5",
            "LABEL": "101-5",
            "FISHERY_AREA_ID": 2290,
            "OBJECTID": 54,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1296918582.0211,
            "FEATURE_LENGTH_M": 159301.0362,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.53000149108,
            "lng": -132.3325013276
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.7278482958,
                "lng": -130.201233227
            },
            {
                "lat": 54.7264865966,
                "lng": -130.2174984345
            },
            {
                "lat": 54.7164810003,
                "lng": -130.2288519858
            },
            {
                "lat": 54.7132754043,
                "lng": -130.2290947558
            },
            {
                "lat": 54.7111282017,
                "lng": -130.2344659594
            },
            {
                "lat": 54.6971738743,
                "lng": -130.2454677678
            },
            {
                "lat": 54.6940004014,
                "lng": -130.2232504086
            },
            {
                "lat": 54.6937630592,
                "lng": -130.1770783962
            },
            {
                "lat": 54.6821021542,
                "lng": -130.1383061139
            },
            {
                "lat": 54.66895621,
                "lng": -130.1221165446
            },
            {
                "lat": 54.6398081838,
                "lng": -130.0905913515
            },
            {
                "lat": 54.6334643475,
                "lng": -130.0719447484
            },
            {
                "lat": 54.6179577208,
                "lng": -130.0547021068
            },
            {
                "lat": 54.6002652601,
                "lng": -130.0253602431
            },
            {
                "lat": 54.5838698671,
                "lng": -129.9796296505
            },
            {
                "lat": 54.5817151327,
                "lng": -129.9317313918
            },
            {
                "lat": 54.5972442855,
                "lng": -129.8894499278
            },
            {
                "lat": 54.6205454797,
                "lng": -129.8773801778
            },
            {
                "lat": 54.6468655064,
                "lng": -129.8959201657
            },
            {
                "lat": 54.6270172997,
                "lng": -129.9520114543
            },
            {
                "lat": 54.6369396264,
                "lng": -129.9921422946
            },
            {
                "lat": 54.6496502584,
                "lng": -130.0212404093
            },
            {
                "lat": 54.6499207481,
                "lng": -130.0209957543
            },
            {
                "lat": 54.6903720946,
                "lng": -130.0519262843
            },
            {
                "lat": 54.7076226417,
                "lng": -130.082260167
            },
            {
                "lat": 54.7171404425,
                "lng": -130.1096339125
            },
            {
                "lat": 54.7284437572,
                "lng": -130.1548311511
            },
            {
                "lat": 54.7296331986,
                "lng": -130.1780400953
            },
            {
                "lat": 54.7278482958,
                "lng": -130.201233227
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 21,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 3-10",
            "LABEL": "3-10",
            "FISHERY_AREA_ID": 1997,
            "OBJECTID": 55,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 126145209.0994,
            "FEATURE_LENGTH_M": 64966.1996,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.66957032222068,
            "lng": -130.08959958988623
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.616667892,
                "lng": -131.3000024264
            },
            {
                "lat": 54.6166679504,
                "lng": -131.9999999345
            },
            {
                "lat": 54.4000014937,
                "lng": -131.9999999324
            },
            {
                "lat": 54.4000018341,
                "lng": -131.3512873071
            },
            {
                "lat": 54.4483258291,
                "lng": -131.3000026782
            },
            {
                "lat": 54.616667892,
                "lng": -131.3000024264
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 22,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 101,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 101-9",
            "LABEL": "101-9",
            "FISHERY_AREA_ID": 2294,
            "OBJECTID": 56,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1084926020.1734,
            "FEATURE_LENGTH_M": 136421.4484,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.516388815216665,
            "lng": -131.54188245083333
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.647430505,
                "lng": -130.4465633068
            },
            {
                "lat": 54.6443671067,
                "lng": -130.4497221873
            },
            {
                "lat": 54.6426047144,
                "lng": -130.453048919
            },
            {
                "lat": 54.6432463851,
                "lng": -130.4502107274
            },
            {
                "lat": 54.6429243595,
                "lng": -130.447799386
            },
            {
                "lat": 54.6414296656,
                "lng": -130.4464108697
            },
            {
                "lat": 54.6390274644,
                "lng": -130.4466858201
            },
            {
                "lat": 54.6370965354,
                "lng": -130.4481353833
            },
            {
                "lat": 54.6364588635,
                "lng": -130.4486084922
            },
            {
                "lat": 54.6353912261,
                "lng": -130.4498291886
            },
            {
                "lat": 54.635071271,
                "lng": -130.4482266667
            },
            {
                "lat": 54.6350403752,
                "lng": -130.4458932747
            },
            {
                "lat": 54.6350182866,
                "lng": -130.4443368738
            },
            {
                "lat": 54.6359262163,
                "lng": -130.4424130461
            },
            {
                "lat": 54.6378509774,
                "lng": -130.4401096932
            },
            {
                "lat": 54.6389731867,
                "lng": -130.4372860226
            },
            {
                "lat": 54.6416429148,
                "lng": -130.4400022095
            },
            {
                "lat": 54.6437797205,
                "lng": -130.444015617
            },
            {
                "lat": 54.6462638191,
                "lng": -130.4460304605
            },
            {
                "lat": 54.647430505,
                "lng": -130.4465633068
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 23,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 57,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 538901.6934,
            "FEATURE_LENGTH_M": 4097.0407,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.64034870491501,
            "lng": -130.446094572565
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.6426047144,
                "lng": -130.453048919
            },
            {
                "lat": 54.6427915234,
                "lng": -130.4543308426
            },
            {
                "lat": 54.6049155665,
                "lng": -130.5374756557
            },
            {
                "lat": 54.5669288257,
                "lng": -130.4804378576
            },
            {
                "lat": 54.5638507821,
                "lng": -130.4796751113
            },
            {
                "lat": 54.552821445,
                "lng": -130.4748684093
            },
            {
                "lat": 54.5511856592,
                "lng": -130.473236472
            },
            {
                "lat": 54.5430677843,
                "lng": -130.4651184833
            },
            {
                "lat": 54.5380700947,
                "lng": -130.4632105399
            },
            {
                "lat": 54.5306666661,
                "lng": -130.4638526143
            },
            {
                "lat": 54.529617585,
                "lng": -130.4639427685
            },
            {
                "lat": 54.5261108132,
                "lng": -130.4630275154
            },
            {
                "lat": 54.5248640299,
                "lng": -130.462570441
            },
            {
                "lat": 54.5246813106,
                "lng": -130.4611209363
            },
            {
                "lat": 54.5174599406,
                "lng": -130.4495389048
            },
            {
                "lat": 54.5174794404,
                "lng": -130.449219094
            },
            {
                "lat": 54.5176316061,
                "lng": -130.4464875815
            },
            {
                "lat": 54.5180779453,
                "lng": -130.4430394179
            },
            {
                "lat": 54.5245245168,
                "lng": -130.4423528856
            },
            {
                "lat": 54.5316433161,
                "lng": -130.4393771938
            },
            {
                "lat": 54.5583390262,
                "lng": -130.4202421579
            },
            {
                "lat": 54.5598751079,
                "lng": -130.4159239051
            },
            {
                "lat": 54.5638456904,
                "lng": -130.4163054715
            },
            {
                "lat": 54.5730705026,
                "lng": -130.3962553538
            },
            {
                "lat": 54.575565309,
                "lng": -130.3929903783
            },
            {
                "lat": 54.5842511327,
                "lng": -130.3992916089
            },
            {
                "lat": 54.5909584301,
                "lng": -130.4105828567
            },
            {
                "lat": 54.6156047745,
                "lng": -130.4303282576
            },
            {
                "lat": 54.6260523028,
                "lng": -130.4306645994
            },
            {
                "lat": 54.6324273612,
                "lng": -130.4356997559
            },
            {
                "lat": 54.6321292714,
                "lng": -130.4404913574
            },
            {
                "lat": 54.6321794844,
                "lng": -130.4420779304
            },
            {
                "lat": 54.6322780729,
                "lng": -130.4428558777
            },
            {
                "lat": 54.6322328079,
                "lng": -130.4432068434
            },
            {
                "lat": 54.6320916001,
                "lng": -130.4435425025
            },
            {
                "lat": 54.6326187684,
                "lng": -130.4443214542
            },
            {
                "lat": 54.6335938263,
                "lng": -130.444321694
            },
            {
                "lat": 54.6350182866,
                "lng": -130.4443368738
            },
            {
                "lat": 54.6350403752,
                "lng": -130.4458932747
            },
            {
                "lat": 54.635071271,
                "lng": -130.4482266667
            },
            {
                "lat": 54.6353912261,
                "lng": -130.4498291886
            },
            {
                "lat": 54.6364588635,
                "lng": -130.4486084922
            },
            {
                "lat": 54.6370965354,
                "lng": -130.4481353833
            },
            {
                "lat": 54.6390274644,
                "lng": -130.4466858201
            },
            {
                "lat": 54.6414296656,
                "lng": -130.4464108697
            },
            {
                "lat": 54.6429243595,
                "lng": -130.447799386
            },
            {
                "lat": 54.6432463851,
                "lng": -130.4502107274
            },
            {
                "lat": 54.6426047144,
                "lng": -130.453048919
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 24,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 3-4",
            "LABEL": "3-4",
            "FISHERY_AREA_ID": 1992,
            "OBJECTID": 58,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 58135095.5635,
            "FEATURE_LENGTH_M": 36170.7839,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.5895711704375,
            "lng": -130.44675456770835
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.6268461398,
                "lng": -130.7785801911
            },
            {
                "lat": 54.6255643998,
                "lng": -130.778320124
            },
            {
                "lat": 54.625186792,
                "lng": -130.7777857351
            },
            {
                "lat": 54.6246298016,
                "lng": -130.7761236294
            },
            {
                "lat": 54.6232448909,
                "lng": -130.7750551239
            },
            {
                "lat": 54.6202350163,
                "lng": -130.7753903056
            },
            {
                "lat": 54.590812471,
                "lng": -130.7611850437
            },
            {
                "lat": 54.5832172938,
                "lng": -130.7614438391
            },
            {
                "lat": 54.5812334294,
                "lng": -130.7574772393
            },
            {
                "lat": 54.5798029887,
                "lng": -130.7558738377
            },
            {
                "lat": 54.5685534498,
                "lng": -130.7087555065
            },
            {
                "lat": 54.5669288257,
                "lng": -130.4804378576
            },
            {
                "lat": 54.6049155665,
                "lng": -130.5374756557
            },
            {
                "lat": 54.6268461398,
                "lng": -130.7785801911
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 25,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 3-2",
            "LABEL": "3-2",
            "FISHERY_AREA_ID": 1990,
            "OBJECTID": 59,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 83109969.7477,
            "FEATURE_LENGTH_M": 44968.3663,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.60342980036428,
            "lng": -130.72874887712857
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.5832172938,
                "lng": -130.7614438391
            },
            {
                "lat": 54.590812471,
                "lng": -130.7611850437
            },
            {
                "lat": 54.6202350163,
                "lng": -130.7753903056
            },
            {
                "lat": 54.6232448909,
                "lng": -130.7750551239
            },
            {
                "lat": 54.6202542709,
                "lng": -130.7785801104
            },
            {
                "lat": 54.6120879711,
                "lng": -130.7794349279
            },
            {
                "lat": 54.6068537445,
                "lng": -130.7906182686
            },
            {
                "lat": 54.6025930899,
                "lng": -130.8043366322
            },
            {
                "lat": 54.5881147811,
                "lng": -130.8339238633
            },
            {
                "lat": 54.5791926499,
                "lng": -130.868423574
            },
            {
                "lat": 54.5720520706,
                "lng": -130.9112547231
            },
            {
                "lat": 54.555396248,
                "lng": -130.9344636129
            },
            {
                "lat": 54.5387417681,
                "lng": -130.9487302765
            },
            {
                "lat": 54.5173269175,
                "lng": -130.9475405141
            },
            {
                "lat": 54.4941248228,
                "lng": -130.9457694604
            },
            {
                "lat": 54.4816323903,
                "lng": -130.9445797435
            },
            {
                "lat": 54.4755860085,
                "lng": -130.9392857415
            },
            {
                "lat": 54.4739718089,
                "lng": -130.9385066222
            },
            {
                "lat": 54.4808263017,
                "lng": -130.9328464823
            },
            {
                "lat": 54.4847877089,
                "lng": -130.9228977837
            },
            {
                "lat": 54.4933537863,
                "lng": -130.9133760406
            },
            {
                "lat": 54.5111990328,
                "lng": -130.9005284193
            },
            {
                "lat": 54.5121498722,
                "lng": -130.8903045998
            },
            {
                "lat": 54.5102465806,
                "lng": -130.8791201196
            },
            {
                "lat": 54.5188144126,
                "lng": -130.8619843026
            },
            {
                "lat": 54.5259517727,
                "lng": -130.850082121
            },
            {
                "lat": 54.5354686537,
                "lng": -130.8312830399
            },
            {
                "lat": 54.5414202303,
                "lng": -130.8186799117
            },
            {
                "lat": 54.5452266241,
                "lng": -130.8055880439
            },
            {
                "lat": 54.5497821825,
                "lng": -130.7935330999
            },
            {
                "lat": 54.5552143566,
                "lng": -130.7892461235
            },
            {
                "lat": 54.5667074352,
                "lng": -130.7754516489
            },
            {
                "lat": 54.5761151044,
                "lng": -130.7680358991
            },
            {
                "lat": 54.5832172938,
                "lng": -130.7614438391
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 26,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 60,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 80075747.7798,
            "FEATURE_LENGTH_M": 45174.9301,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.54782116360295,
            "lng": -130.85096834875884
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.6317788957,
                "lng": -130.3868257757
            },
            {
                "lat": 54.629637779,
                "lng": -130.3894044499
            },
            {
                "lat": 54.6241915138,
                "lng": -130.40469392
            },
            {
                "lat": 54.6213108497,
                "lng": -130.4048310896
            },
            {
                "lat": 54.6121211915,
                "lng": -130.3938444517
            },
            {
                "lat": 54.6043891544,
                "lng": -130.3833011558
            },
            {
                "lat": 54.5924374375,
                "lng": -130.3903196925
            },
            {
                "lat": 54.5804895092,
                "lng": -130.3804779399
            },
            {
                "lat": 54.5734597912,
                "lng": -130.3706361521
            },
            {
                "lat": 54.5615115968,
                "lng": -130.3509674321
            },
            {
                "lat": 54.5333946849,
                "lng": -130.3249509206
            },
            {
                "lat": 54.5024639663,
                "lng": -130.2905126274
            },
            {
                "lat": 54.4518543016,
                "lng": -130.2321621232
            },
            {
                "lat": 54.4033543134,
                "lng": -130.1625826281
            },
            {
                "lat": 54.3752367566,
                "lng": -130.1161798356
            },
            {
                "lat": 54.3365789803,
                "lng": -130.0824430741
            },
            {
                "lat": 54.2979162906,
                "lng": -130.0325470036
            },
            {
                "lat": 54.281749868,
                "lng": -129.9805299924
            },
            {
                "lat": 54.2965124707,
                "lng": -129.9727937926
            },
            {
                "lat": 54.3267362322,
                "lng": -129.9636538565
            },
            {
                "lat": 54.3527453221,
                "lng": -129.9812312651
            },
            {
                "lat": 54.3570553874,
                "lng": -130.0039061523
            },
            {
                "lat": 54.3727604748,
                "lng": -130.0324704472
            },
            {
                "lat": 54.3906066846,
                "lng": -130.0481711296
            },
            {
                "lat": 54.4155887184,
                "lng": -130.041976841
            },
            {
                "lat": 54.4550899997,
                "lng": -130.008911844
            },
            {
                "lat": 54.4841875426,
                "lng": -129.9608459995
            },
            {
                "lat": 54.5179298827,
                "lng": -129.9643557074
            },
            {
                "lat": 54.5256619663,
                "lng": -129.9819332464
            },
            {
                "lat": 54.5073855158,
                "lng": -130.0613555289
            },
            {
                "lat": 54.4757540248,
                "lng": -130.1119691455
            },
            {
                "lat": 54.4736470965,
                "lng": -130.1527401835
            },
            {
                "lat": 54.5214459158,
                "lng": -130.1977236652
            },
            {
                "lat": 54.5453454149,
                "lng": -130.2420043055
            },
            {
                "lat": 54.5601044687,
                "lng": -130.2764584237
            },
            {
                "lat": 54.5804901146,
                "lng": -130.2799686921
            },
            {
                "lat": 54.5959551352,
                "lng": -130.314407807
            },
            {
                "lat": 54.6135253875,
                "lng": -130.3481449413
            },
            {
                "lat": 54.624069478,
                "lng": -130.3657218695
            },
            {
                "lat": 54.6275854974,
                "lng": -130.3762656132
            },
            {
                "lat": 54.6317788957,
                "lng": -130.3868257757
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 27,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 3-6",
            "LABEL": "3-6",
            "FISHERY_AREA_ID": 1994,
            "OBJECTID": 61,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 310042981.8853,
            "FEATURE_LENGTH_M": 118774.6118,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.49916679285121,
            "lng": -130.198806012122
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.5669288257,
                "lng": -130.4804378576
            },
            {
                "lat": 54.5685534498,
                "lng": -130.7087555065
            },
            {
                "lat": 54.5798029887,
                "lng": -130.7558738377
            },
            {
                "lat": 54.5812334294,
                "lng": -130.7574772393
            },
            {
                "lat": 54.5832172938,
                "lng": -130.7614438391
            },
            {
                "lat": 54.5761151044,
                "lng": -130.7680358991
            },
            {
                "lat": 54.5667074352,
                "lng": -130.7754516489
            },
            {
                "lat": 54.5552143566,
                "lng": -130.7892461235
            },
            {
                "lat": 54.5497821825,
                "lng": -130.7935330999
            },
            {
                "lat": 54.5444071517,
                "lng": -130.7934112731
            },
            {
                "lat": 54.4973687767,
                "lng": -130.7933958459
            },
            {
                "lat": 54.4967804668,
                "lng": -130.7936856386
            },
            {
                "lat": 54.4954830689,
                "lng": -130.7938535686
            },
            {
                "lat": 54.4923701455,
                "lng": -130.7942353282
            },
            {
                "lat": 54.4868428852,
                "lng": -130.7921291385
            },
            {
                "lat": 54.4726179937,
                "lng": -130.7903905062
            },
            {
                "lat": 54.4726218416,
                "lng": -130.7758020447
            },
            {
                "lat": 54.4716835983,
                "lng": -130.7764589974
            },
            {
                "lat": 54.469349503,
                "lng": -130.7759861592
            },
            {
                "lat": 54.4624488301,
                "lng": -130.7728885621
            },
            {
                "lat": 54.4457895301,
                "lng": -130.7648012162
            },
            {
                "lat": 54.43746187,
                "lng": -130.7548067037
            },
            {
                "lat": 54.4312778354,
                "lng": -130.747191961
            },
            {
                "lat": 54.4248093785,
                "lng": -130.7438202443
            },
            {
                "lat": 54.4201934702,
                "lng": -130.7427974478
            },
            {
                "lat": 54.4193387959,
                "lng": -130.7393486872
            },
            {
                "lat": 54.4202504778,
                "lng": -130.7351539193
            },
            {
                "lat": 54.4209056913,
                "lng": -130.7325598725
            },
            {
                "lat": 54.4194494591,
                "lng": -130.7308958438
            },
            {
                "lat": 54.4119954495,
                "lng": -130.718918154
            },
            {
                "lat": 54.4115299129,
                "lng": -130.7216642713
            },
            {
                "lat": 54.4105993924,
                "lng": -130.7259220064
            },
            {
                "lat": 54.408207367,
                "lng": -130.7265933585
            },
            {
                "lat": 54.3999633189,
                "lng": -130.7280428385
            },
            {
                "lat": 54.3845361784,
                "lng": -130.7255246349
            },
            {
                "lat": 54.3742934482,
                "lng": -130.719009045
            },
            {
                "lat": 54.3727043155,
                "lng": -130.7143408377
            },
            {
                "lat": 54.3632930762,
                "lng": -130.713241742
            },
            {
                "lat": 54.3633798155,
                "lng": -130.4873809603
            },
            {
                "lat": 54.3636060244,
                "lng": -130.4862975843
            },
            {
                "lat": 54.3649835846,
                "lng": -130.4844820845
            },
            {
                "lat": 54.3678363216,
                "lng": -130.477097169
            },
            {
                "lat": 54.3740242319,
                "lng": -130.4718630652
            },
            {
                "lat": 54.3866350868,
                "lng": -130.4692539417
            },
            {
                "lat": 54.3952020967,
                "lng": -130.4709161414
            },
            {
                "lat": 54.4106681654,
                "lng": -130.4697267624
            },
            {
                "lat": 54.4261362688,
                "lng": -130.4725800514
            },
            {
                "lat": 54.4327959196,
                "lng": -130.4787587196
            },
            {
                "lat": 54.4438402884,
                "lng": -130.4774936494
            },
            {
                "lat": 54.4834972592,
                "lng": -130.4671328675
            },
            {
                "lat": 54.4863467031,
                "lng": -130.4663237028
            },
            {
                "lat": 54.4897268013,
                "lng": -130.4623861458
            },
            {
                "lat": 54.4938080193,
                "lng": -130.4633951462
            },
            {
                "lat": 54.4944572667,
                "lng": -130.4637450627
            },
            {
                "lat": 54.496051416,
                "lng": -130.4646448567
            },
            {
                "lat": 54.4970781262,
                "lng": -130.4645223156
            },
            {
                "lat": 54.4992982774,
                "lng": -130.4651493116
            },
            {
                "lat": 54.5048827297,
                "lng": -130.4696043055
            },
            {
                "lat": 54.506344018,
                "lng": -130.4682768329
            },
            {
                "lat": 54.5216141822,
                "lng": -130.4660794815
            },
            {
                "lat": 54.5226448521,
                "lng": -130.4662783323
            },
            {
                "lat": 54.5229462228,
                "lng": -130.4665061838
            },
            {
                "lat": 54.524703676,
                "lng": -130.4678192784
            },
            {
                "lat": 54.5297436336,
                "lng": -130.4741519988
            },
            {
                "lat": 54.5340232122,
                "lng": -130.4812925756
            },
            {
                "lat": 54.5392606581,
                "lng": -130.4817667481
            },
            {
                "lat": 54.5433048495,
                "lng": -130.4779659914
            },
            {
                "lat": 54.5459213793,
                "lng": -130.4767766888
            },
            {
                "lat": 54.5554384181,
                "lng": -130.4801030776
            },
            {
                "lat": 54.561390258,
                "lng": -130.4815371036
            },
            {
                "lat": 54.5638507821,
                "lng": -130.4796751113
            },
            {
                "lat": 54.5669288257,
                "lng": -130.4804378576
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 28,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea  4-5",
            "LABEL": "4-5",
            "FISHERY_AREA_ID": 2398,
            "OBJECTID": 62,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 434445292.5229,
            "FEATURE_LENGTH_M": 85468.7541,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.47470135646111,
            "lng": -130.6181742500486
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.575565309,
                "lng": -130.3929903783
            },
            {
                "lat": 54.5730705026,
                "lng": -130.3962553538
            },
            {
                "lat": 54.5638456904,
                "lng": -130.4163054715
            },
            {
                "lat": 54.5598751079,
                "lng": -130.4159239051
            },
            {
                "lat": 54.5517688363,
                "lng": -130.3967900342
            },
            {
                "lat": 54.5503425621,
                "lng": -130.3860776588
            },
            {
                "lat": 54.553192041,
                "lng": -130.3789817204
            },
            {
                "lat": 54.5603377543,
                "lng": -130.3758552368
            },
            {
                "lat": 54.565807283,
                "lng": -130.3784640732
            },
            {
                "lat": 54.571995304,
                "lng": -130.3851323759
            },
            {
                "lat": 54.575802422,
                "lng": -130.3889463807
            },
            {
                "lat": 54.575565309,
                "lng": -130.3929903783
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 29,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 3,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 3-5",
            "LABEL": "3-5",
            "FISHERY_AREA_ID": 1993,
            "OBJECTID": 63,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4635391.934,
            "FEATURE_LENGTH_M": 8266.488,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.56476401013334,
            "lng": -130.39205941391666
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.5248640299,
                "lng": -130.462570441
            },
            {
                "lat": 54.5261108132,
                "lng": -130.4630275154
            },
            {
                "lat": 54.529617585,
                "lng": -130.4639427685
            },
            {
                "lat": 54.5306666661,
                "lng": -130.4638526143
            },
            {
                "lat": 54.5380700947,
                "lng": -130.4632105399
            },
            {
                "lat": 54.5430677843,
                "lng": -130.4651184833
            },
            {
                "lat": 54.5511856592,
                "lng": -130.473236472
            },
            {
                "lat": 54.552821445,
                "lng": -130.4748684093
            },
            {
                "lat": 54.5638507821,
                "lng": -130.4796751113
            },
            {
                "lat": 54.561390258,
                "lng": -130.4815371036
            },
            {
                "lat": 54.5554384181,
                "lng": -130.4801030776
            },
            {
                "lat": 54.5459213793,
                "lng": -130.4767766888
            },
            {
                "lat": 54.5433048495,
                "lng": -130.4779659914
            },
            {
                "lat": 54.5392606581,
                "lng": -130.4817667481
            },
            {
                "lat": 54.5340232122,
                "lng": -130.4812925756
            },
            {
                "lat": 54.5297436336,
                "lng": -130.4741519988
            },
            {
                "lat": 54.524703676,
                "lng": -130.4678192784
            },
            {
                "lat": 54.5229462228,
                "lng": -130.4665061838
            },
            {
                "lat": 54.5226448521,
                "lng": -130.4662783323
            },
            {
                "lat": 54.5238107212,
                "lng": -130.4639131669
            },
            {
                "lat": 54.5248640299,
                "lng": -130.462570441
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 30,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 64,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2667938.6674,
            "FEATURE_LENGTH_M": 9993.0304,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.537538417633336,
            "lng": -130.47096114006192
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.4739718089,
                "lng": -130.9385066222
            },
            {
                "lat": 54.4706689227,
                "lng": -130.9462159426
            },
            {
                "lat": 54.4686013947,
                "lng": -130.9491424502
            },
            {
                "lat": 54.4686013991,
                "lng": -130.952102676
            },
            {
                "lat": 54.4685730686,
                "lng": -130.9545149
            },
            {
                "lat": 54.4635260825,
                "lng": -130.9585411792
            },
            {
                "lat": 54.4574508978,
                "lng": -130.958557318
            },
            {
                "lat": 54.4574502692,
                "lng": -130.9802858393
            },
            {
                "lat": 54.4483254978,
                "lng": -130.987671355
            },
            {
                "lat": 54.4483269295,
                "lng": -131.0931579365
            },
            {
                "lat": 54.2352787644,
                "lng": -130.9766694883
            },
            {
                "lat": 54.2946392756,
                "lng": -130.8804934161
            },
            {
                "lat": 54.3632930762,
                "lng": -130.713241742
            },
            {
                "lat": 54.3727043155,
                "lng": -130.7143408377
            },
            {
                "lat": 54.3742934482,
                "lng": -130.719009045
            },
            {
                "lat": 54.3845361784,
                "lng": -130.7255246349
            },
            {
                "lat": 54.3999633189,
                "lng": -130.7280428385
            },
            {
                "lat": 54.408207367,
                "lng": -130.7265933585
            },
            {
                "lat": 54.4105993924,
                "lng": -130.7259220064
            },
            {
                "lat": 54.4115299129,
                "lng": -130.7216642713
            },
            {
                "lat": 54.4119954495,
                "lng": -130.718918154
            },
            {
                "lat": 54.4194494591,
                "lng": -130.7308958438
            },
            {
                "lat": 54.4209056913,
                "lng": -130.7325598725
            },
            {
                "lat": 54.4202504778,
                "lng": -130.7351539193
            },
            {
                "lat": 54.4193387959,
                "lng": -130.7393486872
            },
            {
                "lat": 54.4201934702,
                "lng": -130.7427974478
            },
            {
                "lat": 54.4248093785,
                "lng": -130.7438202443
            },
            {
                "lat": 54.4312778354,
                "lng": -130.747191961
            },
            {
                "lat": 54.43746187,
                "lng": -130.7548067037
            },
            {
                "lat": 54.4457895301,
                "lng": -130.7648012162
            },
            {
                "lat": 54.4624488301,
                "lng": -130.7728885621
            },
            {
                "lat": 54.469349503,
                "lng": -130.7759861592
            },
            {
                "lat": 54.4716835983,
                "lng": -130.7764589974
            },
            {
                "lat": 54.4726218416,
                "lng": -130.7758020447
            },
            {
                "lat": 54.4726179937,
                "lng": -130.7903905062
            },
            {
                "lat": 54.4868428852,
                "lng": -130.7921291385
            },
            {
                "lat": 54.4923701455,
                "lng": -130.7942353282
            },
            {
                "lat": 54.4954830689,
                "lng": -130.7938535686
            },
            {
                "lat": 54.4967804668,
                "lng": -130.7936856386
            },
            {
                "lat": 54.4973687767,
                "lng": -130.7933958459
            },
            {
                "lat": 54.5444071517,
                "lng": -130.7934112731
            },
            {
                "lat": 54.5497821825,
                "lng": -130.7935330999
            },
            {
                "lat": 54.5452266241,
                "lng": -130.8055880439
            },
            {
                "lat": 54.5414202303,
                "lng": -130.8186799117
            },
            {
                "lat": 54.5354686537,
                "lng": -130.8312830399
            },
            {
                "lat": 54.5259517727,
                "lng": -130.850082121
            },
            {
                "lat": 54.5188144126,
                "lng": -130.8619843026
            },
            {
                "lat": 54.5102465806,
                "lng": -130.8791201196
            },
            {
                "lat": 54.5121498722,
                "lng": -130.8903045998
            },
            {
                "lat": 54.5111990328,
                "lng": -130.9005284193
            },
            {
                "lat": 54.4933537863,
                "lng": -130.9133760406
            },
            {
                "lat": 54.4847877089,
                "lng": -130.9228977837
            },
            {
                "lat": 54.4808263017,
                "lng": -130.9328464823
            },
            {
                "lat": 54.4739718089,
                "lng": -130.9385066222
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 31,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea  4-1",
            "LABEL": "4-1",
            "FISHERY_AREA_ID": 2394,
            "OBJECTID": 65,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 430992531.7411,
            "FEATURE_LENGTH_M": 96727.3462,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.45513308346111,
            "lng": -130.82873073252782
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.5226448521,
                "lng": -130.4662783323
            },
            {
                "lat": 54.5216141822,
                "lng": -130.4660794815
            },
            {
                "lat": 54.506344018,
                "lng": -130.4682768329
            },
            {
                "lat": 54.5111692395,
                "lng": -130.4617010002
            },
            {
                "lat": 54.5138052038,
                "lng": -130.4542540262
            },
            {
                "lat": 54.5123020367,
                "lng": -130.4487297097
            },
            {
                "lat": 54.5115890664,
                "lng": -130.4475401173
            },
            {
                "lat": 54.5112910847,
                "lng": -130.4459235185
            },
            {
                "lat": 54.5120774086,
                "lng": -130.4438022925
            },
            {
                "lat": 54.5139351544,
                "lng": -130.4387819167
            },
            {
                "lat": 54.5180779453,
                "lng": -130.4430394179
            },
            {
                "lat": 54.5176316061,
                "lng": -130.4464875815
            },
            {
                "lat": 54.5174794404,
                "lng": -130.449219094
            },
            {
                "lat": 54.5174599406,
                "lng": -130.4495389048
            },
            {
                "lat": 54.5246813106,
                "lng": -130.4611209363
            },
            {
                "lat": 54.5248640299,
                "lng": -130.462570441
            },
            {
                "lat": 54.5238107212,
                "lng": -130.4639131669
            },
            {
                "lat": 54.5226448521,
                "lng": -130.4662783323
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 32,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea  4-14",
            "LABEL": "4-14",
            "FISHERY_AREA_ID": 2406,
            "OBJECTID": 66,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1670076.5661,
            "FEATURE_LENGTH_M": 6744.338,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.516856782922225,
            "lng": -130.45464083902777
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.4000014937,
                "lng": -131.9999999324
            },
            {
                "lat": 54.4000018204,
                "lng": -132.9356232431
            },
            {
                "lat": 54.2494431566,
                "lng": -133.0800018402
            },
            {
                "lat": 54.2494433135,
                "lng": -133.0783234043
            },
            {
                "lat": 54.2494084094,
                "lng": -133.073043382
            },
            {
                "lat": 54.2473604842,
                "lng": -133.0700839919
            },
            {
                "lat": 54.2434117792,
                "lng": -133.0625461073
            },
            {
                "lat": 54.2491751491,
                "lng": -133.0407417875
            },
            {
                "lat": 54.2488855993,
                "lng": -132.9999996082
            },
            {
                "lat": 54.2465706595,
                "lng": -132.9912870714
            },
            {
                "lat": 54.2464590974,
                "lng": -132.9850463985
            },
            {
                "lat": 54.2480242818,
                "lng": -132.9825125739
            },
            {
                "lat": 54.24875279,
                "lng": -132.9814905017
            },
            {
                "lat": 54.2488484852,
                "lng": -132.9813531487
            },
            {
                "lat": 54.2490695212,
                "lng": -132.9802709193
            },
            {
                "lat": 54.2491450695,
                "lng": -132.9793697703
            },
            {
                "lat": 54.2488856049,
                "lng": -132.978775002
            },
            {
                "lat": 54.2487299732,
                "lng": -132.9780577898
            },
            {
                "lat": 54.2470393804,
                "lng": -132.955596157
            },
            {
                "lat": 54.1751481447,
                "lng": -132.0000005839
            },
            {
                "lat": 54.4000014937,
                "lng": -131.9999999324
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 33,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 101,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 101-6",
            "LABEL": "101-6",
            "FISHERY_AREA_ID": 2291,
            "OBJECTID": 67,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1366097416.3591,
            "FEATURE_LENGTH_M": 176332.5939,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.26637170032858,
            "lng": -132.86352967360952
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.5139351544,
                "lng": -130.4387819167
            },
            {
                "lat": 54.5120774086,
                "lng": -130.4438022925
            },
            {
                "lat": 54.5112910847,
                "lng": -130.4459235185
            },
            {
                "lat": 54.5115890664,
                "lng": -130.4475401173
            },
            {
                "lat": 54.5123020367,
                "lng": -130.4487297097
            },
            {
                "lat": 54.5138052038,
                "lng": -130.4542540262
            },
            {
                "lat": 54.5111692395,
                "lng": -130.4617010002
            },
            {
                "lat": 54.506344018,
                "lng": -130.4682768329
            },
            {
                "lat": 54.5048827297,
                "lng": -130.4696043055
            },
            {
                "lat": 54.4992982774,
                "lng": -130.4651493116
            },
            {
                "lat": 54.4970781262,
                "lng": -130.4645223156
            },
            {
                "lat": 54.4972645266,
                "lng": -130.4627379842
            },
            {
                "lat": 54.4976076272,
                "lng": -130.4616394782
            },
            {
                "lat": 54.4979286669,
                "lng": -130.4598542803
            },
            {
                "lat": 54.4996868573,
                "lng": -130.4467476893
            },
            {
                "lat": 54.5003746463,
                "lng": -130.4421234282
            },
            {
                "lat": 54.5025136972,
                "lng": -130.4376060045
            },
            {
                "lat": 54.5075112021,
                "lng": -130.4347375784
            },
            {
                "lat": 54.5122722214,
                "lng": -130.4349822035
            },
            {
                "lat": 54.5139351544,
                "lng": -130.4387819167
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 34,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea  4-6",
            "LABEL": "4-6",
            "FISHERY_AREA_ID": 2399,
            "OBJECTID": 68,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2908714.5768,
            "FEATURE_LENGTH_M": 6834.4718,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.506143347240005,
            "lng": -130.45137479550004
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.5025136972,
                "lng": -130.4376060045
            },
            {
                "lat": 54.5003746463,
                "lng": -130.4421234282
            },
            {
                "lat": 54.4996868573,
                "lng": -130.4467476893
            },
            {
                "lat": 54.4979286669,
                "lng": -130.4598542803
            },
            {
                "lat": 54.4976076272,
                "lng": -130.4616394782
            },
            {
                "lat": 54.4972645266,
                "lng": -130.4627379842
            },
            {
                "lat": 54.4963532789,
                "lng": -130.4624030983
            },
            {
                "lat": 54.4938080193,
                "lng": -130.4633951462
            },
            {
                "lat": 54.4897268013,
                "lng": -130.4623861458
            },
            {
                "lat": 54.4863467031,
                "lng": -130.4663237028
            },
            {
                "lat": 54.4834972592,
                "lng": -130.4671328675
            },
            {
                "lat": 54.482215105,
                "lng": -130.4609841419
            },
            {
                "lat": 54.4745291347,
                "lng": -130.4388121133
            },
            {
                "lat": 54.4743539707,
                "lng": -130.42852843
            },
            {
                "lat": 54.4751516225,
                "lng": -130.4259334606
            },
            {
                "lat": 54.4784809687,
                "lng": -130.4230800735
            },
            {
                "lat": 54.4846681414,
                "lng": -130.4173733569
            },
            {
                "lat": 54.4925231727,
                "lng": -130.426650942
            },
            {
                "lat": 54.5001380515,
                "lng": -130.4288015476
            },
            {
                "lat": 54.5025136972,
                "lng": -130.4376060045
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 35,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea  4-7",
            "LABEL": "4-7",
            "FISHERY_AREA_ID": 2400,
            "OBJECTID": 69,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 6579025.2418,
            "FEATURE_LENGTH_M": 10085.9016,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.49048409738499,
            "lng": -130.44600599478
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.4972645266,
                "lng": -130.4627379842
            },
            {
                "lat": 54.4970781262,
                "lng": -130.4645223156
            },
            {
                "lat": 54.496051416,
                "lng": -130.4646448567
            },
            {
                "lat": 54.4944572667,
                "lng": -130.4637450627
            },
            {
                "lat": 54.4938080193,
                "lng": -130.4633951462
            },
            {
                "lat": 54.4963532789,
                "lng": -130.4624030983
            },
            {
                "lat": 54.4972645266,
                "lng": -130.4627379842
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 36,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 70,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 33657.0247,
            "FEATURE_LENGTH_M": 890.3784,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.49603959432858,
            "lng": -130.46345520684287
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.4751516225,
                "lng": -130.4259334606
            },
            {
                "lat": 54.4743539707,
                "lng": -130.42852843
            },
            {
                "lat": 54.4745291347,
                "lng": -130.4388121133
            },
            {
                "lat": 54.482215105,
                "lng": -130.4609841419
            },
            {
                "lat": 54.4834972592,
                "lng": -130.4671328675
            },
            {
                "lat": 54.4438402884,
                "lng": -130.4774936494
            },
            {
                "lat": 54.4327959196,
                "lng": -130.4787587196
            },
            {
                "lat": 54.433509762,
                "lng": -130.464249465
            },
            {
                "lat": 54.4377943505,
                "lng": -130.4354549733
            },
            {
                "lat": 54.4385070115,
                "lng": -130.3950044058
            },
            {
                "lat": 54.4442177893,
                "lng": -130.375259282
            },
            {
                "lat": 54.4618265484,
                "lng": -130.3726345939
            },
            {
                "lat": 54.4742013029,
                "lng": -130.3807370529
            },
            {
                "lat": 54.483718689,
                "lng": -130.3933406145
            },
            {
                "lat": 54.4853823474,
                "lng": -130.4059604342
            },
            {
                "lat": 54.4846681414,
                "lng": -130.4173733569
            },
            {
                "lat": 54.4751516225,
                "lng": -130.4259334606
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 37,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea  4-8",
            "LABEL": "4-8",
            "FISHERY_AREA_ID": 2401,
            "OBJECTID": 71,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 30145382.9278,
            "FEATURE_LENGTH_M": 23137.8384,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.4638447567647,
            "lng": -130.42609358949412
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.4751516225,
                "lng": -130.4259334606
            },
            {
                "lat": 54.4846681414,
                "lng": -130.4173733569
            },
            {
                "lat": 54.4784809687,
                "lng": -130.4230800735
            },
            {
                "lat": 54.4751516225,
                "lng": -130.4259334606
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 38,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 72,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4835.6744,
            "FEATURE_LENGTH_M": 2395.5539,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.478363088775,
            "lng": -130.4230800879
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.4483269295,
                "lng": -131.0931579365
            },
            {
                "lat": 54.4483258291,
                "lng": -131.3000026782
            },
            {
                "lat": 54.4000018341,
                "lng": -131.3512873071
            },
            {
                "lat": 54.2483329114,
                "lng": -131.5116725774
            },
            {
                "lat": 54.3503682612,
                "lng": -131.300003416
            },
            {
                "lat": 54.4483269295,
                "lng": -131.0931579365
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 39,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 104,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 104-1",
            "LABEL": "104-1",
            "FISHERY_AREA_ID": 2299,
            "OBJECTID": 73,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 148314925.799,
            "FEATURE_LENGTH_M": 74727.4729,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.390613782466666,
            "lng": -131.27488030861667
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.4483269295,
                "lng": -131.0931579365
            },
            {
                "lat": 54.3503682612,
                "lng": -131.300003416
            },
            {
                "lat": 54.1666685414,
                "lng": -131.3000034141
            },
            {
                "lat": 54.0000002942,
                "lng": -130.8672182377
            },
            {
                "lat": 54.2352787644,
                "lng": -130.9766694883
            },
            {
                "lat": 54.4483269295,
                "lng": -131.0931579365
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 40,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 104,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 104-2",
            "LABEL": "104-2",
            "FISHERY_AREA_ID": 2300,
            "OBJECTID": 74,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 720021005.5084,
            "FEATURE_LENGTH_M": 123781.3261,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.2748282867,
            "lng": -131.10503507151665
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.4000018341,
                "lng": -131.3512873071
            },
            {
                "lat": 54.4000014937,
                "lng": -131.9999999324
            },
            {
                "lat": 54.1751481447,
                "lng": -132.0000005839
            },
            {
                "lat": 54.1500013545,
                "lng": -131.6666712768
            },
            {
                "lat": 54.2483329114,
                "lng": -131.5116725774
            },
            {
                "lat": 54.4000018341,
                "lng": -131.3512873071
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 41,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 101,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 101-10",
            "LABEL": "101-10",
            "FISHERY_AREA_ID": 2295,
            "OBJECTID": 75,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 888367489.3238,
            "FEATURE_LENGTH_M": 123785.5977,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.29558126208334,
            "lng": -131.64681983078333
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.2494431566,
                "lng": -133.0800018402
            },
            {
                "lat": 54.2494503149,
                "lng": -134.229644634
            },
            {
                "lat": 54.2280391853,
                "lng": -134.3239748894
            },
            {
                "lat": 54.2049862958,
                "lng": -134.4664916727
            },
            {
                "lat": 54.1213753887,
                "lng": -134.9690247837
            },
            {
                "lat": 54.0000000538,
                "lng": -135.7749938683
            },
            {
                "lat": 54.0000003757,
                "lng": -134.4737170115
            },
            {
                "lat": 54.0000000925,
                "lng": -133.1724402085
            },
            {
                "lat": 54.1758616372,
                "lng": -133.1014253844
            },
            {
                "lat": 54.2249949823,
                "lng": -133.0815728125
            },
            {
                "lat": 54.2260019183,
                "lng": -133.0715180538
            },
            {
                "lat": 54.2295911457,
                "lng": -133.0641635833
            },
            {
                "lat": 54.236769908,
                "lng": -133.0609277324
            },
            {
                "lat": 54.2434117792,
                "lng": -133.0625461073
            },
            {
                "lat": 54.2473604842,
                "lng": -133.0700839919
            },
            {
                "lat": 54.2494084094,
                "lng": -133.073043382
            },
            {
                "lat": 54.2494433135,
                "lng": -133.0783234043
            },
            {
                "lat": 54.2494431566,
                "lng": -133.0800018402
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 42,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 101,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 101-2",
            "LABEL": "101-2",
            "FISHERY_AREA_ID": 2287,
            "OBJECTID": 76,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 3344113151.1143,
            "FEATURE_LENGTH_M": 379598.5544,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.188087866538886,
            "lng": -133.62410528891115
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.3454706709,
                "lng": -130.443786453
            },
            {
                "lat": 54.3390471607,
                "lng": -130.4554448373
            },
            {
                "lat": 54.3386921103,
                "lng": -130.4605406902
            },
            {
                "lat": 54.3356815158,
                "lng": -130.4613037997
            },
            {
                "lat": 54.3074562575,
                "lng": -130.474273304
            },
            {
                "lat": 54.3072548324,
                "lng": -130.4732364742
            },
            {
                "lat": 54.3060418468,
                "lng": -130.4703830459
            },
            {
                "lat": 54.3041386642,
                "lng": -130.4663695643
            },
            {
                "lat": 54.3022808421,
                "lng": -130.462905435
            },
            {
                "lat": 54.2984317919,
                "lng": -130.4609985071
            },
            {
                "lat": 54.2965857938,
                "lng": -130.4597624205
            },
            {
                "lat": 54.2556037917,
                "lng": -130.4049829902
            },
            {
                "lat": 54.2545434515,
                "lng": -130.3985444928
            },
            {
                "lat": 54.2497098901,
                "lng": -130.390319594
            },
            {
                "lat": 54.2449764772,
                "lng": -130.3850403221
            },
            {
                "lat": 54.2435305441,
                "lng": -130.3839414774
            },
            {
                "lat": 54.2428357362,
                "lng": -130.383591096
            },
            {
                "lat": 54.2418365403,
                "lng": -130.383590688
            },
            {
                "lat": 54.2409400066,
                "lng": -130.3835906051
            },
            {
                "lat": 54.2387388744,
                "lng": -130.38244625
            },
            {
                "lat": 54.2367705394,
                "lng": -130.3804321313
            },
            {
                "lat": 54.2358367977,
                "lng": -130.3785715097
            },
            {
                "lat": 54.2356946787,
                "lng": -130.3782963954
            },
            {
                "lat": 54.235223545,
                "lng": -130.3777484658
            },
            {
                "lat": 54.2352405542,
                "lng": -130.377743525
            },
            {
                "lat": 54.2346723846,
                "lng": -130.3347444125
            },
            {
                "lat": 54.2344396097,
                "lng": -130.334240382
            },
            {
                "lat": 54.2342827219,
                "lng": -130.3339051409
            },
            {
                "lat": 54.2341032936,
                "lng": -130.3334625811
            },
            {
                "lat": 54.2328565957,
                "lng": -130.3312188673
            },
            {
                "lat": 54.2313741035,
                "lng": -130.3255651525
            },
            {
                "lat": 54.2365876629,
                "lng": -130.3265382729
            },
            {
                "lat": 54.2439649716,
                "lng": -130.3296209813
            },
            {
                "lat": 54.244281585,
                "lng": -130.3297881526
            },
            {
                "lat": 54.2599066905,
                "lng": -130.3374785646
            },
            {
                "lat": 54.2758486623,
                "lng": -130.3448487827
            },
            {
                "lat": 54.2882236618,
                "lng": -130.3401026055
            },
            {
                "lat": 54.3070186888,
                "lng": -130.3170160176
            },
            {
                "lat": 54.3226474427,
                "lng": -130.2894287659
            },
            {
                "lat": 54.3245278444,
                "lng": -130.2853230684
            },
            {
                "lat": 54.3269384212,
                "lng": -130.2872159571
            },
            {
                "lat": 54.3301353032,
                "lng": -130.2897180755
            },
            {
                "lat": 54.3317870872,
                "lng": -130.2865597268
            },
            {
                "lat": 54.3327975071,
                "lng": -130.2855377874
            },
            {
                "lat": 54.3336408272,
                "lng": -130.2848960403
            },
            {
                "lat": 54.3341984318,
                "lng": -130.2834783604
            },
            {
                "lat": 54.3366159791,
                "lng": -130.2748412266
            },
            {
                "lat": 54.3377153105,
                "lng": -130.274734537
            },
            {
                "lat": 54.33914262,
                "lng": -130.2738346882
            },
            {
                "lat": 54.3418732446,
                "lng": -130.268645972
            },
            {
                "lat": 54.3488002195,
                "lng": -130.2586665982
            },
            {
                "lat": 54.369979444,
                "lng": -130.2415312063
            },
            {
                "lat": 54.3930589192,
                "lng": -130.2365419019
            },
            {
                "lat": 54.4023391637,
                "lng": -130.2334446037
            },
            {
                "lat": 54.4075736227,
                "lng": -130.2431955252
            },
            {
                "lat": 54.4201843934,
                "lng": -130.267700945
            },
            {
                "lat": 54.4473112129,
                "lng": -130.3150631297
            },
            {
                "lat": 54.4461212835,
                "lng": -130.3288569058
            },
            {
                "lat": 54.4366031007,
                "lng": -130.3338618584
            },
            {
                "lat": 54.4216125589,
                "lng": -130.3212433967
            },
            {
                "lat": 54.3935353538,
                "lng": -130.293884982
            },
            {
                "lat": 54.3880607482,
                "lng": -130.2788845285
            },
            {
                "lat": 54.3747369217,
                "lng": -130.2822271132
            },
            {
                "lat": 54.3571321383,
                "lng": -130.2955471582
            },
            {
                "lat": 54.3473738795,
                "lng": -130.3288568316
            },
            {
                "lat": 54.3497550303,
                "lng": -130.3450318761
            },
            {
                "lat": 54.3483274371,
                "lng": -130.3621671305
            },
            {
                "lat": 54.343089969,
                "lng": -130.3954769998
            },
            {
                "lat": 54.3528481348,
                "lng": -130.4059605586
            },
            {
                "lat": 54.3547514982,
                "lng": -130.4292756276
            },
            {
                "lat": 54.3454706709,
                "lng": -130.443786453
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 43,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea  4-10",
            "LABEL": "4-10",
            "FISHERY_AREA_ID": 2402,
            "OBJECTID": 77,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 133982177.6198,
            "FEATURE_LENGTH_M": 71483.7323,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.31226491922112,
            "lng": -130.34960272567753
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.3503682612,
                "lng": -131.300003416
            },
            {
                "lat": 54.2483329114,
                "lng": -131.5116725774
            },
            {
                "lat": 54.1666685414,
                "lng": -131.3000034141
            },
            {
                "lat": 54.3503682612,
                "lng": -131.300003416
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 44,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 104,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 104-4",
            "LABEL": "104-4",
            "FISHERY_AREA_ID": 2302,
            "OBJECTID": 78,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 141063863.9967,
            "FEATURE_LENGTH_M": 54868.5823,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.2789344938,
            "lng": -131.352920705875
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.3632930762,
                "lng": -130.713241742
            },
            {
                "lat": 54.2946392756,
                "lng": -130.8804934161
            },
            {
                "lat": 54.2352787644,
                "lng": -130.9766694883
            },
            {
                "lat": 54.2161366911,
                "lng": -130.7647704927
            },
            {
                "lat": 54.3632930762,
                "lng": -130.713241742
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 45,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea  4-13",
            "LABEL": "4-13",
            "FISHERY_AREA_ID": 2405,
            "OBJECTID": 79,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 128664871.1241,
            "FEATURE_LENGTH_M": 53101.5133,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.2945281767,
            "lng": -130.80968337622
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.3678363216,
                "lng": -130.477097169
            },
            {
                "lat": 54.3649835846,
                "lng": -130.4844820845
            },
            {
                "lat": 54.3636060244,
                "lng": -130.4862975843
            },
            {
                "lat": 54.3633798155,
                "lng": -130.4873809603
            },
            {
                "lat": 54.3632930762,
                "lng": -130.713241742
            },
            {
                "lat": 54.2161366911,
                "lng": -130.7647704927
            },
            {
                "lat": 54.2136720146,
                "lng": -130.7630922754
            },
            {
                "lat": 54.210101641,
                "lng": -130.7527461802
            },
            {
                "lat": 54.206749206,
                "lng": -130.7542116717
            },
            {
                "lat": 54.2065966382,
                "lng": -130.7546243786
            },
            {
                "lat": 54.206333213,
                "lng": -130.754958817
            },
            {
                "lat": 54.2058792229,
                "lng": -130.7554012242
            },
            {
                "lat": 54.2047002546,
                "lng": -130.7566985006
            },
            {
                "lat": 54.2015452254,
                "lng": -130.7570504953
            },
            {
                "lat": 54.1891704158,
                "lng": -130.7532353287
            },
            {
                "lat": 54.1708489978,
                "lng": -130.7299193019
            },
            {
                "lat": 54.1560977525,
                "lng": -130.6918490421
            },
            {
                "lat": 54.1489559249,
                "lng": -130.6706696029
            },
            {
                "lat": 54.1398195185,
                "lng": -130.6508944331
            },
            {
                "lat": 54.1331897611,
                "lng": -130.6445930172
            },
            {
                "lat": 54.1314959147,
                "lng": -130.640121195
            },
            {
                "lat": 54.1308368149,
                "lng": -130.6388548148
            },
            {
                "lat": 54.129989638,
                "lng": -130.6372531792
            },
            {
                "lat": 54.1302994089,
                "lng": -130.6361840507
            },
            {
                "lat": 54.1203155077,
                "lng": -130.6256252399
            },
            {
                "lat": 54.1188928458,
                "lng": -130.6248934622
            },
            {
                "lat": 54.1177483712,
                "lng": -130.6250611317
            },
            {
                "lat": 54.1170046839,
                "lng": -130.6255195443
            },
            {
                "lat": 54.1162071683,
                "lng": -130.6259767219
            },
            {
                "lat": 54.1144376683,
                "lng": -130.6264344861
            },
            {
                "lat": 54.1086692564,
                "lng": -130.6260986712
            },
            {
                "lat": 54.1050149749,
                "lng": -130.6190183326
            },
            {
                "lat": 54.099216209,
                "lng": -130.6033478606
            },
            {
                "lat": 54.0738027243,
                "lng": -130.5765228876
            },
            {
                "lat": 54.0679205372,
                "lng": -130.5735467035
            },
            {
                "lat": 54.066677245,
                "lng": -130.572799142
            },
            {
                "lat": 54.0667757242,
                "lng": -130.5714421732
            },
            {
                "lat": 54.0670286644,
                "lng": -130.5705262488
            },
            {
                "lat": 54.0671997163,
                "lng": -130.569549467
            },
            {
                "lat": 54.0571330348,
                "lng": -130.5523838896
            },
            {
                "lat": 54.057064724,
                "lng": -130.5522162292
            },
            {
                "lat": 54.056709661,
                "lng": -130.5520783742
            },
            {
                "lat": 54.0563927203,
                "lng": -130.5519562394
            },
            {
                "lat": 54.0434649943,
                "lng": -130.5442961056
            },
            {
                "lat": 54.0403973528,
                "lng": -130.5315091311
            },
            {
                "lat": 54.0410123392,
                "lng": -130.5236815101
            },
            {
                "lat": 54.0451690301,
                "lng": -130.518050213
            },
            {
                "lat": 54.0530016388,
                "lng": -130.5147546883
            },
            {
                "lat": 54.0563008157,
                "lng": -130.5103450144
            },
            {
                "lat": 54.0588722806,
                "lng": -130.49848943
            },
            {
                "lat": 54.0658455238,
                "lng": -130.4738922944
            },
            {
                "lat": 54.0690271599,
                "lng": -130.4652095466
            },
            {
                "lat": 54.0654792516,
                "lng": -130.4581137615
            },
            {
                "lat": 54.0552021916,
                "lng": -130.4519953182
            },
            {
                "lat": 54.0489620499,
                "lng": -130.44441163
            },
            {
                "lat": 54.0489618482,
                "lng": -130.4348600861
            },
            {
                "lat": 54.0507967883,
                "lng": -130.4255681583
            },
            {
                "lat": 54.0591156203,
                "lng": -130.4231268201
            },
            {
                "lat": 54.0698817302,
                "lng": -130.4269102268
            },
            {
                "lat": 54.0758784948,
                "lng": -130.4367069963
            },
            {
                "lat": 54.0812605239,
                "lng": -130.437316192
            },
            {
                "lat": 54.089332389,
                "lng": -130.4325410014
            },
            {
                "lat": 54.092025113,
                "lng": -130.4253230747
            },
            {
                "lat": 54.0981437597,
                "lng": -130.4195666108
            },
            {
                "lat": 54.0981594086,
                "lng": -130.4195682842
            },
            {
                "lat": 54.1017737662,
                "lng": -130.4146388772
            },
            {
                "lat": 54.1017861619,
                "lng": -130.4146219555
            },
            {
                "lat": 54.235223545,
                "lng": -130.3777484658
            },
            {
                "lat": 54.2356946787,
                "lng": -130.3782963954
            },
            {
                "lat": 54.2358367977,
                "lng": -130.3785715097
            },
            {
                "lat": 54.2367705394,
                "lng": -130.3804321313
            },
            {
                "lat": 54.2387388744,
                "lng": -130.38244625
            },
            {
                "lat": 54.2409400066,
                "lng": -130.3835906051
            },
            {
                "lat": 54.2418365403,
                "lng": -130.383590688
            },
            {
                "lat": 54.2428357362,
                "lng": -130.383591096
            },
            {
                "lat": 54.2435305441,
                "lng": -130.3839414774
            },
            {
                "lat": 54.2449764772,
                "lng": -130.3850403221
            },
            {
                "lat": 54.2497098901,
                "lng": -130.390319594
            },
            {
                "lat": 54.2545434515,
                "lng": -130.3985444928
            },
            {
                "lat": 54.2556037917,
                "lng": -130.4049829902
            },
            {
                "lat": 54.2965857938,
                "lng": -130.4597624205
            },
            {
                "lat": 54.2984317919,
                "lng": -130.4609985071
            },
            {
                "lat": 54.3022808421,
                "lng": -130.462905435
            },
            {
                "lat": 54.3041386642,
                "lng": -130.4663695643
            },
            {
                "lat": 54.3060418468,
                "lng": -130.4703830459
            },
            {
                "lat": 54.3072548324,
                "lng": -130.4732364742
            },
            {
                "lat": 54.3074562575,
                "lng": -130.474273304
            },
            {
                "lat": 54.3356815158,
                "lng": -130.4613037997
            },
            {
                "lat": 54.3386921103,
                "lng": -130.4605406902
            },
            {
                "lat": 54.3390471607,
                "lng": -130.4554448373
            },
            {
                "lat": 54.3454706709,
                "lng": -130.443786453
            },
            {
                "lat": 54.3557012009,
                "lng": -130.4473573447
            },
            {
                "lat": 54.3678360022,
                "lng": -130.4675755769
            },
            {
                "lat": 54.3678363216,
                "lng": -130.477097169
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 46,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea  4-9",
            "LABEL": "4-9",
            "FISHERY_AREA_ID": 2545,
            "OBJECTID": 80,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 626459664.8767,
            "FEATURE_LENGTH_M": 109377.7692,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.171790155634056,
            "lng": -130.5312579990702
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.2465706595,
                "lng": -132.9912870714
            },
            {
                "lat": 54.2488855993,
                "lng": -132.9999996082
            },
            {
                "lat": 54.2491751491,
                "lng": -133.0407417875
            },
            {
                "lat": 54.2434117792,
                "lng": -133.0625461073
            },
            {
                "lat": 54.236769908,
                "lng": -133.0609277324
            },
            {
                "lat": 54.2295911457,
                "lng": -133.0641635833
            },
            {
                "lat": 54.2260019183,
                "lng": -133.0715180538
            },
            {
                "lat": 54.2262763706,
                "lng": -133.0653227264
            },
            {
                "lat": 54.2269632178,
                "lng": -133.0509334076
            },
            {
                "lat": 54.227940003,
                "lng": -133.0392908297
            },
            {
                "lat": 54.2250018519,
                "lng": -133.0296932234
            },
            {
                "lat": 54.223830723,
                "lng": -133.0117797545
            },
            {
                "lat": 54.224120818,
                "lng": -132.9924014014
            },
            {
                "lat": 54.2269635226,
                "lng": -132.9807582779
            },
            {
                "lat": 54.2465706595,
                "lng": -132.9912870714
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 47,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 81,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 12620527.5159,
            "FEATURE_LENGTH_M": 15389.7201,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.23387155503333,
            "lng": -133.03017670907997
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.2269635226,
                "lng": -132.9807582779
            },
            {
                "lat": 54.2342033933,
                "lng": -132.9739077415
            },
            {
                "lat": 54.2354617725,
                "lng": -132.9723209181
            },
            {
                "lat": 54.2360040062,
                "lng": -132.9705353014
            },
            {
                "lat": 54.2362672353,
                "lng": -132.9678185982
            },
            {
                "lat": 54.2367972508,
                "lng": -132.9656377369
            },
            {
                "lat": 54.2373234853,
                "lng": -132.964705987
            },
            {
                "lat": 54.2376396832,
                "lng": -132.9641877677
            },
            {
                "lat": 54.2338900749,
                "lng": -132.9497373408
            },
            {
                "lat": 54.1567495949,
                "lng": -132.6524192672
            },
            {
                "lat": 54.1148801626,
                "lng": -132.3192596987
            },
            {
                "lat": 54.1500013545,
                "lng": -131.6666712768
            },
            {
                "lat": 54.1751481447,
                "lng": -132.0000005839
            },
            {
                "lat": 54.2470393804,
                "lng": -132.955596157
            },
            {
                "lat": 54.2487299732,
                "lng": -132.9780577898
            },
            {
                "lat": 54.2488856049,
                "lng": -132.978775002
            },
            {
                "lat": 54.2491450695,
                "lng": -132.9793697703
            },
            {
                "lat": 54.2490695212,
                "lng": -132.9802709193
            },
            {
                "lat": 54.2488484852,
                "lng": -132.9813531487
            },
            {
                "lat": 54.24875279,
                "lng": -132.9814905017
            },
            {
                "lat": 54.2480242818,
                "lng": -132.9825125739
            },
            {
                "lat": 54.2464590974,
                "lng": -132.9850463985
            },
            {
                "lat": 54.2465706595,
                "lng": -132.9912870714
            },
            {
                "lat": 54.2269635226,
                "lng": -132.9807582779
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 48,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 101,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 101-7",
            "LABEL": "101-7",
            "FISHERY_AREA_ID": 2292,
            "OBJECTID": 82,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 477027240.6301,
            "FEATURE_LENGTH_M": 178186.898,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.225825752770845,
            "lng": -132.838436587775
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.2260019183,
                "lng": -133.0715180538
            },
            {
                "lat": 54.2249949823,
                "lng": -133.0815728125
            },
            {
                "lat": 54.1758616372,
                "lng": -133.1014253844
            },
            {
                "lat": 54.1758616764,
                "lng": -133.0928038094
            },
            {
                "lat": 54.1758652743,
                "lng": -133.0882409483
            },
            {
                "lat": 54.1758418587,
                "lng": -133.0876610665
            },
            {
                "lat": 54.1758690304,
                "lng": -133.087523943
            },
            {
                "lat": 54.176380018,
                "lng": -133.0869289868
            },
            {
                "lat": 54.1771773033,
                "lng": -133.0860292963
            },
            {
                "lat": 54.1789165783,
                "lng": -133.0822298933
            },
            {
                "lat": 54.1779899388,
                "lng": -133.0738981859
            },
            {
                "lat": 54.17752433,
                "lng": -133.0646207275
            },
            {
                "lat": 54.1777572189,
                "lng": -133.0567471745
            },
            {
                "lat": 54.1791496305,
                "lng": -133.0491020723
            },
            {
                "lat": 54.1791490783,
                "lng": -133.0423883258
            },
            {
                "lat": 54.1775243958,
                "lng": -133.0328824842
            },
            {
                "lat": 54.1759037988,
                "lng": -133.0250091766
            },
            {
                "lat": 54.1735196145,
                "lng": -133.0202488647
            },
            {
                "lat": 54.1733590445,
                "lng": -133.0199128657
            },
            {
                "lat": 54.1589932369,
                "lng": -132.9985952024
            },
            {
                "lat": 54.1552852934,
                "lng": -132.9578250674
            },
            {
                "lat": 54.160152709,
                "lng": -132.949722282
            },
            {
                "lat": 54.1675650655,
                "lng": -132.9443819633
            },
            {
                "lat": 54.1705775411,
                "lng": -132.9440308615
            },
            {
                "lat": 54.1567495949,
                "lng": -132.6524192672
            },
            {
                "lat": 54.2338900749,
                "lng": -132.9497373408
            },
            {
                "lat": 54.2376396832,
                "lng": -132.9641877677
            },
            {
                "lat": 54.2373234853,
                "lng": -132.964705987
            },
            {
                "lat": 54.2367972508,
                "lng": -132.9656377369
            },
            {
                "lat": 54.2362672353,
                "lng": -132.9678185982
            },
            {
                "lat": 54.2360040062,
                "lng": -132.9705353014
            },
            {
                "lat": 54.2354617725,
                "lng": -132.9723209181
            },
            {
                "lat": 54.2342033933,
                "lng": -132.9739077415
            },
            {
                "lat": 54.2269635226,
                "lng": -132.9807582779
            },
            {
                "lat": 54.224120818,
                "lng": -132.9924014014
            },
            {
                "lat": 54.223830723,
                "lng": -133.0117797545
            },
            {
                "lat": 54.2250018519,
                "lng": -133.0296932234
            },
            {
                "lat": 54.227940003,
                "lng": -133.0392908297
            },
            {
                "lat": 54.2269632178,
                "lng": -133.0509334076
            },
            {
                "lat": 54.2262763706,
                "lng": -133.0653227264
            },
            {
                "lat": 54.2260019183,
                "lng": -133.0715180538
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 49,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 1,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 1-2",
            "LABEL": "1-2",
            "FISHERY_AREA_ID": 1886,
            "OBJECTID": 83,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 128796779.3417,
            "FEATURE_LENGTH_M": 67780.7958,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.1980160023122,
            "lng": -133.01629921418535
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.3418732446,
                "lng": -130.268645972
            },
            {
                "lat": 54.33914262,
                "lng": -130.2738346882
            },
            {
                "lat": 54.3377153105,
                "lng": -130.274734537
            },
            {
                "lat": 54.3366159791,
                "lng": -130.2748412266
            },
            {
                "lat": 54.3341984318,
                "lng": -130.2834783604
            },
            {
                "lat": 54.3336408272,
                "lng": -130.2848960403
            },
            {
                "lat": 54.3327975071,
                "lng": -130.2855377874
            },
            {
                "lat": 54.3317870872,
                "lng": -130.2865597268
            },
            {
                "lat": 54.3301353032,
                "lng": -130.2897180755
            },
            {
                "lat": 54.3269384212,
                "lng": -130.2872159571
            },
            {
                "lat": 54.3245278444,
                "lng": -130.2853230684
            },
            {
                "lat": 54.3217730175,
                "lng": -130.280731362
            },
            {
                "lat": 54.3084480976,
                "lng": -130.2712096578
            },
            {
                "lat": 54.2977402257,
                "lng": -130.2664495751
            },
            {
                "lat": 54.2820360711,
                "lng": -130.2676389222
            },
            {
                "lat": 54.2725178312,
                "lng": -130.2774046222
            },
            {
                "lat": 54.2687111843,
                "lng": -130.2926331989
            },
            {
                "lat": 54.2676390326,
                "lng": -130.2961582556
            },
            {
                "lat": 54.2663305617,
                "lng": -130.3004765912
            },
            {
                "lat": 54.2603838097,
                "lng": -130.3007205992
            },
            {
                "lat": 54.2547228329,
                "lng": -130.3015435102
            },
            {
                "lat": 54.2532429252,
                "lng": -130.3066708697
            },
            {
                "lat": 54.2470557896,
                "lng": -130.3171391288
            },
            {
                "lat": 54.2387270962,
                "lng": -130.322845459
            },
            {
                "lat": 54.2342067942,
                "lng": -130.3221284444
            },
            {
                "lat": 54.2306410897,
                "lng": -130.3164212943
            },
            {
                "lat": 54.2225488346,
                "lng": -130.3092808981
            },
            {
                "lat": 54.216056696,
                "lng": -130.3059851043
            },
            {
                "lat": 54.2125736448,
                "lng": -130.3088856398
            },
            {
                "lat": 54.2125579267,
                "lng": -130.3088859213
            },
            {
                "lat": 54.2042799813,
                "lng": -130.3090213621
            },
            {
                "lat": 54.2042616346,
                "lng": -130.3090165501
            },
            {
                "lat": 54.2025434904,
                "lng": -130.3085685308
            },
            {
                "lat": 54.2016406349,
                "lng": -130.3023646269
            },
            {
                "lat": 54.2016710395,
                "lng": -130.3015865493
            },
            {
                "lat": 54.2016209964,
                "lng": -130.3006249713
            },
            {
                "lat": 54.2009385632,
                "lng": -130.2978473552
            },
            {
                "lat": 54.2002824821,
                "lng": -130.2951476415
            },
            {
                "lat": 54.2012300461,
                "lng": -130.2921522018
            },
            {
                "lat": 54.2012370132,
                "lng": -130.2921298364
            },
            {
                "lat": 54.2034758457,
                "lng": -130.2898815018
            },
            {
                "lat": 54.2034903998,
                "lng": -130.2898666672
            },
            {
                "lat": 54.2060977618,
                "lng": -130.2892681544
            },
            {
                "lat": 54.2061152879,
                "lng": -130.2892642047
            },
            {
                "lat": 54.2075387806,
                "lng": -130.2895585896
            },
            {
                "lat": 54.2075528382,
                "lng": -130.2895735241
            },
            {
                "lat": 54.2086253795,
                "lng": -130.2907494243
            },
            {
                "lat": 54.2086388825,
                "lng": -130.2907643019
            },
            {
                "lat": 54.2097593127,
                "lng": -130.2912841361
            },
            {
                "lat": 54.2097769609,
                "lng": -130.2912927174
            },
            {
                "lat": 54.2105629725,
                "lng": -130.2914178479
            },
            {
                "lat": 54.210567697,
                "lng": -130.2866519371
            },
            {
                "lat": 54.213389493,
                "lng": -130.2823163257
            },
            {
                "lat": 54.223128809,
                "lng": -130.2819519511
            },
            {
                "lat": 54.2309795293,
                "lng": -130.2764885724
            },
            {
                "lat": 54.24168761,
                "lng": -130.248885643
            },
            {
                "lat": 54.2433548862,
                "lng": -130.2205668136
            },
            {
                "lat": 54.2364537877,
                "lng": -130.1993872673
            },
            {
                "lat": 54.2393067664,
                "lng": -130.1793971581
            },
            {
                "lat": 54.2516821779,
                "lng": -130.1744078087
            },
            {
                "lat": 54.2614365205,
                "lng": -130.155853018
            },
            {
                "lat": 54.271194096,
                "lng": -130.1380002525
            },
            {
                "lat": 54.2861862322,
                "lng": -130.1396632103
            },
            {
                "lat": 54.291896421,
                "lng": -130.1472787032
            },
            {
                "lat": 54.2888031875,
                "lng": -130.1796415517
            },
            {
                "lat": 54.2821383901,
                "lng": -130.2103431592
            },
            {
                "lat": 54.2899931583,
                "lng": -130.2257988474
            },
            {
                "lat": 54.3090966786,
                "lng": -130.2333219153
            },
            {
                "lat": 54.335190002,
                "lng": -130.2438203685
            },
            {
                "lat": 54.3425065833,
                "lng": -130.2538447427
            },
            {
                "lat": 54.3418732446,
                "lng": -130.268645972
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 50,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 4,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 4-11",
            "LABEL": "4-11",
            "FISHERY_AREA_ID": 2403,
            "OBJECTID": 84,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 72353260.2466,
            "FEATURE_LENGTH_M": 50445.0208,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.25675340298592,
            "lng": -130.27205127332962
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.3245278444,
                "lng": -130.2853230684
            },
            {
                "lat": 54.3226474427,
                "lng": -130.2894287659
            },
            {
                "lat": 54.3070186888,
                "lng": -130.3170160176
            },
            {
                "lat": 54.2882236618,
                "lng": -130.3401026055
            },
            {
                "lat": 54.2758486623,
                "lng": -130.3448487827
            },
            {
                "lat": 54.2599066905,
                "lng": -130.3374785646
            },
            {
                "lat": 54.244281585,
                "lng": -130.3297881526
            },
            {
                "lat": 54.2439649716,
                "lng": -130.3296209813
            },
            {
                "lat": 54.2365876629,
                "lng": -130.3265382729
            },
            {
                "lat": 54.2313741035,
                "lng": -130.3255651525
            },
            {
                "lat": 54.2313566969,
                "lng": -130.325549845
            },
            {
                "lat": 54.2209053104,
                "lng": -130.3162658789
            },
            {
                "lat": 54.2125736448,
                "lng": -130.3088856398
            },
            {
                "lat": 54.216056696,
                "lng": -130.3059851043
            },
            {
                "lat": 54.2225488346,
                "lng": -130.3092808981
            },
            {
                "lat": 54.2306410897,
                "lng": -130.3164212943
            },
            {
                "lat": 54.2342067942,
                "lng": -130.3221284444
            },
            {
                "lat": 54.2387270962,
                "lng": -130.322845459
            },
            {
                "lat": 54.2470557896,
                "lng": -130.3171391288
            },
            {
                "lat": 54.2532429252,
                "lng": -130.3066708697
            },
            {
                "lat": 54.2547228329,
                "lng": -130.3015435102
            },
            {
                "lat": 54.2603838097,
                "lng": -130.3007205992
            },
            {
                "lat": 54.2663305617,
                "lng": -130.3004765912
            },
            {
                "lat": 54.2676390326,
                "lng": -130.2961582556
            },
            {
                "lat": 54.2687111843,
                "lng": -130.2926331989
            },
            {
                "lat": 54.2725178312,
                "lng": -130.2774046222
            },
            {
                "lat": 54.2820360711,
                "lng": -130.2676389222
            },
            {
                "lat": 54.2977402257,
                "lng": -130.2664495751
            },
            {
                "lat": 54.3084480976,
                "lng": -130.2712096578
            },
            {
                "lat": 54.3217730175,
                "lng": -130.280731362
            },
            {
                "lat": 54.3245278444,
                "lng": -130.2853230684
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 51,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 85,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 28172972.4199,
            "FEATURE_LENGTH_M": 29132.8203,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 54.263436345154844,
            "lng": -130.30700555771293
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.791118304,
                "lng": -137.007080354
            },
            {
                "lat": 53.7911185822,
                "lng": -135.7130432566
            },
            {
                "lat": 53.7911186721,
                "lng": -134.4190063792
            },
            {
                "lat": 53.7911185747,
                "lng": -133.1249693127
            },
            {
                "lat": 53.9131124971,
                "lng": -133.1773521311
            },
            {
                "lat": 53.9139213437,
                "lng": -133.176834649
            },
            {
                "lat": 53.9163931197,
                "lng": -133.1752784158
            },
            {
                "lat": 53.9195138426,
                "lng": -133.175231292
            },
            {
                "lat": 53.923541772,
                "lng": -133.1773836968
            },
            {
                "lat": 53.9277374592,
                "lng": -133.1817779077
            },
            {
                "lat": 53.9330019359,
                "lng": -133.1876983568
            },
            {
                "lat": 53.9363359422,
                "lng": -133.1916812318
            },
            {
                "lat": 53.937359297,
                "lng": -133.1934502842
            },
            {
                "lat": 53.9373593416,
                "lng": -133.1959230957
            },
            {
                "lat": 53.9374653655,
                "lng": -133.1969448462
            },
            {
                "lat": 53.9374382759,
                "lng": -133.1977074972
            },
            {
                "lat": 54.0000000925,
                "lng": -133.1724402085
            },
            {
                "lat": 54.0000003757,
                "lng": -134.4737170115
            },
            {
                "lat": 54.0000000538,
                "lng": -135.7749938683
            },
            {
                "lat": 53.9724839247,
                "lng": -135.9568488197
            },
            {
                "lat": 53.9094279863,
                "lng": -136.3457644907
            },
            {
                "lat": 53.791118304,
                "lng": -137.007080354
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 52,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 101,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 101-1",
            "LABEL": "101-1",
            "FISHERY_AREA_ID": 2286,
            "OBJECTID": 86,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4953742720.37,
            "FEATURE_LENGTH_M": 534015.3052,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.90775841192728,
            "lng": -134.14646397543183
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 54.1735196145,
                "lng": -133.0202488647
            },
            {
                "lat": 54.1759037988,
                "lng": -133.0250091766
            },
            {
                "lat": 54.1775243958,
                "lng": -133.0328824842
            },
            {
                "lat": 54.1791490783,
                "lng": -133.0423883258
            },
            {
                "lat": 54.1791496305,
                "lng": -133.0491020723
            },
            {
                "lat": 54.1777572189,
                "lng": -133.0567471745
            },
            {
                "lat": 54.17752433,
                "lng": -133.0646207275
            },
            {
                "lat": 54.1779899388,
                "lng": -133.0738981859
            },
            {
                "lat": 54.1789165783,
                "lng": -133.0822298933
            },
            {
                "lat": 54.1771773033,
                "lng": -133.0860292963
            },
            {
                "lat": 54.176380018,
                "lng": -133.0869289868
            },
            {
                "lat": 54.1758690304,
                "lng": -133.087523943
            },
            {
                "lat": 54.1758418587,
                "lng": -133.0876610665
            },
            {
                "lat": 54.1758652743,
                "lng": -133.0882409483
            },
            {
                "lat": 54.1758616764,
                "lng": -133.0928038094
            },
            {
                "lat": 54.1758616372,
                "lng": -133.1014253844
            },
            {
                "lat": 54.0000000925,
                "lng": -133.1724402085
            },
            {
                "lat": 53.9374382759,
                "lng": -133.1977074972
            },
            {
                "lat": 53.9374653655,
                "lng": -133.1969448462
            },
            {
                "lat": 53.9373593416,
                "lng": -133.1959230957
            },
            {
                "lat": 53.937359297,
                "lng": -133.1934502842
            },
            {
                "lat": 53.9363359422,
                "lng": -133.1916812318
            },
            {
                "lat": 53.9330019359,
                "lng": -133.1876983568
            },
            {
                "lat": 53.9277374592,
                "lng": -133.1817779077
            },
            {
                "lat": 53.923541772,
                "lng": -133.1773836968
            },
            {
                "lat": 53.9195138426,
                "lng": -133.175231292
            },
            {
                "lat": 53.9163931197,
                "lng": -133.1752784158
            },
            {
                "lat": 53.9139213437,
                "lng": -133.176834649
            },
            {
                "lat": 53.9131124971,
                "lng": -133.1773521311
            },
            {
                "lat": 53.7911185747,
                "lng": -133.1249693127
            },
            {
                "lat": 53.778545425,
                "lng": -133.119582759
            },
            {
                "lat": 53.7788655933,
                "lng": -133.1185904312
            },
            {
                "lat": 53.7789647258,
                "lng": -133.116988883
            },
            {
                "lat": 53.7792475297,
                "lng": -133.1155544469
            },
            {
                "lat": 53.7798923366,
                "lng": -133.1140902302
            },
            {
                "lat": 53.7820169525,
                "lng": -133.1143029251
            },
            {
                "lat": 53.7852022371,
                "lng": -133.114455464
            },
            {
                "lat": 53.7892450548,
                "lng": -133.1161195394
            },
            {
                "lat": 53.7908134614,
                "lng": -133.1186073106
            },
            {
                "lat": 53.8020510807,
                "lng": -133.1177214748
            },
            {
                "lat": 53.8044735278,
                "lng": -133.1154023148
            },
            {
                "lat": 53.8070909029,
                "lng": -133.1039885848
            },
            {
                "lat": 53.8111385217,
                "lng": -133.090423666
            },
            {
                "lat": 53.8137556508,
                "lng": -133.0816192254
            },
            {
                "lat": 53.8166086858,
                "lng": -133.0754243825
            },
            {
                "lat": 53.8237496328,
                "lng": -133.0737614555
            },
            {
                "lat": 53.8280290645,
                "lng": -133.0747220687
            },
            {
                "lat": 53.8320766404,
                "lng": -133.0792392669
            },
            {
                "lat": 53.8354075405,
                "lng": -133.0868533818
            },
            {
                "lat": 53.8473048724,
                "lng": -133.0923312839
            },
            {
                "lat": 53.8582491734,
                "lng": -133.0968480977
            },
            {
                "lat": 53.8646733678,
                "lng": -133.1044613311
            },
            {
                "lat": 53.867806002,
                "lng": -133.1125332447
            },
            {
                "lat": 53.8824615626,
                "lng": -133.1273047119
            },
            {
                "lat": 53.9032819974,
                "lng": -133.1249240465
            },
            {
                "lat": 53.9310834403,
                "lng": -133.1075442528
            },
            {
                "lat": 53.9901923216,
                "lng": -133.1028902435
            },
            {
                "lat": 53.9913215734,
                "lng": -133.0987551237
            },
            {
                "lat": 53.9948920047,
                "lng": -133.0743560613
            },
            {
                "lat": 54.0085716335,
                "lng": -133.0535424137
            },
            {
                "lat": 54.0329631654,
                "lng": -133.0416414589
            },
            {
                "lat": 54.0716282395,
                "lng": -133.045807035
            },
            {
                "lat": 54.09899131,
                "lng": -133.0565178882
            },
            {
                "lat": 54.113270104,
                "lng": -133.0725702676
            },
            {
                "lat": 54.1275449242,
                "lng": -133.0642554123
            },
            {
                "lat": 54.1418230439,
                "lng": -133.0511630381
            },
            {
                "lat": 54.1519360801,
                "lng": -133.0356902636
            },
            {
                "lat": 54.1662096795,
                "lng": -133.0196380662
            },
            {
                "lat": 54.1735196145,
                "lng": -133.0202488647
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 53,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 1,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 1-1",
            "LABEL": "1-1",
            "FISHERY_AREA_ID": 1885,
            "OBJECTID": 87,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 207028248.4753,
            "FEATURE_LENGTH_M": 102688.1437,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.973760708928985,
            "lng": -133.10221571276816
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.8386878238,
                "lng": -130.0699606294
            },
            {
                "lat": 53.8548779879,
                "lng": -130.0950780334
            },
            {
                "lat": 53.8686449983,
                "lng": -130.1165618424
            },
            {
                "lat": 53.8771168228,
                "lng": -130.1342619551
            },
            {
                "lat": 53.8869517916,
                "lng": -130.1667943847
            },
            {
                "lat": 53.8999669828,
                "lng": -130.1924290591
            },
            {
                "lat": 53.9067876564,
                "lng": -130.2036900521
            },
            {
                "lat": 53.89842219,
                "lng": -130.2106174114
            },
            {
                "lat": 53.8828653833,
                "lng": -130.2301787591
            },
            {
                "lat": 53.8625905532,
                "lng": -130.254989038
            },
            {
                "lat": 53.8451954796,
                "lng": -130.2677003445
            },
            {
                "lat": 53.8364182178,
                "lng": -130.2627114676
            },
            {
                "lat": 53.8308217392,
                "lng": -130.2359305085
            },
            {
                "lat": 53.8302151295,
                "lng": -130.2055212347
            },
            {
                "lat": 53.8264620899,
                "lng": -130.1698757004
            },
            {
                "lat": 53.8176577812,
                "lng": -130.1608279113
            },
            {
                "lat": 53.8057600281,
                "lng": -130.1632082486
            },
            {
                "lat": 53.7990986855,
                "lng": -130.1786805309
            },
            {
                "lat": 53.7946203805,
                "lng": -130.2106936241
            },
            {
                "lat": 53.7842373491,
                "lng": -130.197463469
            },
            {
                "lat": 53.7670854191,
                "lng": -130.1958013659
            },
            {
                "lat": 53.7223436724,
                "lng": -130.1554108767
            },
            {
                "lat": 53.7185628544,
                "lng": -130.1415403841
            },
            {
                "lat": 53.7180862207,
                "lng": -130.1227564698
            },
            {
                "lat": 53.7254636116,
                "lng": -130.105620932
            },
            {
                "lat": 53.7309372918,
                "lng": -130.0908657242
            },
            {
                "lat": 53.7292705283,
                "lng": -130.0761109124
            },
            {
                "lat": 53.7211797064,
                "lng": -130.067550761
            },
            {
                "lat": 53.7085685201,
                "lng": -130.0680240528
            },
            {
                "lat": 53.6992910871,
                "lng": -130.0720665878
            },
            {
                "lat": 53.6750185237,
                "lng": -130.0773005619
            },
            {
                "lat": 53.6616937631,
                "lng": -130.07730101
            },
            {
                "lat": 53.6431345951,
                "lng": -130.0749201694
            },
            {
                "lat": 53.6362351115,
                "lng": -130.0725404699
            },
            {
                "lat": 53.6330833751,
                "lng": -130.0720968173
            },
            {
                "lat": 53.6387251353,
                "lng": -130.0589143741
            },
            {
                "lat": 53.6460985028,
                "lng": -130.030364313
            },
            {
                "lat": 53.6546669164,
                "lng": -129.9908598471
            },
            {
                "lat": 53.6637074621,
                "lng": -129.9344635791
            },
            {
                "lat": 53.6572837079,
                "lng": -129.9190067072
            },
            {
                "lat": 53.6538388935,
                "lng": -129.9132692536
            },
            {
                "lat": 53.6465761838,
                "lng": -129.9002078827
            },
            {
                "lat": 53.6401520239,
                "lng": -129.8716426532
            },
            {
                "lat": 53.6415782247,
                "lng": -129.8538061426
            },
            {
                "lat": 53.6361045729,
                "lng": -129.8430946607
            },
            {
                "lat": 53.6284908681,
                "lng": -129.8328710342
            },
            {
                "lat": 53.6208760648,
                "lng": -129.8300022748
            },
            {
                "lat": 53.6127890563,
                "lng": -129.8342894369
            },
            {
                "lat": 53.6168333421,
                "lng": -129.8592676729
            },
            {
                "lat": 53.6234932648,
                "lng": -129.890213724
            },
            {
                "lat": 53.6306342578,
                "lng": -129.9111487995
            },
            {
                "lat": 53.6389615309,
                "lng": -129.9266195895
            },
            {
                "lat": 53.6425330802,
                "lng": -129.9380344928
            },
            {
                "lat": 53.6377715735,
                "lng": -129.9487457998
            },
            {
                "lat": 53.6363451927,
                "lng": -129.9613485955
            },
            {
                "lat": 53.6273001979,
                "lng": -129.9880069963
            },
            {
                "lat": 53.6234930291,
                "lng": -130.0108486794
            },
            {
                "lat": 53.6230204804,
                "lng": -130.0455928664
            },
            {
                "lat": 53.6234966382,
                "lng": -130.0474698061
            },
            {
                "lat": 53.6237411314,
                "lng": -130.0605782233
            },
            {
                "lat": 53.6224091259,
                "lng": -130.060286664
            },
            {
                "lat": 53.6136699786,
                "lng": -130.0526125365
            },
            {
                "lat": 53.6001086649,
                "lng": -130.0361937847
            },
            {
                "lat": 53.5827376256,
                "lng": -130.0171664209
            },
            {
                "lat": 53.5770260068,
                "lng": -130.0059810523
            },
            {
                "lat": 53.5826334861,
                "lng": -129.9904630533
            },
            {
                "lat": 53.5929982773,
                "lng": -129.9758147597
            },
            {
                "lat": 53.6058656237,
                "lng": -129.9672399592
            },
            {
                "lat": 53.6158715746,
                "lng": -129.9529415308
            },
            {
                "lat": 53.6187282416,
                "lng": -129.9415132529
            },
            {
                "lat": 53.6201588708,
                "lng": -129.9322211281
            },
            {
                "lat": 53.6194452653,
                "lng": -129.9193413262
            },
            {
                "lat": 53.6147991408,
                "lng": -129.9057619806
            },
            {
                "lat": 53.6069375496,
                "lng": -129.8943328859
            },
            {
                "lat": 53.594783812,
                "lng": -129.8878929145
            },
            {
                "lat": 53.5787010355,
                "lng": -129.8746797697
            },
            {
                "lat": 53.5608328319,
                "lng": -129.8589474504
            },
            {
                "lat": 53.545822234,
                "lng": -129.8475040475
            },
            {
                "lat": 53.5358158631,
                "lng": -129.8242792869
            },
            {
                "lat": 53.5279500933,
                "lng": -129.8042608339
            },
            {
                "lat": 53.5161595666,
                "lng": -129.7996217848
            },
            {
                "lat": 53.5011475395,
                "lng": -129.8039087291
            },
            {
                "lat": 53.4882815554,
                "lng": -129.8285682726
            },
            {
                "lat": 53.4779170525,
                "lng": -129.8503727912
            },
            {
                "lat": 53.4668391074,
                "lng": -129.8610988533
            },
            {
                "lat": 53.4596932172,
                "lng": -129.8559718981
            },
            {
                "lat": 53.466232553,
                "lng": -129.8526311397
            },
            {
                "lat": 53.4726564271,
                "lng": -129.8438265749
            },
            {
                "lat": 53.4745593669,
                "lng": -129.8319240731
            },
            {
                "lat": 53.4681355487,
                "lng": -129.8107450419
            },
            {
                "lat": 53.4596212639,
                "lng": -129.8022309515
            },
            {
                "lat": 53.4637026724,
                "lng": -129.7819972773
            },
            {
                "lat": 53.461040093,
                "lng": -129.7768852564
            },
            {
                "lat": 53.4510997063,
                "lng": -129.7740323494
            },
            {
                "lat": 53.4436069064,
                "lng": -129.7752384916
            },
            {
                "lat": 53.4402576887,
                "lng": -129.785537381
            },
            {
                "lat": 53.4401249017,
                "lng": -129.8033446675
            },
            {
                "lat": 53.4395906105,
                "lng": -129.8199464903
            },
            {
                "lat": 53.4353068461,
                "lng": -129.8263705668
            },
            {
                "lat": 53.4292831313,
                "lng": -129.8255605431
            },
            {
                "lat": 53.4225276925,
                "lng": -129.8225404615
            },
            {
                "lat": 53.4095382216,
                "lng": -129.8230890991
            },
            {
                "lat": 53.3972202403,
                "lng": -129.8080904215
            },
            {
                "lat": 53.4068221905,
                "lng": -129.80041508
            },
            {
                "lat": 53.4141994064,
                "lng": -129.7885133734
            },
            {
                "lat": 53.4101528461,
                "lng": -129.7790067706
            },
            {
                "lat": 53.3949247555,
                "lng": -129.7792354417
            },
            {
                "lat": 53.3844566435,
                "lng": -129.7547301197
            },
            {
                "lat": 53.3732723422,
                "lng": -129.7523500136
            },
            {
                "lat": 53.3610078977,
                "lng": -129.7537986241
            },
            {
                "lat": 53.3418611971,
                "lng": -129.7271268618
            },
            {
                "lat": 53.3373407725,
                "lng": -129.7147526035
            },
            {
                "lat": 53.3442234827,
                "lng": -129.7091983712
            },
            {
                "lat": 53.3485072596,
                "lng": -129.6894532566
            },
            {
                "lat": 53.3518368598,
                "lng": -129.6658936969
            },
            {
                "lat": 53.3618310187,
                "lng": -129.6613769372
            },
            {
                "lat": 53.3737299774,
                "lng": -129.6532743518
            },
            {
                "lat": 53.3751561104,
                "lng": -129.6428072322
            },
            {
                "lat": 53.3675424836,
                "lng": -129.619018394
            },
            {
                "lat": 53.3577877964,
                "lng": -129.6002197222
            },
            {
                "lat": 53.3464316413,
                "lng": -129.5983576041
            },
            {
                "lat": 53.3423499158,
                "lng": -129.5986625881
            },
            {
                "lat": 53.3320122796,
                "lng": -129.5914612011
            },
            {
                "lat": 53.3279380093,
                "lng": -129.5765228917
            },
            {
                "lat": 53.3238636057,
                "lng": -129.5714259197
            },
            {
                "lat": 53.3163955599,
                "lng": -129.5700681345
            },
            {
                "lat": 53.30773544,
                "lng": -129.5775296448
            },
            {
                "lat": 53.3014568849,
                "lng": -129.6184380496
            },
            {
                "lat": 53.3011168038,
                "lng": -129.648834393
            },
            {
                "lat": 53.2784382974,
                "lng": -129.6354978858
            },
            {
                "lat": 53.2532159055,
                "lng": -129.6105043795
            },
            {
                "lat": 53.2351305817,
                "lng": -129.5924227959
            },
            {
                "lat": 53.2191549278,
                "lng": -129.5824428445
            },
            {
                "lat": 53.2218474247,
                "lng": -129.5789487906
            },
            {
                "lat": 53.2295378349,
                "lng": -129.5689846649
            },
            {
                "lat": 53.248336634,
                "lng": -129.5352024094
            },
            {
                "lat": 53.2728235995,
                "lng": -129.539565905
            },
            {
                "lat": 53.3062052873,
                "lng": -129.5544589922
            },
            {
                "lat": 53.3323781658,
                "lng": -129.5586243509
            },
            {
                "lat": 53.3472479143,
                "lng": -129.5604104095
            },
            {
                "lat": 53.3514144529,
                "lng": -129.5526733077
            },
            {
                "lat": 53.3323778918,
                "lng": -129.524704195
            },
            {
                "lat": 53.3170889784,
                "lng": -129.5053709082
            },
            {
                "lat": 53.334217471,
                "lng": -129.492141804
            },
            {
                "lat": 53.3435550868,
                "lng": -129.4924921973
            },
            {
                "lat": 53.3560103025,
                "lng": -129.4876864418
            },
            {
                "lat": 53.3658402486,
                "lng": -129.4817803901
            },
            {
                "lat": 53.3732675263,
                "lng": -129.4771879338
            },
            {
                "lat": 53.3827706131,
                "lng": -129.4710992601
            },
            {
                "lat": 53.4048314191,
                "lng": -129.4615779364
            },
            {
                "lat": 53.4238665665,
                "lng": -129.4694373471
            },
            {
                "lat": 53.4581334843,
                "lng": -129.5132136918
            },
            {
                "lat": 53.480023123,
                "lng": -129.549149143
            },
            {
                "lat": 53.5080990627,
                "lng": -129.5857851004
            },
            {
                "lat": 53.5307848589,
                "lng": -129.6183159573
            },
            {
                "lat": 53.5537301168,
                "lng": -129.6417387588
            },
            {
                "lat": 53.5587047179,
                "lng": -129.6508484366
            },
            {
                "lat": 53.5683401325,
                "lng": -129.6622163893
            },
            {
                "lat": 53.5856328113,
                "lng": -129.6939244682
            },
            {
                "lat": 53.6000100353,
                "lng": -129.7214814349
            },
            {
                "lat": 53.6378516166,
                "lng": -129.7555698006
            },
            {
                "lat": 53.7489668409,
                "lng": -129.9149784308
            },
            {
                "lat": 53.7546002701,
                "lng": -129.926177876
            },
            {
                "lat": 53.7854269549,
                "lng": -129.979385161
            },
            {
                "lat": 53.7858739521,
                "lng": -129.9921266087
            },
            {
                "lat": 53.7796477663,
                "lng": -130.0022122056
            },
            {
                "lat": 53.7754976891,
                "lng": -130.0159907973
            },
            {
                "lat": 53.7977298591,
                "lng": -130.025177359
            },
            {
                "lat": 53.8076634551,
                "lng": -130.024444633
            },
            {
                "lat": 53.8263364234,
                "lng": -130.0383768017
            },
            {
                "lat": 53.8322827334,
                "lng": -130.0600287968
            },
            {
                "lat": 53.8386878238,
                "lng": -130.0699606294
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 88,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 88,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 968959435.9356,
            "FEATURE_LENGTH_M": 292476.0501,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.55799555773606,
            "lng": -129.8588808451447
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.7709809792,
                "lng": -133.0151979735
            },
            {
                "lat": 53.7687153559,
                "lng": -133.0130612485
            },
            {
                "lat": 53.7578735116,
                "lng": -133.0011292484
            },
            {
                "lat": 53.7597538246,
                "lng": -133.0002436348
            },
            {
                "lat": 53.7605394826,
                "lng": -132.9978177898
            },
            {
                "lat": 53.7606430681,
                "lng": -132.9974513639
            },
            {
                "lat": 53.762321924,
                "lng": -132.9916224975
            },
            {
                "lat": 53.7660250491,
                "lng": -132.9872744777
            },
            {
                "lat": 53.7689317788,
                "lng": -132.9864650255
            },
            {
                "lat": 53.7732961752,
                "lng": -132.9871974761
            },
            {
                "lat": 53.7754058032,
                "lng": -132.9885712158
            },
            {
                "lat": 53.7750053178,
                "lng": -132.9930410198
            },
            {
                "lat": 53.7732242985,
                "lng": -133.0023799043
            },
            {
                "lat": 53.7729717597,
                "lng": -133.0041961009
            },
            {
                "lat": 53.7734338881,
                "lng": -133.0092160039
            },
            {
                "lat": 53.772903176,
                "lng": -133.012878412
            },
            {
                "lat": 53.7709809792,
                "lng": -133.0151979735
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 89,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 100,
            "MANAGEMENT_AREA_NAME": "Subarea 2-100",
            "LABEL": "2-100",
            "FISHERY_AREA_ID": 1989,
            "OBJECTID": 89,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2208425.9782,
            "FEATURE_LENGTH_M": 6068.324,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.76841213950588,
            "lng": -133.00017302152352
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.7578735116,
                "lng": -133.0011292484
            },
            {
                "lat": 53.7687153559,
                "lng": -133.0130612485
            },
            {
                "lat": 53.7709809792,
                "lng": -133.0151979735
            },
            {
                "lat": 53.7702370432,
                "lng": -133.0188747393
            },
            {
                "lat": 53.769287183,
                "lng": -133.0254363545
            },
            {
                "lat": 53.7667082872,
                "lng": -133.0282286735
            },
            {
                "lat": 53.7614407507,
                "lng": -133.0324864557
            },
            {
                "lat": 53.7568433377,
                "lng": -133.0360258518
            },
            {
                "lat": 53.752136979,
                "lng": -133.0369263004
            },
            {
                "lat": 53.7505115367,
                "lng": -133.0379792865
            },
            {
                "lat": 53.7475430513,
                "lng": -133.03778064
            },
            {
                "lat": 53.7475396922,
                "lng": -133.0114598122
            },
            {
                "lat": 53.7474254485,
                "lng": -133.0102683334
            },
            {
                "lat": 53.7479515965,
                "lng": -133.0101928067
            },
            {
                "lat": 53.7484441337,
                "lng": -133.0102681691
            },
            {
                "lat": 53.7489888915,
                "lng": -133.0103456588
            },
            {
                "lat": 53.749953909,
                "lng": -133.009887773
            },
            {
                "lat": 53.7508551585,
                "lng": -133.0089713797
            },
            {
                "lat": 53.7515907636,
                "lng": -133.0081635668
            },
            {
                "lat": 53.7525209089,
                "lng": -133.0070801412
            },
            {
                "lat": 53.752834096,
                "lng": -133.0061333231
            },
            {
                "lat": 53.7578735116,
                "lng": -133.0011292484
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 90,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 99,
            "MANAGEMENT_AREA_NAME": "Subarea 2-99",
            "LABEL": "2-99",
            "FISHERY_AREA_ID": 1988,
            "OBJECTID": 90,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4328459.775,
            "FEATURE_LENGTH_M": 8203.8304,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.75582982388637,
            "lng": -133.01713759020456
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.884376656,
                "lng": -130.0321356969
            },
            {
                "lat": 53.871669472,
                "lng": -130.0430292134
            },
            {
                "lat": 53.8719897278,
                "lng": -130.0494847416
            },
            {
                "lat": 53.8721503754,
                "lng": -130.0500949206
            },
            {
                "lat": 53.872596141,
                "lng": -130.0507500835
            },
            {
                "lat": 53.8561862712,
                "lng": -130.0688477302
            },
            {
                "lat": 53.8547251876,
                "lng": -130.068145764
            },
            {
                "lat": 53.8535156968,
                "lng": -130.0660252182
            },
            {
                "lat": 53.8501852659,
                "lng": -130.0646666073
            },
            {
                "lat": 53.8474612581,
                "lng": -130.0669402505
            },
            {
                "lat": 53.8435287351,
                "lng": -130.0657203297
            },
            {
                "lat": 53.8386878238,
                "lng": -130.0699606294
            },
            {
                "lat": 53.8322827334,
                "lng": -130.0600287968
            },
            {
                "lat": 53.8263364234,
                "lng": -130.0383768017
            },
            {
                "lat": 53.8076634551,
                "lng": -130.024444633
            },
            {
                "lat": 53.7977298591,
                "lng": -130.025177359
            },
            {
                "lat": 53.7754976891,
                "lng": -130.0159907973
            },
            {
                "lat": 53.7796477663,
                "lng": -130.0022122056
            },
            {
                "lat": 53.7858739521,
                "lng": -129.9921266087
            },
            {
                "lat": 53.7854269549,
                "lng": -129.979385161
            },
            {
                "lat": 53.7546002701,
                "lng": -129.926177876
            },
            {
                "lat": 53.7489668409,
                "lng": -129.9149784308
            },
            {
                "lat": 53.6378516166,
                "lng": -129.7555698006
            },
            {
                "lat": 53.6000100353,
                "lng": -129.7214814349
            },
            {
                "lat": 53.5856328113,
                "lng": -129.6939244682
            },
            {
                "lat": 53.5683401325,
                "lng": -129.6622163893
            },
            {
                "lat": 53.5705487897,
                "lng": -129.6590111144
            },
            {
                "lat": 53.5705488223,
                "lng": -129.6501012283
            },
            {
                "lat": 53.580593225,
                "lng": -129.6405642353
            },
            {
                "lat": 53.605193986,
                "lng": -129.6687169377
            },
            {
                "lat": 53.6254317161,
                "lng": -129.688950191
            },
            {
                "lat": 53.6461034772,
                "lng": -129.6780545547
            },
            {
                "lat": 53.6607775936,
                "lng": -129.6808781552
            },
            {
                "lat": 53.6828615252,
                "lng": -129.6952515981
            },
            {
                "lat": 53.7138408268,
                "lng": -129.6992491658
            },
            {
                "lat": 53.7280690424,
                "lng": -129.7241516462
            },
            {
                "lat": 53.7338492522,
                "lng": -129.7807773091
            },
            {
                "lat": 53.7639391939,
                "lng": -129.8099671963
            },
            {
                "lat": 53.7599330518,
                "lng": -129.8313140219
            },
            {
                "lat": 53.7514868976,
                "lng": -129.8414008582
            },
            {
                "lat": 53.7634933494,
                "lng": -129.8590246978
            },
            {
                "lat": 53.7840965212,
                "lng": -129.8941647813
            },
            {
                "lat": 53.8021778686,
                "lng": -129.9229125006
            },
            {
                "lat": 53.807067578,
                "lng": -129.9190516701
            },
            {
                "lat": 53.7990650665,
                "lng": -129.870301049
            },
            {
                "lat": 53.7983245691,
                "lng": -129.8388672622
            },
            {
                "lat": 53.8092919177,
                "lng": -129.8408055043
            },
            {
                "lat": 53.8218915857,
                "lng": -129.8505852863
            },
            {
                "lat": 53.8278195672,
                "lng": -129.8685145183
            },
            {
                "lat": 53.8290058305,
                "lng": -129.9571530848
            },
            {
                "lat": 53.8370091127,
                "lng": -129.9832302007
            },
            {
                "lat": 53.8454555536,
                "lng": -129.9937586905
            },
            {
                "lat": 53.8491629938,
                "lng": -129.9899134713
            },
            {
                "lat": 53.8539049966,
                "lng": -129.9728692846
            },
            {
                "lat": 53.8644296554,
                "lng": -129.9575957223
            },
            {
                "lat": 53.8770262403,
                "lng": -129.9567108574
            },
            {
                "lat": 53.8890339453,
                "lng": -129.9681242343
            },
            {
                "lat": 53.897331336,
                "lng": -129.9925690381
            },
            {
                "lat": 53.8951110596,
                "lng": -130.0149539189
            },
            {
                "lat": 53.884376656,
                "lng": -130.0321356969
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 91,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 23,
            "MANAGEMENT_AREA_NAME": "Subarea 5-23",
            "LABEL": "5-23",
            "FISHERY_AREA_ID": 2026,
            "OBJECTID": 91,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 245164680.8422,
            "FEATURE_LENGTH_M": 118822.3796,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.77885309923,
            "lng": -129.90399202716833
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.8666647774,
                "lng": -130.8051910289
            },
            {
                "lat": 53.8666648701,
                "lng": -130.8052588244
            },
            {
                "lat": 53.8474775394,
                "lng": -130.7963317098
            },
            {
                "lat": 53.8474774042,
                "lng": -130.796264888
            },
            {
                "lat": 53.8666647774,
                "lng": -130.8051910289
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 92,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 92,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 9450.9314,
            "FEATURE_LENGTH_M": 4448.8986,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.8589898737,
            "lng": -130.80164749600002
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.7475430513,
                "lng": -133.03778064
            },
            {
                "lat": 53.7475315579,
                "lng": -133.1047206753
            },
            {
                "lat": 53.7103542779,
                "lng": -133.0868990188
            },
            {
                "lat": 53.7103391044,
                "lng": -132.9891961492
            },
            {
                "lat": 53.7109337051,
                "lng": -132.9891815082
            },
            {
                "lat": 53.7116011056,
                "lng": -132.9882968924
            },
            {
                "lat": 53.7128490401,
                "lng": -132.9870604451
            },
            {
                "lat": 53.7138360975,
                "lng": -132.985443464
            },
            {
                "lat": 53.7183567425,
                "lng": -132.9857946912
            },
            {
                "lat": 53.7297778406,
                "lng": -132.9879464068
            },
            {
                "lat": 53.7414358881,
                "lng": -132.9869847789
            },
            {
                "lat": 53.7492902361,
                "lng": -132.9912718012
            },
            {
                "lat": 53.7540470497,
                "lng": -132.9979246408
            },
            {
                "lat": 53.7538109516,
                "lng": -133.0031740254
            },
            {
                "lat": 53.752834096,
                "lng": -133.0061333231
            },
            {
                "lat": 53.7525209089,
                "lng": -133.0070801412
            },
            {
                "lat": 53.7515907636,
                "lng": -133.0081635668
            },
            {
                "lat": 53.7508551585,
                "lng": -133.0089713797
            },
            {
                "lat": 53.749953909,
                "lng": -133.009887773
            },
            {
                "lat": 53.7489888915,
                "lng": -133.0103456588
            },
            {
                "lat": 53.7484441337,
                "lng": -133.0102681691
            },
            {
                "lat": 53.7479515965,
                "lng": -133.0101928067
            },
            {
                "lat": 53.7474254485,
                "lng": -133.0102683334
            },
            {
                "lat": 53.7475396922,
                "lng": -133.0114598122
            },
            {
                "lat": 53.7475430513,
                "lng": -133.03778064
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 93,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 97,
            "MANAGEMENT_AREA_NAME": "Subarea 2-97",
            "LABEL": "2-97",
            "FISHERY_AREA_ID": 1986,
            "OBJECTID": 93,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 30345256.0428,
            "FEATURE_LENGTH_M": 23404.3942,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.73829417192401,
            "lng": -133.01008906965203
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.8444902599,
                "lng": -130.4838706779
            },
            {
                "lat": 53.8433003501,
                "lng": -130.4931488802
            },
            {
                "lat": 53.840683494,
                "lng": -130.4995733876
            },
            {
                "lat": 53.8378032481,
                "lng": -130.5034331517
            },
            {
                "lat": 53.8198925714,
                "lng": -130.5001530282
            },
            {
                "lat": 53.7969892453,
                "lng": -130.4330135456
            },
            {
                "lat": 53.7961582772,
                "lng": -130.4322818804
            },
            {
                "lat": 53.7916981378,
                "lng": -130.4298405785
            },
            {
                "lat": 53.7898448097,
                "lng": -130.4233249034
            },
            {
                "lat": 53.788543312,
                "lng": -130.4156041455
            },
            {
                "lat": 53.7885433085,
                "lng": -130.4108578437
            },
            {
                "lat": 53.7915037446,
                "lng": -130.4095308012
            },
            {
                "lat": 53.7884711485,
                "lng": -130.3954015442
            },
            {
                "lat": 53.7960892198,
                "lng": -130.31286606
            },
            {
                "lat": 53.8283990613,
                "lng": -130.3414611332
            },
            {
                "lat": 53.830570372,
                "lng": -130.3439630015
            },
            {
                "lat": 53.831520608,
                "lng": -130.3482514589
            },
            {
                "lat": 53.8360409036,
                "lng": -130.3534849898
            },
            {
                "lat": 53.8378251713,
                "lng": -130.3599084556
            },
            {
                "lat": 53.8337826149,
                "lng": -130.3715660257
            },
            {
                "lat": 53.8305621876,
                "lng": -130.3787239983
            },
            {
                "lat": 53.8317264969,
                "lng": -130.3987570085
            },
            {
                "lat": 53.8406834925,
                "lng": -130.4067840114
            },
            {
                "lat": 53.862335512,
                "lng": -130.4253383175
            },
            {
                "lat": 53.8723296073,
                "lng": -130.4346161539
            },
            {
                "lat": 53.8680455456,
                "lng": -130.4484245493
            },
            {
                "lat": 53.8494869355,
                "lng": -130.4577033306
            },
            {
                "lat": 53.8456802466,
                "lng": -130.4686425956
            },
            {
                "lat": 53.8444902599,
                "lng": -130.4838706779
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 94,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 5-3",
            "LABEL": "5-3",
            "FISHERY_AREA_ID": 2007,
            "OBJECTID": 94,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 58704990.8106,
            "FEATURE_LENGTH_M": 36088.7743,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.82612034972069,
            "lng": -130.41946193571724
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.8582802584,
                "lng": -130.6142645438
            },
            {
                "lat": 53.8583211181,
                "lng": -130.6169425331
            },
            {
                "lat": 53.8583224344,
                "lng": -130.6170104385
            },
            {
                "lat": 53.8572770756,
                "lng": -130.6256923755
            },
            {
                "lat": 53.8588219181,
                "lng": -130.6339172965
            },
            {
                "lat": 53.8576313019,
                "lng": -130.6384499811
            },
            {
                "lat": 53.8556903229,
                "lng": -130.6389072153
            },
            {
                "lat": 53.8516814041,
                "lng": -130.639868464
            },
            {
                "lat": 53.8495403361,
                "lng": -130.6539056518
            },
            {
                "lat": 53.8527869845,
                "lng": -130.6630321659
            },
            {
                "lat": 53.8489337484,
                "lng": -130.6950748804
            },
            {
                "lat": 53.8477056293,
                "lng": -130.6990262754
            },
            {
                "lat": 53.848064437,
                "lng": -130.7023229075
            },
            {
                "lat": 53.8474843213,
                "lng": -130.7072205585
            },
            {
                "lat": 53.8108484686,
                "lng": -130.641836831
            },
            {
                "lat": 53.8120114798,
                "lng": -130.6387544768
            },
            {
                "lat": 53.8143686641,
                "lng": -130.6350918172
            },
            {
                "lat": 53.8177599794,
                "lng": -130.626334652
            },
            {
                "lat": 53.8248486311,
                "lng": -130.6071087283
            },
            {
                "lat": 53.8327450157,
                "lng": -130.5905830697
            },
            {
                "lat": 53.8418078881,
                "lng": -130.5766211055
            },
            {
                "lat": 53.8464123943,
                "lng": -130.56645862
            },
            {
                "lat": 53.8493923286,
                "lng": -130.5650845605
            },
            {
                "lat": 53.8503910913,
                "lng": -130.5661083849
            },
            {
                "lat": 53.8555414148,
                "lng": -130.588534766
            },
            {
                "lat": 53.8555548501,
                "lng": -130.5885944582
            },
            {
                "lat": 53.8590257365,
                "lng": -130.611012366
            },
            {
                "lat": 53.8590283967,
                "lng": -130.6110308037
            },
            {
                "lat": 53.8587381808,
                "lng": -130.6119836192
            },
            {
                "lat": 53.8582794949,
                "lng": -130.6141967001
            },
            {
                "lat": 53.8582802584,
                "lng": -130.6142645438
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 95,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 5-12",
            "LABEL": "5-12",
            "FISHERY_AREA_ID": 2016,
            "OBJECTID": 95,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 25908267.9314,
            "FEATURE_LENGTH_M": 23084.4557,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.846954050429034,
            "lng": -130.62578176742582
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.8582802584,
                "lng": -130.6142645438
            },
            {
                "lat": 53.8582794949,
                "lng": -130.6141967001
            },
            {
                "lat": 53.8587381808,
                "lng": -130.6119836192
            },
            {
                "lat": 53.8590283967,
                "lng": -130.6110308037
            },
            {
                "lat": 53.859035098,
                "lng": -130.6110754529
            },
            {
                "lat": 53.8587383822,
                "lng": -130.6120514011
            },
            {
                "lat": 53.8582802584,
                "lng": -130.6142645438
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 96,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 96,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 380.6667,
            "FEATURE_LENGTH_M": 457.9374,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.858625724199996,
            "lng": -130.61269529494285
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.8474774042,
                "lng": -130.796264888
            },
            {
                "lat": 53.8474775394,
                "lng": -130.7963317098
            },
            {
                "lat": 53.634472408,
                "lng": -130.6972268393
            },
            {
                "lat": 53.6344722546,
                "lng": -130.6971594202
            },
            {
                "lat": 53.8474774042,
                "lng": -130.796264888
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 97,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 97,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 104890.8816,
            "FEATURE_LENGTH_M": 49307.3216,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.76227540208,
            "lng": -130.75664954906
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.3744016723,
                "lng": -129.1030731123
            },
            {
                "lat": 53.3624073068,
                "lng": -129.1226352288
            },
            {
                "lat": 53.3584446711,
                "lng": -129.1291052796
            },
            {
                "lat": 53.340122199,
                "lng": -129.1360015304
            },
            {
                "lat": 53.3301275811,
                "lng": -129.130523254
            },
            {
                "lat": 53.321800451,
                "lng": -129.1224369615
            },
            {
                "lat": 53.3298915446,
                "lng": -129.0953065873
            },
            {
                "lat": 53.3475001899,
                "lng": -129.0636600429
            },
            {
                "lat": 53.3524967528,
                "lng": -129.0329737581
            },
            {
                "lat": 53.3520208511,
                "lng": -129.0096579905
            },
            {
                "lat": 53.3460694358,
                "lng": -128.9863277662
            },
            {
                "lat": 53.3284642797,
                "lng": -128.9649204334
            },
            {
                "lat": 53.3221544882,
                "lng": -128.9448546722
            },
            {
                "lat": 53.3372917334,
                "lng": -128.9404597077
            },
            {
                "lat": 53.3633804437,
                "lng": -128.9377890046
            },
            {
                "lat": 53.3943132191,
                "lng": -128.9401704369
            },
            {
                "lat": 53.4067920665,
                "lng": -128.9585567426
            },
            {
                "lat": 53.4389156606,
                "lng": -128.9882958656
            },
            {
                "lat": 53.4436722654,
                "lng": -129.0275573828
            },
            {
                "lat": 53.4020305408,
                "lng": -129.0763402712
            },
            {
                "lat": 53.3744016723,
                "lng": -129.1030731123
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 148,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 148,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 102670522.5632,
            "FEATURE_LENGTH_M": 46900.8422,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.36317614405715,
            "lng": -129.03874853051906
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.3666680692,
                "lng": -131.1999964036
            },
            {
                "lat": 53.2605404104,
                "lng": -131.7705388815
            },
            {
                "lat": 53.1088794552,
                "lng": -131.6457973695
            },
            {
                "lat": 52.9638714092,
                "lng": -131.5482781854
            },
            {
                "lat": 52.8779332137,
                "lng": -131.4904780341
            },
            {
                "lat": 52.8043097203,
                "lng": -131.4475409924
            },
            {
                "lat": 52.7051964489,
                "lng": -131.3897409344
            },
            {
                "lat": 52.5857155801,
                "lng": -131.3200535352
            },
            {
                "lat": 52.4416889662,
                "lng": -131.2360538686
            },
            {
                "lat": 52.2881966625,
                "lng": -131.0588685894
            },
            {
                "lat": 52.2084425392,
                "lng": -130.966811907
            },
            {
                "lat": 52.1666684868,
                "lng": -130.9565274769
            },
            {
                "lat": 52.1666683791,
                "lng": -129.9999997098
            },
            {
                "lat": 52.2833329069,
                "lng": -130.1083368701
            },
            {
                "lat": 52.84999821,
                "lng": -130.6833351283
            },
            {
                "lat": 53.1666677123,
                "lng": -130.9999991989
            },
            {
                "lat": 53.3666680692,
                "lng": -131.1999964036
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 149,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 102,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 102-2",
            "LABEL": "102-2",
            "FISHERY_AREA_ID": 2297,
            "OBJECTID": 149,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 7176448345.2002,
            "FEATURE_LENGTH_M": 396168.1318,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.74184977877646,
            "lng": -131.11896196992353
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.8130376406,
                "lng": -132.2467491876
            },
            {
                "lat": 52.8129537461,
                "lng": -133.282556832
            },
            {
                "lat": 52.8128698417,
                "lng": -134.3183642119
            },
            {
                "lat": 52.8127860175,
                "lng": -135.3541715694
            },
            {
                "lat": 52.8127025766,
                "lng": -136.3899792925
            },
            {
                "lat": 52.8126184866,
                "lng": -137.4257867693
            },
            {
                "lat": 52.8125346108,
                "lng": -138.4615940702
            },
            {
                "lat": 52.7614211598,
                "lng": -138.4335322867
            },
            {
                "lat": 52.5830800436,
                "lng": -138.3157349669
            },
            {
                "lat": 52.5192529172,
                "lng": -138.2811433421
            },
            {
                "lat": 52.3251306742,
                "lng": -138.1544801685
            },
            {
                "lat": 52.1360812184,
                "lng": -138.0093997304
            },
            {
                "lat": 51.9527740838,
                "lng": -137.8466490618
            },
            {
                "lat": 51.935883215,
                "lng": -137.8294987037
            },
            {
                "lat": 51.9358845029,
                "lng": -136.6939291084
            },
            {
                "lat": 51.9358852335,
                "lng": -135.558359705
            },
            {
                "lat": 51.9358866839,
                "lng": -134.4227906523
            },
            {
                "lat": 51.93588799,
                "lng": -133.2872209527
            },
            {
                "lat": 51.9358887634,
                "lng": -132.1516519627
            },
            {
                "lat": 51.9358899611,
                "lng": -131.0160824804
            },
            {
                "lat": 51.939277781,
                "lng": -131.0208889291
            },
            {
                "lat": 51.9419135754,
                "lng": -131.0244445287
            },
            {
                "lat": 51.9433056392,
                "lng": -131.0251310618
            },
            {
                "lat": 51.9438667593,
                "lng": -131.0271302458
            },
            {
                "lat": 51.9450231129,
                "lng": -131.0287317769
            },
            {
                "lat": 51.9474941615,
                "lng": -131.0275118861
            },
            {
                "lat": 51.9491798434,
                "lng": -131.0284114844
            },
            {
                "lat": 51.9510917346,
                "lng": -131.0299983343
            },
            {
                "lat": 51.9528892806,
                "lng": -131.0325773985
            },
            {
                "lat": 51.9540136815,
                "lng": -131.0356750205
            },
            {
                "lat": 51.9536784291,
                "lng": -131.0393826192
            },
            {
                "lat": 51.9537166664,
                "lng": -131.0407265639
            },
            {
                "lat": 51.9590950254,
                "lng": -131.0481568628
            },
            {
                "lat": 51.9605901503,
                "lng": -131.0486445322
            },
            {
                "lat": 51.963230476,
                "lng": -131.0506743909
            },
            {
                "lat": 51.9656563289,
                "lng": -131.0572052787
            },
            {
                "lat": 51.9855840359,
                "lng": -131.0847011633
            },
            {
                "lat": 51.9867819737,
                "lng": -131.0850679138
            },
            {
                "lat": 51.9897041429,
                "lng": -131.0858612257
            },
            {
                "lat": 51.9930186453,
                "lng": -131.0870359771
            },
            {
                "lat": 51.9955480695,
                "lng": -131.0883328919
            },
            {
                "lat": 51.9970662859,
                "lng": -131.0905297721
            },
            {
                "lat": 51.9981921544,
                "lng": -131.0938417238
            },
            {
                "lat": 51.9983599234,
                "lng": -131.0977782205
            },
            {
                "lat": 51.9988671548,
                "lng": -131.1012573601
            },
            {
                "lat": 52.0004694414,
                "lng": -131.1033788327
            },
            {
                "lat": 52.0011713514,
                "lng": -131.1042940612
            },
            {
                "lat": 52.0030980293,
                "lng": -131.1085510421
            },
            {
                "lat": 52.0031130396,
                "lng": -131.1087950753
            },
            {
                "lat": 52.1481627303,
                "lng": -131.3209383051
            },
            {
                "lat": 52.2125699207,
                "lng": -131.4151004548
            },
            {
                "lat": 52.8130376406,
                "lng": -132.2467491876
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 150,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 142,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 142-1",
            "LABEL": "142-1",
            "FISHERY_AREA_ID": 2346,
            "OBJECTID": 150,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 43438566272.3294,
            "FEATURE_LENGTH_M": 1122202.7797,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.15129262598654,
            "lng": -132.94744575337307
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.2560423557,
                "lng": -132.7148892638
            },
            {
                "lat": 53.2534638378,
                "lng": -132.7158049879
            },
            {
                "lat": 53.253463573,
                "lng": -132.7266235054
            },
            {
                "lat": 53.1520156336,
                "lng": -132.6292875928
            },
            {
                "lat": 53.1520076953,
                "lng": -132.5856172273
            },
            {
                "lat": 53.1767235606,
                "lng": -132.5638724496
            },
            {
                "lat": 53.1779483067,
                "lng": -132.5608667871
            },
            {
                "lat": 53.1791381171,
                "lng": -132.5562291762
            },
            {
                "lat": 53.181041452,
                "lng": -132.5481264657
            },
            {
                "lat": 53.1822311318,
                "lng": -132.5388498439
            },
            {
                "lat": 53.1860392651,
                "lng": -132.5326692315
            },
            {
                "lat": 53.1984096186,
                "lng": -132.5298151961
            },
            {
                "lat": 53.2088810146,
                "lng": -132.5355223449
            },
            {
                "lat": 53.22140168,
                "lng": -132.5418706856
            },
            {
                "lat": 53.2370222761,
                "lng": -132.5428774037
            },
            {
                "lat": 53.2475472154,
                "lng": -132.5296479252
            },
            {
                "lat": 53.2594333639,
                "lng": -132.5079035846
            },
            {
                "lat": 53.2713205426,
                "lng": -132.4919434508
            },
            {
                "lat": 53.2855836617,
                "lng": -132.4888920287
            },
            {
                "lat": 53.2886395839,
                "lng": -132.4987335186
            },
            {
                "lat": 53.2882995549,
                "lng": -132.5150450121
            },
            {
                "lat": 53.2794686406,
                "lng": -132.5462806067
            },
            {
                "lat": 53.2713205948,
                "lng": -132.5703885971
            },
            {
                "lat": 53.2635077787,
                "lng": -132.6040037328
            },
            {
                "lat": 53.2594332814,
                "lng": -132.6247255406
            },
            {
                "lat": 53.2580756777,
                "lng": -132.6383056888
            },
            {
                "lat": 53.2604517875,
                "lng": -132.6529088249
            },
            {
                "lat": 53.2638477755,
                "lng": -132.6658182289
            },
            {
                "lat": 53.2652053756,
                "lng": -132.6831360546
            },
            {
                "lat": 53.2648698174,
                "lng": -132.6912834249
            },
            {
                "lat": 53.2631679229,
                "lng": -132.6994331334
            },
            {
                "lat": 53.2601129661,
                "lng": -132.7079156485
            },
            {
                "lat": 53.2570570978,
                "lng": -132.7130120656
            },
            {
                "lat": 53.2560423557,
                "lng": -132.7148892638
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 151,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 68,
            "MANAGEMENT_AREA_NAME": "Subarea 2-68",
            "LABEL": "2-68",
            "FISHERY_AREA_ID": 1959,
            "OBJECTID": 151,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 125090706.0047,
            "FEATURE_LENGTH_M": 51309.32,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.23762395623824,
            "lng": -132.59903495564998
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.3687173713,
                "lng": -129.4351655132
            },
            {
                "lat": 53.3752782304,
                "lng": -129.4574125544
            },
            {
                "lat": 53.3758091793,
                "lng": -129.4595186832
            },
            {
                "lat": 53.3792574487,
                "lng": -129.4658205379
            },
            {
                "lat": 53.3827706131,
                "lng": -129.4710992601
            },
            {
                "lat": 53.3732675263,
                "lng": -129.4771879338
            },
            {
                "lat": 53.3658402486,
                "lng": -129.4817803901
            },
            {
                "lat": 53.3560103025,
                "lng": -129.4876864418
            },
            {
                "lat": 53.3435550868,
                "lng": -129.4924921973
            },
            {
                "lat": 53.334217471,
                "lng": -129.492141804
            },
            {
                "lat": 53.3170889784,
                "lng": -129.5053709082
            },
            {
                "lat": 53.3323778918,
                "lng": -129.524704195
            },
            {
                "lat": 53.3514144529,
                "lng": -129.5526733077
            },
            {
                "lat": 53.3472479143,
                "lng": -129.5604104095
            },
            {
                "lat": 53.3323781658,
                "lng": -129.5586243509
            },
            {
                "lat": 53.3062052873,
                "lng": -129.5544589922
            },
            {
                "lat": 53.2728235995,
                "lng": -129.539565905
            },
            {
                "lat": 53.248336634,
                "lng": -129.5352024094
            },
            {
                "lat": 53.2295378349,
                "lng": -129.5689846649
            },
            {
                "lat": 53.2218474247,
                "lng": -129.5789487906
            },
            {
                "lat": 53.2191549278,
                "lng": -129.5824428445
            },
            {
                "lat": 53.2144622256,
                "lng": -129.5841363287
            },
            {
                "lat": 53.2127078854,
                "lng": -129.5867615723
            },
            {
                "lat": 53.2115856278,
                "lng": -129.5868691332
            },
            {
                "lat": 53.1470916812,
                "lng": -129.5689080593
            },
            {
                "lat": 53.1481137322,
                "lng": -129.5676421754
            },
            {
                "lat": 53.1495246194,
                "lng": -129.5656279125
            },
            {
                "lat": 53.1499298792,
                "lng": -129.5640558149
            },
            {
                "lat": 53.1500288322,
                "lng": -129.5626519924
            },
            {
                "lat": 53.1496279531,
                "lng": -129.561584917
            },
            {
                "lat": 53.1498297582,
                "lng": -129.5609745873
            },
            {
                "lat": 53.1506351336,
                "lng": -129.561126763
            },
            {
                "lat": 53.1516454874,
                "lng": -129.5612333781
            },
            {
                "lat": 53.1532592607,
                "lng": -129.5597234447
            },
            {
                "lat": 53.1553800114,
                "lng": -129.5546717754
            },
            {
                "lat": 53.1542709011,
                "lng": -129.5503839142
            },
            {
                "lat": 53.1499601464,
                "lng": -129.5268861337
            },
            {
                "lat": 53.1449622973,
                "lng": -129.5007171701
            },
            {
                "lat": 53.1402017318,
                "lng": -129.4833522917
            },
            {
                "lat": 53.131873532,
                "lng": -129.4723968202
            },
            {
                "lat": 53.1209295091,
                "lng": -129.468597503
            },
            {
                "lat": 53.0931817706,
                "lng": -129.4339748768
            },
            {
                "lat": 53.0757557955,
                "lng": -129.4122314592
            },
            {
                "lat": 53.0324512468,
                "lng": -129.349899264
            },
            {
                "lat": 53.0334013275,
                "lng": -129.3372801608
            },
            {
                "lat": 53.0417285569,
                "lng": -129.3222967508
            },
            {
                "lat": 53.0463791232,
                "lng": -129.316817573
            },
            {
                "lat": 53.0793692153,
                "lng": -129.2772369747
            },
            {
                "lat": 53.0845799469,
                "lng": -129.2782436504
            },
            {
                "lat": 53.1047360623,
                "lng": -129.2772064867
            },
            {
                "lat": 53.1148678076,
                "lng": -129.2965396559
            },
            {
                "lat": 53.143825818,
                "lng": -129.312286042
            },
            {
                "lat": 53.1670647042,
                "lng": -129.3047324186
            },
            {
                "lat": 53.2051348584,
                "lng": -129.2845151637
            },
            {
                "lat": 53.2056124761,
                "lng": -129.3030700066
            },
            {
                "lat": 53.2065624636,
                "lng": -129.3287654917
            },
            {
                "lat": 53.2052572161,
                "lng": -129.3442987557
            },
            {
                "lat": 53.2045248267,
                "lng": -129.3452903241
            },
            {
                "lat": 53.232319345,
                "lng": -129.3680268632
            },
            {
                "lat": 53.2387998299,
                "lng": -129.3058163774
            },
            {
                "lat": 53.2386776152,
                "lng": -129.2997583919
            },
            {
                "lat": 53.2372586777,
                "lng": -129.2916574877
            },
            {
                "lat": 53.2374946595,
                "lng": -129.2718967501
            },
            {
                "lat": 53.2479665369,
                "lng": -129.2626200283
            },
            {
                "lat": 53.2660481961,
                "lng": -129.2469172681
            },
            {
                "lat": 53.2853240886,
                "lng": -129.2488252825
            },
            {
                "lat": 53.3022192701,
                "lng": -129.2649995486
            },
            {
                "lat": 53.3031345736,
                "lng": -129.270310315
            },
            {
                "lat": 53.3017840592,
                "lng": -129.2762149152
            },
            {
                "lat": 53.3022496977,
                "lng": -129.3358611704
            },
            {
                "lat": 53.3121110395,
                "lng": -129.3430169433
            },
            {
                "lat": 53.3112416736,
                "lng": -129.3507998538
            },
            {
                "lat": 53.3093185547,
                "lng": -129.3680271348
            },
            {
                "lat": 53.312744379,
                "lng": -129.3751376171
            },
            {
                "lat": 53.3276630298,
                "lng": -129.3780980249
            },
            {
                "lat": 53.3334616249,
                "lng": -129.3877252521
            },
            {
                "lat": 53.3546294899,
                "lng": -129.3974760348
            },
            {
                "lat": 53.3557740695,
                "lng": -129.4181669341
            },
            {
                "lat": 53.3687173713,
                "lng": -129.4351655132
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 152,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 6-5",
            "LABEL": "6-5",
            "FISHERY_AREA_ID": 2413,
            "OBJECTID": 152,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 488074652.0912,
            "FEATURE_LENGTH_M": 122826.0601,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.230285161922794,
            "lng": -129.4322936271139
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.3843492869,
                "lng": -129.3150782657
            },
            {
                "lat": 53.3709939316,
                "lng": -129.3090973982
            },
            {
                "lat": 53.3629081374,
                "lng": -129.3350517094
            },
            {
                "lat": 53.3617671191,
                "lng": -129.3463597907
            },
            {
                "lat": 53.3510592581,
                "lng": -129.3570552301
            },
            {
                "lat": 53.3396376453,
                "lng": -129.3568267733
            },
            {
                "lat": 53.3241731629,
                "lng": -129.3499302688
            },
            {
                "lat": 53.3121110395,
                "lng": -129.3430169433
            },
            {
                "lat": 53.3022496977,
                "lng": -129.3358611704
            },
            {
                "lat": 53.3017840592,
                "lng": -129.2762149152
            },
            {
                "lat": 53.3031345736,
                "lng": -129.270310315
            },
            {
                "lat": 53.3127713312,
                "lng": -129.2666780413
            },
            {
                "lat": 53.3125956675,
                "lng": -129.2539208879
            },
            {
                "lat": 53.3108364872,
                "lng": -129.2366935823
            },
            {
                "lat": 53.3108062445,
                "lng": -129.2364042466
            },
            {
                "lat": 53.314158817,
                "lng": -129.2221824812
            },
            {
                "lat": 53.3589476585,
                "lng": -129.2278438593
            },
            {
                "lat": 53.360369941,
                "lng": -129.2337952038
            },
            {
                "lat": 53.3665654467,
                "lng": -129.2389223211
            },
            {
                "lat": 53.3701325619,
                "lng": -129.2493905652
            },
            {
                "lat": 53.3695980754,
                "lng": -129.2580408373
            },
            {
                "lat": 53.3671119815,
                "lng": -129.2614283202
            },
            {
                "lat": 53.3670383865,
                "lng": -129.2616581993
            },
            {
                "lat": 53.3646357066,
                "lng": -129.2701258794
            },
            {
                "lat": 53.3655890043,
                "lng": -129.2759696214
            },
            {
                "lat": 53.3708651311,
                "lng": -129.2846839478
            },
            {
                "lat": 53.3871687994,
                "lng": -129.2958070483
            },
            {
                "lat": 53.3843492869,
                "lng": -129.3150782657
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 153,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 28,
            "MANAGEMENT_AREA_NAME": "Subarea 6-28",
            "LABEL": "6-28",
            "FISHERY_AREA_ID": 2435,
            "OBJECTID": 153,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 59191554.0374,
            "FEATURE_LENGTH_M": 32947.3678,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.34670387280359,
            "lng": -129.28512236029286
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.3373407725,
                "lng": -129.7147526035
            },
            {
                "lat": 53.3316308544,
                "lng": -129.7121431197
            },
            {
                "lat": 53.3269962557,
                "lng": -129.7154696153
            },
            {
                "lat": 53.3247563327,
                "lng": -129.7198636972
            },
            {
                "lat": 53.3073429395,
                "lng": -129.6857905538
            },
            {
                "lat": 53.3062088472,
                "lng": -129.6809079961
            },
            {
                "lat": 53.3041727883,
                "lng": -129.6720881836
            },
            {
                "lat": 53.3011168038,
                "lng": -129.648834393
            },
            {
                "lat": 53.3014568849,
                "lng": -129.6184380496
            },
            {
                "lat": 53.30773544,
                "lng": -129.5775296448
            },
            {
                "lat": 53.3163955599,
                "lng": -129.5700681345
            },
            {
                "lat": 53.3238636057,
                "lng": -129.5714259197
            },
            {
                "lat": 53.3279380093,
                "lng": -129.5765228917
            },
            {
                "lat": 53.3320122796,
                "lng": -129.5914612011
            },
            {
                "lat": 53.3423499158,
                "lng": -129.5986625881
            },
            {
                "lat": 53.3464316413,
                "lng": -129.5983576041
            },
            {
                "lat": 53.3577877964,
                "lng": -129.6002197222
            },
            {
                "lat": 53.3675424836,
                "lng": -129.619018394
            },
            {
                "lat": 53.3751561104,
                "lng": -129.6428072322
            },
            {
                "lat": 53.3737299774,
                "lng": -129.6532743518
            },
            {
                "lat": 53.3618310187,
                "lng": -129.6613769372
            },
            {
                "lat": 53.3518368598,
                "lng": -129.6658936969
            },
            {
                "lat": 53.3485072596,
                "lng": -129.6894532566
            },
            {
                "lat": 53.3442234827,
                "lng": -129.7091983712
            },
            {
                "lat": 53.3373407725,
                "lng": -129.7147526035
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 154,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 19,
            "MANAGEMENT_AREA_NAME": "Subarea 5-19",
            "LABEL": "5-19",
            "FISHERY_AREA_ID": 2022,
            "OBJECTID": 154,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 50860012.7442,
            "FEATURE_LENGTH_M": 29149.521,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.334228187668,
            "lng": -129.648332430456
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.3617671191,
                "lng": -129.3463597907
            },
            {
                "lat": 53.364551499,
                "lng": -129.3525234204
            },
            {
                "lat": 53.3699755005,
                "lng": -129.3645635977
            },
            {
                "lat": 53.3775901649,
                "lng": -129.4078673334
            },
            {
                "lat": 53.3709604644,
                "lng": -129.4151005507
            },
            {
                "lat": 53.3687173713,
                "lng": -129.4351655132
            },
            {
                "lat": 53.3557740695,
                "lng": -129.4181669341
            },
            {
                "lat": 53.3546294899,
                "lng": -129.3974760348
            },
            {
                "lat": 53.3334616249,
                "lng": -129.3877252521
            },
            {
                "lat": 53.3276630298,
                "lng": -129.3780980249
            },
            {
                "lat": 53.312744379,
                "lng": -129.3751376171
            },
            {
                "lat": 53.3093185547,
                "lng": -129.3680271348
            },
            {
                "lat": 53.3112416736,
                "lng": -129.3507998538
            },
            {
                "lat": 53.3121110395,
                "lng": -129.3430169433
            },
            {
                "lat": 53.3241731629,
                "lng": -129.3499302688
            },
            {
                "lat": 53.3396376453,
                "lng": -129.3568267733
            },
            {
                "lat": 53.3510592581,
                "lng": -129.3570552301
            },
            {
                "lat": 53.3617671191,
                "lng": -129.3463597907
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 155,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 155,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 20419630.6486,
            "FEATURE_LENGTH_M": 23171.318,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.344841286972226,
            "lng": -129.3750111146611
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.3744016723,
                "lng": -129.1030731123
            },
            {
                "lat": 53.3818393427,
                "lng": -129.1227264138
            },
            {
                "lat": 53.3818359294,
                "lng": -129.1653592577
            },
            {
                "lat": 53.3589476585,
                "lng": -129.2278438593
            },
            {
                "lat": 53.314158817,
                "lng": -129.2221824812
            },
            {
                "lat": 53.3108062445,
                "lng": -129.2364042466
            },
            {
                "lat": 53.3001134536,
                "lng": -129.219268921
            },
            {
                "lat": 53.1445044047,
                "lng": -129.1870574452
            },
            {
                "lat": 53.1098513449,
                "lng": -129.1666715515
            },
            {
                "lat": 53.09704218,
                "lng": -129.1736444643
            },
            {
                "lat": 53.0919149725,
                "lng": -129.1753537404
            },
            {
                "lat": 53.0913424593,
                "lng": -129.176040108
            },
            {
                "lat": 53.0771221142,
                "lng": -129.1983033139
            },
            {
                "lat": 53.0675202296,
                "lng": -129.1841733712
            },
            {
                "lat": 53.0670781981,
                "lng": -129.1837621892
            },
            {
                "lat": 53.0671150787,
                "lng": -129.1816251982
            },
            {
                "lat": 53.0670731167,
                "lng": -129.1661685979
            },
            {
                "lat": 53.0599326613,
                "lng": -129.1450043704
            },
            {
                "lat": 53.0499379947,
                "lng": -129.1285855383
            },
            {
                "lat": 53.0435144679,
                "lng": -129.1133575908
            },
            {
                "lat": 53.0544619095,
                "lng": -129.1047829156
            },
            {
                "lat": 53.0711177508,
                "lng": -129.083373799
            },
            {
                "lat": 53.0892027033,
                "lng": -129.0736086323
            },
            {
                "lat": 53.1208492106,
                "lng": -129.0695646136
            },
            {
                "lat": 53.141075208,
                "lng": -129.0721896748
            },
            {
                "lat": 53.1646307698,
                "lng": -129.0767058785
            },
            {
                "lat": 53.1808085181,
                "lng": -129.0731353359
            },
            {
                "lat": 53.1903302745,
                "lng": -129.0571900067
            },
            {
                "lat": 53.2022243809,
                "lng": -129.0507654426
            },
            {
                "lat": 53.2079357758,
                "lng": -129.0555274652
            },
            {
                "lat": 53.2109298572,
                "lng": -129.0556181662
            },
            {
                "lat": 53.2117344559,
                "lng": -129.0559232278
            },
            {
                "lat": 53.2121397718,
                "lng": -129.0559694367
            },
            {
                "lat": 53.2156216907,
                "lng": -129.0537108418
            },
            {
                "lat": 53.2158209063,
                "lng": -129.0535740952
            },
            {
                "lat": 53.2160220441,
                "lng": -129.0531013683
            },
            {
                "lat": 53.2180175412,
                "lng": -129.0456538049
            },
            {
                "lat": 53.2275206711,
                "lng": -129.0465549783
            },
            {
                "lat": 53.2527581281,
                "lng": -129.056945881
            },
            {
                "lat": 53.2797817208,
                "lng": -129.0658562724
            },
            {
                "lat": 53.2874601483,
                "lng": -129.095366761
            },
            {
                "lat": 53.3116945084,
                "lng": -129.1223903837
            },
            {
                "lat": 53.321800451,
                "lng": -129.1224369615
            },
            {
                "lat": 53.3301275811,
                "lng": -129.130523254
            },
            {
                "lat": 53.340122199,
                "lng": -129.1360015304
            },
            {
                "lat": 53.3584446711,
                "lng": -129.1291052796
            },
            {
                "lat": 53.3624073068,
                "lng": -129.1226352288
            },
            {
                "lat": 53.3744016723,
                "lng": -129.1030731123
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 156,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 6-6",
            "LABEL": "6-6",
            "FISHERY_AREA_ID": 2414,
            "OBJECTID": 156,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 283777191.525,
            "FEATURE_LENGTH_M": 91517.2285,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.204697795147915,
            "lng": -129.1187060441521
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.144779392,
                "lng": -132.4993135716
            },
            {
                "lat": 53.1449550996,
                "lng": -132.4986870774
            },
            {
                "lat": 53.1451030977,
                "lng": -132.4944152739
            },
            {
                "lat": 53.1450426295,
                "lng": -132.4904020007
            },
            {
                "lat": 53.1462017751,
                "lng": -132.4867857866
            },
            {
                "lat": 53.1488425551,
                "lng": -132.4830780469
            },
            {
                "lat": 53.1527249835,
                "lng": -132.476516948
            },
            {
                "lat": 53.1558192513,
                "lng": -132.4610445012
            },
            {
                "lat": 53.1577227371,
                "lng": -132.4427180642
            },
            {
                "lat": 53.1610529145,
                "lng": -132.4260711064
            },
            {
                "lat": 53.1705702266,
                "lng": -132.4227293502
            },
            {
                "lat": 53.1762811905,
                "lng": -132.4272616074
            },
            {
                "lat": 53.1810418128,
                "lng": -132.4429629183
            },
            {
                "lat": 53.1848483785,
                "lng": -132.4607997142
            },
            {
                "lat": 53.1886865497,
                "lng": -132.4719081603
            },
            {
                "lat": 53.2024572509,
                "lng": -132.4719846428
            },
            {
                "lat": 53.2217287338,
                "lng": -132.4669954639
            },
            {
                "lat": 53.2283936392,
                "lng": -132.4755554362
            },
            {
                "lat": 53.2279160227,
                "lng": -132.4905549553
            },
            {
                "lat": 53.206024121,
                "lng": -132.5031586835
            },
            {
                "lat": 53.1779487044,
                "lng": -132.5036315903
            },
            {
                "lat": 53.1655737275,
                "lng": -132.5086361699
            },
            {
                "lat": 53.1577224487,
                "lng": -132.5129088308
            },
            {
                "lat": 53.1527178364,
                "lng": -132.5136408072
            },
            {
                "lat": 53.144779392,
                "lng": -132.4993135716
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 157,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 65,
            "MANAGEMENT_AREA_NAME": "Subarea 2-65",
            "LABEL": "2-65",
            "FISHERY_AREA_ID": 1956,
            "OBJECTID": 157,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 28050263.7335,
            "FEATURE_LENGTH_M": 26028.8504,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.171557378804,
            "lng": -132.47724297115198
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.2605404104,
                "lng": -131.7705388815
            },
            {
                "lat": 53.2605404127,
                "lng": -131.8215030708
            },
            {
                "lat": 53.2550504299,
                "lng": -131.8187872254
            },
            {
                "lat": 53.2493399132,
                "lng": -131.8152165813
            },
            {
                "lat": 53.2284017113,
                "lng": -131.8116458506
            },
            {
                "lat": 53.2157890845,
                "lng": -131.804520328
            },
            {
                "lat": 53.1910438936,
                "lng": -131.8066564659
            },
            {
                "lat": 53.1720089032,
                "lng": -131.8130805175
            },
            {
                "lat": 53.1601098766,
                "lng": -131.8104551883
            },
            {
                "lat": 53.1513055084,
                "lng": -131.7976073237
            },
            {
                "lat": 53.1377441555,
                "lng": -131.7757263976
            },
            {
                "lat": 53.1341739937,
                "lng": -131.7485964728
            },
            {
                "lat": 53.1346511107,
                "lng": -131.7288517597
            },
            {
                "lat": 53.120849096,
                "lng": -131.7183831523
            },
            {
                "lat": 53.1070478184,
                "lng": -131.6974328027
            },
            {
                "lat": 53.1006244877,
                "lng": -131.6850587352
            },
            {
                "lat": 53.1001468019,
                "lng": -131.6638784132
            },
            {
                "lat": 53.1024086555,
                "lng": -131.6509238508
            },
            {
                "lat": 53.1088794552,
                "lng": -131.6457973695
            },
            {
                "lat": 53.2605404104,
                "lng": -131.7705388815
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 158,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 2-2",
            "LABEL": "2-2",
            "FISHERY_AREA_ID": 1893,
            "OBJECTID": 158,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 96478521.6444,
            "FEATURE_LENGTH_M": 48232.0719,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.17255980643999,
            "lng": -131.75775996341503
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.1503454561,
                "lng": -132.2880247308
            },
            {
                "lat": 53.1497724882,
                "lng": -132.2927711269
            },
            {
                "lat": 53.1489410971,
                "lng": -132.2941130203
            },
            {
                "lat": 53.137638212,
                "lng": -132.343765411
            },
            {
                "lat": 53.1338353025,
                "lng": -132.3442377136
            },
            {
                "lat": 53.1296160266,
                "lng": -132.3454443816
            },
            {
                "lat": 53.1278070846,
                "lng": -132.3526769367
            },
            {
                "lat": 53.1297643233,
                "lng": -132.3638150798
            },
            {
                "lat": 53.1293148817,
                "lng": -132.3715057753
            },
            {
                "lat": 53.1267546613,
                "lng": -132.3831021403
            },
            {
                "lat": 53.1254196377,
                "lng": -132.3857874696
            },
            {
                "lat": 53.1207382566,
                "lng": -132.379836755
            },
            {
                "lat": 53.1130828188,
                "lng": -132.3773801175
            },
            {
                "lat": 53.1014636181,
                "lng": -132.3824464529
            },
            {
                "lat": 53.0954552276,
                "lng": -132.3876954805
            },
            {
                "lat": 53.0919457445,
                "lng": -132.3907769487
            },
            {
                "lat": 53.0865859693,
                "lng": -132.4165343597
            },
            {
                "lat": 53.0939372192,
                "lng": -132.5577083898
            },
            {
                "lat": 53.094158561,
                "lng": -132.5660404809
            },
            {
                "lat": 53.085521585,
                "lng": -132.5576937869
            },
            {
                "lat": 53.0800477503,
                "lng": -132.5534056612
            },
            {
                "lat": 53.073387753,
                "lng": -132.5472254371
            },
            {
                "lat": 53.0731466013,
                "lng": -132.5365138616
            },
            {
                "lat": 53.0752911282,
                "lng": -132.5208135953
            },
            {
                "lat": 53.0764773976,
                "lng": -132.5081933673
            },
            {
                "lat": 53.0764774864,
                "lng": -132.5027312232
            },
            {
                "lat": 53.0731466853,
                "lng": -132.4965360249
            },
            {
                "lat": 53.0657695149,
                "lng": -132.495819195
            },
            {
                "lat": 53.0583958198,
                "lng": -132.5003508291
            },
            {
                "lat": 53.0572050378,
                "lng": -132.5136726476
            },
            {
                "lat": 53.0550649738,
                "lng": -132.5186620137
            },
            {
                "lat": 53.050541111,
                "lng": -132.5208130089
            },
            {
                "lat": 53.047446736,
                "lng": -132.5234221481
            },
            {
                "lat": 53.044357219,
                "lng": -132.5146172407
            },
            {
                "lat": 53.0418623137,
                "lng": -132.5099950953
            },
            {
                "lat": 53.0369915356,
                "lng": -132.47749288
            },
            {
                "lat": 53.0405505406,
                "lng": -132.4639429501
            },
            {
                "lat": 53.0455471527,
                "lng": -132.4503783071
            },
            {
                "lat": 53.04887764,
                "lng": -132.4422913452
            },
            {
                "lat": 53.0491141734,
                "lng": -132.4294437469
            },
            {
                "lat": 53.0441174483,
                "lng": -132.4184877151
            },
            {
                "lat": 53.0315059957,
                "lng": -132.4156339913
            },
            {
                "lat": 53.033172247,
                "lng": -132.4083861496
            },
            {
                "lat": 53.0353130927,
                "lng": -132.3972013908
            },
            {
                "lat": 53.0372163387,
                "lng": -132.3829197243
            },
            {
                "lat": 53.0398333322,
                "lng": -132.369354926
            },
            {
                "lat": 53.0398333406,
                "lng": -132.3615116105
            },
            {
                "lat": 53.0494723571,
                "lng": -132.34579449
            },
            {
                "lat": 53.0525669571,
                "lng": -132.334610805
            },
            {
                "lat": 53.0489041321,
                "lng": -132.3160248156
            },
            {
                "lat": 53.0535165346,
                "lng": -132.3020177295
            },
            {
                "lat": 53.0668415832,
                "lng": -132.2934574863
            },
            {
                "lat": 53.068267543,
                "lng": -132.2884520534
            },
            {
                "lat": 53.0651780004,
                "lng": -132.2708443207
            },
            {
                "lat": 53.0670813162,
                "lng": -132.258956984
            },
            {
                "lat": 53.0582767004,
                "lng": -132.2518161759
            },
            {
                "lat": 53.0535170449,
                "lng": -132.2541966008
            },
            {
                "lat": 53.0492327984,
                "lng": -132.2677613706
            },
            {
                "lat": 53.0447120239,
                "lng": -132.2863155416
            },
            {
                "lat": 53.0370975827,
                "lng": -132.309158964
            },
            {
                "lat": 53.0361478518,
                "lng": -132.3220057979
            },
            {
                "lat": 53.0311510299,
                "lng": -132.3324739143
            },
            {
                "lat": 53.0287706089,
                "lng": -132.3386689081
            },
            {
                "lat": 53.0271034173,
                "lng": -132.3467561224
            },
            {
                "lat": 53.0266304279,
                "lng": -132.3527071174
            },
            {
                "lat": 53.0263897916,
                "lng": -132.356277785
            },
            {
                "lat": 53.025101155,
                "lng": -132.3539121801
            },
            {
                "lat": 53.0233688211,
                "lng": -132.3527523675
            },
            {
                "lat": 53.0216106393,
                "lng": -132.3535920943
            },
            {
                "lat": 53.0230485609,
                "lng": -132.351120014
            },
            {
                "lat": 53.0234139475,
                "lng": -132.3473508347
            },
            {
                "lat": 53.0250810107,
                "lng": -132.3379518194
            },
            {
                "lat": 53.0281757785,
                "lng": -132.3258056141
            },
            {
                "lat": 53.0286515988,
                "lng": -132.3184352682
            },
            {
                "lat": 53.0255585788,
                "lng": -132.3065329229
            },
            {
                "lat": 53.0253223222,
                "lng": -132.2924958542
            },
            {
                "lat": 53.0250813352,
                "lng": -132.2765658133
            },
            {
                "lat": 53.0248447796,
                "lng": -132.2667994548
            },
            {
                "lat": 53.0218892042,
                "lng": -132.2513283361
            },
            {
                "lat": 53.0177689247,
                "lng": -132.2396705194
            },
            {
                "lat": 53.015400341,
                "lng": -132.2347408716
            },
            {
                "lat": 53.0127794901,
                "lng": -132.2243198805
            },
            {
                "lat": 53.0058131103,
                "lng": -132.2021026096
            },
            {
                "lat": 53.0007398954,
                "lng": -132.1884307181
            },
            {
                "lat": 52.9988934064,
                "lng": -132.1777034051
            },
            {
                "lat": 52.9984745973,
                "lng": -132.1673272264
            },
            {
                "lat": 52.9991917841,
                "lng": -132.1593935369
            },
            {
                "lat": 52.9999087707,
                "lng": -132.1540225005
            },
            {
                "lat": 53.0044594407,
                "lng": -132.1486359574
            },
            {
                "lat": 53.0035058531,
                "lng": -132.1414950665
            },
            {
                "lat": 53.0030287256,
                "lng": -132.1303092143
            },
            {
                "lat": 52.9970818237,
                "lng": -132.1193692939
            },
            {
                "lat": 52.9894671589,
                "lng": -132.0967715245
            },
            {
                "lat": 52.9730795108,
                "lng": -132.0692439727
            },
            {
                "lat": 52.9667244226,
                "lng": -132.0651088893
            },
            {
                "lat": 52.9621004289,
                "lng": -132.0646510736
            },
            {
                "lat": 52.9578747691,
                "lng": -132.0653983761
            },
            {
                "lat": 52.955203905,
                "lng": -132.0732110887
            },
            {
                "lat": 52.9592473224,
                "lng": -132.0863035016
            },
            {
                "lat": 52.9716219693,
                "lng": -132.112717346
            },
            {
                "lat": 52.9806627262,
                "lng": -132.1274566564
            },
            {
                "lat": 52.9865990362,
                "lng": -132.1481626943
            },
            {
                "lat": 52.9780877716,
                "lng": -132.1494147067
            },
            {
                "lat": 52.9744186504,
                "lng": -132.1444706974
            },
            {
                "lat": 52.9702529153,
                "lng": -132.1386413175
            },
            {
                "lat": 52.9662098855,
                "lng": -132.1299593452
            },
            {
                "lat": 52.9660878193,
                "lng": -132.1244804127
            },
            {
                "lat": 52.9670410344,
                "lng": -132.1174616011
            },
            {
                "lat": 52.9659689517,
                "lng": -132.1130672331
            },
            {
                "lat": 52.9645578584,
                "lng": -132.110106769
            },
            {
                "lat": 52.9612426323,
                "lng": -132.1077727345
            },
            {
                "lat": 52.9589038507,
                "lng": -132.1078796806
            },
            {
                "lat": 52.9575881746,
                "lng": -132.1093138493
            },
            {
                "lat": 52.9560970668,
                "lng": -132.1122282315
            },
            {
                "lat": 52.9533575913,
                "lng": -132.1167599507
            },
            {
                "lat": 52.9516942498,
                "lng": -132.1255649831
            },
            {
                "lat": 52.9514541468,
                "lng": -132.1335300053
            },
            {
                "lat": 52.9540715418,
                "lng": -132.1436461166
            },
            {
                "lat": 52.9599037812,
                "lng": -132.1522065223
            },
            {
                "lat": 52.9672776308,
                "lng": -132.1602940127
            },
            {
                "lat": 52.9706035937,
                "lng": -132.1675112475
            },
            {
                "lat": 52.9679913759,
                "lng": -132.1735078431
            },
            {
                "lat": 52.9663275485,
                "lng": -132.1796868095
            },
            {
                "lat": 52.9635088423,
                "lng": -132.168671014
            },
            {
                "lat": 52.9587513987,
                "lng": -132.1577152182
            },
            {
                "lat": 52.9516103371,
                "lng": -132.1486811121
            },
            {
                "lat": 52.9416194481,
                "lng": -132.1348723703
            },
            {
                "lat": 52.9361458673,
                "lng": -132.1275026071
            },
            {
                "lat": 52.9294822712,
                "lng": -132.1322632967
            },
            {
                "lat": 52.9282947087,
                "lng": -132.1453398927
            },
            {
                "lat": 52.9328149293,
                "lng": -132.1555785102
            },
            {
                "lat": 52.938762516,
                "lng": -132.1658022338
            },
            {
                "lat": 52.9459000475,
                "lng": -132.1777036571
            },
            {
                "lat": 52.9508965938,
                "lng": -132.188889075
            },
            {
                "lat": 52.9577978967,
                "lng": -132.2010190912
            },
            {
                "lat": 52.9627956392,
                "lng": -132.2086335597
            },
            {
                "lat": 52.965305142,
                "lng": -132.2101137414
            },
            {
                "lat": 52.9606928752,
                "lng": -132.2211145117
            },
            {
                "lat": 52.954047809,
                "lng": -132.2103117918
            },
            {
                "lat": 52.9504773982,
                "lng": -132.201019025
            },
            {
                "lat": 52.9454845969,
                "lng": -132.1948398869
            },
            {
                "lat": 52.9416767467,
                "lng": -132.1869808413
            },
            {
                "lat": 52.9388198989,
                "lng": -132.1786649142
            },
            {
                "lat": 52.9371526022,
                "lng": -132.1727136865
            },
            {
                "lat": 52.9335857452,
                "lng": -132.1641386006
            },
            {
                "lat": 52.9283526834,
                "lng": -132.1605678996
            },
            {
                "lat": 52.9216882367,
                "lng": -132.173660255
            },
            {
                "lat": 52.9236564523,
                "lng": -132.1860051721
            },
            {
                "lat": 52.9245296159,
                "lng": -132.1945652023
            },
            {
                "lat": 52.9252584348,
                "lng": -132.2017364843
            },
            {
                "lat": 52.9306684217,
                "lng": -132.2069850179
            },
            {
                "lat": 52.9333460276,
                "lng": -132.209594113
            },
            {
                "lat": 52.9409592631,
                "lng": -132.2191160227
            },
            {
                "lat": 52.9469070897,
                "lng": -132.2398382603
            },
            {
                "lat": 52.9491309251,
                "lng": -132.2443384896
            },
            {
                "lat": 52.9530794357,
                "lng": -132.2579197626
            },
            {
                "lat": 52.9496349307,
                "lng": -132.2722931202
            },
            {
                "lat": 52.9453508369,
                "lng": -132.2915647026
            },
            {
                "lat": 52.9455902176,
                "lng": -132.3110806286
            },
            {
                "lat": 52.9482643015,
                "lng": -132.3248749575
            },
            {
                "lat": 52.941364826,
                "lng": -132.3377234904
            },
            {
                "lat": 52.9324728425,
                "lng": -132.3571317602
            },
            {
                "lat": 52.9304729354,
                "lng": -132.3485565232
            },
            {
                "lat": 52.9312747711,
                "lng": -132.33917234
            },
            {
                "lat": 52.9261167723,
                "lng": -132.3328699089
            },
            {
                "lat": 52.9248964457,
                "lng": -132.3331761353
            },
            {
                "lat": 52.9206959968,
                "lng": -132.3320615606
            },
            {
                "lat": 52.9157181273,
                "lng": -132.3230898003
            },
            {
                "lat": 52.9151876993,
                "lng": -132.3099827188
            },
            {
                "lat": 52.9162982142,
                "lng": -132.2954101576
            },
            {
                "lat": 52.9218255999,
                "lng": -132.2740782989
            },
            {
                "lat": 52.9263607616,
                "lng": -132.263916354
            },
            {
                "lat": 52.9269908705,
                "lng": -132.2568359458
            },
            {
                "lat": 52.924362093,
                "lng": -132.2505495065
            },
            {
                "lat": 52.9205085768,
                "lng": -132.2487330958
            },
            {
                "lat": 52.9108849488,
                "lng": -132.2554014523
            },
            {
                "lat": 52.9004936399,
                "lng": -132.2691030445
            },
            {
                "lat": 52.8977506591,
                "lng": -132.2914893268
            },
            {
                "lat": 52.8994633144,
                "lng": -132.3051454066
            },
            {
                "lat": 52.8955228337,
                "lng": -132.3208464887
            },
            {
                "lat": 52.8942525776,
                "lng": -132.3137658339
            },
            {
                "lat": 52.8924944915,
                "lng": -132.3090963551
            },
            {
                "lat": 52.8891451015,
                "lng": -132.3053434535
            },
            {
                "lat": 52.8802102321,
                "lng": -132.2766724909
            },
            {
                "lat": 52.8749776372,
                "lng": -132.2519232444
            },
            {
                "lat": 52.8747402965,
                "lng": -132.2383730878
            },
            {
                "lat": 52.8767819196,
                "lng": -132.2332145906
            },
            {
                "lat": 52.8828319387,
                "lng": -132.2160026998
            },
            {
                "lat": 52.8854480066,
                "lng": -132.2007744146
            },
            {
                "lat": 52.8804515148,
                "lng": -132.1921997258
            },
            {
                "lat": 52.8696865088,
                "lng": -132.1918341473
            },
            {
                "lat": 52.860511806,
                "lng": -132.1887357342
            },
            {
                "lat": 52.8573684016,
                "lng": -132.1753074928
            },
            {
                "lat": 52.8549914687,
                "lng": -132.1603242038
            },
            {
                "lat": 52.8518983641,
                "lng": -132.1450956623
            },
            {
                "lat": 52.845233602,
                "lng": -132.1410522849
            },
            {
                "lat": 52.8352394008,
                "lng": -132.1434332041
            },
            {
                "lat": 52.8326224312,
                "lng": -132.1581728199
            },
            {
                "lat": 52.8366701911,
                "lng": -132.193160768
            },
            {
                "lat": 52.8428538273,
                "lng": -132.2178954462
            },
            {
                "lat": 52.8503302127,
                "lng": -132.2262117294
            },
            {
                "lat": 52.8402441368,
                "lng": -132.2633207228
            },
            {
                "lat": 52.8395920192,
                "lng": -132.2613981964
            },
            {
                "lat": 52.8374473471,
                "lng": -132.2574157775
            },
            {
                "lat": 52.8314976341,
                "lng": -132.2527618088
            },
            {
                "lat": 52.8250422566,
                "lng": -132.245911444
            },
            {
                "lat": 52.8216744169,
                "lng": -132.2377776627
            },
            {
                "lat": 52.817688314,
                "lng": -132.2333989467
            },
            {
                "lat": 52.814602016,
                "lng": -132.2350766276
            },
            {
                "lat": 52.8084684793,
                "lng": -132.2243805568
            },
            {
                "lat": 52.8044204489,
                "lng": -132.2055816899
            },
            {
                "lat": 52.8048981476,
                "lng": -132.1891637475
            },
            {
                "lat": 52.8029930932,
                "lng": -132.1765441823
            },
            {
                "lat": 52.7932398558,
                "lng": -132.1656029667
            },
            {
                "lat": 52.778724387,
                "lng": -132.1558532176
            },
            {
                "lat": 52.7625425037,
                "lng": -132.1408537987
            },
            {
                "lat": 52.7604030732,
                "lng": -132.1313326044
            },
            {
                "lat": 52.7618286744,
                "lng": -132.118728852
            },
            {
                "lat": 52.7625387469,
                "lng": -132.1167451472
            },
            {
                "lat": 52.7797089109,
                "lng": -132.1161798829
            },
            {
                "lat": 52.7925417422,
                "lng": -132.1071328192
            },
            {
                "lat": 52.7987443215,
                "lng": -132.0785378025
            },
            {
                "lat": 52.8159213994,
                "lng": -132.0719145047
            },
            {
                "lat": 52.8279645245,
                "lng": -132.0888211798
            },
            {
                "lat": 52.8375276836,
                "lng": -132.0979311473
            },
            {
                "lat": 52.8476411748,
                "lng": -132.0996707563
            },
            {
                "lat": 52.8554537417,
                "lng": -132.0947110465
            },
            {
                "lat": 52.8625069442,
                "lng": -132.0869753175
            },
            {
                "lat": 52.8628154129,
                "lng": -132.0863957879
            },
            {
                "lat": 52.8640938852,
                "lng": -132.0839541967
            },
            {
                "lat": 52.8640938696,
                "lng": -132.0775137955
            },
            {
                "lat": 52.8576586393,
                "lng": -132.0694278771
            },
            {
                "lat": 52.8488353702,
                "lng": -132.0610659238
            },
            {
                "lat": 52.8379855509,
                "lng": -132.0525060988
            },
            {
                "lat": 52.8266794488,
                "lng": -132.0451508308
            },
            {
                "lat": 52.8208854606,
                "lng": -132.0424041437
            },
            {
                "lat": 52.8142660855,
                "lng": -132.0342099366
            },
            {
                "lat": 52.8080143328,
                "lng": -132.025299299
            },
            {
                "lat": 52.8043367042,
                "lng": -132.0143589466
            },
            {
                "lat": 52.8056257384,
                "lng": -132.0047918599
            },
            {
                "lat": 52.8059920955,
                "lng": -131.9966122095
            },
            {
                "lat": 52.8045231873,
                "lng": -131.982268697
            },
            {
                "lat": 52.801582527,
                "lng": -131.9664609003
            },
            {
                "lat": 52.7963414821,
                "lng": -131.9614106682
            },
            {
                "lat": 52.7838358314,
                "lng": -131.9513846284
            },
            {
                "lat": 52.7717927652,
                "lng": -131.942367981
            },
            {
                "lat": 52.7658194453,
                "lng": -131.9365844496
            },
            {
                "lat": 52.7599336462,
                "lng": -131.9325412305
            },
            {
                "lat": 52.754970968,
                "lng": -131.9314273132
            },
            {
                "lat": 52.7520292878,
                "lng": -131.9339147401
            },
            {
                "lat": 52.7505457203,
                "lng": -131.9367211404
            },
            {
                "lat": 52.7483515131,
                "lng": -131.9393464843
            },
            {
                "lat": 52.7455935991,
                "lng": -131.9405367125
            },
            {
                "lat": 52.7413627937,
                "lng": -131.9358520187
            },
            {
                "lat": 52.735798415,
                "lng": -131.9276119531
            },
            {
                "lat": 52.7302398318,
                "lng": -131.9162603325
            },
            {
                "lat": 52.7230686107,
                "lng": -131.9102943452
            },
            {
                "lat": 52.714885499,
                "lng": -131.9098349951
            },
            {
                "lat": 52.7089915537,
                "lng": -131.9110868412
            },
            {
                "lat": 52.7072873661,
                "lng": -131.9124608476
            },
            {
                "lat": 52.706245321,
                "lng": -131.9191132598
            },
            {
                "lat": 52.7168155565,
                "lng": -131.9363088791
            },
            {
                "lat": 52.7265621289,
                "lng": -131.9495386715
            },
            {
                "lat": 52.7389716882,
                "lng": -131.9645235229
            },
            {
                "lat": 52.7512014043,
                "lng": -131.9781337025
            },
            {
                "lat": 52.759567143,
                "lng": -131.9890745065
            },
            {
                "lat": 52.7692025012,
                "lng": -132.0019533756
            },
            {
                "lat": 52.7749289258,
                "lng": -132.0115812275
            },
            {
                "lat": 52.7696918327,
                "lng": -132.0157463156
            },
            {
                "lat": 52.7638893891,
                "lng": -132.0061493133
            },
            {
                "lat": 52.7586483457,
                "lng": -131.9983670466
            },
            {
                "lat": 52.7473410285,
                "lng": -131.9827271628
            },
            {
                "lat": 52.7375029738,
                "lng": -131.9743659211
            },
            {
                "lat": 52.7237134518,
                "lng": -131.9691316456
            },
            {
                "lat": 52.7095560447,
                "lng": -131.9742737766
            },
            {
                "lat": 52.7059904385,
                "lng": -131.9812017441
            },
            {
                "lat": 52.709461684,
                "lng": -131.9932092659
            },
            {
                "lat": 52.7158960975,
                "lng": -132.0025023516
            },
            {
                "lat": 52.7215989926,
                "lng": -132.0047921395
            },
            {
                "lat": 52.7265202266,
                "lng": -132.0032502455
            },
            {
                "lat": 52.7307018491,
                "lng": -132.002136307
            },
            {
                "lat": 52.7432933894,
                "lng": -132.0163880742
            },
            {
                "lat": 52.7474630471,
                "lng": -132.0257419947
            },
            {
                "lat": 52.750740513,
                "lng": -132.0336605556
            },
            {
                "lat": 52.7564352781,
                "lng": -132.0441132124
            },
            {
                "lat": 52.7373317058,
                "lng": -132.0853414767
            },
            {
                "lat": 52.721580863,
                "lng": -132.065810895
            },
            {
                "lat": 52.7070658289,
                "lng": -132.0465399317
            },
            {
                "lat": 52.6958839889,
                "lng": -132.0198828958
            },
            {
                "lat": 52.6870811326,
                "lng": -131.9929970627
            },
            {
                "lat": 52.6782766748,
                "lng": -131.9753880644
            },
            {
                "lat": 52.6763730787,
                "lng": -131.9492184651
            },
            {
                "lat": 52.67256494,
                "lng": -131.9216152601
            },
            {
                "lat": 52.6701853217,
                "lng": -131.8956756106
            },
            {
                "lat": 52.6590002614,
                "lng": -131.8716426979
            },
            {
                "lat": 52.6430583495,
                "lng": -131.8685449568
            },
            {
                "lat": 52.6366349274,
                "lng": -131.8854524681
            },
            {
                "lat": 52.6340177466,
                "lng": -131.9128112466
            },
            {
                "lat": 52.627354268,
                "lng": -131.9087674754
            },
            {
                "lat": 52.625450465,
                "lng": -131.8771211115
            },
            {
                "lat": 52.6233096783,
                "lng": -131.8599851814
            },
            {
                "lat": 52.6123656844,
                "lng": -131.8504639029
            },
            {
                "lat": 52.6009441468,
                "lng": -131.8626106169
            },
            {
                "lat": 52.5933305947,
                "lng": -131.8714139893
            },
            {
                "lat": 52.5795282397,
                "lng": -131.8521272321
            },
            {
                "lat": 52.5828589387,
                "lng": -131.823348998
            },
            {
                "lat": 52.5940441678,
                "lng": -131.7926483104
            },
            {
                "lat": 52.5878565031,
                "lng": -131.7764734328
            },
            {
                "lat": 52.5733406214,
                "lng": -131.7731322767
            },
            {
                "lat": 52.5602533312,
                "lng": -131.8078762602
            },
            {
                "lat": 52.5455014907,
                "lng": -131.7902674891
            },
            {
                "lat": 52.5438343159,
                "lng": -131.7707515555
            },
            {
                "lat": 52.544551183,
                "lng": -131.7483827501
            },
            {
                "lat": 52.5331260806,
                "lng": -131.7336433221
            },
            {
                "lat": 52.5186080674,
                "lng": -131.7527767446
            },
            {
                "lat": 52.508380965,
                "lng": -131.7236480467
            },
            {
                "lat": 52.4981493695,
                "lng": -131.699843873
            },
            {
                "lat": 52.4941063357,
                "lng": -131.6667789324
            },
            {
                "lat": 52.4900586693,
                "lng": -131.6501158258
            },
            {
                "lat": 52.4745941035,
                "lng": -131.624176219
            },
            {
                "lat": 52.4562721959,
                "lng": -131.6075288538
            },
            {
                "lat": 52.4369961385,
                "lng": -131.5956268902
            },
            {
                "lat": 52.4232642881,
                "lng": -131.5929710142
            },
            {
                "lat": 52.4249040916,
                "lng": -131.5904078962
            },
            {
                "lat": 52.4311102769,
                "lng": -131.5873868194
            },
            {
                "lat": 52.4384457571,
                "lng": -131.576858029
            },
            {
                "lat": 52.4437102806,
                "lng": -131.5646355784
            },
            {
                "lat": 52.4440882394,
                "lng": -131.5561680272
            },
            {
                "lat": 52.4427714809,
                "lng": -131.5458222806
            },
            {
                "lat": 52.4405132055,
                "lng": -131.536987632
            },
            {
                "lat": 52.4344945559,
                "lng": -131.5375519859
            },
            {
                "lat": 52.4281009255,
                "lng": -131.5462041978
            },
            {
                "lat": 52.4224849475,
                "lng": -131.5440678828
            },
            {
                "lat": 52.4217026597,
                "lng": -131.5382690643
            },
            {
                "lat": 52.4201311439,
                "lng": -131.5294194647
            },
            {
                "lat": 52.418949513,
                "lng": -131.5201718598
            },
            {
                "lat": 52.4181638792,
                "lng": -131.5145731282
            },
            {
                "lat": 52.4150157959,
                "lng": -131.5075991786
            },
            {
                "lat": 52.410988045,
                "lng": -131.5016937084
            },
            {
                "lat": 52.4063642586,
                "lng": -131.4992377321
            },
            {
                "lat": 52.4002721594,
                "lng": -131.4987480792
            },
            {
                "lat": 52.3953560411,
                "lng": -131.5000146246
            },
            {
                "lat": 52.3907351648,
                "lng": -131.5030673238
            },
            {
                "lat": 52.3894579286,
                "lng": -131.5074006871
            },
            {
                "lat": 52.3904384695,
                "lng": -131.5139765001
            },
            {
                "lat": 52.3931929874,
                "lng": -131.5262758856
            },
            {
                "lat": 52.3991890828,
                "lng": -131.5370789999
            },
            {
                "lat": 52.3982045683,
                "lng": -131.5424958074
            },
            {
                "lat": 52.3983033332,
                "lng": -131.5532070556
            },
            {
                "lat": 52.4004661735,
                "lng": -131.566284419
            },
            {
                "lat": 52.3924522405,
                "lng": -131.5647283285
            },
            {
                "lat": 52.3872867758,
                "lng": -131.5654151536
            },
            {
                "lat": 52.3834380741,
                "lng": -131.5774839105
            },
            {
                "lat": 52.3742754542,
                "lng": -131.578674293
            },
            {
                "lat": 52.3633311422,
                "lng": -131.5729679856
            },
            {
                "lat": 52.3569072936,
                "lng": -131.5651249022
            },
            {
                "lat": 52.3547630328,
                "lng": -131.5551291137
            },
            {
                "lat": 52.3549608835,
                "lng": -131.5504142055
            },
            {
                "lat": 52.355479846,
                "lng": -131.5379942294
            },
            {
                "lat": 52.3538129167,
                "lng": -131.5299066671
            },
            {
                "lat": 52.3485787314,
                "lng": -131.5263363232
            },
            {
                "lat": 52.3414377893,
                "lng": -131.5289453326
            },
            {
                "lat": 52.3356710869,
                "lng": -131.532089322
            },
            {
                "lat": 52.3331107408,
                "lng": -131.5334776027
            },
            {
                "lat": 52.3269234597,
                "lng": -131.5270384783
            },
            {
                "lat": 52.3275300721,
                "lng": -131.5243527867
            },
            {
                "lat": 52.3290680028,
                "lng": -131.517531787
            },
            {
                "lat": 52.3278767618,
                "lng": -131.5089571429
            },
            {
                "lat": 52.3209754848,
                "lng": -131.5037230283
            },
            {
                "lat": 52.3109011025,
                "lng": -131.4993129119
            },
            {
                "lat": 52.2993972243,
                "lng": -131.4932255031
            },
            {
                "lat": 52.2836191585,
                "lng": -131.4627988678
            },
            {
                "lat": 52.2738612273,
                "lng": -131.4485165223
            },
            {
                "lat": 52.263156731,
                "lng": -131.4356691575
            },
            {
                "lat": 52.2519717579,
                "lng": -131.4268646031
            },
            {
                "lat": 52.2412638155,
                "lng": -131.4202118459
            },
            {
                "lat": 52.2298435697,
                "lng": -131.4171144604
            },
            {
                "lat": 52.2217526968,
                "lng": -131.4175874202
            },
            {
                "lat": 52.220561931,
                "lng": -131.4098511554
            },
            {
                "lat": 52.2134244783,
                "lng": -131.3979639695
            },
            {
                "lat": 52.2046093985,
                "lng": -131.3913428262
            },
            {
                "lat": 52.1960562616,
                "lng": -131.3658446954
            },
            {
                "lat": 52.2045022741,
                "lng": -131.3669130806
            },
            {
                "lat": 52.2163996067,
                "lng": -131.3673849608
            },
            {
                "lat": 52.2234345605,
                "lng": -131.3671566794
            },
            {
                "lat": 52.2312309719,
                "lng": -131.3688209646
            },
            {
                "lat": 52.2363698745,
                "lng": -131.3726038102
            },
            {
                "lat": 52.2400742437,
                "lng": -131.3757171377
            },
            {
                "lat": 52.2495919925,
                "lng": -131.3818964453
            },
            {
                "lat": 52.261966842,
                "lng": -131.3842769881
            },
            {
                "lat": 52.2748146986,
                "lng": -131.3854673793
            },
            {
                "lat": 52.2776713531,
                "lng": -131.3792872005
            },
            {
                "lat": 52.2776712544,
                "lng": -131.3742828389
            },
            {
                "lat": 52.2736247753,
                "lng": -131.3669127765
            },
            {
                "lat": 52.2643465826,
                "lng": -131.3592994967
            },
            {
                "lat": 52.2488782909,
                "lng": -131.3478694359
            },
            {
                "lat": 52.2360307577,
                "lng": -131.3445434609
            },
            {
                "lat": 52.2338867372,
                "lng": -131.3476410337
            },
            {
                "lat": 52.234363991,
                "lng": -131.3526312741
            },
            {
                "lat": 52.2358630464,
                "lng": -131.3582760656
            },
            {
                "lat": 52.2307098433,
                "lng": -131.3560789408
            },
            {
                "lat": 52.2290423654,
                "lng": -131.3531339546
            },
            {
                "lat": 52.2258491656,
                "lng": -131.3477475443
            },
            {
                "lat": 52.2226561653,
                "lng": -131.3431542085
            },
            {
                "lat": 52.2200621454,
                "lng": -131.3403632667
            },
            {
                "lat": 52.2176700447,
                "lng": -131.3396606365
            },
            {
                "lat": 52.2154768187,
                "lng": -131.3395688898
            },
            {
                "lat": 52.2111892043,
                "lng": -131.339569472
            },
            {
                "lat": 52.2071997491,
                "lng": -131.3378750826
            },
            {
                "lat": 52.2022097857,
                "lng": -131.3374786266
            },
            {
                "lat": 52.1994174207,
                "lng": -131.3379671022
            },
            {
                "lat": 52.2008666532,
                "lng": -131.3350365872
            },
            {
                "lat": 52.2011496191,
                "lng": -131.3302002417
            },
            {
                "lat": 52.1984137105,
                "lng": -131.321472316
            },
            {
                "lat": 52.1931728817,
                "lng": -131.3171382627
            },
            {
                "lat": 52.1883847156,
                "lng": -131.3149720376
            },
            {
                "lat": 52.1824684937,
                "lng": -131.311188585
            },
            {
                "lat": 52.1736635997,
                "lng": -131.300475505
            },
            {
                "lat": 52.166526561,
                "lng": -131.2947690763
            },
            {
                "lat": 52.158912748,
                "lng": -131.2907260008
            },
            {
                "lat": 52.1532024822,
                "lng": -131.2921448868
            },
            {
                "lat": 52.1509707313,
                "lng": -131.281341262
            },
            {
                "lat": 52.1543269849,
                "lng": -131.2450716238
            },
            {
                "lat": 52.1580465648,
                "lng": -131.2391349974
            },
            {
                "lat": 52.15809667,
                "lng": -131.2354441154
            },
            {
                "lat": 52.1595042047,
                "lng": -131.2349243083
            },
            {
                "lat": 52.1598173551,
                "lng": -131.2364340369
            },
            {
                "lat": 52.1616856236,
                "lng": -131.2380980288
            },
            {
                "lat": 52.1692235927,
                "lng": -131.2443236528
            },
            {
                "lat": 52.1744811671,
                "lng": -131.2465975339
            },
            {
                "lat": 52.1758651977,
                "lng": -131.2464596598
            },
            {
                "lat": 52.1796987171,
                "lng": -131.2499852907
            },
            {
                "lat": 52.1918336382,
                "lng": -131.2602235017
            },
            {
                "lat": 52.1961177918,
                "lng": -131.2628330357
            },
            {
                "lat": 52.2012869678,
                "lng": -131.2709802625
            },
            {
                "lat": 52.2078396176,
                "lng": -131.2773435013
            },
            {
                "lat": 52.2172426939,
                "lng": -131.2847591403
            },
            {
                "lat": 52.2264558433,
                "lng": -131.2870327756
            },
            {
                "lat": 52.2372858841,
                "lng": -131.2921603106
            },
            {
                "lat": 52.2428891937,
                "lng": -131.2972867048
            },
            {
                "lat": 52.2452659075,
                "lng": -131.2963405323
            },
            {
                "lat": 52.2452658951,
                "lng": -131.2935186205
            },
            {
                "lat": 52.2452659921,
                "lng": -131.2915647367
            },
            {
                "lat": 52.2392695661,
                "lng": -131.2847294718
            },
            {
                "lat": 52.2361448187,
                "lng": -131.2791450654
            },
            {
                "lat": 52.2340541439,
                "lng": -131.2755427216
            },
            {
                "lat": 52.2288318883,
                "lng": -131.2719272627
            },
            {
                "lat": 52.2278478726,
                "lng": -131.2717125477
            },
            {
                "lat": 52.2175792448,
                "lng": -131.2600099275
            },
            {
                "lat": 52.2163881442,
                "lng": -131.2568365048
            },
            {
                "lat": 52.2142030156,
                "lng": -131.2516019909
            },
            {
                "lat": 52.211162114,
                "lng": -131.2459104746
            },
            {
                "lat": 52.2081228949,
                "lng": -131.242294313
            },
            {
                "lat": 52.2029001022,
                "lng": -131.2383121764
            },
            {
                "lat": 52.1994778612,
                "lng": -131.2360230657
            },
            {
                "lat": 52.1974867135,
                "lng": -131.2347873634
            },
            {
                "lat": 52.1968452474,
                "lng": -131.2344672635
            },
            {
                "lat": 52.1956555396,
                "lng": -131.2320558038
            },
            {
                "lat": 52.1930770089,
                "lng": -131.2284241881
            },
            {
                "lat": 52.1897163128,
                "lng": -131.2249455434
            },
            {
                "lat": 52.1873586351,
                "lng": -131.2212983715
            },
            {
                "lat": 52.1851774194,
                "lng": -131.2181099266
            },
            {
                "lat": 52.1839977934,
                "lng": -131.2165381397
            },
            {
                "lat": 52.1808048074,
                "lng": -131.2143547277
            },
            {
                "lat": 52.17912271,
                "lng": -131.2148588763
            },
            {
                "lat": 52.1769942626,
                "lng": -131.2186588262
            },
            {
                "lat": 52.1763574389,
                "lng": -131.219863482
            },
            {
                "lat": 52.1757357029,
                "lng": -131.2191312371
            },
            {
                "lat": 52.1758955928,
                "lng": -131.2173605379
            },
            {
                "lat": 52.1751561589,
                "lng": -131.2149664857
            },
            {
                "lat": 52.1721840204,
                "lng": -131.2116390444
            },
            {
                "lat": 52.169044243,
                "lng": -131.208785681
            },
            {
                "lat": 52.1664738304,
                "lng": -131.2066794195
            },
            {
                "lat": 52.1641315438,
                "lng": -131.2059323889
            },
            {
                "lat": 52.1621361413,
                "lng": -131.2074129821
            },
            {
                "lat": 52.1588206893,
                "lng": -131.2110138344
            },
            {
                "lat": 52.1561966307,
                "lng": -131.2170105105
            },
            {
                "lat": 52.1565136507,
                "lng": -131.2098846724
            },
            {
                "lat": 52.1557996461,
                "lng": -131.2058415538
            },
            {
                "lat": 52.1544076723,
                "lng": -131.2046502754
            },
            {
                "lat": 52.1510428947,
                "lng": -131.2017972542
            },
            {
                "lat": 52.1419988775,
                "lng": -131.1927496009
            },
            {
                "lat": 52.1348615741,
                "lng": -131.1796725321
            },
            {
                "lat": 52.1360512569,
                "lng": -131.1682433933
            },
            {
                "lat": 52.1419985591,
                "lng": -131.160629783
            },
            {
                "lat": 52.1496121617,
                "lng": -131.1511081281
            },
            {
                "lat": 52.1511768277,
                "lng": -131.1474606501
            },
            {
                "lat": 52.1621059305,
                "lng": -131.1488795499
            },
            {
                "lat": 52.1764600668,
                "lng": -131.1491546858
            },
            {
                "lat": 52.190147022,
                "lng": -131.1594845687
            },
            {
                "lat": 52.1973916227,
                "lng": -131.1678002428
            },
            {
                "lat": 52.2053066074,
                "lng": -131.167937853
            },
            {
                "lat": 52.2082602308,
                "lng": -131.160949615
            },
            {
                "lat": 52.2102742383,
                "lng": -131.1444548324
            },
            {
                "lat": 52.2070540026,
                "lng": -131.1232451799
            },
            {
                "lat": 52.1996734086,
                "lng": -131.1126556634
            },
            {
                "lat": 52.1859898555,
                "lng": -131.1063381943
            },
            {
                "lat": 52.1772656034,
                "lng": -131.1020502959
            },
            {
                "lat": 52.1731040306,
                "lng": -131.0905767815
            },
            {
                "lat": 52.180546238,
                "lng": -131.0560451502
            },
            {
                "lat": 52.188637025,
                "lng": -131.0377355336
            },
            {
                "lat": 52.2074964759,
                "lng": -131.0157925291
            },
            {
                "lat": 52.2142064284,
                "lng": -131.0145105785
            },
            {
                "lat": 52.2168352097,
                "lng": -131.0231023614
            },
            {
                "lat": 52.213264534,
                "lng": -131.0478524269
            },
            {
                "lat": 52.208744213,
                "lng": -131.0668792896
            },
            {
                "lat": 52.2087444917,
                "lng": -131.0878138571
            },
            {
                "lat": 52.2089808982,
                "lng": -131.1006624373
            },
            {
                "lat": 52.2094579434,
                "lng": -131.1087651592
            },
            {
                "lat": 52.2092171524,
                "lng": -131.1175685334
            },
            {
                "lat": 52.2108840283,
                "lng": -131.1246947545
            },
            {
                "lat": 52.218498926,
                "lng": -131.1318367461
            },
            {
                "lat": 52.2261124029,
                "lng": -131.1427919559
            },
            {
                "lat": 52.2223054486,
                "lng": -131.1577758587
            },
            {
                "lat": 52.2182614502,
                "lng": -131.1765750796
            },
            {
                "lat": 52.2156444781,
                "lng": -131.1815647185
            },
            {
                "lat": 52.2168350886,
                "lng": -131.1949011043
            },
            {
                "lat": 52.2249225031,
                "lng": -131.1998908534
            },
            {
                "lat": 52.2349169929,
                "lng": -131.1925204621
            },
            {
                "lat": 52.241580272,
                "lng": -131.1825258919
            },
            {
                "lat": 52.2499085602,
                "lng": -131.1525419715
            },
            {
                "lat": 52.2549051108,
                "lng": -131.1246938575
            },
            {
                "lat": 52.2575221871,
                "lng": -131.1056675715
            },
            {
                "lat": 52.2641825625,
                "lng": -131.0966188243
            },
            {
                "lat": 52.2751314739,
                "lng": -131.0949556288
            },
            {
                "lat": 52.2846495468,
                "lng": -131.096863108
            },
            {
                "lat": 52.2797086185,
                "lng": -131.1085207866
            },
            {
                "lat": 52.2684096663,
                "lng": -131.1376656625
            },
            {
                "lat": 52.267814441,
                "lng": -131.1573029842
            },
            {
                "lat": 52.2761419908,
                "lng": -131.1691890021
            },
            {
                "lat": 52.2927972784,
                "lng": -131.1757354299
            },
            {
                "lat": 52.3023142254,
                "lng": -131.1769250492
            },
            {
                "lat": 52.3159976563,
                "lng": -131.1787112226
            },
            {
                "lat": 52.3055881275,
                "lng": -131.1909032402
            },
            {
                "lat": 52.2913096789,
                "lng": -131.2075654875
            },
            {
                "lat": 52.2829825207,
                "lng": -131.2117313966
            },
            {
                "lat": 52.2669219546,
                "lng": -131.2063745764
            },
            {
                "lat": 52.2520486786,
                "lng": -131.2123261918
            },
            {
                "lat": 52.2437216035,
                "lng": -131.2283934953
            },
            {
                "lat": 52.2371790439,
                "lng": -131.2408758501
            },
            {
                "lat": 52.2377734713,
                "lng": -131.2575382407
            },
            {
                "lat": 52.2514535645,
                "lng": -131.2783511967
            },
            {
                "lat": 52.2716782461,
                "lng": -131.3003694169
            },
            {
                "lat": 52.2889290541,
                "lng": -131.3075101712
            },
            {
                "lat": 52.2961802069,
                "lng": -131.3083800988
            },
            {
                "lat": 52.2933923866,
                "lng": -131.3135068732
            },
            {
                "lat": 52.2841107321,
                "lng": -131.3149410177
            },
            {
                "lat": 52.2736435806,
                "lng": -131.3149413275
            },
            {
                "lat": 52.2672198523,
                "lng": -131.3170778817
            },
            {
                "lat": 52.2665063449,
                "lng": -131.3273166016
            },
            {
                "lat": 52.2753108776,
                "lng": -131.3351590524
            },
            {
                "lat": 52.2891078774,
                "lng": -131.3451536958
            },
            {
                "lat": 52.3024331084,
                "lng": -131.3580021905
            },
            {
                "lat": 52.3179011418,
                "lng": -131.3620454935
            },
            {
                "lat": 52.3295587614,
                "lng": -131.3665774428
            },
            {
                "lat": 52.3414578019,
                "lng": -131.3737178725
            },
            {
                "lat": 52.3471681432,
                "lng": -131.3768007158
            },
            {
                "lat": 52.3425287129,
                "lng": -131.394531285
            },
            {
                "lat": 52.3430063713,
                "lng": -131.4149932953
            },
            {
                "lat": 52.3520471242,
                "lng": -131.4214175167
            },
            {
                "lat": 52.3694149041,
                "lng": -131.424041068
            },
            {
                "lat": 52.3710818612,
                "lng": -131.4349820012
            },
            {
                "lat": 52.3767931138,
                "lng": -131.445694717
            },
            {
                "lat": 52.3865466079,
                "lng": -131.4556882623
            },
            {
                "lat": 52.3958284963,
                "lng": -131.4568787417
            },
            {
                "lat": 52.4091531678,
                "lng": -131.450454388
            },
            {
                "lat": 52.4269988409,
                "lng": -131.4354557137
            },
            {
                "lat": 52.4373935208,
                "lng": -131.4248806169
            },
            {
                "lat": 52.439422477,
                "lng": -131.4253075208
            },
            {
                "lat": 52.4465096694,
                "lng": -131.4267729891
            },
            {
                "lat": 52.4569812218,
                "lng": -131.4329526015
            },
            {
                "lat": 52.4570969193,
                "lng": -131.4375916666
            },
            {
                "lat": 52.4572178747,
                "lng": -131.4424745068
            },
            {
                "lat": 52.4481768489,
                "lng": -131.4581755905
            },
            {
                "lat": 52.436279361,
                "lng": -131.4717414143
            },
            {
                "lat": 52.4374691773,
                "lng": -131.4833993202
            },
            {
                "lat": 52.4510301239,
                "lng": -131.4912569872
            },
            {
                "lat": 52.4579313132,
                "lng": -131.5003044187
            },
            {
                "lat": 52.4595982365,
                "lng": -131.5138541594
            },
            {
                "lat": 52.4638827443,
                "lng": -131.520996667
            },
            {
                "lat": 52.4729233903,
                "lng": -131.5209964494
            },
            {
                "lat": 52.4774434308,
                "lng": -131.5145731067
            },
            {
                "lat": 52.4826775214,
                "lng": -131.5007790044
            },
            {
                "lat": 52.4869619981,
                "lng": -131.4895933386
            },
            {
                "lat": 52.4910049386,
                "lng": -131.48388639
            },
            {
                "lat": 52.4979059712,
                "lng": -131.4815061035
            },
            {
                "lat": 52.4988593028,
                "lng": -131.4774630736
            },
            {
                "lat": 52.5012775102,
                "lng": -131.4798735535
            },
            {
                "lat": 52.5055239784,
                "lng": -131.501251274
            },
            {
                "lat": 52.5086138093,
                "lng": -131.5105286076
            },
            {
                "lat": 52.5086138726,
                "lng": -131.5231474572
            },
            {
                "lat": 52.5033800494,
                "lng": -131.5350340964
            },
            {
                "lat": 52.4938618206,
                "lng": -131.5514531842
            },
            {
                "lat": 52.4914821448,
                "lng": -131.5600283665
            },
            {
                "lat": 52.4831537823,
                "lng": -131.5840605667
            },
            {
                "lat": 52.488628818,
                "lng": -131.5966642003
            },
            {
                "lat": 52.497905838,
                "lng": -131.6135558204
            },
            {
                "lat": 52.5074228999,
                "lng": -131.6352081032
            },
            {
                "lat": 52.5162273043,
                "lng": -131.6485448213
            },
            {
                "lat": 52.5228923423,
                "lng": -131.664962713
            },
            {
                "lat": 52.5264627384,
                "lng": -131.6754309658
            },
            {
                "lat": 52.5352626652,
                "lng": -131.6868444836
            },
            {
                "lat": 52.5452578192,
                "lng": -131.6947025247
            },
            {
                "lat": 52.5545387936,
                "lng": -131.6963653991
            },
            {
                "lat": 52.5611989622,
                "lng": -131.6896974338
            },
            {
                "lat": 52.5619160099,
                "lng": -131.6704246035
            },
            {
                "lat": 52.5578689496,
                "lng": -131.6563875168
            },
            {
                "lat": 52.557155584,
                "lng": -131.6473533503
            },
            {
                "lat": 52.5581088264,
                "lng": -131.6392513109
            },
            {
                "lat": 52.5621535348,
                "lng": -131.6316376332
            },
            {
                "lat": 52.5650066879,
                "lng": -131.6264034173
            },
            {
                "lat": 52.5668567067,
                "lng": -131.6244046112
            },
            {
                "lat": 52.5696493248,
                "lng": -131.6293790286
            },
            {
                "lat": 52.5677445552,
                "lng": -131.6372367889
            },
            {
                "lat": 52.5656017409,
                "lng": -131.6434176856
            },
            {
                "lat": 52.5667910606,
                "lng": -131.6531826147
            },
            {
                "lat": 52.5722649655,
                "lng": -131.6662598194
            },
            {
                "lat": 52.5758354404,
                "lng": -131.6784060025
            },
            {
                "lat": 52.5744061674,
                "lng": -131.6931605624
            },
            {
                "lat": 52.5713116187,
                "lng": -131.7088613651
            },
            {
                "lat": 52.572979048,
                "lng": -131.7238467855
            },
            {
                "lat": 52.5989152273,
                "lng": -131.7443086162
            },
            {
                "lat": 52.6212805789,
                "lng": -131.7645420818
            },
            {
                "lat": 52.6367488202,
                "lng": -131.7693019996
            },
            {
                "lat": 52.6562613229,
                "lng": -131.7666787135
            },
            {
                "lat": 52.6745836618,
                "lng": -131.7723853518
            },
            {
                "lat": 52.6841006552,
                "lng": -131.7999882578
            },
            {
                "lat": 52.6929051608,
                "lng": -131.8409118267
            },
            {
                "lat": 52.6990892987,
                "lng": -131.8535306875
            },
            {
                "lat": 52.7095610194,
                "lng": -131.8544760355
            },
            {
                "lat": 52.7219356389,
                "lng": -131.8449713391
            },
            {
                "lat": 52.7324026236,
                "lng": -131.8240213114
            },
            {
                "lat": 52.7421570495,
                "lng": -131.8078461289
            },
            {
                "lat": 52.7439427075,
                "lng": -131.8198547054
            },
            {
                "lat": 52.7437050882,
                "lng": -131.834854882
            },
            {
                "lat": 52.7394225598,
                "lng": -131.8515016857
            },
            {
                "lat": 52.7344249659,
                "lng": -131.8710176185
            },
            {
                "lat": 52.7368053659,
                "lng": -131.8890993092
            },
            {
                "lat": 52.7467998443,
                "lng": -131.8969570438
            },
            {
                "lat": 52.7608371804,
                "lng": -131.8976744237
            },
            {
                "lat": 52.771068803,
                "lng": -131.8848268767
            },
            {
                "lat": 52.8108058685,
                "lng": -131.917541627
            },
            {
                "lat": 52.839359256,
                "lng": -131.9526364993
            },
            {
                "lat": 52.8524476901,
                "lng": -131.9889224592
            },
            {
                "lat": 52.8619647307,
                "lng": -132.01449664
            },
            {
                "lat": 52.8774302815,
                "lng": -132.0371101696
            },
            {
                "lat": 52.901820592,
                "lng": -132.0430596516
            },
            {
                "lat": 52.9220465438,
                "lng": -132.0240179051
            },
            {
                "lat": 52.9404867694,
                "lng": -131.9924926126
            },
            {
                "lat": 52.9517897953,
                "lng": -131.9752356034
            },
            {
                "lat": 52.9440575049,
                "lng": -131.9449011924
            },
            {
                "lat": 52.9440574832,
                "lng": -131.9151612714
            },
            {
                "lat": 52.9618759287,
                "lng": -131.9279329028
            },
            {
                "lat": 52.9953344914,
                "lng": -131.9563288346
            },
            {
                "lat": 53.006515094,
                "lng": -131.9705961422
            },
            {
                "lat": 53.0167505874,
                "lng": -131.9744112006
            },
            {
                "lat": 53.0269808699,
                "lng": -131.9732212893
            },
            {
                "lat": 53.0315014171,
                "lng": -131.9677423401
            },
            {
                "lat": 53.0343547339,
                "lng": -131.9695286283
            },
            {
                "lat": 53.0362580493,
                "lng": -131.9719095594
            },
            {
                "lat": 53.0355455221,
                "lng": -131.9773861178
            },
            {
                "lat": 53.0341183199,
                "lng": -131.9866635499
            },
            {
                "lat": 53.0343549391,
                "lng": -131.9992681318
            },
            {
                "lat": 53.0343549769,
                "lng": -132.0161586846
            },
            {
                "lat": 53.0336422108,
                "lng": -132.0304416385
            },
            {
                "lat": 53.0362582858,
                "lng": -132.0363924833
            },
            {
                "lat": 53.0453036088,
                "lng": -132.0392456435
            },
            {
                "lat": 53.0536307115,
                "lng": -132.0399634998
            },
            {
                "lat": 53.059818561,
                "lng": -132.0356746124
            },
            {
                "lat": 53.0640990627,
                "lng": -132.0247352609
            },
            {
                "lat": 53.0657649532,
                "lng": -132.0021211968
            },
            {
                "lat": 53.0650523109,
                "lng": -131.988082611
            },
            {
                "lat": 53.0617218647,
                "lng": -131.975952549
            },
            {
                "lat": 53.0593408354,
                "lng": -131.9521642815
            },
            {
                "lat": 53.0583876336,
                "lng": -131.9357447686
            },
            {
                "lat": 53.060291084,
                "lng": -131.9209898391
            },
            {
                "lat": 53.0645752207,
                "lng": -131.9050447367
            },
            {
                "lat": 53.0707624607,
                "lng": -131.889342722
            },
            {
                "lat": 53.072665974,
                "lng": -131.8800659108
            },
            {
                "lat": 53.0700489997,
                "lng": -131.8674469935
            },
            {
                "lat": 53.0671920666,
                "lng": -131.8567352368
            },
            {
                "lat": 53.065288613,
                "lng": -131.8488923517
            },
            {
                "lat": 53.0638615865,
                "lng": -131.844604552
            },
            {
                "lat": 53.0649720307,
                "lng": -131.8445890395
            },
            {
                "lat": 53.0693361918,
                "lng": -131.8429405731
            },
            {
                "lat": 53.0755191672,
                "lng": -131.8353272997
            },
            {
                "lat": 53.0774228332,
                "lng": -131.8167729563
            },
            {
                "lat": 53.0772436322,
                "lng": -131.7763527436
            },
            {
                "lat": 53.0581510372,
                "lng": -131.7006532408
            },
            {
                "lat": 53.056961652,
                "lng": -131.6530605362
            },
            {
                "lat": 53.0786136985,
                "lng": -131.6502073936
            },
            {
                "lat": 53.1024086555,
                "lng": -131.6509238508
            },
            {
                "lat": 53.1001468019,
                "lng": -131.6638784132
            },
            {
                "lat": 53.1006244877,
                "lng": -131.6850587352
            },
            {
                "lat": 53.1070478184,
                "lng": -131.6974328027
            },
            {
                "lat": 53.120849096,
                "lng": -131.7183831523
            },
            {
                "lat": 53.1346511107,
                "lng": -131.7288517597
            },
            {
                "lat": 53.1341739937,
                "lng": -131.7485964728
            },
            {
                "lat": 53.1377441555,
                "lng": -131.7757263976
            },
            {
                "lat": 53.1513055084,
                "lng": -131.7976073237
            },
            {
                "lat": 53.1601098766,
                "lng": -131.8104551883
            },
            {
                "lat": 53.1720089032,
                "lng": -131.8130805175
            },
            {
                "lat": 53.1910438936,
                "lng": -131.8066564659
            },
            {
                "lat": 53.2157890845,
                "lng": -131.804520328
            },
            {
                "lat": 53.2284017113,
                "lng": -131.8116458506
            },
            {
                "lat": 53.2493399132,
                "lng": -131.8152165813
            },
            {
                "lat": 53.2376825033,
                "lng": -131.8228300297
            },
            {
                "lat": 53.2279243546,
                "lng": -131.8497159487
            },
            {
                "lat": 53.2269746258,
                "lng": -131.8708953251
            },
            {
                "lat": 53.225784708,
                "lng": -131.9006502144
            },
            {
                "lat": 53.2226905685,
                "lng": -131.9239657854
            },
            {
                "lat": 53.2060360906,
                "lng": -131.9449012312
            },
            {
                "lat": 53.1874737908,
                "lng": -131.9851068246
            },
            {
                "lat": 53.1770051991,
                "lng": -132.0158085402
            },
            {
                "lat": 53.1646304059,
                "lng": -132.0315094193
            },
            {
                "lat": 53.1551136527,
                "lng": -132.0493628469
            },
            {
                "lat": 53.1381611764,
                "lng": -132.0741123685
            },
            {
                "lat": 53.1262625395,
                "lng": -132.1282355383
            },
            {
                "lat": 53.1274532149,
                "lng": -132.189513997
            },
            {
                "lat": 53.1274533619,
                "lng": -132.2513733729
            },
            {
                "lat": 53.1334000443,
                "lng": -132.2739871796
            },
            {
                "lat": 53.1447025625,
                "lng": -132.2858732936
            },
            {
                "lat": 53.1503454561,
                "lng": -132.2880247308
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 159,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 159,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1656916929.2931,
            "FEATURE_LENGTH_M": 777316.1352,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.662681601259635,
            "lng": -131.79447419784944
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.321800451,
                "lng": -129.1224369615
            },
            {
                "lat": 53.3116945084,
                "lng": -129.1223903837
            },
            {
                "lat": 53.2874601483,
                "lng": -129.095366761
            },
            {
                "lat": 53.2797817208,
                "lng": -129.0658562724
            },
            {
                "lat": 53.2655300033,
                "lng": -129.0400245097
            },
            {
                "lat": 53.2586976287,
                "lng": -128.9942931783
            },
            {
                "lat": 53.2682040243,
                "lng": -128.9197545539
            },
            {
                "lat": 53.2788919259,
                "lng": -128.902832458
            },
            {
                "lat": 53.2991566851,
                "lng": -128.9091486142
            },
            {
                "lat": 53.3144573243,
                "lng": -128.9447479187
            },
            {
                "lat": 53.3221544882,
                "lng": -128.9448546722
            },
            {
                "lat": 53.3284642797,
                "lng": -128.9649204334
            },
            {
                "lat": 53.3460694358,
                "lng": -128.9863277662
            },
            {
                "lat": 53.3520208511,
                "lng": -129.0096579905
            },
            {
                "lat": 53.3524967528,
                "lng": -129.0329737581
            },
            {
                "lat": 53.3475001899,
                "lng": -129.0636600429
            },
            {
                "lat": 53.3298915446,
                "lng": -129.0953065873
            },
            {
                "lat": 53.321800451,
                "lng": -129.1224369615
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 160,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 6-7",
            "LABEL": "6-7",
            "FISHERY_AREA_ID": 2415,
            "OBJECTID": 160,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 99477157.6803,
            "FEATURE_LENGTH_M": 38962.481,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.31033735628888,
            "lng": -129.01872165686115
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.1503454561,
                "lng": -132.2880247308
            },
            {
                "lat": 53.1512951386,
                "lng": -132.2849881365
            },
            {
                "lat": 53.151523197,
                "lng": -132.2827749132
            },
            {
                "lat": 53.152565306,
                "lng": -132.2827757399
            },
            {
                "lat": 53.1700208274,
                "lng": -132.2789922628
            },
            {
                "lat": 53.1819196405,
                "lng": -132.2837518233
            },
            {
                "lat": 53.1963884819,
                "lng": -132.3100132186
            },
            {
                "lat": 53.202812319,
                "lng": -132.3352355597
            },
            {
                "lat": 53.1947250705,
                "lng": -132.3514100575
            },
            {
                "lat": 53.178543224,
                "lng": -132.365921766
            },
            {
                "lat": 53.1666445117,
                "lng": -132.3747251117
            },
            {
                "lat": 53.1509401158,
                "lng": -132.3873444582
            },
            {
                "lat": 53.1442801806,
                "lng": -132.3935251579
            },
            {
                "lat": 53.1386073354,
                "lng": -132.3936921898
            },
            {
                "lat": 53.1262671646,
                "lng": -132.3936924066
            },
            {
                "lat": 53.1261786564,
                "lng": -132.3906547331
            },
            {
                "lat": 53.1254196377,
                "lng": -132.3857874696
            },
            {
                "lat": 53.1267546613,
                "lng": -132.3831021403
            },
            {
                "lat": 53.1293148817,
                "lng": -132.3715057753
            },
            {
                "lat": 53.1297643233,
                "lng": -132.3638150798
            },
            {
                "lat": 53.1278070846,
                "lng": -132.3526769367
            },
            {
                "lat": 53.1296160266,
                "lng": -132.3454443816
            },
            {
                "lat": 53.1338353025,
                "lng": -132.3442377136
            },
            {
                "lat": 53.137638212,
                "lng": -132.343765411
            },
            {
                "lat": 53.1489410971,
                "lng": -132.2941130203
            },
            {
                "lat": 53.1497724882,
                "lng": -132.2927711269
            },
            {
                "lat": 53.1503454561,
                "lng": -132.2880247308
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 161,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 67,
            "MANAGEMENT_AREA_NAME": "Subarea 2-67",
            "LABEL": "2-67",
            "FISHERY_AREA_ID": 1958,
            "OBJECTID": 161,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 40103224.8926,
            "FEATURE_LENGTH_M": 26055.1424,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.150824659133335,
            "lng": -132.33936170561108
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.1779483067,
                "lng": -132.5608667871
            },
            {
                "lat": 53.1767235606,
                "lng": -132.5638724496
            },
            {
                "lat": 53.1520076953,
                "lng": -132.5856172273
            },
            {
                "lat": 53.1506536953,
                "lng": -132.5821234262
            },
            {
                "lat": 53.1468467802,
                "lng": -132.5769799542
            },
            {
                "lat": 53.1486430245,
                "lng": -132.5733189353
            },
            {
                "lat": 53.142998133,
                "lng": -132.5633853109
            },
            {
                "lat": 53.1353836145,
                "lng": -132.5524446846
            },
            {
                "lat": 53.1301494464,
                "lng": -132.535309416
            },
            {
                "lat": 53.1280099593,
                "lng": -132.52197252
            },
            {
                "lat": 53.125392625,
                "lng": -132.5096125525
            },
            {
                "lat": 53.1253928774,
                "lng": -132.5000911939
            },
            {
                "lat": 53.1283988219,
                "lng": -132.4993138269
            },
            {
                "lat": 53.144779392,
                "lng": -132.4993135716
            },
            {
                "lat": 53.1527178364,
                "lng": -132.5136408072
            },
            {
                "lat": 53.1577224487,
                "lng": -132.5129088308
            },
            {
                "lat": 53.1639101455,
                "lng": -132.5168458074
            },
            {
                "lat": 53.1698576117,
                "lng": -132.5304102317
            },
            {
                "lat": 53.1755675139,
                "lng": -132.549209126
            },
            {
                "lat": 53.1767576124,
                "lng": -132.5556334552
            },
            {
                "lat": 53.1779483067,
                "lng": -132.5608667871
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 162,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 64,
            "MANAGEMENT_AREA_NAME": "Subarea 2-64",
            "LABEL": "2-64",
            "FISHERY_AREA_ID": 1955,
            "OBJECTID": 162,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 19857332.0987,
            "FEATURE_LENGTH_M": 18370.723,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.15180044797144,
            "lng": -132.54113032864288
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.3108062445,
                "lng": -129.2364042466
            },
            {
                "lat": 53.3108364872,
                "lng": -129.2366935823
            },
            {
                "lat": 53.3125956675,
                "lng": -129.2539208879
            },
            {
                "lat": 53.3127713312,
                "lng": -129.2666780413
            },
            {
                "lat": 53.3031345736,
                "lng": -129.270310315
            },
            {
                "lat": 53.3022192701,
                "lng": -129.2649995486
            },
            {
                "lat": 53.2853240886,
                "lng": -129.2488252825
            },
            {
                "lat": 53.2660481961,
                "lng": -129.2469172681
            },
            {
                "lat": 53.2479665369,
                "lng": -129.2626200283
            },
            {
                "lat": 53.2374946595,
                "lng": -129.2718967501
            },
            {
                "lat": 53.2051348584,
                "lng": -129.2845151637
            },
            {
                "lat": 53.1670647042,
                "lng": -129.3047324186
            },
            {
                "lat": 53.143825818,
                "lng": -129.312286042
            },
            {
                "lat": 53.1148678076,
                "lng": -129.2965396559
            },
            {
                "lat": 53.1047360623,
                "lng": -129.2772064867
            },
            {
                "lat": 53.1096151099,
                "lng": -129.2730414191
            },
            {
                "lat": 53.1119922502,
                "lng": -129.2592314659
            },
            {
                "lat": 53.1053310417,
                "lng": -129.245437523
            },
            {
                "lat": 53.1093754514,
                "lng": -129.2152093697
            },
            {
                "lat": 53.1181795003,
                "lng": -129.2002254848
            },
            {
                "lat": 53.1098513449,
                "lng": -129.1666715515
            },
            {
                "lat": 53.1445044047,
                "lng": -129.1870574452
            },
            {
                "lat": 53.3001134536,
                "lng": -129.219268921
            },
            {
                "lat": 53.3108062445,
                "lng": -129.2364042466
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 163,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 163,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 121109846.3771,
            "FEATURE_LENGTH_M": 59231.1233,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.21019146278751,
            "lng": -129.25154554768332
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.3047028175,
                "lng": -128.8753512
            },
            {
                "lat": 53.3120578828,
                "lng": -128.8883665084
            },
            {
                "lat": 53.3144573243,
                "lng": -128.9447479187
            },
            {
                "lat": 53.2991566851,
                "lng": -128.9091486142
            },
            {
                "lat": 53.2092014215,
                "lng": -128.8600310062
            },
            {
                "lat": 53.1334380054,
                "lng": -128.7687837552
            },
            {
                "lat": 53.0418288993,
                "lng": -128.64743116
            },
            {
                "lat": 52.9956896557,
                "lng": -128.6259772298
            },
            {
                "lat": 52.9022945654,
                "lng": -128.6015939214
            },
            {
                "lat": 52.802371635,
                "lng": -128.6379244208
            },
            {
                "lat": 52.6964836065,
                "lng": -128.5801093205
            },
            {
                "lat": 52.6836163163,
                "lng": -128.5730891384
            },
            {
                "lat": 52.6774370533,
                "lng": -128.5489655322
            },
            {
                "lat": 52.6774373428,
                "lng": -128.5346072054
            },
            {
                "lat": 52.6770741213,
                "lng": -128.5296025729
            },
            {
                "lat": 52.6919866795,
                "lng": -128.5329129025
            },
            {
                "lat": 52.7830085879,
                "lng": -128.5222927853
            },
            {
                "lat": 52.7984845604,
                "lng": -128.5178530145
            },
            {
                "lat": 52.8065146405,
                "lng": -128.5195462998
            },
            {
                "lat": 52.821216779,
                "lng": -128.5178378256
            },
            {
                "lat": 52.833297517,
                "lng": -128.5134128788
            },
            {
                "lat": 52.8508294136,
                "lng": -128.5126495599
            },
            {
                "lat": 52.860679477,
                "lng": -128.5132292667
            },
            {
                "lat": 52.8679812196,
                "lng": -128.5136570566
            },
            {
                "lat": 52.8792720786,
                "lng": -128.5110623393
            },
            {
                "lat": 52.881733016,
                "lng": -128.5104987577
            },
            {
                "lat": 52.8835409381,
                "lng": -128.5105587351
            },
            {
                "lat": 52.883532802,
                "lng": -128.4980472207
            },
            {
                "lat": 52.8850556748,
                "lng": -128.4936823306
            },
            {
                "lat": 52.8989988994,
                "lng": -128.4930120133
            },
            {
                "lat": 52.912322495,
                "lng": -128.4944305337
            },
            {
                "lat": 52.9173204103,
                "lng": -128.495148381
            },
            {
                "lat": 52.9193494438,
                "lng": -128.4971770082
            },
            {
                "lat": 52.9205773655,
                "lng": -128.4991764941
            },
            {
                "lat": 52.9218977259,
                "lng": -128.500122705
            },
            {
                "lat": 52.9268420112,
                "lng": -128.4970545437
            },
            {
                "lat": 52.9351884788,
                "lng": -128.4927222385
            },
            {
                "lat": 52.9510153584,
                "lng": -128.4973749937
            },
            {
                "lat": 52.9800409865,
                "lng": -128.5009452518
            },
            {
                "lat": 53.0116886151,
                "lng": -128.4949956666
            },
            {
                "lat": 53.0212094184,
                "lng": -128.4995116068
            },
            {
                "lat": 53.0314406772,
                "lng": -128.5121303886
            },
            {
                "lat": 53.0495227621,
                "lng": -128.5261694944
            },
            {
                "lat": 53.0628472406,
                "lng": -128.5347296277
            },
            {
                "lat": 53.0679056256,
                "lng": -128.5399637157
            },
            {
                "lat": 53.0673478533,
                "lng": -128.5508571784
            },
            {
                "lat": 53.0876496931,
                "lng": -128.5563513366
            },
            {
                "lat": 53.098895361,
                "lng": -128.5546270037
            },
            {
                "lat": 53.1153451843,
                "lng": -128.564484278
            },
            {
                "lat": 53.1187129924,
                "lng": -128.5693965021
            },
            {
                "lat": 53.1282310911,
                "lng": -128.5789488225
            },
            {
                "lat": 53.1338500743,
                "lng": -128.5773163413
            },
            {
                "lat": 53.1434557646,
                "lng": -128.5784914225
            },
            {
                "lat": 53.161269707,
                "lng": -128.5902713675
            },
            {
                "lat": 53.174198579,
                "lng": -128.6086574005
            },
            {
                "lat": 53.1848297806,
                "lng": -128.6282036451
            },
            {
                "lat": 53.1815037651,
                "lng": -128.6399985729
            },
            {
                "lat": 53.1808439212,
                "lng": -128.6561885589
            },
            {
                "lat": 53.187126758,
                "lng": -128.6638337972
            },
            {
                "lat": 53.1905665025,
                "lng": -128.6733861013
            },
            {
                "lat": 53.3047028175,
                "lng": -128.8753512
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 164,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 20,
            "MANAGEMENT_AREA_NAME": "Subarea 6-20",
            "LABEL": "6-20",
            "FISHERY_AREA_ID": 2427,
            "OBJECTID": 164,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 565724065.9997,
            "FEATURE_LENGTH_M": 169491.3559,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.990837345491805,
            "lng": -128.58449178145082
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.1520076953,
                "lng": -132.5856172273
            },
            {
                "lat": 53.1520156336,
                "lng": -132.6292875928
            },
            {
                "lat": 53.0955356516,
                "lng": -132.5749357145
            },
            {
                "lat": 53.094158561,
                "lng": -132.5660404809
            },
            {
                "lat": 53.0939372192,
                "lng": -132.5577083898
            },
            {
                "lat": 53.0865859693,
                "lng": -132.4165343597
            },
            {
                "lat": 53.0919457445,
                "lng": -132.3907769487
            },
            {
                "lat": 53.0954552276,
                "lng": -132.3876954805
            },
            {
                "lat": 53.1014636181,
                "lng": -132.3824464529
            },
            {
                "lat": 53.1130828188,
                "lng": -132.3773801175
            },
            {
                "lat": 53.1207382566,
                "lng": -132.379836755
            },
            {
                "lat": 53.1254196377,
                "lng": -132.3857874696
            },
            {
                "lat": 53.1261786564,
                "lng": -132.3906547331
            },
            {
                "lat": 53.1262671646,
                "lng": -132.3936924066
            },
            {
                "lat": 53.1262667745,
                "lng": -132.4007108454
            },
            {
                "lat": 53.1250728964,
                "lng": -132.4046632013
            },
            {
                "lat": 53.1199877078,
                "lng": -132.4023129505
            },
            {
                "lat": 53.1122589529,
                "lng": -132.4013358681
            },
            {
                "lat": 53.1096883653,
                "lng": -132.405746258
            },
            {
                "lat": 53.1123041437,
                "lng": -132.4190676135
            },
            {
                "lat": 53.1120646416,
                "lng": -132.4388121683
            },
            {
                "lat": 53.111114357,
                "lng": -132.4635623791
            },
            {
                "lat": 53.1092108762,
                "lng": -132.4992516827
            },
            {
                "lat": 53.1111145367,
                "lng": -132.5232846399
            },
            {
                "lat": 53.1125417867,
                "lng": -132.5356594977
            },
            {
                "lat": 53.1199191551,
                "lng": -132.5556492104
            },
            {
                "lat": 53.1274684316,
                "lng": -132.5720060209
            },
            {
                "lat": 53.1364856843,
                "lng": -132.5760351423
            },
            {
                "lat": 53.1468467802,
                "lng": -132.5769799542
            },
            {
                "lat": 53.1506536953,
                "lng": -132.5821234262
            },
            {
                "lat": 53.1520076953,
                "lng": -132.5856172273
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 165,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 63,
            "MANAGEMENT_AREA_NAME": "Subarea 2-63",
            "LABEL": "2-63",
            "FISHERY_AREA_ID": 1954,
            "OBJECTID": 165,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 45466973.128,
            "FEATURE_LENGTH_M": 44620.3836,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.11838059144837,
            "lng": -132.47616813595806
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.2991566851,
                "lng": -128.9091486142
            },
            {
                "lat": 53.2788919259,
                "lng": -128.902832458
            },
            {
                "lat": 53.2682040243,
                "lng": -128.9197545539
            },
            {
                "lat": 53.2586976287,
                "lng": -128.9942931783
            },
            {
                "lat": 53.2655300033,
                "lng": -129.0400245097
            },
            {
                "lat": 53.2797817208,
                "lng": -129.0658562724
            },
            {
                "lat": 53.2527581281,
                "lng": -129.056945881
            },
            {
                "lat": 53.2275206711,
                "lng": -129.0465549783
            },
            {
                "lat": 53.2180175412,
                "lng": -129.0456538049
            },
            {
                "lat": 53.2093653618,
                "lng": -129.0398252685
            },
            {
                "lat": 53.2076983224,
                "lng": -129.0269774196
            },
            {
                "lat": 53.2000846345,
                "lng": -129.0017554125
            },
            {
                "lat": 53.1936609189,
                "lng": -128.9715429863
            },
            {
                "lat": 53.1819999972,
                "lng": -128.9405970446
            },
            {
                "lat": 53.1786688431,
                "lng": -128.9032439342
            },
            {
                "lat": 53.1717680979,
                "lng": -128.8844454668
            },
            {
                "lat": 53.1610601971,
                "lng": -128.8792116511
            },
            {
                "lat": 53.1472589895,
                "lng": -128.8830263853
            },
            {
                "lat": 53.1491626364,
                "lng": -128.9296568633
            },
            {
                "lat": 53.1543998357,
                "lng": -128.9603576998
            },
            {
                "lat": 53.1377408467,
                "lng": -128.9662937317
            },
            {
                "lat": 53.1313172158,
                "lng": -128.9822387617
            },
            {
                "lat": 53.150829616,
                "lng": -128.9998468474
            },
            {
                "lat": 53.1722453898,
                "lng": -129.0048525269
            },
            {
                "lat": 53.1839450627,
                "lng": -129.0101004541
            },
            {
                "lat": 53.1923560586,
                "lng": -129.0237884312
            },
            {
                "lat": 53.1927077536,
                "lng": -129.0383910897
            },
            {
                "lat": 53.2022243809,
                "lng": -129.0507654426
            },
            {
                "lat": 53.1903302745,
                "lng": -129.0571900067
            },
            {
                "lat": 53.1808085181,
                "lng": -129.0731353359
            },
            {
                "lat": 53.1646307698,
                "lng": -129.0767058785
            },
            {
                "lat": 53.141075208,
                "lng": -129.0721896748
            },
            {
                "lat": 53.1208492106,
                "lng": -129.0695646136
            },
            {
                "lat": 53.0892027033,
                "lng": -129.0736086323
            },
            {
                "lat": 53.0711177508,
                "lng": -129.083373799
            },
            {
                "lat": 53.0544619095,
                "lng": -129.1047829156
            },
            {
                "lat": 53.0435144679,
                "lng": -129.1133575908
            },
            {
                "lat": 53.0499379947,
                "lng": -129.1285855383
            },
            {
                "lat": 53.0599326613,
                "lng": -129.1450043704
            },
            {
                "lat": 53.0404246571,
                "lng": -129.1561894557
            },
            {
                "lat": 53.0254327005,
                "lng": -129.1718896906
            },
            {
                "lat": 53.0140043102,
                "lng": -129.1747736674
            },
            {
                "lat": 52.9462973426,
                "lng": -129.1629029139
            },
            {
                "lat": 52.9354520108,
                "lng": -129.1682589313
            },
            {
                "lat": 52.9283715598,
                "lng": -129.1664428732
            },
            {
                "lat": 52.9386220362,
                "lng": -129.1565247082
            },
            {
                "lat": 52.952858485,
                "lng": -129.1559449122
            },
            {
                "lat": 52.9690397231,
                "lng": -129.1566619058
            },
            {
                "lat": 52.9854579666,
                "lng": -129.1447609062
            },
            {
                "lat": 52.9883113423,
                "lng": -129.1285857717
            },
            {
                "lat": 52.9761772297,
                "lng": -129.1176303547
            },
            {
                "lat": 52.9466706084,
                "lng": -129.1228644679
            },
            {
                "lat": 52.9317860608,
                "lng": -129.1158591542
            },
            {
                "lat": 52.9754372536,
                "lng": -129.0012203669
            },
            {
                "lat": 53.010204305,
                "lng": -128.9822392219
            },
            {
                "lat": 53.0387575841,
                "lng": -128.95654288
            },
            {
                "lat": 53.043041762,
                "lng": -128.8994444947
            },
            {
                "lat": 53.0275722342,
                "lng": -128.8887327012
            },
            {
                "lat": 53.0159144384,
                "lng": -128.9060972702
            },
            {
                "lat": 53.0052065347,
                "lng": -128.932509594
            },
            {
                "lat": 52.9968874826,
                "lng": -128.9449001159
            },
            {
                "lat": 52.984394012,
                "lng": -128.9417878735
            },
            {
                "lat": 52.9673731758,
                "lng": -128.9417887032
            },
            {
                "lat": 52.9395332409,
                "lng": -128.9598702517
            },
            {
                "lat": 52.9159777953,
                "lng": -128.9748685821
            },
            {
                "lat": 52.8964653113,
                "lng": -129.0212711968
            },
            {
                "lat": 52.8890871084,
                "lng": -129.0574341866
            },
            {
                "lat": 52.8826631388,
                "lng": -129.0610045795
            },
            {
                "lat": 52.8748133443,
                "lng": -129.0357820878
            },
            {
                "lat": 52.8548234903,
                "lng": -129.0115046507
            },
            {
                "lat": 52.8419759829,
                "lng": -128.9998472001
            },
            {
                "lat": 52.8343623245,
                "lng": -129.0046084226
            },
            {
                "lat": 52.8329354698,
                "lng": -129.013167877
            },
            {
                "lat": 52.8343625357,
                "lng": -129.0248412413
            },
            {
                "lat": 52.8467329728,
                "lng": -129.0417334375
            },
            {
                "lat": 52.8605352319,
                "lng": -129.0788418096
            },
            {
                "lat": 52.8717193054,
                "lng": -129.1062164815
            },
            {
                "lat": 52.8764757368,
                "lng": -129.1226344616
            },
            {
                "lat": 52.870292814,
                "lng": -129.1293026644
            },
            {
                "lat": 52.8632126443,
                "lng": -129.1237640565
            },
            {
                "lat": 52.8506015953,
                "lng": -129.1201935372
            },
            {
                "lat": 52.8398669463,
                "lng": -129.1189268478
            },
            {
                "lat": 52.8124695762,
                "lng": -129.1026458687
            },
            {
                "lat": 52.8132439263,
                "lng": -129.0799860457
            },
            {
                "lat": 52.8053889449,
                "lng": -129.0702213481
            },
            {
                "lat": 52.7925412988,
                "lng": -129.0542760702
            },
            {
                "lat": 52.7799301345,
                "lng": -129.0457160546
            },
            {
                "lat": 52.7787395977,
                "lng": -129.0262003178
            },
            {
                "lat": 52.7868315128,
                "lng": -129.0069269528
            },
            {
                "lat": 52.7858815525,
                "lng": -128.9845584806
            },
            {
                "lat": 52.7735063231,
                "lng": -128.9786069823
            },
            {
                "lat": 52.7601815912,
                "lng": -129.0204924727
            },
            {
                "lat": 52.7487599297,
                "lng": -129.0542760374
            },
            {
                "lat": 52.7401919262,
                "lng": -129.0500031243
            },
            {
                "lat": 52.7316284537,
                "lng": -129.0290535669
            },
            {
                "lat": 52.7323422074,
                "lng": -128.9933621863
            },
            {
                "lat": 52.7280578151,
                "lng": -128.9764708174
            },
            {
                "lat": 52.7133068919,
                "lng": -128.9691002337
            },
            {
                "lat": 52.6976013308,
                "lng": -128.9717107502
            },
            {
                "lat": 52.6966512316,
                "lng": -128.9586334166
            },
            {
                "lat": 52.6885605333,
                "lng": -128.934829873
            },
            {
                "lat": 52.6797561408,
                "lng": -128.9200745124
            },
            {
                "lat": 52.6699980875,
                "lng": -128.9029547953
            },
            {
                "lat": 52.6951370662,
                "lng": -128.8595731928
            },
            {
                "lat": 52.7937811242,
                "lng": -128.79074081
            },
            {
                "lat": 52.8472709345,
                "lng": -128.7975306652
            },
            {
                "lat": 52.862972802,
                "lng": -128.7844541971
            },
            {
                "lat": 52.849643011,
                "lng": -128.7517705067
            },
            {
                "lat": 52.8721847668,
                "lng": -128.7360382174
            },
            {
                "lat": 52.9291535393,
                "lng": -128.7597046159
            },
            {
                "lat": 52.9342071835,
                "lng": -128.7350156139
            },
            {
                "lat": 52.9086645252,
                "lng": -128.7105864307
            },
            {
                "lat": 52.9182098111,
                "lng": -128.6920773358
            },
            {
                "lat": 52.9529453714,
                "lng": -128.7019962857
            },
            {
                "lat": 52.9797175148,
                "lng": -128.6909943888
            },
            {
                "lat": 52.9835815622,
                "lng": -128.6642310051
            },
            {
                "lat": 52.9794195958,
                "lng": -128.6457828287
            },
            {
                "lat": 52.9469988327,
                "lng": -128.6362758129
            },
            {
                "lat": 52.9053575572,
                "lng": -128.6454927462
            },
            {
                "lat": 52.8634188277,
                "lng": -128.6654210076
            },
            {
                "lat": 52.8440857877,
                "lng": -128.6883241369
            },
            {
                "lat": 52.8272168697,
                "lng": -128.7220614454
            },
            {
                "lat": 52.7681468765,
                "lng": -128.7303923734
            },
            {
                "lat": 52.743049849,
                "lng": -128.722824094
            },
            {
                "lat": 52.7128950873,
                "lng": -128.7381740917
            },
            {
                "lat": 52.693558206,
                "lng": -128.7361449765
            },
            {
                "lat": 52.6671449423,
                "lng": -128.7323454404
            },
            {
                "lat": 52.6507267517,
                "lng": -128.7321013068
            },
            {
                "lat": 52.6483462386,
                "lng": -128.7397159257
            },
            {
                "lat": 52.6392332553,
                "lng": -128.7485501101
            },
            {
                "lat": 52.6104387689,
                "lng": -128.7526085346
            },
            {
                "lat": 52.6048008899,
                "lng": -128.7498473767
            },
            {
                "lat": 52.6137009938,
                "lng": -128.7309719368
            },
            {
                "lat": 52.6417815141,
                "lng": -128.6991877147
            },
            {
                "lat": 52.6602213841,
                "lng": -128.6706391867
            },
            {
                "lat": 52.6709297387,
                "lng": -128.6289979289
            },
            {
                "lat": 52.6863973529,
                "lng": -128.599853169
            },
            {
                "lat": 52.6964836065,
                "lng": -128.5801093205
            },
            {
                "lat": 52.802371635,
                "lng": -128.6379244208
            },
            {
                "lat": 52.9022945654,
                "lng": -128.6015939214
            },
            {
                "lat": 52.9956896557,
                "lng": -128.6259772298
            },
            {
                "lat": 53.0418288993,
                "lng": -128.64743116
            },
            {
                "lat": 53.1334380054,
                "lng": -128.7687837552
            },
            {
                "lat": 53.2092014215,
                "lng": -128.8600310062
            },
            {
                "lat": 53.2991566851,
                "lng": -128.9091486142
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 166,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 166,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1419905267.062,
            "FEATURE_LENGTH_M": 394609.0968,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.94244426049725,
            "lng": -128.93880945706616
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.1253928774,
                "lng": -132.5000911939
            },
            {
                "lat": 53.125392625,
                "lng": -132.5096125525
            },
            {
                "lat": 53.1280099593,
                "lng": -132.52197252
            },
            {
                "lat": 53.1301494464,
                "lng": -132.535309416
            },
            {
                "lat": 53.1353836145,
                "lng": -132.5524446846
            },
            {
                "lat": 53.142998133,
                "lng": -132.5633853109
            },
            {
                "lat": 53.1486430245,
                "lng": -132.5733189353
            },
            {
                "lat": 53.1468467802,
                "lng": -132.5769799542
            },
            {
                "lat": 53.1364856843,
                "lng": -132.5760351423
            },
            {
                "lat": 53.1274684316,
                "lng": -132.5720060209
            },
            {
                "lat": 53.1199191551,
                "lng": -132.5556492104
            },
            {
                "lat": 53.1125417867,
                "lng": -132.5356594977
            },
            {
                "lat": 53.1111145367,
                "lng": -132.5232846399
            },
            {
                "lat": 53.1092108762,
                "lng": -132.4992516827
            },
            {
                "lat": 53.111114357,
                "lng": -132.4635623791
            },
            {
                "lat": 53.1120646416,
                "lng": -132.4388121683
            },
            {
                "lat": 53.1123041437,
                "lng": -132.4190676135
            },
            {
                "lat": 53.1096883653,
                "lng": -132.405746258
            },
            {
                "lat": 53.1122589529,
                "lng": -132.4013358681
            },
            {
                "lat": 53.1199877078,
                "lng": -132.4023129505
            },
            {
                "lat": 53.1250728964,
                "lng": -132.4046632013
            },
            {
                "lat": 53.1264498912,
                "lng": -132.4092411544
            },
            {
                "lat": 53.126617586,
                "lng": -132.416885852
            },
            {
                "lat": 53.1253925849,
                "lng": -132.4276271089
            },
            {
                "lat": 53.1244390595,
                "lng": -132.4445340872
            },
            {
                "lat": 53.1230117922,
                "lng": -132.4585732292
            },
            {
                "lat": 53.1215858571,
                "lng": -132.4756925451
            },
            {
                "lat": 53.1211085539,
                "lng": -132.4873659356
            },
            {
                "lat": 53.1253928774,
                "lng": -132.5000911939
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 167,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 167,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 19177289.191,
            "FEATURE_LENGTH_M": 28425.4778,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.1240015930276,
            "lng": -132.4879487002207
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.1450426295,
                "lng": -132.4904020007
            },
            {
                "lat": 53.1451030977,
                "lng": -132.4944152739
            },
            {
                "lat": 53.1449550996,
                "lng": -132.4986870774
            },
            {
                "lat": 53.144779392,
                "lng": -132.4993135716
            },
            {
                "lat": 53.1283988219,
                "lng": -132.4993138269
            },
            {
                "lat": 53.1253928774,
                "lng": -132.5000911939
            },
            {
                "lat": 53.1211085539,
                "lng": -132.4873659356
            },
            {
                "lat": 53.1215858571,
                "lng": -132.4756925451
            },
            {
                "lat": 53.1230117922,
                "lng": -132.4585732292
            },
            {
                "lat": 53.1244390595,
                "lng": -132.4445340872
            },
            {
                "lat": 53.1253925849,
                "lng": -132.4276271089
            },
            {
                "lat": 53.126617586,
                "lng": -132.416885852
            },
            {
                "lat": 53.1264498912,
                "lng": -132.4092411544
            },
            {
                "lat": 53.1250728964,
                "lng": -132.4046632013
            },
            {
                "lat": 53.1262667745,
                "lng": -132.4007108454
            },
            {
                "lat": 53.1262671646,
                "lng": -132.3936924066
            },
            {
                "lat": 53.1386073354,
                "lng": -132.3936921898
            },
            {
                "lat": 53.1442801806,
                "lng": -132.3935251579
            },
            {
                "lat": 53.1452902956,
                "lng": -132.4066166332
            },
            {
                "lat": 53.1458850942,
                "lng": -132.4280246408
            },
            {
                "lat": 53.1452903146,
                "lng": -132.4482573531
            },
            {
                "lat": 53.144695687,
                "lng": -132.4684750037
            },
            {
                "lat": 53.1446956016,
                "lng": -132.482162188
            },
            {
                "lat": 53.1450426295,
                "lng": -132.4904020007
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 168,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 66,
            "MANAGEMENT_AREA_NAME": "Subarea 2-66",
            "LABEL": "2-66",
            "FISHERY_AREA_ID": 1957,
            "OBJECTID": 168,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 16565380.4827,
            "FEATURE_LENGTH_M": 18598.8385,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.13473630070417,
            "lng": -132.45051518655416
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.2374946595,
                "lng": -129.2718967501
            },
            {
                "lat": 53.2372586777,
                "lng": -129.2916574877
            },
            {
                "lat": 53.2386776152,
                "lng": -129.2997583919
            },
            {
                "lat": 53.2387998299,
                "lng": -129.3058163774
            },
            {
                "lat": 53.232319345,
                "lng": -129.3680268632
            },
            {
                "lat": 53.2045248267,
                "lng": -129.3452903241
            },
            {
                "lat": 53.2052572161,
                "lng": -129.3442987557
            },
            {
                "lat": 53.2065624636,
                "lng": -129.3287654917
            },
            {
                "lat": 53.2051348584,
                "lng": -129.2845151637
            },
            {
                "lat": 53.2374946595,
                "lng": -129.2718967501
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 169,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 27,
            "MANAGEMENT_AREA_NAME": "Subarea 6-27",
            "LABEL": "6-27",
            "FISHERY_AREA_ID": 2434,
            "OBJECTID": 169,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 18209709.4915,
            "FEATURE_LENGTH_M": 17750.7652,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.22435241516,
            "lng": -129.31119223556001
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.187126758,
                "lng": -128.6638337972
            },
            {
                "lat": 53.1808439212,
                "lng": -128.6561885589
            },
            {
                "lat": 53.1815037651,
                "lng": -128.6399985729
            },
            {
                "lat": 53.1848297806,
                "lng": -128.6282036451
            },
            {
                "lat": 53.1983341481,
                "lng": -128.6382602002
            },
            {
                "lat": 53.2256273235,
                "lng": -128.651473603
            },
            {
                "lat": 53.2480396412,
                "lng": -128.6652679127
            },
            {
                "lat": 53.2540702465,
                "lng": -128.6885371127
            },
            {
                "lat": 53.2506253495,
                "lng": -128.6971585548
            },
            {
                "lat": 53.2302247775,
                "lng": -128.6962888933
            },
            {
                "lat": 53.2017819904,
                "lng": -128.6807864959
            },
            {
                "lat": 53.187126758,
                "lng": -128.6638337972
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 170,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 21,
            "MANAGEMENT_AREA_NAME": "Subarea 6-21",
            "LABEL": "6-21",
            "FISHERY_AREA_ID": 2428,
            "OBJECTID": 170,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 19757439.3916,
            "FEATURE_LENGTH_M": 20283.2875,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.2108445383,
            "lng": -128.66415259532496
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.2115856278,
                "lng": -129.5868691332
            },
            {
                "lat": 53.2163051321,
                "lng": -129.7538142315
            },
            {
                "lat": 53.2111699743,
                "lng": -129.7680673499
            },
            {
                "lat": 53.2028425351,
                "lng": -129.7852024433
            },
            {
                "lat": 53.1889648464,
                "lng": -129.8089603158
            },
            {
                "lat": 53.1956820669,
                "lng": -129.8291009778
            },
            {
                "lat": 53.1970558957,
                "lng": -129.8667750749
            },
            {
                "lat": 53.1892009954,
                "lng": -129.8791504174
            },
            {
                "lat": 53.1806375262,
                "lng": -129.8943788472
            },
            {
                "lat": 53.1803968463,
                "lng": -129.9176940387
            },
            {
                "lat": 53.1768307103,
                "lng": -129.9322055642
            },
            {
                "lat": 53.1725468918,
                "lng": -129.9441064793
            },
            {
                "lat": 53.1676678221,
                "lng": -129.9492185978
            },
            {
                "lat": 53.1658633874,
                "lng": -129.9498748612
            },
            {
                "lat": 53.1614235205,
                "lng": -129.95419254
            },
            {
                "lat": 53.1612512612,
                "lng": -129.9545282558
            },
            {
                "lat": 52.9475557896,
                "lng": -129.6253965745
            },
            {
                "lat": 52.8572846357,
                "lng": -129.5189506928
            },
            {
                "lat": 52.857284728,
                "lng": -129.3527381925
            },
            {
                "lat": 53.0280149636,
                "lng": -129.5334168417
            },
            {
                "lat": 53.1470916812,
                "lng": -129.5689080593
            },
            {
                "lat": 53.2115856278,
                "lng": -129.5868691332
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 171,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 6-9",
            "LABEL": "6-9",
            "FISHERY_AREA_ID": 2546,
            "OBJECTID": 171,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 642930182.7452,
            "FEATURE_LENGTH_M": 126893.7209,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.13764738479092,
            "lng": -129.7709281191818
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.1666677357,
                "lng": -129.965759051
            },
            {
                "lat": 53.1666677123,
                "lng": -130.9999991989
            },
            {
                "lat": 52.84999821,
                "lng": -130.6833351283
            },
            {
                "lat": 52.8499981136,
                "lng": -129.5103605768
            },
            {
                "lat": 52.8572846357,
                "lng": -129.5189506928
            },
            {
                "lat": 52.9475557896,
                "lng": -129.6253965745
            },
            {
                "lat": 53.1612512612,
                "lng": -129.9545282558
            },
            {
                "lat": 53.1666677357,
                "lng": -129.965759051
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 172,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 106,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 106-1",
            "LABEL": "106-1",
            "FISHERY_AREA_ID": 2306,
            "OBJECTID": 172,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2639773037.0308,
            "FEATURE_LENGTH_M": 235789.6297,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.020761399225,
            "lng": -130.02801106613748
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.2180175412,
                "lng": -129.0456538049
            },
            {
                "lat": 53.2160220441,
                "lng": -129.0531013683
            },
            {
                "lat": 53.2158209063,
                "lng": -129.0535740952
            },
            {
                "lat": 53.2156216907,
                "lng": -129.0537108418
            },
            {
                "lat": 53.2121397718,
                "lng": -129.0559694367
            },
            {
                "lat": 53.2117344559,
                "lng": -129.0559232278
            },
            {
                "lat": 53.2109298572,
                "lng": -129.0556181662
            },
            {
                "lat": 53.2079357758,
                "lng": -129.0555274652
            },
            {
                "lat": 53.2022243809,
                "lng": -129.0507654426
            },
            {
                "lat": 53.1927077536,
                "lng": -129.0383910897
            },
            {
                "lat": 53.1923560586,
                "lng": -129.0237884312
            },
            {
                "lat": 53.1839450627,
                "lng": -129.0101004541
            },
            {
                "lat": 53.1722453898,
                "lng": -129.0048525269
            },
            {
                "lat": 53.150829616,
                "lng": -128.9998468474
            },
            {
                "lat": 53.1313172158,
                "lng": -128.9822387617
            },
            {
                "lat": 53.1377408467,
                "lng": -128.9662937317
            },
            {
                "lat": 53.1543998357,
                "lng": -128.9603576998
            },
            {
                "lat": 53.1491626364,
                "lng": -128.9296568633
            },
            {
                "lat": 53.1472589895,
                "lng": -128.8830263853
            },
            {
                "lat": 53.1610601971,
                "lng": -128.8792116511
            },
            {
                "lat": 53.1717680979,
                "lng": -128.8844454668
            },
            {
                "lat": 53.1786688431,
                "lng": -128.9032439342
            },
            {
                "lat": 53.1819999972,
                "lng": -128.9405970446
            },
            {
                "lat": 53.1936609189,
                "lng": -128.9715429863
            },
            {
                "lat": 53.2000846345,
                "lng": -129.0017554125
            },
            {
                "lat": 53.2076983224,
                "lng": -129.0269774196
            },
            {
                "lat": 53.2093653618,
                "lng": -129.0398252685
            },
            {
                "lat": 53.2180175412,
                "lng": -129.0456538049
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 173,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 6-8",
            "LABEL": "6-8",
            "FISHERY_AREA_ID": 2416,
            "OBJECTID": 173,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 42128524.2775,
            "FEATURE_LENGTH_M": 35678.6822,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.1873119193857,
            "lng": -128.99898748672504
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.2051348584,
                "lng": -129.2845151637
            },
            {
                "lat": 53.2065624636,
                "lng": -129.3287654917
            },
            {
                "lat": 53.2056124761,
                "lng": -129.3030700066
            },
            {
                "lat": 53.2051348584,
                "lng": -129.2845151637
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 174,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 174,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 20240.3043,
            "FEATURE_LENGTH_M": 5907.2839,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.205611164125,
            "lng": -129.300216456425
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.1088794552,
                "lng": -131.6457973695
            },
            {
                "lat": 53.1024086555,
                "lng": -131.6509238508
            },
            {
                "lat": 53.0786136985,
                "lng": -131.6502073936
            },
            {
                "lat": 53.056961652,
                "lng": -131.6530605362
            },
            {
                "lat": 53.0581510372,
                "lng": -131.7006532408
            },
            {
                "lat": 53.0772436322,
                "lng": -131.7763527436
            },
            {
                "lat": 53.0774228332,
                "lng": -131.8167729563
            },
            {
                "lat": 53.0755191672,
                "lng": -131.8353272997
            },
            {
                "lat": 53.0693361918,
                "lng": -131.8429405731
            },
            {
                "lat": 53.0649720307,
                "lng": -131.8445890395
            },
            {
                "lat": 53.0638615865,
                "lng": -131.844604552
            },
            {
                "lat": 53.0630606652,
                "lng": -131.8435365163
            },
            {
                "lat": 53.0624845176,
                "lng": -131.8414155039
            },
            {
                "lat": 53.0623932546,
                "lng": -131.8402400953
            },
            {
                "lat": 53.0622025019,
                "lng": -131.8392797841
            },
            {
                "lat": 53.0621417317,
                "lng": -131.8386684233
            },
            {
                "lat": 53.0396339638,
                "lng": -131.8386686195
            },
            {
                "lat": 53.0329472545,
                "lng": -131.8368531902
            },
            {
                "lat": 53.0337904658,
                "lng": -131.8334504795
            },
            {
                "lat": 53.0347555172,
                "lng": -131.8235166747
            },
            {
                "lat": 53.0223581417,
                "lng": -131.7850032112
            },
            {
                "lat": 53.0114595024,
                "lng": -131.7765048744
            },
            {
                "lat": 53.0063439794,
                "lng": -131.7639001408
            },
            {
                "lat": 53.0045164688,
                "lng": -131.7432554005
            },
            {
                "lat": 53.003067173,
                "lng": -131.7250815455
            },
            {
                "lat": 52.9676968342,
                "lng": -131.6152031938
            },
            {
                "lat": 52.9650955818,
                "lng": -131.6122288278
            },
            {
                "lat": 52.9644545203,
                "lng": -131.6077722457
            },
            {
                "lat": 52.9641993513,
                "lng": -131.6058042511
            },
            {
                "lat": 52.9637875447,
                "lng": -131.6039736692
            },
            {
                "lat": 52.9638711224,
                "lng": -131.6033168772
            },
            {
                "lat": 52.9638714092,
                "lng": -131.5482781854
            },
            {
                "lat": 53.1088794552,
                "lng": -131.6457973695
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 175,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 2-3",
            "LABEL": "2-3",
            "FISHERY_AREA_ID": 1894,
            "OBJECTID": 175,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 140965903.138,
            "FEATURE_LENGTH_M": 63008.1397,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.03625396656667,
            "lng": -131.74039329193937
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.0398333406,
                "lng": -132.3615116105
            },
            {
                "lat": 53.0345223439,
                "lng": -132.3600921337
            },
            {
                "lat": 53.0272558248,
                "lng": -132.3600923996
            },
            {
                "lat": 53.0263897916,
                "lng": -132.356277785
            },
            {
                "lat": 53.0266304279,
                "lng": -132.3527071174
            },
            {
                "lat": 53.0271034173,
                "lng": -132.3467561224
            },
            {
                "lat": 53.0287706089,
                "lng": -132.3386689081
            },
            {
                "lat": 53.0311510299,
                "lng": -132.3324739143
            },
            {
                "lat": 53.0361478518,
                "lng": -132.3220057979
            },
            {
                "lat": 53.0370975827,
                "lng": -132.309158964
            },
            {
                "lat": 53.0447120239,
                "lng": -132.2863155416
            },
            {
                "lat": 53.0492327984,
                "lng": -132.2677613706
            },
            {
                "lat": 53.0535170449,
                "lng": -132.2541966008
            },
            {
                "lat": 53.0582767004,
                "lng": -132.2518161759
            },
            {
                "lat": 53.0670813162,
                "lng": -132.258956984
            },
            {
                "lat": 53.0651780004,
                "lng": -132.2708443207
            },
            {
                "lat": 53.068267543,
                "lng": -132.2884520534
            },
            {
                "lat": 53.0668415832,
                "lng": -132.2934574863
            },
            {
                "lat": 53.0535165346,
                "lng": -132.3020177295
            },
            {
                "lat": 53.0489041321,
                "lng": -132.3160248156
            },
            {
                "lat": 53.0525669571,
                "lng": -132.334610805
            },
            {
                "lat": 53.0494723571,
                "lng": -132.34579449
            },
            {
                "lat": 53.0398333406,
                "lng": -132.3615116105
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 176,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 52,
            "MANAGEMENT_AREA_NAME": "Subarea 2-52",
            "LABEL": "2-52",
            "FISHERY_AREA_ID": 1943,
            "OBJECTID": 176,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 14289997.5181,
            "FEATURE_LENGTH_M": 19631.5243,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.04488271962174,
            "lng": -132.31615237986088
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.047446736,
                "lng": -132.5234221481
            },
            {
                "lat": 53.0474515202,
                "lng": -132.5274666146
            },
            {
                "lat": 53.0462802275,
                "lng": -132.5322115558
            },
            {
                "lat": 53.0454480107,
                "lng": -132.5329734711
            },
            {
                "lat": 53.0453758122,
                "lng": -132.5334633195
            },
            {
                "lat": 53.0194244945,
                "lng": -132.5014192371
            },
            {
                "lat": 53.0194245863,
                "lng": -132.4228209779
            },
            {
                "lat": 53.0216029049,
                "lng": -132.4213868308
            },
            {
                "lat": 53.0230938721,
                "lng": -132.4170835332
            },
            {
                "lat": 53.0279312371,
                "lng": -132.4170841008
            },
            {
                "lat": 53.0315059957,
                "lng": -132.4156339913
            },
            {
                "lat": 53.0441174483,
                "lng": -132.4184877151
            },
            {
                "lat": 53.0491141734,
                "lng": -132.4294437469
            },
            {
                "lat": 53.04887764,
                "lng": -132.4422913452
            },
            {
                "lat": 53.0455471527,
                "lng": -132.4503783071
            },
            {
                "lat": 53.0405505406,
                "lng": -132.4639429501
            },
            {
                "lat": 53.0369915356,
                "lng": -132.47749288
            },
            {
                "lat": 53.0418623137,
                "lng": -132.5099950953
            },
            {
                "lat": 53.044357219,
                "lng": -132.5146172407
            },
            {
                "lat": 53.047446736,
                "lng": -132.5234221481
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 177,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 50,
            "MANAGEMENT_AREA_NAME": "Subarea 2-50",
            "LABEL": "2-50",
            "FISHERY_AREA_ID": 1941,
            "OBJECTID": 321,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 17630740.9774,
            "FEATURE_LENGTH_M": 20413.9565,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.03869250782499,
            "lng": -132.47375186043502
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.0315014171,
                "lng": -131.9677423401
            },
            {
                "lat": 53.0286865604,
                "lng": -131.9565583831
            },
            {
                "lat": 53.0143244717,
                "lng": -131.9328154388
            },
            {
                "lat": 53.0113723226,
                "lng": -131.923004093
            },
            {
                "lat": 53.0090066297,
                "lng": -131.9105690771
            },
            {
                "lat": 53.0153080308,
                "lng": -131.8889771963
            },
            {
                "lat": 53.0275958057,
                "lng": -131.8647310813
            },
            {
                "lat": 53.0329475422,
                "lng": -131.845504595
            },
            {
                "lat": 53.0329472545,
                "lng": -131.8368531902
            },
            {
                "lat": 53.0396339638,
                "lng": -131.8386686195
            },
            {
                "lat": 53.0621417317,
                "lng": -131.8386684233
            },
            {
                "lat": 53.0622025019,
                "lng": -131.8392797841
            },
            {
                "lat": 53.0623932546,
                "lng": -131.8402400953
            },
            {
                "lat": 53.0624845176,
                "lng": -131.8414155039
            },
            {
                "lat": 53.0630606652,
                "lng": -131.8435365163
            },
            {
                "lat": 53.0638615865,
                "lng": -131.844604552
            },
            {
                "lat": 53.065288613,
                "lng": -131.8488923517
            },
            {
                "lat": 53.0671920666,
                "lng": -131.8567352368
            },
            {
                "lat": 53.0700489997,
                "lng": -131.8674469935
            },
            {
                "lat": 53.072665974,
                "lng": -131.8800659108
            },
            {
                "lat": 53.0707624607,
                "lng": -131.889342722
            },
            {
                "lat": 53.0645752207,
                "lng": -131.9050447367
            },
            {
                "lat": 53.060291084,
                "lng": -131.9209898391
            },
            {
                "lat": 53.0583876336,
                "lng": -131.9357447686
            },
            {
                "lat": 53.0593408354,
                "lng": -131.9521642815
            },
            {
                "lat": 53.0617218647,
                "lng": -131.975952549
            },
            {
                "lat": 53.0650523109,
                "lng": -131.988082611
            },
            {
                "lat": 53.0657649532,
                "lng": -132.0021211968
            },
            {
                "lat": 53.0640990627,
                "lng": -132.0247352609
            },
            {
                "lat": 53.059818561,
                "lng": -132.0356746124
            },
            {
                "lat": 53.0536307115,
                "lng": -132.0399634998
            },
            {
                "lat": 53.0453036088,
                "lng": -132.0392456435
            },
            {
                "lat": 53.0362582858,
                "lng": -132.0363924833
            },
            {
                "lat": 53.0336422108,
                "lng": -132.0304416385
            },
            {
                "lat": 53.0343549769,
                "lng": -132.0161586846
            },
            {
                "lat": 53.0343549391,
                "lng": -131.9992681318
            },
            {
                "lat": 53.0341183199,
                "lng": -131.9866635499
            },
            {
                "lat": 53.0355455221,
                "lng": -131.9773861178
            },
            {
                "lat": 53.0362580493,
                "lng": -131.9719095594
            },
            {
                "lat": 53.0343547339,
                "lng": -131.9695286283
            },
            {
                "lat": 53.0315014171,
                "lng": -131.9677423401
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 178,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 2-4",
            "LABEL": "2-4",
            "FISHERY_AREA_ID": 1895,
            "OBJECTID": 322,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 57339638.0541,
            "FEATURE_LENGTH_M": 35493.3615,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.04716587003414,
            "lng": -131.93002103017315
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.0315059957,
                "lng": -132.4156339913
            },
            {
                "lat": 53.0279312371,
                "lng": -132.4170841008
            },
            {
                "lat": 53.0230938721,
                "lng": -132.4170835332
            },
            {
                "lat": 53.0072255865,
                "lng": -132.4020379024
            },
            {
                "lat": 53.0192267028,
                "lng": -132.3553005908
            },
            {
                "lat": 53.0202978287,
                "lng": -132.3549653716
            },
            {
                "lat": 53.0216106393,
                "lng": -132.3535920943
            },
            {
                "lat": 53.0233688211,
                "lng": -132.3527523675
            },
            {
                "lat": 53.025101155,
                "lng": -132.3539121801
            },
            {
                "lat": 53.0263897916,
                "lng": -132.356277785
            },
            {
                "lat": 53.0272558248,
                "lng": -132.3600923996
            },
            {
                "lat": 53.0345223439,
                "lng": -132.3600921337
            },
            {
                "lat": 53.0398333406,
                "lng": -132.3615116105
            },
            {
                "lat": 53.0398333322,
                "lng": -132.369354926
            },
            {
                "lat": 53.0372163387,
                "lng": -132.3829197243
            },
            {
                "lat": 53.0353130927,
                "lng": -132.3972013908
            },
            {
                "lat": 53.033172247,
                "lng": -132.4083861496
            },
            {
                "lat": 53.0315059957,
                "lng": -132.4156339913
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 179,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 51,
            "MANAGEMENT_AREA_NAME": "Subarea 2-51",
            "LABEL": "2-51",
            "FISHERY_AREA_ID": 1942,
            "OBJECTID": 323,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 10131932.9075,
            "FEATURE_LENGTH_M": 12757.1842,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.02802245252778,
            "lng": -132.37965734682223
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.0793692153,
                "lng": -129.2772369747
            },
            {
                "lat": 53.0463791232,
                "lng": -129.316817573
            },
            {
                "lat": 53.0417285569,
                "lng": -129.3222967508
            },
            {
                "lat": 53.0334013275,
                "lng": -129.3372801608
            },
            {
                "lat": 53.0324512468,
                "lng": -129.349899264
            },
            {
                "lat": 53.0757557955,
                "lng": -129.4122314592
            },
            {
                "lat": 53.0931817706,
                "lng": -129.4339748768
            },
            {
                "lat": 53.1209295091,
                "lng": -129.468597503
            },
            {
                "lat": 53.131873532,
                "lng": -129.4723968202
            },
            {
                "lat": 53.1402017318,
                "lng": -129.4833522917
            },
            {
                "lat": 53.1449622973,
                "lng": -129.5007171701
            },
            {
                "lat": 53.1499601464,
                "lng": -129.5268861337
            },
            {
                "lat": 53.1542709011,
                "lng": -129.5503839142
            },
            {
                "lat": 53.1553800114,
                "lng": -129.5546717754
            },
            {
                "lat": 53.1532592607,
                "lng": -129.5597234447
            },
            {
                "lat": 53.1516454874,
                "lng": -129.5612333781
            },
            {
                "lat": 53.1506351336,
                "lng": -129.561126763
            },
            {
                "lat": 53.1498297582,
                "lng": -129.5609745873
            },
            {
                "lat": 53.1496279531,
                "lng": -129.561584917
            },
            {
                "lat": 53.1500288322,
                "lng": -129.5626519924
            },
            {
                "lat": 53.1499298792,
                "lng": -129.5640558149
            },
            {
                "lat": 53.1495246194,
                "lng": -129.5656279125
            },
            {
                "lat": 53.1481137322,
                "lng": -129.5676421754
            },
            {
                "lat": 53.1470916812,
                "lng": -129.5689080593
            },
            {
                "lat": 53.0280149636,
                "lng": -129.5334168417
            },
            {
                "lat": 52.857284728,
                "lng": -129.3527381925
            },
            {
                "lat": 52.8701710547,
                "lng": -129.1369170493
            },
            {
                "lat": 52.8716501827,
                "lng": -129.1343680319
            },
            {
                "lat": 52.870292814,
                "lng": -129.1293026644
            },
            {
                "lat": 52.8764757368,
                "lng": -129.1226344616
            },
            {
                "lat": 52.8819544147,
                "lng": -129.1266475947
            },
            {
                "lat": 52.9159045487,
                "lng": -129.157562015
            },
            {
                "lat": 52.9171071634,
                "lng": -129.1578060063
            },
            {
                "lat": 52.9177824017,
                "lng": -129.159560392
            },
            {
                "lat": 52.9201433765,
                "lng": -129.1645513936
            },
            {
                "lat": 52.9217603935,
                "lng": -129.16644323
            },
            {
                "lat": 52.9283715598,
                "lng": -129.1664428732
            },
            {
                "lat": 52.9354520108,
                "lng": -129.1682589313
            },
            {
                "lat": 52.9462973426,
                "lng": -129.1629029139
            },
            {
                "lat": 53.0140043102,
                "lng": -129.1747736674
            },
            {
                "lat": 53.0254327005,
                "lng": -129.1718896906
            },
            {
                "lat": 53.0404246571,
                "lng": -129.1561894557
            },
            {
                "lat": 53.0599326613,
                "lng": -129.1450043704
            },
            {
                "lat": 53.0670731167,
                "lng": -129.1661685979
            },
            {
                "lat": 53.0671150787,
                "lng": -129.1816251982
            },
            {
                "lat": 53.0670781981,
                "lng": -129.1837621892
            },
            {
                "lat": 53.0675202296,
                "lng": -129.1841733712
            },
            {
                "lat": 53.0771221142,
                "lng": -129.1983033139
            },
            {
                "lat": 53.0793692153,
                "lng": -129.2772369747
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 180,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 6-10",
            "LABEL": "6-10",
            "FISHERY_AREA_ID": 2417,
            "OBJECTID": 324,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 523308856.3764,
            "FEATURE_LENGTH_M": 112567.3608,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.043331968883685,
            "lng": -129.33303981903674
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.0230938721,
                "lng": -132.4170835332
            },
            {
                "lat": 53.0216029049,
                "lng": -132.4213868308
            },
            {
                "lat": 53.0194245863,
                "lng": -132.4228209779
            },
            {
                "lat": 53.0194244945,
                "lng": -132.5014192371
            },
            {
                "lat": 52.9265334363,
                "lng": -132.3867945115
            },
            {
                "lat": 52.9265289071,
                "lng": -132.3645777552
            },
            {
                "lat": 52.9272420483,
                "lng": -132.3646846512
            },
            {
                "lat": 52.9281465189,
                "lng": -132.3643338252
            },
            {
                "lat": 52.9324728425,
                "lng": -132.3571317602
            },
            {
                "lat": 52.941364826,
                "lng": -132.3377234904
            },
            {
                "lat": 52.9482643015,
                "lng": -132.3248749575
            },
            {
                "lat": 52.9532471883,
                "lng": -132.3275147295
            },
            {
                "lat": 52.9635309928,
                "lng": -132.3275151449
            },
            {
                "lat": 52.9672923523,
                "lng": -132.325271299
            },
            {
                "lat": 52.976257641,
                "lng": -132.3111118745
            },
            {
                "lat": 52.9891623229,
                "lng": -132.3268732182
            },
            {
                "lat": 53.0026398389,
                "lng": -132.3267674373
            },
            {
                "lat": 53.0072104099,
                "lng": -132.333221348
            },
            {
                "lat": 53.008121901,
                "lng": -132.3355251188
            },
            {
                "lat": 53.0192267028,
                "lng": -132.3553005908
            },
            {
                "lat": 53.0072255865,
                "lng": -132.4020379024
            },
            {
                "lat": 53.0230938721,
                "lng": -132.4170835332
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 181,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 49,
            "MANAGEMENT_AREA_NAME": "Subarea 2-49",
            "LABEL": "2-49",
            "FISHERY_AREA_ID": 1940,
            "OBJECTID": 325,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 75281565.194,
            "FEATURE_LENGTH_M": 38854.3315,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.978686706677266,
            "lng": -132.36595698758185
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.0216106393,
                "lng": -132.3535920943
            },
            {
                "lat": 53.0202978287,
                "lng": -132.3549653716
            },
            {
                "lat": 53.0192267028,
                "lng": -132.3553005908
            },
            {
                "lat": 53.008121901,
                "lng": -132.3355251188
            },
            {
                "lat": 53.0072104099,
                "lng": -132.333221348
            },
            {
                "lat": 53.0026398389,
                "lng": -132.3267674373
            },
            {
                "lat": 52.9990997964,
                "lng": -132.312652065
            },
            {
                "lat": 52.9982415433,
                "lng": -132.2979737477
            },
            {
                "lat": 52.9971579724,
                "lng": -132.2855833955
            },
            {
                "lat": 52.9964706667,
                "lng": -132.2781069792
            },
            {
                "lat": 52.9960169879,
                "lng": -132.2701117881
            },
            {
                "lat": 52.99327394,
                "lng": -132.2568057375
            },
            {
                "lat": 52.9927023696,
                "lng": -132.2525784265
            },
            {
                "lat": 52.9940682053,
                "lng": -132.2501832896
            },
            {
                "lat": 52.99771881,
                "lng": -132.2521660585
            },
            {
                "lat": 53.010711767,
                "lng": -132.2521665601
            },
            {
                "lat": 53.0105327751,
                "lng": -132.2502744418
            },
            {
                "lat": 53.0103451849,
                "lng": -132.2466587301
            },
            {
                "lat": 53.0099062263,
                "lng": -132.2409201751
            },
            {
                "lat": 53.0105327158,
                "lng": -132.2375490504
            },
            {
                "lat": 53.0122789267,
                "lng": -132.2353658698
            },
            {
                "lat": 53.015400341,
                "lng": -132.2347408716
            },
            {
                "lat": 53.0177689247,
                "lng": -132.2396705194
            },
            {
                "lat": 53.0218892042,
                "lng": -132.2513283361
            },
            {
                "lat": 53.0248447796,
                "lng": -132.2667994548
            },
            {
                "lat": 53.0250813352,
                "lng": -132.2765658133
            },
            {
                "lat": 53.0253223222,
                "lng": -132.2924958542
            },
            {
                "lat": 53.0255585788,
                "lng": -132.3065329229
            },
            {
                "lat": 53.0286515988,
                "lng": -132.3184352682
            },
            {
                "lat": 53.0281757785,
                "lng": -132.3258056141
            },
            {
                "lat": 53.0250810107,
                "lng": -132.3379518194
            },
            {
                "lat": 53.0234139475,
                "lng": -132.3473508347
            },
            {
                "lat": 53.0230485609,
                "lng": -132.351120014
            },
            {
                "lat": 53.0216106393,
                "lng": -132.3535920943
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 182,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 53,
            "MANAGEMENT_AREA_NAME": "Subarea 2-53",
            "LABEL": "2-53",
            "FISHERY_AREA_ID": 1944,
            "OBJECTID": 326,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 19664420.8946,
            "FEATURE_LENGTH_M": 20242.8795,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.01217683027647,
            "lng": -132.29061346155
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.0090066297,
                "lng": -131.9105690771
            },
            {
                "lat": 53.0113723226,
                "lng": -131.923004093
            },
            {
                "lat": 53.0143244717,
                "lng": -131.9328154388
            },
            {
                "lat": 53.0286865604,
                "lng": -131.9565583831
            },
            {
                "lat": 53.0315014171,
                "lng": -131.9677423401
            },
            {
                "lat": 53.0269808699,
                "lng": -131.9732212893
            },
            {
                "lat": 53.0167505874,
                "lng": -131.9744112006
            },
            {
                "lat": 53.006515094,
                "lng": -131.9705961422
            },
            {
                "lat": 52.9953344914,
                "lng": -131.9563288346
            },
            {
                "lat": 52.9618759287,
                "lng": -131.9279329028
            },
            {
                "lat": 52.9440574832,
                "lng": -131.9151612714
            },
            {
                "lat": 52.9471016603,
                "lng": -131.9035641155
            },
            {
                "lat": 52.9470977962,
                "lng": -131.9026339062
            },
            {
                "lat": 52.9471738468,
                "lng": -131.9024967044
            },
            {
                "lat": 52.9474179577,
                "lng": -131.9022818553
            },
            {
                "lat": 52.9476547857,
                "lng": -131.9015964734
            },
            {
                "lat": 52.9501003538,
                "lng": -131.8970183951
            },
            {
                "lat": 52.9694709275,
                "lng": -131.9029995719
            },
            {
                "lat": 52.986640724,
                "lng": -131.9089811362
            },
            {
                "lat": 53.0090066297,
                "lng": -131.9105690771
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 183,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 2-5",
            "LABEL": "2-5",
            "FISHERY_AREA_ID": 1896,
            "OBJECTID": 327,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 22645776.6044,
            "FEATURE_LENGTH_M": 23609.4883,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.984903526889994,
            "lng": -131.92702411040503
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.1338500743,
                "lng": -128.5773163413
            },
            {
                "lat": 53.1282310911,
                "lng": -128.5789488225
            },
            {
                "lat": 53.1187129924,
                "lng": -128.5693965021
            },
            {
                "lat": 53.1153451843,
                "lng": -128.564484278
            },
            {
                "lat": 53.1189957008,
                "lng": -128.5576015448
            },
            {
                "lat": 53.1184845638,
                "lng": -128.5396571646
            },
            {
                "lat": 53.1177139524,
                "lng": -128.5237888648
            },
            {
                "lat": 53.118995575,
                "lng": -128.5081635722
            },
            {
                "lat": 53.1215557179,
                "lng": -128.5014949571
            },
            {
                "lat": 53.1310354272,
                "lng": -128.4748533955
            },
            {
                "lat": 53.1410252576,
                "lng": -128.466415261
            },
            {
                "lat": 53.1512683648,
                "lng": -128.4692236589
            },
            {
                "lat": 53.1551137764,
                "lng": -128.4845882519
            },
            {
                "lat": 53.1497341528,
                "lng": -128.5004726291
            },
            {
                "lat": 53.1394882893,
                "lng": -128.5163580591
            },
            {
                "lat": 53.1407663311,
                "lng": -128.5314636191
            },
            {
                "lat": 53.1443519313,
                "lng": -128.5583650899
            },
            {
                "lat": 53.1379505715,
                "lng": -128.5675819084
            },
            {
                "lat": 53.1338500743,
                "lng": -128.5773163413
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 184,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 22,
            "MANAGEMENT_AREA_NAME": "Subarea 6-22",
            "LABEL": "6-22",
            "FISHERY_AREA_ID": 2429,
            "OBJECTID": 328,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 18938743.0349,
            "FEATURE_LENGTH_M": 19794.1959,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.13244573833156,
            "lng": -128.52986790850525
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.015400341,
                "lng": -132.2347408716
            },
            {
                "lat": 53.0122789267,
                "lng": -132.2353658698
            },
            {
                "lat": 53.0105327158,
                "lng": -132.2375490504
            },
            {
                "lat": 53.0099062263,
                "lng": -132.2409201751
            },
            {
                "lat": 53.0103451849,
                "lng": -132.2466587301
            },
            {
                "lat": 53.0105327751,
                "lng": -132.2502744418
            },
            {
                "lat": 53.010711767,
                "lng": -132.2521665601
            },
            {
                "lat": 52.99771881,
                "lng": -132.2521660585
            },
            {
                "lat": 52.9940682053,
                "lng": -132.2501832896
            },
            {
                "lat": 52.9872405425,
                "lng": -132.2212987707
            },
            {
                "lat": 52.9807584615,
                "lng": -132.1995398406
            },
            {
                "lat": 52.9799085922,
                "lng": -132.1897432661
            },
            {
                "lat": 52.9951405095,
                "lng": -132.1897583735
            },
            {
                "lat": 53.0007398954,
                "lng": -132.1884307181
            },
            {
                "lat": 53.0058131103,
                "lng": -132.2021026096
            },
            {
                "lat": 53.0127794901,
                "lng": -132.2243198805
            },
            {
                "lat": 53.015400341,
                "lng": -132.2347408716
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 185,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 54,
            "MANAGEMENT_AREA_NAME": "Subarea 2-54",
            "LABEL": "2-54",
            "FISHERY_AREA_ID": 1945,
            "OBJECTID": 329,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 10487041.2469,
            "FEATURE_LENGTH_M": 13678.481,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.002898582035286,
            "lng": -132.22646819868825
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.0329472545,
                "lng": -131.8368531902
            },
            {
                "lat": 53.0329475422,
                "lng": -131.845504595
            },
            {
                "lat": 53.0275958057,
                "lng": -131.8647310813
            },
            {
                "lat": 53.0153080308,
                "lng": -131.8889771963
            },
            {
                "lat": 53.0090066297,
                "lng": -131.9105690771
            },
            {
                "lat": 52.986640724,
                "lng": -131.9089811362
            },
            {
                "lat": 52.9694709275,
                "lng": -131.9029995719
            },
            {
                "lat": 52.9501003538,
                "lng": -131.8970183951
            },
            {
                "lat": 52.9449314742,
                "lng": -131.888641934
            },
            {
                "lat": 52.9418942105,
                "lng": -131.8850552448
            },
            {
                "lat": 52.8841089655,
                "lng": -131.6898802644
            },
            {
                "lat": 52.8836747456,
                "lng": -131.6810000583
            },
            {
                "lat": 52.8887131777,
                "lng": -131.679321028
            },
            {
                "lat": 52.9013248626,
                "lng": -131.6702879087
            },
            {
                "lat": 52.9110837743,
                "lng": -131.6588590687
            },
            {
                "lat": 52.9146494739,
                "lng": -131.648635184
            },
            {
                "lat": 52.9151655683,
                "lng": -131.6414190064
            },
            {
                "lat": 52.9255332992,
                "lng": -131.6333167672
            },
            {
                "lat": 52.9315450295,
                "lng": -131.6372067334
            },
            {
                "lat": 52.9367790028,
                "lng": -131.6455384814
            },
            {
                "lat": 52.9441563312,
                "lng": -131.6519624543
            },
            {
                "lat": 52.9496309638,
                "lng": -131.6481476865
            },
            {
                "lat": 52.9562916413,
                "lng": -131.6357875766
            },
            {
                "lat": 52.9593844897,
                "lng": -131.6250760594
            },
            {
                "lat": 52.9650955818,
                "lng": -131.6122288278
            },
            {
                "lat": 52.9676968342,
                "lng": -131.6152031938
            },
            {
                "lat": 53.003067173,
                "lng": -131.7250815455
            },
            {
                "lat": 53.0045164688,
                "lng": -131.7432554005
            },
            {
                "lat": 53.0063439794,
                "lng": -131.7639001408
            },
            {
                "lat": 53.0114595024,
                "lng": -131.7765048744
            },
            {
                "lat": 53.0223581417,
                "lng": -131.7850032112
            },
            {
                "lat": 53.0347555172,
                "lng": -131.8235166747
            },
            {
                "lat": 53.0337904658,
                "lng": -131.8334504795
            },
            {
                "lat": 53.0329472545,
                "lng": -131.8368531902
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 186,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 330,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 193157212.9198,
            "FEATURE_LENGTH_M": 58430.4137,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.96749750579705,
            "lng": -131.74972844816472
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.9940682053,
                "lng": -132.2501832896
            },
            {
                "lat": 52.9927023696,
                "lng": -132.2525784265
            },
            {
                "lat": 52.99327394,
                "lng": -132.2568057375
            },
            {
                "lat": 52.9960169879,
                "lng": -132.2701117881
            },
            {
                "lat": 52.9964706667,
                "lng": -132.2781069792
            },
            {
                "lat": 52.9971579724,
                "lng": -132.2855833955
            },
            {
                "lat": 52.9982415433,
                "lng": -132.2979737477
            },
            {
                "lat": 52.9990997964,
                "lng": -132.312652065
            },
            {
                "lat": 53.0026398389,
                "lng": -132.3267674373
            },
            {
                "lat": 52.9891623229,
                "lng": -132.3268732182
            },
            {
                "lat": 52.976257641,
                "lng": -132.3111118745
            },
            {
                "lat": 52.9732893563,
                "lng": -132.3055578897
            },
            {
                "lat": 52.9785800473,
                "lng": -132.276015766
            },
            {
                "lat": 52.9804835874,
                "lng": -132.2443700648
            },
            {
                "lat": 52.9807198965,
                "lng": -132.2148737856
            },
            {
                "lat": 52.9807584615,
                "lng": -132.1995398406
            },
            {
                "lat": 52.9872405425,
                "lng": -132.2212987707
            },
            {
                "lat": 52.9940682053,
                "lng": -132.2501832896
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 187,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 331,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 13556656.966,
            "FEATURE_LENGTH_M": 20044.0416,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.98945729895556,
            "lng": -132.2711437425611
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.9865990362,
                "lng": -132.1481626943
            },
            {
                "lat": 52.9806627262,
                "lng": -132.1274566564
            },
            {
                "lat": 52.9716219693,
                "lng": -132.112717346
            },
            {
                "lat": 52.9592473224,
                "lng": -132.0863035016
            },
            {
                "lat": 52.955203905,
                "lng": -132.0732110887
            },
            {
                "lat": 52.9578747691,
                "lng": -132.0653983761
            },
            {
                "lat": 52.9621004289,
                "lng": -132.0646510736
            },
            {
                "lat": 52.9667244226,
                "lng": -132.0651088893
            },
            {
                "lat": 52.9730795108,
                "lng": -132.0692439727
            },
            {
                "lat": 52.9894671589,
                "lng": -132.0967715245
            },
            {
                "lat": 52.9970818237,
                "lng": -132.1193692939
            },
            {
                "lat": 53.0030287256,
                "lng": -132.1303092143
            },
            {
                "lat": 53.0035058531,
                "lng": -132.1414950665
            },
            {
                "lat": 53.0044594407,
                "lng": -132.1486359574
            },
            {
                "lat": 52.9999087707,
                "lng": -132.1540225005
            },
            {
                "lat": 52.9953158872,
                "lng": -132.1540218008
            },
            {
                "lat": 52.9888187248,
                "lng": -132.1540219281
            },
            {
                "lat": 52.9884834503,
                "lng": -132.1529384722
            },
            {
                "lat": 52.9865990362,
                "lng": -132.1481626943
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 188,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 56,
            "MANAGEMENT_AREA_NAME": "Subarea 2-56",
            "LABEL": "2-56",
            "FISHERY_AREA_ID": 1947,
            "OBJECTID": 332,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 14007503.3944,
            "FEATURE_LENGTH_M": 17421.8265,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.982620155878955,
            "lng": -132.11642116058948
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.0007398954,
                "lng": -132.1884307181
            },
            {
                "lat": 52.9951405095,
                "lng": -132.1897583735
            },
            {
                "lat": 52.9799085922,
                "lng": -132.1897432661
            },
            {
                "lat": 52.9669071164,
                "lng": -132.190826772
            },
            {
                "lat": 52.96709013,
                "lng": -132.1881099299
            },
            {
                "lat": 52.9663275485,
                "lng": -132.1796868095
            },
            {
                "lat": 52.9679913759,
                "lng": -132.1735078431
            },
            {
                "lat": 52.9706035937,
                "lng": -132.1675112475
            },
            {
                "lat": 52.9765967181,
                "lng": -132.166198426
            },
            {
                "lat": 52.9781681815,
                "lng": -132.1569360283
            },
            {
                "lat": 52.9780877716,
                "lng": -132.1494147067
            },
            {
                "lat": 52.9865990362,
                "lng": -132.1481626943
            },
            {
                "lat": 52.9884834503,
                "lng": -132.1529384722
            },
            {
                "lat": 52.9888187248,
                "lng": -132.1540219281
            },
            {
                "lat": 52.9953158872,
                "lng": -132.1540218008
            },
            {
                "lat": 52.9999087707,
                "lng": -132.1540225005
            },
            {
                "lat": 52.9991917841,
                "lng": -132.1593935369
            },
            {
                "lat": 52.9984745973,
                "lng": -132.1673272264
            },
            {
                "lat": 52.9988934064,
                "lng": -132.1777034051
            },
            {
                "lat": 53.0007398954,
                "lng": -132.1884307181
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 189,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 55,
            "MANAGEMENT_AREA_NAME": "Subarea 2-55",
            "LABEL": "2-55",
            "FISHERY_AREA_ID": 1946,
            "OBJECTID": 333,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 7903255.1368,
            "FEATURE_LENGTH_M": 12314.8126,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.98519934926001,
            "lng": -132.169807320155
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.1047360623,
                "lng": -129.2772064867
            },
            {
                "lat": 53.0845799469,
                "lng": -129.2782436504
            },
            {
                "lat": 53.0793692153,
                "lng": -129.2772369747
            },
            {
                "lat": 53.0771221142,
                "lng": -129.1983033139
            },
            {
                "lat": 53.0913424593,
                "lng": -129.176040108
            },
            {
                "lat": 53.0919149725,
                "lng": -129.1753537404
            },
            {
                "lat": 53.09704218,
                "lng": -129.1736444643
            },
            {
                "lat": 53.1098513449,
                "lng": -129.1666715515
            },
            {
                "lat": 53.1181795003,
                "lng": -129.2002254848
            },
            {
                "lat": 53.1093754514,
                "lng": -129.2152093697
            },
            {
                "lat": 53.1053310417,
                "lng": -129.245437523
            },
            {
                "lat": 53.1119922502,
                "lng": -129.2592314659
            },
            {
                "lat": 53.1096151099,
                "lng": -129.2730414191
            },
            {
                "lat": 53.1047360623,
                "lng": -129.2772064867
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 190,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 26,
            "MANAGEMENT_AREA_NAME": "Subarea 6-26",
            "LABEL": "6-26",
            "FISHERY_AREA_ID": 2433,
            "OBJECTID": 334,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 24372053.8529,
            "FEATURE_LENGTH_M": 21110.5069,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.09965626508572,
            "lng": -129.22807514565002
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.9799085922,
                "lng": -132.1897432661
            },
            {
                "lat": 52.9807584615,
                "lng": -132.1995398406
            },
            {
                "lat": 52.9807198965,
                "lng": -132.2148737856
            },
            {
                "lat": 52.9804835874,
                "lng": -132.2443700648
            },
            {
                "lat": 52.9785800473,
                "lng": -132.276015766
            },
            {
                "lat": 52.9732893563,
                "lng": -132.3055578897
            },
            {
                "lat": 52.976257641,
                "lng": -132.3111118745
            },
            {
                "lat": 52.9672923523,
                "lng": -132.325271299
            },
            {
                "lat": 52.9635309928,
                "lng": -132.3275151449
            },
            {
                "lat": 52.9532471883,
                "lng": -132.3275147295
            },
            {
                "lat": 52.9482643015,
                "lng": -132.3248749575
            },
            {
                "lat": 52.9455902176,
                "lng": -132.3110806286
            },
            {
                "lat": 52.9453508369,
                "lng": -132.2915647026
            },
            {
                "lat": 52.9496349307,
                "lng": -132.2722931202
            },
            {
                "lat": 52.9530794357,
                "lng": -132.2579197626
            },
            {
                "lat": 52.958785861,
                "lng": -132.2639315332
            },
            {
                "lat": 52.9605869903,
                "lng": -132.2303311636
            },
            {
                "lat": 52.9606928752,
                "lng": -132.2211145117
            },
            {
                "lat": 52.965305142,
                "lng": -132.2101137414
            },
            {
                "lat": 52.9693033428,
                "lng": -132.2129672384
            },
            {
                "lat": 52.9669071164,
                "lng": -132.190826772
            },
            {
                "lat": 52.9799085922,
                "lng": -132.1897432661
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 191,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 60,
            "MANAGEMENT_AREA_NAME": "Subarea 2-60",
            "LABEL": "2-60",
            "FISHERY_AREA_ID": 1951,
            "OBJECTID": 335,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 23196855.5875,
            "FEATURE_LENGTH_M": 24590.9167,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.965339898086384,
            "lng": -132.25901250266364
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.098895361,
                "lng": -128.5546270037
            },
            {
                "lat": 53.0876496931,
                "lng": -128.5563513366
            },
            {
                "lat": 53.0673478533,
                "lng": -128.5508571784
            },
            {
                "lat": 53.0679056256,
                "lng": -128.5399637157
            },
            {
                "lat": 53.0787276868,
                "lng": -128.5289459781
            },
            {
                "lat": 53.0806463586,
                "lng": -128.5169374008
            },
            {
                "lat": 53.0828093545,
                "lng": -128.4938815934
            },
            {
                "lat": 53.0808906981,
                "lng": -128.4621884273
            },
            {
                "lat": 53.0700837419,
                "lng": -128.4139398076
            },
            {
                "lat": 53.0729675663,
                "lng": -128.3985745087
            },
            {
                "lat": 53.0921746919,
                "lng": -128.3944846957
            },
            {
                "lat": 53.0991364191,
                "lng": -128.4055324526
            },
            {
                "lat": 53.1053774063,
                "lng": -128.4225766417
            },
            {
                "lat": 53.1120979872,
                "lng": -128.4444273952
            },
            {
                "lat": 53.1135405521,
                "lng": -128.4669957768
            },
            {
                "lat": 53.1058580504,
                "lng": -128.4950864284
            },
            {
                "lat": 53.1027372501,
                "lng": -128.5337373502
            },
            {
                "lat": 53.098895361,
                "lng": -128.5546270037
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 192,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 23,
            "MANAGEMENT_AREA_NAME": "Subarea 6-23",
            "LABEL": "6-23",
            "FISHERY_AREA_ID": 2430,
            "OBJECTID": 336,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 34422453.2824,
            "FEATURE_LENGTH_M": 28592.2393,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.08987453651667,
            "lng": -128.48520748303335
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.9780877716,
                "lng": -132.1494147067
            },
            {
                "lat": 52.9781681815,
                "lng": -132.1569360283
            },
            {
                "lat": 52.9765967181,
                "lng": -132.166198426
            },
            {
                "lat": 52.9706035937,
                "lng": -132.1675112475
            },
            {
                "lat": 52.9672776308,
                "lng": -132.1602940127
            },
            {
                "lat": 52.9599037812,
                "lng": -132.1522065223
            },
            {
                "lat": 52.9540715418,
                "lng": -132.1436461166
            },
            {
                "lat": 52.9514541468,
                "lng": -132.1335300053
            },
            {
                "lat": 52.9516942498,
                "lng": -132.1255649831
            },
            {
                "lat": 52.9533575913,
                "lng": -132.1167599507
            },
            {
                "lat": 52.9560970668,
                "lng": -132.1122282315
            },
            {
                "lat": 52.9575881746,
                "lng": -132.1093138493
            },
            {
                "lat": 52.9589038507,
                "lng": -132.1078796806
            },
            {
                "lat": 52.9612426323,
                "lng": -132.1077727345
            },
            {
                "lat": 52.9645578584,
                "lng": -132.110106769
            },
            {
                "lat": 52.9659689517,
                "lng": -132.1130672331
            },
            {
                "lat": 52.9670410344,
                "lng": -132.1174616011
            },
            {
                "lat": 52.9660878193,
                "lng": -132.1244804127
            },
            {
                "lat": 52.9662098855,
                "lng": -132.1299593452
            },
            {
                "lat": 52.9702529153,
                "lng": -132.1386413175
            },
            {
                "lat": 52.9744186504,
                "lng": -132.1444706974
            },
            {
                "lat": 52.9780877716,
                "lng": -132.1494147067
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 193,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 57,
            "MANAGEMENT_AREA_NAME": "Subarea 2-57",
            "LABEL": "2-57",
            "FISHERY_AREA_ID": 1948,
            "OBJECTID": 337,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 6308060.5084,
            "FEATURE_LENGTH_M": 10994.2112,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.96489417352728,
            "lng": -132.13349357171816
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.9669071164,
                "lng": -132.190826772
            },
            {
                "lat": 52.9693033428,
                "lng": -132.2129672384
            },
            {
                "lat": 52.965305142,
                "lng": -132.2101137414
            },
            {
                "lat": 52.9627956392,
                "lng": -132.2086335597
            },
            {
                "lat": 52.9577978967,
                "lng": -132.2010190912
            },
            {
                "lat": 52.9508965938,
                "lng": -132.188889075
            },
            {
                "lat": 52.9459000475,
                "lng": -132.1777036571
            },
            {
                "lat": 52.938762516,
                "lng": -132.1658022338
            },
            {
                "lat": 52.9328149293,
                "lng": -132.1555785102
            },
            {
                "lat": 52.9282947087,
                "lng": -132.1453398927
            },
            {
                "lat": 52.9294822712,
                "lng": -132.1322632967
            },
            {
                "lat": 52.9361458673,
                "lng": -132.1275026071
            },
            {
                "lat": 52.9416194481,
                "lng": -132.1348723703
            },
            {
                "lat": 52.9516103371,
                "lng": -132.1486811121
            },
            {
                "lat": 52.9587513987,
                "lng": -132.1577152182
            },
            {
                "lat": 52.9635088423,
                "lng": -132.168671014
            },
            {
                "lat": 52.9663275485,
                "lng": -132.1796868095
            },
            {
                "lat": 52.96709013,
                "lng": -132.1881099299
            },
            {
                "lat": 52.9669071164,
                "lng": -132.190826772
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 194,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 58,
            "MANAGEMENT_AREA_NAME": "Subarea 2-58",
            "LABEL": "2-58",
            "FISHERY_AREA_ID": 1949,
            "OBJECTID": 338,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 10489506.3289,
            "FEATURE_LENGTH_M": 15428.449,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.9526432048421,
            "lng": -132.17290541585788
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.9606928752,
                "lng": -132.2211145117
            },
            {
                "lat": 52.9605869903,
                "lng": -132.2303311636
            },
            {
                "lat": 52.958785861,
                "lng": -132.2639315332
            },
            {
                "lat": 52.9530794357,
                "lng": -132.2579197626
            },
            {
                "lat": 52.9491309251,
                "lng": -132.2443384896
            },
            {
                "lat": 52.9469070897,
                "lng": -132.2398382603
            },
            {
                "lat": 52.9409592631,
                "lng": -132.2191160227
            },
            {
                "lat": 52.9333460276,
                "lng": -132.209594113
            },
            {
                "lat": 52.9306684217,
                "lng": -132.2069850179
            },
            {
                "lat": 52.9252584348,
                "lng": -132.2017364843
            },
            {
                "lat": 52.9245296159,
                "lng": -132.1945652023
            },
            {
                "lat": 52.9236564523,
                "lng": -132.1860051721
            },
            {
                "lat": 52.9216882367,
                "lng": -132.173660255
            },
            {
                "lat": 52.9283526834,
                "lng": -132.1605678996
            },
            {
                "lat": 52.9335857452,
                "lng": -132.1641386006
            },
            {
                "lat": 52.9371526022,
                "lng": -132.1727136865
            },
            {
                "lat": 52.9388198989,
                "lng": -132.1786649142
            },
            {
                "lat": 52.9416767467,
                "lng": -132.1869808413
            },
            {
                "lat": 52.9454845969,
                "lng": -132.1948398869
            },
            {
                "lat": 52.9504773982,
                "lng": -132.201019025
            },
            {
                "lat": 52.954047809,
                "lng": -132.2103117918
            },
            {
                "lat": 52.9606928752,
                "lng": -132.2211145117
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 195,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 59,
            "MANAGEMENT_AREA_NAME": "Subarea 2-59",
            "LABEL": "2-59",
            "FISHERY_AREA_ID": 1950,
            "OBJECTID": 339,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 11598745.2862,
            "FEATURE_LENGTH_M": 17320.7822,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.94179909021818,
            "lng": -132.2063403248136
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.9324728425,
                "lng": -132.3571317602
            },
            {
                "lat": 52.9281465189,
                "lng": -132.3643338252
            },
            {
                "lat": 52.9272420483,
                "lng": -132.3646846512
            },
            {
                "lat": 52.9265289071,
                "lng": -132.3645777552
            },
            {
                "lat": 52.8923722967,
                "lng": -132.3241722155
            },
            {
                "lat": 52.892685578,
                "lng": -132.3241730346
            },
            {
                "lat": 52.8932036011,
                "lng": -132.3243099379
            },
            {
                "lat": 52.8936390521,
                "lng": -132.3241420361
            },
            {
                "lat": 52.8955228337,
                "lng": -132.3208464887
            },
            {
                "lat": 52.8994633144,
                "lng": -132.3051454066
            },
            {
                "lat": 52.8977506591,
                "lng": -132.2914893268
            },
            {
                "lat": 52.9004936399,
                "lng": -132.2691030445
            },
            {
                "lat": 52.9108849488,
                "lng": -132.2554014523
            },
            {
                "lat": 52.9205085768,
                "lng": -132.2487330958
            },
            {
                "lat": 52.924362093,
                "lng": -132.2505495065
            },
            {
                "lat": 52.9269908705,
                "lng": -132.2568359458
            },
            {
                "lat": 52.9263607616,
                "lng": -132.263916354
            },
            {
                "lat": 52.9218255999,
                "lng": -132.2740782989
            },
            {
                "lat": 52.9162982142,
                "lng": -132.2954101576
            },
            {
                "lat": 52.9151876993,
                "lng": -132.3099827188
            },
            {
                "lat": 52.9157181273,
                "lng": -132.3230898003
            },
            {
                "lat": 52.9206959968,
                "lng": -132.3320615606
            },
            {
                "lat": 52.9248964457,
                "lng": -132.3331761353
            },
            {
                "lat": 52.9261167723,
                "lng": -132.3328699089
            },
            {
                "lat": 52.9312747711,
                "lng": -132.33917234
            },
            {
                "lat": 52.9304729354,
                "lng": -132.3485565232
            },
            {
                "lat": 52.9324728425,
                "lng": -132.3571317602
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 196,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 61,
            "MANAGEMENT_AREA_NAME": "Subarea 2-61",
            "LABEL": "2-61",
            "FISHERY_AREA_ID": 1952,
            "OBJECTID": 340,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 16113206.2754,
            "FEATURE_LENGTH_M": 21360.2212,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.91568844248148,
            "lng": -132.3131509274333
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.9501003538,
                "lng": -131.8970183951
            },
            {
                "lat": 52.9476547857,
                "lng": -131.9015964734
            },
            {
                "lat": 52.9474179577,
                "lng": -131.9022818553
            },
            {
                "lat": 52.9471738468,
                "lng": -131.9024967044
            },
            {
                "lat": 52.9470977962,
                "lng": -131.9026339062
            },
            {
                "lat": 52.9471016603,
                "lng": -131.9035641155
            },
            {
                "lat": 52.9440574832,
                "lng": -131.9151612714
            },
            {
                "lat": 52.9440575049,
                "lng": -131.9449011924
            },
            {
                "lat": 52.9517897953,
                "lng": -131.9752356034
            },
            {
                "lat": 52.9404867694,
                "lng": -131.9924926126
            },
            {
                "lat": 52.9220465438,
                "lng": -132.0240179051
            },
            {
                "lat": 52.901820592,
                "lng": -132.0430596516
            },
            {
                "lat": 52.8774302815,
                "lng": -132.0371101696
            },
            {
                "lat": 52.8619647307,
                "lng": -132.01449664
            },
            {
                "lat": 52.8524476901,
                "lng": -131.9889224592
            },
            {
                "lat": 52.839359256,
                "lng": -131.9526364993
            },
            {
                "lat": 52.8108058685,
                "lng": -131.917541627
            },
            {
                "lat": 52.771068803,
                "lng": -131.8848268767
            },
            {
                "lat": 52.7755892062,
                "lng": -131.8405616388
            },
            {
                "lat": 52.7779699189,
                "lng": -131.8043983926
            },
            {
                "lat": 52.7879024694,
                "lng": -131.7753607056
            },
            {
                "lat": 52.7932585632,
                "lng": -131.7485967549
            },
            {
                "lat": 52.7908779652,
                "lng": -131.7265926101
            },
            {
                "lat": 52.7920691324,
                "lng": -131.6950522036
            },
            {
                "lat": 52.800991008,
                "lng": -131.6700746149
            },
            {
                "lat": 52.8051564924,
                "lng": -131.6611475804
            },
            {
                "lat": 52.8043099906,
                "lng": -131.6536414894
            },
            {
                "lat": 52.8351519105,
                "lng": -131.6459043464
            },
            {
                "lat": 52.8783002794,
                "lng": -131.6702570576
            },
            {
                "lat": 52.8793140884,
                "lng": -131.6712034707
            },
            {
                "lat": 52.8810008678,
                "lng": -131.6740414835
            },
            {
                "lat": 52.8836747456,
                "lng": -131.6810000583
            },
            {
                "lat": 52.8841089655,
                "lng": -131.6898802644
            },
            {
                "lat": 52.9418942105,
                "lng": -131.8850552448
            },
            {
                "lat": 52.9449314742,
                "lng": -131.888641934
            },
            {
                "lat": 52.9501003538,
                "lng": -131.8970183951
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 197,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 2-6",
            "LABEL": "2-6",
            "FISHERY_AREA_ID": 1897,
            "OBJECTID": 341,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 332017830.0115,
            "FEATURE_LENGTH_M": 74108.1967,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.87529120446945,
            "lng": -131.84384506120276
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.9265289071,
                "lng": -132.3645777552
            },
            {
                "lat": 52.9265334363,
                "lng": -132.3867945115
            },
            {
                "lat": 52.8130376406,
                "lng": -132.2467491876
            },
            {
                "lat": 52.8129305399,
                "lng": -132.2458044736
            },
            {
                "lat": 52.8131833725,
                "lng": -132.2442176558
            },
            {
                "lat": 52.8141819941,
                "lng": -132.2377769865
            },
            {
                "lat": 52.814602016,
                "lng": -132.2350766276
            },
            {
                "lat": 52.817688314,
                "lng": -132.2333989467
            },
            {
                "lat": 52.8216744169,
                "lng": -132.2377776627
            },
            {
                "lat": 52.8250422566,
                "lng": -132.245911444
            },
            {
                "lat": 52.8314976341,
                "lng": -132.2527618088
            },
            {
                "lat": 52.8374473471,
                "lng": -132.2574157775
            },
            {
                "lat": 52.8395920192,
                "lng": -132.2613981964
            },
            {
                "lat": 52.8402441368,
                "lng": -132.2633207228
            },
            {
                "lat": 52.8400574764,
                "lng": -132.2642975297
            },
            {
                "lat": 52.8398052361,
                "lng": -132.2647858616
            },
            {
                "lat": 52.8397943638,
                "lng": -132.2649681368
            },
            {
                "lat": 52.8923722967,
                "lng": -132.3241722155
            },
            {
                "lat": 52.9265289071,
                "lng": -132.3645777552
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 198,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 48,
            "MANAGEMENT_AREA_NAME": "Subarea 2-48",
            "LABEL": "2-48",
            "FISHERY_AREA_ID": 1939,
            "OBJECTID": 342,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 15765978.0901,
            "FEATURE_LENGTH_M": 33616.6044,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.845933805857904,
            "lng": -132.27346227660527
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.9638714092,
                "lng": -131.5482781854
            },
            {
                "lat": 52.9638711224,
                "lng": -131.6033168772
            },
            {
                "lat": 52.9637875447,
                "lng": -131.6039736692
            },
            {
                "lat": 52.9641993513,
                "lng": -131.6058042511
            },
            {
                "lat": 52.9644545203,
                "lng": -131.6077722457
            },
            {
                "lat": 52.9650955818,
                "lng": -131.6122288278
            },
            {
                "lat": 52.9593844897,
                "lng": -131.6250760594
            },
            {
                "lat": 52.9562916413,
                "lng": -131.6357875766
            },
            {
                "lat": 52.9496309638,
                "lng": -131.6481476865
            },
            {
                "lat": 52.9441563312,
                "lng": -131.6519624543
            },
            {
                "lat": 52.9367790028,
                "lng": -131.6455384814
            },
            {
                "lat": 52.9315450295,
                "lng": -131.6372067334
            },
            {
                "lat": 52.9255332992,
                "lng": -131.6333167672
            },
            {
                "lat": 52.9151655683,
                "lng": -131.6414190064
            },
            {
                "lat": 52.9146494739,
                "lng": -131.648635184
            },
            {
                "lat": 52.9110837743,
                "lng": -131.6588590687
            },
            {
                "lat": 52.9013248626,
                "lng": -131.6702879087
            },
            {
                "lat": 52.8887131777,
                "lng": -131.679321028
            },
            {
                "lat": 52.8836747456,
                "lng": -131.6810000583
            },
            {
                "lat": 52.8810008678,
                "lng": -131.6740414835
            },
            {
                "lat": 52.8793140884,
                "lng": -131.6712034707
            },
            {
                "lat": 52.8783002794,
                "lng": -131.6702570576
            },
            {
                "lat": 52.8351519105,
                "lng": -131.6459043464
            },
            {
                "lat": 52.8043099906,
                "lng": -131.6536414894
            },
            {
                "lat": 52.8043097203,
                "lng": -131.4475409924
            },
            {
                "lat": 52.8779332137,
                "lng": -131.4904780341
            },
            {
                "lat": 52.9638714092,
                "lng": -131.5482781854
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 199,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 2-7",
            "LABEL": "2-7",
            "FISHERY_AREA_ID": 1898,
            "OBJECTID": 343,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 188252797.4302,
            "FEATURE_LENGTH_M": 58831.2747,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.91582975442592,
            "lng": -131.62367693069632
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.9283715598,
                "lng": -129.1664428732
            },
            {
                "lat": 52.9217603935,
                "lng": -129.16644323
            },
            {
                "lat": 52.9201433765,
                "lng": -129.1645513936
            },
            {
                "lat": 52.9177824017,
                "lng": -129.159560392
            },
            {
                "lat": 52.9171071634,
                "lng": -129.1578060063
            },
            {
                "lat": 52.9159045487,
                "lng": -129.157562015
            },
            {
                "lat": 52.8819544147,
                "lng": -129.1266475947
            },
            {
                "lat": 52.8764757368,
                "lng": -129.1226344616
            },
            {
                "lat": 52.8717193054,
                "lng": -129.1062164815
            },
            {
                "lat": 52.8605352319,
                "lng": -129.0788418096
            },
            {
                "lat": 52.8467329728,
                "lng": -129.0417334375
            },
            {
                "lat": 52.8343625357,
                "lng": -129.0248412413
            },
            {
                "lat": 52.8329354698,
                "lng": -129.013167877
            },
            {
                "lat": 52.8343623245,
                "lng": -129.0046084226
            },
            {
                "lat": 52.8419759829,
                "lng": -128.9998472001
            },
            {
                "lat": 52.8548234903,
                "lng": -129.0115046507
            },
            {
                "lat": 52.8748133443,
                "lng": -129.0357820878
            },
            {
                "lat": 52.8826631388,
                "lng": -129.0610045795
            },
            {
                "lat": 52.8890871084,
                "lng": -129.0574341866
            },
            {
                "lat": 52.8964653113,
                "lng": -129.0212711968
            },
            {
                "lat": 52.9159777953,
                "lng": -128.9748685821
            },
            {
                "lat": 52.9395332409,
                "lng": -128.9598702517
            },
            {
                "lat": 52.9673731758,
                "lng": -128.9417887032
            },
            {
                "lat": 52.984394012,
                "lng": -128.9417878735
            },
            {
                "lat": 52.9968874826,
                "lng": -128.9449001159
            },
            {
                "lat": 53.0052065347,
                "lng": -128.932509594
            },
            {
                "lat": 53.0159144384,
                "lng": -128.9060972702
            },
            {
                "lat": 53.0275722342,
                "lng": -128.8887327012
            },
            {
                "lat": 53.043041762,
                "lng": -128.8994444947
            },
            {
                "lat": 53.0387575841,
                "lng": -128.95654288
            },
            {
                "lat": 53.010204305,
                "lng": -128.9822392219
            },
            {
                "lat": 52.9754372536,
                "lng": -129.0012203669
            },
            {
                "lat": 52.9317860608,
                "lng": -129.1158591542
            },
            {
                "lat": 52.9466706084,
                "lng": -129.1228644679
            },
            {
                "lat": 52.9761772297,
                "lng": -129.1176303547
            },
            {
                "lat": 52.9883113423,
                "lng": -129.1285857717
            },
            {
                "lat": 52.9854579666,
                "lng": -129.1447609062
            },
            {
                "lat": 52.9690397231,
                "lng": -129.1566619058
            },
            {
                "lat": 52.952858485,
                "lng": -129.1559449122
            },
            {
                "lat": 52.9386220362,
                "lng": -129.1565247082
            },
            {
                "lat": 52.9283715598,
                "lng": -129.1664428732
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 200,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 6-12",
            "LABEL": "6-12",
            "FISHERY_AREA_ID": 2419,
            "OBJECTID": 344,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 152656560.1494,
            "FEATURE_LENGTH_M": 82055.9633,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.930184649797546,
            "lng": -129.05544337187317
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.8955228337,
                "lng": -132.3208464887
            },
            {
                "lat": 52.8936390521,
                "lng": -132.3241420361
            },
            {
                "lat": 52.8932036011,
                "lng": -132.3243099379
            },
            {
                "lat": 52.892685578,
                "lng": -132.3241730346
            },
            {
                "lat": 52.8923722967,
                "lng": -132.3241722155
            },
            {
                "lat": 52.8397943638,
                "lng": -132.2649681368
            },
            {
                "lat": 52.8398052361,
                "lng": -132.2647858616
            },
            {
                "lat": 52.8400574764,
                "lng": -132.2642975297
            },
            {
                "lat": 52.8402441368,
                "lng": -132.2633207228
            },
            {
                "lat": 52.8503302127,
                "lng": -132.2262117294
            },
            {
                "lat": 52.8428538273,
                "lng": -132.2178954462
            },
            {
                "lat": 52.8366701911,
                "lng": -132.193160768
            },
            {
                "lat": 52.8326224312,
                "lng": -132.1581728199
            },
            {
                "lat": 52.8352394008,
                "lng": -132.1434332041
            },
            {
                "lat": 52.845233602,
                "lng": -132.1410522849
            },
            {
                "lat": 52.8518983641,
                "lng": -132.1450956623
            },
            {
                "lat": 52.8549914687,
                "lng": -132.1603242038
            },
            {
                "lat": 52.8573684016,
                "lng": -132.1753074928
            },
            {
                "lat": 52.860511806,
                "lng": -132.1887357342
            },
            {
                "lat": 52.8696865088,
                "lng": -132.1918341473
            },
            {
                "lat": 52.8804515148,
                "lng": -132.1921997258
            },
            {
                "lat": 52.8854480066,
                "lng": -132.2007744146
            },
            {
                "lat": 52.8828319387,
                "lng": -132.2160026998
            },
            {
                "lat": 52.8767819196,
                "lng": -132.2332145906
            },
            {
                "lat": 52.8747402965,
                "lng": -132.2383730878
            },
            {
                "lat": 52.8749776372,
                "lng": -132.2519232444
            },
            {
                "lat": 52.8802102321,
                "lng": -132.2766724909
            },
            {
                "lat": 52.8891451015,
                "lng": -132.3053434535
            },
            {
                "lat": 52.8924944915,
                "lng": -132.3090963551
            },
            {
                "lat": 52.8942525776,
                "lng": -132.3137658339
            },
            {
                "lat": 52.8955228337,
                "lng": -132.3208464887
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 201,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 62,
            "MANAGEMENT_AREA_NAME": "Subarea 2-62",
            "LABEL": "2-62",
            "FISHERY_AREA_ID": 1953,
            "OBJECTID": 345,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 36851663.3526,
            "FEATURE_LENGTH_M": 33656.2442,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.86747055931611,
            "lng": -132.24111134973225
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.8159213994,
                "lng": -132.0719145047
            },
            {
                "lat": 52.8146743076,
                "lng": -132.063324009
            },
            {
                "lat": 52.8143196103,
                "lng": -132.0608983059
            },
            {
                "lat": 52.8148570989,
                "lng": -132.0597382247
            },
            {
                "lat": 52.817386713,
                "lng": -132.0506443037
            },
            {
                "lat": 52.8178596629,
                "lng": -132.0493163115
            },
            {
                "lat": 52.8184661817,
                "lng": -132.0484614594
            },
            {
                "lat": 52.8208854606,
                "lng": -132.0424041437
            },
            {
                "lat": 52.8266794488,
                "lng": -132.0451508308
            },
            {
                "lat": 52.8379855509,
                "lng": -132.0525060988
            },
            {
                "lat": 52.8488353702,
                "lng": -132.0610659238
            },
            {
                "lat": 52.8576586393,
                "lng": -132.0694278771
            },
            {
                "lat": 52.8640938696,
                "lng": -132.0775137955
            },
            {
                "lat": 52.8640938852,
                "lng": -132.0839541967
            },
            {
                "lat": 52.8628154129,
                "lng": -132.0863957879
            },
            {
                "lat": 52.8625069442,
                "lng": -132.0869753175
            },
            {
                "lat": 52.8554537417,
                "lng": -132.0947110465
            },
            {
                "lat": 52.8476411748,
                "lng": -132.0996707563
            },
            {
                "lat": 52.8375276836,
                "lng": -132.0979311473
            },
            {
                "lat": 52.8279645245,
                "lng": -132.0888211798
            },
            {
                "lat": 52.8159213994,
                "lng": -132.0719145047
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 202,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 44,
            "MANAGEMENT_AREA_NAME": "Subarea 2-44",
            "LABEL": "2-44",
            "FISHERY_AREA_ID": 1935,
            "OBJECTID": 346,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 12722798.4989,
            "FEATURE_LENGTH_M": 14318.2914,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.83540705140476,
            "lng": -132.06965427263336
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.6699980875,
                "lng": -128.9029547953
            },
            {
                "lat": 52.656437116,
                "lng": -128.8924714991
            },
            {
                "lat": 52.6528967373,
                "lng": -128.8908076067
            },
            {
                "lat": 52.6488490301,
                "lng": -128.8918766996
            },
            {
                "lat": 52.6007999035,
                "lng": -128.753967097
            },
            {
                "lat": 52.6048008899,
                "lng": -128.7498473767
            },
            {
                "lat": 52.6104387689,
                "lng": -128.7526085346
            },
            {
                "lat": 52.6392332553,
                "lng": -128.7485501101
            },
            {
                "lat": 52.6483462386,
                "lng": -128.7397159257
            },
            {
                "lat": 52.6507267517,
                "lng": -128.7321013068
            },
            {
                "lat": 52.6671449423,
                "lng": -128.7323454404
            },
            {
                "lat": 52.693558206,
                "lng": -128.7361449765
            },
            {
                "lat": 52.7128950873,
                "lng": -128.7381740917
            },
            {
                "lat": 52.743049849,
                "lng": -128.722824094
            },
            {
                "lat": 52.7681468765,
                "lng": -128.7303923734
            },
            {
                "lat": 52.8272168697,
                "lng": -128.7220614454
            },
            {
                "lat": 52.8440857877,
                "lng": -128.6883241369
            },
            {
                "lat": 52.8634188277,
                "lng": -128.6654210076
            },
            {
                "lat": 52.9053575572,
                "lng": -128.6454927462
            },
            {
                "lat": 52.9469988327,
                "lng": -128.6362758129
            },
            {
                "lat": 52.9794195958,
                "lng": -128.6457828287
            },
            {
                "lat": 52.9835815622,
                "lng": -128.6642310051
            },
            {
                "lat": 52.9797175148,
                "lng": -128.6909943888
            },
            {
                "lat": 52.9529453714,
                "lng": -128.7019962857
            },
            {
                "lat": 52.9182098111,
                "lng": -128.6920773358
            },
            {
                "lat": 52.9086645252,
                "lng": -128.7105864307
            },
            {
                "lat": 52.9342071835,
                "lng": -128.7350156139
            },
            {
                "lat": 52.9291535393,
                "lng": -128.7597046159
            },
            {
                "lat": 52.8721847668,
                "lng": -128.7360382174
            },
            {
                "lat": 52.849643011,
                "lng": -128.7517705067
            },
            {
                "lat": 52.862972802,
                "lng": -128.7844541971
            },
            {
                "lat": 52.8472709345,
                "lng": -128.7975306652
            },
            {
                "lat": 52.7937811242,
                "lng": -128.79074081
            },
            {
                "lat": 52.6951370662,
                "lng": -128.8595731928
            },
            {
                "lat": 52.6699980875,
                "lng": -128.9029547953
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 203,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 19,
            "MANAGEMENT_AREA_NAME": "Subarea 6-19",
            "LABEL": "6-19",
            "FISHERY_AREA_ID": 2426,
            "OBJECTID": 347,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 260299426.7677,
            "FEATURE_LENGTH_M": 110909.1766,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.78660818601143,
            "lng": -128.75130879901994
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.814602016,
                "lng": -132.2350766276
            },
            {
                "lat": 52.8141819941,
                "lng": -132.2377769865
            },
            {
                "lat": 52.8131833725,
                "lng": -132.2442176558
            },
            {
                "lat": 52.8129305399,
                "lng": -132.2458044736
            },
            {
                "lat": 52.8130376406,
                "lng": -132.2467491876
            },
            {
                "lat": 52.2125699207,
                "lng": -131.4151004548
            },
            {
                "lat": 52.2160411859,
                "lng": -131.4159231505
            },
            {
                "lat": 52.2217526968,
                "lng": -131.4175874202
            },
            {
                "lat": 52.2298435697,
                "lng": -131.4171144604
            },
            {
                "lat": 52.2412638155,
                "lng": -131.4202118459
            },
            {
                "lat": 52.2519717579,
                "lng": -131.4268646031
            },
            {
                "lat": 52.263156731,
                "lng": -131.4356691575
            },
            {
                "lat": 52.2738612273,
                "lng": -131.4485165223
            },
            {
                "lat": 52.2836191585,
                "lng": -131.4627988678
            },
            {
                "lat": 52.2993972243,
                "lng": -131.4932255031
            },
            {
                "lat": 52.3109011025,
                "lng": -131.4993129119
            },
            {
                "lat": 52.3209754848,
                "lng": -131.5037230283
            },
            {
                "lat": 52.3278767618,
                "lng": -131.5089571429
            },
            {
                "lat": 52.3290680028,
                "lng": -131.517531787
            },
            {
                "lat": 52.3275300721,
                "lng": -131.5243527867
            },
            {
                "lat": 52.3269234597,
                "lng": -131.5270384783
            },
            {
                "lat": 52.3331107408,
                "lng": -131.5334776027
            },
            {
                "lat": 52.3356710869,
                "lng": -131.532089322
            },
            {
                "lat": 52.3414377893,
                "lng": -131.5289453326
            },
            {
                "lat": 52.3485787314,
                "lng": -131.5263363232
            },
            {
                "lat": 52.3538129167,
                "lng": -131.5299066671
            },
            {
                "lat": 52.355479846,
                "lng": -131.5379942294
            },
            {
                "lat": 52.3549608835,
                "lng": -131.5504142055
            },
            {
                "lat": 52.3547630328,
                "lng": -131.5551291137
            },
            {
                "lat": 52.3569072936,
                "lng": -131.5651249022
            },
            {
                "lat": 52.3633311422,
                "lng": -131.5729679856
            },
            {
                "lat": 52.3742754542,
                "lng": -131.578674293
            },
            {
                "lat": 52.3834380741,
                "lng": -131.5774839105
            },
            {
                "lat": 52.3872867758,
                "lng": -131.5654151536
            },
            {
                "lat": 52.3924522405,
                "lng": -131.5647283285
            },
            {
                "lat": 52.4004661735,
                "lng": -131.566284419
            },
            {
                "lat": 52.4021497634,
                "lng": -131.5747068054
            },
            {
                "lat": 52.4002184073,
                "lng": -131.5837094891
            },
            {
                "lat": 52.3981091953,
                "lng": -131.5888983292
            },
            {
                "lat": 52.3982965692,
                "lng": -131.5914148893
            },
            {
                "lat": 52.420333621,
                "lng": -131.5914151713
            },
            {
                "lat": 52.4232642881,
                "lng": -131.5929710142
            },
            {
                "lat": 52.4369961385,
                "lng": -131.5956268902
            },
            {
                "lat": 52.4562721959,
                "lng": -131.6075288538
            },
            {
                "lat": 52.4745941035,
                "lng": -131.624176219
            },
            {
                "lat": 52.4900586693,
                "lng": -131.6501158258
            },
            {
                "lat": 52.4941063357,
                "lng": -131.6667789324
            },
            {
                "lat": 52.4981493695,
                "lng": -131.699843873
            },
            {
                "lat": 52.508380965,
                "lng": -131.7236480467
            },
            {
                "lat": 52.5186080674,
                "lng": -131.7527767446
            },
            {
                "lat": 52.5331260806,
                "lng": -131.7336433221
            },
            {
                "lat": 52.544551183,
                "lng": -131.7483827501
            },
            {
                "lat": 52.5438343159,
                "lng": -131.7707515555
            },
            {
                "lat": 52.5455014907,
                "lng": -131.7902674891
            },
            {
                "lat": 52.5602533312,
                "lng": -131.8078762602
            },
            {
                "lat": 52.5733406214,
                "lng": -131.7731322767
            },
            {
                "lat": 52.5878565031,
                "lng": -131.7764734328
            },
            {
                "lat": 52.5940441678,
                "lng": -131.7926483104
            },
            {
                "lat": 52.5828589387,
                "lng": -131.823348998
            },
            {
                "lat": 52.5795282397,
                "lng": -131.8521272321
            },
            {
                "lat": 52.5933305947,
                "lng": -131.8714139893
            },
            {
                "lat": 52.6009441468,
                "lng": -131.8626106169
            },
            {
                "lat": 52.6123656844,
                "lng": -131.8504639029
            },
            {
                "lat": 52.6233096783,
                "lng": -131.8599851814
            },
            {
                "lat": 52.625450465,
                "lng": -131.8771211115
            },
            {
                "lat": 52.627354268,
                "lng": -131.9087674754
            },
            {
                "lat": 52.6340177466,
                "lng": -131.9128112466
            },
            {
                "lat": 52.6366349274,
                "lng": -131.8854524681
            },
            {
                "lat": 52.6430583495,
                "lng": -131.8685449568
            },
            {
                "lat": 52.6590002614,
                "lng": -131.8716426979
            },
            {
                "lat": 52.6701853217,
                "lng": -131.8956756106
            },
            {
                "lat": 52.67256494,
                "lng": -131.9216152601
            },
            {
                "lat": 52.6763730787,
                "lng": -131.9492184651
            },
            {
                "lat": 52.6782766748,
                "lng": -131.9753880644
            },
            {
                "lat": 52.6870811326,
                "lng": -131.9929970627
            },
            {
                "lat": 52.6958839889,
                "lng": -132.0198828958
            },
            {
                "lat": 52.7070658289,
                "lng": -132.0465399317
            },
            {
                "lat": 52.721580863,
                "lng": -132.065810895
            },
            {
                "lat": 52.7373317058,
                "lng": -132.0853414767
            },
            {
                "lat": 52.7380221768,
                "lng": -132.0981759133
            },
            {
                "lat": 52.7364621184,
                "lng": -132.105391742
            },
            {
                "lat": 52.7355875311,
                "lng": -132.1085349396
            },
            {
                "lat": 52.7415423066,
                "lng": -132.1148229204
            },
            {
                "lat": 52.7421804789,
                "lng": -132.1141062563
            },
            {
                "lat": 52.7422522964,
                "lng": -132.1124425423
            },
            {
                "lat": 52.742781972,
                "lng": -132.1111758668
            },
            {
                "lat": 52.7433817666,
                "lng": -132.1103214228
            },
            {
                "lat": 52.7449334583,
                "lng": -132.1102904388
            },
            {
                "lat": 52.7455367412,
                "lng": -132.11059566
            },
            {
                "lat": 52.7501558297,
                "lng": -132.112944789
            },
            {
                "lat": 52.7625387469,
                "lng": -132.1167451472
            },
            {
                "lat": 52.7618286744,
                "lng": -132.118728852
            },
            {
                "lat": 52.7604030732,
                "lng": -132.1313326044
            },
            {
                "lat": 52.7625425037,
                "lng": -132.1408537987
            },
            {
                "lat": 52.778724387,
                "lng": -132.1558532176
            },
            {
                "lat": 52.7932398558,
                "lng": -132.1656029667
            },
            {
                "lat": 52.8029930932,
                "lng": -132.1765441823
            },
            {
                "lat": 52.8048981476,
                "lng": -132.1891637475
            },
            {
                "lat": 52.8044204489,
                "lng": -132.2055816899
            },
            {
                "lat": 52.8084684793,
                "lng": -132.2243805568
            },
            {
                "lat": 52.814602016,
                "lng": -132.2350766276
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 204,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 38,
            "MANAGEMENT_AREA_NAME": "Subarea 2-38",
            "LABEL": "2-38",
            "FISHERY_AREA_ID": 1929,
            "OBJECTID": 348,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 366889870.1826,
            "FEATURE_LENGTH_M": 204325.8034,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.54677622015246,
            "lng": -131.80624491824062
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.8208854606,
                "lng": -132.0424041437
            },
            {
                "lat": 52.8184661817,
                "lng": -132.0484614594
            },
            {
                "lat": 52.8178596629,
                "lng": -132.0493163115
            },
            {
                "lat": 52.817386713,
                "lng": -132.0506443037
            },
            {
                "lat": 52.8148570989,
                "lng": -132.0597382247
            },
            {
                "lat": 52.8143196103,
                "lng": -132.0608983059
            },
            {
                "lat": 52.8146743076,
                "lng": -132.063324009
            },
            {
                "lat": 52.8159213994,
                "lng": -132.0719145047
            },
            {
                "lat": 52.7987443215,
                "lng": -132.0785378025
            },
            {
                "lat": 52.7918548197,
                "lng": -132.0684513992
            },
            {
                "lat": 52.792110807,
                "lng": -132.0642394729
            },
            {
                "lat": 52.7921526641,
                "lng": -132.0613252507
            },
            {
                "lat": 52.7661012964,
                "lng": -132.045761029
            },
            {
                "lat": 52.783603089,
                "lng": -132.0146488177
            },
            {
                "lat": 52.7968527061,
                "lng": -132.0146484688
            },
            {
                "lat": 52.8043367042,
                "lng": -132.0143589466
            },
            {
                "lat": 52.8080143328,
                "lng": -132.025299299
            },
            {
                "lat": 52.8142660855,
                "lng": -132.0342099366
            },
            {
                "lat": 52.8208854606,
                "lng": -132.0424041437
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 205,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 43,
            "MANAGEMENT_AREA_NAME": "Subarea 2-43",
            "LABEL": "2-43",
            "FISHERY_AREA_ID": 1934,
            "OBJECTID": 349,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 15453823.5063,
            "FEATURE_LENGTH_M": 16637.9061,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.80543645901579,
            "lng": -132.04792556996316
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.9351884788,
                "lng": -128.4927222385
            },
            {
                "lat": 52.9268420112,
                "lng": -128.4970545437
            },
            {
                "lat": 52.9218977259,
                "lng": -128.500122705
            },
            {
                "lat": 52.9205773655,
                "lng": -128.4991764941
            },
            {
                "lat": 52.9193494438,
                "lng": -128.4971770082
            },
            {
                "lat": 52.9173204103,
                "lng": -128.495148381
            },
            {
                "lat": 52.9098241227,
                "lng": -128.4825435546
            },
            {
                "lat": 52.9095879835,
                "lng": -128.460646917
            },
            {
                "lat": 52.9124411236,
                "lng": -128.4330441504
            },
            {
                "lat": 52.9286228651,
                "lng": -128.4125823835
            },
            {
                "lat": 52.9379049445,
                "lng": -128.4068757835
            },
            {
                "lat": 52.9450423535,
                "lng": -128.4075929359
            },
            {
                "lat": 52.950275222,
                "lng": -128.4130550521
            },
            {
                "lat": 52.9469457716,
                "lng": -128.4280541112
            },
            {
                "lat": 52.9307640057,
                "lng": -128.468978793
            },
            {
                "lat": 52.9275402395,
                "lng": -128.48063691
            },
            {
                "lat": 52.9351884788,
                "lng": -128.4927222385
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 206,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 24,
            "MANAGEMENT_AREA_NAME": "Subarea 6-24",
            "LABEL": "6-24",
            "FISHERY_AREA_ID": 2431,
            "OBJECTID": 350,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 16035205.8835,
            "FEATURE_LENGTH_M": 17973.1039,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.927959561529406,
            "lng": -128.4628314235412
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.8043367042,
                "lng": -132.0143589466
            },
            {
                "lat": 52.7968527061,
                "lng": -132.0146484688
            },
            {
                "lat": 52.783603089,
                "lng": -132.0146488177
            },
            {
                "lat": 52.7824437749,
                "lng": -132.0149389965
            },
            {
                "lat": 52.7800642881,
                "lng": -132.0138699274
            },
            {
                "lat": 52.7749289258,
                "lng": -132.0115812275
            },
            {
                "lat": 52.7692025012,
                "lng": -132.0019533756
            },
            {
                "lat": 52.7712213431,
                "lng": -131.9971616152
            },
            {
                "lat": 52.7712212756,
                "lng": -131.949463538
            },
            {
                "lat": 52.7717927652,
                "lng": -131.942367981
            },
            {
                "lat": 52.7838358314,
                "lng": -131.9513846284
            },
            {
                "lat": 52.7963414821,
                "lng": -131.9614106682
            },
            {
                "lat": 52.801582527,
                "lng": -131.9664609003
            },
            {
                "lat": 52.8045231873,
                "lng": -131.982268697
            },
            {
                "lat": 52.8059920955,
                "lng": -131.9966122095
            },
            {
                "lat": 52.8056257384,
                "lng": -132.0047918599
            },
            {
                "lat": 52.8043367042,
                "lng": -132.0143589466
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 207,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 45,
            "MANAGEMENT_AREA_NAME": "Subarea 2-45",
            "LABEL": "2-45",
            "FISHERY_AREA_ID": 1936,
            "OBJECTID": 351,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 14771553.0522,
            "FEATURE_LENGTH_M": 15312.3118,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.788700290535296,
            "lng": -131.9913106355412
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.7625387469,
                "lng": -132.1167451472
            },
            {
                "lat": 52.7501558297,
                "lng": -132.112944789
            },
            {
                "lat": 52.7455367412,
                "lng": -132.11059566
            },
            {
                "lat": 52.7449334583,
                "lng": -132.1102904388
            },
            {
                "lat": 52.7433817666,
                "lng": -132.1103214228
            },
            {
                "lat": 52.742781972,
                "lng": -132.1111758668
            },
            {
                "lat": 52.7422522964,
                "lng": -132.1124425423
            },
            {
                "lat": 52.7421804789,
                "lng": -132.1141062563
            },
            {
                "lat": 52.7415423066,
                "lng": -132.1148229204
            },
            {
                "lat": 52.7355875311,
                "lng": -132.1085349396
            },
            {
                "lat": 52.7364621184,
                "lng": -132.105391742
            },
            {
                "lat": 52.7380221768,
                "lng": -132.0981759133
            },
            {
                "lat": 52.7373317058,
                "lng": -132.0853414767
            },
            {
                "lat": 52.7564352781,
                "lng": -132.0441132124
            },
            {
                "lat": 52.7618521156,
                "lng": -132.0436245657
            },
            {
                "lat": 52.7654610365,
                "lng": -132.0460055717
            },
            {
                "lat": 52.7661012964,
                "lng": -132.045761029
            },
            {
                "lat": 52.7921526641,
                "lng": -132.0613252507
            },
            {
                "lat": 52.792110807,
                "lng": -132.0642394729
            },
            {
                "lat": 52.7918548197,
                "lng": -132.0684513992
            },
            {
                "lat": 52.7987443215,
                "lng": -132.0785378025
            },
            {
                "lat": 52.7925417422,
                "lng": -132.1071328192
            },
            {
                "lat": 52.7797089109,
                "lng": -132.1161798829
            },
            {
                "lat": 52.7625387469,
                "lng": -132.1167451472
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 208,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 42,
            "MANAGEMENT_AREA_NAME": "Subarea 2-42",
            "LABEL": "2-42",
            "FISHERY_AREA_ID": 1933,
            "OBJECTID": 352,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 24941388.0054,
            "FEATURE_LENGTH_M": 19707.3388,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.75925870281666,
            "lng": -132.09179188619166
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.8313404736,
                "lng": -128.1544942936
            },
            {
                "lat": 52.8065835353,
                "lng": -128.1582021271
            },
            {
                "lat": 52.7868082068,
                "lng": -128.1619265665
            },
            {
                "lat": 52.7707672638,
                "lng": -128.1645507283
            },
            {
                "lat": 52.7684903511,
                "lng": -128.1559602997
            },
            {
                "lat": 52.7684903657,
                "lng": -128.130325438
            },
            {
                "lat": 52.7698555516,
                "lng": -128.1297611334
            },
            {
                "lat": 52.7720570947,
                "lng": -128.1276858119
            },
            {
                "lat": 52.7826046125,
                "lng": -128.1177364517
            },
            {
                "lat": 52.7963748583,
                "lng": -128.1096955797
            },
            {
                "lat": 52.8255765676,
                "lng": -128.1114648941
            },
            {
                "lat": 52.8510437707,
                "lng": -128.111465328
            },
            {
                "lat": 52.8664631907,
                "lng": -128.1021275015
            },
            {
                "lat": 52.8825844191,
                "lng": -128.0712744352
            },
            {
                "lat": 52.8862032478,
                "lng": -128.0432130315
            },
            {
                "lat": 52.8863221654,
                "lng": -128.0329590546
            },
            {
                "lat": 52.8891256767,
                "lng": -128.0191805561
            },
            {
                "lat": 52.8998720726,
                "lng": -128.0065613234
            },
            {
                "lat": 52.918796148,
                "lng": -128.0060882019
            },
            {
                "lat": 52.9333342867,
                "lng": -128.0083319856
            },
            {
                "lat": 52.9332843228,
                "lng": -128.0194086709
            },
            {
                "lat": 52.9307135254,
                "lng": -128.032257193
            },
            {
                "lat": 52.9255714097,
                "lng": -128.059371925
            },
            {
                "lat": 52.9199672448,
                "lng": -128.0990898865
            },
            {
                "lat": 52.9288440046,
                "lng": -128.1163791016
            },
            {
                "lat": 52.9349172309,
                "lng": -128.1278226306
            },
            {
                "lat": 52.9318813454,
                "lng": -128.1392664633
            },
            {
                "lat": 52.924171409,
                "lng": -128.14814824
            },
            {
                "lat": 52.9089845679,
                "lng": -128.1593628529
            },
            {
                "lat": 52.8914608485,
                "lng": -128.1663669038
            },
            {
                "lat": 52.8665772936,
                "lng": -128.1690679513
            },
            {
                "lat": 52.8573568205,
                "lng": -128.1613920499
            },
            {
                "lat": 52.8443790542,
                "lng": -128.1463317308
            },
            {
                "lat": 52.8405837745,
                "lng": -128.1504519097
            },
            {
                "lat": 52.8313404736,
                "lng": -128.1544942936
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 209,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 7-7",
            "LABEL": "7-7",
            "FISHERY_AREA_ID": 2442,
            "OBJECTID": 353,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 92589383.7996,
            "FEATURE_LENGTH_M": 53911.4491,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.86179220526001,
            "lng": -128.1077776155628
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.8499981136,
                "lng": -129.5103605768
            },
            {
                "lat": 52.84999821,
                "lng": -130.6833351283
            },
            {
                "lat": 52.2833329069,
                "lng": -130.1083368701
            },
            {
                "lat": 52.2833329854,
                "lng": -128.8665316678
            },
            {
                "lat": 52.3888890635,
                "lng": -128.9666747845
            },
            {
                "lat": 52.4036948053,
                "lng": -128.9841311628
            },
            {
                "lat": 52.4535864974,
                "lng": -129.0429531053
            },
            {
                "lat": 52.8499981136,
                "lng": -129.5103605768
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 210,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 106,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 106-2",
            "LABEL": "106-2",
            "FISHERY_AREA_ID": 2307,
            "OBJECTID": 354,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 5199561787.1599,
            "FEATURE_LENGTH_M": 314417.4613,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.545353836962505,
            "lng": -129.45908548405
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.8043097203,
                "lng": -131.4475409924
            },
            {
                "lat": 52.8043099906,
                "lng": -131.6536414894
            },
            {
                "lat": 52.8051564924,
                "lng": -131.6611475804
            },
            {
                "lat": 52.800991008,
                "lng": -131.6700746149
            },
            {
                "lat": 52.7920691324,
                "lng": -131.6950522036
            },
            {
                "lat": 52.7908779652,
                "lng": -131.7265926101
            },
            {
                "lat": 52.7932585632,
                "lng": -131.7485967549
            },
            {
                "lat": 52.7879024694,
                "lng": -131.7753607056
            },
            {
                "lat": 52.7779699189,
                "lng": -131.8043983926
            },
            {
                "lat": 52.7755892062,
                "lng": -131.8405616388
            },
            {
                "lat": 52.771068803,
                "lng": -131.8848268767
            },
            {
                "lat": 52.7608371804,
                "lng": -131.8976744237
            },
            {
                "lat": 52.7467998443,
                "lng": -131.8969570438
            },
            {
                "lat": 52.7368053659,
                "lng": -131.8890993092
            },
            {
                "lat": 52.7344249659,
                "lng": -131.8710176185
            },
            {
                "lat": 52.7394225598,
                "lng": -131.8515016857
            },
            {
                "lat": 52.7437050882,
                "lng": -131.834854882
            },
            {
                "lat": 52.7439427075,
                "lng": -131.8198547054
            },
            {
                "lat": 52.7421570495,
                "lng": -131.8078461289
            },
            {
                "lat": 52.7426259271,
                "lng": -131.8032834066
            },
            {
                "lat": 52.7426182843,
                "lng": -131.7682495699
            },
            {
                "lat": 52.7417985492,
                "lng": -131.7644199011
            },
            {
                "lat": 52.7381779623,
                "lng": -131.763336217
            },
            {
                "lat": 52.7134814818,
                "lng": -131.7360690534
            },
            {
                "lat": 52.7125211528,
                "lng": -131.7335509575
            },
            {
                "lat": 52.7110905298,
                "lng": -131.7334444311
            },
            {
                "lat": 52.7080428186,
                "lng": -131.7293538946
            },
            {
                "lat": 52.705600242,
                "lng": -131.7260136236
            },
            {
                "lat": 52.7057272034,
                "lng": -131.7212532553
            },
            {
                "lat": 52.7067377328,
                "lng": -131.7191923597
            },
            {
                "lat": 52.7091287845,
                "lng": -131.7183985677
            },
            {
                "lat": 52.7111741917,
                "lng": -131.6977846665
            },
            {
                "lat": 52.722896354,
                "lng": -131.6849056648
            },
            {
                "lat": 52.7236097389,
                "lng": -131.6640629449
            },
            {
                "lat": 52.7211149269,
                "lng": -131.6382293875
            },
            {
                "lat": 52.7227217313,
                "lng": -131.6118617406
            },
            {
                "lat": 52.7237889644,
                "lng": -131.5888830816
            },
            {
                "lat": 52.7243233803,
                "lng": -131.5810390363
            },
            {
                "lat": 52.7243232594,
                "lng": -131.5767662922
            },
            {
                "lat": 52.7233919144,
                "lng": -131.5746915527
            },
            {
                "lat": 52.7233883645,
                "lng": -131.5342407343
            },
            {
                "lat": 52.7215385348,
                "lng": -131.5300142553
            },
            {
                "lat": 52.7221451279,
                "lng": -131.5279083137
            },
            {
                "lat": 52.7241175669,
                "lng": -131.5211021503
            },
            {
                "lat": 52.7263406863,
                "lng": -131.5119626803
            },
            {
                "lat": 52.7269288849,
                "lng": -131.4996643162
            },
            {
                "lat": 52.7270429713,
                "lng": -131.4907683057
            },
            {
                "lat": 52.720836726,
                "lng": -131.4847870257
            },
            {
                "lat": 52.712283866,
                "lng": -131.4619449385
            },
            {
                "lat": 52.7072103261,
                "lng": -131.4518740864
            },
            {
                "lat": 52.7047505013,
                "lng": -131.4507747156
            },
            {
                "lat": 52.7051962925,
                "lng": -131.4416200981
            },
            {
                "lat": 52.7051964489,
                "lng": -131.3897409344
            },
            {
                "lat": 52.8043097203,
                "lng": -131.4475409924
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 211,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 2-8",
            "LABEL": "2-8",
            "FISHERY_AREA_ID": 1899,
            "OBJECTID": 355,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 248949824.6343,
            "FEATURE_LENGTH_M": 84848.4196,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.739218132938895,
            "lng": -131.66769134829815
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.7749289258,
                "lng": -132.0115812275
            },
            {
                "lat": 52.7800642881,
                "lng": -132.0138699274
            },
            {
                "lat": 52.7824437749,
                "lng": -132.0149389965
            },
            {
                "lat": 52.783603089,
                "lng": -132.0146488177
            },
            {
                "lat": 52.7661012964,
                "lng": -132.045761029
            },
            {
                "lat": 52.7654610365,
                "lng": -132.0460055717
            },
            {
                "lat": 52.7618521156,
                "lng": -132.0436245657
            },
            {
                "lat": 52.7564352781,
                "lng": -132.0441132124
            },
            {
                "lat": 52.750740513,
                "lng": -132.0336605556
            },
            {
                "lat": 52.7474630471,
                "lng": -132.0257419947
            },
            {
                "lat": 52.7432933894,
                "lng": -132.0163880742
            },
            {
                "lat": 52.7307018491,
                "lng": -132.002136307
            },
            {
                "lat": 52.7265202266,
                "lng": -132.0032502455
            },
            {
                "lat": 52.7215989926,
                "lng": -132.0047921395
            },
            {
                "lat": 52.7158960975,
                "lng": -132.0025023516
            },
            {
                "lat": 52.709461684,
                "lng": -131.9932092659
            },
            {
                "lat": 52.7059904385,
                "lng": -131.9812017441
            },
            {
                "lat": 52.7095560447,
                "lng": -131.9742737766
            },
            {
                "lat": 52.7237134518,
                "lng": -131.9691316456
            },
            {
                "lat": 52.7375029738,
                "lng": -131.9743659211
            },
            {
                "lat": 52.7473410285,
                "lng": -131.9827271628
            },
            {
                "lat": 52.7586483457,
                "lng": -131.9983670466
            },
            {
                "lat": 52.7638893891,
                "lng": -132.0061493133
            },
            {
                "lat": 52.7696918327,
                "lng": -132.0157463156
            },
            {
                "lat": 52.7749289258,
                "lng": -132.0115812275
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 212,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 47,
            "MANAGEMENT_AREA_NAME": "Subarea 2-47",
            "LABEL": "2-47",
            "FISHERY_AREA_ID": 1938,
            "OBJECTID": 356,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 18022783.5163,
            "FEATURE_LENGTH_M": 21622.4136,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.74831312137199,
            "lng": -132.009190737404
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.7717927652,
                "lng": -131.942367981
            },
            {
                "lat": 52.7712212756,
                "lng": -131.949463538
            },
            {
                "lat": 52.7712213431,
                "lng": -131.9971616152
            },
            {
                "lat": 52.7692025012,
                "lng": -132.0019533756
            },
            {
                "lat": 52.759567143,
                "lng": -131.9890745065
            },
            {
                "lat": 52.7512014043,
                "lng": -131.9781337025
            },
            {
                "lat": 52.7389716882,
                "lng": -131.9645235229
            },
            {
                "lat": 52.7265621289,
                "lng": -131.9495386715
            },
            {
                "lat": 52.7168155565,
                "lng": -131.9363088791
            },
            {
                "lat": 52.706245321,
                "lng": -131.9191132598
            },
            {
                "lat": 52.7072873661,
                "lng": -131.9124608476
            },
            {
                "lat": 52.7089915537,
                "lng": -131.9110868412
            },
            {
                "lat": 52.714885499,
                "lng": -131.9098349951
            },
            {
                "lat": 52.7230686107,
                "lng": -131.9102943452
            },
            {
                "lat": 52.7302398318,
                "lng": -131.9162603325
            },
            {
                "lat": 52.735798415,
                "lng": -131.9276119531
            },
            {
                "lat": 52.7413627937,
                "lng": -131.9358520187
            },
            {
                "lat": 52.7455935991,
                "lng": -131.9405367125
            },
            {
                "lat": 52.7483515131,
                "lng": -131.9393464843
            },
            {
                "lat": 52.7505457203,
                "lng": -131.9367211404
            },
            {
                "lat": 52.7520292878,
                "lng": -131.9339147401
            },
            {
                "lat": 52.754970968,
                "lng": -131.9314273132
            },
            {
                "lat": 52.7599336462,
                "lng": -131.9325412305
            },
            {
                "lat": 52.7658194453,
                "lng": -131.9365844496
            },
            {
                "lat": 52.7717927652,
                "lng": -131.942367981
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 213,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 46,
            "MANAGEMENT_AREA_NAME": "Subarea 2-46",
            "LABEL": "2-46",
            "FISHERY_AREA_ID": 1937,
            "OBJECTID": 357,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 18504982.8057,
            "FEATURE_LENGTH_M": 21886.1391,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.74373888567999,
            "lng": -131.94177921748403
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.870292814,
                "lng": -129.1293026644
            },
            {
                "lat": 52.8716501827,
                "lng": -129.1343680319
            },
            {
                "lat": 52.8701710547,
                "lng": -129.1369170493
            },
            {
                "lat": 52.8022193491,
                "lng": -129.2160332828
            },
            {
                "lat": 52.7953610879,
                "lng": -129.2241058539
            },
            {
                "lat": 52.7770386729,
                "lng": -129.2031712487
            },
            {
                "lat": 52.7622827278,
                "lng": -129.1893613134
            },
            {
                "lat": 52.7527660727,
                "lng": -129.1698459135
            },
            {
                "lat": 52.7368241186,
                "lng": -129.1303553561
            },
            {
                "lat": 52.7287332371,
                "lng": -129.1105951334
            },
            {
                "lat": 52.7185024979,
                "lng": -129.101806545
            },
            {
                "lat": 52.7100986683,
                "lng": -129.0940097332
            },
            {
                "lat": 52.7185631211,
                "lng": -129.0875707416
            },
            {
                "lat": 52.7210194276,
                "lng": -129.0867765051
            },
            {
                "lat": 52.7348332302,
                "lng": -129.0565493923
            },
            {
                "lat": 52.7401919262,
                "lng": -129.0500031243
            },
            {
                "lat": 52.7487599297,
                "lng": -129.0542760374
            },
            {
                "lat": 52.7601815912,
                "lng": -129.0204924727
            },
            {
                "lat": 52.7735063231,
                "lng": -128.9786069823
            },
            {
                "lat": 52.7858815525,
                "lng": -128.9845584806
            },
            {
                "lat": 52.7868315128,
                "lng": -129.0069269528
            },
            {
                "lat": 52.7787395977,
                "lng": -129.0262003178
            },
            {
                "lat": 52.7799301345,
                "lng": -129.0457160546
            },
            {
                "lat": 52.7925412988,
                "lng": -129.0542760702
            },
            {
                "lat": 52.8053889449,
                "lng": -129.0702213481
            },
            {
                "lat": 52.8132439263,
                "lng": -129.0799860457
            },
            {
                "lat": 52.8124695762,
                "lng": -129.1026458687
            },
            {
                "lat": 52.8398669463,
                "lng": -129.1189268478
            },
            {
                "lat": 52.8506015953,
                "lng": -129.1201935372
            },
            {
                "lat": 52.8632126443,
                "lng": -129.1237640565
            },
            {
                "lat": 52.870292814,
                "lng": -129.1293026644
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 214,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 6-14",
            "LABEL": "6-14",
            "FISHERY_AREA_ID": 2421,
            "OBJECTID": 358,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 118749759.2396,
            "FEATURE_LENGTH_M": 53976.7493,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.7861934379484,
            "lng": -129.09796340728064
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.8701710547,
                "lng": -129.1369170493
            },
            {
                "lat": 52.857284728,
                "lng": -129.3527381925
            },
            {
                "lat": 52.8337784351,
                "lng": -129.3152156965
            },
            {
                "lat": 52.8269771598,
                "lng": -129.2762746677
            },
            {
                "lat": 52.817390249,
                "lng": -129.2685851329
            },
            {
                "lat": 52.7996403334,
                "lng": -129.240523713
            },
            {
                "lat": 52.7953610879,
                "lng": -129.2241058539
            },
            {
                "lat": 52.8022193491,
                "lng": -129.2160332828
            },
            {
                "lat": 52.8701710547,
                "lng": -129.1369170493
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 215,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 6-11",
            "LABEL": "6-11",
            "FISHERY_AREA_ID": 2418,
            "OBJECTID": 359,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 57329877.5603,
            "FEATURE_LENGTH_M": 36261.4192,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.83033260574445,
            "lng": -129.2408122931
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.8850556748,
                "lng": -128.4936823306
            },
            {
                "lat": 52.883532802,
                "lng": -128.4980472207
            },
            {
                "lat": 52.8835409381,
                "lng": -128.5105587351
            },
            {
                "lat": 52.881733016,
                "lng": -128.5104987577
            },
            {
                "lat": 52.8779370442,
                "lng": -128.5078132496
            },
            {
                "lat": 52.8156357632,
                "lng": -128.4841455945
            },
            {
                "lat": 52.7965998282,
                "lng": -128.4871213674
            },
            {
                "lat": 52.7636334552,
                "lng": -128.5080563538
            },
            {
                "lat": 52.650985337,
                "lng": -128.5056920942
            },
            {
                "lat": 52.6489177675,
                "lng": -128.507660521
            },
            {
                "lat": 52.642154977,
                "lng": -128.5055081681
            },
            {
                "lat": 52.6419713155,
                "lng": -128.4472050988
            },
            {
                "lat": 52.6372144924,
                "lng": -128.4402469957
            },
            {
                "lat": 52.6461948458,
                "lng": -128.4338224141
            },
            {
                "lat": 52.6647523936,
                "lng": -128.420486669
            },
            {
                "lat": 52.6608887968,
                "lng": -128.3927763249
            },
            {
                "lat": 52.6507754323,
                "lng": -128.3481592866
            },
            {
                "lat": 52.662075096,
                "lng": -128.3326873202
            },
            {
                "lat": 52.690036666,
                "lng": -128.3469696142
            },
            {
                "lat": 52.7191846181,
                "lng": -128.3826596261
            },
            {
                "lat": 52.7465479273,
                "lng": -128.3927762241
            },
            {
                "lat": 52.7673679204,
                "lng": -128.3832554529
            },
            {
                "lat": 52.7977907842,
                "lng": -128.3833925493
            },
            {
                "lat": 52.80527489,
                "lng": -128.3923652581
            },
            {
                "lat": 52.8187324623,
                "lng": -128.3916479503
            },
            {
                "lat": 52.819416109,
                "lng": -128.3909759199
            },
            {
                "lat": 52.8228036275,
                "lng": -128.3801275398
            },
            {
                "lat": 52.8398819129,
                "lng": -128.3820945718
            },
            {
                "lat": 52.8440202226,
                "lng": -128.3954161404
            },
            {
                "lat": 52.8373532003,
                "lng": -128.4222251318
            },
            {
                "lat": 52.8737910685,
                "lng": -128.4820409767
            },
            {
                "lat": 52.8850556748,
                "lng": -128.4936823306
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 216,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 7-6",
            "LABEL": "7-6",
            "FISHERY_AREA_ID": 2441,
            "OBJECTID": 360,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 194985326.4526,
            "FEATURE_LENGTH_M": 75153.8228,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.767526751859364,
            "lng": -128.436056180875
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.857284728,
                "lng": -129.3527381925
            },
            {
                "lat": 52.8572846357,
                "lng": -129.5189506928
            },
            {
                "lat": 52.8499981136,
                "lng": -129.5103605768
            },
            {
                "lat": 52.4535864974,
                "lng": -129.0429531053
            },
            {
                "lat": 52.4535900994,
                "lng": -128.9763486093
            },
            {
                "lat": 52.4565958111,
                "lng": -128.9760584878
            },
            {
                "lat": 52.4649242649,
                "lng": -128.9751135188
            },
            {
                "lat": 52.473427451,
                "lng": -128.9786068445
            },
            {
                "lat": 52.4862783621,
                "lng": -128.9824067639
            },
            {
                "lat": 52.5043600177,
                "lng": -128.9902644705
            },
            {
                "lat": 52.5412438117,
                "lng": -129.0200046378
            },
            {
                "lat": 52.5447537881,
                "lng": -129.0495758396
            },
            {
                "lat": 52.6838910447,
                "lng": -129.1613460945
            },
            {
                "lat": 52.6988142103,
                "lng": -129.198638679
            },
            {
                "lat": 52.7154686728,
                "lng": -129.2295832761
            },
            {
                "lat": 52.7404513349,
                "lng": -129.2498007369
            },
            {
                "lat": 52.7684134545,
                "lng": -129.2688440404
            },
            {
                "lat": 52.7922061539,
                "lng": -129.2658691873
            },
            {
                "lat": 52.8074949804,
                "lng": -129.2682493083
            },
            {
                "lat": 52.817390249,
                "lng": -129.2685851329
            },
            {
                "lat": 52.8269771598,
                "lng": -129.2762746677
            },
            {
                "lat": 52.8337784351,
                "lng": -129.3152156965
            },
            {
                "lat": 52.857284728,
                "lng": -129.3527381925
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 217,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 6-13",
            "LABEL": "6-13",
            "FISHERY_AREA_ID": 2420,
            "OBJECTID": 361,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 406349225.8149,
            "FEATURE_LENGTH_M": 125985.0102,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.67328252191738,
            "lng": -129.18384898920434
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.881733016,
                "lng": -128.5104987577
            },
            {
                "lat": 52.8792720786,
                "lng": -128.5110623393
            },
            {
                "lat": 52.8679812196,
                "lng": -128.5136570566
            },
            {
                "lat": 52.860679477,
                "lng": -128.5132292667
            },
            {
                "lat": 52.8508294136,
                "lng": -128.5126495599
            },
            {
                "lat": 52.833297517,
                "lng": -128.5134128788
            },
            {
                "lat": 52.821216779,
                "lng": -128.5178378256
            },
            {
                "lat": 52.8065146405,
                "lng": -128.5195462998
            },
            {
                "lat": 52.7984845604,
                "lng": -128.5178530145
            },
            {
                "lat": 52.7830085879,
                "lng": -128.5222927853
            },
            {
                "lat": 52.6919866795,
                "lng": -128.5329129025
            },
            {
                "lat": 52.6770741213,
                "lng": -128.5296025729
            },
            {
                "lat": 52.6691855236,
                "lng": -128.5278013673
            },
            {
                "lat": 52.6552508939,
                "lng": -128.5163111886
            },
            {
                "lat": 52.6489177675,
                "lng": -128.507660521
            },
            {
                "lat": 52.650985337,
                "lng": -128.5056920942
            },
            {
                "lat": 52.7636334552,
                "lng": -128.5080563538
            },
            {
                "lat": 52.7965998282,
                "lng": -128.4871213674
            },
            {
                "lat": 52.8156357632,
                "lng": -128.4841455945
            },
            {
                "lat": 52.8779370442,
                "lng": -128.5078132496
            },
            {
                "lat": 52.881733016,
                "lng": -128.5104987577
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 218,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 362,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 36914815.0838,
            "FEATURE_LENGTH_M": 52987.4652,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.78628365329524,
            "lng": -128.5128407501762
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.6898724523,
                "lng": -127.0222707502
            },
            {
                "lat": 52.6680492523,
                "lng": -127.0101084903
            },
            {
                "lat": 52.6706129206,
                "lng": -126.9756093389
            },
            {
                "lat": 52.6703184613,
                "lng": -126.9672705142
            },
            {
                "lat": 52.6690982355,
                "lng": -126.944663531
            },
            {
                "lat": 52.6874314833,
                "lng": -126.9281618998
            },
            {
                "lat": 52.7027096777,
                "lng": -126.9061584873
            },
            {
                "lat": 52.7271496471,
                "lng": -126.8872145942
            },
            {
                "lat": 52.7460975645,
                "lng": -126.895165003
            },
            {
                "lat": 52.7815404179,
                "lng": -126.9202203701
            },
            {
                "lat": 52.8255385425,
                "lng": -126.9446639483
            },
            {
                "lat": 52.854873629,
                "lng": -126.971550104
            },
            {
                "lat": 52.8695376227,
                "lng": -127.022270827
            },
            {
                "lat": 52.8915397915,
                "lng": -127.0589369881
            },
            {
                "lat": 52.9025378787,
                "lng": -127.0882720861
            },
            {
                "lat": 52.8817601861,
                "lng": -127.1047671133
            },
            {
                "lat": 52.846927286,
                "lng": -127.0674897446
            },
            {
                "lat": 52.8096497162,
                "lng": -127.0234903954
            },
            {
                "lat": 52.7668718901,
                "lng": -127.0167686201
            },
            {
                "lat": 52.7314304952,
                "lng": -126.9953845179
            },
            {
                "lat": 52.7228737384,
                "lng": -126.9837720482
            },
            {
                "lat": 52.7045399556,
                "lng": -126.9996575226
            },
            {
                "lat": 52.6898724523,
                "lng": -127.0222707502
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 219,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 8-9",
            "LABEL": "8-9",
            "FISHERY_AREA_ID": 2475,
            "OBJECTID": 363,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 156499253.1177,
            "FEATURE_LENGTH_M": 66653.7762,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.76134057812173,
            "lng": -126.98939728890437
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.8313404736,
                "lng": -128.1544942936
            },
            {
                "lat": 52.8405837745,
                "lng": -128.1504519097
            },
            {
                "lat": 52.8443790542,
                "lng": -128.1463317308
            },
            {
                "lat": 52.8573568205,
                "lng": -128.1613920499
            },
            {
                "lat": 52.8665772936,
                "lng": -128.1690679513
            },
            {
                "lat": 52.8465266395,
                "lng": -128.2259517824
            },
            {
                "lat": 52.8342204207,
                "lng": -128.2352906838
            },
            {
                "lat": 52.8216060465,
                "lng": -128.2551580631
            },
            {
                "lat": 52.8136592871,
                "lng": -128.2885588168
            },
            {
                "lat": 52.8166965382,
                "lng": -128.3427735366
            },
            {
                "lat": 52.8213543441,
                "lng": -128.3760075885
            },
            {
                "lat": 52.8228036275,
                "lng": -128.3801275398
            },
            {
                "lat": 52.819416109,
                "lng": -128.3909759199
            },
            {
                "lat": 52.8187324623,
                "lng": -128.3916479503
            },
            {
                "lat": 52.80527489,
                "lng": -128.3923652581
            },
            {
                "lat": 52.7977907842,
                "lng": -128.3833925493
            },
            {
                "lat": 52.7941169246,
                "lng": -128.3732448121
            },
            {
                "lat": 52.7905811649,
                "lng": -128.3671882045
            },
            {
                "lat": 52.7825170316,
                "lng": -128.3613439638
            },
            {
                "lat": 52.7807465654,
                "lng": -128.3522494991
            },
            {
                "lat": 52.7813191642,
                "lng": -128.3513177277
            },
            {
                "lat": 52.7813145806,
                "lng": -128.3471530955
            },
            {
                "lat": 52.780922166,
                "lng": -128.3462834195
            },
            {
                "lat": 52.7810445611,
                "lng": -128.337066909
            },
            {
                "lat": 52.776710454,
                "lng": -128.3239442393
            },
            {
                "lat": 52.7646401333,
                "lng": -128.2829442384
            },
            {
                "lat": 52.757980238,
                "lng": -128.2391660819
            },
            {
                "lat": 52.7586939134,
                "lng": -128.2103725075
            },
            {
                "lat": 52.7727320489,
                "lng": -128.1965640616
            },
            {
                "lat": 52.7879604253,
                "lng": -128.191574495
            },
            {
                "lat": 52.8039015828,
                "lng": -128.1839590326
            },
            {
                "lat": 52.8177035671,
                "lng": -128.1687312149
            },
            {
                "lat": 52.8313404736,
                "lng": -128.1544942936
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 220,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 29,
            "MANAGEMENT_AREA_NAME": "Subarea 7-29",
            "LABEL": "7-29",
            "FISHERY_AREA_ID": 2463,
            "OBJECTID": 364,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 93989710.966,
            "FEATURE_LENGTH_M": 46665.7003,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.80613768364545,
            "lng": -128.27974501272422
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.705600242,
                "lng": -131.7260136236
            },
            {
                "lat": 52.7080428186,
                "lng": -131.7293538946
            },
            {
                "lat": 52.7110905298,
                "lng": -131.7334444311
            },
            {
                "lat": 52.7125211528,
                "lng": -131.7335509575
            },
            {
                "lat": 52.7134814818,
                "lng": -131.7360690534
            },
            {
                "lat": 52.7381779623,
                "lng": -131.763336217
            },
            {
                "lat": 52.7417985492,
                "lng": -131.7644199011
            },
            {
                "lat": 52.7426182843,
                "lng": -131.7682495699
            },
            {
                "lat": 52.7426259271,
                "lng": -131.8032834066
            },
            {
                "lat": 52.7421570495,
                "lng": -131.8078461289
            },
            {
                "lat": 52.7324026236,
                "lng": -131.8240213114
            },
            {
                "lat": 52.7219356389,
                "lng": -131.8449713391
            },
            {
                "lat": 52.7095610194,
                "lng": -131.8544760355
            },
            {
                "lat": 52.6990892987,
                "lng": -131.8535306875
            },
            {
                "lat": 52.6929051608,
                "lng": -131.8409118267
            },
            {
                "lat": 52.6841006552,
                "lng": -131.7999882578
            },
            {
                "lat": 52.6745836618,
                "lng": -131.7723853518
            },
            {
                "lat": 52.6562613229,
                "lng": -131.7666787135
            },
            {
                "lat": 52.6367488202,
                "lng": -131.7693019996
            },
            {
                "lat": 52.6212805789,
                "lng": -131.7645420818
            },
            {
                "lat": 52.5989152273,
                "lng": -131.7443086162
            },
            {
                "lat": 52.572979048,
                "lng": -131.7238467855
            },
            {
                "lat": 52.5713116187,
                "lng": -131.7088613651
            },
            {
                "lat": 52.5744061674,
                "lng": -131.6931605624
            },
            {
                "lat": 52.5758354404,
                "lng": -131.6784060025
            },
            {
                "lat": 52.5722649655,
                "lng": -131.6662598194
            },
            {
                "lat": 52.5667910606,
                "lng": -131.6531826147
            },
            {
                "lat": 52.5656017409,
                "lng": -131.6434176856
            },
            {
                "lat": 52.5677445552,
                "lng": -131.6372367889
            },
            {
                "lat": 52.5696493248,
                "lng": -131.6293790286
            },
            {
                "lat": 52.5668567067,
                "lng": -131.6244046112
            },
            {
                "lat": 52.5671045474,
                "lng": -131.6182105929
            },
            {
                "lat": 52.580646794,
                "lng": -131.5809635838
            },
            {
                "lat": 52.5833012575,
                "lng": -131.5840607764
            },
            {
                "lat": 52.5848774929,
                "lng": -131.5891258587
            },
            {
                "lat": 52.5867808401,
                "lng": -131.5950777251
            },
            {
                "lat": 52.601054527,
                "lng": -131.6131744865
            },
            {
                "lat": 52.6203304936,
                "lng": -131.6329188723
            },
            {
                "lat": 52.6357952419,
                "lng": -131.6486200735
            },
            {
                "lat": 52.637847752,
                "lng": -131.647598011
            },
            {
                "lat": 52.6419828648,
                "lng": -131.6455231971
            },
            {
                "lat": 52.6469802693,
                "lng": -131.6360171733
            },
            {
                "lat": 52.6531679709,
                "lng": -131.6371919822
            },
            {
                "lat": 52.65959192,
                "lng": -131.6452940978
            },
            {
                "lat": 52.6657795255,
                "lng": -131.6559906117
            },
            {
                "lat": 52.6693195933,
                "lng": -131.6660151942
            },
            {
                "lat": 52.6716802821,
                "lng": -131.6695555087
            },
            {
                "lat": 52.6753811367,
                "lng": -131.6782522538
            },
            {
                "lat": 52.682629082,
                "lng": -131.6926879131
            },
            {
                "lat": 52.6860086037,
                "lng": -131.6994020132
            },
            {
                "lat": 52.6885839511,
                "lng": -131.7017056709
            },
            {
                "lat": 52.6946493039,
                "lng": -131.7098084605
            },
            {
                "lat": 52.6965793136,
                "lng": -131.7150267934
            },
            {
                "lat": 52.6986272559,
                "lng": -131.7183835151
            },
            {
                "lat": 52.7041241947,
                "lng": -131.724792056
            },
            {
                "lat": 52.705600242,
                "lng": -131.7260136236
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 221,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 2-10",
            "LABEL": "2-10",
            "FISHERY_AREA_ID": 1901,
            "OBJECTID": 365,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 152569151.9262,
            "FEATURE_LENGTH_M": 61321.0949,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.65406719802143,
            "lng": -131.7051830127375
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.7953610879,
                "lng": -129.2241058539
            },
            {
                "lat": 52.7996403334,
                "lng": -129.240523713
            },
            {
                "lat": 52.817390249,
                "lng": -129.2685851329
            },
            {
                "lat": 52.8074949804,
                "lng": -129.2682493083
            },
            {
                "lat": 52.7922061539,
                "lng": -129.2658691873
            },
            {
                "lat": 52.7684134545,
                "lng": -129.2688440404
            },
            {
                "lat": 52.7404513349,
                "lng": -129.2498007369
            },
            {
                "lat": 52.7154686728,
                "lng": -129.2295832761
            },
            {
                "lat": 52.6988142103,
                "lng": -129.198638679
            },
            {
                "lat": 52.6838910447,
                "lng": -129.1613460945
            },
            {
                "lat": 52.5447537881,
                "lng": -129.0495758396
            },
            {
                "lat": 52.5412438117,
                "lng": -129.0200046378
            },
            {
                "lat": 52.5043600177,
                "lng": -128.9902644705
            },
            {
                "lat": 52.4862783621,
                "lng": -128.9824067639
            },
            {
                "lat": 52.473427451,
                "lng": -128.9786068445
            },
            {
                "lat": 52.4649242649,
                "lng": -128.9751135188
            },
            {
                "lat": 52.4739686059,
                "lng": -128.9689330195
            },
            {
                "lat": 52.480152449,
                "lng": -128.9558410127
            },
            {
                "lat": 52.4809838684,
                "lng": -128.9494020649
            },
            {
                "lat": 52.4927673223,
                "lng": -128.9470980263
            },
            {
                "lat": 52.5293009712,
                "lng": -128.9605103874
            },
            {
                "lat": 52.542731755,
                "lng": -128.9608300626
            },
            {
                "lat": 52.5886574994,
                "lng": -128.9558407873
            },
            {
                "lat": 52.6091200699,
                "lng": -128.9608303087
            },
            {
                "lat": 52.6314204778,
                "lng": -128.991653646
            },
            {
                "lat": 52.64119003,
                "lng": -129.0015562605
            },
            {
                "lat": 52.6538351741,
                "lng": -129.0143895486
            },
            {
                "lat": 52.6660572828,
                "lng": -129.0226900352
            },
            {
                "lat": 52.6660573257,
                "lng": -129.0327148694
            },
            {
                "lat": 52.6710738046,
                "lng": -129.0529324317
            },
            {
                "lat": 52.6925463682,
                "lng": -129.0773925262
            },
            {
                "lat": 52.7100986683,
                "lng": -129.0940097332
            },
            {
                "lat": 52.7185024979,
                "lng": -129.101806545
            },
            {
                "lat": 52.7287332371,
                "lng": -129.1105951334
            },
            {
                "lat": 52.7368241186,
                "lng": -129.1303553561
            },
            {
                "lat": 52.7527660727,
                "lng": -129.1698459135
            },
            {
                "lat": 52.7622827278,
                "lng": -129.1893613134
            },
            {
                "lat": 52.7770386729,
                "lng": -129.2031712487
            },
            {
                "lat": 52.7953610879,
                "lng": -129.2241058539
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 222,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 366,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 231954686.4789,
            "FEATURE_LENGTH_M": 95397.1531,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.65219459755899,
            "lng": -129.0883944661949
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.722896354,
                "lng": -131.6849056648
            },
            {
                "lat": 52.7111741917,
                "lng": -131.6977846665
            },
            {
                "lat": 52.7091287845,
                "lng": -131.7183985677
            },
            {
                "lat": 52.7067377328,
                "lng": -131.7191923597
            },
            {
                "lat": 52.7057272034,
                "lng": -131.7212532553
            },
            {
                "lat": 52.705600242,
                "lng": -131.7260136236
            },
            {
                "lat": 52.7041241947,
                "lng": -131.724792056
            },
            {
                "lat": 52.6986272559,
                "lng": -131.7183835151
            },
            {
                "lat": 52.6965793136,
                "lng": -131.7150267934
            },
            {
                "lat": 52.6946493039,
                "lng": -131.7098084605
            },
            {
                "lat": 52.6885839511,
                "lng": -131.7017056709
            },
            {
                "lat": 52.6860086037,
                "lng": -131.6994020132
            },
            {
                "lat": 52.682629082,
                "lng": -131.6926879131
            },
            {
                "lat": 52.6753811367,
                "lng": -131.6782522538
            },
            {
                "lat": 52.6716802821,
                "lng": -131.6695555087
            },
            {
                "lat": 52.6693195933,
                "lng": -131.6660151942
            },
            {
                "lat": 52.6657795255,
                "lng": -131.6559906117
            },
            {
                "lat": 52.65959192,
                "lng": -131.6452940978
            },
            {
                "lat": 52.6531679709,
                "lng": -131.6371919822
            },
            {
                "lat": 52.6469802693,
                "lng": -131.6360171733
            },
            {
                "lat": 52.6419828648,
                "lng": -131.6455231971
            },
            {
                "lat": 52.637847752,
                "lng": -131.647598011
            },
            {
                "lat": 52.6357952419,
                "lng": -131.6486200735
            },
            {
                "lat": 52.6203304936,
                "lng": -131.6329188723
            },
            {
                "lat": 52.601054527,
                "lng": -131.6131744865
            },
            {
                "lat": 52.5867808401,
                "lng": -131.5950777251
            },
            {
                "lat": 52.5848774929,
                "lng": -131.5891258587
            },
            {
                "lat": 52.5833012575,
                "lng": -131.5840607764
            },
            {
                "lat": 52.5898975814,
                "lng": -131.5831915671
            },
            {
                "lat": 52.5969617044,
                "lng": -131.5866246152
            },
            {
                "lat": 52.6076621188,
                "lng": -131.5977332399
            },
            {
                "lat": 52.6197740725,
                "lng": -131.615890163
            },
            {
                "lat": 52.6304704518,
                "lng": -131.6271974882
            },
            {
                "lat": 52.6369277624,
                "lng": -131.6276096048
            },
            {
                "lat": 52.6474261152,
                "lng": -131.6227572841
            },
            {
                "lat": 52.6550947086,
                "lng": -131.61407556
            },
            {
                "lat": 52.6607477672,
                "lng": -131.6058036247
            },
            {
                "lat": 52.6609490252,
                "lng": -131.593078783
            },
            {
                "lat": 52.656508818,
                "lng": -131.5737152609
            },
            {
                "lat": 52.6490396919,
                "lng": -131.5430290627
            },
            {
                "lat": 52.6385460849,
                "lng": -131.5315247142
            },
            {
                "lat": 52.6307522779,
                "lng": -131.5249020288
            },
            {
                "lat": 52.6847151931,
                "lng": -131.4645388345
            },
            {
                "lat": 52.6930424235,
                "lng": -131.4628902762
            },
            {
                "lat": 52.7047505013,
                "lng": -131.4507747156
            },
            {
                "lat": 52.7072103261,
                "lng": -131.4518740864
            },
            {
                "lat": 52.712283866,
                "lng": -131.4619449385
            },
            {
                "lat": 52.720836726,
                "lng": -131.4847870257
            },
            {
                "lat": 52.7270429713,
                "lng": -131.4907683057
            },
            {
                "lat": 52.7269288849,
                "lng": -131.4996643162
            },
            {
                "lat": 52.7263406863,
                "lng": -131.5119626803
            },
            {
                "lat": 52.7241175669,
                "lng": -131.5211021503
            },
            {
                "lat": 52.7221451279,
                "lng": -131.5279083137
            },
            {
                "lat": 52.7215385348,
                "lng": -131.5300142553
            },
            {
                "lat": 52.7047506156,
                "lng": -131.5367575769
            },
            {
                "lat": 52.6833381585,
                "lng": -131.5468744302
            },
            {
                "lat": 52.6756026061,
                "lng": -131.5558014343
            },
            {
                "lat": 52.6732250268,
                "lng": -131.5694729869
            },
            {
                "lat": 52.6851195722,
                "lng": -131.5766141793
            },
            {
                "lat": 52.6955456522,
                "lng": -131.5792242049
            },
            {
                "lat": 52.7002068472,
                "lng": -131.592315901
            },
            {
                "lat": 52.6899376281,
                "lng": -131.6099091094
            },
            {
                "lat": 52.6845930345,
                "lng": -131.6239778415
            },
            {
                "lat": 52.6831705359,
                "lng": -131.6382289813
            },
            {
                "lat": 52.6883358299,
                "lng": -131.649459625
            },
            {
                "lat": 52.6929668489,
                "lng": -131.6629943647
            },
            {
                "lat": 52.6940376883,
                "lng": -131.6790317719
            },
            {
                "lat": 52.695282495,
                "lng": -131.6882937821
            },
            {
                "lat": 52.7024115713,
                "lng": -131.6913145601
            },
            {
                "lat": 52.7116734421,
                "lng": -131.6872251901
            },
            {
                "lat": 52.722896354,
                "lng": -131.6849056648
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 223,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 367,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 85882194.5357,
            "FEATURE_LENGTH_M": 75743.5322,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.67438228557465,
            "lng": -131.6119934490352
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.7215385348,
                "lng": -131.5300142553
            },
            {
                "lat": 52.7233883645,
                "lng": -131.5342407343
            },
            {
                "lat": 52.7233919144,
                "lng": -131.5746915527
            },
            {
                "lat": 52.7243232594,
                "lng": -131.5767662922
            },
            {
                "lat": 52.7243233803,
                "lng": -131.5810390363
            },
            {
                "lat": 52.7237889644,
                "lng": -131.5888830816
            },
            {
                "lat": 52.7227217313,
                "lng": -131.6118617406
            },
            {
                "lat": 52.7211149269,
                "lng": -131.6382293875
            },
            {
                "lat": 52.7236097389,
                "lng": -131.6640629449
            },
            {
                "lat": 52.722896354,
                "lng": -131.6849056648
            },
            {
                "lat": 52.7116734421,
                "lng": -131.6872251901
            },
            {
                "lat": 52.7024115713,
                "lng": -131.6913145601
            },
            {
                "lat": 52.695282495,
                "lng": -131.6882937821
            },
            {
                "lat": 52.6940376883,
                "lng": -131.6790317719
            },
            {
                "lat": 52.6929668489,
                "lng": -131.6629943647
            },
            {
                "lat": 52.6883358299,
                "lng": -131.649459625
            },
            {
                "lat": 52.6831705359,
                "lng": -131.6382289813
            },
            {
                "lat": 52.6845930345,
                "lng": -131.6239778415
            },
            {
                "lat": 52.6899376281,
                "lng": -131.6099091094
            },
            {
                "lat": 52.7002068472,
                "lng": -131.592315901
            },
            {
                "lat": 52.6955456522,
                "lng": -131.5792242049
            },
            {
                "lat": 52.6851195722,
                "lng": -131.5766141793
            },
            {
                "lat": 52.6732250268,
                "lng": -131.5694729869
            },
            {
                "lat": 52.6756026061,
                "lng": -131.5558014343
            },
            {
                "lat": 52.6833381585,
                "lng": -131.5468744302
            },
            {
                "lat": 52.7047506156,
                "lng": -131.5367575769
            },
            {
                "lat": 52.7215385348,
                "lng": -131.5300142553
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 224,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 2-9",
            "LABEL": "2-9",
            "FISHERY_AREA_ID": 1900,
            "OBJECTID": 368,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 39659120.2783,
            "FEATURE_LENGTH_M": 31244.7485,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.7041790094926,
            "lng": -131.60748906981857
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.7707672638,
                "lng": -128.1645507283
            },
            {
                "lat": 52.7868082068,
                "lng": -128.1619265665
            },
            {
                "lat": 52.8065835353,
                "lng": -128.1582021271
            },
            {
                "lat": 52.8313404736,
                "lng": -128.1544942936
            },
            {
                "lat": 52.8177035671,
                "lng": -128.1687312149
            },
            {
                "lat": 52.8039015828,
                "lng": -128.1839590326
            },
            {
                "lat": 52.7879604253,
                "lng": -128.191574495
            },
            {
                "lat": 52.7727320489,
                "lng": -128.1965640616
            },
            {
                "lat": 52.7586939134,
                "lng": -128.2103725075
            },
            {
                "lat": 52.757980238,
                "lng": -128.2391660819
            },
            {
                "lat": 52.7646401333,
                "lng": -128.2829442384
            },
            {
                "lat": 52.776710454,
                "lng": -128.3239442393
            },
            {
                "lat": 52.7502409867,
                "lng": -128.3163916375
            },
            {
                "lat": 52.7157363937,
                "lng": -128.2896118807
            },
            {
                "lat": 52.6859934506,
                "lng": -128.2551112529
            },
            {
                "lat": 52.6645812824,
                "lng": -128.254516502
            },
            {
                "lat": 52.6193692095,
                "lng": -128.2777251597
            },
            {
                "lat": 52.5991442869,
                "lng": -128.2860414282
            },
            {
                "lat": 52.5934946641,
                "lng": -128.2863618859
            },
            {
                "lat": 52.6011049058,
                "lng": -128.2767793834
            },
            {
                "lat": 52.6734542632,
                "lng": -128.2245022413
            },
            {
                "lat": 52.6855246974,
                "lng": -128.2226252461
            },
            {
                "lat": 52.7040823895,
                "lng": -128.2322547894
            },
            {
                "lat": 52.7152979391,
                "lng": -128.2376247383
            },
            {
                "lat": 52.7193140921,
                "lng": -128.2375185345
            },
            {
                "lat": 52.7237083936,
                "lng": -128.2373968581
            },
            {
                "lat": 52.728614201,
                "lng": -128.2306212874
            },
            {
                "lat": 52.7302593283,
                "lng": -128.2219081498
            },
            {
                "lat": 52.7260438425,
                "lng": -128.2065578865
            },
            {
                "lat": 52.7272148649,
                "lng": -128.1948700218
            },
            {
                "lat": 52.7365611268,
                "lng": -128.1846011193
            },
            {
                "lat": 52.7501106086,
                "lng": -128.1743169714
            },
            {
                "lat": 52.7707672638,
                "lng": -128.1645507283
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 225,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 369,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 75156263.0801,
            "FEATURE_LENGTH_M": 70027.4121,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.72898303129697,
            "lng": -128.22570658452122
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.7051964489,
                "lng": -131.3897409344
            },
            {
                "lat": 52.7051962925,
                "lng": -131.4416200981
            },
            {
                "lat": 52.7047505013,
                "lng": -131.4507747156
            },
            {
                "lat": 52.6930424235,
                "lng": -131.4628902762
            },
            {
                "lat": 52.6847151931,
                "lng": -131.4645388345
            },
            {
                "lat": 52.6307522779,
                "lng": -131.5249020288
            },
            {
                "lat": 52.6385460849,
                "lng": -131.5315247142
            },
            {
                "lat": 52.6490396919,
                "lng": -131.5430290627
            },
            {
                "lat": 52.656508818,
                "lng": -131.5737152609
            },
            {
                "lat": 52.6609490252,
                "lng": -131.593078783
            },
            {
                "lat": 52.6607477672,
                "lng": -131.6058036247
            },
            {
                "lat": 52.6550947086,
                "lng": -131.61407556
            },
            {
                "lat": 52.6474261152,
                "lng": -131.6227572841
            },
            {
                "lat": 52.6369277624,
                "lng": -131.6276096048
            },
            {
                "lat": 52.6304704518,
                "lng": -131.6271974882
            },
            {
                "lat": 52.6197740725,
                "lng": -131.615890163
            },
            {
                "lat": 52.6076621188,
                "lng": -131.5977332399
            },
            {
                "lat": 52.5969617044,
                "lng": -131.5866246152
            },
            {
                "lat": 52.5898975814,
                "lng": -131.5831915671
            },
            {
                "lat": 52.5833012575,
                "lng": -131.5840607764
            },
            {
                "lat": 52.580646794,
                "lng": -131.5809635838
            },
            {
                "lat": 52.5671045474,
                "lng": -131.6182105929
            },
            {
                "lat": 52.5668567067,
                "lng": -131.6244046112
            },
            {
                "lat": 52.5650066879,
                "lng": -131.6264034173
            },
            {
                "lat": 52.5621535348,
                "lng": -131.6316376332
            },
            {
                "lat": 52.5581088264,
                "lng": -131.6392513109
            },
            {
                "lat": 52.557155584,
                "lng": -131.6473533503
            },
            {
                "lat": 52.5578689496,
                "lng": -131.6563875168
            },
            {
                "lat": 52.5619160099,
                "lng": -131.6704246035
            },
            {
                "lat": 52.5611989622,
                "lng": -131.6896974338
            },
            {
                "lat": 52.5545387936,
                "lng": -131.6963653991
            },
            {
                "lat": 52.5452578192,
                "lng": -131.6947025247
            },
            {
                "lat": 52.5352626652,
                "lng": -131.6868444836
            },
            {
                "lat": 52.5264627384,
                "lng": -131.6754309658
            },
            {
                "lat": 52.5228923423,
                "lng": -131.664962713
            },
            {
                "lat": 52.5162273043,
                "lng": -131.6485448213
            },
            {
                "lat": 52.5074228999,
                "lng": -131.6352081032
            },
            {
                "lat": 52.497905838,
                "lng": -131.6135558204
            },
            {
                "lat": 52.488628818,
                "lng": -131.5966642003
            },
            {
                "lat": 52.4831537823,
                "lng": -131.5840605667
            },
            {
                "lat": 52.4914821448,
                "lng": -131.5600283665
            },
            {
                "lat": 52.4938618206,
                "lng": -131.5514531842
            },
            {
                "lat": 52.5033800494,
                "lng": -131.5350340964
            },
            {
                "lat": 52.5086138726,
                "lng": -131.5231474572
            },
            {
                "lat": 52.5086138093,
                "lng": -131.5105286076
            },
            {
                "lat": 52.5055239784,
                "lng": -131.501251274
            },
            {
                "lat": 52.5012775102,
                "lng": -131.4798735535
            },
            {
                "lat": 52.4988593028,
                "lng": -131.4774630736
            },
            {
                "lat": 52.4988595752,
                "lng": -131.4707950976
            },
            {
                "lat": 52.5001527576,
                "lng": -131.4675138658
            },
            {
                "lat": 52.5638887943,
                "lng": -131.4389796218
            },
            {
                "lat": 52.5610879103,
                "lng": -131.4368752246
            },
            {
                "lat": 52.5587457144,
                "lng": -131.432830229
            },
            {
                "lat": 52.5582240842,
                "lng": -131.4037327488
            },
            {
                "lat": 52.5578994517,
                "lng": -131.3845216293
            },
            {
                "lat": 52.5619353357,
                "lng": -131.3740235485
            },
            {
                "lat": 52.56758874,
                "lng": -131.3711247127
            },
            {
                "lat": 52.5779229149,
                "lng": -131.3741909014
            },
            {
                "lat": 52.5827670113,
                "lng": -131.3737030169
            },
            {
                "lat": 52.5839693905,
                "lng": -131.3727722309
            },
            {
                "lat": 52.5850940382,
                "lng": -131.3722227596
            },
            {
                "lat": 52.5857158761,
                "lng": -131.3721320448
            },
            {
                "lat": 52.5857155801,
                "lng": -131.3200535352
            },
            {
                "lat": 52.7051964489,
                "lng": -131.3897409344
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 226,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 2-11",
            "LABEL": "2-11",
            "FISHERY_AREA_ID": 1902,
            "OBJECTID": 370,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 319593855.5664,
            "FEATURE_LENGTH_M": 93455.1787,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.5768610314469,
            "lng": -131.53774724996723
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.7825170316,
                "lng": -128.3613439638
            },
            {
                "lat": 52.7905811649,
                "lng": -128.3671882045
            },
            {
                "lat": 52.7941169246,
                "lng": -128.3732448121
            },
            {
                "lat": 52.7977907842,
                "lng": -128.3833925493
            },
            {
                "lat": 52.7673679204,
                "lng": -128.3832554529
            },
            {
                "lat": 52.7465479273,
                "lng": -128.3927762241
            },
            {
                "lat": 52.7191846181,
                "lng": -128.3826596261
            },
            {
                "lat": 52.690036666,
                "lng": -128.3469696142
            },
            {
                "lat": 52.662075096,
                "lng": -128.3326873202
            },
            {
                "lat": 52.6507754323,
                "lng": -128.3481592866
            },
            {
                "lat": 52.6608887968,
                "lng": -128.3927763249
            },
            {
                "lat": 52.6647523936,
                "lng": -128.420486669
            },
            {
                "lat": 52.6461948458,
                "lng": -128.4338224141
            },
            {
                "lat": 52.6372144924,
                "lng": -128.4402469957
            },
            {
                "lat": 52.6314771999,
                "lng": -128.4249264908
            },
            {
                "lat": 52.5876957624,
                "lng": -128.42445334
            },
            {
                "lat": 52.5584292983,
                "lng": -128.4232637932
            },
            {
                "lat": 52.5523602518,
                "lng": -128.4347375994
            },
            {
                "lat": 52.5511707088,
                "lng": -128.4133298019
            },
            {
                "lat": 52.5487900273,
                "lng": -128.3585962327
            },
            {
                "lat": 52.5416524893,
                "lng": -128.3264774009
            },
            {
                "lat": 52.5386769928,
                "lng": -128.3044733394
            },
            {
                "lat": 52.5481983318,
                "lng": -128.3003085163
            },
            {
                "lat": 52.574371359,
                "lng": -128.3080444377
            },
            {
                "lat": 52.6195800195,
                "lng": -128.313385638
            },
            {
                "lat": 52.6677666651,
                "lng": -128.3098304092
            },
            {
                "lat": 52.7004814204,
                "lng": -128.3300476803
            },
            {
                "lat": 52.7207073138,
                "lng": -128.3556207968
            },
            {
                "lat": 52.7539020291,
                "lng": -128.361968631
            },
            {
                "lat": 52.7825170316,
                "lng": -128.3613439638
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 227,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 371,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 124761726.2123,
            "FEATURE_LENGTH_M": 76264.5067,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.66292736649666,
            "lng": -128.37032725096336
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.5210605012,
                "lng": -132.9422294818
            },
            {
                "lat": 53.5213622224,
                "lng": -132.9450070389
            },
            {
                "lat": 53.5213621462,
                "lng": -132.945587072
            },
            {
                "lat": 53.5210500494,
                "lng": -132.9460744821
            },
            {
                "lat": 53.4683534731,
                "lng": -132.8994300159
            },
            {
                "lat": 53.4686432898,
                "lng": -132.898925958
            },
            {
                "lat": 53.4689670974,
                "lng": -132.8983307845
            },
            {
                "lat": 53.4689517125,
                "lng": -132.8977812021
            },
            {
                "lat": 53.4689981657,
                "lng": -132.8971261187
            },
            {
                "lat": 53.4691008631,
                "lng": -132.8965296112
            },
            {
                "lat": 53.4692299802,
                "lng": -132.89622472
            },
            {
                "lat": 53.4691847729,
                "lng": -132.89567496
            },
            {
                "lat": 53.4692648294,
                "lng": -132.8948670775
            },
            {
                "lat": 53.4698984195,
                "lng": -132.8939663614
            },
            {
                "lat": 53.4725538698,
                "lng": -132.8922728666
            },
            {
                "lat": 53.47385438,
                "lng": -132.8917690919
            },
            {
                "lat": 53.4787632008,
                "lng": -132.8898315338
            },
            {
                "lat": 53.4847109444,
                "lng": -132.8829343041
            },
            {
                "lat": 53.4920883794,
                "lng": -132.881745335
            },
            {
                "lat": 53.4918521436,
                "lng": -132.8772281827
            },
            {
                "lat": 53.4935153013,
                "lng": -132.8698421825
            },
            {
                "lat": 53.4950638254,
                "lng": -132.8622594763
            },
            {
                "lat": 53.4973758094,
                "lng": -132.8497467186
            },
            {
                "lat": 53.5008089859,
                "lng": -132.8442232066
            },
            {
                "lat": 53.5067028829,
                "lng": -132.8393713442
            },
            {
                "lat": 53.5124469231,
                "lng": -132.8359380797
            },
            {
                "lat": 53.5143466703,
                "lng": -132.8354189652
            },
            {
                "lat": 53.5173758694,
                "lng": -132.83459456
            },
            {
                "lat": 53.5202106503,
                "lng": -132.8372046906
            },
            {
                "lat": 53.5213284625,
                "lng": -132.8392183866
            },
            {
                "lat": 53.5228229126,
                "lng": -132.84190416
            },
            {
                "lat": 53.5239407341,
                "lng": -132.8516841997
            },
            {
                "lat": 53.5227473129,
                "lng": -132.8602609077
            },
            {
                "lat": 53.523823097,
                "lng": -132.8716893991
            },
            {
                "lat": 53.5226323645,
                "lng": -132.8959496422
            },
            {
                "lat": 53.5211256243,
                "lng": -132.9168847826
            },
            {
                "lat": 53.5210605012,
                "lng": -132.9422294818
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 120,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 85,
            "MANAGEMENT_AREA_NAME": "Subarea 2-85",
            "LABEL": "2-85",
            "FISHERY_AREA_ID": 1975,
            "OBJECTID": 120,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 24082062.0928,
            "FEATURE_LENGTH_M": 22200.3943,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.497475091024334,
            "lng": -132.88356638869195
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.6344727311,
                "lng": -130.5651011805
            },
            {
                "lat": 53.6275977203,
                "lng": -130.5408239089
            },
            {
                "lat": 53.6272661075,
                "lng": -130.5403047737
            },
            {
                "lat": 53.6241646701,
                "lng": -130.5384893122
            },
            {
                "lat": 53.6215898192,
                "lng": -130.5379554685
            },
            {
                "lat": 53.6180044541,
                "lng": -130.5372076497
            },
            {
                "lat": 53.6179587374,
                "lng": -130.5371258343
            },
            {
                "lat": 53.6180042106,
                "lng": -130.5371402579
            },
            {
                "lat": 53.6215895671,
                "lng": -130.53788807
            },
            {
                "lat": 53.6241649817,
                "lng": -130.5384219707
            },
            {
                "lat": 53.6272664201,
                "lng": -130.5402374272
            },
            {
                "lat": 53.6275980338,
                "lng": -130.5407565467
            },
            {
                "lat": 53.6344724943,
                "lng": -130.5650337611
            },
            {
                "lat": 53.6344727311,
                "lng": -130.5651011805
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 121,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 121,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 8193.091,
            "FEATURE_LENGTH_M": 5787.7597,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.6256159056,
            "lng": -130.54439909585003
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.5916103511,
                "lng": -128.9227447859
            },
            {
                "lat": 53.6334574475,
                "lng": -128.9181055294
            },
            {
                "lat": 53.665245435,
                "lng": -128.9385531333
            },
            {
                "lat": 53.677166707,
                "lng": -129.0021202883
            },
            {
                "lat": 53.6615410413,
                "lng": -129.0578760343
            },
            {
                "lat": 53.6561629841,
                "lng": -129.0770566456
            },
            {
                "lat": 53.635303292,
                "lng": -129.0914915984
            },
            {
                "lat": 53.4986224177,
                "lng": -129.1313325095
            },
            {
                "lat": 53.5547287099,
                "lng": -129.0863339981
            },
            {
                "lat": 53.5904193654,
                "lng": -129.0191192939
            },
            {
                "lat": 53.5957760587,
                "lng": -128.9780729323
            },
            {
                "lat": 53.5916103511,
                "lng": -128.9227447859
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 122,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 122,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 114856176.5051,
            "FEATURE_LENGTH_M": 55688.2418,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.61263701340001,
            "lng": -129.012129294575
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.5181734833,
                "lng": -132.745682504
            },
            {
                "lat": 53.519603972,
                "lng": -132.746155123
            },
            {
                "lat": 53.5209698877,
                "lng": -132.7485810266
            },
            {
                "lat": 53.5209698412,
                "lng": -132.7593228527
            },
            {
                "lat": 53.5224575074,
                "lng": -132.7629392777
            },
            {
                "lat": 53.5175822051,
                "lng": -132.7665101992
            },
            {
                "lat": 53.5121081814,
                "lng": -132.7684168064
            },
            {
                "lat": 53.5030671524,
                "lng": -132.7684168494
            },
            {
                "lat": 53.4971161766,
                "lng": -132.766509949
            },
            {
                "lat": 53.4937856953,
                "lng": -132.7617499474
            },
            {
                "lat": 53.493785532,
                "lng": -132.7581779217
            },
            {
                "lat": 53.4934121083,
                "lng": -132.7557673273
            },
            {
                "lat": 53.4968227257,
                "lng": -132.7442475026
            },
            {
                "lat": 53.5005676894,
                "lng": -132.7420049895
            },
            {
                "lat": 53.5035750003,
                "lng": -132.739791308
            },
            {
                "lat": 53.5062760133,
                "lng": -132.7393192346
            },
            {
                "lat": 53.5117498569,
                "lng": -132.7395013804
            },
            {
                "lat": 53.5153199525,
                "lng": -132.7425996112
            },
            {
                "lat": 53.5181734833,
                "lng": -132.745682504
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 123,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 83,
            "MANAGEMENT_AREA_NAME": "Subarea 2-83",
            "LABEL": "2-83",
            "FISHERY_AREA_ID": 1973,
            "OBJECTID": 123,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4934743.3237,
            "FEATURE_LENGTH_M": 8577.9621,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.50871139284736,
            "lng": -132.75270401656314
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.5005676894,
                "lng": -132.7420049895
            },
            {
                "lat": 53.4968227257,
                "lng": -132.7442475026
            },
            {
                "lat": 53.4934121083,
                "lng": -132.7557673273
            },
            {
                "lat": 53.493785532,
                "lng": -132.7581779217
            },
            {
                "lat": 53.4937856953,
                "lng": -132.7617499474
            },
            {
                "lat": 53.4903139872,
                "lng": -132.7652437769
            },
            {
                "lat": 53.488006735,
                "lng": -132.7723090438
            },
            {
                "lat": 53.4887887638,
                "lng": -132.7783961852
            },
            {
                "lat": 53.4885514274,
                "lng": -132.7948153823
            },
            {
                "lat": 53.4852186876,
                "lng": -132.8112329605
            },
            {
                "lat": 53.4818877298,
                "lng": -132.8231356308
            },
            {
                "lat": 53.4795074751,
                "lng": -132.8283699961
            },
            {
                "lat": 53.47594033,
                "lng": -132.8314664083
            },
            {
                "lat": 53.473560563,
                "lng": -132.8314660493
            },
            {
                "lat": 53.4721872202,
                "lng": -132.831725595
            },
            {
                "lat": 53.4709353679,
                "lng": -132.8317413773
            },
            {
                "lat": 53.4697157501,
                "lng": -132.831803189
            },
            {
                "lat": 53.4688297799,
                "lng": -132.8317876294
            },
            {
                "lat": 53.4685173745,
                "lng": -132.8319556632
            },
            {
                "lat": 53.4644816583,
                "lng": -132.7766114262
            },
            {
                "lat": 53.4643974836,
                "lng": -132.7720946091
            },
            {
                "lat": 53.4653513739,
                "lng": -132.7684166131
            },
            {
                "lat": 53.4672544402,
                "lng": -132.7596129224
            },
            {
                "lat": 53.4693940976,
                "lng": -132.7505641832
            },
            {
                "lat": 53.4734418166,
                "lng": -132.7386620117
            },
            {
                "lat": 53.4815330281,
                "lng": -132.7301028302
            },
            {
                "lat": 53.4927134455,
                "lng": -132.7255853403
            },
            {
                "lat": 53.4977108239,
                "lng": -132.7255857008
            },
            {
                "lat": 53.5022315237,
                "lng": -132.728912239
            },
            {
                "lat": 53.5038986995,
                "lng": -132.7317663343
            },
            {
                "lat": 53.5029451691,
                "lng": -132.736526093
            },
            {
                "lat": 53.5005676894,
                "lng": -132.7420049895
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 124,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 82,
            "MANAGEMENT_AREA_NAME": "Subarea 2-82",
            "LABEL": "2-82",
            "FISHERY_AREA_ID": 1972,
            "OBJECTID": 124,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 16991874.329,
            "FEATURE_LENGTH_M": 18854.4155,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.483320505987486,
            "lng": -132.77637005838747
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.4653511675,
                "lng": -132.6797793862
            },
            {
                "lat": 53.4634814731,
                "lng": -132.6766363399
            },
            {
                "lat": 53.4616163557,
                "lng": -132.6622457933
            },
            {
                "lat": 53.4613080524,
                "lng": -132.6590724592
            },
            {
                "lat": 53.4605911283,
                "lng": -132.6564634783
            },
            {
                "lat": 53.4689213794,
                "lng": -132.6514595016
            },
            {
                "lat": 53.47463155,
                "lng": -132.6481318242
            },
            {
                "lat": 53.4841500023,
                "lng": -132.6535954803
            },
            {
                "lat": 53.4969973827,
                "lng": -132.6626431867
            },
            {
                "lat": 53.5029216844,
                "lng": -132.6676023276
            },
            {
                "lat": 53.5040861772,
                "lng": -132.6741178439
            },
            {
                "lat": 53.5008053361,
                "lng": -132.6783450783
            },
            {
                "lat": 53.4915235188,
                "lng": -132.6793057336
            },
            {
                "lat": 53.4810569147,
                "lng": -132.680724563
            },
            {
                "lat": 53.4712987334,
                "lng": -132.6793058804
            },
            {
                "lat": 53.4653511675,
                "lng": -132.6797793862
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 125,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 81,
            "MANAGEMENT_AREA_NAME": "Subarea 2-81",
            "LABEL": "2-81",
            "FISHERY_AREA_ID": 1971,
            "OBJECTID": 125,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 7211406.5048,
            "FEATURE_LENGTH_M": 11626.6346,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.47838075146875,
            "lng": -132.66807551641875
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.5770260068,
                "lng": -130.0059810523
            },
            {
                "lat": 53.5698886542,
                "lng": -130.0066984818
            },
            {
                "lat": 53.5673100119,
                "lng": -130.0072939337
            },
            {
                "lat": 53.5648954902,
                "lng": -130.0092170169
            },
            {
                "lat": 53.5068895353,
                "lng": -130.0267034128
            },
            {
                "lat": 53.5068660244,
                "lng": -130.0246578202
            },
            {
                "lat": 53.5072713222,
                "lng": -130.0212249127
            },
            {
                "lat": 53.5065991007,
                "lng": -130.0149690311
            },
            {
                "lat": 53.5016177368,
                "lng": -130.0036469862
            },
            {
                "lat": 53.4952243681,
                "lng": -129.9950408677
            },
            {
                "lat": 53.492126857,
                "lng": -129.9893189174
            },
            {
                "lat": 53.4915887747,
                "lng": -129.9864205103
            },
            {
                "lat": 53.4917224563,
                "lng": -129.9849391376
            },
            {
                "lat": 53.4929997444,
                "lng": -129.9833980205
            },
            {
                "lat": 53.4936748462,
                "lng": -129.9813088508
            },
            {
                "lat": 53.4919927646,
                "lng": -129.9784088205
            },
            {
                "lat": 53.4866751358,
                "lng": -129.9613802717
            },
            {
                "lat": 53.4756351411,
                "lng": -129.9433439669
            },
            {
                "lat": 53.4661820873,
                "lng": -129.9458155356
            },
            {
                "lat": 53.4634816908,
                "lng": -129.9159236656
            },
            {
                "lat": 53.4634325807,
                "lng": -129.9154211153
            },
            {
                "lat": 53.463519821,
                "lng": -129.9146269984
            },
            {
                "lat": 53.4638363215,
                "lng": -129.9140772711
            },
            {
                "lat": 53.4637446229,
                "lng": -129.9130094437
            },
            {
                "lat": 53.4633706628,
                "lng": -129.9086459032
            },
            {
                "lat": 53.4625395044,
                "lng": -129.9071351006
            },
            {
                "lat": 53.4618340489,
                "lng": -129.9018395255
            },
            {
                "lat": 53.4632648752,
                "lng": -129.895400629
            },
            {
                "lat": 53.4646944964,
                "lng": -129.8825385011
            },
            {
                "lat": 53.4646946457,
                "lng": -129.8707429855
            },
            {
                "lat": 53.4668391074,
                "lng": -129.8610988533
            },
            {
                "lat": 53.4779170525,
                "lng": -129.8503727912
            },
            {
                "lat": 53.4882815554,
                "lng": -129.8285682726
            },
            {
                "lat": 53.5011475395,
                "lng": -129.8039087291
            },
            {
                "lat": 53.5161595666,
                "lng": -129.7996217848
            },
            {
                "lat": 53.5279500933,
                "lng": -129.8042608339
            },
            {
                "lat": 53.5358158631,
                "lng": -129.8242792869
            },
            {
                "lat": 53.545822234,
                "lng": -129.8475040475
            },
            {
                "lat": 53.5608328319,
                "lng": -129.8589474504
            },
            {
                "lat": 53.5787010355,
                "lng": -129.8746797697
            },
            {
                "lat": 53.594783812,
                "lng": -129.8878929145
            },
            {
                "lat": 53.6069375496,
                "lng": -129.8943328859
            },
            {
                "lat": 53.6147991408,
                "lng": -129.9057619806
            },
            {
                "lat": 53.6194452653,
                "lng": -129.9193413262
            },
            {
                "lat": 53.6201588708,
                "lng": -129.9322211281
            },
            {
                "lat": 53.6187282416,
                "lng": -129.9415132529
            },
            {
                "lat": 53.6158715746,
                "lng": -129.9529415308
            },
            {
                "lat": 53.6058656237,
                "lng": -129.9672399592
            },
            {
                "lat": 53.5929982773,
                "lng": -129.9758147597
            },
            {
                "lat": 53.5826334861,
                "lng": -129.9904630533
            },
            {
                "lat": 53.5770260068,
                "lng": -130.0059810523
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 126,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 16,
            "MANAGEMENT_AREA_NAME": "Subarea 5-16",
            "LABEL": "5-16",
            "FISHERY_AREA_ID": 2019,
            "OBJECTID": 126,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 163012372.5551,
            "FEATURE_LENGTH_M": 51556.0385,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.52222184423726,
            "lng": -129.93207596761962
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.4698984195,
                "lng": -132.8939663614
            },
            {
                "lat": 53.4692648294,
                "lng": -132.8948670775
            },
            {
                "lat": 53.4691847729,
                "lng": -132.89567496
            },
            {
                "lat": 53.4692299802,
                "lng": -132.89622472
            },
            {
                "lat": 53.4691008631,
                "lng": -132.8965296112
            },
            {
                "lat": 53.4689981657,
                "lng": -132.8971261187
            },
            {
                "lat": 53.4689517125,
                "lng": -132.8977812021
            },
            {
                "lat": 53.4689670974,
                "lng": -132.8983307845
            },
            {
                "lat": 53.4686432898,
                "lng": -132.898925958
            },
            {
                "lat": 53.4683534731,
                "lng": -132.8994300159
            },
            {
                "lat": 53.4683532698,
                "lng": -132.9327694922
            },
            {
                "lat": 53.4330936162,
                "lng": -132.8989558723
            },
            {
                "lat": 53.4330980599,
                "lng": -132.6738583609
            },
            {
                "lat": 53.4432906191,
                "lng": -132.7066041124
            },
            {
                "lat": 53.4467924045,
                "lng": -132.7052298731
            },
            {
                "lat": 53.4482425759,
                "lng": -132.7070004139
            },
            {
                "lat": 53.4496723504,
                "lng": -132.7106465563
            },
            {
                "lat": 53.4513123971,
                "lng": -132.7164148907
            },
            {
                "lat": 53.4546433189,
                "lng": -132.728560989
            },
            {
                "lat": 53.458927494,
                "lng": -132.7440183324
            },
            {
                "lat": 53.4613080628,
                "lng": -132.7561487226
            },
            {
                "lat": 53.4629705148,
                "lng": -132.7656710301
            },
            {
                "lat": 53.4643974836,
                "lng": -132.7720946091
            },
            {
                "lat": 53.4644816583,
                "lng": -132.7766114262
            },
            {
                "lat": 53.4685173745,
                "lng": -132.8319556632
            },
            {
                "lat": 53.4688297799,
                "lng": -132.8317876294
            },
            {
                "lat": 53.4697157501,
                "lng": -132.831803189
            },
            {
                "lat": 53.4709353679,
                "lng": -132.8317413773
            },
            {
                "lat": 53.4721872202,
                "lng": -132.831725595
            },
            {
                "lat": 53.473560563,
                "lng": -132.8314660493
            },
            {
                "lat": 53.4741743556,
                "lng": -132.83477845
            },
            {
                "lat": 53.4745864267,
                "lng": -132.8437962776
            },
            {
                "lat": 53.474135534,
                "lng": -132.8550720569
            },
            {
                "lat": 53.4728737882,
                "lng": -132.8631893058
            },
            {
                "lat": 53.4705282712,
                "lng": -132.8737479851
            },
            {
                "lat": 53.469627268,
                "lng": -132.8814237969
            },
            {
                "lat": 53.4697153421,
                "lng": -132.8866575685
            },
            {
                "lat": 53.4694441463,
                "lng": -132.8900754545
            },
            {
                "lat": 53.4698984195,
                "lng": -132.8939663614
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 127,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 79,
            "MANAGEMENT_AREA_NAME": "Subarea 2-79",
            "LABEL": "2-79",
            "FISHERY_AREA_ID": 1969,
            "OBJECTID": 127,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 51263521.0375,
            "FEATURE_LENGTH_M": 38621.4832,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.4641001547718,
            "lng": -132.83016995513844
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.4605911283,
                "lng": -132.6564634783
            },
            {
                "lat": 53.4613080524,
                "lng": -132.6590724592
            },
            {
                "lat": 53.4616163557,
                "lng": -132.6622457933
            },
            {
                "lat": 53.4634814731,
                "lng": -132.6766363399
            },
            {
                "lat": 53.4653511675,
                "lng": -132.6797793862
            },
            {
                "lat": 53.4579738266,
                "lng": -132.6883398833
            },
            {
                "lat": 53.4513124646,
                "lng": -132.6985780011
            },
            {
                "lat": 53.4467924045,
                "lng": -132.7052298731
            },
            {
                "lat": 53.4432906191,
                "lng": -132.7066041124
            },
            {
                "lat": 53.4330980599,
                "lng": -132.6738583609
            },
            {
                "lat": 53.4331704101,
                "lng": -132.6734617072
            },
            {
                "lat": 53.4335209099,
                "lng": -132.6725003927
            },
            {
                "lat": 53.4340973395,
                "lng": -132.6714329555
            },
            {
                "lat": 53.4348910638,
                "lng": -132.6702428482
            },
            {
                "lat": 53.4366800898,
                "lng": -132.6683501144
            },
            {
                "lat": 53.4408419589,
                "lng": -132.6652678861
            },
            {
                "lat": 53.4434579333,
                "lng": -132.6602637615
            },
            {
                "lat": 53.448933061,
                "lng": -132.6566916084
            },
            {
                "lat": 53.4565468046,
                "lng": -132.6557462787
            },
            {
                "lat": 53.4605911283,
                "lng": -132.6564634783
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 128,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 80,
            "MANAGEMENT_AREA_NAME": "Subarea 2-80",
            "LABEL": "2-80",
            "FISHERY_AREA_ID": 1970,
            "OBJECTID": 128,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 7305948.1539,
            "FEATURE_LENGTH_M": 10663.7019,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.448377312545006,
            "lng": -132.67286143593498
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.580593225,
                "lng": -129.6405642353
            },
            {
                "lat": 53.5705488223,
                "lng": -129.6501012283
            },
            {
                "lat": 53.5705487897,
                "lng": -129.6590111144
            },
            {
                "lat": 53.5683401325,
                "lng": -129.6622163893
            },
            {
                "lat": 53.5587047179,
                "lng": -129.6508484366
            },
            {
                "lat": 53.5537301168,
                "lng": -129.6417387588
            },
            {
                "lat": 53.5307848589,
                "lng": -129.6183159573
            },
            {
                "lat": 53.5080990627,
                "lng": -129.5857851004
            },
            {
                "lat": 53.480023123,
                "lng": -129.549149143
            },
            {
                "lat": 53.4581334843,
                "lng": -129.5132136918
            },
            {
                "lat": 53.4238665665,
                "lng": -129.4694373471
            },
            {
                "lat": 53.4048314191,
                "lng": -129.4615779364
            },
            {
                "lat": 53.3827706131,
                "lng": -129.4710992601
            },
            {
                "lat": 53.3792574487,
                "lng": -129.4658205379
            },
            {
                "lat": 53.3758091793,
                "lng": -129.4595186832
            },
            {
                "lat": 53.3752782304,
                "lng": -129.4574125544
            },
            {
                "lat": 53.3687173713,
                "lng": -129.4351655132
            },
            {
                "lat": 53.3709604644,
                "lng": -129.4151005507
            },
            {
                "lat": 53.3775901649,
                "lng": -129.4078673334
            },
            {
                "lat": 53.3699755005,
                "lng": -129.3645635977
            },
            {
                "lat": 53.364551499,
                "lng": -129.3525234204
            },
            {
                "lat": 53.3617671191,
                "lng": -129.3463597907
            },
            {
                "lat": 53.3629081374,
                "lng": -129.3350517094
            },
            {
                "lat": 53.3709939316,
                "lng": -129.3090973982
            },
            {
                "lat": 53.3843492869,
                "lng": -129.3150782657
            },
            {
                "lat": 53.4227407039,
                "lng": -129.3780675506
            },
            {
                "lat": 53.4538655011,
                "lng": -129.4291995428
            },
            {
                "lat": 53.4832117811,
                "lng": -129.4711464924
            },
            {
                "lat": 53.4901769166,
                "lng": -129.4964913741
            },
            {
                "lat": 53.5112269528,
                "lng": -129.5308839675
            },
            {
                "lat": 53.5344961974,
                "lng": -129.5711972826
            },
            {
                "lat": 53.5583610725,
                "lng": -129.5522150693
            },
            {
                "lat": 53.5705149356,
                "lng": -129.5671842128
            },
            {
                "lat": 53.569328385,
                "lng": -129.5842289912
            },
            {
                "lat": 53.5665091166,
                "lng": -129.6097259927
            },
            {
                "lat": 53.580593225,
                "lng": -129.6405642353
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 129,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 24,
            "MANAGEMENT_AREA_NAME": "Subarea 5-24",
            "LABEL": "5-24",
            "FISHERY_AREA_ID": 2027,
            "OBJECTID": 129,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 131883400.8259,
            "FEATURE_LENGTH_M": 75457.8064,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.466504390358324,
            "lng": -129.50187562958334
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.5691192096,
                "lng": -128.8167718163
            },
            {
                "lat": 53.5600050097,
                "lng": -128.8901052653
            },
            {
                "lat": 53.5620341056,
                "lng": -128.8937226255
            },
            {
                "lat": 53.5729896665,
                "lng": -128.9035188943
            },
            {
                "lat": 53.5916103511,
                "lng": -128.9227447859
            },
            {
                "lat": 53.5957760587,
                "lng": -128.9780729323
            },
            {
                "lat": 53.5904193654,
                "lng": -129.0191192939
            },
            {
                "lat": 53.5547287099,
                "lng": -129.0863339981
            },
            {
                "lat": 53.4986224177,
                "lng": -129.1313325095
            },
            {
                "lat": 53.4916724939,
                "lng": -129.1369019602
            },
            {
                "lat": 53.4265325876,
                "lng": -129.1599730449
            },
            {
                "lat": 53.4020352475,
                "lng": -129.163512682
            },
            {
                "lat": 53.3917079987,
                "lng": -129.1642456001
            },
            {
                "lat": 53.3861768263,
                "lng": -129.1653596376
            },
            {
                "lat": 53.3818359294,
                "lng": -129.1653592577
            },
            {
                "lat": 53.3818393427,
                "lng": -129.1227264138
            },
            {
                "lat": 53.3744016723,
                "lng": -129.1030731123
            },
            {
                "lat": 53.4020305408,
                "lng": -129.0763402712
            },
            {
                "lat": 53.4436722654,
                "lng": -129.0275573828
            },
            {
                "lat": 53.4389156606,
                "lng": -128.9882958656
            },
            {
                "lat": 53.4067920665,
                "lng": -128.9585567426
            },
            {
                "lat": 53.3943132191,
                "lng": -128.9401704369
            },
            {
                "lat": 53.3633804437,
                "lng": -128.9377890046
            },
            {
                "lat": 53.3372917334,
                "lng": -128.9404597077
            },
            {
                "lat": 53.3221544882,
                "lng": -128.9448546722
            },
            {
                "lat": 53.3144573243,
                "lng": -128.9447479187
            },
            {
                "lat": 53.3120578828,
                "lng": -128.8883665084
            },
            {
                "lat": 53.3047028175,
                "lng": -128.8753512
            },
            {
                "lat": 53.3211556294,
                "lng": -128.8727873918
            },
            {
                "lat": 53.337340695,
                "lng": -128.8623499393
            },
            {
                "lat": 53.3468591575,
                "lng": -128.8418889891
            },
            {
                "lat": 53.3668437954,
                "lng": -128.8387913177
            },
            {
                "lat": 53.3754122499,
                "lng": -128.8516384912
            },
            {
                "lat": 53.3965299018,
                "lng": -128.8648372676
            },
            {
                "lat": 53.4518402136,
                "lng": -128.8494417308
            },
            {
                "lat": 53.4536969393,
                "lng": -128.8347483444
            },
            {
                "lat": 53.4777263391,
                "lng": -128.823562356
            },
            {
                "lat": 53.5013467082,
                "lng": -128.8356477593
            },
            {
                "lat": 53.557719852,
                "lng": -128.8199472104
            },
            {
                "lat": 53.5592192459,
                "lng": -128.8171691914
            },
            {
                "lat": 53.5691192096,
                "lng": -128.8167718163
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 130,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 6-3",
            "LABEL": "6-3",
            "FISHERY_AREA_ID": 2411,
            "OBJECTID": 130,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 427506533.9289,
            "FEATURE_LENGTH_M": 109886.0367,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.44112403345365,
            "lng": -128.9579254962366
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.4330980599,
                "lng": -132.6738583609
            },
            {
                "lat": 53.4330936162,
                "lng": -132.8989558723
            },
            {
                "lat": 53.3155478649,
                "lng": -132.7861786247
            },
            {
                "lat": 53.3155445857,
                "lng": -132.7651828584
            },
            {
                "lat": 53.3174546285,
                "lng": -132.7646183685
            },
            {
                "lat": 53.319873713,
                "lng": -132.7633970752
            },
            {
                "lat": 53.3255888109,
                "lng": -132.7586218615
            },
            {
                "lat": 53.3281407858,
                "lng": -132.7512965223
            },
            {
                "lat": 53.3302152743,
                "lng": -132.7490381326
            },
            {
                "lat": 53.3325202275,
                "lng": -132.7506563807
            },
            {
                "lat": 53.3331640184,
                "lng": -132.7512973974
            },
            {
                "lat": 53.3332106613,
                "lng": -132.7516639372
            },
            {
                "lat": 53.3332408215,
                "lng": -132.7520136648
            },
            {
                "lat": 53.3333663929,
                "lng": -132.7521207646
            },
            {
                "lat": 53.33510921,
                "lng": -132.7557523332
            },
            {
                "lat": 53.3414994243,
                "lng": -132.7467800863
            },
            {
                "lat": 53.3383475451,
                "lng": -132.7390290299
            },
            {
                "lat": 53.3380007292,
                "lng": -132.7380823347
            },
            {
                "lat": 53.3395303497,
                "lng": -132.7353064552
            },
            {
                "lat": 53.3435781675,
                "lng": -132.7315058435
            },
            {
                "lat": 53.3500023883,
                "lng": -132.723175641
            },
            {
                "lat": 53.3554760091,
                "lng": -132.7157904634
            },
            {
                "lat": 53.3566625462,
                "lng": -132.7098392235
            },
            {
                "lat": 53.3590429462,
                "lng": -132.7057950387
            },
            {
                "lat": 53.3640404823,
                "lng": -132.7055670439
            },
            {
                "lat": 53.3685610809,
                "lng": -132.7148437127
            },
            {
                "lat": 53.3735575224,
                "lng": -132.7217397697
            },
            {
                "lat": 53.3762167221,
                "lng": -132.7237553452
            },
            {
                "lat": 53.4330980599,
                "lng": -132.6738583609
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 131,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 78,
            "MANAGEMENT_AREA_NAME": "Subarea 2-78",
            "LABEL": "2-78",
            "FISHERY_AREA_ID": 1968,
            "OBJECTID": 131,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 109511575.6143,
            "FEATURE_LENGTH_M": 48644.488,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.350233884275845,
            "lng": -132.7417145001
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.4366800898,
                "lng": -132.6683501144
            },
            {
                "lat": 53.4348910638,
                "lng": -132.6702428482
            },
            {
                "lat": 53.4340973395,
                "lng": -132.6714329555
            },
            {
                "lat": 53.4335209099,
                "lng": -132.6725003927
            },
            {
                "lat": 53.4331704101,
                "lng": -132.6734617072
            },
            {
                "lat": 53.4330980599,
                "lng": -132.6738583609
            },
            {
                "lat": 53.3696354767,
                "lng": -132.6738730676
            },
            {
                "lat": 53.367091921,
                "lng": -132.6751405808
            },
            {
                "lat": 53.3651548418,
                "lng": -132.6634218062
            },
            {
                "lat": 53.3623770243,
                "lng": -132.6530308286
            },
            {
                "lat": 53.3622896998,
                "lng": -132.6510468076
            },
            {
                "lat": 53.3627319278,
                "lng": -132.643035614
            },
            {
                "lat": 53.3637010017,
                "lng": -132.6376648738
            },
            {
                "lat": 53.3643156191,
                "lng": -132.6310117555
            },
            {
                "lat": 53.3645168205,
                "lng": -132.6269990548
            },
            {
                "lat": 53.3546636658,
                "lng": -132.5211179035
            },
            {
                "lat": 53.3535573669,
                "lng": -132.5165707397
            },
            {
                "lat": 53.3537101508,
                "lng": -132.5108496401
            },
            {
                "lat": 53.3772234366,
                "lng": -132.5108489808
            },
            {
                "lat": 53.381237677,
                "lng": -132.5111848548
            },
            {
                "lat": 53.3919975518,
                "lng": -132.5120847048
            },
            {
                "lat": 53.4022479509,
                "lng": -132.5135653972
            },
            {
                "lat": 53.4142680271,
                "lng": -132.5170137493
            },
            {
                "lat": 53.422638089,
                "lng": -132.525345286
            },
            {
                "lat": 53.4276348665,
                "lng": -132.5458066923
            },
            {
                "lat": 53.4314428618,
                "lng": -132.5705564618
            },
            {
                "lat": 53.4342997564,
                "lng": -132.6043396952
            },
            {
                "lat": 53.4364391654,
                "lng": -132.6226650519
            },
            {
                "lat": 53.4350133349,
                "lng": -132.6357574578
            },
            {
                "lat": 53.4328722134,
                "lng": -132.6490785795
            },
            {
                "lat": 53.4335862874,
                "lng": -132.6581276355
            },
            {
                "lat": 53.435725458,
                "lng": -132.6643068291
            },
            {
                "lat": 53.4366800898,
                "lng": -132.6683501144
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 132,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 75,
            "MANAGEMENT_AREA_NAME": "Subarea 2-75",
            "LABEL": "2-75",
            "FISHERY_AREA_ID": 1965,
            "OBJECTID": 132,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 84208757.9551,
            "FEATURE_LENGTH_M": 36764.7558,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.40128818652121,
            "lng": -132.61038304671212
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.4330980599,
                "lng": -132.6738583609
            },
            {
                "lat": 53.3762167221,
                "lng": -132.7237553452
            },
            {
                "lat": 53.3735575224,
                "lng": -132.7217397697
            },
            {
                "lat": 53.3727263337,
                "lng": -132.712112796
            },
            {
                "lat": 53.3719975048,
                "lng": -132.7080376862
            },
            {
                "lat": 53.3739849336,
                "lng": -132.707000897
            },
            {
                "lat": 53.3750302261,
                "lng": -132.706435851
            },
            {
                "lat": 53.3753703135,
                "lng": -132.7059936406
            },
            {
                "lat": 53.3754923022,
                "lng": -132.7056272553
            },
            {
                "lat": 53.3755381301,
                "lng": -132.7053839566
            },
            {
                "lat": 53.3820000768,
                "lng": -132.6991118126
            },
            {
                "lat": 53.3791536432,
                "lng": -132.6860045003
            },
            {
                "lat": 53.3704226713,
                "lng": -132.6946408756
            },
            {
                "lat": 53.3693352866,
                "lng": -132.6959228266
            },
            {
                "lat": 53.3670466149,
                "lng": -132.6979520832
            },
            {
                "lat": 53.3631708327,
                "lng": -132.6941686269
            },
            {
                "lat": 53.3623350467,
                "lng": -132.6883088955
            },
            {
                "lat": 53.3636550886,
                "lng": -132.6816096609
            },
            {
                "lat": 53.3652420131,
                "lng": -132.6762392916
            },
            {
                "lat": 53.367091921,
                "lng": -132.6751405808
            },
            {
                "lat": 53.3696354767,
                "lng": -132.6738730676
            },
            {
                "lat": 53.4330980599,
                "lng": -132.6738583609
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 133,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 73,
            "MANAGEMENT_AREA_NAME": "Subarea 2-73",
            "LABEL": "2-73",
            "FISHERY_AREA_ID": 1963,
            "OBJECTID": 133,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 12264753.9729,
            "FEATURE_LENGTH_M": 21548.6185,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.37705448999546,
            "lng": -132.69576255186365
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.5276835657,
                "lng": -130.4745486608
            },
            {
                "lat": 53.5171242598,
                "lng": -130.4858397038
            },
            {
                "lat": 53.5168799612,
                "lng": -130.4867241737
            },
            {
                "lat": 53.5165747509,
                "lng": -130.487258497
            },
            {
                "lat": 53.5161930114,
                "lng": -130.48733596
            },
            {
                "lat": 53.5156435175,
                "lng": -130.4874266916
            },
            {
                "lat": 53.4857181031,
                "lng": -130.4585875409
            },
            {
                "lat": 53.4698212187,
                "lng": -130.438247774
            },
            {
                "lat": 53.4582590502,
                "lng": -130.4074392607
            },
            {
                "lat": 53.4598204883,
                "lng": -130.4039914701
            },
            {
                "lat": 53.4627188537,
                "lng": -130.3989565576
            },
            {
                "lat": 53.4698181332,
                "lng": -130.3936006747
            },
            {
                "lat": 53.4812396957,
                "lng": -130.3912362027
            },
            {
                "lat": 53.4943733922,
                "lng": -130.3787837014
            },
            {
                "lat": 53.50091614,
                "lng": -130.3549802224
            },
            {
                "lat": 53.4979404137,
                "lng": -130.319289568
            },
            {
                "lat": 53.5021058992,
                "lng": -130.2943119654
            },
            {
                "lat": 53.5169757123,
                "lng": -130.2925265001
            },
            {
                "lat": 53.5205457378,
                "lng": -130.3109742986
            },
            {
                "lat": 53.5282783692,
                "lng": -130.3508301332
            },
            {
                "lat": 53.5389859757,
                "lng": -130.376999209
            },
            {
                "lat": 53.5348241788,
                "lng": -130.4079289918
            },
            {
                "lat": 53.52411643,
                "lng": -130.4519499813
            },
            {
                "lat": 53.5276835657,
                "lng": -130.4745486608
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 134,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 21,
            "MANAGEMENT_AREA_NAME": "Subarea 5-21",
            "LABEL": "5-21",
            "FISHERY_AREA_ID": 2024,
            "OBJECTID": 134,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 57811212.7025,
            "FEATURE_LENGTH_M": 35141.6905,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.50351001766666,
            "lng": -130.4089298499833
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.5068895353,
                "lng": -130.0267034128
            },
            {
                "lat": 53.5479464124,
                "lng": -130.0978238533
            },
            {
                "lat": 53.5150372366,
                "lng": -130.109283451
            },
            {
                "lat": 53.503250231,
                "lng": -130.1127316294
            },
            {
                "lat": 53.4662475663,
                "lng": -130.0562136285
            },
            {
                "lat": 53.4590833712,
                "lng": -130.0427091641
            },
            {
                "lat": 53.2809024099,
                "lng": -129.8306877916
            },
            {
                "lat": 53.2513845924,
                "lng": -129.808273555
            },
            {
                "lat": 53.2230686509,
                "lng": -129.778533847
            },
            {
                "lat": 53.2111699743,
                "lng": -129.7680673499
            },
            {
                "lat": 53.2163051321,
                "lng": -129.7538142315
            },
            {
                "lat": 53.2115856278,
                "lng": -129.5868691332
            },
            {
                "lat": 53.2127078854,
                "lng": -129.5867615723
            },
            {
                "lat": 53.2144622256,
                "lng": -129.5841363287
            },
            {
                "lat": 53.2191549278,
                "lng": -129.5824428445
            },
            {
                "lat": 53.2351305817,
                "lng": -129.5924227959
            },
            {
                "lat": 53.2532159055,
                "lng": -129.6105043795
            },
            {
                "lat": 53.2784382974,
                "lng": -129.6354978858
            },
            {
                "lat": 53.3011168038,
                "lng": -129.648834393
            },
            {
                "lat": 53.3041727883,
                "lng": -129.6720881836
            },
            {
                "lat": 53.3062088472,
                "lng": -129.6809079961
            },
            {
                "lat": 53.3073429395,
                "lng": -129.6857905538
            },
            {
                "lat": 53.3247563327,
                "lng": -129.7198636972
            },
            {
                "lat": 53.3269962557,
                "lng": -129.7154696153
            },
            {
                "lat": 53.3316308544,
                "lng": -129.7121431197
            },
            {
                "lat": 53.3373407725,
                "lng": -129.7147526035
            },
            {
                "lat": 53.3418611971,
                "lng": -129.7271268618
            },
            {
                "lat": 53.3610078977,
                "lng": -129.7537986241
            },
            {
                "lat": 53.3732723422,
                "lng": -129.7523500136
            },
            {
                "lat": 53.3844566435,
                "lng": -129.7547301197
            },
            {
                "lat": 53.3949247555,
                "lng": -129.7792354417
            },
            {
                "lat": 53.4101528461,
                "lng": -129.7790067706
            },
            {
                "lat": 53.4141994064,
                "lng": -129.7885133734
            },
            {
                "lat": 53.4068221905,
                "lng": -129.80041508
            },
            {
                "lat": 53.3972202403,
                "lng": -129.8080904215
            },
            {
                "lat": 53.4095382216,
                "lng": -129.8230890991
            },
            {
                "lat": 53.4225276925,
                "lng": -129.8225404615
            },
            {
                "lat": 53.4292831313,
                "lng": -129.8255605431
            },
            {
                "lat": 53.4286198283,
                "lng": -129.8349296668
            },
            {
                "lat": 53.4276195644,
                "lng": -129.8403781668
            },
            {
                "lat": 53.4275778213,
                "lng": -129.8413240845
            },
            {
                "lat": 53.427493796,
                "lng": -129.8432319087
            },
            {
                "lat": 53.4292263162,
                "lng": -129.8470601241
            },
            {
                "lat": 53.4485246495,
                "lng": -129.8572389451
            },
            {
                "lat": 53.4527317205,
                "lng": -129.8558201463
            },
            {
                "lat": 53.4596932172,
                "lng": -129.8559718981
            },
            {
                "lat": 53.4668391074,
                "lng": -129.8610988533
            },
            {
                "lat": 53.4646946457,
                "lng": -129.8707429855
            },
            {
                "lat": 53.4646944964,
                "lng": -129.8825385011
            },
            {
                "lat": 53.4632648752,
                "lng": -129.895400629
            },
            {
                "lat": 53.4618340489,
                "lng": -129.9018395255
            },
            {
                "lat": 53.4625395044,
                "lng": -129.9071351006
            },
            {
                "lat": 53.4633706628,
                "lng": -129.9086459032
            },
            {
                "lat": 53.4637446229,
                "lng": -129.9130094437
            },
            {
                "lat": 53.4638363215,
                "lng": -129.9140772711
            },
            {
                "lat": 53.463519821,
                "lng": -129.9146269984
            },
            {
                "lat": 53.4634325807,
                "lng": -129.9154211153
            },
            {
                "lat": 53.4634816908,
                "lng": -129.9159236656
            },
            {
                "lat": 53.4661820873,
                "lng": -129.9458155356
            },
            {
                "lat": 53.4756351411,
                "lng": -129.9433439669
            },
            {
                "lat": 53.4866751358,
                "lng": -129.9613802717
            },
            {
                "lat": 53.4919927646,
                "lng": -129.9784088205
            },
            {
                "lat": 53.4936748462,
                "lng": -129.9813088508
            },
            {
                "lat": 53.4929997444,
                "lng": -129.9833980205
            },
            {
                "lat": 53.4917224563,
                "lng": -129.9849391376
            },
            {
                "lat": 53.4915887747,
                "lng": -129.9864205103
            },
            {
                "lat": 53.492126857,
                "lng": -129.9893189174
            },
            {
                "lat": 53.4952243681,
                "lng": -129.9950408677
            },
            {
                "lat": 53.5016177368,
                "lng": -130.0036469862
            },
            {
                "lat": 53.5065991007,
                "lng": -130.0149690311
            },
            {
                "lat": 53.5072713222,
                "lng": -130.0212249127
            },
            {
                "lat": 53.5068660244,
                "lng": -130.0246578202
            },
            {
                "lat": 53.5068895353,
                "lng": -130.0267034128
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 135,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 17,
            "MANAGEMENT_AREA_NAME": "Subarea 5-17",
            "LABEL": "5-17",
            "FISHERY_AREA_ID": 2020,
            "OBJECTID": 135,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 392471265.1573,
            "FEATURE_LENGTH_M": 119033.9144,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.40499476586851,
            "lng": -129.84855857289037
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.5636066323,
                "lng": -128.7400659049
            },
            {
                "lat": 53.5650514686,
                "lng": -128.7684480181
            },
            {
                "lat": 53.5691192096,
                "lng": -128.8167718163
            },
            {
                "lat": 53.5592192459,
                "lng": -128.8171691914
            },
            {
                "lat": 53.5282594094,
                "lng": -128.7986147178
            },
            {
                "lat": 53.4782907765,
                "lng": -128.7807769452
            },
            {
                "lat": 53.4336781113,
                "lng": -128.7022551585
            },
            {
                "lat": 53.3991735946,
                "lng": -128.6159975034
            },
            {
                "lat": 53.3741910413,
                "lng": -128.5618591311
            },
            {
                "lat": 53.3331451166,
                "lng": -128.5249780253
            },
            {
                "lat": 53.2956698806,
                "lng": -128.4750210515
            },
            {
                "lat": 53.3045919509,
                "lng": -128.4458615974
            },
            {
                "lat": 53.3539614625,
                "lng": -128.4042204871
            },
            {
                "lat": 53.3783530634,
                "lng": -128.2894126681
            },
            {
                "lat": 53.3789476294,
                "lng": -128.1835319445
            },
            {
                "lat": 53.3390915831,
                "lng": -128.1591334397
            },
            {
                "lat": 53.2897189315,
                "lng": -128.1169121998
            },
            {
                "lat": 53.2611657489,
                "lng": -127.9931721184
            },
            {
                "lat": 53.2224993776,
                "lng": -127.9211964335
            },
            {
                "lat": 53.1874016422,
                "lng": -127.8545680148
            },
            {
                "lat": 53.1766934315,
                "lng": -127.8236385574
            },
            {
                "lat": 53.2284472014,
                "lng": -127.7790227952
            },
            {
                "lat": 53.2641413796,
                "lng": -127.8141173407
            },
            {
                "lat": 53.3521800686,
                "lng": -127.9009699353
            },
            {
                "lat": 53.4759097935,
                "lng": -127.9390411891
            },
            {
                "lat": 53.5181464249,
                "lng": -127.987822494
            },
            {
                "lat": 53.5478895089,
                "lng": -128.0360110533
            },
            {
                "lat": 53.5235027594,
                "lng": -128.1228484404
            },
            {
                "lat": 53.4985156294,
                "lng": -128.196028997
            },
            {
                "lat": 53.5020873327,
                "lng": -128.2923888214
            },
            {
                "lat": 53.5413468285,
                "lng": -128.3619838514
            },
            {
                "lat": 53.5288546626,
                "lng": -128.400650551
            },
            {
                "lat": 53.4901882793,
                "lng": -128.4220732995
            },
            {
                "lat": 53.4628260729,
                "lng": -128.4999999533
            },
            {
                "lat": 53.4698481858,
                "lng": -128.5617985767
            },
            {
                "lat": 53.4889180462,
                "lng": -128.5808714564
            },
            {
                "lat": 53.5055737345,
                "lng": -128.6123959123
            },
            {
                "lat": 53.5192558834,
                "lng": -128.6564183587
            },
            {
                "lat": 53.5323414185,
                "lng": -128.6903233428
            },
            {
                "lat": 53.5513760293,
                "lng": -128.7147210092
            },
            {
                "lat": 53.5636066323,
                "lng": -128.7400659049
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 136,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 6-4",
            "LABEL": "6-4",
            "FISHERY_AREA_ID": 2412,
            "OBJECTID": 136,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1157418233.6033,
            "FEATURE_LENGTH_M": 207942.8485,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.42821427266831,
            "lng": -128.36836971236096
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.2605404104,
                "lng": -131.7705388815
            },
            {
                "lat": 53.4309040026,
                "lng": -131.9106593908
            },
            {
                "lat": 53.4272993506,
                "lng": -131.9654992268
            },
            {
                "lat": 53.383132837,
                "lng": -131.9745180236
            },
            {
                "lat": 53.2767713232,
                "lng": -132.1167144562
            },
            {
                "lat": 53.2586556434,
                "lng": -132.158234194
            },
            {
                "lat": 53.2527081428,
                "lng": -132.2183233584
            },
            {
                "lat": 53.2586554638,
                "lng": -132.2950597971
            },
            {
                "lat": 53.2503284869,
                "lng": -132.3301543704
            },
            {
                "lat": 53.2265318116,
                "lng": -132.3337248435
            },
            {
                "lat": 53.2069019369,
                "lng": -132.3117066384
            },
            {
                "lat": 53.1819196405,
                "lng": -132.2837518233
            },
            {
                "lat": 53.1700208274,
                "lng": -132.2789922628
            },
            {
                "lat": 53.152565306,
                "lng": -132.2827757399
            },
            {
                "lat": 53.151523197,
                "lng": -132.2827749132
            },
            {
                "lat": 53.1512951386,
                "lng": -132.2849881365
            },
            {
                "lat": 53.1503454561,
                "lng": -132.2880247308
            },
            {
                "lat": 53.1447025625,
                "lng": -132.2858732936
            },
            {
                "lat": 53.1334000443,
                "lng": -132.2739871796
            },
            {
                "lat": 53.1274533619,
                "lng": -132.2513733729
            },
            {
                "lat": 53.1274532149,
                "lng": -132.189513997
            },
            {
                "lat": 53.1262625395,
                "lng": -132.1282355383
            },
            {
                "lat": 53.1381611764,
                "lng": -132.0741123685
            },
            {
                "lat": 53.1551136527,
                "lng": -132.0493628469
            },
            {
                "lat": 53.1646304059,
                "lng": -132.0315094193
            },
            {
                "lat": 53.1770051991,
                "lng": -132.0158085402
            },
            {
                "lat": 53.1874737908,
                "lng": -131.9851068246
            },
            {
                "lat": 53.2060360906,
                "lng": -131.9449012312
            },
            {
                "lat": 53.2226905685,
                "lng": -131.9239657854
            },
            {
                "lat": 53.225784708,
                "lng": -131.9006502144
            },
            {
                "lat": 53.2269746258,
                "lng": -131.8708953251
            },
            {
                "lat": 53.2279243546,
                "lng": -131.8497159487
            },
            {
                "lat": 53.2376825033,
                "lng": -131.8228300297
            },
            {
                "lat": 53.2493399132,
                "lng": -131.8152165813
            },
            {
                "lat": 53.2550504299,
                "lng": -131.8187872254
            },
            {
                "lat": 53.2605404127,
                "lng": -131.8215030708
            },
            {
                "lat": 53.2605404104,
                "lng": -131.7705388815
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 137,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 2-1",
            "LABEL": "2-1",
            "FISHERY_AREA_ID": 1892,
            "OBJECTID": 137,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 574543864.2793,
            "FEATURE_LENGTH_M": 115542.3812,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.21741402539999,
            "lng": -132.07865752598917
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.3670466149,
                "lng": -132.6979520832
            },
            {
                "lat": 53.3693352866,
                "lng": -132.6959228266
            },
            {
                "lat": 53.3704226713,
                "lng": -132.6946408756
            },
            {
                "lat": 53.3791536432,
                "lng": -132.6860045003
            },
            {
                "lat": 53.3820000768,
                "lng": -132.6991118126
            },
            {
                "lat": 53.3755381301,
                "lng": -132.7053839566
            },
            {
                "lat": 53.3754923022,
                "lng": -132.7056272553
            },
            {
                "lat": 53.3753703135,
                "lng": -132.7059936406
            },
            {
                "lat": 53.3750302261,
                "lng": -132.706435851
            },
            {
                "lat": 53.3739849336,
                "lng": -132.707000897
            },
            {
                "lat": 53.3719975048,
                "lng": -132.7080376862
            },
            {
                "lat": 53.371200275,
                "lng": -132.7059168929
            },
            {
                "lat": 53.3675759833,
                "lng": -132.7001952284
            },
            {
                "lat": 53.3670466149,
                "lng": -132.6979520832
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 138,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 74,
            "MANAGEMENT_AREA_NAME": "Subarea 2-74",
            "LABEL": "2-74",
            "FISHERY_AREA_ID": 1964,
            "OBJECTID": 138,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1285337.4987,
            "FEATURE_LENGTH_M": 4655.1463,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.37294246973571,
            "lng": -132.70115539924998
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.381237677,
                "lng": -132.5111848548
            },
            {
                "lat": 53.3772234366,
                "lng": -132.5108489808
            },
            {
                "lat": 53.3537101508,
                "lng": -132.5108496401
            },
            {
                "lat": 53.3535573669,
                "lng": -132.5165707397
            },
            {
                "lat": 53.3511277415,
                "lng": -132.5197139928
            },
            {
                "lat": 53.3481860268,
                "lng": -132.5291140003
            },
            {
                "lat": 53.3405990574,
                "lng": -132.5266421257
            },
            {
                "lat": 53.3310812696,
                "lng": -132.5123587369
            },
            {
                "lat": 53.3132356986,
                "lng": -132.4891653558
            },
            {
                "lat": 53.3060982654,
                "lng": -132.4629828079
            },
            {
                "lat": 53.3126407828,
                "lng": -132.451690099
            },
            {
                "lat": 53.3179969854,
                "lng": -132.4493101384
            },
            {
                "lat": 53.3220068114,
                "lng": -132.4514311214
            },
            {
                "lat": 53.3227423036,
                "lng": -132.4521794441
            },
            {
                "lat": 53.3230172339,
                "lng": -132.4526673177
            },
            {
                "lat": 53.3233184663,
                "lng": -132.4537361751
            },
            {
                "lat": 53.323398722,
                "lng": -132.455123352
            },
            {
                "lat": 53.3236539432,
                "lng": -132.4553532733
            },
            {
                "lat": 53.3245970122,
                "lng": -132.4553677312
            },
            {
                "lat": 53.3403929611,
                "lng": -132.4428104569
            },
            {
                "lat": 53.3508381136,
                "lng": -132.4428102148
            },
            {
                "lat": 53.3542023197,
                "lng": -132.4425502224
            },
            {
                "lat": 53.3595840095,
                "lng": -132.4502716175
            },
            {
                "lat": 53.3638684067,
                "lng": -132.4612271811
            },
            {
                "lat": 53.3714831141,
                "lng": -132.4795380839
            },
            {
                "lat": 53.3764796674,
                "lng": -132.4916833428
            },
            {
                "lat": 53.3786204912,
                "lng": -132.5014349036
            },
            {
                "lat": 53.381237677,
                "lng": -132.5111848548
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 139,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 76,
            "MANAGEMENT_AREA_NAME": "Subarea 2-76",
            "LABEL": "2-76",
            "FISHERY_AREA_ID": 1966,
            "OBJECTID": 139,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 28767354.375,
            "FEATURE_LENGTH_M": 22817.6851,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.34379056113215,
            "lng": -132.47820717017143
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.4664188963,
                "lng": -130.5874330872
            },
            {
                "lat": 53.3666680692,
                "lng": -131.1999964036
            },
            {
                "lat": 53.1666677123,
                "lng": -130.9999991989
            },
            {
                "lat": 53.1666677357,
                "lng": -129.965759051
            },
            {
                "lat": 53.3881458535,
                "lng": -130.4250944146
            },
            {
                "lat": 53.4664188963,
                "lng": -130.5874330872
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 140,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 105,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 105-2",
            "LABEL": "105-2",
            "FISHERY_AREA_ID": 2305,
            "OBJECTID": 140,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1675682739.5664,
            "FEATURE_LENGTH_M": 190263.1328,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.33683119388334,
            "lng": -130.62761920708334
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.4596932172,
                "lng": -129.8559718981
            },
            {
                "lat": 53.4527317205,
                "lng": -129.8558201463
            },
            {
                "lat": 53.4485246495,
                "lng": -129.8572389451
            },
            {
                "lat": 53.4292263162,
                "lng": -129.8470601241
            },
            {
                "lat": 53.427493796,
                "lng": -129.8432319087
            },
            {
                "lat": 53.4275778213,
                "lng": -129.8413240845
            },
            {
                "lat": 53.4276195644,
                "lng": -129.8403781668
            },
            {
                "lat": 53.4286198283,
                "lng": -129.8349296668
            },
            {
                "lat": 53.4292831313,
                "lng": -129.8255605431
            },
            {
                "lat": 53.4353068461,
                "lng": -129.8263705668
            },
            {
                "lat": 53.4395906105,
                "lng": -129.8199464903
            },
            {
                "lat": 53.4401249017,
                "lng": -129.8033446675
            },
            {
                "lat": 53.4402576887,
                "lng": -129.785537381
            },
            {
                "lat": 53.4436069064,
                "lng": -129.7752384916
            },
            {
                "lat": 53.4510997063,
                "lng": -129.7740323494
            },
            {
                "lat": 53.461040093,
                "lng": -129.7768852564
            },
            {
                "lat": 53.4637026724,
                "lng": -129.7819972773
            },
            {
                "lat": 53.4596212639,
                "lng": -129.8022309515
            },
            {
                "lat": 53.4681355487,
                "lng": -129.8107450419
            },
            {
                "lat": 53.4745593669,
                "lng": -129.8319240731
            },
            {
                "lat": 53.4726564271,
                "lng": -129.8438265749
            },
            {
                "lat": 53.466232553,
                "lng": -129.8526311397
            },
            {
                "lat": 53.4596932172,
                "lng": -129.8559718981
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 141,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 18,
            "MANAGEMENT_AREA_NAME": "Subarea 5-18",
            "LABEL": "5-18",
            "FISHERY_AREA_ID": 2021,
            "OBJECTID": 141,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 18147375.6651,
            "FEATURE_LENGTH_M": 18413.9096,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.44810425420001,
            "lng": -129.82357381056522
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.3380007292,
                "lng": -132.7380823347
            },
            {
                "lat": 53.3383475451,
                "lng": -132.7390290299
            },
            {
                "lat": 53.3414994243,
                "lng": -132.7467800863
            },
            {
                "lat": 53.33510921,
                "lng": -132.7557523332
            },
            {
                "lat": 53.3333663929,
                "lng": -132.7521207646
            },
            {
                "lat": 53.3332408215,
                "lng": -132.7520136648
            },
            {
                "lat": 53.3332106613,
                "lng": -132.7516639372
            },
            {
                "lat": 53.3331640184,
                "lng": -132.7512973974
            },
            {
                "lat": 53.3325202275,
                "lng": -132.7506563807
            },
            {
                "lat": 53.3302152743,
                "lng": -132.7490381326
            },
            {
                "lat": 53.3318752124,
                "lng": -132.7452239578
            },
            {
                "lat": 53.33431636,
                "lng": -132.7402960646
            },
            {
                "lat": 53.3364337966,
                "lng": -132.7381287653
            },
            {
                "lat": 53.3380007292,
                "lng": -132.7380823347
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 142,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 72,
            "MANAGEMENT_AREA_NAME": "Subarea 2-72",
            "LABEL": "2-72",
            "FISHERY_AREA_ID": 1962,
            "OBJECTID": 142,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 784848.9287,
            "FEATURE_LENGTH_M": 3554.5048,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.33495002876428,
            "lng": -132.74629751312855
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.3542023197,
                "lng": -132.4425502224
            },
            {
                "lat": 53.3508381136,
                "lng": -132.4428102148
            },
            {
                "lat": 53.3403929611,
                "lng": -132.4428104569
            },
            {
                "lat": 53.3245970122,
                "lng": -132.4553677312
            },
            {
                "lat": 53.3236539432,
                "lng": -132.4553532733
            },
            {
                "lat": 53.323398722,
                "lng": -132.455123352
            },
            {
                "lat": 53.3233184663,
                "lng": -132.4537361751
            },
            {
                "lat": 53.3230172339,
                "lng": -132.4526673177
            },
            {
                "lat": 53.3227423036,
                "lng": -132.4521794441
            },
            {
                "lat": 53.3220068114,
                "lng": -132.4514311214
            },
            {
                "lat": 53.3179969854,
                "lng": -132.4493101384
            },
            {
                "lat": 53.3106804372,
                "lng": -132.4458010738
            },
            {
                "lat": 53.2990947451,
                "lng": -132.4456485399
            },
            {
                "lat": 53.2880748497,
                "lng": -132.4376066675
            },
            {
                "lat": 53.2862357637,
                "lng": -132.4279940865
            },
            {
                "lat": 53.2883566946,
                "lng": -132.4205014958
            },
            {
                "lat": 53.2988130527,
                "lng": -132.4144286199
            },
            {
                "lat": 53.3119542907,
                "lng": -132.4132994437
            },
            {
                "lat": 53.31774455,
                "lng": -132.4118806374
            },
            {
                "lat": 53.3181300613,
                "lng": -132.4115749841
            },
            {
                "lat": 53.3228877776,
                "lng": -132.4077917393
            },
            {
                "lat": 53.334983231,
                "lng": -132.4123081026
            },
            {
                "lat": 53.3424721613,
                "lng": -132.4188076539
            },
            {
                "lat": 53.3484070557,
                "lng": -132.4261632252
            },
            {
                "lat": 53.3529277981,
                "lng": -132.4339290197
            },
            {
                "lat": 53.3540613038,
                "lng": -132.4407039089
            },
            {
                "lat": 53.3542023197,
                "lng": -132.4425502224
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 143,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 77,
            "MANAGEMENT_AREA_NAME": "Subarea 2-77",
            "LABEL": "2-77",
            "FISHERY_AREA_ID": 1967,
            "OBJECTID": 143,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 14898855.9188,
            "FEATURE_LENGTH_M": 17564.8533,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.324266332022226,
            "lng": -132.4357158839963
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.3144604659,
                "lng": -132.6400763307
            },
            {
                "lat": 53.3136094907,
                "lng": -132.6390373506
            },
            {
                "lat": 53.313289471,
                "lng": -132.6372078215
            },
            {
                "lat": 53.3142011912,
                "lng": -132.6353450835
            },
            {
                "lat": 53.3131825928,
                "lng": -132.6294706465
            },
            {
                "lat": 53.3127750058,
                "lng": -132.6217495015
            },
            {
                "lat": 53.3125036236,
                "lng": -132.6059117775
            },
            {
                "lat": 53.3149413286,
                "lng": -132.5934602683
            },
            {
                "lat": 53.3104745868,
                "lng": -132.5804748359
            },
            {
                "lat": 53.3018154948,
                "lng": -132.5731660379
            },
            {
                "lat": 53.2955893857,
                "lng": -132.5628810439
            },
            {
                "lat": 53.2945056348,
                "lng": -132.5555725036
            },
            {
                "lat": 53.2988350482,
                "lng": -132.5501555034
            },
            {
                "lat": 53.3102029872,
                "lng": -132.5481267244
            },
            {
                "lat": 53.3256343277,
                "lng": -132.5494844086
            },
            {
                "lat": 53.3342932092,
                "lng": -132.5498812578
            },
            {
                "lat": 53.3372723337,
                "lng": -132.5565191004
            },
            {
                "lat": 53.3398440513,
                "lng": -132.5833127777
            },
            {
                "lat": 53.3364598344,
                "lng": -132.6147159979
            },
            {
                "lat": 53.3300976526,
                "lng": -132.6304169489
            },
            {
                "lat": 53.3289066189,
                "lng": -132.638747673
            },
            {
                "lat": 53.3269345921,
                "lng": -132.6391900518
            },
            {
                "lat": 53.3258285533,
                "lng": -132.6397710386
            },
            {
                "lat": 53.3253976476,
                "lng": -132.6400911889
            },
            {
                "lat": 53.3144604659,
                "lng": -132.6400763307
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 144,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 70,
            "MANAGEMENT_AREA_NAME": "Subarea 2-70",
            "LABEL": "2-70",
            "FISHERY_AREA_ID": 1960,
            "OBJECTID": 144,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 18843468.1493,
            "FEATURE_LENGTH_M": 19305.3221,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.31782062375201,
            "lng": -132.60219368814
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.319873713,
                "lng": -132.7633970752
            },
            {
                "lat": 53.3174546285,
                "lng": -132.7646183685
            },
            {
                "lat": 53.3155445857,
                "lng": -132.7651828584
            },
            {
                "lat": 53.3155478649,
                "lng": -132.7861786247
            },
            {
                "lat": 53.253463573,
                "lng": -132.7266235054
            },
            {
                "lat": 53.2534638378,
                "lng": -132.7158049879
            },
            {
                "lat": 53.2560423557,
                "lng": -132.7148892638
            },
            {
                "lat": 53.2586893273,
                "lng": -132.7133024558
            },
            {
                "lat": 53.2659493442,
                "lng": -132.7063750338
            },
            {
                "lat": 53.2717361246,
                "lng": -132.702728855
            },
            {
                "lat": 53.2789494454,
                "lng": -132.7019196408
            },
            {
                "lat": 53.2832261419,
                "lng": -132.7029876107
            },
            {
                "lat": 53.2837594296,
                "lng": -132.7058413552
            },
            {
                "lat": 53.2843406839,
                "lng": -132.7069239375
            },
            {
                "lat": 53.3144604659,
                "lng": -132.6400763307
            },
            {
                "lat": 53.3253976476,
                "lng": -132.6400911889
            },
            {
                "lat": 53.3258285533,
                "lng": -132.6397710386
            },
            {
                "lat": 53.3269345921,
                "lng": -132.6391900518
            },
            {
                "lat": 53.3289066189,
                "lng": -132.638747673
            },
            {
                "lat": 53.3296351872,
                "lng": -132.653305459
            },
            {
                "lat": 53.3302302593,
                "lng": -132.6764991065
            },
            {
                "lat": 53.3320157376,
                "lng": -132.6884002719
            },
            {
                "lat": 53.3326075719,
                "lng": -132.7008823588
            },
            {
                "lat": 53.3308263725,
                "lng": -132.7157588844
            },
            {
                "lat": 53.3308260168,
                "lng": -132.7217099262
            },
            {
                "lat": 53.3284456061,
                "lng": -132.7294472996
            },
            {
                "lat": 53.3248748399,
                "lng": -132.7449037406
            },
            {
                "lat": 53.3219030607,
                "lng": -132.756806071
            },
            {
                "lat": 53.319873713,
                "lng": -132.7633970752
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 145,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 69,
            "MANAGEMENT_AREA_NAME": "Subarea 2-69",
            "LABEL": "2-69",
            "FISHERY_AREA_ID": 2391,
            "OBJECTID": 145,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 43134109.0011,
            "FEATURE_LENGTH_M": 30202.248,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.30554507925173,
            "lng": -132.70778482927238
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.313289471,
                "lng": -132.6372078215
            },
            {
                "lat": 53.3136094907,
                "lng": -132.6390373506
            },
            {
                "lat": 53.3144604659,
                "lng": -132.6400763307
            },
            {
                "lat": 53.2843406839,
                "lng": -132.7069239375
            },
            {
                "lat": 53.2837594296,
                "lng": -132.7058413552
            },
            {
                "lat": 53.2832261419,
                "lng": -132.7029876107
            },
            {
                "lat": 53.2807851445,
                "lng": -132.687560417
            },
            {
                "lat": 53.2822041657,
                "lng": -132.6719366901
            },
            {
                "lat": 53.2812995261,
                "lng": -132.6571044007
            },
            {
                "lat": 53.2826616918,
                "lng": -132.6434941865
            },
            {
                "lat": 53.2844929122,
                "lng": -132.6338657101
            },
            {
                "lat": 53.2854648561,
                "lng": -132.6295319056
            },
            {
                "lat": 53.2860833697,
                "lng": -132.626785173
            },
            {
                "lat": 53.2891043984,
                "lng": -132.6245581072
            },
            {
                "lat": 53.2963449997,
                "lng": -132.628143725
            },
            {
                "lat": 53.3048588725,
                "lng": -132.6316367945
            },
            {
                "lat": 53.3105849859,
                "lng": -132.6348265208
            },
            {
                "lat": 53.313289471,
                "lng": -132.6372078215
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 146,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 71,
            "MANAGEMENT_AREA_NAME": "Subarea 2-71",
            "LABEL": "2-71",
            "FISHERY_AREA_ID": 1961,
            "OBJECTID": 146,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 10876070.526,
            "FEATURE_LENGTH_M": 14514.4218,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.29388111536666,
            "lng": -132.65215143656667
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 53.4059068431,
                "lng": -130.3108676115
            },
            {
                "lat": 53.3881494045,
                "lng": -130.3114013683
            },
            {
                "lat": 53.3881458535,
                "lng": -130.4250944146
            },
            {
                "lat": 53.1666677357,
                "lng": -129.965759051
            },
            {
                "lat": 53.1612512612,
                "lng": -129.9545282558
            },
            {
                "lat": 53.1614235205,
                "lng": -129.95419254
            },
            {
                "lat": 53.1658633874,
                "lng": -129.9498748612
            },
            {
                "lat": 53.1676678221,
                "lng": -129.9492185978
            },
            {
                "lat": 53.1727177864,
                "lng": -129.9529880601
            },
            {
                "lat": 53.1800952944,
                "lng": -129.9558415006
            },
            {
                "lat": 53.1915166289,
                "lng": -129.9601291094
            },
            {
                "lat": 53.2093621039,
                "lng": -129.9753569718
            },
            {
                "lat": 53.2205471315,
                "lng": -129.9922478756
            },
            {
                "lat": 53.2329220051,
                "lng": -129.998672698
            },
            {
                "lat": 53.2548103376,
                "lng": -130.0101013628
            },
            {
                "lat": 53.2609978601,
                "lng": -130.0305636655
            },
            {
                "lat": 53.2767024739,
                "lng": -130.0555415319
            },
            {
                "lat": 53.2988318472,
                "lng": -130.0795743076
            },
            {
                "lat": 53.3338086311,
                "lng": -130.0955196376
            },
            {
                "lat": 53.353321335,
                "lng": -130.1102752805
            },
            {
                "lat": 53.3609345921,
                "lng": -130.1331177514
            },
            {
                "lat": 53.3637883283,
                "lng": -130.1550142072
            },
            {
                "lat": 53.378779988,
                "lng": -130.1754763788
            },
            {
                "lat": 53.4163773265,
                "lng": -130.230911307
            },
            {
                "lat": 53.4130443674,
                "lng": -130.275650714
            },
            {
                "lat": 53.4059068431,
                "lng": -130.3108676115
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 147,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 5,
            "MANAGEMENT_SUBAREA": 22,
            "MANAGEMENT_AREA_NAME": "Subarea 5-22",
            "LABEL": "5-22",
            "FISHERY_AREA_ID": 2025,
            "OBJECTID": 147,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 256986141.2938,
            "FEATURE_LENGTH_M": 91022.2035,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 53.281905411865374,
            "lng": -130.08918410274998
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.4649242649,
                "lng": -128.9751135188
            },
            {
                "lat": 52.4565958111,
                "lng": -128.9760584878
            },
            {
                "lat": 52.4535900994,
                "lng": -128.9763486093
            },
            {
                "lat": 52.4535864974,
                "lng": -129.0429531053
            },
            {
                "lat": 52.4036948053,
                "lng": -128.9841311628
            },
            {
                "lat": 52.4036944086,
                "lng": -128.8927758603
            },
            {
                "lat": 52.4036947753,
                "lng": -128.7614901601
            },
            {
                "lat": 52.4017751277,
                "lng": -128.7586669424
            },
            {
                "lat": 52.4018586787,
                "lng": -128.7543180709
            },
            {
                "lat": 52.4080428699,
                "lng": -128.7455146436
            },
            {
                "lat": 52.4208949406,
                "lng": -128.7428888983
            },
            {
                "lat": 52.4247017247,
                "lng": -128.7490846689
            },
            {
                "lat": 52.427082239,
                "lng": -128.7578893251
            },
            {
                "lat": 52.4425470903,
                "lng": -128.7578890292
            },
            {
                "lat": 52.4532666183,
                "lng": -128.7554787531
            },
            {
                "lat": 52.459129135,
                "lng": -128.7561805689
            },
            {
                "lat": 52.4587789067,
                "lng": -128.7610631102
            },
            {
                "lat": 52.4581257559,
                "lng": -128.76336624
            },
            {
                "lat": 52.4800458194,
                "lng": -128.9424284419
            },
            {
                "lat": 52.4795533768,
                "lng": -128.9428256486
            },
            {
                "lat": 52.4788363094,
                "lng": -128.943587638
            },
            {
                "lat": 52.4783701024,
                "lng": -128.9446103473
            },
            {
                "lat": 52.4809838684,
                "lng": -128.9494020649
            },
            {
                "lat": 52.480152449,
                "lng": -128.9558410127
            },
            {
                "lat": 52.4739686059,
                "lng": -128.9689330195
            },
            {
                "lat": 52.4649242649,
                "lng": -128.9751135188
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 251,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 17,
            "MANAGEMENT_AREA_NAME": "Subarea 6-17",
            "LABEL": "6-17",
            "FISHERY_AREA_ID": 2424,
            "OBJECTID": 395,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 125904909.3205,
            "FEATURE_LENGTH_M": 51764.8511,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.446646867115376,
            "lng": -128.86669049410386
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.4612737551,
                "lng": -128.699447712
            },
            {
                "lat": 52.462413202,
                "lng": -128.724456831
            },
            {
                "lat": 52.4600373358,
                "lng": -128.7496801496
            },
            {
                "lat": 52.459129135,
                "lng": -128.7561805689
            },
            {
                "lat": 52.4532666183,
                "lng": -128.7554787531
            },
            {
                "lat": 52.4425470903,
                "lng": -128.7578890292
            },
            {
                "lat": 52.427082239,
                "lng": -128.7578893251
            },
            {
                "lat": 52.4247017247,
                "lng": -128.7490846689
            },
            {
                "lat": 52.4208949406,
                "lng": -128.7428888983
            },
            {
                "lat": 52.4080428699,
                "lng": -128.7455146436
            },
            {
                "lat": 52.4018586787,
                "lng": -128.7543180709
            },
            {
                "lat": 52.3983349783,
                "lng": -128.7491607199
            },
            {
                "lat": 52.395027499,
                "lng": -128.7469488803
            },
            {
                "lat": 52.3878864214,
                "lng": -128.7462461801
            },
            {
                "lat": 52.3748014407,
                "lng": -128.7343446394
            },
            {
                "lat": 52.3676603589,
                "lng": -128.7224427893
            },
            {
                "lat": 52.3531460358,
                "lng": -128.7164924081
            },
            {
                "lat": 52.3473132325,
                "lng": -128.7181394889
            },
            {
                "lat": 52.3362538835,
                "lng": -128.7210236603
            },
            {
                "lat": 52.3219757036,
                "lng": -128.7124474314
            },
            {
                "lat": 52.3181687113,
                "lng": -128.7010343855
            },
            {
                "lat": 52.3110316982,
                "lng": -128.6955566214
            },
            {
                "lat": 52.3019906709,
                "lng": -128.6919862018
            },
            {
                "lat": 52.2922326396,
                "lng": -128.6812739501
            },
            {
                "lat": 52.2888265482,
                "lng": -128.6731718139
            },
            {
                "lat": 52.2822000019,
                "lng": -128.6680755928
            },
            {
                "lat": 52.2810826414,
                "lng": -128.6665648431
            },
            {
                "lat": 52.2821467955,
                "lng": -128.6662447934
            },
            {
                "lat": 52.2831451917,
                "lng": -128.6659546589
            },
            {
                "lat": 52.285625038,
                "lng": -128.6641991586
            },
            {
                "lat": 52.2872500765,
                "lng": -128.665465784
            },
            {
                "lat": 52.2885784733,
                "lng": -128.6674342921
            },
            {
                "lat": 52.2913851439,
                "lng": -128.6703650303
            },
            {
                "lat": 52.2957531454,
                "lng": -128.6687784242
            },
            {
                "lat": 52.3053664727,
                "lng": -128.6660608644
            },
            {
                "lat": 52.3377266475,
                "lng": -128.6477363211
            },
            {
                "lat": 52.3741300022,
                "lng": -128.6446382531
            },
            {
                "lat": 52.4386133209,
                "lng": -128.6458282003
            },
            {
                "lat": 52.4545552363,
                "lng": -128.6474913757
            },
            {
                "lat": 52.4612737551,
                "lng": -128.699447712
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 252,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 396,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 103009690.2625,
            "FEATURE_LENGTH_M": 49987.0999,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.36411823384001,
            "lng": -128.70393457812497
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.3520855924,
                "lng": -131.3107141045
            },
            {
                "lat": 52.346236807,
                "lng": -131.3112485872
            },
            {
                "lat": 52.339100245,
                "lng": -131.3131557527
            },
            {
                "lat": 52.3333890894,
                "lng": -131.3164828498
            },
            {
                "lat": 52.3286290351,
                "lng": -131.3188632706
            },
            {
                "lat": 52.3250432262,
                "lng": -131.3185738183
            },
            {
                "lat": 52.3023948698,
                "lng": -131.3079985707
            },
            {
                "lat": 52.3017727461,
                "lng": -131.3072360915
            },
            {
                "lat": 52.2996520654,
                "lng": -131.3070985686
            },
            {
                "lat": 52.2961802069,
                "lng": -131.3083800988
            },
            {
                "lat": 52.2889290541,
                "lng": -131.3075101712
            },
            {
                "lat": 52.2716782461,
                "lng": -131.3003694169
            },
            {
                "lat": 52.2514535645,
                "lng": -131.2783511967
            },
            {
                "lat": 52.2377734713,
                "lng": -131.2575382407
            },
            {
                "lat": 52.2371790439,
                "lng": -131.2408758501
            },
            {
                "lat": 52.2437216035,
                "lng": -131.2283934953
            },
            {
                "lat": 52.2520486786,
                "lng": -131.2123261918
            },
            {
                "lat": 52.2669219546,
                "lng": -131.2063745764
            },
            {
                "lat": 52.2829825207,
                "lng": -131.2117313966
            },
            {
                "lat": 52.2913096789,
                "lng": -131.2075654875
            },
            {
                "lat": 52.3055881275,
                "lng": -131.1909032402
            },
            {
                "lat": 52.3159976563,
                "lng": -131.1787112226
            },
            {
                "lat": 52.3219841214,
                "lng": -131.1765897893
            },
            {
                "lat": 52.3243786713,
                "lng": -131.174468697
            },
            {
                "lat": 52.3254050472,
                "lng": -131.173874359
            },
            {
                "lat": 52.3259768588,
                "lng": -131.1736298038
            },
            {
                "lat": 52.3726196565,
                "lng": -131.24468927
            },
            {
                "lat": 52.3725358355,
                "lng": -131.2451631983
            },
            {
                "lat": 52.3721846456,
                "lng": -131.2461550714
            },
            {
                "lat": 52.3715979898,
                "lng": -131.2502746966
            },
            {
                "lat": 52.3675532387,
                "lng": -131.2600395074
            },
            {
                "lat": 52.3631320668,
                "lng": -131.2655634018
            },
            {
                "lat": 52.3570246617,
                "lng": -131.2689062325
            },
            {
                "lat": 52.3557545376,
                "lng": -131.2696081911
            },
            {
                "lat": 52.3514709544,
                "lng": -131.2715149105
            },
            {
                "lat": 52.3505940421,
                "lng": -131.2789002041
            },
            {
                "lat": 52.3502806916,
                "lng": -131.2815089618
            },
            {
                "lat": 52.3495674913,
                "lng": -131.2917326038
            },
            {
                "lat": 52.3521842947,
                "lng": -131.3022007834
            },
            {
                "lat": 52.3520855924,
                "lng": -131.3107141045
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 253,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 15,
            "MANAGEMENT_AREA_NAME": "Subarea 2-15",
            "LABEL": "2-15",
            "FISHERY_AREA_ID": 1906,
            "OBJECTID": 397,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 92155816.8892,
            "FEATURE_LENGTH_M": 39801.6236,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.3201599470175,
            "lng": -131.26064839962498
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.3675532387,
                "lng": -131.2600395074
            },
            {
                "lat": 52.3616024531,
                "lng": -131.2757418202
            },
            {
                "lat": 52.3561321156,
                "lng": -131.2950131528
            },
            {
                "lat": 52.3561318723,
                "lng": -131.3016817937
            },
            {
                "lat": 52.3520855924,
                "lng": -131.3107141045
            },
            {
                "lat": 52.3521842947,
                "lng": -131.3022007834
            },
            {
                "lat": 52.3495674913,
                "lng": -131.2917326038
            },
            {
                "lat": 52.3502806916,
                "lng": -131.2815089618
            },
            {
                "lat": 52.3505940421,
                "lng": -131.2789002041
            },
            {
                "lat": 52.3514709544,
                "lng": -131.2715149105
            },
            {
                "lat": 52.3557545376,
                "lng": -131.2696081911
            },
            {
                "lat": 52.3570246617,
                "lng": -131.2689062325
            },
            {
                "lat": 52.3631320668,
                "lng": -131.2655634018
            },
            {
                "lat": 52.3675532387,
                "lng": -131.2600395074
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 254,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 398,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2370340.0632,
            "FEATURE_LENGTH_M": 8639.8856,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.356504803642856,
            "lng": -131.28094036964285
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.3744848,
                "lng": -127.8860246441
            },
            {
                "lat": 52.3817514876,
                "lng": -127.8962404364
            },
            {
                "lat": 52.3897130032,
                "lng": -127.9054035293
            },
            {
                "lat": 52.4035457404,
                "lng": -127.9244163186
            },
            {
                "lat": 52.4186701184,
                "lng": -127.9412765404
            },
            {
                "lat": 52.4402804693,
                "lng": -127.9507829664
            },
            {
                "lat": 52.4528122677,
                "lng": -127.9525075086
            },
            {
                "lat": 52.4662130188,
                "lng": -127.9451597751
            },
            {
                "lat": 52.4676895229,
                "lng": -127.9552145531
            },
            {
                "lat": 52.4502372249,
                "lng": -127.9631950254
            },
            {
                "lat": 52.4085315319,
                "lng": -127.9640878254
            },
            {
                "lat": 52.3774758128,
                "lng": -127.9788743691
            },
            {
                "lat": 52.364303012,
                "lng": -127.9948653154
            },
            {
                "lat": 52.3433649533,
                "lng": -127.9917748551
            },
            {
                "lat": 52.329704647,
                "lng": -127.9906236052
            },
            {
                "lat": 52.3278045371,
                "lng": -127.9850163186
            },
            {
                "lat": 52.3266300629,
                "lng": -127.9733351218
            },
            {
                "lat": 52.3279453221,
                "lng": -127.9653546764
            },
            {
                "lat": 52.3379895366,
                "lng": -127.9548344154
            },
            {
                "lat": 52.3412890396,
                "lng": -127.9534219268
            },
            {
                "lat": 52.3471909977,
                "lng": -127.9508967396
            },
            {
                "lat": 52.3539505816,
                "lng": -127.9500510158
            },
            {
                "lat": 52.359301841,
                "lng": -127.9483569097
            },
            {
                "lat": 52.3625870834,
                "lng": -127.9449761687
            },
            {
                "lat": 52.3663404681,
                "lng": -127.9380341416
            },
            {
                "lat": 52.3723519817,
                "lng": -127.922537992
            },
            {
                "lat": 52.3747897301,
                "lng": -127.9086451029
            },
            {
                "lat": 52.3756372164,
                "lng": -127.8942797184
            },
            {
                "lat": 52.3744848,
                "lng": -127.8860246441
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 255,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 399,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 39159256.3978,
            "FEATURE_LENGTH_M": 38757.0827,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.379898993396544,
            "lng": -127.94538662618619
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.4424585725,
                "lng": -128.4073036262
            },
            {
                "lat": 52.4203685229,
                "lng": -128.4087378652
            },
            {
                "lat": 52.4020460967,
                "lng": -128.412063626
            },
            {
                "lat": 52.3965756698,
                "lng": -128.4256283217
            },
            {
                "lat": 52.3920553769,
                "lng": -128.4463346936
            },
            {
                "lat": 52.3872949897,
                "lng": -128.4360966784
            },
            {
                "lat": 52.3794442003,
                "lng": -128.4320519927
            },
            {
                "lat": 52.3725429498,
                "lng": -128.4349054873
            },
            {
                "lat": 52.3674276797,
                "lng": -128.4432072985
            },
            {
                "lat": 52.3709984308,
                "lng": -128.4212031483
            },
            {
                "lat": 52.3763545984,
                "lng": -128.3920435201
            },
            {
                "lat": 52.3882486865,
                "lng": -128.3694466954
            },
            {
                "lat": 52.4114490755,
                "lng": -128.3539738083
            },
            {
                "lat": 52.4286992177,
                "lng": -128.3242337847
            },
            {
                "lat": 52.4275096474,
                "lng": -128.298660153
            },
            {
                "lat": 52.4471398578,
                "lng": -128.3040014827
            },
            {
                "lat": 52.4584424192,
                "lng": -128.3034061225
            },
            {
                "lat": 52.4596334168,
                "lng": -128.3224486641
            },
            {
                "lat": 52.4489252363,
                "lng": -128.3640901773
            },
            {
                "lat": 52.4424585725,
                "lng": -128.4073036262
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 256,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 400,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 44433315.6882,
            "FEATURE_LENGTH_M": 34341.2302,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.411003660860004,
            "lng": -128.38535703861
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.459404154,
                "lng": -128.2122339954
            },
            {
                "lat": 52.4420999277,
                "lng": -128.2220912481
            },
            {
                "lat": 52.4152875136,
                "lng": -128.2346651403
            },
            {
                "lat": 52.4129068003,
                "lng": -128.2239527342
            },
            {
                "lat": 52.4063638621,
                "lng": -128.2061007177
            },
            {
                "lat": 52.40279367,
                "lng": -128.1829067284
            },
            {
                "lat": 52.4159275098,
                "lng": -128.1619567236
            },
            {
                "lat": 52.4298285259,
                "lng": -128.1634366087
            },
            {
                "lat": 52.4466891773,
                "lng": -128.1936029974
            },
            {
                "lat": 52.459404154,
                "lng": -128.2122339954
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 257,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 401,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 18258086.7316,
            "FEATURE_LENGTH_M": 17239.6339,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.42907052947,
            "lng": -128.20131808892
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.3563494312,
                "lng": -131.3446810835
            },
            {
                "lat": 52.355156177,
                "lng": -131.3473964005
            },
            {
                "lat": 52.3549807487,
                "lng": -131.3487860099
            },
            {
                "lat": 52.3548582478,
                "lng": -131.3497470729
            },
            {
                "lat": 52.352814183,
                "lng": -131.3498380278
            },
            {
                "lat": 52.3507420775,
                "lng": -131.3530429803
            },
            {
                "lat": 52.3500716038,
                "lng": -131.35417181
            },
            {
                "lat": 52.3509748842,
                "lng": -131.3601540721
            },
            {
                "lat": 52.3538327068,
                "lng": -131.3696591693
            },
            {
                "lat": 52.3514522815,
                "lng": -131.3741910099
            },
            {
                "lat": 52.3471681432,
                "lng": -131.3768007158
            },
            {
                "lat": 52.3414578019,
                "lng": -131.3737178725
            },
            {
                "lat": 52.3295587614,
                "lng": -131.3665774428
            },
            {
                "lat": 52.3179011418,
                "lng": -131.3620454935
            },
            {
                "lat": 52.3024331084,
                "lng": -131.3580021905
            },
            {
                "lat": 52.2891078774,
                "lng": -131.3451536958
            },
            {
                "lat": 52.2753108776,
                "lng": -131.3351590524
            },
            {
                "lat": 52.2665063449,
                "lng": -131.3273166016
            },
            {
                "lat": 52.2672198523,
                "lng": -131.3170778817
            },
            {
                "lat": 52.2736435806,
                "lng": -131.3149413275
            },
            {
                "lat": 52.2841107321,
                "lng": -131.3149410177
            },
            {
                "lat": 52.2933923866,
                "lng": -131.3135068732
            },
            {
                "lat": 52.2961802069,
                "lng": -131.3083800988
            },
            {
                "lat": 52.2996520654,
                "lng": -131.3070985686
            },
            {
                "lat": 52.3017727461,
                "lng": -131.3072360915
            },
            {
                "lat": 52.3023948698,
                "lng": -131.3079985707
            },
            {
                "lat": 52.3250432262,
                "lng": -131.3185738183
            },
            {
                "lat": 52.3286290351,
                "lng": -131.3188632706
            },
            {
                "lat": 52.3286976215,
                "lng": -131.3226923192
            },
            {
                "lat": 52.3287466004,
                "lng": -131.3256372874
            },
            {
                "lat": 52.3308907186,
                "lng": -131.3287352921
            },
            {
                "lat": 52.3347058289,
                "lng": -131.3294529655
            },
            {
                "lat": 52.3385041142,
                "lng": -131.3301696011
            },
            {
                "lat": 52.345165571,
                "lng": -131.332061741
            },
            {
                "lat": 52.3494497867,
                "lng": -131.3363489268
            },
            {
                "lat": 52.3551597169,
                "lng": -131.3399204357
            },
            {
                "lat": 52.3563494312,
                "lng": -131.3446810835
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 258,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 16,
            "MANAGEMENT_AREA_NAME": "Subarea 2-16",
            "LABEL": "2-16",
            "FISHERY_AREA_ID": 1907,
            "OBJECTID": 402,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 24819538.794,
            "FEATURE_LENGTH_M": 24621.9082,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.325415796989205,
            "lng": -131.33823669924328
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.4424585725,
                "lng": -128.4073036262
            },
            {
                "lat": 52.4468882552,
                "lng": -128.412551752
            },
            {
                "lat": 52.4471327199,
                "lng": -128.4130401587
            },
            {
                "lat": 52.4424585725,
                "lng": -128.4073036262
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 259,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 403,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 3330.0962,
            "FEATURE_LENGTH_M": 1302.3747,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.444734530025,
            "lng": -128.41004979077502
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.4591372658,
                "lng": -127.2016593921
            },
            {
                "lat": 52.4502607177,
                "lng": -127.2261498361
            },
            {
                "lat": 52.4499547347,
                "lng": -127.2328803304
            },
            {
                "lat": 52.4524039965,
                "lng": -127.2380830218
            },
            {
                "lat": 52.4521642134,
                "lng": -127.2422254617
            },
            {
                "lat": 52.4426250604,
                "lng": -127.265352192
            },
            {
                "lat": 52.439227989,
                "lng": -127.2735827395
            },
            {
                "lat": 52.3957479248,
                "lng": -127.263267887
            },
            {
                "lat": 52.3731427102,
                "lng": -127.264457472
            },
            {
                "lat": 52.3594591235,
                "lng": -127.247803704
            },
            {
                "lat": 52.3495023226,
                "lng": -127.2242427303
            },
            {
                "lat": 52.3538965992,
                "lng": -127.1964032929
            },
            {
                "lat": 52.3438418074,
                "lng": -127.1639548739
            },
            {
                "lat": 52.3489493312,
                "lng": -127.1550215264
            },
            {
                "lat": 52.3649748284,
                "lng": -127.1464919349
            },
            {
                "lat": 52.3700683156,
                "lng": -127.1618720571
            },
            {
                "lat": 52.3829228009,
                "lng": -127.1753379646
            },
            {
                "lat": 52.3976177545,
                "lng": -127.1771778628
            },
            {
                "lat": 52.4227145031,
                "lng": -127.1768726687
            },
            {
                "lat": 52.4462818118,
                "lng": -127.1875839928
            },
            {
                "lat": 52.4591372658,
                "lng": -127.2016593921
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 260,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 8-10",
            "LABEL": "8-10",
            "FISHERY_AREA_ID": 2476,
            "OBJECTID": 404,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 69312447.5411,
            "FEATURE_LENGTH_M": 35884.1286,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.405430051261895,
            "lng": -127.21057525395715
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.4018586787,
                "lng": -128.7543180709
            },
            {
                "lat": 52.4017751277,
                "lng": -128.7586669424
            },
            {
                "lat": 52.4036947753,
                "lng": -128.7614901601
            },
            {
                "lat": 52.4036944086,
                "lng": -128.8927758603
            },
            {
                "lat": 52.4036948053,
                "lng": -128.9841311628
            },
            {
                "lat": 52.3888890635,
                "lng": -128.9666747845
            },
            {
                "lat": 52.2833329854,
                "lng": -128.8665316678
            },
            {
                "lat": 52.2614709871,
                "lng": -128.8457949641
            },
            {
                "lat": 52.2614713131,
                "lng": -128.7219389891
            },
            {
                "lat": 52.2708016117,
                "lng": -128.6750181383
            },
            {
                "lat": 52.279090402,
                "lng": -128.6656797581
            },
            {
                "lat": 52.2810826414,
                "lng": -128.6665648431
            },
            {
                "lat": 52.2822000019,
                "lng": -128.6680755928
            },
            {
                "lat": 52.2888265482,
                "lng": -128.6731718139
            },
            {
                "lat": 52.2922326396,
                "lng": -128.6812739501
            },
            {
                "lat": 52.3019906709,
                "lng": -128.6919862018
            },
            {
                "lat": 52.3110316982,
                "lng": -128.6955566214
            },
            {
                "lat": 52.3181687113,
                "lng": -128.7010343855
            },
            {
                "lat": 52.3219757036,
                "lng": -128.7124474314
            },
            {
                "lat": 52.3362538835,
                "lng": -128.7210236603
            },
            {
                "lat": 52.3473132325,
                "lng": -128.7181394889
            },
            {
                "lat": 52.3531460358,
                "lng": -128.7164924081
            },
            {
                "lat": 52.3676603589,
                "lng": -128.7224427893
            },
            {
                "lat": 52.3748014407,
                "lng": -128.7343446394
            },
            {
                "lat": 52.3878864214,
                "lng": -128.7462461801
            },
            {
                "lat": 52.395027499,
                "lng": -128.7469488803
            },
            {
                "lat": 52.3983349783,
                "lng": -128.7491607199
            },
            {
                "lat": 52.4018586787,
                "lng": -128.7543180709
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 261,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 31,
            "MANAGEMENT_AREA_NAME": "Subarea 7-31",
            "LABEL": "7-31",
            "FISHERY_AREA_ID": 2465,
            "OBJECTID": 405,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 218490553.2678,
            "FEATURE_LENGTH_M": 62862.2854,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.339984475082126,
            "lng": -128.74972314912856
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.4152875136,
                "lng": -128.2346651403
            },
            {
                "lat": 52.3974385239,
                "lng": -128.2459563431
            },
            {
                "lat": 52.3641284305,
                "lng": -128.232284607
            },
            {
                "lat": 52.3433078334,
                "lng": -128.2263337586
            },
            {
                "lat": 52.3296244101,
                "lng": -128.2358547877
            },
            {
                "lat": 52.3165405326,
                "lng": -128.2459560131
            },
            {
                "lat": 52.3105891146,
                "lng": -128.2632133999
            },
            {
                "lat": 52.2891733664,
                "lng": -128.2727351952
            },
            {
                "lat": 52.2787520465,
                "lng": -128.2805934136
            },
            {
                "lat": 52.2680472812,
                "lng": -128.2885136497
            },
            {
                "lat": 52.2615700778,
                "lng": -128.2845458127
            },
            {
                "lat": 52.2622485536,
                "lng": -128.2441100379
            },
            {
                "lat": 52.2795526979,
                "lng": -128.2151949523
            },
            {
                "lat": 52.279189309,
                "lng": -128.2077022282
            },
            {
                "lat": 52.2790266577,
                "lng": -128.2043456878
            },
            {
                "lat": 52.2790263294,
                "lng": -128.1942597081
            },
            {
                "lat": 52.2857940744,
                "lng": -128.1838987495
            },
            {
                "lat": 52.2970013913,
                "lng": -128.1679533396
            },
            {
                "lat": 52.3027114318,
                "lng": -128.1648558695
            },
            {
                "lat": 52.3107532363,
                "lng": -128.164153664
            },
            {
                "lat": 52.3612218872,
                "lng": -128.1389162713
            },
            {
                "lat": 52.3842623792,
                "lng": -128.1246489132
            },
            {
                "lat": 52.3944210596,
                "lng": -128.1317900732
            },
            {
                "lat": 52.3944247064,
                "lng": -128.1649479693
            },
            {
                "lat": 52.3976443983,
                "lng": -128.1642305045
            },
            {
                "lat": 52.4005466477,
                "lng": -128.1637262482
            },
            {
                "lat": 52.4159275098,
                "lng": -128.1619567236
            },
            {
                "lat": 52.40279367,
                "lng": -128.1829067284
            },
            {
                "lat": 52.4063638621,
                "lng": -128.2061007177
            },
            {
                "lat": 52.4129068003,
                "lng": -128.2239527342
            },
            {
                "lat": 52.4152875136,
                "lng": -128.2346651403
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 262,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 7-13",
            "LABEL": "7-13",
            "FISHERY_AREA_ID": 2448,
            "OBJECTID": 406,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 98096106.6192,
            "FEATURE_LENGTH_M": 50348.4372,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.33985687890968,
            "lng": -128.20822478650646
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.3224446201,
                "lng": -128.0505669466
            },
            {
                "lat": 52.3312644388,
                "lng": -128.0570676959
            },
            {
                "lat": 52.3412588775,
                "lng": -128.0639646659
            },
            {
                "lat": 52.3657643168,
                "lng": -128.0777585715
            },
            {
                "lat": 52.3850402816,
                "lng": -128.086563163
            },
            {
                "lat": 52.4045523544,
                "lng": -128.09298688
            },
            {
                "lat": 52.4097863422,
                "lng": -128.1003718941
            },
            {
                "lat": 52.4069283907,
                "lng": -128.1079872546
            },
            {
                "lat": 52.3955119183,
                "lng": -128.1179809181
            },
            {
                "lat": 52.3867606078,
                "lng": -128.1231687845
            },
            {
                "lat": 52.3842623792,
                "lng": -128.1246489132
            },
            {
                "lat": 52.3612218872,
                "lng": -128.1389162713
            },
            {
                "lat": 52.3107532363,
                "lng": -128.164153664
            },
            {
                "lat": 52.3027114318,
                "lng": -128.1648558695
            },
            {
                "lat": 52.2970013913,
                "lng": -128.1679533396
            },
            {
                "lat": 52.2857940744,
                "lng": -128.1838987495
            },
            {
                "lat": 52.2796366922,
                "lng": -128.1823427614
            },
            {
                "lat": 52.2844163816,
                "lng": -128.1778407628
            },
            {
                "lat": 52.2866481533,
                "lng": -128.1733703561
            },
            {
                "lat": 52.2865296601,
                "lng": -128.1577151668
            },
            {
                "lat": 52.2841536087,
                "lng": -128.1527100798
            },
            {
                "lat": 52.2915798801,
                "lng": -128.1302340859
            },
            {
                "lat": 52.3019978493,
                "lng": -128.1244052642
            },
            {
                "lat": 52.3091353437,
                "lng": -128.1175087703
            },
            {
                "lat": 52.3134194304,
                "lng": -128.1034695925
            },
            {
                "lat": 52.3191307758,
                "lng": -128.0863348806
            },
            {
                "lat": 52.3236508351,
                "lng": -128.069915204
            },
            {
                "lat": 52.325313086,
                "lng": -128.0608673187
            },
            {
                "lat": 52.3224446201,
                "lng": -128.0505669466
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 263,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 407,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 52553258.2404,
            "FEATURE_LENGTH_M": 37762.1802,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.33169354706207,
            "lng": -128.1175905093448
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.3974385239,
                "lng": -128.2459563431
            },
            {
                "lat": 52.3879659809,
                "lng": -128.2506558745
            },
            {
                "lat": 52.3802332781,
                "lng": -128.2744448589
            },
            {
                "lat": 52.3792496771,
                "lng": -128.3098454255
            },
            {
                "lat": 52.3430210343,
                "lng": -128.3218836351
            },
            {
                "lat": 52.2989918167,
                "lng": -128.3187872593
            },
            {
                "lat": 52.2830431467,
                "lng": -128.3176883416
            },
            {
                "lat": 52.2793348916,
                "lng": -128.3211363315
            },
            {
                "lat": 52.2777938668,
                "lng": -128.3247370011
            },
            {
                "lat": 52.2784575835,
                "lng": -128.3186800982
            },
            {
                "lat": 52.2792664688,
                "lng": -128.3154751494
            },
            {
                "lat": 52.2908169886,
                "lng": -128.2909693234
            },
            {
                "lat": 52.2855678233,
                "lng": -128.2918710311
            },
            {
                "lat": 52.2819020566,
                "lng": -128.2936397193
            },
            {
                "lat": 52.2805750933,
                "lng": -128.2979727407
            },
            {
                "lat": 52.2788510051,
                "lng": -128.3010862244
            },
            {
                "lat": 52.2714573987,
                "lng": -128.3073267851
            },
            {
                "lat": 52.2671663512,
                "lng": -128.3090207489
            },
            {
                "lat": 52.2671660065,
                "lng": -128.3002165608
            },
            {
                "lat": 52.2680472812,
                "lng": -128.2885136497
            },
            {
                "lat": 52.2787520465,
                "lng": -128.2805934136
            },
            {
                "lat": 52.2891733664,
                "lng": -128.2727351952
            },
            {
                "lat": 52.3105891146,
                "lng": -128.2632133999
            },
            {
                "lat": 52.3165405326,
                "lng": -128.2459560131
            },
            {
                "lat": 52.3296244101,
                "lng": -128.2358547877
            },
            {
                "lat": 52.3433078334,
                "lng": -128.2263337586
            },
            {
                "lat": 52.3641284305,
                "lng": -128.232284607
            },
            {
                "lat": 52.3974385239,
                "lng": -128.2459563431
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 264,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 408,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 57565781.9307,
            "FEATURE_LENGTH_M": 40019.5792,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.3109250189607,
            "lng": -128.28581552213572
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.3445588845,
                "lng": -126.9937594302
            },
            {
                "lat": 52.3324626478,
                "lng": -126.9860613152
            },
            {
                "lat": 52.3013994823,
                "lng": -127.0076225062
            },
            {
                "lat": 52.3121190457,
                "lng": -126.965080405
            },
            {
                "lat": 52.3056902495,
                "lng": -126.9349972265
            },
            {
                "lat": 52.325191138,
                "lng": -126.9163813472
            },
            {
                "lat": 52.3420328171,
                "lng": -126.8552236346
            },
            {
                "lat": 52.3473504985,
                "lng": -126.7967214692
            },
            {
                "lat": 52.3517802038,
                "lng": -126.7479708532
            },
            {
                "lat": 52.3881229696,
                "lng": -126.7373347761
            },
            {
                "lat": 52.4147143473,
                "lng": -126.7532884025
            },
            {
                "lat": 52.416488528,
                "lng": -126.7825387022
            },
            {
                "lat": 52.4085120754,
                "lng": -126.8428121074
            },
            {
                "lat": 52.3934408394,
                "lng": -126.9154972601
            },
            {
                "lat": 52.374828516,
                "lng": -126.9695662177
            },
            {
                "lat": 52.3597608232,
                "lng": -126.987289092
            },
            {
                "lat": 52.3445588845,
                "lng": -126.9937594302
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 265,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 8-11",
            "LABEL": "8-11",
            "FISHERY_AREA_ID": 2477,
            "OBJECTID": 409,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 125779781.0153,
            "FEATURE_LENGTH_M": 51676.1239,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.356647761800005,
            "lng": -126.89328848091178
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.3495023226,
                "lng": -127.2242427303
            },
            {
                "lat": 52.3594591235,
                "lng": -127.247803704
            },
            {
                "lat": 52.3731427102,
                "lng": -127.264457472
            },
            {
                "lat": 52.3957479248,
                "lng": -127.263267887
            },
            {
                "lat": 52.3925396116,
                "lng": -127.294403527
            },
            {
                "lat": 52.3779527803,
                "lng": -127.3473970138
            },
            {
                "lat": 52.3533740047,
                "lng": -127.3980793818
            },
            {
                "lat": 52.3234253372,
                "lng": -127.4264982229
            },
            {
                "lat": 52.2996183567,
                "lng": -127.4664298583
            },
            {
                "lat": 52.2627566487,
                "lng": -127.5201873092
            },
            {
                "lat": 52.2389485473,
                "lng": -127.5501410247
            },
            {
                "lat": 52.2174451674,
                "lng": -127.5823972471
            },
            {
                "lat": 52.2143742042,
                "lng": -127.6062009358
            },
            {
                "lat": 52.23894845,
                "lng": -127.6438296742
            },
            {
                "lat": 52.2473941547,
                "lng": -127.6799310292
            },
            {
                "lat": 52.2351079848,
                "lng": -127.715256068
            },
            {
                "lat": 52.2251238444,
                "lng": -127.7490456598
            },
            {
                "lat": 52.221283586,
                "lng": -127.7559581824
            },
            {
                "lat": 52.2182460824,
                "lng": -127.7653193214
            },
            {
                "lat": 52.206772286,
                "lng": -127.7789007763
            },
            {
                "lat": 52.1959955998,
                "lng": -127.7982098282
            },
            {
                "lat": 52.1934743446,
                "lng": -127.8045885357
            },
            {
                "lat": 52.1293790992,
                "lng": -127.8517767614
            },
            {
                "lat": 52.1309591805,
                "lng": -127.8398290737
            },
            {
                "lat": 52.1268276605,
                "lng": -127.8293605527
            },
            {
                "lat": 52.1188389512,
                "lng": -127.827430665
            },
            {
                "lat": 52.1191175456,
                "lng": -127.7839199613
            },
            {
                "lat": 52.119392225,
                "lng": -127.7676697513
            },
            {
                "lat": 52.114712093,
                "lng": -127.7434394557
            },
            {
                "lat": 52.1097526766,
                "lng": -127.7401269846
            },
            {
                "lat": 52.097084429,
                "lng": -127.7456356772
            },
            {
                "lat": 52.0943303375,
                "lng": -127.7638161255
            },
            {
                "lat": 52.0893744584,
                "lng": -127.7971417657
            },
            {
                "lat": 52.0874442643,
                "lng": -127.8456111997
            },
            {
                "lat": 52.0893746316,
                "lng": -127.8651656069
            },
            {
                "lat": 52.0935058154,
                "lng": -127.8786623073
            },
            {
                "lat": 52.0885465572,
                "lng": -127.8748012086
            },
            {
                "lat": 52.0789076233,
                "lng": -127.8728793869
            },
            {
                "lat": 52.0689924496,
                "lng": -127.8764577771
            },
            {
                "lat": 52.0722882586,
                "lng": -127.8708417868
            },
            {
                "lat": 52.0797726394,
                "lng": -127.8686824031
            },
            {
                "lat": 52.0827979498,
                "lng": -127.8636404273
            },
            {
                "lat": 52.0784803815,
                "lng": -127.8502584957
            },
            {
                "lat": 52.0728645865,
                "lng": -127.8358614692
            },
            {
                "lat": 52.0627865004,
                "lng": -127.8252105334
            },
            {
                "lat": 52.0519896636,
                "lng": -127.827507564
            },
            {
                "lat": 52.0423474467,
                "lng": -127.8377307497
            },
            {
                "lat": 52.0335661031,
                "lng": -127.8659437913
            },
            {
                "lat": 52.0256464799,
                "lng": -127.8791890823
            },
            {
                "lat": 52.0202409968,
                "lng": -127.8911821013
            },
            {
                "lat": 51.9931108054,
                "lng": -127.8922658722
            },
            {
                "lat": 51.9879300432,
                "lng": -127.8905635321
            },
            {
                "lat": 51.9815982086,
                "lng": -127.8868177956
            },
            {
                "lat": 51.975261947,
                "lng": -127.8783265165
            },
            {
                "lat": 51.9705120327,
                "lng": -127.8742981288
            },
            {
                "lat": 51.9641804751,
                "lng": -127.8741523529
            },
            {
                "lat": 51.9588505609,
                "lng": -127.8786157778
            },
            {
                "lat": 51.9516562337,
                "lng": -127.8842320368
            },
            {
                "lat": 51.9413485727,
                "lng": -127.892783733
            },
            {
                "lat": 51.9471092441,
                "lng": -127.8798759634
            },
            {
                "lat": 51.948467854,
                "lng": -127.8701696634
            },
            {
                "lat": 51.9503861538,
                "lng": -127.8564608796
            },
            {
                "lat": 51.957366386,
                "lng": -127.8355263218
            },
            {
                "lat": 51.9741175297,
                "lng": -127.8149331055
            },
            {
                "lat": 51.9793511713,
                "lng": -127.7793349846
            },
            {
                "lat": 51.9842371389,
                "lng": -127.7419972155
            },
            {
                "lat": 51.9985469275,
                "lng": -127.7228006906
            },
            {
                "lat": 52.0313492956,
                "lng": -127.7098851797
            },
            {
                "lat": 52.0536837357,
                "lng": -127.7001119593
            },
            {
                "lat": 52.0679887308,
                "lng": -127.6981889182
            },
            {
                "lat": 52.1025582655,
                "lng": -127.6870498977
            },
            {
                "lat": 52.146666817,
                "lng": -127.6652752744
            },
            {
                "lat": 52.1745833856,
                "lng": -127.6234057932
            },
            {
                "lat": 52.196357614,
                "lng": -127.5826484423
            },
            {
                "lat": 52.2058491807,
                "lng": -127.5508192013
            },
            {
                "lat": 52.2159004513,
                "lng": -127.5067130025
            },
            {
                "lat": 52.2276233196,
                "lng": -127.4787981597
            },
            {
                "lat": 52.2432559394,
                "lng": -127.4486463406
            },
            {
                "lat": 52.2577736713,
                "lng": -127.4201739184
            },
            {
                "lat": 52.2622418828,
                "lng": -127.3972787028
            },
            {
                "lat": 52.2601160939,
                "lng": -127.3887790592
            },
            {
                "lat": 52.2588880292,
                "lng": -127.3838811888
            },
            {
                "lat": 52.2683829068,
                "lng": -127.3626632087
            },
            {
                "lat": 52.2795482911,
                "lng": -127.3392181349
            },
            {
                "lat": 52.2817797094,
                "lng": -127.3157654942
            },
            {
                "lat": 52.3002049832,
                "lng": -127.2906415605
            },
            {
                "lat": 52.3052328085,
                "lng": -127.2671886227
            },
            {
                "lat": 52.3169557664,
                "lng": -127.2504422732
            },
            {
                "lat": 52.3314741746,
                "lng": -127.2325749803
            },
            {
                "lat": 52.3495023226,
                "lng": -127.2242427303
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 266,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 410,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 504691730.6521,
            "FEATURE_LENGTH_M": 177773.9292,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.145798919285546,
            "lng": -127.6686651629645
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2358630464,
                "lng": -131.3582760656
            },
            {
                "lat": 52.234363991,
                "lng": -131.3526312741
            },
            {
                "lat": 52.2338867372,
                "lng": -131.3476410337
            },
            {
                "lat": 52.2360307577,
                "lng": -131.3445434609
            },
            {
                "lat": 52.2488782909,
                "lng": -131.3478694359
            },
            {
                "lat": 52.2643465826,
                "lng": -131.3592994967
            },
            {
                "lat": 52.2736247753,
                "lng": -131.3669127765
            },
            {
                "lat": 52.2776712544,
                "lng": -131.3742828389
            },
            {
                "lat": 52.2776713531,
                "lng": -131.3792872005
            },
            {
                "lat": 52.2748146986,
                "lng": -131.3854673793
            },
            {
                "lat": 52.261966842,
                "lng": -131.3842769881
            },
            {
                "lat": 52.2495919925,
                "lng": -131.3818964453
            },
            {
                "lat": 52.2400742437,
                "lng": -131.3757171377
            },
            {
                "lat": 52.2363698745,
                "lng": -131.3726038102
            },
            {
                "lat": 52.2371904763,
                "lng": -131.3685916004
            },
            {
                "lat": 52.2371906414,
                "lng": -131.359771978
            },
            {
                "lat": 52.2368584429,
                "lng": -131.3596498615
            },
            {
                "lat": 52.2358630464,
                "lng": -131.3582760656
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 267,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 37,
            "MANAGEMENT_AREA_NAME": "Subarea 2-37",
            "LABEL": "2-37",
            "FISHERY_AREA_ID": 1928,
            "OBJECTID": 411,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 8463685.4204,
            "FEATURE_LENGTH_M": 12629.8201,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.2495698359389,
            "lng": -131.36538860271668
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2881966625,
                "lng": -131.0588685894
            },
            {
                "lat": 52.2881968919,
                "lng": -131.0911414984
            },
            {
                "lat": 52.2846495468,
                "lng": -131.096863108
            },
            {
                "lat": 52.2751314739,
                "lng": -131.0949556288
            },
            {
                "lat": 52.2641825625,
                "lng": -131.0966188243
            },
            {
                "lat": 52.2575221871,
                "lng": -131.1056675715
            },
            {
                "lat": 52.2549051108,
                "lng": -131.1246938575
            },
            {
                "lat": 52.2499085602,
                "lng": -131.1525419715
            },
            {
                "lat": 52.241580272,
                "lng": -131.1825258919
            },
            {
                "lat": 52.2349169929,
                "lng": -131.1925204621
            },
            {
                "lat": 52.2249225031,
                "lng": -131.1998908534
            },
            {
                "lat": 52.2168350886,
                "lng": -131.1949011043
            },
            {
                "lat": 52.2156444781,
                "lng": -131.1815647185
            },
            {
                "lat": 52.2182614502,
                "lng": -131.1765750796
            },
            {
                "lat": 52.2223054486,
                "lng": -131.1577758587
            },
            {
                "lat": 52.2261124029,
                "lng": -131.1427919559
            },
            {
                "lat": 52.218498926,
                "lng": -131.1318367461
            },
            {
                "lat": 52.2108840283,
                "lng": -131.1246947545
            },
            {
                "lat": 52.2092171524,
                "lng": -131.1175685334
            },
            {
                "lat": 52.2094579434,
                "lng": -131.1087651592
            },
            {
                "lat": 52.2089808982,
                "lng": -131.1006624373
            },
            {
                "lat": 52.2087444917,
                "lng": -131.0878138571
            },
            {
                "lat": 52.208744213,
                "lng": -131.0668792896
            },
            {
                "lat": 52.213264534,
                "lng": -131.0478524269
            },
            {
                "lat": 52.2168352097,
                "lng": -131.0231023614
            },
            {
                "lat": 52.2142064284,
                "lng": -131.0145105785
            },
            {
                "lat": 52.2157175688,
                "lng": -131.0102233238
            },
            {
                "lat": 52.2159658409,
                "lng": -131.0069727164
            },
            {
                "lat": 52.2148782715,
                "lng": -131.0026700579
            },
            {
                "lat": 52.2144962973,
                "lng": -131.0005636982
            },
            {
                "lat": 52.2084425392,
                "lng": -130.966811907
            },
            {
                "lat": 52.2881966625,
                "lng": -131.0588685894
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 268,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 17,
            "MANAGEMENT_AREA_NAME": "Subarea 2-17",
            "LABEL": "2-17",
            "FISHERY_AREA_ID": 1908,
            "OBJECTID": 412,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 71980913.774,
            "FEATURE_LENGTH_M": 42777.5514,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.232493832418754,
            "lng": -131.09749041907816
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.3564334143,
                "lng": -127.8639065039
            },
            {
                "lat": 52.3627315936,
                "lng": -127.8670499801
            },
            {
                "lat": 52.3708422605,
                "lng": -127.8841782739
            },
            {
                "lat": 52.37149406,
                "lng": -127.8845066388
            },
            {
                "lat": 52.3744848,
                "lng": -127.8860246441
            },
            {
                "lat": 52.3756372164,
                "lng": -127.8942797184
            },
            {
                "lat": 52.3747897301,
                "lng": -127.9086451029
            },
            {
                "lat": 52.3723519817,
                "lng": -127.922537992
            },
            {
                "lat": 52.3663404681,
                "lng": -127.9380341416
            },
            {
                "lat": 52.3625870834,
                "lng": -127.9449761687
            },
            {
                "lat": 52.359301841,
                "lng": -127.9483569097
            },
            {
                "lat": 52.3539505816,
                "lng": -127.9500510158
            },
            {
                "lat": 52.3471909977,
                "lng": -127.9508967396
            },
            {
                "lat": 52.3412890396,
                "lng": -127.9534219268
            },
            {
                "lat": 52.3379895366,
                "lng": -127.9548344154
            },
            {
                "lat": 52.3279453221,
                "lng": -127.9653546764
            },
            {
                "lat": 52.3266300629,
                "lng": -127.9733351218
            },
            {
                "lat": 52.3278045371,
                "lng": -127.9850163186
            },
            {
                "lat": 52.329704647,
                "lng": -127.9906236052
            },
            {
                "lat": 52.3227279514,
                "lng": -128.003555685
            },
            {
                "lat": 52.3197625247,
                "lng": -128.0069428844
            },
            {
                "lat": 52.318073669,
                "lng": -128.0078126411
            },
            {
                "lat": 52.3196098841,
                "lng": -128.025848801
            },
            {
                "lat": 52.3190722683,
                "lng": -128.0469812979
            },
            {
                "lat": 52.3224446201,
                "lng": -128.0505669466
            },
            {
                "lat": 52.325313086,
                "lng": -128.0608673187
            },
            {
                "lat": 52.3236508351,
                "lng": -128.069915204
            },
            {
                "lat": 52.3191307758,
                "lng": -128.0863348806
            },
            {
                "lat": 52.3134194304,
                "lng": -128.1034695925
            },
            {
                "lat": 52.3091353437,
                "lng": -128.1175087703
            },
            {
                "lat": 52.3019978493,
                "lng": -128.1244052642
            },
            {
                "lat": 52.2915798801,
                "lng": -128.1302340859
            },
            {
                "lat": 52.2841536087,
                "lng": -128.1527100798
            },
            {
                "lat": 52.2865296601,
                "lng": -128.1577151668
            },
            {
                "lat": 52.2866481533,
                "lng": -128.1733703561
            },
            {
                "lat": 52.2844163816,
                "lng": -128.1778407628
            },
            {
                "lat": 52.2796366922,
                "lng": -128.1823427614
            },
            {
                "lat": 52.2746234949,
                "lng": -128.1815802377
            },
            {
                "lat": 52.2485164893,
                "lng": -128.1762389609
            },
            {
                "lat": 52.2451707432,
                "lng": -128.1741176431
            },
            {
                "lat": 52.2448541291,
                "lng": -128.1713258661
            },
            {
                "lat": 52.2446142619,
                "lng": -128.1694030168
            },
            {
                "lat": 52.2457269834,
                "lng": -128.1659545161
            },
            {
                "lat": 52.2447355086,
                "lng": -128.159468878
            },
            {
                "lat": 52.2436374789,
                "lng": -128.1549532187
            },
            {
                "lat": 52.2433054699,
                "lng": -128.149886951
            },
            {
                "lat": 52.2414325647,
                "lng": -128.1417394788
            },
            {
                "lat": 52.2392311478,
                "lng": -128.1313935827
            },
            {
                "lat": 52.2375790388,
                "lng": -128.1226954366
            },
            {
                "lat": 52.2346072208,
                "lng": -128.1163023788
            },
            {
                "lat": 52.2317430632,
                "lng": -128.1148683752
            },
            {
                "lat": 52.2289925984,
                "lng": -128.1177361733
            },
            {
                "lat": 52.2276687361,
                "lng": -128.1210320419
            },
            {
                "lat": 52.2265816906,
                "lng": -128.1233059463
            },
            {
                "lat": 52.2042042361,
                "lng": -128.0952916542
            },
            {
                "lat": 52.203697682,
                "lng": -128.0947420357
            },
            {
                "lat": 52.2026898166,
                "lng": -128.0922996124
            },
            {
                "lat": 52.2022097525,
                "lng": -128.0851587119
            },
            {
                "lat": 52.2030447427,
                "lng": -128.0797114015
            },
            {
                "lat": 52.2045895702,
                "lng": -128.0696872552
            },
            {
                "lat": 52.2093463047,
                "lng": -128.059570483
            },
            {
                "lat": 52.2164833673,
                "lng": -128.04589745
            },
            {
                "lat": 52.2123219876,
                "lng": -128.007828091
            },
            {
                "lat": 52.2141074467,
                "lng": -127.9881897982
            },
            {
                "lat": 52.2331428188,
                "lng": -127.983429165
            },
            {
                "lat": 52.2546454502,
                "lng": -127.9801176585
            },
            {
                "lat": 52.2800790912,
                "lng": -127.9767999561
            },
            {
                "lat": 52.2952084829,
                "lng": -127.9743044842
            },
            {
                "lat": 52.3033479894,
                "lng": -127.9661334223
            },
            {
                "lat": 52.3033525512,
                "lng": -127.9300909428
            },
            {
                "lat": 52.2971233744,
                "lng": -127.9237515751
            },
            {
                "lat": 52.3202700429,
                "lng": -127.912124562
            },
            {
                "lat": 52.3497396633,
                "lng": -127.880844349
            },
            {
                "lat": 52.3564334143,
                "lng": -127.8639065039
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 269,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 15,
            "MANAGEMENT_AREA_NAME": "Subarea 7-15",
            "LABEL": "7-15",
            "FISHERY_AREA_ID": 2450,
            "OBJECTID": 413,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 172502830.8074,
            "FEATURE_LENGTH_M": 71167.5616,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.2896737863811,
            "lng": -128.0384639757676
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.3649748284,
                "lng": -127.1464919349
            },
            {
                "lat": 52.3489493312,
                "lng": -127.1550215264
            },
            {
                "lat": 52.3438418074,
                "lng": -127.1639548739
            },
            {
                "lat": 52.3538965992,
                "lng": -127.1964032929
            },
            {
                "lat": 52.3495023226,
                "lng": -127.2242427303
            },
            {
                "lat": 52.3314741746,
                "lng": -127.2325749803
            },
            {
                "lat": 52.3169557664,
                "lng": -127.2504422732
            },
            {
                "lat": 52.3052328085,
                "lng": -127.2671886227
            },
            {
                "lat": 52.3002049832,
                "lng": -127.2906415605
            },
            {
                "lat": 52.2817797094,
                "lng": -127.3157654942
            },
            {
                "lat": 52.2795482911,
                "lng": -127.3392181349
            },
            {
                "lat": 52.2683829068,
                "lng": -127.3626632087
            },
            {
                "lat": 52.2588880292,
                "lng": -127.3838811888
            },
            {
                "lat": 52.2601160939,
                "lng": -127.3887790592
            },
            {
                "lat": 52.2622418828,
                "lng": -127.3972787028
            },
            {
                "lat": 52.2577736713,
                "lng": -127.4201739184
            },
            {
                "lat": 52.2432559394,
                "lng": -127.4486463406
            },
            {
                "lat": 52.2276233196,
                "lng": -127.4787981597
            },
            {
                "lat": 52.2159004513,
                "lng": -127.5067130025
            },
            {
                "lat": 52.206276115,
                "lng": -127.4992136627
            },
            {
                "lat": 52.1866302317,
                "lng": -127.4725807608
            },
            {
                "lat": 52.1825565068,
                "lng": -127.4652332688
            },
            {
                "lat": 52.1805263125,
                "lng": -127.4502865961
            },
            {
                "lat": 52.1762423703,
                "lng": -127.4232865094
            },
            {
                "lat": 52.1815950314,
                "lng": -127.382836044
            },
            {
                "lat": 52.2006302194,
                "lng": -127.3298944503
            },
            {
                "lat": 52.2244264883,
                "lng": -127.2710032947
            },
            {
                "lat": 52.2445758649,
                "lng": -127.2378395931
            },
            {
                "lat": 52.2696340767,
                "lng": -127.1877217693
            },
            {
                "lat": 52.2892642692,
                "lng": -127.1472708588
            },
            {
                "lat": 52.2886693325,
                "lng": -127.0996859001
            },
            {
                "lat": 52.2835728056,
                "lng": -127.0798494104
            },
            {
                "lat": 52.2941813942,
                "lng": -127.0368731008
            },
            {
                "lat": 52.2928122934,
                "lng": -127.0204012698
            },
            {
                "lat": 52.3013994823,
                "lng": -127.0076225062
            },
            {
                "lat": 52.3324626478,
                "lng": -126.9860613152
            },
            {
                "lat": 52.3445588845,
                "lng": -126.9937594302
            },
            {
                "lat": 52.3590317641,
                "lng": -127.0159832789
            },
            {
                "lat": 52.3579948244,
                "lng": -127.0906748643
            },
            {
                "lat": 52.3649748284,
                "lng": -127.1464919349
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 270,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 15,
            "MANAGEMENT_AREA_NAME": "Subarea 8-15",
            "LABEL": "8-15",
            "FISHERY_AREA_ID": 2481,
            "OBJECTID": 414,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 277216185.5003,
            "FEATURE_LENGTH_M": 90213.9268,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.2783139664925,
            "lng": -127.25783622059251
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1961177918,
                "lng": -131.2628330357
            },
            {
                "lat": 52.1966508544,
                "lng": -131.2602390643
            },
            {
                "lat": 52.1966477892,
                "lng": -131.2361442357
            },
            {
                "lat": 52.1968452474,
                "lng": -131.2344672635
            },
            {
                "lat": 52.1974867135,
                "lng": -131.2347873634
            },
            {
                "lat": 52.1994778612,
                "lng": -131.2360230657
            },
            {
                "lat": 52.2029001022,
                "lng": -131.2383121764
            },
            {
                "lat": 52.2081228949,
                "lng": -131.242294313
            },
            {
                "lat": 52.211162114,
                "lng": -131.2459104746
            },
            {
                "lat": 52.2142030156,
                "lng": -131.2516019909
            },
            {
                "lat": 52.2163881442,
                "lng": -131.2568365048
            },
            {
                "lat": 52.2175792448,
                "lng": -131.2600099275
            },
            {
                "lat": 52.2278478726,
                "lng": -131.2717125477
            },
            {
                "lat": 52.2288318883,
                "lng": -131.2719272627
            },
            {
                "lat": 52.2340541439,
                "lng": -131.2755427216
            },
            {
                "lat": 52.2361448187,
                "lng": -131.2791450654
            },
            {
                "lat": 52.2392695661,
                "lng": -131.2847294718
            },
            {
                "lat": 52.2452659921,
                "lng": -131.2915647367
            },
            {
                "lat": 52.2452658951,
                "lng": -131.2935186205
            },
            {
                "lat": 52.2452659075,
                "lng": -131.2963405323
            },
            {
                "lat": 52.2428891937,
                "lng": -131.2972867048
            },
            {
                "lat": 52.2372858841,
                "lng": -131.2921603106
            },
            {
                "lat": 52.2264558433,
                "lng": -131.2870327756
            },
            {
                "lat": 52.2172426939,
                "lng": -131.2847591403
            },
            {
                "lat": 52.2078396176,
                "lng": -131.2773435013
            },
            {
                "lat": 52.2012869678,
                "lng": -131.2709802625
            },
            {
                "lat": 52.1961177918,
                "lng": -131.2628330357
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 271,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 34,
            "MANAGEMENT_AREA_NAME": "Subarea 2-34",
            "LABEL": "2-34",
            "FISHERY_AREA_ID": 1925,
            "OBJECTID": 415,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 8348813.2095,
            "FEATURE_LENGTH_M": 15168.4413,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.217949846285194,
            "lng": -131.2665309668519
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2358630464,
                "lng": -131.3582760656
            },
            {
                "lat": 52.2368584429,
                "lng": -131.3596498615
            },
            {
                "lat": 52.2371906414,
                "lng": -131.359771978
            },
            {
                "lat": 52.2371904763,
                "lng": -131.3685916004
            },
            {
                "lat": 52.2363698745,
                "lng": -131.3726038102
            },
            {
                "lat": 52.2312309719,
                "lng": -131.3688209646
            },
            {
                "lat": 52.2234345605,
                "lng": -131.3671566794
            },
            {
                "lat": 52.2163996067,
                "lng": -131.3673849608
            },
            {
                "lat": 52.2045022741,
                "lng": -131.3669130806
            },
            {
                "lat": 52.1960562616,
                "lng": -131.3658446954
            },
            {
                "lat": 52.1929782993,
                "lng": -131.3639216649
            },
            {
                "lat": 52.1939770465,
                "lng": -131.3599693964
            },
            {
                "lat": 52.1939773148,
                "lng": -131.3376152732
            },
            {
                "lat": 52.1944658405,
                "lng": -131.3365330153
            },
            {
                "lat": 52.1960864964,
                "lng": -131.3359385056
            },
            {
                "lat": 52.1972239273,
                "lng": -131.3368834624
            },
            {
                "lat": 52.1977235369,
                "lng": -131.3376771862
            },
            {
                "lat": 52.1994174207,
                "lng": -131.3379671022
            },
            {
                "lat": 52.2022097857,
                "lng": -131.3374786266
            },
            {
                "lat": 52.2071997491,
                "lng": -131.3378750826
            },
            {
                "lat": 52.2111892043,
                "lng": -131.339569472
            },
            {
                "lat": 52.2154768187,
                "lng": -131.3395688898
            },
            {
                "lat": 52.2176700447,
                "lng": -131.3396606365
            },
            {
                "lat": 52.2200621454,
                "lng": -131.3403632667
            },
            {
                "lat": 52.2226561653,
                "lng": -131.3431542085
            },
            {
                "lat": 52.2258491656,
                "lng": -131.3477475443
            },
            {
                "lat": 52.2290423654,
                "lng": -131.3531339546
            },
            {
                "lat": 52.2307098433,
                "lng": -131.3560789408
            },
            {
                "lat": 52.2358630464,
                "lng": -131.3582760656
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 272,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 36,
            "MANAGEMENT_AREA_NAME": "Subarea 2-36",
            "LABEL": "2-36",
            "FISHERY_AREA_ID": 1927,
            "OBJECTID": 416,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 7926787.7107,
            "FEATURE_LENGTH_M": 13028.1663,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.21513359905516,
            "lng": -131.35153193071378
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2833329854,
                "lng": -128.8665316678
            },
            {
                "lat": 52.2833329069,
                "lng": -130.1083368701
            },
            {
                "lat": 52.1666683791,
                "lng": -129.9999997098
            },
            {
                "lat": 52.1666685892,
                "lng": -128.7558588493
            },
            {
                "lat": 52.2614709871,
                "lng": -128.8457949641
            },
            {
                "lat": 52.2833329854,
                "lng": -128.8665316678
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 273,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 107,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 107-1",
            "LABEL": "107-1",
            "FISHERY_AREA_ID": 2308,
            "OBJECTID": 417,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1102456430.3508,
            "FEATURE_LENGTH_M": 199525.8463,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.24080113885,
            "lng": -129.24050895481668
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2217526968,
                "lng": -131.4175874202
            },
            {
                "lat": 52.2160411859,
                "lng": -131.4159231505
            },
            {
                "lat": 52.2125699207,
                "lng": -131.4151004548
            },
            {
                "lat": 52.1481627303,
                "lng": -131.3209383051
            },
            {
                "lat": 52.1481629187,
                "lng": -131.3116913716
            },
            {
                "lat": 52.1482081846,
                "lng": -131.3114776482
            },
            {
                "lat": 52.1481965044,
                "lng": -131.3106083131
            },
            {
                "lat": 52.1481965305,
                "lng": -131.3098752864
            },
            {
                "lat": 52.1486355429,
                "lng": -131.309509844
            },
            {
                "lat": 52.1499639646,
                "lng": -131.3087158315
            },
            {
                "lat": 52.1512979927,
                "lng": -131.3078609783
            },
            {
                "lat": 52.1520115909,
                "lng": -131.3064272178
            },
            {
                "lat": 52.1520118857,
                "lng": -131.3033287855
            },
            {
                "lat": 52.151298023,
                "lng": -131.2971500704
            },
            {
                "lat": 52.1532024822,
                "lng": -131.2921448868
            },
            {
                "lat": 52.158912748,
                "lng": -131.2907260008
            },
            {
                "lat": 52.166526561,
                "lng": -131.2947690763
            },
            {
                "lat": 52.1736635997,
                "lng": -131.300475505
            },
            {
                "lat": 52.1824684937,
                "lng": -131.311188585
            },
            {
                "lat": 52.1883847156,
                "lng": -131.3149720376
            },
            {
                "lat": 52.1931728817,
                "lng": -131.3171382627
            },
            {
                "lat": 52.1984137105,
                "lng": -131.321472316
            },
            {
                "lat": 52.2011496191,
                "lng": -131.3302002417
            },
            {
                "lat": 52.2008666532,
                "lng": -131.3350365872
            },
            {
                "lat": 52.1994174207,
                "lng": -131.3379671022
            },
            {
                "lat": 52.1977235369,
                "lng": -131.3376771862
            },
            {
                "lat": 52.1972239273,
                "lng": -131.3368834624
            },
            {
                "lat": 52.1960864964,
                "lng": -131.3359385056
            },
            {
                "lat": 52.1944658405,
                "lng": -131.3365330153
            },
            {
                "lat": 52.1939773148,
                "lng": -131.3376152732
            },
            {
                "lat": 52.1939770465,
                "lng": -131.3599693964
            },
            {
                "lat": 52.1929782993,
                "lng": -131.3639216649
            },
            {
                "lat": 52.1960562616,
                "lng": -131.3658446954
            },
            {
                "lat": 52.2046093985,
                "lng": -131.3913428262
            },
            {
                "lat": 52.2134244783,
                "lng": -131.3979639695
            },
            {
                "lat": 52.220561931,
                "lng": -131.4098511554
            },
            {
                "lat": 52.2217526968,
                "lng": -131.4175874202
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 274,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 35,
            "MANAGEMENT_AREA_NAME": "Subarea 2-35",
            "LABEL": "2-35",
            "FISHERY_AREA_ID": 1926,
            "OBJECTID": 418,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 22143556.8255,
            "FEATURE_LENGTH_M": 27413.7213,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.18204123743243,
            "lng": -131.3373895634973
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2084425392,
                "lng": -130.966811907
            },
            {
                "lat": 52.2144962973,
                "lng": -131.0005636982
            },
            {
                "lat": 52.2148782715,
                "lng": -131.0026700579
            },
            {
                "lat": 52.2159658409,
                "lng": -131.0069727164
            },
            {
                "lat": 52.2157175688,
                "lng": -131.0102233238
            },
            {
                "lat": 52.2142064284,
                "lng": -131.0145105785
            },
            {
                "lat": 52.2074964759,
                "lng": -131.0157925291
            },
            {
                "lat": 52.188637025,
                "lng": -131.0377355336
            },
            {
                "lat": 52.180546238,
                "lng": -131.0560451502
            },
            {
                "lat": 52.1731040306,
                "lng": -131.0905767815
            },
            {
                "lat": 52.1772656034,
                "lng": -131.1020502959
            },
            {
                "lat": 52.1859898555,
                "lng": -131.1063381943
            },
            {
                "lat": 52.1996734086,
                "lng": -131.1126556634
            },
            {
                "lat": 52.2070540026,
                "lng": -131.1232451799
            },
            {
                "lat": 52.2102742383,
                "lng": -131.1444548324
            },
            {
                "lat": 52.2082602308,
                "lng": -131.160949615
            },
            {
                "lat": 52.2053066074,
                "lng": -131.167937853
            },
            {
                "lat": 52.1973916227,
                "lng": -131.1678002428
            },
            {
                "lat": 52.190147022,
                "lng": -131.1594845687
            },
            {
                "lat": 52.1764600668,
                "lng": -131.1491546858
            },
            {
                "lat": 52.1621059305,
                "lng": -131.1488795499
            },
            {
                "lat": 52.1511768277,
                "lng": -131.1474606501
            },
            {
                "lat": 52.1507148521,
                "lng": -131.1392671193
            },
            {
                "lat": 52.1501689049,
                "lng": -131.1171877857
            },
            {
                "lat": 52.1485065684,
                "lng": -131.1126710323
            },
            {
                "lat": 52.1483236651,
                "lng": -131.1083383379
            },
            {
                "lat": 52.1474726825,
                "lng": -131.0996398274
            },
            {
                "lat": 52.1424750408,
                "lng": -131.0853579121
            },
            {
                "lat": 52.1403319528,
                "lng": -131.0715631998
            },
            {
                "lat": 52.1405715553,
                "lng": -131.06228595
            },
            {
                "lat": 52.1362875456,
                "lng": -131.056808978
            },
            {
                "lat": 52.1317672235,
                "lng": -131.0579986084
            },
            {
                "lat": 52.1306157548,
                "lng": -131.0641023157
            },
            {
                "lat": 52.1203453887,
                "lng": -131.0696566537
            },
            {
                "lat": 52.1139214668,
                "lng": -131.0587157331
            },
            {
                "lat": 52.1124919806,
                "lng": -131.0399167287
            },
            {
                "lat": 52.1101113458,
                "lng": -131.0246892496
            },
            {
                "lat": 52.0989307332,
                "lng": -131.0275417417
            },
            {
                "lat": 52.0917934413,
                "lng": -131.0218357313
            },
            {
                "lat": 52.0894126459,
                "lng": -131.0137481157
            },
            {
                "lat": 52.0858426075,
                "lng": -130.9997095439
            },
            {
                "lat": 52.0882227774,
                "lng": -130.9932705364
            },
            {
                "lat": 52.0917932972,
                "lng": -130.9878085918
            },
            {
                "lat": 52.0908389014,
                "lng": -130.979003948
            },
            {
                "lat": 52.0894128314,
                "lng": -130.9637757819
            },
            {
                "lat": 52.0905152813,
                "lng": -130.9552311949
            },
            {
                "lat": 52.0854605831,
                "lng": -130.9415131897
            },
            {
                "lat": 52.0834118338,
                "lng": -130.941192797
            },
            {
                "lat": 52.083114823,
                "lng": -130.9377140195
            },
            {
                "lat": 52.0831752976,
                "lng": -130.9369196411
            },
            {
                "lat": 52.083506814,
                "lng": -130.9364619733
            },
            {
                "lat": 52.0835759022,
                "lng": -130.9362793648
            },
            {
                "lat": 52.0834579941,
                "lng": -130.9360800467
            },
            {
                "lat": 52.1666684868,
                "lng": -130.9565274769
            },
            {
                "lat": 52.2084425392,
                "lng": -130.966811907
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 275,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 18,
            "MANAGEMENT_AREA_NAME": "Subarea 2-18",
            "LABEL": "2-18",
            "FISHERY_AREA_ID": 1909,
            "OBJECTID": 419,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 120726842.6158,
            "FEATURE_LENGTH_M": 60143.5266,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.147386888181835,
            "lng": -131.04530797528548
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2971233744,
                "lng": -127.9237515751
            },
            {
                "lat": 52.3033525512,
                "lng": -127.9300909428
            },
            {
                "lat": 52.3033479894,
                "lng": -127.9661334223
            },
            {
                "lat": 52.2952084829,
                "lng": -127.9743044842
            },
            {
                "lat": 52.272933209,
                "lng": -127.9618380153
            },
            {
                "lat": 52.2542987351,
                "lng": -127.9352179298
            },
            {
                "lat": 52.2310411363,
                "lng": -127.920554847
            },
            {
                "lat": 52.2136301284,
                "lng": -127.9213251828
            },
            {
                "lat": 52.2010188237,
                "lng": -127.9225159175
            },
            {
                "lat": 52.1922146383,
                "lng": -127.917282549
            },
            {
                "lat": 52.1895973187,
                "lng": -127.9110949957
            },
            {
                "lat": 52.188884814,
                "lng": -127.9026486888
            },
            {
                "lat": 52.189250466,
                "lng": -127.8942113497
            },
            {
                "lat": 52.1851843412,
                "lng": -127.8842922563
            },
            {
                "lat": 52.1918339208,
                "lng": -127.8693008144
            },
            {
                "lat": 52.1933169502,
                "lng": -127.8674390904
            },
            {
                "lat": 52.1950410028,
                "lng": -127.8665541729
            },
            {
                "lat": 52.1962853038,
                "lng": -127.865409403
            },
            {
                "lat": 52.198132129,
                "lng": -127.8624726888
            },
            {
                "lat": 52.2015871024,
                "lng": -127.8589092997
            },
            {
                "lat": 52.2050132703,
                "lng": -127.8540575043
            },
            {
                "lat": 52.2062644782,
                "lng": -127.8531726718
            },
            {
                "lat": 52.2068861561,
                "lng": -127.8530801398
            },
            {
                "lat": 52.2071879787,
                "lng": -127.8531416322
            },
            {
                "lat": 52.2085952994,
                "lng": -127.853187191
            },
            {
                "lat": 52.2124220112,
                "lng": -127.8562921144
            },
            {
                "lat": 52.2125548511,
                "lng": -127.8669580898
            },
            {
                "lat": 52.2177852708,
                "lng": -127.8751594245
            },
            {
                "lat": 52.218066806,
                "lng": -127.8796843512
            },
            {
                "lat": 52.2237200288,
                "lng": -127.8885879246
            },
            {
                "lat": 52.2341847956,
                "lng": -127.8885879099
            },
            {
                "lat": 52.2490274196,
                "lng": -127.8917006687
            },
            {
                "lat": 52.2597424074,
                "lng": -127.8946989932
            },
            {
                "lat": 52.2862091876,
                "lng": -127.9161760974
            },
            {
                "lat": 52.2971233744,
                "lng": -127.9237515751
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 276,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 30,
            "MANAGEMENT_AREA_NAME": "Subarea 7-30",
            "LABEL": "7-30",
            "FISHERY_AREA_ID": 2464,
            "OBJECTID": 420,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 41876513.1202,
            "FEATURE_LENGTH_M": 34734.1375,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.226801878651415,
            "lng": -127.89438811181142
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1968452474,
                "lng": -131.2344672635
            },
            {
                "lat": 52.1966477892,
                "lng": -131.2361442357
            },
            {
                "lat": 52.1966508544,
                "lng": -131.2602390643
            },
            {
                "lat": 52.1961177918,
                "lng": -131.2628330357
            },
            {
                "lat": 52.1918336382,
                "lng": -131.2602235017
            },
            {
                "lat": 52.1796987171,
                "lng": -131.2499852907
            },
            {
                "lat": 52.1758651977,
                "lng": -131.2464596598
            },
            {
                "lat": 52.1761735323,
                "lng": -131.2450558276
            },
            {
                "lat": 52.1761666002,
                "lng": -131.220963119
            },
            {
                "lat": 52.1761550397,
                "lng": -131.2203365939
            },
            {
                "lat": 52.1763574389,
                "lng": -131.219863482
            },
            {
                "lat": 52.1769942626,
                "lng": -131.2186588262
            },
            {
                "lat": 52.17912271,
                "lng": -131.2148588763
            },
            {
                "lat": 52.1808048074,
                "lng": -131.2143547277
            },
            {
                "lat": 52.1839977934,
                "lng": -131.2165381397
            },
            {
                "lat": 52.1851774194,
                "lng": -131.2181099266
            },
            {
                "lat": 52.1873586351,
                "lng": -131.2212983715
            },
            {
                "lat": 52.1897163128,
                "lng": -131.2249455434
            },
            {
                "lat": 52.1930770089,
                "lng": -131.2284241881
            },
            {
                "lat": 52.1956555396,
                "lng": -131.2320558038
            },
            {
                "lat": 52.1968452474,
                "lng": -131.2344672635
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 277,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 33,
            "MANAGEMENT_AREA_NAME": "Subarea 2-33",
            "LABEL": "2-33",
            "FISHERY_AREA_ID": 1924,
            "OBJECTID": 421,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 5266757.826,
            "FEATURE_LENGTH_M": 9228.9107,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.186060075404754,
            "lng": -131.2323944162238
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.3056902495,
                "lng": -126.9349972265
            },
            {
                "lat": 52.3121190457,
                "lng": -126.965080405
            },
            {
                "lat": 52.3013994823,
                "lng": -127.0076225062
            },
            {
                "lat": 52.2928122934,
                "lng": -127.0204012698
            },
            {
                "lat": 52.2702326946,
                "lng": -127.0076826912
            },
            {
                "lat": 52.2090762755,
                "lng": -126.9695663035
            },
            {
                "lat": 52.156776642,
                "lng": -126.934997607
            },
            {
                "lat": 52.0973895447,
                "lng": -126.8534468293
            },
            {
                "lat": 52.0326846777,
                "lng": -126.7967220887
            },
            {
                "lat": 51.9644314261,
                "lng": -126.7417679261
            },
            {
                "lat": 51.9493639985,
                "lng": -126.7089690174
            },
            {
                "lat": 51.9360694871,
                "lng": -126.6557844253
            },
            {
                "lat": 51.9617728111,
                "lng": -126.6203303632
            },
            {
                "lat": 52.0300258305,
                "lng": -126.6451487055
            },
            {
                "lat": 52.0903011643,
                "lng": -126.682381146
            },
            {
                "lat": 52.1638675032,
                "lng": -126.7639245469
            },
            {
                "lat": 52.2303463758,
                "lng": -126.850792397
            },
            {
                "lat": 52.2888485464,
                "lng": -126.918152662
            },
            {
                "lat": 52.3056902495,
                "lng": -126.9349972265
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 278,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 8-12",
            "LABEL": "8-12",
            "FISHERY_AREA_ID": 2478,
            "OBJECTID": 422,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 392604104.0595,
            "FEATURE_LENGTH_M": 103256.5648,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.152573594626304,
            "lng": -126.84277712332106
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2777938668,
                "lng": -128.3247370011
            },
            {
                "lat": 52.2777065717,
                "lng": -128.3277436706
            },
            {
                "lat": 52.2754098481,
                "lng": -128.3305660242
            },
            {
                "lat": 52.2739517768,
                "lng": -128.3298640941
            },
            {
                "lat": 52.2725831646,
                "lng": -128.3342738153
            },
            {
                "lat": 52.2706830396,
                "lng": -128.3429404452
            },
            {
                "lat": 52.2684294269,
                "lng": -128.3489839393
            },
            {
                "lat": 52.2661777034,
                "lng": -128.3476252471
            },
            {
                "lat": 52.2648963152,
                "lng": -128.3478392981
            },
            {
                "lat": 52.2646295399,
                "lng": -128.3478853902
            },
            {
                "lat": 52.263843795,
                "lng": -128.3481896614
            },
            {
                "lat": 52.2611348714,
                "lng": -128.3160543999
            },
            {
                "lat": 52.2646833036,
                "lng": -128.3144831686
            },
            {
                "lat": 52.2657352017,
                "lng": -128.3121641034
            },
            {
                "lat": 52.2671663512,
                "lng": -128.3090207489
            },
            {
                "lat": 52.2714573987,
                "lng": -128.3073267851
            },
            {
                "lat": 52.2788510051,
                "lng": -128.3010862244
            },
            {
                "lat": 52.2805750933,
                "lng": -128.2979727407
            },
            {
                "lat": 52.2819020566,
                "lng": -128.2936397193
            },
            {
                "lat": 52.2855678233,
                "lng": -128.2918710311
            },
            {
                "lat": 52.2908169886,
                "lng": -128.2909693234
            },
            {
                "lat": 52.2792664688,
                "lng": -128.3154751494
            },
            {
                "lat": 52.2784575835,
                "lng": -128.3186800982
            },
            {
                "lat": 52.2777938668,
                "lng": -128.3247370011
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 279,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 7-8",
            "LABEL": "7-8",
            "FISHERY_AREA_ID": 2443,
            "OBJECTID": 423,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4826758.9281,
            "FEATURE_LENGTH_M": 11570.4281,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.27331304419167,
            "lng": -128.3218387116708
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2952084829,
                "lng": -127.9743044842
            },
            {
                "lat": 52.2800790912,
                "lng": -127.9767999561
            },
            {
                "lat": 52.2546454502,
                "lng": -127.9801176585
            },
            {
                "lat": 52.2331428188,
                "lng": -127.983429165
            },
            {
                "lat": 52.2141074467,
                "lng": -127.9881897982
            },
            {
                "lat": 52.2123219876,
                "lng": -128.007828091
            },
            {
                "lat": 52.2164833673,
                "lng": -128.04589745
            },
            {
                "lat": 52.2093463047,
                "lng": -128.059570483
            },
            {
                "lat": 52.2045895702,
                "lng": -128.0696872552
            },
            {
                "lat": 52.2030447427,
                "lng": -128.0797114015
            },
            {
                "lat": 52.1988794657,
                "lng": -128.0708768585
            },
            {
                "lat": 52.1922146084,
                "lng": -128.0516061314
            },
            {
                "lat": 52.1893611962,
                "lng": -128.0308987677
            },
            {
                "lat": 52.187931626,
                "lng": -128.0085299016
            },
            {
                "lat": 52.1907886546,
                "lng": -127.9949717301
            },
            {
                "lat": 52.1926917765,
                "lng": -127.9833141424
            },
            {
                "lat": 52.1872180966,
                "lng": -127.9573740995
            },
            {
                "lat": 52.1819838338,
                "lng": -127.9354861704
            },
            {
                "lat": 52.1812703262,
                "lng": -127.9209748115
            },
            {
                "lat": 52.1846010823,
                "lng": -127.9102636919
            },
            {
                "lat": 52.188884814,
                "lng": -127.9026486888
            },
            {
                "lat": 52.1895973187,
                "lng": -127.9110949957
            },
            {
                "lat": 52.1922146383,
                "lng": -127.917282549
            },
            {
                "lat": 52.2010188237,
                "lng": -127.9225159175
            },
            {
                "lat": 52.2136301284,
                "lng": -127.9213251828
            },
            {
                "lat": 52.2310411363,
                "lng": -127.920554847
            },
            {
                "lat": 52.2542987351,
                "lng": -127.9352179298
            },
            {
                "lat": 52.272933209,
                "lng": -127.9618380153
            },
            {
                "lat": 52.2952084829,
                "lng": -127.9743044842
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 280,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 424,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 52321787.3529,
            "FEATURE_LENGTH_M": 42595.2329,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.21547369706896,
            "lng": -127.97919360888964
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.279090402,
                "lng": -128.6656797581
            },
            {
                "lat": 52.2708016117,
                "lng": -128.6750181383
            },
            {
                "lat": 52.2614713131,
                "lng": -128.7219389891
            },
            {
                "lat": 52.1562501285,
                "lng": -128.5526890247
            },
            {
                "lat": 52.237011048,
                "lng": -128.4412383796
            },
            {
                "lat": 52.279090402,
                "lng": -128.6656797581
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 281,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 7-2",
            "LABEL": "7-2",
            "FISHERY_AREA_ID": 2437,
            "OBJECTID": 425,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 119395678.8561,
            "FEATURE_LENGTH_M": 48728.3403,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.24728581755,
            "lng": -128.62037400798332
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2857940744,
                "lng": -128.1838987495
            },
            {
                "lat": 52.2790263294,
                "lng": -128.1942597081
            },
            {
                "lat": 52.2790266577,
                "lng": -128.2043456878
            },
            {
                "lat": 52.279189309,
                "lng": -128.2077022282
            },
            {
                "lat": 52.2795526979,
                "lng": -128.2151949523
            },
            {
                "lat": 52.2622485536,
                "lng": -128.2441100379
            },
            {
                "lat": 52.2615700778,
                "lng": -128.2845458127
            },
            {
                "lat": 52.2680472812,
                "lng": -128.2885136497
            },
            {
                "lat": 52.2671660065,
                "lng": -128.3002165608
            },
            {
                "lat": 52.2671663512,
                "lng": -128.3090207489
            },
            {
                "lat": 52.2657352017,
                "lng": -128.3121641034
            },
            {
                "lat": 52.2646833036,
                "lng": -128.3144831686
            },
            {
                "lat": 52.2611348714,
                "lng": -128.3160543999
            },
            {
                "lat": 52.263843795,
                "lng": -128.3481896614
            },
            {
                "lat": 52.269496998,
                "lng": -128.407287508
            },
            {
                "lat": 52.237011048,
                "lng": -128.4412383796
            },
            {
                "lat": 52.23736131,
                "lng": -128.4359286746
            },
            {
                "lat": 52.2360727298,
                "lng": -128.4358066007
            },
            {
                "lat": 52.2354663254,
                "lng": -128.4338379915
            },
            {
                "lat": 52.2355230821,
                "lng": -128.4319920067
            },
            {
                "lat": 52.2352601253,
                "lng": -128.4247438494
            },
            {
                "lat": 52.2338413181,
                "lng": -128.4191592531
            },
            {
                "lat": 52.2320749627,
                "lng": -128.4122615217
            },
            {
                "lat": 52.2336653506,
                "lng": -128.4058077931
            },
            {
                "lat": 52.2345499905,
                "lng": -128.3971250796
            },
            {
                "lat": 52.2337002246,
                "lng": -128.3856194997
            },
            {
                "lat": 52.2333787158,
                "lng": -128.380859938
            },
            {
                "lat": 52.237480578,
                "lng": -128.3779293247
            },
            {
                "lat": 52.2362825705,
                "lng": -128.3722995034
            },
            {
                "lat": 52.2277610123,
                "lng": -128.360412292
            },
            {
                "lat": 52.2319146343,
                "lng": -128.3557128871
            },
            {
                "lat": 52.2245910318,
                "lng": -128.3293610189
            },
            {
                "lat": 52.2257802806,
                "lng": -128.3010404188
            },
            {
                "lat": 52.2278248911,
                "lng": -128.2932278277
            },
            {
                "lat": 52.2272067246,
                "lng": -128.2784422037
            },
            {
                "lat": 52.2295604976,
                "lng": -128.270828924
            },
            {
                "lat": 52.2297967376,
                "lng": -128.2707367746
            },
            {
                "lat": 52.2297595916,
                "lng": -128.2701717518
            },
            {
                "lat": 52.2293589602,
                "lng": -128.263855191
            },
            {
                "lat": 52.2225649077,
                "lng": -128.253341525
            },
            {
                "lat": 52.2216457308,
                "lng": -128.2499388098
            },
            {
                "lat": 52.219547563,
                "lng": -128.2421714681
            },
            {
                "lat": 52.2167934856,
                "lng": -128.2266848602
            },
            {
                "lat": 52.2127492893,
                "lng": -128.2207338878
            },
            {
                "lat": 52.2132231003,
                "lng": -128.2159721599
            },
            {
                "lat": 52.2164150534,
                "lng": -128.2056882389
            },
            {
                "lat": 52.2081375864,
                "lng": -128.1907646185
            },
            {
                "lat": 52.2085990626,
                "lng": -128.1888732425
            },
            {
                "lat": 52.2092784749,
                "lng": -128.1868443325
            },
            {
                "lat": 52.208697924,
                "lng": -128.1843265114
            },
            {
                "lat": 52.2075382908,
                "lng": -128.1811379827
            },
            {
                "lat": 52.2061842353,
                "lng": -128.1780544235
            },
            {
                "lat": 52.2052190928,
                "lng": -128.1740878488
            },
            {
                "lat": 52.2042539525,
                "lng": -128.1733098791
            },
            {
                "lat": 52.2035755926,
                "lng": -128.1727296238
            },
            {
                "lat": 52.2030945618,
                "lng": -128.1716763054
            },
            {
                "lat": 52.2026104081,
                "lng": -128.1706091151
            },
            {
                "lat": 52.2021261288,
                "lng": -128.1699369326
            },
            {
                "lat": 52.20205718,
                "lng": -128.1698455006
            },
            {
                "lat": 52.2008699493,
                "lng": -128.168288613
            },
            {
                "lat": 52.1991305915,
                "lng": -128.165007987
            },
            {
                "lat": 52.1935279327,
                "lng": -128.1596986905
            },
            {
                "lat": 52.1871490636,
                "lng": -128.1557309202
            },
            {
                "lat": 52.1838639834,
                "lng": -128.1500245103
            },
            {
                "lat": 52.1855087756,
                "lng": -128.1465609295
            },
            {
                "lat": 52.1893727611,
                "lng": -128.1442412151
            },
            {
                "lat": 52.1910163514,
                "lng": -128.1398927972
            },
            {
                "lat": 52.1900482132,
                "lng": -128.133407097
            },
            {
                "lat": 52.1879236693,
                "lng": -128.1312860957
            },
            {
                "lat": 52.1847348364,
                "lng": -128.1306153392
            },
            {
                "lat": 52.1833842152,
                "lng": -128.1280051285
            },
            {
                "lat": 52.1839641487,
                "lng": -128.1227875076
            },
            {
                "lat": 52.1851237476,
                "lng": -128.1174771556
            },
            {
                "lat": 52.1855091307,
                "lng": -128.1148678204
            },
            {
                "lat": 52.1851505129,
                "lng": -128.1122129955
            },
            {
                "lat": 52.2042042361,
                "lng": -128.0952916542
            },
            {
                "lat": 52.2265816906,
                "lng": -128.1233059463
            },
            {
                "lat": 52.2276687361,
                "lng": -128.1210320419
            },
            {
                "lat": 52.2289925984,
                "lng": -128.1177361733
            },
            {
                "lat": 52.2317430632,
                "lng": -128.1148683752
            },
            {
                "lat": 52.2346072208,
                "lng": -128.1163023788
            },
            {
                "lat": 52.2375790388,
                "lng": -128.1226954366
            },
            {
                "lat": 52.2392311478,
                "lng": -128.1313935827
            },
            {
                "lat": 52.2414325647,
                "lng": -128.1417394788
            },
            {
                "lat": 52.2433054699,
                "lng": -128.149886951
            },
            {
                "lat": 52.2436374789,
                "lng": -128.1549532187
            },
            {
                "lat": 52.2447355086,
                "lng": -128.159468878
            },
            {
                "lat": 52.2457269834,
                "lng": -128.1659545161
            },
            {
                "lat": 52.2446142619,
                "lng": -128.1694030168
            },
            {
                "lat": 52.2448541291,
                "lng": -128.1713258661
            },
            {
                "lat": 52.2451707432,
                "lng": -128.1741176431
            },
            {
                "lat": 52.2485164893,
                "lng": -128.1762389609
            },
            {
                "lat": 52.2746234949,
                "lng": -128.1815802377
            },
            {
                "lat": 52.2796366922,
                "lng": -128.1823427614
            },
            {
                "lat": 52.2857940744,
                "lng": -128.1838987495
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 282,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 7-12",
            "LABEL": "7-12",
            "FISHERY_AREA_ID": 2447,
            "OBJECTID": 426,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 107132618.6925,
            "FEATURE_LENGTH_M": 64700.4215,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.22854894028525,
            "lng": -128.23135530124418
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.221283586,
                "lng": -127.7559581824
            },
            {
                "lat": 52.2459752103,
                "lng": -127.7659379296
            },
            {
                "lat": 52.2677426257,
                "lng": -127.7850042132
            },
            {
                "lat": 52.2888635392,
                "lng": -127.8081816278
            },
            {
                "lat": 52.2723883354,
                "lng": -127.8214109844
            },
            {
                "lat": 52.2350311942,
                "lng": -127.8473663814
            },
            {
                "lat": 52.2197491562,
                "lng": -127.8558500114
            },
            {
                "lat": 52.2124220112,
                "lng": -127.8562921144
            },
            {
                "lat": 52.2085952994,
                "lng": -127.853187191
            },
            {
                "lat": 52.2071879787,
                "lng": -127.8531416322
            },
            {
                "lat": 52.2068861561,
                "lng": -127.8530801398
            },
            {
                "lat": 52.2062644782,
                "lng": -127.8531726718
            },
            {
                "lat": 52.2050132703,
                "lng": -127.8540575043
            },
            {
                "lat": 52.2015871024,
                "lng": -127.8589092997
            },
            {
                "lat": 52.198132129,
                "lng": -127.8624726888
            },
            {
                "lat": 52.1962853038,
                "lng": -127.865409403
            },
            {
                "lat": 52.1950410028,
                "lng": -127.8665541729
            },
            {
                "lat": 52.1933169502,
                "lng": -127.8674390904
            },
            {
                "lat": 52.1918339208,
                "lng": -127.8693008144
            },
            {
                "lat": 52.1851843412,
                "lng": -127.8842922563
            },
            {
                "lat": 52.1370009033,
                "lng": -127.9099196582
            },
            {
                "lat": 52.1297564677,
                "lng": -127.9191522816
            },
            {
                "lat": 52.1142919757,
                "lng": -127.927712107
            },
            {
                "lat": 52.0972861633,
                "lng": -127.9363936713
            },
            {
                "lat": 52.0901370453,
                "lng": -127.9406813734
            },
            {
                "lat": 52.0802313619,
                "lng": -127.9431309211
            },
            {
                "lat": 52.0758210623,
                "lng": -127.9424594434
            },
            {
                "lat": 52.0638202574,
                "lng": -127.9509276153
            },
            {
                "lat": 52.0661319632,
                "lng": -127.8893510425
            },
            {
                "lat": 52.0652196376,
                "lng": -127.8856429942
            },
            {
                "lat": 52.0667911686,
                "lng": -127.8825149413
            },
            {
                "lat": 52.0689924496,
                "lng": -127.8764577771
            },
            {
                "lat": 52.0789076233,
                "lng": -127.8728793869
            },
            {
                "lat": 52.0885465572,
                "lng": -127.8748012086
            },
            {
                "lat": 52.0935058154,
                "lng": -127.8786623073
            },
            {
                "lat": 52.0954547565,
                "lng": -127.8824394901
            },
            {
                "lat": 52.1032135913,
                "lng": -127.8652417833
            },
            {
                "lat": 52.112263049,
                "lng": -127.8596265605
            },
            {
                "lat": 52.1220356756,
                "lng": -127.8571779827
            },
            {
                "lat": 52.1290326605,
                "lng": -127.8544239645
            },
            {
                "lat": 52.1293790992,
                "lng": -127.8517767614
            },
            {
                "lat": 52.1934743446,
                "lng": -127.8045885357
            },
            {
                "lat": 52.1959955998,
                "lng": -127.7982098282
            },
            {
                "lat": 52.206772286,
                "lng": -127.7789007763
            },
            {
                "lat": 52.2182460824,
                "lng": -127.7653193214
            },
            {
                "lat": 52.221283586,
                "lng": -127.7559581824
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 283,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 8-5",
            "LABEL": "8-5",
            "FISHERY_AREA_ID": 2471,
            "OBJECTID": 427,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 111291567.7199,
            "FEATURE_LENGTH_M": 61474.0364,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.16092119073478,
            "lng": -127.85959496141085
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2614713131,
                "lng": -128.7219389891
            },
            {
                "lat": 52.2614709871,
                "lng": -128.8457949641
            },
            {
                "lat": 52.1666685892,
                "lng": -128.7558588493
            },
            {
                "lat": 52.0042036523,
                "lng": -128.6017459932
            },
            {
                "lat": 52.0042035922,
                "lng": -128.4213102841
            },
            {
                "lat": 52.0782475734,
                "lng": -128.4465478939
            },
            {
                "lat": 52.1157876583,
                "lng": -128.4728242686
            },
            {
                "lat": 52.1476516427,
                "lng": -128.4846194083
            },
            {
                "lat": 52.1493607408,
                "lng": -128.541640948
            },
            {
                "lat": 52.1562501285,
                "lng": -128.5526890247
            },
            {
                "lat": 52.2614713131,
                "lng": -128.7219389891
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 284,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 7-1",
            "LABEL": "7-1",
            "FISHERY_AREA_ID": 2436,
            "OBJECTID": 428,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 379832631.2698,
            "FEATURE_LENGTH_M": 92054.1957,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.146071562790915,
            "lng": -128.59699178294548
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1763574389,
                "lng": -131.219863482
            },
            {
                "lat": 52.1761550397,
                "lng": -131.2203365939
            },
            {
                "lat": 52.1761666002,
                "lng": -131.220963119
            },
            {
                "lat": 52.1761735323,
                "lng": -131.2450558276
            },
            {
                "lat": 52.1758651977,
                "lng": -131.2464596598
            },
            {
                "lat": 52.1744811671,
                "lng": -131.2465975339
            },
            {
                "lat": 52.1692235927,
                "lng": -131.2443236528
            },
            {
                "lat": 52.1616856236,
                "lng": -131.2380980288
            },
            {
                "lat": 52.1598173551,
                "lng": -131.2364340369
            },
            {
                "lat": 52.1595042047,
                "lng": -131.2349243083
            },
            {
                "lat": 52.15809667,
                "lng": -131.2354441154
            },
            {
                "lat": 52.1559520987,
                "lng": -131.233352452
            },
            {
                "lat": 52.155491262,
                "lng": -131.2324828932
            },
            {
                "lat": 52.1551852511,
                "lng": -131.218430036
            },
            {
                "lat": 52.1552538148,
                "lng": -131.2180327639
            },
            {
                "lat": 52.1561966307,
                "lng": -131.2170105105
            },
            {
                "lat": 52.1588206893,
                "lng": -131.2110138344
            },
            {
                "lat": 52.1621361413,
                "lng": -131.2074129821
            },
            {
                "lat": 52.1641315438,
                "lng": -131.2059323889
            },
            {
                "lat": 52.1664738304,
                "lng": -131.2066794195
            },
            {
                "lat": 52.169044243,
                "lng": -131.208785681
            },
            {
                "lat": 52.1721840204,
                "lng": -131.2116390444
            },
            {
                "lat": 52.1751561589,
                "lng": -131.2149664857
            },
            {
                "lat": 52.1758955928,
                "lng": -131.2173605379
            },
            {
                "lat": 52.1757357029,
                "lng": -131.2191312371
            },
            {
                "lat": 52.1763574389,
                "lng": -131.219863482
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 285,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 32,
            "MANAGEMENT_AREA_NAME": "Subarea 2-32",
            "LABEL": "2-32",
            "FISHERY_AREA_ID": 1923,
            "OBJECTID": 429,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4819417.4934,
            "FEATURE_LENGTH_M": 8482.1962,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.16682849388462,
            "lng": -131.2242536195
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1561966307,
                "lng": -131.2170105105
            },
            {
                "lat": 52.1552538148,
                "lng": -131.2180327639
            },
            {
                "lat": 52.1551852511,
                "lng": -131.218430036
            },
            {
                "lat": 52.155491262,
                "lng": -131.2324828932
            },
            {
                "lat": 52.1559520987,
                "lng": -131.233352452
            },
            {
                "lat": 52.15809667,
                "lng": -131.2354441154
            },
            {
                "lat": 52.1580465648,
                "lng": -131.2391349974
            },
            {
                "lat": 52.1543269849,
                "lng": -131.2450716238
            },
            {
                "lat": 52.1509707313,
                "lng": -131.281341262
            },
            {
                "lat": 52.1532024822,
                "lng": -131.2921448868
            },
            {
                "lat": 52.151298023,
                "lng": -131.2971500704
            },
            {
                "lat": 52.1520118857,
                "lng": -131.3033287855
            },
            {
                "lat": 52.1520115909,
                "lng": -131.3064272178
            },
            {
                "lat": 52.1512979927,
                "lng": -131.3078609783
            },
            {
                "lat": 52.1499639646,
                "lng": -131.3087158315
            },
            {
                "lat": 52.1486355429,
                "lng": -131.309509844
            },
            {
                "lat": 52.1481965305,
                "lng": -131.3098752864
            },
            {
                "lat": 52.1481965044,
                "lng": -131.3106083131
            },
            {
                "lat": 52.1482081846,
                "lng": -131.3114776482
            },
            {
                "lat": 52.1481629187,
                "lng": -131.3116913716
            },
            {
                "lat": 52.1481627303,
                "lng": -131.3209383051
            },
            {
                "lat": 52.0031130396,
                "lng": -131.1087950753
            },
            {
                "lat": 52.0030980293,
                "lng": -131.1085510421
            },
            {
                "lat": 52.0011713514,
                "lng": -131.1042940612
            },
            {
                "lat": 52.0004694414,
                "lng": -131.1033788327
            },
            {
                "lat": 52.0020864198,
                "lng": -131.0991670375
            },
            {
                "lat": 52.0030358897,
                "lng": -131.0891722078
            },
            {
                "lat": 52.0075563256,
                "lng": -131.0758506064
            },
            {
                "lat": 52.0187415661,
                "lng": -131.0727543688
            },
            {
                "lat": 52.0270687704,
                "lng": -131.0708467431
            },
            {
                "lat": 52.0389667343,
                "lng": -131.0725099134
            },
            {
                "lat": 52.0453917403,
                "lng": -131.0765530278
            },
            {
                "lat": 52.0463452097,
                "lng": -131.0827488606
            },
            {
                "lat": 52.0480083446,
                "lng": -131.0908364948
            },
            {
                "lat": 52.0572890849,
                "lng": -131.1001136484
            },
            {
                "lat": 52.0758480756,
                "lng": -131.1029668971
            },
            {
                "lat": 52.0901266941,
                "lng": -131.1067815242
            },
            {
                "lat": 52.0980868865,
                "lng": -131.1142424618
            },
            {
                "lat": 52.1387331688,
                "lng": -131.1165309008
            },
            {
                "lat": 52.1485065684,
                "lng": -131.1126710323
            },
            {
                "lat": 52.1501689049,
                "lng": -131.1171877857
            },
            {
                "lat": 52.1507148521,
                "lng": -131.1392671193
            },
            {
                "lat": 52.1511768277,
                "lng": -131.1474606501
            },
            {
                "lat": 52.1496121617,
                "lng": -131.1511081281
            },
            {
                "lat": 52.1419985591,
                "lng": -131.160629783
            },
            {
                "lat": 52.1360512569,
                "lng": -131.1682433933
            },
            {
                "lat": 52.1348615741,
                "lng": -131.1796725321
            },
            {
                "lat": 52.1419988775,
                "lng": -131.1927496009
            },
            {
                "lat": 52.1510428947,
                "lng": -131.2017972542
            },
            {
                "lat": 52.1544076723,
                "lng": -131.2046502754
            },
            {
                "lat": 52.1557996461,
                "lng": -131.2058415538
            },
            {
                "lat": 52.1565136507,
                "lng": -131.2098846724
            },
            {
                "lat": 52.1561966307,
                "lng": -131.2170105105
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 286,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 31,
            "MANAGEMENT_AREA_NAME": "Subarea 2-31",
            "LABEL": "2-31",
            "FISHERY_AREA_ID": 1922,
            "OBJECTID": 430,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 131184823.6993,
            "FEATURE_LENGTH_M": 58274.222,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.112887834109436,
            "lng": -131.18706221109056
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1666683791,
                "lng": -129.9999997098
            },
            {
                "lat": 52.1666684868,
                "lng": -130.9565274769
            },
            {
                "lat": 52.0834579941,
                "lng": -130.9360800467
            },
            {
                "lat": 51.9358899611,
                "lng": -131.0160824804
            },
            {
                "lat": 51.6555479281,
                "lng": -130.5083307374
            },
            {
                "lat": 51.7999996952,
                "lng": -129.9999997991
            },
            {
                "lat": 52.1666683791,
                "lng": -129.9999997098
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 287,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 102,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 102-3",
            "LABEL": "102-3",
            "FISHERY_AREA_ID": 2298,
            "OBJECTID": 431,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 3059668882.834,
            "FEATURE_LENGTH_M": 218391.571,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.99641440335714,
            "lng": -130.4881457085857
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2355230821,
                "lng": -128.4319920067
            },
            {
                "lat": 52.2354663254,
                "lng": -128.4338379915
            },
            {
                "lat": 52.2360727298,
                "lng": -128.4358066007
            },
            {
                "lat": 52.23736131,
                "lng": -128.4359286746
            },
            {
                "lat": 52.237011048,
                "lng": -128.4412383796
            },
            {
                "lat": 52.1562501285,
                "lng": -128.5526890247
            },
            {
                "lat": 52.1493607408,
                "lng": -128.541640948
            },
            {
                "lat": 52.1476516427,
                "lng": -128.4846194083
            },
            {
                "lat": 52.1484261352,
                "lng": -128.4847571622
            },
            {
                "lat": 52.1517254988,
                "lng": -128.485854425
            },
            {
                "lat": 52.1577801354,
                "lng": -128.4840846802
            },
            {
                "lat": 52.1609116352,
                "lng": -128.4774321507
            },
            {
                "lat": 52.1702607746,
                "lng": -128.457566167
            },
            {
                "lat": 52.1765021796,
                "lng": -128.4476773198
            },
            {
                "lat": 52.2019840078,
                "lng": -128.4409181554
            },
            {
                "lat": 52.2009435002,
                "lng": -128.4221955392
            },
            {
                "lat": 52.1995579515,
                "lng": -128.4085082985
            },
            {
                "lat": 52.2051053882,
                "lng": -128.4012146164
            },
            {
                "lat": 52.216231915,
                "lng": -128.3996882206
            },
            {
                "lat": 52.2201271918,
                "lng": -128.4018250803
            },
            {
                "lat": 52.2229574793,
                "lng": -128.4081881986
            },
            {
                "lat": 52.2283559496,
                "lng": -128.4145661477
            },
            {
                "lat": 52.2285351715,
                "lng": -128.4210205718
            },
            {
                "lat": 52.2272066379,
                "lng": -128.4255371077
            },
            {
                "lat": 52.2313650509,
                "lng": -128.4310145062
            },
            {
                "lat": 52.234283611,
                "lng": -128.4285432956
            },
            {
                "lat": 52.2355230821,
                "lng": -128.4319920067
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 288,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 32,
            "MANAGEMENT_AREA_NAME": "Subarea 7-32",
            "LABEL": "7-32",
            "FISHERY_AREA_ID": 2466,
            "OBJECTID": 432,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 41978265.3903,
            "FEATURE_LENGTH_M": 32849.7546,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.20194371492221,
            "lng": -128.44556802532222
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2277610123,
                "lng": -128.360412292
            },
            {
                "lat": 52.2362825705,
                "lng": -128.3722995034
            },
            {
                "lat": 52.237480578,
                "lng": -128.3779293247
            },
            {
                "lat": 52.2333787158,
                "lng": -128.380859938
            },
            {
                "lat": 52.2306290877,
                "lng": -128.380462878
            },
            {
                "lat": 52.229305515,
                "lng": -128.3837283072
            },
            {
                "lat": 52.2238308786,
                "lng": -128.3942114551
            },
            {
                "lat": 52.216231915,
                "lng": -128.3996882206
            },
            {
                "lat": 52.2051053882,
                "lng": -128.4012146164
            },
            {
                "lat": 52.1995579515,
                "lng": -128.4085082985
            },
            {
                "lat": 52.2009435002,
                "lng": -128.4221955392
            },
            {
                "lat": 52.1909977681,
                "lng": -128.4279939573
            },
            {
                "lat": 52.1793366737,
                "lng": -128.4287109355
            },
            {
                "lat": 52.1788596235,
                "lng": -128.4144296041
            },
            {
                "lat": 52.1771855284,
                "lng": -128.4002838865
            },
            {
                "lat": 52.1646311218,
                "lng": -128.4049374901
            },
            {
                "lat": 52.1615330317,
                "lng": -128.4084475248
            },
            {
                "lat": 52.1570665437,
                "lng": -128.4077461829
            },
            {
                "lat": 52.1562689259,
                "lng": -128.4076387036
            },
            {
                "lat": 52.154888032,
                "lng": -128.4076087171
            },
            {
                "lat": 52.1535880615,
                "lng": -128.4030459661
            },
            {
                "lat": 52.150074402,
                "lng": -128.4033208575
            },
            {
                "lat": 52.1484868564,
                "lng": -128.4054572828
            },
            {
                "lat": 52.1461759299,
                "lng": -128.4047551604
            },
            {
                "lat": 52.1441767156,
                "lng": -128.4040063368
            },
            {
                "lat": 52.143489995,
                "lng": -128.4045256741
            },
            {
                "lat": 52.1424063195,
                "lng": -128.4064636304
            },
            {
                "lat": 52.1417727894,
                "lng": -128.407363869
            },
            {
                "lat": 52.1414188449,
                "lng": -128.4073643572
            },
            {
                "lat": 52.1414144516,
                "lng": -128.3554682749
            },
            {
                "lat": 52.1400722095,
                "lng": -128.3525243939
            },
            {
                "lat": 52.1467940776,
                "lng": -128.350677602
            },
            {
                "lat": 52.1494596836,
                "lng": -128.3492888308
            },
            {
                "lat": 52.1521688138,
                "lng": -128.345535801
            },
            {
                "lat": 52.1545601989,
                "lng": -128.3436428279
            },
            {
                "lat": 52.1571469153,
                "lng": -128.3433999625
            },
            {
                "lat": 52.1593551797,
                "lng": -128.3429562467
            },
            {
                "lat": 52.161075836,
                "lng": -128.3423002551
            },
            {
                "lat": 52.1614111393,
                "lng": -128.3438264306
            },
            {
                "lat": 52.1613308078,
                "lng": -128.3447725241
            },
            {
                "lat": 52.1701656425,
                "lng": -128.3447870972
            },
            {
                "lat": 52.179871277,
                "lng": -128.3488161595
            },
            {
                "lat": 52.2139048275,
                "lng": -128.3629911127
            },
            {
                "lat": 52.2277610123,
                "lng": -128.360412292
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 289,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 21,
            "MANAGEMENT_AREA_NAME": "Subarea 7-21",
            "LABEL": "7-21",
            "FISHERY_AREA_ID": 2547,
            "OBJECTID": 433,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 37636867.5261,
            "FEATURE_LENGTH_M": 30514.3401,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.17612173518636,
            "lng": -128.38334114364093
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.0834118338,
                "lng": -130.941192797
            },
            {
                "lat": 52.0854605831,
                "lng": -130.9415131897
            },
            {
                "lat": 52.0905152813,
                "lng": -130.9552311949
            },
            {
                "lat": 52.0894128314,
                "lng": -130.9637757819
            },
            {
                "lat": 52.0908389014,
                "lng": -130.979003948
            },
            {
                "lat": 52.0917932972,
                "lng": -130.9878085918
            },
            {
                "lat": 52.0882227774,
                "lng": -130.9932705364
            },
            {
                "lat": 52.0858426075,
                "lng": -130.9997095439
            },
            {
                "lat": 52.0894126459,
                "lng": -131.0137481157
            },
            {
                "lat": 52.0917934413,
                "lng": -131.0218357313
            },
            {
                "lat": 52.0989307332,
                "lng": -131.0275417417
            },
            {
                "lat": 52.1101113458,
                "lng": -131.0246892496
            },
            {
                "lat": 52.1124919806,
                "lng": -131.0399167287
            },
            {
                "lat": 52.1139214668,
                "lng": -131.0587157331
            },
            {
                "lat": 52.1203453887,
                "lng": -131.0696566537
            },
            {
                "lat": 52.1306157548,
                "lng": -131.0641023157
            },
            {
                "lat": 52.1317672235,
                "lng": -131.0579986084
            },
            {
                "lat": 52.1362875456,
                "lng": -131.056808978
            },
            {
                "lat": 52.1405715553,
                "lng": -131.06228595
            },
            {
                "lat": 52.1403319528,
                "lng": -131.0715631998
            },
            {
                "lat": 52.1424750408,
                "lng": -131.0853579121
            },
            {
                "lat": 52.1474726825,
                "lng": -131.0996398274
            },
            {
                "lat": 52.1483236651,
                "lng": -131.1083383379
            },
            {
                "lat": 52.1485065684,
                "lng": -131.1126710323
            },
            {
                "lat": 52.1387331688,
                "lng": -131.1165309008
            },
            {
                "lat": 52.0980868865,
                "lng": -131.1142424618
            },
            {
                "lat": 52.0901266941,
                "lng": -131.1067815242
            },
            {
                "lat": 52.0758480756,
                "lng": -131.1029668971
            },
            {
                "lat": 52.0572890849,
                "lng": -131.1001136484
            },
            {
                "lat": 52.0480083446,
                "lng": -131.0908364948
            },
            {
                "lat": 52.0463452097,
                "lng": -131.0827488606
            },
            {
                "lat": 52.0453917403,
                "lng": -131.0765530278
            },
            {
                "lat": 52.0389667343,
                "lng": -131.0725099134
            },
            {
                "lat": 52.0270687704,
                "lng": -131.0708467431
            },
            {
                "lat": 52.0187415661,
                "lng": -131.0727543688
            },
            {
                "lat": 52.0075563256,
                "lng": -131.0758506064
            },
            {
                "lat": 52.0030358897,
                "lng": -131.0891722078
            },
            {
                "lat": 52.0020864198,
                "lng": -131.0991670375
            },
            {
                "lat": 52.0004694414,
                "lng": -131.1033788327
            },
            {
                "lat": 51.9988671548,
                "lng": -131.1012573601
            },
            {
                "lat": 51.9983599234,
                "lng": -131.0977782205
            },
            {
                "lat": 51.9981921544,
                "lng": -131.0938417238
            },
            {
                "lat": 51.9970662859,
                "lng": -131.0905297721
            },
            {
                "lat": 51.9955480695,
                "lng": -131.0883328919
            },
            {
                "lat": 51.9930186453,
                "lng": -131.0870359771
            },
            {
                "lat": 51.9897041429,
                "lng": -131.0858612257
            },
            {
                "lat": 51.9867819737,
                "lng": -131.0850679138
            },
            {
                "lat": 51.9855840359,
                "lng": -131.0847011633
            },
            {
                "lat": 51.9656563289,
                "lng": -131.0572052787
            },
            {
                "lat": 51.963230476,
                "lng": -131.0506743909
            },
            {
                "lat": 51.9605901503,
                "lng": -131.0486445322
            },
            {
                "lat": 51.9590950254,
                "lng": -131.0481568628
            },
            {
                "lat": 51.9537166664,
                "lng": -131.0407265639
            },
            {
                "lat": 51.9536784291,
                "lng": -131.0393826192
            },
            {
                "lat": 51.9540136815,
                "lng": -131.0356750205
            },
            {
                "lat": 51.9528892806,
                "lng": -131.0325773985
            },
            {
                "lat": 51.9510917346,
                "lng": -131.0299983343
            },
            {
                "lat": 51.9491798434,
                "lng": -131.0284114844
            },
            {
                "lat": 51.9474941615,
                "lng": -131.0275118861
            },
            {
                "lat": 51.9450231129,
                "lng": -131.0287317769
            },
            {
                "lat": 51.9438667593,
                "lng": -131.0271302458
            },
            {
                "lat": 51.9433056392,
                "lng": -131.0251310618
            },
            {
                "lat": 51.9475981678,
                "lng": -131.0233772752
            },
            {
                "lat": 51.950451569,
                "lng": -131.0248114518
            },
            {
                "lat": 51.9525907927,
                "lng": -131.0276643807
            },
            {
                "lat": 51.954258295,
                "lng": -131.0300439504
            },
            {
                "lat": 51.9561613078,
                "lng": -131.0336151955
            },
            {
                "lat": 51.9571149763,
                "lng": -131.0374137907
            },
            {
                "lat": 51.9594955289,
                "lng": -131.0412290805
            },
            {
                "lat": 51.9623488189,
                "lng": -131.0440822408
            },
            {
                "lat": 51.9697264355,
                "lng": -131.0462185574
            },
            {
                "lat": 51.9744828534,
                "lng": -131.0436096947
            },
            {
                "lat": 51.9778134776,
                "lng": -131.0357517173
            },
            {
                "lat": 51.9823342316,
                "lng": -131.0338440201
            },
            {
                "lat": 51.9954225509,
                "lng": -131.0357519655
            },
            {
                "lat": 52.0023238767,
                "lng": -131.0464634509
            },
            {
                "lat": 52.0116042296,
                "lng": -131.0574041606
            },
            {
                "lat": 52.0215949992,
                "lng": -131.0612177581
            },
            {
                "lat": 52.0320666247,
                "lng": -131.0609746315
            },
            {
                "lat": 52.0427746514,
                "lng": -131.0631104641
            },
            {
                "lat": 52.0551493502,
                "lng": -131.0719154541
            },
            {
                "lat": 52.0637127683,
                "lng": -131.0773919707
            },
            {
                "lat": 52.0725174224,
                "lng": -131.0785817724
            },
            {
                "lat": 52.0803684588,
                "lng": -131.0745392874
            },
            {
                "lat": 52.0829890759,
                "lng": -131.0678708727
            },
            {
                "lat": 52.0844148192,
                "lng": -131.059311138
            },
            {
                "lat": 52.0803686127,
                "lng": -131.0455019578
            },
            {
                "lat": 52.078468614,
                "lng": -131.0283814952
            },
            {
                "lat": 52.0787048347,
                "lng": -131.0143427601
            },
            {
                "lat": 52.0784686002,
                "lng": -130.994354503
            },
            {
                "lat": 52.0775152092,
                "lng": -130.9774472183
            },
            {
                "lat": 52.0789411698,
                "lng": -130.9608002433
            },
            {
                "lat": 52.0813219887,
                "lng": -130.9508057201
            },
            {
                "lat": 52.0829888777,
                "lng": -130.9431912438
            },
            {
                "lat": 52.0834118338,
                "lng": -130.941192797
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 290,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 434,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 64036064.3753,
            "FEATURE_LENGTH_M": 72743.317,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.0368638963526,
            "lng": -131.0471305805074
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2333787158,
                "lng": -128.380859938
            },
            {
                "lat": 52.2337002246,
                "lng": -128.3856194997
            },
            {
                "lat": 52.2345499905,
                "lng": -128.3971250796
            },
            {
                "lat": 52.2336653506,
                "lng": -128.4058077931
            },
            {
                "lat": 52.2320749627,
                "lng": -128.4122615217
            },
            {
                "lat": 52.2338413181,
                "lng": -128.4191592531
            },
            {
                "lat": 52.2352601253,
                "lng": -128.4247438494
            },
            {
                "lat": 52.2355230821,
                "lng": -128.4319920067
            },
            {
                "lat": 52.234283611,
                "lng": -128.4285432956
            },
            {
                "lat": 52.2313650509,
                "lng": -128.4310145062
            },
            {
                "lat": 52.2272066379,
                "lng": -128.4255371077
            },
            {
                "lat": 52.2285351715,
                "lng": -128.4210205718
            },
            {
                "lat": 52.2283559496,
                "lng": -128.4145661477
            },
            {
                "lat": 52.2229574793,
                "lng": -128.4081881986
            },
            {
                "lat": 52.2201271918,
                "lng": -128.4018250803
            },
            {
                "lat": 52.216231915,
                "lng": -128.3996882206
            },
            {
                "lat": 52.2238308786,
                "lng": -128.3942114551
            },
            {
                "lat": 52.229305515,
                "lng": -128.3837283072
            },
            {
                "lat": 52.2306290877,
                "lng": -128.380462878
            },
            {
                "lat": 52.2333787158,
                "lng": -128.380859938
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 291,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 435,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 3157269.1211,
            "FEATURE_LENGTH_M": 9803.5038,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.22991004869,
            "lng": -128.406360732405
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2295604976,
                "lng": -128.270828924
            },
            {
                "lat": 52.2272067246,
                "lng": -128.2784422037
            },
            {
                "lat": 52.2278248911,
                "lng": -128.2932278277
            },
            {
                "lat": 52.2257802806,
                "lng": -128.3010404188
            },
            {
                "lat": 52.2245910318,
                "lng": -128.3293610189
            },
            {
                "lat": 52.2319146343,
                "lng": -128.3557128871
            },
            {
                "lat": 52.2277610123,
                "lng": -128.360412292
            },
            {
                "lat": 52.2139048275,
                "lng": -128.3629911127
            },
            {
                "lat": 52.179871277,
                "lng": -128.3488161595
            },
            {
                "lat": 52.1861642865,
                "lng": -128.3407744101
            },
            {
                "lat": 52.1971095765,
                "lng": -128.3248286993
            },
            {
                "lat": 52.2109108766,
                "lng": -128.3072361672
            },
            {
                "lat": 52.2175710286,
                "lng": -128.2967534627
            },
            {
                "lat": 52.221854918,
                "lng": -128.2834317465
            },
            {
                "lat": 52.2216186647,
                "lng": -128.2791438156
            },
            {
                "lat": 52.224472119,
                "lng": -128.2742765186
            },
            {
                "lat": 52.2282298216,
                "lng": -128.2713615855
            },
            {
                "lat": 52.2295604976,
                "lng": -128.270828924
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 292,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 436,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 15348584.3168,
            "FEATURE_LENGTH_M": 20020.1391,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.218105942550004,
            "lng": -128.3083037874389
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2295604976,
                "lng": -128.270828924
            },
            {
                "lat": 52.2297595916,
                "lng": -128.2701717518
            },
            {
                "lat": 52.2297967376,
                "lng": -128.2707367746
            },
            {
                "lat": 52.2295604976,
                "lng": -128.270828924
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 293,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 437,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 520.4219,
            "FEATURE_LENGTH_M": 115.8369,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.2296693311,
            "lng": -128.27064159359998
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2295604976,
                "lng": -128.270828924
            },
            {
                "lat": 52.2282298216,
                "lng": -128.2713615855
            },
            {
                "lat": 52.224472119,
                "lng": -128.2742765186
            },
            {
                "lat": 52.2216186647,
                "lng": -128.2791438156
            },
            {
                "lat": 52.2181506838,
                "lng": -128.275909164
            },
            {
                "lat": 52.2181439818,
                "lng": -128.2694846922
            },
            {
                "lat": 52.2134206265,
                "lng": -128.2700795688
            },
            {
                "lat": 52.2036023467,
                "lng": -128.2731933345
            },
            {
                "lat": 52.2007223373,
                "lng": -128.2683105761
            },
            {
                "lat": 52.1993938235,
                "lng": -128.2541346611
            },
            {
                "lat": 52.2009432378,
                "lng": -128.2473456672
            },
            {
                "lat": 52.2072905779,
                "lng": -128.2419584314
            },
            {
                "lat": 52.219547563,
                "lng": -128.2421714681
            },
            {
                "lat": 52.2216457308,
                "lng": -128.2499388098
            },
            {
                "lat": 52.2225649077,
                "lng": -128.253341525
            },
            {
                "lat": 52.2293589602,
                "lng": -128.263855191
            },
            {
                "lat": 52.2297595916,
                "lng": -128.2701717518
            },
            {
                "lat": 52.2295604976,
                "lng": -128.270828924
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 294,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 22,
            "MANAGEMENT_AREA_NAME": "Subarea 7-22",
            "LABEL": "7-22",
            "FISHERY_AREA_ID": 2456,
            "OBJECTID": 438,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 5642278.4098,
            "FEATURE_LENGTH_M": 10061.5852,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.21766588717222,
            "lng": -128.2636852560389
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2134206265,
                "lng": -128.2700795688
            },
            {
                "lat": 52.2181439818,
                "lng": -128.2694846922
            },
            {
                "lat": 52.2181506838,
                "lng": -128.275909164
            },
            {
                "lat": 52.2216186647,
                "lng": -128.2791438156
            },
            {
                "lat": 52.221854918,
                "lng": -128.2834317465
            },
            {
                "lat": 52.2175710286,
                "lng": -128.2967534627
            },
            {
                "lat": 52.2109108766,
                "lng": -128.3072361672
            },
            {
                "lat": 52.1971095765,
                "lng": -128.3248286993
            },
            {
                "lat": 52.1861642865,
                "lng": -128.3407744101
            },
            {
                "lat": 52.179871277,
                "lng": -128.3488161595
            },
            {
                "lat": 52.1701656425,
                "lng": -128.3447870972
            },
            {
                "lat": 52.1613308078,
                "lng": -128.3447725241
            },
            {
                "lat": 52.1614111393,
                "lng": -128.3438264306
            },
            {
                "lat": 52.161075836,
                "lng": -128.3423002551
            },
            {
                "lat": 52.1593551797,
                "lng": -128.3429562467
            },
            {
                "lat": 52.1571469153,
                "lng": -128.3433999625
            },
            {
                "lat": 52.1545601989,
                "lng": -128.3436428279
            },
            {
                "lat": 52.1521688138,
                "lng": -128.345535801
            },
            {
                "lat": 52.1499171591,
                "lng": -128.3450771326
            },
            {
                "lat": 52.1446264538,
                "lng": -128.3458106638
            },
            {
                "lat": 52.1397622441,
                "lng": -128.3480222425
            },
            {
                "lat": 52.1343117212,
                "lng": -128.3485571648
            },
            {
                "lat": 52.1276472628,
                "lng": -128.3521269437
            },
            {
                "lat": 52.1195599127,
                "lng": -128.3594979218
            },
            {
                "lat": 52.1176565946,
                "lng": -128.3630679805
            },
            {
                "lat": 52.1143224274,
                "lng": -128.3699640714
            },
            {
                "lat": 52.1121939301,
                "lng": -128.3736114835
            },
            {
                "lat": 52.1080286436,
                "lng": -128.3776097554
            },
            {
                "lat": 52.1060603709,
                "lng": -128.3794863871
            },
            {
                "lat": 52.105923045,
                "lng": -128.3762973458
            },
            {
                "lat": 52.1034581476,
                "lng": -128.3748630042
            },
            {
                "lat": 52.1003535964,
                "lng": -128.3712002767
            },
            {
                "lat": 52.1004790776,
                "lng": -128.3643493589
            },
            {
                "lat": 52.1017154569,
                "lng": -128.3628384548
            },
            {
                "lat": 52.1089102435,
                "lng": -128.3540654913
            },
            {
                "lat": 52.112952961,
                "lng": -128.3466485834
            },
            {
                "lat": 52.1112676214,
                "lng": -128.3394008142
            },
            {
                "lat": 52.1040195341,
                "lng": -128.3338317779
            },
            {
                "lat": 52.0957560338,
                "lng": -128.3292844055
            },
            {
                "lat": 52.0907246585,
                "lng": -128.3276972142
            },
            {
                "lat": 52.0894311847,
                "lng": -128.3275300745
            },
            {
                "lat": 52.089230167,
                "lng": -128.3251489922
            },
            {
                "lat": 52.0895768153,
                "lng": -128.3240358713
            },
            {
                "lat": 52.0895838262,
                "lng": -128.3015901167
            },
            {
                "lat": 52.0893209726,
                "lng": -128.3003988158
            },
            {
                "lat": 52.088787608,
                "lng": -128.2994390913
            },
            {
                "lat": 52.0840718855,
                "lng": -128.2955316043
            },
            {
                "lat": 52.0760913792,
                "lng": -128.2909245445
            },
            {
                "lat": 52.0718070704,
                "lng": -128.2884681961
            },
            {
                "lat": 52.070895838,
                "lng": -128.2846374245
            },
            {
                "lat": 52.0715789012,
                "lng": -128.2805935555
            },
            {
                "lat": 52.0746082039,
                "lng": -128.2710564162
            },
            {
                "lat": 52.084274226,
                "lng": -128.2619321237
            },
            {
                "lat": 52.082848174,
                "lng": -128.2512816058
            },
            {
                "lat": 52.0828513992,
                "lng": -128.242538028
            },
            {
                "lat": 52.0797272099,
                "lng": -128.2422186315
            },
            {
                "lat": 52.0734256791,
                "lng": -128.240158164
            },
            {
                "lat": 52.0649680701,
                "lng": -128.2332915184
            },
            {
                "lat": 52.0558315295,
                "lng": -128.2274020554
            },
            {
                "lat": 52.0510595756,
                "lng": -128.2168585647
            },
            {
                "lat": 52.0458608462,
                "lng": -128.2075796865
            },
            {
                "lat": 52.0486256821,
                "lng": -128.203002454
            },
            {
                "lat": 52.0577814587,
                "lng": -128.2024682276
            },
            {
                "lat": 52.066184593,
                "lng": -128.2094884118
            },
            {
                "lat": 52.0705486809,
                "lng": -128.2192847744
            },
            {
                "lat": 52.0742715808,
                "lng": -128.2243964644
            },
            {
                "lat": 52.0799144499,
                "lng": -128.2304538469
            },
            {
                "lat": 52.0861926311,
                "lng": -128.2330019029
            },
            {
                "lat": 52.0931089262,
                "lng": -128.2339636795
            },
            {
                "lat": 52.1184921619,
                "lng": -128.2278595673
            },
            {
                "lat": 52.1343606443,
                "lng": -128.2233426975
            },
            {
                "lat": 52.1451374274,
                "lng": -128.2268825776
            },
            {
                "lat": 52.1500326369,
                "lng": -128.2411801487
            },
            {
                "lat": 52.1427847126,
                "lng": -128.2521523742
            },
            {
                "lat": 52.1416086625,
                "lng": -128.2562554113
            },
            {
                "lat": 52.1437950263,
                "lng": -128.2590636131
            },
            {
                "lat": 52.1455272132,
                "lng": -128.2619472626
            },
            {
                "lat": 52.1437649481,
                "lng": -128.2633206359
            },
            {
                "lat": 52.1435852819,
                "lng": -128.2632444546
            },
            {
                "lat": 52.1414143749,
                "lng": -128.2623441064
            },
            {
                "lat": 52.1410221536,
                "lng": -128.2620088254
            },
            {
                "lat": 52.1400416488,
                "lng": -128.2611541793
            },
            {
                "lat": 52.1363174583,
                "lng": -128.2627261436
            },
            {
                "lat": 52.1341665765,
                "lng": -128.2646947376
            },
            {
                "lat": 52.1278949517,
                "lng": -128.2687982288
            },
            {
                "lat": 52.1200605721,
                "lng": -128.2770228584
            },
            {
                "lat": 52.1139869687,
                "lng": -128.284470197
            },
            {
                "lat": 52.1128121143,
                "lng": -128.2891852918
            },
            {
                "lat": 52.1121069596,
                "lng": -128.2926939243
            },
            {
                "lat": 52.1128425917,
                "lng": -128.3005983431
            },
            {
                "lat": 52.1240728625,
                "lng": -128.3104858521
            },
            {
                "lat": 52.1280824457,
                "lng": -128.3077240505
            },
            {
                "lat": 52.1291812876,
                "lng": -128.3076937458
            },
            {
                "lat": 52.1313700274,
                "lng": -128.3063965203
            },
            {
                "lat": 52.1363677114,
                "lng": -128.3047332333
            },
            {
                "lat": 52.1465460415,
                "lng": -128.3112795619
            },
            {
                "lat": 52.1523968863,
                "lng": -128.3185726047
            },
            {
                "lat": 52.1626589332,
                "lng": -128.3160247364
            },
            {
                "lat": 52.1776512431,
                "lng": -128.3050841893
            },
            {
                "lat": 52.1933551189,
                "lng": -128.2908018343
            },
            {
                "lat": 52.1922494229,
                "lng": -128.2804875467
            },
            {
                "lat": 52.1919295491,
                "lng": -128.2774809384
            },
            {
                "lat": 52.1926419454,
                "lng": -128.2717742749
            },
            {
                "lat": 52.1973996505,
                "lng": -128.2689200554
            },
            {
                "lat": 52.2025034785,
                "lng": -128.2761380541
            },
            {
                "lat": 52.2042993584,
                "lng": -128.2786713197
            },
            {
                "lat": 52.2085834971,
                "lng": -128.2801057755
            },
            {
                "lat": 52.2104872555,
                "lng": -128.2767630751
            },
            {
                "lat": 52.21265846,
                "lng": -128.271820742
            },
            {
                "lat": 52.2134206265,
                "lng": -128.2700795688
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 295,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 23,
            "MANAGEMENT_AREA_NAME": "Subarea 7-23",
            "LABEL": "7-23",
            "FISHERY_AREA_ID": 2457,
            "OBJECTID": 439,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 73605257.101,
            "FEATURE_LENGTH_M": 71113.139,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.1305795181864,
            "lng": -128.2944695055009
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2132231003,
                "lng": -128.2159721599
            },
            {
                "lat": 52.2127492893,
                "lng": -128.2207338878
            },
            {
                "lat": 52.2167934856,
                "lng": -128.2266848602
            },
            {
                "lat": 52.219547563,
                "lng": -128.2421714681
            },
            {
                "lat": 52.2072905779,
                "lng": -128.2419584314
            },
            {
                "lat": 52.2009432378,
                "lng": -128.2473456672
            },
            {
                "lat": 52.2013289329,
                "lng": -128.2392881307
            },
            {
                "lat": 52.2051354713,
                "lng": -128.2281045179
            },
            {
                "lat": 52.2101329547,
                "lng": -128.2190698533
            },
            {
                "lat": 52.2132231003,
                "lng": -128.2159721599
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 296,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 440,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2281903.5342,
            "FEATURE_LENGTH_M": 6859.6062,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.21003677130999,
            "lng": -128.22973011364
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2009432378,
                "lng": -128.2473456672
            },
            {
                "lat": 52.1993938235,
                "lng": -128.2541346611
            },
            {
                "lat": 52.193474342,
                "lng": -128.2611843902
            },
            {
                "lat": 52.188476263,
                "lng": -128.2721256949
            },
            {
                "lat": 52.1813392539,
                "lng": -128.2790220085
            },
            {
                "lat": 52.167060717,
                "lng": -128.283081608
            },
            {
                "lat": 52.1580199865,
                "lng": -128.2880703952
            },
            {
                "lat": 52.1494519498,
                "lng": -128.2959289322
            },
            {
                "lat": 52.1363677114,
                "lng": -128.3047332333
            },
            {
                "lat": 52.1313700274,
                "lng": -128.3063965203
            },
            {
                "lat": 52.1291812876,
                "lng": -128.3076937458
            },
            {
                "lat": 52.1280824457,
                "lng": -128.3077240505
            },
            {
                "lat": 52.1240728625,
                "lng": -128.3104858521
            },
            {
                "lat": 52.1128425917,
                "lng": -128.3005983431
            },
            {
                "lat": 52.1121069596,
                "lng": -128.2926939243
            },
            {
                "lat": 52.1128121143,
                "lng": -128.2891852918
            },
            {
                "lat": 52.1139869687,
                "lng": -128.284470197
            },
            {
                "lat": 52.1200605721,
                "lng": -128.2770228584
            },
            {
                "lat": 52.1278949517,
                "lng": -128.2687982288
            },
            {
                "lat": 52.1341665765,
                "lng": -128.2646947376
            },
            {
                "lat": 52.1363174583,
                "lng": -128.2627261436
            },
            {
                "lat": 52.1400416488,
                "lng": -128.2611541793
            },
            {
                "lat": 52.1410221536,
                "lng": -128.2620088254
            },
            {
                "lat": 52.1414143749,
                "lng": -128.2623441064
            },
            {
                "lat": 52.1435852819,
                "lng": -128.2632444546
            },
            {
                "lat": 52.1437649481,
                "lng": -128.2633206359
            },
            {
                "lat": 52.1455272132,
                "lng": -128.2619472626
            },
            {
                "lat": 52.1437950263,
                "lng": -128.2590636131
            },
            {
                "lat": 52.1416086625,
                "lng": -128.2562554113
            },
            {
                "lat": 52.1427847126,
                "lng": -128.2521523742
            },
            {
                "lat": 52.1550745352,
                "lng": -128.2470855514
            },
            {
                "lat": 52.1800923712,
                "lng": -128.2205349233
            },
            {
                "lat": 52.1806754069,
                "lng": -128.2149652963
            },
            {
                "lat": 52.1804845241,
                "lng": -128.188003514
            },
            {
                "lat": 52.1831889065,
                "lng": -128.1749574003
            },
            {
                "lat": 52.1884076587,
                "lng": -128.1660772008
            },
            {
                "lat": 52.1940120855,
                "lng": -128.1644287304
            },
            {
                "lat": 52.1991305915,
                "lng": -128.165007987
            },
            {
                "lat": 52.2008699493,
                "lng": -128.168288613
            },
            {
                "lat": 52.20205718,
                "lng": -128.1698455006
            },
            {
                "lat": 52.2021261288,
                "lng": -128.1699369326
            },
            {
                "lat": 52.2026104081,
                "lng": -128.1706091151
            },
            {
                "lat": 52.2030945618,
                "lng": -128.1716763054
            },
            {
                "lat": 52.2035755926,
                "lng": -128.1727296238
            },
            {
                "lat": 52.2042539525,
                "lng": -128.1733098791
            },
            {
                "lat": 52.2052190928,
                "lng": -128.1740878488
            },
            {
                "lat": 52.2061842353,
                "lng": -128.1780544235
            },
            {
                "lat": 52.2075382908,
                "lng": -128.1811379827
            },
            {
                "lat": 52.208697924,
                "lng": -128.1843265114
            },
            {
                "lat": 52.2092784749,
                "lng": -128.1868443325
            },
            {
                "lat": 52.2085990626,
                "lng": -128.1888732425
            },
            {
                "lat": 52.2081375864,
                "lng": -128.1907646185
            },
            {
                "lat": 52.2164150534,
                "lng": -128.2056882389
            },
            {
                "lat": 52.2132231003,
                "lng": -128.2159721599
            },
            {
                "lat": 52.2101329547,
                "lng": -128.2190698533
            },
            {
                "lat": 52.2051354713,
                "lng": -128.2281045179
            },
            {
                "lat": 52.2013289329,
                "lng": -128.2392881307
            },
            {
                "lat": 52.2009432378,
                "lng": -128.2473456672
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 297,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 24,
            "MANAGEMENT_AREA_NAME": "Subarea 7-24",
            "LABEL": "7-24",
            "FISHERY_AREA_ID": 2458,
            "OBJECTID": 441,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 41388850.3341,
            "FEATURE_LENGTH_M": 34416.9113,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.17105954125517,
            "lng": -128.23580381806204
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.2500003759,
                "lng": -137.0977328582
            },
            {
                "lat": 51.2500003442,
                "lng": -136.0528195729
            },
            {
                "lat": 51.2500002875,
                "lng": -135.0079065731
            },
            {
                "lat": 51.2500002084,
                "lng": -133.9629932208
            },
            {
                "lat": 51.2500003969,
                "lng": -132.9180803428
            },
            {
                "lat": 51.2499999862,
                "lng": -131.8731672806
            },
            {
                "lat": 51.2500001243,
                "lng": -130.8282542294
            },
            {
                "lat": 51.2500001578,
                "lng": -129.783340885
            },
            {
                "lat": 51.3943821578,
                "lng": -130.0416716724
            },
            {
                "lat": 51.6555479281,
                "lng": -130.5083307374
            },
            {
                "lat": 51.6555494236,
                "lng": -131.5112062412
            },
            {
                "lat": 51.6555504415,
                "lng": -132.514081228
            },
            {
                "lat": 51.6555514113,
                "lng": -133.5169568505
            },
            {
                "lat": 51.6555529713,
                "lng": -134.5198321889
            },
            {
                "lat": 51.6555540411,
                "lng": -135.5227075947
            },
            {
                "lat": 51.6555548411,
                "lng": -136.5255823394
            },
            {
                "lat": 51.65555606,
                "lng": -137.5284577189
            },
            {
                "lat": 51.6113010997,
                "lng": -137.4774937256
            },
            {
                "lat": 51.5579418945,
                "lng": -137.4239960371
            },
            {
                "lat": 51.4932671548,
                "lng": -137.3504332008
            },
            {
                "lat": 51.4144636629,
                "lng": -137.2724001805
            },
            {
                "lat": 51.3382837819,
                "lng": -137.196975817
            },
            {
                "lat": 51.2500003759,
                "lng": -137.0977328582
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 298,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 130,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 130-2",
            "LABEL": "130-2",
            "FISHERY_AREA_ID": 2344,
            "OBJECTID": 442,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 22474005128.2501,
            "FEATURE_LENGTH_M": 1116898.4015,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.448002570726096,
            "lng": -134.50139797188697
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1666685892,
                "lng": -128.7558588493
            },
            {
                "lat": 52.1666683791,
                "lng": -129.9999997098
            },
            {
                "lat": 51.7999996952,
                "lng": -129.9999997991
            },
            {
                "lat": 51.7540318219,
                "lng": -128.8000021087
            },
            {
                "lat": 51.8515018261,
                "lng": -128.4568784606
            },
            {
                "lat": 51.8899419736,
                "lng": -128.4933467176
            },
            {
                "lat": 52.0042036523,
                "lng": -128.6017459932
            },
            {
                "lat": 52.1666685892,
                "lng": -128.7558588493
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 299,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 107,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 107-2",
            "LABEL": "107-2",
            "FISHERY_AREA_ID": 2309,
            "OBJECTID": 443,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4170983335.7397,
            "FEATURE_LENGTH_M": 275347.7601,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.97496056582501,
            "lng": -128.98296131095
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1993938235,
                "lng": -128.2541346611
            },
            {
                "lat": 52.2007223373,
                "lng": -128.2683105761
            },
            {
                "lat": 52.2036023467,
                "lng": -128.2731933345
            },
            {
                "lat": 52.2134206265,
                "lng": -128.2700795688
            },
            {
                "lat": 52.21265846,
                "lng": -128.271820742
            },
            {
                "lat": 52.2104872555,
                "lng": -128.2767630751
            },
            {
                "lat": 52.2085834971,
                "lng": -128.2801057755
            },
            {
                "lat": 52.2042993584,
                "lng": -128.2786713197
            },
            {
                "lat": 52.2025034785,
                "lng": -128.2761380541
            },
            {
                "lat": 52.1973996505,
                "lng": -128.2689200554
            },
            {
                "lat": 52.1926419454,
                "lng": -128.2717742749
            },
            {
                "lat": 52.1919295491,
                "lng": -128.2774809384
            },
            {
                "lat": 52.1922494229,
                "lng": -128.2804875467
            },
            {
                "lat": 52.1933551189,
                "lng": -128.2908018343
            },
            {
                "lat": 52.1776512431,
                "lng": -128.3050841893
            },
            {
                "lat": 52.1626589332,
                "lng": -128.3160247364
            },
            {
                "lat": 52.1523968863,
                "lng": -128.3185726047
            },
            {
                "lat": 52.1465460415,
                "lng": -128.3112795619
            },
            {
                "lat": 52.1363677114,
                "lng": -128.3047332333
            },
            {
                "lat": 52.1494519498,
                "lng": -128.2959289322
            },
            {
                "lat": 52.1580199865,
                "lng": -128.2880703952
            },
            {
                "lat": 52.167060717,
                "lng": -128.283081608
            },
            {
                "lat": 52.1813392539,
                "lng": -128.2790220085
            },
            {
                "lat": 52.188476263,
                "lng": -128.2721256949
            },
            {
                "lat": 52.193474342,
                "lng": -128.2611843902
            },
            {
                "lat": 52.1993938235,
                "lng": -128.2541346611
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 300,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 444,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 10799470.7985,
            "FEATURE_LENGTH_M": 21878.3593,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.186003231596146,
            "lng": -128.28184322201153
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2009435002,
                "lng": -128.4221955392
            },
            {
                "lat": 52.2019840078,
                "lng": -128.4409181554
            },
            {
                "lat": 52.1765021796,
                "lng": -128.4476773198
            },
            {
                "lat": 52.1707800328,
                "lng": -128.4286041784
            },
            {
                "lat": 52.1622850556,
                "lng": -128.4117885519
            },
            {
                "lat": 52.1615330317,
                "lng": -128.4084475248
            },
            {
                "lat": 52.1646311218,
                "lng": -128.4049374901
            },
            {
                "lat": 52.1771855284,
                "lng": -128.4002838865
            },
            {
                "lat": 52.1788596235,
                "lng": -128.4144296041
            },
            {
                "lat": 52.1793366737,
                "lng": -128.4287109355
            },
            {
                "lat": 52.1909977681,
                "lng": -128.4279939573
            },
            {
                "lat": 52.2009435002,
                "lng": -128.4221955392
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 301,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 445,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 6370863.6232,
            "FEATURE_LENGTH_M": 13636.8928,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.180498501950005,
            "lng": -128.42151522351665
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1866302317,
                "lng": -127.4725807608
            },
            {
                "lat": 52.206276115,
                "lng": -127.4992136627
            },
            {
                "lat": 52.2159004513,
                "lng": -127.5067130025
            },
            {
                "lat": 52.2058491807,
                "lng": -127.5508192013
            },
            {
                "lat": 52.196357614,
                "lng": -127.5826484423
            },
            {
                "lat": 52.1745833856,
                "lng": -127.6234057932
            },
            {
                "lat": 52.146666817,
                "lng": -127.6652752744
            },
            {
                "lat": 52.1025582655,
                "lng": -127.6870498977
            },
            {
                "lat": 52.0679887308,
                "lng": -127.6981889182
            },
            {
                "lat": 52.0536837357,
                "lng": -127.7001119593
            },
            {
                "lat": 52.0313492956,
                "lng": -127.7098851797
            },
            {
                "lat": 51.9985469275,
                "lng": -127.7228006906
            },
            {
                "lat": 51.9842371389,
                "lng": -127.7419972155
            },
            {
                "lat": 51.9793511713,
                "lng": -127.7793349846
            },
            {
                "lat": 51.9741175297,
                "lng": -127.8149331055
            },
            {
                "lat": 51.957366386,
                "lng": -127.8355263218
            },
            {
                "lat": 51.9503861538,
                "lng": -127.8564608796
            },
            {
                "lat": 51.948467854,
                "lng": -127.8701696634
            },
            {
                "lat": 51.9471092441,
                "lng": -127.8798759634
            },
            {
                "lat": 51.9413485727,
                "lng": -127.892783733
            },
            {
                "lat": 51.9386213383,
                "lng": -127.8936462109
            },
            {
                "lat": 51.9357339302,
                "lng": -127.8945617425
            },
            {
                "lat": 51.9313930888,
                "lng": -127.8947373428
            },
            {
                "lat": 51.9313998684,
                "lng": -127.8982007979
            },
            {
                "lat": 51.9303473516,
                "lng": -127.8992695668
            },
            {
                "lat": 51.9271473158,
                "lng": -127.8979874229
            },
            {
                "lat": 51.9251067126,
                "lng": -127.9005354285
            },
            {
                "lat": 51.9242741676,
                "lng": -127.9025113292
            },
            {
                "lat": 51.9248084003,
                "lng": -127.9038166331
            },
            {
                "lat": 51.9246900379,
                "lng": -127.9047310733
            },
            {
                "lat": 51.9241335285,
                "lng": -127.9056163359
            },
            {
                "lat": 51.9054372504,
                "lng": -127.8711095992
            },
            {
                "lat": 51.8937791747,
                "lng": -127.8745274574
            },
            {
                "lat": 51.8921468153,
                "lng": -127.8737786087
            },
            {
                "lat": 51.8924558027,
                "lng": -127.8617859115
            },
            {
                "lat": 51.8875348872,
                "lng": -127.8448718508
            },
            {
                "lat": 51.8966257687,
                "lng": -127.8284068207
            },
            {
                "lat": 51.9106643349,
                "lng": -127.8150786382
            },
            {
                "lat": 51.9192312352,
                "lng": -127.7877203794
            },
            {
                "lat": 51.924465253,
                "lng": -127.7417905299
            },
            {
                "lat": 51.9292220209,
                "lng": -127.7089534947
            },
            {
                "lat": 51.9249375019,
                "lng": -127.6730262402
            },
            {
                "lat": 51.9327920752,
                "lng": -127.6409078531
            },
            {
                "lat": 51.947544394,
                "lng": -127.6256711589
            },
            {
                "lat": 51.9668191226,
                "lng": -127.6175837731
            },
            {
                "lat": 51.9996568029,
                "lng": -127.6152045856
            },
            {
                "lat": 52.0329512276,
                "lng": -127.624069275
            },
            {
                "lat": 52.0605883365,
                "lng": -127.618850742
            },
            {
                "lat": 52.0934406063,
                "lng": -127.6016465439
            },
            {
                "lat": 52.1040113563,
                "lng": -127.601287804
            },
            {
                "lat": 52.1151927557,
                "lng": -127.5860978948
            },
            {
                "lat": 52.1132580728,
                "lng": -127.5750503764
            },
            {
                "lat": 52.1137774928,
                "lng": -127.551064498
            },
            {
                "lat": 52.1226423165,
                "lng": -127.5322875297
            },
            {
                "lat": 52.1394389502,
                "lng": -127.5167842242
            },
            {
                "lat": 52.1524270679,
                "lng": -127.515190352
            },
            {
                "lat": 52.1584589588,
                "lng": -127.5273063284
            },
            {
                "lat": 52.1866302317,
                "lng": -127.4725807608
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 302,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 8-13",
            "LABEL": "8-13",
            "FISHERY_AREA_ID": 2479,
            "OBJECTID": 446,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 299840980.9701,
            "FEATURE_LENGTH_M": 108642.3714,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.01211310954483,
            "lng": -127.72565554765346
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.2030447427,
                "lng": -128.0797114015
            },
            {
                "lat": 52.2022097525,
                "lng": -128.0851587119
            },
            {
                "lat": 52.2026898166,
                "lng": -128.0922996124
            },
            {
                "lat": 52.203697682,
                "lng": -128.0947420357
            },
            {
                "lat": 52.2042042361,
                "lng": -128.0952916542
            },
            {
                "lat": 52.1851505129,
                "lng": -128.1122129955
            },
            {
                "lat": 52.1855091307,
                "lng": -128.1148678204
            },
            {
                "lat": 52.1851237476,
                "lng": -128.1174771556
            },
            {
                "lat": 52.1839641487,
                "lng": -128.1227875076
            },
            {
                "lat": 52.1833842152,
                "lng": -128.1280051285
            },
            {
                "lat": 52.1847348364,
                "lng": -128.1306153392
            },
            {
                "lat": 52.1879236693,
                "lng": -128.1312860957
            },
            {
                "lat": 52.1900482132,
                "lng": -128.133407097
            },
            {
                "lat": 52.1910163514,
                "lng": -128.1398927972
            },
            {
                "lat": 52.1893727611,
                "lng": -128.1442412151
            },
            {
                "lat": 52.1855087756,
                "lng": -128.1465609295
            },
            {
                "lat": 52.1807745615,
                "lng": -128.1463621338
            },
            {
                "lat": 52.1732364576,
                "lng": -128.1487728807
            },
            {
                "lat": 52.1388705765,
                "lng": -128.1610410523
            },
            {
                "lat": 52.1038935869,
                "lng": -128.1629490284
            },
            {
                "lat": 52.08818824,
                "lng": -128.1650852717
            },
            {
                "lat": 52.0789077333,
                "lng": -128.1755521368
            },
            {
                "lat": 52.0827143576,
                "lng": -128.1857904279
            },
            {
                "lat": 52.0936622111,
                "lng": -128.1919708821
            },
            {
                "lat": 52.0996094447,
                "lng": -128.2086340557
            },
            {
                "lat": 52.0950892569,
                "lng": -128.2193296221
            },
            {
                "lat": 52.0872344404,
                "lng": -128.2259982323
            },
            {
                "lat": 52.0799144499,
                "lng": -128.2304538469
            },
            {
                "lat": 52.0742715808,
                "lng": -128.2243964644
            },
            {
                "lat": 52.0705486809,
                "lng": -128.2192847744
            },
            {
                "lat": 52.066184593,
                "lng": -128.2094884118
            },
            {
                "lat": 52.0577814587,
                "lng": -128.2024682276
            },
            {
                "lat": 52.0463102586,
                "lng": -128.1950675386
            },
            {
                "lat": 52.0313191213,
                "lng": -128.1898351472
            },
            {
                "lat": 52.0237394696,
                "lng": -128.1898651329
            },
            {
                "lat": 52.0202635116,
                "lng": -128.1861729389
            },
            {
                "lat": 52.0165636266,
                "lng": -128.186767219
            },
            {
                "lat": 52.0131151543,
                "lng": -128.1862024278
            },
            {
                "lat": 52.0118102304,
                "lng": -128.1851505236
            },
            {
                "lat": 52.0108333683,
                "lng": -128.1838067431
            },
            {
                "lat": 52.0098115296,
                "lng": -128.1835319257
            },
            {
                "lat": 52.0098148876,
                "lng": -128.1660152101
            },
            {
                "lat": 52.0106623032,
                "lng": -128.1587062177
            },
            {
                "lat": 52.0120432931,
                "lng": -128.1571196017
            },
            {
                "lat": 52.021846726,
                "lng": -128.1457516562
            },
            {
                "lat": 52.0406454188,
                "lng": -128.1219485967
            },
            {
                "lat": 52.0430226593,
                "lng": -128.101959112
            },
            {
                "lat": 52.0382658129,
                "lng": -128.0791172288
            },
            {
                "lat": 52.0331005131,
                "lng": -128.0732426282
            },
            {
                "lat": 52.060718523,
                "lng": -127.9632641162
            },
            {
                "lat": 52.0597419703,
                "lng": -127.9563442884
            },
            {
                "lat": 52.0638202574,
                "lng": -127.9509276153
            },
            {
                "lat": 52.0758210623,
                "lng": -127.9424594434
            },
            {
                "lat": 52.0802313619,
                "lng": -127.9431309211
            },
            {
                "lat": 52.0901370453,
                "lng": -127.9406813734
            },
            {
                "lat": 52.0972861633,
                "lng": -127.9363936713
            },
            {
                "lat": 52.1142919757,
                "lng": -127.927712107
            },
            {
                "lat": 52.1297564677,
                "lng": -127.9191522816
            },
            {
                "lat": 52.1370009033,
                "lng": -127.9099196582
            },
            {
                "lat": 52.1851843412,
                "lng": -127.8842922563
            },
            {
                "lat": 52.189250466,
                "lng": -127.8942113497
            },
            {
                "lat": 52.188884814,
                "lng": -127.9026486888
            },
            {
                "lat": 52.1846010823,
                "lng": -127.9102636919
            },
            {
                "lat": 52.1812703262,
                "lng": -127.9209748115
            },
            {
                "lat": 52.1819838338,
                "lng": -127.9354861704
            },
            {
                "lat": 52.1872180966,
                "lng": -127.9573740995
            },
            {
                "lat": 52.1926917765,
                "lng": -127.9833141424
            },
            {
                "lat": 52.1907886546,
                "lng": -127.9949717301
            },
            {
                "lat": 52.187931626,
                "lng": -128.0085299016
            },
            {
                "lat": 52.1893611962,
                "lng": -128.0308987677
            },
            {
                "lat": 52.1922146084,
                "lng": -128.0516061314
            },
            {
                "lat": 52.1988794657,
                "lng": -128.0708768585
            },
            {
                "lat": 52.2030447427,
                "lng": -128.0797114015
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 303,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 17,
            "MANAGEMENT_AREA_NAME": "Subarea 7-17",
            "LABEL": "7-17",
            "FISHERY_AREA_ID": 2452,
            "OBJECTID": 447,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 291522618.9484,
            "FEATURE_LENGTH_M": 81698.2237,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.117722505020566,
            "lng": -128.09111699001784
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1855087756,
                "lng": -128.1465609295
            },
            {
                "lat": 52.1838639834,
                "lng": -128.1500245103
            },
            {
                "lat": 52.1871490636,
                "lng": -128.1557309202
            },
            {
                "lat": 52.1935279327,
                "lng": -128.1596986905
            },
            {
                "lat": 52.1991305915,
                "lng": -128.165007987
            },
            {
                "lat": 52.1940120855,
                "lng": -128.1644287304
            },
            {
                "lat": 52.1884076587,
                "lng": -128.1660772008
            },
            {
                "lat": 52.1831889065,
                "lng": -128.1749574003
            },
            {
                "lat": 52.1804845241,
                "lng": -128.188003514
            },
            {
                "lat": 52.1806754069,
                "lng": -128.2149652963
            },
            {
                "lat": 52.1800923712,
                "lng": -128.2205349233
            },
            {
                "lat": 52.1550745352,
                "lng": -128.2470855514
            },
            {
                "lat": 52.1427847126,
                "lng": -128.2521523742
            },
            {
                "lat": 52.1500326369,
                "lng": -128.2411801487
            },
            {
                "lat": 52.1451374274,
                "lng": -128.2268825776
            },
            {
                "lat": 52.1343606443,
                "lng": -128.2233426975
            },
            {
                "lat": 52.1184921619,
                "lng": -128.2278595673
            },
            {
                "lat": 52.0931089262,
                "lng": -128.2339636795
            },
            {
                "lat": 52.0861926311,
                "lng": -128.2330019029
            },
            {
                "lat": 52.0799144499,
                "lng": -128.2304538469
            },
            {
                "lat": 52.0872344404,
                "lng": -128.2259982323
            },
            {
                "lat": 52.0950892569,
                "lng": -128.2193296221
            },
            {
                "lat": 52.0996094447,
                "lng": -128.2086340557
            },
            {
                "lat": 52.0936622111,
                "lng": -128.1919708821
            },
            {
                "lat": 52.0827143576,
                "lng": -128.1857904279
            },
            {
                "lat": 52.0789077333,
                "lng": -128.1755521368
            },
            {
                "lat": 52.08818824,
                "lng": -128.1650852717
            },
            {
                "lat": 52.1038935869,
                "lng": -128.1629490284
            },
            {
                "lat": 52.1388705765,
                "lng": -128.1610410523
            },
            {
                "lat": 52.1732364576,
                "lng": -128.1487728807
            },
            {
                "lat": 52.1807745615,
                "lng": -128.1463621338
            },
            {
                "lat": 52.1855087756,
                "lng": -128.1465609295
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 304,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 448,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 52446741.7413,
            "FEATURE_LENGTH_M": 40268.504,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.14277590835313,
            "lng": -128.19249872193436
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1765021796,
                "lng": -128.4476773198
            },
            {
                "lat": 52.1702607746,
                "lng": -128.457566167
            },
            {
                "lat": 52.1609116352,
                "lng": -128.4774321507
            },
            {
                "lat": 52.1577801354,
                "lng": -128.4840846802
            },
            {
                "lat": 52.1517254988,
                "lng": -128.485854425
            },
            {
                "lat": 52.1484261352,
                "lng": -128.4847571622
            },
            {
                "lat": 52.1476516427,
                "lng": -128.4846194083
            },
            {
                "lat": 52.1157876583,
                "lng": -128.4728242686
            },
            {
                "lat": 52.1232033905,
                "lng": -128.4463958627
            },
            {
                "lat": 52.12339804,
                "lng": -128.4463958596
            },
            {
                "lat": 52.1238365894,
                "lng": -128.4461360478
            },
            {
                "lat": 52.1241838299,
                "lng": -128.4463203082
            },
            {
                "lat": 52.1264154974,
                "lng": -128.4475248822
            },
            {
                "lat": 52.1310999586,
                "lng": -128.4460908578
            },
            {
                "lat": 52.1345821329,
                "lng": -128.443817309
            },
            {
                "lat": 52.1408079614,
                "lng": -128.4342806846
            },
            {
                "lat": 52.1467592392,
                "lng": -128.4187770282
            },
            {
                "lat": 52.1562689259,
                "lng": -128.4076387036
            },
            {
                "lat": 52.1570665437,
                "lng": -128.4077461829
            },
            {
                "lat": 52.1615330317,
                "lng": -128.4084475248
            },
            {
                "lat": 52.1622850556,
                "lng": -128.4117885519
            },
            {
                "lat": 52.1707800328,
                "lng": -128.4286041784
            },
            {
                "lat": 52.1765021796,
                "lng": -128.4476773198
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 305,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 20,
            "MANAGEMENT_AREA_NAME": "Subarea 7-20",
            "LABEL": "7-20",
            "FISHERY_AREA_ID": 2455,
            "OBJECTID": 449,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 20168050.7314,
            "FEATURE_LENGTH_M": 18635.309,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.14729426384348,
            "lng": -128.44706334275216
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.9447516203,
                "lng": -128.1408693099
            },
            {
                "lat": 51.9435621463,
                "lng": -128.1294399889
            },
            {
                "lat": 51.9397537128,
                "lng": -128.1244498179
            },
            {
                "lat": 51.9254757392,
                "lng": -128.1075599837
            },
            {
                "lat": 51.906204325,
                "lng": -128.1018366267
            },
            {
                "lat": 51.9064405741,
                "lng": -128.1182561393
            },
            {
                "lat": 51.8988268876,
                "lng": -128.136108286
            },
            {
                "lat": 51.8952566423,
                "lng": -128.1489570683
            },
            {
                "lat": 51.8895498215,
                "lng": -128.1587063266
            },
            {
                "lat": 51.8824079944,
                "lng": -128.1677558869
            },
            {
                "lat": 51.8733670618,
                "lng": -128.1696625744
            },
            {
                "lat": 51.864799786,
                "lng": -128.1672817044
            },
            {
                "lat": 51.8548090158,
                "lng": -128.1684723178
            },
            {
                "lat": 51.8462221459,
                "lng": -128.1689604242
            },
            {
                "lat": 51.8217088267,
                "lng": -128.1434482172
            },
            {
                "lat": 51.8218112917,
                "lng": -128.1431888914
            },
            {
                "lat": 51.821948796,
                "lng": -128.1429757893
            },
            {
                "lat": 51.8220370842,
                "lng": -128.1423641874
            },
            {
                "lat": 51.8222537317,
                "lng": -128.1419987063
            },
            {
                "lat": 51.8228800526,
                "lng": -128.1412653737
            },
            {
                "lat": 51.824516685,
                "lng": -128.140655103
            },
            {
                "lat": 51.8249021411,
                "lng": -128.1404423213
            },
            {
                "lat": 51.825073294,
                "lng": -128.1392977916
            },
            {
                "lat": 51.8252871271,
                "lng": -128.1368570761
            },
            {
                "lat": 51.8237497131,
                "lng": -128.1357880181
            },
            {
                "lat": 51.8212312345,
                "lng": -128.1351934725
            },
            {
                "lat": 51.8206985729,
                "lng": -128.1351474641
            },
            {
                "lat": 51.8194163637,
                "lng": -128.1304320277
            },
            {
                "lat": 51.8157842325,
                "lng": -128.1237332808
            },
            {
                "lat": 51.8184011531,
                "lng": -128.1182557994
            },
            {
                "lat": 51.8243525754,
                "lng": -128.1139836476
            },
            {
                "lat": 51.8279193347,
                "lng": -128.1075591158
            },
            {
                "lat": 51.8379138941,
                "lng": -128.095657506
            },
            {
                "lat": 51.848144143,
                "lng": -128.1008914132
            },
            {
                "lat": 51.8590199791,
                "lng": -128.1036995132
            },
            {
                "lat": 51.8823774254,
                "lng": -128.10374472
            },
            {
                "lat": 51.8919260186,
                "lng": -128.0920871961
            },
            {
                "lat": 51.9062043608,
                "lng": -128.0806738943
            },
            {
                "lat": 51.9181017775,
                "lng": -128.0759119502
            },
            {
                "lat": 51.9223826218,
                "lng": -128.060440353
            },
            {
                "lat": 51.9235721773,
                "lng": -128.0387885727
            },
            {
                "lat": 51.9295235875,
                "lng": -128.0235603647
            },
            {
                "lat": 51.9430843424,
                "lng": -128.0161902645
            },
            {
                "lat": 51.9592665397,
                "lng": -128.0233153485
            },
            {
                "lat": 51.9630740264,
                "lng": -128.0340274174
            },
            {
                "lat": 51.9647366924,
                "lng": -128.0771028544
            },
            {
                "lat": 51.965927831,
                "lng": -128.1199342758
            },
            {
                "lat": 51.9604572725,
                "lng": -128.1361084856
            },
            {
                "lat": 51.9447516203,
                "lng": -128.1408693099
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 318,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 28,
            "MANAGEMENT_AREA_NAME": "Subarea 7-28",
            "LABEL": "7-28",
            "FISHERY_AREA_ID": 2462,
            "OBJECTID": 450,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 75500185.6627,
            "FEATURE_LENGTH_M": 50346.9586,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.87746661209797,
            "lng": -128.1172225750571
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1866302317,
                "lng": -127.4725807608
            },
            {
                "lat": 52.1584589588,
                "lng": -127.5273063284
            },
            {
                "lat": 52.1524270679,
                "lng": -127.515190352
            },
            {
                "lat": 52.1394389502,
                "lng": -127.5167842242
            },
            {
                "lat": 52.1226423165,
                "lng": -127.5322875297
            },
            {
                "lat": 52.1137774928,
                "lng": -127.551064498
            },
            {
                "lat": 52.1132580728,
                "lng": -127.5750503764
            },
            {
                "lat": 52.1151927557,
                "lng": -127.5860978948
            },
            {
                "lat": 52.1040113563,
                "lng": -127.601287804
            },
            {
                "lat": 52.0934406063,
                "lng": -127.6016465439
            },
            {
                "lat": 52.0605883365,
                "lng": -127.618850742
            },
            {
                "lat": 52.0329512276,
                "lng": -127.624069275
            },
            {
                "lat": 52.0255086883,
                "lng": -127.6139142561
            },
            {
                "lat": 52.029414666,
                "lng": -127.599396438
            },
            {
                "lat": 52.0450515129,
                "lng": -127.5753857408
            },
            {
                "lat": 52.0601239709,
                "lng": -127.55863908
            },
            {
                "lat": 52.0668260865,
                "lng": -127.5418862184
            },
            {
                "lat": 52.055099157,
                "lng": -127.4821471484
            },
            {
                "lat": 52.0584487688,
                "lng": -127.4352496303
            },
            {
                "lat": 52.0712927724,
                "lng": -127.3794178566
            },
            {
                "lat": 52.0746418783,
                "lng": -127.3693616487
            },
            {
                "lat": 52.093623472,
                "lng": -127.3487087268
            },
            {
                "lat": 52.1070255323,
                "lng": -127.3498225583
            },
            {
                "lat": 52.1248891921,
                "lng": -127.3682474032
            },
            {
                "lat": 52.1427575275,
                "lng": -127.4000702862
            },
            {
                "lat": 52.1689987555,
                "lng": -127.4324562483
            },
            {
                "lat": 52.1805263125,
                "lng": -127.4502865961
            },
            {
                "lat": 52.1825565068,
                "lng": -127.4652332688
            },
            {
                "lat": 52.1850934843,
                "lng": -127.4708094993
            },
            {
                "lat": 52.1866302317,
                "lng": -127.4725807608
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 306,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 8-14",
            "LABEL": "8-14",
            "FISHERY_AREA_ID": 2480,
            "OBJECTID": 451,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 168529831.0532,
            "FEATURE_LENGTH_M": 60218.5112,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.10837752963001,
            "lng": -127.50119432314332
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1825565068,
                "lng": -127.4652332688
            },
            {
                "lat": 52.1866302317,
                "lng": -127.4725807608
            },
            {
                "lat": 52.1850934843,
                "lng": -127.4708094993
            },
            {
                "lat": 52.1825565068,
                "lng": -127.4652332688
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 307,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 452,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 15512.0537,
            "FEATURE_LENGTH_M": 1360.5502,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.1842091824,
            "lng": -127.468464199425
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.0834579941,
                "lng": -130.9360800467
            },
            {
                "lat": 52.0835759022,
                "lng": -130.9362793648
            },
            {
                "lat": 52.083506814,
                "lng": -130.9364619733
            },
            {
                "lat": 52.0831752976,
                "lng": -130.9369196411
            },
            {
                "lat": 52.083114823,
                "lng": -130.9377140195
            },
            {
                "lat": 52.0834118338,
                "lng": -130.941192797
            },
            {
                "lat": 52.0829888777,
                "lng": -130.9431912438
            },
            {
                "lat": 52.0813219887,
                "lng": -130.9508057201
            },
            {
                "lat": 52.0789411698,
                "lng": -130.9608002433
            },
            {
                "lat": 52.0775152092,
                "lng": -130.9774472183
            },
            {
                "lat": 52.0784686002,
                "lng": -130.994354503
            },
            {
                "lat": 52.0787048347,
                "lng": -131.0143427601
            },
            {
                "lat": 52.078468614,
                "lng": -131.0283814952
            },
            {
                "lat": 52.0803686127,
                "lng": -131.0455019578
            },
            {
                "lat": 52.0844148192,
                "lng": -131.059311138
            },
            {
                "lat": 52.0829890759,
                "lng": -131.0678708727
            },
            {
                "lat": 52.0803684588,
                "lng": -131.0745392874
            },
            {
                "lat": 52.0725174224,
                "lng": -131.0785817724
            },
            {
                "lat": 52.0637127683,
                "lng": -131.0773919707
            },
            {
                "lat": 52.0551493502,
                "lng": -131.0719154541
            },
            {
                "lat": 52.0427746514,
                "lng": -131.0631104641
            },
            {
                "lat": 52.0320666247,
                "lng": -131.0609746315
            },
            {
                "lat": 52.0215949992,
                "lng": -131.0612177581
            },
            {
                "lat": 52.0116042296,
                "lng": -131.0574041606
            },
            {
                "lat": 52.0023238767,
                "lng": -131.0464634509
            },
            {
                "lat": 51.9954225509,
                "lng": -131.0357519655
            },
            {
                "lat": 51.9823342316,
                "lng": -131.0338440201
            },
            {
                "lat": 51.9778134776,
                "lng": -131.0357517173
            },
            {
                "lat": 51.9744828534,
                "lng": -131.0436096947
            },
            {
                "lat": 51.9697264355,
                "lng": -131.0462185574
            },
            {
                "lat": 51.9623488189,
                "lng": -131.0440822408
            },
            {
                "lat": 51.9594955289,
                "lng": -131.0412290805
            },
            {
                "lat": 51.9571149763,
                "lng": -131.0374137907
            },
            {
                "lat": 51.9561613078,
                "lng": -131.0336151955
            },
            {
                "lat": 51.954258295,
                "lng": -131.0300439504
            },
            {
                "lat": 51.9525907927,
                "lng": -131.0276643807
            },
            {
                "lat": 51.950451569,
                "lng": -131.0248114518
            },
            {
                "lat": 51.9475981678,
                "lng": -131.0233772752
            },
            {
                "lat": 51.9433056392,
                "lng": -131.0251310618
            },
            {
                "lat": 51.9419135754,
                "lng": -131.0244445287
            },
            {
                "lat": 51.939277781,
                "lng": -131.0208889291
            },
            {
                "lat": 51.9358899611,
                "lng": -131.0160824804
            },
            {
                "lat": 52.0834579941,
                "lng": -130.9360800467
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 308,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 19,
            "MANAGEMENT_AREA_NAME": "Subarea 2-19",
            "LABEL": "2-19",
            "FISHERY_AREA_ID": 1910,
            "OBJECTID": 453,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 81936322.2995,
            "FEATURE_LENGTH_M": 45279.5124,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.024887925681405,
            "lng": -131.01716963515813
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1484868564,
                "lng": -128.4054572828
            },
            {
                "lat": 52.150074402,
                "lng": -128.4033208575
            },
            {
                "lat": 52.1535880615,
                "lng": -128.4030459661
            },
            {
                "lat": 52.154888032,
                "lng": -128.4076087171
            },
            {
                "lat": 52.1562689259,
                "lng": -128.4076387036
            },
            {
                "lat": 52.1467592392,
                "lng": -128.4187770282
            },
            {
                "lat": 52.1408079614,
                "lng": -128.4342806846
            },
            {
                "lat": 52.1345821329,
                "lng": -128.443817309
            },
            {
                "lat": 52.1310999586,
                "lng": -128.4460908578
            },
            {
                "lat": 52.1264154974,
                "lng": -128.4475248822
            },
            {
                "lat": 52.130069559,
                "lng": -128.4430698991
            },
            {
                "lat": 52.1305844879,
                "lng": -128.4385072488
            },
            {
                "lat": 52.1293025477,
                "lng": -128.4333340186
            },
            {
                "lat": 52.131103663,
                "lng": -128.4294738392
            },
            {
                "lat": 52.1336101526,
                "lng": -128.4287412254
            },
            {
                "lat": 52.1367533082,
                "lng": -128.4240568105
            },
            {
                "lat": 52.1393770991,
                "lng": -128.421660996
            },
            {
                "lat": 52.1423493267,
                "lng": -128.4150237413
            },
            {
                "lat": 52.1438902571,
                "lng": -128.4108588818
            },
            {
                "lat": 52.1484868564,
                "lng": -128.4054572828
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 309,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 454,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2604533.8758,
            "FEATURE_LENGTH_M": 9733.7151,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.140424916250005,
            "lng": -128.42338731162
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1521688138,
                "lng": -128.345535801
            },
            {
                "lat": 52.1494596836,
                "lng": -128.3492888308
            },
            {
                "lat": 52.1467940776,
                "lng": -128.350677602
            },
            {
                "lat": 52.1400722095,
                "lng": -128.3525243939
            },
            {
                "lat": 52.130451605,
                "lng": -128.3568118839
            },
            {
                "lat": 52.1195599127,
                "lng": -128.3594979218
            },
            {
                "lat": 52.1276472628,
                "lng": -128.3521269437
            },
            {
                "lat": 52.1343117212,
                "lng": -128.3485571648
            },
            {
                "lat": 52.1397622441,
                "lng": -128.3480222425
            },
            {
                "lat": 52.1446264538,
                "lng": -128.3458106638
            },
            {
                "lat": 52.1499171591,
                "lng": -128.3450771326
            },
            {
                "lat": 52.1521688138,
                "lng": -128.345535801
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 310,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 455,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1121029.4117,
            "FEATURE_LENGTH_M": 7639.6879,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.14057832975001,
            "lng": -128.34995553181668
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1400722095,
                "lng": -128.3525243939
            },
            {
                "lat": 52.1414144516,
                "lng": -128.3554682749
            },
            {
                "lat": 52.1414188449,
                "lng": -128.4073643572
            },
            {
                "lat": 52.1417727894,
                "lng": -128.407363869
            },
            {
                "lat": 52.1424063195,
                "lng": -128.4064636304
            },
            {
                "lat": 52.143489995,
                "lng": -128.4045256741
            },
            {
                "lat": 52.1441767156,
                "lng": -128.4040063368
            },
            {
                "lat": 52.1461759299,
                "lng": -128.4047551604
            },
            {
                "lat": 52.1484868564,
                "lng": -128.4054572828
            },
            {
                "lat": 52.1438902571,
                "lng": -128.4108588818
            },
            {
                "lat": 52.1423493267,
                "lng": -128.4150237413
            },
            {
                "lat": 52.1393770991,
                "lng": -128.421660996
            },
            {
                "lat": 52.1367533082,
                "lng": -128.4240568105
            },
            {
                "lat": 52.1336101526,
                "lng": -128.4287412254
            },
            {
                "lat": 52.131103663,
                "lng": -128.4294738392
            },
            {
                "lat": 52.1293025477,
                "lng": -128.4333340186
            },
            {
                "lat": 52.1305844879,
                "lng": -128.4385072488
            },
            {
                "lat": 52.130069559,
                "lng": -128.4430698991
            },
            {
                "lat": 52.1264154974,
                "lng": -128.4475248822
            },
            {
                "lat": 52.1241838299,
                "lng": -128.4463203082
            },
            {
                "lat": 52.1238365894,
                "lng": -128.4461360478
            },
            {
                "lat": 52.12339804,
                "lng": -128.4463958596
            },
            {
                "lat": 52.1232033905,
                "lng": -128.4463958627
            },
            {
                "lat": 52.1157876583,
                "lng": -128.4728242686
            },
            {
                "lat": 52.0782475734,
                "lng": -128.4465478939
            },
            {
                "lat": 52.0969469614,
                "lng": -128.3984990591
            },
            {
                "lat": 52.1060603709,
                "lng": -128.3794863871
            },
            {
                "lat": 52.1080286436,
                "lng": -128.3776097554
            },
            {
                "lat": 52.1121939301,
                "lng": -128.3736114835
            },
            {
                "lat": 52.1143224274,
                "lng": -128.3699640714
            },
            {
                "lat": 52.1176565946,
                "lng": -128.3630679805
            },
            {
                "lat": 52.1195599127,
                "lng": -128.3594979218
            },
            {
                "lat": 52.130451605,
                "lng": -128.3568118839
            },
            {
                "lat": 52.1400722095,
                "lng": -128.3525243939
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 311,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 19,
            "MANAGEMENT_AREA_NAME": "Subarea 7-19",
            "LABEL": "7-19",
            "FISHERY_AREA_ID": 2454,
            "OBJECTID": 456,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 33103535.8482,
            "FEATURE_LENGTH_M": 25703.7563,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.128435874917635,
            "lng": -128.40811393234708
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1293790992,
                "lng": -127.8517767614
            },
            {
                "lat": 52.1290326605,
                "lng": -127.8544239645
            },
            {
                "lat": 52.1220356756,
                "lng": -127.8571779827
            },
            {
                "lat": 52.112263049,
                "lng": -127.8596265605
            },
            {
                "lat": 52.1032135913,
                "lng": -127.8652417833
            },
            {
                "lat": 52.0954547565,
                "lng": -127.8824394901
            },
            {
                "lat": 52.0935058154,
                "lng": -127.8786623073
            },
            {
                "lat": 52.0893746316,
                "lng": -127.8651656069
            },
            {
                "lat": 52.0874442643,
                "lng": -127.8456111997
            },
            {
                "lat": 52.0893744584,
                "lng": -127.7971417657
            },
            {
                "lat": 52.0943303375,
                "lng": -127.7638161255
            },
            {
                "lat": 52.097084429,
                "lng": -127.7456356772
            },
            {
                "lat": 52.1097526766,
                "lng": -127.7401269846
            },
            {
                "lat": 52.114712093,
                "lng": -127.7434394557
            },
            {
                "lat": 52.119392225,
                "lng": -127.7676697513
            },
            {
                "lat": 52.1191175456,
                "lng": -127.7839199613
            },
            {
                "lat": 52.1188389512,
                "lng": -127.827430665
            },
            {
                "lat": 52.1268276605,
                "lng": -127.8293605527
            },
            {
                "lat": 52.1309591805,
                "lng": -127.8398290737
            },
            {
                "lat": 52.1293790992,
                "lng": -127.8517767614
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 312,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 8-6",
            "LABEL": "8-6",
            "FISHERY_AREA_ID": 2472,
            "OBJECTID": 457,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 28790990.9339,
            "FEATURE_LENGTH_M": 24771.3782,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.110573609994994,
            "lng": -127.82251362152499
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.1060603709,
                "lng": -128.3794863871
            },
            {
                "lat": 52.0969469614,
                "lng": -128.3984990591
            },
            {
                "lat": 52.0782475734,
                "lng": -128.4465478939
            },
            {
                "lat": 52.0042035922,
                "lng": -128.4213102841
            },
            {
                "lat": 52.0098115296,
                "lng": -128.1835319257
            },
            {
                "lat": 52.0108333683,
                "lng": -128.1838067431
            },
            {
                "lat": 52.0118102304,
                "lng": -128.1851505236
            },
            {
                "lat": 52.0131151543,
                "lng": -128.1862024278
            },
            {
                "lat": 52.0165636266,
                "lng": -128.186767219
            },
            {
                "lat": 52.0202635116,
                "lng": -128.1861729389
            },
            {
                "lat": 52.0237394696,
                "lng": -128.1898651329
            },
            {
                "lat": 52.0295832795,
                "lng": -128.1995092996
            },
            {
                "lat": 52.0510595756,
                "lng": -128.2168585647
            },
            {
                "lat": 52.0558315295,
                "lng": -128.2274020554
            },
            {
                "lat": 52.0649680701,
                "lng": -128.2332915184
            },
            {
                "lat": 52.0734256791,
                "lng": -128.240158164
            },
            {
                "lat": 52.0797272099,
                "lng": -128.2422186315
            },
            {
                "lat": 52.0828513992,
                "lng": -128.242538028
            },
            {
                "lat": 52.082848174,
                "lng": -128.2512816058
            },
            {
                "lat": 52.084274226,
                "lng": -128.2619321237
            },
            {
                "lat": 52.0746082039,
                "lng": -128.2710564162
            },
            {
                "lat": 52.0715789012,
                "lng": -128.2805935555
            },
            {
                "lat": 52.070895838,
                "lng": -128.2846374245
            },
            {
                "lat": 52.0718070704,
                "lng": -128.2884681961
            },
            {
                "lat": 52.0760913792,
                "lng": -128.2909245445
            },
            {
                "lat": 52.0840718855,
                "lng": -128.2955316043
            },
            {
                "lat": 52.088787608,
                "lng": -128.2994390913
            },
            {
                "lat": 52.0893209726,
                "lng": -128.3003988158
            },
            {
                "lat": 52.0895838262,
                "lng": -128.3015901167
            },
            {
                "lat": 52.0895768153,
                "lng": -128.3240358713
            },
            {
                "lat": 52.089230167,
                "lng": -128.3251489922
            },
            {
                "lat": 52.0894311847,
                "lng": -128.3275300745
            },
            {
                "lat": 52.0907246585,
                "lng": -128.3276972142
            },
            {
                "lat": 52.0957560338,
                "lng": -128.3292844055
            },
            {
                "lat": 52.1040195341,
                "lng": -128.3338317779
            },
            {
                "lat": 52.1112676214,
                "lng": -128.3394008142
            },
            {
                "lat": 52.112952961,
                "lng": -128.3466485834
            },
            {
                "lat": 52.1089102435,
                "lng": -128.3540654913
            },
            {
                "lat": 52.1017154569,
                "lng": -128.3628384548
            },
            {
                "lat": 52.1004790776,
                "lng": -128.3643493589
            },
            {
                "lat": 52.1003535964,
                "lng": -128.3712002767
            },
            {
                "lat": 52.1034581476,
                "lng": -128.3748630042
            },
            {
                "lat": 52.105923045,
                "lng": -128.3762973458
            },
            {
                "lat": 52.1060603709,
                "lng": -128.3794863871
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 313,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 18,
            "MANAGEMENT_AREA_NAME": "Subarea 7-18",
            "LABEL": "7-18",
            "FISHERY_AREA_ID": 2453,
            "OBJECTID": 458,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 141567772.299,
            "FEATURE_LENGTH_M": 53944.1656,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.073244752952284,
            "lng": -128.2934510987091
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.0689924496,
                "lng": -127.8764577771
            },
            {
                "lat": 52.0667911686,
                "lng": -127.8825149413
            },
            {
                "lat": 52.0652196376,
                "lng": -127.8856429942
            },
            {
                "lat": 52.0661319632,
                "lng": -127.8893510425
            },
            {
                "lat": 52.0638202574,
                "lng": -127.9509276153
            },
            {
                "lat": 52.0597419703,
                "lng": -127.9563442884
            },
            {
                "lat": 52.0472481828,
                "lng": -127.9599612316
            },
            {
                "lat": 52.0222199561,
                "lng": -127.9736179684
            },
            {
                "lat": 52.009354037,
                "lng": -127.9687119087
            },
            {
                "lat": 51.9841301038,
                "lng": -127.9689480621
            },
            {
                "lat": 51.9539111319,
                "lng": -127.9832302915
            },
            {
                "lat": 51.9241671318,
                "lng": -127.9951244689
            },
            {
                "lat": 51.9134594029,
                "lng": -128.00393699
            },
            {
                "lat": 51.9113193069,
                "lng": -128.0165404532
            },
            {
                "lat": 51.9072763864,
                "lng": -128.0293891674
            },
            {
                "lat": 51.9041751371,
                "lng": -128.0379947921
            },
            {
                "lat": 51.8989979971,
                "lng": -128.0408178667
            },
            {
                "lat": 51.8929979746,
                "lng": -128.028198206
            },
            {
                "lat": 51.88419362,
                "lng": -128.018448205
            },
            {
                "lat": 51.8653949517,
                "lng": -128.0241552327
            },
            {
                "lat": 51.8485031754,
                "lng": -128.0336763753
            },
            {
                "lat": 51.8449324058,
                "lng": -128.0498501648
            },
            {
                "lat": 51.8449324188,
                "lng": -128.0660403498
            },
            {
                "lat": 51.8448301487,
                "lng": -128.0703727758
            },
            {
                "lat": 51.84469637,
                "lng": -128.0760343198
            },
            {
                "lat": 51.8375553851,
                "lng": -128.079361784
            },
            {
                "lat": 51.8355368127,
                "lng": -128.0754391486
            },
            {
                "lat": 51.8304176716,
                "lng": -128.0588993811
            },
            {
                "lat": 51.8149534202,
                "lng": -128.0657959442
            },
            {
                "lat": 51.802100745,
                "lng": -128.0681760661
            },
            {
                "lat": 51.7987712252,
                "lng": -128.0793615372
            },
            {
                "lat": 51.792346567,
                "lng": -128.0950626744
            },
            {
                "lat": 51.7906689269,
                "lng": -128.0999142966
            },
            {
                "lat": 51.788685251,
                "lng": -128.1021428846
            },
            {
                "lat": 51.7876129054,
                "lng": -128.1034701855
            },
            {
                "lat": 51.786137151,
                "lng": -128.1023862333
            },
            {
                "lat": 51.7835766919,
                "lng": -128.102385698
            },
            {
                "lat": 51.7789194231,
                "lng": -128.1037446421
            },
            {
                "lat": 51.7734718598,
                "lng": -128.1086269504
            },
            {
                "lat": 51.766720163,
                "lng": -128.1095890604
            },
            {
                "lat": 51.7457235806,
                "lng": -128.1163328303
            },
            {
                "lat": 51.741817534,
                "lng": -128.1171868374
            },
            {
                "lat": 51.7396047046,
                "lng": -128.1177064086
            },
            {
                "lat": 51.7361831141,
                "lng": -128.1161800418
            },
            {
                "lat": 51.7430074848,
                "lng": -128.0348363666
            },
            {
                "lat": 51.7437242809,
                "lng": -128.0349730515
            },
            {
                "lat": 51.7464519385,
                "lng": -128.036361242
            },
            {
                "lat": 51.7481305485,
                "lng": -128.034056953
            },
            {
                "lat": 51.7483403719,
                "lng": -128.0308989535
            },
            {
                "lat": 51.7476267772,
                "lng": -128.0283048608
            },
            {
                "lat": 51.7482563851,
                "lng": -128.0268404069
            },
            {
                "lat": 51.7510681567,
                "lng": -128.0246878184
            },
            {
                "lat": 51.7555999963,
                "lng": -128.0195777124
            },
            {
                "lat": 51.7567744402,
                "lng": -128.0161279503
            },
            {
                "lat": 51.7561880278,
                "lng": -128.0126950191
            },
            {
                "lat": 51.7552228752,
                "lng": -128.0103459041
            },
            {
                "lat": 51.754966587,
                "lng": -128.0094144244
            },
            {
                "lat": 51.7335089055,
                "lng": -127.9966355278
            },
            {
                "lat": 51.8158147179,
                "lng": -127.8973610044
            },
            {
                "lat": 51.8161884018,
                "lng": -127.8826973633
            },
            {
                "lat": 51.8281823682,
                "lng": -127.8756255087
            },
            {
                "lat": 51.8503261259,
                "lng": -127.8507161805
            },
            {
                "lat": 51.8715441332,
                "lng": -127.8338010403
            },
            {
                "lat": 51.8875348872,
                "lng": -127.8448718508
            },
            {
                "lat": 51.8924558027,
                "lng": -127.8617859115
            },
            {
                "lat": 51.8921468153,
                "lng": -127.8737786087
            },
            {
                "lat": 51.8937791747,
                "lng": -127.8745274574
            },
            {
                "lat": 51.9054372504,
                "lng": -127.8711095992
            },
            {
                "lat": 51.9241335285,
                "lng": -127.9056163359
            },
            {
                "lat": 51.9246900379,
                "lng": -127.9047310733
            },
            {
                "lat": 51.9248084003,
                "lng": -127.9038166331
            },
            {
                "lat": 51.9242741676,
                "lng": -127.9025113292
            },
            {
                "lat": 51.9251067126,
                "lng": -127.9005354285
            },
            {
                "lat": 51.9271473158,
                "lng": -127.8979874229
            },
            {
                "lat": 51.9303473516,
                "lng": -127.8992695668
            },
            {
                "lat": 51.9313998684,
                "lng": -127.8982007979
            },
            {
                "lat": 51.9313930888,
                "lng": -127.8947373428
            },
            {
                "lat": 51.9357339302,
                "lng": -127.8945617425
            },
            {
                "lat": 51.9386213383,
                "lng": -127.8936462109
            },
            {
                "lat": 51.9413485727,
                "lng": -127.892783733
            },
            {
                "lat": 51.9516562337,
                "lng": -127.8842320368
            },
            {
                "lat": 51.9588505609,
                "lng": -127.8786157778
            },
            {
                "lat": 51.9641804751,
                "lng": -127.8741523529
            },
            {
                "lat": 51.9705120327,
                "lng": -127.8742981288
            },
            {
                "lat": 51.975261947,
                "lng": -127.8783265165
            },
            {
                "lat": 51.9815982086,
                "lng": -127.8868177956
            },
            {
                "lat": 51.9879300432,
                "lng": -127.8905635321
            },
            {
                "lat": 51.9931108054,
                "lng": -127.8922658722
            },
            {
                "lat": 52.0202409968,
                "lng": -127.8911821013
            },
            {
                "lat": 52.0256464799,
                "lng": -127.8791890823
            },
            {
                "lat": 52.0335661031,
                "lng": -127.8659437913
            },
            {
                "lat": 52.0423474467,
                "lng": -127.8377307497
            },
            {
                "lat": 52.0519896636,
                "lng": -127.827507564
            },
            {
                "lat": 52.0627865004,
                "lng": -127.8252105334
            },
            {
                "lat": 52.0728645865,
                "lng": -127.8358614692
            },
            {
                "lat": 52.0784803815,
                "lng": -127.8502584957
            },
            {
                "lat": 52.0827979498,
                "lng": -127.8636404273
            },
            {
                "lat": 52.0797726394,
                "lng": -127.8686824031
            },
            {
                "lat": 52.0722882586,
                "lng": -127.8708417868
            },
            {
                "lat": 52.0689924496,
                "lng": -127.8764577771
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 314,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 8-4",
            "LABEL": "8-4",
            "FISHERY_AREA_ID": 2470,
            "OBJECTID": 459,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 341070396.143,
            "FEATURE_LENGTH_M": 113593.1724,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.89444394945803,
            "lng": -127.96730256619497
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.0577814587,
                "lng": -128.2024682276
            },
            {
                "lat": 52.0486256821,
                "lng": -128.203002454
            },
            {
                "lat": 52.0458608462,
                "lng": -128.2075796865
            },
            {
                "lat": 52.0510595756,
                "lng": -128.2168585647
            },
            {
                "lat": 52.0295832795,
                "lng": -128.1995092996
            },
            {
                "lat": 52.0237394696,
                "lng": -128.1898651329
            },
            {
                "lat": 52.0313191213,
                "lng": -128.1898351472
            },
            {
                "lat": 52.0463102586,
                "lng": -128.1950675386
            },
            {
                "lat": 52.0577814587,
                "lng": -128.2024682276
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 315,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 460,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2481843.2979,
            "FEATURE_LENGTH_M": 9851.2806,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.04356235003333,
            "lng": -128.2007393643
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.0597419703,
                "lng": -127.9563442884
            },
            {
                "lat": 52.060718523,
                "lng": -127.9632641162
            },
            {
                "lat": 52.0331005131,
                "lng": -128.0732426282
            },
            {
                "lat": 52.0382658129,
                "lng": -128.0791172288
            },
            {
                "lat": 52.0430226593,
                "lng": -128.101959112
            },
            {
                "lat": 52.0406454188,
                "lng": -128.1219485967
            },
            {
                "lat": 52.021846726,
                "lng": -128.1457516562
            },
            {
                "lat": 52.0120432931,
                "lng": -128.1571196017
            },
            {
                "lat": 52.0106623032,
                "lng": -128.1587062177
            },
            {
                "lat": 51.9830591324,
                "lng": -128.1529999948
            },
            {
                "lat": 51.9447516203,
                "lng": -128.1408693099
            },
            {
                "lat": 51.9604572725,
                "lng": -128.1361084856
            },
            {
                "lat": 51.965927831,
                "lng": -128.1199342758
            },
            {
                "lat": 51.9647366924,
                "lng": -128.0771028544
            },
            {
                "lat": 51.9630740264,
                "lng": -128.0340274174
            },
            {
                "lat": 51.9592665397,
                "lng": -128.0233153485
            },
            {
                "lat": 51.9430843424,
                "lng": -128.0161902645
            },
            {
                "lat": 51.9295235875,
                "lng": -128.0235603647
            },
            {
                "lat": 51.9235721773,
                "lng": -128.0387885727
            },
            {
                "lat": 51.9223826218,
                "lng": -128.060440353
            },
            {
                "lat": 51.9181017775,
                "lng": -128.0759119502
            },
            {
                "lat": 51.9062043608,
                "lng": -128.0806738943
            },
            {
                "lat": 51.8919260186,
                "lng": -128.0920871961
            },
            {
                "lat": 51.8823774254,
                "lng": -128.10374472
            },
            {
                "lat": 51.8590199791,
                "lng": -128.1036995132
            },
            {
                "lat": 51.848144143,
                "lng": -128.1008914132
            },
            {
                "lat": 51.8379138941,
                "lng": -128.095657506
            },
            {
                "lat": 51.8279193347,
                "lng": -128.1075591158
            },
            {
                "lat": 51.8255387196,
                "lng": -128.1035149994
            },
            {
                "lat": 51.8212580067,
                "lng": -128.0975641158
            },
            {
                "lat": 51.8112642605,
                "lng": -128.0870969978
            },
            {
                "lat": 51.8053127809,
                "lng": -128.0847161375
            },
            {
                "lat": 51.7981755534,
                "lng": -128.0932763749
            },
            {
                "lat": 51.7948462019,
                "lng": -128.099699989
            },
            {
                "lat": 51.7906689269,
                "lng": -128.0999142966
            },
            {
                "lat": 51.792346567,
                "lng": -128.0950626744
            },
            {
                "lat": 51.7987712252,
                "lng": -128.0793615372
            },
            {
                "lat": 51.802100745,
                "lng": -128.0681760661
            },
            {
                "lat": 51.8149534202,
                "lng": -128.0657959442
            },
            {
                "lat": 51.8304176716,
                "lng": -128.0588993811
            },
            {
                "lat": 51.8355368127,
                "lng": -128.0754391486
            },
            {
                "lat": 51.8375553851,
                "lng": -128.079361784
            },
            {
                "lat": 51.84469637,
                "lng": -128.0760343198
            },
            {
                "lat": 51.8448301487,
                "lng": -128.0703727758
            },
            {
                "lat": 51.8449324188,
                "lng": -128.0660403498
            },
            {
                "lat": 51.8449324058,
                "lng": -128.0498501648
            },
            {
                "lat": 51.8485031754,
                "lng": -128.0336763753
            },
            {
                "lat": 51.8653949517,
                "lng": -128.0241552327
            },
            {
                "lat": 51.88419362,
                "lng": -128.018448205
            },
            {
                "lat": 51.8929979746,
                "lng": -128.028198206
            },
            {
                "lat": 51.8989979971,
                "lng": -128.0408178667
            },
            {
                "lat": 51.9041751371,
                "lng": -128.0379947921
            },
            {
                "lat": 51.9072763864,
                "lng": -128.0293891674
            },
            {
                "lat": 51.9113193069,
                "lng": -128.0165404532
            },
            {
                "lat": 51.9134594029,
                "lng": -128.00393699
            },
            {
                "lat": 51.9241671318,
                "lng": -127.9951244689
            },
            {
                "lat": 51.9539111319,
                "lng": -127.9832302915
            },
            {
                "lat": 51.9841301038,
                "lng": -127.9689480621
            },
            {
                "lat": 52.009354037,
                "lng": -127.9687119087
            },
            {
                "lat": 52.0222199561,
                "lng": -127.9736179684
            },
            {
                "lat": 52.0472481828,
                "lng": -127.9599612316
            },
            {
                "lat": 52.0597419703,
                "lng": -127.9563442884
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 316,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 461,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 173391942.5073,
            "FEATURE_LENGTH_M": 94799.7303,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.91155996907098,
            "lng": -128.06177884775485
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.0106623032,
                "lng": -128.1587062177
            },
            {
                "lat": 52.0098148876,
                "lng": -128.1660152101
            },
            {
                "lat": 52.0098115296,
                "lng": -128.1835319257
            },
            {
                "lat": 52.0042035922,
                "lng": -128.4213102841
            },
            {
                "lat": 52.0042036523,
                "lng": -128.6017459932
            },
            {
                "lat": 51.8899419736,
                "lng": -128.4933467176
            },
            {
                "lat": 51.8899420533,
                "lng": -128.2556455922
            },
            {
                "lat": 51.8896067356,
                "lng": -128.2505791959
            },
            {
                "lat": 51.8900827377,
                "lng": -128.2489622678
            },
            {
                "lat": 51.8899190491,
                "lng": -128.246750034
            },
            {
                "lat": 51.8872982186,
                "lng": -128.2393653919
            },
            {
                "lat": 51.8873785067,
                "lng": -128.2373196256
            },
            {
                "lat": 51.8877874033,
                "lng": -128.2355954828
            },
            {
                "lat": 51.8873785509,
                "lng": -128.2342070242
            },
            {
                "lat": 51.8860666009,
                "lng": -128.2319180665
            },
            {
                "lat": 51.8832821531,
                "lng": -128.2275698318
            },
            {
                "lat": 51.8816450165,
                "lng": -128.2212682831
            },
            {
                "lat": 51.8820005053,
                "lng": -128.2164611644
            },
            {
                "lat": 51.8831179372,
                "lng": -128.201278701
            },
            {
                "lat": 51.8893085984,
                "lng": -128.1760864118
            },
            {
                "lat": 51.898827028,
                "lng": -128.1570431539
            },
            {
                "lat": 51.9178620712,
                "lng": -128.1392058348
            },
            {
                "lat": 51.9314266935,
                "lng": -128.1251678158
            },
            {
                "lat": 51.9397537128,
                "lng": -128.1244498179
            },
            {
                "lat": 51.9435621463,
                "lng": -128.1294399889
            },
            {
                "lat": 51.9447516203,
                "lng": -128.1408693099
            },
            {
                "lat": 51.9830591324,
                "lng": -128.1529999948
            },
            {
                "lat": 52.0106623032,
                "lng": -128.1587062177
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 317,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 25,
            "MANAGEMENT_AREA_NAME": "Subarea 7-25",
            "LABEL": "7-25",
            "FISHERY_AREA_ID": 2459,
            "OBJECTID": 462,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 359862967.0803,
            "FEATURE_LENGTH_M": 83072.6851,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.92547702545714,
            "lng": -128.2276980555393
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.9397537128,
                "lng": -128.1244498179
            },
            {
                "lat": 51.9314266935,
                "lng": -128.1251678158
            },
            {
                "lat": 51.9178620712,
                "lng": -128.1392058348
            },
            {
                "lat": 51.898827028,
                "lng": -128.1570431539
            },
            {
                "lat": 51.8893085984,
                "lng": -128.1760864118
            },
            {
                "lat": 51.8831179372,
                "lng": -128.201278701
            },
            {
                "lat": 51.8820005053,
                "lng": -128.2164611644
            },
            {
                "lat": 51.8780899748,
                "lng": -128.212935994
            },
            {
                "lat": 51.8680453702,
                "lng": -128.1945646103
            },
            {
                "lat": 51.8710361877,
                "lng": -128.1860047824
            },
            {
                "lat": 51.8703961568,
                "lng": -128.1751099753
            },
            {
                "lat": 51.864799786,
                "lng": -128.1672817044
            },
            {
                "lat": 51.8733670618,
                "lng": -128.1696625744
            },
            {
                "lat": 51.8824079944,
                "lng": -128.1677558869
            },
            {
                "lat": 51.8895498215,
                "lng": -128.1587063266
            },
            {
                "lat": 51.8952566423,
                "lng": -128.1489570683
            },
            {
                "lat": 51.8988268876,
                "lng": -128.136108286
            },
            {
                "lat": 51.9064405741,
                "lng": -128.1182561393
            },
            {
                "lat": 51.906204325,
                "lng": -128.1018366267
            },
            {
                "lat": 51.9254757392,
                "lng": -128.1075599837
            },
            {
                "lat": 51.9397537128,
                "lng": -128.1244498179
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 319,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 463,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 14490598.823,
            "FEATURE_LENGTH_M": 25871.9529,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.89580698955237,
            "lng": -128.15756584170472
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.8899420533,
                "lng": -128.2556455922
            },
            {
                "lat": 51.8899419736,
                "lng": -128.4933467176
            },
            {
                "lat": 51.8515018261,
                "lng": -128.4568784606
            },
            {
                "lat": 51.7361833024,
                "lng": -128.3418585209
            },
            {
                "lat": 51.7361831141,
                "lng": -128.1161800418
            },
            {
                "lat": 51.781363895,
                "lng": -128.2543942005
            },
            {
                "lat": 51.8481596176,
                "lng": -128.2740320449
            },
            {
                "lat": 51.8899420533,
                "lng": -128.2556455922
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 320,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 26,
            "MANAGEMENT_AREA_NAME": "Subarea 7-26",
            "LABEL": "7-26",
            "FISHERY_AREA_ID": 2460,
            "OBJECTID": 464,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 208927343.0091,
            "FEATURE_LENGTH_M": 75130.1396,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.827902229425,
            "lng": -128.3059976463375
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.8820005053,
                "lng": -128.2164611644
            },
            {
                "lat": 51.8816450165,
                "lng": -128.2212682831
            },
            {
                "lat": 51.8832821531,
                "lng": -128.2275698318
            },
            {
                "lat": 51.8860666009,
                "lng": -128.2319180665
            },
            {
                "lat": 51.8873785509,
                "lng": -128.2342070242
            },
            {
                "lat": 51.8877874033,
                "lng": -128.2355954828
            },
            {
                "lat": 51.8873785067,
                "lng": -128.2373196256
            },
            {
                "lat": 51.8872982186,
                "lng": -128.2393653919
            },
            {
                "lat": 51.8899190491,
                "lng": -128.246750034
            },
            {
                "lat": 51.8900827377,
                "lng": -128.2489622678
            },
            {
                "lat": 51.8896067356,
                "lng": -128.2505791959
            },
            {
                "lat": 51.8899420533,
                "lng": -128.2556455922
            },
            {
                "lat": 51.8481596176,
                "lng": -128.2740320449
            },
            {
                "lat": 51.781363895,
                "lng": -128.2543942005
            },
            {
                "lat": 51.7361831141,
                "lng": -128.1161800418
            },
            {
                "lat": 51.7396047046,
                "lng": -128.1177064086
            },
            {
                "lat": 51.741817534,
                "lng": -128.1171868374
            },
            {
                "lat": 51.7457235806,
                "lng": -128.1163328303
            },
            {
                "lat": 51.766720163,
                "lng": -128.1095890604
            },
            {
                "lat": 51.7734718598,
                "lng": -128.1086269504
            },
            {
                "lat": 51.7789194231,
                "lng": -128.1037446421
            },
            {
                "lat": 51.7835766919,
                "lng": -128.102385698
            },
            {
                "lat": 51.786137151,
                "lng": -128.1023862333
            },
            {
                "lat": 51.7876129054,
                "lng": -128.1034701855
            },
            {
                "lat": 51.788685251,
                "lng": -128.1021428846
            },
            {
                "lat": 51.7906689269,
                "lng": -128.0999142966
            },
            {
                "lat": 51.7948462019,
                "lng": -128.099699989
            },
            {
                "lat": 51.7981755534,
                "lng": -128.0932763749
            },
            {
                "lat": 51.8053127809,
                "lng": -128.0847161375
            },
            {
                "lat": 51.8112642605,
                "lng": -128.0870969978
            },
            {
                "lat": 51.8212580067,
                "lng": -128.0975641158
            },
            {
                "lat": 51.8255387196,
                "lng": -128.1035149994
            },
            {
                "lat": 51.8279193347,
                "lng": -128.1075591158
            },
            {
                "lat": 51.8243525754,
                "lng": -128.1139836476
            },
            {
                "lat": 51.8184011531,
                "lng": -128.1182557994
            },
            {
                "lat": 51.8157842325,
                "lng": -128.1237332808
            },
            {
                "lat": 51.8194163637,
                "lng": -128.1304320277
            },
            {
                "lat": 51.8206985729,
                "lng": -128.1351474641
            },
            {
                "lat": 51.8212312345,
                "lng": -128.1351934725
            },
            {
                "lat": 51.8237497131,
                "lng": -128.1357880181
            },
            {
                "lat": 51.8252871271,
                "lng": -128.1368570761
            },
            {
                "lat": 51.825073294,
                "lng": -128.1392977916
            },
            {
                "lat": 51.8249021411,
                "lng": -128.1404423213
            },
            {
                "lat": 51.824516685,
                "lng": -128.140655103
            },
            {
                "lat": 51.8228800526,
                "lng": -128.1412653737
            },
            {
                "lat": 51.8222537317,
                "lng": -128.1419987063
            },
            {
                "lat": 51.8220370842,
                "lng": -128.1423641874
            },
            {
                "lat": 51.821948796,
                "lng": -128.1429757893
            },
            {
                "lat": 51.8218112917,
                "lng": -128.1431888914
            },
            {
                "lat": 51.8217088267,
                "lng": -128.1434482172
            },
            {
                "lat": 51.8462221459,
                "lng": -128.1689604242
            },
            {
                "lat": 51.8548090158,
                "lng": -128.1684723178
            },
            {
                "lat": 51.864799786,
                "lng": -128.1672817044
            },
            {
                "lat": 51.8703961568,
                "lng": -128.1751099753
            },
            {
                "lat": 51.8710361877,
                "lng": -128.1860047824
            },
            {
                "lat": 51.8680453702,
                "lng": -128.1945646103
            },
            {
                "lat": 51.8780899748,
                "lng": -128.212935994
            },
            {
                "lat": 51.8820005053,
                "lng": -128.2164611644
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 321,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 27,
            "MANAGEMENT_AREA_NAME": "Subarea 7-27",
            "LABEL": "7-27",
            "FISHERY_AREA_ID": 2461,
            "OBJECTID": 465,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 123031726.169,
            "FEATURE_LENGTH_M": 51337.3363,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.829082745250005,
            "lng": -128.15762034732933
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.7608260092,
                "lng": -127.4344180861
            },
            {
                "lat": 51.758620841,
                "lng": -127.4273980237
            },
            {
                "lat": 51.7588955461,
                "lng": -127.4113537671
            },
            {
                "lat": 51.761108734,
                "lng": -127.3925405905
            },
            {
                "lat": 51.7743871845,
                "lng": -127.3706890404
            },
            {
                "lat": 51.8048213921,
                "lng": -127.3518755182
            },
            {
                "lat": 51.8421666386,
                "lng": -127.3435750395
            },
            {
                "lat": 51.877304208,
                "lng": -127.3344499461
            },
            {
                "lat": 51.889754688,
                "lng": -127.3466191821
            },
            {
                "lat": 51.8908617715,
                "lng": -127.3568576243
            },
            {
                "lat": 51.8806233118,
                "lng": -127.3676443306
            },
            {
                "lat": 51.857940607,
                "lng": -127.379540311
            },
            {
                "lat": 51.823635672,
                "lng": -127.3994598316
            },
            {
                "lat": 51.7959672017,
                "lng": -127.4102481223
            },
            {
                "lat": 51.7793696052,
                "lng": -127.4196548231
            },
            {
                "lat": 51.7752267011,
                "lng": -127.4262159147
            },
            {
                "lat": 51.772605695,
                "lng": -127.4278795687
            },
            {
                "lat": 51.7714849596,
                "lng": -127.4306715675
            },
            {
                "lat": 51.7710612465,
                "lng": -127.4309153507
            },
            {
                "lat": 51.7661703711,
                "lng": -127.4380255671
            },
            {
                "lat": 51.7608260092,
                "lng": -127.4344180861
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 322,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 9,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 9-8",
            "LABEL": "9-8",
            "FISHERY_AREA_ID": 2034,
            "OBJECTID": 466,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 45885355.156,
            "FEATURE_LENGTH_M": 35044.2651,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.803507542533346,
            "lng": -127.39687858530476
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.8515018261,
                "lng": -128.4568784606
            },
            {
                "lat": 51.7540318219,
                "lng": -128.8000021087
            },
            {
                "lat": 51.7361833024,
                "lng": -128.3418585209
            },
            {
                "lat": 51.8515018261,
                "lng": -128.4568784606
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 323,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 107,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 107-3",
            "LABEL": "107-3",
            "FISHERY_AREA_ID": 2310,
            "OBJECTID": 467,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 194793052.9534,
            "FEATURE_LENGTH_M": 72755.388,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.798304694125,
            "lng": -128.5139043877
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.7540318219,
                "lng": -128.8000021087
            },
            {
                "lat": 51.7999996952,
                "lng": -129.9999997991
            },
            {
                "lat": 51.6555479281,
                "lng": -130.5083307374
            },
            {
                "lat": 51.3943821578,
                "lng": -130.0416716724
            },
            {
                "lat": 51.416668308,
                "lng": -129.9650263431
            },
            {
                "lat": 51.4802509339,
                "lng": -129.7461388703
            },
            {
                "lat": 51.7540318219,
                "lng": -128.8000021087
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 324,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 108,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 108-2",
            "LABEL": "108-2",
            "FISHERY_AREA_ID": 2312,
            "OBJECTID": 468,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2653746158.3435,
            "FEATURE_LENGTH_M": 259756.9855,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.607844666685715,
            "lng": -129.69445309138572
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.8161884018,
                "lng": -127.8826973633
            },
            {
                "lat": 51.8158147179,
                "lng": -127.8973610044
            },
            {
                "lat": 51.7335089055,
                "lng": -127.9966355278
            },
            {
                "lat": 51.7161256689,
                "lng": -127.8910223682
            },
            {
                "lat": 51.7153285977,
                "lng": -127.8876886745
            },
            {
                "lat": 51.7176405915,
                "lng": -127.876983738
            },
            {
                "lat": 51.7218408649,
                "lng": -127.8650141396
            },
            {
                "lat": 51.7383844792,
                "lng": -127.8559417228
            },
            {
                "lat": 51.7433051473,
                "lng": -127.8294911425
            },
            {
                "lat": 51.759296149,
                "lng": -127.7889013259
            },
            {
                "lat": 51.7669863682,
                "lng": -127.7584532041
            },
            {
                "lat": 51.7802092805,
                "lng": -127.7461551724
            },
            {
                "lat": 51.7897413161,
                "lng": -127.7495345427
            },
            {
                "lat": 51.7937386994,
                "lng": -127.761527592
            },
            {
                "lat": 51.8023524276,
                "lng": -127.7904357399
            },
            {
                "lat": 51.8097308244,
                "lng": -127.822112875
            },
            {
                "lat": 51.8143465927,
                "lng": -127.8522489622
            },
            {
                "lat": 51.8161884018,
                "lng": -127.8826973633
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 325,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 8-3",
            "LABEL": "8-3",
            "FISHERY_AREA_ID": 2469,
            "OBJECTID": 469,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 109317560.6404,
            "FEATURE_LENGTH_M": 43917.2184,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.76948485746667,
            "lng": -127.84082791436666
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.7608260092,
                "lng": -127.4344180861
            },
            {
                "lat": 51.7661703711,
                "lng": -127.4380255671
            },
            {
                "lat": 51.7710612465,
                "lng": -127.4309153507
            },
            {
                "lat": 51.7714849596,
                "lng": -127.4306715675
            },
            {
                "lat": 51.772605695,
                "lng": -127.4278795687
            },
            {
                "lat": 51.7752267011,
                "lng": -127.4262159147
            },
            {
                "lat": 51.7837912939,
                "lng": -127.4304959517
            },
            {
                "lat": 51.7902151884,
                "lng": -127.4428710018
            },
            {
                "lat": 51.7892650331,
                "lng": -127.4514395707
            },
            {
                "lat": 51.7792705397,
                "lng": -127.4588168022
            },
            {
                "lat": 51.7661819806,
                "lng": -127.4685660741
            },
            {
                "lat": 51.7423894172,
                "lng": -127.4728550231
            },
            {
                "lat": 51.7304916778,
                "lng": -127.4699933824
            },
            {
                "lat": 51.7100292404,
                "lng": -127.4635699218
            },
            {
                "lat": 51.7001034409,
                "lng": -127.4640654348
            },
            {
                "lat": 51.6960140627,
                "lng": -127.4565734296
            },
            {
                "lat": 51.6960141794,
                "lng": -127.4360884009
            },
            {
                "lat": 51.6939464663,
                "lng": -127.4320760108
            },
            {
                "lat": 51.6964646179,
                "lng": -127.4312137101
            },
            {
                "lat": 51.7083623016,
                "lng": -127.4290685041
            },
            {
                "lat": 51.7216872921,
                "lng": -127.4300232333
            },
            {
                "lat": 51.7340619818,
                "lng": -127.4362109706
            },
            {
                "lat": 51.7459528106,
                "lng": -127.4394986038
            },
            {
                "lat": 51.7608260092,
                "lng": -127.4344180861
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 326,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 9,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 9-7",
            "LABEL": "9-7",
            "FISHERY_AREA_ID": 2033,
            "OBJECTID": 470,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 23667953.6061,
            "FEATURE_LENGTH_M": 25006.3013,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.74426843817083,
            "lng": -127.44316542361248
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8608320492,
                "lng": -136.6046139276
            },
            {
                "lat": 50.8608319391,
                "lng": -136.600006039
            },
            {
                "lat": 50.8608320841,
                "lng": -135.5290525305
            },
            {
                "lat": 50.8608320463,
                "lng": -134.4580992413
            },
            {
                "lat": 50.8608318867,
                "lng": -133.3871458563
            },
            {
                "lat": 50.8608317119,
                "lng": -132.3161922903
            },
            {
                "lat": 50.8608317036,
                "lng": -131.2452389677
            },
            {
                "lat": 50.8608315847,
                "lng": -130.1742854643
            },
            {
                "lat": 50.8608317405,
                "lng": -129.1033315962
            },
            {
                "lat": 51.2500001578,
                "lng": -129.783340885
            },
            {
                "lat": 51.2500001243,
                "lng": -130.8282542294
            },
            {
                "lat": 51.2499999862,
                "lng": -131.8731672806
            },
            {
                "lat": 51.2500003969,
                "lng": -132.9180803428
            },
            {
                "lat": 51.2500002084,
                "lng": -133.9629932208
            },
            {
                "lat": 51.2500002875,
                "lng": -135.0079065731
            },
            {
                "lat": 51.2500003442,
                "lng": -136.0528195729
            },
            {
                "lat": 51.2500003759,
                "lng": -137.0977328582
            },
            {
                "lat": 51.1675337285,
                "lng": -137.0050358845
            },
            {
                "lat": 51.1329035936,
                "lng": -136.9610902133
            },
            {
                "lat": 51.1296268525,
                "lng": -136.9574127796
            },
            {
                "lat": 50.9663089181,
                "lng": -136.750625421
            },
            {
                "lat": 50.8848042526,
                "lng": -136.6345371424
            },
            {
                "lat": 50.8608320492,
                "lng": -136.6046139276
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 327,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 130,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 130-1",
            "LABEL": "130-1",
            "FISHERY_AREA_ID": 2343,
            "OBJECTID": 471,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 22514250387.2838,
            "FEATURE_LENGTH_M": 1157542.7167,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.03867382703478,
            "lng": -134.25459027149563
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.7826046125,
                "lng": -128.1177364517
            },
            {
                "lat": 52.7720570947,
                "lng": -128.1276858119
            },
            {
                "lat": 52.7698555516,
                "lng": -128.1297611334
            },
            {
                "lat": 52.7684903657,
                "lng": -128.130325438
            },
            {
                "lat": 52.7597044641,
                "lng": -128.1131286155
            },
            {
                "lat": 52.7508357145,
                "lng": -128.1028283883
            },
            {
                "lat": 52.752807855,
                "lng": -128.0878745815
            },
            {
                "lat": 52.7476608477,
                "lng": -128.0623477178
            },
            {
                "lat": 52.7395902996,
                "lng": -128.0376427923
            },
            {
                "lat": 52.7286945446,
                "lng": -127.9737699885
            },
            {
                "lat": 52.7218354818,
                "lng": -127.9601524276
            },
            {
                "lat": 52.7188076997,
                "lng": -127.9418948283
            },
            {
                "lat": 52.7241559631,
                "lng": -127.9240342521
            },
            {
                "lat": 52.7232478754,
                "lng": -127.8914485631
            },
            {
                "lat": 52.7209287511,
                "lng": -127.8661272475
            },
            {
                "lat": 52.7162858591,
                "lng": -127.8350522851
            },
            {
                "lat": 52.7208250784,
                "lng": -127.8157806068
            },
            {
                "lat": 52.7377742505,
                "lng": -127.8127521152
            },
            {
                "lat": 52.7507891017,
                "lng": -127.8165884365
            },
            {
                "lat": 52.7604751639,
                "lng": -127.8315201958
            },
            {
                "lat": 52.7683444731,
                "lng": -127.8548273619
            },
            {
                "lat": 52.7697569087,
                "lng": -127.8861008052
            },
            {
                "lat": 52.7631001219,
                "lng": -127.9099117343
            },
            {
                "lat": 52.7587659877,
                "lng": -127.9246826601
            },
            {
                "lat": 52.7598951068,
                "lng": -127.9485545937
            },
            {
                "lat": 52.769252684,
                "lng": -127.9725646131
            },
            {
                "lat": 52.7861029138,
                "lng": -127.9779120237
            },
            {
                "lat": 52.7952809593,
                "lng": -127.986785343
            },
            {
                "lat": 52.7935671063,
                "lng": -128.0039367319
            },
            {
                "lat": 52.7856972412,
                "lng": -128.0085751008
            },
            {
                "lat": 52.7736926135,
                "lng": -128.0124207302
            },
            {
                "lat": 52.7703632918,
                "lng": -128.0201872873
            },
            {
                "lat": 52.7707671715,
                "lng": -128.0317837745
            },
            {
                "lat": 52.7750045966,
                "lng": -128.0420687492
            },
            {
                "lat": 52.7813609787,
                "lng": -128.0608364123
            },
            {
                "lat": 52.7862014648,
                "lng": -128.0954438786
            },
            {
                "lat": 52.7826046125,
                "lng": -128.1177364517
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 228,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 7-11",
            "LABEL": "7-11",
            "FISHERY_AREA_ID": 2446,
            "OBJECTID": 372,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 94378052.3083,
            "FEATURE_LENGTH_M": 54413.3879,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.75830229207838,
            "lng": -127.9846697332
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.776710454,
                "lng": -128.3239442393
            },
            {
                "lat": 52.7810445611,
                "lng": -128.337066909
            },
            {
                "lat": 52.780922166,
                "lng": -128.3462834195
            },
            {
                "lat": 52.7813145806,
                "lng": -128.3471530955
            },
            {
                "lat": 52.7813191642,
                "lng": -128.3513177277
            },
            {
                "lat": 52.7807465654,
                "lng": -128.3522494991
            },
            {
                "lat": 52.7825170316,
                "lng": -128.3613439638
            },
            {
                "lat": 52.7539020291,
                "lng": -128.361968631
            },
            {
                "lat": 52.7207073138,
                "lng": -128.3556207968
            },
            {
                "lat": 52.7004814204,
                "lng": -128.3300476803
            },
            {
                "lat": 52.6677666651,
                "lng": -128.3098304092
            },
            {
                "lat": 52.6195800195,
                "lng": -128.313385638
            },
            {
                "lat": 52.574371359,
                "lng": -128.3080444377
            },
            {
                "lat": 52.5481983318,
                "lng": -128.3003085163
            },
            {
                "lat": 52.5386769928,
                "lng": -128.3044733394
            },
            {
                "lat": 52.5416524893,
                "lng": -128.3264774009
            },
            {
                "lat": 52.5487900273,
                "lng": -128.3585962327
            },
            {
                "lat": 52.5511707088,
                "lng": -128.4133298019
            },
            {
                "lat": 52.5523602518,
                "lng": -128.4347375994
            },
            {
                "lat": 52.5506901397,
                "lng": -128.4374850771
            },
            {
                "lat": 52.5492022535,
                "lng": -128.4405512984
            },
            {
                "lat": 52.547816647,
                "lng": -128.4420774183
            },
            {
                "lat": 52.5427015294,
                "lng": -128.4417265485
            },
            {
                "lat": 52.5413208399,
                "lng": -128.441115967
            },
            {
                "lat": 52.5390772622,
                "lng": -128.4385978537
            },
            {
                "lat": 52.5308640929,
                "lng": -128.420348737
            },
            {
                "lat": 52.5208933302,
                "lng": -128.3786319052
            },
            {
                "lat": 52.5164335704,
                "lng": -128.3376918724
            },
            {
                "lat": 52.5130239673,
                "lng": -128.3061978879
            },
            {
                "lat": 52.5043634603,
                "lng": -128.2964930129
            },
            {
                "lat": 52.4925531487,
                "lng": -128.2970129431
            },
            {
                "lat": 52.4862550275,
                "lng": -128.3030550317
            },
            {
                "lat": 52.4883576789,
                "lng": -128.320373868
            },
            {
                "lat": 52.4896695262,
                "lng": -128.3458260387
            },
            {
                "lat": 52.4838940119,
                "lng": -128.3825684583
            },
            {
                "lat": 52.4775960292,
                "lng": -128.4085382921
            },
            {
                "lat": 52.4678883241,
                "lng": -128.4169309736
            },
            {
                "lat": 52.4626998854,
                "lng": -128.4196621796
            },
            {
                "lat": 52.4471327199,
                "lng": -128.4130401587
            },
            {
                "lat": 52.4468882552,
                "lng": -128.412551752
            },
            {
                "lat": 52.4424585725,
                "lng": -128.4073036262
            },
            {
                "lat": 52.4489252363,
                "lng": -128.3640901773
            },
            {
                "lat": 52.4596334168,
                "lng": -128.3224486641
            },
            {
                "lat": 52.4584424192,
                "lng": -128.3034061225
            },
            {
                "lat": 52.4471398578,
                "lng": -128.3040014827
            },
            {
                "lat": 52.4275096474,
                "lng": -128.298660153
            },
            {
                "lat": 52.4286992177,
                "lng": -128.3242337847
            },
            {
                "lat": 52.4114490755,
                "lng": -128.3539738083
            },
            {
                "lat": 52.3882486865,
                "lng": -128.3694466954
            },
            {
                "lat": 52.3763545984,
                "lng": -128.3920435201
            },
            {
                "lat": 52.3709984308,
                "lng": -128.4212031483
            },
            {
                "lat": 52.3674276797,
                "lng": -128.4432072985
            },
            {
                "lat": 52.3665310732,
                "lng": -128.4491883036
            },
            {
                "lat": 52.3655578974,
                "lng": -128.4544526208
            },
            {
                "lat": 52.3638955848,
                "lng": -128.4580839959
            },
            {
                "lat": 52.3507804737,
                "lng": -128.4580837049
            },
            {
                "lat": 52.3456349037,
                "lng": -128.4537057633
            },
            {
                "lat": 52.3433343298,
                "lng": -128.4508669037
            },
            {
                "lat": 52.343334384,
                "lng": -128.44653307
            },
            {
                "lat": 52.3394087715,
                "lng": -128.443007853
            },
            {
                "lat": 52.329391475,
                "lng": -128.4417883498
            },
            {
                "lat": 52.321136523,
                "lng": -128.4430080677
            },
            {
                "lat": 52.3187906844,
                "lng": -128.4456333428
            },
            {
                "lat": 52.269496998,
                "lng": -128.407287508
            },
            {
                "lat": 52.263843795,
                "lng": -128.3481896614
            },
            {
                "lat": 52.2646295399,
                "lng": -128.3478853902
            },
            {
                "lat": 52.2648963152,
                "lng": -128.3478392981
            },
            {
                "lat": 52.2661777034,
                "lng": -128.3476252471
            },
            {
                "lat": 52.2684294269,
                "lng": -128.3489839393
            },
            {
                "lat": 52.2706830396,
                "lng": -128.3429404452
            },
            {
                "lat": 52.2725831646,
                "lng": -128.3342738153
            },
            {
                "lat": 52.2739517768,
                "lng": -128.3298640941
            },
            {
                "lat": 52.2754098481,
                "lng": -128.3305660242
            },
            {
                "lat": 52.2777065717,
                "lng": -128.3277436706
            },
            {
                "lat": 52.2777938668,
                "lng": -128.3247370011
            },
            {
                "lat": 52.2793348916,
                "lng": -128.3211363315
            },
            {
                "lat": 52.2830431467,
                "lng": -128.3176883416
            },
            {
                "lat": 52.2989918167,
                "lng": -128.3187872593
            },
            {
                "lat": 52.3430210343,
                "lng": -128.3218836351
            },
            {
                "lat": 52.3792496771,
                "lng": -128.3098454255
            },
            {
                "lat": 52.3802332781,
                "lng": -128.2744448589
            },
            {
                "lat": 52.3879659809,
                "lng": -128.2506558745
            },
            {
                "lat": 52.3974385239,
                "lng": -128.2459563431
            },
            {
                "lat": 52.4152875136,
                "lng": -128.2346651403
            },
            {
                "lat": 52.4420999277,
                "lng": -128.2220912481
            },
            {
                "lat": 52.459404154,
                "lng": -128.2122339954
            },
            {
                "lat": 52.4694636421,
                "lng": -128.2051388724
            },
            {
                "lat": 52.4789816872,
                "lng": -128.1911616877
            },
            {
                "lat": 52.492069116,
                "lng": -128.1881868692
            },
            {
                "lat": 52.5224080553,
                "lng": -128.21437091
            },
            {
                "lat": 52.5604783653,
                "lng": -128.2304230243
            },
            {
                "lat": 52.5718377264,
                "lng": -128.2458647677
            },
            {
                "lat": 52.5871502724,
                "lng": -128.2867127317
            },
            {
                "lat": 52.5934946641,
                "lng": -128.2863618859
            },
            {
                "lat": 52.5991442869,
                "lng": -128.2860414282
            },
            {
                "lat": 52.6193692095,
                "lng": -128.2777251597
            },
            {
                "lat": 52.6645812824,
                "lng": -128.254516502
            },
            {
                "lat": 52.6859934506,
                "lng": -128.2551112529
            },
            {
                "lat": 52.7157363937,
                "lng": -128.2896118807
            },
            {
                "lat": 52.7502409867,
                "lng": -128.3163916375
            },
            {
                "lat": 52.776710454,
                "lng": -128.3239442393
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 229,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 7-9",
            "LABEL": "7-9",
            "FISHERY_AREA_ID": 2444,
            "OBJECTID": 373,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 364889479.3717,
            "FEATURE_LENGTH_M": 173565.6314,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.481148201521776,
            "lng": -128.3455833115386
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.7684903657,
                "lng": -128.130325438
            },
            {
                "lat": 52.7684903511,
                "lng": -128.1559602997
            },
            {
                "lat": 52.7707672638,
                "lng": -128.1645507283
            },
            {
                "lat": 52.7501106086,
                "lng": -128.1743169714
            },
            {
                "lat": 52.7365611268,
                "lng": -128.1846011193
            },
            {
                "lat": 52.7272148649,
                "lng": -128.1948700218
            },
            {
                "lat": 52.7260438425,
                "lng": -128.2065578865
            },
            {
                "lat": 52.7302593283,
                "lng": -128.2219081498
            },
            {
                "lat": 52.728614201,
                "lng": -128.2306212874
            },
            {
                "lat": 52.7237083936,
                "lng": -128.2373968581
            },
            {
                "lat": 52.7193140921,
                "lng": -128.2375185345
            },
            {
                "lat": 52.7152979391,
                "lng": -128.2376247383
            },
            {
                "lat": 52.7040823895,
                "lng": -128.2322547894
            },
            {
                "lat": 52.6855246974,
                "lng": -128.2226252461
            },
            {
                "lat": 52.6734542632,
                "lng": -128.2245022413
            },
            {
                "lat": 52.6011049058,
                "lng": -128.2767793834
            },
            {
                "lat": 52.5934946641,
                "lng": -128.2863618859
            },
            {
                "lat": 52.5871502724,
                "lng": -128.2867127317
            },
            {
                "lat": 52.5718377264,
                "lng": -128.2458647677
            },
            {
                "lat": 52.5604783653,
                "lng": -128.2304230243
            },
            {
                "lat": 52.5676154976,
                "lng": -128.2194218108
            },
            {
                "lat": 52.5991441545,
                "lng": -128.1819453909
            },
            {
                "lat": 52.6372145638,
                "lng": -128.1504211117
            },
            {
                "lat": 52.6830212905,
                "lng": -128.126021519
            },
            {
                "lat": 52.7246632648,
                "lng": -128.1046138058
            },
            {
                "lat": 52.7508357145,
                "lng": -128.1028283883
            },
            {
                "lat": 52.7597044641,
                "lng": -128.1131286155
            },
            {
                "lat": 52.7684903657,
                "lng": -128.130325438
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 230,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 7-10",
            "LABEL": "7-10",
            "FISHERY_AREA_ID": 2445,
            "OBJECTID": 374,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 139171291.6895,
            "FEATURE_LENGTH_M": 58220.5065,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.69045317775356,
            "lng": -128.1968029351036
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.7401919262,
                "lng": -129.0500031243
            },
            {
                "lat": 52.7348332302,
                "lng": -129.0565493923
            },
            {
                "lat": 52.7210194276,
                "lng": -129.0867765051
            },
            {
                "lat": 52.7185631211,
                "lng": -129.0875707416
            },
            {
                "lat": 52.7100986683,
                "lng": -129.0940097332
            },
            {
                "lat": 52.6925463682,
                "lng": -129.0773925262
            },
            {
                "lat": 52.6710738046,
                "lng": -129.0529324317
            },
            {
                "lat": 52.6660573257,
                "lng": -129.0327148694
            },
            {
                "lat": 52.6660572828,
                "lng": -129.0226900352
            },
            {
                "lat": 52.671703862,
                "lng": -129.0139922671
            },
            {
                "lat": 52.6732637667,
                "lng": -129.0133355236
            },
            {
                "lat": 52.6889950073,
                "lng": -128.9752189059
            },
            {
                "lat": 52.6976013308,
                "lng": -128.9717107502
            },
            {
                "lat": 52.7133068919,
                "lng": -128.9691002337
            },
            {
                "lat": 52.7280578151,
                "lng": -128.9764708174
            },
            {
                "lat": 52.7323422074,
                "lng": -128.9933621863
            },
            {
                "lat": 52.7316284537,
                "lng": -129.0290535669
            },
            {
                "lat": 52.7401919262,
                "lng": -129.0500031243
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 231,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 15,
            "MANAGEMENT_AREA_NAME": "Subarea 6-15",
            "LABEL": "6-15",
            "FISHERY_AREA_ID": 2422,
            "OBJECTID": 375,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 46411925.6175,
            "FEATURE_LENGTH_M": 25899.3678,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.70541846754444,
            "lng": -129.03071592968888
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.6976013308,
                "lng": -128.9717107502
            },
            {
                "lat": 52.6889950073,
                "lng": -128.9752189059
            },
            {
                "lat": 52.6732637667,
                "lng": -129.0133355236
            },
            {
                "lat": 52.671703862,
                "lng": -129.0139922671
            },
            {
                "lat": 52.6660572828,
                "lng": -129.0226900352
            },
            {
                "lat": 52.6538351741,
                "lng": -129.0143895486
            },
            {
                "lat": 52.64119003,
                "lng": -129.0015562605
            },
            {
                "lat": 52.6314204778,
                "lng": -128.991653646
            },
            {
                "lat": 52.6091200699,
                "lng": -128.9608303087
            },
            {
                "lat": 52.5886574994,
                "lng": -128.9558407873
            },
            {
                "lat": 52.542731755,
                "lng": -128.9608300626
            },
            {
                "lat": 52.5293009712,
                "lng": -128.9605103874
            },
            {
                "lat": 52.4927673223,
                "lng": -128.9470980263
            },
            {
                "lat": 52.4809838684,
                "lng": -128.9494020649
            },
            {
                "lat": 52.4783701024,
                "lng": -128.9446103473
            },
            {
                "lat": 52.4788363094,
                "lng": -128.943587638
            },
            {
                "lat": 52.4795533768,
                "lng": -128.9428256486
            },
            {
                "lat": 52.4800458194,
                "lng": -128.9424284419
            },
            {
                "lat": 52.4581257559,
                "lng": -128.76336624
            },
            {
                "lat": 52.4587789067,
                "lng": -128.7610631102
            },
            {
                "lat": 52.459129135,
                "lng": -128.7561805689
            },
            {
                "lat": 52.4600373358,
                "lng": -128.7496801496
            },
            {
                "lat": 52.462413202,
                "lng": -128.724456831
            },
            {
                "lat": 52.4612737551,
                "lng": -128.699447712
            },
            {
                "lat": 52.4706462953,
                "lng": -128.6924740585
            },
            {
                "lat": 52.475105024,
                "lng": -128.6927189782
            },
            {
                "lat": 52.4787675884,
                "lng": -128.6984254858
            },
            {
                "lat": 52.4805533322,
                "lng": -128.7030185699
            },
            {
                "lat": 52.4822992747,
                "lng": -128.7030185042
            },
            {
                "lat": 52.4826849396,
                "lng": -128.7054896002
            },
            {
                "lat": 52.5072668921,
                "lng": -128.7407532637
            },
            {
                "lat": 52.5126870913,
                "lng": -128.7792365684
            },
            {
                "lat": 52.5354077851,
                "lng": -128.8041375123
            },
            {
                "lat": 52.5583346801,
                "lng": -128.8140260128
            },
            {
                "lat": 52.5927995535,
                "lng": -128.7545468772
            },
            {
                "lat": 52.6007999035,
                "lng": -128.753967097
            },
            {
                "lat": 52.6488490301,
                "lng": -128.8918766996
            },
            {
                "lat": 52.6528967373,
                "lng": -128.8908076067
            },
            {
                "lat": 52.656437116,
                "lng": -128.8924714991
            },
            {
                "lat": 52.6699980875,
                "lng": -128.9029547953
            },
            {
                "lat": 52.6797561408,
                "lng": -128.9200745124
            },
            {
                "lat": 52.6885605333,
                "lng": -128.934829873
            },
            {
                "lat": 52.6966512316,
                "lng": -128.9586334166
            },
            {
                "lat": 52.6976013308,
                "lng": -128.9717107502
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 232,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 16,
            "MANAGEMENT_AREA_NAME": "Subarea 6-16",
            "LABEL": "6-16",
            "FISHERY_AREA_ID": 2423,
            "OBJECTID": 376,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 281277693.6499,
            "FEATURE_LENGTH_M": 85090.2355,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.56391578825908,
            "lng": -128.86754265779317
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.6774370533,
                "lng": -128.5489655322
            },
            {
                "lat": 52.6836163163,
                "lng": -128.5730891384
            },
            {
                "lat": 52.6964836065,
                "lng": -128.5801093205
            },
            {
                "lat": 52.6863973529,
                "lng": -128.599853169
            },
            {
                "lat": 52.6709297387,
                "lng": -128.6289979289
            },
            {
                "lat": 52.6602213841,
                "lng": -128.6706391867
            },
            {
                "lat": 52.6417815141,
                "lng": -128.6991877147
            },
            {
                "lat": 52.6137009938,
                "lng": -128.7309719368
            },
            {
                "lat": 52.6048008899,
                "lng": -128.7498473767
            },
            {
                "lat": 52.6007999035,
                "lng": -128.753967097
            },
            {
                "lat": 52.5927995535,
                "lng": -128.7545468772
            },
            {
                "lat": 52.590026892,
                "lng": -128.7319030888
            },
            {
                "lat": 52.5876456972,
                "lng": -128.6902610891
            },
            {
                "lat": 52.5828890132,
                "lng": -128.6170951963
            },
            {
                "lat": 52.5816995215,
                "lng": -128.568313941
            },
            {
                "lat": 52.6066857569,
                "lng": -128.5540459758
            },
            {
                "lat": 52.6705625006,
                "lng": -128.5494532764
            },
            {
                "lat": 52.672584803,
                "lng": -128.5489509701
            },
            {
                "lat": 52.6774370533,
                "lng": -128.5489655322
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 233,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 25,
            "MANAGEMENT_AREA_NAME": "Subarea 6-25",
            "LABEL": "6-25",
            "FISHERY_AREA_ID": 2432,
            "OBJECTID": 377,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 108481778.7224,
            "FEATURE_LENGTH_M": 43862.2351,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.63676313391052,
            "lng": -128.6367981235684
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.5857155801,
                "lng": -131.3200535352
            },
            {
                "lat": 52.5857158761,
                "lng": -131.3721320448
            },
            {
                "lat": 52.5850940382,
                "lng": -131.3722227596
            },
            {
                "lat": 52.5839693905,
                "lng": -131.3727722309
            },
            {
                "lat": 52.5827670113,
                "lng": -131.3737030169
            },
            {
                "lat": 52.5779229149,
                "lng": -131.3741909014
            },
            {
                "lat": 52.56758874,
                "lng": -131.3711247127
            },
            {
                "lat": 52.5619353357,
                "lng": -131.3740235485
            },
            {
                "lat": 52.5578994517,
                "lng": -131.3845216293
            },
            {
                "lat": 52.5582240842,
                "lng": -131.4037327488
            },
            {
                "lat": 52.5587457144,
                "lng": -131.432830229
            },
            {
                "lat": 52.5610879103,
                "lng": -131.4368752246
            },
            {
                "lat": 52.5638887943,
                "lng": -131.4389796218
            },
            {
                "lat": 52.5001527576,
                "lng": -131.4675138658
            },
            {
                "lat": 52.4988595752,
                "lng": -131.4707950976
            },
            {
                "lat": 52.4988593028,
                "lng": -131.4774630736
            },
            {
                "lat": 52.4979059712,
                "lng": -131.4815061035
            },
            {
                "lat": 52.4910049386,
                "lng": -131.48388639
            },
            {
                "lat": 52.4869619981,
                "lng": -131.4895933386
            },
            {
                "lat": 52.4826775214,
                "lng": -131.5007790044
            },
            {
                "lat": 52.4774434308,
                "lng": -131.5145731067
            },
            {
                "lat": 52.4729233903,
                "lng": -131.5209964494
            },
            {
                "lat": 52.4638827443,
                "lng": -131.520996667
            },
            {
                "lat": 52.4595982365,
                "lng": -131.5138541594
            },
            {
                "lat": 52.4579313132,
                "lng": -131.5003044187
            },
            {
                "lat": 52.4510301239,
                "lng": -131.4912569872
            },
            {
                "lat": 52.4374691773,
                "lng": -131.4833993202
            },
            {
                "lat": 52.436279361,
                "lng": -131.4717414143
            },
            {
                "lat": 52.4481768489,
                "lng": -131.4581755905
            },
            {
                "lat": 52.4572178747,
                "lng": -131.4424745068
            },
            {
                "lat": 52.4570969193,
                "lng": -131.4375916666
            },
            {
                "lat": 52.4569812218,
                "lng": -131.4329526015
            },
            {
                "lat": 52.4465096694,
                "lng": -131.4267729891
            },
            {
                "lat": 52.439422477,
                "lng": -131.4253075208
            },
            {
                "lat": 52.4373935208,
                "lng": -131.4248806169
            },
            {
                "lat": 52.4341199768,
                "lng": -131.4216153994
            },
            {
                "lat": 52.4364549116,
                "lng": -131.3835903277
            },
            {
                "lat": 52.4351011042,
                "lng": -131.3582754757
            },
            {
                "lat": 52.4426157394,
                "lng": -131.3203123461
            },
            {
                "lat": 52.4458731097,
                "lng": -131.3143764227
            },
            {
                "lat": 52.4401057842,
                "lng": -131.3070686309
            },
            {
                "lat": 52.434288627,
                "lng": -131.3037112551
            },
            {
                "lat": 52.4344096431,
                "lng": -131.2852935935
            },
            {
                "lat": 52.4366190563,
                "lng": -131.2555997791
            },
            {
                "lat": 52.4412151895,
                "lng": -131.2416228293
            },
            {
                "lat": 52.4416472501,
                "lng": -131.2381130752
            },
            {
                "lat": 52.4416889662,
                "lng": -131.2360538686
            },
            {
                "lat": 52.5857155801,
                "lng": -131.3200535352
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 234,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 2-12",
            "LABEL": "2-12",
            "FISHERY_AREA_ID": 1903,
            "OBJECTID": 378,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 199785837.3913,
            "FEATURE_LENGTH_M": 66784.7432,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.490337253208345,
            "lng": -131.40520132563748
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.6770741213,
                "lng": -128.5296025729
            },
            {
                "lat": 52.6774373428,
                "lng": -128.5346072054
            },
            {
                "lat": 52.6774370533,
                "lng": -128.5489655322
            },
            {
                "lat": 52.672584803,
                "lng": -128.5489509701
            },
            {
                "lat": 52.6624719838,
                "lng": -128.5467370952
            },
            {
                "lat": 52.6291583658,
                "lng": -128.5407863844
            },
            {
                "lat": 52.6020311353,
                "lng": -128.5341180005
            },
            {
                "lat": 52.5739555725,
                "lng": -128.5291283425
            },
            {
                "lat": 52.5551570256,
                "lng": -128.5188899212
            },
            {
                "lat": 52.5432007114,
                "lng": -128.5053102301
            },
            {
                "lat": 52.5427051875,
                "lng": -128.4957584086
            },
            {
                "lat": 52.5427015294,
                "lng": -128.4417265485
            },
            {
                "lat": 52.547816647,
                "lng": -128.4420774183
            },
            {
                "lat": 52.5492022535,
                "lng": -128.4405512984
            },
            {
                "lat": 52.5506901397,
                "lng": -128.4374850771
            },
            {
                "lat": 52.5523602518,
                "lng": -128.4347375994
            },
            {
                "lat": 52.5584292983,
                "lng": -128.4232637932
            },
            {
                "lat": 52.5876957624,
                "lng": -128.42445334
            },
            {
                "lat": 52.6314771999,
                "lng": -128.4249264908
            },
            {
                "lat": 52.6372144924,
                "lng": -128.4402469957
            },
            {
                "lat": 52.6419713155,
                "lng": -128.4472050988
            },
            {
                "lat": 52.642154977,
                "lng": -128.5055081681
            },
            {
                "lat": 52.6489177675,
                "lng": -128.507660521
            },
            {
                "lat": 52.6552508939,
                "lng": -128.5163111886
            },
            {
                "lat": 52.6691855236,
                "lng": -128.5278013673
            },
            {
                "lat": 52.6770741213,
                "lng": -128.5296025729
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 235,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 7-5",
            "LABEL": "7-5",
            "FISHERY_AREA_ID": 2440,
            "OBJECTID": 379,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 84479370.3609,
            "FEATURE_LENGTH_M": 41704.4831,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.61174444136539,
            "lng": -128.49140046696922
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.672584803,
                "lng": -128.5489509701
            },
            {
                "lat": 52.6705625006,
                "lng": -128.5494532764
            },
            {
                "lat": 52.6066857569,
                "lng": -128.5540459758
            },
            {
                "lat": 52.5816995215,
                "lng": -128.568313941
            },
            {
                "lat": 52.5828890132,
                "lng": -128.6170951963
            },
            {
                "lat": 52.5876456972,
                "lng": -128.6902610891
            },
            {
                "lat": 52.590026892,
                "lng": -128.7319030888
            },
            {
                "lat": 52.5812871851,
                "lng": -128.7318266134
            },
            {
                "lat": 52.5714571884,
                "lng": -128.6994931682
            },
            {
                "lat": 52.5336606447,
                "lng": -128.6885688754
            },
            {
                "lat": 52.5225183694,
                "lng": -128.6848592964
            },
            {
                "lat": 52.52141165,
                "lng": -128.6804350783
            },
            {
                "lat": 52.5205532409,
                "lng": -128.6769860288
            },
            {
                "lat": 52.5170097125,
                "lng": -128.6576682698
            },
            {
                "lat": 52.4419823819,
                "lng": -128.5164798592
            },
            {
                "lat": 52.4347795765,
                "lng": -128.5083927648
            },
            {
                "lat": 52.4370836505,
                "lng": -128.5072634921
            },
            {
                "lat": 52.4407610475,
                "lng": -128.4980316208
            },
            {
                "lat": 52.5201911476,
                "lng": -128.4962625503
            },
            {
                "lat": 52.5432007114,
                "lng": -128.5053102301
            },
            {
                "lat": 52.5551570256,
                "lng": -128.5188899212
            },
            {
                "lat": 52.5739555725,
                "lng": -128.5291283425
            },
            {
                "lat": 52.6020311353,
                "lng": -128.5341180005
            },
            {
                "lat": 52.6291583658,
                "lng": -128.5407863844
            },
            {
                "lat": 52.6624719838,
                "lng": -128.5467370952
            },
            {
                "lat": 52.672584803,
                "lng": -128.5489509701
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 236,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 380,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 154463065.0587,
            "FEATURE_LENGTH_M": 73436.9707,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.56051344526155,
            "lng": -128.5896235422692
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.6690982355,
                "lng": -126.944663531
            },
            {
                "lat": 52.6703184613,
                "lng": -126.9672705142
            },
            {
                "lat": 52.6706129206,
                "lng": -126.9756093389
            },
            {
                "lat": 52.6680492523,
                "lng": -127.0101084903
            },
            {
                "lat": 52.6898724523,
                "lng": -127.0222707502
            },
            {
                "lat": 52.6550408875,
                "lng": -127.0736002605
            },
            {
                "lat": 52.640372517,
                "lng": -127.1145470218
            },
            {
                "lat": 52.6354868973,
                "lng": -127.1762696689
            },
            {
                "lat": 52.5945434189,
                "lng": -127.2532659116
            },
            {
                "lat": 52.5719299422,
                "lng": -127.2801587927
            },
            {
                "lat": 52.5212099958,
                "lng": -127.2991028372
            },
            {
                "lat": 52.4857637014,
                "lng": -127.310714583
            },
            {
                "lat": 52.4631538519,
                "lng": -127.3021538173
            },
            {
                "lat": 52.4548334654,
                "lng": -127.2929688509
            },
            {
                "lat": 52.439227989,
                "lng": -127.2735827395
            },
            {
                "lat": 52.4426250604,
                "lng": -127.265352192
            },
            {
                "lat": 52.4521642134,
                "lng": -127.2422254617
            },
            {
                "lat": 52.4524039965,
                "lng": -127.2380830218
            },
            {
                "lat": 52.4499547347,
                "lng": -127.2328803304
            },
            {
                "lat": 52.4502607177,
                "lng": -127.2261498361
            },
            {
                "lat": 52.4591372658,
                "lng": -127.2016593921
            },
            {
                "lat": 52.4851526067,
                "lng": -127.2080461812
            },
            {
                "lat": 52.4924846233,
                "lng": -127.212325953
            },
            {
                "lat": 52.5157095944,
                "lng": -127.2068247292
            },
            {
                "lat": 52.5291517421,
                "lng": -127.188491791
            },
            {
                "lat": 52.5456536529,
                "lng": -127.1493830375
            },
            {
                "lat": 52.5707085281,
                "lng": -127.0864331427
            },
            {
                "lat": 52.5835423577,
                "lng": -127.061378167
            },
            {
                "lat": 52.5957636354,
                "lng": -127.012489532
            },
            {
                "lat": 52.6110416738,
                "lng": -126.9733805321
            },
            {
                "lat": 52.6440388003,
                "lng": -126.94771618
            },
            {
                "lat": 52.6690982355,
                "lng": -126.944663531
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 237,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 8-8",
            "LABEL": "8-8",
            "FISHERY_AREA_ID": 2474,
            "OBJECTID": 381,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 256754532.7826,
            "FEATURE_LENGTH_M": 83566.247,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.555575169596885,
            "lng": -127.1466803162125
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.4694636421,
                "lng": -128.2051388724
            },
            {
                "lat": 52.459404154,
                "lng": -128.2122339954
            },
            {
                "lat": 52.4466891773,
                "lng": -128.1936029974
            },
            {
                "lat": 52.4298285259,
                "lng": -128.1634366087
            },
            {
                "lat": 52.4159275098,
                "lng": -128.1619567236
            },
            {
                "lat": 52.4005466477,
                "lng": -128.1637262482
            },
            {
                "lat": 52.3976443983,
                "lng": -128.1642305045
            },
            {
                "lat": 52.3944247064,
                "lng": -128.1649479693
            },
            {
                "lat": 52.3944210596,
                "lng": -128.1317900732
            },
            {
                "lat": 52.3842623792,
                "lng": -128.1246489132
            },
            {
                "lat": 52.3867606078,
                "lng": -128.1231687845
            },
            {
                "lat": 52.3955119183,
                "lng": -128.1179809181
            },
            {
                "lat": 52.4069283907,
                "lng": -128.1079872546
            },
            {
                "lat": 52.4097863422,
                "lng": -128.1003718941
            },
            {
                "lat": 52.4045523544,
                "lng": -128.09298688
            },
            {
                "lat": 52.3850402816,
                "lng": -128.086563163
            },
            {
                "lat": 52.3657643168,
                "lng": -128.0777585715
            },
            {
                "lat": 52.3412588775,
                "lng": -128.0639646659
            },
            {
                "lat": 52.3312644388,
                "lng": -128.0570676959
            },
            {
                "lat": 52.3224446201,
                "lng": -128.0505669466
            },
            {
                "lat": 52.3190722683,
                "lng": -128.0469812979
            },
            {
                "lat": 52.3196098841,
                "lng": -128.025848801
            },
            {
                "lat": 52.318073669,
                "lng": -128.0078126411
            },
            {
                "lat": 52.3197625247,
                "lng": -128.0069428844
            },
            {
                "lat": 52.3227279514,
                "lng": -128.003555685
            },
            {
                "lat": 52.329704647,
                "lng": -127.9906236052
            },
            {
                "lat": 52.3433649533,
                "lng": -127.9917748551
            },
            {
                "lat": 52.364303012,
                "lng": -127.9948653154
            },
            {
                "lat": 52.3774758128,
                "lng": -127.9788743691
            },
            {
                "lat": 52.4085315319,
                "lng": -127.9640878254
            },
            {
                "lat": 52.4502372249,
                "lng": -127.9631950254
            },
            {
                "lat": 52.4676895229,
                "lng": -127.9552145531
            },
            {
                "lat": 52.4662130188,
                "lng": -127.9451597751
            },
            {
                "lat": 52.4671981706,
                "lng": -127.9324338409
            },
            {
                "lat": 52.4718291596,
                "lng": -127.9235685009
            },
            {
                "lat": 52.4886895361,
                "lng": -127.9253389875
            },
            {
                "lat": 52.4943089075,
                "lng": -127.9321435262
            },
            {
                "lat": 52.503475191,
                "lng": -127.9596478631
            },
            {
                "lat": 52.5253636396,
                "lng": -127.9661558243
            },
            {
                "lat": 52.5357130382,
                "lng": -127.9776916445
            },
            {
                "lat": 52.5487292176,
                "lng": -128.013184061
            },
            {
                "lat": 52.5553475526,
                "lng": -128.0772252953
            },
            {
                "lat": 52.5673638737,
                "lng": -128.0815126827
            },
            {
                "lat": 52.5806726928,
                "lng": -128.073822143
            },
            {
                "lat": 52.5895456396,
                "lng": -128.0471963117
            },
            {
                "lat": 52.6034472417,
                "lng": -128.0261992465
            },
            {
                "lat": 52.6223749184,
                "lng": -128.0105289612
            },
            {
                "lat": 52.6427837997,
                "lng": -128.0155487806
            },
            {
                "lat": 52.6593472288,
                "lng": -128.0303342785
            },
            {
                "lat": 52.6620097011,
                "lng": -128.0587314433
            },
            {
                "lat": 52.6519547479,
                "lng": -128.076186964
            },
            {
                "lat": 52.627994108,
                "lng": -128.0897826979
            },
            {
                "lat": 52.6158720656,
                "lng": -128.1069482808
            },
            {
                "lat": 52.6046298672,
                "lng": -128.1252745408
            },
            {
                "lat": 52.5788998698,
                "lng": -128.1294242484
            },
            {
                "lat": 52.5546454331,
                "lng": -128.1285403595
            },
            {
                "lat": 52.5389670202,
                "lng": -128.1255792479
            },
            {
                "lat": 52.5191543564,
                "lng": -128.1172944128
            },
            {
                "lat": 52.5006754269,
                "lng": -128.107024878
            },
            {
                "lat": 52.4759288844,
                "lng": -128.1205144593
            },
            {
                "lat": 52.4718289403,
                "lng": -128.1335601303
            },
            {
                "lat": 52.4833644187,
                "lng": -128.1548615864
            },
            {
                "lat": 52.4804073651,
                "lng": -128.1755670333
            },
            {
                "lat": 52.4694631869,
                "lng": -128.1867976015
            },
            {
                "lat": 52.4694636421,
                "lng": -128.2051388724
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 238,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 7-14",
            "LABEL": "7-14",
            "FISHERY_AREA_ID": 2449,
            "OBJECTID": 382,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 329428908.4548,
            "FEATURE_LENGTH_M": 111795.0426,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.46630983401232,
            "lng": -128.0688126925815
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.439227989,
                "lng": -127.2735827395
            },
            {
                "lat": 52.4548334654,
                "lng": -127.2929688509
            },
            {
                "lat": 52.4631538519,
                "lng": -127.3021538173
            },
            {
                "lat": 52.4724120929,
                "lng": -127.3427888889
            },
            {
                "lat": 52.4562838255,
                "lng": -127.3796457628
            },
            {
                "lat": 52.4624294024,
                "lng": -127.41497774
            },
            {
                "lat": 52.5085070158,
                "lng": -127.4718100649
            },
            {
                "lat": 52.5753208866,
                "lng": -127.5516739135
            },
            {
                "lat": 52.6260068838,
                "lng": -127.6092759992
            },
            {
                "lat": 52.6321489566,
                "lng": -127.6445998604
            },
            {
                "lat": 52.6075739054,
                "lng": -127.6614991665
            },
            {
                "lat": 52.5384555638,
                "lng": -127.6215590558
            },
            {
                "lat": 52.5000571964,
                "lng": -127.5693351962
            },
            {
                "lat": 52.4432290937,
                "lng": -127.5547486719
            },
            {
                "lat": 52.4102058892,
                "lng": -127.5923766658
            },
            {
                "lat": 52.389468817,
                "lng": -127.6530458585
            },
            {
                "lat": 52.3833278309,
                "lng": -127.7106476518
            },
            {
                "lat": 52.3671982452,
                "lng": -127.7536544806
            },
            {
                "lat": 52.3295673643,
                "lng": -127.7912826748
            },
            {
                "lat": 52.2888635392,
                "lng": -127.8081816278
            },
            {
                "lat": 52.2677426257,
                "lng": -127.7850042132
            },
            {
                "lat": 52.2459752103,
                "lng": -127.7659379296
            },
            {
                "lat": 52.221283586,
                "lng": -127.7559581824
            },
            {
                "lat": 52.2251238444,
                "lng": -127.7490456598
            },
            {
                "lat": 52.2351079848,
                "lng": -127.715256068
            },
            {
                "lat": 52.2473941547,
                "lng": -127.6799310292
            },
            {
                "lat": 52.23894845,
                "lng": -127.6438296742
            },
            {
                "lat": 52.2143742042,
                "lng": -127.6062009358
            },
            {
                "lat": 52.2174451674,
                "lng": -127.5823972471
            },
            {
                "lat": 52.2389485473,
                "lng": -127.5501410247
            },
            {
                "lat": 52.2627566487,
                "lng": -127.5201873092
            },
            {
                "lat": 52.2996183567,
                "lng": -127.4664298583
            },
            {
                "lat": 52.3234253372,
                "lng": -127.4264982229
            },
            {
                "lat": 52.3533740047,
                "lng": -127.3980793818
            },
            {
                "lat": 52.3779527803,
                "lng": -127.3473970138
            },
            {
                "lat": 52.3925396116,
                "lng": -127.294403527
            },
            {
                "lat": 52.3957479248,
                "lng": -127.263267887
            },
            {
                "lat": 52.439227989,
                "lng": -127.2735827395
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 239,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 8-7",
            "LABEL": "8-7",
            "FISHERY_AREA_ID": 2473,
            "OBJECTID": 383,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 686705441.866,
            "FEATURE_LENGTH_M": 143703.0977,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.3827699537579,
            "lng": -127.54798306817369
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.590026892,
                "lng": -128.7319030888
            },
            {
                "lat": 52.5927995535,
                "lng": -128.7545468772
            },
            {
                "lat": 52.5583346801,
                "lng": -128.8140260128
            },
            {
                "lat": 52.5354077851,
                "lng": -128.8041375123
            },
            {
                "lat": 52.5126870913,
                "lng": -128.7792365684
            },
            {
                "lat": 52.5072668921,
                "lng": -128.7407532637
            },
            {
                "lat": 52.5070076189,
                "lng": -128.7303010579
            },
            {
                "lat": 52.5142170811,
                "lng": -128.7243954263
            },
            {
                "lat": 52.5205989054,
                "lng": -128.7205806867
            },
            {
                "lat": 52.5229561174,
                "lng": -128.7119445199
            },
            {
                "lat": 52.5216444596,
                "lng": -128.7023313826
            },
            {
                "lat": 52.5225185669,
                "lng": -128.6931615203
            },
            {
                "lat": 52.5225183694,
                "lng": -128.6848592964
            },
            {
                "lat": 52.5336606447,
                "lng": -128.6885688754
            },
            {
                "lat": 52.5714571884,
                "lng": -128.6994931682
            },
            {
                "lat": 52.5812871851,
                "lng": -128.7318266134
            },
            {
                "lat": 52.590026892,
                "lng": -128.7319030888
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 240,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 6,
            "MANAGEMENT_SUBAREA": 18,
            "MANAGEMENT_AREA_NAME": "Subarea 6-18",
            "LABEL": "6-18",
            "FISHERY_AREA_ID": 2425,
            "OBJECTID": 384,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 54935559.7126,
            "FEATURE_LENGTH_M": 29321.4065,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.54143623076471,
            "lng": -128.7319981740647
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.4249040916,
                "lng": -131.5904078962
            },
            {
                "lat": 52.4202315349,
                "lng": -131.5869745707
            },
            {
                "lat": 52.4202314716,
                "lng": -131.5495297039
            },
            {
                "lat": 52.4281009255,
                "lng": -131.5462041978
            },
            {
                "lat": 52.4344945559,
                "lng": -131.5375519859
            },
            {
                "lat": 52.4405132055,
                "lng": -131.536987632
            },
            {
                "lat": 52.4427714809,
                "lng": -131.5458222806
            },
            {
                "lat": 52.4440882394,
                "lng": -131.5561680272
            },
            {
                "lat": 52.4437102806,
                "lng": -131.5646355784
            },
            {
                "lat": 52.4384457571,
                "lng": -131.576858029
            },
            {
                "lat": 52.4311102769,
                "lng": -131.5873868194
            },
            {
                "lat": 52.4249040916,
                "lng": -131.5904078962
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 241,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 40,
            "MANAGEMENT_AREA_NAME": "Subarea 2-40",
            "LABEL": "2-40",
            "FISHERY_AREA_ID": 1931,
            "OBJECTID": 385,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 7071857.1339,
            "FEATURE_LENGTH_M": 10382.4374,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.43279215929166,
            "lng": -131.564077884775
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.5427015294,
                "lng": -128.4417265485
            },
            {
                "lat": 52.5427051875,
                "lng": -128.4957584086
            },
            {
                "lat": 52.5432007114,
                "lng": -128.5053102301
            },
            {
                "lat": 52.5201911476,
                "lng": -128.4962625503
            },
            {
                "lat": 52.4407610475,
                "lng": -128.4980316208
            },
            {
                "lat": 52.4370836505,
                "lng": -128.5072634921
            },
            {
                "lat": 52.4347795765,
                "lng": -128.5083927648
            },
            {
                "lat": 52.4322782104,
                "lng": -128.4982140971
            },
            {
                "lat": 52.3952639335,
                "lng": -128.4785005661
            },
            {
                "lat": 52.3982884565,
                "lng": -128.4746858723
            },
            {
                "lat": 52.3968810432,
                "lng": -128.4710382745
            },
            {
                "lat": 52.3934825625,
                "lng": -128.4622650758
            },
            {
                "lat": 52.3920553769,
                "lng": -128.4463346936
            },
            {
                "lat": 52.3965756698,
                "lng": -128.4256283217
            },
            {
                "lat": 52.4020460967,
                "lng": -128.412063626
            },
            {
                "lat": 52.4203685229,
                "lng": -128.4087378652
            },
            {
                "lat": 52.4424585725,
                "lng": -128.4073036262
            },
            {
                "lat": 52.4471327199,
                "lng": -128.4130401587
            },
            {
                "lat": 52.4626998854,
                "lng": -128.4196621796
            },
            {
                "lat": 52.4678883241,
                "lng": -128.4169309736
            },
            {
                "lat": 52.4775960292,
                "lng": -128.4085382921
            },
            {
                "lat": 52.4838940119,
                "lng": -128.3825684583
            },
            {
                "lat": 52.4896695262,
                "lng": -128.3458260387
            },
            {
                "lat": 52.4883576789,
                "lng": -128.320373868
            },
            {
                "lat": 52.4862550275,
                "lng": -128.3030550317
            },
            {
                "lat": 52.4925531487,
                "lng": -128.2970129431
            },
            {
                "lat": 52.5043634603,
                "lng": -128.2964930129
            },
            {
                "lat": 52.5130239673,
                "lng": -128.3061978879
            },
            {
                "lat": 52.5164335704,
                "lng": -128.3376918724
            },
            {
                "lat": 52.5208933302,
                "lng": -128.3786319052
            },
            {
                "lat": 52.5308640929,
                "lng": -128.420348737
            },
            {
                "lat": 52.5390772622,
                "lng": -128.4385978537
            },
            {
                "lat": 52.5413208399,
                "lng": -128.441115967
            },
            {
                "lat": 52.5427015294,
                "lng": -128.4417265485
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 242,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 7-4",
            "LABEL": "7-4",
            "FISHERY_AREA_ID": 2439,
            "OBJECTID": 386,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 120177530.181,
            "FEATURE_LENGTH_M": 56533.4069,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.47164252057941,
            "lng": -128.42074498123822
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.434288627,
                "lng": -131.3037112551
            },
            {
                "lat": 52.4401057842,
                "lng": -131.3070686309
            },
            {
                "lat": 52.4458731097,
                "lng": -131.3143764227
            },
            {
                "lat": 52.4426157394,
                "lng": -131.3203123461
            },
            {
                "lat": 52.4351011042,
                "lng": -131.3582754757
            },
            {
                "lat": 52.4364549116,
                "lng": -131.3835903277
            },
            {
                "lat": 52.4341199768,
                "lng": -131.4216153994
            },
            {
                "lat": 52.4373935208,
                "lng": -131.4248806169
            },
            {
                "lat": 52.4269988409,
                "lng": -131.4354557137
            },
            {
                "lat": 52.4091531678,
                "lng": -131.450454388
            },
            {
                "lat": 52.3958284963,
                "lng": -131.4568787417
            },
            {
                "lat": 52.3865466079,
                "lng": -131.4556882623
            },
            {
                "lat": 52.3767931138,
                "lng": -131.445694717
            },
            {
                "lat": 52.3710818612,
                "lng": -131.4349820012
            },
            {
                "lat": 52.3694149041,
                "lng": -131.424041068
            },
            {
                "lat": 52.3520471242,
                "lng": -131.4214175167
            },
            {
                "lat": 52.3430063713,
                "lng": -131.4149932953
            },
            {
                "lat": 52.3425287129,
                "lng": -131.394531285
            },
            {
                "lat": 52.3471681432,
                "lng": -131.3768007158
            },
            {
                "lat": 52.3514522815,
                "lng": -131.3741910099
            },
            {
                "lat": 52.3538327068,
                "lng": -131.3696591693
            },
            {
                "lat": 52.3509748842,
                "lng": -131.3601540721
            },
            {
                "lat": 52.3500716038,
                "lng": -131.35417181
            },
            {
                "lat": 52.3507420775,
                "lng": -131.3530429803
            },
            {
                "lat": 52.352814183,
                "lng": -131.3498380278
            },
            {
                "lat": 52.3548582478,
                "lng": -131.3497470729
            },
            {
                "lat": 52.3549807487,
                "lng": -131.3487860099
            },
            {
                "lat": 52.355156177,
                "lng": -131.3473964005
            },
            {
                "lat": 52.3563494312,
                "lng": -131.3446810835
            },
            {
                "lat": 52.3644790793,
                "lng": -131.3441315465
            },
            {
                "lat": 52.3668211828,
                "lng": -131.3439638508
            },
            {
                "lat": 52.3834765942,
                "lng": -131.3446806858
            },
            {
                "lat": 52.4025119106,
                "lng": -131.3494406597
            },
            {
                "lat": 52.408458515,
                "lng": -131.3551476961
            },
            {
                "lat": 52.4146459652,
                "lng": -131.352539146
            },
            {
                "lat": 52.4194072761,
                "lng": -131.3392025186
            },
            {
                "lat": 52.4260672187,
                "lng": -131.3277889354
            },
            {
                "lat": 52.4310648021,
                "lng": -131.3192139225
            },
            {
                "lat": 52.4327775162,
                "lng": -131.3106533717
            },
            {
                "lat": 52.4334444104,
                "lng": -131.3073279576
            },
            {
                "lat": 52.434288627,
                "lng": -131.3037112551
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 243,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 2-13",
            "LABEL": "2-13",
            "FISHERY_AREA_ID": 1904,
            "OBJECTID": 387,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 66901927.2882,
            "FEATURE_LENGTH_M": 38240.5691,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.39207794039999,
            "lng": -131.36815213076102
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.4232642881,
                "lng": -131.5929710142
            },
            {
                "lat": 52.420333621,
                "lng": -131.5914151713
            },
            {
                "lat": 52.3982965692,
                "lng": -131.5914148893
            },
            {
                "lat": 52.3981091953,
                "lng": -131.5888983292
            },
            {
                "lat": 52.4002184073,
                "lng": -131.5837094891
            },
            {
                "lat": 52.4021497634,
                "lng": -131.5747068054
            },
            {
                "lat": 52.4004661735,
                "lng": -131.566284419
            },
            {
                "lat": 52.3983033332,
                "lng": -131.5532070556
            },
            {
                "lat": 52.3982045683,
                "lng": -131.5424958074
            },
            {
                "lat": 52.3991890828,
                "lng": -131.5370789999
            },
            {
                "lat": 52.4066014057,
                "lng": -131.5409695805
            },
            {
                "lat": 52.4082839378,
                "lng": -131.543808202
            },
            {
                "lat": 52.4090076651,
                "lng": -131.545027803
            },
            {
                "lat": 52.4190439868,
                "lng": -131.5450279699
            },
            {
                "lat": 52.4224849475,
                "lng": -131.5440678828
            },
            {
                "lat": 52.4281009255,
                "lng": -131.5462041978
            },
            {
                "lat": 52.4202314716,
                "lng": -131.5495297039
            },
            {
                "lat": 52.4202315349,
                "lng": -131.5869745707
            },
            {
                "lat": 52.4249040916,
                "lng": -131.5904078962
            },
            {
                "lat": 52.4232642881,
                "lng": -131.5929710142
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 244,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 39,
            "MANAGEMENT_AREA_NAME": "Subarea 2-39",
            "LABEL": "2-39",
            "FISHERY_AREA_ID": 1930,
            "OBJECTID": 388,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 7884782.7147,
            "FEATURE_LENGTH_M": 14354.0452,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.411034462835005,
            "lng": -131.56535854007
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.4416889662,
                "lng": -131.2360538686
            },
            {
                "lat": 52.4416472501,
                "lng": -131.2381130752
            },
            {
                "lat": 52.4412151895,
                "lng": -131.2416228293
            },
            {
                "lat": 52.430847581,
                "lng": -131.2502746932
            },
            {
                "lat": 52.4229926592,
                "lng": -131.260756725
            },
            {
                "lat": 52.4175221812,
                "lng": -131.2728888442
            },
            {
                "lat": 52.411098892,
                "lng": -131.2843019778
            },
            {
                "lat": 52.4022939738,
                "lng": -131.2985841084
            },
            {
                "lat": 52.391586346,
                "lng": -131.312148801
            },
            {
                "lat": 52.3825454918,
                "lng": -131.3133394024
            },
            {
                "lat": 52.3687443525,
                "lng": -131.321182753
            },
            {
                "lat": 52.3604158066,
                "lng": -131.3290408032
            },
            {
                "lat": 52.3549419411,
                "lng": -131.3321378059
            },
            {
                "lat": 52.3489912213,
                "lng": -131.3328549965
            },
            {
                "lat": 52.3473287124,
                "lng": -131.32547017
            },
            {
                "lat": 52.3520855924,
                "lng": -131.3107141045
            },
            {
                "lat": 52.3561318723,
                "lng": -131.3016817937
            },
            {
                "lat": 52.3561321156,
                "lng": -131.2950131528
            },
            {
                "lat": 52.3616024531,
                "lng": -131.2757418202
            },
            {
                "lat": 52.3675532387,
                "lng": -131.2600395074
            },
            {
                "lat": 52.3715979898,
                "lng": -131.2502746966
            },
            {
                "lat": 52.3721846456,
                "lng": -131.2461550714
            },
            {
                "lat": 52.3725358355,
                "lng": -131.2451631983
            },
            {
                "lat": 52.3726196565,
                "lng": -131.24468927
            },
            {
                "lat": 52.3259768588,
                "lng": -131.1736298038
            },
            {
                "lat": 52.3254050472,
                "lng": -131.173874359
            },
            {
                "lat": 52.3243786713,
                "lng": -131.174468697
            },
            {
                "lat": 52.3219841214,
                "lng": -131.1765897893
            },
            {
                "lat": 52.3159976563,
                "lng": -131.1787112226
            },
            {
                "lat": 52.3023142254,
                "lng": -131.1769250492
            },
            {
                "lat": 52.2927972784,
                "lng": -131.1757354299
            },
            {
                "lat": 52.2761419908,
                "lng": -131.1691890021
            },
            {
                "lat": 52.267814441,
                "lng": -131.1573029842
            },
            {
                "lat": 52.2684096663,
                "lng": -131.1376656625
            },
            {
                "lat": 52.2797086185,
                "lng": -131.1085207866
            },
            {
                "lat": 52.2846495468,
                "lng": -131.096863108
            },
            {
                "lat": 52.2881968919,
                "lng": -131.0911414984
            },
            {
                "lat": 52.2881966625,
                "lng": -131.0588685894
            },
            {
                "lat": 52.4416889662,
                "lng": -131.2360538686
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 245,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 2-14",
            "LABEL": "2-14",
            "FISHERY_AREA_ID": 1905,
            "OBJECTID": 389,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 119331415.0412,
            "FEATURE_LENGTH_M": 61936.395,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.3551272976154,
            "lng": -131.2324047004923
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.4412151895,
                "lng": -131.2416228293
            },
            {
                "lat": 52.4366190563,
                "lng": -131.2555997791
            },
            {
                "lat": 52.4344096431,
                "lng": -131.2852935935
            },
            {
                "lat": 52.434288627,
                "lng": -131.3037112551
            },
            {
                "lat": 52.4334444104,
                "lng": -131.3073279576
            },
            {
                "lat": 52.4327775162,
                "lng": -131.3106533717
            },
            {
                "lat": 52.4310648021,
                "lng": -131.3192139225
            },
            {
                "lat": 52.4260672187,
                "lng": -131.3277889354
            },
            {
                "lat": 52.4194072761,
                "lng": -131.3392025186
            },
            {
                "lat": 52.4146459652,
                "lng": -131.352539146
            },
            {
                "lat": 52.408458515,
                "lng": -131.3551476961
            },
            {
                "lat": 52.4025119106,
                "lng": -131.3494406597
            },
            {
                "lat": 52.3834765942,
                "lng": -131.3446806858
            },
            {
                "lat": 52.3668211828,
                "lng": -131.3439638508
            },
            {
                "lat": 52.3644790793,
                "lng": -131.3441315465
            },
            {
                "lat": 52.3563494312,
                "lng": -131.3446810835
            },
            {
                "lat": 52.3551597169,
                "lng": -131.3399204357
            },
            {
                "lat": 52.3494497867,
                "lng": -131.3363489268
            },
            {
                "lat": 52.345165571,
                "lng": -131.332061741
            },
            {
                "lat": 52.3385041142,
                "lng": -131.3301696011
            },
            {
                "lat": 52.3347058289,
                "lng": -131.3294529655
            },
            {
                "lat": 52.3308907186,
                "lng": -131.3287352921
            },
            {
                "lat": 52.3287466004,
                "lng": -131.3256372874
            },
            {
                "lat": 52.3286976215,
                "lng": -131.3226923192
            },
            {
                "lat": 52.3286290351,
                "lng": -131.3188632706
            },
            {
                "lat": 52.3333890894,
                "lng": -131.3164828498
            },
            {
                "lat": 52.339100245,
                "lng": -131.3131557527
            },
            {
                "lat": 52.346236807,
                "lng": -131.3112485872
            },
            {
                "lat": 52.3520855924,
                "lng": -131.3107141045
            },
            {
                "lat": 52.3473287124,
                "lng": -131.32547017
            },
            {
                "lat": 52.3489912213,
                "lng": -131.3328549965
            },
            {
                "lat": 52.3549419411,
                "lng": -131.3321378059
            },
            {
                "lat": 52.3604158066,
                "lng": -131.3290408032
            },
            {
                "lat": 52.3687443525,
                "lng": -131.321182753
            },
            {
                "lat": 52.3825454918,
                "lng": -131.3133394024
            },
            {
                "lat": 52.391586346,
                "lng": -131.312148801
            },
            {
                "lat": 52.4022939738,
                "lng": -131.2985841084
            },
            {
                "lat": 52.411098892,
                "lng": -131.2843019778
            },
            {
                "lat": 52.4175221812,
                "lng": -131.2728888442
            },
            {
                "lat": 52.4229926592,
                "lng": -131.260756725
            },
            {
                "lat": 52.430847581,
                "lng": -131.2502746932
            },
            {
                "lat": 52.4412151895,
                "lng": -131.2416228293
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 246,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 390,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 30167705.2166,
            "FEATURE_LENGTH_M": 35808.8408,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.38279336888571,
            "lng": -131.3146449017786
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.4224849475,
                "lng": -131.5440678828
            },
            {
                "lat": 52.4190439868,
                "lng": -131.5450279699
            },
            {
                "lat": 52.4090076651,
                "lng": -131.545027803
            },
            {
                "lat": 52.4082839378,
                "lng": -131.543808202
            },
            {
                "lat": 52.4066014057,
                "lng": -131.5409695805
            },
            {
                "lat": 52.3991890828,
                "lng": -131.5370789999
            },
            {
                "lat": 52.3931929874,
                "lng": -131.5262758856
            },
            {
                "lat": 52.3904384695,
                "lng": -131.5139765001
            },
            {
                "lat": 52.3894579286,
                "lng": -131.5074006871
            },
            {
                "lat": 52.3907351648,
                "lng": -131.5030673238
            },
            {
                "lat": 52.3953560411,
                "lng": -131.5000146246
            },
            {
                "lat": 52.4002721594,
                "lng": -131.4987480792
            },
            {
                "lat": 52.4063642586,
                "lng": -131.4992377321
            },
            {
                "lat": 52.410988045,
                "lng": -131.5016937084
            },
            {
                "lat": 52.4150157959,
                "lng": -131.5075991786
            },
            {
                "lat": 52.4181638792,
                "lng": -131.5145731282
            },
            {
                "lat": 52.418949513,
                "lng": -131.5201718598
            },
            {
                "lat": 52.4201311439,
                "lng": -131.5294194647
            },
            {
                "lat": 52.4217026597,
                "lng": -131.5382690643
            },
            {
                "lat": 52.4224849475,
                "lng": -131.5440678828
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 247,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 2,
            "MANAGEMENT_SUBAREA": 41,
            "MANAGEMENT_AREA_NAME": "Subarea 2-41",
            "LABEL": "2-41",
            "FISHERY_AREA_ID": 1932,
            "OBJECTID": 391,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 8065395.8632,
            "FEATURE_LENGTH_M": 11015.1994,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.407893200965,
            "lng": -131.52302477786998
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.5225183694,
                "lng": -128.6848592964
            },
            {
                "lat": 52.5225185669,
                "lng": -128.6931615203
            },
            {
                "lat": 52.5216444596,
                "lng": -128.7023313826
            },
            {
                "lat": 52.5229561174,
                "lng": -128.7119445199
            },
            {
                "lat": 52.5205989054,
                "lng": -128.7205806867
            },
            {
                "lat": 52.5142170811,
                "lng": -128.7243954263
            },
            {
                "lat": 52.5070076189,
                "lng": -128.7303010579
            },
            {
                "lat": 52.5072668921,
                "lng": -128.7407532637
            },
            {
                "lat": 52.4826849396,
                "lng": -128.7054896002
            },
            {
                "lat": 52.4822992747,
                "lng": -128.7030185042
            },
            {
                "lat": 52.4805533322,
                "lng": -128.7030185699
            },
            {
                "lat": 52.4787675884,
                "lng": -128.6984254858
            },
            {
                "lat": 52.475105024,
                "lng": -128.6927189782
            },
            {
                "lat": 52.4706462953,
                "lng": -128.6924740585
            },
            {
                "lat": 52.4612737551,
                "lng": -128.699447712
            },
            {
                "lat": 52.4545552363,
                "lng": -128.6474913757
            },
            {
                "lat": 52.4386133209,
                "lng": -128.6458282003
            },
            {
                "lat": 52.3741300022,
                "lng": -128.6446382531
            },
            {
                "lat": 52.3377266475,
                "lng": -128.6477363211
            },
            {
                "lat": 52.3053664727,
                "lng": -128.6660608644
            },
            {
                "lat": 52.2957531454,
                "lng": -128.6687784242
            },
            {
                "lat": 52.2913851439,
                "lng": -128.6703650303
            },
            {
                "lat": 52.2885784733,
                "lng": -128.6674342921
            },
            {
                "lat": 52.2872500765,
                "lng": -128.665465784
            },
            {
                "lat": 52.285625038,
                "lng": -128.6641991586
            },
            {
                "lat": 52.2831451917,
                "lng": -128.6659546589
            },
            {
                "lat": 52.2821467955,
                "lng": -128.6662447934
            },
            {
                "lat": 52.2810826414,
                "lng": -128.6665648431
            },
            {
                "lat": 52.279090402,
                "lng": -128.6656797581
            },
            {
                "lat": 52.237011048,
                "lng": -128.4412383796
            },
            {
                "lat": 52.269496998,
                "lng": -128.407287508
            },
            {
                "lat": 52.3187906844,
                "lng": -128.4456333428
            },
            {
                "lat": 52.321136523,
                "lng": -128.4430080677
            },
            {
                "lat": 52.329391475,
                "lng": -128.4417883498
            },
            {
                "lat": 52.3394087715,
                "lng": -128.443007853
            },
            {
                "lat": 52.343334384,
                "lng": -128.44653307
            },
            {
                "lat": 52.3433343298,
                "lng": -128.4508669037
            },
            {
                "lat": 52.3456349037,
                "lng": -128.4537057633
            },
            {
                "lat": 52.3507804737,
                "lng": -128.4580837049
            },
            {
                "lat": 52.3638955848,
                "lng": -128.4580839959
            },
            {
                "lat": 52.3655578974,
                "lng": -128.4544526208
            },
            {
                "lat": 52.3665310732,
                "lng": -128.4491883036
            },
            {
                "lat": 52.3674276797,
                "lng": -128.4432072985
            },
            {
                "lat": 52.3725429498,
                "lng": -128.4349054873
            },
            {
                "lat": 52.3794442003,
                "lng": -128.4320519927
            },
            {
                "lat": 52.3872949897,
                "lng": -128.4360966784
            },
            {
                "lat": 52.3920553769,
                "lng": -128.4463346936
            },
            {
                "lat": 52.3934825625,
                "lng": -128.4622650758
            },
            {
                "lat": 52.3968810432,
                "lng": -128.4710382745
            },
            {
                "lat": 52.3982884565,
                "lng": -128.4746858723
            },
            {
                "lat": 52.3952639335,
                "lng": -128.4785005661
            },
            {
                "lat": 52.4322782104,
                "lng": -128.4982140971
            },
            {
                "lat": 52.4347795765,
                "lng": -128.5083927648
            },
            {
                "lat": 52.4419823819,
                "lng": -128.5164798592
            },
            {
                "lat": 52.5170097125,
                "lng": -128.6576682698
            },
            {
                "lat": 52.5205532409,
                "lng": -128.6769860288
            },
            {
                "lat": 52.52141165,
                "lng": -128.6804350783
            },
            {
                "lat": 52.5225183694,
                "lng": -128.6848592964
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 248,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 7-3",
            "LABEL": "7-3",
            "FISHERY_AREA_ID": 2438,
            "OBJECTID": 392,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 349507350.9312,
            "FEATURE_LENGTH_M": 94713.2543,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.3986556084069,
            "lng": -128.58535105201034
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.65555606,
                "lng": -137.5284577189
            },
            {
                "lat": 51.6555548411,
                "lng": -136.5255823394
            },
            {
                "lat": 51.6555540411,
                "lng": -135.5227075947
            },
            {
                "lat": 51.6555529713,
                "lng": -134.5198321889
            },
            {
                "lat": 51.6555514113,
                "lng": -133.5169568505
            },
            {
                "lat": 51.6555504415,
                "lng": -132.514081228
            },
            {
                "lat": 51.6555494236,
                "lng": -131.5112062412
            },
            {
                "lat": 51.6555479281,
                "lng": -130.5083307374
            },
            {
                "lat": 51.9358899611,
                "lng": -131.0160824804
            },
            {
                "lat": 51.9358887634,
                "lng": -132.1516519627
            },
            {
                "lat": 51.93588799,
                "lng": -133.2872209527
            },
            {
                "lat": 51.9358866839,
                "lng": -134.4227906523
            },
            {
                "lat": 51.9358852335,
                "lng": -135.558359705
            },
            {
                "lat": 51.9358845029,
                "lng": -136.6939291084
            },
            {
                "lat": 51.935883215,
                "lng": -137.8294987037
            },
            {
                "lat": 51.7758483445,
                "lng": -137.6670073252
            },
            {
                "lat": 51.65555606,
                "lng": -137.5284577189
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 249,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 130,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 130-3",
            "LABEL": "130-3",
            "FISHERY_AREA_ID": 2345,
            "OBJECTID": 393,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 14905063408.4312,
            "FEATURE_LENGTH_M": 1037179.5253,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.778060463076464,
            "lng": -134.60600902989998
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 52.4671981706,
                "lng": -127.9324338409
            },
            {
                "lat": 52.4662130188,
                "lng": -127.9451597751
            },
            {
                "lat": 52.4528122677,
                "lng": -127.9525075086
            },
            {
                "lat": 52.4402804693,
                "lng": -127.9507829664
            },
            {
                "lat": 52.4186701184,
                "lng": -127.9412765404
            },
            {
                "lat": 52.4035457404,
                "lng": -127.9244163186
            },
            {
                "lat": 52.3897130032,
                "lng": -127.9054035293
            },
            {
                "lat": 52.3817514876,
                "lng": -127.8962404364
            },
            {
                "lat": 52.3744848,
                "lng": -127.8860246441
            },
            {
                "lat": 52.37149406,
                "lng": -127.8845066388
            },
            {
                "lat": 52.3708422605,
                "lng": -127.8841782739
            },
            {
                "lat": 52.3627315936,
                "lng": -127.8670499801
            },
            {
                "lat": 52.3564334143,
                "lng": -127.8639065039
            },
            {
                "lat": 52.3508150076,
                "lng": -127.8595887463
            },
            {
                "lat": 52.3343925155,
                "lng": -127.8509438739
            },
            {
                "lat": 52.3292053637,
                "lng": -127.8379739599
            },
            {
                "lat": 52.3326650961,
                "lng": -127.8297658758
            },
            {
                "lat": 52.3426056729,
                "lng": -127.8267371467
            },
            {
                "lat": 52.356865518,
                "lng": -127.8271706565
            },
            {
                "lat": 52.3745886805,
                "lng": -127.8297659499
            },
            {
                "lat": 52.3845258019,
                "lng": -127.8362497536
            },
            {
                "lat": 52.420833547,
                "lng": -127.855696933
            },
            {
                "lat": 52.4316366949,
                "lng": -127.8729862165
            },
            {
                "lat": 52.4346622917,
                "lng": -127.8907094704
            },
            {
                "lat": 52.4398498697,
                "lng": -127.9010770756
            },
            {
                "lat": 52.4545437959,
                "lng": -127.8803332775
            },
            {
                "lat": 52.4783130529,
                "lng": -127.8721232395
            },
            {
                "lat": 52.4761536997,
                "lng": -127.8535391927
            },
            {
                "lat": 52.463188895,
                "lng": -127.8384098217
            },
            {
                "lat": 52.4437368591,
                "lng": -127.8206864957
            },
            {
                "lat": 52.4342301103,
                "lng": -127.7964863162
            },
            {
                "lat": 52.4441678375,
                "lng": -127.7584528996
            },
            {
                "lat": 52.4493562296,
                "lng": -127.742896291
            },
            {
                "lat": 52.4355286217,
                "lng": -127.7238769463
            },
            {
                "lat": 52.4433060723,
                "lng": -127.7078859132
            },
            {
                "lat": 52.4644854599,
                "lng": -127.7009735073
            },
            {
                "lat": 52.4796102555,
                "lng": -127.7182619077
            },
            {
                "lat": 52.4912798799,
                "lng": -127.7428967282
            },
            {
                "lat": 52.4869570563,
                "lng": -127.7588884955
            },
            {
                "lat": 52.4865262789,
                "lng": -127.7774736398
            },
            {
                "lat": 52.4986266352,
                "lng": -127.7977830862
            },
            {
                "lat": 52.521103198,
                "lng": -127.8198242841
            },
            {
                "lat": 52.5241274419,
                "lng": -127.8479161354
            },
            {
                "lat": 52.5159155427,
                "lng": -127.8751443779
            },
            {
                "lat": 52.5059736981,
                "lng": -127.9015126524
            },
            {
                "lat": 52.4852297997,
                "lng": -127.9140474833
            },
            {
                "lat": 52.467940736,
                "lng": -127.9175031437
            },
            {
                "lat": 52.4618905489,
                "lng": -127.9265749156
            },
            {
                "lat": 52.4671981706,
                "lng": -127.9324338409
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 250,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 7,
            "MANAGEMENT_SUBAREA": 16,
            "MANAGEMENT_AREA_NAME": "Subarea 7-16",
            "LABEL": "7-16",
            "FISHERY_AREA_ID": 2451,
            "OBJECTID": 394,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 138457815.2106,
            "FEATURE_LENGTH_M": 79290.0348,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 52.43200421101021,
            "lng": -127.85058116746939
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.7361833024,
                "lng": -128.3418585209
            },
            {
                "lat": 51.7540318219,
                "lng": -128.8000021087
            },
            {
                "lat": 51.4802509339,
                "lng": -129.7461388703
            },
            {
                "lat": 51.4802439856,
                "lng": -128.0865629187
            },
            {
                "lat": 51.7361833024,
                "lng": -128.3418585209
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 328,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 108,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 108-1",
            "LABEL": "108-1",
            "FISHERY_AREA_ID": 2311,
            "OBJECTID": 472,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2168441746.7468,
            "FEATURE_LENGTH_M": 252505.654,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.63737866924,
            "lng": -128.66328418790002
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.7335089055,
                "lng": -127.9966355278
            },
            {
                "lat": 51.754966587,
                "lng": -128.0094144244
            },
            {
                "lat": 51.7552228752,
                "lng": -128.0103459041
            },
            {
                "lat": 51.7561880278,
                "lng": -128.0126950191
            },
            {
                "lat": 51.7567744402,
                "lng": -128.0161279503
            },
            {
                "lat": 51.7555999963,
                "lng": -128.0195777124
            },
            {
                "lat": 51.7510681567,
                "lng": -128.0246878184
            },
            {
                "lat": 51.7482563851,
                "lng": -128.0268404069
            },
            {
                "lat": 51.7476267772,
                "lng": -128.0283048608
            },
            {
                "lat": 51.7483403719,
                "lng": -128.0308989535
            },
            {
                "lat": 51.7481305485,
                "lng": -128.034056953
            },
            {
                "lat": 51.7464519385,
                "lng": -128.036361242
            },
            {
                "lat": 51.7437242809,
                "lng": -128.0349730515
            },
            {
                "lat": 51.7430074848,
                "lng": -128.0348363666
            },
            {
                "lat": 51.7361831141,
                "lng": -128.1161800418
            },
            {
                "lat": 51.6934018146,
                "lng": -128.1204371364
            },
            {
                "lat": 51.6852527907,
                "lng": -128.1307528169
            },
            {
                "lat": 51.6657295159,
                "lng": -128.130753146
            },
            {
                "lat": 51.6624528746,
                "lng": -128.1315000553
            },
            {
                "lat": 51.6591485949,
                "lng": -128.1362151666
            },
            {
                "lat": 51.6517333258,
                "lng": -128.1359102771
            },
            {
                "lat": 51.6421127047,
                "lng": -128.1327053032
            },
            {
                "lat": 51.6400988517,
                "lng": -128.1246489504
            },
            {
                "lat": 51.6305232303,
                "lng": -128.1118927421
            },
            {
                "lat": 51.6312407688,
                "lng": -128.03575108
            },
            {
                "lat": 51.6383780218,
                "lng": -127.9600749924
            },
            {
                "lat": 51.6384094897,
                "lng": -127.9577190482
            },
            {
                "lat": 51.6453593751,
                "lng": -127.9563073478
            },
            {
                "lat": 51.6468508064,
                "lng": -127.9566588347
            },
            {
                "lat": 51.6557169861,
                "lng": -127.9616015261
            },
            {
                "lat": 51.6563386062,
                "lng": -127.9618311286
            },
            {
                "lat": 51.6564023947,
                "lng": -127.9619063772
            },
            {
                "lat": 51.6567354308,
                "lng": -127.9623113007
            },
            {
                "lat": 51.6601418359,
                "lng": -127.9648067986
            },
            {
                "lat": 51.662284722,
                "lng": -127.9676596423
            },
            {
                "lat": 51.6644260731,
                "lng": -127.9819417407
            },
            {
                "lat": 51.6815576158,
                "lng": -127.9938353888
            },
            {
                "lat": 51.6977461193,
                "lng": -128.0024496831
            },
            {
                "lat": 51.7194450029,
                "lng": -127.9991538526
            },
            {
                "lat": 51.7280318664,
                "lng": -127.9942783853
            },
            {
                "lat": 51.7335089055,
                "lng": -127.9966355278
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 329,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 8-2",
            "LABEL": "8-2",
            "FISHERY_AREA_ID": 2468,
            "OBJECTID": 473,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 117427964.4548,
            "FEATURE_LENGTH_M": 46642.381,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.69580677105855,
            "lng": -128.0293091336951
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.7361831141,
                "lng": -128.1161800418
            },
            {
                "lat": 51.7361833024,
                "lng": -128.3418585209
            },
            {
                "lat": 51.4802439856,
                "lng": -128.0865629187
            },
            {
                "lat": 51.4825444142,
                "lng": -128.0844119842
            },
            {
                "lat": 51.4954988303,
                "lng": -128.0766906801
            },
            {
                "lat": 51.5333750583,
                "lng": -128.099227806
            },
            {
                "lat": 51.6400988517,
                "lng": -128.1246489504
            },
            {
                "lat": 51.6421127047,
                "lng": -128.1327053032
            },
            {
                "lat": 51.6517333258,
                "lng": -128.1359102771
            },
            {
                "lat": 51.6591485949,
                "lng": -128.1362151666
            },
            {
                "lat": 51.6624528746,
                "lng": -128.1315000553
            },
            {
                "lat": 51.6657295159,
                "lng": -128.130753146
            },
            {
                "lat": 51.6852527907,
                "lng": -128.1307528169
            },
            {
                "lat": 51.6934018146,
                "lng": -128.1204371364
            },
            {
                "lat": 51.7361831141,
                "lng": -128.1161800418
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 330,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 8-1",
            "LABEL": "8-1",
            "FISHERY_AREA_ID": 2467,
            "OBJECTID": 474,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 201514189.6862,
            "FEATURE_LENGTH_M": 78955.7348,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.633342819460005,
            "lng": -128.13093565635998
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.7218408649,
                "lng": -127.8650141396
            },
            {
                "lat": 51.7176405915,
                "lng": -127.876983738
            },
            {
                "lat": 51.7153285977,
                "lng": -127.8876886745
            },
            {
                "lat": 51.7161256689,
                "lng": -127.8910223682
            },
            {
                "lat": 51.7335089055,
                "lng": -127.9966355278
            },
            {
                "lat": 51.7280318664,
                "lng": -127.9942783853
            },
            {
                "lat": 51.7194450029,
                "lng": -127.9991538526
            },
            {
                "lat": 51.6977461193,
                "lng": -128.0024496831
            },
            {
                "lat": 51.6815576158,
                "lng": -127.9938353888
            },
            {
                "lat": 51.6644260731,
                "lng": -127.9819417407
            },
            {
                "lat": 51.662284722,
                "lng": -127.9676596423
            },
            {
                "lat": 51.6601418359,
                "lng": -127.9648067986
            },
            {
                "lat": 51.6567354308,
                "lng": -127.9623113007
            },
            {
                "lat": 51.6564023947,
                "lng": -127.9619063772
            },
            {
                "lat": 51.6563386062,
                "lng": -127.9618311286
            },
            {
                "lat": 51.6557169861,
                "lng": -127.9616015261
            },
            {
                "lat": 51.6468508064,
                "lng": -127.9566588347
            },
            {
                "lat": 51.6453593751,
                "lng": -127.9563073478
            },
            {
                "lat": 51.6384094897,
                "lng": -127.9577190482
            },
            {
                "lat": 51.6316991978,
                "lng": -127.9576875426
            },
            {
                "lat": 51.6102835176,
                "lng": -127.9338988993
            },
            {
                "lat": 51.6005288493,
                "lng": -127.9177173192
            },
            {
                "lat": 51.5988081618,
                "lng": -127.9157416136
            },
            {
                "lat": 51.6033135466,
                "lng": -127.9123995174
            },
            {
                "lat": 51.6033052506,
                "lng": -127.8638079061
            },
            {
                "lat": 51.6085661075,
                "lng": -127.8287506351
            },
            {
                "lat": 51.608566444,
                "lng": -127.8196110711
            },
            {
                "lat": 51.607384588,
                "lng": -127.8193831463
            },
            {
                "lat": 51.60403522,
                "lng": -127.8177955962
            },
            {
                "lat": 51.6035966184,
                "lng": -127.814652039
            },
            {
                "lat": 51.6030654493,
                "lng": -127.8091358599
            },
            {
                "lat": 51.6051799254,
                "lng": -127.8042915297
            },
            {
                "lat": 51.6070294051,
                "lng": -127.8028191247
            },
            {
                "lat": 51.60757909,
                "lng": -127.8024376477
            },
            {
                "lat": 51.6285445238,
                "lng": -127.8204887076
            },
            {
                "lat": 51.6296682284,
                "lng": -127.8108677279
            },
            {
                "lat": 51.6636818587,
                "lng": -127.808747374
            },
            {
                "lat": 51.6705283809,
                "lng": -127.8128903599
            },
            {
                "lat": 51.6729442146,
                "lng": -127.8228918264
            },
            {
                "lat": 51.669837994,
                "lng": -127.8294457374
            },
            {
                "lat": 51.6592724065,
                "lng": -127.8383490452
            },
            {
                "lat": 51.6582456558,
                "lng": -127.8444063134
            },
            {
                "lat": 51.6573837307,
                "lng": -127.8494811719
            },
            {
                "lat": 51.6653597775,
                "lng": -127.8580864657
            },
            {
                "lat": 51.682365386,
                "lng": -127.8715214802
            },
            {
                "lat": 51.6909756758,
                "lng": -127.8734132257
            },
            {
                "lat": 51.6923224899,
                "lng": -127.8718496322
            },
            {
                "lat": 51.7016835763,
                "lng": -127.8610230828
            },
            {
                "lat": 51.7100794944,
                "lng": -127.8494807619
            },
            {
                "lat": 51.7184797856,
                "lng": -127.8498996839
            },
            {
                "lat": 51.7218408649,
                "lng": -127.8650141396
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 331,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 8,
            "MANAGEMENT_SUBAREA": 16,
            "MANAGEMENT_AREA_NAME": "Subarea 8-16",
            "LABEL": "8-16",
            "FISHERY_AREA_ID": 2482,
            "OBJECTID": 475,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 126574619.995,
            "FEATURE_LENGTH_M": 56012.0163,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.65823620329608,
            "lng": -127.88819199385097
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.6252555272,
                "lng": -127.4358829182
            },
            {
                "lat": 51.6236118286,
                "lng": -127.4181292649
            },
            {
                "lat": 51.6315002368,
                "lng": -127.391829996
            },
            {
                "lat": 51.638733224,
                "lng": -127.3770287779
            },
            {
                "lat": 51.6377493252,
                "lng": -127.3421860519
            },
            {
                "lat": 51.6515586286,
                "lng": -127.2813561781
            },
            {
                "lat": 51.6578027923,
                "lng": -127.2488106901
            },
            {
                "lat": 51.67161238,
                "lng": -127.2244793362
            },
            {
                "lat": 51.6877218383,
                "lng": -127.2251364985
            },
            {
                "lat": 51.6958426734,
                "lng": -127.2492675261
            },
            {
                "lat": 51.696269942,
                "lng": -127.2741232739
            },
            {
                "lat": 51.7100790407,
                "lng": -127.3001018802
            },
            {
                "lat": 51.7314498547,
                "lng": -127.3158797245
            },
            {
                "lat": 51.7416416996,
                "lng": -127.3411943934
            },
            {
                "lat": 51.7396697113,
                "lng": -127.3573067566
            },
            {
                "lat": 51.7209278306,
                "lng": -127.3737483687
            },
            {
                "lat": 51.6942983226,
                "lng": -127.3924868524
            },
            {
                "lat": 51.6791770774,
                "lng": -127.4184565901
            },
            {
                "lat": 51.668655411,
                "lng": -127.4302983051
            },
            {
                "lat": 51.665367741,
                "lng": -127.4312816332
            },
            {
                "lat": 51.661346894,
                "lng": -127.4298935661
            },
            {
                "lat": 51.6572068226,
                "lng": -127.4291542489
            },
            {
                "lat": 51.6383513205,
                "lng": -127.4465339619
            },
            {
                "lat": 51.6252555272,
                "lng": -127.4358829182
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 332,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 9,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 9-6",
            "LABEL": "9-6",
            "FISHERY_AREA_ID": 2032,
            "OBJECTID": 476,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 104625485.2201,
            "FEATURE_LENGTH_M": 44542.7147,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.672961902066675,
            "lng": -127.35710207129581
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.6960140627,
                "lng": -127.4565734296
            },
            {
                "lat": 51.7001034409,
                "lng": -127.4640654348
            },
            {
                "lat": 51.7002105045,
                "lng": -127.4719314717
            },
            {
                "lat": 51.6999967212,
                "lng": -127.4788891716
            },
            {
                "lat": 51.6985772195,
                "lng": -127.4830708015
            },
            {
                "lat": 51.6991570197,
                "lng": -127.4890824707
            },
            {
                "lat": 51.7022168444,
                "lng": -127.4926146924
            },
            {
                "lat": 51.7060198055,
                "lng": -127.503029064
            },
            {
                "lat": 51.7092211412,
                "lng": -127.517838081
            },
            {
                "lat": 51.7118266997,
                "lng": -127.5292429068
            },
            {
                "lat": 51.7090222752,
                "lng": -127.5392540777
            },
            {
                "lat": 51.7112233994,
                "lng": -127.5504610739
            },
            {
                "lat": 51.7170292182,
                "lng": -127.5588683355
            },
            {
                "lat": 51.7162283658,
                "lng": -127.564277134
            },
            {
                "lat": 51.7108229334,
                "lng": -127.5730812432
            },
            {
                "lat": 51.710422709,
                "lng": -127.5820837856
            },
            {
                "lat": 51.7144273266,
                "lng": -127.6016999998
            },
            {
                "lat": 51.7156298797,
                "lng": -127.6143110896
            },
            {
                "lat": 51.712059425,
                "lng": -127.6224739437
            },
            {
                "lat": 51.7153084099,
                "lng": -127.6560825303
            },
            {
                "lat": 51.7124450949,
                "lng": -127.6667867171
            },
            {
                "lat": 51.7036170567,
                "lng": -127.6733634543
            },
            {
                "lat": 51.6982152125,
                "lng": -127.6739579153
            },
            {
                "lat": 51.6944122927,
                "lng": -127.6497425216
            },
            {
                "lat": 51.691608822,
                "lng": -127.6205218047
            },
            {
                "lat": 51.6890072064,
                "lng": -127.5858920558
            },
            {
                "lat": 51.67699776,
                "lng": -127.5622714336
            },
            {
                "lat": 51.6745947327,
                "lng": -127.5484620644
            },
            {
                "lat": 51.6824041437,
                "lng": -127.5312498659
            },
            {
                "lat": 51.685203877,
                "lng": -127.5226438723
            },
            {
                "lat": 51.683803353,
                "lng": -127.5150371055
            },
            {
                "lat": 51.6818008875,
                "lng": -127.5000225958
            },
            {
                "lat": 51.6795997116,
                "lng": -127.4852137355
            },
            {
                "lat": 51.6800000149,
                "lng": -127.4764024814
            },
            {
                "lat": 51.6817320132,
                "lng": -127.4656140514
            },
            {
                "lat": 51.6854367598,
                "lng": -127.4579622801
            },
            {
                "lat": 51.6960140627,
                "lng": -127.4565734296
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 333,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 9,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 9-9",
            "LABEL": "9-9",
            "FISHERY_AREA_ID": 2035,
            "OBJECTID": 477,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 39726054.1132,
            "FEATURE_LENGTH_M": 35083.1573,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.698713794670276,
            "lng": -127.54434184112702
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.669837994,
                "lng": -127.8294457374
            },
            {
                "lat": 51.6729442146,
                "lng": -127.8228918264
            },
            {
                "lat": 51.6705283809,
                "lng": -127.8128903599
            },
            {
                "lat": 51.6656911906,
                "lng": -127.8015215797
            },
            {
                "lat": 51.6508633293,
                "lng": -127.8056557867
            },
            {
                "lat": 51.63534538,
                "lng": -127.808067407
            },
            {
                "lat": 51.6296682284,
                "lng": -127.8108677279
            },
            {
                "lat": 51.6296535219,
                "lng": -127.8108752678
            },
            {
                "lat": 51.6285445238,
                "lng": -127.8204887076
            },
            {
                "lat": 51.60757909,
                "lng": -127.8024376477
            },
            {
                "lat": 51.6070294051,
                "lng": -127.8028191247
            },
            {
                "lat": 51.6051799254,
                "lng": -127.8042915297
            },
            {
                "lat": 51.6030654493,
                "lng": -127.8091358599
            },
            {
                "lat": 51.6035966184,
                "lng": -127.814652039
            },
            {
                "lat": 51.60403522,
                "lng": -127.8177955962
            },
            {
                "lat": 51.607384588,
                "lng": -127.8193831463
            },
            {
                "lat": 51.608566444,
                "lng": -127.8196110711
            },
            {
                "lat": 51.6085661075,
                "lng": -127.8287506351
            },
            {
                "lat": 51.6033052506,
                "lng": -127.8638079061
            },
            {
                "lat": 51.6033135466,
                "lng": -127.9123995174
            },
            {
                "lat": 51.5988081618,
                "lng": -127.9157416136
            },
            {
                "lat": 51.5827859474,
                "lng": -127.9114079121
            },
            {
                "lat": 51.5566905066,
                "lng": -127.9101329762
            },
            {
                "lat": 51.5482898858,
                "lng": -127.9159391819
            },
            {
                "lat": 51.5401991516,
                "lng": -127.933311239
            },
            {
                "lat": 51.5313945923,
                "lng": -127.9454422524
            },
            {
                "lat": 51.5233083888,
                "lng": -127.9433056901
            },
            {
                "lat": 51.5187865432,
                "lng": -127.9230808449
            },
            {
                "lat": 51.5232818776,
                "lng": -127.9085009332
            },
            {
                "lat": 51.5183942386,
                "lng": -127.7887496579
            },
            {
                "lat": 51.5171246278,
                "lng": -127.7811588644
            },
            {
                "lat": 51.5176003804,
                "lng": -127.7814487256
            },
            {
                "lat": 51.5178984386,
                "lng": -127.7817990481
            },
            {
                "lat": 51.5183644004,
                "lng": -127.7819974243
            },
            {
                "lat": 51.5204619385,
                "lng": -127.7827372595
            },
            {
                "lat": 51.5223500565,
                "lng": -127.7812277619
            },
            {
                "lat": 51.5241817484,
                "lng": -127.776649346
            },
            {
                "lat": 51.5249938758,
                "lng": -127.774619712
            },
            {
                "lat": 51.5272143171,
                "lng": -127.7747119518
            },
            {
                "lat": 51.5295216285,
                "lng": -127.7748104038
            },
            {
                "lat": 51.5372621888,
                "lng": -127.7765042379
            },
            {
                "lat": 51.5450023987,
                "lng": -127.7768862852
            },
            {
                "lat": 51.5525551381,
                "lng": -127.7695233513
            },
            {
                "lat": 51.5582204405,
                "lng": -127.7585674105
            },
            {
                "lat": 51.5608672686,
                "lng": -127.7531970273
            },
            {
                "lat": 51.5888559158,
                "lng": -127.7352985514
            },
            {
                "lat": 51.5918887683,
                "lng": -127.7201302209
            },
            {
                "lat": 51.5943032181,
                "lng": -127.6687390391
            },
            {
                "lat": 51.598441644,
                "lng": -127.6521919563
            },
            {
                "lat": 51.621204135,
                "lng": -127.6559830871
            },
            {
                "lat": 51.658104381,
                "lng": -127.6894379937
            },
            {
                "lat": 51.677761114,
                "lng": -127.6973643055
            },
            {
                "lat": 51.6929360681,
                "lng": -127.7256477187
            },
            {
                "lat": 51.6981083181,
                "lng": -127.7573775729
            },
            {
                "lat": 51.7032819848,
                "lng": -127.7977210548
            },
            {
                "lat": 51.7019043658,
                "lng": -127.8215181644
            },
            {
                "lat": 51.691902115,
                "lng": -127.839111336
            },
            {
                "lat": 51.6725886613,
                "lng": -127.841522408
            },
            {
                "lat": 51.669837994,
                "lng": -127.8294457374
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 334,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 9,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 9-12",
            "LABEL": "9-12",
            "FISHERY_AREA_ID": 2037,
            "OBJECTID": 478,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 210810616.4974,
            "FEATURE_LENGTH_M": 78145.8095,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.59476907173052,
            "lng": -127.8062835378085
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.6960141794,
                "lng": -127.4360884009
            },
            {
                "lat": 51.6960140627,
                "lng": -127.4565734296
            },
            {
                "lat": 51.6854367598,
                "lng": -127.4579622801
            },
            {
                "lat": 51.6817320132,
                "lng": -127.4656140514
            },
            {
                "lat": 51.6763874984,
                "lng": -127.4683982479
            },
            {
                "lat": 51.6750033131,
                "lng": -127.4699939214
            },
            {
                "lat": 51.6647868914,
                "lng": -127.4742054699
            },
            {
                "lat": 51.6589812533,
                "lng": -127.4834137296
            },
            {
                "lat": 51.6492045852,
                "lng": -127.4967572479
            },
            {
                "lat": 51.6471636816,
                "lng": -127.4998854972
            },
            {
                "lat": 51.6453059236,
                "lng": -127.5027311602
            },
            {
                "lat": 51.6442870527,
                "lng": -127.5054244452
            },
            {
                "lat": 51.6232267397,
                "lng": -127.5047224053
            },
            {
                "lat": 51.6179506553,
                "lng": -127.5004265478
            },
            {
                "lat": 51.6183508206,
                "lng": -127.4924160644
            },
            {
                "lat": 51.61754986,
                "lng": -127.4758075291
            },
            {
                "lat": 51.6193500767,
                "lng": -127.4559863594
            },
            {
                "lat": 51.622752896,
                "lng": -127.4455789869
            },
            {
                "lat": 51.6252555272,
                "lng": -127.4358829182
            },
            {
                "lat": 51.6383513205,
                "lng": -127.4465339619
            },
            {
                "lat": 51.6572068226,
                "lng": -127.4291542489
            },
            {
                "lat": 51.661346894,
                "lng": -127.4298935661
            },
            {
                "lat": 51.6609459486,
                "lng": -127.432434095
            },
            {
                "lat": 51.6627310266,
                "lng": -127.4347456374
            },
            {
                "lat": 51.6667216682,
                "lng": -127.4411541982
            },
            {
                "lat": 51.6690338354,
                "lng": -127.4460830773
            },
            {
                "lat": 51.6718673182,
                "lng": -127.4468236355
            },
            {
                "lat": 51.6774330936,
                "lng": -127.4425127614
            },
            {
                "lat": 51.6870919484,
                "lng": -127.4371643142
            },
            {
                "lat": 51.6902013898,
                "lng": -127.4350513932
            },
            {
                "lat": 51.6960141794,
                "lng": -127.4360884009
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 335,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 9,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 9-5",
            "LABEL": "9-5",
            "FISHERY_AREA_ID": 2031,
            "OBJECTID": 479,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 26864195.9294,
            "FEATURE_LENGTH_M": 25231.7579,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.65818384629677,
            "lng": -127.46082283814195
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.6296682284,
                "lng": -127.8108677279
            },
            {
                "lat": 51.63534538,
                "lng": -127.808067407
            },
            {
                "lat": 51.6508633293,
                "lng": -127.8056557867
            },
            {
                "lat": 51.6656911906,
                "lng": -127.8015215797
            },
            {
                "lat": 51.6705283809,
                "lng": -127.8128903599
            },
            {
                "lat": 51.6636818587,
                "lng": -127.808747374
            },
            {
                "lat": 51.6296682284,
                "lng": -127.8108677279
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 336,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 480,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1227137.6466,
            "FEATURE_LENGTH_M": 9636.5772,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.649349513757144,
            "lng": -127.80837399472857
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.6179506553,
                "lng": -127.5004265478
            },
            {
                "lat": 51.6232267397,
                "lng": -127.5047224053
            },
            {
                "lat": 51.6442870527,
                "lng": -127.5054244452
            },
            {
                "lat": 51.6453059236,
                "lng": -127.5027311602
            },
            {
                "lat": 51.6471636816,
                "lng": -127.4998854972
            },
            {
                "lat": 51.6539311862,
                "lng": -127.5019299369
            },
            {
                "lat": 51.6633677249,
                "lng": -127.5248633879
            },
            {
                "lat": 51.67092178,
                "lng": -127.5456306063
            },
            {
                "lat": 51.6655272672,
                "lng": -127.5575025995
            },
            {
                "lat": 51.6250648108,
                "lng": -127.5731502824
            },
            {
                "lat": 51.5835230461,
                "lng": -127.585555943
            },
            {
                "lat": 51.5773090114,
                "lng": -127.5830304589
            },
            {
                "lat": 51.5746198413,
                "lng": -127.5803379743
            },
            {
                "lat": 51.5728304395,
                "lng": -127.5803603583
            },
            {
                "lat": 51.5673248592,
                "lng": -127.5804216669
            },
            {
                "lat": 51.5660208862,
                "lng": -127.5799476895
            },
            {
                "lat": 51.5654567734,
                "lng": -127.577903888
            },
            {
                "lat": 51.5653682405,
                "lng": -127.5750581894
            },
            {
                "lat": 51.5658723824,
                "lng": -127.5731655593
            },
            {
                "lat": 51.569335463,
                "lng": -127.5729291337
            },
            {
                "lat": 51.5704915123,
                "lng": -127.5723347295
            },
            {
                "lat": 51.5707593301,
                "lng": -127.5717094548
            },
            {
                "lat": 51.5708161065,
                "lng": -127.5710910667
            },
            {
                "lat": 51.5708547526,
                "lng": -127.5702814887
            },
            {
                "lat": 51.5702630665,
                "lng": -127.5305250688
            },
            {
                "lat": 51.5701677688,
                "lng": -127.5238644966
            },
            {
                "lat": 51.570724439,
                "lng": -127.521292913
            },
            {
                "lat": 51.5766184115,
                "lng": -127.5178832543
            },
            {
                "lat": 51.5813983036,
                "lng": -127.5128102058
            },
            {
                "lat": 51.5886497673,
                "lng": -127.5054394451
            },
            {
                "lat": 51.6032148699,
                "lng": -127.5022043188
            },
            {
                "lat": 51.6179506553,
                "lng": -127.5004265478
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 337,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 9,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 9-4",
            "LABEL": "9-4",
            "FISHERY_AREA_ID": 2483,
            "OBJECTID": 481,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 49840517.9894,
            "FEATURE_LENGTH_M": 30380.1638,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.5976973983875,
            "lng": -127.54390127249687
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.6384094897,
                "lng": -127.9577190482
            },
            {
                "lat": 51.6383780218,
                "lng": -127.9600749924
            },
            {
                "lat": 51.6312407688,
                "lng": -128.03575108
            },
            {
                "lat": 51.6305232303,
                "lng": -128.1118927421
            },
            {
                "lat": 51.6400988517,
                "lng": -128.1246489504
            },
            {
                "lat": 51.5333750583,
                "lng": -128.099227806
            },
            {
                "lat": 51.4954988303,
                "lng": -128.0766906801
            },
            {
                "lat": 51.4890435959,
                "lng": -128.0502619633
            },
            {
                "lat": 51.4833335016,
                "lng": -128.0202797157
            },
            {
                "lat": 51.489757329,
                "lng": -128.0014794564
            },
            {
                "lat": 51.4764323574,
                "lng": -127.9743570218
            },
            {
                "lat": 51.4704813871,
                "lng": -127.9588854505
            },
            {
                "lat": 51.4588235654,
                "lng": -127.9434206338
            },
            {
                "lat": 51.441691839,
                "lng": -127.931289119
            },
            {
                "lat": 51.4403834294,
                "lng": -127.927742339
            },
            {
                "lat": 51.4336009234,
                "lng": -127.9093925633
            },
            {
                "lat": 51.4313390551,
                "lng": -127.9001158959
            },
            {
                "lat": 51.4403832502,
                "lng": -127.9022597266
            },
            {
                "lat": 51.4529343924,
                "lng": -127.8916471252
            },
            {
                "lat": 51.4878574009,
                "lng": -127.9000167908
            },
            {
                "lat": 51.5187865432,
                "lng": -127.9230808449
            },
            {
                "lat": 51.5233083888,
                "lng": -127.9433056901
            },
            {
                "lat": 51.5313945923,
                "lng": -127.9454422524
            },
            {
                "lat": 51.5401991516,
                "lng": -127.933311239
            },
            {
                "lat": 51.5482898858,
                "lng": -127.9159391819
            },
            {
                "lat": 51.5566905066,
                "lng": -127.9101329762
            },
            {
                "lat": 51.5827859474,
                "lng": -127.9114079121
            },
            {
                "lat": 51.5988081618,
                "lng": -127.9157416136
            },
            {
                "lat": 51.6005288493,
                "lng": -127.9177173192
            },
            {
                "lat": 51.6102835176,
                "lng": -127.9338988993
            },
            {
                "lat": 51.6316991978,
                "lng": -127.9576875426
            },
            {
                "lat": 51.6384094897,
                "lng": -127.9577190482
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 338,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 482,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 224350805.9858,
            "FEATURE_LENGTH_M": 70855.7921,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.533899078425,
            "lng": -127.963829300625
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.6285445238,
                "lng": -127.8204887076
            },
            {
                "lat": 51.6296535219,
                "lng": -127.8108752678
            },
            {
                "lat": 51.6296682284,
                "lng": -127.8108677279
            },
            {
                "lat": 51.6285445238,
                "lng": -127.8204887076
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 339,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 483,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 512.4144,
            "FEATURE_LENGTH_M": 1354.5412,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.629102699475,
            "lng": -127.81568010272501
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.5673248592,
                "lng": -127.5804216669
            },
            {
                "lat": 51.5728304395,
                "lng": -127.5803603583
            },
            {
                "lat": 51.5746198413,
                "lng": -127.5803379743
            },
            {
                "lat": 51.5773090114,
                "lng": -127.5830304589
            },
            {
                "lat": 51.5835230461,
                "lng": -127.585555943
            },
            {
                "lat": 51.5835230418,
                "lng": -127.5909506742
            },
            {
                "lat": 51.5762401928,
                "lng": -127.5974276424
            },
            {
                "lat": 51.5706323415,
                "lng": -127.6050039092
            },
            {
                "lat": 51.5313756898,
                "lng": -127.7342447981
            },
            {
                "lat": 51.5344958032,
                "lng": -127.7368934972
            },
            {
                "lat": 51.5438885258,
                "lng": -127.7414170209
            },
            {
                "lat": 51.5506790643,
                "lng": -127.7534102432
            },
            {
                "lat": 51.5525551381,
                "lng": -127.7695233513
            },
            {
                "lat": 51.5450023987,
                "lng": -127.7768862852
            },
            {
                "lat": 51.5372621888,
                "lng": -127.7765042379
            },
            {
                "lat": 51.5295216285,
                "lng": -127.7748104038
            },
            {
                "lat": 51.5272143171,
                "lng": -127.7747119518
            },
            {
                "lat": 51.5249938758,
                "lng": -127.774619712
            },
            {
                "lat": 51.5241817484,
                "lng": -127.776649346
            },
            {
                "lat": 51.5223500565,
                "lng": -127.7812277619
            },
            {
                "lat": 51.5204619385,
                "lng": -127.7827372595
            },
            {
                "lat": 51.5183644004,
                "lng": -127.7819974243
            },
            {
                "lat": 51.5178984386,
                "lng": -127.7817990481
            },
            {
                "lat": 51.5176003804,
                "lng": -127.7814487256
            },
            {
                "lat": 51.5171246278,
                "lng": -127.7811588644
            },
            {
                "lat": 51.4895746521,
                "lng": -127.7683033386
            },
            {
                "lat": 51.4895330428,
                "lng": -127.7674786384
            },
            {
                "lat": 51.4898679611,
                "lng": -127.7664716102
            },
            {
                "lat": 51.4904140312,
                "lng": -127.7638251206
            },
            {
                "lat": 51.491226742,
                "lng": -127.7611306234
            },
            {
                "lat": 51.491878476,
                "lng": -127.758972327
            },
            {
                "lat": 51.4923595164,
                "lng": -127.7579809244
            },
            {
                "lat": 51.4950718293,
                "lng": -127.7551121749
            },
            {
                "lat": 51.4978294633,
                "lng": -127.7513360358
            },
            {
                "lat": 51.4984396238,
                "lng": -127.745917993
            },
            {
                "lat": 51.4977190993,
                "lng": -127.7381210393
            },
            {
                "lat": 51.5005574661,
                "lng": -127.7275995569
            },
            {
                "lat": 51.500381725,
                "lng": -127.7244647506
            },
            {
                "lat": 51.5006900218,
                "lng": -127.7221144227
            },
            {
                "lat": 51.5025329552,
                "lng": -127.7202757571
            },
            {
                "lat": 51.504466792,
                "lng": -127.7177280855
            },
            {
                "lat": 51.5054896806,
                "lng": -127.7137451732
            },
            {
                "lat": 51.5064082799,
                "lng": -127.7117996755
            },
            {
                "lat": 51.5056530664,
                "lng": -127.7087024754
            },
            {
                "lat": 51.5069880698,
                "lng": -127.703751075
            },
            {
                "lat": 51.5062978672,
                "lng": -127.6991423986
            },
            {
                "lat": 51.5089344691,
                "lng": -127.6965413148
            },
            {
                "lat": 51.5078925376,
                "lng": -127.6943584532
            },
            {
                "lat": 51.5040929992,
                "lng": -127.6864248565
            },
            {
                "lat": 51.5041920346,
                "lng": -127.6859895091
            },
            {
                "lat": 51.5043557341,
                "lng": -127.6852788836
            },
            {
                "lat": 51.5045850714,
                "lng": -127.6843038611
            },
            {
                "lat": 51.5048183839,
                "lng": -127.6830972235
            },
            {
                "lat": 51.5056226616,
                "lng": -127.6810304514
            },
            {
                "lat": 51.505000822,
                "lng": -127.6802447252
            },
            {
                "lat": 51.5038982895,
                "lng": -127.6788401516
            },
            {
                "lat": 51.5026890111,
                "lng": -127.6772918116
            },
            {
                "lat": 51.4992404423,
                "lng": -127.6762011446
            },
            {
                "lat": 51.4972266684,
                "lng": -127.6743014883
            },
            {
                "lat": 51.4968831874,
                "lng": -127.6718289061
            },
            {
                "lat": 51.4984364364,
                "lng": -127.6672292027
            },
            {
                "lat": 51.5003887385,
                "lng": -127.6634900126
            },
            {
                "lat": 51.5044139125,
                "lng": -127.6577609308
            },
            {
                "lat": 51.5055469166,
                "lng": -127.6481322292
            },
            {
                "lat": 51.5080034136,
                "lng": -127.6377488085
            },
            {
                "lat": 51.5181963726,
                "lng": -127.62698275
            },
            {
                "lat": 51.5363199298,
                "lng": -127.6056519552
            },
            {
                "lat": 51.5508582987,
                "lng": -127.59149163
            },
            {
                "lat": 51.5602949315,
                "lng": -127.5843204009
            },
            {
                "lat": 51.5673248592,
                "lng": -127.5804216669
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 340,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 9,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 9-11",
            "LABEL": "9-11",
            "FISHERY_AREA_ID": 2484,
            "OBJECTID": 484,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 64798941.319,
            "FEATURE_LENGTH_M": 44263.4024,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.51976527824571,
            "lng": -127.70122948745572
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.5813983036,
                "lng": -127.5128102058
            },
            {
                "lat": 51.5766184115,
                "lng": -127.5178832543
            },
            {
                "lat": 51.570724439,
                "lng": -127.521292913
            },
            {
                "lat": 51.5701677688,
                "lng": -127.5238644966
            },
            {
                "lat": 51.5702630665,
                "lng": -127.5305250688
            },
            {
                "lat": 51.5708547526,
                "lng": -127.5702814887
            },
            {
                "lat": 51.5708161065,
                "lng": -127.5710910667
            },
            {
                "lat": 51.5707593301,
                "lng": -127.5717094548
            },
            {
                "lat": 51.5704915123,
                "lng": -127.5723347295
            },
            {
                "lat": 51.569335463,
                "lng": -127.5729291337
            },
            {
                "lat": 51.5658723824,
                "lng": -127.5731655593
            },
            {
                "lat": 51.5598792931,
                "lng": -127.5727769406
            },
            {
                "lat": 51.5517126127,
                "lng": -127.5701208513
            },
            {
                "lat": 51.5389292146,
                "lng": -127.5707927441
            },
            {
                "lat": 51.532928892,
                "lng": -127.5729073989
            },
            {
                "lat": 51.5274853282,
                "lng": -127.5777966874
            },
            {
                "lat": 51.5249291533,
                "lng": -127.5794597049
            },
            {
                "lat": 51.5240290908,
                "lng": -127.5809249199
            },
            {
                "lat": 51.5194365191,
                "lng": -127.5819632954
            },
            {
                "lat": 51.509219639,
                "lng": -127.5558541536
            },
            {
                "lat": 51.5066294126,
                "lng": -127.549224952
            },
            {
                "lat": 51.4795488068,
                "lng": -127.5531771844
            },
            {
                "lat": 51.4843056414,
                "lng": -127.5479425139
            },
            {
                "lat": 51.4900169982,
                "lng": -127.5386576676
            },
            {
                "lat": 51.487639724,
                "lng": -127.5253373313
            },
            {
                "lat": 51.4946176768,
                "lng": -127.5144121371
            },
            {
                "lat": 51.5146329121,
                "lng": -127.5086284971
            },
            {
                "lat": 51.5334437482,
                "lng": -127.5009073032
            },
            {
                "lat": 51.5599713286,
                "lng": -127.5028381535
            },
            {
                "lat": 51.5813983036,
                "lng": -127.5128102058
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 341,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 9,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 9-3",
            "LABEL": "9-3",
            "FISHERY_AREA_ID": 2030,
            "OBJECTID": 485,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 39223091.575,
            "FEATURE_LENGTH_M": 29958.6848,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.54026852771334,
            "lng": -127.54848066710666
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.5658723824,
                "lng": -127.5731655593
            },
            {
                "lat": 51.5653682405,
                "lng": -127.5750581894
            },
            {
                "lat": 51.5654567734,
                "lng": -127.577903888
            },
            {
                "lat": 51.5660208862,
                "lng": -127.5799476895
            },
            {
                "lat": 51.5673248592,
                "lng": -127.5804216669
            },
            {
                "lat": 51.5602949315,
                "lng": -127.5843204009
            },
            {
                "lat": 51.5508582987,
                "lng": -127.59149163
            },
            {
                "lat": 51.5363199298,
                "lng": -127.6056519552
            },
            {
                "lat": 51.5181963726,
                "lng": -127.62698275
            },
            {
                "lat": 51.5080034136,
                "lng": -127.6377488085
            },
            {
                "lat": 51.5055469166,
                "lng": -127.6481322292
            },
            {
                "lat": 51.5044139125,
                "lng": -127.6577609308
            },
            {
                "lat": 51.5003887385,
                "lng": -127.6634900126
            },
            {
                "lat": 51.5013655186,
                "lng": -127.6563035915
            },
            {
                "lat": 51.500823793,
                "lng": -127.6537017709
            },
            {
                "lat": 51.4991152356,
                "lng": -127.6525114844
            },
            {
                "lat": 51.4955482053,
                "lng": -127.6503672044
            },
            {
                "lat": 51.4948309963,
                "lng": -127.6441801445
            },
            {
                "lat": 51.4984010562,
                "lng": -127.6358563368
            },
            {
                "lat": 51.5033992088,
                "lng": -127.6265722646
            },
            {
                "lat": 51.5081590993,
                "lng": -127.6234817544
            },
            {
                "lat": 51.5124398189,
                "lng": -127.6194385897
            },
            {
                "lat": 51.5086333261,
                "lng": -127.6163406659
            },
            {
                "lat": 51.5053023998,
                "lng": -127.6132507759
            },
            {
                "lat": 51.5053024289,
                "lng": -127.6103976716
            },
            {
                "lat": 51.507205556,
                "lng": -127.6080171432
            },
            {
                "lat": 51.5138704068,
                "lng": -127.6084905297
            },
            {
                "lat": 51.5159950088,
                "lng": -127.6053995791
            },
            {
                "lat": 51.5164876311,
                "lng": -127.6046824315
            },
            {
                "lat": 51.5162234276,
                "lng": -127.6038429975
            },
            {
                "lat": 51.515296829,
                "lng": -127.6008761289
            },
            {
                "lat": 51.5157705934,
                "lng": -127.597786142
            },
            {
                "lat": 51.5172499074,
                "lng": -127.5968395247
            },
            {
                "lat": 51.5183911382,
                "lng": -127.596116019
            },
            {
                "lat": 51.5243377584,
                "lng": -127.5968323267
            },
            {
                "lat": 51.5321921955,
                "lng": -127.5915989246
            },
            {
                "lat": 51.5428965973,
                "lng": -127.5870747041
            },
            {
                "lat": 51.5495602676,
                "lng": -127.5832667545
            },
            {
                "lat": 51.5497977707,
                "lng": -127.5777962598
            },
            {
                "lat": 51.5469440369,
                "lng": -127.5749428446
            },
            {
                "lat": 51.5371852034,
                "lng": -127.5758894033
            },
            {
                "lat": 51.530048661,
                "lng": -127.5794602752
            },
            {
                "lat": 51.5240290908,
                "lng": -127.5809249199
            },
            {
                "lat": 51.5249291533,
                "lng": -127.5794597049
            },
            {
                "lat": 51.5274853282,
                "lng": -127.5777966874
            },
            {
                "lat": 51.532928892,
                "lng": -127.5729073989
            },
            {
                "lat": 51.5389292146,
                "lng": -127.5707927441
            },
            {
                "lat": 51.5517126127,
                "lng": -127.5701208513
            },
            {
                "lat": 51.5598792931,
                "lng": -127.5727769406
            },
            {
                "lat": 51.5658723824,
                "lng": -127.5731655593
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 342,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 486,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 11260218.7676,
            "FEATURE_LENGTH_M": 30061.1941,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.52645211397001,
            "lng": -127.60382669519394
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.5003887385,
                "lng": -127.6634900126
            },
            {
                "lat": 51.4984364364,
                "lng": -127.6672292027
            },
            {
                "lat": 51.4968831874,
                "lng": -127.6718289061
            },
            {
                "lat": 51.4972266684,
                "lng": -127.6743014883
            },
            {
                "lat": 51.4992404423,
                "lng": -127.6762011446
            },
            {
                "lat": 51.5026890111,
                "lng": -127.6772918116
            },
            {
                "lat": 51.5038982895,
                "lng": -127.6788401516
            },
            {
                "lat": 51.505000822,
                "lng": -127.6802447252
            },
            {
                "lat": 51.5056226616,
                "lng": -127.6810304514
            },
            {
                "lat": 51.5048183839,
                "lng": -127.6830972235
            },
            {
                "lat": 51.5045850714,
                "lng": -127.6843038611
            },
            {
                "lat": 51.5043557341,
                "lng": -127.6852788836
            },
            {
                "lat": 51.5041920346,
                "lng": -127.6859895091
            },
            {
                "lat": 51.5040929992,
                "lng": -127.6864248565
            },
            {
                "lat": 51.5078925376,
                "lng": -127.6943584532
            },
            {
                "lat": 51.5089344691,
                "lng": -127.6965413148
            },
            {
                "lat": 51.5062978672,
                "lng": -127.6991423986
            },
            {
                "lat": 51.504665476,
                "lng": -127.7032085387
            },
            {
                "lat": 51.5046656439,
                "lng": -127.7102278085
            },
            {
                "lat": 51.5022665649,
                "lng": -127.7154006212
            },
            {
                "lat": 51.4982034737,
                "lng": -127.7181699659
            },
            {
                "lat": 51.4928473378,
                "lng": -127.7181702056
            },
            {
                "lat": 51.484169807,
                "lng": -127.723526481
            },
            {
                "lat": 51.4780729439,
                "lng": -127.7227860343
            },
            {
                "lat": 51.4760439111,
                "lng": -127.7196427201
            },
            {
                "lat": 51.4708898325,
                "lng": -127.7225953618
            },
            {
                "lat": 51.4677538383,
                "lng": -127.7225953391
            },
            {
                "lat": 51.4663733047,
                "lng": -127.7223503805
            },
            {
                "lat": 51.4627001113,
                "lng": -127.7306366214
            },
            {
                "lat": 51.463298856,
                "lng": -127.7310024764
            },
            {
                "lat": 51.4641568101,
                "lng": -127.7324371132
            },
            {
                "lat": 51.4612846687,
                "lng": -127.7355881241
            },
            {
                "lat": 51.4579350929,
                "lng": -127.7377010762
            },
            {
                "lat": 51.4562379803,
                "lng": -127.7374648413
            },
            {
                "lat": 51.4559179467,
                "lng": -127.7372207249
            },
            {
                "lat": 51.4558070996,
                "lng": -127.7369839598
            },
            {
                "lat": 51.4534802333,
                "lng": -127.7450717323
            },
            {
                "lat": 51.377063924,
                "lng": -127.7462841178
            },
            {
                "lat": 51.3718752846,
                "lng": -127.7382958783
            },
            {
                "lat": 51.3764994737,
                "lng": -127.7195813982
            },
            {
                "lat": 51.3795016704,
                "lng": -127.6949010339
            },
            {
                "lat": 51.3698277162,
                "lng": -127.6862258456
            },
            {
                "lat": 51.3621557046,
                "lng": -127.6742176051
            },
            {
                "lat": 51.3628238838,
                "lng": -127.6525420447
            },
            {
                "lat": 51.3845028835,
                "lng": -127.631194888
            },
            {
                "lat": 51.4221959571,
                "lng": -127.6038435634
            },
            {
                "lat": 51.4348678999,
                "lng": -127.5898365281
            },
            {
                "lat": 51.4505427206,
                "lng": -127.5694876659
            },
            {
                "lat": 51.4576303377,
                "lng": -127.5650110204
            },
            {
                "lat": 51.4729190347,
                "lng": -127.5657881851
            },
            {
                "lat": 51.4749601458,
                "lng": -127.5624085746
            },
            {
                "lat": 51.4795488068,
                "lng": -127.5531771844
            },
            {
                "lat": 51.5066294126,
                "lng": -127.549224952
            },
            {
                "lat": 51.509219639,
                "lng": -127.5558541536
            },
            {
                "lat": 51.5194365191,
                "lng": -127.5819632954
            },
            {
                "lat": 51.5240290908,
                "lng": -127.5809249199
            },
            {
                "lat": 51.530048661,
                "lng": -127.5794602752
            },
            {
                "lat": 51.5371852034,
                "lng": -127.5758894033
            },
            {
                "lat": 51.5469440369,
                "lng": -127.5749428446
            },
            {
                "lat": 51.5497977707,
                "lng": -127.5777962598
            },
            {
                "lat": 51.5495602676,
                "lng": -127.5832667545
            },
            {
                "lat": 51.5428965973,
                "lng": -127.5870747041
            },
            {
                "lat": 51.5321921955,
                "lng": -127.5915989246
            },
            {
                "lat": 51.5243377584,
                "lng": -127.5968323267
            },
            {
                "lat": 51.5183911382,
                "lng": -127.596116019
            },
            {
                "lat": 51.5172499074,
                "lng": -127.5968395247
            },
            {
                "lat": 51.5157705934,
                "lng": -127.597786142
            },
            {
                "lat": 51.515296829,
                "lng": -127.6008761289
            },
            {
                "lat": 51.5162234276,
                "lng": -127.6038429975
            },
            {
                "lat": 51.5164876311,
                "lng": -127.6046824315
            },
            {
                "lat": 51.5159950088,
                "lng": -127.6053995791
            },
            {
                "lat": 51.5138704068,
                "lng": -127.6084905297
            },
            {
                "lat": 51.507205556,
                "lng": -127.6080171432
            },
            {
                "lat": 51.5053024289,
                "lng": -127.6103976716
            },
            {
                "lat": 51.5053023998,
                "lng": -127.6132507759
            },
            {
                "lat": 51.5086333261,
                "lng": -127.6163406659
            },
            {
                "lat": 51.5124398189,
                "lng": -127.6194385897
            },
            {
                "lat": 51.5081590993,
                "lng": -127.6234817544
            },
            {
                "lat": 51.5033992088,
                "lng": -127.6265722646
            },
            {
                "lat": 51.4984010562,
                "lng": -127.6358563368
            },
            {
                "lat": 51.4948309963,
                "lng": -127.6441801445
            },
            {
                "lat": 51.4955482053,
                "lng": -127.6503672044
            },
            {
                "lat": 51.4991152356,
                "lng": -127.6525114844
            },
            {
                "lat": 51.500823793,
                "lng": -127.6537017709
            },
            {
                "lat": 51.5013655186,
                "lng": -127.6563035915
            },
            {
                "lat": 51.5003887385,
                "lng": -127.6634900126
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 343,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 9,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 9-2",
            "LABEL": "9-2",
            "FISHERY_AREA_ID": 2029,
            "OBJECTID": 487,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 159093731.7147,
            "FEATURE_LENGTH_M": 64170.1457,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.48547347914302,
            "lng": -127.65446443756741
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.4802439856,
                "lng": -128.0865629187
            },
            {
                "lat": 51.4802509339,
                "lng": -129.7461388703
            },
            {
                "lat": 51.416668308,
                "lng": -129.9650263431
            },
            {
                "lat": 51.4166682296,
                "lng": -128.0830228294
            },
            {
                "lat": 51.4802439856,
                "lng": -128.0865629187
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 344,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 109,
            "MANAGEMENT_SUBAREA": 109,
            "MANAGEMENT_AREA_NAME": "Subarea 109",
            "LABEL": "109",
            "FISHERY_AREA_ID": 2620,
            "OBJECTID": 488,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 858911058.1377,
            "FEATURE_LENGTH_M": 269706.3933,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.45481508854,
            "lng": -128.79346277604
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.5171246278,
                "lng": -127.7811588644
            },
            {
                "lat": 51.5183942386,
                "lng": -127.7887496579
            },
            {
                "lat": 51.5232818776,
                "lng": -127.9085009332
            },
            {
                "lat": 51.5187865432,
                "lng": -127.9230808449
            },
            {
                "lat": 51.4878574009,
                "lng": -127.9000167908
            },
            {
                "lat": 51.4529343924,
                "lng": -127.8916471252
            },
            {
                "lat": 51.4403832502,
                "lng": -127.9022597266
            },
            {
                "lat": 51.4313390551,
                "lng": -127.9001158959
            },
            {
                "lat": 51.4298481131,
                "lng": -127.8889466304
            },
            {
                "lat": 51.429874594,
                "lng": -127.8867798773
            },
            {
                "lat": 51.4299430433,
                "lng": -127.8861391074
            },
            {
                "lat": 51.3669172018,
                "lng": -127.808135575
            },
            {
                "lat": 51.3732301975,
                "lng": -127.7813805098
            },
            {
                "lat": 51.3740731143,
                "lng": -127.7778018669
            },
            {
                "lat": 51.3726542411,
                "lng": -127.7777096945
            },
            {
                "lat": 51.3704491104,
                "lng": -127.777549944
            },
            {
                "lat": 51.3689379541,
                "lng": -127.7769011924
            },
            {
                "lat": 51.3685644708,
                "lng": -127.7762757432
            },
            {
                "lat": 51.368336519,
                "lng": -127.7759249603
            },
            {
                "lat": 51.3678823441,
                "lng": -127.7754356356
            },
            {
                "lat": 51.3674130703,
                "lng": -127.7749780776
            },
            {
                "lat": 51.3665505597,
                "lng": -127.7743680939
            },
            {
                "lat": 51.3653492571,
                "lng": -127.7729948231
            },
            {
                "lat": 51.3619115395,
                "lng": -127.769042113
            },
            {
                "lat": 51.3625146383,
                "lng": -127.762603361
            },
            {
                "lat": 51.3644025043,
                "lng": -127.749809006
            },
            {
                "lat": 51.368011401,
                "lng": -127.7425919918
            },
            {
                "lat": 51.3718752846,
                "lng": -127.7382958783
            },
            {
                "lat": 51.377063924,
                "lng": -127.7462841178
            },
            {
                "lat": 51.4534802333,
                "lng": -127.7450717323
            },
            {
                "lat": 51.4558070996,
                "lng": -127.7369839598
            },
            {
                "lat": 51.4559179467,
                "lng": -127.7372207249
            },
            {
                "lat": 51.4562379803,
                "lng": -127.7374648413
            },
            {
                "lat": 51.4579350929,
                "lng": -127.7377010762
            },
            {
                "lat": 51.4612846687,
                "lng": -127.7355881241
            },
            {
                "lat": 51.4641568101,
                "lng": -127.7324371132
            },
            {
                "lat": 51.463298856,
                "lng": -127.7310024764
            },
            {
                "lat": 51.4627001113,
                "lng": -127.7306366214
            },
            {
                "lat": 51.4663733047,
                "lng": -127.7223503805
            },
            {
                "lat": 51.4677538383,
                "lng": -127.7225953391
            },
            {
                "lat": 51.4708898325,
                "lng": -127.7225953618
            },
            {
                "lat": 51.4751858289,
                "lng": -127.7253189676
            },
            {
                "lat": 51.4792288348,
                "lng": -127.730025839
            },
            {
                "lat": 51.481460698,
                "lng": -127.7331688352
            },
            {
                "lat": 51.4877353174,
                "lng": -127.7399365369
            },
            {
                "lat": 51.4912871383,
                "lng": -127.7466279645
            },
            {
                "lat": 51.4920309704,
                "lng": -127.7517464879
            },
            {
                "lat": 51.4923595164,
                "lng": -127.7579809244
            },
            {
                "lat": 51.491878476,
                "lng": -127.758972327
            },
            {
                "lat": 51.491226742,
                "lng": -127.7611306234
            },
            {
                "lat": 51.4904140312,
                "lng": -127.7638251206
            },
            {
                "lat": 51.4898679611,
                "lng": -127.7664716102
            },
            {
                "lat": 51.4895330428,
                "lng": -127.7674786384
            },
            {
                "lat": 51.4895746521,
                "lng": -127.7683033386
            },
            {
                "lat": 51.5171246278,
                "lng": -127.7811588644
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 345,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 9,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 9-1",
            "LABEL": "9-1",
            "FISHERY_AREA_ID": 2028,
            "OBJECTID": 489,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 156717495.1679,
            "FEATURE_LENGTH_M": 56540.4091,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.440193601449074,
            "lng": -127.77925948849635
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.5062978672,
                "lng": -127.6991423986
            },
            {
                "lat": 51.5069880698,
                "lng": -127.703751075
            },
            {
                "lat": 51.5056530664,
                "lng": -127.7087024754
            },
            {
                "lat": 51.5064082799,
                "lng": -127.7117996755
            },
            {
                "lat": 51.5054896806,
                "lng": -127.7137451732
            },
            {
                "lat": 51.504466792,
                "lng": -127.7177280855
            },
            {
                "lat": 51.5025329552,
                "lng": -127.7202757571
            },
            {
                "lat": 51.5006900218,
                "lng": -127.7221144227
            },
            {
                "lat": 51.500381725,
                "lng": -127.7244647506
            },
            {
                "lat": 51.5005574661,
                "lng": -127.7275995569
            },
            {
                "lat": 51.4977190993,
                "lng": -127.7381210393
            },
            {
                "lat": 51.4984396238,
                "lng": -127.745917993
            },
            {
                "lat": 51.4978294633,
                "lng": -127.7513360358
            },
            {
                "lat": 51.4950718293,
                "lng": -127.7551121749
            },
            {
                "lat": 51.4923595164,
                "lng": -127.7579809244
            },
            {
                "lat": 51.4920309704,
                "lng": -127.7517464879
            },
            {
                "lat": 51.4912871383,
                "lng": -127.7466279645
            },
            {
                "lat": 51.4877353174,
                "lng": -127.7399365369
            },
            {
                "lat": 51.481460698,
                "lng": -127.7331688352
            },
            {
                "lat": 51.4792288348,
                "lng": -127.730025839
            },
            {
                "lat": 51.4751858289,
                "lng": -127.7253189676
            },
            {
                "lat": 51.4708898325,
                "lng": -127.7225953618
            },
            {
                "lat": 51.4760439111,
                "lng": -127.7196427201
            },
            {
                "lat": 51.4780729439,
                "lng": -127.7227860343
            },
            {
                "lat": 51.484169807,
                "lng": -127.723526481
            },
            {
                "lat": 51.4928473378,
                "lng": -127.7181702056
            },
            {
                "lat": 51.4982034737,
                "lng": -127.7181699659
            },
            {
                "lat": 51.5022665649,
                "lng": -127.7154006212
            },
            {
                "lat": 51.5046656439,
                "lng": -127.7102278085
            },
            {
                "lat": 51.504665476,
                "lng": -127.7032085387
            },
            {
                "lat": 51.5062978672,
                "lng": -127.6991423986
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 346,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 490,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4245855.117,
            "FEATURE_LENGTH_M": 13446.52,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.49503022909355,
            "lng": -127.72508020337742
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.4954988303,
                "lng": -128.0766906801
            },
            {
                "lat": 51.4825444142,
                "lng": -128.0844119842
            },
            {
                "lat": 51.4802439856,
                "lng": -128.0865629187
            },
            {
                "lat": 51.4166682296,
                "lng": -128.0830228294
            },
            {
                "lat": 51.2500000305,
                "lng": -128.0737605294
            },
            {
                "lat": 51.1635814463,
                "lng": -128.0689535902
            },
            {
                "lat": 51.1635813875,
                "lng": -127.8993601854
            },
            {
                "lat": 51.4299430433,
                "lng": -127.8861391074
            },
            {
                "lat": 51.429874594,
                "lng": -127.8867798773
            },
            {
                "lat": 51.4298481131,
                "lng": -127.8889466304
            },
            {
                "lat": 51.4313390551,
                "lng": -127.9001158959
            },
            {
                "lat": 51.4336009234,
                "lng": -127.9093925633
            },
            {
                "lat": 51.4403834294,
                "lng": -127.927742339
            },
            {
                "lat": 51.441691839,
                "lng": -127.931289119
            },
            {
                "lat": 51.4588235654,
                "lng": -127.9434206338
            },
            {
                "lat": 51.4704813871,
                "lng": -127.9588854505
            },
            {
                "lat": 51.4764323574,
                "lng": -127.9743570218
            },
            {
                "lat": 51.489757329,
                "lng": -128.0014794564
            },
            {
                "lat": 51.4833335016,
                "lng": -128.0202797157
            },
            {
                "lat": 51.4890435959,
                "lng": -128.0502619633
            },
            {
                "lat": 51.4954988303,
                "lng": -128.0766906801
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 347,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 10,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 10-1",
            "LABEL": "10-1",
            "FISHERY_AREA_ID": 2038,
            "OBJECTID": 491,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 441212214.0582,
            "FEATURE_LENGTH_M": 95179.802,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.421531899428565,
            "lng": -127.98707348434759
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.487639724,
                "lng": -127.5253373313
            },
            {
                "lat": 51.4900169982,
                "lng": -127.5386576676
            },
            {
                "lat": 51.4843056414,
                "lng": -127.5479425139
            },
            {
                "lat": 51.4795488068,
                "lng": -127.5531771844
            },
            {
                "lat": 51.4749601458,
                "lng": -127.5624085746
            },
            {
                "lat": 51.4729190347,
                "lng": -127.5657881851
            },
            {
                "lat": 51.4576303377,
                "lng": -127.5650110204
            },
            {
                "lat": 51.4509959322,
                "lng": -127.5546031219
            },
            {
                "lat": 51.4352916529,
                "lng": -127.5212935908
            },
            {
                "lat": 51.4281504655,
                "lng": -127.5224839779
            },
            {
                "lat": 51.4193495141,
                "lng": -127.5317613138
            },
            {
                "lat": 51.4122085582,
                "lng": -127.5622180324
            },
            {
                "lat": 51.4003111063,
                "lng": -127.5660241808
            },
            {
                "lat": 51.3915063597,
                "lng": -127.5541303256
            },
            {
                "lat": 51.3915066479,
                "lng": -127.5215308447
            },
            {
                "lat": 51.3972201017,
                "lng": -127.459182873
            },
            {
                "lat": 51.4136358551,
                "lng": -127.4211115915
            },
            {
                "lat": 51.4160154239,
                "lng": -127.4018402991
            },
            {
                "lat": 51.4231561061,
                "lng": -127.3870929013
            },
            {
                "lat": 51.4255334604,
                "lng": -127.3554455246
            },
            {
                "lat": 51.4164924762,
                "lng": -127.332839773
            },
            {
                "lat": 51.399361235,
                "lng": -127.3204653939
            },
            {
                "lat": 51.3907963812,
                "lng": -127.3047562119
            },
            {
                "lat": 51.3946043469,
                "lng": -127.2723996626
            },
            {
                "lat": 51.3938870254,
                "lng": -127.2502669703
            },
            {
                "lat": 51.3976937176,
                "lng": -127.2214818359
            },
            {
                "lat": 51.4141122253,
                "lng": -127.2169568392
            },
            {
                "lat": 51.4217265617,
                "lng": -127.2552647199
            },
            {
                "lat": 51.436481086,
                "lng": -127.2914360212
            },
            {
                "lat": 51.4476628436,
                "lng": -127.3021387772
            },
            {
                "lat": 51.4674116902,
                "lng": -127.3109436203
            },
            {
                "lat": 51.4697912792,
                "lng": -127.327598831
            },
            {
                "lat": 51.4638448216,
                "lng": -127.3518752122
            },
            {
                "lat": 51.4633675441,
                "lng": -127.4025577788
            },
            {
                "lat": 51.4636036727,
                "lng": -127.4651330331
            },
            {
                "lat": 51.459800423,
                "lng": -127.478461446
            },
            {
                "lat": 51.4774058924,
                "lng": -127.5243838561
            },
            {
                "lat": 51.487639724,
                "lng": -127.5253373313
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 348,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 9,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 9-10",
            "LABEL": "9-10",
            "FISHERY_AREA_ID": 2036,
            "OBJECTID": 492,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 138417439.1762,
            "FEATURE_LENGTH_M": 70324.357,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.43730486365,
            "lng": -127.43082469391052
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.416668308,
                "lng": -129.9650263431
            },
            {
                "lat": 51.3943821578,
                "lng": -130.0416716724
            },
            {
                "lat": 51.2500001578,
                "lng": -129.783340885
            },
            {
                "lat": 51.2500000305,
                "lng": -128.0737605294
            },
            {
                "lat": 51.4166682296,
                "lng": -128.0830228294
            },
            {
                "lat": 51.416668308,
                "lng": -129.9650263431
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 349,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 110,
            "MANAGEMENT_SUBAREA": 110,
            "MANAGEMENT_AREA_NAME": "Subarea 110",
            "LABEL": "110",
            "FISHERY_AREA_ID": 2621,
            "OBJECTID": 493,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2394846702.5742,
            "FEATURE_LENGTH_M": 298524.2863,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.35739786528333,
            "lng": -129.31864143373335
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.3619115395,
                "lng": -127.769042113
            },
            {
                "lat": 51.3653492571,
                "lng": -127.7729948231
            },
            {
                "lat": 51.3665505597,
                "lng": -127.7743680939
            },
            {
                "lat": 51.3674130703,
                "lng": -127.7749780776
            },
            {
                "lat": 51.3678823441,
                "lng": -127.7754356356
            },
            {
                "lat": 51.368336519,
                "lng": -127.7759249603
            },
            {
                "lat": 51.3685644708,
                "lng": -127.7762757432
            },
            {
                "lat": 51.3689379541,
                "lng": -127.7769011924
            },
            {
                "lat": 51.3704491104,
                "lng": -127.777549944
            },
            {
                "lat": 51.3726542411,
                "lng": -127.7777096945
            },
            {
                "lat": 51.3740731143,
                "lng": -127.7778018669
            },
            {
                "lat": 51.3732301975,
                "lng": -127.7813805098
            },
            {
                "lat": 51.3669172018,
                "lng": -127.808135575
            },
            {
                "lat": 51.4299430433,
                "lng": -127.8861391074
            },
            {
                "lat": 51.1635813875,
                "lng": -127.8993601854
            },
            {
                "lat": 51.1635817869,
                "lng": -127.8047112152
            },
            {
                "lat": 51.1635813593,
                "lng": -127.7867505529
            },
            {
                "lat": 51.1710894268,
                "lng": -127.7522964241
            },
            {
                "lat": 51.1784744373,
                "lng": -127.7546996648
            },
            {
                "lat": 51.1893952975,
                "lng": -127.762328507
            },
            {
                "lat": 51.1996223448,
                "lng": -127.769782989
            },
            {
                "lat": 51.2025715637,
                "lng": -127.7694394089
            },
            {
                "lat": 51.2074247546,
                "lng": -127.764060711
            },
            {
                "lat": 51.2176555042,
                "lng": -127.7644113048
            },
            {
                "lat": 51.2230266861,
                "lng": -127.7673571243
            },
            {
                "lat": 51.2270850421,
                "lng": -127.7732003861
            },
            {
                "lat": 51.2279894295,
                "lng": -127.7781519323
            },
            {
                "lat": 51.2289197797,
                "lng": -127.7810516164
            },
            {
                "lat": 51.2296295059,
                "lng": -127.782883256
            },
            {
                "lat": 51.2309952511,
                "lng": -127.7845764672
            },
            {
                "lat": 51.2318148491,
                "lng": -127.7854537144
            },
            {
                "lat": 51.2586523607,
                "lng": -127.801697253
            },
            {
                "lat": 51.2697068823,
                "lng": -127.810913412
            },
            {
                "lat": 51.2713590332,
                "lng": -127.8109208833
            },
            {
                "lat": 51.2720982497,
                "lng": -127.811126937
            },
            {
                "lat": 51.2730983735,
                "lng": -127.8107758162
            },
            {
                "lat": 51.2741887297,
                "lng": -127.8099062744
            },
            {
                "lat": 51.275013071,
                "lng": -127.8090356826
            },
            {
                "lat": 51.2754892969,
                "lng": -127.8081741676
            },
            {
                "lat": 51.2762981427,
                "lng": -127.8076402093
            },
            {
                "lat": 51.3182147748,
                "lng": -127.7815094257
            },
            {
                "lat": 51.3204987238,
                "lng": -127.7800900512
            },
            {
                "lat": 51.3214335779,
                "lng": -127.7794182223
            },
            {
                "lat": 51.3216326319,
                "lng": -127.7775349448
            },
            {
                "lat": 51.3218341957,
                "lng": -127.7746656941
            },
            {
                "lat": 51.3232194953,
                "lng": -127.7718887456
            },
            {
                "lat": 51.3243104148,
                "lng": -127.7685160868
            },
            {
                "lat": 51.325992709,
                "lng": -127.766044336
            },
            {
                "lat": 51.3294641322,
                "lng": -127.7623755065
            },
            {
                "lat": 51.3389742063,
                "lng": -127.7556384278
            },
            {
                "lat": 51.3404619709,
                "lng": -127.7649539694
            },
            {
                "lat": 51.3429378254,
                "lng": -127.7738722603
            },
            {
                "lat": 51.3458332865,
                "lng": -127.7790067769
            },
            {
                "lat": 51.3594784908,
                "lng": -127.7784263052
            },
            {
                "lat": 51.3603667532,
                "lng": -127.7781447591
            },
            {
                "lat": 51.3619115395,
                "lng": -127.769042113
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 350,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 10,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 10-2",
            "LABEL": "10-2",
            "FISHERY_AREA_ID": 2039,
            "OBJECTID": 494,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 201094674.0027,
            "FEATURE_LENGTH_M": 78214.1734,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.29430571244284,
            "lng": -127.78440126886785
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.3511543517,
                "lng": -127.5429385543
            },
            {
                "lat": 51.3455471185,
                "lng": -127.5413969485
            },
            {
                "lat": 51.3372466499,
                "lng": -127.536567613
            },
            {
                "lat": 51.3373072259,
                "lng": -127.5358663621
            },
            {
                "lat": 51.337791137,
                "lng": -127.5322037266
            },
            {
                "lat": 51.3377609065,
                "lng": -127.5302883933
            },
            {
                "lat": 51.3389591378,
                "lng": -127.5263215434
            },
            {
                "lat": 51.3403130186,
                "lng": -127.5230794285
            },
            {
                "lat": 51.3413045733,
                "lng": -127.5185624467
            },
            {
                "lat": 51.3402208493,
                "lng": -127.5139616238
            },
            {
                "lat": 51.338508257,
                "lng": -127.5102612307
            },
            {
                "lat": 51.3375167791,
                "lng": -127.505661353
            },
            {
                "lat": 51.3399993969,
                "lng": -127.5004043785
            },
            {
                "lat": 51.3444630467,
                "lng": -127.4971930644
            },
            {
                "lat": 51.3457375056,
                "lng": -127.4930575164
            },
            {
                "lat": 51.3459203419,
                "lng": -127.482978545
            },
            {
                "lat": 51.3494876389,
                "lng": -127.4627538639
            },
            {
                "lat": 51.3554386106,
                "lng": -127.4294352481
            },
            {
                "lat": 51.3654295591,
                "lng": -127.4163514402
            },
            {
                "lat": 51.3728069093,
                "lng": -127.4061202126
            },
            {
                "lat": 51.3866077184,
                "lng": -127.3525854468
            },
            {
                "lat": 51.4054062104,
                "lng": -127.346870308
            },
            {
                "lat": 51.4137348524,
                "lng": -127.3556752549
            },
            {
                "lat": 51.4130211492,
                "lng": -127.3620985175
            },
            {
                "lat": 51.4094508312,
                "lng": -127.3716207103
            },
            {
                "lat": 51.4011233681,
                "lng": -127.3882744295
            },
            {
                "lat": 51.3916056366,
                "lng": -127.4082647915
            },
            {
                "lat": 51.3851165182,
                "lng": -127.4252089657
            },
            {
                "lat": 51.3828011138,
                "lng": -127.4415741449
            },
            {
                "lat": 51.3789942,
                "lng": -127.4629900786
            },
            {
                "lat": 51.374710281,
                "lng": -127.4803614249
            },
            {
                "lat": 51.367332714,
                "lng": -127.5022512217
            },
            {
                "lat": 51.3575785098,
                "lng": -127.5236672625
            },
            {
                "lat": 51.3511543517,
                "lng": -127.5429385543
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 351,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 10,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 10-6",
            "LABEL": "10-6",
            "FISHERY_AREA_ID": 2043,
            "OBJECTID": 495,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 37285798.6828,
            "FEATURE_LENGTH_M": 34613.0022,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.36239854318824,
            "lng": -127.4696995471794
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.0979385207,
                "lng": -127.5008010093
            },
            {
                "lat": 51.0977824638,
                "lng": -127.5003811839
            },
            {
                "lat": 51.097621711,
                "lng": -127.4993438283
            },
            {
                "lat": 51.0969660867,
                "lng": -127.4974062135
            },
            {
                "lat": 51.0962226295,
                "lng": -127.4960785836
            },
            {
                "lat": 51.0943409693,
                "lng": -127.4908900038
            },
            {
                "lat": 51.092586788,
                "lng": -127.4903943466
            },
            {
                "lat": 51.0890848118,
                "lng": -127.4847950255
            },
            {
                "lat": 51.0848847472,
                "lng": -127.474753916
            },
            {
                "lat": 51.079749795,
                "lng": -127.4668273404
            },
            {
                "lat": 51.0753179067,
                "lng": -127.4567941128
            },
            {
                "lat": 51.0697169408,
                "lng": -127.4469909761
            },
            {
                "lat": 51.0641207811,
                "lng": -127.4392929154
            },
            {
                "lat": 51.0594516217,
                "lng": -127.4390562253
            },
            {
                "lat": 51.0601536463,
                "lng": -127.4542240474
            },
            {
                "lat": 51.0545537297,
                "lng": -127.4565581278
            },
            {
                "lat": 51.049651906,
                "lng": -127.4488608762
            },
            {
                "lat": 51.044521127,
                "lng": -127.430427305
            },
            {
                "lat": 51.043353767,
                "lng": -127.4157252147
            },
            {
                "lat": 51.0405538281,
                "lng": -127.4024269891
            },
            {
                "lat": 51.0386887446,
                "lng": -127.3902972356
            },
            {
                "lat": 51.0382199154,
                "lng": -127.378860402
            },
            {
                "lat": 51.0396574213,
                "lng": -127.3677750433
            },
            {
                "lat": 51.038864603,
                "lng": -127.3653943233
            },
            {
                "lat": 51.0331997724,
                "lng": -127.3570640457
            },
            {
                "lat": 51.030329994,
                "lng": -127.3501961085
            },
            {
                "lat": 51.0289997083,
                "lng": -127.3400580542
            },
            {
                "lat": 51.0266640738,
                "lng": -127.3296587022
            },
            {
                "lat": 51.0264628161,
                "lng": -127.3235935338
            },
            {
                "lat": 51.0270648265,
                "lng": -127.3223269698
            },
            {
                "lat": 51.0313304346,
                "lng": -127.3233956393
            },
            {
                "lat": 51.0328634081,
                "lng": -127.3237222101
            },
            {
                "lat": 51.0340378464,
                "lng": -127.324752726
            },
            {
                "lat": 51.0397147157,
                "lng": -127.3247529766
            },
            {
                "lat": 51.0402405522,
                "lng": -127.3144230532
            },
            {
                "lat": 51.039734369,
                "lng": -127.31205797
            },
            {
                "lat": 51.0385966752,
                "lng": -127.306991489
            },
            {
                "lat": 51.0372663676,
                "lng": -127.3017198081
            },
            {
                "lat": 51.0379985294,
                "lng": -127.3001932449
            },
            {
                "lat": 51.0409927595,
                "lng": -127.2996293298
            },
            {
                "lat": 51.041477046,
                "lng": -127.2900775151
            },
            {
                "lat": 51.0408517898,
                "lng": -127.2851716582
            },
            {
                "lat": 51.0402408581,
                "lng": -127.2829814231
            },
            {
                "lat": 51.0399974207,
                "lng": -127.2800594982
            },
            {
                "lat": 51.0401193507,
                "lng": -127.2749558655
            },
            {
                "lat": 51.0387842864,
                "lng": -127.2636419702
            },
            {
                "lat": 51.0384175896,
                "lng": -127.2557374301
            },
            {
                "lat": 51.0362279328,
                "lng": -127.2540359155
            },
            {
                "lat": 51.0327041102,
                "lng": -127.2508697284
            },
            {
                "lat": 51.0308760959,
                "lng": -127.2407766862
            },
            {
                "lat": 51.0284465528,
                "lng": -127.2297134644
            },
            {
                "lat": 51.0291744124,
                "lng": -127.2203443531
            },
            {
                "lat": 51.0350110391,
                "lng": -127.2179180358
            },
            {
                "lat": 51.0362280764,
                "lng": -127.2109837038
            },
            {
                "lat": 51.0363503217,
                "lng": -127.2012555537
            },
            {
                "lat": 51.0361054539,
                "lng": -127.1876296366
            },
            {
                "lat": 51.0324593515,
                "lng": -127.1667180516
            },
            {
                "lat": 51.0319707664,
                "lng": -127.146766309
            },
            {
                "lat": 51.0350114356,
                "lng": -127.1378932657
            },
            {
                "lat": 51.0350113128,
                "lng": -127.1273120703
            },
            {
                "lat": 51.0333103889,
                "lng": -127.108703424
            },
            {
                "lat": 51.0300253491,
                "lng": -127.1000674351
            },
            {
                "lat": 51.0267410363,
                "lng": -127.0894928248
            },
            {
                "lat": 51.0268634906,
                "lng": -127.0812223105
            },
            {
                "lat": 51.0311207344,
                "lng": -127.0728301541
            },
            {
                "lat": 51.0368392578,
                "lng": -127.0649263188
            },
            {
                "lat": 51.0406074743,
                "lng": -127.0565343122
            },
            {
                "lat": 51.0436484267,
                "lng": -127.0318446133
            },
            {
                "lat": 51.0452267834,
                "lng": -127.0011974196
            },
            {
                "lat": 51.0458380376,
                "lng": -126.9847787631
            },
            {
                "lat": 51.0486328025,
                "lng": -126.9590001495
            },
            {
                "lat": 51.051067404,
                "lng": -126.9367447185
            },
            {
                "lat": 51.052524918,
                "lng": -126.9164275073
            },
            {
                "lat": 51.0527678657,
                "lng": -126.9041519887
            },
            {
                "lat": 51.0532535403,
                "lng": -126.8925933925
            },
            {
                "lat": 51.0537412647,
                "lng": -126.8660810715
            },
            {
                "lat": 51.05483711,
                "lng": -126.8542854971
            },
            {
                "lat": 51.0637019969,
                "lng": -126.853188128
            },
            {
                "lat": 51.0815086062,
                "lng": -126.8531876019
            },
            {
                "lat": 51.0816077423,
                "lng": -126.8525465549
            },
            {
                "lat": 51.0819094188,
                "lng": -126.8507234935
            },
            {
                "lat": 51.0826030411,
                "lng": -126.8457341548
            },
            {
                "lat": 51.083038219,
                "lng": -126.8413541719
            },
            {
                "lat": 51.0839883919,
                "lng": -126.835540661
            },
            {
                "lat": 51.0856320888,
                "lng": -126.8116768174
            },
            {
                "lat": 51.0881001102,
                "lng": -126.7960433698
            },
            {
                "lat": 51.0930363644,
                "lng": -126.787818661
            },
            {
                "lat": 51.1012643215,
                "lng": -126.7812346958
            },
            {
                "lat": 51.1119620027,
                "lng": -126.7762979832
            },
            {
                "lat": 51.1147082784,
                "lng": -126.794678048
            },
            {
                "lat": 51.1117327043,
                "lng": -126.847030889
            },
            {
                "lat": 51.0962680803,
                "lng": -126.8892672105
            },
            {
                "lat": 51.0879407564,
                "lng": -126.9047320751
            },
            {
                "lat": 51.090915366,
                "lng": -126.9118728076
            },
            {
                "lat": 51.0873445041,
                "lng": -126.9320910594
            },
            {
                "lat": 51.0828240265,
                "lng": -126.942939431
            },
            {
                "lat": 51.0831408684,
                "lng": -126.9643788846
            },
            {
                "lat": 51.087344772,
                "lng": -126.9767066452
            },
            {
                "lat": 51.0921024725,
                "lng": -126.9981223713
            },
            {
                "lat": 51.0915064965,
                "lng": -127.0254894216
            },
            {
                "lat": 51.0842820282,
                "lng": -127.0418705349
            },
            {
                "lat": 51.0852772039,
                "lng": -127.1096726654
            },
            {
                "lat": 51.0742643659,
                "lng": -127.1252600597
            },
            {
                "lat": 51.0638578156,
                "lng": -127.1356581817
            },
            {
                "lat": 51.0607764126,
                "lng": -127.1402818596
            },
            {
                "lat": 51.060005624,
                "lng": -127.1427917454
            },
            {
                "lat": 51.0584642412,
                "lng": -127.1464536569
            },
            {
                "lat": 51.0563433262,
                "lng": -127.1547397312
            },
            {
                "lat": 51.0573084014,
                "lng": -127.1635965544
            },
            {
                "lat": 51.0580792613,
                "lng": -127.1740044198
            },
            {
                "lat": 51.059620092,
                "lng": -127.1851811619
            },
            {
                "lat": 51.0615459564,
                "lng": -127.2042546813
            },
            {
                "lat": 51.0619313622,
                "lng": -127.2300717988
            },
            {
                "lat": 51.0623165291,
                "lng": -127.2466423448
            },
            {
                "lat": 51.0586588396,
                "lng": -127.2562799275
            },
            {
                "lat": 51.0598145919,
                "lng": -127.2724610056
            },
            {
                "lat": 51.0627017622,
                "lng": -127.2888404248
            },
            {
                "lat": 51.0640520167,
                "lng": -127.3046422312
            },
            {
                "lat": 51.0632816498,
                "lng": -127.3227540567
            },
            {
                "lat": 51.0605856298,
                "lng": -127.3400949863
            },
            {
                "lat": 51.0553816408,
                "lng": -127.350692689
            },
            {
                "lat": 51.0551873767,
                "lng": -127.3568579
            },
            {
                "lat": 51.058464108,
                "lng": -127.3622510244
            },
            {
                "lat": 51.063086981,
                "lng": -127.3676532963
            },
            {
                "lat": 51.0659792113,
                "lng": -127.3782420836
            },
            {
                "lat": 51.0692557535,
                "lng": -127.3946227716
            },
            {
                "lat": 51.0729138854,
                "lng": -127.4171675991
            },
            {
                "lat": 51.0759962813,
                "lng": -127.4281539642
            },
            {
                "lat": 51.078312252,
                "lng": -127.4370122115
            },
            {
                "lat": 51.0810093672,
                "lng": -127.4427945912
            },
            {
                "lat": 51.0840907334,
                "lng": -127.4522324724
            },
            {
                "lat": 51.0873678685,
                "lng": -127.4576336149
            },
            {
                "lat": 51.0883295861,
                "lng": -127.4609076079
            },
            {
                "lat": 51.0889091249,
                "lng": -127.4636081988
            },
            {
                "lat": 51.0891607673,
                "lng": -127.4647598547
            },
            {
                "lat": 51.0894970343,
                "lng": -127.4660491898
            },
            {
                "lat": 51.0897064468,
                "lng": -127.4666054629
            },
            {
                "lat": 51.0900687088,
                "lng": -127.4668889662
            },
            {
                "lat": 51.0905260631,
                "lng": -127.4668960044
            },
            {
                "lat": 51.0936205773,
                "lng": -127.4674840621
            },
            {
                "lat": 51.0979385207,
                "lng": -127.5008010093
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 374,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 11,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 11-3",
            "LABEL": "11-3",
            "FISHERY_AREA_ID": 2052,
            "OBJECTID": 518,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 180782322.697,
            "FEATURE_LENGTH_M": 121127.1356,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.061235194504974,
            "lng": -127.21646905588584
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.0982935889,
                "lng": -127.4617540528
            },
            {
                "lat": 51.0964926256,
                "lng": -127.4658885118
            },
            {
                "lat": 51.094436444,
                "lng": -127.467415402
            },
            {
                "lat": 51.0936205773,
                "lng": -127.4674840621
            },
            {
                "lat": 51.0905260631,
                "lng": -127.4668960044
            },
            {
                "lat": 51.0900687088,
                "lng": -127.4668889662
            },
            {
                "lat": 51.0897064468,
                "lng": -127.4666054629
            },
            {
                "lat": 51.0894970343,
                "lng": -127.4660491898
            },
            {
                "lat": 51.0891607673,
                "lng": -127.4647598547
            },
            {
                "lat": 51.0889091249,
                "lng": -127.4636081988
            },
            {
                "lat": 51.0883295861,
                "lng": -127.4609076079
            },
            {
                "lat": 51.0873678685,
                "lng": -127.4576336149
            },
            {
                "lat": 51.0859001913,
                "lng": -127.4504542604
            },
            {
                "lat": 51.0849338011,
                "lng": -127.4398574111
            },
            {
                "lat": 51.0833317227,
                "lng": -127.4214015941
            },
            {
                "lat": 51.0804398112,
                "lng": -127.4034271924
            },
            {
                "lat": 51.0794796753,
                "lng": -127.3873746906
            },
            {
                "lat": 51.0820467198,
                "lng": -127.3793489929
            },
            {
                "lat": 51.0834924281,
                "lng": -127.3661880814
            },
            {
                "lat": 51.0836517672,
                "lng": -127.3567204902
            },
            {
                "lat": 51.080893822,
                "lng": -127.3463741476
            },
            {
                "lat": 51.0811313527,
                "lng": -127.3347169489
            },
            {
                "lat": 51.0823213972,
                "lng": -127.3259119556
            },
            {
                "lat": 51.0820801944,
                "lng": -127.3133006707
            },
            {
                "lat": 51.0801769343,
                "lng": -127.2990263655
            },
            {
                "lat": 51.0780372513,
                "lng": -127.2845080703
            },
            {
                "lat": 51.0780373794,
                "lng": -127.2638094072
            },
            {
                "lat": 51.0780371342,
                "lng": -127.2423937819
            },
            {
                "lat": 51.0777975898,
                "lng": -127.2157433433
            },
            {
                "lat": 51.0794644556,
                "lng": -127.205513062
            },
            {
                "lat": 51.0761339425,
                "lng": -127.2033690616
            },
            {
                "lat": 51.0711361811,
                "lng": -127.2014616255
            },
            {
                "lat": 51.0654258633,
                "lng": -127.1974187611
            },
            {
                "lat": 51.0620951108,
                "lng": -127.1869502522
            },
            {
                "lat": 51.0580792613,
                "lng": -127.1740044198
            },
            {
                "lat": 51.0573084014,
                "lng": -127.1635965544
            },
            {
                "lat": 51.0563433262,
                "lng": -127.1547397312
            },
            {
                "lat": 51.0584642412,
                "lng": -127.1464536569
            },
            {
                "lat": 51.060005624,
                "lng": -127.1427917454
            },
            {
                "lat": 51.0607764126,
                "lng": -127.1402818596
            },
            {
                "lat": 51.0638578156,
                "lng": -127.1356581817
            },
            {
                "lat": 51.0670928117,
                "lng": -127.1412659181
            },
            {
                "lat": 51.0694693205,
                "lng": -127.1543500436
            },
            {
                "lat": 51.0732768433,
                "lng": -127.1712499937
            },
            {
                "lat": 51.0751806782,
                "lng": -127.181235564
            },
            {
                "lat": 51.0792272404,
                "lng": -127.1924206234
            },
            {
                "lat": 51.0896948387,
                "lng": -127.1914748886
            },
            {
                "lat": 51.0982629812,
                "lng": -127.1912309705
            },
            {
                "lat": 51.0994531415,
                "lng": -127.1957548902
            },
            {
                "lat": 51.0968358523,
                "lng": -127.2069389778
            },
            {
                "lat": 51.0954053227,
                "lng": -127.2254947637
            },
            {
                "lat": 51.0968361576,
                "lng": -127.2447745104
            },
            {
                "lat": 51.0973084275,
                "lng": -127.2699969882
            },
            {
                "lat": 51.0968361781,
                "lng": -127.2899780237
            },
            {
                "lat": 51.096835873,
                "lng": -127.3054512659
            },
            {
                "lat": 51.0961190706,
                "lng": -127.3201984289
            },
            {
                "lat": 51.0968362058,
                "lng": -127.338760974
            },
            {
                "lat": 51.0963329629,
                "lng": -127.3570403876
            },
            {
                "lat": 51.0976147595,
                "lng": -127.3673098865
            },
            {
                "lat": 51.0977742488,
                "lng": -127.3851312193
            },
            {
                "lat": 51.0963329101,
                "lng": -127.4013361575
            },
            {
                "lat": 51.0961725896,
                "lng": -127.4162676563
            },
            {
                "lat": 51.0964924397,
                "lng": -127.4342423069
            },
            {
                "lat": 51.0976146863,
                "lng": -127.4515770162
            },
            {
                "lat": 51.0982935889,
                "lng": -127.4617540528
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 375,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 11,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 11-8",
            "LABEL": "11-8",
            "FISHERY_AREA_ID": 2057,
            "OBJECTID": 519,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 40528465.2914,
            "FEATURE_LENGTH_M": 51831.574,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.08382442726309,
            "lng": -127.31313727310159
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.0580792613,
                "lng": -127.1740044198
            },
            {
                "lat": 51.0620951108,
                "lng": -127.1869502522
            },
            {
                "lat": 51.0654258633,
                "lng": -127.1974187611
            },
            {
                "lat": 51.0711361811,
                "lng": -127.2014616255
            },
            {
                "lat": 51.0761339425,
                "lng": -127.2033690616
            },
            {
                "lat": 51.0794644556,
                "lng": -127.205513062
            },
            {
                "lat": 51.0777975898,
                "lng": -127.2157433433
            },
            {
                "lat": 51.0780371342,
                "lng": -127.2423937819
            },
            {
                "lat": 51.0780373794,
                "lng": -127.2638094072
            },
            {
                "lat": 51.0780372513,
                "lng": -127.2845080703
            },
            {
                "lat": 51.0801769343,
                "lng": -127.2990263655
            },
            {
                "lat": 51.0820801944,
                "lng": -127.3133006707
            },
            {
                "lat": 51.0823213972,
                "lng": -127.3259119556
            },
            {
                "lat": 51.0811313527,
                "lng": -127.3347169489
            },
            {
                "lat": 51.080893822,
                "lng": -127.3463741476
            },
            {
                "lat": 51.0836517672,
                "lng": -127.3567204902
            },
            {
                "lat": 51.0834924281,
                "lng": -127.3661880814
            },
            {
                "lat": 51.0820467198,
                "lng": -127.3793489929
            },
            {
                "lat": 51.0794796753,
                "lng": -127.3873746906
            },
            {
                "lat": 51.0804398112,
                "lng": -127.4034271924
            },
            {
                "lat": 51.0833317227,
                "lng": -127.4214015941
            },
            {
                "lat": 51.0849338011,
                "lng": -127.4398574111
            },
            {
                "lat": 51.0859001913,
                "lng": -127.4504542604
            },
            {
                "lat": 51.0873678685,
                "lng": -127.4576336149
            },
            {
                "lat": 51.0840907334,
                "lng": -127.4522324724
            },
            {
                "lat": 51.0810093672,
                "lng": -127.4427945912
            },
            {
                "lat": 51.078312252,
                "lng": -127.4370122115
            },
            {
                "lat": 51.0759962813,
                "lng": -127.4281539642
            },
            {
                "lat": 51.0729138854,
                "lng": -127.4171675991
            },
            {
                "lat": 51.0692557535,
                "lng": -127.3946227716
            },
            {
                "lat": 51.0659792113,
                "lng": -127.3782420836
            },
            {
                "lat": 51.063086981,
                "lng": -127.3676532963
            },
            {
                "lat": 51.058464108,
                "lng": -127.3622510244
            },
            {
                "lat": 51.0551873767,
                "lng": -127.3568579
            },
            {
                "lat": 51.0553816408,
                "lng": -127.350692689
            },
            {
                "lat": 51.0605856298,
                "lng": -127.3400949863
            },
            {
                "lat": 51.0632816498,
                "lng": -127.3227540567
            },
            {
                "lat": 51.0640520167,
                "lng": -127.3046422312
            },
            {
                "lat": 51.0627017622,
                "lng": -127.2888404248
            },
            {
                "lat": 51.0598145919,
                "lng": -127.2724610056
            },
            {
                "lat": 51.0586588396,
                "lng": -127.2562799275
            },
            {
                "lat": 51.0623165291,
                "lng": -127.2466423448
            },
            {
                "lat": 51.0619313622,
                "lng": -127.2300717988
            },
            {
                "lat": 51.0615459564,
                "lng": -127.2042546813
            },
            {
                "lat": 51.059620092,
                "lng": -127.1851811619
            },
            {
                "lat": 51.0580792613,
                "lng": -127.1740044198
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 376,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 520,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 31350357.6867,
            "FEATURE_LENGTH_M": 42824.5103,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.072038198623915,
            "lng": -127.31890904006963
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.9888385827,
                "lng": -126.5433039297
            },
            {
                "lat": 50.9883496746,
                "lng": -126.5406416777
            },
            {
                "lat": 50.9875604921,
                "lng": -126.5377352612
            },
            {
                "lat": 50.9860877151,
                "lng": -126.5358739624
            },
            {
                "lat": 50.9856185831,
                "lng": -126.5355065694
            },
            {
                "lat": 50.9945453327,
                "lng": -126.5031278109
            },
            {
                "lat": 50.995361551,
                "lng": -126.4996871273
            },
            {
                "lat": 50.9994461799,
                "lng": -126.4943539575
            },
            {
                "lat": 51.0134847172,
                "lng": -126.4983978546
            },
            {
                "lat": 51.0437051221,
                "lng": -126.4998245584
            },
            {
                "lat": 51.0698812211,
                "lng": -126.5086287321
            },
            {
                "lat": 51.0719449709,
                "lng": -126.5310594237
            },
            {
                "lat": 51.0548889511,
                "lng": -126.5357517061
            },
            {
                "lat": 51.0241925763,
                "lng": -126.5483626148
            },
            {
                "lat": 51.0032543572,
                "lng": -126.555031
            },
            {
                "lat": 50.9939763171,
                "lng": -126.5478902241
            },
            {
                "lat": 50.9888385827,
                "lng": -126.5433039297
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 377,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 48,
            "MANAGEMENT_AREA_NAME": "Subarea 12-48",
            "LABEL": "12-48",
            "FISHERY_AREA_ID": 2106,
            "OBJECTID": 521,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 27646763.8174,
            "FEATURE_LENGTH_M": 23174.8394,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.0111749957,
            "lng": -126.52696943174118
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.0409927595,
                "lng": -127.2996293298
            },
            {
                "lat": 51.0379985294,
                "lng": -127.3001932449
            },
            {
                "lat": 51.0372663676,
                "lng": -127.3017198081
            },
            {
                "lat": 51.0385966752,
                "lng": -127.306991489
            },
            {
                "lat": 51.039734369,
                "lng": -127.31205797
            },
            {
                "lat": 51.0402405522,
                "lng": -127.3144230532
            },
            {
                "lat": 51.0397147157,
                "lng": -127.3247529766
            },
            {
                "lat": 51.0340378464,
                "lng": -127.324752726
            },
            {
                "lat": 51.0328634081,
                "lng": -127.3237222101
            },
            {
                "lat": 51.0313304346,
                "lng": -127.3233956393
            },
            {
                "lat": 51.0270648265,
                "lng": -127.3223269698
            },
            {
                "lat": 51.01894392,
                "lng": -127.3095246898
            },
            {
                "lat": 51.0071450699,
                "lng": -127.2986136481
            },
            {
                "lat": 51.0006862013,
                "lng": -127.3030699027
            },
            {
                "lat": 50.991782701,
                "lng": -127.3099745861
            },
            {
                "lat": 50.9862170161,
                "lng": -127.3097540221
            },
            {
                "lat": 50.9779816367,
                "lng": -127.2934947212
            },
            {
                "lat": 50.9770886603,
                "lng": -127.2565380819
            },
            {
                "lat": 50.9617269555,
                "lng": -127.2378388989
            },
            {
                "lat": 50.9508176703,
                "lng": -127.2229233135
            },
            {
                "lat": 50.9485927421,
                "lng": -127.2131268246
            },
            {
                "lat": 50.9485924931,
                "lng": -127.1997763591
            },
            {
                "lat": 50.9601701669,
                "lng": -127.1701586648
            },
            {
                "lat": 50.9688524156,
                "lng": -127.1668250863
            },
            {
                "lat": 50.9942323058,
                "lng": -127.1735002668
            },
            {
                "lat": 51.0191647025,
                "lng": -127.1806260405
            },
            {
                "lat": 51.0256235214,
                "lng": -127.187751533
            },
            {
                "lat": 51.0282943105,
                "lng": -127.2028882508
            },
            {
                "lat": 51.0213930119,
                "lng": -127.210457358
            },
            {
                "lat": 51.0091472428,
                "lng": -127.2336121363
            },
            {
                "lat": 51.0089262766,
                "lng": -127.2556535147
            },
            {
                "lat": 51.016048363,
                "lng": -127.2763599667
            },
            {
                "lat": 51.0242849835,
                "lng": -127.2885975979
            },
            {
                "lat": 51.0294071107,
                "lng": -127.2745742946
            },
            {
                "lat": 51.0360872123,
                "lng": -127.2725672265
            },
            {
                "lat": 51.0380895936,
                "lng": -127.2768025727
            },
            {
                "lat": 51.0389784755,
                "lng": -127.2825926354
            },
            {
                "lat": 51.0398715907,
                "lng": -127.2859267452
            },
            {
                "lat": 51.0396459006,
                "lng": -127.2937164953
            },
            {
                "lat": 51.0409927595,
                "lng": -127.2996293298
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 378,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 11,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 11-9",
            "LABEL": "11-9",
            "FISHERY_AREA_ID": 2058,
            "OBJECTID": 522,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 61049402.546,
            "FEATURE_LENGTH_M": 40906.7215,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.01271563734749,
            "lng": -127.2685210045025
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8868985761,
                "lng": -126.9053188595
            },
            {
                "lat": 50.8826526779,
                "lng": -126.9116897841
            },
            {
                "lat": 50.87661311,
                "lng": -126.9288708143
            },
            {
                "lat": 50.8691821934,
                "lng": -126.9390944022
            },
            {
                "lat": 50.8543171716,
                "lng": -126.9841463154
            },
            {
                "lat": 50.8403812324,
                "lng": -127.020843672
            },
            {
                "lat": 50.8233714625,
                "lng": -127.0315015651
            },
            {
                "lat": 50.8213692822,
                "lng": -127.0254830616
            },
            {
                "lat": 50.8210528083,
                "lng": -127.0217212405
            },
            {
                "lat": 50.8195767134,
                "lng": -127.0177386862
            },
            {
                "lat": 50.8182026455,
                "lng": -127.0146864199
            },
            {
                "lat": 50.8176571329,
                "lng": -127.0142976508
            },
            {
                "lat": 50.7830165699,
                "lng": -126.8484566423
            },
            {
                "lat": 50.7840348656,
                "lng": -126.846687633
            },
            {
                "lat": 50.7856485433,
                "lng": -126.8434598849
            },
            {
                "lat": 50.7877731592,
                "lng": -126.8409573914
            },
            {
                "lat": 50.7900504477,
                "lng": -126.8385240499
            },
            {
                "lat": 50.7903519444,
                "lng": -126.8339385744
            },
            {
                "lat": 50.7903131273,
                "lng": -126.8275989961
            },
            {
                "lat": 50.7920219867,
                "lng": -126.8258517292
            },
            {
                "lat": 50.7974858647,
                "lng": -126.8257829983
            },
            {
                "lat": 50.801856937,
                "lng": -126.8297805893
            },
            {
                "lat": 50.8031841971,
                "lng": -126.8358689857
            },
            {
                "lat": 50.8047943912,
                "lng": -126.8432543183
            },
            {
                "lat": 50.8101504712,
                "lng": -126.8650287399
            },
            {
                "lat": 50.8155057749,
                "lng": -126.8831707399
            },
            {
                "lat": 50.8220748312,
                "lng": -126.8952707045
            },
            {
                "lat": 50.8282958431,
                "lng": -126.8994139498
            },
            {
                "lat": 50.830025078,
                "lng": -126.8944094814
            },
            {
                "lat": 50.8270828064,
                "lng": -126.8679655735
            },
            {
                "lat": 50.826568441,
                "lng": -126.8534544847
            },
            {
                "lat": 50.8291587021,
                "lng": -126.8423912407
            },
            {
                "lat": 50.8307151102,
                "lng": -126.8280495641
            },
            {
                "lat": 50.8327864634,
                "lng": -126.8216560164
            },
            {
                "lat": 50.8337324321,
                "lng": -126.8168105244
            },
            {
                "lat": 50.8374593794,
                "lng": -126.8144833335
            },
            {
                "lat": 50.8387561074,
                "lng": -126.8144829663
            },
            {
                "lat": 50.8389355796,
                "lng": -126.8146594125
            },
            {
                "lat": 50.8397639284,
                "lng": -126.8149187437
            },
            {
                "lat": 50.8455584235,
                "lng": -126.8174288268
            },
            {
                "lat": 50.856334538,
                "lng": -126.8180469211
            },
            {
                "lat": 50.8760301291,
                "lng": -126.8108590394
            },
            {
                "lat": 50.8749156109,
                "lng": -126.7828677195
            },
            {
                "lat": 50.8721882286,
                "lng": -126.7556159231
            },
            {
                "lat": 50.8647576457,
                "lng": -126.7429809933
            },
            {
                "lat": 50.862994738,
                "lng": -126.7339172968
            },
            {
                "lat": 50.866668943,
                "lng": -126.7294853508
            },
            {
                "lat": 50.8733639657,
                "lng": -126.7214042532
            },
            {
                "lat": 50.8746525438,
                "lng": -126.7210010672
            },
            {
                "lat": 50.8779869449,
                "lng": -126.7206272954
            },
            {
                "lat": 50.8781476525,
                "lng": -126.7199018695
            },
            {
                "lat": 50.8918996167,
                "lng": -126.7256016189
            },
            {
                "lat": 50.9042059967,
                "lng": -126.7520598781
            },
            {
                "lat": 50.9122047705,
                "lng": -126.7686758774
            },
            {
                "lat": 50.9183574223,
                "lng": -126.7656019637
            },
            {
                "lat": 50.915897274,
                "lng": -126.732986825
            },
            {
                "lat": 50.9134332332,
                "lng": -126.6499174391
            },
            {
                "lat": 50.9232826542,
                "lng": -126.6197661487
            },
            {
                "lat": 50.942356094,
                "lng": -126.6259149934
            },
            {
                "lat": 50.9546624049,
                "lng": -126.656684955
            },
            {
                "lat": 50.9632794262,
                "lng": -126.6972962915
            },
            {
                "lat": 50.9595870813,
                "lng": -126.7317587863
            },
            {
                "lat": 50.9534344641,
                "lng": -126.756988082
            },
            {
                "lat": 50.9688154168,
                "lng": -126.7631384909
            },
            {
                "lat": 50.9952774101,
                "lng": -126.7662126206
            },
            {
                "lat": 51.0211183701,
                "lng": -126.7705228198
            },
            {
                "lat": 51.0358890651,
                "lng": -126.7809825879
            },
            {
                "lat": 51.0377356657,
                "lng": -126.7975999119
            },
            {
                "lat": 51.0174290947,
                "lng": -126.8142168469
            },
            {
                "lat": 50.997123916,
                "lng": -126.8431318853
            },
            {
                "lat": 50.994044955,
                "lng": -126.8591309413
            },
            {
                "lat": 50.9805070024,
                "lng": -126.8800580738
            },
            {
                "lat": 50.9534343109,
                "lng": -126.9058985069
            },
            {
                "lat": 50.9158977423,
                "lng": -126.9169769608
            },
            {
                "lat": 50.9065166637,
                "lng": -126.9204482234
            },
            {
                "lat": 50.90355263,
                "lng": -126.9154045404
            },
            {
                "lat": 50.9016948655,
                "lng": -126.9107585026
            },
            {
                "lat": 50.9007648187,
                "lng": -126.9047166785
            },
            {
                "lat": 50.901230084,
                "lng": -126.8968274732
            },
            {
                "lat": 50.9002804597,
                "lng": -126.8921659217
            },
            {
                "lat": 50.8986819441,
                "lng": -126.8902432793
            },
            {
                "lat": 50.8982246052,
                "lng": -126.890083864
            },
            {
                "lat": 50.8860394293,
                "lng": -126.8954317348
            },
            {
                "lat": 50.8855059654,
                "lng": -126.8956682089
            },
            {
                "lat": 50.8853150399,
                "lng": -126.8963089642
            },
            {
                "lat": 50.884956647,
                "lng": -126.8972548505
            },
            {
                "lat": 50.8868985761,
                "lng": -126.9053188595
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 379,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 41,
            "MANAGEMENT_AREA_NAME": "Subarea 12-41",
            "LABEL": "12-41",
            "FISHERY_AREA_ID": 2099,
            "OBJECTID": 523,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 295809799.1153,
            "FEATURE_LENGTH_M": 114986.3412,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.87604543066896,
            "lng": -126.83847827473565
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.0307506865,
                "lng": -125.5314327574
            },
            {
                "lat": 51.0317389194,
                "lng": -125.5424654564
            },
            {
                "lat": 51.0122717802,
                "lng": -125.5899351206
            },
            {
                "lat": 51.0121996285,
                "lng": -125.5900035418
            },
            {
                "lat": 51.0117492924,
                "lng": -125.5913235893
            },
            {
                "lat": 51.0087586274,
                "lng": -125.5999225281
            },
            {
                "lat": 51.0065770629,
                "lng": -125.5994179508
            },
            {
                "lat": 51.001709744,
                "lng": -125.5975720687
            },
            {
                "lat": 50.9953267207,
                "lng": -125.594383036
            },
            {
                "lat": 50.9882774206,
                "lng": -125.5913622995
            },
            {
                "lat": 50.9813959596,
                "lng": -125.5906903356
            },
            {
                "lat": 50.9768640773,
                "lng": -125.5910259032
            },
            {
                "lat": 50.9718244148,
                "lng": -125.5960620135
            },
            {
                "lat": 50.9666220338,
                "lng": -125.5974040991
            },
            {
                "lat": 50.9617535519,
                "lng": -125.5963972006
            },
            {
                "lat": 50.9562144517,
                "lng": -125.5916977805
            },
            {
                "lat": 50.9501723872,
                "lng": -125.5858236362
            },
            {
                "lat": 50.9474867817,
                "lng": -125.5797797264
            },
            {
                "lat": 50.9459722606,
                "lng": -125.573906064
            },
            {
                "lat": 50.9447974946,
                "lng": -125.5646743439
            },
            {
                "lat": 50.94386299,
                "lng": -125.5599209947
            },
            {
                "lat": 50.953213234,
                "lng": -125.5574194506
            },
            {
                "lat": 50.975883719,
                "lng": -125.5366904806
            },
            {
                "lat": 50.9753531132,
                "lng": -125.5270698882
            },
            {
                "lat": 50.9755205806,
                "lng": -125.5228732576
            },
            {
                "lat": 50.98122757,
                "lng": -125.5213617645
            },
            {
                "lat": 50.986598635,
                "lng": -125.5201867489
            },
            {
                "lat": 50.9949912583,
                "lng": -125.5213619299
            },
            {
                "lat": 51.0001947517,
                "lng": -125.5248864475
            },
            {
                "lat": 51.0131228813,
                "lng": -125.5285796615
            },
            {
                "lat": 51.0231930104,
                "lng": -125.5322720331
            },
            {
                "lat": 51.0282323843,
                "lng": -125.5326070892
            },
            {
                "lat": 51.0307506865,
                "lng": -125.5314327574
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 380,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 33,
            "MANAGEMENT_AREA_NAME": "Subarea 12-33",
            "LABEL": "12-33",
            "FISHERY_AREA_ID": 2091,
            "OBJECTID": 524,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 35453061.5969,
            "FEATURE_LENGTH_M": 25677.1089,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.98741236697274,
            "lng": -125.56399824106968
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8868985761,
                "lng": -126.9053188595
            },
            {
                "lat": 50.884956647,
                "lng": -126.8972548505
            },
            {
                "lat": 50.8853150399,
                "lng": -126.8963089642
            },
            {
                "lat": 50.8855059654,
                "lng": -126.8956682089
            },
            {
                "lat": 50.8860394293,
                "lng": -126.8954317348
            },
            {
                "lat": 50.8982246052,
                "lng": -126.890083864
            },
            {
                "lat": 50.8986819441,
                "lng": -126.8902432793
            },
            {
                "lat": 50.9002804597,
                "lng": -126.8921659217
            },
            {
                "lat": 50.901230084,
                "lng": -126.8968274732
            },
            {
                "lat": 50.9007648187,
                "lng": -126.9047166785
            },
            {
                "lat": 50.9016948655,
                "lng": -126.9107585026
            },
            {
                "lat": 50.90355263,
                "lng": -126.9154045404
            },
            {
                "lat": 50.9065166637,
                "lng": -126.9204482234
            },
            {
                "lat": 50.9081956895,
                "lng": -126.9233017275
            },
            {
                "lat": 50.9137726594,
                "lng": -126.9339823811
            },
            {
                "lat": 50.9165578025,
                "lng": -126.951629952
            },
            {
                "lat": 50.9174877495,
                "lng": -126.9794997888
            },
            {
                "lat": 50.9267762484,
                "lng": -127.0189815704
            },
            {
                "lat": 50.9272418803,
                "lng": -127.0477831014
            },
            {
                "lat": 50.9295660431,
                "lng": -127.0714727789
            },
            {
                "lat": 50.9369965598,
                "lng": -127.074257433
            },
            {
                "lat": 50.9411770966,
                "lng": -127.0621790831
            },
            {
                "lat": 50.95093156,
                "lng": -127.0268780267
            },
            {
                "lat": 50.9727591992,
                "lng": -127.0226983556
            },
            {
                "lat": 51.0038797816,
                "lng": -127.0422052651
            },
            {
                "lat": 51.0173535853,
                "lng": -127.0598601159
            },
            {
                "lat": 51.0145639656,
                "lng": -127.075187668
            },
            {
                "lat": 51.0038795635,
                "lng": -127.0830841478
            },
            {
                "lat": 50.9848363834,
                "lng": -127.0835491768
            },
            {
                "lat": 50.9681169179,
                "lng": -127.0937652542
            },
            {
                "lat": 50.9639363729,
                "lng": -127.1095576758
            },
            {
                "lat": 50.9602202361,
                "lng": -127.136031895
            },
            {
                "lat": 50.9481436017,
                "lng": -127.1634370919
            },
            {
                "lat": 50.9374576768,
                "lng": -127.1908416114
            },
            {
                "lat": 50.9253843331,
                "lng": -127.2038497366
            },
            {
                "lat": 50.9146995379,
                "lng": -127.2066338992
            },
            {
                "lat": 50.9109838569,
                "lng": -127.1987376284
            },
            {
                "lat": 50.9137731719,
                "lng": -127.1871264271
            },
            {
                "lat": 50.9086610389,
                "lng": -127.1601865019
            },
            {
                "lat": 50.9044794078,
                "lng": -127.1392823866
            },
            {
                "lat": 50.8919416201,
                "lng": -127.0965495458
            },
            {
                "lat": 50.8798634799,
                "lng": -127.0398868319
            },
            {
                "lat": 50.8747561947,
                "lng": -127.0031888538
            },
            {
                "lat": 50.8793980419,
                "lng": -126.9753190444
            },
            {
                "lat": 50.886367295,
                "lng": -126.9544216868
            },
            {
                "lat": 50.8891522921,
                "lng": -126.9293368276
            },
            {
                "lat": 50.8877598961,
                "lng": -126.9088972206
            },
            {
                "lat": 50.8868985761,
                "lng": -126.9053188595
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 381,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 42,
            "MANAGEMENT_AREA_NAME": "Subarea 12-42",
            "LABEL": "12-42",
            "FISHERY_AREA_ID": 2100,
            "OBJECTID": 525,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 130898145.7691,
            "FEATURE_LENGTH_M": 68014.6833,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.92161731341875,
            "lng": -127.01603230524165
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.9994461799,
                "lng": -126.4943539575
            },
            {
                "lat": 50.995361551,
                "lng": -126.4996871273
            },
            {
                "lat": 50.9945453327,
                "lng": -126.5031278109
            },
            {
                "lat": 50.9856185831,
                "lng": -126.5355065694
            },
            {
                "lat": 50.9860877151,
                "lng": -126.5358739624
            },
            {
                "lat": 50.9875604921,
                "lng": -126.5377352612
            },
            {
                "lat": 50.9883496746,
                "lng": -126.5406416777
            },
            {
                "lat": 50.9888385827,
                "lng": -126.5433039297
            },
            {
                "lat": 50.9846186796,
                "lng": -126.5423659724
            },
            {
                "lat": 50.9781457305,
                "lng": -126.5398328889
            },
            {
                "lat": 50.9696080152,
                "lng": -126.5319599741
            },
            {
                "lat": 50.961731368,
                "lng": -126.5244522679
            },
            {
                "lat": 50.9577674633,
                "lng": -126.520905266
            },
            {
                "lat": 50.9532886633,
                "lng": -126.520415802
            },
            {
                "lat": 50.9490050319,
                "lng": -126.5163043489
            },
            {
                "lat": 50.941036291,
                "lng": -126.5121238142
            },
            {
                "lat": 50.9436603121,
                "lng": -126.46107449
            },
            {
                "lat": 50.9509083207,
                "lng": -126.4589013184
            },
            {
                "lat": 50.9649469998,
                "lng": -126.4717483917
            },
            {
                "lat": 50.9796979881,
                "lng": -126.4807893686
            },
            {
                "lat": 50.990405375,
                "lng": -126.4850689975
            },
            {
                "lat": 50.9994461799,
                "lng": -126.4943539575
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 382,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 47,
            "MANAGEMENT_AREA_NAME": "Subarea 12-47",
            "LABEL": "12-47",
            "FISHERY_AREA_ID": 2105,
            "OBJECTID": 526,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 22089169.4961,
            "FEATURE_LENGTH_M": 20261.0731,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.97500338770908,
            "lng": -126.5113875979182
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.9787220605,
                "lng": -127.4656601966
            },
            {
                "lat": 50.976954984,
                "lng": -127.467033087
            },
            {
                "lat": 50.9768337477,
                "lng": -127.4677196262
            },
            {
                "lat": 50.9764174137,
                "lng": -127.4685211995
            },
            {
                "lat": 50.9757685,
                "lng": -127.4689934397
            },
            {
                "lat": 50.9753564392,
                "lng": -127.4691242054
            },
            {
                "lat": 50.9418673873,
                "lng": -127.6585618188
            },
            {
                "lat": 50.9056017849,
                "lng": -127.5979538466
            },
            {
                "lat": 50.8600846133,
                "lng": -127.5193630498
            },
            {
                "lat": 50.8666954432,
                "lng": -127.4519038678
            },
            {
                "lat": 50.8716166181,
                "lng": -127.4337770565
            },
            {
                "lat": 50.8724593848,
                "lng": -127.17920017
            },
            {
                "lat": 50.8738633871,
                "lng": -127.1793589904
            },
            {
                "lat": 50.8756256888,
                "lng": -127.1793441416
            },
            {
                "lat": 50.8788946206,
                "lng": -127.1790694595
            },
            {
                "lat": 50.879803046,
                "lng": -127.1831588778
            },
            {
                "lat": 50.8810647059,
                "lng": -127.1885993999
            },
            {
                "lat": 50.8877261233,
                "lng": -127.2020029962
            },
            {
                "lat": 50.8961254301,
                "lng": -127.219970452
            },
            {
                "lat": 50.9040603086,
                "lng": -127.2265017238
            },
            {
                "lat": 50.9119905403,
                "lng": -127.2423704505
            },
            {
                "lat": 50.9196926215,
                "lng": -127.2552023776
            },
            {
                "lat": 50.9236602963,
                "lng": -127.2696680321
            },
            {
                "lat": 50.9196927202,
                "lng": -127.2815625312
            },
            {
                "lat": 50.9149663496,
                "lng": -127.2989653575
            },
            {
                "lat": 50.9154935445,
                "lng": -127.3156279029
            },
            {
                "lat": 50.9164272317,
                "lng": -127.3272927353
            },
            {
                "lat": 50.9238931425,
                "lng": -127.3370973571
            },
            {
                "lat": 50.9306182791,
                "lng": -127.3430636827
            },
            {
                "lat": 50.9368288434,
                "lng": -127.3605655506
            },
            {
                "lat": 50.9360235816,
                "lng": -127.3671950341
            },
            {
                "lat": 50.9346240257,
                "lng": -127.382599775
            },
            {
                "lat": 50.9420885371,
                "lng": -127.4054642316
            },
            {
                "lat": 50.9497906894,
                "lng": -127.4245916849
            },
            {
                "lat": 50.954689559,
                "lng": -127.4194564024
            },
            {
                "lat": 50.956520624,
                "lng": -127.4160386768
            },
            {
                "lat": 50.9600561474,
                "lng": -127.4094237113
            },
            {
                "lat": 50.9644894946,
                "lng": -127.4052267319
            },
            {
                "lat": 50.9698558379,
                "lng": -127.4077904316
            },
            {
                "lat": 50.9761589661,
                "lng": -127.4143296596
            },
            {
                "lat": 50.9794230705,
                "lng": -127.4280930237
            },
            {
                "lat": 50.9822240643,
                "lng": -127.4425581544
            },
            {
                "lat": 50.9822236803,
                "lng": -127.4509586751
            },
            {
                "lat": 50.9831540576,
                "lng": -127.4609910609
            },
            {
                "lat": 50.9787220605,
                "lng": -127.4656601966
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 383,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 12-13",
            "LABEL": "12-13",
            "FISHERY_AREA_ID": 2071,
            "OBJECTID": 527,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 232326784.9762,
            "FEATURE_LENGTH_M": 80900.1972,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.931529992271116,
            "lng": -127.36750246738892
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.9755205806,
                "lng": -125.5228732576
            },
            {
                "lat": 50.9753531132,
                "lng": -125.5270698882
            },
            {
                "lat": 50.975883719,
                "lng": -125.5366904806
            },
            {
                "lat": 50.953213234,
                "lng": -125.5574194506
            },
            {
                "lat": 50.94386299,
                "lng": -125.5599209947
            },
            {
                "lat": 50.9423563354,
                "lng": -125.5570072548
            },
            {
                "lat": 50.9369998914,
                "lng": -125.5552215791
            },
            {
                "lat": 50.9294089071,
                "lng": -125.5592420983
            },
            {
                "lat": 50.9244994289,
                "lng": -125.5686182606
            },
            {
                "lat": 50.9202618181,
                "lng": -125.5791097136
            },
            {
                "lat": 50.9141083321,
                "lng": -125.5841680676
            },
            {
                "lat": 50.9090998321,
                "lng": -125.5752717989
            },
            {
                "lat": 50.9052886819,
                "lng": -125.5677640503
            },
            {
                "lat": 50.9039914793,
                "lng": -125.5651315688
            },
            {
                "lat": 50.9039884203,
                "lng": -125.5304028248
            },
            {
                "lat": 50.9040939978,
                "lng": -125.5202021827
            },
            {
                "lat": 50.9079822084,
                "lng": -125.5190663746
            },
            {
                "lat": 50.9099916941,
                "lng": -125.5181730208
            },
            {
                "lat": 50.9133409583,
                "lng": -125.5141604881
            },
            {
                "lat": 50.9171337766,
                "lng": -125.5070109126
            },
            {
                "lat": 50.9202620085,
                "lng": -125.5021051859
            },
            {
                "lat": 50.926510078,
                "lng": -125.495185532
            },
            {
                "lat": 50.9318659007,
                "lng": -125.4916150241
            },
            {
                "lat": 50.9378929927,
                "lng": -125.4889376045
            },
            {
                "lat": 50.9454800223,
                "lng": -125.4880450736
            },
            {
                "lat": 50.9512827948,
                "lng": -125.4893798896
            },
            {
                "lat": 50.9582026067,
                "lng": -125.4947352569
            },
            {
                "lat": 50.9640050432,
                "lng": -125.5023268106
            },
            {
                "lat": 50.9702531135,
                "lng": -125.509918102
            },
            {
                "lat": 50.9736021848,
                "lng": -125.5177308355
            },
            {
                "lat": 50.9755205806,
                "lng": -125.5228732576
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 384,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 32,
            "MANAGEMENT_AREA_NAME": "Subarea 12-32",
            "LABEL": "12-32",
            "FISHERY_AREA_ID": 2090,
            "OBJECTID": 528,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 33495631.645,
            "FEATURE_LENGTH_M": 23750.4872,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.936169571754824,
            "lng": -125.5299153819226
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8978459416,
                "lng": -126.2283250621
            },
            {
                "lat": 50.8937984112,
                "lng": -126.2100070401
            },
            {
                "lat": 50.8949893371,
                "lng": -126.1964417712
            },
            {
                "lat": 50.9037935,
                "lng": -126.1852575418
            },
            {
                "lat": 50.9337728097,
                "lng": -126.1712190426
            },
            {
                "lat": 50.9523354012,
                "lng": -126.1657488204
            },
            {
                "lat": 50.9697032516,
                "lng": -126.1740806584
            },
            {
                "lat": 50.9749375682,
                "lng": -126.1824041124
            },
            {
                "lat": 50.9723203415,
                "lng": -126.190971179
            },
            {
                "lat": 50.9625659379,
                "lng": -126.2038199808
            },
            {
                "lat": 50.9511457144,
                "lng": -126.2102439523
            },
            {
                "lat": 50.9411504981,
                "lng": -126.2152402542
            },
            {
                "lat": 50.9335369247,
                "lng": -126.2216637851
            },
            {
                "lat": 50.9306796695,
                "lng": -126.2283256651
            },
            {
                "lat": 50.9247056899,
                "lng": -126.2277300978
            },
            {
                "lat": 50.9052498422,
                "lng": -126.2277305328
            },
            {
                "lat": 50.8978459416,
                "lng": -126.2283250621
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 385,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 46,
            "MANAGEMENT_AREA_NAME": "Subarea 12-46",
            "LABEL": "12-46",
            "FISHERY_AREA_ID": 2104,
            "OBJECTID": 529,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 25823029.9005,
            "FEATURE_LENGTH_M": 21793.4663,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.93178686943529,
            "lng": -126.20397262107062
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.6379849312,
                "lng": -124.839280272
            },
            {
                "lat": 50.658172625,
                "lng": -124.8237449349
            },
            {
                "lat": 50.7047620074,
                "lng": -124.8051159128
            },
            {
                "lat": 50.7529030909,
                "lng": -124.7973472112
            },
            {
                "lat": 50.7886196805,
                "lng": -124.8221970252
            },
            {
                "lat": 50.8258896853,
                "lng": -124.8377229726
            },
            {
                "lat": 50.8491819652,
                "lng": -124.7756045651
            },
            {
                "lat": 50.8848997172,
                "lng": -124.705727394
            },
            {
                "lat": 50.9237216803,
                "lng": -124.7569729835
            },
            {
                "lat": 50.953228425,
                "lng": -124.8299557055
            },
            {
                "lat": 50.9656524559,
                "lng": -124.8827591454
            },
            {
                "lat": 50.8895574518,
                "lng": -124.9293451331
            },
            {
                "lat": 50.8445245604,
                "lng": -124.9883584969
            },
            {
                "lat": 50.7824065208,
                "lng": -125.006989387
            },
            {
                "lat": 50.7047617923,
                "lng": -124.9510887846
            },
            {
                "lat": 50.6566193668,
                "lng": -124.9417641053
            },
            {
                "lat": 50.6368021401,
                "lng": -124.9162214812
            },
            {
                "lat": 50.6386382402,
                "lng": -124.8981178302
            },
            {
                "lat": 50.6363598866,
                "lng": -124.8644102308
            },
            {
                "lat": 50.6379849312,
                "lng": -124.839280272
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 386,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 22,
            "MANAGEMENT_AREA_NAME": "Subarea 13-22",
            "LABEL": "13-22",
            "FISHERY_AREA_ID": 2127,
            "OBJECTID": 530,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 409377709.5744,
            "FEATURE_LENGTH_M": 94813.9225,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.768633557705,
            "lng": -124.860600192165
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.9418673873,
                "lng": -127.6585618188
            },
            {
                "lat": 50.9447626961,
                "lng": -127.899353456
            },
            {
                "lat": 50.9444844565,
                "lng": -127.8995054641
            },
            {
                "lat": 50.9444846376,
                "lng": -127.8996274769
            },
            {
                "lat": 50.9445227963,
                "lng": -127.8999933074
            },
            {
                "lat": 50.9444575105,
                "lng": -127.90046695
            },
            {
                "lat": 50.9443014561,
                "lng": -127.9007639546
            },
            {
                "lat": 50.9439769806,
                "lng": -127.9009932433
            },
            {
                "lat": 50.9434776795,
                "lng": -127.9010699253
            },
            {
                "lat": 50.941944061,
                "lng": -127.8999930461
            },
            {
                "lat": 50.9407311583,
                "lng": -127.9002681841
            },
            {
                "lat": 50.936611406,
                "lng": -127.9001239454
            },
            {
                "lat": 50.9351536176,
                "lng": -127.8957892819
            },
            {
                "lat": 50.9108394789,
                "lng": -127.8867647239
            },
            {
                "lat": 50.9009136979,
                "lng": -127.8803182836
            },
            {
                "lat": 50.8927158514,
                "lng": -127.8783259882
            },
            {
                "lat": 50.8888019126,
                "lng": -127.8785856806
            },
            {
                "lat": 50.8803861164,
                "lng": -127.8439788091
            },
            {
                "lat": 50.880726306,
                "lng": -127.8433001461
            },
            {
                "lat": 50.8806758042,
                "lng": -127.8430250839
            },
            {
                "lat": 50.8808667542,
                "lng": -127.8416902521
            },
            {
                "lat": 50.8811383586,
                "lng": -127.8403320544
            },
            {
                "lat": 50.8820113728,
                "lng": -127.8385922033
            },
            {
                "lat": 50.9008672471,
                "lng": -127.7760236937
            },
            {
                "lat": 50.9007299051,
                "lng": -127.7719644104
            },
            {
                "lat": 50.9015467812,
                "lng": -127.7687302102
            },
            {
                "lat": 50.9042010577,
                "lng": -127.7649688967
            },
            {
                "lat": 50.9070695679,
                "lng": -127.7554476289
            },
            {
                "lat": 50.9066585169,
                "lng": -127.7534785999
            },
            {
                "lat": 50.9042548782,
                "lng": -127.7462154757
            },
            {
                "lat": 50.9019087861,
                "lng": -127.7408591271
            },
            {
                "lat": 50.901130296,
                "lng": -127.7371147843
            },
            {
                "lat": 50.9016879202,
                "lng": -127.7338787902
            },
            {
                "lat": 50.9043688356,
                "lng": -127.7307507937
            },
            {
                "lat": 50.908390085,
                "lng": -127.7250513606
            },
            {
                "lat": 50.911079102,
                "lng": -127.7235714304
            },
            {
                "lat": 50.9155121948,
                "lng": -127.7274399207
            },
            {
                "lat": 50.9158673171,
                "lng": -127.7265086166
            },
            {
                "lat": 50.9418673873,
                "lng": -127.6585618188
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 387,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 12-12",
            "LABEL": "12-12",
            "FISHERY_AREA_ID": 2070,
            "OBJECTID": 531,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 71289584.2034,
            "FEATURE_LENGTH_M": 42382.2512,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.91428183011795,
            "lng": -127.81723048300002
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5000004886,
                "lng": -128.3500060056
            },
            {
                "lat": 50.5000001526,
                "lng": -129.4578708503
            },
            {
                "lat": 50.4999999894,
                "lng": -130.565735177
            },
            {
                "lat": 50.5000002467,
                "lng": -131.6735993298
            },
            {
                "lat": 50.5000004736,
                "lng": -132.7814637374
            },
            {
                "lat": 50.5000001615,
                "lng": -133.8893279802
            },
            {
                "lat": 50.5000002926,
                "lng": -134.9971928118
            },
            {
                "lat": 50.5000001837,
                "lng": -136.1050569251
            },
            {
                "lat": 50.4280007662,
                "lng": -135.9914858398
            },
            {
                "lat": 50.3748700223,
                "lng": -135.897354759
            },
            {
                "lat": 50.3641587524,
                "lng": -135.8825221189
            },
            {
                "lat": 50.2887157299,
                "lng": -135.7651976615
            },
            {
                "lat": 50.2816053634,
                "lng": -135.7565921077
            },
            {
                "lat": 50.2136196499,
                "lng": -135.6638790595
            },
            {
                "lat": 50.1745031925,
                "lng": -135.6168063616
            },
            {
                "lat": 50.0169906744,
                "lng": -135.4033357581
            },
            {
                "lat": 49.9360472537,
                "lng": -135.280090565
            },
            {
                "lat": 49.8872076186,
                "lng": -135.2144322722
            },
            {
                "lat": 49.7377583287,
                "lng": -134.988708582
            },
            {
                "lat": 49.7055089376,
                "lng": -134.9339899095
            },
            {
                "lat": 49.6363403683,
                "lng": -134.8302150866
            },
            {
                "lat": 49.6067048564,
                "lng": -134.7802575259
            },
            {
                "lat": 49.5907324056,
                "lng": -134.756378642
            },
            {
                "lat": 49.4497219567,
                "lng": -134.519439381
            },
            {
                "lat": 49.4386790471,
                "lng": -134.4994195409
            },
            {
                "lat": 49.3070261758,
                "lng": -134.2508542254
            },
            {
                "lat": 49.1852078971,
                "lng": -133.9914400403
            },
            {
                "lat": 49.0736308815,
                "lng": -133.722122921
            },
            {
                "lat": 49.0000003335,
                "lng": -133.5191502393
            },
            {
                "lat": 49.0000003189,
                "lng": -132.4784246822
            },
            {
                "lat": 49.0000003251,
                "lng": -131.4376982859
            },
            {
                "lat": 49.0000004876,
                "lng": -130.3969721484
            },
            {
                "lat": 49.000000358,
                "lng": -129.3562462079
            },
            {
                "lat": 50.1092298074,
                "lng": -127.9392396991
            },
            {
                "lat": 50.5000004886,
                "lng": -128.3500060056
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 388,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 127,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 127-2",
            "LABEL": "127-2",
            "FISHERY_AREA_ID": 2340,
            "OBJECTID": 532,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 77131386147.1077,
            "FEATURE_LENGTH_M": 1319565.3054,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.89446468531144,
            "lng": -133.51550035552856
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.941036291,
                "lng": -126.5121238142
            },
            {
                "lat": 50.9490050319,
                "lng": -126.5163043489
            },
            {
                "lat": 50.9532886633,
                "lng": -126.520415802
            },
            {
                "lat": 50.9496344163,
                "lng": -126.5255743358
            },
            {
                "lat": 50.9401819697,
                "lng": -126.5403973872
            },
            {
                "lat": 50.9227789269,
                "lng": -126.5698321453
            },
            {
                "lat": 50.9083831126,
                "lng": -126.5895987927
            },
            {
                "lat": 50.8963513029,
                "lng": -126.6321409048
            },
            {
                "lat": 50.8875419123,
                "lng": -126.6564179399
            },
            {
                "lat": 50.879161352,
                "lng": -126.6635057382
            },
            {
                "lat": 50.8705829574,
                "lng": -126.6662144192
            },
            {
                "lat": 50.8683627506,
                "lng": -126.6647336399
            },
            {
                "lat": 50.8651045184,
                "lng": -126.6636508333
            },
            {
                "lat": 50.8703955594,
                "lng": -126.6062770286
            },
            {
                "lat": 50.8701669459,
                "lng": -126.6059948679
            },
            {
                "lat": 50.8699679414,
                "lng": -126.6053238499
            },
            {
                "lat": 50.8698005048,
                "lng": -126.6035841265
            },
            {
                "lat": 50.869949319,
                "lng": -126.6021117499
            },
            {
                "lat": 50.8700333717,
                "lng": -126.6016151957
            },
            {
                "lat": 50.8675723648,
                "lng": -126.5698617287
            },
            {
                "lat": 50.8661343621,
                "lng": -126.5650784919
            },
            {
                "lat": 50.8660391457,
                "lng": -126.5624700131
            },
            {
                "lat": 50.8664326346,
                "lng": -126.5606772176
            },
            {
                "lat": 50.8666757655,
                "lng": -126.5602110568
            },
            {
                "lat": 50.8667829139,
                "lng": -126.5595503573
            },
            {
                "lat": 50.8667828165,
                "lng": -126.558219149
            },
            {
                "lat": 50.8623812675,
                "lng": -126.5535196505
            },
            {
                "lat": 50.8645129873,
                "lng": -126.5494459891
            },
            {
                "lat": 50.8655243888,
                "lng": -126.5418552988
            },
            {
                "lat": 50.8685609088,
                "lng": -126.5362924707
            },
            {
                "lat": 50.8737863364,
                "lng": -126.5310668346
            },
            {
                "lat": 50.8825524176,
                "lng": -126.5341028001
            },
            {
                "lat": 50.8867109464,
                "lng": -126.5373077281
            },
            {
                "lat": 50.8884546412,
                "lng": -126.5386498723
            },
            {
                "lat": 50.8946945026,
                "lng": -126.5356217494
            },
            {
                "lat": 50.8978995191,
                "lng": -126.5268474796
            },
            {
                "lat": 50.8978463064,
                "lng": -126.5256267724
            },
            {
                "lat": 50.8973921328,
                "lng": -126.5148764924
            },
            {
                "lat": 50.9034619987,
                "lng": -126.5017320219
            },
            {
                "lat": 50.9134096084,
                "lng": -126.493133897
            },
            {
                "lat": 50.9189684438,
                "lng": -126.4911036273
            },
            {
                "lat": 50.9233214728,
                "lng": -126.4989165631
            },
            {
                "lat": 50.941036291,
                "lng": -126.5121238142
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 389,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 43,
            "MANAGEMENT_AREA_NAME": "Subarea 12-43",
            "LABEL": "12-43",
            "FISHERY_AREA_ID": 2101,
            "OBJECTID": 533,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 55433484.899,
            "FEATURE_LENGTH_M": 36618.7318,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.89066653535348,
            "lng": -126.56288623246046
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.9418673873,
                "lng": -127.6585618188
            },
            {
                "lat": 50.9158673171,
                "lng": -127.7265086166
            },
            {
                "lat": 50.9155121948,
                "lng": -127.7274399207
            },
            {
                "lat": 50.911079102,
                "lng": -127.7235714304
            },
            {
                "lat": 50.8319128693,
                "lng": -127.6545861263
            },
            {
                "lat": 50.8213039744,
                "lng": -127.5933074393
            },
            {
                "lat": 50.8053892403,
                "lng": -127.4606397338
            },
            {
                "lat": 50.8600846133,
                "lng": -127.5193630498
            },
            {
                "lat": 50.9056017849,
                "lng": -127.5979538466
            },
            {
                "lat": 50.9418673873,
                "lng": -127.6585618188
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 390,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 12-11",
            "LABEL": "12-11",
            "FISHERY_AREA_ID": 2069,
            "OBJECTID": 534,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 125482509.7372,
            "FEATURE_LENGTH_M": 50991.3237,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.885048587069996,
            "lng": -127.63204938010999
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.9509083207,
                "lng": -126.4589013184
            },
            {
                "lat": 50.9436603121,
                "lng": -126.46107449
            },
            {
                "lat": 50.9233214728,
                "lng": -126.4989165631
            },
            {
                "lat": 50.9189684438,
                "lng": -126.4911036273
            },
            {
                "lat": 50.9180722539,
                "lng": -126.4779355549
            },
            {
                "lat": 50.9109310149,
                "lng": -126.4591372784
            },
            {
                "lat": 50.9018902993,
                "lng": -126.4489058354
            },
            {
                "lat": 50.8907050156,
                "lng": -126.4498604252
            },
            {
                "lat": 50.8852349941,
                "lng": -126.4738914419
            },
            {
                "lat": 50.8804746572,
                "lng": -126.491736741
            },
            {
                "lat": 50.8721460168,
                "lng": -126.4917366718
            },
            {
                "lat": 50.8697670076,
                "lng": -126.4865044495
            },
            {
                "lat": 50.8702427813,
                "lng": -126.4719844243
            },
            {
                "lat": 50.8697668859,
                "lng": -126.4467623582
            },
            {
                "lat": 50.8711928139,
                "lng": -126.4265365984
            },
            {
                "lat": 50.8733373903,
                "lng": -126.4098818742
            },
            {
                "lat": 50.8838038662,
                "lng": -126.3972707535
            },
            {
                "lat": 50.8978458955,
                "lng": -126.3927456522
            },
            {
                "lat": 50.9080764253,
                "lng": -126.393700156
            },
            {
                "lat": 50.9111712213,
                "lng": -126.3913194953
            },
            {
                "lat": 50.9097400642,
                "lng": -126.380851818
            },
            {
                "lat": 50.905933483,
                "lng": -126.3580093155
            },
            {
                "lat": 50.9045062072,
                "lng": -126.3408735679
            },
            {
                "lat": 50.9071234004,
                "lng": -126.3175590564
            },
            {
                "lat": 50.9071231057,
                "lng": -126.2997133411
            },
            {
                "lat": 50.9018902036,
                "lng": -126.2647318687
            },
            {
                "lat": 50.8995099005,
                "lng": -126.2426074615
            },
            {
                "lat": 50.8978459416,
                "lng": -126.2283250621
            },
            {
                "lat": 50.9052498422,
                "lng": -126.2277305328
            },
            {
                "lat": 50.9247056899,
                "lng": -126.2277300978
            },
            {
                "lat": 50.9306796695,
                "lng": -126.2283256651
            },
            {
                "lat": 50.9313969231,
                "lng": -126.2407000962
            },
            {
                "lat": 50.933059138,
                "lng": -126.2656864895
            },
            {
                "lat": 50.9349629295,
                "lng": -126.2806781694
            },
            {
                "lat": 50.9397243456,
                "lng": -126.2975690523
            },
            {
                "lat": 50.9397243676,
                "lng": -126.3168412585
            },
            {
                "lat": 50.9387709377,
                "lng": -126.3342131198
            },
            {
                "lat": 50.9416275633,
                "lng": -126.3570565367
            },
            {
                "lat": 50.9402004196,
                "lng": -126.3732378549
            },
            {
                "lat": 50.9376787622,
                "lng": -126.3874593935
            },
            {
                "lat": 50.9402001001,
                "lng": -126.3927453514
            },
            {
                "lat": 50.9456709464,
                "lng": -126.4117817476
            },
            {
                "lat": 50.9504320396,
                "lng": -126.4322511701
            },
            {
                "lat": 50.9525718149,
                "lng": -126.4465255664
            },
            {
                "lat": 50.9509083207,
                "lng": -126.4589013184
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 391,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 45,
            "MANAGEMENT_AREA_NAME": "Subarea 12-45",
            "LABEL": "12-45",
            "FISHERY_AREA_ID": 2103,
            "OBJECTID": 535,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 87884410.4796,
            "FEATURE_LENGTH_M": 56669.5774,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.913838960102225,
            "lng": -126.38071134712442
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.936611406,
                "lng": -127.9001239454
            },
            {
                "lat": 50.9335214209,
                "lng": -127.9049761804
            },
            {
                "lat": 50.9329486426,
                "lng": -127.9114533614
            },
            {
                "lat": 50.9174307275,
                "lng": -127.9273450496
            },
            {
                "lat": 50.9131276871,
                "lng": -127.9215468527
            },
            {
                "lat": 50.9095729047,
                "lng": -127.9069443647
            },
            {
                "lat": 50.9075133166,
                "lng": -127.8966526597
            },
            {
                "lat": 50.9011463555,
                "lng": -127.8872911806
            },
            {
                "lat": 50.9009136979,
                "lng": -127.8803182836
            },
            {
                "lat": 50.9108394789,
                "lng": -127.8867647239
            },
            {
                "lat": 50.9351536176,
                "lng": -127.8957892819
            },
            {
                "lat": 50.936611406,
                "lng": -127.9001239454
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 392,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 536,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 6232794.8051,
            "FEATURE_LENGTH_M": 11266.2803,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.919615888441676,
            "lng": -127.90161081910834
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.9436603121,
                "lng": -126.46107449
            },
            {
                "lat": 50.941036291,
                "lng": -126.5121238142
            },
            {
                "lat": 50.9233214728,
                "lng": -126.4989165631
            },
            {
                "lat": 50.9436603121,
                "lng": -126.46107449
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 393,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 44,
            "MANAGEMENT_AREA_NAME": "Subarea 12-44",
            "LABEL": "12-44",
            "FISHERY_AREA_ID": 2102,
            "OBJECTID": 537,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 3671306.1084,
            "FEATURE_LENGTH_M": 9267.9084,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.937919597000004,
            "lng": -126.48329733932499
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.9174307275,
                "lng": -127.9273450496
            },
            {
                "lat": 50.9234200075,
                "lng": -127.9331506293
            },
            {
                "lat": 50.9234201596,
                "lng": -127.9363326933
            },
            {
                "lat": 50.9226728439,
                "lng": -127.939322973
            },
            {
                "lat": 50.921924471,
                "lng": -127.9419481852
            },
            {
                "lat": 50.9204250674,
                "lng": -127.9449455734
            },
            {
                "lat": 50.9174305219,
                "lng": -127.9507450175
            },
            {
                "lat": 50.9142536698,
                "lng": -127.9586715155
            },
            {
                "lat": 50.9141047118,
                "lng": -127.972938291
            },
            {
                "lat": 50.916164204,
                "lng": -127.9845587115
            },
            {
                "lat": 50.9146914313,
                "lng": -127.9911802089
            },
            {
                "lat": 50.9136656347,
                "lng": -127.9973674277
            },
            {
                "lat": 50.8770297716,
                "lng": -128.0530245372
            },
            {
                "lat": 50.876701839,
                "lng": -128.0530245823
            },
            {
                "lat": 50.8765339324,
                "lng": -128.0530248791
            },
            {
                "lat": 50.8760563672,
                "lng": -128.0531007521
            },
            {
                "lat": 50.8757851846,
                "lng": -128.0534359575
            },
            {
                "lat": 50.8749923911,
                "lng": -128.0546568139
            },
            {
                "lat": 50.8735805347,
                "lng": -128.0565337892
            },
            {
                "lat": 50.8638076999,
                "lng": -128.0632784663
            },
            {
                "lat": 50.8559877947,
                "lng": -128.0542599143
            },
            {
                "lat": 50.8421481038,
                "lng": -128.0163425638
            },
            {
                "lat": 50.8409458589,
                "lng": -127.9922792112
            },
            {
                "lat": 50.8499677087,
                "lng": -127.9760359321
            },
            {
                "lat": 50.8535805628,
                "lng": -127.9591901309
            },
            {
                "lat": 50.8487668082,
                "lng": -127.9230876125
            },
            {
                "lat": 50.8385393217,
                "lng": -127.8767547323
            },
            {
                "lat": 50.8259014256,
                "lng": -127.8021477573
            },
            {
                "lat": 50.8150715753,
                "lng": -127.7233272493
            },
            {
                "lat": 50.8078503063,
                "lng": -127.6806031469
            },
            {
                "lat": 50.8012841488,
                "lng": -127.6564711598
            },
            {
                "lat": 50.8068385367,
                "lng": -127.6550974756
            },
            {
                "lat": 50.8088840697,
                "lng": -127.6546395862
            },
            {
                "lat": 50.8091315742,
                "lng": -127.6545714948
            },
            {
                "lat": 50.809364368,
                "lng": -127.6545863199
            },
            {
                "lat": 50.8246462471,
                "lng": -127.6545865392
            },
            {
                "lat": 50.8319128693,
                "lng": -127.6545861263
            },
            {
                "lat": 50.8362695506,
                "lng": -127.6590109249
            },
            {
                "lat": 50.8377907493,
                "lng": -127.6631623864
            },
            {
                "lat": 50.8411062922,
                "lng": -127.671455059
            },
            {
                "lat": 50.8448412996,
                "lng": -127.6816864459
            },
            {
                "lat": 50.8491249256,
                "lng": -127.6902538842
            },
            {
                "lat": 50.8532718153,
                "lng": -127.7044898433
            },
            {
                "lat": 50.8558960215,
                "lng": -127.7188637187
            },
            {
                "lat": 50.8585242252,
                "lng": -127.7314451672
            },
            {
                "lat": 50.8615641323,
                "lng": -127.7404250249
            },
            {
                "lat": 50.8647423598,
                "lng": -127.7492746742
            },
            {
                "lat": 50.8687514721,
                "lng": -127.7633745877
            },
            {
                "lat": 50.8708226747,
                "lng": -127.778160136
            },
            {
                "lat": 50.8745570766,
                "lng": -127.7947466494
            },
            {
                "lat": 50.877735121,
                "lng": -127.8085713731
            },
            {
                "lat": 50.8795314506,
                "lng": -127.8217007008
            },
            {
                "lat": 50.8800846807,
                "lng": -127.8398058261
            },
            {
                "lat": 50.8806758042,
                "lng": -127.8430250839
            },
            {
                "lat": 50.880726306,
                "lng": -127.8433001461
            },
            {
                "lat": 50.8803861164,
                "lng": -127.8439788091
            },
            {
                "lat": 50.8888019126,
                "lng": -127.8785856806
            },
            {
                "lat": 50.8927158514,
                "lng": -127.8783259882
            },
            {
                "lat": 50.9009136979,
                "lng": -127.8803182836
            },
            {
                "lat": 50.9011463555,
                "lng": -127.8872911806
            },
            {
                "lat": 50.9075133166,
                "lng": -127.8966526597
            },
            {
                "lat": 50.9095729047,
                "lng": -127.9069443647
            },
            {
                "lat": 50.9131276871,
                "lng": -127.9215468527
            },
            {
                "lat": 50.9174307275,
                "lng": -127.9273450496
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 394,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 15,
            "MANAGEMENT_AREA_NAME": "Subarea 12-15",
            "LABEL": "12-15",
            "FISHERY_AREA_ID": 2073,
            "OBJECTID": 538,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 162534373.7763,
            "FEATURE_LENGTH_M": 69775.8097,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.87050839027654,
            "lng": -127.85985771103904
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.329578155,
                "lng": -127.1954957829
            },
            {
                "lat": 51.3244025182,
                "lng": -127.1933211461
            },
            {
                "lat": 51.3160939423,
                "lng": -127.1882858338
            },
            {
                "lat": 51.3110159617,
                "lng": -127.1855013178
            },
            {
                "lat": 51.3124462894,
                "lng": -127.1721800549
            },
            {
                "lat": 51.3122067301,
                "lng": -127.1479108802
            },
            {
                "lat": 51.3236272002,
                "lng": -127.1257781402
            },
            {
                "lat": 51.3367153439,
                "lng": -127.1167370196
            },
            {
                "lat": 51.3412358528,
                "lng": -127.101036232
            },
            {
                "lat": 51.3445667042,
                "lng": -127.0601048756
            },
            {
                "lat": 51.3505175075,
                "lng": -127.0517805962
            },
            {
                "lat": 51.3666950374,
                "lng": -127.0601045743
            },
            {
                "lat": 51.3809746108,
                "lng": -127.0765226616
            },
            {
                "lat": 51.3976289762,
                "lng": -127.0948486161
            },
            {
                "lat": 51.4100034621,
                "lng": -127.111740931
            },
            {
                "lat": 51.4085765094,
                "lng": -127.1243521781
            },
            {
                "lat": 51.3843035652,
                "lng": -127.1429141059
            },
            {
                "lat": 51.3659822392,
                "lng": -127.1612319802
            },
            {
                "lat": 51.350276863,
                "lng": -127.1676558262
            },
            {
                "lat": 51.344806565,
                "lng": -127.1736075936
            },
            {
                "lat": 51.343139524,
                "lng": -127.1795587548
            },
            {
                "lat": 51.3409996955,
                "lng": -127.1866915142
            },
            {
                "lat": 51.33647949,
                "lng": -127.1940692131
            },
            {
                "lat": 51.329578155,
                "lng": -127.1954957829
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 352,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 10,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 10-10",
            "LABEL": "10-10",
            "FISHERY_AREA_ID": 2047,
            "OBJECTID": 496,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 55019140.8302,
            "FEATURE_LENGTH_M": 32604.1153,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.34841045408749,
            "lng": -127.14195523380415
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.329578155,
                "lng": -127.1954957829
            },
            {
                "lat": 51.33647949,
                "lng": -127.1940692131
            },
            {
                "lat": 51.3409996955,
                "lng": -127.1866915142
            },
            {
                "lat": 51.343139524,
                "lng": -127.1795587548
            },
            {
                "lat": 51.344806565,
                "lng": -127.1736075936
            },
            {
                "lat": 51.350276863,
                "lng": -127.1676558262
            },
            {
                "lat": 51.3532639436,
                "lng": -127.171363897
            },
            {
                "lat": 51.3599122435,
                "lng": -127.189407376
            },
            {
                "lat": 51.3491474971,
                "lng": -127.2729725857
            },
            {
                "lat": 51.3469358924,
                "lng": -127.3337478188
            },
            {
                "lat": 51.3415527631,
                "lng": -127.3533783218
            },
            {
                "lat": 51.3304712814,
                "lng": -127.3609694826
            },
            {
                "lat": 51.3181262725,
                "lng": -127.3631898746
            },
            {
                "lat": 51.3136942293,
                "lng": -127.3726881742
            },
            {
                "lat": 51.3150669518,
                "lng": -127.3799670315
            },
            {
                "lat": 51.317791209,
                "lng": -127.395721426
            },
            {
                "lat": 51.3181799668,
                "lng": -127.4158477757
            },
            {
                "lat": 51.3195418712,
                "lng": -127.4355931483
            },
            {
                "lat": 51.3199306084,
                "lng": -127.4493941458
            },
            {
                "lat": 51.3197363653,
                "lng": -127.4663161645
            },
            {
                "lat": 51.3211937942,
                "lng": -127.497146233
            },
            {
                "lat": 51.3242113526,
                "lng": -127.5087133765
            },
            {
                "lat": 51.3246955317,
                "lng": -127.5164942617
            },
            {
                "lat": 51.3232381635,
                "lng": -127.5257341264
            },
            {
                "lat": 51.321971449,
                "lng": -127.5318604546
            },
            {
                "lat": 51.3214875488,
                "lng": -127.5370106211
            },
            {
                "lat": 51.3211937,
                "lng": -127.5382773178
            },
            {
                "lat": 51.3205607313,
                "lng": -127.5403825498
            },
            {
                "lat": 51.3190726426,
                "lng": -127.5395504411
            },
            {
                "lat": 51.3110576322,
                "lng": -127.5350870665
            },
            {
                "lat": 51.310497955,
                "lng": -127.5333628314
            },
            {
                "lat": 51.3102146698,
                "lng": -127.5299532592
            },
            {
                "lat": 51.3097804917,
                "lng": -127.527572729
            },
            {
                "lat": 51.3090671039,
                "lng": -127.5257113702
            },
            {
                "lat": 51.3067582607,
                "lng": -127.525062635
            },
            {
                "lat": 51.2994917895,
                "lng": -127.5234372204
            },
            {
                "lat": 51.2990225488,
                "lng": -127.5134883071
            },
            {
                "lat": 51.2973589988,
                "lng": -127.4842222983
            },
            {
                "lat": 51.2964056975,
                "lng": -127.4616159814
            },
            {
                "lat": 51.2968830992,
                "lng": -127.4373476773
            },
            {
                "lat": 51.2971190425,
                "lng": -127.4140243812
            },
            {
                "lat": 51.2983474363,
                "lng": -127.4050521533
            },
            {
                "lat": 51.2957957847,
                "lng": -127.395126512
            },
            {
                "lat": 51.2944943162,
                "lng": -127.3909000791
            },
            {
                "lat": 51.2948376013,
                "lng": -127.3864283854
            },
            {
                "lat": 51.2952195287,
                "lng": -127.3808908351
            },
            {
                "lat": 51.295718598,
                "lng": -127.3773724142
            },
            {
                "lat": 51.3010601541,
                "lng": -127.3715432646
            },
            {
                "lat": 51.3048177693,
                "lng": -127.3674401688
            },
            {
                "lat": 51.305057658,
                "lng": -127.366905435
            },
            {
                "lat": 51.3049773862,
                "lng": -127.3652801085
            },
            {
                "lat": 51.3046871676,
                "lng": -127.3637991133
            },
            {
                "lat": 51.304507899,
                "lng": -127.3634489167
            },
            {
                "lat": 51.3039017061,
                "lng": -127.3622508325
            },
            {
                "lat": 51.303127474,
                "lng": -127.3582230061
            },
            {
                "lat": 51.3031277004,
                "lng": -127.3546444787
            },
            {
                "lat": 51.3036080214,
                "lng": -127.3518749469
            },
            {
                "lat": 51.3039936262,
                "lng": -127.3496553715
            },
            {
                "lat": 51.3050808416,
                "lng": -127.3447724255
            },
            {
                "lat": 51.3058399873,
                "lng": -127.3415138554
            },
            {
                "lat": 51.3055118858,
                "lng": -127.3383712956
            },
            {
                "lat": 51.3046154921,
                "lng": -127.3353049855
            },
            {
                "lat": 51.3168595224,
                "lng": -127.336281493
            },
            {
                "lat": 51.3228763084,
                "lng": -127.3334344934
            },
            {
                "lat": 51.3260426118,
                "lng": -127.3242570941
            },
            {
                "lat": 51.3282581392,
                "lng": -127.2970271524
            },
            {
                "lat": 51.3279415391,
                "lng": -127.2634743543
            },
            {
                "lat": 51.3323707683,
                "lng": -127.2305527728
            },
            {
                "lat": 51.3352205803,
                "lng": -127.2049183109
            },
            {
                "lat": 51.329578155,
                "lng": -127.1954957829
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 353,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 10,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 10-7",
            "LABEL": "10-7",
            "FISHERY_AREA_ID": 2044,
            "OBJECTID": 497,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 63293983.9337,
            "FEATURE_LENGTH_M": 61131.3276,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.31667698928571,
            "lng": -127.38085615834001
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.3473163843,
                "lng": -127.5696482275
            },
            {
                "lat": 51.3407783451,
                "lng": -127.5732189241
            },
            {
                "lat": 51.3387562755,
                "lng": -127.5755392267
            },
            {
                "lat": 51.3377763047,
                "lng": -127.5773692051
            },
            {
                "lat": 51.3372569118,
                "lng": -127.5782391048
            },
            {
                "lat": 51.33693678,
                "lng": -127.5786211319
            },
            {
                "lat": 51.3172868084,
                "lng": -127.5938037369
            },
            {
                "lat": 51.3049705518,
                "lng": -127.5912317685
            },
            {
                "lat": 51.3019793269,
                "lng": -127.5901260863
            },
            {
                "lat": 51.2983173016,
                "lng": -127.5842288662
            },
            {
                "lat": 51.2928539606,
                "lng": -127.5802758961
            },
            {
                "lat": 51.2904090678,
                "lng": -127.5756758157
            },
            {
                "lat": 51.2910573893,
                "lng": -127.572799516
            },
            {
                "lat": 51.2934988124,
                "lng": -127.5700692835
            },
            {
                "lat": 51.2962297289,
                "lng": -127.5670546494
            },
            {
                "lat": 51.3000415177,
                "lng": -127.5618743699
            },
            {
                "lat": 51.3016208667,
                "lng": -127.5553365293
            },
            {
                "lat": 51.29723808,
                "lng": -127.5464939568
            },
            {
                "lat": 51.2922786801,
                "lng": -127.5398101861
            },
            {
                "lat": 51.2947918437,
                "lng": -127.5314779336
            },
            {
                "lat": 51.2994917895,
                "lng": -127.5234372204
            },
            {
                "lat": 51.3067582607,
                "lng": -127.525062635
            },
            {
                "lat": 51.3090671039,
                "lng": -127.5257113702
            },
            {
                "lat": 51.3097804917,
                "lng": -127.527572729
            },
            {
                "lat": 51.3102146698,
                "lng": -127.5299532592
            },
            {
                "lat": 51.310497955,
                "lng": -127.5333628314
            },
            {
                "lat": 51.3110576322,
                "lng": -127.5350870665
            },
            {
                "lat": 51.3190726426,
                "lng": -127.5395504411
            },
            {
                "lat": 51.3205607313,
                "lng": -127.5403825498
            },
            {
                "lat": 51.3211937,
                "lng": -127.5382773178
            },
            {
                "lat": 51.3214875488,
                "lng": -127.5370106211
            },
            {
                "lat": 51.321971449,
                "lng": -127.5318604546
            },
            {
                "lat": 51.3238947286,
                "lng": -127.5278554501
            },
            {
                "lat": 51.3264199713,
                "lng": -127.5123360747
            },
            {
                "lat": 51.3290367019,
                "lng": -127.4975428677
            },
            {
                "lat": 51.3332745351,
                "lng": -127.4850011333
            },
            {
                "lat": 51.3362546925,
                "lng": -127.484275715
            },
            {
                "lat": 51.3379668623,
                "lng": -127.4888839427
            },
            {
                "lat": 51.3377878571,
                "lng": -127.4988013428
            },
            {
                "lat": 51.3375167791,
                "lng": -127.505661353
            },
            {
                "lat": 51.338508257,
                "lng": -127.5102612307
            },
            {
                "lat": 51.3402208493,
                "lng": -127.5139616238
            },
            {
                "lat": 51.3413045733,
                "lng": -127.5185624467
            },
            {
                "lat": 51.3403130186,
                "lng": -127.5230794285
            },
            {
                "lat": 51.3389591378,
                "lng": -127.5263215434
            },
            {
                "lat": 51.3377609065,
                "lng": -127.5302883933
            },
            {
                "lat": 51.337791137,
                "lng": -127.5322037266
            },
            {
                "lat": 51.3373072259,
                "lng": -127.5358663621
            },
            {
                "lat": 51.3372466499,
                "lng": -127.536567613
            },
            {
                "lat": 51.3455471185,
                "lng": -127.5413969485
            },
            {
                "lat": 51.3511543517,
                "lng": -127.5429385543
            },
            {
                "lat": 51.3494572798,
                "lng": -127.5566180439
            },
            {
                "lat": 51.3473163843,
                "lng": -127.5696482275
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 354,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 10,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 10-5",
            "LABEL": "10-5",
            "FISHERY_AREA_ID": 2042,
            "OBJECTID": 498,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 25516352.8436,
            "FEATURE_LENGTH_M": 26854.2216,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.322218640175464,
            "lng": -127.54223084777547
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5000001837,
                "lng": -136.1050569251
            },
            {
                "lat": 50.5000002926,
                "lng": -134.9971928118
            },
            {
                "lat": 50.5000001615,
                "lng": -133.8893279802
            },
            {
                "lat": 50.5000004736,
                "lng": -132.7814637374
            },
            {
                "lat": 50.5000002467,
                "lng": -131.6735993298
            },
            {
                "lat": 50.4999999894,
                "lng": -130.565735177
            },
            {
                "lat": 50.5000001526,
                "lng": -129.4578708503
            },
            {
                "lat": 50.5000004886,
                "lng": -128.3500060056
            },
            {
                "lat": 50.799766119,
                "lng": -128.6880192463
            },
            {
                "lat": 50.8608317405,
                "lng": -129.1033315962
            },
            {
                "lat": 50.8608315847,
                "lng": -130.1742854643
            },
            {
                "lat": 50.8608317036,
                "lng": -131.2452389677
            },
            {
                "lat": 50.8608317119,
                "lng": -132.3161922903
            },
            {
                "lat": 50.8608318867,
                "lng": -133.3871458563
            },
            {
                "lat": 50.8608320463,
                "lng": -134.4580992413
            },
            {
                "lat": 50.8608320841,
                "lng": -135.5290525305
            },
            {
                "lat": 50.8608319391,
                "lng": -136.600006039
            },
            {
                "lat": 50.8608320492,
                "lng": -136.6046139276
            },
            {
                "lat": 50.7311777995,
                "lng": -136.4427791264
            },
            {
                "lat": 50.5753056869,
                "lng": -136.2238611476
            },
            {
                "lat": 50.5000001837,
                "lng": -136.1050569251
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 355,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 127,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 127-4",
            "LABEL": "127-4",
            "FISHERY_AREA_ID": 2342,
            "OBJECTID": 499,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 22077696418.3539,
            "FEATURE_LENGTH_M": 1202132.1023,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.683511358280946,
            "lng": -133.08085405599053
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.3433874926,
                "lng": -127.7219079249
            },
            {
                "lat": 51.323219747,
                "lng": -127.7225416722
            },
            {
                "lat": 51.3219224375,
                "lng": -127.727432139
            },
            {
                "lat": 51.2711379037,
                "lng": -127.7189405506
            },
            {
                "lat": 51.2603830943,
                "lng": -127.7220152656
            },
            {
                "lat": 51.2540582007,
                "lng": -127.7243661333
            },
            {
                "lat": 51.2517162241,
                "lng": -127.7204589981
            },
            {
                "lat": 51.2489583922,
                "lng": -127.7132494039
            },
            {
                "lat": 51.2487449756,
                "lng": -127.7056124195
            },
            {
                "lat": 51.2527764793,
                "lng": -127.6931987242
            },
            {
                "lat": 51.2597769608,
                "lng": -127.6835481054
            },
            {
                "lat": 51.2650794814,
                "lng": -127.6718823002
            },
            {
                "lat": 51.2668839212,
                "lng": -127.6645653244
            },
            {
                "lat": 51.2719604587,
                "lng": -127.6650315381
            },
            {
                "lat": 51.2760735261,
                "lng": -127.6650310028
            },
            {
                "lat": 51.2764743319,
                "lng": -127.6649473943
            },
            {
                "lat": 51.2768092554,
                "lng": -127.6647644201
            },
            {
                "lat": 51.2769314744,
                "lng": -127.6642002509
            },
            {
                "lat": 51.2768558214,
                "lng": -127.6637730848
            },
            {
                "lat": 51.2767824276,
                "lng": -127.6635738546
            },
            {
                "lat": 51.2766868981,
                "lng": -127.6633154891
            },
            {
                "lat": 51.276566352,
                "lng": -127.6626587784
            },
            {
                "lat": 51.2766117803,
                "lng": -127.6617436505
            },
            {
                "lat": 51.2773286509,
                "lng": -127.6610411962
            },
            {
                "lat": 51.2784110697,
                "lng": -127.6602089004
            },
            {
                "lat": 51.2903786476,
                "lng": -127.6355744216
            },
            {
                "lat": 51.2947851827,
                "lng": -127.6121754896
            },
            {
                "lat": 51.2953033942,
                "lng": -127.6112520315
            },
            {
                "lat": 51.2956465072,
                "lng": -127.6105653878
            },
            {
                "lat": 51.2956882465,
                "lng": -127.6103138204
            },
            {
                "lat": 51.2959096953,
                "lng": -127.6089478534
            },
            {
                "lat": 51.2969433899,
                "lng": -127.6052321153
            },
            {
                "lat": 51.2984582449,
                "lng": -127.6017687714
            },
            {
                "lat": 51.300617392,
                "lng": -127.5948710441
            },
            {
                "lat": 51.3019793269,
                "lng": -127.5901260863
            },
            {
                "lat": 51.3049705518,
                "lng": -127.5912317685
            },
            {
                "lat": 51.3172868084,
                "lng": -127.5938037369
            },
            {
                "lat": 51.33693678,
                "lng": -127.5786211319
            },
            {
                "lat": 51.3372569118,
                "lng": -127.5782391048
            },
            {
                "lat": 51.3377763047,
                "lng": -127.5773692051
            },
            {
                "lat": 51.3387562755,
                "lng": -127.5755392267
            },
            {
                "lat": 51.3407783451,
                "lng": -127.5732189241
            },
            {
                "lat": 51.3473163843,
                "lng": -127.5696482275
            },
            {
                "lat": 51.3485641772,
                "lng": -127.5787515369
            },
            {
                "lat": 51.3480298957,
                "lng": -127.6051635029
            },
            {
                "lat": 51.347495635,
                "lng": -127.6412126689
            },
            {
                "lat": 51.3458905381,
                "lng": -127.665305678
            },
            {
                "lat": 51.3451731344,
                "lng": -127.6795808437
            },
            {
                "lat": 51.3433874926,
                "lng": -127.7219079249
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 356,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 10,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 10-4",
            "LABEL": "10-4",
            "FISHERY_AREA_ID": 2041,
            "OBJECTID": 500,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 74733529.9968,
            "FEATURE_LENGTH_M": 38609.4746,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.29777278813674,
            "lng": -127.6481720208918
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.3389742063,
                "lng": -127.7556384278
            },
            {
                "lat": 51.3294641322,
                "lng": -127.7623755065
            },
            {
                "lat": 51.325992709,
                "lng": -127.766044336
            },
            {
                "lat": 51.3243104148,
                "lng": -127.7685160868
            },
            {
                "lat": 51.3232194953,
                "lng": -127.7718887456
            },
            {
                "lat": 51.3218341957,
                "lng": -127.7746656941
            },
            {
                "lat": 51.3216326319,
                "lng": -127.7775349448
            },
            {
                "lat": 51.3214335779,
                "lng": -127.7794182223
            },
            {
                "lat": 51.3204987238,
                "lng": -127.7800900512
            },
            {
                "lat": 51.3182147748,
                "lng": -127.7815094257
            },
            {
                "lat": 51.2762981427,
                "lng": -127.8076402093
            },
            {
                "lat": 51.2754892969,
                "lng": -127.8081741676
            },
            {
                "lat": 51.275013071,
                "lng": -127.8090356826
            },
            {
                "lat": 51.2741887297,
                "lng": -127.8099062744
            },
            {
                "lat": 51.2730983735,
                "lng": -127.8107758162
            },
            {
                "lat": 51.2720982497,
                "lng": -127.811126937
            },
            {
                "lat": 51.2713590332,
                "lng": -127.8109208833
            },
            {
                "lat": 51.2697068823,
                "lng": -127.810913412
            },
            {
                "lat": 51.2586523607,
                "lng": -127.801697253
            },
            {
                "lat": 51.2318148491,
                "lng": -127.7854537144
            },
            {
                "lat": 51.2309952511,
                "lng": -127.7845764672
            },
            {
                "lat": 51.2296295059,
                "lng": -127.782883256
            },
            {
                "lat": 51.2289197797,
                "lng": -127.7810516164
            },
            {
                "lat": 51.2279894295,
                "lng": -127.7781519323
            },
            {
                "lat": 51.2270850421,
                "lng": -127.7732003861
            },
            {
                "lat": 51.2334281343,
                "lng": -127.7651064243
            },
            {
                "lat": 51.2410580584,
                "lng": -127.7498478075
            },
            {
                "lat": 51.2497255282,
                "lng": -127.7280052404
            },
            {
                "lat": 51.2540582007,
                "lng": -127.7243661333
            },
            {
                "lat": 51.2603830943,
                "lng": -127.7220152656
            },
            {
                "lat": 51.2711379037,
                "lng": -127.7189405506
            },
            {
                "lat": 51.3219224375,
                "lng": -127.727432139
            },
            {
                "lat": 51.323219747,
                "lng": -127.7225416722
            },
            {
                "lat": 51.3433874926,
                "lng": -127.7219079249
            },
            {
                "lat": 51.3389742063,
                "lng": -127.7556384278
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 357,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 10,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 10-3",
            "LABEL": "10-3",
            "FISHERY_AREA_ID": 2040,
            "OBJECTID": 501,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 53906633.6118,
            "FEATURE_LENGTH_M": 31811.3075,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.283005933194296,
            "lng": -127.7719711724057
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.329578155,
                "lng": -127.1954957829
            },
            {
                "lat": 51.3352205803,
                "lng": -127.2049183109
            },
            {
                "lat": 51.3323707683,
                "lng": -127.2305527728
            },
            {
                "lat": 51.3279415391,
                "lng": -127.2634743543
            },
            {
                "lat": 51.3282581392,
                "lng": -127.2970271524
            },
            {
                "lat": 51.3260426118,
                "lng": -127.3242570941
            },
            {
                "lat": 51.3228763084,
                "lng": -127.3334344934
            },
            {
                "lat": 51.3168595224,
                "lng": -127.336281493
            },
            {
                "lat": 51.3046154921,
                "lng": -127.3353049855
            },
            {
                "lat": 51.3042103578,
                "lng": -127.3339239747
            },
            {
                "lat": 51.3027990399,
                "lng": -127.3276291723
            },
            {
                "lat": 51.3023651237,
                "lng": -127.3206863243
            },
            {
                "lat": 51.3025815078,
                "lng": -127.3174293543
            },
            {
                "lat": 51.3067361737,
                "lng": -127.317566523
            },
            {
                "lat": 51.3110156896,
                "lng": -127.298050474
            },
            {
                "lat": 51.3162533038,
                "lng": -127.266167018
            },
            {
                "lat": 51.321250696,
                "lng": -127.2350013418
            },
            {
                "lat": 51.3281474417,
                "lng": -127.2059702747
            },
            {
                "lat": 51.329578155,
                "lng": -127.1954957829
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 358,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 502,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 14362817.1953,
            "FEATURE_LENGTH_M": 22739.4401,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.31835266345264,
            "lng": -127.28098245680526
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.3110159617,
                "lng": -127.1855013178
            },
            {
                "lat": 51.3160939423,
                "lng": -127.1882858338
            },
            {
                "lat": 51.3244025182,
                "lng": -127.1933211461
            },
            {
                "lat": 51.329578155,
                "lng": -127.1954957829
            },
            {
                "lat": 51.3281474417,
                "lng": -127.2059702747
            },
            {
                "lat": 51.321250696,
                "lng": -127.2350013418
            },
            {
                "lat": 51.3162533038,
                "lng": -127.266167018
            },
            {
                "lat": 51.3110156896,
                "lng": -127.298050474
            },
            {
                "lat": 51.3067361737,
                "lng": -127.317566523
            },
            {
                "lat": 51.3025815078,
                "lng": -127.3174293543
            },
            {
                "lat": 51.3008656269,
                "lng": -127.3166046316
            },
            {
                "lat": 51.2918969614,
                "lng": -127.3139572965
            },
            {
                "lat": 51.2872843351,
                "lng": -127.3126602748
            },
            {
                "lat": 51.2862704376,
                "lng": -127.299476389
            },
            {
                "lat": 51.2898411114,
                "lng": -127.267593681
            },
            {
                "lat": 51.2931702006,
                "lng": -127.2407082003
            },
            {
                "lat": 51.2946014236,
                "lng": -127.2197646788
            },
            {
                "lat": 51.3055453762,
                "lng": -127.1926420821
            },
            {
                "lat": 51.3110159617,
                "lng": -127.1855013178
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 359,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 10,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 10-9",
            "LABEL": "10-9",
            "FISHERY_AREA_ID": 2046,
            "OBJECTID": 503,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 25459592.3505,
            "FEATURE_LENGTH_M": 22862.3304,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.30671404338421,
            "lng": -127.25008934833158
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.3046154921,
                "lng": -127.3353049855
            },
            {
                "lat": 51.3055118858,
                "lng": -127.3383712956
            },
            {
                "lat": 51.3058399873,
                "lng": -127.3415138554
            },
            {
                "lat": 51.3050808416,
                "lng": -127.3447724255
            },
            {
                "lat": 51.3039936262,
                "lng": -127.3496553715
            },
            {
                "lat": 51.3036080214,
                "lng": -127.3518749469
            },
            {
                "lat": 51.3031277004,
                "lng": -127.3546444787
            },
            {
                "lat": 51.303127474,
                "lng": -127.3582230061
            },
            {
                "lat": 51.3039017061,
                "lng": -127.3622508325
            },
            {
                "lat": 51.304507899,
                "lng": -127.3634489167
            },
            {
                "lat": 51.3046871676,
                "lng": -127.3637991133
            },
            {
                "lat": 51.3049773862,
                "lng": -127.3652801085
            },
            {
                "lat": 51.305057658,
                "lng": -127.366905435
            },
            {
                "lat": 51.3048177693,
                "lng": -127.3674401688
            },
            {
                "lat": 51.3010601541,
                "lng": -127.3715432646
            },
            {
                "lat": 51.295718598,
                "lng": -127.3773724142
            },
            {
                "lat": 51.2860562663,
                "lng": -127.3773723618
            },
            {
                "lat": 51.2803424908,
                "lng": -127.3756327624
            },
            {
                "lat": 51.2825430194,
                "lng": -127.373069832
            },
            {
                "lat": 51.2844275517,
                "lng": -127.3660513512
            },
            {
                "lat": 51.2859993331,
                "lng": -127.3588253003
            },
            {
                "lat": 51.2873310266,
                "lng": -127.3531032476
            },
            {
                "lat": 51.2874149898,
                "lng": -127.3527451263
            },
            {
                "lat": 51.2877235258,
                "lng": -127.3515316247
            },
            {
                "lat": 51.2878118752,
                "lng": -127.3511816993
            },
            {
                "lat": 51.2880397251,
                "lng": -127.3506619781
            },
            {
                "lat": 51.288387023,
                "lng": -127.3501961265
            },
            {
                "lat": 51.2886917211,
                "lng": -127.3496787367
            },
            {
                "lat": 51.2889866279,
                "lng": -127.3494643023
            },
            {
                "lat": 51.2891124289,
                "lng": -127.3492204917
            },
            {
                "lat": 51.2892412865,
                "lng": -127.3488610324
            },
            {
                "lat": 51.2893416161,
                "lng": -127.34858773
            },
            {
                "lat": 51.2894021976,
                "lng": -127.3484953549
            },
            {
                "lat": 51.2897911466,
                "lng": -127.346084548
            },
            {
                "lat": 51.2898446978,
                "lng": -127.3452149242
            },
            {
                "lat": 51.287250855,
                "lng": -127.3450472716
            },
            {
                "lat": 51.2868502294,
                "lng": -127.3360907342
            },
            {
                "lat": 51.2867429116,
                "lng": -127.3196020964
            },
            {
                "lat": 51.2872843351,
                "lng": -127.3126602748
            },
            {
                "lat": 51.2918969614,
                "lng": -127.3139572965
            },
            {
                "lat": 51.3008656269,
                "lng": -127.3166046316
            },
            {
                "lat": 51.3025815078,
                "lng": -127.3174293543
            },
            {
                "lat": 51.3023651237,
                "lng": -127.3206863243
            },
            {
                "lat": 51.3027990399,
                "lng": -127.3276291723
            },
            {
                "lat": 51.3042103578,
                "lng": -127.3339239747
            },
            {
                "lat": 51.3046154921,
                "lng": -127.3353049855
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 360,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 10,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 10-8",
            "LABEL": "10-8",
            "FISHERY_AREA_ID": 2045,
            "OBJECTID": 504,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 8273569.9713,
            "FEATURE_LENGTH_M": 13331.5663,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.295164877328254,
            "lng": -127.34863728837828
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.2500000305,
                "lng": -128.0737605294
            },
            {
                "lat": 51.2500001578,
                "lng": -129.783340885
            },
            {
                "lat": 50.8608317405,
                "lng": -129.1033315962
            },
            {
                "lat": 50.799766119,
                "lng": -128.6880192463
            },
            {
                "lat": 50.7820011001,
                "lng": -128.6186517436
            },
            {
                "lat": 50.7865366748,
                "lng": -128.4310303619
            },
            {
                "lat": 50.8770297716,
                "lng": -128.0530245372
            },
            {
                "lat": 51.1635814463,
                "lng": -128.0689535902
            },
            {
                "lat": 51.2500000305,
                "lng": -128.0737605294
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 361,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 111,
            "MANAGEMENT_SUBAREA": 111,
            "MANAGEMENT_AREA_NAME": "Subarea 111",
            "LABEL": "111",
            "FISHERY_AREA_ID": 2622,
            "OBJECTID": 505,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4547714238.2046,
            "FEATURE_LENGTH_M": 302133.2476,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.00219411901111,
            "lng": -128.5437636688
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.3019793269,
                "lng": -127.5901260863
            },
            {
                "lat": 51.300617392,
                "lng": -127.5948710441
            },
            {
                "lat": 51.2984582449,
                "lng": -127.6017687714
            },
            {
                "lat": 51.2969433899,
                "lng": -127.6052321153
            },
            {
                "lat": 51.2948187144,
                "lng": -127.6034543807
            },
            {
                "lat": 51.2917022461,
                "lng": -127.6010127732
            },
            {
                "lat": 51.2919956321,
                "lng": -127.5945810113
            },
            {
                "lat": 51.2886351201,
                "lng": -127.5884470164
            },
            {
                "lat": 51.2902415613,
                "lng": -127.5805657914
            },
            {
                "lat": 51.2892190305,
                "lng": -127.5738450876
            },
            {
                "lat": 51.2922854514,
                "lng": -127.5701976512
            },
            {
                "lat": 51.294964201,
                "lng": -127.5672225282
            },
            {
                "lat": 51.295006382,
                "lng": -127.5567239566
            },
            {
                "lat": 51.2945023888,
                "lng": -127.5563203351
            },
            {
                "lat": 51.2876011212,
                "lng": -127.5537034741
            },
            {
                "lat": 51.2802271912,
                "lng": -127.5548926876
            },
            {
                "lat": 51.2833170545,
                "lng": -127.5337142503
            },
            {
                "lat": 51.282368452,
                "lng": -127.4965967729
            },
            {
                "lat": 51.2880784186,
                "lng": -127.4742277005
            },
            {
                "lat": 51.2909355066,
                "lng": -127.4456710662
            },
            {
                "lat": 51.2925454338,
                "lng": -127.4364171446
            },
            {
                "lat": 51.292449561,
                "lng": -127.4210280025
            },
            {
                "lat": 51.2929270908,
                "lng": -127.4060206264
            },
            {
                "lat": 51.2941707008,
                "lng": -127.3951273371
            },
            {
                "lat": 51.2944943162,
                "lng": -127.3909000791
            },
            {
                "lat": 51.2957957847,
                "lng": -127.395126512
            },
            {
                "lat": 51.2983474363,
                "lng": -127.4050521533
            },
            {
                "lat": 51.2971190425,
                "lng": -127.4140243812
            },
            {
                "lat": 51.2968830992,
                "lng": -127.4373476773
            },
            {
                "lat": 51.2964056975,
                "lng": -127.4616159814
            },
            {
                "lat": 51.2973589988,
                "lng": -127.4842222983
            },
            {
                "lat": 51.2990225488,
                "lng": -127.5134883071
            },
            {
                "lat": 51.2994917895,
                "lng": -127.5234372204
            },
            {
                "lat": 51.2947918437,
                "lng": -127.5314779336
            },
            {
                "lat": 51.2922786801,
                "lng": -127.5398101861
            },
            {
                "lat": 51.29723808,
                "lng": -127.5464939568
            },
            {
                "lat": 51.3016208667,
                "lng": -127.5553365293
            },
            {
                "lat": 51.3000415177,
                "lng": -127.5618743699
            },
            {
                "lat": 51.2962297289,
                "lng": -127.5670546494
            },
            {
                "lat": 51.2934988124,
                "lng": -127.5700692835
            },
            {
                "lat": 51.2910573893,
                "lng": -127.572799516
            },
            {
                "lat": 51.2904090678,
                "lng": -127.5756758157
            },
            {
                "lat": 51.2928539606,
                "lng": -127.5802758961
            },
            {
                "lat": 51.2983173016,
                "lng": -127.5842288662
            },
            {
                "lat": 51.3019793269,
                "lng": -127.5901260863
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 362,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 506,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 14372268.4109,
            "FEATURE_LENGTH_M": 34681.5865,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.29402722002444,
            "lng": -127.52671567355553
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.295718598,
                "lng": -127.3773724142
            },
            {
                "lat": 51.2952195287,
                "lng": -127.3808908351
            },
            {
                "lat": 51.2948376013,
                "lng": -127.3864283854
            },
            {
                "lat": 51.2944943162,
                "lng": -127.3909000791
            },
            {
                "lat": 51.2941707008,
                "lng": -127.3951273371
            },
            {
                "lat": 51.2929270908,
                "lng": -127.4060206264
            },
            {
                "lat": 51.292449561,
                "lng": -127.4210280025
            },
            {
                "lat": 51.2925454338,
                "lng": -127.4364171446
            },
            {
                "lat": 51.2909355066,
                "lng": -127.4456710662
            },
            {
                "lat": 51.2880784186,
                "lng": -127.4742277005
            },
            {
                "lat": 51.282368452,
                "lng": -127.4965967729
            },
            {
                "lat": 51.2833170545,
                "lng": -127.5337142503
            },
            {
                "lat": 51.2802271912,
                "lng": -127.5548926876
            },
            {
                "lat": 51.2876011212,
                "lng": -127.5537034741
            },
            {
                "lat": 51.2945023888,
                "lng": -127.5563203351
            },
            {
                "lat": 51.295006382,
                "lng": -127.5567239566
            },
            {
                "lat": 51.294964201,
                "lng": -127.5672225282
            },
            {
                "lat": 51.2922854514,
                "lng": -127.5701976512
            },
            {
                "lat": 51.2892190305,
                "lng": -127.5738450876
            },
            {
                "lat": 51.2902415613,
                "lng": -127.5805657914
            },
            {
                "lat": 51.2886351201,
                "lng": -127.5884470164
            },
            {
                "lat": 51.2919956321,
                "lng": -127.5945810113
            },
            {
                "lat": 51.2917022461,
                "lng": -127.6010127732
            },
            {
                "lat": 51.2948187144,
                "lng": -127.6034543807
            },
            {
                "lat": 51.2969433899,
                "lng": -127.6052321153
            },
            {
                "lat": 51.2959096953,
                "lng": -127.6089478534
            },
            {
                "lat": 51.2956882465,
                "lng": -127.6103138204
            },
            {
                "lat": 51.2956465072,
                "lng": -127.6105653878
            },
            {
                "lat": 51.2953033942,
                "lng": -127.6112520315
            },
            {
                "lat": 51.2947851827,
                "lng": -127.6121754896
            },
            {
                "lat": 51.2903786476,
                "lng": -127.6355744216
            },
            {
                "lat": 51.2784110697,
                "lng": -127.6602089004
            },
            {
                "lat": 51.2773286509,
                "lng": -127.6610411962
            },
            {
                "lat": 51.2766117803,
                "lng": -127.6617436505
            },
            {
                "lat": 51.276566352,
                "lng": -127.6626587784
            },
            {
                "lat": 51.2766868981,
                "lng": -127.6633154891
            },
            {
                "lat": 51.2767824276,
                "lng": -127.6635738546
            },
            {
                "lat": 51.2768558214,
                "lng": -127.6637730848
            },
            {
                "lat": 51.2769314744,
                "lng": -127.6642002509
            },
            {
                "lat": 51.2768092554,
                "lng": -127.6647644201
            },
            {
                "lat": 51.2764743319,
                "lng": -127.6649473943
            },
            {
                "lat": 51.2760735261,
                "lng": -127.6650310028
            },
            {
                "lat": 51.2719604587,
                "lng": -127.6650315381
            },
            {
                "lat": 51.2668839212,
                "lng": -127.6645653244
            },
            {
                "lat": 51.2686162866,
                "lng": -127.6601789538
            },
            {
                "lat": 51.2702904043,
                "lng": -127.6567309807
            },
            {
                "lat": 51.2722473368,
                "lng": -127.6519325441
            },
            {
                "lat": 51.2727430955,
                "lng": -127.6490707279
            },
            {
                "lat": 51.2733226062,
                "lng": -127.6371842907
            },
            {
                "lat": 51.2715413879,
                "lng": -127.6313553049
            },
            {
                "lat": 51.2628932203,
                "lng": -127.6210552897
            },
            {
                "lat": 51.2571259713,
                "lng": -127.6016999489
            },
            {
                "lat": 51.2542422641,
                "lng": -127.5761557866
            },
            {
                "lat": 51.2546545822,
                "lng": -127.5444416408
            },
            {
                "lat": 51.2546544696,
                "lng": -127.5147851925
            },
            {
                "lat": 51.254654468,
                "lng": -127.4838863221
            },
            {
                "lat": 51.2600095527,
                "lng": -127.4645318093
            },
            {
                "lat": 51.2633054967,
                "lng": -127.4468156725
            },
            {
                "lat": 51.2641297119,
                "lng": -127.4266362962
            },
            {
                "lat": 51.2678371883,
                "lng": -127.4138643543
            },
            {
                "lat": 51.2744249883,
                "lng": -127.4068602577
            },
            {
                "lat": 51.2797812269,
                "lng": -127.3996204792
            },
            {
                "lat": 51.2801588487,
                "lng": -127.3980795337
            },
            {
                "lat": 51.2821620122,
                "lng": -127.3894351812
            },
            {
                "lat": 51.2813411906,
                "lng": -127.3832399478
            },
            {
                "lat": 51.2802961431,
                "lng": -127.3770442534
            },
            {
                "lat": 51.2803424908,
                "lng": -127.3756327624
            },
            {
                "lat": 51.2860562663,
                "lng": -127.3773723618
            },
            {
                "lat": 51.295718598,
                "lng": -127.3773724142
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 363,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 10,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 10-12",
            "LABEL": "10-12",
            "FISHERY_AREA_ID": 2049,
            "OBJECTID": 507,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 59345499.8825,
            "FEATURE_LENGTH_M": 48302.6467,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.28115713247535,
            "lng": -127.54245303794639
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.2898446978,
                "lng": -127.3452149242
            },
            {
                "lat": 51.2897911466,
                "lng": -127.346084548
            },
            {
                "lat": 51.2894021976,
                "lng": -127.3484953549
            },
            {
                "lat": 51.2893416161,
                "lng": -127.34858773
            },
            {
                "lat": 51.2892412865,
                "lng": -127.3488610324
            },
            {
                "lat": 51.2891124289,
                "lng": -127.3492204917
            },
            {
                "lat": 51.2889866279,
                "lng": -127.3494643023
            },
            {
                "lat": 51.2886917211,
                "lng": -127.3496787367
            },
            {
                "lat": 51.288387023,
                "lng": -127.3501961265
            },
            {
                "lat": 51.2880397251,
                "lng": -127.3506619781
            },
            {
                "lat": 51.2878118752,
                "lng": -127.3511816993
            },
            {
                "lat": 51.2877235258,
                "lng": -127.3515316247
            },
            {
                "lat": 51.2874149898,
                "lng": -127.3527451263
            },
            {
                "lat": 51.2873310266,
                "lng": -127.3531032476
            },
            {
                "lat": 51.2863126255,
                "lng": -127.354316559
            },
            {
                "lat": 51.2827522226,
                "lng": -127.3568882992
            },
            {
                "lat": 51.2784578931,
                "lng": -127.361549648
            },
            {
                "lat": 51.2738990246,
                "lng": -127.3666764381
            },
            {
                "lat": 51.269447044,
                "lng": -127.3685157605
            },
            {
                "lat": 51.2636905989,
                "lng": -127.3690804201
            },
            {
                "lat": 51.2560772493,
                "lng": -127.3759767199
            },
            {
                "lat": 51.2536961238,
                "lng": -127.3878789803
            },
            {
                "lat": 51.2515568331,
                "lng": -127.4042971322
            },
            {
                "lat": 51.2506065894,
                "lng": -127.4228515162
            },
            {
                "lat": 51.2477500925,
                "lng": -127.4385606051
            },
            {
                "lat": 51.2470361526,
                "lng": -127.4473646589
            },
            {
                "lat": 51.244891707,
                "lng": -127.4597312328
            },
            {
                "lat": 51.2422749615,
                "lng": -127.4702075958
            },
            {
                "lat": 51.2368043748,
                "lng": -127.4766314067
            },
            {
                "lat": 51.2299043296,
                "lng": -127.4759140764
            },
            {
                "lat": 51.226570196,
                "lng": -127.4599764761
            },
            {
                "lat": 51.2260967011,
                "lng": -127.4368893719
            },
            {
                "lat": 51.2272874245,
                "lng": -127.4071500824
            },
            {
                "lat": 51.2270464452,
                "lng": -127.3890687715
            },
            {
                "lat": 51.229190844,
                "lng": -127.3714593565
            },
            {
                "lat": 51.2282373932,
                "lng": -127.3624121248
            },
            {
                "lat": 51.2263341515,
                "lng": -127.3500435293
            },
            {
                "lat": 51.2272876341,
                "lng": -127.3386232971
            },
            {
                "lat": 51.2265703477,
                "lng": -127.3248212104
            },
            {
                "lat": 51.2296683944,
                "lng": -127.3081674066
            },
            {
                "lat": 51.2315671368,
                "lng": -127.2967454219
            },
            {
                "lat": 51.2339478734,
                "lng": -127.2865068073
            },
            {
                "lat": 51.2377545193,
                "lng": -127.2729492017
            },
            {
                "lat": 51.2427520754,
                "lng": -127.2603373768
            },
            {
                "lat": 51.2496532761,
                "lng": -127.2515333594
            },
            {
                "lat": 51.2548861598,
                "lng": -127.2510607697
            },
            {
                "lat": 51.2591699723,
                "lng": -127.2608107562
            },
            {
                "lat": 51.2575031178,
                "lng": -127.271759305
            },
            {
                "lat": 51.2503656418,
                "lng": -127.2953108018
            },
            {
                "lat": 51.2463228287,
                "lng": -127.3148271439
            },
            {
                "lat": 51.246558913,
                "lng": -127.3314811626
            },
            {
                "lat": 51.2467997114,
                "lng": -127.3440927853
            },
            {
                "lat": 51.2477500621,
                "lng": -127.350754281
            },
            {
                "lat": 51.2567906353,
                "lng": -127.3486180444
            },
            {
                "lat": 51.2694016598,
                "lng": -127.3471903928
            },
            {
                "lat": 51.2763017106,
                "lng": -127.3509901413
            },
            {
                "lat": 51.2839168375,
                "lng": -127.3476643769
            },
            {
                "lat": 51.287250855,
                "lng": -127.3450472716
            },
            {
                "lat": 51.2895010226,
                "lng": -127.3452834404
            },
            {
                "lat": 51.2898446978,
                "lng": -127.3452149242
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 364,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 10,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 10-11",
            "LABEL": "10-11",
            "FISHERY_AREA_ID": 2048,
            "OBJECTID": 508,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 40979209.557,
            "FEATURE_LENGTH_M": 42185.7719,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.25981009914167,
            "lng": -127.35663762271169
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.287250855,
                "lng": -127.3450472716
            },
            {
                "lat": 51.2898446978,
                "lng": -127.3452149242
            },
            {
                "lat": 51.2895010226,
                "lng": -127.3452834404
            },
            {
                "lat": 51.287250855,
                "lng": -127.3450472716
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 365,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 509,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 913.2474,
            "FEATURE_LENGTH_M": 578.9823,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.2884618576,
            "lng": -127.34514822694999
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.05483711,
                "lng": -126.8542854971
            },
            {
                "lat": 51.0494306733,
                "lng": -126.8297799168
            },
            {
                "lat": 51.0494304013,
                "lng": -126.7927552639
            },
            {
                "lat": 51.0469624969,
                "lng": -126.7606660558
            },
            {
                "lat": 51.0362660515,
                "lng": -126.7491452701
            },
            {
                "lat": 51.0107606814,
                "lng": -126.755729419
            },
            {
                "lat": 50.9901924898,
                "lng": -126.7532580774
            },
            {
                "lat": 50.9753835999,
                "lng": -126.7450331442
            },
            {
                "lat": 50.9762076257,
                "lng": -126.7252885922
            },
            {
                "lat": 50.9836115556,
                "lng": -126.6857986868
            },
            {
                "lat": 51.0082927595,
                "lng": -126.6438372143
            },
            {
                "lat": 51.0486075197,
                "lng": -126.6232674876
            },
            {
                "lat": 51.096328264,
                "lng": -126.6438369668
            },
            {
                "lat": 51.1432262527,
                "lng": -126.6290283237
            },
            {
                "lat": 51.1893002628,
                "lng": -126.6240922749
            },
            {
                "lat": 51.2304378237,
                "lng": -126.6454771799
            },
            {
                "lat": 51.2271466069,
                "lng": -126.6742786706
            },
            {
                "lat": 51.1884768296,
                "lng": -126.6890869914
            },
            {
                "lat": 51.1572114609,
                "lng": -126.7096561759
            },
            {
                "lat": 51.1374660252,
                "lng": -126.7384487231
            },
            {
                "lat": 51.1251258193,
                "lng": -126.7590183829
            },
            {
                "lat": 51.1119620027,
                "lng": -126.7762979832
            },
            {
                "lat": 51.1012643215,
                "lng": -126.7812346958
            },
            {
                "lat": 51.0930363644,
                "lng": -126.787818661
            },
            {
                "lat": 51.0881001102,
                "lng": -126.7960433698
            },
            {
                "lat": 51.0856320888,
                "lng": -126.8116768174
            },
            {
                "lat": 51.0839883919,
                "lng": -126.835540661
            },
            {
                "lat": 51.083038219,
                "lng": -126.8413541719
            },
            {
                "lat": 51.0826030411,
                "lng": -126.8457341548
            },
            {
                "lat": 51.0819094188,
                "lng": -126.8507234935
            },
            {
                "lat": 51.0816077423,
                "lng": -126.8525465549
            },
            {
                "lat": 51.0815086062,
                "lng": -126.8531876019
            },
            {
                "lat": 51.0637019969,
                "lng": -126.853188128
            },
            {
                "lat": 51.05483711,
                "lng": -126.8542854971
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 366,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 11,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 11-10",
            "LABEL": "11-10",
            "FISHERY_AREA_ID": 2059,
            "OBJECTID": 510,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 221627910.1671,
            "FEATURE_LENGTH_M": 76074.3408,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.08287916833822,
            "lng": -126.7579823560206
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.1507989853,
                "lng": -127.4752583154
            },
            {
                "lat": 51.1536556948,
                "lng": -127.4681171088
            },
            {
                "lat": 51.1567453699,
                "lng": -127.4638365172
            },
            {
                "lat": 51.1586537949,
                "lng": -127.4543149541
            },
            {
                "lat": 51.1581763908,
                "lng": -127.4417036106
            },
            {
                "lat": 51.1579367421,
                "lng": -127.4345709313
            },
            {
                "lat": 51.1534162648,
                "lng": -127.4312367402
            },
            {
                "lat": 51.145801466,
                "lng": -127.4324259713
            },
            {
                "lat": 51.1381878556,
                "lng": -127.4340896695
            },
            {
                "lat": 51.1345174995,
                "lng": -127.4322204188
            },
            {
                "lat": 51.1351703206,
                "lng": -127.4096751666
            },
            {
                "lat": 51.1367604103,
                "lng": -127.4062507442
            },
            {
                "lat": 51.1371759396,
                "lng": -127.4042519208
            },
            {
                "lat": 51.1412807586,
                "lng": -127.4026798338
            },
            {
                "lat": 51.1515117197,
                "lng": -127.3957825744
            },
            {
                "lat": 51.1629331076,
                "lng": -127.3969719391
            },
            {
                "lat": 51.1757851769,
                "lng": -127.3986356942
            },
            {
                "lat": 51.1810194565,
                "lng": -127.4033969534
            },
            {
                "lat": 51.1881556692,
                "lng": -127.4000631883
            },
            {
                "lat": 51.1952979904,
                "lng": -127.39126584
            },
            {
                "lat": 51.1941066362,
                "lng": -127.3819812602
            },
            {
                "lat": 51.1898225053,
                "lng": -127.3703230669
            },
            {
                "lat": 51.1833986531,
                "lng": -127.3600920537
            },
            {
                "lat": 51.1795924177,
                "lng": -127.3465272385
            },
            {
                "lat": 51.1784022316,
                "lng": -127.3224946077
            },
            {
                "lat": 51.180542172,
                "lng": -127.2932289883
            },
            {
                "lat": 51.1833990675,
                "lng": -127.2577745044
            },
            {
                "lat": 51.1938671181,
                "lng": -127.2425455688
            },
            {
                "lat": 51.2095712796,
                "lng": -127.2365951923
            },
            {
                "lat": 51.2157589265,
                "lng": -127.2415922873
            },
            {
                "lat": 51.2164723625,
                "lng": -127.2523042104
            },
            {
                "lat": 51.213141786,
                "lng": -127.2651513551
            },
            {
                "lat": 51.206717873,
                "lng": -127.278472602
            },
            {
                "lat": 51.2007681026,
                "lng": -127.2918011515
            },
            {
                "lat": 51.1986274825,
                "lng": -127.3065571934
            },
            {
                "lat": 51.2005304729,
                "lng": -127.312027227
            },
            {
                "lat": 51.2048146078,
                "lng": -127.32939875
            },
            {
                "lat": 51.212665837,
                "lng": -127.3567573644
            },
            {
                "lat": 51.2178998112,
                "lng": -127.3746029817
            },
            {
                "lat": 51.2198031192,
                "lng": -127.4107746995
            },
            {
                "lat": 51.2117125295,
                "lng": -127.427428477
            },
            {
                "lat": 51.1960104344,
                "lng": -127.4495617897
            },
            {
                "lat": 51.1829227815,
                "lng": -127.4731135042
            },
            {
                "lat": 51.1698340947,
                "lng": -127.4807286766
            },
            {
                "lat": 51.1572231262,
                "lng": -127.4807286176
            },
            {
                "lat": 51.1507989853,
                "lng": -127.4752583154
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 367,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 11,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 11-5",
            "LABEL": "11-5",
            "FISHERY_AREA_ID": 2054,
            "OBJECTID": 511,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 66274001.7948,
            "FEATURE_LENGTH_M": 51420.039,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.177856196239134,
            "lng": -127.3803167342522
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.1240385551,
                "lng": -127.1537016338
            },
            {
                "lat": 51.1209562825,
                "lng": -127.1336294241
            },
            {
                "lat": 51.1226699444,
                "lng": -127.122100358
            },
            {
                "lat": 51.1294707229,
                "lng": -127.1134868387
            },
            {
                "lat": 51.1417117207,
                "lng": -127.0980756697
            },
            {
                "lat": 51.1530494645,
                "lng": -127.0853728934
            },
            {
                "lat": 51.1553149465,
                "lng": -127.0735850195
            },
            {
                "lat": 51.1485139366,
                "lng": -127.0495527226
            },
            {
                "lat": 51.1476058185,
                "lng": -127.005577155
            },
            {
                "lat": 51.1503299585,
                "lng": -126.9615938622
            },
            {
                "lat": 51.1584894434,
                "lng": -126.9262311949
            },
            {
                "lat": 51.1793439987,
                "lng": -126.9035576397
            },
            {
                "lat": 51.1965748693,
                "lng": -126.9058227168
            },
            {
                "lat": 51.2038309369,
                "lng": -126.9248658489
            },
            {
                "lat": 51.196575092,
                "lng": -126.9407343858
            },
            {
                "lat": 51.1838795693,
                "lng": -126.9579698931
            },
            {
                "lat": 51.1879614045,
                "lng": -126.9951474028
            },
            {
                "lat": 51.1961210166,
                "lng": -127.0373153511
            },
            {
                "lat": 51.2006565414,
                "lng": -127.0840152388
            },
            {
                "lat": 51.196574716,
                "lng": -127.1016996335
            },
            {
                "lat": 51.1720928641,
                "lng": -127.1257326121
            },
            {
                "lat": 51.1417116131,
                "lng": -127.1393358344
            },
            {
                "lat": 51.1276626764,
                "lng": -127.1536643188
            },
            {
                "lat": 51.1260456709,
                "lng": -127.1536795521
            },
            {
                "lat": 51.1240385551,
                "lng": -127.1537016338
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 368,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 11,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 11-6",
            "LABEL": "11-6",
            "FISHERY_AREA_ID": 2055,
            "OBJECTID": 512,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 79738351.4921,
            "FEATURE_LENGTH_M": 46855.4667,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.159408812715995,
            "lng": -127.05200595334401
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.1710894268,
                "lng": -127.7522964241
            },
            {
                "lat": 51.1635813593,
                "lng": -127.7867505529
            },
            {
                "lat": 51.1635817869,
                "lng": -127.8047112152
            },
            {
                "lat": 51.1635813875,
                "lng": -127.8993601854
            },
            {
                "lat": 50.9447626961,
                "lng": -127.899353456
            },
            {
                "lat": 50.9418673873,
                "lng": -127.6585618188
            },
            {
                "lat": 50.9753564392,
                "lng": -127.4691242054
            },
            {
                "lat": 50.9757685,
                "lng": -127.4689934397
            },
            {
                "lat": 50.9764174137,
                "lng": -127.4685211995
            },
            {
                "lat": 50.9768337477,
                "lng": -127.4677196262
            },
            {
                "lat": 50.976954984,
                "lng": -127.467033087
            },
            {
                "lat": 50.9787220605,
                "lng": -127.4656601966
            },
            {
                "lat": 50.9795920299,
                "lng": -127.4673461774
            },
            {
                "lat": 50.9802671799,
                "lng": -127.468475118
            },
            {
                "lat": 50.9803625237,
                "lng": -127.4704127934
            },
            {
                "lat": 50.9847909961,
                "lng": -127.4780277754
            },
            {
                "lat": 50.9934231324,
                "lng": -127.4915540893
            },
            {
                "lat": 51.0118563508,
                "lng": -127.5015868186
            },
            {
                "lat": 51.0288891839,
                "lng": -127.5036927457
            },
            {
                "lat": 51.0442883255,
                "lng": -127.499488618
            },
            {
                "lat": 51.0510522476,
                "lng": -127.4922559998
            },
            {
                "lat": 51.0574524531,
                "lng": -127.4903336195
            },
            {
                "lat": 51.0592197184,
                "lng": -127.4857259342
            },
            {
                "lat": 51.0585212473,
                "lng": -127.4775544969
            },
            {
                "lat": 51.0596844702,
                "lng": -127.471023943
            },
            {
                "lat": 51.065285256,
                "lng": -127.4658889799
            },
            {
                "lat": 51.0715864436,
                "lng": -127.466826977
            },
            {
                "lat": 51.0788195467,
                "lng": -127.4731212867
            },
            {
                "lat": 51.0834845538,
                "lng": -127.4836274051
            },
            {
                "lat": 51.0904842914,
                "lng": -127.4927214931
            },
            {
                "lat": 51.0962226295,
                "lng": -127.4960785836
            },
            {
                "lat": 51.0969660867,
                "lng": -127.4974062135
            },
            {
                "lat": 51.097621711,
                "lng": -127.4993438283
            },
            {
                "lat": 51.0977824638,
                "lng": -127.5003811839
            },
            {
                "lat": 51.0979385207,
                "lng": -127.5008010093
            },
            {
                "lat": 51.1000291508,
                "lng": -127.5072859359
            },
            {
                "lat": 51.099521895,
                "lng": -127.5085759108
            },
            {
                "lat": 51.0990596645,
                "lng": -127.5120313626
            },
            {
                "lat": 51.099037284,
                "lng": -127.5182036171
            },
            {
                "lat": 51.1019095109,
                "lng": -127.5256724403
            },
            {
                "lat": 51.1070827247,
                "lng": -127.5400391936
            },
            {
                "lat": 51.1111025492,
                "lng": -127.5584176071
            },
            {
                "lat": 51.1162713393,
                "lng": -127.5825500468
            },
            {
                "lat": 51.1102899103,
                "lng": -127.6031491925
            },
            {
                "lat": 51.1117172518,
                "lng": -127.6259539136
            },
            {
                "lat": 51.1326140767,
                "lng": -127.6611018677
            },
            {
                "lat": 51.1520876463,
                "lng": -127.6976772955
            },
            {
                "lat": 51.1710894268,
                "lng": -127.7522964241
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 369,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 11,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 11-2",
            "LABEL": "11-2",
            "FISHERY_AREA_ID": 2051,
            "OBJECTID": 513,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 610437822.3796,
            "FEATURE_LENGTH_M": 103148.3735,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.06429002044376,
            "lng": -127.5494732355
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.1635813875,
                "lng": -127.8993601854
            },
            {
                "lat": 51.1635814463,
                "lng": -128.0689535902
            },
            {
                "lat": 50.8770297716,
                "lng": -128.0530245372
            },
            {
                "lat": 50.9136656347,
                "lng": -127.9973674277
            },
            {
                "lat": 50.9146914313,
                "lng": -127.9911802089
            },
            {
                "lat": 50.916164204,
                "lng": -127.9845587115
            },
            {
                "lat": 50.9141047118,
                "lng": -127.972938291
            },
            {
                "lat": 50.9142536698,
                "lng": -127.9586715155
            },
            {
                "lat": 50.9174305219,
                "lng": -127.9507450175
            },
            {
                "lat": 50.9204250674,
                "lng": -127.9449455734
            },
            {
                "lat": 50.921924471,
                "lng": -127.9419481852
            },
            {
                "lat": 50.9226728439,
                "lng": -127.939322973
            },
            {
                "lat": 50.9234201596,
                "lng": -127.9363326933
            },
            {
                "lat": 50.9234200075,
                "lng": -127.9331506293
            },
            {
                "lat": 50.9174307275,
                "lng": -127.9273450496
            },
            {
                "lat": 50.9329486426,
                "lng": -127.9114533614
            },
            {
                "lat": 50.9335214209,
                "lng": -127.9049761804
            },
            {
                "lat": 50.936611406,
                "lng": -127.9001239454
            },
            {
                "lat": 50.9407311583,
                "lng": -127.9002681841
            },
            {
                "lat": 50.941944061,
                "lng": -127.8999930461
            },
            {
                "lat": 50.9434776795,
                "lng": -127.9010699253
            },
            {
                "lat": 50.9439769806,
                "lng": -127.9009932433
            },
            {
                "lat": 50.9443014561,
                "lng": -127.9007639546
            },
            {
                "lat": 50.9444575105,
                "lng": -127.90046695
            },
            {
                "lat": 50.9445227963,
                "lng": -127.8999933074
            },
            {
                "lat": 50.9444846376,
                "lng": -127.8996274769
            },
            {
                "lat": 50.9444844565,
                "lng": -127.8995054641
            },
            {
                "lat": 50.9447626961,
                "lng": -127.899353456
            },
            {
                "lat": 51.1635813875,
                "lng": -127.8993601854
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 370,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 11,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 11-1",
            "LABEL": "11-1",
            "FISHERY_AREA_ID": 2050,
            "OBJECTID": 514,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 320186457.5905,
            "FEATURE_LENGTH_M": 83492.28,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.952675942941376,
            "lng": -127.93509631962414
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.1371759396,
                "lng": -127.4042519208
            },
            {
                "lat": 51.1367604103,
                "lng": -127.4062507442
            },
            {
                "lat": 51.1351703206,
                "lng": -127.4096751666
            },
            {
                "lat": 51.1345174995,
                "lng": -127.4322204188
            },
            {
                "lat": 51.1381878556,
                "lng": -127.4340896695
            },
            {
                "lat": 51.145801466,
                "lng": -127.4324259713
            },
            {
                "lat": 51.1534162648,
                "lng": -127.4312367402
            },
            {
                "lat": 51.1579367421,
                "lng": -127.4345709313
            },
            {
                "lat": 51.1581763908,
                "lng": -127.4417036106
            },
            {
                "lat": 51.1586537949,
                "lng": -127.4543149541
            },
            {
                "lat": 51.1567453699,
                "lng": -127.4638365172
            },
            {
                "lat": 51.1536556948,
                "lng": -127.4681171088
            },
            {
                "lat": 51.1507989853,
                "lng": -127.4752583154
            },
            {
                "lat": 51.1490215448,
                "lng": -127.4894794824
            },
            {
                "lat": 51.155341789,
                "lng": -127.5164794684
            },
            {
                "lat": 51.1633838678,
                "lng": -127.5526730631
            },
            {
                "lat": 51.167979852,
                "lng": -127.6026615164
            },
            {
                "lat": 51.1547668288,
                "lng": -127.627938141
            },
            {
                "lat": 51.1323590644,
                "lng": -127.6250613608
            },
            {
                "lat": 51.1208685612,
                "lng": -127.6107028581
            },
            {
                "lat": 51.1162713393,
                "lng": -127.5825500468
            },
            {
                "lat": 51.1111025492,
                "lng": -127.5584176071
            },
            {
                "lat": 51.1070827247,
                "lng": -127.5400391936
            },
            {
                "lat": 51.1019095109,
                "lng": -127.5256724403
            },
            {
                "lat": 51.099037284,
                "lng": -127.5182036171
            },
            {
                "lat": 51.0990596645,
                "lng": -127.5120313626
            },
            {
                "lat": 51.099521895,
                "lng": -127.5085759108
            },
            {
                "lat": 51.1000291508,
                "lng": -127.5072859359
            },
            {
                "lat": 51.0979385207,
                "lng": -127.5008010093
            },
            {
                "lat": 51.0936205773,
                "lng": -127.4674840621
            },
            {
                "lat": 51.094436444,
                "lng": -127.467415402
            },
            {
                "lat": 51.0964926256,
                "lng": -127.4658885118
            },
            {
                "lat": 51.0982935889,
                "lng": -127.4617540528
            },
            {
                "lat": 51.1086883833,
                "lng": -127.4771575159
            },
            {
                "lat": 51.1108243712,
                "lng": -127.4931873451
            },
            {
                "lat": 51.1190191183,
                "lng": -127.5003123842
            },
            {
                "lat": 51.1183053262,
                "lng": -127.4743111228
            },
            {
                "lat": 51.1175952457,
                "lng": -127.4561391782
            },
            {
                "lat": 51.1179499991,
                "lng": -127.4308463389
            },
            {
                "lat": 51.1141471608,
                "lng": -127.4056702726
            },
            {
                "lat": 51.1143076002,
                "lng": -127.3788682519
            },
            {
                "lat": 51.1139877018,
                "lng": -127.3602525213
            },
            {
                "lat": 51.1145356174,
                "lng": -127.3438646374
            },
            {
                "lat": 51.1148606615,
                "lng": -127.3210678758
            },
            {
                "lat": 51.1135602531,
                "lng": -127.2904508403
            },
            {
                "lat": 51.1145357302,
                "lng": -127.2643975665
            },
            {
                "lat": 51.1161653642,
                "lng": -127.238990731
            },
            {
                "lat": 51.1145356676,
                "lng": -127.2139123649
            },
            {
                "lat": 51.1125837155,
                "lng": -127.1917728993
            },
            {
                "lat": 51.1123775795,
                "lng": -127.1906735289
            },
            {
                "lat": 51.1133811937,
                "lng": -127.1758501055
            },
            {
                "lat": 51.1125907406,
                "lng": -127.171509225
            },
            {
                "lat": 51.1119198474,
                "lng": -127.16487139
            },
            {
                "lat": 51.111740425,
                "lng": -127.1543815561
            },
            {
                "lat": 51.1148840368,
                "lng": -127.1537008709
            },
            {
                "lat": 51.1240385551,
                "lng": -127.1537016338
            },
            {
                "lat": 51.1260456709,
                "lng": -127.1536795521
            },
            {
                "lat": 51.1276626764,
                "lng": -127.1536643188
            },
            {
                "lat": 51.1302644695,
                "lng": -127.158095553
            },
            {
                "lat": 51.1333575477,
                "lng": -127.1681907646
            },
            {
                "lat": 51.1369968372,
                "lng": -127.1742558315
            },
            {
                "lat": 51.1403310858,
                "lng": -127.1897201355
            },
            {
                "lat": 51.1436615483,
                "lng": -127.2104255173
            },
            {
                "lat": 51.1450876309,
                "lng": -127.2332688424
            },
            {
                "lat": 51.1441377839,
                "lng": -127.2484967768
            },
            {
                "lat": 51.1427117604,
                "lng": -127.2575381754
            },
            {
                "lat": 51.1450879848,
                "lng": -127.2720490011
            },
            {
                "lat": 51.1438978504,
                "lng": -127.2875134333
            },
            {
                "lat": 51.1458016635,
                "lng": -127.3048861016
            },
            {
                "lat": 51.1448513929,
                "lng": -127.3239211258
            },
            {
                "lat": 51.141758079,
                "lng": -127.3415295495
            },
            {
                "lat": 51.1410445458,
                "lng": -127.3608020063
            },
            {
                "lat": 51.1403311064,
                "lng": -127.3760300799
            },
            {
                "lat": 51.1389051304,
                "lng": -127.38888623
            },
            {
                "lat": 51.1379505371,
                "lng": -127.4005439034
            },
            {
                "lat": 51.1371759396,
                "lng": -127.4042519208
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 371,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 11,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 11-4",
            "LABEL": "11-4",
            "FISHERY_AREA_ID": 2053,
            "OBJECTID": 515,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 129596516.4375,
            "FEATURE_LENGTH_M": 82868.3996,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.127619863844735,
            "lng": -127.37819350113944
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.1226699444,
                "lng": -127.122100358
            },
            {
                "lat": 51.1209562825,
                "lng": -127.1336294241
            },
            {
                "lat": 51.1240385551,
                "lng": -127.1537016338
            },
            {
                "lat": 51.1148840368,
                "lng": -127.1537008709
            },
            {
                "lat": 51.111740425,
                "lng": -127.1543815561
            },
            {
                "lat": 51.1058918436,
                "lng": -127.1393355599
            },
            {
                "lat": 51.1027182536,
                "lng": -127.1211939063
            },
            {
                "lat": 51.0972781155,
                "lng": -127.0976180922
            },
            {
                "lat": 51.0986404755,
                "lng": -127.064063881
            },
            {
                "lat": 51.0977323999,
                "lng": -127.0427556095
            },
            {
                "lat": 51.0981864766,
                "lng": -127.0196303676
            },
            {
                "lat": 51.0995451026,
                "lng": -126.9987709593
            },
            {
                "lat": 51.096370211,
                "lng": -126.9815437697
            },
            {
                "lat": 51.096370461,
                "lng": -126.9665836893
            },
            {
                "lat": 51.0959161455,
                "lng": -126.9502561147
            },
            {
                "lat": 51.0927428477,
                "lng": -126.9330286435
            },
            {
                "lat": 51.0945587925,
                "lng": -126.9189756305
            },
            {
                "lat": 51.0936507485,
                "lng": -126.9008402858
            },
            {
                "lat": 51.0959161629,
                "lng": -126.8949440888
            },
            {
                "lat": 51.1045343691,
                "lng": -126.8917689002
            },
            {
                "lat": 51.1158672999,
                "lng": -126.9008401004
            },
            {
                "lat": 51.1190411624,
                "lng": -126.9194263917
            },
            {
                "lat": 51.1208569345,
                "lng": -126.9484485198
            },
            {
                "lat": 51.1226696546,
                "lng": -126.9824517273
            },
            {
                "lat": 51.1249348881,
                "lng": -127.0631559559
            },
            {
                "lat": 51.121761732,
                "lng": -127.1026078939
            },
            {
                "lat": 51.1226699444,
                "lng": -127.122100358
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 372,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 11,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 11-7",
            "LABEL": "11-7",
            "FISHERY_AREA_ID": 2056,
            "OBJECTID": 516,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 48048559.0118,
            "FEATURE_LENGTH_M": 40324.4848,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.10785715797037,
            "lng": -127.02510571437779
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 51.0307506865,
                "lng": -125.5314327574
            },
            {
                "lat": 51.0323022667,
                "lng": -125.5284417883
            },
            {
                "lat": 51.0358739334,
                "lng": -125.5255432501
            },
            {
                "lat": 51.043239514,
                "lng": -125.5257645397
            },
            {
                "lat": 51.0501559096,
                "lng": -125.5275495535
            },
            {
                "lat": 51.0610916101,
                "lng": -125.5317916815
            },
            {
                "lat": 51.0668986424,
                "lng": -125.5344701182
            },
            {
                "lat": 51.0764925597,
                "lng": -125.5393758618
            },
            {
                "lat": 51.0829661433,
                "lng": -125.5436164976
            },
            {
                "lat": 51.0901069944,
                "lng": -125.5476381371
            },
            {
                "lat": 51.0972517198,
                "lng": -125.5525435447
            },
            {
                "lat": 51.1019363382,
                "lng": -125.5556713535
            },
            {
                "lat": 51.1070711067,
                "lng": -125.5605777885
            },
            {
                "lat": 51.1119805342,
                "lng": -125.567947481
            },
            {
                "lat": 51.115329688,
                "lng": -125.5746384718
            },
            {
                "lat": 51.1180071887,
                "lng": -125.5835727444
            },
            {
                "lat": 51.1197931589,
                "lng": -125.5922776346
            },
            {
                "lat": 51.1206857721,
                "lng": -125.6027608139
            },
            {
                "lat": 51.1197932441,
                "lng": -125.6116941626
            },
            {
                "lat": 51.117336939,
                "lng": -125.6183858218
            },
            {
                "lat": 51.1135437841,
                "lng": -125.6246340775
            },
            {
                "lat": 51.1068500018,
                "lng": -125.6335677891
            },
            {
                "lat": 51.0994829945,
                "lng": -125.641373313
            },
            {
                "lat": 51.0921174212,
                "lng": -125.6478508139
            },
            {
                "lat": 51.0822979839,
                "lng": -125.6485214546
            },
            {
                "lat": 51.0673442482,
                "lng": -125.6456143917
            },
            {
                "lat": 51.0559612481,
                "lng": -125.6364676053
            },
            {
                "lat": 51.0519402667,
                "lng": -125.6288763207
            },
            {
                "lat": 51.0456922931,
                "lng": -125.6197279141
            },
            {
                "lat": 51.0405619241,
                "lng": -125.6141434152
            },
            {
                "lat": 51.0316318188,
                "lng": -125.6181648906
            },
            {
                "lat": 51.026054486,
                "lng": -125.6232987283
            },
            {
                "lat": 51.0193557123,
                "lng": -125.6210637376
            },
            {
                "lat": 51.0160097267,
                "lng": -125.6152645821
            },
            {
                "lat": 51.0135534526,
                "lng": -125.6076735766
            },
            {
                "lat": 51.0110970689,
                "lng": -125.6020973556
            },
            {
                "lat": 51.0087586274,
                "lng": -125.5999225281
            },
            {
                "lat": 51.0117492924,
                "lng": -125.5913235893
            },
            {
                "lat": 51.0121996285,
                "lng": -125.5900035418
            },
            {
                "lat": 51.0122717802,
                "lng": -125.5899351206
            },
            {
                "lat": 51.0317389194,
                "lng": -125.5424654564
            },
            {
                "lat": 51.0307506865,
                "lng": -125.5314327574
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 373,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 34,
            "MANAGEMENT_AREA_NAME": "Subarea 12-34",
            "LABEL": "12-34",
            "FISHERY_AREA_ID": 2092,
            "OBJECTID": 517,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 71399067.3493,
            "FEATURE_LENGTH_M": 33716.6072,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 51.063810174171444,
            "lng": -125.58640754670239
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.7959943778,
                "lng": -126.5517347457
            },
            {
                "lat": 50.7980422938,
                "lng": -126.5513766644
            },
            {
                "lat": 50.7996101404,
                "lng": -126.5525210923
            },
            {
                "lat": 50.8013224963,
                "lng": -126.5545201299
            },
            {
                "lat": 50.8013228639,
                "lng": -126.5585096917
            },
            {
                "lat": 50.8020364619,
                "lng": -126.5630796816
            },
            {
                "lat": 50.8047450566,
                "lng": -126.5670707791
            },
            {
                "lat": 50.8052749496,
                "lng": -126.5749969756
            },
            {
                "lat": 50.8066976899,
                "lng": -126.5854491754
            },
            {
                "lat": 50.8097838579,
                "lng": -126.6039660999
            },
            {
                "lat": 50.8102609446,
                "lng": -126.6220171191
            },
            {
                "lat": 50.8045614167,
                "lng": -126.6376880373
            },
            {
                "lat": 50.8002886422,
                "lng": -126.6478955609
            },
            {
                "lat": 50.7998130099,
                "lng": -126.6657099745
            },
            {
                "lat": 50.7993390842,
                "lng": -126.6835169793
            },
            {
                "lat": 50.7960131044,
                "lng": -126.6996614947
            },
            {
                "lat": 50.7893635754,
                "lng": -126.7065500444
            },
            {
                "lat": 50.7872387667,
                "lng": -126.7121274158
            },
            {
                "lat": 50.786979162,
                "lng": -126.7205816941
            },
            {
                "lat": 50.7893634924,
                "lng": -126.7250752607
            },
            {
                "lat": 50.7945902967,
                "lng": -126.734565549
            },
            {
                "lat": 50.8000493517,
                "lng": -126.7457274803
            },
            {
                "lat": 50.8033751797,
                "lng": -126.7604528068
            },
            {
                "lat": 50.8007626067,
                "lng": -126.7694782623
            },
            {
                "lat": 50.7964904731,
                "lng": -126.7784960058
            },
            {
                "lat": 50.7893632312,
                "lng": -126.7839584959
            },
            {
                "lat": 50.7849384629,
                "lng": -126.7864304751
            },
            {
                "lat": 50.7846144984,
                "lng": -126.7969062202
            },
            {
                "lat": 50.7862781243,
                "lng": -126.798919343
            },
            {
                "lat": 50.7891269315,
                "lng": -126.8020095094
            },
            {
                "lat": 50.7900769499,
                "lng": -126.8062822522
            },
            {
                "lat": 50.7891270757,
                "lng": -126.8138812975
            },
            {
                "lat": 50.7853282679,
                "lng": -126.819099231
            },
            {
                "lat": 50.7838862034,
                "lng": -126.8203049762
            },
            {
                "lat": 50.7833214183,
                "lng": -126.8385542226
            },
            {
                "lat": 50.7856485433,
                "lng": -126.8434598849
            },
            {
                "lat": 50.7840348656,
                "lng": -126.846687633
            },
            {
                "lat": 50.7830165699,
                "lng": -126.8484566423
            },
            {
                "lat": 50.6806489326,
                "lng": -126.6575326269
            },
            {
                "lat": 50.6604693356,
                "lng": -126.6209870632
            },
            {
                "lat": 50.6599534886,
                "lng": -126.6200555494
            },
            {
                "lat": 50.6600838916,
                "lng": -126.6198273596
            },
            {
                "lat": 50.6603272907,
                "lng": -126.6192698943
            },
            {
                "lat": 50.6605336796,
                "lng": -126.6188056791
            },
            {
                "lat": 50.6611097476,
                "lng": -126.6174849526
            },
            {
                "lat": 50.6614380338,
                "lng": -126.616500481
            },
            {
                "lat": 50.6617277605,
                "lng": -126.6145636308
            },
            {
                "lat": 50.6618917823,
                "lng": -126.6131213148
            },
            {
                "lat": 50.6631281947,
                "lng": -126.6102374684
            },
            {
                "lat": 50.6639139031,
                "lng": -126.6085505072
            },
            {
                "lat": 50.6660723459,
                "lng": -126.6051020191
            },
            {
                "lat": 50.6677011439,
                "lng": -126.6036455941
            },
            {
                "lat": 50.6700902151,
                "lng": -126.6017533178
            },
            {
                "lat": 50.6707267932,
                "lng": -126.6016689504
            },
            {
                "lat": 50.6719015752,
                "lng": -126.6022491915
            },
            {
                "lat": 50.6717370366,
                "lng": -126.6000677121
            },
            {
                "lat": 50.671203997,
                "lng": -126.597389415
            },
            {
                "lat": 50.6716536107,
                "lng": -126.5954134437
            },
            {
                "lat": 50.6731380398,
                "lng": -126.5942616076
            },
            {
                "lat": 50.6752775622,
                "lng": -126.5932316096
            },
            {
                "lat": 50.677422067,
                "lng": -126.5932696618
            },
            {
                "lat": 50.6793973353,
                "lng": -126.5933532836
            },
            {
                "lat": 50.6800958084,
                "lng": -126.5939784477
            },
            {
                "lat": 50.6818805058,
                "lng": -126.5916745012
            },
            {
                "lat": 50.6820750846,
                "lng": -126.5905539874
            },
            {
                "lat": 50.6814996405,
                "lng": -126.5879976414
            },
            {
                "lat": 50.6793556858,
                "lng": -126.5837934996
            },
            {
                "lat": 50.6772578985,
                "lng": -126.58050446
            },
            {
                "lat": 50.6762695733,
                "lng": -126.5776211078
            },
            {
                "lat": 50.6758155211,
                "lng": -126.5749818676
            },
            {
                "lat": 50.6763078337,
                "lng": -126.5719379514
            },
            {
                "lat": 50.6772960466,
                "lng": -126.570167453
            },
            {
                "lat": 50.6782462713,
                "lng": -126.5693441454
            },
            {
                "lat": 50.6797673622,
                "lng": -126.568763428
            },
            {
                "lat": 50.6814159669,
                "lng": -126.568389602
            },
            {
                "lat": 50.6832691703,
                "lng": -126.5688484903
            },
            {
                "lat": 50.6848341925,
                "lng": -126.5699994174
            },
            {
                "lat": 50.6857409025,
                "lng": -126.5719766699
            },
            {
                "lat": 50.6863589576,
                "lng": -126.5746150205
            },
            {
                "lat": 50.6865649203,
                "lng": -126.5765914093
            },
            {
                "lat": 50.6876756178,
                "lng": -126.5783998031
            },
            {
                "lat": 50.6888688875,
                "lng": -126.5792232971
            },
            {
                "lat": 50.6896242528,
                "lng": -126.5775521454
            },
            {
                "lat": 50.6885569884,
                "lng": -126.5727542812
            },
            {
                "lat": 50.689266073,
                "lng": -126.5649342377
            },
            {
                "lat": 50.6901555578,
                "lng": -126.561561453
            },
            {
                "lat": 50.6919366681,
                "lng": -126.5565788356
            },
            {
                "lat": 50.6940643878,
                "lng": -126.5519630312
            },
            {
                "lat": 50.6954884651,
                "lng": -126.5489423119
            },
            {
                "lat": 50.6983337352,
                "lng": -126.5476907408
            },
            {
                "lat": 50.7017096006,
                "lng": -126.5489422475
            },
            {
                "lat": 50.7034870161,
                "lng": -126.5521391089
            },
            {
                "lat": 50.7056198786,
                "lng": -126.5580067646
            },
            {
                "lat": 50.705959957,
                "lng": -126.5605009396
            },
            {
                "lat": 50.7368168096,
                "lng": -126.5205610805
            },
            {
                "lat": 50.7370802371,
                "lng": -126.5187219654
            },
            {
                "lat": 50.7340577341,
                "lng": -126.5105435869
            },
            {
                "lat": 50.7310371012,
                "lng": -126.4979241139
            },
            {
                "lat": 50.7289043712,
                "lng": -126.4917069902
            },
            {
                "lat": 50.7260590199,
                "lng": -126.4844205944
            },
            {
                "lat": 50.7267728823,
                "lng": -126.4817511864
            },
            {
                "lat": 50.730857714,
                "lng": -126.4782024967
            },
            {
                "lat": 50.7333452723,
                "lng": -126.4782024365
            },
            {
                "lat": 50.7354815642,
                "lng": -126.4785539435
            },
            {
                "lat": 50.7385027841,
                "lng": -126.4812161464
            },
            {
                "lat": 50.7401011865,
                "lng": -126.4849550807
            },
            {
                "lat": 50.7408097127,
                "lng": -126.4872667408
            },
            {
                "lat": 50.740989447,
                "lng": -126.4922409138
            },
            {
                "lat": 50.7441906337,
                "lng": -126.4934839785
            },
            {
                "lat": 50.7488098797,
                "lng": -126.4897529173
            },
            {
                "lat": 50.7528996537,
                "lng": -126.4854888465
            },
            {
                "lat": 50.7585867983,
                "lng": -126.482818231
            },
            {
                "lat": 50.7599870684,
                "lng": -126.4788589836
            },
            {
                "lat": 50.7594608527,
                "lng": -126.4738994827
            },
            {
                "lat": 50.7588811987,
                "lng": -126.4664989897
            },
            {
                "lat": 50.7584724593,
                "lng": -126.4593282669
            },
            {
                "lat": 50.75975452,
                "lng": -126.457168523
            },
            {
                "lat": 50.7644724734,
                "lng": -126.4554833119
            },
            {
                "lat": 50.7675053103,
                "lng": -126.4562376633
            },
            {
                "lat": 50.7705960352,
                "lng": -126.4560093059
            },
            {
                "lat": 50.7718196395,
                "lng": -126.4616624702
            },
            {
                "lat": 50.7724611224,
                "lng": -126.4660337959
            },
            {
                "lat": 50.7733343023,
                "lng": -126.4690624057
            },
            {
                "lat": 50.7738033678,
                "lng": -126.4700543357
            },
            {
                "lat": 50.7739186718,
                "lng": -126.471336094
            },
            {
                "lat": 50.7739525092,
                "lng": -126.4724955
            },
            {
                "lat": 50.7757646231,
                "lng": -126.4781726692
            },
            {
                "lat": 50.7928315893,
                "lng": -126.5316932603
            },
            {
                "lat": 50.7919199291,
                "lng": -126.5331107802
            },
            {
                "lat": 50.789909063,
                "lng": -126.5358280768
            },
            {
                "lat": 50.7873424478,
                "lng": -126.5403906692
            },
            {
                "lat": 50.7860560708,
                "lng": -126.5496679251
            },
            {
                "lat": 50.7870562313,
                "lng": -126.5607907959
            },
            {
                "lat": 50.7912633574,
                "lng": -126.5624164053
            },
            {
                "lat": 50.7938766125,
                "lng": -126.5581356039
            },
            {
                "lat": 50.7959943778,
                "lng": -126.5517347457
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 414,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 39,
            "MANAGEMENT_AREA_NAME": "Subarea 12-39",
            "LABEL": "12-39",
            "FISHERY_AREA_ID": 2097,
            "OBJECTID": 558,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 233027013.9347,
            "FEATURE_LENGTH_M": 82009.4359,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.73620708280736,
            "lng": -126.59191816797423
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.789909063,
                "lng": -126.5358280768
            },
            {
                "lat": 50.791621727,
                "lng": -126.540390839
            },
            {
                "lat": 50.7921909853,
                "lng": -126.5452425613
            },
            {
                "lat": 50.7926217893,
                "lng": -126.549667755
            },
            {
                "lat": 50.7956162377,
                "lng": -126.5518037217
            },
            {
                "lat": 50.7959943778,
                "lng": -126.5517347457
            },
            {
                "lat": 50.7938766125,
                "lng": -126.5581356039
            },
            {
                "lat": 50.7912633574,
                "lng": -126.5624164053
            },
            {
                "lat": 50.7870562313,
                "lng": -126.5607907959
            },
            {
                "lat": 50.7860560708,
                "lng": -126.5496679251
            },
            {
                "lat": 50.7873424478,
                "lng": -126.5403906692
            },
            {
                "lat": 50.789909063,
                "lng": -126.5358280768
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 415,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 559,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1184037.3662,
            "FEATURE_LENGTH_M": 4745.9746,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.79112149690834,
            "lng": -126.54849143130834
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.7687719299,
                "lng": -127.1204453
            },
            {
                "lat": 50.6589246233,
                "lng": -127.2824470117
            },
            {
                "lat": 50.630527212,
                "lng": -127.1548920862
            },
            {
                "lat": 50.6354831264,
                "lng": -127.1546860632
            },
            {
                "lat": 50.6417228883,
                "lng": -127.1530998794
            },
            {
                "lat": 50.6511537547,
                "lng": -127.1351321968
            },
            {
                "lat": 50.6631621004,
                "lng": -127.1105964299
            },
            {
                "lat": 50.6587222117,
                "lng": -127.0813526279
            },
            {
                "lat": 50.6587219629,
                "lng": -127.0557704571
            },
            {
                "lat": 50.6532402426,
                "lng": -127.0351486467
            },
            {
                "lat": 50.6516768538,
                "lng": -127.0176620862
            },
            {
                "lat": 50.6558534797,
                "lng": -126.9959950665
            },
            {
                "lat": 50.6524575387,
                "lng": -126.9743270855
            },
            {
                "lat": 50.6584625149,
                "lng": -126.962837312
            },
            {
                "lat": 50.6640050914,
                "lng": -126.9660640549
            },
            {
                "lat": 50.7687719299,
                "lng": -127.1204453
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 416,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 12-8",
            "LABEL": "12-8",
            "FISHERY_AREA_ID": 2066,
            "OBJECTID": 560,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 159462101.0255,
            "FEATURE_LENGTH_M": 58995.0258,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.6669785912875,
            "lng": -127.08255635024999
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.6806489326,
                "lng": -126.6575326269
            },
            {
                "lat": 50.6896093137,
                "lng": -126.8100732208
            },
            {
                "lat": 50.7687719299,
                "lng": -127.1204453
            },
            {
                "lat": 50.6640050914,
                "lng": -126.9660640549
            },
            {
                "lat": 50.6584625149,
                "lng": -126.962837312
            },
            {
                "lat": 50.6535032902,
                "lng": -126.9461291557
            },
            {
                "lat": 50.6488034479,
                "lng": -126.928382981
            },
            {
                "lat": 50.6525262605,
                "lng": -126.9094925247
            },
            {
                "lat": 50.6558535226,
                "lng": -126.8926167167
            },
            {
                "lat": 50.6456720773,
                "lng": -126.8743442062
            },
            {
                "lat": 50.637046701,
                "lng": -126.8670572402
            },
            {
                "lat": 50.6375776824,
                "lng": -126.8625945061
            },
            {
                "lat": 50.6336636851,
                "lng": -126.8482352421
            },
            {
                "lat": 50.6296879921,
                "lng": -126.8341443521
            },
            {
                "lat": 50.6290093215,
                "lng": -126.8243866606
            },
            {
                "lat": 50.6277460747,
                "lng": -126.7364954292
            },
            {
                "lat": 50.6270677475,
                "lng": -126.7365877468
            },
            {
                "lat": 50.6250384201,
                "lng": -126.7363512092
            },
            {
                "lat": 50.6234251925,
                "lng": -126.7355116361
            },
            {
                "lat": 50.618114016,
                "lng": -126.7287682829
            },
            {
                "lat": 50.6130184758,
                "lng": -126.7196122955
            },
            {
                "lat": 50.6086038758,
                "lng": -126.7113262142
            },
            {
                "lat": 50.6065482723,
                "lng": -126.7088551633
            },
            {
                "lat": 50.6051790231,
                "lng": -126.7075798886
            },
            {
                "lat": 50.6034654856,
                "lng": -126.7068479295
            },
            {
                "lat": 50.6026112994,
                "lng": -126.7068716149
            },
            {
                "lat": 50.6019255142,
                "lng": -126.7069014789
            },
            {
                "lat": 50.6003842456,
                "lng": -126.7073357613
            },
            {
                "lat": 50.5874597748,
                "lng": -126.6825553002
            },
            {
                "lat": 50.5873457269,
                "lng": -126.6814646517
            },
            {
                "lat": 50.5876960315,
                "lng": -126.679916473
            },
            {
                "lat": 50.5890228097,
                "lng": -126.6651458506
            },
            {
                "lat": 50.5870774808,
                "lng": -126.6627432102
            },
            {
                "lat": 50.5864981973,
                "lng": -126.6610108055
            },
            {
                "lat": 50.5865932443,
                "lng": -126.6594693587
            },
            {
                "lat": 50.5861127885,
                "lng": -126.6567767441
            },
            {
                "lat": 50.5853419579,
                "lng": -126.6547546495
            },
            {
                "lat": 50.5846715753,
                "lng": -126.6535032649
            },
            {
                "lat": 50.5841904358,
                "lng": -126.6513821709
            },
            {
                "lat": 50.5842858801,
                "lng": -126.6492694396
            },
            {
                "lat": 50.5842858554,
                "lng": -126.6464762401
            },
            {
                "lat": 50.5844772181,
                "lng": -126.6440734484
            },
            {
                "lat": 50.5855366739,
                "lng": -126.6419523547
            },
            {
                "lat": 50.5864981849,
                "lng": -126.6380078791
            },
            {
                "lat": 50.5863069279,
                "lng": -126.6311724309
            },
            {
                "lat": 50.587363874,
                "lng": -126.6262663642
            },
            {
                "lat": 50.5875584215,
                "lng": -126.6202998594
            },
            {
                "lat": 50.5868831075,
                "lng": -126.6150058712
            },
            {
                "lat": 50.5863069966,
                "lng": -126.6115414541
            },
            {
                "lat": 50.5839960582,
                "lng": -126.6105810592
            },
            {
                "lat": 50.5823595933,
                "lng": -126.6083680326
            },
            {
                "lat": 50.5825535871,
                "lng": -126.6019138006
            },
            {
                "lat": 50.5836108314,
                "lng": -126.599219558
            },
            {
                "lat": 50.5892905869,
                "lng": -126.5959470492
            },
            {
                "lat": 50.5927543785,
                "lng": -126.5957562078
            },
            {
                "lat": 50.5952642681,
                "lng": -126.5957567472
            },
            {
                "lat": 50.5953175816,
                "lng": -126.5951312015
            },
            {
                "lat": 50.5951918488,
                "lng": -126.5931244573
            },
            {
                "lat": 50.5957297798,
                "lng": -126.5896309987
            },
            {
                "lat": 50.5959961716,
                "lng": -126.5860756367
            },
            {
                "lat": 50.5962523395,
                "lng": -126.5847698992
            },
            {
                "lat": 50.5964012463,
                "lng": -126.5839998139
            },
            {
                "lat": 50.5968703566,
                "lng": -126.583091651
            },
            {
                "lat": 50.5967668065,
                "lng": -126.5818483536
            },
            {
                "lat": 50.5966345505,
                "lng": -126.5810472434
            },
            {
                "lat": 50.5966376964,
                "lng": -126.5804439675
            },
            {
                "lat": 50.6258964747,
                "lng": -126.5800473388
            },
            {
                "lat": 50.6599534886,
                "lng": -126.6200555494
            },
            {
                "lat": 50.6604693356,
                "lng": -126.6209870632
            },
            {
                "lat": 50.6806489326,
                "lng": -126.6575326269
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 417,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 12-6",
            "LABEL": "12-6",
            "FISHERY_AREA_ID": 2064,
            "OBJECTID": 561,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 247171329.7455,
            "FEATURE_LENGTH_M": 95212.3725,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.612258264014265,
            "lng": -126.69759281181575
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.7313151577,
                "lng": -125.6753532654
            },
            {
                "lat": 50.7339137829,
                "lng": -125.6798096628
            },
            {
                "lat": 50.7372628856,
                "lng": -125.6817253709
            },
            {
                "lat": 50.7397390457,
                "lng": -125.682472513
            },
            {
                "lat": 50.7367634703,
                "lng": -125.7153017441
            },
            {
                "lat": 50.7336119698,
                "lng": -125.725448693
            },
            {
                "lat": 50.7247121212,
                "lng": -125.725173631
            },
            {
                "lat": 50.7155337955,
                "lng": -125.730453346
            },
            {
                "lat": 50.7089239662,
                "lng": -125.7268066613
            },
            {
                "lat": 50.6878397765,
                "lng": -125.7386022394
            },
            {
                "lat": 50.6863056122,
                "lng": -125.7406078715
            },
            {
                "lat": 50.6837267956,
                "lng": -125.7441628141
            },
            {
                "lat": 50.6795613904,
                "lng": -125.7455440239
            },
            {
                "lat": 50.6744078624,
                "lng": -125.7469323143
            },
            {
                "lat": 50.6650888918,
                "lng": -125.745543728
            },
            {
                "lat": 50.6581456922,
                "lng": -125.7433700076
            },
            {
                "lat": 50.6545789233,
                "lng": -125.7399976732
            },
            {
                "lat": 50.6516031574,
                "lng": -125.733054823
            },
            {
                "lat": 50.6496195295,
                "lng": -125.7263181938
            },
            {
                "lat": 50.6498188729,
                "lng": -125.7231448419
            },
            {
                "lat": 50.6520005069,
                "lng": -125.7201687025
            },
            {
                "lat": 50.6571541505,
                "lng": -125.7177882821
            },
            {
                "lat": 50.661121508,
                "lng": -125.7169955587
            },
            {
                "lat": 50.6664731045,
                "lng": -125.7169954647
            },
            {
                "lat": 50.6718291061,
                "lng": -125.7152098594
            },
            {
                "lat": 50.6748049192,
                "lng": -125.7098545444
            },
            {
                "lat": 50.6759908964,
                "lng": -125.7031175076
            },
            {
                "lat": 50.6835287956,
                "lng": -125.6920094814
            },
            {
                "lat": 50.6920553705,
                "lng": -125.6836851939
            },
            {
                "lat": 50.7035557353,
                "lng": -125.6775364583
            },
            {
                "lat": 50.7162442731,
                "lng": -125.6729744839
            },
            {
                "lat": 50.7263571277,
                "lng": -125.6713859525
            },
            {
                "lat": 50.7313151577,
                "lng": -125.6753532654
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 418,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 29,
            "MANAGEMENT_AREA_NAME": "Subarea 12-29",
            "LABEL": "12-29",
            "FISHERY_AREA_ID": 2087,
            "OBJECTID": 562,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 30410943.2416,
            "FEATURE_LENGTH_M": 25266.6875,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.691360707593944,
            "lng": -125.7134211567576
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.550300757,
                "lng": -125.5595549569
            },
            {
                "lat": 50.5497900771,
                "lng": -125.5531537855
            },
            {
                "lat": 50.5499725444,
                "lng": -125.5516121881
            },
            {
                "lat": 50.5502090632,
                "lng": -125.5505297758
            },
            {
                "lat": 50.5505221373,
                "lng": -125.5499116217
            },
            {
                "lat": 50.5605432906,
                "lng": -125.5371328676
            },
            {
                "lat": 50.5637665747,
                "lng": -125.5326157878
            },
            {
                "lat": 50.5649717239,
                "lng": -125.5317152924
            },
            {
                "lat": 50.5695385941,
                "lng": -125.5282894019
            },
            {
                "lat": 50.5801193657,
                "lng": -125.5186687397
            },
            {
                "lat": 50.5945518459,
                "lng": -125.5157771026
            },
            {
                "lat": 50.6133119807,
                "lng": -125.5196310647
            },
            {
                "lat": 50.6364019524,
                "lng": -125.5124132687
            },
            {
                "lat": 50.6546776211,
                "lng": -125.4917292121
            },
            {
                "lat": 50.6556390998,
                "lng": -125.4696046547
            },
            {
                "lat": 50.6667060302,
                "lng": -125.4489207293
            },
            {
                "lat": 50.6854624332,
                "lng": -125.4378582039
            },
            {
                "lat": 50.7047039948,
                "lng": -125.4099584652
            },
            {
                "lat": 50.7109564405,
                "lng": -125.3950428894
            },
            {
                "lat": 50.7196152164,
                "lng": -125.389274191
            },
            {
                "lat": 50.7258678428,
                "lng": -125.3887939743
            },
            {
                "lat": 50.7282756056,
                "lng": -125.4022598939
            },
            {
                "lat": 50.7277947606,
                "lng": -125.4152527918
            },
            {
                "lat": 50.72779481,
                "lng": -125.4368980993
            },
            {
                "lat": 50.7301976342,
                "lng": -125.4599841332
            },
            {
                "lat": 50.7306783764,
                "lng": -125.4715267936
            },
            {
                "lat": 50.7282757583,
                "lng": -125.4811468411
            },
            {
                "lat": 50.722984267,
                "lng": -125.4869241198
            },
            {
                "lat": 50.7157668008,
                "lng": -125.4869233915
            },
            {
                "lat": 50.7090345951,
                "lng": -125.4811470119
            },
            {
                "lat": 50.7042244068,
                "lng": -125.4773022856
            },
            {
                "lat": 50.6984523384,
                "lng": -125.4744116306
            },
            {
                "lat": 50.6931610152,
                "lng": -125.4773018463
            },
            {
                "lat": 50.6893123263,
                "lng": -125.4835514795
            },
            {
                "lat": 50.6864240274,
                "lng": -125.4936525006
            },
            {
                "lat": 50.6854625334,
                "lng": -125.5047151219
            },
            {
                "lat": 50.6845024665,
                "lng": -125.5167472336
            },
            {
                "lat": 50.6806525793,
                "lng": -125.5273277211
            },
            {
                "lat": 50.6753610719,
                "lng": -125.5345383882
            },
            {
                "lat": 50.6676672531,
                "lng": -125.5417550399
            },
            {
                "lat": 50.6570854057,
                "lng": -125.5489734511
            },
            {
                "lat": 50.643135158,
                "lng": -125.5619576681
            },
            {
                "lat": 50.6282237886,
                "lng": -125.5658030632
            },
            {
                "lat": 50.613311891,
                "lng": -125.5648415688
            },
            {
                "lat": 50.5979192916,
                "lng": -125.564362383
            },
            {
                "lat": 50.5921473971,
                "lng": -125.569176162
            },
            {
                "lat": 50.5839694169,
                "lng": -125.5802388308
            },
            {
                "lat": 50.5767549175,
                "lng": -125.5831226076
            },
            {
                "lat": 50.5680961321,
                "lng": -125.5792771803
            },
            {
                "lat": 50.5632858009,
                "lng": -125.5691760096
            },
            {
                "lat": 50.5565525077,
                "lng": -125.5643618246
            },
            {
                "lat": 50.550300757,
                "lng": -125.5595549569
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 419,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 43,
            "MANAGEMENT_AREA_NAME": "Subarea 13-43",
            "LABEL": "13-43",
            "FISHERY_AREA_ID": 2148,
            "OBJECTID": 563,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 86547957.2233,
            "FEATURE_LENGTH_M": 56142.254,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.643739109157686,
            "lng": -125.50685385005191
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.7100949808,
                "lng": -125.7952342568
            },
            {
                "lat": 50.7042123654,
                "lng": -125.794197704
            },
            {
                "lat": 50.6822510078,
                "lng": -125.7941974581
            },
            {
                "lat": 50.6754719296,
                "lng": -125.7954708444
            },
            {
                "lat": 50.6713147256,
                "lng": -125.7919153215
            },
            {
                "lat": 50.6671410296,
                "lng": -125.7819058848
            },
            {
                "lat": 50.668255186,
                "lng": -125.7641063979
            },
            {
                "lat": 50.6710353109,
                "lng": -125.7552114458
            },
            {
                "lat": 50.6771540373,
                "lng": -125.7538153805
            },
            {
                "lat": 50.6827164128,
                "lng": -125.7527078609
            },
            {
                "lat": 50.6832726251,
                "lng": -125.7493665689
            },
            {
                "lat": 50.6837267956,
                "lng": -125.7441628141
            },
            {
                "lat": 50.6863056122,
                "lng": -125.7406078715
            },
            {
                "lat": 50.6878397765,
                "lng": -125.7386022394
            },
            {
                "lat": 50.7089239662,
                "lng": -125.7268066613
            },
            {
                "lat": 50.7155337955,
                "lng": -125.730453346
            },
            {
                "lat": 50.7155339801,
                "lng": -125.7354589793
            },
            {
                "lat": 50.7163697221,
                "lng": -125.748252654
            },
            {
                "lat": 50.7152554981,
                "lng": -125.7577130451
            },
            {
                "lat": 50.7133103296,
                "lng": -125.7707825194
            },
            {
                "lat": 50.7110824391,
                "lng": -125.7830206765
            },
            {
                "lat": 50.7100949808,
                "lng": -125.7952342568
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 420,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 28,
            "MANAGEMENT_AREA_NAME": "Subarea 12-28",
            "LABEL": "12-28",
            "FISHERY_AREA_ID": 2086,
            "OBJECTID": 564,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 19287931.1272,
            "FEATURE_LENGTH_M": 17656.2963,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.69349529575909,
            "lng": -125.76360109940914
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.6754719296,
                "lng": -125.7954708444
            },
            {
                "lat": 50.6822510078,
                "lng": -125.7941974581
            },
            {
                "lat": 50.7042123654,
                "lng": -125.794197704
            },
            {
                "lat": 50.7100949808,
                "lng": -125.7952342568
            },
            {
                "lat": 50.7086593432,
                "lng": -125.8084489397
            },
            {
                "lat": 50.7089007865,
                "lng": -125.8185568385
            },
            {
                "lat": 50.7093779553,
                "lng": -125.827392344
            },
            {
                "lat": 50.7088618685,
                "lng": -125.8397143385
            },
            {
                "lat": 50.7068099812,
                "lng": -125.8515169279
            },
            {
                "lat": 50.7032157208,
                "lng": -125.8628078184
            },
            {
                "lat": 50.7016756718,
                "lng": -125.8766640093
            },
            {
                "lat": 50.6991122546,
                "lng": -125.8894965372
            },
            {
                "lat": 50.6955188235,
                "lng": -125.9264517215
            },
            {
                "lat": 50.6939774367,
                "lng": -125.9490359105
            },
            {
                "lat": 50.7021901159,
                "lng": -125.9474940176
            },
            {
                "lat": 50.7088623243,
                "lng": -125.9439017006
            },
            {
                "lat": 50.7114291638,
                "lng": -125.9500575022
            },
            {
                "lat": 50.7114291626,
                "lng": -125.9685363298
            },
            {
                "lat": 50.7088618993,
                "lng": -125.9895785254
            },
            {
                "lat": 50.7062950901,
                "lng": -126.0008695128
            },
            {
                "lat": 50.7083474805,
                "lng": -126.0121613387
            },
            {
                "lat": 50.7068098977,
                "lng": -126.0224225234
            },
            {
                "lat": 50.6988945427,
                "lng": -126.0300746873
            },
            {
                "lat": 50.6966817796,
                "lng": -126.0413824264
            },
            {
                "lat": 50.6920168463,
                "lng": -126.0536651577
            },
            {
                "lat": 50.6883311851,
                "lng": -126.066192848
            },
            {
                "lat": 50.6851345563,
                "lng": -126.0782316803
            },
            {
                "lat": 50.6829259568,
                "lng": -126.0914991543
            },
            {
                "lat": 50.6807141075,
                "lng": -126.1035387818
            },
            {
                "lat": 50.6794859252,
                "lng": -126.1180345357
            },
            {
                "lat": 50.6767850588,
                "lng": -126.1352310911
            },
            {
                "lat": 50.6753076854,
                "lng": -126.1536561295
            },
            {
                "lat": 50.6745719046,
                "lng": -126.167900218
            },
            {
                "lat": 50.6739770728,
                "lng": -126.1750251977
            },
            {
                "lat": 50.6726114983,
                "lng": -126.1776273449
            },
            {
                "lat": 50.6702876649,
                "lng": -126.1815181077
            },
            {
                "lat": 50.6695475747,
                "lng": -126.1836543931
            },
            {
                "lat": 50.6694031443,
                "lng": -126.1840820104
            },
            {
                "lat": 50.6684984753,
                "lng": -126.1845471991
            },
            {
                "lat": 50.6619073811,
                "lng": -126.1779176291
            },
            {
                "lat": 50.6527057785,
                "lng": -126.1686631305
            },
            {
                "lat": 50.6456718491,
                "lng": -126.161590069
            },
            {
                "lat": 50.6456069018,
                "lng": -126.1618498073
            },
            {
                "lat": 50.6456603788,
                "lng": -126.1621400367
            },
            {
                "lat": 50.6457170341,
                "lng": -126.1625602716
            },
            {
                "lat": 50.6456949427,
                "lng": -126.1630783677
            },
            {
                "lat": 50.6455494484,
                "lng": -126.1639019669
            },
            {
                "lat": 50.6453888172,
                "lng": -126.1647033919
            },
            {
                "lat": 50.6450922773,
                "lng": -126.1655273154
            },
            {
                "lat": 50.6448440942,
                "lng": -126.1666714888
            },
            {
                "lat": 50.6441071373,
                "lng": -126.1706088776
            },
            {
                "lat": 50.6438638507,
                "lng": -126.1764986524
            },
            {
                "lat": 50.6426310381,
                "lng": -126.1833799586
            },
            {
                "lat": 50.6409151833,
                "lng": -126.1878052906
            },
            {
                "lat": 50.6382107376,
                "lng": -126.1897664416
            },
            {
                "lat": 50.6342014127,
                "lng": -126.1897663228
            },
            {
                "lat": 50.6293111191,
                "lng": -126.1840964297
            },
            {
                "lat": 50.6298253979,
                "lng": -126.1651078038
            },
            {
                "lat": 50.6318777175,
                "lng": -126.1302031477
            },
            {
                "lat": 50.634441375,
                "lng": -126.1045452115
            },
            {
                "lat": 50.6411128421,
                "lng": -126.083503389
            },
            {
                "lat": 50.6467593093,
                "lng": -126.0598912645
            },
            {
                "lat": 50.6498406967,
                "lng": -126.0280689086
            },
            {
                "lat": 50.6570249862,
                "lng": -126.0024110925
            },
            {
                "lat": 50.6570248767,
                "lng": -125.9798276571
            },
            {
                "lat": 50.6585661964,
                "lng": -125.9526211927
            },
            {
                "lat": 50.6595917847,
                "lng": -125.9264521502
            },
            {
                "lat": 50.6607128389,
                "lng": -125.9189758413
            },
            {
                "lat": 50.6601063157,
                "lng": -125.9120784045
            },
            {
                "lat": 50.6580501443,
                "lng": -125.8961711321
            },
            {
                "lat": 50.659080727,
                "lng": -125.8792337004
            },
            {
                "lat": 50.6616443408,
                "lng": -125.8592151343
            },
            {
                "lat": 50.6683157346,
                "lng": -125.8463819408
            },
            {
                "lat": 50.671909701,
                "lng": -125.8325273326
            },
            {
                "lat": 50.6744767717,
                "lng": -125.8145672953
            },
            {
                "lat": 50.6754718926,
                "lng": -125.8080905285
            },
            {
                "lat": 50.6754719035,
                "lng": -125.8051838146
            },
            {
                "lat": 50.6754719296,
                "lng": -125.7954708444
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 421,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 27,
            "MANAGEMENT_AREA_NAME": "Subarea 12-27",
            "LABEL": "12-27",
            "FISHERY_AREA_ID": 2085,
            "OBJECTID": 565,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 132609688.2086,
            "FEATURE_LENGTH_M": 69984.3906,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.67219739875255,
            "lng": -126.02168104226803
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.6639139031,
                "lng": -126.6085505072
            },
            {
                "lat": 50.6631281947,
                "lng": -126.6102374684
            },
            {
                "lat": 50.6618917823,
                "lng": -126.6131213148
            },
            {
                "lat": 50.6617277605,
                "lng": -126.6145636308
            },
            {
                "lat": 50.6614380338,
                "lng": -126.616500481
            },
            {
                "lat": 50.6611097476,
                "lng": -126.6174849526
            },
            {
                "lat": 50.6605336796,
                "lng": -126.6188056791
            },
            {
                "lat": 50.6603272907,
                "lng": -126.6192698943
            },
            {
                "lat": 50.6600838916,
                "lng": -126.6198273596
            },
            {
                "lat": 50.6599534886,
                "lng": -126.6200555494
            },
            {
                "lat": 50.6258964747,
                "lng": -126.5800473388
            },
            {
                "lat": 50.5966376964,
                "lng": -126.5804439675
            },
            {
                "lat": 50.5966345505,
                "lng": -126.5810472434
            },
            {
                "lat": 50.5967668065,
                "lng": -126.5818483536
            },
            {
                "lat": 50.5968703566,
                "lng": -126.583091651
            },
            {
                "lat": 50.5964012463,
                "lng": -126.5839998139
            },
            {
                "lat": 50.5962523395,
                "lng": -126.5847698992
            },
            {
                "lat": 50.5959961716,
                "lng": -126.5860756367
            },
            {
                "lat": 50.5957297798,
                "lng": -126.5896309987
            },
            {
                "lat": 50.5951918488,
                "lng": -126.5931244573
            },
            {
                "lat": 50.593246683,
                "lng": -126.5939935529
            },
            {
                "lat": 50.5911339184,
                "lng": -126.593963901
            },
            {
                "lat": 50.5894549846,
                "lng": -126.5936582487
            },
            {
                "lat": 50.5874096187,
                "lng": -126.5914083927
            },
            {
                "lat": 50.585700437,
                "lng": -126.588860243
            },
            {
                "lat": 50.5845257858,
                "lng": -126.5864102152
            },
            {
                "lat": 50.5837748596,
                "lng": -126.585487337
            },
            {
                "lat": 50.5582544401,
                "lng": -126.5954816585
            },
            {
                "lat": 50.5544545016,
                "lng": -126.5980144844
            },
            {
                "lat": 50.5518154124,
                "lng": -126.5979997519
            },
            {
                "lat": 50.5484198619,
                "lng": -126.5979996725
            },
            {
                "lat": 50.5462381365,
                "lng": -126.5978242453
            },
            {
                "lat": 50.5451808383,
                "lng": -126.5935133395
            },
            {
                "lat": 50.5437767981,
                "lng": -126.5931322304
            },
            {
                "lat": 50.5417748304,
                "lng": -126.5871198154
            },
            {
                "lat": 50.5423473011,
                "lng": -126.5805429856
            },
            {
                "lat": 50.5449216475,
                "lng": -126.5776830608
            },
            {
                "lat": 50.5466386542,
                "lng": -126.5751109921
            },
            {
                "lat": 50.5480648488,
                "lng": -126.5682449527
            },
            {
                "lat": 50.548351238,
                "lng": -126.5593798286
            },
            {
                "lat": 50.5477793544,
                "lng": -126.5533673696
            },
            {
                "lat": 50.5454944484,
                "lng": -126.5473633648
            },
            {
                "lat": 50.5437769803,
                "lng": -126.5419235184
            },
            {
                "lat": 50.5432055703,
                "lng": -126.5367818277
            },
            {
                "lat": 50.5452078445,
                "lng": -126.5324858945
            },
            {
                "lat": 50.5474930113,
                "lng": -126.5287699577
            },
            {
                "lat": 50.5494999797,
                "lng": -126.5259098539
            },
            {
                "lat": 50.549499857,
                "lng": -126.5187607061
            },
            {
                "lat": 50.5495000452,
                "lng": -126.5121762979
            },
            {
                "lat": 50.5500679158,
                "lng": -126.5001679626
            },
            {
                "lat": 50.5503544913,
                "lng": -126.4852911242
            },
            {
                "lat": 50.5509259647,
                "lng": -126.4772796389
            },
            {
                "lat": 50.5520703881,
                "lng": -126.4704131463
            },
            {
                "lat": 50.5552171487,
                "lng": -126.4664076898
            },
            {
                "lat": 50.5565676764,
                "lng": -126.4658506871
            },
            {
                "lat": 50.5569727956,
                "lng": -126.4593047098
            },
            {
                "lat": 50.5557897153,
                "lng": -126.4572522814
            },
            {
                "lat": 50.5495000386,
                "lng": -126.4506764245
            },
            {
                "lat": 50.5463524442,
                "lng": -126.4398038085
            },
            {
                "lat": 50.546925047,
                "lng": -126.4303666491
            },
            {
                "lat": 50.5492100317,
                "lng": -126.4255071564
            },
            {
                "lat": 50.5537877989,
                "lng": -126.422927703
            },
            {
                "lat": 50.5586512658,
                "lng": -126.4246449479
            },
            {
                "lat": 50.5591092,
                "lng": -126.4247128108
            },
            {
                "lat": 50.5619275604,
                "lng": -126.4251171543
            },
            {
                "lat": 50.5662579477,
                "lng": -126.4306564632
            },
            {
                "lat": 50.5693362179,
                "lng": -126.4188611481
            },
            {
                "lat": 50.5744699044,
                "lng": -126.4009018323
            },
            {
                "lat": 50.5770334992,
                "lng": -126.3788374894
            },
            {
                "lat": 50.5770331892,
                "lng": -126.364982697
            },
            {
                "lat": 50.5790859139,
                "lng": -126.3454823967
            },
            {
                "lat": 50.5862696231,
                "lng": -126.3423989986
            },
            {
                "lat": 50.5847319769,
                "lng": -126.3306045143
            },
            {
                "lat": 50.5785745138,
                "lng": -126.3167499907
            },
            {
                "lat": 50.5724186619,
                "lng": -126.3028948586
            },
            {
                "lat": 50.5703315405,
                "lng": -126.2960207043
            },
            {
                "lat": 50.5674362888,
                "lng": -126.2896804507
            },
            {
                "lat": 50.5658192626,
                "lng": -126.2861710424
            },
            {
                "lat": 50.568325802,
                "lng": -126.2752603593
            },
            {
                "lat": 50.5688366224,
                "lng": -126.2750931174
            },
            {
                "lat": 50.5702289011,
                "lng": -126.2754141282
            },
            {
                "lat": 50.5710487078,
                "lng": -126.2754058937
            },
            {
                "lat": 50.5721928962,
                "lng": -126.2738723783
            },
            {
                "lat": 50.5733572099,
                "lng": -126.2700576269
            },
            {
                "lat": 50.5740208456,
                "lng": -126.2620929533
            },
            {
                "lat": 50.5731880192,
                "lng": -126.2561183485
            },
            {
                "lat": 50.5718609483,
                "lng": -126.2508086271
            },
            {
                "lat": 50.5725710066,
                "lng": -126.2451165871
            },
            {
                "lat": 50.5754243248,
                "lng": -126.2222063407
            },
            {
                "lat": 50.5717169801,
                "lng": -126.219559041
            },
            {
                "lat": 50.5772208885,
                "lng": -126.207779529
            },
            {
                "lat": 50.5788844688,
                "lng": -126.1989294693
            },
            {
                "lat": 50.5793303776,
                "lng": -126.1995088936
            },
            {
                "lat": 50.5801510705,
                "lng": -126.1996228954
            },
            {
                "lat": 50.5809436004,
                "lng": -126.1994701279
            },
            {
                "lat": 50.5831835709,
                "lng": -126.196518363
            },
            {
                "lat": 50.5864490081,
                "lng": -126.1946940133
            },
            {
                "lat": 50.5992619653,
                "lng": -126.1875077675
            },
            {
                "lat": 50.6072535928,
                "lng": -126.1870657503
            },
            {
                "lat": 50.6232260733,
                "lng": -126.1897659684
            },
            {
                "lat": 50.6330526814,
                "lng": -126.1897658873
            },
            {
                "lat": 50.6342014127,
                "lng": -126.1897663228
            },
            {
                "lat": 50.6382107376,
                "lng": -126.1897664416
            },
            {
                "lat": 50.6409151833,
                "lng": -126.1878052906
            },
            {
                "lat": 50.6426310381,
                "lng": -126.1833799586
            },
            {
                "lat": 50.6438638507,
                "lng": -126.1764986524
            },
            {
                "lat": 50.6441071373,
                "lng": -126.1706088776
            },
            {
                "lat": 50.6448440942,
                "lng": -126.1666714888
            },
            {
                "lat": 50.6450922773,
                "lng": -126.1655273154
            },
            {
                "lat": 50.6453888172,
                "lng": -126.1647033919
            },
            {
                "lat": 50.6455494484,
                "lng": -126.1639019669
            },
            {
                "lat": 50.6456949427,
                "lng": -126.1630783677
            },
            {
                "lat": 50.6457170341,
                "lng": -126.1625602716
            },
            {
                "lat": 50.6456603788,
                "lng": -126.1621400367
            },
            {
                "lat": 50.6456069018,
                "lng": -126.1618498073
            },
            {
                "lat": 50.6456718491,
                "lng": -126.161590069
            },
            {
                "lat": 50.6527057785,
                "lng": -126.1686631305
            },
            {
                "lat": 50.6619073811,
                "lng": -126.1779176291
            },
            {
                "lat": 50.6684984753,
                "lng": -126.1845471991
            },
            {
                "lat": 50.6600994223,
                "lng": -126.2428740765
            },
            {
                "lat": 50.6566698728,
                "lng": -126.2670672618
            },
            {
                "lat": 50.6567918738,
                "lng": -126.2800451072
            },
            {
                "lat": 50.656959732,
                "lng": -126.280273459
            },
            {
                "lat": 50.662044693,
                "lng": -126.286888267
            },
            {
                "lat": 50.6625521581,
                "lng": -126.2914657613
            },
            {
                "lat": 50.6610261112,
                "lng": -126.2980732965
            },
            {
                "lat": 50.6594998359,
                "lng": -126.3021461929
            },
            {
                "lat": 50.6570364114,
                "lng": -126.3064034768
            },
            {
                "lat": 50.6576046411,
                "lng": -126.3675228648
            },
            {
                "lat": 50.6610257559,
                "lng": -126.3743673517
            },
            {
                "lat": 50.6640782709,
                "lng": -126.3860699244
            },
            {
                "lat": 50.6666219971,
                "lng": -126.4038694904
            },
            {
                "lat": 50.6722179714,
                "lng": -126.4221801866
            },
            {
                "lat": 50.6813741225,
                "lng": -126.4425286714
            },
            {
                "lat": 50.687476449,
                "lng": -126.4542234108
            },
            {
                "lat": 50.6890030949,
                "lng": -126.4618536212
            },
            {
                "lat": 50.6864593038,
                "lng": -126.4842305904
            },
            {
                "lat": 50.681880299,
                "lng": -126.5010137451
            },
            {
                "lat": 50.6767954384,
                "lng": -126.5162736155
            },
            {
                "lat": 50.6681479558,
                "lng": -126.523902297
            },
            {
                "lat": 50.6635709044,
                "lng": -126.5330588371
            },
            {
                "lat": 50.6630632475,
                "lng": -126.5686643049
            },
            {
                "lat": 50.6656031997,
                "lng": -126.5890122113
            },
            {
                "lat": 50.6658864123,
                "lng": -126.5946805721
            },
            {
                "lat": 50.665351794,
                "lng": -126.5981670712
            },
            {
                "lat": 50.6642832437,
                "lng": -126.6011128988
            },
            {
                "lat": 50.6638678159,
                "lng": -126.6022485348
            },
            {
                "lat": 50.6639516681,
                "lng": -126.6045992853
            },
            {
                "lat": 50.6639139031,
                "lng": -126.6085505072
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 422,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 26,
            "MANAGEMENT_AREA_NAME": "Subarea 12-26",
            "LABEL": "12-26",
            "FISHERY_AREA_ID": 2084,
            "OBJECTID": 566,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 329375937.5508,
            "FEATURE_LENGTH_M": 95416.1411,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.60482220705707,
            "lng": -126.41497857417515
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5735209854,
                "lng": -127.5543444424
            },
            {
                "lat": 50.5759207281,
                "lng": -127.5572435038
            },
            {
                "lat": 50.5802198064,
                "lng": -127.5627443459
            },
            {
                "lat": 50.5862163457,
                "lng": -127.5692443617
            },
            {
                "lat": 50.5885167688,
                "lng": -127.5698395671
            },
            {
                "lat": 50.5896189986,
                "lng": -127.5673445077
            },
            {
                "lat": 50.5909160422,
                "lng": -127.5640106996
            },
            {
                "lat": 50.5937459791,
                "lng": -127.5637742309
            },
            {
                "lat": 50.5986216645,
                "lng": -127.5651099829
            },
            {
                "lat": 50.6050685582,
                "lng": -127.5686498377
            },
            {
                "lat": 50.607742801,
                "lng": -127.5707712625
            },
            {
                "lat": 50.6075821088,
                "lng": -127.5739983204
            },
            {
                "lat": 50.6056177807,
                "lng": -127.5790249338
            },
            {
                "lat": 50.6031034405,
                "lng": -127.5891727776
            },
            {
                "lat": 50.6021569522,
                "lng": -127.6083529216
            },
            {
                "lat": 50.6008226429,
                "lng": -127.6263661208
            },
            {
                "lat": 50.6034167171,
                "lng": -127.6317061893
            },
            {
                "lat": 50.6036529945,
                "lng": -127.6404339066
            },
            {
                "lat": 50.6072687345,
                "lng": -127.6463312914
            },
            {
                "lat": 50.6117518108,
                "lng": -127.6479864064
            },
            {
                "lat": 50.6130106756,
                "lng": -127.6548240769
            },
            {
                "lat": 50.6120639528,
                "lng": -127.6620552861
            },
            {
                "lat": 50.6112779345,
                "lng": -127.6760560954
            },
            {
                "lat": 50.6115947607,
                "lng": -127.6836856739
            },
            {
                "lat": 50.612537864,
                "lng": -127.6920159804
            },
            {
                "lat": 50.6135600238,
                "lng": -127.6997989843
            },
            {
                "lat": 50.6130104687,
                "lng": -127.704521937
            },
            {
                "lat": 50.6119883364,
                "lng": -127.7109671341
            },
            {
                "lat": 50.6119079119,
                "lng": -127.7220537813
            },
            {
                "lat": 50.6114351942,
                "lng": -127.7284241206
            },
            {
                "lat": 50.6092341627,
                "lng": -127.7385633976
            },
            {
                "lat": 50.6090778656,
                "lng": -127.7481537138
            },
            {
                "lat": 50.6108860028,
                "lng": -127.7564932348
            },
            {
                "lat": 50.6114354974,
                "lng": -127.7638012108
            },
            {
                "lat": 50.6125376827,
                "lng": -127.7765431564
            },
            {
                "lat": 50.6129299146,
                "lng": -127.786292969
            },
            {
                "lat": 50.611435214,
                "lng": -127.7917931186
            },
            {
                "lat": 50.6121445453,
                "lng": -127.8006062754
            },
            {
                "lat": 50.6154479432,
                "lng": -127.8115306973
            },
            {
                "lat": 50.6206355766,
                "lng": -127.8330764112
            },
            {
                "lat": 50.621578363,
                "lng": -127.8413314919
            },
            {
                "lat": 50.6249614121,
                "lng": -127.8607551601
            },
            {
                "lat": 50.6274763134,
                "lng": -127.8738022645
            },
            {
                "lat": 50.6283409121,
                "lng": -127.8818211019
            },
            {
                "lat": 50.6314852919,
                "lng": -127.8893737061
            },
            {
                "lat": 50.6355738422,
                "lng": -127.8909455366
            },
            {
                "lat": 50.6386416855,
                "lng": -127.8962936513
            },
            {
                "lat": 50.639270337,
                "lng": -127.9009325829
            },
            {
                "lat": 50.6388776434,
                "lng": -127.9077757134
            },
            {
                "lat": 50.6400572536,
                "lng": -127.9180750139
            },
            {
                "lat": 50.6444621287,
                "lng": -127.937568557
            },
            {
                "lat": 50.6476057298,
                "lng": -127.9519572414
            },
            {
                "lat": 50.6498067398,
                "lng": -127.963126357
            },
            {
                "lat": 50.6477626678,
                "lng": -127.971221736
            },
            {
                "lat": 50.6498874845,
                "lng": -127.9812933012
            },
            {
                "lat": 50.6546021873,
                "lng": -127.9961470159
            },
            {
                "lat": 50.6582982984,
                "lng": -128.0102238889
            },
            {
                "lat": 50.6598698116,
                "lng": -128.0184016153
            },
            {
                "lat": 50.657589477,
                "lng": -128.0204475781
            },
            {
                "lat": 50.6552317607,
                "lng": -128.0230410929
            },
            {
                "lat": 50.6505926392,
                "lng": -128.0251616589
            },
            {
                "lat": 50.6471331176,
                "lng": -128.0231931051
            },
            {
                "lat": 50.6439895849,
                "lng": -128.0186313026
            },
            {
                "lat": 50.640922105,
                "lng": -128.0095970052
            },
            {
                "lat": 50.6371454343,
                "lng": -127.9989775582
            },
            {
                "lat": 50.6340828784,
                "lng": -127.992691378
            },
            {
                "lat": 50.6317219077,
                "lng": -127.9858472326
            },
            {
                "lat": 50.6284983866,
                "lng": -127.9683149041
            },
            {
                "lat": 50.6266138137,
                "lng": -127.959274332
            },
            {
                "lat": 50.6265331468,
                "lng": -127.9421311748
            },
            {
                "lat": 50.6253549606,
                "lng": -127.9345010148
            },
            {
                "lat": 50.6237788475,
                "lng": -127.9275058514
            },
            {
                "lat": 50.6215782982,
                "lng": -127.9170532965
            },
            {
                "lat": 50.6181977681,
                "lng": -127.9044716288
            },
            {
                "lat": 50.6153677658,
                "lng": -127.8973161903
            },
            {
                "lat": 50.6134797343,
                "lng": -127.8870164266
            },
            {
                "lat": 50.6124574007,
                "lng": -127.8817442781
            },
            {
                "lat": 50.6103361665,
                "lng": -127.8753738315
            },
            {
                "lat": 50.6103364801,
                "lng": -127.8685379862
            },
            {
                "lat": 50.6098636818,
                "lng": -127.8588639585
            },
            {
                "lat": 50.6089974763,
                "lng": -127.8510813014
            },
            {
                "lat": 50.6069567099,
                "lng": -127.8414072488
            },
            {
                "lat": 50.6043581572,
                "lng": -127.833938407
            },
            {
                "lat": 50.6016880748,
                "lng": -127.8271020452
            },
            {
                "lat": 50.6003490787,
                "lng": -127.8212049068
            },
            {
                "lat": 50.5964967513,
                "lng": -127.8114550398
            },
            {
                "lat": 50.5924070742,
                "lng": -127.7932891459
            },
            {
                "lat": 50.5883223061,
                "lng": -127.7767797289
            },
            {
                "lat": 50.5859599516,
                "lng": -127.7700117836
            },
            {
                "lat": 50.5837584802,
                "lng": -127.7659308453
            },
            {
                "lat": 50.5818703553,
                "lng": -127.7574382558
            },
            {
                "lat": 50.581794659,
                "lng": -127.7501226772
            },
            {
                "lat": 50.5825804273,
                "lng": -127.7412408802
            },
            {
                "lat": 50.5819506824,
                "lng": -127.7306976558
            },
            {
                "lat": 50.5799866522,
                "lng": -127.7189864813
            },
            {
                "lat": 50.5788038461,
                "lng": -127.7056959285
            },
            {
                "lat": 50.5786482831,
                "lng": -127.6949230124
            },
            {
                "lat": 50.5789649238,
                "lng": -127.681006914
            },
            {
                "lat": 50.5791207516,
                "lng": -127.6688228554
            },
            {
                "lat": 50.578804315,
                "lng": -127.6618189205
            },
            {
                "lat": 50.5769195634,
                "lng": -127.6522290004
            },
            {
                "lat": 50.575344146,
                "lng": -127.642402164
            },
            {
                "lat": 50.5751880497,
                "lng": -127.6331255952
            },
            {
                "lat": 50.5738528033,
                "lng": -127.6203846639
            },
            {
                "lat": 50.5729870491,
                "lng": -127.6090618215
            },
            {
                "lat": 50.570629265,
                "lng": -127.6011205295
            },
            {
                "lat": 50.5673256341,
                "lng": -127.5919272847
            },
            {
                "lat": 50.5639462703,
                "lng": -127.5851597642
            },
            {
                "lat": 50.5590707914,
                "lng": -127.579346309
            },
            {
                "lat": 50.5544816974,
                "lng": -127.5743482626
            },
            {
                "lat": 50.558982982,
                "lng": -127.5620956332
            },
            {
                "lat": 50.5688321965,
                "lng": -127.5543670964
            },
            {
                "lat": 50.5735209854,
                "lng": -127.5543444424
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 423,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 27,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 27-11",
            "LABEL": "27-11",
            "FISHERY_AREA_ID": 2511,
            "OBJECTID": 567,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 91018533.0167,
            "FEATURE_LENGTH_M": 77218.7677,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.608193780838945,
            "lng": -127.77054258571945
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.6532402426,
                "lng": -127.0351486467
            },
            {
                "lat": 50.6587219629,
                "lng": -127.0557704571
            },
            {
                "lat": 50.6587222117,
                "lng": -127.0813526279
            },
            {
                "lat": 50.6631621004,
                "lng": -127.1105964299
            },
            {
                "lat": 50.6511537547,
                "lng": -127.1351321968
            },
            {
                "lat": 50.6417228883,
                "lng": -127.1530998794
            },
            {
                "lat": 50.6391979661,
                "lng": -127.104843044
            },
            {
                "lat": 50.6438442202,
                "lng": -127.059166083
            },
            {
                "lat": 50.6532402426,
                "lng": -127.0351486467
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 424,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 568,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 13065090.9671,
            "FEATURE_LENGTH_M": 18035.6392,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.6514450655,
            "lng": -127.0855842235
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.6589246233,
                "lng": -127.2824470117
            },
            {
                "lat": 50.6583252733,
                "lng": -127.2825626018
            },
            {
                "lat": 50.6530610043,
                "lng": -127.2841186309
            },
            {
                "lat": 50.6484411745,
                "lng": -127.285736079
            },
            {
                "lat": 50.6452786591,
                "lng": -127.2808539584
            },
            {
                "lat": 50.6412586018,
                "lng": -127.2707979494
            },
            {
                "lat": 50.633789439,
                "lng": -127.2535551788
            },
            {
                "lat": 50.624023391,
                "lng": -127.2334519996
            },
            {
                "lat": 50.6108057378,
                "lng": -127.2124780504
            },
            {
                "lat": 50.6019019082,
                "lng": -127.1943811788
            },
            {
                "lat": 50.5996014856,
                "lng": -127.1808771471
            },
            {
                "lat": 50.5975913035,
                "lng": -127.165938542
            },
            {
                "lat": 50.5972292256,
                "lng": -127.1558537224
            },
            {
                "lat": 50.6003072283,
                "lng": -127.1452939097
            },
            {
                "lat": 50.6033864804,
                "lng": -127.1395718484
            },
            {
                "lat": 50.6051491048,
                "lng": -127.1329720216
            },
            {
                "lat": 50.6060288171,
                "lng": -127.1290127199
            },
            {
                "lat": 50.6129450467,
                "lng": -127.1294788443
            },
            {
                "lat": 50.630527212,
                "lng": -127.1548920862
            },
            {
                "lat": 50.6589246233,
                "lng": -127.2824470117
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 425,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 17,
            "MANAGEMENT_AREA_NAME": "Subarea 12-17",
            "LABEL": "12-17",
            "FISHERY_AREA_ID": 2075,
            "OBJECTID": 569,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 31927763.1002,
            "FEATURE_LENGTH_M": 27418.2632,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.624375016980004,
            "lng": -127.20983602460501
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.6584625149,
                "lng": -126.962837312
            },
            {
                "lat": 50.6524575387,
                "lng": -126.9743270855
            },
            {
                "lat": 50.6558534797,
                "lng": -126.9959950665
            },
            {
                "lat": 50.6516768538,
                "lng": -127.0176620862
            },
            {
                "lat": 50.634211734,
                "lng": -127.0095898985
            },
            {
                "lat": 50.6394076217,
                "lng": -126.9871147405
            },
            {
                "lat": 50.6381005245,
                "lng": -126.9552693132
            },
            {
                "lat": 50.6336637393,
                "lng": -126.9189836082
            },
            {
                "lat": 50.6354903033,
                "lng": -126.8800892655
            },
            {
                "lat": 50.637046701,
                "lng": -126.8670572402
            },
            {
                "lat": 50.6456720773,
                "lng": -126.8743442062
            },
            {
                "lat": 50.6558535226,
                "lng": -126.8926167167
            },
            {
                "lat": 50.6525262605,
                "lng": -126.9094925247
            },
            {
                "lat": 50.6488034479,
                "lng": -126.928382981
            },
            {
                "lat": 50.6535032902,
                "lng": -126.9461291557
            },
            {
                "lat": 50.6584625149,
                "lng": -126.962837312
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 426,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 570,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 18817871.7544,
            "FEATURE_LENGTH_M": 24625.9906,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.646949507768745,
            "lng": -126.9426705320375
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.6516768538,
                "lng": -127.0176620862
            },
            {
                "lat": 50.6532402426,
                "lng": -127.0351486467
            },
            {
                "lat": 50.6438442202,
                "lng": -127.059166083
            },
            {
                "lat": 50.6391979661,
                "lng": -127.104843044
            },
            {
                "lat": 50.6417228883,
                "lng": -127.1530998794
            },
            {
                "lat": 50.6354831264,
                "lng": -127.1546860632
            },
            {
                "lat": 50.630527212,
                "lng": -127.1548920862
            },
            {
                "lat": 50.6129450467,
                "lng": -127.1294788443
            },
            {
                "lat": 50.6060288171,
                "lng": -127.1290127199
            },
            {
                "lat": 50.6060292098,
                "lng": -127.1184538442
            },
            {
                "lat": 50.6060287149,
                "lng": -127.1105342495
            },
            {
                "lat": 50.6055866734,
                "lng": -127.0986551406
            },
            {
                "lat": 50.6053121146,
                "lng": -127.089332962
            },
            {
                "lat": 50.6053283472,
                "lng": -127.08657839
            },
            {
                "lat": 50.6052434186,
                "lng": -127.0850371669
            },
            {
                "lat": 50.6047857298,
                "lng": -127.0827333059
            },
            {
                "lat": 50.6043432311,
                "lng": -127.0801312046
            },
            {
                "lat": 50.6043433623,
                "lng": -127.079422603
            },
            {
                "lat": 50.6043281686,
                "lng": -127.0784989392
            },
            {
                "lat": 50.6040384073,
                "lng": -127.0758290014
            },
            {
                "lat": 50.5984991226,
                "lng": -127.0248341389
            },
            {
                "lat": 50.5868870273,
                "lng": -126.9508588775
            },
            {
                "lat": 50.589392989,
                "lng": -126.9511405732
            },
            {
                "lat": 50.5925561273,
                "lng": -126.950477829
            },
            {
                "lat": 50.5960546037,
                "lng": -126.9468999127
            },
            {
                "lat": 50.596718304,
                "lng": -126.9414060547
            },
            {
                "lat": 50.5962188706,
                "lng": -126.9341661686
            },
            {
                "lat": 50.5952185031,
                "lng": -126.9301756631
            },
            {
                "lat": 50.5931395368,
                "lng": -126.9253469697
            },
            {
                "lat": 50.5895618047,
                "lng": -126.9215170259
            },
            {
                "lat": 50.5855678699,
                "lng": -126.9172747393
            },
            {
                "lat": 50.5810692907,
                "lng": -126.9136049034
            },
            {
                "lat": 50.5801578159,
                "lng": -126.912864951
            },
            {
                "lat": 50.5798497725,
                "lng": -126.9054791755
            },
            {
                "lat": 50.5518075598,
                "lng": -126.8542786408
            },
            {
                "lat": 50.5715711078,
                "lng": -126.7874908825
            },
            {
                "lat": 50.6290093215,
                "lng": -126.8243866606
            },
            {
                "lat": 50.6296879921,
                "lng": -126.8341443521
            },
            {
                "lat": 50.6336636851,
                "lng": -126.8482352421
            },
            {
                "lat": 50.6375776824,
                "lng": -126.8625945061
            },
            {
                "lat": 50.637046701,
                "lng": -126.8670572402
            },
            {
                "lat": 50.6354903033,
                "lng": -126.8800892655
            },
            {
                "lat": 50.6336637393,
                "lng": -126.9189836082
            },
            {
                "lat": 50.6381005245,
                "lng": -126.9552693132
            },
            {
                "lat": 50.6394076217,
                "lng": -126.9871147405
            },
            {
                "lat": 50.634211734,
                "lng": -127.0095898985
            },
            {
                "lat": 50.6516768538,
                "lng": -127.0176620862
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 427,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 18,
            "MANAGEMENT_AREA_NAME": "Subarea 12-18",
            "LABEL": "12-18",
            "FISHERY_AREA_ID": 2076,
            "OBJECTID": 571,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 136249975.3049,
            "FEATURE_LENGTH_M": 64794.9854,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.61178383436597,
            "lng": -126.99353488679148
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5864490081,
                "lng": -126.1946940133
            },
            {
                "lat": 50.5831835709,
                "lng": -126.196518363
            },
            {
                "lat": 50.5809436004,
                "lng": -126.1994701279
            },
            {
                "lat": 50.5801510705,
                "lng": -126.1996228954
            },
            {
                "lat": 50.5793303776,
                "lng": -126.1995088936
            },
            {
                "lat": 50.5788844688,
                "lng": -126.1989294693
            },
            {
                "lat": 50.5684931798,
                "lng": -126.1907578943
            },
            {
                "lat": 50.5673792955,
                "lng": -126.1870422113
            },
            {
                "lat": 50.5653082271,
                "lng": -126.1845091399
            },
            {
                "lat": 50.5675586169,
                "lng": -126.1814959084
            },
            {
                "lat": 50.5679206029,
                "lng": -126.1756589744
            },
            {
                "lat": 50.5693816303,
                "lng": -126.1676403768
            },
            {
                "lat": 50.5701104231,
                "lng": -126.1592478999
            },
            {
                "lat": 50.572296705,
                "lng": -126.1504972058
            },
            {
                "lat": 50.5766715432,
                "lng": -126.1439359671
            },
            {
                "lat": 50.5814127047,
                "lng": -126.1395644023
            },
            {
                "lat": 50.5835989916,
                "lng": -126.1337281059
            },
            {
                "lat": 50.5832327368,
                "lng": -126.1206051709
            },
            {
                "lat": 50.5861508937,
                "lng": -126.1107559571
            },
            {
                "lat": 50.5905271221,
                "lng": -126.1012796703
            },
            {
                "lat": 50.5930791814,
                "lng": -126.0907061566
            },
            {
                "lat": 50.5934443927,
                "lng": -126.0801319749
            },
            {
                "lat": 50.5952688399,
                "lng": -126.0651850525
            },
            {
                "lat": 50.5996402665,
                "lng": -126.0465923964
            },
            {
                "lat": 50.6014634459,
                "lng": -126.0374750778
            },
            {
                "lat": 50.6051106235,
                "lng": -126.026168944
            },
            {
                "lat": 50.6058427321,
                "lng": -126.0083081873
            },
            {
                "lat": 50.6087568644,
                "lng": -125.9933549563
            },
            {
                "lat": 50.611309426,
                "lng": -125.9773175815
            },
            {
                "lat": 50.6156844746,
                "lng": -125.9598152309
            },
            {
                "lat": 50.6186027967,
                "lng": -125.9485159791
            },
            {
                "lat": 50.6226122468,
                "lng": -125.938667586
            },
            {
                "lat": 50.6299025322,
                "lng": -125.9324717819
            },
            {
                "lat": 50.6353727622,
                "lng": -125.9306485948
            },
            {
                "lat": 50.6397475667,
                "lng": -125.9306489189
            },
            {
                "lat": 50.6426658618,
                "lng": -125.9361193696
            },
            {
                "lat": 50.6444892345,
                "lng": -125.9408571621
            },
            {
                "lat": 50.6477694755,
                "lng": -125.9506994983
            },
            {
                "lat": 50.648498726,
                "lng": -125.9620055166
            },
            {
                "lat": 50.6470410458,
                "lng": -125.9729391545
            },
            {
                "lat": 50.6448512934,
                "lng": -125.9838785278
            },
            {
                "lat": 50.6401143727,
                "lng": -125.9966437769
            },
            {
                "lat": 50.6364671305,
                "lng": -126.0053866653
            },
            {
                "lat": 50.6328197805,
                "lng": -126.0210649207
            },
            {
                "lat": 50.6295390794,
                "lng": -126.0367427012
            },
            {
                "lat": 50.6280829395,
                "lng": -126.0491410132
            },
            {
                "lat": 50.6251639452,
                "lng": -126.0673752595
            },
            {
                "lat": 50.6222489257,
                "lng": -126.082320318
            },
            {
                "lat": 50.6175074517,
                "lng": -126.0969086559
            },
            {
                "lat": 50.6116750246,
                "lng": -126.1078411037
            },
            {
                "lat": 50.6065712157,
                "lng": -126.1319039541
            },
            {
                "lat": 50.6047469851,
                "lng": -126.1428445997
            },
            {
                "lat": 50.6011009808,
                "lng": -126.154876187
            },
            {
                "lat": 50.5949018132,
                "lng": -126.169097433
            },
            {
                "lat": 50.5887026792,
                "lng": -126.1862326784
            },
            {
                "lat": 50.5861511504,
                "lng": -126.1938864561
            },
            {
                "lat": 50.5864490081,
                "lng": -126.1946940133
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 428,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 23,
            "MANAGEMENT_AREA_NAME": "Subarea 12-23",
            "LABEL": "12-23",
            "FISHERY_AREA_ID": 2081,
            "OBJECTID": 572,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 57578664.0446,
            "FEATURE_LENGTH_M": 44464.6531,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.60355054455964,
            "lng": -126.08219175492455
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.6379849312,
                "lng": -124.839280272
            },
            {
                "lat": 50.6363598866,
                "lng": -124.8644102308
            },
            {
                "lat": 50.6386382402,
                "lng": -124.8981178302
            },
            {
                "lat": 50.6368021401,
                "lng": -124.9162214812
            },
            {
                "lat": 50.6158217119,
                "lng": -124.9224998533
            },
            {
                "lat": 50.6146320423,
                "lng": -124.9462975999
            },
            {
                "lat": 50.5932164197,
                "lng": -124.976044057
            },
            {
                "lat": 50.5676386386,
                "lng": -125.0170903368
            },
            {
                "lat": 50.5390854231,
                "lng": -125.0396953122
            },
            {
                "lat": 50.5188595841,
                "lng": -125.0932309318
            },
            {
                "lat": 50.5123134762,
                "lng": -125.1235664777
            },
            {
                "lat": 50.4938732014,
                "lng": -125.1265423912
            },
            {
                "lat": 50.4593736658,
                "lng": -125.1289202067
            },
            {
                "lat": 50.4409298373,
                "lng": -125.1104812193
            },
            {
                "lat": 50.4404945898,
                "lng": -125.1074756149
            },
            {
                "lat": 50.4433744729,
                "lng": -125.1018900012
            },
            {
                "lat": 50.4460489619,
                "lng": -125.0999527133
            },
            {
                "lat": 50.4452549224,
                "lng": -125.0982359064
            },
            {
                "lat": 50.4442940815,
                "lng": -125.0570987247
            },
            {
                "lat": 50.4405513815,
                "lng": -125.0442344124
            },
            {
                "lat": 50.4558032584,
                "lng": -125.0343391972
            },
            {
                "lat": 50.4688904629,
                "lng": -125.0307682293
            },
            {
                "lat": 50.483165385,
                "lng": -124.9956752461
            },
            {
                "lat": 50.4950639496,
                "lng": -124.9558179875
            },
            {
                "lat": 50.5218318546,
                "lng": -124.9129871132
            },
            {
                "lat": 50.5408703346,
                "lng": -124.8630143896
            },
            {
                "lat": 50.5777517046,
                "lng": -124.8338697684
            },
            {
                "lat": 50.6134408701,
                "lng": -124.8267282305
            },
            {
                "lat": 50.6379849312,
                "lng": -124.839280272
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 429,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 21,
            "MANAGEMENT_AREA_NAME": "Subarea 13-21",
            "LABEL": "13-21",
            "FISHERY_AREA_ID": 2126,
            "OBJECTID": 573,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 216708882.1952,
            "FEATURE_LENGTH_M": 69750.7063,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.52966725377585,
            "lng": -124.9932333105793
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5874597748,
                "lng": -126.6825553002
            },
            {
                "lat": 50.6003842456,
                "lng": -126.7073357613
            },
            {
                "lat": 50.6019255142,
                "lng": -126.7069014789
            },
            {
                "lat": 50.6026112994,
                "lng": -126.7068716149
            },
            {
                "lat": 50.6034654856,
                "lng": -126.7068479295
            },
            {
                "lat": 50.6051790231,
                "lng": -126.7075798886
            },
            {
                "lat": 50.6065482723,
                "lng": -126.7088551633
            },
            {
                "lat": 50.6086038758,
                "lng": -126.7113262142
            },
            {
                "lat": 50.6130184758,
                "lng": -126.7196122955
            },
            {
                "lat": 50.618114016,
                "lng": -126.7287682829
            },
            {
                "lat": 50.6234251925,
                "lng": -126.7355116361
            },
            {
                "lat": 50.6250384201,
                "lng": -126.7363512092
            },
            {
                "lat": 50.6270677475,
                "lng": -126.7365877468
            },
            {
                "lat": 50.6277460747,
                "lng": -126.7364954292
            },
            {
                "lat": 50.6290093215,
                "lng": -126.8243866606
            },
            {
                "lat": 50.5715711078,
                "lng": -126.7874908825
            },
            {
                "lat": 50.5716518153,
                "lng": -126.7873536784
            },
            {
                "lat": 50.5716784604,
                "lng": -126.7872384971
            },
            {
                "lat": 50.5717086301,
                "lng": -126.7871012986
            },
            {
                "lat": 50.5717319629,
                "lng": -126.7868191917
            },
            {
                "lat": 50.5717925522,
                "lng": -126.7862702216
            },
            {
                "lat": 50.5718994576,
                "lng": -126.7851261335
            },
            {
                "lat": 50.5733417011,
                "lng": -126.7777332441
            },
            {
                "lat": 50.574032252,
                "lng": -126.7733995268
            },
            {
                "lat": 50.5729939734,
                "lng": -126.7685390931
            },
            {
                "lat": 50.5694918219,
                "lng": -126.7509228396
            },
            {
                "lat": 50.5672718242,
                "lng": -126.7119214989
            },
            {
                "lat": 50.5659215696,
                "lng": -126.6963578435
            },
            {
                "lat": 50.5661925964,
                "lng": -126.692970554
            },
            {
                "lat": 50.5498052542,
                "lng": -126.678802761
            },
            {
                "lat": 50.5493998087,
                "lng": -126.6747446381
            },
            {
                "lat": 50.5492254357,
                "lng": -126.6706159087
            },
            {
                "lat": 50.5482601898,
                "lng": -126.6622691987
            },
            {
                "lat": 50.54869797,
                "lng": -126.658142513
            },
            {
                "lat": 50.5482603834,
                "lng": -126.6512985332
            },
            {
                "lat": 50.5479959286,
                "lng": -126.6434786214
            },
            {
                "lat": 50.5480842006,
                "lng": -126.6400527288
            },
            {
                "lat": 50.5474699687,
                "lng": -126.6351398186
            },
            {
                "lat": 50.5475537927,
                "lng": -126.6321559632
            },
            {
                "lat": 50.5478214528,
                "lng": -126.6303093801
            },
            {
                "lat": 50.5475533225,
                "lng": -126.6261823423
            },
            {
                "lat": 50.5463254655,
                "lng": -126.6207353506
            },
            {
                "lat": 50.5457121134,
                "lng": -126.6165234878
            },
            {
                "lat": 50.5455359274,
                "lng": -126.610900671
            },
            {
                "lat": 50.5449212066,
                "lng": -126.6086195004
            },
            {
                "lat": 50.5440445376,
                "lng": -126.6049346594
            },
            {
                "lat": 50.544045083,
                "lng": -126.601417843
            },
            {
                "lat": 50.5450973528,
                "lng": -126.5991361081
            },
            {
                "lat": 50.5462381365,
                "lng": -126.5978242453
            },
            {
                "lat": 50.5484198619,
                "lng": -126.5979996725
            },
            {
                "lat": 50.5518154124,
                "lng": -126.5979997519
            },
            {
                "lat": 50.5544545016,
                "lng": -126.5980144844
            },
            {
                "lat": 50.5536270014,
                "lng": -126.606383543
            },
            {
                "lat": 50.5558469752,
                "lng": -126.6166533866
            },
            {
                "lat": 50.5565723844,
                "lng": -126.6254190031
            },
            {
                "lat": 50.5564265468,
                "lng": -126.6317518449
            },
            {
                "lat": 50.5556910614,
                "lng": -126.6373446905
            },
            {
                "lat": 50.5571635105,
                "lng": -126.648979065
            },
            {
                "lat": 50.5604020694,
                "lng": -126.6567839616
            },
            {
                "lat": 50.5623175263,
                "lng": -126.6591412382
            },
            {
                "lat": 50.5652965089,
                "lng": -126.660354435
            },
            {
                "lat": 50.5659834303,
                "lng": -126.6627805483
            },
            {
                "lat": 50.5659900678,
                "lng": -126.6635512627
            },
            {
                "lat": 50.5734104644,
                "lng": -126.6803973338
            },
            {
                "lat": 50.5874597748,
                "lng": -126.6825553002
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 430,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 12-5",
            "LABEL": "12-5",
            "FISHERY_AREA_ID": 2485,
            "OBJECTID": 574,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 56017198.6178,
            "FEATURE_LENGTH_M": 43265.8433,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.570520016796934,
            "lng": -126.68345576783078
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5735209854,
                "lng": -127.5543444424
            },
            {
                "lat": 50.5688321965,
                "lng": -127.5543670964
            },
            {
                "lat": 50.558982982,
                "lng": -127.5620956332
            },
            {
                "lat": 50.5561102918,
                "lng": -127.5552750196
            },
            {
                "lat": 50.5551834513,
                "lng": -127.5502549242
            },
            {
                "lat": 50.5512811751,
                "lng": -127.5492476969
            },
            {
                "lat": 50.5473137781,
                "lng": -127.5399019869
            },
            {
                "lat": 50.5461576996,
                "lng": -127.5362626942
            },
            {
                "lat": 50.5429306927,
                "lng": -127.5327075723
            },
            {
                "lat": 50.5379712183,
                "lng": -127.5303118055
            },
            {
                "lat": 50.5331761708,
                "lng": -127.5253441972
            },
            {
                "lat": 50.5286257963,
                "lng": -127.5183190075
            },
            {
                "lat": 50.5266417694,
                "lng": -127.5112084035
            },
            {
                "lat": 50.5262299652,
                "lng": -127.5064161925
            },
            {
                "lat": 50.5291215702,
                "lng": -127.5007099476
            },
            {
                "lat": 50.5302812181,
                "lng": -127.4992223772
            },
            {
                "lat": 50.5342482401,
                "lng": -127.5001298768
            },
            {
                "lat": 50.53846751,
                "lng": -127.5019450639
            },
            {
                "lat": 50.5421868784,
                "lng": -127.5056693573
            },
            {
                "lat": 50.5461577388,
                "lng": -127.5077365304
            },
            {
                "lat": 50.5482251197,
                "lng": -127.5110473236
            },
            {
                "lat": 50.5520282898,
                "lng": -127.5188987205
            },
            {
                "lat": 50.5567400908,
                "lng": -127.5228647899
            },
            {
                "lat": 50.5618670825,
                "lng": -127.5232853693
            },
            {
                "lat": 50.5655060951,
                "lng": -127.5218731358
            },
            {
                "lat": 50.5676536244,
                "lng": -127.5137715841
            },
            {
                "lat": 50.5680696742,
                "lng": -127.5055001256
            },
            {
                "lat": 50.5672416585,
                "lng": -127.4973143042
            },
            {
                "lat": 50.5685654624,
                "lng": -127.4811105098
            },
            {
                "lat": 50.5696411087,
                "lng": -127.4682159029
            },
            {
                "lat": 50.5711289529,
                "lng": -127.4547353935
            },
            {
                "lat": 50.5716247685,
                "lng": -127.4453891047
            },
            {
                "lat": 50.5733603137,
                "lng": -127.4281924172
            },
            {
                "lat": 50.5726167915,
                "lng": -127.4197617754
            },
            {
                "lat": 50.5752602038,
                "lng": -127.4118199852
            },
            {
                "lat": 50.5789831334,
                "lng": -127.4088440625
            },
            {
                "lat": 50.5879138563,
                "lng": -127.4096686441
            },
            {
                "lat": 50.5965960638,
                "lng": -127.4103320281
            },
            {
                "lat": 50.6043663716,
                "lng": -127.4111561777
            },
            {
                "lat": 50.6101571198,
                "lng": -127.4127279593
            },
            {
                "lat": 50.6118088614,
                "lng": -127.4161222478
            },
            {
                "lat": 50.611728862,
                "lng": -127.4218288631
            },
            {
                "lat": 50.6098251072,
                "lng": -127.4267043282
            },
            {
                "lat": 50.6056064033,
                "lng": -127.4318317758
            },
            {
                "lat": 50.6027147809,
                "lng": -127.4366304261
            },
            {
                "lat": 50.6023830953,
                "lng": -127.4420017783
            },
            {
                "lat": 50.6018867204,
                "lng": -127.4467165552
            },
            {
                "lat": 50.5996550541,
                "lng": -127.452834857
            },
            {
                "lat": 50.5972560326,
                "lng": -127.4608537143
            },
            {
                "lat": 50.5954364437,
                "lng": -127.4716876858
            },
            {
                "lat": 50.5970920091,
                "lng": -127.4782187239
            },
            {
                "lat": 50.6010594606,
                "lng": -127.4878086254
            },
            {
                "lat": 50.6029628824,
                "lng": -127.4945905713
            },
            {
                "lat": 50.6032106123,
                "lng": -127.5047613264
            },
            {
                "lat": 50.601803035,
                "lng": -127.5111229517
            },
            {
                "lat": 50.5976715204,
                "lng": -127.5210500914
            },
            {
                "lat": 50.5920485985,
                "lng": -127.5312197405
            },
            {
                "lat": 50.5883743614,
                "lng": -127.5362463485
            },
            {
                "lat": 50.5824548245,
                "lng": -127.5431975307
            },
            {
                "lat": 50.5786968067,
                "lng": -127.5470967348
            },
            {
                "lat": 50.5747982231,
                "lng": -127.5521243345
            },
            {
                "lat": 50.5735209854,
                "lng": -127.5543444424
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 431,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 27,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 27-10",
            "LABEL": "27-10",
            "FISHERY_AREA_ID": 2510,
            "OBJECTID": 575,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 42313032.0754,
            "FEATURE_LENGTH_M": 36859.9635,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.57214454499677,
            "lng": -127.49172536761291
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.6060288171,
                "lng": -127.1290127199
            },
            {
                "lat": 50.6051491048,
                "lng": -127.1329720216
            },
            {
                "lat": 50.6033864804,
                "lng": -127.1395718484
            },
            {
                "lat": 50.6003072283,
                "lng": -127.1452939097
            },
            {
                "lat": 50.5972292256,
                "lng": -127.1558537224
            },
            {
                "lat": 50.5862276291,
                "lng": -127.1624522387
            },
            {
                "lat": 50.5787505378,
                "lng": -127.1413341846
            },
            {
                "lat": 50.5739098567,
                "lng": -127.0973355388
            },
            {
                "lat": 50.5747913694,
                "lng": -127.0524599586
            },
            {
                "lat": 50.5734713591,
                "lng": -127.0234150313
            },
            {
                "lat": 50.5664289482,
                "lng": -127.0185779356
            },
            {
                "lat": 50.5527911744,
                "lng": -127.0221023328
            },
            {
                "lat": 50.5382731989,
                "lng": -127.0212171908
            },
            {
                "lat": 50.5325511925,
                "lng": -127.0106581322
            },
            {
                "lat": 50.5343139246,
                "lng": -127.0005422358
            },
            {
                "lat": 50.5488316148,
                "lng": -126.9864579279
            },
            {
                "lat": 50.5475117194,
                "lng": -126.9719388939
            },
            {
                "lat": 50.5461926115,
                "lng": -126.9358588741
            },
            {
                "lat": 50.5501520603,
                "lng": -126.907264833
            },
            {
                "lat": 50.5510299988,
                "lng": -126.8874666528
            },
            {
                "lat": 50.5501522449,
                "lng": -126.8773497256
            },
            {
                "lat": 50.5465316908,
                "lng": -126.8733826337
            },
            {
                "lat": 50.5471844129,
                "lng": -126.8640825456
            },
            {
                "lat": 50.5484883456,
                "lng": -126.8601684588
            },
            {
                "lat": 50.5497931924,
                "lng": -126.8583755494
            },
            {
                "lat": 50.5510217738,
                "lng": -126.8562007378
            },
            {
                "lat": 50.5514337186,
                "lng": -126.8552087351
            },
            {
                "lat": 50.5516582839,
                "lng": -126.8544849494
            },
            {
                "lat": 50.551704831,
                "lng": -126.8543472907
            },
            {
                "lat": 50.5518075598,
                "lng": -126.8542786408
            },
            {
                "lat": 50.5798497725,
                "lng": -126.9054791755
            },
            {
                "lat": 50.5801578159,
                "lng": -126.912864951
            },
            {
                "lat": 50.5810692907,
                "lng": -126.9136049034
            },
            {
                "lat": 50.5855678699,
                "lng": -126.9172747393
            },
            {
                "lat": 50.5895618047,
                "lng": -126.9215170259
            },
            {
                "lat": 50.5931395368,
                "lng": -126.9253469697
            },
            {
                "lat": 50.5952185031,
                "lng": -126.9301756631
            },
            {
                "lat": 50.5962188706,
                "lng": -126.9341661686
            },
            {
                "lat": 50.596718304,
                "lng": -126.9414060547
            },
            {
                "lat": 50.5960546037,
                "lng": -126.9468999127
            },
            {
                "lat": 50.5925561273,
                "lng": -126.950477829
            },
            {
                "lat": 50.589392989,
                "lng": -126.9511405732
            },
            {
                "lat": 50.5868870273,
                "lng": -126.9508588775
            },
            {
                "lat": 50.5984991226,
                "lng": -127.0248341389
            },
            {
                "lat": 50.6040384073,
                "lng": -127.0758290014
            },
            {
                "lat": 50.6043281686,
                "lng": -127.0784989392
            },
            {
                "lat": 50.6043433623,
                "lng": -127.079422603
            },
            {
                "lat": 50.6043432311,
                "lng": -127.0801312046
            },
            {
                "lat": 50.6047857298,
                "lng": -127.0827333059
            },
            {
                "lat": 50.6052434186,
                "lng": -127.0850371669
            },
            {
                "lat": 50.6053283472,
                "lng": -127.08657839
            },
            {
                "lat": 50.6053121146,
                "lng": -127.089332962
            },
            {
                "lat": 50.6055866734,
                "lng": -127.0986551406
            },
            {
                "lat": 50.6060287149,
                "lng": -127.1105342495
            },
            {
                "lat": 50.6060292098,
                "lng": -127.1184538442
            },
            {
                "lat": 50.6060288171,
                "lng": -127.1290127199
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 432,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 19,
            "MANAGEMENT_AREA_NAME": "Subarea 12-19",
            "LABEL": "12-19",
            "FISHERY_AREA_ID": 2077,
            "OBJECTID": 576,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 78879545.918,
            "FEATURE_LENGTH_M": 53609.6951,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.578381998896425,
            "lng": -126.9962487849911
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5951918488,
                "lng": -126.5931244573
            },
            {
                "lat": 50.5953175816,
                "lng": -126.5951312015
            },
            {
                "lat": 50.5952642681,
                "lng": -126.5957567472
            },
            {
                "lat": 50.5927543785,
                "lng": -126.5957562078
            },
            {
                "lat": 50.5892905869,
                "lng": -126.5959470492
            },
            {
                "lat": 50.5836108314,
                "lng": -126.599219558
            },
            {
                "lat": 50.5825535871,
                "lng": -126.6019138006
            },
            {
                "lat": 50.5823595933,
                "lng": -126.6083680326
            },
            {
                "lat": 50.5839960582,
                "lng": -126.6105810592
            },
            {
                "lat": 50.5863069966,
                "lng": -126.6115414541
            },
            {
                "lat": 50.5868831075,
                "lng": -126.6150058712
            },
            {
                "lat": 50.5875584215,
                "lng": -126.6202998594
            },
            {
                "lat": 50.587363874,
                "lng": -126.6262663642
            },
            {
                "lat": 50.5863069279,
                "lng": -126.6311724309
            },
            {
                "lat": 50.5864981849,
                "lng": -126.6380078791
            },
            {
                "lat": 50.5855366739,
                "lng": -126.6419523547
            },
            {
                "lat": 50.5844772181,
                "lng": -126.6440734484
            },
            {
                "lat": 50.5842858554,
                "lng": -126.6464762401
            },
            {
                "lat": 50.5842858801,
                "lng": -126.6492694396
            },
            {
                "lat": 50.5841904358,
                "lng": -126.6513821709
            },
            {
                "lat": 50.5846715753,
                "lng": -126.6535032649
            },
            {
                "lat": 50.5853419579,
                "lng": -126.6547546495
            },
            {
                "lat": 50.5861127885,
                "lng": -126.6567767441
            },
            {
                "lat": 50.5848882002,
                "lng": -126.6560055837
            },
            {
                "lat": 50.5831791591,
                "lng": -126.6543505352
            },
            {
                "lat": 50.5808901918,
                "lng": -126.6513300071
            },
            {
                "lat": 50.5789064357,
                "lng": -126.6466974356
            },
            {
                "lat": 50.578365126,
                "lng": -126.6424328487
            },
            {
                "lat": 50.5787240383,
                "lng": -126.639487949
            },
            {
                "lat": 50.5783652467,
                "lng": -126.6368410049
            },
            {
                "lat": 50.5782734327,
                "lng": -126.6366957751
            },
            {
                "lat": 50.5772813629,
                "lng": -126.6351012659
            },
            {
                "lat": 50.57571803,
                "lng": -126.6352151814
            },
            {
                "lat": 50.5739752281,
                "lng": -126.6358792419
            },
            {
                "lat": 50.572353715,
                "lng": -126.638405143
            },
            {
                "lat": 50.5713313185,
                "lng": -126.639068662
            },
            {
                "lat": 50.5700074812,
                "lng": -126.6391293251
            },
            {
                "lat": 50.5677828626,
                "lng": -126.6393051916
            },
            {
                "lat": 50.5651394573,
                "lng": -126.640144203
            },
            {
                "lat": 50.56333615,
                "lng": -126.6411662986
            },
            {
                "lat": 50.5621336329,
                "lng": -126.6434555566
            },
            {
                "lat": 50.5611729376,
                "lng": -126.6461559232
            },
            {
                "lat": 50.5605733479,
                "lng": -126.6501844369
            },
            {
                "lat": 50.560993581,
                "lng": -126.653312378
            },
            {
                "lat": 50.5618933979,
                "lng": -126.6556547359
            },
            {
                "lat": 50.5636361616,
                "lng": -126.6574022324
            },
            {
                "lat": 50.5647774376,
                "lng": -126.6587826675
            },
            {
                "lat": 50.5652965089,
                "lng": -126.660354435
            },
            {
                "lat": 50.5623175263,
                "lng": -126.6591412382
            },
            {
                "lat": 50.5604020694,
                "lng": -126.6567839616
            },
            {
                "lat": 50.5571635105,
                "lng": -126.648979065
            },
            {
                "lat": 50.5556910614,
                "lng": -126.6373446905
            },
            {
                "lat": 50.5564265468,
                "lng": -126.6317518449
            },
            {
                "lat": 50.5565723844,
                "lng": -126.6254190031
            },
            {
                "lat": 50.5558469752,
                "lng": -126.6166533866
            },
            {
                "lat": 50.5536270014,
                "lng": -126.606383543
            },
            {
                "lat": 50.5544545016,
                "lng": -126.5980144844
            },
            {
                "lat": 50.5582544401,
                "lng": -126.5954816585
            },
            {
                "lat": 50.5837748596,
                "lng": -126.585487337
            },
            {
                "lat": 50.5845257858,
                "lng": -126.5864102152
            },
            {
                "lat": 50.585700437,
                "lng": -126.588860243
            },
            {
                "lat": 50.5874096187,
                "lng": -126.5914083927
            },
            {
                "lat": 50.5894549846,
                "lng": -126.5936582487
            },
            {
                "lat": 50.5911339184,
                "lng": -126.593963901
            },
            {
                "lat": 50.593246683,
                "lng": -126.5939935529
            },
            {
                "lat": 50.5951918488,
                "lng": -126.5931244573
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 433,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 577,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 12918100.4783,
            "FEATURE_LENGTH_M": 21233.3201,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.57667192766362,
            "lng": -126.6283590534152
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5870774808,
                "lng": -126.6627432102
            },
            {
                "lat": 50.5890228097,
                "lng": -126.6651458506
            },
            {
                "lat": 50.5876960315,
                "lng": -126.679916473
            },
            {
                "lat": 50.5873457269,
                "lng": -126.6814646517
            },
            {
                "lat": 50.5858110685,
                "lng": -126.6714939425
            },
            {
                "lat": 50.5860371006,
                "lng": -126.6678014315
            },
            {
                "lat": 50.5870774808,
                "lng": -126.6627432102
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 434,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 578,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 266480.3306,
            "FEATURE_LENGTH_M": 2812.1819,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.5871525284,
            "lng": -126.67018696710001
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5873457269,
                "lng": -126.6814646517
            },
            {
                "lat": 50.5874597748,
                "lng": -126.6825553002
            },
            {
                "lat": 50.5734104644,
                "lng": -126.6803973338
            },
            {
                "lat": 50.5659900678,
                "lng": -126.6635512627
            },
            {
                "lat": 50.5659834303,
                "lng": -126.6627805483
            },
            {
                "lat": 50.5652965089,
                "lng": -126.660354435
            },
            {
                "lat": 50.5647774376,
                "lng": -126.6587826675
            },
            {
                "lat": 50.5636361616,
                "lng": -126.6574022324
            },
            {
                "lat": 50.5618933979,
                "lng": -126.6556547359
            },
            {
                "lat": 50.560993581,
                "lng": -126.653312378
            },
            {
                "lat": 50.5605733479,
                "lng": -126.6501844369
            },
            {
                "lat": 50.5611729376,
                "lng": -126.6461559232
            },
            {
                "lat": 50.5621336329,
                "lng": -126.6434555566
            },
            {
                "lat": 50.56333615,
                "lng": -126.6411662986
            },
            {
                "lat": 50.5651394573,
                "lng": -126.640144203
            },
            {
                "lat": 50.5677828626,
                "lng": -126.6393051916
            },
            {
                "lat": 50.5700074812,
                "lng": -126.6391293251
            },
            {
                "lat": 50.5713313185,
                "lng": -126.639068662
            },
            {
                "lat": 50.572353715,
                "lng": -126.638405143
            },
            {
                "lat": 50.5739752281,
                "lng": -126.6358792419
            },
            {
                "lat": 50.57571803,
                "lng": -126.6352151814
            },
            {
                "lat": 50.5772813629,
                "lng": -126.6351012659
            },
            {
                "lat": 50.5782734327,
                "lng": -126.6366957751
            },
            {
                "lat": 50.5783652467,
                "lng": -126.6368410049
            },
            {
                "lat": 50.5787240383,
                "lng": -126.639487949
            },
            {
                "lat": 50.578365126,
                "lng": -126.6424328487
            },
            {
                "lat": 50.5789064357,
                "lng": -126.6466974356
            },
            {
                "lat": 50.5808901918,
                "lng": -126.6513300071
            },
            {
                "lat": 50.5831791591,
                "lng": -126.6543505352
            },
            {
                "lat": 50.5848882002,
                "lng": -126.6560055837
            },
            {
                "lat": 50.5861127885,
                "lng": -126.6567767441
            },
            {
                "lat": 50.5865932443,
                "lng": -126.6594693587
            },
            {
                "lat": 50.5864981973,
                "lng": -126.6610108055
            },
            {
                "lat": 50.5870774808,
                "lng": -126.6627432102
            },
            {
                "lat": 50.5860371006,
                "lng": -126.6678014315
            },
            {
                "lat": 50.5858110685,
                "lng": -126.6714939425
            },
            {
                "lat": 50.5873457269,
                "lng": -126.6814646517
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 435,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 20,
            "MANAGEMENT_AREA_NAME": "Subarea 12-20",
            "LABEL": "12-20",
            "FISHERY_AREA_ID": 2078,
            "OBJECTID": 579,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 6196286.311,
            "FEATURE_LENGTH_M": 10454.1913,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.57472052736757,
            "lng": -126.65308289887027
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5482601898,
                "lng": -126.6622691987
            },
            {
                "lat": 50.5471155844,
                "lng": -126.6536634783
            },
            {
                "lat": 50.5460624366,
                "lng": -126.6461174307
            },
            {
                "lat": 50.5463252931,
                "lng": -126.6433947601
            },
            {
                "lat": 50.5463255964,
                "lng": -126.6393501049
            },
            {
                "lat": 50.5465014008,
                "lng": -126.6344379341
            },
            {
                "lat": 50.5469435497,
                "lng": -126.630745333
            },
            {
                "lat": 50.5458866933,
                "lng": -126.6261829935
            },
            {
                "lat": 50.5440450405,
                "lng": -126.6193319548
            },
            {
                "lat": 50.5436931992,
                "lng": -126.6143271961
            },
            {
                "lat": 50.543693135,
                "lng": -126.6110768459
            },
            {
                "lat": 50.5422890681,
                "lng": -126.6089703751
            },
            {
                "lat": 50.5384255813,
                "lng": -126.6076584018
            },
            {
                "lat": 50.537544063,
                "lng": -126.6083610697
            },
            {
                "lat": 50.5347359766,
                "lng": -126.6085357425
            },
            {
                "lat": 50.5330689126,
                "lng": -126.6074829626
            },
            {
                "lat": 50.5286488537,
                "lng": -126.6056602895
            },
            {
                "lat": 50.5290831691,
                "lng": -126.6011661563
            },
            {
                "lat": 50.5290830014,
                "lng": -126.5832747786
            },
            {
                "lat": 50.5288738218,
                "lng": -126.5670469085
            },
            {
                "lat": 50.5301201069,
                "lng": -126.5564422279
            },
            {
                "lat": 50.529704869,
                "lng": -126.5468743704
            },
            {
                "lat": 50.52970493,
                "lng": -126.5325161744
            },
            {
                "lat": 50.5270003645,
                "lng": -126.5173340397
            },
            {
                "lat": 50.5259632115,
                "lng": -126.4907001856
            },
            {
                "lat": 50.5245057852,
                "lng": -126.4617844744
            },
            {
                "lat": 50.523674612,
                "lng": -126.4257969957
            },
            {
                "lat": 50.5240901965,
                "lng": -126.4191356189
            },
            {
                "lat": 50.5274162902,
                "lng": -126.4158098405
            },
            {
                "lat": 50.5309563508,
                "lng": -126.4066545002
            },
            {
                "lat": 50.5346989342,
                "lng": -126.4012448676
            },
            {
                "lat": 50.5355300223,
                "lng": -126.3941724595
            },
            {
                "lat": 50.5340759205,
                "lng": -126.3825233409
            },
            {
                "lat": 50.5309564888,
                "lng": -126.3704599691
            },
            {
                "lat": 50.5263785564,
                "lng": -126.3573530478
            },
            {
                "lat": 50.524406818,
                "lng": -126.3496473478
            },
            {
                "lat": 50.5248643989,
                "lng": -126.3444521209
            },
            {
                "lat": 50.5263485531,
                "lng": -126.3404304088
            },
            {
                "lat": 50.5598948229,
                "lng": -126.2883522039
            },
            {
                "lat": 50.5658192626,
                "lng": -126.2861710424
            },
            {
                "lat": 50.5674362888,
                "lng": -126.2896804507
            },
            {
                "lat": 50.5703315405,
                "lng": -126.2960207043
            },
            {
                "lat": 50.5724186619,
                "lng": -126.3028948586
            },
            {
                "lat": 50.5785745138,
                "lng": -126.3167499907
            },
            {
                "lat": 50.5847319769,
                "lng": -126.3306045143
            },
            {
                "lat": 50.5862696231,
                "lng": -126.3423989986
            },
            {
                "lat": 50.5790859139,
                "lng": -126.3454823967
            },
            {
                "lat": 50.5770331892,
                "lng": -126.364982697
            },
            {
                "lat": 50.5770334992,
                "lng": -126.3788374894
            },
            {
                "lat": 50.5744699044,
                "lng": -126.4009018323
            },
            {
                "lat": 50.5693362179,
                "lng": -126.4188611481
            },
            {
                "lat": 50.5662579477,
                "lng": -126.4306564632
            },
            {
                "lat": 50.5619275604,
                "lng": -126.4251171543
            },
            {
                "lat": 50.5591092,
                "lng": -126.4247128108
            },
            {
                "lat": 50.5586512658,
                "lng": -126.4246449479
            },
            {
                "lat": 50.5537877989,
                "lng": -126.422927703
            },
            {
                "lat": 50.5492100317,
                "lng": -126.4255071564
            },
            {
                "lat": 50.546925047,
                "lng": -126.4303666491
            },
            {
                "lat": 50.5463524442,
                "lng": -126.4398038085
            },
            {
                "lat": 50.5495000386,
                "lng": -126.4506764245
            },
            {
                "lat": 50.5557897153,
                "lng": -126.4572522814
            },
            {
                "lat": 50.5569727956,
                "lng": -126.4593047098
            },
            {
                "lat": 50.5565676764,
                "lng": -126.4658506871
            },
            {
                "lat": 50.5552171487,
                "lng": -126.4664076898
            },
            {
                "lat": 50.5520703881,
                "lng": -126.4704131463
            },
            {
                "lat": 50.5509259647,
                "lng": -126.4772796389
            },
            {
                "lat": 50.5503544913,
                "lng": -126.4852911242
            },
            {
                "lat": 50.5500679158,
                "lng": -126.5001679626
            },
            {
                "lat": 50.5495000452,
                "lng": -126.5121762979
            },
            {
                "lat": 50.549499857,
                "lng": -126.5187607061
            },
            {
                "lat": 50.5494999797,
                "lng": -126.5259098539
            },
            {
                "lat": 50.5474930113,
                "lng": -126.5287699577
            },
            {
                "lat": 50.5452078445,
                "lng": -126.5324858945
            },
            {
                "lat": 50.5432055703,
                "lng": -126.5367818277
            },
            {
                "lat": 50.5437769803,
                "lng": -126.5419235184
            },
            {
                "lat": 50.5454944484,
                "lng": -126.5473633648
            },
            {
                "lat": 50.5477793544,
                "lng": -126.5533673696
            },
            {
                "lat": 50.548351238,
                "lng": -126.5593798286
            },
            {
                "lat": 50.5480648488,
                "lng": -126.5682449527
            },
            {
                "lat": 50.5466386542,
                "lng": -126.5751109921
            },
            {
                "lat": 50.5449216475,
                "lng": -126.5776830608
            },
            {
                "lat": 50.5423473011,
                "lng": -126.5805429856
            },
            {
                "lat": 50.5417748304,
                "lng": -126.5871198154
            },
            {
                "lat": 50.5437767981,
                "lng": -126.5931322304
            },
            {
                "lat": 50.5451808383,
                "lng": -126.5935133395
            },
            {
                "lat": 50.5462381365,
                "lng": -126.5978242453
            },
            {
                "lat": 50.5450973528,
                "lng": -126.5991361081
            },
            {
                "lat": 50.544045083,
                "lng": -126.601417843
            },
            {
                "lat": 50.5440445376,
                "lng": -126.6049346594
            },
            {
                "lat": 50.5449212066,
                "lng": -126.6086195004
            },
            {
                "lat": 50.5455359274,
                "lng": -126.610900671
            },
            {
                "lat": 50.5457121134,
                "lng": -126.6165234878
            },
            {
                "lat": 50.5463254655,
                "lng": -126.6207353506
            },
            {
                "lat": 50.5475533225,
                "lng": -126.6261823423
            },
            {
                "lat": 50.5478214528,
                "lng": -126.6303093801
            },
            {
                "lat": 50.5475537927,
                "lng": -126.6321559632
            },
            {
                "lat": 50.5474699687,
                "lng": -126.6351398186
            },
            {
                "lat": 50.5480842006,
                "lng": -126.6400527288
            },
            {
                "lat": 50.5479959286,
                "lng": -126.6434786214
            },
            {
                "lat": 50.5482603834,
                "lng": -126.6512985332
            },
            {
                "lat": 50.54869797,
                "lng": -126.658142513
            },
            {
                "lat": 50.5482601898,
                "lng": -126.6622691987
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 436,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 580,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 75760546.5226,
            "FEATURE_LENGTH_M": 63658.1139,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.54681621666568,
            "lng": -126.51158913284512
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5788844688,
                "lng": -126.1989294693
            },
            {
                "lat": 50.5772208885,
                "lng": -126.207779529
            },
            {
                "lat": 50.5717169801,
                "lng": -126.219559041
            },
            {
                "lat": 50.5680731929,
                "lng": -126.2227862156
            },
            {
                "lat": 50.545467399,
                "lng": -126.2598640365
            },
            {
                "lat": 50.5405009823,
                "lng": -126.2614595284
            },
            {
                "lat": 50.5378608947,
                "lng": -126.2668226531
            },
            {
                "lat": 50.5375290848,
                "lng": -126.2677390038
            },
            {
                "lat": 50.5367167365,
                "lng": -126.2699888883
            },
            {
                "lat": 50.5361598779,
                "lng": -126.272453707
            },
            {
                "lat": 50.5358117018,
                "lng": -126.2747494845
            },
            {
                "lat": 50.5353576487,
                "lng": -126.2769237355
            },
            {
                "lat": 50.5348316602,
                "lng": -126.2773130396
            },
            {
                "lat": 50.5270772042,
                "lng": -126.2843481566
            },
            {
                "lat": 50.5246011542,
                "lng": -126.2784653409
            },
            {
                "lat": 50.5242879113,
                "lng": -126.2706984289
            },
            {
                "lat": 50.5238267312,
                "lng": -126.2647015789
            },
            {
                "lat": 50.5231367684,
                "lng": -126.2550270653
            },
            {
                "lat": 50.5238267833,
                "lng": -126.2508773528
            },
            {
                "lat": 50.5249783077,
                "lng": -126.2471930276
            },
            {
                "lat": 50.5268211082,
                "lng": -126.2418895125
            },
            {
                "lat": 50.52728205,
                "lng": -126.234054189
            },
            {
                "lat": 50.5265920423,
                "lng": -126.2266847685
            },
            {
                "lat": 50.5263638089,
                "lng": -126.2195356643
            },
            {
                "lat": 50.5268210011,
                "lng": -126.214004669
            },
            {
                "lat": 50.5268213103,
                "lng": -126.2073214147
            },
            {
                "lat": 50.5268209519,
                "lng": -126.2020254255
            },
            {
                "lat": 50.5249782454,
                "lng": -126.1962584103
            },
            {
                "lat": 50.5222132345,
                "lng": -126.1937252043
            },
            {
                "lat": 50.5162240139,
                "lng": -126.1914219771
            },
            {
                "lat": 50.5118442104,
                "lng": -126.1916511294
            },
            {
                "lat": 50.51000244,
                "lng": -126.1886599702
            },
            {
                "lat": 50.5104603452,
                "lng": -126.1838148061
            },
            {
                "lat": 50.5127637775,
                "lng": -126.1782909443
            },
            {
                "lat": 50.5148399329,
                "lng": -126.1732179497
            },
            {
                "lat": 50.5187568299,
                "lng": -126.16906752
            },
            {
                "lat": 50.5222135233,
                "lng": -126.1683803057
            },
            {
                "lat": 50.5256685922,
                "lng": -126.1683808658
            },
            {
                "lat": 50.5309716368,
                "lng": -126.170218505
            },
            {
                "lat": 50.5360412133,
                "lng": -126.1722947297
            },
            {
                "lat": 50.5397257789,
                "lng": -126.1734468306
            },
            {
                "lat": 50.5436445431,
                "lng": -126.1759789879
            },
            {
                "lat": 50.5480229209,
                "lng": -126.1789775336
            },
            {
                "lat": 50.5528642594,
                "lng": -126.1810537242
            },
            {
                "lat": 50.5581622403,
                "lng": -126.1826627201
            },
            {
                "lat": 50.5625423059,
                "lng": -126.1838146544
            },
            {
                "lat": 50.5653082271,
                "lng": -126.1845091399
            },
            {
                "lat": 50.5673792955,
                "lng": -126.1870422113
            },
            {
                "lat": 50.5684931798,
                "lng": -126.1907578943
            },
            {
                "lat": 50.5788844688,
                "lng": -126.1989294693
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 437,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 22,
            "MANAGEMENT_AREA_NAME": "Subarea 12-22",
            "LABEL": "12-22",
            "FISHERY_AREA_ID": 2080,
            "OBJECTID": 581,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 30428379.5523,
            "FEATURE_LENGTH_M": 25972.5428,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.53674787728401,
            "lng": -126.21711500818601
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5482601898,
                "lng": -126.6622691987
            },
            {
                "lat": 50.5492254357,
                "lng": -126.6706159087
            },
            {
                "lat": 50.5493998087,
                "lng": -126.6747446381
            },
            {
                "lat": 50.5498052542,
                "lng": -126.678802761
            },
            {
                "lat": 50.5661925964,
                "lng": -126.692970554
            },
            {
                "lat": 50.5659215696,
                "lng": -126.6963578435
            },
            {
                "lat": 50.5672718242,
                "lng": -126.7119214989
            },
            {
                "lat": 50.5694918219,
                "lng": -126.7509228396
            },
            {
                "lat": 50.5729939734,
                "lng": -126.7685390931
            },
            {
                "lat": 50.574032252,
                "lng": -126.7733995268
            },
            {
                "lat": 50.5733417011,
                "lng": -126.7777332441
            },
            {
                "lat": 50.5718994576,
                "lng": -126.7851261335
            },
            {
                "lat": 50.5717925522,
                "lng": -126.7862702216
            },
            {
                "lat": 50.5717319629,
                "lng": -126.7868191917
            },
            {
                "lat": 50.5717086301,
                "lng": -126.7871012986
            },
            {
                "lat": 50.5716784604,
                "lng": -126.7872384971
            },
            {
                "lat": 50.5716518153,
                "lng": -126.7873536784
            },
            {
                "lat": 50.5715711078,
                "lng": -126.7874908825
            },
            {
                "lat": 50.5437505098,
                "lng": -126.7825321599
            },
            {
                "lat": 50.5434571138,
                "lng": -126.782729994
            },
            {
                "lat": 50.5430717933,
                "lng": -126.7831423979
            },
            {
                "lat": 50.542934042,
                "lng": -126.7838052533
            },
            {
                "lat": 50.5428201042,
                "lng": -126.78491143
            },
            {
                "lat": 50.5425188672,
                "lng": -126.7851858598
            },
            {
                "lat": 50.5420493379,
                "lng": -126.7855147071
            },
            {
                "lat": 50.541443113,
                "lng": -126.7859567473
            },
            {
                "lat": 50.5383264884,
                "lng": -126.7892909255
            },
            {
                "lat": 50.5324290473,
                "lng": -126.7938688925
            },
            {
                "lat": 50.5244555048,
                "lng": -126.7785415725
            },
            {
                "lat": 50.5085183509,
                "lng": -126.7313317486
            },
            {
                "lat": 50.4944160462,
                "lng": -126.6896441958
            },
            {
                "lat": 50.4803123448,
                "lng": -126.6534657092
            },
            {
                "lat": 50.4674378121,
                "lng": -126.6172942207
            },
            {
                "lat": 50.4594691168,
                "lng": -126.5878678467
            },
            {
                "lat": 50.456401689,
                "lng": -126.5682455999
            },
            {
                "lat": 50.463760314,
                "lng": -126.5388191492
            },
            {
                "lat": 50.4662127705,
                "lng": -126.5216444449
            },
            {
                "lat": 50.4637602379,
                "lng": -126.4873122508
            },
            {
                "lat": 50.4594688201,
                "lng": -126.4450081073
            },
            {
                "lat": 50.4631466189,
                "lng": -126.4161918425
            },
            {
                "lat": 50.4711152651,
                "lng": -126.3916702176
            },
            {
                "lat": 50.4741815264,
                "lng": -126.3738866127
            },
            {
                "lat": 50.4746437593,
                "lng": -126.3509830862
            },
            {
                "lat": 50.4796029393,
                "lng": -126.3504481284
            },
            {
                "lat": 50.4817577205,
                "lng": -126.3498921788
            },
            {
                "lat": 50.4984555652,
                "lng": -126.3498921435
            },
            {
                "lat": 50.5202563182,
                "lng": -126.3498920432
            },
            {
                "lat": 50.524406818,
                "lng": -126.3496473478
            },
            {
                "lat": 50.5263785564,
                "lng": -126.3573530478
            },
            {
                "lat": 50.5309564888,
                "lng": -126.3704599691
            },
            {
                "lat": 50.5340759205,
                "lng": -126.3825233409
            },
            {
                "lat": 50.5355300223,
                "lng": -126.3941724595
            },
            {
                "lat": 50.5346989342,
                "lng": -126.4012448676
            },
            {
                "lat": 50.5309563508,
                "lng": -126.4066545002
            },
            {
                "lat": 50.5274162902,
                "lng": -126.4158098405
            },
            {
                "lat": 50.5240901965,
                "lng": -126.4191356189
            },
            {
                "lat": 50.523674612,
                "lng": -126.4257969957
            },
            {
                "lat": 50.5245057852,
                "lng": -126.4617844744
            },
            {
                "lat": 50.5259632115,
                "lng": -126.4907001856
            },
            {
                "lat": 50.5270003645,
                "lng": -126.5173340397
            },
            {
                "lat": 50.52970493,
                "lng": -126.5325161744
            },
            {
                "lat": 50.529704869,
                "lng": -126.5468743704
            },
            {
                "lat": 50.5301201069,
                "lng": -126.5564422279
            },
            {
                "lat": 50.5288738218,
                "lng": -126.5670469085
            },
            {
                "lat": 50.5290830014,
                "lng": -126.5832747786
            },
            {
                "lat": 50.5290831691,
                "lng": -126.6011661563
            },
            {
                "lat": 50.5286488537,
                "lng": -126.6056602895
            },
            {
                "lat": 50.52845791,
                "lng": -126.6076201425
            },
            {
                "lat": 50.5273289926,
                "lng": -126.6097110613
            },
            {
                "lat": 50.527419711,
                "lng": -126.6135329801
            },
            {
                "lat": 50.5353894741,
                "lng": -126.6390917412
            },
            {
                "lat": 50.5439192953,
                "lng": -126.6468507645
            },
            {
                "lat": 50.5460624366,
                "lng": -126.6461174307
            },
            {
                "lat": 50.5471155844,
                "lng": -126.6536634783
            },
            {
                "lat": 50.5482601898,
                "lng": -126.6622691987
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 438,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 12-3",
            "LABEL": "12-3",
            "FISHERY_AREA_ID": 2062,
            "OBJECTID": 582,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 214935271.3717,
            "FEATURE_LENGTH_M": 78552.4814,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.52782625929067,
            "lng": -126.60318801155069
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5717169801,
                "lng": -126.219559041
            },
            {
                "lat": 50.5754243248,
                "lng": -126.2222063407
            },
            {
                "lat": 50.5725710066,
                "lng": -126.2451165871
            },
            {
                "lat": 50.5712964292,
                "lng": -126.244086489
            },
            {
                "lat": 50.5664523319,
                "lng": -126.2451318374
            },
            {
                "lat": 50.5609520056,
                "lng": -126.2501150593
            },
            {
                "lat": 50.5536152875,
                "lng": -126.2570492461
            },
            {
                "lat": 50.5485084256,
                "lng": -126.2611160002
            },
            {
                "lat": 50.5444450127,
                "lng": -126.2662193486
            },
            {
                "lat": 50.5407792728,
                "lng": -126.2679215173
            },
            {
                "lat": 50.5375290848,
                "lng": -126.2677390038
            },
            {
                "lat": 50.5378608947,
                "lng": -126.2668226531
            },
            {
                "lat": 50.5405009823,
                "lng": -126.2614595284
            },
            {
                "lat": 50.545467399,
                "lng": -126.2598640365
            },
            {
                "lat": 50.5680731929,
                "lng": -126.2227862156
            },
            {
                "lat": 50.5717169801,
                "lng": -126.219559041
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 439,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 583,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 3696894.5203,
            "FEATURE_LENGTH_M": 11694.6115,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.55668185066251,
            "lng": -126.24854699656875
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5715711078,
                "lng": -126.7874908825
            },
            {
                "lat": 50.5518075598,
                "lng": -126.8542786408
            },
            {
                "lat": 50.551704831,
                "lng": -126.8543472907
            },
            {
                "lat": 50.5516582839,
                "lng": -126.8544849494
            },
            {
                "lat": 50.5514337186,
                "lng": -126.8552087351
            },
            {
                "lat": 50.5510217738,
                "lng": -126.8562007378
            },
            {
                "lat": 50.5497931924,
                "lng": -126.8583755494
            },
            {
                "lat": 50.5484883456,
                "lng": -126.8601684588
            },
            {
                "lat": 50.5471844129,
                "lng": -126.8640825456
            },
            {
                "lat": 50.5465316908,
                "lng": -126.8733826337
            },
            {
                "lat": 50.5463671911,
                "lng": -126.8812105172
            },
            {
                "lat": 50.5439191851,
                "lng": -126.8851242888
            },
            {
                "lat": 50.5398407955,
                "lng": -126.8885502277
            },
            {
                "lat": 50.5346219683,
                "lng": -126.887244963
            },
            {
                "lat": 50.5282588036,
                "lng": -126.8799056342
            },
            {
                "lat": 50.5243447802,
                "lng": -126.8719102595
            },
            {
                "lat": 50.5238539352,
                "lng": -126.8621218927
            },
            {
                "lat": 50.5277711045,
                "lng": -126.8529886575
            },
            {
                "lat": 50.5321730212,
                "lng": -126.8425521371
            },
            {
                "lat": 50.5370667281,
                "lng": -126.8387990677
            },
            {
                "lat": 50.5414730116,
                "lng": -126.8339006599
            },
            {
                "lat": 50.5411451462,
                "lng": -126.829986153
            },
            {
                "lat": 50.5380483041,
                "lng": -126.8224868314
            },
            {
                "lat": 50.5373951027,
                "lng": -126.8169409321
            },
            {
                "lat": 50.538372069,
                "lng": -126.8094334015
            },
            {
                "lat": 50.5365790377,
                "lng": -126.8032374435
            },
            {
                "lat": 50.5324290473,
                "lng": -126.7938688925
            },
            {
                "lat": 50.5383264884,
                "lng": -126.7892909255
            },
            {
                "lat": 50.541443113,
                "lng": -126.7859567473
            },
            {
                "lat": 50.5420493379,
                "lng": -126.7855147071
            },
            {
                "lat": 50.5425188672,
                "lng": -126.7851858598
            },
            {
                "lat": 50.5428201042,
                "lng": -126.78491143
            },
            {
                "lat": 50.542934042,
                "lng": -126.7838052533
            },
            {
                "lat": 50.5430717933,
                "lng": -126.7831423979
            },
            {
                "lat": 50.5434571138,
                "lng": -126.782729994
            },
            {
                "lat": 50.5437505098,
                "lng": -126.7825321599
            },
            {
                "lat": 50.5715711078,
                "lng": -126.7874908825
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 440,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 12-4",
            "LABEL": "12-4",
            "FISHERY_AREA_ID": 2063,
            "OBJECTID": 584,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 19429085.0412,
            "FEATURE_LENGTH_M": 21766.8333,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.54261612501081,
            "lng": -126.83159034433515
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5725710066,
                "lng": -126.2451165871
            },
            {
                "lat": 50.5718609483,
                "lng": -126.2508086271
            },
            {
                "lat": 50.5731880192,
                "lng": -126.2561183485
            },
            {
                "lat": 50.5740208456,
                "lng": -126.2620929533
            },
            {
                "lat": 50.5733572099,
                "lng": -126.2700576269
            },
            {
                "lat": 50.5721928962,
                "lng": -126.2738723783
            },
            {
                "lat": 50.5710487078,
                "lng": -126.2754058937
            },
            {
                "lat": 50.5702289011,
                "lng": -126.2754141282
            },
            {
                "lat": 50.5688366224,
                "lng": -126.2750931174
            },
            {
                "lat": 50.568325802,
                "lng": -126.2752603593
            },
            {
                "lat": 50.5658192626,
                "lng": -126.2861710424
            },
            {
                "lat": 50.5598948229,
                "lng": -126.2883522039
            },
            {
                "lat": 50.5263485531,
                "lng": -126.3404304088
            },
            {
                "lat": 50.5248643989,
                "lng": -126.3444521209
            },
            {
                "lat": 50.524406818,
                "lng": -126.3496473478
            },
            {
                "lat": 50.5202563182,
                "lng": -126.3498920432
            },
            {
                "lat": 50.4984555652,
                "lng": -126.3498921435
            },
            {
                "lat": 50.4817577205,
                "lng": -126.3498921788
            },
            {
                "lat": 50.4796029393,
                "lng": -126.3504481284
            },
            {
                "lat": 50.4746437593,
                "lng": -126.3509830862
            },
            {
                "lat": 50.4721640752,
                "lng": -126.3431936784
            },
            {
                "lat": 50.4711034258,
                "lng": -126.3316879232
            },
            {
                "lat": 50.4700430374,
                "lng": -126.3157495384
            },
            {
                "lat": 50.4652598135,
                "lng": -126.3019405826
            },
            {
                "lat": 50.4608345032,
                "lng": -126.2895502741
            },
            {
                "lat": 50.4583547225,
                "lng": -126.2787470888
            },
            {
                "lat": 50.4595949964,
                "lng": -126.2688292943
            },
            {
                "lat": 50.4636691364,
                "lng": -126.2543185356
            },
            {
                "lat": 50.4670336014,
                "lng": -126.2452850203
            },
            {
                "lat": 50.466323703,
                "lng": -126.236258782
            },
            {
                "lat": 50.4647299839,
                "lng": -126.2284693184
            },
            {
                "lat": 50.4629253403,
                "lng": -126.2228247006
            },
            {
                "lat": 50.466972205,
                "lng": -126.2227248954
            },
            {
                "lat": 50.46923395,
                "lng": -126.2228470379
            },
            {
                "lat": 50.4731211745,
                "lng": -126.221970385
            },
            {
                "lat": 50.498440426,
                "lng": -126.2163308028
            },
            {
                "lat": 50.5020179493,
                "lng": -126.2152100122
            },
            {
                "lat": 50.5033716341,
                "lng": -126.2204673983
            },
            {
                "lat": 50.5054857518,
                "lng": -126.2260973654
            },
            {
                "lat": 50.5082471349,
                "lng": -126.2324902238
            },
            {
                "lat": 50.5106848017,
                "lng": -126.2388301439
            },
            {
                "lat": 50.5114436849,
                "lng": -126.2433780801
            },
            {
                "lat": 50.5125429053,
                "lng": -126.249824466
            },
            {
                "lat": 50.5204844983,
                "lng": -126.2686844691
            },
            {
                "lat": 50.5209271975,
                "lng": -126.2738880582
            },
            {
                "lat": 50.5246011542,
                "lng": -126.2784653409
            },
            {
                "lat": 50.5270772042,
                "lng": -126.2843481566
            },
            {
                "lat": 50.5348316602,
                "lng": -126.2773130396
            },
            {
                "lat": 50.5353576487,
                "lng": -126.2769237355
            },
            {
                "lat": 50.5358117018,
                "lng": -126.2747494845
            },
            {
                "lat": 50.5361598779,
                "lng": -126.272453707
            },
            {
                "lat": 50.5367167365,
                "lng": -126.2699888883
            },
            {
                "lat": 50.5375290848,
                "lng": -126.2677390038
            },
            {
                "lat": 50.5407792728,
                "lng": -126.2679215173
            },
            {
                "lat": 50.5444450127,
                "lng": -126.2662193486
            },
            {
                "lat": 50.5485084256,
                "lng": -126.2611160002
            },
            {
                "lat": 50.5536152875,
                "lng": -126.2570492461
            },
            {
                "lat": 50.5609520056,
                "lng": -126.2501150593
            },
            {
                "lat": 50.5664523319,
                "lng": -126.2451318374
            },
            {
                "lat": 50.5712964292,
                "lng": -126.244086489
            },
            {
                "lat": 50.5725710066,
                "lng": -126.2451165871
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 441,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 12-2",
            "LABEL": "12-2",
            "FISHERY_AREA_ID": 2061,
            "OBJECTID": 585,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 72485684.1095,
            "FEATURE_LENGTH_M": 41617.2071,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.51939992802624,
            "lng": -126.27258583999503
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4699590576,
                "lng": -125.4019477172
            },
            {
                "lat": 50.4700547468,
                "lng": -125.3992836555
            },
            {
                "lat": 50.4691619106,
                "lng": -125.3982614448
            },
            {
                "lat": 50.4677776493,
                "lng": -125.3975444224
            },
            {
                "lat": 50.4712558823,
                "lng": -125.3384089572
            },
            {
                "lat": 50.476436373,
                "lng": -125.3341598155
            },
            {
                "lat": 50.4809957059,
                "lng": -125.3338546452
            },
            {
                "lat": 50.4898102537,
                "lng": -125.3378063871
            },
            {
                "lat": 50.5135229159,
                "lng": -125.342063244
            },
            {
                "lat": 50.5442239852,
                "lng": -125.3441930027
            },
            {
                "lat": 50.56489601,
                "lng": -125.3551332456
            },
            {
                "lat": 50.5706710461,
                "lng": -125.3618235291
            },
            {
                "lat": 50.5688472216,
                "lng": -125.3688118835
            },
            {
                "lat": 50.557598028,
                "lng": -125.3788445239
            },
            {
                "lat": 50.5427059877,
                "lng": -125.3983000467
            },
            {
                "lat": 50.5326735116,
                "lng": -125.4083325932
            },
            {
                "lat": 50.5168653318,
                "lng": -125.4034725884
            },
            {
                "lat": 50.5040966492,
                "lng": -125.3876649596
            },
            {
                "lat": 50.4937627847,
                "lng": -125.3831025652
            },
            {
                "lat": 50.4861641585,
                "lng": -125.3870545012
            },
            {
                "lat": 50.4794774478,
                "lng": -125.3961706265
            },
            {
                "lat": 50.4699590576,
                "lng": -125.4019477172
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 442,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 24,
            "MANAGEMENT_AREA_NAME": "Subarea 13-24",
            "LABEL": "13-24",
            "FISHERY_AREA_ID": 2129,
            "OBJECTID": 586,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 39190691.4704,
            "FEATURE_LENGTH_M": 29434.9023,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.50640525976819,
            "lng": -125.37537191235
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.558982982,
                "lng": -127.5620956332
            },
            {
                "lat": 50.5544816974,
                "lng": -127.5743482626
            },
            {
                "lat": 50.5490260171,
                "lng": -127.5891879055
            },
            {
                "lat": 50.5499959648,
                "lng": -127.5898964352
            },
            {
                "lat": 50.5546223092,
                "lng": -127.5956198436
            },
            {
                "lat": 50.5554734857,
                "lng": -127.6004872556
            },
            {
                "lat": 50.552063237,
                "lng": -127.6062091823
            },
            {
                "lat": 50.5481681658,
                "lng": -127.6090083552
            },
            {
                "lat": 50.5436632056,
                "lng": -127.6097405196
            },
            {
                "lat": 50.5419620639,
                "lng": -127.6171646769
            },
            {
                "lat": 50.5422018564,
                "lng": -127.6217955934
            },
            {
                "lat": 50.5433006562,
                "lng": -127.6273956637
            },
            {
                "lat": 50.5415720062,
                "lng": -127.6308744706
            },
            {
                "lat": 50.5392497633,
                "lng": -127.6291729704
            },
            {
                "lat": 50.5379794229,
                "lng": -127.6282428924
            },
            {
                "lat": 50.536334866,
                "lng": -127.6268617085
            },
            {
                "lat": 50.5358163151,
                "lng": -127.6265253447
            },
            {
                "lat": 50.5353322599,
                "lng": -127.626441689
            },
            {
                "lat": 50.532542816,
                "lng": -127.620917703
            },
            {
                "lat": 50.5254939131,
                "lng": -127.6069488475
            },
            {
                "lat": 50.525043739,
                "lng": -127.6067352015
            },
            {
                "lat": 50.5240366614,
                "lng": -127.6066966449
            },
            {
                "lat": 50.5230560825,
                "lng": -127.6062621135
            },
            {
                "lat": 50.5216591619,
                "lng": -127.604515264
            },
            {
                "lat": 50.5214846039,
                "lng": -127.6011960832
            },
            {
                "lat": 50.5213089678,
                "lng": -127.5948947051
            },
            {
                "lat": 50.5200840237,
                "lng": -127.5891262017
            },
            {
                "lat": 50.5188597482,
                "lng": -127.5835264988
            },
            {
                "lat": 50.5185088913,
                "lng": -127.5809096714
            },
            {
                "lat": 50.5183369934,
                "lng": -127.5784614513
            },
            {
                "lat": 50.5179862869,
                "lng": -127.576537932
            },
            {
                "lat": 50.5172876812,
                "lng": -127.5726849322
            },
            {
                "lat": 50.5190353715,
                "lng": -127.5670932342
            },
            {
                "lat": 50.5220074409,
                "lng": -127.5618443159
            },
            {
                "lat": 50.5242808417,
                "lng": -127.5592186139
            },
            {
                "lat": 50.5288280337,
                "lng": -127.5592189194
            },
            {
                "lat": 50.5359993734,
                "lng": -127.5576477495
            },
            {
                "lat": 50.5408966998,
                "lng": -127.5569452496
            },
            {
                "lat": 50.545269262,
                "lng": -127.5548477705
            },
            {
                "lat": 50.5491147653,
                "lng": -127.5513540273
            },
            {
                "lat": 50.5512811751,
                "lng": -127.5492476969
            },
            {
                "lat": 50.5551834513,
                "lng": -127.5502549242
            },
            {
                "lat": 50.5561102918,
                "lng": -127.5552750196
            },
            {
                "lat": 50.558982982,
                "lng": -127.5620956332
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 443,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 27,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 27-9",
            "LABEL": "27-9",
            "FISHERY_AREA_ID": 2509,
            "OBJECTID": 587,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 15867486.1803,
            "FEATURE_LENGTH_M": 17617.6596,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.536656943938624,
            "lng": -127.59058010924322
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4892199579,
                "lng": -128.087189584
            },
            {
                "lat": 50.4768554722,
                "lng": -128.0672450725
            },
            {
                "lat": 50.4669570071,
                "lng": -128.0486148109
            },
            {
                "lat": 50.46295899,
                "lng": -128.0385286205
            },
            {
                "lat": 50.4607087077,
                "lng": -128.0311587504
            },
            {
                "lat": 50.4605518924,
                "lng": -128.0304565966
            },
            {
                "lat": 50.4605293433,
                "lng": -128.0297851403
            },
            {
                "lat": 50.4605596539,
                "lng": -128.0295098719
            },
            {
                "lat": 50.4607126782,
                "lng": -128.029037492
            },
            {
                "lat": 50.4607309318,
                "lng": -128.0289299573
            },
            {
                "lat": 50.4607578701,
                "lng": -128.0288395448
            },
            {
                "lat": 50.469565571,
                "lng": -128.0081475507
            },
            {
                "lat": 50.4712676624,
                "lng": -128.0078126694
            },
            {
                "lat": 50.472820505,
                "lng": -128.0071256826
            },
            {
                "lat": 50.4747658539,
                "lng": -128.0043023028
            },
            {
                "lat": 50.4765238273,
                "lng": -128.0047919623
            },
            {
                "lat": 50.4822656363,
                "lng": -128.0075679769
            },
            {
                "lat": 50.4886974442,
                "lng": -128.011230548
            },
            {
                "lat": 50.4954833733,
                "lng": -128.0173187154
            },
            {
                "lat": 50.5003508215,
                "lng": -128.0221860478
            },
            {
                "lat": 50.5034833462,
                "lng": -128.0188759495
            },
            {
                "lat": 50.5102657718,
                "lng": -128.0113981928
            },
            {
                "lat": 50.5154800107,
                "lng": -128.0032195778
            },
            {
                "lat": 50.5222656579,
                "lng": -128.0009619677
            },
            {
                "lat": 50.5252228378,
                "lng": -127.9966123609
            },
            {
                "lat": 50.5260923792,
                "lng": -127.9880903133
            },
            {
                "lat": 50.5269620659,
                "lng": -127.9790500854
            },
            {
                "lat": 50.5292205647,
                "lng": -127.9715728477
            },
            {
                "lat": 50.530788675,
                "lng": -127.9588774978
            },
            {
                "lat": 50.5321771694,
                "lng": -127.9428788278
            },
            {
                "lat": 50.5313067458,
                "lng": -127.9362634155
            },
            {
                "lat": 50.5266114391,
                "lng": -127.9230493894
            },
            {
                "lat": 50.5168724292,
                "lng": -127.9087909254
            },
            {
                "lat": 50.5151331346,
                "lng": -127.8999173975
            },
            {
                "lat": 50.517048258,
                "lng": -127.8936535438
            },
            {
                "lat": 50.5210493017,
                "lng": -127.8919144968
            },
            {
                "lat": 50.5250469017,
                "lng": -127.894004644
            },
            {
                "lat": 50.5293965381,
                "lng": -127.8999179657
            },
            {
                "lat": 50.5340927099,
                "lng": -127.9089583855
            },
            {
                "lat": 50.5405279581,
                "lng": -127.9197471437
            },
            {
                "lat": 50.5460925886,
                "lng": -127.92600296
            },
            {
                "lat": 50.5488735464,
                "lng": -127.9334870013
            },
            {
                "lat": 50.5497434182,
                "lng": -127.9479212672
            },
            {
                "lat": 50.548873972,
                "lng": -127.9559170367
            },
            {
                "lat": 50.5497431276,
                "lng": -127.9667053429
            },
            {
                "lat": 50.5509608532,
                "lng": -127.9753952438
            },
            {
                "lat": 50.5495684516,
                "lng": -127.9906996091
            },
            {
                "lat": 50.5448722579,
                "lng": -128.0070501923
            },
            {
                "lat": 50.5400052717,
                "lng": -128.0166171758
            },
            {
                "lat": 50.5360029415,
                "lng": -128.0202635055
            },
            {
                "lat": 50.5342636262,
                "lng": -128.0279235428
            },
            {
                "lat": 50.5273089042,
                "lng": -128.0343618875
            },
            {
                "lat": 50.5130455063,
                "lng": -128.0430446301
            },
            {
                "lat": 50.5029609394,
                "lng": -128.0524438383
            },
            {
                "lat": 50.4994809431,
                "lng": -128.0609584444
            },
            {
                "lat": 50.5088731962,
                "lng": -128.0771332954
            },
            {
                "lat": 50.5144380338,
                "lng": -128.0868834035
            },
            {
                "lat": 50.5111352864,
                "lng": -128.093307583
            },
            {
                "lat": 50.5024377766,
                "lng": -128.0926215746
            },
            {
                "lat": 50.4892199579,
                "lng": -128.087189584
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 444,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 27,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 27-3",
            "LABEL": "27-3",
            "FISHERY_AREA_ID": 2503,
            "OBJECTID": 588,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 46447010.3413,
            "FEATURE_LENGTH_M": 44665.7185,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.508320461551655,
            "lng": -127.99805768238838
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5649717239,
                "lng": -125.5317152924
            },
            {
                "lat": 50.5637665747,
                "lng": -125.5326157878
            },
            {
                "lat": 50.5605432906,
                "lng": -125.5371328676
            },
            {
                "lat": 50.5505221373,
                "lng": -125.5499116217
            },
            {
                "lat": 50.5502090632,
                "lng": -125.5505297758
            },
            {
                "lat": 50.5499725444,
                "lng": -125.5516121881
            },
            {
                "lat": 50.5497900771,
                "lng": -125.5531537855
            },
            {
                "lat": 50.550300757,
                "lng": -125.5595549569
            },
            {
                "lat": 50.5493357291,
                "lng": -125.5653228196
            },
            {
                "lat": 50.5440446482,
                "lng": -125.5749430564
            },
            {
                "lat": 50.5329820655,
                "lng": -125.5908195172
            },
            {
                "lat": 50.5276906583,
                "lng": -125.6042865234
            },
            {
                "lat": 50.5209584342,
                "lng": -125.623527914
            },
            {
                "lat": 50.5122983778,
                "lng": -125.638923347
            },
            {
                "lat": 50.4997909329,
                "lng": -125.6504659109
            },
            {
                "lat": 50.4906546654,
                "lng": -125.6499868152
            },
            {
                "lat": 50.48007222,
                "lng": -125.6331479884
            },
            {
                "lat": 50.4752620487,
                "lng": -125.6249689098
            },
            {
                "lat": 50.4699708178,
                "lng": -125.621124022
            },
            {
                "lat": 50.4549868955,
                "lng": -125.6231156231
            },
            {
                "lat": 50.4517171966,
                "lng": -125.6186287364
            },
            {
                "lat": 50.4504665205,
                "lng": -125.6174779983
            },
            {
                "lat": 50.4495084765,
                "lng": -125.5954674751
            },
            {
                "lat": 50.4494209975,
                "lng": -125.593505793
            },
            {
                "lat": 50.4497644966,
                "lng": -125.5931470053
            },
            {
                "lat": 50.4504769705,
                "lng": -125.5918736136
            },
            {
                "lat": 50.4522127665,
                "lng": -125.5879063194
            },
            {
                "lat": 50.454094301,
                "lng": -125.5836027785
            },
            {
                "lat": 50.4617914447,
                "lng": -125.5710976058
            },
            {
                "lat": 50.4728544351,
                "lng": -125.5638810618
            },
            {
                "lat": 50.5012352801,
                "lng": -125.5354991884
            },
            {
                "lat": 50.5252878804,
                "lng": -125.5196308556
            },
            {
                "lat": 50.5435640614,
                "lng": -125.5124131055
            },
            {
                "lat": 50.5546271503,
                "lng": -125.5124124931
            },
            {
                "lat": 50.5632860938,
                "lng": -125.5229953904
            },
            {
                "lat": 50.5649717239,
                "lng": -125.5317152924
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 445,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 42,
            "MANAGEMENT_AREA_NAME": "Subarea 13-42",
            "LABEL": "13-42",
            "FISHERY_AREA_ID": 2147,
            "OBJECTID": 589,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 65802471.9338,
            "FEATURE_LENGTH_M": 34957.3918,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.50815009602778,
            "lng": -125.57828092876113
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4912258283,
                "lng": -126.0985108467
            },
            {
                "lat": 50.4894784613,
                "lng": -126.097038071
            },
            {
                "lat": 50.4873694315,
                "lng": -126.0955664196
            },
            {
                "lat": 50.4858241409,
                "lng": -126.0954512366
            },
            {
                "lat": 50.4848102264,
                "lng": -126.0951609774
            },
            {
                "lat": 50.4834133059,
                "lng": -126.0822220588
            },
            {
                "lat": 50.4832387409,
                "lng": -126.0805891045
            },
            {
                "lat": 50.4879451922,
                "lng": -126.0804670992
            },
            {
                "lat": 50.4910769246,
                "lng": -126.0797271678
            },
            {
                "lat": 50.4933319186,
                "lng": -126.0792006285
            },
            {
                "lat": 50.4965021559,
                "lng": -126.0763471525
            },
            {
                "lat": 50.4961851281,
                "lng": -126.0700072432
            },
            {
                "lat": 50.5009388064,
                "lng": -126.0522610806
            },
            {
                "lat": 50.5066418957,
                "lng": -126.0310210467
            },
            {
                "lat": 50.5091783619,
                "lng": -126.0183492007
            },
            {
                "lat": 50.5142484531,
                "lng": -125.9907758757
            },
            {
                "lat": 50.5158349896,
                "lng": -125.9796827516
            },
            {
                "lat": 50.5202716527,
                "lng": -125.9698566618
            },
            {
                "lat": 50.5269284563,
                "lng": -125.9590759368
            },
            {
                "lat": 50.5305894285,
                "lng": -125.9557492415
            },
            {
                "lat": 50.5339503777,
                "lng": -125.940628244
            },
            {
                "lat": 50.5361101626,
                "lng": -125.9179605404
            },
            {
                "lat": 50.5412366758,
                "lng": -125.9012298421
            },
            {
                "lat": 50.5455547649,
                "lng": -125.8987967114
            },
            {
                "lat": 50.5520318697,
                "lng": -125.9001472694
            },
            {
                "lat": 50.5558088561,
                "lng": -125.9104005268
            },
            {
                "lat": 50.5612067502,
                "lng": -125.9284821717
            },
            {
                "lat": 50.5641739528,
                "lng": -125.9392776465
            },
            {
                "lat": 50.5608402468,
                "lng": -125.9479827256
            },
            {
                "lat": 50.5567163982,
                "lng": -125.9597169399
            },
            {
                "lat": 50.5519636651,
                "lng": -125.9698566333
            },
            {
                "lat": 50.5478433631,
                "lng": -125.9799965458
            },
            {
                "lat": 50.5440403693,
                "lng": -125.9888684951
            },
            {
                "lat": 50.5399216608,
                "lng": -125.9986952469
            },
            {
                "lat": 50.5377050709,
                "lng": -126.0082012282
            },
            {
                "lat": 50.5364350016,
                "lng": -126.02056911
            },
            {
                "lat": 50.532314722,
                "lng": -126.0389478952
            },
            {
                "lat": 50.5291449756,
                "lng": -126.0513077429
            },
            {
                "lat": 50.5262914811,
                "lng": -126.0639873034
            },
            {
                "lat": 50.5215381002,
                "lng": -126.075714078
            },
            {
                "lat": 50.5148813405,
                "lng": -126.0855408163
            },
            {
                "lat": 50.5104452628,
                "lng": -126.0925067864
            },
            {
                "lat": 50.4987185103,
                "lng": -126.0979005462
            },
            {
                "lat": 50.4912258283,
                "lng": -126.0985108467
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 446,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 25,
            "MANAGEMENT_AREA_NAME": "Subarea 12-25",
            "LABEL": "12-25",
            "FISHERY_AREA_ID": 2083,
            "OBJECTID": 590,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 42105967.5588,
            "FEATURE_LENGTH_M": 36152.4309,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.52011665693637,
            "lng": -126.01823376575908
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5415802954,
                "lng": -128.202651859
            },
            {
                "lat": 50.5387388013,
                "lng": -128.2093044125
            },
            {
                "lat": 50.5390135711,
                "lng": -128.2121271917
            },
            {
                "lat": 50.5395361379,
                "lng": -128.2154228996
            },
            {
                "lat": 50.5404629172,
                "lng": -128.2190095719
            },
            {
                "lat": 50.5000003868,
                "lng": -128.1734471662
            },
            {
                "lat": 50.3252330443,
                "lng": -127.976630527
            },
            {
                "lat": 50.3248822952,
                "lng": -127.9761347721
            },
            {
                "lat": 50.3247266265,
                "lng": -127.9755702305
            },
            {
                "lat": 50.3246231126,
                "lng": -127.9750738497
            },
            {
                "lat": 50.3246462996,
                "lng": -127.9748763589
            },
            {
                "lat": 50.3246763451,
                "lng": -127.9745781662
            },
            {
                "lat": 50.3248486626,
                "lng": -127.974021792
            },
            {
                "lat": 50.325374836,
                "lng": -127.9734720544
            },
            {
                "lat": 50.326381875,
                "lng": -127.9729915623
            },
            {
                "lat": 50.3279840246,
                "lng": -127.9719387999
            },
            {
                "lat": 50.3294334246,
                "lng": -127.9712904142
            },
            {
                "lat": 50.3335229114,
                "lng": -127.970809633
            },
            {
                "lat": 50.3385778361,
                "lng": -127.9718020374
            },
            {
                "lat": 50.3456196375,
                "lng": -127.9697257398
            },
            {
                "lat": 50.3513951502,
                "lng": -127.9661172734
            },
            {
                "lat": 50.3541948151,
                "lng": -127.9630511622
            },
            {
                "lat": 50.3588863748,
                "lng": -127.960067528
            },
            {
                "lat": 50.3640293712,
                "lng": -127.9576337804
            },
            {
                "lat": 50.3703495148,
                "lng": -127.9563678545
            },
            {
                "lat": 50.3746836942,
                "lng": -127.9553753425
            },
            {
                "lat": 50.3822636472,
                "lng": -127.9523085082
            },
            {
                "lat": 50.3930923799,
                "lng": -127.9460829342
            },
            {
                "lat": 50.3972472345,
                "lng": -127.9436419929
            },
            {
                "lat": 50.4041063426,
                "lng": -127.9405741644
            },
            {
                "lat": 50.4087981804,
                "lng": -127.9364243048
            },
            {
                "lat": 50.4138529833,
                "lng": -127.9306486915
            },
            {
                "lat": 50.4183652992,
                "lng": -127.9256813064
            },
            {
                "lat": 50.4210743655,
                "lng": -127.9198150495
            },
            {
                "lat": 50.4233325503,
                "lng": -127.9175572941
            },
            {
                "lat": 50.4274827508,
                "lng": -127.9148478396
            },
            {
                "lat": 50.4308205584,
                "lng": -127.9153908057
            },
            {
                "lat": 50.4336204145,
                "lng": -127.9173813445
            },
            {
                "lat": 50.4337088514,
                "lng": -127.922074272
            },
            {
                "lat": 50.4340708471,
                "lng": -127.9251403926
            },
            {
                "lat": 50.4375004867,
                "lng": -127.9293827254
            },
            {
                "lat": 50.4408414917,
                "lng": -127.9328989701
            },
            {
                "lat": 50.4439089391,
                "lng": -127.9329915425
            },
            {
                "lat": 50.4498669653,
                "lng": -127.9328150908
            },
            {
                "lat": 50.4562757671,
                "lng": -127.9318166532
            },
            {
                "lat": 50.459434415,
                "lng": -127.9307326355
            },
            {
                "lat": 50.4611012327,
                "lng": -127.9303440767
            },
            {
                "lat": 50.4627532501,
                "lng": -127.9339678236
            },
            {
                "lat": 50.4639008325,
                "lng": -127.9361340978
            },
            {
                "lat": 50.4643329509,
                "lng": -127.936836319
            },
            {
                "lat": 50.4683305475,
                "lng": -127.9368360967
            },
            {
                "lat": 50.47653183,
                "lng": -127.9368365497
            },
            {
                "lat": 50.4786030725,
                "lng": -127.9368364689
            },
            {
                "lat": 50.4789697959,
                "lng": -127.9370038005
            },
            {
                "lat": 50.4808429881,
                "lng": -127.9374394129
            },
            {
                "lat": 50.4812201452,
                "lng": -127.9386824698
            },
            {
                "lat": 50.4816440551,
                "lng": -127.9400782805
            },
            {
                "lat": 50.483482272,
                "lng": -127.943412379
            },
            {
                "lat": 50.4840580818,
                "lng": -127.946395238
            },
            {
                "lat": 50.4844016294,
                "lng": -127.9501878688
            },
            {
                "lat": 50.4834821509,
                "lng": -127.9547808219
            },
            {
                "lat": 50.4815295968,
                "lng": -127.9589162391
            },
            {
                "lat": 50.4786600894,
                "lng": -127.963622168
            },
            {
                "lat": 50.4757879275,
                "lng": -127.9703974841
            },
            {
                "lat": 50.4748686827,
                "lng": -127.9801639152
            },
            {
                "lat": 50.4745250481,
                "lng": -127.9875107853
            },
            {
                "lat": 50.4729617016,
                "lng": -127.9971159644
            },
            {
                "lat": 50.4732365671,
                "lng": -128.0025027411
            },
            {
                "lat": 50.4747658539,
                "lng": -128.0043023028
            },
            {
                "lat": 50.472820505,
                "lng": -128.0071256826
            },
            {
                "lat": 50.4712676624,
                "lng": -128.0078126694
            },
            {
                "lat": 50.469565571,
                "lng": -128.0081475507
            },
            {
                "lat": 50.4607578701,
                "lng": -128.0288395448
            },
            {
                "lat": 50.4607309318,
                "lng": -128.0289299573
            },
            {
                "lat": 50.4607126782,
                "lng": -128.029037492
            },
            {
                "lat": 50.4605596539,
                "lng": -128.0295098719
            },
            {
                "lat": 50.4605293433,
                "lng": -128.0297851403
            },
            {
                "lat": 50.4605518924,
                "lng": -128.0304565966
            },
            {
                "lat": 50.4607087077,
                "lng": -128.0311587504
            },
            {
                "lat": 50.46295899,
                "lng": -128.0385286205
            },
            {
                "lat": 50.4669570071,
                "lng": -128.0486148109
            },
            {
                "lat": 50.4768554722,
                "lng": -128.0672450725
            },
            {
                "lat": 50.4892199579,
                "lng": -128.087189584
            },
            {
                "lat": 50.4965135191,
                "lng": -128.1036070876
            },
            {
                "lat": 50.509277292,
                "lng": -128.1327822781
            },
            {
                "lat": 50.5256918869,
                "lng": -128.1619571815
            },
            {
                "lat": 50.5328443792,
                "lng": -128.1796565832
            },
            {
                "lat": 50.5358889725,
                "lng": -128.1928405227
            },
            {
                "lat": 50.5415802954,
                "lng": -128.202651859
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 447,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 27,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 27-2",
            "LABEL": "27-2",
            "FISHERY_AREA_ID": 2502,
            "OBJECTID": 591,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 162399589.9758,
            "FEATURE_LENGTH_M": 73470.3227,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.43931516090899,
            "lng": -127.99577391749435
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5254939131,
                "lng": -127.6069488475
            },
            {
                "lat": 50.532542816,
                "lng": -127.620917703
            },
            {
                "lat": 50.5353322599,
                "lng": -127.626441689
            },
            {
                "lat": 50.5358163151,
                "lng": -127.6265253447
            },
            {
                "lat": 50.536334866,
                "lng": -127.6268617085
            },
            {
                "lat": 50.5379794229,
                "lng": -127.6282428924
            },
            {
                "lat": 50.5392497633,
                "lng": -127.6291729704
            },
            {
                "lat": 50.5415720062,
                "lng": -127.6308744706
            },
            {
                "lat": 50.543201798,
                "lng": -127.6380312728
            },
            {
                "lat": 50.5432017817,
                "lng": -127.6476512128
            },
            {
                "lat": 50.5401613823,
                "lng": -127.667403465
            },
            {
                "lat": 50.5350947595,
                "lng": -127.6785504136
            },
            {
                "lat": 50.5290178087,
                "lng": -127.695770799
            },
            {
                "lat": 50.5264857343,
                "lng": -127.7089381114
            },
            {
                "lat": 50.5219271102,
                "lng": -127.7297060554
            },
            {
                "lat": 50.5168606709,
                "lng": -127.7479400585
            },
            {
                "lat": 50.509770023,
                "lng": -127.7732691748
            },
            {
                "lat": 50.506225857,
                "lng": -127.786438803
            },
            {
                "lat": 50.5036928432,
                "lng": -127.8021395042
            },
            {
                "lat": 50.5026811023,
                "lng": -127.8142926466
            },
            {
                "lat": 50.5067324192,
                "lng": -127.8234097096
            },
            {
                "lat": 50.5128091464,
                "lng": -127.8289798165
            },
            {
                "lat": 50.5188862231,
                "lng": -127.8360747734
            },
            {
                "lat": 50.5209117072,
                "lng": -127.8406295696
            },
            {
                "lat": 50.5209118869,
                "lng": -127.8462071403
            },
            {
                "lat": 50.5163529141,
                "lng": -127.8548123957
            },
            {
                "lat": 50.5133171927,
                "lng": -127.8629153388
            },
            {
                "lat": 50.5097699925,
                "lng": -127.872033087
            },
            {
                "lat": 50.5062263796,
                "lng": -127.8796310798
            },
            {
                "lat": 50.5021747161,
                "lng": -127.8811489641
            },
            {
                "lat": 50.4950827347,
                "lng": -127.8811493495
            },
            {
                "lat": 50.4900163197,
                "lng": -127.8846968212
            },
            {
                "lat": 50.4869770853,
                "lng": -127.8927991334
            },
            {
                "lat": 50.4859650464,
                "lng": -127.9044498889
            },
            {
                "lat": 50.4900164759,
                "lng": -127.9171148452
            },
            {
                "lat": 50.4930572874,
                "lng": -127.9247129339
            },
            {
                "lat": 50.4940676097,
                "lng": -127.9297794741
            },
            {
                "lat": 50.4925495014,
                "lng": -127.9338306172
            },
            {
                "lat": 50.4879909293,
                "lng": -127.9373709405
            },
            {
                "lat": 50.4812201452,
                "lng": -127.9386824698
            },
            {
                "lat": 50.4808429881,
                "lng": -127.9374394129
            },
            {
                "lat": 50.4789697959,
                "lng": -127.9370038005
            },
            {
                "lat": 50.4786030725,
                "lng": -127.9368364689
            },
            {
                "lat": 50.47653183,
                "lng": -127.9368365497
            },
            {
                "lat": 50.4683305475,
                "lng": -127.9368360967
            },
            {
                "lat": 50.4643329509,
                "lng": -127.936836319
            },
            {
                "lat": 50.4639008325,
                "lng": -127.9361340978
            },
            {
                "lat": 50.4627532501,
                "lng": -127.9339678236
            },
            {
                "lat": 50.4611012327,
                "lng": -127.9303440767
            },
            {
                "lat": 50.4608767143,
                "lng": -127.9298481817
            },
            {
                "lat": 50.4577672297,
                "lng": -127.9244157212
            },
            {
                "lat": 50.4546624227,
                "lng": -127.9166491239
            },
            {
                "lat": 50.4496155509,
                "lng": -127.8960722317
            },
            {
                "lat": 50.4480626984,
                "lng": -127.8840409299
            },
            {
                "lat": 50.4468997098,
                "lng": -127.8743287668
            },
            {
                "lat": 50.4453434698,
                "lng": -127.8673396729
            },
            {
                "lat": 50.4484513981,
                "lng": -127.8599699509
            },
            {
                "lat": 50.4538882205,
                "lng": -127.854531295
            },
            {
                "lat": 50.4562153827,
                "lng": -127.8467635855
            },
            {
                "lat": 50.4562153085,
                "lng": -127.8359001471
            },
            {
                "lat": 50.4488417294,
                "lng": -127.8207552881
            },
            {
                "lat": 50.4503931416,
                "lng": -127.8067782787
            },
            {
                "lat": 50.4554397176,
                "lng": -127.7928000433
            },
            {
                "lat": 50.4639811153,
                "lng": -127.7838749847
            },
            {
                "lat": 50.4701923318,
                "lng": -127.7730028601
            },
            {
                "lat": 50.4721339545,
                "lng": -127.7667852248
            },
            {
                "lat": 50.468250017,
                "lng": -127.755531018
            },
            {
                "lat": 50.4701922625,
                "lng": -127.7361143049
            },
            {
                "lat": 50.4729117506,
                "lng": -127.7233050375
            },
            {
                "lat": 50.4764063274,
                "lng": -127.7027286121
            },
            {
                "lat": 50.4791221278,
                "lng": -127.6809850684
            },
            {
                "lat": 50.4787334579,
                "lng": -127.6701129375
            },
            {
                "lat": 50.4795108881,
                "lng": -127.6569133655
            },
            {
                "lat": 50.4756277369,
                "lng": -127.6386725646
            },
            {
                "lat": 50.4744644185,
                "lng": -127.6200330811
            },
            {
                "lat": 50.4787336057,
                "lng": -127.6095501235
            },
            {
                "lat": 50.4803053469,
                "lng": -127.6105887779
            },
            {
                "lat": 50.4822117967,
                "lng": -127.6117090906
            },
            {
                "lat": 50.4846416759,
                "lng": -127.6126781234
            },
            {
                "lat": 50.4864342339,
                "lng": -127.6141735453
            },
            {
                "lat": 50.4882654402,
                "lng": -127.6178360662
            },
            {
                "lat": 50.4903949139,
                "lng": -127.6207198522
            },
            {
                "lat": 50.4916683765,
                "lng": -127.6232901399
            },
            {
                "lat": 50.4931597335,
                "lng": -127.6251297337
            },
            {
                "lat": 50.4939465265,
                "lng": -127.6259840568
            },
            {
                "lat": 50.4944612991,
                "lng": -127.6264266365
            },
            {
                "lat": 50.5009115266,
                "lng": -127.6059793518
            },
            {
                "lat": 50.5254939131,
                "lng": -127.6069488475
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 448,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 27,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 27-7",
            "LABEL": "27-7",
            "FISHERY_AREA_ID": 2507,
            "OBJECTID": 592,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 133488844.2859,
            "FEATURE_LENGTH_M": 64083.181,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.49122581724318,
            "lng": -127.77736416771249
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5460624366,
                "lng": -126.6461174307
            },
            {
                "lat": 50.5439192953,
                "lng": -126.6468507645
            },
            {
                "lat": 50.5353894741,
                "lng": -126.6390917412
            },
            {
                "lat": 50.527419711,
                "lng": -126.6135329801
            },
            {
                "lat": 50.5273289926,
                "lng": -126.6097110613
            },
            {
                "lat": 50.52845791,
                "lng": -126.6076201425
            },
            {
                "lat": 50.5286488537,
                "lng": -126.6056602895
            },
            {
                "lat": 50.5330689126,
                "lng": -126.6074829626
            },
            {
                "lat": 50.5347359766,
                "lng": -126.6085357425
            },
            {
                "lat": 50.537544063,
                "lng": -126.6083610697
            },
            {
                "lat": 50.5384255813,
                "lng": -126.6076584018
            },
            {
                "lat": 50.5422890681,
                "lng": -126.6089703751
            },
            {
                "lat": 50.543693135,
                "lng": -126.6110768459
            },
            {
                "lat": 50.5436931992,
                "lng": -126.6143271961
            },
            {
                "lat": 50.5440450405,
                "lng": -126.6193319548
            },
            {
                "lat": 50.5458866933,
                "lng": -126.6261829935
            },
            {
                "lat": 50.5469435497,
                "lng": -126.630745333
            },
            {
                "lat": 50.5465014008,
                "lng": -126.6344379341
            },
            {
                "lat": 50.5463255964,
                "lng": -126.6393501049
            },
            {
                "lat": 50.5463252931,
                "lng": -126.6433947601
            },
            {
                "lat": 50.5460624366,
                "lng": -126.6461174307
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 449,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 21,
            "MANAGEMENT_AREA_NAME": "Subarea 12-21",
            "LABEL": "12-21",
            "FISHERY_AREA_ID": 2079,
            "OBJECTID": 593,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 3952657.7578,
            "FEATURE_LENGTH_M": 8305.197,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.53965555330954,
            "lng": -126.6225979768857
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4993401035,
                "lng": -125.8497621119
            },
            {
                "lat": 50.4958503152,
                "lng": -125.8478542179
            },
            {
                "lat": 50.489899083,
                "lng": -125.8169941997
            },
            {
                "lat": 50.4920469492,
                "lng": -125.8142474558
            },
            {
                "lat": 50.4953690734,
                "lng": -125.8129126799
            },
            {
                "lat": 50.4984704745,
                "lng": -125.8102565831
            },
            {
                "lat": 50.5011293324,
                "lng": -125.800293212
            },
            {
                "lat": 50.5057791845,
                "lng": -125.7856746976
            },
            {
                "lat": 50.5082132564,
                "lng": -125.7703935448
            },
            {
                "lat": 50.5090982756,
                "lng": -125.7531205874
            },
            {
                "lat": 50.5119783495,
                "lng": -125.7334063221
            },
            {
                "lat": 50.5137526497,
                "lng": -125.7225565101
            },
            {
                "lat": 50.5212823096,
                "lng": -125.7152483595
            },
            {
                "lat": 50.5292554688,
                "lng": -125.7159115004
            },
            {
                "lat": 50.5327947198,
                "lng": -125.7218933022
            },
            {
                "lat": 50.5334628788,
                "lng": -125.7303084477
            },
            {
                "lat": 50.5339052476,
                "lng": -125.7440413868
            },
            {
                "lat": 50.5347899024,
                "lng": -125.7566607803
            },
            {
                "lat": 50.5409921576,
                "lng": -125.7732692382
            },
            {
                "lat": 50.5423208022,
                "lng": -125.7834629245
            },
            {
                "lat": 50.5370065616,
                "lng": -125.7907713107
            },
            {
                "lat": 50.5339052434,
                "lng": -125.798966
            },
            {
                "lat": 50.5332413299,
                "lng": -125.8100360977
            },
            {
                "lat": 50.5427629874,
                "lng": -125.8233257074
            },
            {
                "lat": 50.5458643129,
                "lng": -125.8339540855
            },
            {
                "lat": 50.5403252991,
                "lng": -125.8414837107
            },
            {
                "lat": 50.5314671706,
                "lng": -125.8441397523
            },
            {
                "lat": 50.5268174076,
                "lng": -125.8383862649
            },
            {
                "lat": 50.5208399332,
                "lng": -125.8368301434
            },
            {
                "lat": 50.5148584475,
                "lng": -125.8394924549
            },
            {
                "lat": 50.5079923725,
                "lng": -125.8436972462
            },
            {
                "lat": 50.5017928995,
                "lng": -125.8476869633
            },
            {
                "lat": 50.4993401035,
                "lng": -125.8497621119
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 450,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 37,
            "MANAGEMENT_AREA_NAME": "Subarea 13-37",
            "LABEL": "13-37",
            "FISHERY_AREA_ID": 2142,
            "OBJECTID": 594,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 32945345.7811,
            "FEATURE_LENGTH_M": 27523.7131,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.51896801825455,
            "lng": -125.79566060335759
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.3575858317,
                "lng": -124.7605905082
            },
            {
                "lat": 50.3445017535,
                "lng": -124.7533180814
            },
            {
                "lat": 50.3252027023,
                "lng": -124.7358616887
            },
            {
                "lat": 50.3251954467,
                "lng": -124.7341460097
            },
            {
                "lat": 50.3250047183,
                "lng": -124.7302471539
            },
            {
                "lat": 50.3266522646,
                "lng": -124.7172848476
            },
            {
                "lat": 50.328972095,
                "lng": -124.7002712934
            },
            {
                "lat": 50.3537175219,
                "lng": -124.6438224849
            },
            {
                "lat": 50.3784637969,
                "lng": -124.5873637506
            },
            {
                "lat": 50.3947027765,
                "lng": -124.4721365037
            },
            {
                "lat": 50.4256365876,
                "lng": -124.367744346
            },
            {
                "lat": 50.4789965436,
                "lng": -124.3221136305
            },
            {
                "lat": 50.5215298259,
                "lng": -124.3205708134
            },
            {
                "lat": 50.5292622781,
                "lng": -124.3468620667
            },
            {
                "lat": 50.5184371462,
                "lng": -124.3708331287
            },
            {
                "lat": 50.5006487837,
                "lng": -124.4272852852
            },
            {
                "lat": 50.4813153477,
                "lng": -124.4845122851
            },
            {
                "lat": 50.4527056274,
                "lng": -124.5548870898
            },
            {
                "lat": 50.4449692874,
                "lng": -124.6066969533
            },
            {
                "lat": 50.4557950881,
                "lng": -124.6600574294
            },
            {
                "lat": 50.4581185592,
                "lng": -124.6778410257
            },
            {
                "lat": 50.4349170445,
                "lng": -124.6933142539
            },
            {
                "lat": 50.3885196405,
                "lng": -124.7304298118
            },
            {
                "lat": 50.3637731385,
                "lng": -124.7505335785
            },
            {
                "lat": 50.3575858317,
                "lng": -124.7605905082
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 451,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 15,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 15-6",
            "LABEL": "15-6",
            "FISHERY_AREA_ID": 2169,
            "OBJECTID": 595,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 285766672.8583,
            "FEATURE_LENGTH_M": 85267.3389,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.410888385499995,
            "lng": -124.59637258113202
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5172876812,
                "lng": -127.5726849322
            },
            {
                "lat": 50.5179862869,
                "lng": -127.576537932
            },
            {
                "lat": 50.5183369934,
                "lng": -127.5784614513
            },
            {
                "lat": 50.5185088913,
                "lng": -127.5809096714
            },
            {
                "lat": 50.5188597482,
                "lng": -127.5835264988
            },
            {
                "lat": 50.5200840237,
                "lng": -127.5891262017
            },
            {
                "lat": 50.5213089678,
                "lng": -127.5948947051
            },
            {
                "lat": 50.5214846039,
                "lng": -127.6011960832
            },
            {
                "lat": 50.5216591619,
                "lng": -127.604515264
            },
            {
                "lat": 50.5230560825,
                "lng": -127.6062621135
            },
            {
                "lat": 50.5240366614,
                "lng": -127.6066966449
            },
            {
                "lat": 50.525043739,
                "lng": -127.6067352015
            },
            {
                "lat": 50.5254939131,
                "lng": -127.6069488475
            },
            {
                "lat": 50.5009115266,
                "lng": -127.6059793518
            },
            {
                "lat": 50.4944612991,
                "lng": -127.6264266365
            },
            {
                "lat": 50.4939465265,
                "lng": -127.6259840568
            },
            {
                "lat": 50.4931597335,
                "lng": -127.6251297337
            },
            {
                "lat": 50.4916683765,
                "lng": -127.6232901399
            },
            {
                "lat": 50.4903949139,
                "lng": -127.6207198522
            },
            {
                "lat": 50.4882654402,
                "lng": -127.6178360662
            },
            {
                "lat": 50.4864342339,
                "lng": -127.6141735453
            },
            {
                "lat": 50.4846416759,
                "lng": -127.6126781234
            },
            {
                "lat": 50.4822117967,
                "lng": -127.6117090906
            },
            {
                "lat": 50.4803053469,
                "lng": -127.6105887779
            },
            {
                "lat": 50.4787336057,
                "lng": -127.6095501235
            },
            {
                "lat": 50.477180465,
                "lng": -127.6017834921
            },
            {
                "lat": 50.4771805079,
                "lng": -127.5947957427
            },
            {
                "lat": 50.476791665,
                "lng": -127.5862575655
            },
            {
                "lat": 50.4752386724,
                "lng": -127.5788807207
            },
            {
                "lat": 50.4721339075,
                "lng": -127.5718917825
            },
            {
                "lat": 50.4694181897,
                "lng": -127.5687867339
            },
            {
                "lat": 50.4612619118,
                "lng": -127.5629582372
            },
            {
                "lat": 50.4558300341,
                "lng": -127.5571359867
            },
            {
                "lat": 50.4488416081,
                "lng": -127.5485995798
            },
            {
                "lat": 50.4391326609,
                "lng": -127.5408323511
            },
            {
                "lat": 50.4298170265,
                "lng": -127.5342323103
            },
            {
                "lat": 50.4181673397,
                "lng": -127.5249098973
            },
            {
                "lat": 50.3991427589,
                "lng": -127.5128785446
            },
            {
                "lat": 50.388275315,
                "lng": -127.5043336317
            },
            {
                "lat": 50.3801193137,
                "lng": -127.496574523
            },
            {
                "lat": 50.373130835,
                "lng": -127.4857023746
            },
            {
                "lat": 50.3680840101,
                "lng": -127.4779357115
            },
            {
                "lat": 50.3676987641,
                "lng": -127.4697790612
            },
            {
                "lat": 50.3645892812,
                "lng": -127.4589152961
            },
            {
                "lat": 50.3599317093,
                "lng": -127.4558024699
            },
            {
                "lat": 50.350612711,
                "lng": -127.4546433277
            },
            {
                "lat": 50.3451807371,
                "lng": -127.4523092139
            },
            {
                "lat": 50.3389662271,
                "lng": -127.4468769113
            },
            {
                "lat": 50.3335310131,
                "lng": -127.4476544383
            },
            {
                "lat": 50.3269313695,
                "lng": -127.4460980053
            },
            {
                "lat": 50.3230478776,
                "lng": -127.4394985918
            },
            {
                "lat": 50.3226627267,
                "lng": -127.4321214851
            },
            {
                "lat": 50.3246007414,
                "lng": -127.4259109243
            },
            {
                "lat": 50.3292619962,
                "lng": -127.4235836748
            },
            {
                "lat": 50.3389662355,
                "lng": -127.4243544416
            },
            {
                "lat": 50.3467335665,
                "lng": -127.4274666264
            },
            {
                "lat": 50.3533318306,
                "lng": -127.4309620827
            },
            {
                "lat": 50.3607105322,
                "lng": -127.4313510125
            },
            {
                "lat": 50.3665313499,
                "lng": -127.4305727161
            },
            {
                "lat": 50.3758505153,
                "lng": -127.4344553402
            },
            {
                "lat": 50.3820608594,
                "lng": -127.4383321474
            },
            {
                "lat": 50.3886607706,
                "lng": -127.4457093169
            },
            {
                "lat": 50.3995328786,
                "lng": -127.4565817663
            },
            {
                "lat": 50.4076842669,
                "lng": -127.466285495
            },
            {
                "lat": 50.4158403003,
                "lng": -127.4740526101
            },
            {
                "lat": 50.4259331299,
                "lng": -127.4783250051
            },
            {
                "lat": 50.4383585883,
                "lng": -127.4884186837
            },
            {
                "lat": 50.4503933558,
                "lng": -127.4992905218
            },
            {
                "lat": 50.4635924936,
                "lng": -127.5121001839
            },
            {
                "lat": 50.478343617,
                "lng": -127.5264669726
            },
            {
                "lat": 50.4872736554,
                "lng": -127.5396643274
            },
            {
                "lat": 50.5000878063,
                "lng": -127.5579148247
            },
            {
                "lat": 50.510570966,
                "lng": -127.5695644067
            },
            {
                "lat": 50.5172876812,
                "lng": -127.5726849322
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 452,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 27,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 27-8",
            "LABEL": "27-8",
            "FISHERY_AREA_ID": 2508,
            "OBJECTID": 596,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 74407223.3993,
            "FEATURE_LENGTH_M": 57657.3643,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.43976739195406,
            "lng": -127.53133509517707
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5000003868,
                "lng": -128.1734471662
            },
            {
                "lat": 50.5000004886,
                "lng": -128.3500060056
            },
            {
                "lat": 50.1092298074,
                "lng": -127.9392396991
            },
            {
                "lat": 50.1138459633,
                "lng": -127.9405362519
            },
            {
                "lat": 50.3252330443,
                "lng": -127.976630527
            },
            {
                "lat": 50.5000003868,
                "lng": -128.1734471662
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 453,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 127,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 127-1",
            "LABEL": "127-1",
            "FISHERY_AREA_ID": 2339,
            "OBJECTID": 597,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 413123677.9533,
            "FEATURE_LENGTH_M": 113062.6359,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.34138501286666,
            "lng": -128.09221780266668
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4349402664,
                "lng": -125.2819822858
            },
            {
                "lat": 50.4242859132,
                "lng": -125.2720935631
            },
            {
                "lat": 50.4011345173,
                "lng": -125.2326504215
            },
            {
                "lat": 50.3931774742,
                "lng": -125.2152947872
            },
            {
                "lat": 50.3868445247,
                "lng": -125.2014914394
            },
            {
                "lat": 50.3765525056,
                "lng": -125.1769102376
            },
            {
                "lat": 50.3622624492,
                "lng": -125.1666178463
            },
            {
                "lat": 50.3576245122,
                "lng": -125.1647332466
            },
            {
                "lat": 50.3453983207,
                "lng": -125.1597601932
            },
            {
                "lat": 50.3396803049,
                "lng": -125.1497566182
            },
            {
                "lat": 50.3359638512,
                "lng": -125.1391835307
            },
            {
                "lat": 50.3332828172,
                "lng": -125.1320576908
            },
            {
                "lat": 50.3323903074,
                "lng": -125.1288447675
            },
            {
                "lat": 50.361472811,
                "lng": -125.1359178023
            },
            {
                "lat": 50.3731387316,
                "lng": -125.1348265066
            },
            {
                "lat": 50.3762169486,
                "lng": -125.1312707488
            },
            {
                "lat": 50.3817142517,
                "lng": -125.134101547
            },
            {
                "lat": 50.3880422967,
                "lng": -125.1302715479
            },
            {
                "lat": 50.4010308695,
                "lng": -125.1299442486
            },
            {
                "lat": 50.4061965321,
                "lng": -125.1347725388
            },
            {
                "lat": 50.412251075,
                "lng": -125.1311798301
            },
            {
                "lat": 50.4215854326,
                "lng": -125.1303034279
            },
            {
                "lat": 50.4252697694,
                "lng": -125.130302904
            },
            {
                "lat": 50.4292823664,
                "lng": -125.1323466623
            },
            {
                "lat": 50.4268227375,
                "lng": -125.139525731
            },
            {
                "lat": 50.4394341662,
                "lng": -125.2145685786
            },
            {
                "lat": 50.4411092215,
                "lng": -125.2150887574
            },
            {
                "lat": 50.4503747813,
                "lng": -125.2202757568
            },
            {
                "lat": 50.4563023634,
                "lng": -125.2317655971
            },
            {
                "lat": 50.4692722229,
                "lng": -125.2447364563
            },
            {
                "lat": 50.484096903,
                "lng": -125.2365791741
            },
            {
                "lat": 50.5007741993,
                "lng": -125.2332452309
            },
            {
                "lat": 50.51003656,
                "lng": -125.2421422441
            },
            {
                "lat": 50.5111505288,
                "lng": -125.2547384255
            },
            {
                "lat": 50.4992899618,
                "lng": -125.2732700073
            },
            {
                "lat": 50.4878012374,
                "lng": -125.2851257964
            },
            {
                "lat": 50.4774247185,
                "lng": -125.292907346
            },
            {
                "lat": 50.4700126917,
                "lng": -125.3003240255
            },
            {
                "lat": 50.4644202901,
                "lng": -125.3064878758
            },
            {
                "lat": 50.4577065716,
                "lng": -125.3084033212
            },
            {
                "lat": 50.4552875737,
                "lng": -125.3089141234
            },
            {
                "lat": 50.4433559298,
                "lng": -125.2866674618
            },
            {
                "lat": 50.4349402664,
                "lng": -125.2819822858
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 454,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 23,
            "MANAGEMENT_AREA_NAME": "Subarea 13-23",
            "LABEL": "13-23",
            "FISHERY_AREA_ID": 2128,
            "OBJECTID": 598,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 90605568.862,
            "FEATURE_LENGTH_M": 53863.9898,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.42045004124885,
            "lng": -125.20356657179535
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.5020179493,
                "lng": -126.2152100122
            },
            {
                "lat": 50.498440426,
                "lng": -126.2163308028
            },
            {
                "lat": 50.4863818121,
                "lng": -126.1591188853
            },
            {
                "lat": 50.4861492099,
                "lng": -126.1580192864
            },
            {
                "lat": 50.4867775909,
                "lng": -126.1580197251
            },
            {
                "lat": 50.4868814831,
                "lng": -126.1580130398
            },
            {
                "lat": 50.4869686055,
                "lng": -126.1579587137
            },
            {
                "lat": 50.4870722773,
                "lng": -126.1577150052
            },
            {
                "lat": 50.4871980294,
                "lng": -126.157340948
            },
            {
                "lat": 50.4872820825,
                "lng": -126.1570742585
            },
            {
                "lat": 50.4879302058,
                "lng": -126.1562120836
            },
            {
                "lat": 50.4889072522,
                "lng": -126.1554488352
            },
            {
                "lat": 50.4901503975,
                "lng": -126.1559369102
            },
            {
                "lat": 50.4913445064,
                "lng": -126.1573492489
            },
            {
                "lat": 50.49307605,
                "lng": -126.1622234093
            },
            {
                "lat": 50.4938347051,
                "lng": -126.1663972394
            },
            {
                "lat": 50.4942204889,
                "lng": -126.1704711101
            },
            {
                "lat": 50.4942702194,
                "lng": -126.1709974217
            },
            {
                "lat": 50.4955138533,
                "lng": -126.1754457281
            },
            {
                "lat": 50.4981155722,
                "lng": -126.1796114973
            },
            {
                "lat": 50.5014763838,
                "lng": -126.1884462548
            },
            {
                "lat": 50.5021699538,
                "lng": -126.1920163575
            },
            {
                "lat": 50.5015752494,
                "lng": -126.1958921369
            },
            {
                "lat": 50.5021781137,
                "lng": -126.2007444733
            },
            {
                "lat": 50.5031015571,
                "lng": -126.2055129069
            },
            {
                "lat": 50.5033714694,
                "lng": -126.2083286635
            },
            {
                "lat": 50.5029376375,
                "lng": -126.2114717144
            },
            {
                "lat": 50.5021246658,
                "lng": -126.2137455584
            },
            {
                "lat": 50.5020179493,
                "lng": -126.2152100122
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 455,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 24,
            "MANAGEMENT_AREA_NAME": "Subarea 12-24",
            "LABEL": "12-24",
            "FISHERY_AREA_ID": 2082,
            "OBJECTID": 599,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 3123443.7534,
            "FEATURE_LENGTH_M": 9942.7432,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.49460295505517,
            "lng": -126.1784918013345
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4889072522,
                "lng": -126.1554488352
            },
            {
                "lat": 50.4879302058,
                "lng": -126.1562120836
            },
            {
                "lat": 50.4872820825,
                "lng": -126.1570742585
            },
            {
                "lat": 50.4871980294,
                "lng": -126.157340948
            },
            {
                "lat": 50.4870722773,
                "lng": -126.1577150052
            },
            {
                "lat": 50.4869686055,
                "lng": -126.1579587137
            },
            {
                "lat": 50.4868814831,
                "lng": -126.1580130398
            },
            {
                "lat": 50.4867775909,
                "lng": -126.1580197251
            },
            {
                "lat": 50.4861492099,
                "lng": -126.1580192864
            },
            {
                "lat": 50.4863818121,
                "lng": -126.1591188853
            },
            {
                "lat": 50.498440426,
                "lng": -126.2163308028
            },
            {
                "lat": 50.4731211745,
                "lng": -126.221970385
            },
            {
                "lat": 50.46923395,
                "lng": -126.2228470379
            },
            {
                "lat": 50.466972205,
                "lng": -126.2227248954
            },
            {
                "lat": 50.4629253403,
                "lng": -126.2228247006
            },
            {
                "lat": 50.4585645867,
                "lng": -126.2134706258
            },
            {
                "lat": 50.4538879799,
                "lng": -126.1963341691
            },
            {
                "lat": 50.4520185143,
                "lng": -126.1804424193
            },
            {
                "lat": 50.448280622,
                "lng": -126.1595612735
            },
            {
                "lat": 50.4470326669,
                "lng": -126.1405488361
            },
            {
                "lat": 50.4407995681,
                "lng": -126.1068961006
            },
            {
                "lat": 50.4339450952,
                "lng": -126.0751043119
            },
            {
                "lat": 50.4239730557,
                "lng": -126.0296100853
            },
            {
                "lat": 50.4199216528,
                "lng": -126.0118408631
            },
            {
                "lat": 50.4180530934,
                "lng": -126.0065457258
            },
            {
                "lat": 50.4174534792,
                "lng": -126.0020903815
            },
            {
                "lat": 50.4194825294,
                "lng": -126.002112464
            },
            {
                "lat": 50.4753157578,
                "lng": -126.0021124418
            },
            {
                "lat": 50.4753039814,
                "lng": -126.0013504402
            },
            {
                "lat": 50.4755100742,
                "lng": -126.0008157338
            },
            {
                "lat": 50.476009307,
                "lng": -126.000167377
            },
            {
                "lat": 50.476574234,
                "lng": -125.9996414492
            },
            {
                "lat": 50.4775844619,
                "lng": -125.9984900303
            },
            {
                "lat": 50.4781605904,
                "lng": -125.99704785
            },
            {
                "lat": 50.4783065233,
                "lng": -125.9950339279
            },
            {
                "lat": 50.4784473605,
                "lng": -125.9928667033
            },
            {
                "lat": 50.4778709935,
                "lng": -125.9901278716
            },
            {
                "lat": 50.4778707163,
                "lng": -125.9873889348
            },
            {
                "lat": 50.4810444991,
                "lng": -125.9823388715
            },
            {
                "lat": 50.4878229979,
                "lng": -125.9762799813
            },
            {
                "lat": 50.4937357158,
                "lng": -125.9745478048
            },
            {
                "lat": 50.4996486834,
                "lng": -125.9767153472
            },
            {
                "lat": 50.5008013756,
                "lng": -125.9819027668
            },
            {
                "lat": 50.5002248255,
                "lng": -125.9934467489
            },
            {
                "lat": 50.4976276601,
                "lng": -126.0071407298
            },
            {
                "lat": 50.4920050507,
                "lng": -126.0198359169
            },
            {
                "lat": 50.4879684988,
                "lng": -126.037719162
            },
            {
                "lat": 50.4882552739,
                "lng": -126.0508418008
            },
            {
                "lat": 50.4886895653,
                "lng": -126.0691532037
            },
            {
                "lat": 50.4910769246,
                "lng": -126.0797271678
            },
            {
                "lat": 50.4879451922,
                "lng": -126.0804670992
            },
            {
                "lat": 50.4832387409,
                "lng": -126.0805891045
            },
            {
                "lat": 50.4834133059,
                "lng": -126.0822220588
            },
            {
                "lat": 50.4848102264,
                "lng": -126.0951609774
            },
            {
                "lat": 50.4858241409,
                "lng": -126.0954512366
            },
            {
                "lat": 50.4873694315,
                "lng": -126.0955664196
            },
            {
                "lat": 50.4894784613,
                "lng": -126.097038071
            },
            {
                "lat": 50.4912258283,
                "lng": -126.0985108467
            },
            {
                "lat": 50.4926034699,
                "lng": -126.1014475517
            },
            {
                "lat": 50.4930607003,
                "lng": -126.1049339928
            },
            {
                "lat": 50.4928779538,
                "lng": -126.1069560572
            },
            {
                "lat": 50.4923285647,
                "lng": -126.1122810386
            },
            {
                "lat": 50.4915010366,
                "lng": -126.1175232179
            },
            {
                "lat": 50.4903064112,
                "lng": -126.121375402
            },
            {
                "lat": 50.4881933977,
                "lng": -126.1253279372
            },
            {
                "lat": 50.486175933,
                "lng": -126.1290891778
            },
            {
                "lat": 50.4858964868,
                "lng": -126.129875217
            },
            {
                "lat": 50.4860187986,
                "lng": -126.1337507731
            },
            {
                "lat": 50.4864694964,
                "lng": -126.1348649931
            },
            {
                "lat": 50.4872281811,
                "lng": -126.1384967209
            },
            {
                "lat": 50.4876592988,
                "lng": -126.1443480792
            },
            {
                "lat": 50.4877697954,
                "lng": -126.1478651483
            },
            {
                "lat": 50.4887431418,
                "lng": -126.1531219479
            },
            {
                "lat": 50.4889072522,
                "lng": -126.1554488352
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 456,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 12-1",
            "LABEL": "12-1",
            "FISHERY_AREA_ID": 2060,
            "OBJECTID": 600,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 91089927.911,
            "FEATURE_LENGTH_M": 50395.6372,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.47771020006486,
            "lng": -126.09057540572435
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4920469492,
                "lng": -125.8142474558
            },
            {
                "lat": 50.489899083,
                "lng": -125.8169941997
            },
            {
                "lat": 50.4958503152,
                "lng": -125.8478542179
            },
            {
                "lat": 50.4993401035,
                "lng": -125.8497621119
            },
            {
                "lat": 50.4997744158,
                "lng": -125.8550181961
            },
            {
                "lat": 50.4995580883,
                "lng": -125.8597264211
            },
            {
                "lat": 50.4998848645,
                "lng": -125.8693617405
            },
            {
                "lat": 50.4982448387,
                "lng": -125.8774644947
            },
            {
                "lat": 50.4945215609,
                "lng": -125.885673299
            },
            {
                "lat": 50.4907987906,
                "lng": -125.8921350385
            },
            {
                "lat": 50.4863086596,
                "lng": -125.9012223258
            },
            {
                "lat": 50.4824748947,
                "lng": -125.9070280855
            },
            {
                "lat": 50.4795194757,
                "lng": -125.9126126731
            },
            {
                "lat": 50.4780956546,
                "lng": -125.9137034393
            },
            {
                "lat": 50.4764483517,
                "lng": -125.9141003631
            },
            {
                "lat": 50.4748885388,
                "lng": -125.9121935181
            },
            {
                "lat": 50.4717868354,
                "lng": -125.9098362392
            },
            {
                "lat": 50.4600975566,
                "lng": -125.9009327134
            },
            {
                "lat": 50.4561765995,
                "lng": -125.8989944378
            },
            {
                "lat": 50.4577065143,
                "lng": -125.8955611662
            },
            {
                "lat": 50.4586029475,
                "lng": -125.888229923
            },
            {
                "lat": 50.4602514448,
                "lng": -125.8743216388
            },
            {
                "lat": 50.4623408635,
                "lng": -125.8637011552
            },
            {
                "lat": 50.4642863681,
                "lng": -125.8520356238
            },
            {
                "lat": 50.4662328413,
                "lng": -125.8391721563
            },
            {
                "lat": 50.4683272777,
                "lng": -125.8242186716
            },
            {
                "lat": 50.4692234199,
                "lng": -125.8171916654
            },
            {
                "lat": 50.468769598,
                "lng": -125.8117678158
            },
            {
                "lat": 50.468722765,
                "lng": -125.807060167
            },
            {
                "lat": 50.4702605963,
                "lng": -125.8017889215
            },
            {
                "lat": 50.4714807783,
                "lng": -125.7995453237
            },
            {
                "lat": 50.4738391735,
                "lng": -125.7985235831
            },
            {
                "lat": 50.4750712497,
                "lng": -125.7976001669
            },
            {
                "lat": 50.4755821248,
                "lng": -125.7944635371
            },
            {
                "lat": 50.4789013872,
                "lng": -125.7823180444
            },
            {
                "lat": 50.4789998787,
                "lng": -125.78157136
            },
            {
                "lat": 50.4791679334,
                "lng": -125.7807917446
            },
            {
                "lat": 50.4796448924,
                "lng": -125.7794723292
            },
            {
                "lat": 50.4796447609,
                "lng": -125.7765967228
            },
            {
                "lat": 50.479645168,
                "lng": -125.7712789613
            },
            {
                "lat": 50.4792027221,
                "lng": -125.7648551986
            },
            {
                "lat": 50.4798661455,
                "lng": -125.7615356123
            },
            {
                "lat": 50.4811931365,
                "lng": -125.7599867827
            },
            {
                "lat": 50.4842943831,
                "lng": -125.7591015219
            },
            {
                "lat": 50.4889451745,
                "lng": -125.7591013606
            },
            {
                "lat": 50.4920469352,
                "lng": -125.7582088339
            },
            {
                "lat": 50.496475287,
                "lng": -125.7613147142
            },
            {
                "lat": 50.4982495112,
                "lng": -125.7686233553
            },
            {
                "lat": 50.4978067273,
                "lng": -125.7750472306
            },
            {
                "lat": 50.4964751431,
                "lng": -125.7881090894
            },
            {
                "lat": 50.4940407457,
                "lng": -125.8020633826
            },
            {
                "lat": 50.49315635,
                "lng": -125.809150911
            },
            {
                "lat": 50.4920469492,
                "lng": -125.8142474558
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 457,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 36,
            "MANAGEMENT_AREA_NAME": "Subarea 13-36",
            "LABEL": "13-36",
            "FISHERY_AREA_ID": 2141,
            "OBJECTID": 601,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 31772594.2911,
            "FEATURE_LENGTH_M": 27393.3349,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.48087201452831,
            "lng": -125.82938522824719
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4842943831,
                "lng": -125.7591015219
            },
            {
                "lat": 50.4811931365,
                "lng": -125.7599867827
            },
            {
                "lat": 50.4798661455,
                "lng": -125.7615356123
            },
            {
                "lat": 50.4792027221,
                "lng": -125.7648551986
            },
            {
                "lat": 50.479645168,
                "lng": -125.7712789613
            },
            {
                "lat": 50.4796447609,
                "lng": -125.7765967228
            },
            {
                "lat": 50.4796448924,
                "lng": -125.7794723292
            },
            {
                "lat": 50.479137211,
                "lng": -125.7783277521
            },
            {
                "lat": 50.4784014571,
                "lng": -125.7763139159
            },
            {
                "lat": 50.4778826965,
                "lng": -125.7741536922
            },
            {
                "lat": 50.4774935003,
                "lng": -125.7724835681
            },
            {
                "lat": 50.4768217244,
                "lng": -125.7713170506
            },
            {
                "lat": 50.4757846016,
                "lng": -125.7694547305
            },
            {
                "lat": 50.4751131685,
                "lng": -125.769280215
            },
            {
                "lat": 50.4736219714,
                "lng": -125.7696992212
            },
            {
                "lat": 50.4667776113,
                "lng": -125.7691572411
            },
            {
                "lat": 50.4670184983,
                "lng": -125.7688750534
            },
            {
                "lat": 50.4676017917,
                "lng": -125.7679833602
            },
            {
                "lat": 50.4681278563,
                "lng": -125.766854142
            },
            {
                "lat": 50.4696772983,
                "lng": -125.763968515
            },
            {
                "lat": 50.4701197677,
                "lng": -125.7628933602
            },
            {
                "lat": 50.4716716347,
                "lng": -125.7591019544
            },
            {
                "lat": 50.4738882545,
                "lng": -125.7480234227
            },
            {
                "lat": 50.4756591673,
                "lng": -125.7360690014
            },
            {
                "lat": 50.4765435938,
                "lng": -125.7265390133
            },
            {
                "lat": 50.4780957186,
                "lng": -125.7041782668
            },
            {
                "lat": 50.4829672465,
                "lng": -125.6944271898
            },
            {
                "lat": 50.4893913667,
                "lng": -125.6915514946
            },
            {
                "lat": 50.4942618735,
                "lng": -125.6975330307
            },
            {
                "lat": 50.4951480594,
                "lng": -125.7052844084
            },
            {
                "lat": 50.4938199479,
                "lng": -125.7254331376
            },
            {
                "lat": 50.492046485,
                "lng": -125.7362903729
            },
            {
                "lat": 50.4873961785,
                "lng": -125.7493516727
            },
            {
                "lat": 50.4856265831,
                "lng": -125.7553328803
            },
            {
                "lat": 50.4842943831,
                "lng": -125.7591015219
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 458,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 38,
            "MANAGEMENT_AREA_NAME": "Subarea 13-38",
            "LABEL": "13-38",
            "FISHERY_AREA_ID": 2143,
            "OBJECTID": 602,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 9035114.5672,
            "FEATURE_LENGTH_M": 15479.4415,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.479082310157125,
            "lng": -125.75262303753716
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4796448924,
                "lng": -125.7794723292
            },
            {
                "lat": 50.4791679334,
                "lng": -125.7807917446
            },
            {
                "lat": 50.4789998787,
                "lng": -125.78157136
            },
            {
                "lat": 50.4789013872,
                "lng": -125.7823180444
            },
            {
                "lat": 50.4755821248,
                "lng": -125.7944635371
            },
            {
                "lat": 50.4750712497,
                "lng": -125.7976001669
            },
            {
                "lat": 50.4738391735,
                "lng": -125.7985235831
            },
            {
                "lat": 50.4714807783,
                "lng": -125.7995453237
            },
            {
                "lat": 50.4679942336,
                "lng": -125.7978062967
            },
            {
                "lat": 50.4653250092,
                "lng": -125.7928773755
            },
            {
                "lat": 50.4628638022,
                "lng": -125.7849810577
            },
            {
                "lat": 50.4611211576,
                "lng": -125.7789305179
            },
            {
                "lat": 50.4604876812,
                "lng": -125.7763973965
            },
            {
                "lat": 50.4567379206,
                "lng": -125.7719118958
            },
            {
                "lat": 50.454040543,
                "lng": -125.7718506863
            },
            {
                "lat": 50.4516833161,
                "lng": -125.7714382145
            },
            {
                "lat": 50.4492189848,
                "lng": -125.7686685809
            },
            {
                "lat": 50.4440919755,
                "lng": -125.7629243788
            },
            {
                "lat": 50.4407041938,
                "lng": -125.7570726673
            },
            {
                "lat": 50.4390638927,
                "lng": -125.7515334927
            },
            {
                "lat": 50.4385373586,
                "lng": -125.7501377344
            },
            {
                "lat": 50.4286338912,
                "lng": -125.7382885708
            },
            {
                "lat": 50.4270555074,
                "lng": -125.7376861188
            },
            {
                "lat": 50.4254535056,
                "lng": -125.734481835
            },
            {
                "lat": 50.4255106748,
                "lng": -125.7165066919
            },
            {
                "lat": 50.4277993372,
                "lng": -125.713073528
            },
            {
                "lat": 50.4306025097,
                "lng": -125.7138222573
            },
            {
                "lat": 50.4351801013,
                "lng": -125.7169036794
            },
            {
                "lat": 50.4402230496,
                "lng": -125.7251199237
            },
            {
                "lat": 50.445645013,
                "lng": -125.7333445644
            },
            {
                "lat": 50.4526481487,
                "lng": -125.7417523107
            },
            {
                "lat": 50.4590953278,
                "lng": -125.7499702434
            },
            {
                "lat": 50.4648894866,
                "lng": -125.7552030935
            },
            {
                "lat": 50.4686240983,
                "lng": -125.7604367597
            },
            {
                "lat": 50.4701197677,
                "lng": -125.7628933602
            },
            {
                "lat": 50.4696772983,
                "lng": -125.763968515
            },
            {
                "lat": 50.4681278563,
                "lng": -125.766854142
            },
            {
                "lat": 50.4676017917,
                "lng": -125.7679833602
            },
            {
                "lat": 50.4670184983,
                "lng": -125.7688750534
            },
            {
                "lat": 50.4667776113,
                "lng": -125.7691572411
            },
            {
                "lat": 50.4736219714,
                "lng": -125.7696992212
            },
            {
                "lat": 50.4751131685,
                "lng": -125.769280215
            },
            {
                "lat": 50.4757846016,
                "lng": -125.7694547305
            },
            {
                "lat": 50.4768217244,
                "lng": -125.7713170506
            },
            {
                "lat": 50.4774935003,
                "lng": -125.7724835681
            },
            {
                "lat": 50.4778826965,
                "lng": -125.7741536922
            },
            {
                "lat": 50.4784014571,
                "lng": -125.7763139159
            },
            {
                "lat": 50.479137211,
                "lng": -125.7783277521
            },
            {
                "lat": 50.4796448924,
                "lng": -125.7794723292
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 459,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 39,
            "MANAGEMENT_AREA_NAME": "Subarea 13-39",
            "LABEL": "13-39",
            "FISHERY_AREA_ID": 2144,
            "OBJECTID": 603,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 12029404.7553,
            "FEATURE_LENGTH_M": 19087.9345,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.459982493569385,
            "lng": -125.76423755321024
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4778707163,
                "lng": -125.9873889348
            },
            {
                "lat": 50.4778709935,
                "lng": -125.9901278716
            },
            {
                "lat": 50.4784473605,
                "lng": -125.9928667033
            },
            {
                "lat": 50.4783065233,
                "lng": -125.9950339279
            },
            {
                "lat": 50.4781605904,
                "lng": -125.99704785
            },
            {
                "lat": 50.4775844619,
                "lng": -125.9984900303
            },
            {
                "lat": 50.476574234,
                "lng": -125.9996414492
            },
            {
                "lat": 50.476009307,
                "lng": -126.000167377
            },
            {
                "lat": 50.4755100742,
                "lng": -126.0008157338
            },
            {
                "lat": 50.4753039814,
                "lng": -126.0013504402
            },
            {
                "lat": 50.4753157578,
                "lng": -126.0021124418
            },
            {
                "lat": 50.4194825294,
                "lng": -126.002112464
            },
            {
                "lat": 50.4279098534,
                "lng": -125.9889217509
            },
            {
                "lat": 50.4381439329,
                "lng": -125.9737552717
            },
            {
                "lat": 50.4381909452,
                "lng": -125.9730836633
            },
            {
                "lat": 50.4383081355,
                "lng": -125.9723662263
            },
            {
                "lat": 50.438411758,
                "lng": -125.9712826456
            },
            {
                "lat": 50.4384771734,
                "lng": -125.9701999583
            },
            {
                "lat": 50.4389766106,
                "lng": -125.9668882806
            },
            {
                "lat": 50.4393770055,
                "lng": -125.9632870109
            },
            {
                "lat": 50.4383769255,
                "lng": -125.9587785771
            },
            {
                "lat": 50.4392780804,
                "lng": -125.9526672988
            },
            {
                "lat": 50.4382745139,
                "lng": -125.9464568005
            },
            {
                "lat": 50.4365727463,
                "lng": -125.9346311889
            },
            {
                "lat": 50.4374735048,
                "lng": -125.9282230581
            },
            {
                "lat": 50.4406820195,
                "lng": -125.9172052294
            },
            {
                "lat": 50.4431838455,
                "lng": -125.9085843438
            },
            {
                "lat": 50.4491953361,
                "lng": -125.9036788583
            },
            {
                "lat": 50.4543039149,
                "lng": -125.9016718528
            },
            {
                "lat": 50.4561765995,
                "lng": -125.8989944378
            },
            {
                "lat": 50.4600975566,
                "lng": -125.9009327134
            },
            {
                "lat": 50.4717868354,
                "lng": -125.9098362392
            },
            {
                "lat": 50.4748885388,
                "lng": -125.9121935181
            },
            {
                "lat": 50.4764483517,
                "lng": -125.9141003631
            },
            {
                "lat": 50.4761315987,
                "lng": -125.9147792858
            },
            {
                "lat": 50.4757379846,
                "lng": -125.9155649422
            },
            {
                "lat": 50.4757879729,
                "lng": -125.9178235481
            },
            {
                "lat": 50.4759323723,
                "lng": -125.9202423212
            },
            {
                "lat": 50.4758114984,
                "lng": -125.9224245875
            },
            {
                "lat": 50.4753676945,
                "lng": -125.9237978233
            },
            {
                "lat": 50.4749489994,
                "lng": -125.9249639834
            },
            {
                "lat": 50.4749676012,
                "lng": -125.9293517674
            },
            {
                "lat": 50.4748953145,
                "lng": -125.9295879646
            },
            {
                "lat": 50.4739801304,
                "lng": -125.9363705031
            },
            {
                "lat": 50.4750325636,
                "lng": -125.9426878152
            },
            {
                "lat": 50.4750781959,
                "lng": -125.9429626024
            },
            {
                "lat": 50.4750780352,
                "lng": -125.9521337891
            },
            {
                "lat": 50.4739800144,
                "lng": -125.9618379894
            },
            {
                "lat": 50.4737962886,
                "lng": -125.9667816919
            },
            {
                "lat": 50.475170052,
                "lng": -125.9715041493
            },
            {
                "lat": 50.4752350441,
                "lng": -125.9851530311
            },
            {
                "lat": 50.4778707163,
                "lng": -125.9873889348
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 460,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 35,
            "MANAGEMENT_AREA_NAME": "Subarea 13-35",
            "LABEL": "13-35",
            "FISHERY_AREA_ID": 2140,
            "OBJECTID": 604,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 30250892.4709,
            "FEATURE_LENGTH_M": 24792.5058,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.462226399809616,
            "lng": -125.95346637001154
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.476436373,
                "lng": -125.3341598155
            },
            {
                "lat": 50.4712558823,
                "lng": -125.3384089572
            },
            {
                "lat": 50.4677776493,
                "lng": -125.3975444224
            },
            {
                "lat": 50.458629673,
                "lng": -125.3966670024
            },
            {
                "lat": 50.4580464307,
                "lng": -125.3943411717
            },
            {
                "lat": 50.4573747353,
                "lng": -125.3913570148
            },
            {
                "lat": 50.4551393204,
                "lng": -125.386513273
            },
            {
                "lat": 50.4527473622,
                "lng": -125.38243907
            },
            {
                "lat": 50.4546854974,
                "lng": -125.3795314018
            },
            {
                "lat": 50.4523081006,
                "lng": -125.36953777
            },
            {
                "lat": 50.4468344115,
                "lng": -125.3524094456
            },
            {
                "lat": 50.4399335623,
                "lng": -125.3333740605
            },
            {
                "lat": 50.430416195,
                "lng": -125.3331378336
            },
            {
                "lat": 50.4120937275,
                "lng": -125.3457489703
            },
            {
                "lat": 50.4020993387,
                "lng": -125.3585966423
            },
            {
                "lat": 50.4025769191,
                "lng": -125.369300857
            },
            {
                "lat": 50.4061474683,
                "lng": -125.3819120597
            },
            {
                "lat": 50.4052460576,
                "lng": -125.3891070828
            },
            {
                "lat": 50.405192762,
                "lng": -125.3895264852
            },
            {
                "lat": 50.400672367,
                "lng": -125.3911899729
            },
            {
                "lat": 50.3937726856,
                "lng": -125.3911896685
            },
            {
                "lat": 50.3862831626,
                "lng": -125.3877112182
            },
            {
                "lat": 50.3720363742,
                "lng": -125.3908616825
            },
            {
                "lat": 50.3668445302,
                "lng": -125.3836671046
            },
            {
                "lat": 50.3542097799,
                "lng": -125.3795085986
            },
            {
                "lat": 50.3490560706,
                "lng": -125.3783335486
            },
            {
                "lat": 50.3449441015,
                "lng": -125.3640973183
            },
            {
                "lat": 50.3390353097,
                "lng": -125.3463595585
            },
            {
                "lat": 50.3324660013,
                "lng": -125.3240283852
            },
            {
                "lat": 50.3278648002,
                "lng": -125.3056343385
            },
            {
                "lat": 50.3337787325,
                "lng": -125.2898641254
            },
            {
                "lat": 50.3475716411,
                "lng": -125.2865835948
            },
            {
                "lat": 50.3679353449,
                "lng": -125.2970954347
            },
            {
                "lat": 50.3935548507,
                "lng": -125.3049772723
            },
            {
                "lat": 50.4080091304,
                "lng": -125.2977529955
            },
            {
                "lat": 50.4244303121,
                "lng": -125.2859261514
            },
            {
                "lat": 50.4349402664,
                "lng": -125.2819822858
            },
            {
                "lat": 50.4433559298,
                "lng": -125.2866674618
            },
            {
                "lat": 50.4552875737,
                "lng": -125.3089141234
            },
            {
                "lat": 50.4577065716,
                "lng": -125.3084033212
            },
            {
                "lat": 50.4644202901,
                "lng": -125.3064878758
            },
            {
                "lat": 50.4673921978,
                "lng": -125.3093647244
            },
            {
                "lat": 50.4715123333,
                "lng": -125.3192371716
            },
            {
                "lat": 50.4753494907,
                "lng": -125.3281553843
            },
            {
                "lat": 50.476436373,
                "lng": -125.3341598155
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 461,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 26,
            "MANAGEMENT_AREA_NAME": "Subarea 13-26",
            "LABEL": "13-26",
            "FISHERY_AREA_ID": 2131,
            "OBJECTID": 605,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 79809995.4915,
            "FEATURE_LENGTH_M": 49895.6926,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.41652905971333,
            "lng": -125.34692814373558
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4677776493,
                "lng": -125.3975444224
            },
            {
                "lat": 50.4691619106,
                "lng": -125.3982614448
            },
            {
                "lat": 50.4700547468,
                "lng": -125.3992836555
            },
            {
                "lat": 50.4699590576,
                "lng": -125.4019477172
            },
            {
                "lat": 50.4697300443,
                "lng": -125.4039537831
            },
            {
                "lat": 50.4679303253,
                "lng": -125.4067080462
            },
            {
                "lat": 50.4664271924,
                "lng": -125.4081185559
            },
            {
                "lat": 50.4535328011,
                "lng": -125.5091475247
            },
            {
                "lat": 50.453879811,
                "lng": -125.5105358489
            },
            {
                "lat": 50.4544413264,
                "lng": -125.5139691187
            },
            {
                "lat": 50.4545554163,
                "lng": -125.5174644971
            },
            {
                "lat": 50.4550629627,
                "lng": -125.522239535
            },
            {
                "lat": 50.4544980489,
                "lng": -125.5265733342
            },
            {
                "lat": 50.4531475314,
                "lng": -125.5295028409
            },
            {
                "lat": 50.4519649244,
                "lng": -125.5309838082
            },
            {
                "lat": 50.4509391764,
                "lng": -125.5294571763
            },
            {
                "lat": 50.4462131234,
                "lng": -125.5249100165
            },
            {
                "lat": 50.4429895323,
                "lng": -125.5245064086
            },
            {
                "lat": 50.4027865835,
                "lng": -125.5195000122
            },
            {
                "lat": 50.3992801695,
                "lng": -125.5190658925
            },
            {
                "lat": 50.391616529,
                "lng": -125.5154033143
            },
            {
                "lat": 50.3836444689,
                "lng": -125.5092238931
            },
            {
                "lat": 50.3865894362,
                "lng": -125.5016175205
            },
            {
                "lat": 50.3901063279,
                "lng": -125.4961395304
            },
            {
                "lat": 50.3909225796,
                "lng": -125.4908986206
            },
            {
                "lat": 50.3931311133,
                "lng": -125.485831618
            },
            {
                "lat": 50.3952603272,
                "lng": -125.4819864971
            },
            {
                "lat": 50.40008587,
                "lng": -125.480674424
            },
            {
                "lat": 50.4054839002,
                "lng": -125.4795305198
            },
            {
                "lat": 50.409819996,
                "lng": -125.4811704302
            },
            {
                "lat": 50.4110952406,
                "lng": -125.4808883648
            },
            {
                "lat": 50.4300881182,
                "lng": -125.4472356932
            },
            {
                "lat": 50.431103251,
                "lng": -125.4426952512
            },
            {
                "lat": 50.432170713,
                "lng": -125.43181631
            },
            {
                "lat": 50.4360124614,
                "lng": -125.4224239876
            },
            {
                "lat": 50.437721539,
                "lng": -125.412605342
            },
            {
                "lat": 50.436652882,
                "lng": -125.4051358233
            },
            {
                "lat": 50.4343038189,
                "lng": -125.3978729506
            },
            {
                "lat": 50.4368669373,
                "lng": -125.3893351705
            },
            {
                "lat": 50.4430581003,
                "lng": -125.3850707974
            },
            {
                "lat": 50.4527473622,
                "lng": -125.38243907
            },
            {
                "lat": 50.4551393204,
                "lng": -125.386513273
            },
            {
                "lat": 50.4573747353,
                "lng": -125.3913570148
            },
            {
                "lat": 50.4580464307,
                "lng": -125.3943411717
            },
            {
                "lat": 50.458629673,
                "lng": -125.3966670024
            },
            {
                "lat": 50.4677776493,
                "lng": -125.3975444224
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 462,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 25,
            "MANAGEMENT_AREA_NAME": "Subarea 13-25",
            "LABEL": "13-25",
            "FISHERY_AREA_ID": 2130,
            "OBJECTID": 606,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 45825627.3511,
            "FEATURE_LENGTH_M": 33855.0757,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.43651698075,
            "lng": -125.45826286199564
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4714807783,
                "lng": -125.7995453237
            },
            {
                "lat": 50.4702605963,
                "lng": -125.8017889215
            },
            {
                "lat": 50.468722765,
                "lng": -125.807060167
            },
            {
                "lat": 50.468769598,
                "lng": -125.8117678158
            },
            {
                "lat": 50.4692234199,
                "lng": -125.8171916654
            },
            {
                "lat": 50.4683272777,
                "lng": -125.8242186716
            },
            {
                "lat": 50.4662328413,
                "lng": -125.8391721563
            },
            {
                "lat": 50.4642863681,
                "lng": -125.8520356238
            },
            {
                "lat": 50.4623408635,
                "lng": -125.8637011552
            },
            {
                "lat": 50.4602514448,
                "lng": -125.8743216388
            },
            {
                "lat": 50.4586029475,
                "lng": -125.888229923
            },
            {
                "lat": 50.4577065143,
                "lng": -125.8955611662
            },
            {
                "lat": 50.4561765995,
                "lng": -125.8989944378
            },
            {
                "lat": 50.4543039149,
                "lng": -125.9016718528
            },
            {
                "lat": 50.4491953361,
                "lng": -125.9036788583
            },
            {
                "lat": 50.4431838455,
                "lng": -125.9085843438
            },
            {
                "lat": 50.4406820195,
                "lng": -125.9172052294
            },
            {
                "lat": 50.4374735048,
                "lng": -125.9282230581
            },
            {
                "lat": 50.4365727463,
                "lng": -125.9346311889
            },
            {
                "lat": 50.4382745139,
                "lng": -125.9464568005
            },
            {
                "lat": 50.4392780804,
                "lng": -125.9526672988
            },
            {
                "lat": 50.4383769255,
                "lng": -125.9587785771
            },
            {
                "lat": 50.4393770055,
                "lng": -125.9632870109
            },
            {
                "lat": 50.4389766106,
                "lng": -125.9668882806
            },
            {
                "lat": 50.4384771734,
                "lng": -125.9701999583
            },
            {
                "lat": 50.438411758,
                "lng": -125.9712826456
            },
            {
                "lat": 50.437866134,
                "lng": -125.9700692064
            },
            {
                "lat": 50.4375723177,
                "lng": -125.9696574759
            },
            {
                "lat": 50.43817555,
                "lng": -125.9679952645
            },
            {
                "lat": 50.438175582,
                "lng": -125.9661943878
            },
            {
                "lat": 50.4374738329,
                "lng": -125.9633862649
            },
            {
                "lat": 50.4342688784,
                "lng": -125.9571761039
            },
            {
                "lat": 50.4292602668,
                "lng": -125.952163863
            },
            {
                "lat": 50.4288670043,
                "lng": -125.9518201875
            },
            {
                "lat": 50.4217981225,
                "lng": -125.9350969078
            },
            {
                "lat": 50.4214440991,
                "lng": -125.9319307634
            },
            {
                "lat": 50.420043645,
                "lng": -125.9252170532
            },
            {
                "lat": 50.4192426883,
                "lng": -125.9202121715
            },
            {
                "lat": 50.4185412104,
                "lng": -125.9126962966
            },
            {
                "lat": 50.4191391561,
                "lng": -125.9069824389
            },
            {
                "lat": 50.4222487604,
                "lng": -125.8962634517
            },
            {
                "lat": 50.42425127,
                "lng": -125.8847432867
            },
            {
                "lat": 50.424751554,
                "lng": -125.8733213596
            },
            {
                "lat": 50.4245523853,
                "lng": -125.8640060928
            },
            {
                "lat": 50.4219203216,
                "lng": -125.8565368041
            },
            {
                "lat": 50.4208648402,
                "lng": -125.855056714
            },
            {
                "lat": 50.4197424007,
                "lng": -125.8534924455
            },
            {
                "lat": 50.4178390692,
                "lng": -125.8439703857
            },
            {
                "lat": 50.4166376349,
                "lng": -125.8345565329
            },
            {
                "lat": 50.4158368944,
                "lng": -125.8233337105
            },
            {
                "lat": 50.4159357131,
                "lng": -125.8127128187
            },
            {
                "lat": 50.4171372429,
                "lng": -125.8044044069
            },
            {
                "lat": 50.4184420748,
                "lng": -125.7921834352
            },
            {
                "lat": 50.4214444968,
                "lng": -125.7777564367
            },
            {
                "lat": 50.4240495999,
                "lng": -125.7702405252
            },
            {
                "lat": 50.4262545338,
                "lng": -125.7649301549
            },
            {
                "lat": 50.4273573076,
                "lng": -125.7570186042
            },
            {
                "lat": 50.4266550791,
                "lng": -125.74810031
            },
            {
                "lat": 50.426856613,
                "lng": -125.7438964068
            },
            {
                "lat": 50.4270555074,
                "lng": -125.7376861188
            },
            {
                "lat": 50.4286338912,
                "lng": -125.7382885708
            },
            {
                "lat": 50.4385373586,
                "lng": -125.7501377344
            },
            {
                "lat": 50.4390638927,
                "lng": -125.7515334927
            },
            {
                "lat": 50.4407041938,
                "lng": -125.7570726673
            },
            {
                "lat": 50.4440919755,
                "lng": -125.7629243788
            },
            {
                "lat": 50.4492189848,
                "lng": -125.7686685809
            },
            {
                "lat": 50.4516833161,
                "lng": -125.7714382145
            },
            {
                "lat": 50.454040543,
                "lng": -125.7718506863
            },
            {
                "lat": 50.4567379206,
                "lng": -125.7719118958
            },
            {
                "lat": 50.4604876812,
                "lng": -125.7763973965
            },
            {
                "lat": 50.4611211576,
                "lng": -125.7789305179
            },
            {
                "lat": 50.4628638022,
                "lng": -125.7849810577
            },
            {
                "lat": 50.4653250092,
                "lng": -125.7928773755
            },
            {
                "lat": 50.4679942336,
                "lng": -125.7978062967
            },
            {
                "lat": 50.4714807783,
                "lng": -125.7995453237
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 463,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 607,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 56454495.2492,
            "FEATURE_LENGTH_M": 38601.5822,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.440115359648004,
            "lng": -125.85728421793196
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.911079102,
                "lng": -127.7235714304
            },
            {
                "lat": 50.908390085,
                "lng": -127.7250513606
            },
            {
                "lat": 50.9043688356,
                "lng": -127.7307507937
            },
            {
                "lat": 50.9016879202,
                "lng": -127.7338787902
            },
            {
                "lat": 50.901130296,
                "lng": -127.7371147843
            },
            {
                "lat": 50.9019087861,
                "lng": -127.7408591271
            },
            {
                "lat": 50.9042548782,
                "lng": -127.7462154757
            },
            {
                "lat": 50.9066585169,
                "lng": -127.7534785999
            },
            {
                "lat": 50.9070695679,
                "lng": -127.7554476289
            },
            {
                "lat": 50.9042010577,
                "lng": -127.7649688967
            },
            {
                "lat": 50.9015467812,
                "lng": -127.7687302102
            },
            {
                "lat": 50.9007299051,
                "lng": -127.7719644104
            },
            {
                "lat": 50.9008672471,
                "lng": -127.7760236937
            },
            {
                "lat": 50.8820113728,
                "lng": -127.8385922033
            },
            {
                "lat": 50.8811383586,
                "lng": -127.8403320544
            },
            {
                "lat": 50.8808667542,
                "lng": -127.8416902521
            },
            {
                "lat": 50.8806758042,
                "lng": -127.8430250839
            },
            {
                "lat": 50.8800846807,
                "lng": -127.8398058261
            },
            {
                "lat": 50.8795314506,
                "lng": -127.8217007008
            },
            {
                "lat": 50.877735121,
                "lng": -127.8085713731
            },
            {
                "lat": 50.8745570766,
                "lng": -127.7947466494
            },
            {
                "lat": 50.8708226747,
                "lng": -127.778160136
            },
            {
                "lat": 50.8687514721,
                "lng": -127.7633745877
            },
            {
                "lat": 50.8647423598,
                "lng": -127.7492746742
            },
            {
                "lat": 50.8615641323,
                "lng": -127.7404250249
            },
            {
                "lat": 50.8585242252,
                "lng": -127.7314451672
            },
            {
                "lat": 50.8558960215,
                "lng": -127.7188637187
            },
            {
                "lat": 50.8532718153,
                "lng": -127.7044898433
            },
            {
                "lat": 50.8491249256,
                "lng": -127.6902538842
            },
            {
                "lat": 50.8448412996,
                "lng": -127.6816864459
            },
            {
                "lat": 50.8411062922,
                "lng": -127.671455059
            },
            {
                "lat": 50.8377907493,
                "lng": -127.6631623864
            },
            {
                "lat": 50.8362695506,
                "lng": -127.6590109249
            },
            {
                "lat": 50.8319128693,
                "lng": -127.6545861263
            },
            {
                "lat": 50.911079102,
                "lng": -127.7235714304
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 395,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 539,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 36794089.1893,
            "FEATURE_LENGTH_M": 34543.9865,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.879319745348575,
            "lng": -127.75103653582856
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.9040939978,
                "lng": -125.5202021827
            },
            {
                "lat": 50.9039884203,
                "lng": -125.5304028248
            },
            {
                "lat": 50.9039914793,
                "lng": -125.5651315688
            },
            {
                "lat": 50.9052886819,
                "lng": -125.5677640503
            },
            {
                "lat": 50.9090998321,
                "lng": -125.5752717989
            },
            {
                "lat": 50.9141083321,
                "lng": -125.5841680676
            },
            {
                "lat": 50.9163318603,
                "lng": -125.5925134406
            },
            {
                "lat": 50.9182781252,
                "lng": -125.5994644423
            },
            {
                "lat": 50.9160534245,
                "lng": -125.6119773711
            },
            {
                "lat": 50.9138297748,
                "lng": -125.62505301
            },
            {
                "lat": 50.9102128178,
                "lng": -125.6364521359
            },
            {
                "lat": 50.9040942425,
                "lng": -125.6509170288
            },
            {
                "lat": 50.8979762418,
                "lng": -125.6662138443
            },
            {
                "lat": 50.8912998558,
                "lng": -125.6801153907
            },
            {
                "lat": 50.8832362135,
                "lng": -125.6929092994
            },
            {
                "lat": 50.877117077,
                "lng": -125.6954116498
            },
            {
                "lat": 50.8674125422,
                "lng": -125.6957399006
            },
            {
                "lat": 50.8662336573,
                "lng": -125.6867212581
            },
            {
                "lat": 50.8659784447,
                "lng": -125.681014542
            },
            {
                "lat": 50.8658528341,
                "lng": -125.6788934155
            },
            {
                "lat": 50.8545605646,
                "lng": -125.6466679773
            },
            {
                "lat": 50.8518408672,
                "lng": -125.6388782531
            },
            {
                "lat": 50.8551485397,
                "lng": -125.6345054499
            },
            {
                "lat": 50.8573718903,
                "lng": -125.6300583466
            },
            {
                "lat": 50.8573722393,
                "lng": -125.626441441
            },
            {
                "lat": 50.8587605659,
                "lng": -125.6203233168
            },
            {
                "lat": 50.8615420538,
                "lng": -125.6136481107
            },
            {
                "lat": 50.8640438395,
                "lng": -125.6086432968
            },
            {
                "lat": 50.8626563441,
                "lng": -125.6019663568
            },
            {
                "lat": 50.8604312387,
                "lng": -125.5933462244
            },
            {
                "lat": 50.8593174754,
                "lng": -125.5827788082
            },
            {
                "lat": 50.8565375465,
                "lng": -125.5736004942
            },
            {
                "lat": 50.8554231103,
                "lng": -125.5624774465
            },
            {
                "lat": 50.8579297923,
                "lng": -125.5477371993
            },
            {
                "lat": 50.862098352,
                "lng": -125.5393906162
            },
            {
                "lat": 50.8715547734,
                "lng": -125.5299382895
            },
            {
                "lat": 50.8829578834,
                "lng": -125.5249334727
            },
            {
                "lat": 50.8924144276,
                "lng": -125.5215910708
            },
            {
                "lat": 50.8999215761,
                "lng": -125.5210349156
            },
            {
                "lat": 50.9040939978,
                "lng": -125.5202021827
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 396,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 31,
            "MANAGEMENT_AREA_NAME": "Subarea 12-31",
            "LABEL": "12-31",
            "FISHERY_AREA_ID": 2089,
            "OBJECTID": 540,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 60727018.686,
            "FEATURE_LENGTH_M": 32819.5918,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.881511373322496,
            "lng": -125.6043625122825
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8638076999,
                "lng": -128.0632784663
            },
            {
                "lat": 50.8735805347,
                "lng": -128.0565337892
            },
            {
                "lat": 50.8749923911,
                "lng": -128.0546568139
            },
            {
                "lat": 50.8757851846,
                "lng": -128.0534359575
            },
            {
                "lat": 50.8760563672,
                "lng": -128.0531007521
            },
            {
                "lat": 50.8765339324,
                "lng": -128.0530248791
            },
            {
                "lat": 50.876701839,
                "lng": -128.0530245823
            },
            {
                "lat": 50.8770297716,
                "lng": -128.0530245372
            },
            {
                "lat": 50.7865366748,
                "lng": -128.4310303619
            },
            {
                "lat": 50.7844888372,
                "lng": -128.4295808197
            },
            {
                "lat": 50.7798916853,
                "lng": -128.4259494696
            },
            {
                "lat": 50.7765541326,
                "lng": -128.4233089481
            },
            {
                "lat": 50.7749254606,
                "lng": -128.4216919688
            },
            {
                "lat": 50.7742993489,
                "lng": -128.4192197837
            },
            {
                "lat": 50.7737124398,
                "lng": -128.415008904
            },
            {
                "lat": 50.7742572517,
                "lng": -128.4096221429
            },
            {
                "lat": 50.7745471576,
                "lng": -128.4072721614
            },
            {
                "lat": 50.7745471046,
                "lng": -128.4042207006
            },
            {
                "lat": 50.7747578364,
                "lng": -128.3936153035
            },
            {
                "lat": 50.7777672642,
                "lng": -128.3809652025
            },
            {
                "lat": 50.7783703354,
                "lng": -128.368942271
            },
            {
                "lat": 50.7801749019,
                "lng": -128.3617246083
            },
            {
                "lat": 50.7843851487,
                "lng": -128.3526919268
            },
            {
                "lat": 50.7873960872,
                "lng": -128.3376465363
            },
            {
                "lat": 50.7884637009,
                "lng": -128.3267977474
            },
            {
                "lat": 50.7910036079,
                "lng": -128.3009487984
            },
            {
                "lat": 50.791606604,
                "lng": -128.2654421034
            },
            {
                "lat": 50.7964216678,
                "lng": -128.2383735424
            },
            {
                "lat": 50.8114626897,
                "lng": -128.2076872192
            },
            {
                "lat": 50.8240972814,
                "lng": -128.1685784842
            },
            {
                "lat": 50.8277094984,
                "lng": -128.145111499
            },
            {
                "lat": 50.8306850693,
                "lng": -128.1254571597
            },
            {
                "lat": 50.8505709657,
                "lng": -128.0909573714
            },
            {
                "lat": 50.862605881,
                "lng": -128.0711068655
            },
            {
                "lat": 50.8638076999,
                "lng": -128.0632784663
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 397,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 12-14",
            "LABEL": "12-14",
            "FISHERY_AREA_ID": 2072,
            "OBJECTID": 541,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 71939260.3821,
            "FEATURE_LENGTH_M": 59661.6369,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.813986687239996,
            "lng": -128.25218028981715
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8788946206,
                "lng": -127.1790694595
            },
            {
                "lat": 50.8756256888,
                "lng": -127.1793441416
            },
            {
                "lat": 50.8738633871,
                "lng": -127.1793589904
            },
            {
                "lat": 50.8724593848,
                "lng": -127.17920017
            },
            {
                "lat": 50.8716166181,
                "lng": -127.4337770565
            },
            {
                "lat": 50.7687719299,
                "lng": -127.1204453
            },
            {
                "lat": 50.6896093137,
                "lng": -126.8100732208
            },
            {
                "lat": 50.6806489326,
                "lng": -126.6575326269
            },
            {
                "lat": 50.7830165699,
                "lng": -126.8484566423
            },
            {
                "lat": 50.8176571329,
                "lng": -127.0142976508
            },
            {
                "lat": 50.8182026455,
                "lng": -127.0146864199
            },
            {
                "lat": 50.8195767134,
                "lng": -127.0177386862
            },
            {
                "lat": 50.8210528083,
                "lng": -127.0217212405
            },
            {
                "lat": 50.8213692822,
                "lng": -127.0254830616
            },
            {
                "lat": 50.8233714625,
                "lng": -127.0315015651
            },
            {
                "lat": 50.8340340755,
                "lng": -127.0635759852
            },
            {
                "lat": 50.8412941297,
                "lng": -127.0669097706
            },
            {
                "lat": 50.8466608198,
                "lng": -127.073203663
            },
            {
                "lat": 50.8522602553,
                "lng": -127.0872039629
            },
            {
                "lat": 50.8573952034,
                "lng": -127.1019054229
            },
            {
                "lat": 50.8587943176,
                "lng": -127.1140361258
            },
            {
                "lat": 50.8643954577,
                "lng": -127.1278073237
            },
            {
                "lat": 50.8769949131,
                "lng": -127.1469344303
            },
            {
                "lat": 50.8823579431,
                "lng": -127.1637338555
            },
            {
                "lat": 50.8814281289,
                "lng": -127.1763384827
            },
            {
                "lat": 50.8788946206,
                "lng": -127.1790694595
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 398,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 12-7",
            "LABEL": "12-7",
            "FISHERY_AREA_ID": 2065,
            "OBJECTID": 542,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 370279222.0277,
            "FEATURE_LENGTH_M": 122249.2145,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.834240244423086,
            "lng": -127.07743864285386
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8395618374,
                "lng": -126.216415817
            },
            {
                "lat": 50.8356241813,
                "lng": -126.2155451749
            },
            {
                "lat": 50.8332482425,
                "lng": -126.2148899479
            },
            {
                "lat": 50.8256835554,
                "lng": -126.1918725126
            },
            {
                "lat": 50.8256757588,
                "lng": -126.1915058042
            },
            {
                "lat": 50.8260658165,
                "lng": -126.1895666912
            },
            {
                "lat": 50.8256753802,
                "lng": -126.1877521182
            },
            {
                "lat": 50.8244290452,
                "lng": -126.1878658542
            },
            {
                "lat": 50.8251037784,
                "lng": -126.1848217424
            },
            {
                "lat": 50.8260122404,
                "lng": -126.1815867189
            },
            {
                "lat": 50.8261377697,
                "lng": -126.1782223735
            },
            {
                "lat": 50.8271749973,
                "lng": -126.172919862
            },
            {
                "lat": 50.8304065355,
                "lng": -126.1681287612
            },
            {
                "lat": 50.8388176907,
                "lng": -126.1633461012
            },
            {
                "lat": 50.8458024979,
                "lng": -126.1607593735
            },
            {
                "lat": 50.8533055097,
                "lng": -126.1592023811
            },
            {
                "lat": 50.8596457861,
                "lng": -126.1581720986
            },
            {
                "lat": 50.8698655153,
                "lng": -126.1568752134
            },
            {
                "lat": 50.8759460379,
                "lng": -126.1572645189
            },
            {
                "lat": 50.8796992672,
                "lng": -126.1589506094
            },
            {
                "lat": 50.8817669224,
                "lng": -126.1629554675
            },
            {
                "lat": 50.8831905424,
                "lng": -126.168518418
            },
            {
                "lat": 50.8838387518,
                "lng": -126.1740804819
            },
            {
                "lat": 50.88344894,
                "lng": -126.177833942
            },
            {
                "lat": 50.882156873,
                "lng": -126.1833962217
            },
            {
                "lat": 50.8798294831,
                "lng": -126.1866302291
            },
            {
                "lat": 50.8767239135,
                "lng": -126.1896055083
            },
            {
                "lat": 50.8701254213,
                "lng": -126.1928408264
            },
            {
                "lat": 50.8655966427,
                "lng": -126.1949148571
            },
            {
                "lat": 50.8566701585,
                "lng": -126.1987921595
            },
            {
                "lat": 50.8513636608,
                "lng": -126.1990510642
            },
            {
                "lat": 50.8459319749,
                "lng": -126.2008664031
            },
            {
                "lat": 50.8434757584,
                "lng": -126.2075885801
            },
            {
                "lat": 50.8395618374,
                "lng": -126.216415817
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 399,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 37,
            "MANAGEMENT_AREA_NAME": "Subarea 12-37",
            "LABEL": "12-37",
            "FISHERY_AREA_ID": 2095,
            "OBJECTID": 543,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 17263080.3535,
            "FEATURE_LENGTH_M": 17633.4426,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.85110477422352,
            "lng": -126.18379863677058
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8012841488,
                "lng": -127.6564711598
            },
            {
                "lat": 50.8078503063,
                "lng": -127.6806031469
            },
            {
                "lat": 50.8150715753,
                "lng": -127.7233272493
            },
            {
                "lat": 50.8259014256,
                "lng": -127.8021477573
            },
            {
                "lat": 50.8385393217,
                "lng": -127.8767547323
            },
            {
                "lat": 50.8487668082,
                "lng": -127.9230876125
            },
            {
                "lat": 50.8535805628,
                "lng": -127.9591901309
            },
            {
                "lat": 50.8499677087,
                "lng": -127.9760359321
            },
            {
                "lat": 50.8409458589,
                "lng": -127.9922792112
            },
            {
                "lat": 50.8421481038,
                "lng": -128.0163425638
            },
            {
                "lat": 50.8559877947,
                "lng": -128.0542599143
            },
            {
                "lat": 50.8638076999,
                "lng": -128.0632784663
            },
            {
                "lat": 50.862605881,
                "lng": -128.0711068655
            },
            {
                "lat": 50.8505709657,
                "lng": -128.0909573714
            },
            {
                "lat": 50.8306850693,
                "lng": -128.1254571597
            },
            {
                "lat": 50.8277094984,
                "lng": -128.145111499
            },
            {
                "lat": 50.8240972814,
                "lng": -128.1685784842
            },
            {
                "lat": 50.8114626897,
                "lng": -128.2076872192
            },
            {
                "lat": 50.7964216678,
                "lng": -128.2383735424
            },
            {
                "lat": 50.791606604,
                "lng": -128.2654421034
            },
            {
                "lat": 50.7910036079,
                "lng": -128.3009487984
            },
            {
                "lat": 50.7884637009,
                "lng": -128.3267977474
            },
            {
                "lat": 50.7682420344,
                "lng": -128.3309631123
            },
            {
                "lat": 50.7354162513,
                "lng": -128.3461605142
            },
            {
                "lat": 50.7123152578,
                "lng": -128.3461609415
            },
            {
                "lat": 50.6959004416,
                "lng": -128.3248743774
            },
            {
                "lat": 50.6959005499,
                "lng": -128.3023833308
            },
            {
                "lat": 50.6971171406,
                "lng": -128.2701723303
            },
            {
                "lat": 50.6928633048,
                "lng": -128.2519379873
            },
            {
                "lat": 50.6825289419,
                "lng": -128.2440333546
            },
            {
                "lat": 50.6630748028,
                "lng": -128.2440331488
            },
            {
                "lat": 50.6484865576,
                "lng": -128.2634735234
            },
            {
                "lat": 50.6399769873,
                "lng": -128.2871856822
            },
            {
                "lat": 50.6302489655,
                "lng": -128.2829283771
            },
            {
                "lat": 50.6229555064,
                "lng": -128.2653042063
            },
            {
                "lat": 50.6156614086,
                "lng": -128.2458495694
            },
            {
                "lat": 50.6059347494,
                "lng": -128.227614812
            },
            {
                "lat": 50.594821626,
                "lng": -128.2135617041
            },
            {
                "lat": 50.5785447258,
                "lng": -128.2112726193
            },
            {
                "lat": 50.5415802954,
                "lng": -128.202651859
            },
            {
                "lat": 50.5358889725,
                "lng": -128.1928405227
            },
            {
                "lat": 50.5328443792,
                "lng": -128.1796565832
            },
            {
                "lat": 50.5256918869,
                "lng": -128.1619571815
            },
            {
                "lat": 50.509277292,
                "lng": -128.1327822781
            },
            {
                "lat": 50.4965135191,
                "lng": -128.1036070876
            },
            {
                "lat": 50.4892199579,
                "lng": -128.087189584
            },
            {
                "lat": 50.5024377766,
                "lng": -128.0926215746
            },
            {
                "lat": 50.5111352864,
                "lng": -128.093307583
            },
            {
                "lat": 50.5144380338,
                "lng": -128.0868834035
            },
            {
                "lat": 50.5088731962,
                "lng": -128.0771332954
            },
            {
                "lat": 50.4994809431,
                "lng": -128.0609584444
            },
            {
                "lat": 50.5029609394,
                "lng": -128.0524438383
            },
            {
                "lat": 50.5130455063,
                "lng": -128.0430446301
            },
            {
                "lat": 50.5273089042,
                "lng": -128.0343618875
            },
            {
                "lat": 50.5342636262,
                "lng": -128.0279235428
            },
            {
                "lat": 50.5360029415,
                "lng": -128.0202635055
            },
            {
                "lat": 50.5400052717,
                "lng": -128.0166171758
            },
            {
                "lat": 50.5448722579,
                "lng": -128.0070501923
            },
            {
                "lat": 50.5495684516,
                "lng": -127.9906996091
            },
            {
                "lat": 50.5509608532,
                "lng": -127.9753952438
            },
            {
                "lat": 50.5497431276,
                "lng": -127.9667053429
            },
            {
                "lat": 50.548873972,
                "lng": -127.9559170367
            },
            {
                "lat": 50.5497434182,
                "lng": -127.9479212672
            },
            {
                "lat": 50.5488735464,
                "lng": -127.9334870013
            },
            {
                "lat": 50.5460925886,
                "lng": -127.92600296
            },
            {
                "lat": 50.5405279581,
                "lng": -127.9197471437
            },
            {
                "lat": 50.5340927099,
                "lng": -127.9089583855
            },
            {
                "lat": 50.5293965381,
                "lng": -127.8999179657
            },
            {
                "lat": 50.5250469017,
                "lng": -127.894004644
            },
            {
                "lat": 50.5210493017,
                "lng": -127.8919144968
            },
            {
                "lat": 50.517048258,
                "lng": -127.8936535438
            },
            {
                "lat": 50.5151331346,
                "lng": -127.8999173975
            },
            {
                "lat": 50.5168724292,
                "lng": -127.9087909254
            },
            {
                "lat": 50.5266114391,
                "lng": -127.9230493894
            },
            {
                "lat": 50.5313067458,
                "lng": -127.9362634155
            },
            {
                "lat": 50.5321771694,
                "lng": -127.9428788278
            },
            {
                "lat": 50.530788675,
                "lng": -127.9588774978
            },
            {
                "lat": 50.5292205647,
                "lng": -127.9715728477
            },
            {
                "lat": 50.5269620659,
                "lng": -127.9790500854
            },
            {
                "lat": 50.5260923792,
                "lng": -127.9880903133
            },
            {
                "lat": 50.5252228378,
                "lng": -127.9966123609
            },
            {
                "lat": 50.5222656579,
                "lng": -128.0009619677
            },
            {
                "lat": 50.5154800107,
                "lng": -128.0032195778
            },
            {
                "lat": 50.5102657718,
                "lng": -128.0113981928
            },
            {
                "lat": 50.5034833462,
                "lng": -128.0188759495
            },
            {
                "lat": 50.5003508215,
                "lng": -128.0221860478
            },
            {
                "lat": 50.4954833733,
                "lng": -128.0173187154
            },
            {
                "lat": 50.4886974442,
                "lng": -128.011230548
            },
            {
                "lat": 50.4822656363,
                "lng": -128.0075679769
            },
            {
                "lat": 50.4765238273,
                "lng": -128.0047919623
            },
            {
                "lat": 50.4747658539,
                "lng": -128.0043023028
            },
            {
                "lat": 50.4732365671,
                "lng": -128.0025027411
            },
            {
                "lat": 50.4729617016,
                "lng": -127.9971159644
            },
            {
                "lat": 50.4745250481,
                "lng": -127.9875107853
            },
            {
                "lat": 50.4748686827,
                "lng": -127.9801639152
            },
            {
                "lat": 50.4757879275,
                "lng": -127.9703974841
            },
            {
                "lat": 50.4786600894,
                "lng": -127.963622168
            },
            {
                "lat": 50.4815295968,
                "lng": -127.9589162391
            },
            {
                "lat": 50.4834821509,
                "lng": -127.9547808219
            },
            {
                "lat": 50.4844016294,
                "lng": -127.9501878688
            },
            {
                "lat": 50.4840580818,
                "lng": -127.946395238
            },
            {
                "lat": 50.483482272,
                "lng": -127.943412379
            },
            {
                "lat": 50.4816440551,
                "lng": -127.9400782805
            },
            {
                "lat": 50.4812201452,
                "lng": -127.9386824698
            },
            {
                "lat": 50.4879909293,
                "lng": -127.9373709405
            },
            {
                "lat": 50.4925495014,
                "lng": -127.9338306172
            },
            {
                "lat": 50.4940676097,
                "lng": -127.9297794741
            },
            {
                "lat": 50.4930572874,
                "lng": -127.9247129339
            },
            {
                "lat": 50.4900164759,
                "lng": -127.9171148452
            },
            {
                "lat": 50.4859650464,
                "lng": -127.9044498889
            },
            {
                "lat": 50.4869770853,
                "lng": -127.8927991334
            },
            {
                "lat": 50.4900163197,
                "lng": -127.8846968212
            },
            {
                "lat": 50.4950827347,
                "lng": -127.8811493495
            },
            {
                "lat": 50.5021747161,
                "lng": -127.8811489641
            },
            {
                "lat": 50.5062263796,
                "lng": -127.8796310798
            },
            {
                "lat": 50.5097699925,
                "lng": -127.872033087
            },
            {
                "lat": 50.5133171927,
                "lng": -127.8629153388
            },
            {
                "lat": 50.5163529141,
                "lng": -127.8548123957
            },
            {
                "lat": 50.5209118869,
                "lng": -127.8462071403
            },
            {
                "lat": 50.5209117072,
                "lng": -127.8406295696
            },
            {
                "lat": 50.5188862231,
                "lng": -127.8360747734
            },
            {
                "lat": 50.5128091464,
                "lng": -127.8289798165
            },
            {
                "lat": 50.5067324192,
                "lng": -127.8234097096
            },
            {
                "lat": 50.5026811023,
                "lng": -127.8142926466
            },
            {
                "lat": 50.5036928432,
                "lng": -127.8021395042
            },
            {
                "lat": 50.506225857,
                "lng": -127.786438803
            },
            {
                "lat": 50.509770023,
                "lng": -127.7732691748
            },
            {
                "lat": 50.5168606709,
                "lng": -127.7479400585
            },
            {
                "lat": 50.5219271102,
                "lng": -127.7297060554
            },
            {
                "lat": 50.5264857343,
                "lng": -127.7089381114
            },
            {
                "lat": 50.5290178087,
                "lng": -127.695770799
            },
            {
                "lat": 50.5350947595,
                "lng": -127.6785504136
            },
            {
                "lat": 50.5401613823,
                "lng": -127.667403465
            },
            {
                "lat": 50.5432017817,
                "lng": -127.6476512128
            },
            {
                "lat": 50.543201798,
                "lng": -127.6380312728
            },
            {
                "lat": 50.5415720062,
                "lng": -127.6308744706
            },
            {
                "lat": 50.5433006562,
                "lng": -127.6273956637
            },
            {
                "lat": 50.5422018564,
                "lng": -127.6217955934
            },
            {
                "lat": 50.5419620639,
                "lng": -127.6171646769
            },
            {
                "lat": 50.5436632056,
                "lng": -127.6097405196
            },
            {
                "lat": 50.5481681658,
                "lng": -127.6090083552
            },
            {
                "lat": 50.552063237,
                "lng": -127.6062091823
            },
            {
                "lat": 50.5554734857,
                "lng": -127.6004872556
            },
            {
                "lat": 50.5546223092,
                "lng": -127.5956198436
            },
            {
                "lat": 50.5499959648,
                "lng": -127.5898964352
            },
            {
                "lat": 50.5490260171,
                "lng": -127.5891879055
            },
            {
                "lat": 50.5544816974,
                "lng": -127.5743482626
            },
            {
                "lat": 50.5590707914,
                "lng": -127.579346309
            },
            {
                "lat": 50.5639462703,
                "lng": -127.5851597642
            },
            {
                "lat": 50.5673256341,
                "lng": -127.5919272847
            },
            {
                "lat": 50.570629265,
                "lng": -127.6011205295
            },
            {
                "lat": 50.5729870491,
                "lng": -127.6090618215
            },
            {
                "lat": 50.5738528033,
                "lng": -127.6203846639
            },
            {
                "lat": 50.5751880497,
                "lng": -127.6331255952
            },
            {
                "lat": 50.575344146,
                "lng": -127.642402164
            },
            {
                "lat": 50.5769195634,
                "lng": -127.6522290004
            },
            {
                "lat": 50.578804315,
                "lng": -127.6618189205
            },
            {
                "lat": 50.5791207516,
                "lng": -127.6688228554
            },
            {
                "lat": 50.5789649238,
                "lng": -127.681006914
            },
            {
                "lat": 50.5786482831,
                "lng": -127.6949230124
            },
            {
                "lat": 50.5788038461,
                "lng": -127.7056959285
            },
            {
                "lat": 50.5799866522,
                "lng": -127.7189864813
            },
            {
                "lat": 50.5819506824,
                "lng": -127.7306976558
            },
            {
                "lat": 50.5825804273,
                "lng": -127.7412408802
            },
            {
                "lat": 50.581794659,
                "lng": -127.7501226772
            },
            {
                "lat": 50.5818703553,
                "lng": -127.7574382558
            },
            {
                "lat": 50.5837584802,
                "lng": -127.7659308453
            },
            {
                "lat": 50.5859599516,
                "lng": -127.7700117836
            },
            {
                "lat": 50.5883223061,
                "lng": -127.7767797289
            },
            {
                "lat": 50.5924070742,
                "lng": -127.7932891459
            },
            {
                "lat": 50.5964967513,
                "lng": -127.8114550398
            },
            {
                "lat": 50.6003490787,
                "lng": -127.8212049068
            },
            {
                "lat": 50.6016880748,
                "lng": -127.8271020452
            },
            {
                "lat": 50.6043581572,
                "lng": -127.833938407
            },
            {
                "lat": 50.6069567099,
                "lng": -127.8414072488
            },
            {
                "lat": 50.6089974763,
                "lng": -127.8510813014
            },
            {
                "lat": 50.6098636818,
                "lng": -127.8588639585
            },
            {
                "lat": 50.6103364801,
                "lng": -127.8685379862
            },
            {
                "lat": 50.6103361665,
                "lng": -127.8753738315
            },
            {
                "lat": 50.6124574007,
                "lng": -127.8817442781
            },
            {
                "lat": 50.6134797343,
                "lng": -127.8870164266
            },
            {
                "lat": 50.6153677658,
                "lng": -127.8973161903
            },
            {
                "lat": 50.6181977681,
                "lng": -127.9044716288
            },
            {
                "lat": 50.6215782982,
                "lng": -127.9170532965
            },
            {
                "lat": 50.6237788475,
                "lng": -127.9275058514
            },
            {
                "lat": 50.6253549606,
                "lng": -127.9345010148
            },
            {
                "lat": 50.6265331468,
                "lng": -127.9421311748
            },
            {
                "lat": 50.6266138137,
                "lng": -127.959274332
            },
            {
                "lat": 50.6284983866,
                "lng": -127.9683149041
            },
            {
                "lat": 50.6317219077,
                "lng": -127.9858472326
            },
            {
                "lat": 50.6340828784,
                "lng": -127.992691378
            },
            {
                "lat": 50.6371454343,
                "lng": -127.9989775582
            },
            {
                "lat": 50.640922105,
                "lng": -128.0095970052
            },
            {
                "lat": 50.6439895849,
                "lng": -128.0186313026
            },
            {
                "lat": 50.6471331176,
                "lng": -128.0231931051
            },
            {
                "lat": 50.6505926392,
                "lng": -128.0251616589
            },
            {
                "lat": 50.6552317607,
                "lng": -128.0230410929
            },
            {
                "lat": 50.657589477,
                "lng": -128.0204475781
            },
            {
                "lat": 50.6598698116,
                "lng": -128.0184016153
            },
            {
                "lat": 50.6582982984,
                "lng": -128.0102238889
            },
            {
                "lat": 50.6546021873,
                "lng": -127.9961470159
            },
            {
                "lat": 50.6498874845,
                "lng": -127.9812933012
            },
            {
                "lat": 50.6477626678,
                "lng": -127.971221736
            },
            {
                "lat": 50.6498067398,
                "lng": -127.963126357
            },
            {
                "lat": 50.6476057298,
                "lng": -127.9519572414
            },
            {
                "lat": 50.6444621287,
                "lng": -127.937568557
            },
            {
                "lat": 50.6400572536,
                "lng": -127.9180750139
            },
            {
                "lat": 50.6388776434,
                "lng": -127.9077757134
            },
            {
                "lat": 50.639270337,
                "lng": -127.9009325829
            },
            {
                "lat": 50.6386416855,
                "lng": -127.8962936513
            },
            {
                "lat": 50.6355738422,
                "lng": -127.8909455366
            },
            {
                "lat": 50.6314852919,
                "lng": -127.8893737061
            },
            {
                "lat": 50.6283409121,
                "lng": -127.8818211019
            },
            {
                "lat": 50.6274763134,
                "lng": -127.8738022645
            },
            {
                "lat": 50.6249614121,
                "lng": -127.8607551601
            },
            {
                "lat": 50.621578363,
                "lng": -127.8413314919
            },
            {
                "lat": 50.6206355766,
                "lng": -127.8330764112
            },
            {
                "lat": 50.6154479432,
                "lng": -127.8115306973
            },
            {
                "lat": 50.6121445453,
                "lng": -127.8006062754
            },
            {
                "lat": 50.611435214,
                "lng": -127.7917931186
            },
            {
                "lat": 50.6129299146,
                "lng": -127.786292969
            },
            {
                "lat": 50.6125376827,
                "lng": -127.7765431564
            },
            {
                "lat": 50.6114354974,
                "lng": -127.7638012108
            },
            {
                "lat": 50.6108860028,
                "lng": -127.7564932348
            },
            {
                "lat": 50.6090778656,
                "lng": -127.7481537138
            },
            {
                "lat": 50.6092341627,
                "lng": -127.7385633976
            },
            {
                "lat": 50.6114351942,
                "lng": -127.7284241206
            },
            {
                "lat": 50.6119079119,
                "lng": -127.7220537813
            },
            {
                "lat": 50.6119883364,
                "lng": -127.7109671341
            },
            {
                "lat": 50.6130104687,
                "lng": -127.704521937
            },
            {
                "lat": 50.6135600238,
                "lng": -127.6997989843
            },
            {
                "lat": 50.612537864,
                "lng": -127.6920159804
            },
            {
                "lat": 50.6115947607,
                "lng": -127.6836856739
            },
            {
                "lat": 50.6112779345,
                "lng": -127.6760560954
            },
            {
                "lat": 50.6120639528,
                "lng": -127.6620552861
            },
            {
                "lat": 50.6130106756,
                "lng": -127.6548240769
            },
            {
                "lat": 50.6117518108,
                "lng": -127.6479864064
            },
            {
                "lat": 50.6072687345,
                "lng": -127.6463312914
            },
            {
                "lat": 50.6036529945,
                "lng": -127.6404339066
            },
            {
                "lat": 50.6034167171,
                "lng": -127.6317061893
            },
            {
                "lat": 50.6008226429,
                "lng": -127.6263661208
            },
            {
                "lat": 50.6021569522,
                "lng": -127.6083529216
            },
            {
                "lat": 50.6031034405,
                "lng": -127.5891727776
            },
            {
                "lat": 50.6056177807,
                "lng": -127.5790249338
            },
            {
                "lat": 50.6075821088,
                "lng": -127.5739983204
            },
            {
                "lat": 50.607742801,
                "lng": -127.5707712625
            },
            {
                "lat": 50.6050685582,
                "lng": -127.5686498377
            },
            {
                "lat": 50.5986216645,
                "lng": -127.5651099829
            },
            {
                "lat": 50.5937459791,
                "lng": -127.5637742309
            },
            {
                "lat": 50.5909160422,
                "lng": -127.5640106996
            },
            {
                "lat": 50.5896189986,
                "lng": -127.5673445077
            },
            {
                "lat": 50.5885167688,
                "lng": -127.5698395671
            },
            {
                "lat": 50.5862163457,
                "lng": -127.5692443617
            },
            {
                "lat": 50.5802198064,
                "lng": -127.5627443459
            },
            {
                "lat": 50.5759207281,
                "lng": -127.5572435038
            },
            {
                "lat": 50.5735209854,
                "lng": -127.5543444424
            },
            {
                "lat": 50.5747982231,
                "lng": -127.5521243345
            },
            {
                "lat": 50.5786968067,
                "lng": -127.5470967348
            },
            {
                "lat": 50.5824548245,
                "lng": -127.5431975307
            },
            {
                "lat": 50.5883743614,
                "lng": -127.5362463485
            },
            {
                "lat": 50.5920485985,
                "lng": -127.5312197405
            },
            {
                "lat": 50.5976715204,
                "lng": -127.5210500914
            },
            {
                "lat": 50.601803035,
                "lng": -127.5111229517
            },
            {
                "lat": 50.6032106123,
                "lng": -127.5047613264
            },
            {
                "lat": 50.6029628824,
                "lng": -127.4945905713
            },
            {
                "lat": 50.6010594606,
                "lng": -127.4878086254
            },
            {
                "lat": 50.5970920091,
                "lng": -127.4782187239
            },
            {
                "lat": 50.5954364437,
                "lng": -127.4716876858
            },
            {
                "lat": 50.5972560326,
                "lng": -127.4608537143
            },
            {
                "lat": 50.5996550541,
                "lng": -127.452834857
            },
            {
                "lat": 50.6018867204,
                "lng": -127.4467165552
            },
            {
                "lat": 50.6023830953,
                "lng": -127.4420017783
            },
            {
                "lat": 50.6027147809,
                "lng": -127.4366304261
            },
            {
                "lat": 50.6056064033,
                "lng": -127.4318317758
            },
            {
                "lat": 50.6098251072,
                "lng": -127.4267043282
            },
            {
                "lat": 50.611728862,
                "lng": -127.4218288631
            },
            {
                "lat": 50.6118088614,
                "lng": -127.4161222478
            },
            {
                "lat": 50.6101571198,
                "lng": -127.4127279593
            },
            {
                "lat": 50.6043663716,
                "lng": -127.4111561777
            },
            {
                "lat": 50.5965960638,
                "lng": -127.4103320281
            },
            {
                "lat": 50.5879138563,
                "lng": -127.4096686441
            },
            {
                "lat": 50.5789831334,
                "lng": -127.4088440625
            },
            {
                "lat": 50.5752602038,
                "lng": -127.4118199852
            },
            {
                "lat": 50.5726167915,
                "lng": -127.4197617754
            },
            {
                "lat": 50.5733603137,
                "lng": -127.4281924172
            },
            {
                "lat": 50.5716247685,
                "lng": -127.4453891047
            },
            {
                "lat": 50.5711289529,
                "lng": -127.4547353935
            },
            {
                "lat": 50.5696411087,
                "lng": -127.4682159029
            },
            {
                "lat": 50.5685654624,
                "lng": -127.4811105098
            },
            {
                "lat": 50.5672416585,
                "lng": -127.4973143042
            },
            {
                "lat": 50.5680696742,
                "lng": -127.5055001256
            },
            {
                "lat": 50.5676536244,
                "lng": -127.5137715841
            },
            {
                "lat": 50.5655060951,
                "lng": -127.5218731358
            },
            {
                "lat": 50.5618670825,
                "lng": -127.5232853693
            },
            {
                "lat": 50.5567400908,
                "lng": -127.5228647899
            },
            {
                "lat": 50.5520282898,
                "lng": -127.5188987205
            },
            {
                "lat": 50.5482251197,
                "lng": -127.5110473236
            },
            {
                "lat": 50.5461577388,
                "lng": -127.5077365304
            },
            {
                "lat": 50.5421868784,
                "lng": -127.5056693573
            },
            {
                "lat": 50.53846751,
                "lng": -127.5019450639
            },
            {
                "lat": 50.5342482401,
                "lng": -127.5001298768
            },
            {
                "lat": 50.5302812181,
                "lng": -127.4992223772
            },
            {
                "lat": 50.5291215702,
                "lng": -127.5007099476
            },
            {
                "lat": 50.5262299652,
                "lng": -127.5064161925
            },
            {
                "lat": 50.5266417694,
                "lng": -127.5112084035
            },
            {
                "lat": 50.5286257963,
                "lng": -127.5183190075
            },
            {
                "lat": 50.5331761708,
                "lng": -127.5253441972
            },
            {
                "lat": 50.5379712183,
                "lng": -127.5303118055
            },
            {
                "lat": 50.5429306927,
                "lng": -127.5327075723
            },
            {
                "lat": 50.5461576996,
                "lng": -127.5362626942
            },
            {
                "lat": 50.5473137781,
                "lng": -127.5399019869
            },
            {
                "lat": 50.5512811751,
                "lng": -127.5492476969
            },
            {
                "lat": 50.5491147653,
                "lng": -127.5513540273
            },
            {
                "lat": 50.545269262,
                "lng": -127.5548477705
            },
            {
                "lat": 50.5408966998,
                "lng": -127.5569452496
            },
            {
                "lat": 50.5359993734,
                "lng": -127.5576477495
            },
            {
                "lat": 50.5288280337,
                "lng": -127.5592189194
            },
            {
                "lat": 50.5242808417,
                "lng": -127.5592186139
            },
            {
                "lat": 50.5220074409,
                "lng": -127.5618443159
            },
            {
                "lat": 50.5190353715,
                "lng": -127.5670932342
            },
            {
                "lat": 50.5172876812,
                "lng": -127.5726849322
            },
            {
                "lat": 50.510570966,
                "lng": -127.5695644067
            },
            {
                "lat": 50.5000878063,
                "lng": -127.5579148247
            },
            {
                "lat": 50.4872736554,
                "lng": -127.5396643274
            },
            {
                "lat": 50.478343617,
                "lng": -127.5264669726
            },
            {
                "lat": 50.4635924936,
                "lng": -127.5121001839
            },
            {
                "lat": 50.4503933558,
                "lng": -127.4992905218
            },
            {
                "lat": 50.4383585883,
                "lng": -127.4884186837
            },
            {
                "lat": 50.4259331299,
                "lng": -127.4783250051
            },
            {
                "lat": 50.4158403003,
                "lng": -127.4740526101
            },
            {
                "lat": 50.4076842669,
                "lng": -127.466285495
            },
            {
                "lat": 50.3995328786,
                "lng": -127.4565817663
            },
            {
                "lat": 50.3886607706,
                "lng": -127.4457093169
            },
            {
                "lat": 50.3820608594,
                "lng": -127.4383321474
            },
            {
                "lat": 50.3758505153,
                "lng": -127.4344553402
            },
            {
                "lat": 50.3665313499,
                "lng": -127.4305727161
            },
            {
                "lat": 50.3607105322,
                "lng": -127.4313510125
            },
            {
                "lat": 50.3533318306,
                "lng": -127.4309620827
            },
            {
                "lat": 50.3467335665,
                "lng": -127.4274666264
            },
            {
                "lat": 50.3389662355,
                "lng": -127.4243544416
            },
            {
                "lat": 50.3292619962,
                "lng": -127.4235836748
            },
            {
                "lat": 50.3246007414,
                "lng": -127.4259109243
            },
            {
                "lat": 50.3226627267,
                "lng": -127.4321214851
            },
            {
                "lat": 50.3230478776,
                "lng": -127.4394985918
            },
            {
                "lat": 50.3269313695,
                "lng": -127.4460980053
            },
            {
                "lat": 50.3335310131,
                "lng": -127.4476544383
            },
            {
                "lat": 50.3389662271,
                "lng": -127.4468769113
            },
            {
                "lat": 50.3451807371,
                "lng": -127.4523092139
            },
            {
                "lat": 50.350612711,
                "lng": -127.4546433277
            },
            {
                "lat": 50.3599317093,
                "lng": -127.4558024699
            },
            {
                "lat": 50.3645892812,
                "lng": -127.4589152961
            },
            {
                "lat": 50.3676987641,
                "lng": -127.4697790612
            },
            {
                "lat": 50.3680840101,
                "lng": -127.4779357115
            },
            {
                "lat": 50.373130835,
                "lng": -127.4857023746
            },
            {
                "lat": 50.3801193137,
                "lng": -127.496574523
            },
            {
                "lat": 50.388275315,
                "lng": -127.5043336317
            },
            {
                "lat": 50.3991427589,
                "lng": -127.5128785446
            },
            {
                "lat": 50.4181673397,
                "lng": -127.5249098973
            },
            {
                "lat": 50.4298170265,
                "lng": -127.5342323103
            },
            {
                "lat": 50.4391326609,
                "lng": -127.5408323511
            },
            {
                "lat": 50.4488416081,
                "lng": -127.5485995798
            },
            {
                "lat": 50.4558300341,
                "lng": -127.5571359867
            },
            {
                "lat": 50.4612619118,
                "lng": -127.5629582372
            },
            {
                "lat": 50.4694181897,
                "lng": -127.5687867339
            },
            {
                "lat": 50.4721339075,
                "lng": -127.5718917825
            },
            {
                "lat": 50.4752386724,
                "lng": -127.5788807207
            },
            {
                "lat": 50.476791665,
                "lng": -127.5862575655
            },
            {
                "lat": 50.4771805079,
                "lng": -127.5947957427
            },
            {
                "lat": 50.477180465,
                "lng": -127.6017834921
            },
            {
                "lat": 50.4787336057,
                "lng": -127.6095501235
            },
            {
                "lat": 50.4744644185,
                "lng": -127.6200330811
            },
            {
                "lat": 50.4756277369,
                "lng": -127.6386725646
            },
            {
                "lat": 50.4795108881,
                "lng": -127.6569133655
            },
            {
                "lat": 50.4787334579,
                "lng": -127.6701129375
            },
            {
                "lat": 50.4791221278,
                "lng": -127.6809850684
            },
            {
                "lat": 50.4764063274,
                "lng": -127.7027286121
            },
            {
                "lat": 50.4729117506,
                "lng": -127.7233050375
            },
            {
                "lat": 50.4701922625,
                "lng": -127.7361143049
            },
            {
                "lat": 50.468250017,
                "lng": -127.755531018
            },
            {
                "lat": 50.4721339545,
                "lng": -127.7667852248
            },
            {
                "lat": 50.4701923318,
                "lng": -127.7730028601
            },
            {
                "lat": 50.4639811153,
                "lng": -127.7838749847
            },
            {
                "lat": 50.4554397176,
                "lng": -127.7928000433
            },
            {
                "lat": 50.4503931416,
                "lng": -127.8067782787
            },
            {
                "lat": 50.4488417294,
                "lng": -127.8207552881
            },
            {
                "lat": 50.4562153085,
                "lng": -127.8359001471
            },
            {
                "lat": 50.4562153827,
                "lng": -127.8467635855
            },
            {
                "lat": 50.4538882205,
                "lng": -127.854531295
            },
            {
                "lat": 50.4484513981,
                "lng": -127.8599699509
            },
            {
                "lat": 50.4453434698,
                "lng": -127.8673396729
            },
            {
                "lat": 50.4468997098,
                "lng": -127.8743287668
            },
            {
                "lat": 50.4480626984,
                "lng": -127.8840409299
            },
            {
                "lat": 50.4496155509,
                "lng": -127.8960722317
            },
            {
                "lat": 50.4546624227,
                "lng": -127.9166491239
            },
            {
                "lat": 50.4577672297,
                "lng": -127.9244157212
            },
            {
                "lat": 50.4608767143,
                "lng": -127.9298481817
            },
            {
                "lat": 50.4611012327,
                "lng": -127.9303440767
            },
            {
                "lat": 50.459434415,
                "lng": -127.9307326355
            },
            {
                "lat": 50.4562757671,
                "lng": -127.9318166532
            },
            {
                "lat": 50.4498669653,
                "lng": -127.9328150908
            },
            {
                "lat": 50.4439089391,
                "lng": -127.9329915425
            },
            {
                "lat": 50.4408414917,
                "lng": -127.9328989701
            },
            {
                "lat": 50.4375004867,
                "lng": -127.9293827254
            },
            {
                "lat": 50.4340708471,
                "lng": -127.9251403926
            },
            {
                "lat": 50.4337088514,
                "lng": -127.922074272
            },
            {
                "lat": 50.4336204145,
                "lng": -127.9173813445
            },
            {
                "lat": 50.4308205584,
                "lng": -127.9153908057
            },
            {
                "lat": 50.4274827508,
                "lng": -127.9148478396
            },
            {
                "lat": 50.4233325503,
                "lng": -127.9175572941
            },
            {
                "lat": 50.4210743655,
                "lng": -127.9198150495
            },
            {
                "lat": 50.4183652992,
                "lng": -127.9256813064
            },
            {
                "lat": 50.4138529833,
                "lng": -127.9306486915
            },
            {
                "lat": 50.4087981804,
                "lng": -127.9364243048
            },
            {
                "lat": 50.4041063426,
                "lng": -127.9405741644
            },
            {
                "lat": 50.3972472345,
                "lng": -127.9436419929
            },
            {
                "lat": 50.3930923799,
                "lng": -127.9460829342
            },
            {
                "lat": 50.3822636472,
                "lng": -127.9523085082
            },
            {
                "lat": 50.3746836942,
                "lng": -127.9553753425
            },
            {
                "lat": 50.3703495148,
                "lng": -127.9563678545
            },
            {
                "lat": 50.3640293712,
                "lng": -127.9576337804
            },
            {
                "lat": 50.3588863748,
                "lng": -127.960067528
            },
            {
                "lat": 50.3541948151,
                "lng": -127.9630511622
            },
            {
                "lat": 50.3513951502,
                "lng": -127.9661172734
            },
            {
                "lat": 50.3456196375,
                "lng": -127.9697257398
            },
            {
                "lat": 50.3385778361,
                "lng": -127.9718020374
            },
            {
                "lat": 50.3335229114,
                "lng": -127.970809633
            },
            {
                "lat": 50.3339464772,
                "lng": -127.962563086
            },
            {
                "lat": 50.3327136428,
                "lng": -127.9456094725
            },
            {
                "lat": 50.3361048577,
                "lng": -127.9348226981
            },
            {
                "lat": 50.3425752625,
                "lng": -127.9129412899
            },
            {
                "lat": 50.3493576236,
                "lng": -127.8956839619
            },
            {
                "lat": 50.3512077003,
                "lng": -127.8802714802
            },
            {
                "lat": 50.3536719561,
                "lng": -127.8673246026
            },
            {
                "lat": 50.3555220354,
                "lng": -127.858077691
            },
            {
                "lat": 50.3530574879,
                "lng": -127.8525320753
            },
            {
                "lat": 50.3459666952,
                "lng": -127.8522189567
            },
            {
                "lat": 50.3348695369,
                "lng": -127.8571552658
            },
            {
                "lat": 50.3259315281,
                "lng": -127.854376783
            },
            {
                "lat": 50.3209990915,
                "lng": -127.8488308272
            },
            {
                "lat": 50.3182256415,
                "lng": -127.8355785632
            },
            {
                "lat": 50.3228486504,
                "lng": -127.8220135412
            },
            {
                "lat": 50.3299410084,
                "lng": -127.8106077598
            },
            {
                "lat": 50.3311723194,
                "lng": -127.8016736079
            },
            {
                "lat": 50.3308640872,
                "lng": -127.7874911551
            },
            {
                "lat": 50.3280906418,
                "lng": -127.7757797731
            },
            {
                "lat": 50.3209990975,
                "lng": -127.758826593
            },
            {
                "lat": 50.3132936049,
                "lng": -127.7443391604
            },
            {
                "lat": 50.3086705157,
                "lng": -127.7310868086
            },
            {
                "lat": 50.3040499455,
                "lng": -127.71999331
            },
            {
                "lat": 50.2988090207,
                "lng": -127.7159805455
            },
            {
                "lat": 50.2920265795,
                "lng": -127.7138289212
            },
            {
                "lat": 50.289562052,
                "lng": -127.7141341263
            },
            {
                "lat": 50.2886353653,
                "lng": -127.7196811606
            },
            {
                "lat": 50.2917218537,
                "lng": -127.7267682697
            },
            {
                "lat": 50.2938767221,
                "lng": -127.736945163
            },
            {
                "lat": 50.2957261274,
                "lng": -127.7508160234
            },
            {
                "lat": 50.3015820287,
                "lng": -127.7643745292
            },
            {
                "lat": 50.3068244079,
                "lng": -127.7797851328
            },
            {
                "lat": 50.3095971731,
                "lng": -127.7881092854
            },
            {
                "lat": 50.3114471038,
                "lng": -127.7945777633
            },
            {
                "lat": 50.3114470432,
                "lng": -127.7976604747
            },
            {
                "lat": 50.3089789921,
                "lng": -127.7995151182
            },
            {
                "lat": 50.3049733788,
                "lng": -127.7982788128
            },
            {
                "lat": 50.3003500768,
                "lng": -127.8004381467
            },
            {
                "lat": 50.2957264983,
                "lng": -127.8066028952
            },
            {
                "lat": 50.2917216958,
                "lng": -127.813385323
            },
            {
                "lat": 50.2901767237,
                "lng": -127.8238600679
            },
            {
                "lat": 50.2880205394,
                "lng": -127.8337249343
            },
            {
                "lat": 50.2858621839,
                "lng": -127.8420487461
            },
            {
                "lat": 50.2855525236,
                "lng": -127.8500593927
            },
            {
                "lat": 50.2814210696,
                "lng": -127.84612211
            },
            {
                "lat": 50.2729948254,
                "lng": -127.8330688713
            },
            {
                "lat": 50.2643360746,
                "lng": -127.8159870416
            },
            {
                "lat": 50.2541308456,
                "lng": -127.7972334878
            },
            {
                "lat": 50.2517583532,
                "lng": -127.790115843
            },
            {
                "lat": 50.2519192543,
                "lng": -127.7865288246
            },
            {
                "lat": 50.2520936089,
                "lng": -127.7764509134
            },
            {
                "lat": 50.2551010292,
                "lng": -127.7607184929
            },
            {
                "lat": 50.2568704635,
                "lng": -127.749221163
            },
            {
                "lat": 50.2612870711,
                "lng": -127.7366720321
            },
            {
                "lat": 50.2678298316,
                "lng": -127.7253574322
            },
            {
                "lat": 50.2710149616,
                "lng": -127.7175750086
            },
            {
                "lat": 50.2729601205,
                "lng": -127.7127984338
            },
            {
                "lat": 50.271366487,
                "lng": -127.7096171048
            },
            {
                "lat": 50.2658842091,
                "lng": -127.7106777971
            },
            {
                "lat": 50.2595210833,
                "lng": -127.7186357946
            },
            {
                "lat": 50.2545698539,
                "lng": -127.7172240283
            },
            {
                "lat": 50.2499725119,
                "lng": -127.7124485301
            },
            {
                "lat": 50.2436073959,
                "lng": -127.7122722549
            },
            {
                "lat": 50.23900997,
                "lng": -127.7166903047
            },
            {
                "lat": 50.2351229605,
                "lng": -127.726768653
            },
            {
                "lat": 50.234238074,
                "lng": -127.7324295874
            },
            {
                "lat": 50.2335280979,
                "lng": -127.7474588071
            },
            {
                "lat": 50.2310564699,
                "lng": -127.751524981
            },
            {
                "lat": 50.2234533283,
                "lng": -127.7571798686
            },
            {
                "lat": 50.2197385666,
                "lng": -127.7654872925
            },
            {
                "lat": 50.2174413732,
                "lng": -127.776627167
            },
            {
                "lat": 50.2181472383,
                "lng": -127.7851180488
            },
            {
                "lat": 50.2167316074,
                "lng": -127.7877653952
            },
            {
                "lat": 50.2137256215,
                "lng": -127.7900694805
            },
            {
                "lat": 50.2089540463,
                "lng": -127.787589647
            },
            {
                "lat": 50.2027661436,
                "lng": -127.7835235673
            },
            {
                "lat": 50.1990501153,
                "lng": -127.7833478383
            },
            {
                "lat": 50.1951631336,
                "lng": -127.7856447165
            },
            {
                "lat": 50.1930389287,
                "lng": -127.7893600824
            },
            {
                "lat": 50.1919779835,
                "lng": -127.7957222959
            },
            {
                "lat": 50.191978183,
                "lng": -127.8042148988
            },
            {
                "lat": 50.1910966409,
                "lng": -127.8086318925
            },
            {
                "lat": 50.1880911733,
                "lng": -127.8141171613
            },
            {
                "lat": 50.1833153734,
                "lng": -127.8195955795
            },
            {
                "lat": 50.1751826543,
                "lng": -127.82242566
            },
            {
                "lat": 50.1670487311,
                "lng": -127.8268440962
            },
            {
                "lat": 50.1617475868,
                "lng": -127.8348003899
            },
            {
                "lat": 50.1569708774,
                "lng": -127.8496557136
            },
            {
                "lat": 50.1525543195,
                "lng": -127.860435809
            },
            {
                "lat": 50.1463617814,
                "lng": -127.8701626776
            },
            {
                "lat": 50.138934349,
                "lng": -127.8858941328
            },
            {
                "lat": 50.1329264747,
                "lng": -127.8977433303
            },
            {
                "lat": 50.1285060313,
                "lng": -127.9044649437
            },
            {
                "lat": 50.1272580586,
                "lng": -127.90837853
            },
            {
                "lat": 50.1265563774,
                "lng": -127.9113765476
            },
            {
                "lat": 50.121925107,
                "lng": -127.9013284249
            },
            {
                "lat": 50.1051185745,
                "lng": -127.8581084597
            },
            {
                "lat": 50.0948261374,
                "lng": -127.8244933812
            },
            {
                "lat": 50.093108863,
                "lng": -127.8104243751
            },
            {
                "lat": 50.0893361214,
                "lng": -127.8069995882
            },
            {
                "lat": 50.0951692192,
                "lng": -127.7967074286
            },
            {
                "lat": 50.1106071763,
                "lng": -127.778526553
            },
            {
                "lat": 50.1239856155,
                "lng": -127.7555390925
            },
            {
                "lat": 50.1339346078,
                "lng": -127.735985525
            },
            {
                "lat": 50.1383934232,
                "lng": -127.721581401
            },
            {
                "lat": 50.1418227336,
                "lng": -127.7147222643
            },
            {
                "lat": 50.1490513126,
                "lng": -127.7042849659
            },
            {
                "lat": 50.1550827651,
                "lng": -127.6886970024
            },
            {
                "lat": 50.163124841,
                "lng": -127.6746211864
            },
            {
                "lat": 50.1721760047,
                "lng": -127.6700978
            },
            {
                "lat": 50.1854970786,
                "lng": -127.6713562772
            },
            {
                "lat": 50.1935393456,
                "lng": -127.6660766673
            },
            {
                "lat": 50.2038454804,
                "lng": -127.6560290803
            },
            {
                "lat": 50.2103808939,
                "lng": -127.6474757735
            },
            {
                "lat": 50.2138978793,
                "lng": -127.6394348255
            },
            {
                "lat": 50.2156606884,
                "lng": -127.6333998575
            },
            {
                "lat": 50.2151562535,
                "lng": -127.6278762814
            },
            {
                "lat": 50.2116360107,
                "lng": -127.6240996322
            },
            {
                "lat": 50.2053532742,
                "lng": -127.6215896456
            },
            {
                "lat": 50.203845318,
                "lng": -127.6165616738
            },
            {
                "lat": 50.2038457403,
                "lng": -127.6077652326
            },
            {
                "lat": 50.2020875863,
                "lng": -127.6007304072
            },
            {
                "lat": 50.1988187015,
                "lng": -127.596953548
            },
            {
                "lat": 50.1932867864,
                "lng": -127.5979622108
            },
            {
                "lat": 50.1847418722,
                "lng": -127.6055070593
            },
            {
                "lat": 50.1794622362,
                "lng": -127.6107795273
            },
            {
                "lat": 50.1689067969,
                "lng": -127.6138002965
            },
            {
                "lat": 50.1591038414,
                "lng": -127.6223450688
            },
            {
                "lat": 50.152820586,
                "lng": -127.6263658498
            },
            {
                "lat": 50.1447787165,
                "lng": -127.6328959725
            },
            {
                "lat": 50.1410066063,
                "lng": -127.6326529276
            },
            {
                "lat": 50.135226973,
                "lng": -127.6273729835
            },
            {
                "lat": 50.1286924595,
                "lng": -127.6215900305
            },
            {
                "lat": 50.1259275647,
                "lng": -127.6127931364
            },
            {
                "lat": 50.126430576,
                "lng": -127.6057511666
            },
            {
                "lat": 50.1254200937,
                "lng": -127.6031343673
            },
            {
                "lat": 50.1289670187,
                "lng": -127.5974959909
            },
            {
                "lat": 50.1337551337,
                "lng": -127.5908734837
            },
            {
                "lat": 50.1363340828,
                "lng": -127.5746687012
            },
            {
                "lat": 50.1348576856,
                "lng": -127.5588383088
            },
            {
                "lat": 50.1392778227,
                "lng": -127.5444799028
            },
            {
                "lat": 50.1442755052,
                "lng": -127.5362095575
            },
            {
                "lat": 50.1527904293,
                "lng": -127.5255657572
            },
            {
                "lat": 50.1652374562,
                "lng": -127.5105063835
            },
            {
                "lat": 50.1801375433,
                "lng": -127.4983899762
            },
            {
                "lat": 50.1902887829,
                "lng": -127.4921649561
            },
            {
                "lat": 50.1940540456,
                "lng": -127.4852904129
            },
            {
                "lat": 50.1953622296,
                "lng": -127.4702233646
            },
            {
                "lat": 50.1988022714,
                "lng": -127.4551625088
            },
            {
                "lat": 50.2009318567,
                "lng": -127.4420616717
            },
            {
                "lat": 50.2001109247,
                "lng": -127.4324031119
            },
            {
                "lat": 50.1986393277,
                "lng": -127.4271618673
            },
            {
                "lat": 50.1958544695,
                "lng": -127.4240489069
            },
            {
                "lat": 50.191597645,
                "lng": -127.421920848
            },
            {
                "lat": 50.1870116437,
                "lng": -127.4212642532
            },
            {
                "lat": 50.1819383312,
                "lng": -127.4232327784
            },
            {
                "lat": 50.1791535944,
                "lng": -127.4273227319
            },
            {
                "lat": 50.1763688781,
                "lng": -127.4340363433
            },
            {
                "lat": 50.1726029273,
                "lng": -127.4454950476
            },
            {
                "lat": 50.1678545675,
                "lng": -127.448775439
            },
            {
                "lat": 50.1586877489,
                "lng": -127.4581061503
            },
            {
                "lat": 50.154102322,
                "lng": -127.4648216357
            },
            {
                "lat": 50.148536598,
                "lng": -127.4718635931
            },
            {
                "lat": 50.1432952593,
                "lng": -127.4790648232
            },
            {
                "lat": 50.1413302254,
                "lng": -127.4875793774
            },
            {
                "lat": 50.1364179585,
                "lng": -127.4972381243
            },
            {
                "lat": 50.1349447975,
                "lng": -127.5059208535
            },
            {
                "lat": 50.1337974468,
                "lng": -127.5083769419
            },
            {
                "lat": 50.1331711553,
                "lng": -127.5102852961
            },
            {
                "lat": 50.1310157939,
                "lng": -127.5065769067
            },
            {
                "lat": 50.1285595385,
                "lng": -127.504935624
            },
            {
                "lat": 50.1249541807,
                "lng": -127.5055918765
            },
            {
                "lat": 50.1223371347,
                "lng": -127.5044482695
            },
            {
                "lat": 50.120861123,
                "lng": -127.5001908149
            },
            {
                "lat": 50.121517893,
                "lng": -127.4941249742
            },
            {
                "lat": 50.125282691,
                "lng": -127.4875792113
            },
            {
                "lat": 50.1285597075,
                "lng": -127.4802094385
            },
            {
                "lat": 50.1331440258,
                "lng": -127.473495706
            },
            {
                "lat": 50.1346169351,
                "lng": -127.4638363971
            },
            {
                "lat": 50.1355971289,
                "lng": -127.4538502738
            },
            {
                "lat": 50.1369089986,
                "lng": -127.4484490328
            },
            {
                "lat": 50.137729768,
                "lng": -127.4407501857
            },
            {
                "lat": 50.1387096882,
                "lng": -127.4340364864
            },
            {
                "lat": 50.1410021422,
                "lng": -127.4278185092
            },
            {
                "lat": 50.1436240979,
                "lng": -127.4229047269
            },
            {
                "lat": 50.1446042411,
                "lng": -127.4196238325
            },
            {
                "lat": 50.1446042146,
                "lng": -127.4170067701
            },
            {
                "lat": 50.14296738,
                "lng": -127.41569538
            },
            {
                "lat": 50.1410020993,
                "lng": -127.4160234098
            },
            {
                "lat": 50.1374013615,
                "lng": -127.4166791505
            },
            {
                "lat": 50.1331441836,
                "lng": -127.4178232714
            },
            {
                "lat": 50.1292118083,
                "lng": -127.4197922434
            },
            {
                "lat": 50.1264301958,
                "lng": -127.4217601206
            },
            {
                "lat": 50.1247901376,
                "lng": -127.4261783207
            },
            {
                "lat": 50.1225016012,
                "lng": -127.4301072106
            },
            {
                "lat": 50.1211895544,
                "lng": -127.4364924993
            },
            {
                "lat": 50.1203721569,
                "lng": -127.4436956728
            },
            {
                "lat": 50.1202095045,
                "lng": -127.452048968
            },
            {
                "lat": 50.1193886813,
                "lng": -127.458923793
            },
            {
                "lat": 50.1175874608,
                "lng": -127.4661330876
            },
            {
                "lat": 50.1157876667,
                "lng": -127.4721906156
            },
            {
                "lat": 50.1136584531,
                "lng": -127.4736627693
            },
            {
                "lat": 50.1107097784,
                "lng": -127.4749762483
            },
            {
                "lat": 50.1066167846,
                "lng": -127.4752963143
            },
            {
                "lat": 50.1028519261,
                "lng": -127.4761207216
            },
            {
                "lat": 50.0987583263,
                "lng": -127.4766083848
            },
            {
                "lat": 50.095481307,
                "lng": -127.4775916578
            },
            {
                "lat": 50.0921215203,
                "lng": -127.4735571198
            },
            {
                "lat": 50.0888785745,
                "lng": -127.4703140862
            },
            {
                "lat": 50.0834766517,
                "lng": -127.4641044733
            },
            {
                "lat": 50.0753706365,
                "lng": -127.4514010413
            },
            {
                "lat": 50.0707782963,
                "lng": -127.4438397144
            },
            {
                "lat": 50.064025951,
                "lng": -127.4389727258
            },
            {
                "lat": 50.0580832288,
                "lng": -127.4368128588
            },
            {
                "lat": 50.0502472075,
                "lng": -127.4314114507
            },
            {
                "lat": 50.0459245796,
                "lng": -127.4257360506
            },
            {
                "lat": 50.0501667244,
                "lng": -127.4150547349
            },
            {
                "lat": 50.0497745097,
                "lng": -127.4045332705
            },
            {
                "lat": 50.0478291036,
                "lng": -127.3823079682
            },
            {
                "lat": 50.04977467,
                "lng": -127.3628159036
            },
            {
                "lat": 50.0513341771,
                "lng": -127.3538517763
            },
            {
                "lat": 50.0486066493,
                "lng": -127.3394241038
            },
            {
                "lat": 50.0513341624,
                "lng": -127.3148656369
            },
            {
                "lat": 50.0536733861,
                "lng": -127.2977140235
            },
            {
                "lat": 50.0560108389,
                "lng": -127.2891392057
            },
            {
                "lat": 50.0565266821,
                "lng": -127.2849653149
            },
            {
                "lat": 50.0566139218,
                "lng": -127.279701168
            },
            {
                "lat": 50.0598189379,
                "lng": -127.2762982171
            },
            {
                "lat": 50.0613905654,
                "lng": -127.2766495588
            },
            {
                "lat": 50.0626675993,
                "lng": -127.2776413592
            },
            {
                "lat": 50.0631329306,
                "lng": -127.2816461749
            },
            {
                "lat": 50.0636559269,
                "lng": -127.2853689529
            },
            {
                "lat": 50.0649943634,
                "lng": -127.2879256805
            },
            {
                "lat": 50.0680736241,
                "lng": -127.2907711428
            },
            {
                "lat": 50.0705755931,
                "lng": -127.2914733826
            },
            {
                "lat": 50.0746421361,
                "lng": -127.2915886891
            },
            {
                "lat": 50.0798726808,
                "lng": -127.2910610103
            },
            {
                "lat": 50.0812681609,
                "lng": -127.289031401
            },
            {
                "lat": 50.0820810026,
                "lng": -127.2853090362
            },
            {
                "lat": 50.0826638973,
                "lng": -127.2810061975
            },
            {
                "lat": 50.0852207263,
                "lng": -127.2821123663
            },
            {
                "lat": 50.0882450886,
                "lng": -127.282638688
            },
            {
                "lat": 50.091381326,
                "lng": -127.2851330251
            },
            {
                "lat": 50.0954511865,
                "lng": -127.2865908012
            },
            {
                "lat": 50.0978352472,
                "lng": -127.2861251903
            },
            {
                "lat": 50.1001590017,
                "lng": -127.2849046112
            },
            {
                "lat": 50.1026573345,
                "lng": -127.2817689936
            },
            {
                "lat": 50.1041683779,
                "lng": -127.2794948916
            },
            {
                "lat": 50.1066093831,
                "lng": -127.276877956
            },
            {
                "lat": 50.1069030036,
                "lng": -127.2728731156
            },
            {
                "lat": 50.1072501442,
                "lng": -127.2702566454
            },
            {
                "lat": 50.1088178404,
                "lng": -127.2714767573
            },
            {
                "lat": 50.1100882692,
                "lng": -127.2732322616
            },
            {
                "lat": 50.1102370626,
                "lng": -127.2753296425
            },
            {
                "lat": 50.1109891414,
                "lng": -127.2780225513
            },
            {
                "lat": 50.1138308955,
                "lng": -127.2808609029
            },
            {
                "lat": 50.114727023,
                "lng": -127.2854995229
            },
            {
                "lat": 50.1166723876,
                "lng": -127.290588539
            },
            {
                "lat": 50.1172671852,
                "lng": -127.2970197862
            },
            {
                "lat": 50.1214558379,
                "lng": -127.3049467896
            },
            {
                "lat": 50.1245994303,
                "lng": -127.3118283341
            },
            {
                "lat": 50.1272928271,
                "lng": -127.3142168615
            },
            {
                "lat": 50.1298332009,
                "lng": -127.3142168091
            },
            {
                "lat": 50.1323781266,
                "lng": -127.3115236559
            },
            {
                "lat": 50.134472702,
                "lng": -127.310028372
            },
            {
                "lat": 50.1406018748,
                "lng": -127.3100282667
            },
            {
                "lat": 50.1452407262,
                "lng": -127.3101811536
            },
            {
                "lat": 50.1503255902,
                "lng": -127.3115235003
            },
            {
                "lat": 50.1551130866,
                "lng": -127.3128732946
            },
            {
                "lat": 50.1599008833,
                "lng": -127.3128733336
            },
            {
                "lat": 50.1661840254,
                "lng": -127.313468749
            },
            {
                "lat": 50.1720163563,
                "lng": -127.3142166985
            },
            {
                "lat": 50.1765018401,
                "lng": -127.3149643763
            },
            {
                "lat": 50.1814392109,
                "lng": -127.3154154078
            },
            {
                "lat": 50.1868210001,
                "lng": -127.3158645213
            },
            {
                "lat": 50.1931072871,
                "lng": -127.3164590501
            },
            {
                "lat": 50.198192297,
                "lng": -127.3160170999
            },
            {
                "lat": 50.2011828738,
                "lng": -127.3145221577
            },
            {
                "lat": 50.2019304072,
                "lng": -127.3124226928
            },
            {
                "lat": 50.2014808154,
                "lng": -127.3088374977
            },
            {
                "lat": 50.2004356347,
                "lng": -127.3052448847
            },
            {
                "lat": 50.1978910888,
                "lng": -127.3021007197
            },
            {
                "lat": 50.1943017692,
                "lng": -127.2980654393
            },
            {
                "lat": 50.1905635711,
                "lng": -127.2973168334
            },
            {
                "lat": 50.1842805335,
                "lng": -127.2968677833
            },
            {
                "lat": 50.1766509837,
                "lng": -127.2968679759
            },
            {
                "lat": 50.1706695129,
                "lng": -127.2953714077
            },
            {
                "lat": 50.1672282832,
                "lng": -127.294777155
            },
            {
                "lat": 50.1625902171,
                "lng": -127.2979131664
            },
            {
                "lat": 50.1601976316,
                "lng": -127.2980653395
            },
            {
                "lat": 50.1578066428,
                "lng": -127.2926793542
            },
            {
                "lat": 50.1569055501,
                "lng": -127.2856519732
            },
            {
                "lat": 50.1539147757,
                "lng": -127.2775731698
            },
            {
                "lat": 50.1500275731,
                "lng": -127.2678521188
            },
            {
                "lat": 50.1467356925,
                "lng": -127.2618640291
            },
            {
                "lat": 50.1432954055,
                "lng": -127.2576753231
            },
            {
                "lat": 50.1385074402,
                "lng": -127.2537920596
            },
            {
                "lat": 50.1340219153,
                "lng": -127.2461621886
            },
            {
                "lat": 50.1305804382,
                "lng": -127.2404780138
            },
            {
                "lat": 50.1242976225,
                "lng": -127.2274630535
            },
            {
                "lat": 50.1187635861,
                "lng": -127.2204281152
            },
            {
                "lat": 50.1142767729,
                "lng": -127.2174373752
            },
            {
                "lat": 50.1084441829,
                "lng": -127.2148972296
            },
            {
                "lat": 50.105453816,
                "lng": -127.2153469847
            },
            {
                "lat": 50.1032100536,
                "lng": -127.2174376123
            },
            {
                "lat": 50.1014138525,
                "lng": -127.2186356249
            },
            {
                "lat": 50.099918294,
                "lng": -127.2171407334
            },
            {
                "lat": 50.0984230959,
                "lng": -127.2105633421
            },
            {
                "lat": 50.0972253451,
                "lng": -127.2006915731
            },
            {
                "lat": 50.0973742221,
                "lng": -127.1920088927
            },
            {
                "lat": 50.0982705098,
                "lng": -127.1861800246
            },
            {
                "lat": 50.0975266847,
                "lng": -127.1798933955
            },
            {
                "lat": 50.09704244,
                "lng": -127.1779936131
            },
            {
                "lat": 50.0987281125,
                "lng": -127.1786803145
            },
            {
                "lat": 50.1005399019,
                "lng": -127.1785044061
            },
            {
                "lat": 50.1031684342,
                "lng": -127.1769864576
            },
            {
                "lat": 50.1052740847,
                "lng": -127.1741789585
            },
            {
                "lat": 50.1058575691,
                "lng": -127.1690363356
            },
            {
                "lat": 50.1070250142,
                "lng": -127.1616748151
            },
            {
                "lat": 50.1068493504,
                "lng": -127.1570666125
            },
            {
                "lat": 50.1074944659,
                "lng": -127.1532054632
            },
            {
                "lat": 50.1081929496,
                "lng": -127.1491165728
            },
            {
                "lat": 50.1074339947,
                "lng": -127.1439212412
            },
            {
                "lat": 50.1070253907,
                "lng": -127.1404109848
            },
            {
                "lat": 50.1075512062,
                "lng": -127.1374895636
            },
            {
                "lat": 50.1094210439,
                "lng": -127.1319430367
            },
            {
                "lat": 50.1115222923,
                "lng": -127.1279137845
            },
            {
                "lat": 50.1151463108,
                "lng": -127.1225967065
            },
            {
                "lat": 50.1185339799,
                "lng": -127.1164014957
            },
            {
                "lat": 50.1216085257,
                "lng": -127.1131900303
            },
            {
                "lat": 50.1265445412,
                "lng": -127.1127547752
            },
            {
                "lat": 50.1320649507,
                "lng": -127.1134800193
            },
            {
                "lat": 50.1356926795,
                "lng": -127.1178361367
            },
            {
                "lat": 50.1391791708,
                "lng": -127.1233517081
            },
            {
                "lat": 50.144699747,
                "lng": -127.1291573932
            },
            {
                "lat": 50.1486201958,
                "lng": -127.1319199536
            },
            {
                "lat": 50.1522477988,
                "lng": -127.1325001382
            },
            {
                "lat": 50.1547173288,
                "lng": -127.1317743197
            },
            {
                "lat": 50.1571852787,
                "lng": -127.1303248775
            },
            {
                "lat": 50.1582039146,
                "lng": -127.1274184634
            },
            {
                "lat": 50.1579124161,
                "lng": -127.1219021393
            },
            {
                "lat": 50.1560244983,
                "lng": -127.1156546852
            },
            {
                "lat": 50.1544259347,
                "lng": -127.1085356316
            },
            {
                "lat": 50.1541361064,
                "lng": -127.1035999363
            },
            {
                "lat": 50.1535565435,
                "lng": -127.0982286616
            },
            {
                "lat": 50.1526830978,
                "lng": -127.0943069659
            },
            {
                "lat": 50.1507947961,
                "lng": -127.0903855648
            },
            {
                "lat": 50.1461488346,
                "lng": -127.0871962932
            },
            {
                "lat": 50.1391789437,
                "lng": -127.0840003761
            },
            {
                "lat": 50.1330802364,
                "lng": -127.0819628511
            },
            {
                "lat": 50.1269834842,
                "lng": -127.0786284986
            },
            {
                "lat": 50.1249506773,
                "lng": -127.0722351132
            },
            {
                "lat": 50.123352616,
                "lng": -127.0668639369
            },
            {
                "lat": 50.1216085422,
                "lng": -127.0659943298
            },
            {
                "lat": 50.1185575591,
                "lng": -127.0662838666
            },
            {
                "lat": 50.1156539094,
                "lng": -127.0704960625
            },
            {
                "lat": 50.1136214665,
                "lng": -127.0761573233
            },
            {
                "lat": 50.1091194183,
                "lng": -127.0803680845
            },
            {
                "lat": 50.1024395,
                "lng": -127.0850137069
            },
            {
                "lat": 50.100261245,
                "lng": -127.08806627
            },
            {
                "lat": 50.0993920506,
                "lng": -127.0927121645
            },
            {
                "lat": 50.098083222,
                "lng": -127.0993874373
            },
            {
                "lat": 50.0963404461,
                "lng": -127.1059268623
            },
            {
                "lat": 50.0934369896,
                "lng": -127.1143498177
            },
            {
                "lat": 50.090533534,
                "lng": -127.1181260072
            },
            {
                "lat": 50.0885013431,
                "lng": -127.1253806706
            },
            {
                "lat": 50.0867573807,
                "lng": -127.1326444815
            },
            {
                "lat": 50.0835604869,
                "lng": -127.1355513018
            },
            {
                "lat": 50.0806584611,
                "lng": -127.1406328427
            },
            {
                "lat": 50.0789141781,
                "lng": -127.1446992044
            },
            {
                "lat": 50.0760120982,
                "lng": -127.1439747794
            },
            {
                "lat": 50.0742684982,
                "lng": -127.1407784679
            },
            {
                "lat": 50.0728151337,
                "lng": -127.1326444734
            },
            {
                "lat": 50.0715106192,
                "lng": -127.1197207108
            },
            {
                "lat": 50.0703475521,
                "lng": -127.1069408872
            },
            {
                "lat": 50.0697667258,
                "lng": -127.0999753784
            },
            {
                "lat": 50.0670083069,
                "lng": -127.0921327151
            },
            {
                "lat": 50.065409936,
                "lng": -127.088645676
            },
            {
                "lat": 50.0627979457,
                "lng": -127.0855939626
            },
            {
                "lat": 50.0582961461,
                "lng": -127.0867542408
            },
            {
                "lat": 50.0543750332,
                "lng": -127.0903853254
            },
            {
                "lat": 50.0543752609,
                "lng": -127.0976485876
            },
            {
                "lat": 50.0559738388,
                "lng": -127.1115882916
            },
            {
                "lat": 50.0572776527,
                "lng": -127.1217567177
            },
            {
                "lat": 50.0584417405,
                "lng": -127.1310501752
            },
            {
                "lat": 50.0598908973,
                "lng": -127.1401984939
            },
            {
                "lat": 50.0582958385,
                "lng": -127.1512295689
            },
            {
                "lat": 50.0582961499,
                "lng": -127.1576232124
            },
            {
                "lat": 50.0581515068,
                "lng": -127.1627045881
            },
            {
                "lat": 50.0578571575,
                "lng": -127.1677855786
            },
            {
                "lat": 50.0578574475,
                "lng": -127.1715620332
            },
            {
                "lat": 50.0495533327,
                "lng": -127.1682658089
            },
            {
                "lat": 50.0427543979,
                "lng": -127.167838473
            },
            {
                "lat": 50.0389829177,
                "lng": -127.1692357493
            },
            {
                "lat": 50.0311051016,
                "lng": -127.1730121108
            },
            {
                "lat": 50.0284074284,
                "lng": -127.1761400721
            },
            {
                "lat": 50.0279386959,
                "lng": -127.1689298602
            },
            {
                "lat": 50.0312843769,
                "lng": -127.1595617113
            },
            {
                "lat": 50.0336267051,
                "lng": -127.1515349712
            },
            {
                "lat": 50.034293313,
                "lng": -127.1378176524
            },
            {
                "lat": 50.0339582462,
                "lng": -127.1284569532
            },
            {
                "lat": 50.0339583905,
                "lng": -127.1130678405
            },
            {
                "lat": 50.0359653492,
                "lng": -127.0980150352
            },
            {
                "lat": 50.0383077358,
                "lng": -127.0903168796
            },
            {
                "lat": 50.0379756179,
                "lng": -127.084296941
            },
            {
                "lat": 50.031952199,
                "lng": -127.0755992973
            },
            {
                "lat": 50.0265997228,
                "lng": -127.072258446
            },
            {
                "lat": 50.0202447014,
                "lng": -127.0742642942
            },
            {
                "lat": 50.0175703425,
                "lng": -127.0802838577
            },
            {
                "lat": 50.0168987109,
                "lng": -127.0913235103
            },
            {
                "lat": 50.0145564552,
                "lng": -127.1047060079
            },
            {
                "lat": 50.0135540734,
                "lng": -127.1221002991
            },
            {
                "lat": 50.013217702,
                "lng": -127.1354753117
            },
            {
                "lat": 50.0105435659,
                "lng": -127.1364822506
            },
            {
                "lat": 50.0071981247,
                "lng": -127.1321331391
            },
            {
                "lat": 50.001846379,
                "lng": -127.1194225319
            },
            {
                "lat": 49.9981685128,
                "lng": -127.1170804021
            },
            {
                "lat": 49.9938203648,
                "lng": -127.1180871679
            },
            {
                "lat": 49.9898028665,
                "lng": -127.1224289458
            },
            {
                "lat": 49.9884680773,
                "lng": -127.1317975137
            },
            {
                "lat": 49.9901395248,
                "lng": -127.1495293799
            },
            {
                "lat": 49.9901396113,
                "lng": -127.169265684
            },
            {
                "lat": 49.994152589,
                "lng": -127.1813044786
            },
            {
                "lat": 49.9894711751,
                "lng": -127.1906739415
            },
            {
                "lat": 49.9824442687,
                "lng": -127.2027129559
            },
            {
                "lat": 49.9760893146,
                "lng": -127.2174309565
            },
            {
                "lat": 49.9690660917,
                "lng": -127.2211069383
            },
            {
                "lat": 49.9663882566,
                "lng": -127.2251274896
            },
            {
                "lat": 49.9647180625,
                "lng": -127.2301408475
            },
            {
                "lat": 49.9614639427,
                "lng": -127.2269824334
            },
            {
                "lat": 49.9539225553,
                "lng": -127.2134092978
            },
            {
                "lat": 49.948646999,
                "lng": -127.2038574899
            },
            {
                "lat": 49.942360458,
                "lng": -127.200087751
            },
            {
                "lat": 49.9333150288,
                "lng": -127.1975779561
            },
            {
                "lat": 49.9209981282,
                "lng": -127.1902845683
            },
            {
                "lat": 49.9064215982,
                "lng": -127.1814888741
            },
            {
                "lat": 49.8890807244,
                "lng": -127.1686714704
            },
            {
                "lat": 49.8745007272,
                "lng": -127.1578681177
            },
            {
                "lat": 49.863441383,
                "lng": -127.1493226251
            },
            {
                "lat": 49.8659558515,
                "lng": -127.141021363
            },
            {
                "lat": 49.8707312098,
                "lng": -127.1279526641
            },
            {
                "lat": 49.8719862329,
                "lng": -127.1108631883
            },
            {
                "lat": 49.8729930734,
                "lng": -127.0962908524
            },
            {
                "lat": 49.8724900876,
                "lng": -127.0804520087
            },
            {
                "lat": 49.8669626279,
                "lng": -127.065375553
            },
            {
                "lat": 49.8629384175,
                "lng": -127.0475314386
            },
            {
                "lat": 49.861683571,
                "lng": -127.0294338225
            },
            {
                "lat": 49.8629385005,
                "lng": -127.0120924311
            },
            {
                "lat": 49.8647007854,
                "lng": -127.0073157495
            },
            {
                "lat": 49.8694760039,
                "lng": -127.0168688587
            },
            {
                "lat": 49.8858106271,
                "lng": -127.0339588633
            },
            {
                "lat": 49.9252705668,
                "lng": -127.0535583053
            },
            {
                "lat": 49.9431145047,
                "lng": -127.060852196
            },
            {
                "lat": 49.9473871111,
                "lng": -127.0533072472
            },
            {
                "lat": 49.9491459881,
                "lng": -127.0425038015
            },
            {
                "lat": 49.9408526186,
                "lng": -127.034713323
            },
            {
                "lat": 49.9147152793,
                "lng": -127.0140992409
            },
            {
                "lat": 49.8963660342,
                "lng": -126.9972608963
            },
            {
                "lat": 49.8920934814,
                "lng": -126.9857024646
            },
            {
                "lat": 49.8863146702,
                "lng": -126.9758986781
            },
            {
                "lat": 49.8797802743,
                "lng": -126.9676057127
            },
            {
                "lat": 49.8732457416,
                "lng": -126.9590603987
            },
            {
                "lat": 49.8689733942,
                "lng": -126.9610676785
            },
            {
                "lat": 49.8703156523,
                "lng": -126.9479069946
            },
            {
                "lat": 49.8773539509,
                "lng": -126.9520184913
            },
            {
                "lat": 49.8910327681,
                "lng": -126.9485406723
            },
            {
                "lat": 49.9167674782,
                "lng": -126.9529419203
            },
            {
                "lat": 49.9575725405,
                "lng": -126.9538734028
            },
            {
                "lat": 49.9786678041,
                "lng": -126.9541015587
            },
            {
                "lat": 49.9846961878,
                "lng": -126.953178252
            },
            {
                "lat": 49.992115328,
                "lng": -126.9494699803
            },
            {
                "lat": 49.992813741,
                "lng": -126.9445952652
            },
            {
                "lat": 49.9909554822,
                "lng": -126.9348606439
            },
            {
                "lat": 49.9870149713,
                "lng": -126.9269794114
            },
            {
                "lat": 49.9798273384,
                "lng": -126.9207160298
            },
            {
                "lat": 49.9691615061,
                "lng": -126.9167790923
            },
            {
                "lat": 49.966614601,
                "lng": -126.9109799494
            },
            {
                "lat": 49.9663814431,
                "lng": -126.8975375166
            },
            {
                "lat": 49.9656863668,
                "lng": -126.8813021229
            },
            {
                "lat": 49.9652210717,
                "lng": -126.8718029212
            },
            {
                "lat": 49.9638283407,
                "lng": -126.8669285745
            },
            {
                "lat": 49.9698601372,
                "lng": -126.8657693591
            },
            {
                "lat": 49.9779740374,
                "lng": -126.8653106592
            },
            {
                "lat": 49.9863199778,
                "lng": -126.8648457221
            },
            {
                "lat": 49.9893340544,
                "lng": -126.8604428654
            },
            {
                "lat": 49.9902605271,
                "lng": -126.8523255243
            },
            {
                "lat": 49.9877087467,
                "lng": -126.8421249959
            },
            {
                "lat": 49.9779740699,
                "lng": -126.8374857005
            },
            {
                "lat": 49.9693940863,
                "lng": -126.8388747329
            },
            {
                "lat": 49.9652208836,
                "lng": -126.826362618
            },
            {
                "lat": 49.9605863777,
                "lng": -126.8054964969
            },
            {
                "lat": 49.9566421646,
                "lng": -126.7955249175
            },
            {
                "lat": 49.949920233,
                "lng": -126.7913514941
            },
            {
                "lat": 49.9420389905,
                "lng": -126.7911226986
            },
            {
                "lat": 49.9405825385,
                "lng": -126.7951347258
            },
            {
                "lat": 49.8663868114,
                "lng": -126.6840587515
            },
            {
                "lat": 49.864994183,
                "lng": -126.6724322769
            },
            {
                "lat": 49.8685611581,
                "lng": -126.6766658954
            },
            {
                "lat": 49.8755261539,
                "lng": -126.6787876494
            },
            {
                "lat": 49.8956718176,
                "lng": -126.6762154362
            },
            {
                "lat": 49.9055139808,
                "lng": -126.6737893197
            },
            {
                "lat": 49.9177359721,
                "lng": -126.6688839446
            },
            {
                "lat": 49.9272346346,
                "lng": -126.6640852204
            },
            {
                "lat": 49.9278109515,
                "lng": -126.6555401786
            },
            {
                "lat": 49.9243577421,
                "lng": -126.6456602327
            },
            {
                "lat": 49.9179269173,
                "lng": -126.6384658923
            },
            {
                "lat": 49.910541713,
                "lng": -126.6366426368
            },
            {
                "lat": 49.9037281879,
                "lng": -126.6392280738
            },
            {
                "lat": 49.9002729903,
                "lng": -126.6434552054
            },
            {
                "lat": 49.8905332233,
                "lng": -126.6443479179
            },
            {
                "lat": 49.6970640415,
                "lng": -126.5865477638
            },
            {
                "lat": 49.6986843398,
                "lng": -126.5780950031
            },
            {
                "lat": 49.699927926,
                "lng": -126.5722126865
            },
            {
                "lat": 49.7249872207,
                "lng": -126.5117724471
            },
            {
                "lat": 49.7275309694,
                "lng": -126.5142601753
            },
            {
                "lat": 49.735920194,
                "lng": -126.5226515245
            },
            {
                "lat": 49.7415730195,
                "lng": -126.5251994164
            },
            {
                "lat": 49.7481424759,
                "lng": -126.5235588453
            },
            {
                "lat": 49.7546164797,
                "lng": -126.5216444364
            },
            {
                "lat": 49.7577175568,
                "lng": -126.5179977311
            },
            {
                "lat": 49.7621843029,
                "lng": -126.5089724672
            },
            {
                "lat": 49.7630040534,
                "lng": -126.5068741237
            },
            {
                "lat": 49.7622753006,
                "lng": -126.5052339197
            },
            {
                "lat": 49.7582624438,
                "lng": -126.505233955
            },
            {
                "lat": 49.7514228308,
                "lng": -126.5082401167
            },
            {
                "lat": 49.7468641895,
                "lng": -126.5097883004
            },
            {
                "lat": 49.7404821854,
                "lng": -126.5040431421
            },
            {
                "lat": 49.7392958328,
                "lng": -126.4971164437
            },
            {
                "lat": 49.7402074085,
                "lng": -126.4908218953
            },
            {
                "lat": 49.7398419497,
                "lng": -126.4848022659
            },
            {
                "lat": 49.738654354,
                "lng": -126.4806975742
            },
            {
                "lat": 49.7380480542,
                "lng": -126.4802630343
            },
            {
                "lat": 49.7463649422,
                "lng": -126.4602049672
            },
            {
                "lat": 49.7478441476,
                "lng": -126.4590684657
            },
            {
                "lat": 49.7481760827,
                "lng": -126.458731932
            },
            {
                "lat": 49.7503172044,
                "lng": -126.4633797215
            },
            {
                "lat": 49.7587466322,
                "lng": -126.4752584152
            },
            {
                "lat": 49.7719688734,
                "lng": -126.4859849289
            },
            {
                "lat": 49.7840390948,
                "lng": -126.4936449385
            },
            {
                "lat": 49.7940029832,
                "lng": -126.5030364069
            },
            {
                "lat": 49.8053059277,
                "lng": -126.5082099513
            },
            {
                "lat": 49.8091395503,
                "lng": -126.5057140449
            },
            {
                "lat": 49.8089482949,
                "lng": -126.4972911047
            },
            {
                "lat": 49.8041564802,
                "lng": -126.4829174012
            },
            {
                "lat": 49.7982172653,
                "lng": -126.4664389744
            },
            {
                "lat": 49.7974509846,
                "lng": -126.456092685
            },
            {
                "lat": 49.7985990366,
                "lng": -126.4459380887
            },
            {
                "lat": 49.7972604846,
                "lng": -126.4380801079
            },
            {
                "lat": 49.7945781047,
                "lng": -126.4323353508
            },
            {
                "lat": 49.7911266658,
                "lng": -126.4304197168
            },
            {
                "lat": 49.7865288792,
                "lng": -126.4304199462
            },
            {
                "lat": 49.7811660928,
                "lng": -126.4252473358
            },
            {
                "lat": 49.7790565711,
                "lng": -126.4179689543
            },
            {
                "lat": 49.7780994962,
                "lng": -126.4112628546
            },
            {
                "lat": 49.7757990952,
                "lng": -126.4032135836
            },
            {
                "lat": 49.7721599611,
                "lng": -126.3965067856
            },
            {
                "lat": 49.7673686104,
                "lng": -126.3924869178
            },
            {
                "lat": 49.7618141771,
                "lng": -126.385971631
            },
            {
                "lat": 49.7577895706,
                "lng": -126.3821327252
            },
            {
                "lat": 49.7535745419,
                "lng": -126.3807978965
            },
            {
                "lat": 49.7491677982,
                "lng": -126.3815615565
            },
            {
                "lat": 49.7476348369,
                "lng": -126.3865433652
            },
            {
                "lat": 49.7478258643,
                "lng": -126.3928677215
            },
            {
                "lat": 49.7505077929,
                "lng": -126.402251828
            },
            {
                "lat": 49.752041341,
                "lng": -126.4085764668
            },
            {
                "lat": 49.7508918273,
                "lng": -126.4152839161
            },
            {
                "lat": 49.7489784167,
                "lng": -126.4227530334
            },
            {
                "lat": 49.7499349136,
                "lng": -126.431572344
            },
            {
                "lat": 49.7464869389,
                "lng": -126.4348299542
            },
            {
                "lat": 49.7268673776,
                "lng": -126.4417491838
            },
            {
                "lat": 49.6612696918,
                "lng": -126.4410325396
            },
            {
                "lat": 49.6661905093,
                "lng": -126.4286807665
            },
            {
                "lat": 49.6693839873,
                "lng": -126.3909139753
            },
            {
                "lat": 49.6696622401,
                "lng": -126.3732828932
            },
            {
                "lat": 49.6659123628,
                "lng": -126.3637008474
            },
            {
                "lat": 49.6621662856,
                "lng": -126.3609237574
            },
            {
                "lat": 49.6563338552,
                "lng": -126.3570398062
            },
            {
                "lat": 49.6503640606,
                "lng": -126.3503726801
            },
            {
                "lat": 49.6492533409,
                "lng": -126.3469012255
            },
            {
                "lat": 49.6517377981,
                "lng": -126.3364482099
            },
            {
                "lat": 49.6525875064,
                "lng": -126.3162840125
            },
            {
                "lat": 49.6600146975,
                "lng": -126.2670371618
            },
            {
                "lat": 49.6689300043,
                "lng": -126.2332915855
            },
            {
                "lat": 49.6719019515,
                "lng": -126.2256467962
            },
            {
                "lat": 49.6740265918,
                "lng": -126.2165220871
            },
            {
                "lat": 49.6804922814,
                "lng": -126.1875305391
            },
            {
                "lat": 49.6872478234,
                "lng": -126.1378788641
            },
            {
                "lat": 49.6987078428,
                "lng": -126.1246561596
            },
            {
                "lat": 49.7145728037,
                "lng": -126.1055604176
            },
            {
                "lat": 49.7157474987,
                "lng": -126.0985032839
            },
            {
                "lat": 49.7113424139,
                "lng": -126.0920410517
            },
            {
                "lat": 49.7034073371,
                "lng": -126.0943905133
            },
            {
                "lat": 49.6954759855,
                "lng": -126.1008524779
            },
            {
                "lat": 49.6869544434,
                "lng": -126.1008528277
            },
            {
                "lat": 49.6749076976,
                "lng": -126.0917517169
            },
            {
                "lat": 49.6625666552,
                "lng": -126.0817574943
            },
            {
                "lat": 49.6572800907,
                "lng": -126.0729442462
            },
            {
                "lat": 49.6481706769,
                "lng": -126.0685343823
            },
            {
                "lat": 49.6405292391,
                "lng": -126.0567859344
            },
            {
                "lat": 49.6349481662,
                "lng": -126.0479665061
            },
            {
                "lat": 49.6299552518,
                "lng": -126.0420906529
            },
            {
                "lat": 49.623489528,
                "lng": -126.0388640861
            },
            {
                "lat": 49.6170273889,
                "lng": -126.0362167624
            },
            {
                "lat": 49.610268183,
                "lng": -126.0356288681
            },
            {
                "lat": 49.6049806846,
                "lng": -126.0394517188
            },
            {
                "lat": 49.603805892,
                "lng": -126.0479660385
            },
            {
                "lat": 49.6035113933,
                "lng": -126.0570756097
            },
            {
                "lat": 49.6137920833,
                "lng": -126.0711819432
            },
            {
                "lat": 49.6237829736,
                "lng": -126.078529311
            },
            {
                "lat": 49.6287806354,
                "lng": -126.0914531019
            },
            {
                "lat": 49.637886533,
                "lng": -126.0999746824
            },
            {
                "lat": 49.6464081749,
                "lng": -126.1043849941
            },
            {
                "lat": 49.64640818,
                "lng": -126.1120224278
            },
            {
                "lat": 49.6428831307,
                "lng": -126.1243595926
            },
            {
                "lat": 49.6414150969,
                "lng": -126.1411052983
            },
            {
                "lat": 49.6411170478,
                "lng": -126.1587370544
            },
            {
                "lat": 49.6417085452,
                "lng": -126.1734320229
            },
            {
                "lat": 49.6452334214,
                "lng": -126.1848903753
            },
            {
                "lat": 49.6467015336,
                "lng": -126.1925277136
            },
            {
                "lat": 49.6475826938,
                "lng": -126.2036889859
            },
            {
                "lat": 49.6443523196,
                "lng": -126.2145616099
            },
            {
                "lat": 49.6402357475,
                "lng": -126.2230826913
            },
            {
                "lat": 49.6361240628,
                "lng": -126.2354274966
            },
            {
                "lat": 49.6314244581,
                "lng": -126.2577514892
            },
            {
                "lat": 49.6302484929,
                "lng": -126.2771453414
            },
            {
                "lat": 49.6299550959,
                "lng": -126.3091733912
            },
            {
                "lat": 49.6314244748,
                "lng": -126.331505446
            },
            {
                "lat": 49.6328924719,
                "lng": -126.3391417682
            },
            {
                "lat": 49.6329842735,
                "lng": -126.3420559334
            },
            {
                "lat": 49.6270146781,
                "lng": -126.3391425506
            },
            {
                "lat": 49.6243706564,
                "lng": -126.3520666384
            },
            {
                "lat": 49.621433563,
                "lng": -126.3829191994
            },
            {
                "lat": 49.6252520612,
                "lng": -126.4040753075
            },
            {
                "lat": 49.6273078296,
                "lng": -126.4211191684
            },
            {
                "lat": 49.6208457199,
                "lng": -126.4422689094
            },
            {
                "lat": 49.6193775733,
                "lng": -126.4537273443
            },
            {
                "lat": 49.6234896064,
                "lng": -126.4663620081
            },
            {
                "lat": 49.6132158871,
                "lng": -126.4801563031
            },
            {
                "lat": 49.5961110536,
                "lng": -126.5031664786
            },
            {
                "lat": 49.5884018858,
                "lng": -126.5137635536
            },
            {
                "lat": 49.5874367699,
                "lng": -126.5176241583
            },
            {
                "lat": 49.583942025,
                "lng": -126.5269010667
            },
            {
                "lat": 49.5810514956,
                "lng": -126.5418400042
            },
            {
                "lat": 49.5761141954,
                "lng": -126.5481028252
            },
            {
                "lat": 49.5745473324,
                "lng": -126.5534055432
            },
            {
                "lat": 49.5751492735,
                "lng": -126.5601498403
            },
            {
                "lat": 49.5687638753,
                "lng": -126.5586999546
            },
            {
                "lat": 49.5588835308,
                "lng": -126.5579827207
            },
            {
                "lat": 49.5474399579,
                "lng": -126.5572589889
            },
            {
                "lat": 49.539249086,
                "lng": -126.5556945537
            },
            {
                "lat": 49.5365981171,
                "lng": -126.5565333932
            },
            {
                "lat": 49.5327413227,
                "lng": -126.5608671069
            },
            {
                "lat": 49.522170984,
                "lng": -126.5464862349
            },
            {
                "lat": 49.513988089,
                "lng": -126.5441975056
            },
            {
                "lat": 49.5025329231,
                "lng": -126.5477982267
            },
            {
                "lat": 49.4760169473,
                "lng": -126.5422366594
            },
            {
                "lat": 49.453429861,
                "lng": -126.5373232406
            },
            {
                "lat": 49.4400097377,
                "lng": -126.5402684336
            },
            {
                "lat": 49.4233132082,
                "lng": -126.5419087811
            },
            {
                "lat": 49.404983681,
                "lng": -126.5369946167
            },
            {
                "lat": 49.392871967,
                "lng": -126.5333938549
            },
            {
                "lat": 49.3869777495,
                "lng": -126.5337224906
            },
            {
                "lat": 49.3859252704,
                "lng": -126.5193098682
            },
            {
                "lat": 49.3874015979,
                "lng": -126.509422743
            },
            {
                "lat": 49.38865313,
                "lng": -126.4970395801
            },
            {
                "lat": 49.3877449845,
                "lng": -126.4882968419
            },
            {
                "lat": 49.3854714917,
                "lng": -126.4812546533
            },
            {
                "lat": 49.4012258745,
                "lng": -126.4766996888
            },
            {
                "lat": 49.4081084432,
                "lng": -126.4811398738
            },
            {
                "lat": 49.4172132904,
                "lng": -126.4949036301
            },
            {
                "lat": 49.4298664106,
                "lng": -126.4960180346
            },
            {
                "lat": 49.4414135333,
                "lng": -126.4906843706
            },
            {
                "lat": 49.4560655405,
                "lng": -126.479583671
            },
            {
                "lat": 49.4753800903,
                "lng": -126.4627074519
            },
            {
                "lat": 49.4791565004,
                "lng": -126.4438398803
            },
            {
                "lat": 49.4800447561,
                "lng": -126.4280775504
            },
            {
                "lat": 49.480266005,
                "lng": -126.4085385414
            },
            {
                "lat": 49.479377688,
                "lng": -126.4020998831
            },
            {
                "lat": 49.476715416,
                "lng": -126.3976596
            },
            {
                "lat": 49.4651680864,
                "lng": -126.3945461296
            },
            {
                "lat": 49.4491844442,
                "lng": -126.3952185011
            },
            {
                "lat": 49.4316439765,
                "lng": -126.3949970917
            },
            {
                "lat": 49.4200967636,
                "lng": -126.3981015764
            },
            {
                "lat": 49.4163249238,
                "lng": -126.3981018195
            },
            {
                "lat": 49.4135089571,
                "lng": -126.4005813385
            },
            {
                "lat": 49.4132387689,
                "lng": -126.3539813161
            },
            {
                "lat": 49.407688213,
                "lng": -126.3389965409
            },
            {
                "lat": 49.3810045933,
                "lng": -126.308569952
            },
            {
                "lat": 49.3826064584,
                "lng": -126.2802880322
            },
            {
                "lat": 49.3986170884,
                "lng": -126.266410223
            },
            {
                "lat": 49.412719503,
                "lng": -126.2639240647
            },
            {
                "lat": 49.4226345239,
                "lng": -126.2637413313
            },
            {
                "lat": 49.4263691068,
                "lng": -126.2680138885
            },
            {
                "lat": 49.4237023002,
                "lng": -126.2813566638
            },
            {
                "lat": 49.4156952263,
                "lng": -126.297897653
            },
            {
                "lat": 49.4130245731,
                "lng": -126.3139114873
            },
            {
                "lat": 49.4146277318,
                "lng": -126.3288496871
            },
            {
                "lat": 49.4215660001,
                "lng": -126.3347243265
            },
            {
                "lat": 49.4349093895,
                "lng": -126.3283154102
            },
            {
                "lat": 49.4583928374,
                "lng": -126.3021695381
            },
            {
                "lat": 49.4856105605,
                "lng": -126.3096387128
            },
            {
                "lat": 49.5331115567,
                "lng": -126.313377311
            },
            {
                "lat": 49.5379136808,
                "lng": -126.306968425
            },
            {
                "lat": 49.5368462684,
                "lng": -126.2968289555
            },
            {
                "lat": 49.5208356161,
                "lng": -126.2845542476
            },
            {
                "lat": 49.4893452541,
                "lng": -126.2685466588
            },
            {
                "lat": 49.4834745496,
                "lng": -126.2541353615
            },
            {
                "lat": 49.4882778375,
                "lng": -126.2343908518
            },
            {
                "lat": 49.4818761427,
                "lng": -126.2157055497
            },
            {
                "lat": 49.4621274606,
                "lng": -126.2023689753
            },
            {
                "lat": 49.4407803533,
                "lng": -126.1986309532
            },
            {
                "lat": 49.4237021706,
                "lng": -126.2055659845
            },
            {
                "lat": 49.4167639002,
                "lng": -126.2178420913
            },
            {
                "lat": 49.4135973615,
                "lng": -126.2203989115
            },
            {
                "lat": 49.4235146925,
                "lng": -126.1237179274
            },
            {
                "lat": 49.4241025983,
                "lng": -126.1244882729
            },
            {
                "lat": 49.4250909436,
                "lng": -126.1261972341
            },
            {
                "lat": 49.4267278215,
                "lng": -126.1268086492
            },
            {
                "lat": 49.4298290916,
                "lng": -126.125954446
            },
            {
                "lat": 49.4314798904,
                "lng": -126.1247397425
            },
            {
                "lat": 49.4330862981,
                "lng": -126.1207272985
            },
            {
                "lat": 49.4342120227,
                "lng": -126.1091609289
            },
            {
                "lat": 49.4347720846,
                "lng": -126.0958250048
            },
            {
                "lat": 49.4363786168,
                "lng": -126.0920480709
            },
            {
                "lat": 49.4395905425,
                "lng": -126.0886003296
            },
            {
                "lat": 49.4426416596,
                "lng": -126.0870740526
            },
            {
                "lat": 49.4434474712,
                "lng": -126.084984481
            },
            {
                "lat": 49.4436067618,
                "lng": -126.0796045994
            },
            {
                "lat": 49.4431273188,
                "lng": -126.0761493596
            },
            {
                "lat": 49.4405557087,
                "lng": -126.0724566729
            },
            {
                "lat": 49.4402355297,
                "lng": -126.0684430748
            },
            {
                "lat": 49.440795424,
                "lng": -126.0636978797
            },
            {
                "lat": 49.4428024606,
                "lng": -126.0565567731
            },
            {
                "lat": 49.4464985518,
                "lng": -126.0518956486
            },
            {
                "lat": 49.4501110803,
                "lng": -126.0468374801
            },
            {
                "lat": 49.4534077873,
                "lng": -126.0420151204
            },
            {
                "lat": 49.4557337053,
                "lng": -126.0387264275
            },
            {
                "lat": 49.4584659833,
                "lng": -126.0357513081
            },
            {
                "lat": 49.4599107095,
                "lng": -126.0318151615
            },
            {
                "lat": 49.4593508371,
                "lng": -126.0290828405
            },
            {
                "lat": 49.4571798085,
                "lng": -126.0263514037
            },
            {
                "lat": 49.4546093749,
                "lng": -126.0263516609
            },
            {
                "lat": 49.4502750927,
                "lng": -126.0288462831
            },
            {
                "lat": 49.4460177356,
                "lng": -126.0323792155
            },
            {
                "lat": 49.439910717,
                "lng": -126.0331801278
            },
            {
                "lat": 49.4354171799,
                "lng": -126.0315778668
            },
            {
                "lat": 49.4325252759,
                "lng": -126.0306089419
            },
            {
                "lat": 49.4285088449,
                "lng": -126.0309291503
            },
            {
                "lat": 49.4240107949,
                "lng": -126.0329440375
            },
            {
                "lat": 49.4220013039,
                "lng": -126.0369566941
            },
            {
                "lat": 49.4192736116,
                "lng": -126.0436256279
            },
            {
                "lat": 49.41919354,
                "lng": -126.046837157
            },
            {
                "lat": 49.419754667,
                "lng": -126.0554276204
            },
            {
                "lat": 49.4201550583,
                "lng": -126.0596847898
            },
            {
                "lat": 49.4203143554,
                "lng": -126.0624163335
            },
            {
                "lat": 49.4182738145,
                "lng": -126.0619203652
            },
            {
                "lat": 49.4160303591,
                "lng": -126.0607450493
            },
            {
                "lat": 49.4148522376,
                "lng": -126.0586244421
            },
            {
                "lat": 49.4132039407,
                "lng": -126.0575637216
            },
            {
                "lat": 49.4096683734,
                "lng": -126.0563811397
            },
            {
                "lat": 49.4063678369,
                "lng": -126.0568547288
            },
            {
                "lat": 49.4043655724,
                "lng": -126.060859664
            },
            {
                "lat": 49.4031828117,
                "lng": -126.0643998836
            },
            {
                "lat": 49.4016530043,
                "lng": -126.0655740116
            },
            {
                "lat": 49.3836168205,
                "lng": -126.0625147889
            },
            {
                "lat": 49.3790208679,
                "lng": -126.0590968085
            },
            {
                "lat": 49.3760720289,
                "lng": -126.0562671312
            },
            {
                "lat": 49.3737146946,
                "lng": -126.0535511428
            },
            {
                "lat": 49.3706517369,
                "lng": -126.0526129121
            },
            {
                "lat": 49.3682942942,
                "lng": -126.053436534
            },
            {
                "lat": 49.367351415,
                "lng": -126.0556787328
            },
            {
                "lat": 49.3668788165,
                "lng": -126.0581518452
            },
            {
                "lat": 49.3649938625,
                "lng": -126.0587384343
            },
            {
                "lat": 49.361812435,
                "lng": -126.0585022744
            },
            {
                "lat": 49.3603978956,
                "lng": -126.0586242836
            },
            {
                "lat": 49.3596574799,
                "lng": -126.0586241992
            },
            {
                "lat": 49.3592488716,
                "lng": -126.0586546302
            },
            {
                "lat": 49.3558005157,
                "lng": -126.0541459106
            },
            {
                "lat": 49.345191794,
                "lng": -126.0429456814
            },
            {
                "lat": 49.3338738157,
                "lng": -126.0311581121
            },
            {
                "lat": 49.3258585924,
                "lng": -126.0229032239
            },
            {
                "lat": 49.3220715386,
                "lng": -126.0174863204
            },
            {
                "lat": 49.3217163921,
                "lng": -126.0172353652
            },
            {
                "lat": 49.3278654937,
                "lng": -126.0086433065
            },
            {
                "lat": 49.3332857542,
                "lng": -126.002159141
            },
            {
                "lat": 49.3369413066,
                "lng": -125.9981534452
            },
            {
                "lat": 49.3422430026,
                "lng": -125.9960323397
            },
            {
                "lat": 49.3515549891,
                "lng": -125.9941491709
            },
            {
                "lat": 49.3594548228,
                "lng": -125.9916688648
            },
            {
                "lat": 49.3615758766,
                "lng": -125.9877774076
            },
            {
                "lat": 49.360867007,
                "lng": -125.9838934562
            },
            {
                "lat": 49.3546212712,
                "lng": -125.9798813561
            },
            {
                "lat": 49.3550917588,
                "lng": -125.9758763013
            },
            {
                "lat": 49.3575667359,
                "lng": -125.9715114701
            },
            {
                "lat": 49.3638159146,
                "lng": -125.9629066078
            },
            {
                "lat": 49.3675875968,
                "lng": -125.9625544962
            },
            {
                "lat": 49.3719484909,
                "lng": -125.962555262
            },
            {
                "lat": 49.3780791217,
                "lng": -125.9606702933
            },
            {
                "lat": 49.3838543327,
                "lng": -125.9576033228
            },
            {
                "lat": 49.3889230554,
                "lng": -125.9545362809
            },
            {
                "lat": 49.3962292869,
                "lng": -125.9506523195
            },
            {
                "lat": 49.4014169242,
                "lng": -125.9464030141
            },
            {
                "lat": 49.405189934,
                "lng": -125.9405143599
            },
            {
                "lat": 49.4062504013,
                "lng": -125.9334418908
            },
            {
                "lat": 49.4068369949,
                "lng": -125.926955884
            },
            {
                "lat": 49.4082528071,
                "lng": -125.9229502112
            },
            {
                "lat": 49.4097857522,
                "lng": -125.9216532965
            },
            {
                "lat": 49.4132039519,
                "lng": -125.9214168456
            },
            {
                "lat": 49.417213141,
                "lng": -125.9203564747
            },
            {
                "lat": 49.4207499616,
                "lng": -125.9182353503
            },
            {
                "lat": 49.4274671406,
                "lng": -125.9164662974
            },
            {
                "lat": 49.4319455934,
                "lng": -125.9137571632
            },
            {
                "lat": 49.4330060596,
                "lng": -125.9108130011
            },
            {
                "lat": 49.4332422709,
                "lng": -125.9058606429
            },
            {
                "lat": 49.4307674265,
                "lng": -125.902442332
            },
            {
                "lat": 49.4268755934,
                "lng": -125.8990249051
            },
            {
                "lat": 49.4220468439,
                "lng": -125.8951341159
            },
            {
                "lat": 49.4188615108,
                "lng": -125.8930126175
            },
            {
                "lat": 49.4163854801,
                "lng": -125.8919527538
            },
            {
                "lat": 49.4127311361,
                "lng": -125.8915937915
            },
            {
                "lat": 49.4080164422,
                "lng": -125.8918303117
            },
            {
                "lat": 49.4047160373,
                "lng": -125.893714813
            },
            {
                "lat": 49.4016531391,
                "lng": -125.8964305828
            },
            {
                "lat": 49.397762373,
                "lng": -125.8994902323
            },
            {
                "lat": 49.3946996941,
                "lng": -125.903266861
            },
            {
                "lat": 49.3913993156,
                "lng": -125.9080955853
            },
            {
                "lat": 49.388332957,
                "lng": -125.9143451069
            },
            {
                "lat": 49.385268766,
                "lng": -125.9183496399
            },
            {
                "lat": 49.3823201039,
                "lng": -125.9190601248
            },
            {
                "lat": 49.3774875626,
                "lng": -125.9200059874
            },
            {
                "lat": 49.3739509525,
                "lng": -125.9220054542
            },
            {
                "lat": 49.3687670458,
                "lng": -125.9224776523
            },
            {
                "lat": 49.3622814046,
                "lng": -125.9249576532
            },
            {
                "lat": 49.3575665261,
                "lng": -125.9271926188
            },
            {
                "lat": 49.3526153577,
                "lng": -125.9294360985
            },
            {
                "lat": 49.3484919687,
                "lng": -125.9311982775
            },
            {
                "lat": 49.343894909,
                "lng": -125.9333260752
            },
            {
                "lat": 49.3389437692,
                "lng": -125.9374466596
            },
            {
                "lat": 49.3357620422,
                "lng": -125.9408646988
            },
            {
                "lat": 49.3329319308,
                "lng": -125.9441684471
            },
            {
                "lat": 49.3290403375,
                "lng": -125.9451061318
            },
            {
                "lat": 49.3229143227,
                "lng": -125.944756043
            },
            {
                "lat": 49.3172532435,
                "lng": -125.9435800846
            },
            {
                "lat": 49.3113591768,
                "lng": -125.9433442276
            },
            {
                "lat": 49.3049968494,
                "lng": -125.9426350068
            },
            {
                "lat": 49.3001632983,
                "lng": -125.9431080521
            },
            {
                "lat": 49.2962728461,
                "lng": -125.9449921007
            },
            {
                "lat": 49.2948571124,
                "lng": -125.948410529
            },
            {
                "lat": 49.2928549227,
                "lng": -125.9565430592
            },
            {
                "lat": 49.2920306035,
                "lng": -125.9609063591
            },
            {
                "lat": 49.2916754323,
                "lng": -125.9659730575
            },
            {
                "lat": 49.2902600395,
                "lng": -125.9710463557
            },
            {
                "lat": 49.2904963164,
                "lng": -125.9770507013
            },
            {
                "lat": 49.2930911794,
                "lng": -125.9864803143
            },
            {
                "lat": 49.2955661663,
                "lng": -125.9940266084
            },
            {
                "lat": 49.2981609863,
                "lng": -126.0003966458
            },
            {
                "lat": 49.3011053176,
                "lng": -126.0061724337
            },
            {
                "lat": 49.3026396694,
                "lng": -126.0104142383
            },
            {
                "lat": 49.3032263194,
                "lng": -126.0130083116
            },
            {
                "lat": 49.3033449902,
                "lng": -126.0152433691
            },
            {
                "lat": 49.3020668913,
                "lng": -126.0144959557
            },
            {
                "lat": 49.3002052272,
                "lng": -126.0114216628
            },
            {
                "lat": 49.2957150288,
                "lng": -126.0039756852
            },
            {
                "lat": 49.2928920739,
                "lng": -126.0000618079
            },
            {
                "lat": 49.2883984686,
                "lng": -125.9985201874
            },
            {
                "lat": 49.2828791769,
                "lng": -125.9990996806
            },
            {
                "lat": 49.2799908229,
                "lng": -126.0019833036
            },
            {
                "lat": 49.2783239933,
                "lng": -126.0068662401
            },
            {
                "lat": 49.2780688389,
                "lng": -126.0110326191
            },
            {
                "lat": 49.2782598147,
                "lng": -126.0134046531
            },
            {
                "lat": 49.2788011838,
                "lng": -126.017494907
            },
            {
                "lat": 49.2560384012,
                "lng": -125.9621881504
            },
            {
                "lat": 49.2732656429,
                "lng": -125.9359666257
            },
            {
                "lat": 49.2882390804,
                "lng": -125.9205933759
            },
            {
                "lat": 49.2951168373,
                "lng": -125.8910591515
            },
            {
                "lat": 49.2951167637,
                "lng": -125.8615195271
            },
            {
                "lat": 49.3076594883,
                "lng": -125.836028787
            },
            {
                "lat": 49.3193894095,
                "lng": -125.8234863373
            },
            {
                "lat": 49.3323359324,
                "lng": -125.819847386
            },
            {
                "lat": 49.3497354505,
                "lng": -125.8113480309
            },
            {
                "lat": 49.3671336568,
                "lng": -125.795165791
            },
            {
                "lat": 49.3719905126,
                "lng": -125.7797931285
            },
            {
                "lat": 49.3719905888,
                "lng": -125.7627943465
            },
            {
                "lat": 49.3642999478,
                "lng": -125.7567293617
            },
            {
                "lat": 49.353783046,
                "lng": -125.7632065006
            },
            {
                "lat": 49.3412393268,
                "lng": -125.7810065887
            },
            {
                "lat": 49.3307190583,
                "lng": -125.7862627374
            },
            {
                "lat": 49.3193895235,
                "lng": -125.7822195606
            },
            {
                "lat": 49.3084673971,
                "lng": -125.7862622629
            },
            {
                "lat": 49.2890468779,
                "lng": -125.7951655476
            },
            {
                "lat": 49.2651748391,
                "lng": -125.8032599827
            },
            {
                "lat": 49.2562712596,
                "lng": -125.8044737243
            },
            {
                "lat": 49.2506099203,
                "lng": -125.8044735512
            },
            {
                "lat": 49.247997622,
                "lng": -125.7998654295
            },
            {
                "lat": 49.2467112465,
                "lng": -125.7940822222
            },
            {
                "lat": 49.2463912211,
                "lng": -125.7870025373
            },
            {
                "lat": 49.2483212429,
                "lng": -125.7786404348
            },
            {
                "lat": 49.253468173,
                "lng": -125.7660897724
            },
            {
                "lat": 49.2595785814,
                "lng": -125.7567678115
            },
            {
                "lat": 49.2647242799,
                "lng": -125.7474366163
            },
            {
                "lat": 49.2676206046,
                "lng": -125.7406848506
            },
            {
                "lat": 49.270835911,
                "lng": -125.7303841196
            },
            {
                "lat": 49.2711596324,
                "lng": -125.7249224761
            },
            {
                "lat": 49.2663342934,
                "lng": -125.7168811167
            },
            {
                "lat": 49.2615088756,
                "lng": -125.7162328319
            },
            {
                "lat": 49.2557184554,
                "lng": -125.7178413627
            },
            {
                "lat": 49.2492865296,
                "lng": -125.7236335226
            },
            {
                "lat": 49.2415658202,
                "lng": -125.7313541009
            },
            {
                "lat": 49.233844981,
                "lng": -125.740684806
            },
            {
                "lat": 49.2303083406,
                "lng": -125.7432549869
            },
            {
                "lat": 49.2241929611,
                "lng": -125.7448651428
            },
            {
                "lat": 49.2187273734,
                "lng": -125.7448651503
            },
            {
                "lat": 49.2110066583,
                "lng": -125.7442168477
            },
            {
                "lat": 49.2048949153,
                "lng": -125.7429352655
            },
            {
                "lat": 49.1936340831,
                "lng": -125.7413254177
            },
            {
                "lat": 49.184951849,
                "lng": -125.7410045774
            },
            {
                "lat": 49.1798015977,
                "lng": -125.7419666937
            },
            {
                "lat": 49.1736908496,
                "lng": -125.7496871434
            },
            {
                "lat": 49.1662938885,
                "lng": -125.7577290165
            },
            {
                "lat": 49.1617887272,
                "lng": -125.7606278842
            },
            {
                "lat": 49.1556779442,
                "lng": -125.7625577427
            },
            {
                "lat": 49.1534277304,
                "lng": -125.7635192458
            },
            {
                "lat": 49.1514964855,
                "lng": -125.7664183731
            },
            {
                "lat": 49.1498187981,
                "lng": -125.7679663861
            },
            {
                "lat": 49.1508480321,
                "lng": -125.7649692822
            },
            {
                "lat": 49.1517828567,
                "lng": -125.7604063324
            },
            {
                "lat": 49.1507456065,
                "lng": -125.7558509844
            },
            {
                "lat": 49.1521949807,
                "lng": -125.751601775
            },
            {
                "lat": 49.154788562,
                "lng": -125.7487035455
            },
            {
                "lat": 49.1594511713,
                "lng": -125.7482828968
            },
            {
                "lat": 49.1633870741,
                "lng": -125.7489096307
            },
            {
                "lat": 49.1664963452,
                "lng": -125.7506710078
            },
            {
                "lat": 49.169918066,
                "lng": -125.7510833181
            },
            {
                "lat": 49.171470797,
                "lng": -125.7496331556
            },
            {
                "lat": 49.1736490112,
                "lng": -125.7452776935
            },
            {
                "lat": 49.1735454298,
                "lng": -125.7421726503
            },
            {
                "lat": 49.1705395281,
                "lng": -125.7365718515
            },
            {
                "lat": 49.1649435242,
                "lng": -125.7321167756
            },
            {
                "lat": 49.1597629523,
                "lng": -125.7295298406
            },
            {
                "lat": 49.1544765388,
                "lng": -125.7282861327
            },
            {
                "lat": 49.1521948183,
                "lng": -125.727348609
            },
            {
                "lat": 49.1525080772,
                "lng": -125.7246550885
            },
            {
                "lat": 49.1555137852,
                "lng": -125.7224812596
            },
            {
                "lat": 49.161007503,
                "lng": -125.719993436
            },
            {
                "lat": 49.1655650908,
                "lng": -125.7162633641
            },
            {
                "lat": 49.1687770472,
                "lng": -125.7137754717
            },
            {
                "lat": 49.1710589009,
                "lng": -125.71336329
            },
            {
                "lat": 49.1737515726,
                "lng": -125.7151259854
            },
            {
                "lat": 49.1761356863,
                "lng": -125.7160567319
            },
            {
                "lat": 49.1792450884,
                "lng": -125.7152250761
            },
            {
                "lat": 49.1806943899,
                "lng": -125.713676432
            },
            {
                "lat": 49.1809040856,
                "lng": -125.710464555
            },
            {
                "lat": 49.1801753101,
                "lng": -125.707664489
            },
            {
                "lat": 49.1789319854,
                "lng": -125.7046588134
            },
            {
                "lat": 49.1792450155,
                "lng": -125.7032095895
            },
            {
                "lat": 49.1804883931,
                "lng": -125.7000964361
            },
            {
                "lat": 49.1835979226,
                "lng": -125.6959535126
            },
            {
                "lat": 49.1846316112,
                "lng": -125.6932609192
            },
            {
                "lat": 49.1883616573,
                "lng": -125.6909785219
            },
            {
                "lat": 49.19199031,
                "lng": -125.6899419161
            },
            {
                "lat": 49.1957201174,
                "lng": -125.6884924262
            },
            {
                "lat": 49.1983103716,
                "lng": -125.686729954
            },
            {
                "lat": 49.2019390967,
                "lng": -125.6851735898
            },
            {
                "lat": 49.2053607103,
                "lng": -125.6833116453
            },
            {
                "lat": 49.2091941627,
                "lng": -125.6796796638
            },
            {
                "lat": 49.2139589604,
                "lng": -125.6777108769
            },
            {
                "lat": 49.2185214142,
                "lng": -125.6736758119
            },
            {
                "lat": 49.2230802773,
                "lng": -125.670456011
            },
            {
                "lat": 49.2246318677,
                "lng": -125.6668319373
            },
            {
                "lat": 49.2248416512,
                "lng": -125.6638259705
            },
            {
                "lat": 49.2237017122,
                "lng": -125.6605152402
            },
            {
                "lat": 49.2220417754,
                "lng": -125.6582336525
            },
            {
                "lat": 49.2195551876,
                "lng": -125.6572954218
            },
            {
                "lat": 49.2161370671,
                "lng": -125.6569906664
            },
            {
                "lat": 49.2135469263,
                "lng": -125.6578145034
            },
            {
                "lat": 49.2108531764,
                "lng": -125.657195913
            },
            {
                "lat": 49.2091941263,
                "lng": -125.6518096353
            },
            {
                "lat": 49.2128216629,
                "lng": -125.6436154878
            },
            {
                "lat": 49.21758653,
                "lng": -125.6324234096
            },
            {
                "lat": 49.2229764728,
                "lng": -125.6176066998
            },
            {
                "lat": 49.2233921113,
                "lng": -125.6141891716
            },
            {
                "lat": 49.2258799256,
                "lng": -125.6103521776
            },
            {
                "lat": 49.2315784175,
                "lng": -125.6061017776
            },
            {
                "lat": 49.2355157397,
                "lng": -125.6036153689
            },
            {
                "lat": 49.238625067,
                "lng": -125.5981294302
            },
            {
                "lat": 49.2399708577,
                "lng": -125.5934599595
            },
            {
                "lat": 49.2401768081,
                "lng": -125.5898361487
            },
            {
                "lat": 49.2393490635,
                "lng": -125.5870361095
            },
            {
                "lat": 49.2377928212,
                "lng": -125.5852741839
            },
            {
                "lat": 49.2360348762,
                "lng": -125.5849683607
            },
            {
                "lat": 49.2328229033,
                "lng": -125.5857926795
            },
            {
                "lat": 49.2269136612,
                "lng": -125.5869374909
            },
            {
                "lat": 49.2187274952,
                "lng": -125.5904540439
            },
            {
                "lat": 49.215099959,
                "lng": -125.5933613117
            },
            {
                "lat": 49.211784486,
                "lng": -125.5954290355
            },
            {
                "lat": 49.2080533249,
                "lng": -125.595641463
            },
            {
                "lat": 49.2039065525,
                "lng": -125.5970921314
            },
            {
                "lat": 49.1997645751,
                "lng": -125.602584597
            },
            {
                "lat": 49.195720187,
                "lng": -125.6111832134
            },
            {
                "lat": 49.1917842544,
                "lng": -125.6170880533
            },
            {
                "lat": 49.1863942497,
                "lng": -125.6254797829
            },
            {
                "lat": 49.1822508875,
                "lng": -125.627662748
            },
            {
                "lat": 49.176757366,
                "lng": -125.6315986554
            },
            {
                "lat": 49.1666035838,
                "lng": -125.6348116317
            },
            {
                "lat": 49.1618351133,
                "lng": -125.6378177945
            },
            {
                "lat": 49.1548922123,
                "lng": -125.642379896
            },
            {
                "lat": 49.1504358326,
                "lng": -125.6471400122
            },
            {
                "lat": 49.1413158844,
                "lng": -125.6483839748
            },
            {
                "lat": 49.1348919951,
                "lng": -125.646835195
            },
            {
                "lat": 49.1292947664,
                "lng": -125.6438297362
            },
            {
                "lat": 49.1243215589,
                "lng": -125.6444477007
            },
            {
                "lat": 49.1206928387,
                "lng": -125.6467282115
            },
            {
                "lat": 49.1210025229,
                "lng": -125.6518096738
            },
            {
                "lat": 49.1228708619,
                "lng": -125.6596825888
            },
            {
                "lat": 49.1276359697,
                "lng": -125.6656946297
            },
            {
                "lat": 49.1312634583,
                "lng": -125.6736757993
            },
            {
                "lat": 49.1314694059,
                "lng": -125.6796797923
            },
            {
                "lat": 49.1297069472,
                "lng": -125.6853789002
            },
            {
                "lat": 49.1268080527,
                "lng": -125.6934665168
            },
            {
                "lat": 49.1246301553,
                "lng": -125.7026903844
            },
            {
                "lat": 49.1223530634,
                "lng": -125.7085945619
            },
            {
                "lat": 49.1218338609,
                "lng": -125.7139810469
            },
            {
                "lat": 49.120280693,
                "lng": -125.7180103444
            },
            {
                "lat": 49.1180841345,
                "lng": -125.7169118492
            },
            {
                "lat": 49.1132550283,
                "lng": -125.7155994945
            },
            {
                "lat": 49.108639303,
                "lng": -125.7171325706
            },
            {
                "lat": 49.1075401672,
                "lng": -125.7237238517
            },
            {
                "lat": 49.106441225,
                "lng": -125.7366872355
            },
            {
                "lat": 49.1031454949,
                "lng": -125.7373428093
            },
            {
                "lat": 49.0985331647,
                "lng": -125.7224043423
            },
            {
                "lat": 49.0932619349,
                "lng": -125.7193295047
            },
            {
                "lat": 49.0840336877,
                "lng": -125.7206498888
            },
            {
                "lat": 49.0754666692,
                "lng": -125.7285612738
            },
            {
                "lat": 49.0719485291,
                "lng": -125.7342676514
            },
            {
                "lat": 49.070854375,
                "lng": -125.7448115625
            },
            {
                "lat": 49.0673374954,
                "lng": -125.7340470464
            },
            {
                "lat": 49.0572314298,
                "lng": -125.7136162632
            },
            {
                "lat": 49.0462452864,
                "lng": -125.6925272138
            },
            {
                "lat": 49.035701415,
                "lng": -125.6758347784
            },
            {
                "lat": 49.026916792,
                "lng": -125.6694642246
            },
            {
                "lat": 49.0179062027,
                "lng": -125.6659471872
            },
            {
                "lat": 49.0113183192,
                "lng": -125.6641845386
            },
            {
                "lat": 49.0069235946,
                "lng": -125.6644055563
            },
            {
                "lat": 49.0102193837,
                "lng": -125.6472706992
            },
            {
                "lat": 49.00692358,
                "lng": -125.6340941016
            },
            {
                "lat": 49.0005533197,
                "lng": -125.6217888723
            },
            {
                "lat": 48.9943996061,
                "lng": -125.6110222124
            },
            {
                "lat": 48.9856114815,
                "lng": -125.6055299661
            },
            {
                "lat": 48.9757266709,
                "lng": -125.6026769699
            },
            {
                "lat": 48.9724960452,
                "lng": -125.6019592947
            },
            {
                "lat": 48.9621050158,
                "lng": -125.5945442968
            },
            {
                "lat": 48.9708056869,
                "lng": -125.5948643818
            },
            {
                "lat": 48.9812807888,
                "lng": -125.590935398
            },
            {
                "lat": 48.9852098861,
                "lng": -125.5783613862
            },
            {
                "lat": 48.9846884187,
                "lng": -125.5610816668
            },
            {
                "lat": 48.9749978148,
                "lng": -125.5500792923
            },
            {
                "lat": 48.9564018903,
                "lng": -125.5327984764
            },
            {
                "lat": 48.9406890924,
                "lng": -125.5155108655
            },
            {
                "lat": 48.9336203733,
                "lng": -125.5076600241
            },
            {
                "lat": 48.9332280015,
                "lng": -125.5069042951
            },
            {
                "lat": 48.9545588605,
                "lng": -125.4517141819
            },
            {
                "lat": 48.9606973936,
                "lng": -125.4364171994
            },
            {
                "lat": 48.9638173299,
                "lng": -125.4317091495
            },
            {
                "lat": 48.9701457783,
                "lng": -125.4257044665
            },
            {
                "lat": 48.9773251968,
                "lng": -125.4157942933
            },
            {
                "lat": 48.9889443904,
                "lng": -125.4000778291
            },
            {
                "lat": 49.0009039184,
                "lng": -125.3874278922
            },
            {
                "lat": 49.0121845051,
                "lng": -125.3802571096
            },
            {
                "lat": 49.0220948544,
                "lng": -125.3758085426
            },
            {
                "lat": 49.0350797766,
                "lng": -125.3700036169
            },
            {
                "lat": 49.0436213568,
                "lng": -125.3648759351
            },
            {
                "lat": 49.0480648536,
                "lng": -125.3576967266
            },
            {
                "lat": 49.0484083734,
                "lng": -125.3412930438
            },
            {
                "lat": 49.0456736741,
                "lng": -125.3238670662
            },
            {
                "lat": 49.0429378633,
                "lng": -125.3060998457
            },
            {
                "lat": 49.0384978697,
                "lng": -125.286957005
            },
            {
                "lat": 49.0378157475,
                "lng": -125.2736294721
            },
            {
                "lat": 49.0381544465,
                "lng": -125.2520981533
            },
            {
                "lat": 49.0402068034,
                "lng": -125.2350157428
            },
            {
                "lat": 49.0419157079,
                "lng": -125.2261279179
            },
            {
                "lat": 49.0432813887,
                "lng": -125.2124565083
            },
            {
                "lat": 49.0446469079,
                "lng": -125.2035748103
            },
            {
                "lat": 49.0443082215,
                "lng": -125.1977619658
            },
            {
                "lat": 49.0405467998,
                "lng": -125.1950294213
            },
            {
                "lat": 49.0367889907,
                "lng": -125.19605391
            },
            {
                "lat": 49.0326886015,
                "lng": -125.1998125854
            },
            {
                "lat": 49.0299529123,
                "lng": -125.2087016984
            },
            {
                "lat": 49.0265350053,
                "lng": -125.2230539561
            },
            {
                "lat": 49.0241427479,
                "lng": -125.2322762698
            },
            {
                "lat": 49.0214080595,
                "lng": -125.252441611
            },
            {
                "lat": 49.0190157573,
                "lng": -125.262350954
            },
            {
                "lat": 49.0162847612,
                "lng": -125.2702112654
            },
            {
                "lat": 49.0132067279,
                "lng": -125.2773882199
            },
            {
                "lat": 49.0132065431,
                "lng": -125.286276643
            },
            {
                "lat": 49.0142323443,
                "lng": -125.2924273444
            },
            {
                "lat": 49.0128667684,
                "lng": -125.2968683187
            },
            {
                "lat": 49.0097921701,
                "lng": -125.2982337936
            },
            {
                "lat": 49.0101588079,
                "lng": -125.2943731386
            },
            {
                "lat": 49.0096698824,
                "lng": -125.2881243001
            },
            {
                "lat": 49.00756042,
                "lng": -125.2852008434
            },
            {
                "lat": 49.0009120756,
                "lng": -125.2843095851
            },
            {
                "lat": 48.9921528493,
                "lng": -125.2830961355
            },
            {
                "lat": 48.981609123,
                "lng": -125.2822874925
            },
            {
                "lat": 48.9745521435,
                "lng": -125.2836612065
            },
            {
                "lat": 48.9710654206,
                "lng": -125.2859330534
            },
            {
                "lat": 48.9698104822,
                "lng": -125.2802588991
            },
            {
                "lat": 48.9696579007,
                "lng": -125.2745287149
            },
            {
                "lat": 48.9664915414,
                "lng": -125.2662353891
            },
            {
                "lat": 48.9615169216,
                "lng": -125.2552339703
            },
            {
                "lat": 48.9585042959,
                "lng": -125.2476960757
            },
            {
                "lat": 48.9548835909,
                "lng": -125.2436207755
            },
            {
                "lat": 48.9505123329,
                "lng": -125.2401582641
            },
            {
                "lat": 48.9551468174,
                "lng": -125.2306668421
            },
            {
                "lat": 48.9625658306,
                "lng": -125.2187965885
            },
            {
                "lat": 48.9709700388,
                "lng": -125.2089076817
            },
            {
                "lat": 48.982837212,
                "lng": -125.2004993887
            },
            {
                "lat": 48.9966849504,
                "lng": -125.200004696
            },
            {
                "lat": 49.0115209502,
                "lng": -125.1970368955
            },
            {
                "lat": 49.0159680226,
                "lng": -125.1915958956
            },
            {
                "lat": 49.0243754006,
                "lng": -125.1772606866
            },
            {
                "lat": 49.0273440035,
                "lng": -125.1693499634
            },
            {
                "lat": 49.03426741,
                "lng": -125.1663820381
            },
            {
                "lat": 49.0426714101,
                "lng": -125.1703339841
            },
            {
                "lat": 49.0515748553,
                "lng": -125.1752777566
            },
            {
                "lat": 49.05800208,
                "lng": -125.1747899619
            },
            {
                "lat": 49.0634420788,
                "lng": -125.1668787617
            },
            {
                "lat": 49.0693735431,
                "lng": -125.1703338481
            },
            {
                "lat": 49.0758020142,
                "lng": -125.1767649253
            },
            {
                "lat": 49.0817380613,
                "lng": -125.190612448
            },
            {
                "lat": 49.0866809847,
                "lng": -125.2019812814
            },
            {
                "lat": 49.0906366338,
                "lng": -125.2074199731
            },
            {
                "lat": 49.0975610582,
                "lng": -125.2113800399
            },
            {
                "lat": 49.1034958976,
                "lng": -125.2093968492
            },
            {
                "lat": 49.1064610206,
                "lng": -125.2034688363
            },
            {
                "lat": 49.1030002089,
                "lng": -125.1846769923
            },
            {
                "lat": 49.0911328341,
                "lng": -125.1609421299
            },
            {
                "lat": 49.0807494858,
                "lng": -125.1465992019
            },
            {
                "lat": 49.0748136509,
                "lng": -125.14264563
            },
            {
                "lat": 49.0634420038,
                "lng": -125.1367107329
            },
            {
                "lat": 49.0486073661,
                "lng": -125.1307749335
            },
            {
                "lat": 49.0387155983,
                "lng": -125.1297843603
            },
            {
                "lat": 49.0263522806,
                "lng": -125.131768205
            },
            {
                "lat": 49.0204195951,
                "lng": -125.1391827721
            },
            {
                "lat": 49.014980814,
                "lng": -125.1381907234
            },
            {
                "lat": 49.0065733157,
                "lng": -125.1327503577
            },
            {
                "lat": 49.0011331263,
                "lng": -125.125335208
            },
            {
                "lat": 48.9939502858,
                "lng": -125.120247589
            },
            {
                "lat": 48.987228563,
                "lng": -125.1151579107
            },
            {
                "lat": 48.9827081906,
                "lng": -125.1045305426
            },
            {
                "lat": 48.9840386242,
                "lng": -125.0912485636
            },
            {
                "lat": 48.9880260995,
                "lng": -125.081947615
            },
            {
                "lat": 48.9951971242,
                "lng": -125.0702598609
            },
            {
                "lat": 48.9986498936,
                "lng": -125.0593634757
            },
            {
                "lat": 49.0007745901,
                "lng": -125.0521934785
            },
            {
                "lat": 49.0015719907,
                "lng": -125.0468825609
            },
            {
                "lat": 48.9991833444,
                "lng": -125.0365215337
            },
            {
                "lat": 48.9970552596,
                "lng": -125.026954599
            },
            {
                "lat": 48.9949724141,
                "lng": -125.0196600859
            },
            {
                "lat": 48.9925382336,
                "lng": -125.015526346
            },
            {
                "lat": 48.9864310911,
                "lng": -125.0088882107
            },
            {
                "lat": 48.9811168234,
                "lng": -125.0080867606
            },
            {
                "lat": 48.9755357914,
                "lng": -125.0094211912
            },
            {
                "lat": 48.9760666417,
                "lng": -125.0134040012
            },
            {
                "lat": 48.9779280705,
                "lng": -125.0173862007
            },
            {
                "lat": 48.9799153499,
                "lng": -125.0207452596
            },
            {
                "lat": 48.9803308636,
                "lng": -125.0211339452
            },
            {
                "lat": 48.9806476136,
                "lng": -125.0214616003
            },
            {
                "lat": 48.9792868008,
                "lng": -125.0218346192
            },
            {
                "lat": 48.9780316585,
                "lng": -125.021576084
            },
            {
                "lat": 48.9773180362,
                "lng": -125.0190499078
            },
            {
                "lat": 48.9758874128,
                "lng": -125.0167469591
            },
            {
                "lat": 48.973251644,
                "lng": -125.016144179
            },
            {
                "lat": 48.9712213793,
                "lng": -125.0176227309
            },
            {
                "lat": 48.9690782233,
                "lng": -125.0197139673
            },
            {
                "lat": 48.9675450658,
                "lng": -125.0220173141
            },
            {
                "lat": 48.9648512639,
                "lng": -125.0251460876
            },
            {
                "lat": 48.9624133547,
                "lng": -125.0264364057
            },
            {
                "lat": 48.959838391,
                "lng": -125.0271378705
            },
            {
                "lat": 48.9600607507,
                "lng": -125.026833194
            },
            {
                "lat": 48.9629476815,
                "lng": -125.0248192361
            },
            {
                "lat": 48.9643937823,
                "lng": -125.0245060342
            },
            {
                "lat": 48.9655262498,
                "lng": -125.0230638192
            },
            {
                "lat": 48.9657137279,
                "lng": -125.0218050006
            },
            {
                "lat": 48.964710194,
                "lng": -125.019919827
            },
            {
                "lat": 48.9647709628,
                "lng": -125.0169672093
            },
            {
                "lat": 48.9654005276,
                "lng": -125.0135729169
            },
            {
                "lat": 48.9668464551,
                "lng": -125.0096122059
            },
            {
                "lat": 48.9678500822,
                "lng": -125.0070417971
            },
            {
                "lat": 48.9696731416,
                "lng": -125.0038996361
            },
            {
                "lat": 48.9716836913,
                "lng": -125.001259678
            },
            {
                "lat": 48.9741328914,
                "lng": -125.000129549
            },
            {
                "lat": 48.9767067643,
                "lng": -124.99993803
            },
            {
                "lat": 48.9792213723,
                "lng": -125.0000001766
            },
            {
                "lat": 48.9815439447,
                "lng": -125.0011989662
            },
            {
                "lat": 48.9852528294,
                "lng": -125.0030215679
            },
            {
                "lat": 48.9880178922,
                "lng": -125.0050897386
            },
            {
                "lat": 48.9904672224,
                "lng": -125.0072245726
            },
            {
                "lat": 48.993732773,
                "lng": -125.0110620173
            },
            {
                "lat": 48.9959945655,
                "lng": -125.0131979733
            },
            {
                "lat": 48.997505777,
                "lng": -125.0165852427
            },
            {
                "lat": 48.9995767629,
                "lng": -125.0223702243
            },
            {
                "lat": 49.001903796,
                "lng": -125.0294642691
            },
            {
                "lat": 49.0051041281,
                "lng": -125.038261102
            },
            {
                "lat": 49.0068014369,
                "lng": -125.0423507995
            },
            {
                "lat": 49.0082472974,
                "lng": -125.0456165329
            },
            {
                "lat": 49.0103182041,
                "lng": -125.0498271608
            },
            {
                "lat": 49.0117642247,
                "lng": -125.0520864117
            },
            {
                "lat": 49.0140913009,
                "lng": -125.0537186317
            },
            {
                "lat": 49.0160367414,
                "lng": -125.0537798972
            },
            {
                "lat": 49.0184860661,
                "lng": -125.0523988277
            },
            {
                "lat": 49.0206223826,
                "lng": -125.049385235
            },
            {
                "lat": 49.0226979496,
                "lng": -125.0442967718
            },
            {
                "lat": 49.0238911104,
                "lng": -125.0409024343
            },
            {
                "lat": 49.0253371619,
                "lng": -125.0386431143
            },
            {
                "lat": 49.0270309367,
                "lng": -125.0342410459
            },
            {
                "lat": 49.0290415133,
                "lng": -125.0315394049
            },
            {
                "lat": 49.0301089446,
                "lng": -125.0299071952
            },
            {
                "lat": 49.030673532,
                "lng": -125.0279626837
            },
            {
                "lat": 49.0306734957,
                "lng": -125.0253828057
            },
            {
                "lat": 49.0307387916,
                "lng": -125.0231857236
            },
            {
                "lat": 49.0300483055,
                "lng": -125.0212401092
            },
            {
                "lat": 49.0293579794,
                "lng": -125.0204248156
            },
            {
                "lat": 49.0280995381,
                "lng": -125.0201029419
            },
            {
                "lat": 49.025901817,
                "lng": -125.0205468722
            },
            {
                "lat": 49.0239528762,
                "lng": -125.0216137744
            },
            {
                "lat": 49.0199318543,
                "lng": -125.0226830071
            },
            {
                "lat": 49.0174219791,
                "lng": -125.0228721124
            },
            {
                "lat": 49.0167269465,
                "lng": -125.0211712379
            },
            {
                "lat": 49.0150947748,
                "lng": -125.0171500332
            },
            {
                "lat": 49.0128968682,
                "lng": -125.0129464967
            },
            {
                "lat": 49.0106968383,
                "lng": -125.0091103322
            },
            {
                "lat": 49.0098189303,
                "lng": -125.0071629147
            },
            {
                "lat": 49.0064243733,
                "lng": -125.0013185997
            },
            {
                "lat": 49.0041006938,
                "lng": -124.9967950536
            },
            {
                "lat": 49.0025289585,
                "lng": -124.9922710822
            },
            {
                "lat": 48.9992635136,
                "lng": -124.9863047966
            },
            {
                "lat": 48.9960599092,
                "lng": -124.9803396936
            },
            {
                "lat": 48.993293737,
                "lng": -124.975752813
            },
            {
                "lat": 48.9907186592,
                "lng": -124.9729228085
            },
            {
                "lat": 48.9885219077,
                "lng": -124.9703508886
            },
            {
                "lat": 48.9875149965,
                "lng": -124.9681469637
            },
            {
                "lat": 48.9867594492,
                "lng": -124.9655759355
            },
            {
                "lat": 48.9870760068,
                "lng": -124.9631261822
            },
            {
                "lat": 48.9883962632,
                "lng": -124.9577182477
            },
            {
                "lat": 48.9895893266,
                "lng": -124.9521951459
            },
            {
                "lat": 48.9898374149,
                "lng": -124.9476705666
            },
            {
                "lat": 48.9888955293,
                "lng": -124.943771632
            },
            {
                "lat": 48.9872623003,
                "lng": -124.9367361465
            },
            {
                "lat": 48.9861331861,
                "lng": -124.9294507785
            },
            {
                "lat": 48.9854392166,
                "lng": -124.9229813086
            },
            {
                "lat": 48.9855649733,
                "lng": -124.9204026719
            },
            {
                "lat": 48.9859432766,
                "lng": -124.9187085971
            },
            {
                "lat": 48.9902377595,
                "lng": -124.9161747711
            },
            {
                "lat": 48.992313572,
                "lng": -124.912025289
            },
            {
                "lat": 48.9967571462,
                "lng": -124.906105328
            },
            {
                "lat": 49.0014951834,
                "lng": -124.9019621651
            },
            {
                "lat": 49.0077130677,
                "lng": -124.8963308997
            },
            {
                "lat": 49.0145267767,
                "lng": -124.892189413
            },
            {
                "lat": 49.0195582228,
                "lng": -124.8838966782
            },
            {
                "lat": 49.0219307445,
                "lng": -124.8770831182
            },
            {
                "lat": 49.0266688392,
                "lng": -124.872046942
            },
            {
                "lat": 49.0334813908,
                "lng": -124.8708645473
            },
            {
                "lat": 49.0441439365,
                "lng": -124.869385269
            },
            {
                "lat": 49.0497702006,
                "lng": -124.8676073479
            },
            {
                "lat": 49.0536190099,
                "lng": -124.8702693741
            },
            {
                "lat": 49.0542103697,
                "lng": -124.8761897343
            },
            {
                "lat": 49.0550998029,
                "lng": -124.8883351263
            },
            {
                "lat": 49.0592465163,
                "lng": -124.9010687469
            },
            {
                "lat": 49.0636901677,
                "lng": -124.9037405727
            },
            {
                "lat": 49.0690197063,
                "lng": -124.9016637488
            },
            {
                "lat": 49.0710940514,
                "lng": -124.8972246523
            },
            {
                "lat": 49.0707972989,
                "lng": -124.8909975516
            },
            {
                "lat": 49.0710940941,
                "lng": -124.8868565956
            },
            {
                "lat": 49.0722769473,
                "lng": -124.8827063094
            },
            {
                "lat": 49.073166263,
                "lng": -124.8770828697
            },
            {
                "lat": 49.0752373025,
                "lng": -124.8676073711
            },
            {
                "lat": 49.077313059,
                "lng": -124.8616791244
            },
            {
                "lat": 49.0804398675,
                "lng": -124.8553997738
            },
            {
                "lat": 49.0816798231,
                "lng": -124.8529967734
            },
            {
                "lat": 49.0842281608,
                "lng": -124.8475271463
            },
            {
                "lat": 49.0888217681,
                "lng": -124.8427959238
            },
            {
                "lat": 49.0926820973,
                "lng": -124.8392258635
            },
            {
                "lat": 49.0947952222,
                "lng": -124.838638046
            },
            {
                "lat": 49.0983620612,
                "lng": -124.8400265257
            },
            {
                "lat": 49.1022993522,
                "lng": -124.84039192
            },
            {
                "lat": 49.1052854489,
                "lng": -124.839226475
            },
            {
                "lat": 49.1190340676,
                "lng": -124.8367385365
            },
            {
                "lat": 49.1374055663,
                "lng": -124.8278200447
            },
            {
                "lat": 49.152630132,
                "lng": -124.8299171235
            },
            {
                "lat": 49.184124036,
                "lng": -124.8519602198
            },
            {
                "lat": 49.2082675027,
                "lng": -124.8551105483
            },
            {
                "lat": 49.2350384004,
                "lng": -124.8635107956
            },
            {
                "lat": 49.27230864,
                "lng": -124.8813559046
            },
            {
                "lat": 49.2996022126,
                "lng": -124.8923806383
            },
            {
                "lat": 49.308002933,
                "lng": -124.869285255
            },
            {
                "lat": 49.3064266382,
                "lng": -124.8488171448
            },
            {
                "lat": 49.2959292765,
                "lng": -124.8162682781
            },
            {
                "lat": 49.2649570094,
                "lng": -124.7842481758
            },
            {
                "lat": 49.2135166159,
                "lng": -124.7826765902
            },
            {
                "lat": 49.1767737486,
                "lng": -124.7800517111
            },
            {
                "lat": 49.1526302016,
                "lng": -124.7753228559
            },
            {
                "lat": 49.1305811526,
                "lng": -124.7810979737
            },
            {
                "lat": 49.1132620198,
                "lng": -124.7978969331
            },
            {
                "lat": 49.1027836706,
                "lng": -124.8080740793
            },
            {
                "lat": 49.1007077355,
                "lng": -124.8065949926
            },
            {
                "lat": 49.0965611596,
                "lng": -124.8080742518
            },
            {
                "lat": 49.0918231068,
                "lng": -124.8089682466
            },
            {
                "lat": 49.0858998705,
                "lng": -124.8113334359
            },
            {
                "lat": 49.0782013582,
                "lng": -124.8169633697
            },
            {
                "lat": 49.0693164569,
                "lng": -124.8231802039
            },
            {
                "lat": 49.0610241589,
                "lng": -124.8279189422
            },
            {
                "lat": 49.0506597294,
                "lng": -124.8311773031
            },
            {
                "lat": 49.0423663952,
                "lng": -124.8320614293
            },
            {
                "lat": 49.0373302571,
                "lng": -124.8335417212
            },
            {
                "lat": 49.0314069273,
                "lng": -124.838873752
            },
            {
                "lat": 49.0165978072,
                "lng": -124.84509187
            },
            {
                "lat": 49.0056385787,
                "lng": -124.8507232334
            },
            {
                "lat": 48.9934963653,
                "lng": -124.8560567526
            },
            {
                "lat": 48.9887583684,
                "lng": -124.8640524698
            },
            {
                "lat": 48.9831318523,
                "lng": -124.8759007466
            },
            {
                "lat": 48.9798734537,
                "lng": -124.8868557209
            },
            {
                "lat": 48.9742471226,
                "lng": -124.8915943076
            },
            {
                "lat": 48.9712832415,
                "lng": -124.894256635
            },
            {
                "lat": 48.9680294544,
                "lng": -124.9016640904
            },
            {
                "lat": 48.9663202253,
                "lng": -124.9097215947
            },
            {
                "lat": 48.967545038,
                "lng": -124.9260179301
            },
            {
                "lat": 48.9642865097,
                "lng": -124.9398641437
            },
            {
                "lat": 48.9577672116,
                "lng": -124.9573818313
            },
            {
                "lat": 48.9516600255,
                "lng": -124.9687884233
            },
            {
                "lat": 48.9463655808,
                "lng": -124.9744869097
            },
            {
                "lat": 48.9369965791,
                "lng": -124.9753041099
            },
            {
                "lat": 48.9345517503,
                "lng": -124.9785610227
            },
            {
                "lat": 48.9317019127,
                "lng": -124.987527322
            },
            {
                "lat": 48.9341431442,
                "lng": -124.9981152335
            },
            {
                "lat": 48.9394378099,
                "lng": -125.0070725809
            },
            {
                "lat": 48.9213713271,
                "lng": -124.9988333649
            },
            {
                "lat": 48.8945316959,
                "lng": -124.9837653863
            },
            {
                "lat": 48.8785245565,
                "lng": -124.9884712609
            },
            {
                "lat": 48.8733441108,
                "lng": -125.0139006091
            },
            {
                "lat": 48.8634570597,
                "lng": -125.0445025081
            },
            {
                "lat": 48.8540378719,
                "lng": -125.0736924021
            },
            {
                "lat": 48.8502727793,
                "lng": -125.0897067922
            },
            {
                "lat": 48.8401797161,
                "lng": -125.1039875359
            },
            {
                "lat": 48.8371238504,
                "lng": -125.1020973844
            },
            {
                "lat": 48.8309478621,
                "lng": -125.106437026
            },
            {
                "lat": 48.8270993572,
                "lng": -125.1104746799
            },
            {
                "lat": 48.8252027416,
                "lng": -125.1219644991
            },
            {
                "lat": 48.8254473358,
                "lng": -125.1282580204
            },
            {
                "lat": 48.8179628894,
                "lng": -125.1359774638
            },
            {
                "lat": 48.8126067803,
                "lng": -125.1466822392
            },
            {
                "lat": 48.8085066047,
                "lng": -125.1595302531
            },
            {
                "lat": 48.8093983168,
                "lng": -125.1704101812
            },
            {
                "lat": 48.8076135776,
                "lng": -125.176125681
            },
            {
                "lat": 48.8015437181,
                "lng": -125.1775507203
            },
            {
                "lat": 48.7974434874,
                "lng": -125.1818323828
            },
            {
                "lat": 48.7942318488,
                "lng": -125.1884302541
            },
            {
                "lat": 48.7942316672,
                "lng": -125.1968220446
            },
            {
                "lat": 48.7947650035,
                "lng": -125.1998519961
            },
            {
                "lat": 48.7883412153,
                "lng": -125.201279593
            },
            {
                "lat": 48.7900618145,
                "lng": -125.1916957335
            },
            {
                "lat": 48.7931561591,
                "lng": -125.1736140558
            },
            {
                "lat": 48.7952957429,
                "lng": -125.1524349376
            },
            {
                "lat": 48.8036230763,
                "lng": -125.132690489
            },
            {
                "lat": 48.807670636,
                "lng": -125.1257863685
            },
            {
                "lat": 48.815757956,
                "lng": -125.1181712121
            },
            {
                "lat": 48.806953625,
                "lng": -125.1072238056
            },
            {
                "lat": 48.7826843495,
                "lng": -125.0986638744
            },
            {
                "lat": 48.7629356745,
                "lng": -125.1107940402
            },
            {
                "lat": 48.746753994,
                "lng": -125.1100855642
            },
            {
                "lat": 48.7377133127,
                "lng": -125.1036599663
            },
            {
                "lat": 48.7291451746,
                "lng": -125.0950936591
            },
            {
                "lat": 48.7308120057,
                "lng": -125.0798644884
            },
            {
                "lat": 48.7293848627,
                "lng": -125.0532142982
            },
            {
                "lat": 48.7262917264,
                "lng": -125.0329885039
            },
            {
                "lat": 48.7236746458,
                "lng": -125.0044318035
            },
            {
                "lat": 48.7198679088,
                "lng": -124.9811179208
            },
            {
                "lat": 48.7182009369,
                "lng": -124.9563677816
            },
            {
                "lat": 48.7148703291,
                "lng": -124.9420922977
            },
            {
                "lat": 48.7077326749,
                "lng": -124.9268644648
            },
            {
                "lat": 48.6977386279,
                "lng": -124.9080657153
            },
            {
                "lat": 48.68798063,
                "lng": -124.879509462
            },
            {
                "lat": 48.6829827844,
                "lng": -124.8628530995
            },
            {
                "lat": 48.6840900742,
                "lng": -124.8580094972
            },
            {
                "lat": 48.6888920395,
                "lng": -124.8563317527
            },
            {
                "lat": 48.69581177,
                "lng": -124.8504028231
            },
            {
                "lat": 48.7017397789,
                "lng": -124.825691503
            },
            {
                "lat": 48.7313918889,
                "lng": -124.7930763521
            },
            {
                "lat": 48.7694397922,
                "lng": -124.7515704864
            },
            {
                "lat": 48.808975815,
                "lng": -124.7070922918
            },
            {
                "lat": 48.8405987789,
                "lng": -124.6739883507
            },
            {
                "lat": 48.852955209,
                "lng": -124.6517493368
            },
            {
                "lat": 48.8450469922,
                "lng": -124.64384543
            },
            {
                "lat": 48.7946438646,
                "lng": -124.6542203526
            },
            {
                "lat": 48.7437436305,
                "lng": -124.7248847682
            },
            {
                "lat": 48.7274362388,
                "lng": -124.7446520228
            },
            {
                "lat": 48.6977887286,
                "lng": -124.7802282669
            },
            {
                "lat": 48.6864322692,
                "lng": -124.7777328528
            },
            {
                "lat": 48.6702388236,
                "lng": -124.7611009963
            },
            {
                "lat": 48.6063421404,
                "lng": -124.7142713155
            },
            {
                "lat": 48.5552403178,
                "lng": -124.4945304343
            },
            {
                "lat": 48.6676335951,
                "lng": -124.4658954428
            },
            {
                "lat": 48.6001160402,
                "lng": -124.2367862535
            },
            {
                "lat": 48.5838808607,
                "lng": -124.235678311
            },
            {
                "lat": 48.5731847839,
                "lng": -124.2419520832
            },
            {
                "lat": 48.5433007579,
                "lng": -124.3039329384
            },
            {
                "lat": 48.5363007881,
                "lng": -124.329597809
            },
            {
                "lat": 48.5167382739,
                "lng": -124.27553508
            },
            {
                "lat": 48.4235758154,
                "lng": -123.9207230679
            },
            {
                "lat": 48.4106945173,
                "lng": -123.7119968406
            },
            {
                "lat": 48.4109914168,
                "lng": -123.6940223821
            },
            {
                "lat": 48.4122310084,
                "lng": -123.6183232864
            },
            {
                "lat": 48.3702541737,
                "lng": -123.6162722925
            },
            {
                "lat": 48.3497813417,
                "lng": -123.6331639254
            },
            {
                "lat": 48.3636439581,
                "lng": -123.5753632164
            },
            {
                "lat": 48.41299004,
                "lng": -123.5273355654
            },
            {
                "lat": 48.4512255839,
                "lng": -123.4714817253
            },
            {
                "lat": 48.4606053043,
                "lng": -123.4640197135
            },
            {
                "lat": 48.4615667392,
                "lng": -123.4503394172
            },
            {
                "lat": 48.4568217974,
                "lng": -123.4388732794
            },
            {
                "lat": 48.4567494806,
                "lng": -123.4362327134
            },
            {
                "lat": 48.4682885273,
                "lng": -123.4286350627
            },
            {
                "lat": 48.4677728575,
                "lng": -123.4092795964
            },
            {
                "lat": 48.4469869601,
                "lng": -123.3693616215
            },
            {
                "lat": 48.4322512043,
                "lng": -123.3646233928
            },
            {
                "lat": 48.4170461319,
                "lng": -123.3654620703
            },
            {
                "lat": 48.4170263491,
                "lng": -123.3651883123
            },
            {
                "lat": 48.417294135,
                "lng": -123.3493426629
            },
            {
                "lat": 48.4153055903,
                "lng": -123.327445553
            },
            {
                "lat": 48.4116932416,
                "lng": -123.3223569438
            },
            {
                "lat": 48.455337482,
                "lng": -123.3137513721
            },
            {
                "lat": 48.4620626297,
                "lng": -123.3298041758
            },
            {
                "lat": 48.50954853,
                "lng": -123.3858187703
            },
            {
                "lat": 48.6247868653,
                "lng": -123.4295664866
            },
            {
                "lat": 48.6706696814,
                "lng": -123.4343707031
            },
            {
                "lat": 48.6799551029,
                "lng": -123.4286585117
            },
            {
                "lat": 48.686889752,
                "lng": -123.4702380994
            },
            {
                "lat": 48.6841237151,
                "lng": -123.4683689957
            },
            {
                "lat": 48.6683693152,
                "lng": -123.4683151524
            },
            {
                "lat": 48.6710851359,
                "lng": -123.4581142914
            },
            {
                "lat": 48.6681027886,
                "lng": -123.4451903985
            },
            {
                "lat": 48.6525950319,
                "lng": -123.4410180598
            },
            {
                "lat": 48.5985189485,
                "lng": -123.4471818421
            },
            {
                "lat": 48.5786062155,
                "lng": -123.4591836926
            },
            {
                "lat": 48.553043499,
                "lng": -123.4600976437
            },
            {
                "lat": 48.5577014188,
                "lng": -123.4844129959
            },
            {
                "lat": 48.5370330864,
                "lng": -123.5219961514
            },
            {
                "lat": 48.5245591755,
                "lng": -123.5227289098
            },
            {
                "lat": 48.5136264441,
                "lng": -123.531280819
            },
            {
                "lat": 48.4853749076,
                "lng": -123.5378416976
            },
            {
                "lat": 48.4806786881,
                "lng": -123.5494306658
            },
            {
                "lat": 48.4858127474,
                "lng": -123.557647573
            },
            {
                "lat": 48.5026859574,
                "lng": -123.5583811369
            },
            {
                "lat": 48.5144962637,
                "lng": -123.5521473256
            },
            {
                "lat": 48.5154497434,
                "lng": -123.5473787888
            },
            {
                "lat": 48.5344997127,
                "lng": -123.5610433355
            },
            {
                "lat": 48.548484674,
                "lng": -123.5638809642
            },
            {
                "lat": 48.549034322,
                "lng": -123.5637206915
            },
            {
                "lat": 48.5548900426,
                "lng": -123.562034927
            },
            {
                "lat": 48.5593417996,
                "lng": -123.5607528492
            },
            {
                "lat": 48.5638421871,
                "lng": -123.5454941246
            },
            {
                "lat": 48.5649180261,
                "lng": -123.5303356521
            },
            {
                "lat": 48.5642892042,
                "lng": -123.5271752656
            },
            {
                "lat": 48.5690076135,
                "lng": -123.5267873679
            },
            {
                "lat": 48.6055411469,
                "lng": -123.5315847676
            },
            {
                "lat": 48.6387520201,
                "lng": -123.5551995353
            },
            {
                "lat": 48.6556741972,
                "lng": -123.5647575104
            },
            {
                "lat": 48.6633949316,
                "lng": -123.5608374914
            },
            {
                "lat": 48.6640128774,
                "lng": -123.5422203799
            },
            {
                "lat": 48.6935081534,
                "lng": -123.5364077273
            },
            {
                "lat": 48.695076055,
                "lng": -123.5411067905
            },
            {
                "lat": 48.6988911574,
                "lng": -123.5523311571
            },
            {
                "lat": 48.7052763371,
                "lng": -123.5617225294
            },
            {
                "lat": 48.7142986757,
                "lng": -123.5620891378
            },
            {
                "lat": 48.7181438816,
                "lng": -123.5686873346
            },
            {
                "lat": 48.7391055333,
                "lng": -123.651161674
            },
            {
                "lat": 48.7552184911,
                "lng": -123.6596460653
            },
            {
                "lat": 48.778438813,
                "lng": -123.6475225364
            },
            {
                "lat": 48.7751465057,
                "lng": -123.5929405111
            },
            {
                "lat": 48.7791139251,
                "lng": -123.5949474516
            },
            {
                "lat": 48.78985576,
                "lng": -123.6118313117
            },
            {
                "lat": 48.8318139498,
                "lng": -123.6343381883
            },
            {
                "lat": 48.8436511478,
                "lng": -123.6370088005
            },
            {
                "lat": 48.8593370057,
                "lng": -123.6450197707
            },
            {
                "lat": 48.874355811,
                "lng": -123.6670467578
            },
            {
                "lat": 48.8873709758,
                "lng": -123.6920781173
            },
            {
                "lat": 48.9094014808,
                "lng": -123.708098188
            },
            {
                "lat": 48.911857695,
                "lng": -123.7108606595
            },
            {
                "lat": 48.9199754045,
                "lng": -123.7205429829
            },
            {
                "lat": 48.9285166735,
                "lng": -123.7334975173
            },
            {
                "lat": 48.9397659435,
                "lng": -123.7485969251
            },
            {
                "lat": 48.9510117789,
                "lng": -123.75542329
            },
            {
                "lat": 48.9554937591,
                "lng": -123.7584686332
            },
            {
                "lat": 48.9566951564,
                "lng": -123.7633668607
            },
            {
                "lat": 48.9605869449,
                "lng": -123.7789242431
            },
            {
                "lat": 48.9747546211,
                "lng": -123.8022526333
            },
            {
                "lat": 48.9944764658,
                "lng": -123.8261486531
            },
            {
                "lat": 49.0133627389,
                "lng": -123.8600315366
            },
            {
                "lat": 49.0230868716,
                "lng": -123.8675299464
            },
            {
                "lat": 49.0330846704,
                "lng": -123.8594821051
            },
            {
                "lat": 49.0369714936,
                "lng": -123.8441993582
            },
            {
                "lat": 49.0116118357,
                "lng": -123.7985074895
            },
            {
                "lat": 49.024494238,
                "lng": -123.7952576167
            },
            {
                "lat": 49.0324557578,
                "lng": -123.7904504373
            },
            {
                "lat": 49.0404163091,
                "lng": -123.7667309674
            },
            {
                "lat": 49.0533756767,
                "lng": -123.7765124079
            },
            {
                "lat": 49.0727727524,
                "lng": -123.7969811113
            },
            {
                "lat": 49.0904888112,
                "lng": -123.8116843942
            },
            {
                "lat": 49.097140794,
                "lng": -123.8120338409
            },
            {
                "lat": 49.1011922837,
                "lng": -123.8082734085
            },
            {
                "lat": 49.1022141408,
                "lng": -123.8066258359
            },
            {
                "lat": 49.1086310388,
                "lng": -123.8080670123
            },
            {
                "lat": 49.1201550733,
                "lng": -123.8108136167
            },
            {
                "lat": 49.1281433914,
                "lng": -123.8173597234
            },
            {
                "lat": 49.1322016614,
                "lng": -123.8233865575
            },
            {
                "lat": 49.1314158133,
                "lng": -123.8309789155
            },
            {
                "lat": 49.1353413138,
                "lng": -123.8489144126
            },
            {
                "lat": 49.1371761655,
                "lng": -123.8575587286
            },
            {
                "lat": 49.1371457878,
                "lng": -123.8614650254
            },
            {
                "lat": 49.1380922763,
                "lng": -123.8656774519
            },
            {
                "lat": 49.1413646111,
                "lng": -123.8735347182
            },
            {
                "lat": 49.1452937176,
                "lng": -123.8765484244
            },
            {
                "lat": 49.1455534431,
                "lng": -123.8799592977
            },
            {
                "lat": 49.1473124333,
                "lng": -123.8840262925
            },
            {
                "lat": 49.1450458175,
                "lng": -123.8837954925
            },
            {
                "lat": 49.1401820077,
                "lng": -123.8771959944
            },
            {
                "lat": 49.1332623447,
                "lng": -123.8692694331
            },
            {
                "lat": 49.12854733,
                "lng": -123.8754655958
            },
            {
                "lat": 49.1248778568,
                "lng": -123.8797525527
            },
            {
                "lat": 49.1227988719,
                "lng": -123.8790514482
            },
            {
                "lat": 49.1202808201,
                "lng": -123.8779066556
            },
            {
                "lat": 49.1176300121,
                "lng": -123.877837536
            },
            {
                "lat": 49.1159514056,
                "lng": -123.878844901
            },
            {
                "lat": 49.1158604212,
                "lng": -123.880211103
            },
            {
                "lat": 49.1158605985,
                "lng": -123.8808202558
            },
            {
                "lat": 49.116218986,
                "lng": -123.8836136325
            },
            {
                "lat": 49.1183702858,
                "lng": -123.8849874851
            },
            {
                "lat": 49.1207499472,
                "lng": -123.8869705868
            },
            {
                "lat": 49.1222957818,
                "lng": -123.8904942712
            },
            {
                "lat": 49.1227068515,
                "lng": -123.8937067093
            },
            {
                "lat": 49.1228140968,
                "lng": -123.9027259053
            },
            {
                "lat": 49.1280247926,
                "lng": -123.9199215653
            },
            {
                "lat": 49.1474764568,
                "lng": -123.9395441987
            },
            {
                "lat": 49.1698794876,
                "lng": -123.9515297757
            },
            {
                "lat": 49.1839449048,
                "lng": -123.9511790285
            },
            {
                "lat": 49.191238151,
                "lng": -123.9508369815
            },
            {
                "lat": 49.190460706,
                "lng": -123.953164348
            },
            {
                "lat": 49.1902243839,
                "lng": -123.9556426413
            },
            {
                "lat": 49.191581553,
                "lng": -123.9611975449
            },
            {
                "lat": 49.1959531284,
                "lng": -123.9671025208
            },
            {
                "lat": 49.200794638,
                "lng": -123.9714727521
            },
            {
                "lat": 49.2055783264,
                "lng": -123.9723593898
            },
            {
                "lat": 49.2088238497,
                "lng": -123.9708787394
            },
            {
                "lat": 49.2138436407,
                "lng": -123.9598381793
            },
            {
                "lat": 49.2143176624,
                "lng": -123.9443661968
            },
            {
                "lat": 49.2192081901,
                "lng": -123.9472039798
            },
            {
                "lat": 49.221912643,
                "lng": -123.9477841667
            },
            {
                "lat": 49.2213931708,
                "lng": -123.9489064107
            },
            {
                "lat": 49.2236516959,
                "lng": -123.9537425721
            },
            {
                "lat": 49.2253037908,
                "lng": -123.9601048475
            },
            {
                "lat": 49.2276797678,
                "lng": -123.9647069563
            },
            {
                "lat": 49.2305178903,
                "lng": -123.9674299467
            },
            {
                "lat": 49.2314724198,
                "lng": -123.9705580816
            },
            {
                "lat": 49.2311897025,
                "lng": -123.9795843762
            },
            {
                "lat": 49.2320371477,
                "lng": -124.0018759253
            },
            {
                "lat": 49.2357030365,
                "lng": -124.0286781288
            },
            {
                "lat": 49.2438846619,
                "lng": -124.0684581213
            },
            {
                "lat": 49.2492444423,
                "lng": -124.0851058814
            },
            {
                "lat": 49.248837139,
                "lng": -124.0957800224
            },
            {
                "lat": 49.2459905937,
                "lng": -124.1158906069
            },
            {
                "lat": 49.2438579382,
                "lng": -124.1380304271
            },
            {
                "lat": 49.2469056396,
                "lng": -124.1541823055
            },
            {
                "lat": 49.249649703,
                "lng": -124.1686014292
            },
            {
                "lat": 49.2532037028,
                "lng": -124.1790608705
            },
            {
                "lat": 49.2586858113,
                "lng": -124.1910478554
            },
            {
                "lat": 49.2627522314,
                "lng": -124.1973496097
            },
            {
                "lat": 49.2663038597,
                "lng": -124.1984629351
            },
            {
                "lat": 49.2710805719,
                "lng": -124.1968389239
            },
            {
                "lat": 49.272094462,
                "lng": -124.1917559282
            },
            {
                "lat": 49.2740245291,
                "lng": -124.1808926361
            },
            {
                "lat": 49.2757487427,
                "lng": -124.1729722873
            },
            {
                "lat": 49.2753447329,
                "lng": -124.1663664587
            },
            {
                "lat": 49.2713854527,
                "lng": -124.1512376926
            },
            {
                "lat": 49.2694553734,
                "lng": -124.1419899851
            },
            {
                "lat": 49.2685390899,
                "lng": -124.1374210977
            },
            {
                "lat": 49.2698592009,
                "lng": -124.1200558519
            },
            {
                "lat": 49.2699548969,
                "lng": -124.1190408716
            },
            {
                "lat": 49.2731933441,
                "lng": -124.1219928818
            },
            {
                "lat": 49.2775765029,
                "lng": -124.1270056937
            },
            {
                "lat": 49.2836301394,
                "lng": -124.1374367116
            },
            {
                "lat": 49.2888488832,
                "lng": -124.1478728392
            },
            {
                "lat": 49.2880132038,
                "lng": -124.15705937
            },
            {
                "lat": 49.2915615727,
                "lng": -124.1670751563
            },
            {
                "lat": 49.3009527193,
                "lng": -124.1770929025
            },
            {
                "lat": 49.3065486254,
                "lng": -124.1826933136
            },
            {
                "lat": 49.3005789267,
                "lng": -124.1841812438
            },
            {
                "lat": 49.2894893394,
                "lng": -124.1940160562
            },
            {
                "lat": 49.289237809,
                "lng": -124.2106476426
            },
            {
                "lat": 49.2962950117,
                "lng": -124.2366109435
            },
            {
                "lat": 49.3058734814,
                "lng": -124.2666086216
            },
            {
                "lat": 49.3058735337,
                "lng": -124.2867730465
            },
            {
                "lat": 49.3149492038,
                "lng": -124.3344112498
            },
            {
                "lat": 49.3383907347,
                "lng": -124.3684391703
            },
            {
                "lat": 49.3407213757,
                "lng": -124.3804630703
            },
            {
                "lat": 49.3430169833,
                "lng": -124.4031448507
            },
            {
                "lat": 49.3435931946,
                "lng": -124.4467855506
            },
            {
                "lat": 49.3522068155,
                "lng": -124.5113830352
            },
            {
                "lat": 49.3648379724,
                "lng": -124.5498592579
            },
            {
                "lat": 49.3677449141,
                "lng": -124.5721593864
            },
            {
                "lat": 49.3746448126,
                "lng": -124.5940553717
            },
            {
                "lat": 49.391540141,
                "lng": -124.6345057536
            },
            {
                "lat": 49.4184261651,
                "lng": -124.6763846571
            },
            {
                "lat": 49.4462657295,
                "lng": -124.7125481947
            },
            {
                "lat": 49.4606548476,
                "lng": -124.7242747955
            },
            {
                "lat": 49.4573741675,
                "lng": -124.7280514655
            },
            {
                "lat": 49.4542310451,
                "lng": -124.7321081407
            },
            {
                "lat": 49.4467432022,
                "lng": -124.7389604215
            },
            {
                "lat": 49.444126005,
                "lng": -124.7558581519
            },
            {
                "lat": 49.447932767,
                "lng": -124.7810823814
            },
            {
                "lat": 49.4595906738,
                "lng": -124.8032074103
            },
            {
                "lat": 49.476249691,
                "lng": -124.8077303427
            },
            {
                "lat": 49.4864802995,
                "lng": -124.8151101093
            },
            {
                "lat": 49.4926678412,
                "lng": -124.8274767317
            },
            {
                "lat": 49.5117041135,
                "lng": -124.8507998733
            },
            {
                "lat": 49.5345458107,
                "lng": -124.8686448191
            },
            {
                "lat": 49.5466809742,
                "lng": -124.8781590649
            },
            {
                "lat": 49.5537904297,
                "lng": -124.8793260737
            },
            {
                "lat": 49.5606345387,
                "lng": -124.883598251
            },
            {
                "lat": 49.5767777008,
                "lng": -124.8912960963
            },
            {
                "lat": 49.5898245951,
                "lng": -124.8957898176
            },
            {
                "lat": 49.6001819768,
                "lng": -124.9020702036
            },
            {
                "lat": 49.614418434,
                "lng": -124.9120792261
            },
            {
                "lat": 49.6323243463,
                "lng": -124.9267583422
            },
            {
                "lat": 49.6388969911,
                "lng": -124.9309230897
            },
            {
                "lat": 49.6412161009,
                "lng": -124.9361026837
            },
            {
                "lat": 49.6443791239,
                "lng": -124.9421457712
            },
            {
                "lat": 49.647812243,
                "lng": -124.9506682438
            },
            {
                "lat": 49.6512453104,
                "lng": -124.9576709132
            },
            {
                "lat": 49.6586644571,
                "lng": -124.9681172854
            },
            {
                "lat": 49.6622348094,
                "lng": -124.9725114288
            },
            {
                "lat": 49.6670450436,
                "lng": -124.9767678766
            },
            {
                "lat": 49.6699333972,
                "lng": -124.9798508118
            },
            {
                "lat": 49.6739123328,
                "lng": -124.9823300239
            },
            {
                "lat": 49.6790127426,
                "lng": -124.9849545817
            },
            {
                "lat": 49.6821022161,
                "lng": -124.985603239
            },
            {
                "lat": 49.6838345321,
                "lng": -124.9849554628
            },
            {
                "lat": 49.6850510007,
                "lng": -124.9823760601
            },
            {
                "lat": 49.685505058,
                "lng": -124.9803987661
            },
            {
                "lat": 49.6844933648,
                "lng": -124.9744347581
            },
            {
                "lat": 49.6827088993,
                "lng": -124.9635843821
            },
            {
                "lat": 49.6803699974,
                "lng": -124.952866932
            },
            {
                "lat": 49.6777611609,
                "lng": -124.9337696158
            },
            {
                "lat": 49.6741905887,
                "lng": -124.9093178657
            },
            {
                "lat": 49.672530679,
                "lng": -124.9054255424
            },
            {
                "lat": 49.6831627825,
                "lng": -124.8883353372
            },
            {
                "lat": 49.697883473,
                "lng": -124.8715299327
            },
            {
                "lat": 49.6972688791,
                "lng": -124.8803013409
            },
            {
                "lat": 49.7106403283,
                "lng": -124.90544965
            },
            {
                "lat": 49.7310141578,
                "lng": -124.9500114294
            },
            {
                "lat": 49.7778048922,
                "lng": -125.020682492
            },
            {
                "lat": 49.8265110104,
                "lng": -125.0986709594
            },
            {
                "lat": 49.8618429701,
                "lng": -125.1381450922
            },
            {
                "lat": 49.8834912275,
                "lng": -125.1664734958
            },
            {
                "lat": 49.9105453918,
                "lng": -125.1976708016
            },
            {
                "lat": 49.9283721312,
                "lng": -125.200218094
            },
            {
                "lat": 49.9382405984,
                "lng": -125.1998991056
            },
            {
                "lat": 49.9476468582,
                "lng": -125.204894703
            },
            {
                "lat": 49.956283912,
                "lng": -125.2116771885
            },
            {
                "lat": 49.966460933,
                "lng": -125.2209325957
            },
            {
                "lat": 49.9786456261,
                "lng": -125.2292555059
            },
            {
                "lat": 49.9926763576,
                "lng": -125.234963205
            },
            {
                "lat": 49.9971498642,
                "lng": -125.2369681664
            },
            {
                "lat": 50.0040432116,
                "lng": -125.2387622708
            },
            {
                "lat": 50.0094375504,
                "lng": -125.2401127509
            },
            {
                "lat": 50.0155062562,
                "lng": -125.2420505855
            },
            {
                "lat": 50.0295250372,
                "lng": -125.2478629684
            },
            {
                "lat": 50.0327534126,
                "lng": -125.2473979326
            },
            {
                "lat": 50.0372052799,
                "lng": -125.2472145056
            },
            {
                "lat": 50.0402225393,
                "lng": -125.2476812346
            },
            {
                "lat": 50.0418849755,
                "lng": -125.2488789137
            },
            {
                "lat": 50.0411225858,
                "lng": -125.2486488999
            },
            {
                "lat": 50.0400889731,
                "lng": -125.2484443069
            },
            {
                "lat": 50.0382877877,
                "lng": -125.2486502497
            },
            {
                "lat": 50.0357361715,
                "lng": -125.2494740354
            },
            {
                "lat": 50.0344925794,
                "lng": -125.2526771954
            },
            {
                "lat": 50.0332448346,
                "lng": -125.2567753529
            },
            {
                "lat": 50.0323564767,
                "lng": -125.2621149971
            },
            {
                "lat": 50.0311082813,
                "lng": -125.2676311968
            },
            {
                "lat": 50.0311083452,
                "lng": -125.2729719079
            },
            {
                "lat": 50.0325319848,
                "lng": -125.2829434967
            },
            {
                "lat": 50.0344924663,
                "lng": -125.2930905812
            },
            {
                "lat": 50.0376966004,
                "lng": -125.302702995
            },
            {
                "lat": 50.0423236858,
                "lng": -125.3132097545
            },
            {
                "lat": 50.0437464806,
                "lng": -125.3183664241
            },
            {
                "lat": 50.0449943252,
                "lng": -125.3194350732
            },
            {
                "lat": 50.0469515684,
                "lng": -125.3181910818
            },
            {
                "lat": 50.0478441237,
                "lng": -125.315521247
            },
            {
                "lat": 50.0481983794,
                "lng": -125.3121420609
            },
            {
                "lat": 50.0462414332,
                "lng": -125.306617953
            },
            {
                "lat": 50.043925653,
                "lng": -125.2989653838
            },
            {
                "lat": 50.0410773433,
                "lng": -125.2923811607
            },
            {
                "lat": 50.0398327587,
                "lng": -125.280097592
            },
            {
                "lat": 50.0384062593,
                "lng": -125.2743981384
            },
            {
                "lat": 50.0387649519,
                "lng": -125.2713699976
            },
            {
                "lat": 50.0416102584,
                "lng": -125.270835873
            },
            {
                "lat": 50.0469515423,
                "lng": -125.2686996188
            },
            {
                "lat": 50.051761901,
                "lng": -125.2669222629
            },
            {
                "lat": 50.0588794246,
                "lng": -125.2687002304
            },
            {
                "lat": 50.0636862369,
                "lng": -125.2717292259
            },
            {
                "lat": 50.0670702468,
                "lng": -125.2747570625
            },
            {
                "lat": 50.0713427074,
                "lng": -125.2788469822
            },
            {
                "lat": 50.0725254614,
                "lng": -125.2811648664
            },
            {
                "lat": 50.0726933121,
                "lng": -125.2851945398
            },
            {
                "lat": 50.071178633,
                "lng": -125.2872090794
            },
            {
                "lat": 50.0678177049,
                "lng": -125.288894326
            },
            {
                "lat": 50.0666432305,
                "lng": -125.2939309955
            },
            {
                "lat": 50.0676498979,
                "lng": -125.2967913089
            },
            {
                "lat": 50.0762219284,
                "lng": -125.3065335709
            },
            {
                "lat": 50.0878143732,
                "lng": -125.3157729473
            },
            {
                "lat": 50.0945315312,
                "lng": -125.323333379
            },
            {
                "lat": 50.1017575379,
                "lng": -125.328376582
            },
            {
                "lat": 50.1076400706,
                "lng": -125.3330845449
            },
            {
                "lat": 50.1093219433,
                "lng": -125.3386238321
            },
            {
                "lat": 50.1111685558,
                "lng": -125.346687619
            },
            {
                "lat": 50.1121752943,
                "lng": -125.3586197111
            },
            {
                "lat": 50.1104969294,
                "lng": -125.3687053415
            },
            {
                "lat": 50.109322267,
                "lng": -125.3742444762
            },
            {
                "lat": 50.1104968042,
                "lng": -125.3767704788
            },
            {
                "lat": 50.1168819424,
                "lng": -125.3863443527
            },
            {
                "lat": 50.1262894883,
                "lng": -125.3925632102
            },
            {
                "lat": 50.1328427559,
                "lng": -125.395751773
            },
            {
                "lat": 50.1373783575,
                "lng": -125.3957518453
            },
            {
                "lat": 50.1397318654,
                "lng": -125.3952475077
            },
            {
                "lat": 50.1409077819,
                "lng": -125.3923942035
            },
            {
                "lat": 50.1419148655,
                "lng": -125.3891975998
            },
            {
                "lat": 50.1407391746,
                "lng": -125.3829801717
            },
            {
                "lat": 50.1378819753,
                "lng": -125.3747487128
            },
            {
                "lat": 50.1343537124,
                "lng": -125.3655098831
            },
            {
                "lat": 50.1321721608,
                "lng": -125.3618166445
            },
            {
                "lat": 50.1356855971,
                "lng": -125.3628463281
            },
            {
                "lat": 50.1391138707,
                "lng": -125.3638231463
            },
            {
                "lat": 50.1514784193,
                "lng": -125.3692096033
            },
            {
                "lat": 50.1557610666,
                "lng": -125.3711699983
            },
            {
                "lat": 50.1589427167,
                "lng": -125.3743514609
            },
            {
                "lat": 50.1616365787,
                "lng": -125.376434194
            },
            {
                "lat": 50.1642070366,
                "lng": -125.3771662456
            },
            {
                "lat": 50.1693495691,
                "lng": -125.3780285462
            },
            {
                "lat": 50.1740038042,
                "lng": -125.380478164
            },
            {
                "lat": 50.177550824,
                "lng": -125.3836600817
            },
            {
                "lat": 50.1835484867,
                "lng": -125.3853687041
            },
            {
                "lat": 50.1864888531,
                "lng": -125.3864747511
            },
            {
                "lat": 50.1912611126,
                "lng": -125.386962709
            },
            {
                "lat": 50.199462283,
                "lng": -125.3890453838
            },
            {
                "lat": 50.2059515735,
                "lng": -125.3911211184
            },
            {
                "lat": 50.2104832365,
                "lng": -125.3952861498
            },
            {
                "lat": 50.2219888127,
                "lng": -125.401282634
            },
            {
                "lat": 50.231292843,
                "lng": -125.4036097846
            },
            {
                "lat": 50.2376592476,
                "lng": -125.4072794482
            },
            {
                "lat": 50.2432907467,
                "lng": -125.407279502
            },
            {
                "lat": 50.2458610196,
                "lng": -125.404099312
            },
            {
                "lat": 50.2479436138,
                "lng": -125.4037248496
            },
            {
                "lat": 50.2511021308,
                "lng": -125.4047396366
            },
            {
                "lat": 50.2581672679,
                "lng": -125.4083326904
            },
            {
                "lat": 50.2647515906,
                "lng": -125.411330688
            },
            {
                "lat": 50.2679636851,
                "lng": -125.4138943251
            },
            {
                "lat": 50.2686349969,
                "lng": -125.4167707559
            },
            {
                "lat": 50.2703060663,
                "lng": -125.4227520758
            },
            {
                "lat": 50.2711367563,
                "lng": -125.4315112972
            },
            {
                "lat": 50.2729448319,
                "lng": -125.4387441699
            },
            {
                "lat": 50.2790638978,
                "lng": -125.4434663236
            },
            {
                "lat": 50.2815667549,
                "lng": -125.4444425986
            },
            {
                "lat": 50.2851826386,
                "lng": -125.4423516499
            },
            {
                "lat": 50.2879639418,
                "lng": -125.4404066626
            },
            {
                "lat": 50.2933837309,
                "lng": -125.4413829861
            },
            {
                "lat": 50.2974167176,
                "lng": -125.4441605318
            },
            {
                "lat": 50.3036761583,
                "lng": -125.4445804673
            },
            {
                "lat": 50.3099324534,
                "lng": -125.4441605632
            },
            {
                "lat": 50.3146590419,
                "lng": -125.4458318691
            },
            {
                "lat": 50.3182756628,
                "lng": -125.4498671822
            },
            {
                "lat": 50.3218882419,
                "lng": -125.4565361805
            },
            {
                "lat": 50.323971215,
                "lng": -125.4573900546
            },
            {
                "lat": 50.3230629948,
                "lng": -125.4647742279
            },
            {
                "lat": 50.3212622,
                "lng": -125.4787680262
            },
            {
                "lat": 50.3214645088,
                "lng": -125.5019610888
            },
            {
                "lat": 50.3250620165,
                "lng": -125.5153578012
            },
            {
                "lat": 50.3296588197,
                "lng": -125.5299536245
            },
            {
                "lat": 50.3360595691,
                "lng": -125.5425415547
            },
            {
                "lat": 50.3450549966,
                "lng": -125.5585403027
            },
            {
                "lat": 50.3502541967,
                "lng": -125.5699382083
            },
            {
                "lat": 50.3532577763,
                "lng": -125.5801422378
            },
            {
                "lat": 50.3532573257,
                "lng": -125.5861383891
            },
            {
                "lat": 50.3532577074,
                "lng": -125.6009315224
            },
            {
                "lat": 50.3542564897,
                "lng": -125.6239273156
            },
            {
                "lat": 50.3538560197,
                "lng": -125.6403229212
            },
            {
                "lat": 50.3530548877,
                "lng": -125.6513164248
            },
            {
                "lat": 50.3510514298,
                "lng": -125.6607046871
            },
            {
                "lat": 50.3494532812,
                "lng": -125.678695596
            },
            {
                "lat": 50.347454347,
                "lng": -125.699287189
            },
            {
                "lat": 50.3482551846,
                "lng": -125.7138826362
            },
            {
                "lat": 50.3498534773,
                "lng": -125.7280802295
            },
            {
                "lat": 50.3512542846,
                "lng": -125.7338787618
            },
            {
                "lat": 50.3536532186,
                "lng": -125.7468725517
            },
            {
                "lat": 50.3550489956,
                "lng": -125.7652666549
            },
            {
                "lat": 50.3584485589,
                "lng": -125.7886579417
            },
            {
                "lat": 50.3656463488,
                "lng": -125.8044512616
            },
            {
                "lat": 50.3722463025,
                "lng": -125.8194433723
            },
            {
                "lat": 50.3762439438,
                "lng": -125.8282392958
            },
            {
                "lat": 50.37604446,
                "lng": -125.8344421873
            },
            {
                "lat": 50.3744430873,
                "lng": -125.8412397478
            },
            {
                "lat": 50.3728445475,
                "lng": -125.8572312379
            },
            {
                "lat": 50.3738441856,
                "lng": -125.8758238846
            },
            {
                "lat": 50.3758435083,
                "lng": -125.8918149967
            },
            {
                "lat": 50.3790436265,
                "lng": -125.9046176425
            },
            {
                "lat": 50.3816417181,
                "lng": -125.9128114823
            },
            {
                "lat": 50.3821299625,
                "lng": -125.9182205449
            },
            {
                "lat": 50.383086541,
                "lng": -125.9264148638
            },
            {
                "lat": 50.3849493331,
                "lng": -125.935241226
            },
            {
                "lat": 50.3862043054,
                "lng": -125.9397733662
            },
            {
                "lat": 50.3813592924,
                "lng": -125.9349967053
            },
            {
                "lat": 50.3752405274,
                "lng": -125.9297325532
            },
            {
                "lat": 50.3745765757,
                "lng": -125.926116685
            },
            {
                "lat": 50.3738932005,
                "lng": -125.9230124869
            },
            {
                "lat": 50.3725812425,
                "lng": -125.9198602901
            },
            {
                "lat": 50.3697432298,
                "lng": -125.9174493165
            },
            {
                "lat": 50.3649096546,
                "lng": -125.915763869
            },
            {
                "lat": 50.3608129856,
                "lng": -125.9156572658
            },
            {
                "lat": 50.3613171714,
                "lng": -125.918434773
            },
            {
                "lat": 50.3613658246,
                "lng": -125.9189144556
            },
            {
                "lat": 50.3615498453,
                "lng": -125.9209667778
            },
            {
                "lat": 50.3626019744,
                "lng": -125.9212259281
            },
            {
                "lat": 50.3659097818,
                "lng": -125.9224856241
            },
            {
                "lat": 50.3682218298,
                "lng": -125.9238051856
            },
            {
                "lat": 50.3695335048,
                "lng": -125.9258507172
            },
            {
                "lat": 50.3700212674,
                "lng": -125.9279548
            },
            {
                "lat": 50.3678748727,
                "lng": -125.9299698589
            },
            {
                "lat": 50.3672063252,
                "lng": -125.9314420274
            },
            {
                "lat": 50.3665999329,
                "lng": -125.9354704872
            },
            {
                "lat": 50.3665996933,
                "lng": -125.9400332254
            },
            {
                "lat": 50.3668662917,
                "lng": -125.9439927751
            },
            {
                "lat": 50.3682784914,
                "lng": -125.9479519391
            },
            {
                "lat": 50.369952676,
                "lng": -125.9515691856
            },
            {
                "lat": 50.3725055532,
                "lng": -125.9548562287
            },
            {
                "lat": 50.3761294334,
                "lng": -125.9568716511
            },
            {
                "lat": 50.3800202494,
                "lng": -125.9594884182
            },
            {
                "lat": 50.3830378487,
                "lng": -125.9610285791
            },
            {
                "lat": 50.3869973324,
                "lng": -125.9611658385
            },
            {
                "lat": 50.3906176723,
                "lng": -125.9615715819
            },
            {
                "lat": 50.3937725685,
                "lng": -125.9624411953
            },
            {
                "lat": 50.3954510114,
                "lng": -125.96344755
            },
            {
                "lat": 50.396590922,
                "lng": -125.9646522713
            },
            {
                "lat": 50.3985359923,
                "lng": -125.9671403875
            },
            {
                "lat": 50.4013522322,
                "lng": -125.9724354369
            },
            {
                "lat": 50.4032974391,
                "lng": -125.9763946984
            },
            {
                "lat": 50.4074593061,
                "lng": -125.9848480852
            },
            {
                "lat": 50.4107481429,
                "lng": -125.9906844042
            },
            {
                "lat": 50.415912467,
                "lng": -125.9992759503
            },
            {
                "lat": 50.4174534792,
                "lng": -126.0020903815
            },
            {
                "lat": 50.4180530934,
                "lng": -126.0065457258
            },
            {
                "lat": 50.4199216528,
                "lng": -126.0118408631
            },
            {
                "lat": 50.4239730557,
                "lng": -126.0296100853
            },
            {
                "lat": 50.4339450952,
                "lng": -126.0751043119
            },
            {
                "lat": 50.4407995681,
                "lng": -126.1068961006
            },
            {
                "lat": 50.4470326669,
                "lng": -126.1405488361
            },
            {
                "lat": 50.448280622,
                "lng": -126.1595612735
            },
            {
                "lat": 50.4520185143,
                "lng": -126.1804424193
            },
            {
                "lat": 50.4538879799,
                "lng": -126.1963341691
            },
            {
                "lat": 50.4585645867,
                "lng": -126.2134706258
            },
            {
                "lat": 50.4629253403,
                "lng": -126.2228247006
            },
            {
                "lat": 50.4647299839,
                "lng": -126.2284693184
            },
            {
                "lat": 50.466323703,
                "lng": -126.236258782
            },
            {
                "lat": 50.4670336014,
                "lng": -126.2452850203
            },
            {
                "lat": 50.4636691364,
                "lng": -126.2543185356
            },
            {
                "lat": 50.4595949964,
                "lng": -126.2688292943
            },
            {
                "lat": 50.4583547225,
                "lng": -126.2787470888
            },
            {
                "lat": 50.4608345032,
                "lng": -126.2895502741
            },
            {
                "lat": 50.4652598135,
                "lng": -126.3019405826
            },
            {
                "lat": 50.4700430374,
                "lng": -126.3157495384
            },
            {
                "lat": 50.4711034258,
                "lng": -126.3316879232
            },
            {
                "lat": 50.4721640752,
                "lng": -126.3431936784
            },
            {
                "lat": 50.4746437593,
                "lng": -126.3509830862
            },
            {
                "lat": 50.4741815264,
                "lng": -126.3738866127
            },
            {
                "lat": 50.4711152651,
                "lng": -126.3916702176
            },
            {
                "lat": 50.4631466189,
                "lng": -126.4161918425
            },
            {
                "lat": 50.4594688201,
                "lng": -126.4450081073
            },
            {
                "lat": 50.4637602379,
                "lng": -126.4873122508
            },
            {
                "lat": 50.4662127705,
                "lng": -126.5216444449
            },
            {
                "lat": 50.463760314,
                "lng": -126.5388191492
            },
            {
                "lat": 50.456401689,
                "lng": -126.5682455999
            },
            {
                "lat": 50.4594691168,
                "lng": -126.5878678467
            },
            {
                "lat": 50.4674378121,
                "lng": -126.6172942207
            },
            {
                "lat": 50.4803123448,
                "lng": -126.6534657092
            },
            {
                "lat": 50.4944160462,
                "lng": -126.6896441958
            },
            {
                "lat": 50.5085183509,
                "lng": -126.7313317486
            },
            {
                "lat": 50.5244555048,
                "lng": -126.7785415725
            },
            {
                "lat": 50.5324290473,
                "lng": -126.7938688925
            },
            {
                "lat": 50.5365790377,
                "lng": -126.8032374435
            },
            {
                "lat": 50.538372069,
                "lng": -126.8094334015
            },
            {
                "lat": 50.5373951027,
                "lng": -126.8169409321
            },
            {
                "lat": 50.5380483041,
                "lng": -126.8224868314
            },
            {
                "lat": 50.5411451462,
                "lng": -126.829986153
            },
            {
                "lat": 50.5414730116,
                "lng": -126.8339006599
            },
            {
                "lat": 50.5370667281,
                "lng": -126.8387990677
            },
            {
                "lat": 50.5321730212,
                "lng": -126.8425521371
            },
            {
                "lat": 50.5277711045,
                "lng": -126.8529886575
            },
            {
                "lat": 50.5238539352,
                "lng": -126.8621218927
            },
            {
                "lat": 50.5243447802,
                "lng": -126.8719102595
            },
            {
                "lat": 50.5282588036,
                "lng": -126.8799056342
            },
            {
                "lat": 50.5346219683,
                "lng": -126.887244963
            },
            {
                "lat": 50.5398407955,
                "lng": -126.8885502277
            },
            {
                "lat": 50.5439191851,
                "lng": -126.8851242888
            },
            {
                "lat": 50.5463671911,
                "lng": -126.8812105172
            },
            {
                "lat": 50.5465316908,
                "lng": -126.8733826337
            },
            {
                "lat": 50.5501522449,
                "lng": -126.8773497256
            },
            {
                "lat": 50.5510299988,
                "lng": -126.8874666528
            },
            {
                "lat": 50.5501520603,
                "lng": -126.907264833
            },
            {
                "lat": 50.5461926115,
                "lng": -126.9358588741
            },
            {
                "lat": 50.5475117194,
                "lng": -126.9719388939
            },
            {
                "lat": 50.5488316148,
                "lng": -126.9864579279
            },
            {
                "lat": 50.5343139246,
                "lng": -127.0005422358
            },
            {
                "lat": 50.5325511925,
                "lng": -127.0106581322
            },
            {
                "lat": 50.5382731989,
                "lng": -127.0212171908
            },
            {
                "lat": 50.5527911744,
                "lng": -127.0221023328
            },
            {
                "lat": 50.5664289482,
                "lng": -127.0185779356
            },
            {
                "lat": 50.5734713591,
                "lng": -127.0234150313
            },
            {
                "lat": 50.5747913694,
                "lng": -127.0524599586
            },
            {
                "lat": 50.5739098567,
                "lng": -127.0973355388
            },
            {
                "lat": 50.5787505378,
                "lng": -127.1413341846
            },
            {
                "lat": 50.5862276291,
                "lng": -127.1624522387
            },
            {
                "lat": 50.5972292256,
                "lng": -127.1558537224
            },
            {
                "lat": 50.5975913035,
                "lng": -127.165938542
            },
            {
                "lat": 50.5996014856,
                "lng": -127.1808771471
            },
            {
                "lat": 50.6019019082,
                "lng": -127.1943811788
            },
            {
                "lat": 50.6108057378,
                "lng": -127.2124780504
            },
            {
                "lat": 50.624023391,
                "lng": -127.2334519996
            },
            {
                "lat": 50.633789439,
                "lng": -127.2535551788
            },
            {
                "lat": 50.6412586018,
                "lng": -127.2707979494
            },
            {
                "lat": 50.6452786591,
                "lng": -127.2808539584
            },
            {
                "lat": 50.6484411745,
                "lng": -127.285736079
            },
            {
                "lat": 50.6555672645,
                "lng": -127.3046643121
            },
            {
                "lat": 50.6612924159,
                "lng": -127.3280871184
            },
            {
                "lat": 50.672740802,
                "lng": -127.3561855964
            },
            {
                "lat": 50.6841923749,
                "lng": -127.3816909512
            },
            {
                "lat": 50.6862718111,
                "lng": -127.401985045
            },
            {
                "lat": 50.6873135753,
                "lng": -127.4165578321
            },
            {
                "lat": 50.6935579929,
                "lng": -127.429046592
            },
            {
                "lat": 50.7050098567,
                "lng": -127.435814177
            },
            {
                "lat": 50.7122954278,
                "lng": -127.4462201655
            },
            {
                "lat": 50.712295285,
                "lng": -127.4514239773
            },
            {
                "lat": 50.700325502,
                "lng": -127.4613115929
            },
            {
                "lat": 50.6919968388,
                "lng": -127.4649582002
            },
            {
                "lat": 50.6883543128,
                "lng": -127.4722438911
            },
            {
                "lat": 50.6977236029,
                "lng": -127.4909744035
            },
            {
                "lat": 50.7112545292,
                "lng": -127.5013815568
            },
            {
                "lat": 50.7268634476,
                "lng": -127.5050273462
            },
            {
                "lat": 50.7362325955,
                "lng": -127.5086669559
            },
            {
                "lat": 50.7565274627,
                "lng": -127.5404128671
            },
            {
                "lat": 50.7757835093,
                "lng": -127.5841291741
            },
            {
                "lat": 50.7893143343,
                "lng": -127.6174394072
            },
            {
                "lat": 50.7971223126,
                "lng": -127.6450194908
            },
            {
                "lat": 50.8012841488,
                "lng": -127.6564711598
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 400,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 544,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 28756761197.8855,
            "FEATURE_LENGTH_M": 2372763.1644,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.77536278361553,
            "lng": -126.25972554579826
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8781476525,
                "lng": -126.7199018695
            },
            {
                "lat": 50.8779869449,
                "lng": -126.7206272954
            },
            {
                "lat": 50.8746525438,
                "lng": -126.7210010672
            },
            {
                "lat": 50.8733639657,
                "lng": -126.7214042532
            },
            {
                "lat": 50.866668943,
                "lng": -126.7294853508
            },
            {
                "lat": 50.862994738,
                "lng": -126.7339172968
            },
            {
                "lat": 50.8647576457,
                "lng": -126.7429809933
            },
            {
                "lat": 50.8625292245,
                "lng": -126.7453387341
            },
            {
                "lat": 50.8616594051,
                "lng": -126.7561115582
            },
            {
                "lat": 50.8612908036,
                "lng": -126.7686235137
            },
            {
                "lat": 50.8555906751,
                "lng": -126.795134943
            },
            {
                "lat": 50.8524930537,
                "lng": -126.8126000445
            },
            {
                "lat": 50.8455584235,
                "lng": -126.8174288268
            },
            {
                "lat": 50.8397639284,
                "lng": -126.8149187437
            },
            {
                "lat": 50.8389355796,
                "lng": -126.8146594125
            },
            {
                "lat": 50.8387561074,
                "lng": -126.8144829663
            },
            {
                "lat": 50.8374593794,
                "lng": -126.8144833335
            },
            {
                "lat": 50.8337324321,
                "lng": -126.8168105244
            },
            {
                "lat": 50.8322789079,
                "lng": -126.8144449951
            },
            {
                "lat": 50.8288572736,
                "lng": -126.8133083256
            },
            {
                "lat": 50.8240052942,
                "lng": -126.8148724188
            },
            {
                "lat": 50.8165859127,
                "lng": -126.8143086164
            },
            {
                "lat": 50.8057443205,
                "lng": -126.8117374487
            },
            {
                "lat": 50.79333096,
                "lng": -126.8087381209
            },
            {
                "lat": 50.7926219127,
                "lng": -126.8044586952
            },
            {
                "lat": 50.7934764091,
                "lng": -126.7967604566
            },
            {
                "lat": 50.7984694081,
                "lng": -126.7876290603
            },
            {
                "lat": 50.8057442113,
                "lng": -126.7810663657
            },
            {
                "lat": 50.8154447984,
                "lng": -126.7837752259
            },
            {
                "lat": 50.8228643852,
                "lng": -126.7884835844
            },
            {
                "lat": 50.8301387684,
                "lng": -126.790192313
            },
            {
                "lat": 50.8336253518,
                "lng": -126.7901917193
            },
            {
                "lat": 50.8309327105,
                "lng": -126.7731173404
            },
            {
                "lat": 50.8319932066,
                "lng": -126.7677997208
            },
            {
                "lat": 50.8322786859,
                "lng": -126.7458258826
            },
            {
                "lat": 50.8328516235,
                "lng": -126.7274244613
            },
            {
                "lat": 50.8347057233,
                "lng": -126.710731977
            },
            {
                "lat": 50.8351333977,
                "lng": -126.7003175646
            },
            {
                "lat": 50.8347057511,
                "lng": -126.6909030349
            },
            {
                "lat": 50.8335648561,
                "lng": -126.6861949461
            },
            {
                "lat": 50.8254322208,
                "lng": -126.679633356
            },
            {
                "lat": 50.8208650603,
                "lng": -126.6737829344
            },
            {
                "lat": 50.8187071953,
                "lng": -126.6654352455
            },
            {
                "lat": 50.8200110441,
                "lng": -126.6559522727
            },
            {
                "lat": 50.8244322215,
                "lng": -126.637687916
            },
            {
                "lat": 50.823006141,
                "lng": -126.6249916652
            },
            {
                "lat": 50.8197255903,
                "lng": -126.6055916173
            },
            {
                "lat": 50.8160163704,
                "lng": -126.589469605
            },
            {
                "lat": 50.8105965966,
                "lng": -126.5747759792
            },
            {
                "lat": 50.808883935,
                "lng": -126.5719228415
            },
            {
                "lat": 50.8047450566,
                "lng": -126.5670707791
            },
            {
                "lat": 50.8020364619,
                "lng": -126.5630796816
            },
            {
                "lat": 50.8013228639,
                "lng": -126.5585096917
            },
            {
                "lat": 50.8013224963,
                "lng": -126.5545201299
            },
            {
                "lat": 50.7996101404,
                "lng": -126.5525210923
            },
            {
                "lat": 50.7980422938,
                "lng": -126.5513766644
            },
            {
                "lat": 50.7959943778,
                "lng": -126.5517347457
            },
            {
                "lat": 50.7956162377,
                "lng": -126.5518037217
            },
            {
                "lat": 50.7926217893,
                "lng": -126.549667755
            },
            {
                "lat": 50.7921909853,
                "lng": -126.5452425613
            },
            {
                "lat": 50.791621727,
                "lng": -126.540390839
            },
            {
                "lat": 50.789909063,
                "lng": -126.5358280768
            },
            {
                "lat": 50.7919199291,
                "lng": -126.5331107802
            },
            {
                "lat": 50.7928315893,
                "lng": -126.5316932603
            },
            {
                "lat": 50.8170584767,
                "lng": -126.5076287844
            },
            {
                "lat": 50.8179129178,
                "lng": -126.5067436908
            },
            {
                "lat": 50.8189085881,
                "lng": -126.5044167579
            },
            {
                "lat": 50.8210145564,
                "lng": -126.499701721
            },
            {
                "lat": 50.822361371,
                "lng": -126.4939737746
            },
            {
                "lat": 50.8257326659,
                "lng": -126.4860453283
            },
            {
                "lat": 50.8306234451,
                "lng": -126.4804840632
            },
            {
                "lat": 50.8338282368,
                "lng": -126.4749223333
            },
            {
                "lat": 50.8400643989,
                "lng": -126.4668276075
            },
            {
                "lat": 50.8471486778,
                "lng": -126.462271935
            },
            {
                "lat": 50.8543966943,
                "lng": -126.4619372989
            },
            {
                "lat": 50.8592867965,
                "lng": -126.4681779725
            },
            {
                "lat": 50.8628270377,
                "lng": -126.4840244751
            },
            {
                "lat": 50.8661995824,
                "lng": -126.4946517718
            },
            {
                "lat": 50.8690645882,
                "lng": -126.510161898
            },
            {
                "lat": 50.866534793,
                "lng": -126.5185929552
            },
            {
                "lat": 50.8641775063,
                "lng": -126.5268482604
            },
            {
                "lat": 50.8597944814,
                "lng": -126.5373077559
            },
            {
                "lat": 50.8594545334,
                "lng": -126.547928463
            },
            {
                "lat": 50.8596456869,
                "lng": -126.5509562006
            },
            {
                "lat": 50.8615720164,
                "lng": -126.5529178582
            },
            {
                "lat": 50.8623812675,
                "lng": -126.5535196505
            },
            {
                "lat": 50.8667828165,
                "lng": -126.558219149
            },
            {
                "lat": 50.8667829139,
                "lng": -126.5595503573
            },
            {
                "lat": 50.8666757655,
                "lng": -126.5602110568
            },
            {
                "lat": 50.8664326346,
                "lng": -126.5606772176
            },
            {
                "lat": 50.8660391457,
                "lng": -126.5624700131
            },
            {
                "lat": 50.8661343621,
                "lng": -126.5650784919
            },
            {
                "lat": 50.8675723648,
                "lng": -126.5698617287
            },
            {
                "lat": 50.8700333717,
                "lng": -126.6016151957
            },
            {
                "lat": 50.869949319,
                "lng": -126.6021117499
            },
            {
                "lat": 50.8698005048,
                "lng": -126.6035841265
            },
            {
                "lat": 50.8699679414,
                "lng": -126.6053238499
            },
            {
                "lat": 50.8701669459,
                "lng": -126.6059948679
            },
            {
                "lat": 50.8703955594,
                "lng": -126.6062770286
            },
            {
                "lat": 50.8651045184,
                "lng": -126.6636508333
            },
            {
                "lat": 50.8683627506,
                "lng": -126.6647336399
            },
            {
                "lat": 50.8705829574,
                "lng": -126.6662144192
            },
            {
                "lat": 50.8727108804,
                "lng": -126.6716766115
            },
            {
                "lat": 50.8753011011,
                "lng": -126.6788946687
            },
            {
                "lat": 50.8776128703,
                "lng": -126.6879653033
            },
            {
                "lat": 50.8794663169,
                "lng": -126.6978679887
            },
            {
                "lat": 50.8798366743,
                "lng": -126.7092440925
            },
            {
                "lat": 50.8790022293,
                "lng": -126.7160950662
            },
            {
                "lat": 50.8781476525,
                "lng": -126.7199018695
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 401,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 40,
            "MANAGEMENT_AREA_NAME": "Subarea 12-40",
            "LABEL": "12-40",
            "FISHERY_AREA_ID": 2098,
            "OBJECTID": 545,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 129151178.6119,
            "FEATURE_LENGTH_M": 70408.7828,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.83922941849815,
            "lng": -126.64546097334036
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8716166181,
                "lng": -127.4337770565
            },
            {
                "lat": 50.8666954432,
                "lng": -127.4519038678
            },
            {
                "lat": 50.8600846133,
                "lng": -127.5193630498
            },
            {
                "lat": 50.8053892403,
                "lng": -127.4606397338
            },
            {
                "lat": 50.8716166181,
                "lng": -127.4337770565
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 402,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 12-10",
            "LABEL": "12-10",
            "FISHERY_AREA_ID": 2068,
            "OBJECTID": 546,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 20152574.4058,
            "FEATURE_LENGTH_M": 21162.2072,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.855080506600004,
            "lng": -127.45989215288
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8716166181,
                "lng": -127.4337770565
            },
            {
                "lat": 50.8053892403,
                "lng": -127.4606397338
            },
            {
                "lat": 50.7261926976,
                "lng": -127.3654398247
            },
            {
                "lat": 50.7211345078,
                "lng": -127.3591996879
            },
            {
                "lat": 50.6589246233,
                "lng": -127.2824470117
            },
            {
                "lat": 50.7687719299,
                "lng": -127.1204453
            },
            {
                "lat": 50.8716166181,
                "lng": -127.4337770565
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 403,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 12-9",
            "LABEL": "12-9",
            "FISHERY_AREA_ID": 2067,
            "OBJECTID": 547,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 263001469.2913,
            "FEATURE_LENGTH_M": 69798.9031,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.77480660501429,
            "lng": -127.35081795301429
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8455584235,
                "lng": -126.8174288268
            },
            {
                "lat": 50.8524930537,
                "lng": -126.8126000445
            },
            {
                "lat": 50.8555906751,
                "lng": -126.795134943
            },
            {
                "lat": 50.8612908036,
                "lng": -126.7686235137
            },
            {
                "lat": 50.8616594051,
                "lng": -126.7561115582
            },
            {
                "lat": 50.8625292245,
                "lng": -126.7453387341
            },
            {
                "lat": 50.8647576457,
                "lng": -126.7429809933
            },
            {
                "lat": 50.8721882286,
                "lng": -126.7556159231
            },
            {
                "lat": 50.8749156109,
                "lng": -126.7828677195
            },
            {
                "lat": 50.8760301291,
                "lng": -126.8108590394
            },
            {
                "lat": 50.856334538,
                "lng": -126.8180469211
            },
            {
                "lat": 50.8455584235,
                "lng": -126.8174288268
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 404,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 548,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 8680065.1222,
            "FEATURE_LENGTH_M": 14611.3022,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.86074218010834,
            "lng": -126.78525308695832
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8244290452,
                "lng": -126.1878658542
            },
            {
                "lat": 50.8256753802,
                "lng": -126.1877521182
            },
            {
                "lat": 50.8260658165,
                "lng": -126.1895666912
            },
            {
                "lat": 50.8256757588,
                "lng": -126.1915058042
            },
            {
                "lat": 50.8256835554,
                "lng": -126.1918725126
            },
            {
                "lat": 50.8332482425,
                "lng": -126.2148899479
            },
            {
                "lat": 50.8356241813,
                "lng": -126.2155451749
            },
            {
                "lat": 50.8395618374,
                "lng": -126.216415817
            },
            {
                "lat": 50.8418121108,
                "lng": -126.2249830129
            },
            {
                "lat": 50.8431658733,
                "lng": -126.2335508418
            },
            {
                "lat": 50.8445210965,
                "lng": -126.2452696092
            },
            {
                "lat": 50.8463206477,
                "lng": -126.2470701419
            },
            {
                "lat": 50.8512801479,
                "lng": -126.2443694792
            },
            {
                "lat": 50.8539844934,
                "lng": -126.2407605611
            },
            {
                "lat": 50.8602942451,
                "lng": -126.2335514398
            },
            {
                "lat": 50.8652533247,
                "lng": -126.2294915443
            },
            {
                "lat": 50.8702123576,
                "lng": -126.2281412878
            },
            {
                "lat": 50.8738212175,
                "lng": -126.2312926218
            },
            {
                "lat": 50.8742708457,
                "lng": -126.2385100827
            },
            {
                "lat": 50.8742706916,
                "lng": -126.2466202304
            },
            {
                "lat": 50.8724676785,
                "lng": -126.2524786746
            },
            {
                "lat": 50.8679574285,
                "lng": -126.2596966635
            },
            {
                "lat": 50.863902881,
                "lng": -126.2655563812
            },
            {
                "lat": 50.8598446803,
                "lng": -126.2718654914
            },
            {
                "lat": 50.855339294,
                "lng": -126.2786255428
            },
            {
                "lat": 50.8548853854,
                "lng": -126.2880939474
            },
            {
                "lat": 50.8548852669,
                "lng": -126.2980111466
            },
            {
                "lat": 50.8553392514,
                "lng": -126.3016202314
            },
            {
                "lat": 50.8553396499,
                "lng": -126.3133383567
            },
            {
                "lat": 50.8557889973,
                "lng": -126.3264083745
            },
            {
                "lat": 50.853984233,
                "lng": -126.3363264421
            },
            {
                "lat": 50.8490259497,
                "lng": -126.3521042134
            },
            {
                "lat": 50.844970004,
                "lng": -126.3638303711
            },
            {
                "lat": 50.8413627099,
                "lng": -126.3719408079
            },
            {
                "lat": 50.837306526,
                "lng": -126.3791571781
            },
            {
                "lat": 50.8350527059,
                "lng": -126.3899762552
            },
            {
                "lat": 50.8327980231,
                "lng": -126.3985370438
            },
            {
                "lat": 50.8309936962,
                "lng": -126.4102558902
            },
            {
                "lat": 50.8287395853,
                "lng": -126.4224312644
            },
            {
                "lat": 50.825134283,
                "lng": -126.4373090294
            },
            {
                "lat": 50.8224301563,
                "lng": -126.4503780323
            },
            {
                "lat": 50.8198127448,
                "lng": -126.4578253727
            },
            {
                "lat": 50.8182717766,
                "lng": -126.485686482
            },
            {
                "lat": 50.8179248439,
                "lng": -126.4919896763
            },
            {
                "lat": 50.8193890871,
                "lng": -126.5002898747
            },
            {
                "lat": 50.8189085881,
                "lng": -126.5044167579
            },
            {
                "lat": 50.8179129178,
                "lng": -126.5067436908
            },
            {
                "lat": 50.8170584767,
                "lng": -126.5076287844
            },
            {
                "lat": 50.7944869226,
                "lng": -126.4414981969
            },
            {
                "lat": 50.7947007258,
                "lng": -126.4409484562
            },
            {
                "lat": 50.7951052047,
                "lng": -126.4393004279
            },
            {
                "lat": 50.7954064189,
                "lng": -126.4373550264
            },
            {
                "lat": 50.7964102224,
                "lng": -126.4329986828
            },
            {
                "lat": 50.7979246231,
                "lng": -126.4220119508
            },
            {
                "lat": 50.8059769747,
                "lng": -126.4010005645
            },
            {
                "lat": 50.8077696516,
                "lng": -126.3723149383
            },
            {
                "lat": 50.8176345543,
                "lng": -126.3346552834
            },
            {
                "lat": 50.8140444974,
                "lng": -126.3140336649
            },
            {
                "lat": 50.8077696231,
                "lng": -126.2978972493
            },
            {
                "lat": 50.8077695717,
                "lng": -126.2718963119
            },
            {
                "lat": 50.809562724,
                "lng": -126.2503809062
            },
            {
                "lat": 50.800598547,
                "lng": -126.2378302173
            },
            {
                "lat": 50.7853583847,
                "lng": -126.2360311647
            },
            {
                "lat": 50.7728043459,
                "lng": -126.2297594285
            },
            {
                "lat": 50.7548754435,
                "lng": -126.2073440813
            },
            {
                "lat": 50.7530827107,
                "lng": -126.192099825
            },
            {
                "lat": 50.745010182,
                "lng": -126.1965867312
            },
            {
                "lat": 50.7369426708,
                "lng": -126.2046579361
            },
            {
                "lat": 50.7360462202,
                "lng": -126.2351381303
            },
            {
                "lat": 50.7297704674,
                "lng": -126.250381151
            },
            {
                "lat": 50.7225990468,
                "lng": -126.2566522939
            },
            {
                "lat": 50.713634086,
                "lng": -126.2674177618
            },
            {
                "lat": 50.7055628459,
                "lng": -126.2763826052
            },
            {
                "lat": 50.6876335979,
                "lng": -126.2772759406
            },
            {
                "lat": 50.6741829267,
                "lng": -126.2745897554
            },
            {
                "lat": 50.6687933275,
                "lng": -126.2824095738
            },
            {
                "lat": 50.6566698728,
                "lng": -126.2670672618
            },
            {
                "lat": 50.6600994223,
                "lng": -126.2428740765
            },
            {
                "lat": 50.6684984753,
                "lng": -126.1845471991
            },
            {
                "lat": 50.6694031443,
                "lng": -126.1840820104
            },
            {
                "lat": 50.6695475747,
                "lng": -126.1836543931
            },
            {
                "lat": 50.6702876649,
                "lng": -126.1815181077
            },
            {
                "lat": 50.6726114983,
                "lng": -126.1776273449
            },
            {
                "lat": 50.6739770728,
                "lng": -126.1750251977
            },
            {
                "lat": 50.6799808075,
                "lng": -126.1747441772
            },
            {
                "lat": 50.7176970674,
                "lng": -126.1425478582
            },
            {
                "lat": 50.7192196961,
                "lng": -126.1379780082
            },
            {
                "lat": 50.7251170323,
                "lng": -126.1296233372
            },
            {
                "lat": 50.7321426034,
                "lng": -126.1253732995
            },
            {
                "lat": 50.7359241162,
                "lng": -126.1191940144
            },
            {
                "lat": 50.7415922832,
                "lng": -126.1122439483
            },
            {
                "lat": 50.749279473,
                "lng": -126.1068275159
            },
            {
                "lat": 50.753917345,
                "lng": -126.1116179947
            },
            {
                "lat": 50.7691616607,
                "lng": -126.1193701983
            },
            {
                "lat": 50.7794423124,
                "lng": -126.1230471634
            },
            {
                "lat": 50.7786450713,
                "lng": -126.1315386498
            },
            {
                "lat": 50.8226897722,
                "lng": -126.1880334495
            },
            {
                "lat": 50.8244290452,
                "lng": -126.1878658542
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 405,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 35,
            "MANAGEMENT_AREA_NAME": "Subarea 12-35",
            "LABEL": "12-35",
            "FISHERY_AREA_ID": 2093,
            "OBJECTID": 549,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 201397401.8041,
            "FEATURE_LENGTH_M": 100097.7205,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.79350803283978,
            "lng": -126.27600812311735
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8518408672,
                "lng": -125.6388782531
            },
            {
                "lat": 50.8545605646,
                "lng": -125.6466679773
            },
            {
                "lat": 50.8658528341,
                "lng": -125.6788934155
            },
            {
                "lat": 50.8659784447,
                "lng": -125.681014542
            },
            {
                "lat": 50.8662336573,
                "lng": -125.6867212581
            },
            {
                "lat": 50.8674125422,
                "lng": -125.6957399006
            },
            {
                "lat": 50.8584824623,
                "lng": -125.698753052
            },
            {
                "lat": 50.841796854,
                "lng": -125.6970828939
            },
            {
                "lat": 50.8278922826,
                "lng": -125.695411929
            },
            {
                "lat": 50.8156546327,
                "lng": -125.6906816223
            },
            {
                "lat": 50.8020281872,
                "lng": -125.6856761212
            },
            {
                "lat": 50.7922935211,
                "lng": -125.6859591243
            },
            {
                "lat": 50.7850605897,
                "lng": -125.6912387923
            },
            {
                "lat": 50.7783851201,
                "lng": -125.7029180356
            },
            {
                "lat": 50.7686539666,
                "lng": -125.7104342863
            },
            {
                "lat": 50.7575306799,
                "lng": -125.7171094571
            },
            {
                "lat": 50.7491840156,
                "lng": -125.7276763417
            },
            {
                "lat": 50.7425122427,
                "lng": -125.7296221267
            },
            {
                "lat": 50.7363931537,
                "lng": -125.728233364
            },
            {
                "lat": 50.7336119698,
                "lng": -125.725448693
            },
            {
                "lat": 50.7367634703,
                "lng": -125.7153017441
            },
            {
                "lat": 50.7397390457,
                "lng": -125.682472513
            },
            {
                "lat": 50.7372628856,
                "lng": -125.6817253709
            },
            {
                "lat": 50.7339137829,
                "lng": -125.6798096628
            },
            {
                "lat": 50.7313151577,
                "lng": -125.6753532654
            },
            {
                "lat": 50.7350008212,
                "lng": -125.6692732522
            },
            {
                "lat": 50.7383385041,
                "lng": -125.659813517
            },
            {
                "lat": 50.7413976202,
                "lng": -125.651474639
            },
            {
                "lat": 50.7425119212,
                "lng": -125.6425714137
            },
            {
                "lat": 50.7430649765,
                "lng": -125.6295010411
            },
            {
                "lat": 50.7461243842,
                "lng": -125.6197660104
            },
            {
                "lat": 50.7514117847,
                "lng": -125.6097558887
            },
            {
                "lat": 50.7600326772,
                "lng": -125.6036373848
            },
            {
                "lat": 50.7703214587,
                "lng": -125.5964055601
            },
            {
                "lat": 50.7789420031,
                "lng": -125.5902863422
            },
            {
                "lat": 50.7900701042,
                "lng": -125.5847243198
            },
            {
                "lat": 50.7992436314,
                "lng": -125.5833358167
            },
            {
                "lat": 50.8064762437,
                "lng": -125.5861127776
            },
            {
                "lat": 50.8131525566,
                "lng": -125.593070928
            },
            {
                "lat": 50.8206593675,
                "lng": -125.6041951507
            },
            {
                "lat": 50.826221461,
                "lng": -125.6128156829
            },
            {
                "lat": 50.8306731208,
                "lng": -125.6231072824
            },
            {
                "lat": 50.8351216157,
                "lng": -125.6325608882
            },
            {
                "lat": 50.843742316,
                "lng": -125.6370091611
            },
            {
                "lat": 50.8518408672,
                "lng": -125.6388782531
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 406,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 30,
            "MANAGEMENT_AREA_NAME": "Subarea 12-30",
            "LABEL": "12-30",
            "FISHERY_AREA_ID": 2088,
            "OBJECTID": 550,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 89472649.5456,
            "FEATURE_LENGTH_M": 42992.9604,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.790326674788865,
            "lng": -125.66038042337559
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8053892403,
                "lng": -127.4606397338
            },
            {
                "lat": 50.8213039744,
                "lng": -127.5933074393
            },
            {
                "lat": 50.8319128693,
                "lng": -127.6545861263
            },
            {
                "lat": 50.8246462471,
                "lng": -127.6545865392
            },
            {
                "lat": 50.809364368,
                "lng": -127.6545863199
            },
            {
                "lat": 50.8091315742,
                "lng": -127.6545714948
            },
            {
                "lat": 50.8088840697,
                "lng": -127.6546395862
            },
            {
                "lat": 50.8068385367,
                "lng": -127.6550974756
            },
            {
                "lat": 50.8012841488,
                "lng": -127.6564711598
            },
            {
                "lat": 50.7971223126,
                "lng": -127.6450194908
            },
            {
                "lat": 50.7893143343,
                "lng": -127.6174394072
            },
            {
                "lat": 50.7757835093,
                "lng": -127.5841291741
            },
            {
                "lat": 50.7565274627,
                "lng": -127.5404128671
            },
            {
                "lat": 50.7362325955,
                "lng": -127.5086669559
            },
            {
                "lat": 50.7268634476,
                "lng": -127.5050273462
            },
            {
                "lat": 50.7112545292,
                "lng": -127.5013815568
            },
            {
                "lat": 50.6977236029,
                "lng": -127.4909744035
            },
            {
                "lat": 50.6883543128,
                "lng": -127.4722438911
            },
            {
                "lat": 50.6919968388,
                "lng": -127.4649582002
            },
            {
                "lat": 50.700325502,
                "lng": -127.4613115929
            },
            {
                "lat": 50.712295285,
                "lng": -127.4514239773
            },
            {
                "lat": 50.7122954278,
                "lng": -127.4462201655
            },
            {
                "lat": 50.7050098567,
                "lng": -127.435814177
            },
            {
                "lat": 50.6935579929,
                "lng": -127.429046592
            },
            {
                "lat": 50.6873135753,
                "lng": -127.4165578321
            },
            {
                "lat": 50.6862718111,
                "lng": -127.401985045
            },
            {
                "lat": 50.6841923749,
                "lng": -127.3816909512
            },
            {
                "lat": 50.672740802,
                "lng": -127.3561855964
            },
            {
                "lat": 50.6612924159,
                "lng": -127.3280871184
            },
            {
                "lat": 50.6555672645,
                "lng": -127.3046643121
            },
            {
                "lat": 50.6484411745,
                "lng": -127.285736079
            },
            {
                "lat": 50.6530610043,
                "lng": -127.2841186309
            },
            {
                "lat": 50.6583252733,
                "lng": -127.2825626018
            },
            {
                "lat": 50.6589246233,
                "lng": -127.2824470117
            },
            {
                "lat": 50.7211345078,
                "lng": -127.3591996879
            },
            {
                "lat": 50.7261926976,
                "lng": -127.3654398247
            },
            {
                "lat": 50.8053892403,
                "lng": -127.4606397338
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 407,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 16,
            "MANAGEMENT_AREA_NAME": "Subarea 12-16",
            "LABEL": "12-16",
            "FISHERY_AREA_ID": 2074,
            "OBJECTID": 551,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 170527031.6981,
            "FEATURE_LENGTH_M": 75605.0974,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.73330429198378,
            "lng": -127.47842892155407
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.8047450566,
                "lng": -126.5670707791
            },
            {
                "lat": 50.808883935,
                "lng": -126.5719228415
            },
            {
                "lat": 50.8105965966,
                "lng": -126.5747759792
            },
            {
                "lat": 50.8160163704,
                "lng": -126.589469605
            },
            {
                "lat": 50.8197255903,
                "lng": -126.6055916173
            },
            {
                "lat": 50.823006141,
                "lng": -126.6249916652
            },
            {
                "lat": 50.8244322215,
                "lng": -126.637687916
            },
            {
                "lat": 50.8200110441,
                "lng": -126.6559522727
            },
            {
                "lat": 50.8187071953,
                "lng": -126.6654352455
            },
            {
                "lat": 50.8208650603,
                "lng": -126.6737829344
            },
            {
                "lat": 50.8254322208,
                "lng": -126.679633356
            },
            {
                "lat": 50.8335648561,
                "lng": -126.6861949461
            },
            {
                "lat": 50.8347057511,
                "lng": -126.6909030349
            },
            {
                "lat": 50.8351333977,
                "lng": -126.7003175646
            },
            {
                "lat": 50.8347057233,
                "lng": -126.710731977
            },
            {
                "lat": 50.8328516235,
                "lng": -126.7274244613
            },
            {
                "lat": 50.8322786859,
                "lng": -126.7458258826
            },
            {
                "lat": 50.8319932066,
                "lng": -126.7677997208
            },
            {
                "lat": 50.8309327105,
                "lng": -126.7731173404
            },
            {
                "lat": 50.8336253518,
                "lng": -126.7901917193
            },
            {
                "lat": 50.8301387684,
                "lng": -126.790192313
            },
            {
                "lat": 50.8228643852,
                "lng": -126.7884835844
            },
            {
                "lat": 50.8154447984,
                "lng": -126.7837752259
            },
            {
                "lat": 50.8057442113,
                "lng": -126.7810663657
            },
            {
                "lat": 50.7984694081,
                "lng": -126.7876290603
            },
            {
                "lat": 50.7934764091,
                "lng": -126.7967604566
            },
            {
                "lat": 50.7926219127,
                "lng": -126.8044586952
            },
            {
                "lat": 50.79333096,
                "lng": -126.8087381209
            },
            {
                "lat": 50.8057443205,
                "lng": -126.8117374487
            },
            {
                "lat": 50.8165859127,
                "lng": -126.8143086164
            },
            {
                "lat": 50.8240052942,
                "lng": -126.8148724188
            },
            {
                "lat": 50.8288572736,
                "lng": -126.8133083256
            },
            {
                "lat": 50.8322789079,
                "lng": -126.8144449951
            },
            {
                "lat": 50.8337324321,
                "lng": -126.8168105244
            },
            {
                "lat": 50.8327864634,
                "lng": -126.8216560164
            },
            {
                "lat": 50.8307151102,
                "lng": -126.8280495641
            },
            {
                "lat": 50.8291587021,
                "lng": -126.8423912407
            },
            {
                "lat": 50.826568441,
                "lng": -126.8534544847
            },
            {
                "lat": 50.8270828064,
                "lng": -126.8679655735
            },
            {
                "lat": 50.830025078,
                "lng": -126.8944094814
            },
            {
                "lat": 50.8282958431,
                "lng": -126.8994139498
            },
            {
                "lat": 50.8220748312,
                "lng": -126.8952707045
            },
            {
                "lat": 50.8155057749,
                "lng": -126.8831707399
            },
            {
                "lat": 50.8101504712,
                "lng": -126.8650287399
            },
            {
                "lat": 50.8047943912,
                "lng": -126.8432543183
            },
            {
                "lat": 50.8031841971,
                "lng": -126.8358689857
            },
            {
                "lat": 50.801856937,
                "lng": -126.8297805893
            },
            {
                "lat": 50.7974858647,
                "lng": -126.8257829983
            },
            {
                "lat": 50.7920219867,
                "lng": -126.8258517292
            },
            {
                "lat": 50.7903131273,
                "lng": -126.8275989961
            },
            {
                "lat": 50.7903519444,
                "lng": -126.8339385744
            },
            {
                "lat": 50.7900504477,
                "lng": -126.8385240499
            },
            {
                "lat": 50.7877731592,
                "lng": -126.8409573914
            },
            {
                "lat": 50.7856485433,
                "lng": -126.8434598849
            },
            {
                "lat": 50.7833214183,
                "lng": -126.8385542226
            },
            {
                "lat": 50.7838862034,
                "lng": -126.8203049762
            },
            {
                "lat": 50.7853282679,
                "lng": -126.819099231
            },
            {
                "lat": 50.7891270757,
                "lng": -126.8138812975
            },
            {
                "lat": 50.7900769499,
                "lng": -126.8062822522
            },
            {
                "lat": 50.7891269315,
                "lng": -126.8020095094
            },
            {
                "lat": 50.7862781243,
                "lng": -126.798919343
            },
            {
                "lat": 50.7846144984,
                "lng": -126.7969062202
            },
            {
                "lat": 50.7849384629,
                "lng": -126.7864304751
            },
            {
                "lat": 50.7893632312,
                "lng": -126.7839584959
            },
            {
                "lat": 50.7964904731,
                "lng": -126.7784960058
            },
            {
                "lat": 50.8007626067,
                "lng": -126.7694782623
            },
            {
                "lat": 50.8033751797,
                "lng": -126.7604528068
            },
            {
                "lat": 50.8000493517,
                "lng": -126.7457274803
            },
            {
                "lat": 50.7945902967,
                "lng": -126.734565549
            },
            {
                "lat": 50.7893634924,
                "lng": -126.7250752607
            },
            {
                "lat": 50.786979162,
                "lng": -126.7205816941
            },
            {
                "lat": 50.7872387667,
                "lng": -126.7121274158
            },
            {
                "lat": 50.7893635754,
                "lng": -126.7065500444
            },
            {
                "lat": 50.7960131044,
                "lng": -126.6996614947
            },
            {
                "lat": 50.7993390842,
                "lng": -126.6835169793
            },
            {
                "lat": 50.7998130099,
                "lng": -126.6657099745
            },
            {
                "lat": 50.8002886422,
                "lng": -126.6478955609
            },
            {
                "lat": 50.8045614167,
                "lng": -126.6376880373
            },
            {
                "lat": 50.8102609446,
                "lng": -126.6220171191
            },
            {
                "lat": 50.8097838579,
                "lng": -126.6039660999
            },
            {
                "lat": 50.8066976899,
                "lng": -126.5854491754
            },
            {
                "lat": 50.8052749496,
                "lng": -126.5749969756
            },
            {
                "lat": 50.8047450566,
                "lng": -126.5670707791
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 408,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 552,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 60875254.2594,
            "FEATURE_LENGTH_M": 65251.9991,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.80882414068194,
            "lng": -126.75504346699276
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.7865366748,
                "lng": -128.4310303619
            },
            {
                "lat": 50.7820011001,
                "lng": -128.6186517436
            },
            {
                "lat": 50.799766119,
                "lng": -128.6880192463
            },
            {
                "lat": 50.5000004886,
                "lng": -128.3500060056
            },
            {
                "lat": 50.5000003868,
                "lng": -128.1734471662
            },
            {
                "lat": 50.5404629172,
                "lng": -128.2190095719
            },
            {
                "lat": 50.6085704772,
                "lng": -128.303574054
            },
            {
                "lat": 50.6614191461,
                "lng": -128.367660771
            },
            {
                "lat": 50.6707230787,
                "lng": -128.3629301609
            },
            {
                "lat": 50.6712983667,
                "lng": -128.3629002361
            },
            {
                "lat": 50.6721608008,
                "lng": -128.362945619
            },
            {
                "lat": 50.672668608,
                "lng": -128.3629457832
            },
            {
                "lat": 50.673893292,
                "lng": -128.363418365
            },
            {
                "lat": 50.6781122794,
                "lng": -128.3656007069
            },
            {
                "lat": 50.6859318331,
                "lng": -128.3706964282
            },
            {
                "lat": 50.6895372318,
                "lng": -128.3732753723
            },
            {
                "lat": 50.6915782169,
                "lng": -128.3759304487
            },
            {
                "lat": 50.6919868159,
                "lng": -128.3775019973
            },
            {
                "lat": 50.6919480143,
                "lng": -128.3778993709
            },
            {
                "lat": 50.6919785218,
                "lng": -128.378128611
            },
            {
                "lat": 50.6919782025,
                "lng": -128.378433264
            },
            {
                "lat": 50.6919817767,
                "lng": -128.3788452822
            },
            {
                "lat": 50.6919903199,
                "lng": -128.37899807
            },
            {
                "lat": 50.7417600198,
                "lng": -128.4173130282
            },
            {
                "lat": 50.7666287756,
                "lng": -128.4190974796
            },
            {
                "lat": 50.767200328,
                "lng": -128.4185640991
            },
            {
                "lat": 50.7684213946,
                "lng": -128.4170082238
            },
            {
                "lat": 50.7709312098,
                "lng": -128.4157258178
            },
            {
                "lat": 50.7737124398,
                "lng": -128.415008904
            },
            {
                "lat": 50.7742993489,
                "lng": -128.4192197837
            },
            {
                "lat": 50.7749254606,
                "lng": -128.4216919688
            },
            {
                "lat": 50.7765541326,
                "lng": -128.4233089481
            },
            {
                "lat": 50.7798916853,
                "lng": -128.4259494696
            },
            {
                "lat": 50.7844888372,
                "lng": -128.4295808197
            },
            {
                "lat": 50.7865366748,
                "lng": -128.4310303619
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 409,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 127,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 127-3",
            "LABEL": "127-3",
            "FISHERY_AREA_ID": 2341,
            "OBJECTID": 553,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 416105410.5208,
            "FEATURE_LENGTH_M": 110268.1817,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.705767856437156,
            "lng": -128.39358135830005
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.7794423124,
                "lng": -126.1230471634
            },
            {
                "lat": 50.7691616607,
                "lng": -126.1193701983
            },
            {
                "lat": 50.753917345,
                "lng": -126.1116179947
            },
            {
                "lat": 50.749279473,
                "lng": -126.1068275159
            },
            {
                "lat": 50.7506596916,
                "lng": -126.1016236206
            },
            {
                "lat": 50.7545891624,
                "lng": -126.0878675442
            },
            {
                "lat": 50.7614669029,
                "lng": -126.0741192389
            },
            {
                "lat": 50.7649043313,
                "lng": -126.0637968211
            },
            {
                "lat": 50.7673607813,
                "lng": -126.0495535043
            },
            {
                "lat": 50.7678533695,
                "lng": -126.0372692684
            },
            {
                "lat": 50.7693246673,
                "lng": -126.0299073666
            },
            {
                "lat": 50.7752223385,
                "lng": -126.0195923483
            },
            {
                "lat": 50.7806238624,
                "lng": -126.0097658658
            },
            {
                "lat": 50.7884864595,
                "lng": -126.0048523023
            },
            {
                "lat": 50.798801268,
                "lng": -125.999939665
            },
            {
                "lat": 50.808136323,
                "lng": -125.9999396524
            },
            {
                "lat": 50.8159942961,
                "lng": -126.005340805
            },
            {
                "lat": 50.8204160548,
                "lng": -126.0107492503
            },
            {
                "lat": 50.820416033,
                "lng": -126.0156556202
            },
            {
                "lat": 50.818450678,
                "lng": -126.0230252428
            },
            {
                "lat": 50.8145215842,
                "lng": -126.0313799012
            },
            {
                "lat": 50.8086243264,
                "lng": -126.0382536952
            },
            {
                "lat": 50.8017500276,
                "lng": -126.0500494222
            },
            {
                "lat": 50.7978172896,
                "lng": -126.0642926561
            },
            {
                "lat": 50.792415584,
                "lng": -126.0790249535
            },
            {
                "lat": 50.7899591198,
                "lng": -126.0981820931
            },
            {
                "lat": 50.7865181075,
                "lng": -126.1124345648
            },
            {
                "lat": 50.7794423124,
                "lng": -126.1230471634
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 410,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 36,
            "MANAGEMENT_AREA_NAME": "Subarea 12-36",
            "LABEL": "12-36",
            "FISHERY_AREA_ID": 2094,
            "OBJECTID": 554,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 33802403.482,
            "FEATURE_LENGTH_M": 25618.2717,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.78519840579286,
            "lng": -126.05680447992857
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.7979246231,
                "lng": -126.4220119508
            },
            {
                "lat": 50.7964102224,
                "lng": -126.4329986828
            },
            {
                "lat": 50.7954064189,
                "lng": -126.4373550264
            },
            {
                "lat": 50.7951052047,
                "lng": -126.4393004279
            },
            {
                "lat": 50.7947007258,
                "lng": -126.4409484562
            },
            {
                "lat": 50.7944869226,
                "lng": -126.4414981969
            },
            {
                "lat": 50.8170584767,
                "lng": -126.5076287844
            },
            {
                "lat": 50.7928315893,
                "lng": -126.5316932603
            },
            {
                "lat": 50.7757646231,
                "lng": -126.4781726692
            },
            {
                "lat": 50.7739525092,
                "lng": -126.4724955
            },
            {
                "lat": 50.7739186718,
                "lng": -126.471336094
            },
            {
                "lat": 50.7738033678,
                "lng": -126.4700543357
            },
            {
                "lat": 50.7733343023,
                "lng": -126.4690624057
            },
            {
                "lat": 50.7724611224,
                "lng": -126.4660337959
            },
            {
                "lat": 50.7718196395,
                "lng": -126.4616624702
            },
            {
                "lat": 50.7705960352,
                "lng": -126.4560093059
            },
            {
                "lat": 50.7675053103,
                "lng": -126.4562376633
            },
            {
                "lat": 50.765724235,
                "lng": -126.4356458817
            },
            {
                "lat": 50.7674294371,
                "lng": -126.4013676751
            },
            {
                "lat": 50.7719731344,
                "lng": -126.3685990688
            },
            {
                "lat": 50.7810666316,
                "lng": -126.3540118798
            },
            {
                "lat": 50.7901573167,
                "lng": -126.3506008908
            },
            {
                "lat": 50.7950815537,
                "lng": -126.3560949261
            },
            {
                "lat": 50.7952725618,
                "lng": -126.3924636819
            },
            {
                "lat": 50.7967870605,
                "lng": -126.4096976732
            },
            {
                "lat": 50.7979246231,
                "lng": -126.4220119508
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 411,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 12,
            "MANAGEMENT_SUBAREA": 38,
            "MANAGEMENT_AREA_NAME": "Subarea 12-38",
            "LABEL": "12-38",
            "FISHERY_AREA_ID": 2096,
            "OBJECTID": 555,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 37377625.43,
            "FEATURE_LENGTH_M": 30227.4565,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.784557550730774,
            "lng": -126.4363458713
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.7979246231,
                "lng": -126.4220119508
            },
            {
                "lat": 50.7967870605,
                "lng": -126.4096976732
            },
            {
                "lat": 50.7952725618,
                "lng": -126.3924636819
            },
            {
                "lat": 50.7950815537,
                "lng": -126.3560949261
            },
            {
                "lat": 50.7901573167,
                "lng": -126.3506008908
            },
            {
                "lat": 50.7810666316,
                "lng": -126.3540118798
            },
            {
                "lat": 50.7719731344,
                "lng": -126.3685990688
            },
            {
                "lat": 50.7674294371,
                "lng": -126.4013676751
            },
            {
                "lat": 50.765724235,
                "lng": -126.4356458817
            },
            {
                "lat": 50.7675053103,
                "lng": -126.4562376633
            },
            {
                "lat": 50.7644724734,
                "lng": -126.4554833119
            },
            {
                "lat": 50.75975452,
                "lng": -126.457168523
            },
            {
                "lat": 50.7584724593,
                "lng": -126.4593282669
            },
            {
                "lat": 50.7588811987,
                "lng": -126.4664989897
            },
            {
                "lat": 50.7594608527,
                "lng": -126.4738994827
            },
            {
                "lat": 50.7599870684,
                "lng": -126.4788589836
            },
            {
                "lat": 50.7585867983,
                "lng": -126.482818231
            },
            {
                "lat": 50.7528996537,
                "lng": -126.4854888465
            },
            {
                "lat": 50.7488098797,
                "lng": -126.4897529173
            },
            {
                "lat": 50.7441906337,
                "lng": -126.4934839785
            },
            {
                "lat": 50.740989447,
                "lng": -126.4922409138
            },
            {
                "lat": 50.7408097127,
                "lng": -126.4872667408
            },
            {
                "lat": 50.7401011865,
                "lng": -126.4849550807
            },
            {
                "lat": 50.7385027841,
                "lng": -126.4812161464
            },
            {
                "lat": 50.7354815642,
                "lng": -126.4785539435
            },
            {
                "lat": 50.7333452723,
                "lng": -126.4782024365
            },
            {
                "lat": 50.730857714,
                "lng": -126.4782024967
            },
            {
                "lat": 50.7267728823,
                "lng": -126.4817511864
            },
            {
                "lat": 50.7260590199,
                "lng": -126.4844205944
            },
            {
                "lat": 50.7289043712,
                "lng": -126.4917069902
            },
            {
                "lat": 50.7310371012,
                "lng": -126.4979241139
            },
            {
                "lat": 50.7340577341,
                "lng": -126.5105435869
            },
            {
                "lat": 50.7370802371,
                "lng": -126.5187219654
            },
            {
                "lat": 50.7368168096,
                "lng": -126.5205610805
            },
            {
                "lat": 50.705959957,
                "lng": -126.5605009396
            },
            {
                "lat": 50.7056198786,
                "lng": -126.5580067646
            },
            {
                "lat": 50.7034870161,
                "lng": -126.5521391089
            },
            {
                "lat": 50.7017096006,
                "lng": -126.5489422475
            },
            {
                "lat": 50.6983337352,
                "lng": -126.5476907408
            },
            {
                "lat": 50.6954884651,
                "lng": -126.5489423119
            },
            {
                "lat": 50.6940643878,
                "lng": -126.5519630312
            },
            {
                "lat": 50.6919366681,
                "lng": -126.5565788356
            },
            {
                "lat": 50.6901555578,
                "lng": -126.561561453
            },
            {
                "lat": 50.689266073,
                "lng": -126.5649342377
            },
            {
                "lat": 50.6885569884,
                "lng": -126.5727542812
            },
            {
                "lat": 50.6896242528,
                "lng": -126.5775521454
            },
            {
                "lat": 50.6888688875,
                "lng": -126.5792232971
            },
            {
                "lat": 50.6876756178,
                "lng": -126.5783998031
            },
            {
                "lat": 50.6865649203,
                "lng": -126.5765914093
            },
            {
                "lat": 50.6863589576,
                "lng": -126.5746150205
            },
            {
                "lat": 50.6857409025,
                "lng": -126.5719766699
            },
            {
                "lat": 50.6848341925,
                "lng": -126.5699994174
            },
            {
                "lat": 50.6832691703,
                "lng": -126.5688484903
            },
            {
                "lat": 50.6814159669,
                "lng": -126.568389602
            },
            {
                "lat": 50.6797673622,
                "lng": -126.568763428
            },
            {
                "lat": 50.6782462713,
                "lng": -126.5693441454
            },
            {
                "lat": 50.6772960466,
                "lng": -126.570167453
            },
            {
                "lat": 50.6763078337,
                "lng": -126.5719379514
            },
            {
                "lat": 50.6758155211,
                "lng": -126.5749818676
            },
            {
                "lat": 50.6762695733,
                "lng": -126.5776211078
            },
            {
                "lat": 50.6772578985,
                "lng": -126.58050446
            },
            {
                "lat": 50.6793556858,
                "lng": -126.5837934996
            },
            {
                "lat": 50.6814996405,
                "lng": -126.5879976414
            },
            {
                "lat": 50.6820750846,
                "lng": -126.5905539874
            },
            {
                "lat": 50.6818805058,
                "lng": -126.5916745012
            },
            {
                "lat": 50.6800958084,
                "lng": -126.5939784477
            },
            {
                "lat": 50.6793973353,
                "lng": -126.5933532836
            },
            {
                "lat": 50.677422067,
                "lng": -126.5932696618
            },
            {
                "lat": 50.6752775622,
                "lng": -126.5932316096
            },
            {
                "lat": 50.6731380398,
                "lng": -126.5942616076
            },
            {
                "lat": 50.6716536107,
                "lng": -126.5954134437
            },
            {
                "lat": 50.671203997,
                "lng": -126.597389415
            },
            {
                "lat": 50.6717370366,
                "lng": -126.6000677121
            },
            {
                "lat": 50.6719015752,
                "lng": -126.6022491915
            },
            {
                "lat": 50.6707267932,
                "lng": -126.6016689504
            },
            {
                "lat": 50.6700902151,
                "lng": -126.6017533178
            },
            {
                "lat": 50.6677011439,
                "lng": -126.6036455941
            },
            {
                "lat": 50.6660723459,
                "lng": -126.6051020191
            },
            {
                "lat": 50.6639139031,
                "lng": -126.6085505072
            },
            {
                "lat": 50.6639516681,
                "lng": -126.6045992853
            },
            {
                "lat": 50.6638678159,
                "lng": -126.6022485348
            },
            {
                "lat": 50.6642832437,
                "lng": -126.6011128988
            },
            {
                "lat": 50.665351794,
                "lng": -126.5981670712
            },
            {
                "lat": 50.6658864123,
                "lng": -126.5946805721
            },
            {
                "lat": 50.6656031997,
                "lng": -126.5890122113
            },
            {
                "lat": 50.6630632475,
                "lng": -126.5686643049
            },
            {
                "lat": 50.6635709044,
                "lng": -126.5330588371
            },
            {
                "lat": 50.6681479558,
                "lng": -126.523902297
            },
            {
                "lat": 50.6767954384,
                "lng": -126.5162736155
            },
            {
                "lat": 50.681880299,
                "lng": -126.5010137451
            },
            {
                "lat": 50.6864593038,
                "lng": -126.4842305904
            },
            {
                "lat": 50.6890030949,
                "lng": -126.4618536212
            },
            {
                "lat": 50.687476449,
                "lng": -126.4542234108
            },
            {
                "lat": 50.6813741225,
                "lng": -126.4425286714
            },
            {
                "lat": 50.6722179714,
                "lng": -126.4221801866
            },
            {
                "lat": 50.6666219971,
                "lng": -126.4038694904
            },
            {
                "lat": 50.6640782709,
                "lng": -126.3860699244
            },
            {
                "lat": 50.6610257559,
                "lng": -126.3743673517
            },
            {
                "lat": 50.6576046411,
                "lng": -126.3675228648
            },
            {
                "lat": 50.6570364114,
                "lng": -126.3064034768
            },
            {
                "lat": 50.6594998359,
                "lng": -126.3021461929
            },
            {
                "lat": 50.6610261112,
                "lng": -126.2980732965
            },
            {
                "lat": 50.6625521581,
                "lng": -126.2914657613
            },
            {
                "lat": 50.662044693,
                "lng": -126.286888267
            },
            {
                "lat": 50.656959732,
                "lng": -126.280273459
            },
            {
                "lat": 50.6567918738,
                "lng": -126.2800451072
            },
            {
                "lat": 50.6566698728,
                "lng": -126.2670672618
            },
            {
                "lat": 50.6687933275,
                "lng": -126.2824095738
            },
            {
                "lat": 50.6741829267,
                "lng": -126.2745897554
            },
            {
                "lat": 50.6876335979,
                "lng": -126.2772759406
            },
            {
                "lat": 50.7055628459,
                "lng": -126.2763826052
            },
            {
                "lat": 50.713634086,
                "lng": -126.2674177618
            },
            {
                "lat": 50.7225990468,
                "lng": -126.2566522939
            },
            {
                "lat": 50.7297704674,
                "lng": -126.250381151
            },
            {
                "lat": 50.7360462202,
                "lng": -126.2351381303
            },
            {
                "lat": 50.7369426708,
                "lng": -126.2046579361
            },
            {
                "lat": 50.745010182,
                "lng": -126.1965867312
            },
            {
                "lat": 50.7530827107,
                "lng": -126.192099825
            },
            {
                "lat": 50.7548754435,
                "lng": -126.2073440813
            },
            {
                "lat": 50.7728043459,
                "lng": -126.2297594285
            },
            {
                "lat": 50.7853583847,
                "lng": -126.2360311647
            },
            {
                "lat": 50.800598547,
                "lng": -126.2378302173
            },
            {
                "lat": 50.809562724,
                "lng": -126.2503809062
            },
            {
                "lat": 50.8077695717,
                "lng": -126.2718963119
            },
            {
                "lat": 50.8077696231,
                "lng": -126.2978972493
            },
            {
                "lat": 50.8140444974,
                "lng": -126.3140336649
            },
            {
                "lat": 50.8176345543,
                "lng": -126.3346552834
            },
            {
                "lat": 50.8077696516,
                "lng": -126.3723149383
            },
            {
                "lat": 50.8059769747,
                "lng": -126.4010005645
            },
            {
                "lat": 50.7979246231,
                "lng": -126.4220119508
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 412,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 556,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 266825787.6447,
            "FEATURE_LENGTH_M": 101554.7028,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.71174214298078,
            "lng": -126.46344118832612
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.7884637009,
                "lng": -128.3267977474
            },
            {
                "lat": 50.7873960872,
                "lng": -128.3376465363
            },
            {
                "lat": 50.7843851487,
                "lng": -128.3526919268
            },
            {
                "lat": 50.7801749019,
                "lng": -128.3617246083
            },
            {
                "lat": 50.7783703354,
                "lng": -128.368942271
            },
            {
                "lat": 50.7777672642,
                "lng": -128.3809652025
            },
            {
                "lat": 50.7747578364,
                "lng": -128.3936153035
            },
            {
                "lat": 50.7745471046,
                "lng": -128.4042207006
            },
            {
                "lat": 50.7745471576,
                "lng": -128.4072721614
            },
            {
                "lat": 50.7742572517,
                "lng": -128.4096221429
            },
            {
                "lat": 50.7737124398,
                "lng": -128.415008904
            },
            {
                "lat": 50.7709312098,
                "lng": -128.4157258178
            },
            {
                "lat": 50.7684213946,
                "lng": -128.4170082238
            },
            {
                "lat": 50.767200328,
                "lng": -128.4185640991
            },
            {
                "lat": 50.7666287756,
                "lng": -128.4190974796
            },
            {
                "lat": 50.7417600198,
                "lng": -128.4173130282
            },
            {
                "lat": 50.6919903199,
                "lng": -128.37899807
            },
            {
                "lat": 50.6919817767,
                "lng": -128.3788452822
            },
            {
                "lat": 50.6919782025,
                "lng": -128.378433264
            },
            {
                "lat": 50.6919785218,
                "lng": -128.378128611
            },
            {
                "lat": 50.6919480143,
                "lng": -128.3778993709
            },
            {
                "lat": 50.6919868159,
                "lng": -128.3775019973
            },
            {
                "lat": 50.6915782169,
                "lng": -128.3759304487
            },
            {
                "lat": 50.6895372318,
                "lng": -128.3732753723
            },
            {
                "lat": 50.6859318331,
                "lng": -128.3706964282
            },
            {
                "lat": 50.6781122794,
                "lng": -128.3656007069
            },
            {
                "lat": 50.673893292,
                "lng": -128.363418365
            },
            {
                "lat": 50.672668608,
                "lng": -128.3629457832
            },
            {
                "lat": 50.6721608008,
                "lng": -128.362945619
            },
            {
                "lat": 50.6712983667,
                "lng": -128.3629002361
            },
            {
                "lat": 50.6707230787,
                "lng": -128.3629301609
            },
            {
                "lat": 50.6614191461,
                "lng": -128.367660771
            },
            {
                "lat": 50.6085704772,
                "lng": -128.303574054
            },
            {
                "lat": 50.5404629172,
                "lng": -128.2190095719
            },
            {
                "lat": 50.5395361379,
                "lng": -128.2154228996
            },
            {
                "lat": 50.5390135711,
                "lng": -128.2121271917
            },
            {
                "lat": 50.5387388013,
                "lng": -128.2093044125
            },
            {
                "lat": 50.5415802954,
                "lng": -128.202651859
            },
            {
                "lat": 50.5785447258,
                "lng": -128.2112726193
            },
            {
                "lat": 50.594821626,
                "lng": -128.2135617041
            },
            {
                "lat": 50.6059347494,
                "lng": -128.227614812
            },
            {
                "lat": 50.6156614086,
                "lng": -128.2458495694
            },
            {
                "lat": 50.6229555064,
                "lng": -128.2653042063
            },
            {
                "lat": 50.6302489655,
                "lng": -128.2829283771
            },
            {
                "lat": 50.6399769873,
                "lng": -128.2871856822
            },
            {
                "lat": 50.6484865576,
                "lng": -128.2634735234
            },
            {
                "lat": 50.6630748028,
                "lng": -128.2440331488
            },
            {
                "lat": 50.6825289419,
                "lng": -128.2440333546
            },
            {
                "lat": 50.6928633048,
                "lng": -128.2519379873
            },
            {
                "lat": 50.6971171406,
                "lng": -128.2701723303
            },
            {
                "lat": 50.6959005499,
                "lng": -128.3023833308
            },
            {
                "lat": 50.6959004416,
                "lng": -128.3248743774
            },
            {
                "lat": 50.7123152578,
                "lng": -128.3461609415
            },
            {
                "lat": 50.7354162513,
                "lng": -128.3461605142
            },
            {
                "lat": 50.7682420344,
                "lng": -128.3309631123
            },
            {
                "lat": 50.7884637009,
                "lng": -128.3267977474
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 413,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 27,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 27-1",
            "LABEL": "27-1",
            "FISHERY_AREA_ID": 2501,
            "OBJECTID": 557,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 135264937.5769,
            "FEATURE_LENGTH_M": 75757.5374,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.690693975241075,
            "lng": -128.33091292798213
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.345978164,
                "lng": -124.9518572967
            },
            {
                "lat": 50.3460768996,
                "lng": -124.9648585359
            },
            {
                "lat": 50.3313631443,
                "lng": -124.9654987956
            },
            {
                "lat": 50.3121840319,
                "lng": -124.9663318464
            },
            {
                "lat": 50.3115540191,
                "lng": -124.9676822755
            },
            {
                "lat": 50.311091781,
                "lng": -124.9688339726
            },
            {
                "lat": 50.3071022437,
                "lng": -124.9736557998
            },
            {
                "lat": 50.2912944039,
                "lng": -124.9926679988
            },
            {
                "lat": 50.281169836,
                "lng": -125.0005722904
            },
            {
                "lat": 50.2777098902,
                "lng": -125.0020520335
            },
            {
                "lat": 50.2757296342,
                "lng": -125.0028534615
            },
            {
                "lat": 50.2743793134,
                "lng": -125.0039286645
            },
            {
                "lat": 50.2714351604,
                "lng": -125.0039301972
            },
            {
                "lat": 50.2380715987,
                "lng": -125.0039299314
            },
            {
                "lat": 50.2375873429,
                "lng": -125.0039300449
            },
            {
                "lat": 50.2369995705,
                "lng": -125.0023584555
            },
            {
                "lat": 50.235492946,
                "lng": -125.0005652667
            },
            {
                "lat": 50.2308200605,
                "lng": -124.9927818346
            },
            {
                "lat": 50.2227292604,
                "lng": -124.9820783853
            },
            {
                "lat": 50.2086908534,
                "lng": -124.9689945523
            },
            {
                "lat": 50.1898917606,
                "lng": -124.9535208171
            },
            {
                "lat": 50.178947719,
                "lng": -124.9404372801
            },
            {
                "lat": 50.1706207741,
                "lng": -124.9299690751
            },
            {
                "lat": 50.1558682798,
                "lng": -124.9173583443
            },
            {
                "lat": 50.1503946539,
                "lng": -124.9187850197
            },
            {
                "lat": 50.145874299,
                "lng": -124.928543239
            },
            {
                "lat": 50.1406368202,
                "lng": -124.9361578914
            },
            {
                "lat": 50.1356388512,
                "lng": -124.9354405792
            },
            {
                "lat": 50.1332094673,
                "lng": -124.935278839
            },
            {
                "lat": 50.0693285284,
                "lng": -124.893256763
            },
            {
                "lat": 50.0668758604,
                "lng": -124.8897557413
            },
            {
                "lat": 50.0647315577,
                "lng": -124.8864204262
            },
            {
                "lat": 50.0635417816,
                "lng": -124.8852376956
            },
            {
                "lat": 50.0602112589,
                "lng": -124.8852388654
            },
            {
                "lat": 50.0580901281,
                "lng": -124.8852540949
            },
            {
                "lat": 50.0574033902,
                "lng": -124.8854140585
            },
            {
                "lat": 50.0591770976,
                "lng": -124.8714448803
            },
            {
                "lat": 50.0627173514,
                "lng": -124.8435434708
            },
            {
                "lat": 50.076133483,
                "lng": -124.8050685797
            },
            {
                "lat": 50.0723042326,
                "lng": -124.8011695607
            },
            {
                "lat": 50.069122449,
                "lng": -124.7973484025
            },
            {
                "lat": 50.0668910049,
                "lng": -124.7941661965
            },
            {
                "lat": 50.0637094356,
                "lng": -124.7900316577
            },
            {
                "lat": 50.0637090954,
                "lng": -124.7842937442
            },
            {
                "lat": 50.0630727758,
                "lng": -124.7709282141
            },
            {
                "lat": 50.0723042049,
                "lng": -124.7480006406
            },
            {
                "lat": 50.0799445579,
                "lng": -124.7301792013
            },
            {
                "lat": 50.0824930003,
                "lng": -124.7257146397
            },
            {
                "lat": 50.0835188394,
                "lng": -124.7238987942
            },
            {
                "lat": 50.0843194969,
                "lng": -124.7222446677
            },
            {
                "lat": 50.0875780585,
                "lng": -124.7164925659
            },
            {
                "lat": 50.0882226742,
                "lng": -124.7085266324
            },
            {
                "lat": 50.0934873003,
                "lng": -124.6957019998
            },
            {
                "lat": 50.1018144826,
                "lng": -124.6915363615
            },
            {
                "lat": 50.1172823624,
                "lng": -124.6713098313
            },
            {
                "lat": 50.1369135126,
                "lng": -124.6463232222
            },
            {
                "lat": 50.1660617724,
                "lng": -124.6112286372
            },
            {
                "lat": 50.1969944569,
                "lng": -124.589812626
            },
            {
                "lat": 50.2344708491,
                "lng": -124.5689916501
            },
            {
                "lat": 50.2654038311,
                "lng": -124.5761331268
            },
            {
                "lat": 50.2933619963,
                "lng": -124.6023026436
            },
            {
                "lat": 50.3171539107,
                "lng": -124.6368028903
            },
            {
                "lat": 50.3260802346,
                "lng": -124.6725006298
            },
            {
                "lat": 50.328972095,
                "lng": -124.7002712934
            },
            {
                "lat": 50.3266522646,
                "lng": -124.7172848476
            },
            {
                "lat": 50.3250047183,
                "lng": -124.7302471539
            },
            {
                "lat": 50.3251954467,
                "lng": -124.7341460097
            },
            {
                "lat": 50.3252027023,
                "lng": -124.7358616887
            },
            {
                "lat": 50.3445017535,
                "lng": -124.7533180814
            },
            {
                "lat": 50.3575858317,
                "lng": -124.7605905082
            },
            {
                "lat": 50.3540118302,
                "lng": -124.7773905314
            },
            {
                "lat": 50.347819628,
                "lng": -124.7904586765
            },
            {
                "lat": 50.3448370751,
                "lng": -124.7984765624
            },
            {
                "lat": 50.345978164,
                "lng": -124.9518572967
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 482,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 15,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 15-5",
            "LABEL": "15-5",
            "FISHERY_AREA_ID": 2168,
            "OBJECTID": 626,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 717957691.3848,
            "FEATURE_LENGTH_M": 107398.7596,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.196469314328375,
            "lng": -124.83879768223923
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.3246462996,
                "lng": -127.9748763589
            },
            {
                "lat": 50.3246231126,
                "lng": -127.9750738497
            },
            {
                "lat": 50.3247266265,
                "lng": -127.9755702305
            },
            {
                "lat": 50.3248822952,
                "lng": -127.9761347721
            },
            {
                "lat": 50.3252330443,
                "lng": -127.976630527
            },
            {
                "lat": 50.1138459633,
                "lng": -127.9405362519
            },
            {
                "lat": 50.1092298074,
                "lng": -127.9392396991
            },
            {
                "lat": 50.129184781,
                "lng": -127.9204938298
            },
            {
                "lat": 50.2307779828,
                "lng": -127.8082502269
            },
            {
                "lat": 50.2473638062,
                "lng": -127.7900774322
            },
            {
                "lat": 50.2517583532,
                "lng": -127.790115843
            },
            {
                "lat": 50.2541308456,
                "lng": -127.7972334878
            },
            {
                "lat": 50.2643360746,
                "lng": -127.8159870416
            },
            {
                "lat": 50.2729948254,
                "lng": -127.8330688713
            },
            {
                "lat": 50.2814210696,
                "lng": -127.84612211
            },
            {
                "lat": 50.2855525236,
                "lng": -127.8500593927
            },
            {
                "lat": 50.2880523672,
                "lng": -127.854919684
            },
            {
                "lat": 50.288970965,
                "lng": -127.857200426
            },
            {
                "lat": 50.2889018186,
                "lng": -127.8589404996
            },
            {
                "lat": 50.288356913,
                "lng": -127.860603113
            },
            {
                "lat": 50.2879148518,
                "lng": -127.8617626732
            },
            {
                "lat": 50.287761136,
                "lng": -127.8620152498
            },
            {
                "lat": 50.287703913,
                "lng": -127.8622436937
            },
            {
                "lat": 50.287735456,
                "lng": -127.8624722891
            },
            {
                "lat": 50.2878195321,
                "lng": -127.8628846958
            },
            {
                "lat": 50.2879254809,
                "lng": -127.8631663665
            },
            {
                "lat": 50.2880521665,
                "lng": -127.863350602
            },
            {
                "lat": 50.2881431463,
                "lng": -127.8635868958
            },
            {
                "lat": 50.2880977218,
                "lng": -127.8638149545
            },
            {
                "lat": 50.2879487289,
                "lng": -127.8641203745
            },
            {
                "lat": 50.2877844288,
                "lng": -127.8643338807
            },
            {
                "lat": 50.2877423086,
                "lng": -127.8645322353
            },
            {
                "lat": 50.2877191696,
                "lng": -127.8647383912
            },
            {
                "lat": 50.2877191076,
                "lng": -127.8648752905
            },
            {
                "lat": 50.3246462996,
                "lng": -127.9748763589
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 483,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 27,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 27-4",
            "LABEL": "27-4",
            "FISHERY_AREA_ID": 2504,
            "OBJECTID": 627,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 155961260.4648,
            "FEATURE_LENGTH_M": 59356.3705,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.27353436920571,
            "lng": -127.87725450281712
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.3365820812,
                "lng": -125.4016107395
            },
            {
                "lat": 50.3364830271,
                "lng": -125.4063721516
            },
            {
                "lat": 50.3302424196,
                "lng": -125.4395908845
            },
            {
                "lat": 50.3305203414,
                "lng": -125.4409028812
            },
            {
                "lat": 50.3304866011,
                "lng": -125.4420079825
            },
            {
                "lat": 50.3299711961,
                "lng": -125.4439470776
            },
            {
                "lat": 50.3296773757,
                "lng": -125.4500052571
            },
            {
                "lat": 50.3284261744,
                "lng": -125.4545891462
            },
            {
                "lat": 50.3256449815,
                "lng": -125.4580689129
            },
            {
                "lat": 50.323971215,
                "lng": -125.4573900546
            },
            {
                "lat": 50.3218882419,
                "lng": -125.4565361805
            },
            {
                "lat": 50.3182756628,
                "lng": -125.4498671822
            },
            {
                "lat": 50.3146590419,
                "lng": -125.4458318691
            },
            {
                "lat": 50.3099324534,
                "lng": -125.4441605632
            },
            {
                "lat": 50.3036761583,
                "lng": -125.4445804673
            },
            {
                "lat": 50.2974167176,
                "lng": -125.4441605318
            },
            {
                "lat": 50.2933837309,
                "lng": -125.4413829861
            },
            {
                "lat": 50.2879639418,
                "lng": -125.4404066626
            },
            {
                "lat": 50.2851826386,
                "lng": -125.4423516499
            },
            {
                "lat": 50.2815667549,
                "lng": -125.4444425986
            },
            {
                "lat": 50.2790638978,
                "lng": -125.4434663236
            },
            {
                "lat": 50.2729448319,
                "lng": -125.4387441699
            },
            {
                "lat": 50.2711367563,
                "lng": -125.4315112972
            },
            {
                "lat": 50.2703060663,
                "lng": -125.4227520758
            },
            {
                "lat": 50.2686349969,
                "lng": -125.4167707559
            },
            {
                "lat": 50.2679636851,
                "lng": -125.4138943251
            },
            {
                "lat": 50.2698330873,
                "lng": -125.4114611566
            },
            {
                "lat": 50.2705234117,
                "lng": -125.4045329049
            },
            {
                "lat": 50.277401149,
                "lng": -125.3833315529
            },
            {
                "lat": 50.2946237261,
                "lng": -125.3943863144
            },
            {
                "lat": 50.2983279346,
                "lng": -125.3940349448
            },
            {
                "lat": 50.2996982765,
                "lng": -125.3958436485
            },
            {
                "lat": 50.3099287466,
                "lng": -125.3987731286
            },
            {
                "lat": 50.313949911,
                "lng": -125.3976365365
            },
            {
                "lat": 50.3167731114,
                "lng": -125.3977657856
            },
            {
                "lat": 50.3228000846,
                "lng": -125.3994293137
            },
            {
                "lat": 50.3316461416,
                "lng": -125.4000698348
            },
            {
                "lat": 50.3365820812,
                "lng": -125.4016107395
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 484,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 13-9",
            "LABEL": "13-9",
            "FISHERY_AREA_ID": 2115,
            "OBJECTID": 628,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 25187486.0066,
            "FEATURE_LENGTH_M": 21876.5572,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.30494970134473,
            "lng": -125.42616369966579
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.323711444,
                "lng": -125.2377554274
            },
            {
                "lat": 50.3147545603,
                "lng": -125.2391439199
            },
            {
                "lat": 50.3056752303,
                "lng": -125.237609517
            },
            {
                "lat": 50.3055459461,
                "lng": -125.237922387
            },
            {
                "lat": 50.3053480332,
                "lng": -125.2383031116
            },
            {
                "lat": 50.3037867513,
                "lng": -125.2394860807
            },
            {
                "lat": 50.3026888205,
                "lng": -125.2391903246
            },
            {
                "lat": 50.2996982199,
                "lng": -125.2374731441
            },
            {
                "lat": 50.2970159725,
                "lng": -125.2355123083
            },
            {
                "lat": 50.2960207925,
                "lng": -125.2343216798
            },
            {
                "lat": 50.2855457908,
                "lng": -125.2307596574
            },
            {
                "lat": 50.2842672514,
                "lng": -125.2356115291
            },
            {
                "lat": 50.2811731748,
                "lng": -125.2417988102
            },
            {
                "lat": 50.2765352165,
                "lng": -125.250564979
            },
            {
                "lat": 50.2724120358,
                "lng": -125.2583000776
            },
            {
                "lat": 50.2667389965,
                "lng": -125.2613919132
            },
            {
                "lat": 50.2605515728,
                "lng": -125.2624203285
            },
            {
                "lat": 50.254878473,
                "lng": -125.2583009864
            },
            {
                "lat": 50.2533337506,
                "lng": -125.2428287211
            },
            {
                "lat": 50.2533337474,
                "lng": -125.2294228697
            },
            {
                "lat": 50.2481763943,
                "lng": -125.2191162261
            },
            {
                "lat": 50.245807158,
                "lng": -125.2172462033
            },
            {
                "lat": 50.1782758708,
                "lng": -125.1942816624
            },
            {
                "lat": 50.1736637171,
                "lng": -125.1950915399
            },
            {
                "lat": 50.165793788,
                "lng": -125.2001797625
            },
            {
                "lat": 50.1587522724,
                "lng": -125.2013849502
            },
            {
                "lat": 50.1526412301,
                "lng": -125.2012946024
            },
            {
                "lat": 50.1496773773,
                "lng": -125.2006451834
            },
            {
                "lat": 50.1486587053,
                "lng": -125.192772043
            },
            {
                "lat": 50.1481965333,
                "lng": -125.1855469974
            },
            {
                "lat": 50.1481206833,
                "lng": -125.1840282379
            },
            {
                "lat": 50.1481211004,
                "lng": -125.1761547472
            },
            {
                "lat": 50.1481208674,
                "lng": -125.1500100255
            },
            {
                "lat": 50.1482816232,
                "lng": -125.1494367522
            },
            {
                "lat": 50.1486624871,
                "lng": -125.1487736389
            },
            {
                "lat": 50.149970657,
                "lng": -125.1460428969
            },
            {
                "lat": 50.1507834956,
                "lng": -125.1429137218
            },
            {
                "lat": 50.1555331855,
                "lng": -125.1375807158
            },
            {
                "lat": 50.1664274819,
                "lng": -125.1302795373
            },
            {
                "lat": 50.1721034729,
                "lng": -125.124024166
            },
            {
                "lat": 50.1734958546,
                "lng": -125.1186905133
            },
            {
                "lat": 50.1775508791,
                "lng": -125.1164938516
            },
            {
                "lat": 50.1851994787,
                "lng": -125.1174165529
            },
            {
                "lat": 50.1905404836,
                "lng": -125.1195837346
            },
            {
                "lat": 50.1955114072,
                "lng": -125.1193840656
            },
            {
                "lat": 50.200008546,
                "lng": -125.1194457269
            },
            {
                "lat": 50.2047848737,
                "lng": -125.119507241
            },
            {
                "lat": 50.2134743945,
                "lng": -125.1183471999
            },
            {
                "lat": 50.218803632,
                "lng": -125.1166073171
            },
            {
                "lat": 50.2251773484,
                "lng": -125.1065216487
            },
            {
                "lat": 50.2265582056,
                "lng": -125.1050645738
            },
            {
                "lat": 50.2300449488,
                "lng": -125.1045524482
            },
            {
                "lat": 50.2379260774,
                "lng": -125.103858827
            },
            {
                "lat": 50.2411692543,
                "lng": -125.1016545005
            },
            {
                "lat": 50.2445304393,
                "lng": -125.0960922431
            },
            {
                "lat": 50.248591996,
                "lng": -125.0908201671
            },
            {
                "lat": 50.2629855918,
                "lng": -125.0823451791
            },
            {
                "lat": 50.2641871358,
                "lng": -125.0810546353
            },
            {
                "lat": 50.2664409341,
                "lng": -125.0795742973
            },
            {
                "lat": 50.2689288295,
                "lng": -125.0784152896
            },
            {
                "lat": 50.2699542511,
                "lng": -125.0782400954
            },
            {
                "lat": 50.2724188308,
                "lng": -125.0767814475
            },
            {
                "lat": 50.2735291917,
                "lng": -125.0752099283
            },
            {
                "lat": 50.276031058,
                "lng": -125.0746536721
            },
            {
                "lat": 50.2764511208,
                "lng": -125.0890422695
            },
            {
                "lat": 50.2939453805,
                "lng": -125.2114027795
            },
            {
                "lat": 50.298542094,
                "lng": -125.2188643189
            },
            {
                "lat": 50.3050956289,
                "lng": -125.2180702614
            },
            {
                "lat": 50.3097381309,
                "lng": -125.2160801401
            },
            {
                "lat": 50.3195911991,
                "lng": -125.2109685319
            },
            {
                "lat": 50.3268475799,
                "lng": -125.2106998434
            },
            {
                "lat": 50.3307884029,
                "lng": -125.2159879252
            },
            {
                "lat": 50.3321304649,
                "lng": -125.2204662666
            },
            {
                "lat": 50.3306076451,
                "lng": -125.2263796387
            },
            {
                "lat": 50.3267554765,
                "lng": -125.2328255792
            },
            {
                "lat": 50.323711444,
                "lng": -125.2377554274
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 485,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 13-12",
            "LABEL": "13-12",
            "FISHERY_AREA_ID": 2118,
            "OBJECTID": 629,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 124895966.0858,
            "FEATURE_LENGTH_M": 59380.9448,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.24198463277105,
            "lng": -125.17439183579476
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.3037867513,
                "lng": -125.2394860807
            },
            {
                "lat": 50.3053480332,
                "lng": -125.2383031116
            },
            {
                "lat": 50.3055459461,
                "lng": -125.237922387
            },
            {
                "lat": 50.3056752303,
                "lng": -125.237609517
            },
            {
                "lat": 50.3147545603,
                "lng": -125.2391439199
            },
            {
                "lat": 50.323711444,
                "lng": -125.2377554274
            },
            {
                "lat": 50.3267625294,
                "lng": -125.2409353988
            },
            {
                "lat": 50.3290024523,
                "lng": -125.2460945739
            },
            {
                "lat": 50.3310197885,
                "lng": -125.2568518119
            },
            {
                "lat": 50.3319209053,
                "lng": -125.2653746487
            },
            {
                "lat": 50.3303494108,
                "lng": -125.2714242171
            },
            {
                "lat": 50.3254167674,
                "lng": -125.2821894204
            },
            {
                "lat": 50.3222772728,
                "lng": -125.2947381834
            },
            {
                "lat": 50.3186915202,
                "lng": -125.3063965376
            },
            {
                "lat": 50.3166726158,
                "lng": -125.3169320948
            },
            {
                "lat": 50.3139838509,
                "lng": -125.3324047234
            },
            {
                "lat": 50.3124157782,
                "lng": -125.3411486557
            },
            {
                "lat": 50.309276905,
                "lng": -125.3440627184
            },
            {
                "lat": 50.3032224881,
                "lng": -125.346748094
            },
            {
                "lat": 50.3004108887,
                "lng": -125.3497538932
            },
            {
                "lat": 50.2987437977,
                "lng": -125.3625948954
            },
            {
                "lat": 50.2970813415,
                "lng": -125.3754269635
            },
            {
                "lat": 50.2983279346,
                "lng": -125.3940349448
            },
            {
                "lat": 50.2946237261,
                "lng": -125.3943863144
            },
            {
                "lat": 50.277401149,
                "lng": -125.3833315529
            },
            {
                "lat": 50.2772384495,
                "lng": -125.3772545609
            },
            {
                "lat": 50.2779461146,
                "lng": -125.3738556607
            },
            {
                "lat": 50.2778167531,
                "lng": -125.3699718293
            },
            {
                "lat": 50.2787131132,
                "lng": -125.3645483485
            },
            {
                "lat": 50.2808914562,
                "lng": -125.3611371799
            },
            {
                "lat": 50.2804676539,
                "lng": -125.3540345864
            },
            {
                "lat": 50.2807089285,
                "lng": -125.3414235285
            },
            {
                "lat": 50.2833255171,
                "lng": -125.3200075159
            },
            {
                "lat": 50.2864189074,
                "lng": -125.302870665
            },
            {
                "lat": 50.2914166542,
                "lng": -125.2943041978
            },
            {
                "lat": 50.2962116936,
                "lng": -125.2891682197
            },
            {
                "lat": 50.3020206725,
                "lng": -125.2574994654
            },
            {
                "lat": 50.3017809064,
                "lng": -125.2524256435
            },
            {
                "lat": 50.3014492039,
                "lng": -125.2469181154
            },
            {
                "lat": 50.3028685756,
                "lng": -125.2409899288
            },
            {
                "lat": 50.3037867513,
                "lng": -125.2394860807
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 486,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 13-10",
            "LABEL": "13-10",
            "FISHERY_AREA_ID": 2116,
            "OBJECTID": 630,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 32682284.4546,
            "FEATURE_LENGTH_M": 28675.5044,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.30291425459755,
            "lng": -125.30538891737318
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.311091781,
                "lng": -124.9688339726
            },
            {
                "lat": 50.3109927031,
                "lng": -124.9730988987
            },
            {
                "lat": 50.3110466704,
                "lng": -124.9735874965
            },
            {
                "lat": 50.3140141854,
                "lng": -124.9860000819
            },
            {
                "lat": 50.315990586,
                "lng": -124.9941484788
            },
            {
                "lat": 50.3152500434,
                "lng": -125.0000766562
            },
            {
                "lat": 50.3110502552,
                "lng": -125.0040282199
            },
            {
                "lat": 50.3066065041,
                "lng": -125.0119323891
            },
            {
                "lat": 50.3044932434,
                "lng": -125.015457608
            },
            {
                "lat": 50.3010175267,
                "lng": -125.0211867209
            },
            {
                "lat": 50.2998661838,
                "lng": -125.0279620171
            },
            {
                "lat": 50.2996903957,
                "lng": -125.0319359871
            },
            {
                "lat": 50.2942585557,
                "lng": -125.0262606509
            },
            {
                "lat": 50.2838857758,
                "lng": -125.011931135
            },
            {
                "lat": 50.2799335857,
                "lng": -125.0057610388
            },
            {
                "lat": 50.2777098902,
                "lng": -125.0020520335
            },
            {
                "lat": 50.281169836,
                "lng": -125.0005722904
            },
            {
                "lat": 50.2912944039,
                "lng": -124.9926679988
            },
            {
                "lat": 50.3071022437,
                "lng": -124.9736557998
            },
            {
                "lat": 50.311091781,
                "lng": -124.9688339726
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 487,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 631,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 8373947.2603,
            "FEATURE_LENGTH_M": 13008.7036,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.301377807509986,
            "lng": -124.99949917232998
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.3037867513,
                "lng": -125.2394860807
            },
            {
                "lat": 50.3028685756,
                "lng": -125.2409899288
            },
            {
                "lat": 50.3014492039,
                "lng": -125.2469181154
            },
            {
                "lat": 50.3017809064,
                "lng": -125.2524256435
            },
            {
                "lat": 50.3020206725,
                "lng": -125.2574994654
            },
            {
                "lat": 50.2962116936,
                "lng": -125.2891682197
            },
            {
                "lat": 50.2914166542,
                "lng": -125.2943041978
            },
            {
                "lat": 50.2864189074,
                "lng": -125.302870665
            },
            {
                "lat": 50.2833255171,
                "lng": -125.3200075159
            },
            {
                "lat": 50.2807089285,
                "lng": -125.3414235285
            },
            {
                "lat": 50.2804676539,
                "lng": -125.3540345864
            },
            {
                "lat": 50.2808914562,
                "lng": -125.3611371799
            },
            {
                "lat": 50.2787131132,
                "lng": -125.3645483485
            },
            {
                "lat": 50.2778167531,
                "lng": -125.3699718293
            },
            {
                "lat": 50.2779461146,
                "lng": -125.3738556607
            },
            {
                "lat": 50.2772384495,
                "lng": -125.3772545609
            },
            {
                "lat": 50.277401149,
                "lng": -125.3833315529
            },
            {
                "lat": 50.2759450362,
                "lng": -125.3765978611
            },
            {
                "lat": 50.2750395036,
                "lng": -125.3747026985
            },
            {
                "lat": 50.275253757,
                "lng": -125.3648915288
            },
            {
                "lat": 50.274520998,
                "lng": -125.3562996654
            },
            {
                "lat": 50.2697713173,
                "lng": -125.3424155032
            },
            {
                "lat": 50.2662998324,
                "lng": -125.3305356813
            },
            {
                "lat": 50.2668457093,
                "lng": -125.3188401941
            },
            {
                "lat": 50.2701333877,
                "lng": -125.3003848906
            },
            {
                "lat": 50.2705001228,
                "lng": -125.2864913399
            },
            {
                "lat": 50.269405809,
                "lng": -125.2753448239
            },
            {
                "lat": 50.2675783302,
                "lng": -125.2698599023
            },
            {
                "lat": 50.2622787247,
                "lng": -125.2682196302
            },
            {
                "lat": 50.2538716361,
                "lng": -125.2705907526
            },
            {
                "lat": 50.2487561515,
                "lng": -125.2826538802
            },
            {
                "lat": 50.2423590113,
                "lng": -125.294350309
            },
            {
                "lat": 50.2350508373,
                "lng": -125.3009257619
            },
            {
                "lat": 50.2315793734,
                "lng": -125.3100674798
            },
            {
                "lat": 50.2321237005,
                "lng": -125.3213959726
            },
            {
                "lat": 50.2343183816,
                "lng": -125.3376619401
            },
            {
                "lat": 50.2356003181,
                "lng": -125.3508140462
            },
            {
                "lat": 50.2399828987,
                "lng": -125.3619611792
            },
            {
                "lat": 50.244582827,
                "lng": -125.3654330671
            },
            {
                "lat": 50.2432440032,
                "lng": -125.3658900154
            },
            {
                "lat": 50.2257685663,
                "lng": -125.3621066693
            },
            {
                "lat": 50.2236209096,
                "lng": -125.35938249
            },
            {
                "lat": 50.2189140393,
                "lng": -125.3539118582
            },
            {
                "lat": 50.2112879645,
                "lng": -125.3499748984
            },
            {
                "lat": 50.2028968999,
                "lng": -125.3429793032
            },
            {
                "lat": 50.1943738862,
                "lng": -125.3349682543
            },
            {
                "lat": 50.1889069417,
                "lng": -125.326324021
            },
            {
                "lat": 50.1828045151,
                "lng": -125.3193281864
            },
            {
                "lat": 50.1782261023,
                "lng": -125.3188173873
            },
            {
                "lat": 50.1722525356,
                "lng": -125.3223801208
            },
            {
                "lat": 50.1698333712,
                "lng": -125.3279726676
            },
            {
                "lat": 50.168055928,
                "lng": -125.3324282043
            },
            {
                "lat": 50.1620440022,
                "lng": -125.3288122284
            },
            {
                "lat": 50.1551327967,
                "lng": -125.3289343836
            },
            {
                "lat": 50.1508603151,
                "lng": -125.3357236522
            },
            {
                "lat": 50.1491014068,
                "lng": -125.3391114468
            },
            {
                "lat": 50.1457704169,
                "lng": -125.3389280879
            },
            {
                "lat": 50.1463397839,
                "lng": -125.3361657576
            },
            {
                "lat": 50.1460986023,
                "lng": -125.3325117923
            },
            {
                "lat": 50.141223177,
                "lng": -125.3284531481
            },
            {
                "lat": 50.1353000605,
                "lng": -125.3157039477
            },
            {
                "lat": 50.1318053897,
                "lng": -125.3056338005
            },
            {
                "lat": 50.1248251503,
                "lng": -125.2989812258
            },
            {
                "lat": 50.1172827404,
                "lng": -125.2831428838
            },
            {
                "lat": 50.11058387,
                "lng": -125.2716761792
            },
            {
                "lat": 50.104984216,
                "lng": -125.2698591559
            },
            {
                "lat": 50.1029086251,
                "lng": -125.2635192674
            },
            {
                "lat": 50.1053081973,
                "lng": -125.2608188371
            },
            {
                "lat": 50.1066283206,
                "lng": -125.2573615025
            },
            {
                "lat": 50.1025650909,
                "lng": -125.2484368855
            },
            {
                "lat": 50.096267441,
                "lng": -125.2432239243
            },
            {
                "lat": 50.0943450632,
                "lng": -125.241637979
            },
            {
                "lat": 50.0902855796,
                "lng": -125.2371667909
            },
            {
                "lat": 50.0881577739,
                "lng": -125.2323983092
            },
            {
                "lat": 50.0879437798,
                "lng": -125.2293552385
            },
            {
                "lat": 50.0872418021,
                "lng": -125.2235708291
            },
            {
                "lat": 50.0855174398,
                "lng": -125.2170722206
            },
            {
                "lat": 50.0837900723,
                "lng": -125.2124030433
            },
            {
                "lat": 50.0799329695,
                "lng": -125.2087476405
            },
            {
                "lat": 50.0728305766,
                "lng": -125.2069238046
            },
            {
                "lat": 50.0661317773,
                "lng": -125.2089541619
            },
            {
                "lat": 50.0621713977,
                "lng": -125.2126091343
            },
            {
                "lat": 50.059939816,
                "lng": -125.2155545862
            },
            {
                "lat": 50.0540583121,
                "lng": -125.2215421689
            },
            {
                "lat": 50.050201557,
                "lng": -125.2181536907
            },
            {
                "lat": 50.046009325,
                "lng": -125.2133648145
            },
            {
                "lat": 50.0420877831,
                "lng": -125.2125005531
            },
            {
                "lat": 50.0400279522,
                "lng": -125.2133022428
            },
            {
                "lat": 50.03995961,
                "lng": -125.2156907382
            },
            {
                "lat": 50.0406917714,
                "lng": -125.2178194298
            },
            {
                "lat": 50.0405389504,
                "lng": -125.219877821
            },
            {
                "lat": 50.0387150554,
                "lng": -125.2143632137
            },
            {
                "lat": 50.0375252849,
                "lng": -125.2112740891
            },
            {
                "lat": 50.0363355067,
                "lng": -125.2048487273
            },
            {
                "lat": 50.0341913477,
                "lng": -125.1965175959
            },
            {
                "lat": 50.02967078,
                "lng": -125.1920016463
            },
            {
                "lat": 50.0196799276,
                "lng": -125.1905668728
            },
            {
                "lat": 50.0120614724,
                "lng": -125.1910473817
            },
            {
                "lat": 50.0037345657,
                "lng": -125.1905672783
            },
            {
                "lat": 50.003029049,
                "lng": -125.184754063
            },
            {
                "lat": 50.006122274,
                "lng": -125.1752385144
            },
            {
                "lat": 50.0089758115,
                "lng": -125.1635740183
            },
            {
                "lat": 50.0117078997,
                "lng": -125.155051333
            },
            {
                "lat": 50.0132679748,
                "lng": -125.1603471995
            },
            {
                "lat": 50.0216790383,
                "lng": -125.1647104148
            },
            {
                "lat": 50.0316462405,
                "lng": -125.1687624719
            },
            {
                "lat": 50.0469130278,
                "lng": -125.1765529969
            },
            {
                "lat": 50.0573495346,
                "lng": -125.1799764424
            },
            {
                "lat": 50.0698127774,
                "lng": -125.1818476157
            },
            {
                "lat": 50.0780716879,
                "lng": -125.1809080575
            },
            {
                "lat": 50.0793762812,
                "lng": -125.1811221373
            },
            {
                "lat": 50.0859227113,
                "lng": -125.1844475258
            },
            {
                "lat": 50.0858607251,
                "lng": -125.1916580109
            },
            {
                "lat": 50.0877302977,
                "lng": -125.1999140997
            },
            {
                "lat": 50.0909994586,
                "lng": -125.2064588444
            },
            {
                "lat": 50.0953638653,
                "lng": -125.2122202991
            },
            {
                "lat": 50.0970765393,
                "lng": -125.2179864675
            },
            {
                "lat": 50.1048656756,
                "lng": -125.2284238795
            },
            {
                "lat": 50.114676929,
                "lng": -125.2369921577
            },
            {
                "lat": 50.1218449078,
                "lng": -125.2458736797
            },
            {
                "lat": 50.1291663357,
                "lng": -125.2471161635
            },
            {
                "lat": 50.1358649568,
                "lng": -125.2460259667
            },
            {
                "lat": 50.139602809,
                "lng": -125.2411946718
            },
            {
                "lat": 50.1422534065,
                "lng": -125.2340318837
            },
            {
                "lat": 50.1456797751,
                "lng": -125.221572576
            },
            {
                "lat": 50.147548393,
                "lng": -125.2092591577
            },
            {
                "lat": 50.1496773773,
                "lng": -125.2006451834
            },
            {
                "lat": 50.1526412301,
                "lng": -125.2012946024
            },
            {
                "lat": 50.1587522724,
                "lng": -125.2013849502
            },
            {
                "lat": 50.165793788,
                "lng": -125.2001797625
            },
            {
                "lat": 50.1736637171,
                "lng": -125.1950915399
            },
            {
                "lat": 50.1782758708,
                "lng": -125.1942816624
            },
            {
                "lat": 50.245807158,
                "lng": -125.2172462033
            },
            {
                "lat": 50.2481763943,
                "lng": -125.2191162261
            },
            {
                "lat": 50.2533337474,
                "lng": -125.2294228697
            },
            {
                "lat": 50.2533337506,
                "lng": -125.2428287211
            },
            {
                "lat": 50.254878473,
                "lng": -125.2583009864
            },
            {
                "lat": 50.2605515728,
                "lng": -125.2624203285
            },
            {
                "lat": 50.2667389965,
                "lng": -125.2613919132
            },
            {
                "lat": 50.2724120358,
                "lng": -125.2583000776
            },
            {
                "lat": 50.2765352165,
                "lng": -125.250564979
            },
            {
                "lat": 50.2811731748,
                "lng": -125.2417988102
            },
            {
                "lat": 50.2842672514,
                "lng": -125.2356115291
            },
            {
                "lat": 50.2855457908,
                "lng": -125.2307596574
            },
            {
                "lat": 50.2960207925,
                "lng": -125.2343216798
            },
            {
                "lat": 50.2970159725,
                "lng": -125.2355123083
            },
            {
                "lat": 50.2996982199,
                "lng": -125.2374731441
            },
            {
                "lat": 50.3026888205,
                "lng": -125.2391903246
            },
            {
                "lat": 50.3037867513,
                "lng": -125.2394860807
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 488,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 632,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 171314352.6813,
            "FEATURE_LENGTH_M": 109859.0574,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.167076707620794,
            "lng": -125.26299062792346
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.2996903957,
                "lng": -125.0319359871
            },
            {
                "lat": 50.3009112038,
                "lng": -125.0352325312
            },
            {
                "lat": 50.300781185,
                "lng": -125.0367500477
            },
            {
                "lat": 50.300723899,
                "lng": -125.0395280065
            },
            {
                "lat": 50.2771798688,
                "lng": -125.0513981689
            },
            {
                "lat": 50.276031058,
                "lng": -125.0746536721
            },
            {
                "lat": 50.2735291917,
                "lng": -125.0752099283
            },
            {
                "lat": 50.27103795,
                "lng": -125.0751572518
            },
            {
                "lat": 50.2706295794,
                "lng": -125.0749586057
            },
            {
                "lat": 50.2634817172,
                "lng": -125.0714636127
            },
            {
                "lat": 50.2478295947,
                "lng": -125.0638421992
            },
            {
                "lat": 50.2301718078,
                "lng": -125.0582199901
            },
            {
                "lat": 50.2281646136,
                "lng": -125.064239061
            },
            {
                "lat": 50.2268979509,
                "lng": -125.0774077515
            },
            {
                "lat": 50.2228201155,
                "lng": -125.0840385407
            },
            {
                "lat": 50.2165787155,
                "lng": -125.0968625198
            },
            {
                "lat": 50.2072179158,
                "lng": -125.1041408238
            },
            {
                "lat": 50.1961213228,
                "lng": -125.1044927782
            },
            {
                "lat": 50.1874540608,
                "lng": -125.0982521536
            },
            {
                "lat": 50.179893299,
                "lng": -125.0921703427
            },
            {
                "lat": 50.1794202898,
                "lng": -125.0850377257
            },
            {
                "lat": 50.1779712587,
                "lng": -125.0809708438
            },
            {
                "lat": 50.1782108903,
                "lng": -125.0801310264
            },
            {
                "lat": 50.1782457713,
                "lng": -125.0748981803
            },
            {
                "lat": 50.1786494864,
                "lng": -125.0116656946
            },
            {
                "lat": 50.179691606,
                "lng": -124.9858539652
            },
            {
                "lat": 50.1792446903,
                "lng": -124.9847873613
            },
            {
                "lat": 50.1787951881,
                "lng": -124.9824061968
            },
            {
                "lat": 50.1745112062,
                "lng": -124.9795457802
            },
            {
                "lat": 50.1734806355,
                "lng": -124.9767386623
            },
            {
                "lat": 50.1699103496,
                "lng": -124.9715037542
            },
            {
                "lat": 50.1665797786,
                "lng": -124.9715038686
            },
            {
                "lat": 50.1599163894,
                "lng": -124.9712679249
            },
            {
                "lat": 50.1484943974,
                "lng": -124.9667436006
            },
            {
                "lat": 50.1380278175,
                "lng": -124.9591285713
            },
            {
                "lat": 50.1354104675,
                "lng": -124.9529415462
            },
            {
                "lat": 50.1342196908,
                "lng": -124.9448544556
            },
            {
                "lat": 50.1377869683,
                "lng": -124.9410481725
            },
            {
                "lat": 50.1413573461,
                "lng": -124.9396199835
            },
            {
                "lat": 50.1444517053,
                "lng": -124.9405746159
            },
            {
                "lat": 50.149448011,
                "lng": -124.9424752646
            },
            {
                "lat": 50.1537285205,
                "lng": -124.9408115842
            },
            {
                "lat": 50.1551594338,
                "lng": -124.934386868
            },
            {
                "lat": 50.1568217387,
                "lng": -124.9312894445
            },
            {
                "lat": 50.1611057164,
                "lng": -124.9286724324
            },
            {
                "lat": 50.1653899515,
                "lng": -124.9315254868
            },
            {
                "lat": 50.1682433729,
                "lng": -124.9381946528
            },
            {
                "lat": 50.1694332808,
                "lng": -124.9453285591
            },
            {
                "lat": 50.1727673621,
                "lng": -124.9543684843
            },
            {
                "lat": 50.1770479145,
                "lng": -124.9643631323
            },
            {
                "lat": 50.1825214019,
                "lng": -124.9695971618
            },
            {
                "lat": 50.1891815631,
                "lng": -124.9719761762
            },
            {
                "lat": 50.1922762636,
                "lng": -124.9705508311
            },
            {
                "lat": 50.1968000964,
                "lng": -124.9700770379
            },
            {
                "lat": 50.200366842,
                "lng": -124.9717405002
            },
            {
                "lat": 50.2048871642,
                "lng": -124.9760274709
            },
            {
                "lat": 50.2089349857,
                "lng": -124.9817342531
            },
            {
                "lat": 50.2132145955,
                "lng": -124.9893498891
            },
            {
                "lat": 50.2182121204,
                "lng": -124.9938727633
            },
            {
                "lat": 50.2215884479,
                "lng": -124.998931767
            },
            {
                "lat": 50.2220190356,
                "lng": -124.9995793382
            },
            {
                "lat": 50.2265430187,
                "lng": -125.0014878192
            },
            {
                "lat": 50.2316747238,
                "lng": -125.0020829411
            },
            {
                "lat": 50.2323721058,
                "lng": -125.0012351314
            },
            {
                "lat": 50.235492946,
                "lng": -125.0005652667
            },
            {
                "lat": 50.2369995705,
                "lng": -125.0023584555
            },
            {
                "lat": 50.2375873429,
                "lng": -125.0039300449
            },
            {
                "lat": 50.2380715987,
                "lng": -125.0039299314
            },
            {
                "lat": 50.2714351604,
                "lng": -125.0039301972
            },
            {
                "lat": 50.2743793134,
                "lng": -125.0039286645
            },
            {
                "lat": 50.2757296342,
                "lng": -125.0028534615
            },
            {
                "lat": 50.2777098902,
                "lng": -125.0020520335
            },
            {
                "lat": 50.2799335857,
                "lng": -125.0057610388
            },
            {
                "lat": 50.2838857758,
                "lng": -125.011931135
            },
            {
                "lat": 50.2942585557,
                "lng": -125.0262606509
            },
            {
                "lat": 50.2996903957,
                "lng": -125.0319359871
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 489,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 17,
            "MANAGEMENT_AREA_NAME": "Subarea 13-17",
            "LABEL": "13-17",
            "FISHERY_AREA_ID": 2486,
            "OBJECTID": 633,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 84760012.0705,
            "FEATURE_LENGTH_M": 54287.195,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.21061095808027,
            "lng": -125.00684568103945
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.2517583532,
                "lng": -127.790115843
            },
            {
                "lat": 50.2473638062,
                "lng": -127.7900774322
            },
            {
                "lat": 50.2307779828,
                "lng": -127.8082502269
            },
            {
                "lat": 50.129184781,
                "lng": -127.9204938298
            },
            {
                "lat": 50.1291581337,
                "lng": -127.9202495838
            },
            {
                "lat": 50.1291346288,
                "lng": -127.9199215153
            },
            {
                "lat": 50.1290394359,
                "lng": -127.9195790596
            },
            {
                "lat": 50.1288495702,
                "lng": -127.918960625
            },
            {
                "lat": 50.1280556937,
                "lng": -127.9166874951
            },
            {
                "lat": 50.1265563774,
                "lng": -127.9113765476
            },
            {
                "lat": 50.1272580586,
                "lng": -127.90837853
            },
            {
                "lat": 50.1285060313,
                "lng": -127.9044649437
            },
            {
                "lat": 50.1329264747,
                "lng": -127.8977433303
            },
            {
                "lat": 50.138934349,
                "lng": -127.8858941328
            },
            {
                "lat": 50.1463617814,
                "lng": -127.8701626776
            },
            {
                "lat": 50.1525543195,
                "lng": -127.860435809
            },
            {
                "lat": 50.1569708774,
                "lng": -127.8496557136
            },
            {
                "lat": 50.1617475868,
                "lng": -127.8348003899
            },
            {
                "lat": 50.1670487311,
                "lng": -127.8268440962
            },
            {
                "lat": 50.1751826543,
                "lng": -127.82242566
            },
            {
                "lat": 50.1833153734,
                "lng": -127.8195955795
            },
            {
                "lat": 50.1880911733,
                "lng": -127.8141171613
            },
            {
                "lat": 50.1910966409,
                "lng": -127.8086318925
            },
            {
                "lat": 50.191978183,
                "lng": -127.8042148988
            },
            {
                "lat": 50.1919779835,
                "lng": -127.7957222959
            },
            {
                "lat": 50.1930389287,
                "lng": -127.7893600824
            },
            {
                "lat": 50.1951631336,
                "lng": -127.7856447165
            },
            {
                "lat": 50.1990501153,
                "lng": -127.7833478383
            },
            {
                "lat": 50.2027661436,
                "lng": -127.7835235673
            },
            {
                "lat": 50.2089540463,
                "lng": -127.787589647
            },
            {
                "lat": 50.2137256215,
                "lng": -127.7900694805
            },
            {
                "lat": 50.2167316074,
                "lng": -127.7877653952
            },
            {
                "lat": 50.2181472383,
                "lng": -127.7851180488
            },
            {
                "lat": 50.2174413732,
                "lng": -127.776627167
            },
            {
                "lat": 50.2197385666,
                "lng": -127.7654872925
            },
            {
                "lat": 50.2234533283,
                "lng": -127.7571798686
            },
            {
                "lat": 50.2310564699,
                "lng": -127.751524981
            },
            {
                "lat": 50.2335280979,
                "lng": -127.7474588071
            },
            {
                "lat": 50.234238074,
                "lng": -127.7324295874
            },
            {
                "lat": 50.2351229605,
                "lng": -127.726768653
            },
            {
                "lat": 50.23900997,
                "lng": -127.7166903047
            },
            {
                "lat": 50.2436073959,
                "lng": -127.7122722549
            },
            {
                "lat": 50.2499725119,
                "lng": -127.7124485301
            },
            {
                "lat": 50.2545698539,
                "lng": -127.7172240283
            },
            {
                "lat": 50.2595210833,
                "lng": -127.7186357946
            },
            {
                "lat": 50.2658842091,
                "lng": -127.7106777971
            },
            {
                "lat": 50.271366487,
                "lng": -127.7096171048
            },
            {
                "lat": 50.2729601205,
                "lng": -127.7127984338
            },
            {
                "lat": 50.2710149616,
                "lng": -127.7175750086
            },
            {
                "lat": 50.2678298316,
                "lng": -127.7253574322
            },
            {
                "lat": 50.2612870711,
                "lng": -127.7366720321
            },
            {
                "lat": 50.2568704635,
                "lng": -127.749221163
            },
            {
                "lat": 50.2551010292,
                "lng": -127.7607184929
            },
            {
                "lat": 50.2520936089,
                "lng": -127.7764509134
            },
            {
                "lat": 50.2519192543,
                "lng": -127.7865288246
            },
            {
                "lat": 50.2517583532,
                "lng": -127.790115843
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 490,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 27,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 27-6",
            "LABEL": "27-6",
            "FISHERY_AREA_ID": 2506,
            "OBJECTID": 634,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 52074005.6716,
            "FEATURE_LENGTH_M": 48826.4401,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.20412055162858,
            "lng": -127.80038747071613
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.277401149,
                "lng": -125.3833315529
            },
            {
                "lat": 50.2705234117,
                "lng": -125.4045329049
            },
            {
                "lat": 50.2698330873,
                "lng": -125.4114611566
            },
            {
                "lat": 50.2679636851,
                "lng": -125.4138943251
            },
            {
                "lat": 50.2647515906,
                "lng": -125.411330688
            },
            {
                "lat": 50.2581672679,
                "lng": -125.4083326904
            },
            {
                "lat": 50.2511021308,
                "lng": -125.4047396366
            },
            {
                "lat": 50.2479436138,
                "lng": -125.4037248496
            },
            {
                "lat": 50.2458610196,
                "lng": -125.404099312
            },
            {
                "lat": 50.2459444845,
                "lng": -125.400734227
            },
            {
                "lat": 50.2457768597,
                "lng": -125.3985215521
            },
            {
                "lat": 50.2457501621,
                "lng": -125.3970257272
            },
            {
                "lat": 50.246143973,
                "lng": -125.3919069926
            },
            {
                "lat": 50.2475884629,
                "lng": -125.3729624974
            },
            {
                "lat": 50.2480506688,
                "lng": -125.3669360363
            },
            {
                "lat": 50.277401149,
                "lng": -125.3833315529
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 491,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 13-8",
            "LABEL": "13-8",
            "FISHERY_AREA_ID": 2114,
            "OBJECTID": 635,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 7388170.7628,
            "FEATURE_LENGTH_M": 11182.7873,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.256887669737495,
            "lng": -125.39730410635
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.2480506688,
                "lng": -125.3669360363
            },
            {
                "lat": 50.2479019201,
                "lng": -125.3661728882
            },
            {
                "lat": 50.2477876814,
                "lng": -125.3652567593
            },
            {
                "lat": 50.2468944931,
                "lng": -125.3649598382
            },
            {
                "lat": 50.244582827,
                "lng": -125.3654330671
            },
            {
                "lat": 50.2399828987,
                "lng": -125.3619611792
            },
            {
                "lat": 50.2356003181,
                "lng": -125.3508140462
            },
            {
                "lat": 50.2343183816,
                "lng": -125.3376619401
            },
            {
                "lat": 50.2321237005,
                "lng": -125.3213959726
            },
            {
                "lat": 50.2315793734,
                "lng": -125.3100674798
            },
            {
                "lat": 50.2350508373,
                "lng": -125.3009257619
            },
            {
                "lat": 50.2423590113,
                "lng": -125.294350309
            },
            {
                "lat": 50.2487561515,
                "lng": -125.2826538802
            },
            {
                "lat": 50.2538716361,
                "lng": -125.2705907526
            },
            {
                "lat": 50.2622787247,
                "lng": -125.2682196302
            },
            {
                "lat": 50.2675783302,
                "lng": -125.2698599023
            },
            {
                "lat": 50.269405809,
                "lng": -125.2753448239
            },
            {
                "lat": 50.2705001228,
                "lng": -125.2864913399
            },
            {
                "lat": 50.2701333877,
                "lng": -125.3003848906
            },
            {
                "lat": 50.2668457093,
                "lng": -125.3188401941
            },
            {
                "lat": 50.2662998324,
                "lng": -125.3305356813
            },
            {
                "lat": 50.2697713173,
                "lng": -125.3424155032
            },
            {
                "lat": 50.274520998,
                "lng": -125.3562996654
            },
            {
                "lat": 50.275253757,
                "lng": -125.3648915288
            },
            {
                "lat": 50.2750395036,
                "lng": -125.3747026985
            },
            {
                "lat": 50.2759450362,
                "lng": -125.3765978611
            },
            {
                "lat": 50.277401149,
                "lng": -125.3833315529
            },
            {
                "lat": 50.2480506688,
                "lng": -125.3669360363
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 492,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 13-11",
            "LABEL": "13-11",
            "FISHERY_AREA_ID": 2117,
            "OBJECTID": 636,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 25800907.1701,
            "FEATURE_LENGTH_M": 22256.5379,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.25563872303214,
            "lng": -125.3312154006857
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.179893299,
                "lng": -125.0921703427
            },
            {
                "lat": 50.1874540608,
                "lng": -125.0982521536
            },
            {
                "lat": 50.1961213228,
                "lng": -125.1044927782
            },
            {
                "lat": 50.2072179158,
                "lng": -125.1041408238
            },
            {
                "lat": 50.2165787155,
                "lng": -125.0968625198
            },
            {
                "lat": 50.2228201155,
                "lng": -125.0840385407
            },
            {
                "lat": 50.2268979509,
                "lng": -125.0774077515
            },
            {
                "lat": 50.2281646136,
                "lng": -125.064239061
            },
            {
                "lat": 50.2301718078,
                "lng": -125.0582199901
            },
            {
                "lat": 50.2478295947,
                "lng": -125.0638421992
            },
            {
                "lat": 50.2634817172,
                "lng": -125.0714636127
            },
            {
                "lat": 50.2706295794,
                "lng": -125.0749586057
            },
            {
                "lat": 50.27103795,
                "lng": -125.0751572518
            },
            {
                "lat": 50.2735291917,
                "lng": -125.0752099283
            },
            {
                "lat": 50.2724188308,
                "lng": -125.0767814475
            },
            {
                "lat": 50.2699542511,
                "lng": -125.0782400954
            },
            {
                "lat": 50.2689288295,
                "lng": -125.0784152896
            },
            {
                "lat": 50.2664409341,
                "lng": -125.0795742973
            },
            {
                "lat": 50.2641871358,
                "lng": -125.0810546353
            },
            {
                "lat": 50.2629855918,
                "lng": -125.0823451791
            },
            {
                "lat": 50.248591996,
                "lng": -125.0908201671
            },
            {
                "lat": 50.2445304393,
                "lng": -125.0960922431
            },
            {
                "lat": 50.2411692543,
                "lng": -125.1016545005
            },
            {
                "lat": 50.2379260774,
                "lng": -125.103858827
            },
            {
                "lat": 50.2300449488,
                "lng": -125.1045524482
            },
            {
                "lat": 50.2265582056,
                "lng": -125.1050645738
            },
            {
                "lat": 50.2251773484,
                "lng": -125.1065216487
            },
            {
                "lat": 50.218803632,
                "lng": -125.1166073171
            },
            {
                "lat": 50.2134743945,
                "lng": -125.1183471999
            },
            {
                "lat": 50.2047848737,
                "lng": -125.119507241
            },
            {
                "lat": 50.200008546,
                "lng": -125.1194457269
            },
            {
                "lat": 50.1955114072,
                "lng": -125.1193840656
            },
            {
                "lat": 50.1905404836,
                "lng": -125.1195837346
            },
            {
                "lat": 50.1851994787,
                "lng": -125.1174165529
            },
            {
                "lat": 50.1775508791,
                "lng": -125.1164938516
            },
            {
                "lat": 50.1734958546,
                "lng": -125.1186905133
            },
            {
                "lat": 50.1721034729,
                "lng": -125.124024166
            },
            {
                "lat": 50.1664274819,
                "lng": -125.1302795373
            },
            {
                "lat": 50.1555331855,
                "lng": -125.1375807158
            },
            {
                "lat": 50.1507834956,
                "lng": -125.1429137218
            },
            {
                "lat": 50.149970657,
                "lng": -125.1460428969
            },
            {
                "lat": 50.1480105903,
                "lng": -125.143097184
            },
            {
                "lat": 50.1427765563,
                "lng": -125.1423789863
            },
            {
                "lat": 50.1380160551,
                "lng": -125.1407163569
            },
            {
                "lat": 50.1338493537,
                "lng": -125.1404190914
            },
            {
                "lat": 50.1345516229,
                "lng": -125.1376112946
            },
            {
                "lat": 50.1351965133,
                "lng": -125.1324453822
            },
            {
                "lat": 50.1341776897,
                "lng": -125.1299973245
            },
            {
                "lat": 50.1322405102,
                "lng": -125.1281580427
            },
            {
                "lat": 50.1387285177,
                "lng": -125.1257250874
            },
            {
                "lat": 50.1506272549,
                "lng": -125.1207274695
            },
            {
                "lat": 50.1608582377,
                "lng": -125.1083528313
            },
            {
                "lat": 50.168712579,
                "lng": -125.1050275817
            },
            {
                "lat": 50.1775133302,
                "lng": -125.0990760801
            },
            {
                "lat": 50.179893299,
                "lng": -125.0921703427
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 493,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 637,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 24205144.1039,
            "FEATURE_LENGTH_M": 37171.3372,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.20200148418,
            "lng": -125.10577547650367
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.2480506688,
                "lng": -125.3669360363
            },
            {
                "lat": 50.2475884629,
                "lng": -125.3729624974
            },
            {
                "lat": 50.246143973,
                "lng": -125.3919069926
            },
            {
                "lat": 50.2457501621,
                "lng": -125.3970257272
            },
            {
                "lat": 50.2457768597,
                "lng": -125.3985215521
            },
            {
                "lat": 50.2459444845,
                "lng": -125.400734227
            },
            {
                "lat": 50.2458610196,
                "lng": -125.404099312
            },
            {
                "lat": 50.2432907467,
                "lng": -125.407279502
            },
            {
                "lat": 50.2376592476,
                "lng": -125.4072794482
            },
            {
                "lat": 50.231292843,
                "lng": -125.4036097846
            },
            {
                "lat": 50.2219888127,
                "lng": -125.401282634
            },
            {
                "lat": 50.2104832365,
                "lng": -125.3952861498
            },
            {
                "lat": 50.2059515735,
                "lng": -125.3911211184
            },
            {
                "lat": 50.199462283,
                "lng": -125.3890453838
            },
            {
                "lat": 50.1912611126,
                "lng": -125.386962709
            },
            {
                "lat": 50.1864888531,
                "lng": -125.3864747511
            },
            {
                "lat": 50.1867333798,
                "lng": -125.3837806085
            },
            {
                "lat": 50.1863481772,
                "lng": -125.3819047183
            },
            {
                "lat": 50.1812969953,
                "lng": -125.3565377334
            },
            {
                "lat": 50.1804507333,
                "lng": -125.3522803111
            },
            {
                "lat": 50.1788641477,
                "lng": -125.3511196474
            },
            {
                "lat": 50.1769558819,
                "lng": -125.3489528513
            },
            {
                "lat": 50.1744121896,
                "lng": -125.3456506969
            },
            {
                "lat": 50.1707231854,
                "lng": -125.3396755899
            },
            {
                "lat": 50.1683083585,
                "lng": -125.334968145
            },
            {
                "lat": 50.168055928,
                "lng": -125.3324282043
            },
            {
                "lat": 50.1698333712,
                "lng": -125.3279726676
            },
            {
                "lat": 50.1722525356,
                "lng": -125.3223801208
            },
            {
                "lat": 50.1782261023,
                "lng": -125.3188173873
            },
            {
                "lat": 50.1828045151,
                "lng": -125.3193281864
            },
            {
                "lat": 50.1889069417,
                "lng": -125.326324021
            },
            {
                "lat": 50.1943738862,
                "lng": -125.3349682543
            },
            {
                "lat": 50.2028968999,
                "lng": -125.3429793032
            },
            {
                "lat": 50.2112879645,
                "lng": -125.3499748984
            },
            {
                "lat": 50.2189140393,
                "lng": -125.3539118582
            },
            {
                "lat": 50.2236209096,
                "lng": -125.35938249
            },
            {
                "lat": 50.2257685663,
                "lng": -125.3621066693
            },
            {
                "lat": 50.2432440032,
                "lng": -125.3658900154
            },
            {
                "lat": 50.244582827,
                "lng": -125.3654330671
            },
            {
                "lat": 50.2468944931,
                "lng": -125.3649598382
            },
            {
                "lat": 50.2477876814,
                "lng": -125.3652567593
            },
            {
                "lat": 50.2479019201,
                "lng": -125.3661728882
            },
            {
                "lat": 50.2480506688,
                "lng": -125.3669360363
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 494,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 13-7",
            "LABEL": "13-7",
            "FISHERY_AREA_ID": 2113,
            "OBJECTID": 638,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 26459810.2619,
            "FEATURE_LENGTH_M": 24581.3167,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.210988154448835,
            "lng": -125.36606094866511
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.1263617774,
                "lng": -123.7733532441
            },
            {
                "lat": 50.1436158039,
                "lng": -123.7834622434
            },
            {
                "lat": 50.1596753053,
                "lng": -123.8013143129
            },
            {
                "lat": 50.1739537546,
                "lng": -123.7745438224
            },
            {
                "lat": 50.1947746308,
                "lng": -123.7430117793
            },
            {
                "lat": 50.2161864077,
                "lng": -123.7525331243
            },
            {
                "lat": 50.2161864136,
                "lng": -123.7906041304
            },
            {
                "lat": 50.2048872191,
                "lng": -123.8387904832
            },
            {
                "lat": 50.197746381,
                "lng": -123.8732918495
            },
            {
                "lat": 50.2096440852,
                "lng": -123.9042198939
            },
            {
                "lat": 50.2262999451,
                "lng": -123.9637071403
            },
            {
                "lat": 50.2274895528,
                "lng": -123.992263333
            },
            {
                "lat": 50.2209468734,
                "lng": -124.0124892847
            },
            {
                "lat": 50.1959604526,
                "lng": -124.0112986813
            },
            {
                "lat": 50.1329075573,
                "lng": -123.9393230183
            },
            {
                "lat": 50.0989992894,
                "lng": -123.8584207917
            },
            {
                "lat": 50.0900761271,
                "lng": -123.8322452841
            },
            {
                "lat": 50.093376384,
                "lng": -123.80966104
            },
            {
                "lat": 50.0929913999,
                "lng": -123.8056017685
            },
            {
                "lat": 50.0927317309,
                "lng": -123.8019420121
            },
            {
                "lat": 50.1123697459,
                "lng": -123.7850335002
            },
            {
                "lat": 50.1128612469,
                "lng": -123.7853243301
            },
            {
                "lat": 50.1135947443,
                "lng": -123.7857135547
            },
            {
                "lat": 50.114063479,
                "lng": -123.785347995
            },
            {
                "lat": 50.119224126,
                "lng": -123.7804953844
            },
            {
                "lat": 50.1263617774,
                "lng": -123.7733532441
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 495,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 15,
            "MANAGEMENT_AREA_NAME": "Subarea 16-15",
            "LABEL": "16-15",
            "FISHERY_AREA_ID": 2184,
            "OBJECTID": 639,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 170700659.2721,
            "FEATURE_LENGTH_M": 59303.1281,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.154357161946145,
            "lng": -123.83682097099614
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.235492946,
                "lng": -125.0005652667
            },
            {
                "lat": 50.2323721058,
                "lng": -125.0012351314
            },
            {
                "lat": 50.2316747238,
                "lng": -125.0020829411
            },
            {
                "lat": 50.2265430187,
                "lng": -125.0014878192
            },
            {
                "lat": 50.2220190356,
                "lng": -124.9995793382
            },
            {
                "lat": 50.2215884479,
                "lng": -124.998931767
            },
            {
                "lat": 50.2182121204,
                "lng": -124.9938727633
            },
            {
                "lat": 50.2132145955,
                "lng": -124.9893498891
            },
            {
                "lat": 50.2089349857,
                "lng": -124.9817342531
            },
            {
                "lat": 50.2048871642,
                "lng": -124.9760274709
            },
            {
                "lat": 50.200366842,
                "lng": -124.9717405002
            },
            {
                "lat": 50.1968000964,
                "lng": -124.9700770379
            },
            {
                "lat": 50.1922762636,
                "lng": -124.9705508311
            },
            {
                "lat": 50.1891815631,
                "lng": -124.9719761762
            },
            {
                "lat": 50.1825214019,
                "lng": -124.9695971618
            },
            {
                "lat": 50.1770479145,
                "lng": -124.9643631323
            },
            {
                "lat": 50.1727673621,
                "lng": -124.9543684843
            },
            {
                "lat": 50.1694332808,
                "lng": -124.9453285591
            },
            {
                "lat": 50.1682433729,
                "lng": -124.9381946528
            },
            {
                "lat": 50.1653899515,
                "lng": -124.9315254868
            },
            {
                "lat": 50.1611057164,
                "lng": -124.9286724324
            },
            {
                "lat": 50.1568217387,
                "lng": -124.9312894445
            },
            {
                "lat": 50.1551594338,
                "lng": -124.934386868
            },
            {
                "lat": 50.1537285205,
                "lng": -124.9408115842
            },
            {
                "lat": 50.149448011,
                "lng": -124.9424752646
            },
            {
                "lat": 50.1444517053,
                "lng": -124.9405746159
            },
            {
                "lat": 50.1413573461,
                "lng": -124.9396199835
            },
            {
                "lat": 50.1377869683,
                "lng": -124.9410481725
            },
            {
                "lat": 50.1342196908,
                "lng": -124.9448544556
            },
            {
                "lat": 50.1354104675,
                "lng": -124.9529415462
            },
            {
                "lat": 50.1380278175,
                "lng": -124.9591285713
            },
            {
                "lat": 50.1484943974,
                "lng": -124.9667436006
            },
            {
                "lat": 50.1599163894,
                "lng": -124.9712679249
            },
            {
                "lat": 50.1665797786,
                "lng": -124.9715038686
            },
            {
                "lat": 50.1699103496,
                "lng": -124.9715037542
            },
            {
                "lat": 50.1734806355,
                "lng": -124.9767386623
            },
            {
                "lat": 50.1745112062,
                "lng": -124.9795457802
            },
            {
                "lat": 50.1680872303,
                "lng": -124.9824061806
            },
            {
                "lat": 50.1597590404,
                "lng": -124.9874045175
            },
            {
                "lat": 50.1519056141,
                "lng": -124.9931112806
            },
            {
                "lat": 50.146671796,
                "lng": -124.9954907384
            },
            {
                "lat": 50.1392976105,
                "lng": -124.9914472802
            },
            {
                "lat": 50.1281125132,
                "lng": -124.9954896397
            },
            {
                "lat": 50.1266866822,
                "lng": -125.00144157
            },
            {
                "lat": 50.1307299532,
                "lng": -125.0092934828
            },
            {
                "lat": 50.1371532485,
                "lng": -125.0159533134
            },
            {
                "lat": 50.1381068493,
                "lng": -125.0209503897
            },
            {
                "lat": 50.1323964835,
                "lng": -125.032371761
            },
            {
                "lat": 50.1278764654,
                "lng": -125.039512773
            },
            {
                "lat": 50.1264385266,
                "lng": -125.043823419
            },
            {
                "lat": 50.1148643449,
                "lng": -125.0564199875
            },
            {
                "lat": 50.1144979971,
                "lng": -125.0565499451
            },
            {
                "lat": 50.1165502773,
                "lng": -125.0422061808
            },
            {
                "lat": 50.1105349429,
                "lng": -125.0308457472
            },
            {
                "lat": 50.1105349365,
                "lng": -125.0121312033
            },
            {
                "lat": 50.1105349254,
                "lng": -124.9994363167
            },
            {
                "lat": 50.1005126843,
                "lng": -124.975373261
            },
            {
                "lat": 50.0904883751,
                "lng": -124.9727021102
            },
            {
                "lat": 50.0784570475,
                "lng": -124.9680251855
            },
            {
                "lat": 50.0677639925,
                "lng": -124.9686900115
            },
            {
                "lat": 50.0577396428,
                "lng": -124.9773791331
            },
            {
                "lat": 50.0463788523,
                "lng": -124.9827272063
            },
            {
                "lat": 50.0368148945,
                "lng": -124.9832843976
            },
            {
                "lat": 50.0513260082,
                "lng": -124.973052505
            },
            {
                "lat": 50.0663181075,
                "lng": -124.9611581926
            },
            {
                "lat": 50.0706025242,
                "lng": -124.9559174277
            },
            {
                "lat": 50.0760762498,
                "lng": -124.9418786744
            },
            {
                "lat": 50.0767897931,
                "lng": -124.9237968481
            },
            {
                "lat": 50.0741695645,
                "lng": -124.9121396014
            },
            {
                "lat": 50.0693285284,
                "lng": -124.893256763
            },
            {
                "lat": 50.1332094673,
                "lng": -124.935278839
            },
            {
                "lat": 50.1356388512,
                "lng": -124.9354405792
            },
            {
                "lat": 50.1406368202,
                "lng": -124.9361578914
            },
            {
                "lat": 50.145874299,
                "lng": -124.928543239
            },
            {
                "lat": 50.1503946539,
                "lng": -124.9187850197
            },
            {
                "lat": 50.1558682798,
                "lng": -124.9173583443
            },
            {
                "lat": 50.1706207741,
                "lng": -124.9299690751
            },
            {
                "lat": 50.178947719,
                "lng": -124.9404372801
            },
            {
                "lat": 50.1898917606,
                "lng": -124.9535208171
            },
            {
                "lat": 50.2086908534,
                "lng": -124.9689945523
            },
            {
                "lat": 50.2227292604,
                "lng": -124.9820783853
            },
            {
                "lat": 50.2308200605,
                "lng": -124.9927818346
            },
            {
                "lat": 50.235492946,
                "lng": -125.0005652667
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 496,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 640,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 61195015.156,
            "FEATURE_LENGTH_M": 75803.3316,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.1491770941253,
            "lng": -124.97302738987592
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.1418227336,
                "lng": -127.7147222643
            },
            {
                "lat": 50.1366771183,
                "lng": -127.7064887975
            },
            {
                "lat": 50.1335912189,
                "lng": -127.7013399678
            },
            {
                "lat": 50.1336630783,
                "lng": -127.6985773206
            },
            {
                "lat": 50.1339870681,
                "lng": -127.6966251633
            },
            {
                "lat": 50.134025492,
                "lng": -127.6942749026
            },
            {
                "lat": 50.133880797,
                "lng": -127.6926113562
            },
            {
                "lat": 50.1336249851,
                "lng": -127.691704548
            },
            {
                "lat": 50.1334455003,
                "lng": -127.6906204314
            },
            {
                "lat": 50.1332666319,
                "lng": -127.6897890962
            },
            {
                "lat": 50.1329766101,
                "lng": -127.6893156842
            },
            {
                "lat": 50.132686362,
                "lng": -127.6884539923
            },
            {
                "lat": 50.1326529849,
                "lng": -127.6873316062
            },
            {
                "lat": 50.1325071991,
                "lng": -127.6863563408
            },
            {
                "lat": 50.1323243672,
                "lng": -127.6853412441
            },
            {
                "lat": 50.1320347169,
                "lng": -127.6845854227
            },
            {
                "lat": 50.1316378534,
                "lng": -127.6841885562
            },
            {
                "lat": 50.1308820552,
                "lng": -127.6841124799
            },
            {
                "lat": 50.0862660325,
                "lng": -127.6075670296
            },
            {
                "lat": 50.1033894788,
                "lng": -127.6075670672
            },
            {
                "lat": 50.1076924743,
                "lng": -127.6075669866
            },
            {
                "lat": 50.1080053844,
                "lng": -127.6075745439
            },
            {
                "lat": 50.1084259112,
                "lng": -127.6072546483
            },
            {
                "lat": 50.1092833999,
                "lng": -127.6066818342
            },
            {
                "lat": 50.110538706,
                "lng": -127.6058048739
            },
            {
                "lat": 50.1110949283,
                "lng": -127.605003723
            },
            {
                "lat": 50.1126059286,
                "lng": -127.6022344919
            },
            {
                "lat": 50.1138610753,
                "lng": -127.5984647936
            },
            {
                "lat": 50.1171299968,
                "lng": -127.5979615807
            },
            {
                "lat": 50.1198922955,
                "lng": -127.5967026194
            },
            {
                "lat": 50.1221547319,
                "lng": -127.5967024704
            },
            {
                "lat": 50.123664697,
                "lng": -127.5992202003
            },
            {
                "lat": 50.125172161,
                "lng": -127.6024855973
            },
            {
                "lat": 50.1254200937,
                "lng": -127.6031343673
            },
            {
                "lat": 50.126430576,
                "lng": -127.6057511666
            },
            {
                "lat": 50.1259275647,
                "lng": -127.6127931364
            },
            {
                "lat": 50.1286924595,
                "lng": -127.6215900305
            },
            {
                "lat": 50.135226973,
                "lng": -127.6273729835
            },
            {
                "lat": 50.1410066063,
                "lng": -127.6326529276
            },
            {
                "lat": 50.1447787165,
                "lng": -127.6328959725
            },
            {
                "lat": 50.152820586,
                "lng": -127.6263658498
            },
            {
                "lat": 50.1591038414,
                "lng": -127.6223450688
            },
            {
                "lat": 50.1689067969,
                "lng": -127.6138002965
            },
            {
                "lat": 50.1794622362,
                "lng": -127.6107795273
            },
            {
                "lat": 50.1847418722,
                "lng": -127.6055070593
            },
            {
                "lat": 50.1932867864,
                "lng": -127.5979622108
            },
            {
                "lat": 50.1988187015,
                "lng": -127.596953548
            },
            {
                "lat": 50.2020875863,
                "lng": -127.6007304072
            },
            {
                "lat": 50.2038457403,
                "lng": -127.6077652326
            },
            {
                "lat": 50.203845318,
                "lng": -127.6165616738
            },
            {
                "lat": 50.2053532742,
                "lng": -127.6215896456
            },
            {
                "lat": 50.2116360107,
                "lng": -127.6240996322
            },
            {
                "lat": 50.2151562535,
                "lng": -127.6278762814
            },
            {
                "lat": 50.2156606884,
                "lng": -127.6333998575
            },
            {
                "lat": 50.2138978793,
                "lng": -127.6394348255
            },
            {
                "lat": 50.2103808939,
                "lng": -127.6474757735
            },
            {
                "lat": 50.2038454804,
                "lng": -127.6560290803
            },
            {
                "lat": 50.1935393456,
                "lng": -127.6660766673
            },
            {
                "lat": 50.1854970786,
                "lng": -127.6713562772
            },
            {
                "lat": 50.1721760047,
                "lng": -127.6700978
            },
            {
                "lat": 50.163124841,
                "lng": -127.6746211864
            },
            {
                "lat": 50.1550827651,
                "lng": -127.6886970024
            },
            {
                "lat": 50.1490513126,
                "lng": -127.7042849659
            },
            {
                "lat": 50.1418227336,
                "lng": -127.7147222643
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 497,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 26,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 26-10",
            "LABEL": "26-10",
            "FISHERY_AREA_ID": 2499,
            "OBJECTID": 641,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 54313591.8052,
            "FEATURE_LENGTH_M": 39383.1525,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.14836704672344,
            "lng": -127.6435930367594
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.1331372117,
                "lng": -127.5155792134
            },
            {
                "lat": 50.133002984,
                "lng": -127.5118940149
            },
            {
                "lat": 50.1331711553,
                "lng": -127.5102852961
            },
            {
                "lat": 50.1337974468,
                "lng": -127.5083769419
            },
            {
                "lat": 50.1349447975,
                "lng": -127.5059208535
            },
            {
                "lat": 50.1364179585,
                "lng": -127.4972381243
            },
            {
                "lat": 50.1413302254,
                "lng": -127.4875793774
            },
            {
                "lat": 50.1432952593,
                "lng": -127.4790648232
            },
            {
                "lat": 50.148536598,
                "lng": -127.4718635931
            },
            {
                "lat": 50.154102322,
                "lng": -127.4648216357
            },
            {
                "lat": 50.1586877489,
                "lng": -127.4581061503
            },
            {
                "lat": 50.1678545675,
                "lng": -127.448775439
            },
            {
                "lat": 50.1726029273,
                "lng": -127.4454950476
            },
            {
                "lat": 50.1763688781,
                "lng": -127.4340363433
            },
            {
                "lat": 50.1791535944,
                "lng": -127.4273227319
            },
            {
                "lat": 50.1819383312,
                "lng": -127.4232327784
            },
            {
                "lat": 50.1870116437,
                "lng": -127.4212642532
            },
            {
                "lat": 50.191597645,
                "lng": -127.421920848
            },
            {
                "lat": 50.1958544695,
                "lng": -127.4240489069
            },
            {
                "lat": 50.1986393277,
                "lng": -127.4271618673
            },
            {
                "lat": 50.2001109247,
                "lng": -127.4324031119
            },
            {
                "lat": 50.2009318567,
                "lng": -127.4420616717
            },
            {
                "lat": 50.1988022714,
                "lng": -127.4551625088
            },
            {
                "lat": 50.1953622296,
                "lng": -127.4702233646
            },
            {
                "lat": 50.1940540456,
                "lng": -127.4852904129
            },
            {
                "lat": 50.1902887829,
                "lng": -127.4921649561
            },
            {
                "lat": 50.1801375433,
                "lng": -127.4983899762
            },
            {
                "lat": 50.1652374562,
                "lng": -127.5105063835
            },
            {
                "lat": 50.1527904293,
                "lng": -127.5255657572
            },
            {
                "lat": 50.1442755052,
                "lng": -127.5362095575
            },
            {
                "lat": 50.1392778227,
                "lng": -127.5444799028
            },
            {
                "lat": 50.1331370601,
                "lng": -127.5297774709
            },
            {
                "lat": 50.1331372117,
                "lng": -127.5155792134
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 498,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 26,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 26-9",
            "LABEL": "26-9",
            "FISHERY_AREA_ID": 2498,
            "OBJECTID": 642,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 31081338.1024,
            "FEATURE_LENGTH_M": 24953.3386,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.16451479488485,
            "lng": -127.47641825839095
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.09704244,
                "lng": -127.1779936131
            },
            {
                "lat": 50.0975266847,
                "lng": -127.1798933955
            },
            {
                "lat": 50.0982705098,
                "lng": -127.1861800246
            },
            {
                "lat": 50.0973742221,
                "lng": -127.1920088927
            },
            {
                "lat": 50.0972253451,
                "lng": -127.2006915731
            },
            {
                "lat": 50.0984230959,
                "lng": -127.2105633421
            },
            {
                "lat": 50.099918294,
                "lng": -127.2171407334
            },
            {
                "lat": 50.1014138525,
                "lng": -127.2186356249
            },
            {
                "lat": 50.1032100536,
                "lng": -127.2174376123
            },
            {
                "lat": 50.105453816,
                "lng": -127.2153469847
            },
            {
                "lat": 50.1084441829,
                "lng": -127.2148972296
            },
            {
                "lat": 50.1142767729,
                "lng": -127.2174373752
            },
            {
                "lat": 50.1187635861,
                "lng": -127.2204281152
            },
            {
                "lat": 50.1242976225,
                "lng": -127.2274630535
            },
            {
                "lat": 50.1305804382,
                "lng": -127.2404780138
            },
            {
                "lat": 50.1340219153,
                "lng": -127.2461621886
            },
            {
                "lat": 50.1385074402,
                "lng": -127.2537920596
            },
            {
                "lat": 50.1432954055,
                "lng": -127.2576753231
            },
            {
                "lat": 50.1467356925,
                "lng": -127.2618640291
            },
            {
                "lat": 50.1500275731,
                "lng": -127.2678521188
            },
            {
                "lat": 50.1539147757,
                "lng": -127.2775731698
            },
            {
                "lat": 50.1569055501,
                "lng": -127.2856519732
            },
            {
                "lat": 50.1578066428,
                "lng": -127.2926793542
            },
            {
                "lat": 50.1601976316,
                "lng": -127.2980653395
            },
            {
                "lat": 50.1625902171,
                "lng": -127.2979131664
            },
            {
                "lat": 50.1672282832,
                "lng": -127.294777155
            },
            {
                "lat": 50.1706695129,
                "lng": -127.2953714077
            },
            {
                "lat": 50.1766509837,
                "lng": -127.2968679759
            },
            {
                "lat": 50.1842805335,
                "lng": -127.2968677833
            },
            {
                "lat": 50.1905635711,
                "lng": -127.2973168334
            },
            {
                "lat": 50.1943017692,
                "lng": -127.2980654393
            },
            {
                "lat": 50.1978910888,
                "lng": -127.3021007197
            },
            {
                "lat": 50.2004356347,
                "lng": -127.3052448847
            },
            {
                "lat": 50.2014808154,
                "lng": -127.3088374977
            },
            {
                "lat": 50.2019304072,
                "lng": -127.3124226928
            },
            {
                "lat": 50.2011828738,
                "lng": -127.3145221577
            },
            {
                "lat": 50.198192297,
                "lng": -127.3160170999
            },
            {
                "lat": 50.1931072871,
                "lng": -127.3164590501
            },
            {
                "lat": 50.1868210001,
                "lng": -127.3158645213
            },
            {
                "lat": 50.1814392109,
                "lng": -127.3154154078
            },
            {
                "lat": 50.1765018401,
                "lng": -127.3149643763
            },
            {
                "lat": 50.1720163563,
                "lng": -127.3142166985
            },
            {
                "lat": 50.1661840254,
                "lng": -127.313468749
            },
            {
                "lat": 50.1599008833,
                "lng": -127.3128733336
            },
            {
                "lat": 50.1551130866,
                "lng": -127.3128732946
            },
            {
                "lat": 50.1503255902,
                "lng": -127.3115235003
            },
            {
                "lat": 50.1452407262,
                "lng": -127.3101811536
            },
            {
                "lat": 50.1406018748,
                "lng": -127.3100282667
            },
            {
                "lat": 50.134472702,
                "lng": -127.310028372
            },
            {
                "lat": 50.1323781266,
                "lng": -127.3115236559
            },
            {
                "lat": 50.1298332009,
                "lng": -127.3142168091
            },
            {
                "lat": 50.1272928271,
                "lng": -127.3142168615
            },
            {
                "lat": 50.1245994303,
                "lng": -127.3118283341
            },
            {
                "lat": 50.1214558379,
                "lng": -127.3049467896
            },
            {
                "lat": 50.1172671852,
                "lng": -127.2970197862
            },
            {
                "lat": 50.1166723876,
                "lng": -127.290588539
            },
            {
                "lat": 50.114727023,
                "lng": -127.2854995229
            },
            {
                "lat": 50.1138308955,
                "lng": -127.2808609029
            },
            {
                "lat": 50.1109891414,
                "lng": -127.2780225513
            },
            {
                "lat": 50.1102370626,
                "lng": -127.2753296425
            },
            {
                "lat": 50.1100882692,
                "lng": -127.2732322616
            },
            {
                "lat": 50.1088178404,
                "lng": -127.2714767573
            },
            {
                "lat": 50.1072501442,
                "lng": -127.2702566454
            },
            {
                "lat": 50.1069030036,
                "lng": -127.2728731156
            },
            {
                "lat": 50.1066093831,
                "lng": -127.276877956
            },
            {
                "lat": 50.1041683779,
                "lng": -127.2794948916
            },
            {
                "lat": 50.1026573345,
                "lng": -127.2817689936
            },
            {
                "lat": 50.1001590017,
                "lng": -127.2849046112
            },
            {
                "lat": 50.0978352472,
                "lng": -127.2861251903
            },
            {
                "lat": 50.0954511865,
                "lng": -127.2865908012
            },
            {
                "lat": 50.091381326,
                "lng": -127.2851330251
            },
            {
                "lat": 50.0882450886,
                "lng": -127.282638688
            },
            {
                "lat": 50.0852207263,
                "lng": -127.2821123663
            },
            {
                "lat": 50.0826638973,
                "lng": -127.2810061975
            },
            {
                "lat": 50.0820810026,
                "lng": -127.2853090362
            },
            {
                "lat": 50.0812681609,
                "lng": -127.289031401
            },
            {
                "lat": 50.0798726808,
                "lng": -127.2910610103
            },
            {
                "lat": 50.0746421361,
                "lng": -127.2915886891
            },
            {
                "lat": 50.0705755931,
                "lng": -127.2914733826
            },
            {
                "lat": 50.0680736241,
                "lng": -127.2907711428
            },
            {
                "lat": 50.0649943634,
                "lng": -127.2879256805
            },
            {
                "lat": 50.0636559269,
                "lng": -127.2853689529
            },
            {
                "lat": 50.0631329306,
                "lng": -127.2816461749
            },
            {
                "lat": 50.0626675993,
                "lng": -127.2776413592
            },
            {
                "lat": 50.0613905654,
                "lng": -127.2766495588
            },
            {
                "lat": 50.0598189379,
                "lng": -127.2762982171
            },
            {
                "lat": 50.058193628,
                "lng": -127.2737428257
            },
            {
                "lat": 50.05749512,
                "lng": -127.2710720658
            },
            {
                "lat": 50.0572623981,
                "lng": -127.2690350339
            },
            {
                "lat": 50.0579305109,
                "lng": -127.26708942
            },
            {
                "lat": 50.0667652306,
                "lng": -127.235946163
            },
            {
                "lat": 50.0674739821,
                "lng": -127.2358326713
            },
            {
                "lat": 50.0684661786,
                "lng": -127.2356491589
            },
            {
                "lat": 50.0688749959,
                "lng": -127.2354733856
            },
            {
                "lat": 50.0692403591,
                "lng": -127.2352143502
            },
            {
                "lat": 50.0696293103,
                "lng": -127.2348555247
            },
            {
                "lat": 50.0697747502,
                "lng": -127.2346647492
            },
            {
                "lat": 50.0718084804,
                "lng": -127.2335051827
            },
            {
                "lat": 50.0768050021,
                "lng": -127.2290030633
            },
            {
                "lat": 50.080677091,
                "lng": -127.22438064
            },
            {
                "lat": 50.0826755889,
                "lng": -127.2186355344
            },
            {
                "lat": 50.0830542297,
                "lng": -127.2112649511
            },
            {
                "lat": 50.0824280572,
                "lng": -127.2042694784
            },
            {
                "lat": 50.0815498567,
                "lng": -127.1975173835
            },
            {
                "lat": 50.0811764563,
                "lng": -127.194648862
            },
            {
                "lat": 50.0808027856,
                "lng": -127.1931464092
            },
            {
                "lat": 50.0806348393,
                "lng": -127.1929323915
            },
            {
                "lat": 50.079795581,
                "lng": -127.1918719397
            },
            {
                "lat": 50.079601494,
                "lng": -127.1913218672
            },
            {
                "lat": 50.0795324525,
                "lng": -127.1910554325
            },
            {
                "lat": 50.0794485977,
                "lng": -127.1907038861
            },
            {
                "lat": 50.0969003767,
                "lng": -127.1693419103
            },
            {
                "lat": 50.0970193276,
                "lng": -127.1708914433
            },
            {
                "lat": 50.0969124068,
                "lng": -127.1733857692
            },
            {
                "lat": 50.0969352955,
                "lng": -127.1754535697
            },
            {
                "lat": 50.09704244,
                "lng": -127.1779936131
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 499,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 26,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 26-5",
            "LABEL": "26-5",
            "FISHERY_AREA_ID": 2494,
            "OBJECTID": 643,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 57912640.1887,
            "FEATURE_LENGTH_M": 48033.6042,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.11377215863963,
            "lng": -127.26151155908188
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.168055928,
                "lng": -125.3324282043
            },
            {
                "lat": 50.1683083585,
                "lng": -125.334968145
            },
            {
                "lat": 50.1707231854,
                "lng": -125.3396755899
            },
            {
                "lat": 50.1744121896,
                "lng": -125.3456506969
            },
            {
                "lat": 50.1769558819,
                "lng": -125.3489528513
            },
            {
                "lat": 50.1788641477,
                "lng": -125.3511196474
            },
            {
                "lat": 50.1804507333,
                "lng": -125.3522803111
            },
            {
                "lat": 50.1812969953,
                "lng": -125.3565377334
            },
            {
                "lat": 50.1863481772,
                "lng": -125.3819047183
            },
            {
                "lat": 50.1867333798,
                "lng": -125.3837806085
            },
            {
                "lat": 50.1864888531,
                "lng": -125.3864747511
            },
            {
                "lat": 50.1835484867,
                "lng": -125.3853687041
            },
            {
                "lat": 50.177550824,
                "lng": -125.3836600817
            },
            {
                "lat": 50.1740038042,
                "lng": -125.380478164
            },
            {
                "lat": 50.1693495691,
                "lng": -125.3780285462
            },
            {
                "lat": 50.1642070366,
                "lng": -125.3771662456
            },
            {
                "lat": 50.1616365787,
                "lng": -125.376434194
            },
            {
                "lat": 50.1589427167,
                "lng": -125.3743514609
            },
            {
                "lat": 50.1557610666,
                "lng": -125.3711699983
            },
            {
                "lat": 50.1514784193,
                "lng": -125.3692096033
            },
            {
                "lat": 50.1391138707,
                "lng": -125.3638231463
            },
            {
                "lat": 50.1356855971,
                "lng": -125.3628463281
            },
            {
                "lat": 50.1321721608,
                "lng": -125.3618166445
            },
            {
                "lat": 50.1306111084,
                "lng": -125.3588633708
            },
            {
                "lat": 50.1305002792,
                "lng": -125.3478315677
            },
            {
                "lat": 50.1317252932,
                "lng": -125.3460697828
            },
            {
                "lat": 50.1331859779,
                "lng": -125.3450170765
            },
            {
                "lat": 50.134819553,
                "lng": -125.3451692933
            },
            {
                "lat": 50.1351474758,
                "lng": -125.3451383414
            },
            {
                "lat": 50.1353839072,
                "lng": -125.3451226963
            },
            {
                "lat": 50.1356584653,
                "lng": -125.3450925121
            },
            {
                "lat": 50.1360277972,
                "lng": -125.3452227308
            },
            {
                "lat": 50.1367763499,
                "lng": -125.3453518585
            },
            {
                "lat": 50.1387903159,
                "lng": -125.3442831586
            },
            {
                "lat": 50.1405747112,
                "lng": -125.3430637862
            },
            {
                "lat": 50.1440697516,
                "lng": -125.3419262405
            },
            {
                "lat": 50.1457704169,
                "lng": -125.3389280879
            },
            {
                "lat": 50.1491014068,
                "lng": -125.3391114468
            },
            {
                "lat": 50.1508603151,
                "lng": -125.3357236522
            },
            {
                "lat": 50.1551327967,
                "lng": -125.3289343836
            },
            {
                "lat": 50.1620440022,
                "lng": -125.3288122284
            },
            {
                "lat": 50.168055928,
                "lng": -125.3324282043
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 500,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 13-6",
            "LABEL": "13-6",
            "FISHERY_AREA_ID": 2112,
            "OBJECTID": 644,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 13989372.7827,
            "FEATURE_LENGTH_M": 16742.7331,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.1561029479,
            "lng": -125.35476706649763
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.1745112062,
                "lng": -124.9795457802
            },
            {
                "lat": 50.1787951881,
                "lng": -124.9824061968
            },
            {
                "lat": 50.1792446903,
                "lng": -124.9847873613
            },
            {
                "lat": 50.179691606,
                "lng": -124.9858539652
            },
            {
                "lat": 50.1786494864,
                "lng": -125.0116656946
            },
            {
                "lat": 50.1782457713,
                "lng": -125.0748981803
            },
            {
                "lat": 50.1782108903,
                "lng": -125.0801310264
            },
            {
                "lat": 50.1779712587,
                "lng": -125.0809708438
            },
            {
                "lat": 50.1794202898,
                "lng": -125.0850377257
            },
            {
                "lat": 50.179893299,
                "lng": -125.0921703427
            },
            {
                "lat": 50.1775133302,
                "lng": -125.0990760801
            },
            {
                "lat": 50.168712579,
                "lng": -125.1050275817
            },
            {
                "lat": 50.1608582377,
                "lng": -125.1083528313
            },
            {
                "lat": 50.1506272549,
                "lng": -125.1207274695
            },
            {
                "lat": 50.1387285177,
                "lng": -125.1257250874
            },
            {
                "lat": 50.1322405102,
                "lng": -125.1281580427
            },
            {
                "lat": 50.1305050416,
                "lng": -125.1271753601
            },
            {
                "lat": 50.12894014,
                "lng": -125.1284328686
            },
            {
                "lat": 50.1277089552,
                "lng": -125.1292642651
            },
            {
                "lat": 50.127582701,
                "lng": -125.129355855
            },
            {
                "lat": 50.1274189556,
                "lng": -125.1293957071
            },
            {
                "lat": 50.1109622622,
                "lng": -125.0729676805
            },
            {
                "lat": 50.1082418876,
                "lng": -125.0636287959
            },
            {
                "lat": 50.1079783725,
                "lng": -125.0630347332
            },
            {
                "lat": 50.1079133942,
                "lng": -125.0621342241
            },
            {
                "lat": 50.1086842846,
                "lng": -125.0608826477
            },
            {
                "lat": 50.1108356573,
                "lng": -125.0585100803
            },
            {
                "lat": 50.1144979971,
                "lng": -125.0565499451
            },
            {
                "lat": 50.1148643449,
                "lng": -125.0564199875
            },
            {
                "lat": 50.1264385266,
                "lng": -125.043823419
            },
            {
                "lat": 50.1278764654,
                "lng": -125.039512773
            },
            {
                "lat": 50.1323964835,
                "lng": -125.032371761
            },
            {
                "lat": 50.1381068493,
                "lng": -125.0209503897
            },
            {
                "lat": 50.1371532485,
                "lng": -125.0159533134
            },
            {
                "lat": 50.1307299532,
                "lng": -125.0092934828
            },
            {
                "lat": 50.1266866822,
                "lng": -125.00144157
            },
            {
                "lat": 50.1281125132,
                "lng": -124.9954896397
            },
            {
                "lat": 50.1392976105,
                "lng": -124.9914472802
            },
            {
                "lat": 50.146671796,
                "lng": -124.9954907384
            },
            {
                "lat": 50.1519056141,
                "lng": -124.9931112806
            },
            {
                "lat": 50.1597590404,
                "lng": -124.9874045175
            },
            {
                "lat": 50.1680872303,
                "lng": -124.9824061806
            },
            {
                "lat": 50.1745112062,
                "lng": -124.9795457802
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 501,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 16,
            "MANAGEMENT_AREA_NAME": "Subarea 13-16",
            "LABEL": "13-16",
            "FISHERY_AREA_ID": 2122,
            "OBJECTID": 645,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 56622934.9706,
            "FEATURE_LENGTH_M": 32804.9161,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.144818170441866,
            "lng": -125.05280298804654
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.09704244,
                "lng": -127.1779936131
            },
            {
                "lat": 50.0969352955,
                "lng": -127.1754535697
            },
            {
                "lat": 50.0969124068,
                "lng": -127.1733857692
            },
            {
                "lat": 50.0970193276,
                "lng": -127.1708914433
            },
            {
                "lat": 50.0969003767,
                "lng": -127.1693419103
            },
            {
                "lat": 50.0794485977,
                "lng": -127.1907038861
            },
            {
                "lat": 50.0676654397,
                "lng": -127.1856153563
            },
            {
                "lat": 50.0662844348,
                "lng": -127.1850206121
            },
            {
                "lat": 50.0654100702,
                "lng": -127.1830368571
            },
            {
                "lat": 50.0638120314,
                "lng": -127.1792603175
            },
            {
                "lat": 50.0609630553,
                "lng": -127.1774221991
            },
            {
                "lat": 50.0604669484,
                "lng": -127.1770470698
            },
            {
                "lat": 50.0600131024,
                "lng": -127.1767655929
            },
            {
                "lat": 50.0596502297,
                "lng": -127.1764375687
            },
            {
                "lat": 50.0591702711,
                "lng": -127.1759712207
            },
            {
                "lat": 50.0584414801,
                "lng": -127.1747581782
            },
            {
                "lat": 50.0578574475,
                "lng": -127.1715620332
            },
            {
                "lat": 50.0578571575,
                "lng": -127.1677855786
            },
            {
                "lat": 50.0581515068,
                "lng": -127.1627045881
            },
            {
                "lat": 50.0582961499,
                "lng": -127.1576232124
            },
            {
                "lat": 50.0582958385,
                "lng": -127.1512295689
            },
            {
                "lat": 50.0598908973,
                "lng": -127.1401984939
            },
            {
                "lat": 50.0584417405,
                "lng": -127.1310501752
            },
            {
                "lat": 50.0572776527,
                "lng": -127.1217567177
            },
            {
                "lat": 50.0559738388,
                "lng": -127.1115882916
            },
            {
                "lat": 50.0543752609,
                "lng": -127.0976485876
            },
            {
                "lat": 50.0543750332,
                "lng": -127.0903853254
            },
            {
                "lat": 50.0582961461,
                "lng": -127.0867542408
            },
            {
                "lat": 50.0627979457,
                "lng": -127.0855939626
            },
            {
                "lat": 50.065409936,
                "lng": -127.088645676
            },
            {
                "lat": 50.0670083069,
                "lng": -127.0921327151
            },
            {
                "lat": 50.0697667258,
                "lng": -127.0999753784
            },
            {
                "lat": 50.0703475521,
                "lng": -127.1069408872
            },
            {
                "lat": 50.0715106192,
                "lng": -127.1197207108
            },
            {
                "lat": 50.0728151337,
                "lng": -127.1326444734
            },
            {
                "lat": 50.0742684982,
                "lng": -127.1407784679
            },
            {
                "lat": 50.0760120982,
                "lng": -127.1439747794
            },
            {
                "lat": 50.0789141781,
                "lng": -127.1446992044
            },
            {
                "lat": 50.0806584611,
                "lng": -127.1406328427
            },
            {
                "lat": 50.0835604869,
                "lng": -127.1355513018
            },
            {
                "lat": 50.0867573807,
                "lng": -127.1326444815
            },
            {
                "lat": 50.0885013431,
                "lng": -127.1253806706
            },
            {
                "lat": 50.090533534,
                "lng": -127.1181260072
            },
            {
                "lat": 50.0934369896,
                "lng": -127.1143498177
            },
            {
                "lat": 50.0963404461,
                "lng": -127.1059268623
            },
            {
                "lat": 50.098083222,
                "lng": -127.0993874373
            },
            {
                "lat": 50.0993920506,
                "lng": -127.0927121645
            },
            {
                "lat": 50.100261245,
                "lng": -127.08806627
            },
            {
                "lat": 50.1024395,
                "lng": -127.0850137069
            },
            {
                "lat": 50.1091194183,
                "lng": -127.0803680845
            },
            {
                "lat": 50.1136214665,
                "lng": -127.0761573233
            },
            {
                "lat": 50.1156539094,
                "lng": -127.0704960625
            },
            {
                "lat": 50.1185575591,
                "lng": -127.0662838666
            },
            {
                "lat": 50.1216085422,
                "lng": -127.0659943298
            },
            {
                "lat": 50.123352616,
                "lng": -127.0668639369
            },
            {
                "lat": 50.1249506773,
                "lng": -127.0722351132
            },
            {
                "lat": 50.1269834842,
                "lng": -127.0786284986
            },
            {
                "lat": 50.1330802364,
                "lng": -127.0819628511
            },
            {
                "lat": 50.1391789437,
                "lng": -127.0840003761
            },
            {
                "lat": 50.1461488346,
                "lng": -127.0871962932
            },
            {
                "lat": 50.1507947961,
                "lng": -127.0903855648
            },
            {
                "lat": 50.1526830978,
                "lng": -127.0943069659
            },
            {
                "lat": 50.1535565435,
                "lng": -127.0982286616
            },
            {
                "lat": 50.1541361064,
                "lng": -127.1035999363
            },
            {
                "lat": 50.1544259347,
                "lng": -127.1085356316
            },
            {
                "lat": 50.1560244983,
                "lng": -127.1156546852
            },
            {
                "lat": 50.1579124161,
                "lng": -127.1219021393
            },
            {
                "lat": 50.1582039146,
                "lng": -127.1274184634
            },
            {
                "lat": 50.1571852787,
                "lng": -127.1303248775
            },
            {
                "lat": 50.1547173288,
                "lng": -127.1317743197
            },
            {
                "lat": 50.1522477988,
                "lng": -127.1325001382
            },
            {
                "lat": 50.1486201958,
                "lng": -127.1319199536
            },
            {
                "lat": 50.144699747,
                "lng": -127.1291573932
            },
            {
                "lat": 50.1391791708,
                "lng": -127.1233517081
            },
            {
                "lat": 50.1356926795,
                "lng": -127.1178361367
            },
            {
                "lat": 50.1320649507,
                "lng": -127.1134800193
            },
            {
                "lat": 50.1265445412,
                "lng": -127.1127547752
            },
            {
                "lat": 50.1216085257,
                "lng": -127.1131900303
            },
            {
                "lat": 50.1185339799,
                "lng": -127.1164014957
            },
            {
                "lat": 50.1151463108,
                "lng": -127.1225967065
            },
            {
                "lat": 50.1115222923,
                "lng": -127.1279137845
            },
            {
                "lat": 50.1094210439,
                "lng": -127.1319430367
            },
            {
                "lat": 50.1075512062,
                "lng": -127.1374895636
            },
            {
                "lat": 50.1070253907,
                "lng": -127.1404109848
            },
            {
                "lat": 50.1074339947,
                "lng": -127.1439212412
            },
            {
                "lat": 50.1081929496,
                "lng": -127.1491165728
            },
            {
                "lat": 50.1074944659,
                "lng": -127.1532054632
            },
            {
                "lat": 50.1068493504,
                "lng": -127.1570666125
            },
            {
                "lat": 50.1070250142,
                "lng": -127.1616748151
            },
            {
                "lat": 50.1058575691,
                "lng": -127.1690363356
            },
            {
                "lat": 50.1052740847,
                "lng": -127.1741789585
            },
            {
                "lat": 50.1031684342,
                "lng": -127.1769864576
            },
            {
                "lat": 50.1005399019,
                "lng": -127.1785044061
            },
            {
                "lat": 50.0987281125,
                "lng": -127.1786803145
            },
            {
                "lat": 50.09704244,
                "lng": -127.1779936131
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 502,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 26,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 26-4",
            "LABEL": "26-4",
            "FISHERY_AREA_ID": 2493,
            "OBJECTID": 646,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 44293584.514,
            "FEATURE_LENGTH_M": 43119.51,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.09940420614001,
            "lng": -127.13006229782
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.0893361214,
                "lng": -127.8069995882
            },
            {
                "lat": 50.081169429,
                "lng": -127.8142695103
            },
            {
                "lat": 50.0599667221,
                "lng": -127.8417509956
            },
            {
                "lat": 49.763283145,
                "lng": -127.270797449
            },
            {
                "lat": 49.8594099931,
                "lng": -127.1521148891
            },
            {
                "lat": 49.9976727422,
                "lng": -127.455559089
            },
            {
                "lat": 50.0862660325,
                "lng": -127.6075670296
            },
            {
                "lat": 50.1308820552,
                "lng": -127.6841124799
            },
            {
                "lat": 50.1316378534,
                "lng": -127.6841885562
            },
            {
                "lat": 50.1320347169,
                "lng": -127.6845854227
            },
            {
                "lat": 50.1323243672,
                "lng": -127.6853412441
            },
            {
                "lat": 50.1325071991,
                "lng": -127.6863563408
            },
            {
                "lat": 50.1326529849,
                "lng": -127.6873316062
            },
            {
                "lat": 50.132686362,
                "lng": -127.6884539923
            },
            {
                "lat": 50.1329766101,
                "lng": -127.6893156842
            },
            {
                "lat": 50.1332666319,
                "lng": -127.6897890962
            },
            {
                "lat": 50.1334455003,
                "lng": -127.6906204314
            },
            {
                "lat": 50.1336249851,
                "lng": -127.691704548
            },
            {
                "lat": 50.133880797,
                "lng": -127.6926113562
            },
            {
                "lat": 50.134025492,
                "lng": -127.6942749026
            },
            {
                "lat": 50.1339870681,
                "lng": -127.6966251633
            },
            {
                "lat": 50.1336630783,
                "lng": -127.6985773206
            },
            {
                "lat": 50.1335912189,
                "lng": -127.7013399678
            },
            {
                "lat": 50.1366771183,
                "lng": -127.7064887975
            },
            {
                "lat": 50.1418227336,
                "lng": -127.7147222643
            },
            {
                "lat": 50.1383934232,
                "lng": -127.721581401
            },
            {
                "lat": 50.1339346078,
                "lng": -127.735985525
            },
            {
                "lat": 50.1239856155,
                "lng": -127.7555390925
            },
            {
                "lat": 50.1106071763,
                "lng": -127.778526553
            },
            {
                "lat": 50.0951692192,
                "lng": -127.7967074286
            },
            {
                "lat": 50.0893361214,
                "lng": -127.8069995882
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 503,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 126,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 126-1",
            "LABEL": "126-1",
            "FISHERY_AREA_ID": 2335,
            "OBJECTID": 647,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 664341045.4004,
            "FEATURE_LENGTH_M": 130797.8774,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.09787797164517,
            "lng": -127.67776894559354
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.1331711553,
                "lng": -127.5102852961
            },
            {
                "lat": 50.133002984,
                "lng": -127.5118940149
            },
            {
                "lat": 50.1331372117,
                "lng": -127.5155792134
            },
            {
                "lat": 50.1168178434,
                "lng": -127.5229180746
            },
            {
                "lat": 50.1120563946,
                "lng": -127.5171427212
            },
            {
                "lat": 50.1113398177,
                "lng": -127.5150682577
            },
            {
                "lat": 50.1101801985,
                "lng": -127.5104295239
            },
            {
                "lat": 50.1086349843,
                "lng": -127.5071793317
            },
            {
                "lat": 50.1065441541,
                "lng": -127.5047835623
            },
            {
                "lat": 50.1037599545,
                "lng": -127.5037765199
            },
            {
                "lat": 50.1006664946,
                "lng": -127.5032347588
            },
            {
                "lat": 50.1005362907,
                "lng": -127.5031815225
            },
            {
                "lat": 50.0961982846,
                "lng": -127.497924932
            },
            {
                "lat": 50.0948832874,
                "lng": -127.4884416662
            },
            {
                "lat": 50.0943302674,
                "lng": -127.4844515729
            },
            {
                "lat": 50.0943335972,
                "lng": -127.4843293583
            },
            {
                "lat": 50.0944373938,
                "lng": -127.4840085924
            },
            {
                "lat": 50.0945014804,
                "lng": -127.4836734177
            },
            {
                "lat": 50.0945130047,
                "lng": -127.4833601135
            },
            {
                "lat": 50.0944023132,
                "lng": -127.4831092457
            },
            {
                "lat": 50.0941359462,
                "lng": -127.48256671
            },
            {
                "lat": 50.0938456038,
                "lng": -127.4820103013
            },
            {
                "lat": 50.093353621,
                "lng": -127.4790647176
            },
            {
                "lat": 50.095481307,
                "lng": -127.4775916578
            },
            {
                "lat": 50.0987583263,
                "lng": -127.4766083848
            },
            {
                "lat": 50.1028519261,
                "lng": -127.4761207216
            },
            {
                "lat": 50.1066167846,
                "lng": -127.4752963143
            },
            {
                "lat": 50.1107097784,
                "lng": -127.4749762483
            },
            {
                "lat": 50.1136584531,
                "lng": -127.4736627693
            },
            {
                "lat": 50.1157876667,
                "lng": -127.4721906156
            },
            {
                "lat": 50.1175874608,
                "lng": -127.4661330876
            },
            {
                "lat": 50.1193886813,
                "lng": -127.458923793
            },
            {
                "lat": 50.1202095045,
                "lng": -127.452048968
            },
            {
                "lat": 50.1203721569,
                "lng": -127.4436956728
            },
            {
                "lat": 50.1211895544,
                "lng": -127.4364924993
            },
            {
                "lat": 50.1225016012,
                "lng": -127.4301072106
            },
            {
                "lat": 50.1247901376,
                "lng": -127.4261783207
            },
            {
                "lat": 50.1264301958,
                "lng": -127.4217601206
            },
            {
                "lat": 50.1292118083,
                "lng": -127.4197922434
            },
            {
                "lat": 50.1331441836,
                "lng": -127.4178232714
            },
            {
                "lat": 50.1374013615,
                "lng": -127.4166791505
            },
            {
                "lat": 50.1410020993,
                "lng": -127.4160234098
            },
            {
                "lat": 50.14296738,
                "lng": -127.41569538
            },
            {
                "lat": 50.1446042146,
                "lng": -127.4170067701
            },
            {
                "lat": 50.1446042411,
                "lng": -127.4196238325
            },
            {
                "lat": 50.1436240979,
                "lng": -127.4229047269
            },
            {
                "lat": 50.1410021422,
                "lng": -127.4278185092
            },
            {
                "lat": 50.1387096882,
                "lng": -127.4340364864
            },
            {
                "lat": 50.137729768,
                "lng": -127.4407501857
            },
            {
                "lat": 50.1369089986,
                "lng": -127.4484490328
            },
            {
                "lat": 50.1355971289,
                "lng": -127.4538502738
            },
            {
                "lat": 50.1346169351,
                "lng": -127.4638363971
            },
            {
                "lat": 50.1331440258,
                "lng": -127.473495706
            },
            {
                "lat": 50.1285597075,
                "lng": -127.4802094385
            },
            {
                "lat": 50.125282691,
                "lng": -127.4875792113
            },
            {
                "lat": 50.121517893,
                "lng": -127.4941249742
            },
            {
                "lat": 50.120861123,
                "lng": -127.5001908149
            },
            {
                "lat": 50.1223371347,
                "lng": -127.5044482695
            },
            {
                "lat": 50.1249541807,
                "lng": -127.5055918765
            },
            {
                "lat": 50.1285595385,
                "lng": -127.504935624
            },
            {
                "lat": 50.1310157939,
                "lng": -127.5065769067
            },
            {
                "lat": 50.1331711553,
                "lng": -127.5102852961
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 504,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 26,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 26-8",
            "LABEL": "26-8",
            "FISHERY_AREA_ID": 2497,
            "OBJECTID": 648,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 16981449.6925,
            "FEATURE_LENGTH_M": 23716.7247,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.118316824330634,
            "lng": -127.47261173542259
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.149970657,
                "lng": -125.1460428969
            },
            {
                "lat": 50.1486624871,
                "lng": -125.1487736389
            },
            {
                "lat": 50.1482816232,
                "lng": -125.1494367522
            },
            {
                "lat": 50.1481208674,
                "lng": -125.1500100255
            },
            {
                "lat": 50.1481211004,
                "lng": -125.1761547472
            },
            {
                "lat": 50.1481206833,
                "lng": -125.1840282379
            },
            {
                "lat": 50.1481965333,
                "lng": -125.1855469974
            },
            {
                "lat": 50.1486587053,
                "lng": -125.192772043
            },
            {
                "lat": 50.1496773773,
                "lng": -125.2006451834
            },
            {
                "lat": 50.147548393,
                "lng": -125.2092591577
            },
            {
                "lat": 50.1456797751,
                "lng": -125.221572576
            },
            {
                "lat": 50.1422534065,
                "lng": -125.2340318837
            },
            {
                "lat": 50.139602809,
                "lng": -125.2411946718
            },
            {
                "lat": 50.1358649568,
                "lng": -125.2460259667
            },
            {
                "lat": 50.1291663357,
                "lng": -125.2471161635
            },
            {
                "lat": 50.1218449078,
                "lng": -125.2458736797
            },
            {
                "lat": 50.114676929,
                "lng": -125.2369921577
            },
            {
                "lat": 50.1048656756,
                "lng": -125.2284238795
            },
            {
                "lat": 50.0970765393,
                "lng": -125.2179864675
            },
            {
                "lat": 50.0953638653,
                "lng": -125.2122202991
            },
            {
                "lat": 50.0909994586,
                "lng": -125.2064588444
            },
            {
                "lat": 50.0877302977,
                "lng": -125.1999140997
            },
            {
                "lat": 50.0858607251,
                "lng": -125.1916580109
            },
            {
                "lat": 50.0859227113,
                "lng": -125.1844475258
            },
            {
                "lat": 50.0870205201,
                "lng": -125.1836015682
            },
            {
                "lat": 50.0890776396,
                "lng": -125.1824029271
            },
            {
                "lat": 50.0909875459,
                "lng": -125.1822655544
            },
            {
                "lat": 50.0920565312,
                "lng": -125.182457757
            },
            {
                "lat": 50.0938409071,
                "lng": -125.1831210969
            },
            {
                "lat": 50.0952756255,
                "lng": -125.1839899428
            },
            {
                "lat": 50.0956186145,
                "lng": -125.1844031367
            },
            {
                "lat": 50.0958132438,
                "lng": -125.1845848599
            },
            {
                "lat": 50.0960041268,
                "lng": -125.1847159809
            },
            {
                "lat": 50.0961077299,
                "lng": -125.1847455765
            },
            {
                "lat": 50.0962752859,
                "lng": -125.1848369292
            },
            {
                "lat": 50.0963822726,
                "lng": -125.184868212
            },
            {
                "lat": 50.09651505,
                "lng": -125.184892044
            },
            {
                "lat": 50.0966341633,
                "lng": -125.1848916587
            },
            {
                "lat": 50.096816483,
                "lng": -125.1848534484
            },
            {
                "lat": 50.0970799825,
                "lng": -125.1847753433
            },
            {
                "lat": 50.0973629452,
                "lng": -125.1847458138
            },
            {
                "lat": 50.0975757269,
                "lng": -125.1847227827
            },
            {
                "lat": 50.0978285627,
                "lng": -125.1847986325
            },
            {
                "lat": 50.0985300733,
                "lng": -125.1852945545
            },
            {
                "lat": 50.0995643954,
                "lng": -125.1861200412
            },
            {
                "lat": 50.1005557041,
                "lng": -125.1869043949
            },
            {
                "lat": 50.1015468591,
                "lng": -125.1878967358
            },
            {
                "lat": 50.102370778,
                "lng": -125.1887662787
            },
            {
                "lat": 50.1031149113,
                "lng": -125.1896355257
            },
            {
                "lat": 50.1035690044,
                "lng": -125.1904138433
            },
            {
                "lat": 50.1041066971,
                "lng": -125.1913304972
            },
            {
                "lat": 50.1049271741,
                "lng": -125.1919642842
            },
            {
                "lat": 50.1052439425,
                "lng": -125.1922540091
            },
            {
                "lat": 50.1054084529,
                "lng": -125.1923909
            },
            {
                "lat": 50.1055721125,
                "lng": -125.192566255
            },
            {
                "lat": 50.1056295367,
                "lng": -125.1926578612
            },
            {
                "lat": 50.1056898807,
                "lng": -125.1927651445
            },
            {
                "lat": 50.1057936505,
                "lng": -125.1929712738
            },
            {
                "lat": 50.1059415724,
                "lng": -125.1932220602
            },
            {
                "lat": 50.1061825356,
                "lng": -125.1935952834
            },
            {
                "lat": 50.1064491756,
                "lng": -125.194099131
            },
            {
                "lat": 50.1068007431,
                "lng": -125.1949230551
            },
            {
                "lat": 50.1068647876,
                "lng": -125.1950757185
            },
            {
                "lat": 50.107067313,
                "lng": -125.1955719769
            },
            {
                "lat": 50.1077501903,
                "lng": -125.1955249533
            },
            {
                "lat": 50.1173172996,
                "lng": -125.173424294
            },
            {
                "lat": 50.1269417634,
                "lng": -125.1511838006
            },
            {
                "lat": 50.130325975,
                "lng": -125.1433708608
            },
            {
                "lat": 50.1307601552,
                "lng": -125.143210555
            },
            {
                "lat": 50.1326784197,
                "lng": -125.1430817428
            },
            {
                "lat": 50.1334303703,
                "lng": -125.1420970373
            },
            {
                "lat": 50.1338493537,
                "lng": -125.1404190914
            },
            {
                "lat": 50.1380160551,
                "lng": -125.1407163569
            },
            {
                "lat": 50.1427765563,
                "lng": -125.1423789863
            },
            {
                "lat": 50.1480105903,
                "lng": -125.143097184
            },
            {
                "lat": 50.149970657,
                "lng": -125.1460428969
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 505,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 13-13",
            "LABEL": "13-13",
            "FISHERY_AREA_ID": 2119,
            "OBJECTID": 649,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 31479365.8365,
            "FEATURE_LENGTH_M": 25124.0448,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.113806532399984,
            "lng": -125.18659507571847
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.1331372117,
                "lng": -127.5155792134
            },
            {
                "lat": 50.1331370601,
                "lng": -127.5297774709
            },
            {
                "lat": 50.1392778227,
                "lng": -127.5444799028
            },
            {
                "lat": 50.1348576856,
                "lng": -127.5588383088
            },
            {
                "lat": 50.1363340828,
                "lng": -127.5746687012
            },
            {
                "lat": 50.1337551337,
                "lng": -127.5908734837
            },
            {
                "lat": 50.1289670187,
                "lng": -127.5974959909
            },
            {
                "lat": 50.1254200937,
                "lng": -127.6031343673
            },
            {
                "lat": 50.125172161,
                "lng": -127.6024855973
            },
            {
                "lat": 50.123664697,
                "lng": -127.5992202003
            },
            {
                "lat": 50.1221547319,
                "lng": -127.5967024704
            },
            {
                "lat": 50.1198922955,
                "lng": -127.5967026194
            },
            {
                "lat": 50.1171299968,
                "lng": -127.5979615807
            },
            {
                "lat": 50.1138610753,
                "lng": -127.5984647936
            },
            {
                "lat": 50.1126059286,
                "lng": -127.6022344919
            },
            {
                "lat": 50.1110949283,
                "lng": -127.605003723
            },
            {
                "lat": 50.110538706,
                "lng": -127.6058048739
            },
            {
                "lat": 50.1092833999,
                "lng": -127.6066818342
            },
            {
                "lat": 50.1084259112,
                "lng": -127.6072546483
            },
            {
                "lat": 50.1080053844,
                "lng": -127.6075745439
            },
            {
                "lat": 50.1076924743,
                "lng": -127.6075669866
            },
            {
                "lat": 50.1033894788,
                "lng": -127.6075670672
            },
            {
                "lat": 50.0862660325,
                "lng": -127.6075670296
            },
            {
                "lat": 49.9976727422,
                "lng": -127.455559089
            },
            {
                "lat": 50.0242574933,
                "lng": -127.4138258361
            },
            {
                "lat": 50.0243456393,
                "lng": -127.413657583
            },
            {
                "lat": 50.0244520624,
                "lng": -127.4134672138
            },
            {
                "lat": 50.0246813705,
                "lng": -127.4131166441
            },
            {
                "lat": 50.025585876,
                "lng": -127.4125287598
            },
            {
                "lat": 50.0261269707,
                "lng": -127.4134981965
            },
            {
                "lat": 50.0262563082,
                "lng": -127.4148021046
            },
            {
                "lat": 50.0275914239,
                "lng": -127.4169539746
            },
            {
                "lat": 50.0300595792,
                "lng": -127.4177478666
            },
            {
                "lat": 50.0302692754,
                "lng": -127.4177857971
            },
            {
                "lat": 50.0326919365,
                "lng": -127.4185329936
            },
            {
                "lat": 50.0328255332,
                "lng": -127.4186322506
            },
            {
                "lat": 50.0340770238,
                "lng": -127.4190289467
            },
            {
                "lat": 50.0345950736,
                "lng": -127.4186018036
            },
            {
                "lat": 50.0355497925,
                "lng": -127.4193496651
            },
            {
                "lat": 50.0364765077,
                "lng": -127.4193497157
            },
            {
                "lat": 50.0367245012,
                "lng": -127.4198600645
            },
            {
                "lat": 50.0369105113,
                "lng": -127.420165174
            },
            {
                "lat": 50.0371513527,
                "lng": -127.4205321988
            },
            {
                "lat": 50.0375788872,
                "lng": -127.4208910846
            },
            {
                "lat": 50.0380629754,
                "lng": -127.4211348064
            },
            {
                "lat": 50.0413322917,
                "lng": -127.4227674637
            },
            {
                "lat": 50.0459245796,
                "lng": -127.4257360506
            },
            {
                "lat": 50.0502472075,
                "lng": -127.4314114507
            },
            {
                "lat": 50.0580832288,
                "lng": -127.4368128588
            },
            {
                "lat": 50.064025951,
                "lng": -127.4389727258
            },
            {
                "lat": 50.0707782963,
                "lng": -127.4438397144
            },
            {
                "lat": 50.0753706365,
                "lng": -127.4514010413
            },
            {
                "lat": 50.0834766517,
                "lng": -127.4641044733
            },
            {
                "lat": 50.0888785745,
                "lng": -127.4703140862
            },
            {
                "lat": 50.0921215203,
                "lng": -127.4735571198
            },
            {
                "lat": 50.095481307,
                "lng": -127.4775916578
            },
            {
                "lat": 50.093353621,
                "lng": -127.4790647176
            },
            {
                "lat": 50.0938456038,
                "lng": -127.4820103013
            },
            {
                "lat": 50.0941359462,
                "lng": -127.48256671
            },
            {
                "lat": 50.0944023132,
                "lng": -127.4831092457
            },
            {
                "lat": 50.0945130047,
                "lng": -127.4833601135
            },
            {
                "lat": 50.0945014804,
                "lng": -127.4836734177
            },
            {
                "lat": 50.0944373938,
                "lng": -127.4840085924
            },
            {
                "lat": 50.0943335972,
                "lng": -127.4843293583
            },
            {
                "lat": 50.0943302674,
                "lng": -127.4844515729
            },
            {
                "lat": 50.0948832874,
                "lng": -127.4884416662
            },
            {
                "lat": 50.0961982846,
                "lng": -127.497924932
            },
            {
                "lat": 50.1005362907,
                "lng": -127.5031815225
            },
            {
                "lat": 50.1006664946,
                "lng": -127.5032347588
            },
            {
                "lat": 50.1037599545,
                "lng": -127.5037765199
            },
            {
                "lat": 50.1065441541,
                "lng": -127.5047835623
            },
            {
                "lat": 50.1086349843,
                "lng": -127.5071793317
            },
            {
                "lat": 50.1101801985,
                "lng": -127.5104295239
            },
            {
                "lat": 50.1113398177,
                "lng": -127.5150682577
            },
            {
                "lat": 50.1120563946,
                "lng": -127.5171427212
            },
            {
                "lat": 50.1168178434,
                "lng": -127.5229180746
            },
            {
                "lat": 50.1331372117,
                "lng": -127.5155792134
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 506,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 26,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 26-7",
            "LABEL": "26-7",
            "FISHERY_AREA_ID": 2496,
            "OBJECTID": 650,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 105877961.9273,
            "FEATURE_LENGTH_M": 46960.1639,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.08190044851171,
            "lng": -127.49552473278052
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.1263617774,
                "lng": -123.7733532441
            },
            {
                "lat": 50.119224126,
                "lng": -123.7804953844
            },
            {
                "lat": 50.114063479,
                "lng": -123.785347995
            },
            {
                "lat": 50.1135947443,
                "lng": -123.7857135547
            },
            {
                "lat": 50.1128612469,
                "lng": -123.7853243301
            },
            {
                "lat": 50.1123697459,
                "lng": -123.7850335002
            },
            {
                "lat": 50.0927317309,
                "lng": -123.8019420121
            },
            {
                "lat": 50.0929913999,
                "lng": -123.8056017685
            },
            {
                "lat": 50.093376384,
                "lng": -123.80966104
            },
            {
                "lat": 50.0900761271,
                "lng": -123.8322452841
            },
            {
                "lat": 50.0716363267,
                "lng": -123.85544484
            },
            {
                "lat": 50.0567665256,
                "lng": -123.8917319967
            },
            {
                "lat": 50.0466498,
                "lng": -123.9149333546
            },
            {
                "lat": 50.0392111572,
                "lng": -123.9327466652
            },
            {
                "lat": 50.0292552598,
                "lng": -123.926886984
            },
            {
                "lat": 50.0259475455,
                "lng": -123.9258349393
            },
            {
                "lat": 50.0253332477,
                "lng": -123.9257511943
            },
            {
                "lat": 50.0050428788,
                "lng": -123.9052506143
            },
            {
                "lat": 50.0039637558,
                "lng": -123.9040529304
            },
            {
                "lat": 49.9901394144,
                "lng": -123.8881612945
            },
            {
                "lat": 49.9978719853,
                "lng": -123.868530142
            },
            {
                "lat": 50.0097692183,
                "lng": -123.8316486252
            },
            {
                "lat": 50.025237554,
                "lng": -123.7727582951
            },
            {
                "lat": 50.0549819813,
                "lng": -123.7412336281
            },
            {
                "lat": 50.0835336449,
                "lng": -123.716247532
            },
            {
                "lat": 50.1055453701,
                "lng": -123.7138664464
            },
            {
                "lat": 50.1210101569,
                "lng": -123.7412343691
            },
            {
                "lat": 50.1263617774,
                "lng": -123.7733532441
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 507,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 16-14",
            "LABEL": "16-14",
            "FISHERY_AREA_ID": 2183,
            "OBJECTID": 651,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 114688169.2996,
            "FEATURE_LENGTH_M": 46406.5557,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.0673538700393,
            "lng": -123.8276566145893
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.1457704169,
                "lng": -125.3389280879
            },
            {
                "lat": 50.1440697516,
                "lng": -125.3419262405
            },
            {
                "lat": 50.1405747112,
                "lng": -125.3430637862
            },
            {
                "lat": 50.1387903159,
                "lng": -125.3442831586
            },
            {
                "lat": 50.1367763499,
                "lng": -125.3453518585
            },
            {
                "lat": 50.1360277972,
                "lng": -125.3452227308
            },
            {
                "lat": 50.1356584653,
                "lng": -125.3450925121
            },
            {
                "lat": 50.1353839072,
                "lng": -125.3451226963
            },
            {
                "lat": 50.1351474758,
                "lng": -125.3451383414
            },
            {
                "lat": 50.134819553,
                "lng": -125.3451692933
            },
            {
                "lat": 50.1331859779,
                "lng": -125.3450170765
            },
            {
                "lat": 50.1317252932,
                "lng": -125.3460697828
            },
            {
                "lat": 50.1305002792,
                "lng": -125.3478315677
            },
            {
                "lat": 50.1306111084,
                "lng": -125.3588633708
            },
            {
                "lat": 50.1321721608,
                "lng": -125.3618166445
            },
            {
                "lat": 50.1343537124,
                "lng": -125.3655098831
            },
            {
                "lat": 50.1378819753,
                "lng": -125.3747487128
            },
            {
                "lat": 50.1407391746,
                "lng": -125.3829801717
            },
            {
                "lat": 50.1419148655,
                "lng": -125.3891975998
            },
            {
                "lat": 50.1409077819,
                "lng": -125.3923942035
            },
            {
                "lat": 50.1397318654,
                "lng": -125.3952475077
            },
            {
                "lat": 50.1373783575,
                "lng": -125.3957518453
            },
            {
                "lat": 50.1328427559,
                "lng": -125.395751773
            },
            {
                "lat": 50.1262894883,
                "lng": -125.3925632102
            },
            {
                "lat": 50.1168819424,
                "lng": -125.3863443527
            },
            {
                "lat": 50.1104968042,
                "lng": -125.3767704788
            },
            {
                "lat": 50.109322267,
                "lng": -125.3742444762
            },
            {
                "lat": 50.1104969294,
                "lng": -125.3687053415
            },
            {
                "lat": 50.1121752943,
                "lng": -125.3586197111
            },
            {
                "lat": 50.1111685558,
                "lng": -125.346687619
            },
            {
                "lat": 50.1093219433,
                "lng": -125.3386238321
            },
            {
                "lat": 50.1076400706,
                "lng": -125.3330845449
            },
            {
                "lat": 50.1017575379,
                "lng": -125.328376582
            },
            {
                "lat": 50.0945315312,
                "lng": -125.323333379
            },
            {
                "lat": 50.0878143732,
                "lng": -125.3157729473
            },
            {
                "lat": 50.0762219284,
                "lng": -125.3065335709
            },
            {
                "lat": 50.0676498979,
                "lng": -125.2967913089
            },
            {
                "lat": 50.0666432305,
                "lng": -125.2939309955
            },
            {
                "lat": 50.0678177049,
                "lng": -125.288894326
            },
            {
                "lat": 50.071178633,
                "lng": -125.2872090794
            },
            {
                "lat": 50.0726933121,
                "lng": -125.2851945398
            },
            {
                "lat": 50.0725254614,
                "lng": -125.2811648664
            },
            {
                "lat": 50.0713427074,
                "lng": -125.2788469822
            },
            {
                "lat": 50.073200787,
                "lng": -125.2768703624
            },
            {
                "lat": 50.0732009904,
                "lng": -125.2585002574
            },
            {
                "lat": 50.0443997001,
                "lng": -125.2369154919
            },
            {
                "lat": 50.0443995557,
                "lng": -125.2506176574
            },
            {
                "lat": 50.0424075387,
                "lng": -125.2499470904
            },
            {
                "lat": 50.0418849755,
                "lng": -125.2488789137
            },
            {
                "lat": 50.0402225393,
                "lng": -125.2476812346
            },
            {
                "lat": 50.0372052799,
                "lng": -125.2472145056
            },
            {
                "lat": 50.0327534126,
                "lng": -125.2473979326
            },
            {
                "lat": 50.0295250372,
                "lng": -125.2478629684
            },
            {
                "lat": 50.0155062562,
                "lng": -125.2420505855
            },
            {
                "lat": 50.0094375504,
                "lng": -125.2401127509
            },
            {
                "lat": 50.0040432116,
                "lng": -125.2387622708
            },
            {
                "lat": 49.9971498642,
                "lng": -125.2369681664
            },
            {
                "lat": 49.9985542852,
                "lng": -125.2299043262
            },
            {
                "lat": 49.9985540087,
                "lng": -125.1956090759
            },
            {
                "lat": 50.0037345657,
                "lng": -125.1905672783
            },
            {
                "lat": 50.0120614724,
                "lng": -125.1910473817
            },
            {
                "lat": 50.0196799276,
                "lng": -125.1905668728
            },
            {
                "lat": 50.02967078,
                "lng": -125.1920016463
            },
            {
                "lat": 50.0341913477,
                "lng": -125.1965175959
            },
            {
                "lat": 50.0363355067,
                "lng": -125.2048487273
            },
            {
                "lat": 50.0375252849,
                "lng": -125.2112740891
            },
            {
                "lat": 50.0387150554,
                "lng": -125.2143632137
            },
            {
                "lat": 50.0405389504,
                "lng": -125.219877821
            },
            {
                "lat": 50.0416064766,
                "lng": -125.2214494065
            },
            {
                "lat": 50.0523679618,
                "lng": -125.2311019417
            },
            {
                "lat": 50.0536268006,
                "lng": -125.2304068881
            },
            {
                "lat": 50.054222698,
                "lng": -125.23100955
            },
            {
                "lat": 50.0547523773,
                "lng": -125.2315452628
            },
            {
                "lat": 50.0565450668,
                "lng": -125.2326270709
            },
            {
                "lat": 50.0580516865,
                "lng": -125.2328949936
            },
            {
                "lat": 50.0585895968,
                "lng": -125.2325978773
            },
            {
                "lat": 50.0589412527,
                "lng": -125.2316195242
            },
            {
                "lat": 50.0589943335,
                "lng": -125.2307736433
            },
            {
                "lat": 50.0588457497,
                "lng": -125.229454556
            },
            {
                "lat": 50.057713019,
                "lng": -125.2278588545
            },
            {
                "lat": 50.0566488304,
                "lng": -125.224669944
            },
            {
                "lat": 50.0540583121,
                "lng": -125.2215421689
            },
            {
                "lat": 50.059939816,
                "lng": -125.2155545862
            },
            {
                "lat": 50.0621713977,
                "lng": -125.2126091343
            },
            {
                "lat": 50.0661317773,
                "lng": -125.2089541619
            },
            {
                "lat": 50.0728305766,
                "lng": -125.2069238046
            },
            {
                "lat": 50.0799329695,
                "lng": -125.2087476405
            },
            {
                "lat": 50.0837900723,
                "lng": -125.2124030433
            },
            {
                "lat": 50.0855174398,
                "lng": -125.2170722206
            },
            {
                "lat": 50.0872418021,
                "lng": -125.2235708291
            },
            {
                "lat": 50.0879437798,
                "lng": -125.2293552385
            },
            {
                "lat": 50.0881577739,
                "lng": -125.2323983092
            },
            {
                "lat": 50.0902855796,
                "lng": -125.2371667909
            },
            {
                "lat": 50.0943450632,
                "lng": -125.241637979
            },
            {
                "lat": 50.096267441,
                "lng": -125.2432239243
            },
            {
                "lat": 50.1025650909,
                "lng": -125.2484368855
            },
            {
                "lat": 50.1066283206,
                "lng": -125.2573615025
            },
            {
                "lat": 50.1053081973,
                "lng": -125.2608188371
            },
            {
                "lat": 50.1029086251,
                "lng": -125.2635192674
            },
            {
                "lat": 50.104984216,
                "lng": -125.2698591559
            },
            {
                "lat": 50.11058387,
                "lng": -125.2716761792
            },
            {
                "lat": 50.1172827404,
                "lng": -125.2831428838
            },
            {
                "lat": 50.1248251503,
                "lng": -125.2989812258
            },
            {
                "lat": 50.1318053897,
                "lng": -125.3056338005
            },
            {
                "lat": 50.1353000605,
                "lng": -125.3157039477
            },
            {
                "lat": 50.141223177,
                "lng": -125.3284531481
            },
            {
                "lat": 50.1460986023,
                "lng": -125.3325117923
            },
            {
                "lat": 50.1463397839,
                "lng": -125.3361657576
            },
            {
                "lat": 50.1457704169,
                "lng": -125.3389280879
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 508,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 13-3",
            "LABEL": "13-3",
            "FISHERY_AREA_ID": 2109,
            "OBJECTID": 652,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 70422890.2118,
            "FEATURE_LENGTH_M": 59605.6141,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.0852933471578,
            "lng": -125.28228340385684
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.1265563774,
                "lng": -127.9113765476
            },
            {
                "lat": 50.1280556937,
                "lng": -127.9166874951
            },
            {
                "lat": 50.1288495702,
                "lng": -127.918960625
            },
            {
                "lat": 50.1290394359,
                "lng": -127.9195790596
            },
            {
                "lat": 50.1291346288,
                "lng": -127.9199215153
            },
            {
                "lat": 50.1291581337,
                "lng": -127.9202495838
            },
            {
                "lat": 50.129184781,
                "lng": -127.9204938298
            },
            {
                "lat": 50.1092298074,
                "lng": -127.9392396991
            },
            {
                "lat": 50.081169429,
                "lng": -127.8142695103
            },
            {
                "lat": 50.0893361214,
                "lng": -127.8069995882
            },
            {
                "lat": 50.093108863,
                "lng": -127.8104243751
            },
            {
                "lat": 50.0948261374,
                "lng": -127.8244933812
            },
            {
                "lat": 50.1051185745,
                "lng": -127.8581084597
            },
            {
                "lat": 50.121925107,
                "lng": -127.9013284249
            },
            {
                "lat": 50.1265563774,
                "lng": -127.9113765476
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 509,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 26,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 26-11",
            "LABEL": "26-11",
            "FISHERY_AREA_ID": 2500,
            "OBJECTID": 653,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 16168717.2603,
            "FEATURE_LENGTH_M": 22497.7431,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.11474993585333,
            "lng": -127.88623390948665
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.1338493537,
                "lng": -125.1404190914
            },
            {
                "lat": 50.1334303703,
                "lng": -125.1420970373
            },
            {
                "lat": 50.1326784197,
                "lng": -125.1430817428
            },
            {
                "lat": 50.1307601552,
                "lng": -125.143210555
            },
            {
                "lat": 50.130325975,
                "lng": -125.1433708608
            },
            {
                "lat": 50.1269417634,
                "lng": -125.1511838006
            },
            {
                "lat": 50.1173172996,
                "lng": -125.173424294
            },
            {
                "lat": 50.1077501903,
                "lng": -125.1955249533
            },
            {
                "lat": 50.107067313,
                "lng": -125.1955719769
            },
            {
                "lat": 50.1068647876,
                "lng": -125.1950757185
            },
            {
                "lat": 50.1068007431,
                "lng": -125.1949230551
            },
            {
                "lat": 50.1064491756,
                "lng": -125.194099131
            },
            {
                "lat": 50.1061825356,
                "lng": -125.1935952834
            },
            {
                "lat": 50.1059415724,
                "lng": -125.1932220602
            },
            {
                "lat": 50.1057936505,
                "lng": -125.1929712738
            },
            {
                "lat": 50.1056898807,
                "lng": -125.1927651445
            },
            {
                "lat": 50.1056295367,
                "lng": -125.1926578612
            },
            {
                "lat": 50.1055721125,
                "lng": -125.192566255
            },
            {
                "lat": 50.1054084529,
                "lng": -125.1923909
            },
            {
                "lat": 50.1052439425,
                "lng": -125.1922540091
            },
            {
                "lat": 50.1049271741,
                "lng": -125.1919642842
            },
            {
                "lat": 50.1041066971,
                "lng": -125.1913304972
            },
            {
                "lat": 50.1035690044,
                "lng": -125.1904138433
            },
            {
                "lat": 50.1031149113,
                "lng": -125.1896355257
            },
            {
                "lat": 50.102370778,
                "lng": -125.1887662787
            },
            {
                "lat": 50.1015468591,
                "lng": -125.1878967358
            },
            {
                "lat": 50.1005557041,
                "lng": -125.1869043949
            },
            {
                "lat": 50.0995643954,
                "lng": -125.1861200412
            },
            {
                "lat": 50.0985300733,
                "lng": -125.1852945545
            },
            {
                "lat": 50.0978285627,
                "lng": -125.1847986325
            },
            {
                "lat": 50.0975757269,
                "lng": -125.1847227827
            },
            {
                "lat": 50.0973629452,
                "lng": -125.1847458138
            },
            {
                "lat": 50.0970799825,
                "lng": -125.1847753433
            },
            {
                "lat": 50.096816483,
                "lng": -125.1848534484
            },
            {
                "lat": 50.0966341633,
                "lng": -125.1848916587
            },
            {
                "lat": 50.09651505,
                "lng": -125.184892044
            },
            {
                "lat": 50.0963822726,
                "lng": -125.184868212
            },
            {
                "lat": 50.0962752859,
                "lng": -125.1848369292
            },
            {
                "lat": 50.0961077299,
                "lng": -125.1847455765
            },
            {
                "lat": 50.0960041268,
                "lng": -125.1847159809
            },
            {
                "lat": 50.0958132438,
                "lng": -125.1845848599
            },
            {
                "lat": 50.0956186145,
                "lng": -125.1844031367
            },
            {
                "lat": 50.0952756255,
                "lng": -125.1839899428
            },
            {
                "lat": 50.0938409071,
                "lng": -125.1831210969
            },
            {
                "lat": 50.0920565312,
                "lng": -125.182457757
            },
            {
                "lat": 50.0909875459,
                "lng": -125.1822655544
            },
            {
                "lat": 50.0890776396,
                "lng": -125.1824029271
            },
            {
                "lat": 50.0870205201,
                "lng": -125.1836015682
            },
            {
                "lat": 50.0859227113,
                "lng": -125.1844475258
            },
            {
                "lat": 50.0793762812,
                "lng": -125.1811221373
            },
            {
                "lat": 50.0780716879,
                "lng": -125.1809080575
            },
            {
                "lat": 50.0698127774,
                "lng": -125.1818476157
            },
            {
                "lat": 50.0573495346,
                "lng": -125.1799764424
            },
            {
                "lat": 50.0469130278,
                "lng": -125.1765529969
            },
            {
                "lat": 50.0316462405,
                "lng": -125.1687624719
            },
            {
                "lat": 50.0216790383,
                "lng": -125.1647104148
            },
            {
                "lat": 50.0132679748,
                "lng": -125.1603471995
            },
            {
                "lat": 50.0117078997,
                "lng": -125.155051333
            },
            {
                "lat": 50.0117379273,
                "lng": -125.1478809777
            },
            {
                "lat": 50.0115928218,
                "lng": -125.1467064559
            },
            {
                "lat": 50.0116997342,
                "lng": -124.9865423273
            },
            {
                "lat": 50.0326113666,
                "lng": -125.058111877
            },
            {
                "lat": 50.1274189556,
                "lng": -125.1293957071
            },
            {
                "lat": 50.127582701,
                "lng": -125.129355855
            },
            {
                "lat": 50.1277089552,
                "lng": -125.1292642651
            },
            {
                "lat": 50.12894014,
                "lng": -125.1284328686
            },
            {
                "lat": 50.1305050416,
                "lng": -125.1271753601
            },
            {
                "lat": 50.1322405102,
                "lng": -125.1281580427
            },
            {
                "lat": 50.1341776897,
                "lng": -125.1299973245
            },
            {
                "lat": 50.1351965133,
                "lng": -125.1324453822
            },
            {
                "lat": 50.1345516229,
                "lng": -125.1376112946
            },
            {
                "lat": 50.1338493537,
                "lng": -125.1404190914
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 510,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 13-14",
            "LABEL": "13-14",
            "FISHERY_AREA_ID": 2120,
            "OBJECTID": 654,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 89107837.2745,
            "FEATURE_LENGTH_M": 47585.5317,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.09560855958612,
            "lng": -125.16857815900556
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.1144979971,
                "lng": -125.0565499451
            },
            {
                "lat": 50.1108356573,
                "lng": -125.0585100803
            },
            {
                "lat": 50.1086842846,
                "lng": -125.0608826477
            },
            {
                "lat": 50.1079133942,
                "lng": -125.0621342241
            },
            {
                "lat": 50.1079783725,
                "lng": -125.0630347332
            },
            {
                "lat": 50.1082418876,
                "lng": -125.0636287959
            },
            {
                "lat": 50.1109622622,
                "lng": -125.0729676805
            },
            {
                "lat": 50.1274189556,
                "lng": -125.1293957071
            },
            {
                "lat": 50.0326113666,
                "lng": -125.058111877
            },
            {
                "lat": 50.0116997342,
                "lng": -124.9865423273
            },
            {
                "lat": 50.0186124175,
                "lng": -124.9873498678
            },
            {
                "lat": 50.0284845697,
                "lng": -124.986854954
            },
            {
                "lat": 50.0368148945,
                "lng": -124.9832843976
            },
            {
                "lat": 50.0463788523,
                "lng": -124.9827272063
            },
            {
                "lat": 50.0577396428,
                "lng": -124.9773791331
            },
            {
                "lat": 50.0677639925,
                "lng": -124.9686900115
            },
            {
                "lat": 50.0784570475,
                "lng": -124.9680251855
            },
            {
                "lat": 50.0904883751,
                "lng": -124.9727021102
            },
            {
                "lat": 50.1005126843,
                "lng": -124.975373261
            },
            {
                "lat": 50.1105349254,
                "lng": -124.9994363167
            },
            {
                "lat": 50.1105349365,
                "lng": -125.0121312033
            },
            {
                "lat": 50.1105349429,
                "lng": -125.0308457472
            },
            {
                "lat": 50.1165502773,
                "lng": -125.0422061808
            },
            {
                "lat": 50.1144979971,
                "lng": -125.0565499451
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 511,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 15,
            "MANAGEMENT_AREA_NAME": "Subarea 13-15",
            "LABEL": "13-15",
            "FISHERY_AREA_ID": 2121,
            "OBJECTID": 655,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 78287498.3257,
            "FEATURE_LENGTH_M": 40071.3213,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.084531227804156,
            "lng": -125.02313806409586
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.081169429,
                "lng": -127.8142695103
            },
            {
                "lat": 50.1092298074,
                "lng": -127.9392396991
            },
            {
                "lat": 49.000000358,
                "lng": -129.3562462079
            },
            {
                "lat": 49.00000056,
                "lng": -129.1937098109
            },
            {
                "lat": 49.5833320832,
                "lng": -128.4534304496
            },
            {
                "lat": 50.0599667221,
                "lng": -127.8417509956
            },
            {
                "lat": 50.081169429,
                "lng": -127.8142695103
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 512,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 126,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 126-4",
            "LABEL": "126-4",
            "FISHERY_AREA_ID": 2338,
            "OBJECTID": 656,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1461489252.077,
            "FEATURE_LENGTH_M": 338039.6685,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.702124055528564,
            "lng": -128.34470231195715
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.0882226742,
                "lng": -124.7085266324
            },
            {
                "lat": 50.0875780585,
                "lng": -124.7164925659
            },
            {
                "lat": 50.0843194969,
                "lng": -124.7222446677
            },
            {
                "lat": 50.0835188394,
                "lng": -124.7238987942
            },
            {
                "lat": 50.0824930003,
                "lng": -124.7257146397
            },
            {
                "lat": 50.0799445579,
                "lng": -124.7301792013
            },
            {
                "lat": 50.0723042049,
                "lng": -124.7480006406
            },
            {
                "lat": 50.0630727758,
                "lng": -124.7709282141
            },
            {
                "lat": 50.0637090954,
                "lng": -124.7842937442
            },
            {
                "lat": 50.0637094356,
                "lng": -124.7900316577
            },
            {
                "lat": 50.0668910049,
                "lng": -124.7941661965
            },
            {
                "lat": 50.069122449,
                "lng": -124.7973484025
            },
            {
                "lat": 50.0723042326,
                "lng": -124.8011695607
            },
            {
                "lat": 50.076133483,
                "lng": -124.8050685797
            },
            {
                "lat": 50.0627173514,
                "lng": -124.8435434708
            },
            {
                "lat": 50.0620843793,
                "lng": -124.8386687452
            },
            {
                "lat": 50.0592229337,
                "lng": -124.8302841464
            },
            {
                "lat": 50.0560677275,
                "lng": -124.8180463233
            },
            {
                "lat": 50.0474706123,
                "lng": -124.7998957122
            },
            {
                "lat": 50.0296439233,
                "lng": -124.7769784774
            },
            {
                "lat": 50.0220035583,
                "lng": -124.7607424314
            },
            {
                "lat": 50.0048100233,
                "lng": -124.7409972941
            },
            {
                "lat": 49.9796601036,
                "lng": -124.7196736175
            },
            {
                "lat": 49.9599229219,
                "lng": -124.7002487229
            },
            {
                "lat": 49.9529191778,
                "lng": -124.6824185397
            },
            {
                "lat": 49.9551461517,
                "lng": -124.6620486954
            },
            {
                "lat": 49.9650157026,
                "lng": -124.6559972107
            },
            {
                "lat": 49.9895331528,
                "lng": -124.6696860384
            },
            {
                "lat": 50.0019453814,
                "lng": -124.6766901791
            },
            {
                "lat": 50.0200920145,
                "lng": -124.6941987499
            },
            {
                "lat": 50.0436509517,
                "lng": -124.6910173609
            },
            {
                "lat": 50.0500178612,
                "lng": -124.6817865616
            },
            {
                "lat": 50.055747674,
                "lng": -124.6438983969
            },
            {
                "lat": 50.0802612462,
                "lng": -124.6359398775
            },
            {
                "lat": 50.093315142,
                "lng": -124.6451715454
            },
            {
                "lat": 50.0926782006,
                "lng": -124.6588669592
            },
            {
                "lat": 50.0875851854,
                "lng": -124.6754138917
            },
            {
                "lat": 50.0863112728,
                "lng": -124.6926108143
            },
            {
                "lat": 50.0882226742,
                "lng": -124.7085266324
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 513,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 15,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 15-4",
            "LABEL": "15-4",
            "FISHERY_AREA_ID": 2167,
            "OBJECTID": 657,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 92365420.4017,
            "FEATURE_LENGTH_M": 53739.1713,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.048701759792316,
            "lng": -124.73131830491027
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.0794485977,
                "lng": -127.1907038861
            },
            {
                "lat": 50.0795324525,
                "lng": -127.1910554325
            },
            {
                "lat": 50.079601494,
                "lng": -127.1913218672
            },
            {
                "lat": 50.079795581,
                "lng": -127.1918719397
            },
            {
                "lat": 50.0806348393,
                "lng": -127.1929323915
            },
            {
                "lat": 50.0808027856,
                "lng": -127.1931464092
            },
            {
                "lat": 50.0811764563,
                "lng": -127.194648862
            },
            {
                "lat": 50.0815498567,
                "lng": -127.1975173835
            },
            {
                "lat": 50.0824280572,
                "lng": -127.2042694784
            },
            {
                "lat": 50.0830542297,
                "lng": -127.2112649511
            },
            {
                "lat": 50.0826755889,
                "lng": -127.2186355344
            },
            {
                "lat": 50.080677091,
                "lng": -127.22438064
            },
            {
                "lat": 50.0768050021,
                "lng": -127.2290030633
            },
            {
                "lat": 50.0718084804,
                "lng": -127.2335051827
            },
            {
                "lat": 50.0697747502,
                "lng": -127.2346647492
            },
            {
                "lat": 50.0696293103,
                "lng": -127.2348555247
            },
            {
                "lat": 50.0692403591,
                "lng": -127.2352143502
            },
            {
                "lat": 50.0688749959,
                "lng": -127.2354733856
            },
            {
                "lat": 50.0684661786,
                "lng": -127.2356491589
            },
            {
                "lat": 50.0674739821,
                "lng": -127.2358326713
            },
            {
                "lat": 50.0667652306,
                "lng": -127.235946163
            },
            {
                "lat": 50.0579305109,
                "lng": -127.26708942
            },
            {
                "lat": 50.0572623981,
                "lng": -127.2690350339
            },
            {
                "lat": 50.05749512,
                "lng": -127.2710720658
            },
            {
                "lat": 50.058193628,
                "lng": -127.2737428257
            },
            {
                "lat": 50.0598189379,
                "lng": -127.2762982171
            },
            {
                "lat": 50.0566139218,
                "lng": -127.279701168
            },
            {
                "lat": 50.0532728709,
                "lng": -127.2794798992
            },
            {
                "lat": 50.0501899801,
                "lng": -127.2794797632
            },
            {
                "lat": 50.0374334297,
                "lng": -127.2794803487
            },
            {
                "lat": 50.0368584274,
                "lng": -127.2791518242
            },
            {
                "lat": 50.0360873186,
                "lng": -127.2787244487
            },
            {
                "lat": 50.0314984993,
                "lng": -127.2776421148
            },
            {
                "lat": 50.00730165,
                "lng": -127.2762598218
            },
            {
                "lat": 50.0024795207,
                "lng": -127.2761842187
            },
            {
                "lat": 49.9883155686,
                "lng": -127.2760313475
            },
            {
                "lat": 49.9807319559,
                "lng": -127.2780992266
            },
            {
                "lat": 49.9761395357,
                "lng": -127.2823103539
            },
            {
                "lat": 49.9755899911,
                "lng": -127.2829746224
            },
            {
                "lat": 49.9753001408,
                "lng": -127.2840191409
            },
            {
                "lat": 49.9744455855,
                "lng": -127.2843323287
            },
            {
                "lat": 49.9740335755,
                "lng": -127.2845769257
            },
            {
                "lat": 49.9739033321,
                "lng": -127.2848050492
            },
            {
                "lat": 49.9715574025,
                "lng": -127.2631220328
            },
            {
                "lat": 49.9703592407,
                "lng": -127.2520824872
            },
            {
                "lat": 49.9702718906,
                "lng": -127.2519066945
            },
            {
                "lat": 49.9701765478,
                "lng": -127.2519380682
            },
            {
                "lat": 49.9700273878,
                "lng": -127.2520071677
            },
            {
                "lat": 49.9698295518,
                "lng": -127.2521062987
            },
            {
                "lat": 49.9693406932,
                "lng": -127.2521965692
            },
            {
                "lat": 49.9686774242,
                "lng": -127.2521060701
            },
            {
                "lat": 49.9677468492,
                "lng": -127.2522434703
            },
            {
                "lat": 49.9667286729,
                "lng": -127.2519306586
            },
            {
                "lat": 49.9659309924,
                "lng": -127.2514043421
            },
            {
                "lat": 49.9659766175,
                "lng": -127.2492745747
            },
            {
                "lat": 49.9660801963,
                "lng": -127.2482223593
            },
            {
                "lat": 49.9661939492,
                "lng": -127.2470162027
            },
            {
                "lat": 49.9660647683,
                "lng": -127.2428978669
            },
            {
                "lat": 49.9665523656,
                "lng": -127.2398383015
            },
            {
                "lat": 49.9661523503,
                "lng": -127.2363887028
            },
            {
                "lat": 49.9653548455,
                "lng": -127.2336428412
            },
            {
                "lat": 49.9647180625,
                "lng": -127.2301408475
            },
            {
                "lat": 49.9663882566,
                "lng": -127.2251274896
            },
            {
                "lat": 49.9690660917,
                "lng": -127.2211069383
            },
            {
                "lat": 49.9760893146,
                "lng": -127.2174309565
            },
            {
                "lat": 49.9824442687,
                "lng": -127.2027129559
            },
            {
                "lat": 49.9894711751,
                "lng": -127.1906739415
            },
            {
                "lat": 49.994152589,
                "lng": -127.1813044786
            },
            {
                "lat": 50.0016752917,
                "lng": -127.1839143966
            },
            {
                "lat": 50.016555197,
                "lng": -127.1839212538
            },
            {
                "lat": 50.0210538444,
                "lng": -127.1838077643
            },
            {
                "lat": 50.0257182127,
                "lng": -127.1839061749
            },
            {
                "lat": 50.0276532315,
                "lng": -127.1831590523
            },
            {
                "lat": 50.0290560401,
                "lng": -127.181106889
            },
            {
                "lat": 50.0284074284,
                "lng": -127.1761400721
            },
            {
                "lat": 50.0311051016,
                "lng": -127.1730121108
            },
            {
                "lat": 50.0389829177,
                "lng": -127.1692357493
            },
            {
                "lat": 50.0427543979,
                "lng": -127.167838473
            },
            {
                "lat": 50.0495533327,
                "lng": -127.1682658089
            },
            {
                "lat": 50.0578574475,
                "lng": -127.1715620332
            },
            {
                "lat": 50.0584414801,
                "lng": -127.1747581782
            },
            {
                "lat": 50.0591702711,
                "lng": -127.1759712207
            },
            {
                "lat": 50.0596502297,
                "lng": -127.1764375687
            },
            {
                "lat": 50.0600131024,
                "lng": -127.1767655929
            },
            {
                "lat": 50.0604669484,
                "lng": -127.1770470698
            },
            {
                "lat": 50.0609630553,
                "lng": -127.1774221991
            },
            {
                "lat": 50.0638120314,
                "lng": -127.1792603175
            },
            {
                "lat": 50.0654100702,
                "lng": -127.1830368571
            },
            {
                "lat": 50.0662844348,
                "lng": -127.1850206121
            },
            {
                "lat": 50.0676654397,
                "lng": -127.1856153563
            },
            {
                "lat": 50.0794485977,
                "lng": -127.1907038861
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 514,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 26,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 26-2",
            "LABEL": "26-2",
            "FISHERY_AREA_ID": 2285,
            "OBJECTID": 658,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 76257377.5233,
            "FEATURE_LENGTH_M": 37461.0453,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.02591385522857,
            "lng": -127.22735290266262
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.0592229337,
                "lng": -124.8302841464
            },
            {
                "lat": 50.0620843793,
                "lng": -124.8386687452
            },
            {
                "lat": 50.0627173514,
                "lng": -124.8435434708
            },
            {
                "lat": 50.0591770976,
                "lng": -124.8714448803
            },
            {
                "lat": 50.0574033902,
                "lng": -124.8854140585
            },
            {
                "lat": 50.0580901281,
                "lng": -124.8852540949
            },
            {
                "lat": 50.0602112589,
                "lng": -124.8852388654
            },
            {
                "lat": 50.0635417816,
                "lng": -124.8852376956
            },
            {
                "lat": 50.0647315577,
                "lng": -124.8864204262
            },
            {
                "lat": 50.0668758604,
                "lng": -124.8897557413
            },
            {
                "lat": 50.0693285284,
                "lng": -124.893256763
            },
            {
                "lat": 50.0741695645,
                "lng": -124.9121396014
            },
            {
                "lat": 50.0767897931,
                "lng": -124.9237968481
            },
            {
                "lat": 50.0760762498,
                "lng": -124.9418786744
            },
            {
                "lat": 50.0706025242,
                "lng": -124.9559174277
            },
            {
                "lat": 50.0663181075,
                "lng": -124.9611581926
            },
            {
                "lat": 50.0513260082,
                "lng": -124.973052505
            },
            {
                "lat": 50.0368148945,
                "lng": -124.9832843976
            },
            {
                "lat": 50.0284845697,
                "lng": -124.986854954
            },
            {
                "lat": 50.0186124175,
                "lng": -124.9873498678
            },
            {
                "lat": 50.0116997342,
                "lng": -124.9865423273
            },
            {
                "lat": 49.9502334243,
                "lng": -125.0122069965
            },
            {
                "lat": 49.9497559505,
                "lng": -125.006941617
            },
            {
                "lat": 49.9495886185,
                "lng": -125.0040435385
            },
            {
                "lat": 49.9491811055,
                "lng": -125.0028610925
            },
            {
                "lat": 49.9482310297,
                "lng": -125.0028905386
            },
            {
                "lat": 49.9618796177,
                "lng": -124.9118720631
            },
            {
                "lat": 49.9856948796,
                "lng": -124.7750777055
            },
            {
                "lat": 49.9862743148,
                "lng": -124.7737198786
            },
            {
                "lat": 49.9881592458,
                "lng": -124.7720096218
            },
            {
                "lat": 49.9930415331,
                "lng": -124.7686685023
            },
            {
                "lat": 50.0098880066,
                "lng": -124.7812963866
            },
            {
                "lat": 50.0301131912,
                "lng": -124.8003299032
            },
            {
                "lat": 50.048553228,
                "lng": -124.8199623473
            },
            {
                "lat": 50.0592229337,
                "lng": -124.8302841464
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 515,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 15,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 15-3",
            "LABEL": "15-3",
            "FISHERY_AREA_ID": 2166,
            "OBJECTID": 659,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 156074132.9619,
            "FEATURE_LENGTH_M": 51826.9293,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.02868843455716,
            "lng": -124.89910451489712
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.0418849755,
                "lng": -125.2488789137
            },
            {
                "lat": 50.0424075387,
                "lng": -125.2499470904
            },
            {
                "lat": 50.0443995557,
                "lng": -125.2506176574
            },
            {
                "lat": 50.0443997001,
                "lng": -125.2369154919
            },
            {
                "lat": 50.0732009904,
                "lng": -125.2585002574
            },
            {
                "lat": 50.073200787,
                "lng": -125.2768703624
            },
            {
                "lat": 50.0713427074,
                "lng": -125.2788469822
            },
            {
                "lat": 50.0670702468,
                "lng": -125.2747570625
            },
            {
                "lat": 50.0636862369,
                "lng": -125.2717292259
            },
            {
                "lat": 50.0588794246,
                "lng": -125.2687002304
            },
            {
                "lat": 50.051761901,
                "lng": -125.2669222629
            },
            {
                "lat": 50.0469515423,
                "lng": -125.2686996188
            },
            {
                "lat": 50.0416102584,
                "lng": -125.270835873
            },
            {
                "lat": 50.0387649519,
                "lng": -125.2713699976
            },
            {
                "lat": 50.0384062593,
                "lng": -125.2743981384
            },
            {
                "lat": 50.0398327587,
                "lng": -125.280097592
            },
            {
                "lat": 50.0410773433,
                "lng": -125.2923811607
            },
            {
                "lat": 50.043925653,
                "lng": -125.2989653838
            },
            {
                "lat": 50.0462414332,
                "lng": -125.306617953
            },
            {
                "lat": 50.0481983794,
                "lng": -125.3121420609
            },
            {
                "lat": 50.0478441237,
                "lng": -125.315521247
            },
            {
                "lat": 50.0469515684,
                "lng": -125.3181910818
            },
            {
                "lat": 50.0449943252,
                "lng": -125.3194350732
            },
            {
                "lat": 50.0437464806,
                "lng": -125.3183664241
            },
            {
                "lat": 50.0423236858,
                "lng": -125.3132097545
            },
            {
                "lat": 50.0376966004,
                "lng": -125.302702995
            },
            {
                "lat": 50.0344924663,
                "lng": -125.2930905812
            },
            {
                "lat": 50.0325319848,
                "lng": -125.2829434967
            },
            {
                "lat": 50.0311083452,
                "lng": -125.2729719079
            },
            {
                "lat": 50.0311082813,
                "lng": -125.2676311968
            },
            {
                "lat": 50.0323564767,
                "lng": -125.2621149971
            },
            {
                "lat": 50.0332448346,
                "lng": -125.2567753529
            },
            {
                "lat": 50.0344925794,
                "lng": -125.2526771954
            },
            {
                "lat": 50.0357361715,
                "lng": -125.2494740354
            },
            {
                "lat": 50.0382877877,
                "lng": -125.2486502497
            },
            {
                "lat": 50.0400889731,
                "lng": -125.2484443069
            },
            {
                "lat": 50.0411225858,
                "lng": -125.2486488999
            },
            {
                "lat": 50.0418849755,
                "lng": -125.2488789137
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 516,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 13-5",
            "LABEL": "13-5",
            "FISHERY_AREA_ID": 2111,
            "OBJECTID": 660,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 9938259.2253,
            "FEATURE_LENGTH_M": 20371.9316,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.04492776025263,
            "lng": -125.2757347638026
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.763283145,
                "lng": -127.270797449
            },
            {
                "lat": 50.0599667221,
                "lng": -127.8417509956
            },
            {
                "lat": 49.5833320832,
                "lng": -128.4534304496
            },
            {
                "lat": 49.5833319558,
                "lng": -127.5018690701
            },
            {
                "lat": 49.763283145,
                "lng": -127.270797449
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 517,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 126,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 126-2",
            "LABEL": "126-2",
            "FISHERY_AREA_ID": 2336,
            "OBJECTID": 661,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2506405169.6425,
            "FEATURE_LENGTH_M": 216437.7331,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.750639410219996,
            "lng": -127.66772908266
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.0459245796,
                "lng": -127.4257360506
            },
            {
                "lat": 50.0413322917,
                "lng": -127.4227674637
            },
            {
                "lat": 50.0380629754,
                "lng": -127.4211348064
            },
            {
                "lat": 50.0375788872,
                "lng": -127.4208910846
            },
            {
                "lat": 50.0371513527,
                "lng": -127.4205321988
            },
            {
                "lat": 50.0369105113,
                "lng": -127.420165174
            },
            {
                "lat": 50.0367245012,
                "lng": -127.4198600645
            },
            {
                "lat": 50.0364765077,
                "lng": -127.4193497157
            },
            {
                "lat": 50.0355497925,
                "lng": -127.4193496651
            },
            {
                "lat": 50.0345950736,
                "lng": -127.4186018036
            },
            {
                "lat": 50.0345649026,
                "lng": -127.4183268761
            },
            {
                "lat": 50.0313025666,
                "lng": -127.4160153193
            },
            {
                "lat": 50.0288394778,
                "lng": -127.4140848284
            },
            {
                "lat": 50.026646084,
                "lng": -127.4125066483
            },
            {
                "lat": 50.025585876,
                "lng": -127.4125287598
            },
            {
                "lat": 50.0246813705,
                "lng": -127.4131166441
            },
            {
                "lat": 50.0244520624,
                "lng": -127.4134672138
            },
            {
                "lat": 50.0243456393,
                "lng": -127.413657583
            },
            {
                "lat": 50.0242574933,
                "lng": -127.4138258361
            },
            {
                "lat": 49.9739033321,
                "lng": -127.2848050492
            },
            {
                "lat": 49.9740335755,
                "lng": -127.2845769257
            },
            {
                "lat": 49.9744455855,
                "lng": -127.2843323287
            },
            {
                "lat": 49.9753001408,
                "lng": -127.2840191409
            },
            {
                "lat": 49.9796563131,
                "lng": -127.2829128691
            },
            {
                "lat": 49.9816016397,
                "lng": -127.2823182471
            },
            {
                "lat": 49.9832873161,
                "lng": -127.2826845968
            },
            {
                "lat": 49.9849697542,
                "lng": -127.2829436834
            },
            {
                "lat": 49.9865261283,
                "lng": -127.2837457469
            },
            {
                "lat": 50.0374334297,
                "lng": -127.2794803487
            },
            {
                "lat": 50.0501899801,
                "lng": -127.2794797632
            },
            {
                "lat": 50.0532728709,
                "lng": -127.2794798992
            },
            {
                "lat": 50.0566139218,
                "lng": -127.279701168
            },
            {
                "lat": 50.0565266821,
                "lng": -127.2849653149
            },
            {
                "lat": 50.0560108389,
                "lng": -127.2891392057
            },
            {
                "lat": 50.0536733861,
                "lng": -127.2977140235
            },
            {
                "lat": 50.0513341624,
                "lng": -127.3148656369
            },
            {
                "lat": 50.0486066493,
                "lng": -127.3394241038
            },
            {
                "lat": 50.0513341771,
                "lng": -127.3538517763
            },
            {
                "lat": 50.04977467,
                "lng": -127.3628159036
            },
            {
                "lat": 50.0478291036,
                "lng": -127.3823079682
            },
            {
                "lat": 50.0497745097,
                "lng": -127.4045332705
            },
            {
                "lat": 50.0501667244,
                "lng": -127.4150547349
            },
            {
                "lat": 50.0459245796,
                "lng": -127.4257360506
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 518,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 26,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 26-6",
            "LABEL": "26-6",
            "FISHERY_AREA_ID": 2495,
            "OBJECTID": 662,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 56190681.6525,
            "FEATURE_LENGTH_M": 33534.0771,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.02784119573021,
            "lng": -127.35992570910929
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.0540583121,
                "lng": -125.2215421689
            },
            {
                "lat": 50.0566488304,
                "lng": -125.224669944
            },
            {
                "lat": 50.057713019,
                "lng": -125.2278588545
            },
            {
                "lat": 50.0588457497,
                "lng": -125.229454556
            },
            {
                "lat": 50.0589943335,
                "lng": -125.2307736433
            },
            {
                "lat": 50.0589412527,
                "lng": -125.2316195242
            },
            {
                "lat": 50.0585895968,
                "lng": -125.2325978773
            },
            {
                "lat": 50.0580516865,
                "lng": -125.2328949936
            },
            {
                "lat": 50.0565450668,
                "lng": -125.2326270709
            },
            {
                "lat": 50.0547523773,
                "lng": -125.2315452628
            },
            {
                "lat": 50.054222698,
                "lng": -125.23100955
            },
            {
                "lat": 50.0536268006,
                "lng": -125.2304068881
            },
            {
                "lat": 50.0523679618,
                "lng": -125.2311019417
            },
            {
                "lat": 50.0416064766,
                "lng": -125.2214494065
            },
            {
                "lat": 50.0405389504,
                "lng": -125.219877821
            },
            {
                "lat": 50.0406917714,
                "lng": -125.2178194298
            },
            {
                "lat": 50.03995961,
                "lng": -125.2156907382
            },
            {
                "lat": 50.0400279522,
                "lng": -125.2133022428
            },
            {
                "lat": 50.0420877831,
                "lng": -125.2125005531
            },
            {
                "lat": 50.046009325,
                "lng": -125.2133648145
            },
            {
                "lat": 50.050201557,
                "lng": -125.2181536907
            },
            {
                "lat": 50.0540583121,
                "lng": -125.2215421689
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 519,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 13-4",
            "LABEL": "13-4",
            "FISHERY_AREA_ID": 2110,
            "OBJECTID": 663,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1430725.0296,
            "FEATURE_LENGTH_M": 5560.4872,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.051297246500006,
            "lng": -125.22462741549093
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.9901394144,
                "lng": -123.8881612945
            },
            {
                "lat": 50.0039637558,
                "lng": -123.9040529304
            },
            {
                "lat": 50.0050428788,
                "lng": -123.9052506143
            },
            {
                "lat": 50.0253332477,
                "lng": -123.9257511943
            },
            {
                "lat": 50.0259475455,
                "lng": -123.9258349393
            },
            {
                "lat": 50.0292552598,
                "lng": -123.926886984
            },
            {
                "lat": 50.0392111572,
                "lng": -123.9327466652
            },
            {
                "lat": 50.0355952364,
                "lng": -123.9402089375
            },
            {
                "lat": 50.0305901734,
                "lng": -123.9535970184
            },
            {
                "lat": 50.0293992729,
                "lng": -123.9744180913
            },
            {
                "lat": 50.0222621967,
                "lng": -123.9928591189
            },
            {
                "lat": 50.0151259205,
                "lng": -124.0202255354
            },
            {
                "lat": 50.0062025735,
                "lng": -124.0291446827
            },
            {
                "lat": 49.9853814366,
                "lng": -124.0273587763
            },
            {
                "lat": 49.9651567876,
                "lng": -124.0184396791
            },
            {
                "lat": 49.9437408237,
                "lng": -124.0059429946
            },
            {
                "lat": 49.9205396915,
                "lng": -123.9910725819
            },
            {
                "lat": 49.8937722537,
                "lng": -123.9672774878
            },
            {
                "lat": 49.8898499121,
                "lng": -123.9527731569
            },
            {
                "lat": 49.887820827,
                "lng": -123.9452674317
            },
            {
                "lat": 49.875926695,
                "lng": -123.9345628027
            },
            {
                "lat": 49.8616485314,
                "lng": -123.9339673345
            },
            {
                "lat": 49.8521305831,
                "lng": -123.9482429973
            },
            {
                "lat": 49.8422698124,
                "lng": -123.9622654259
            },
            {
                "lat": 49.8236468563,
                "lng": -123.9734493053
            },
            {
                "lat": 49.804377861,
                "lng": -123.9698258377
            },
            {
                "lat": 49.7757301977,
                "lng": -123.9468242295
            },
            {
                "lat": 49.7754672691,
                "lng": -123.9460289139
            },
            {
                "lat": 49.7750252553,
                "lng": -123.9440544419
            },
            {
                "lat": 49.7750252284,
                "lng": -123.9416349348
            },
            {
                "lat": 49.7760353243,
                "lng": -123.940093422
            },
            {
                "lat": 49.7747992768,
                "lng": -123.917906461
            },
            {
                "lat": 49.7807470058,
                "lng": -123.9054104452
            },
            {
                "lat": 49.8033522918,
                "lng": -123.876265893
            },
            {
                "lat": 49.8408277569,
                "lng": -123.8578269819
            },
            {
                "lat": 49.8658137869,
                "lng": -123.8518746035
            },
            {
                "lat": 49.883034041,
                "lng": -123.8485796938
            },
            {
                "lat": 49.9062657895,
                "lng": -123.8441390325
            },
            {
                "lat": 49.9407653677,
                "lng": -123.8500904005
            },
            {
                "lat": 49.9455258197,
                "lng": -123.8697203889
            },
            {
                "lat": 49.9389794422,
                "lng": -123.9143366859
            },
            {
                "lat": 49.9556389321,
                "lng": -123.9488376194
            },
            {
                "lat": 49.9794314952,
                "lng": -123.9452665263
            },
            {
                "lat": 49.9824059147,
                "lng": -123.9262308064
            },
            {
                "lat": 49.9901394144,
                "lng": -123.8881612945
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 520,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 16-13",
            "LABEL": "16-13",
            "FISHERY_AREA_ID": 2182,
            "OBJECTID": 664,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 204108790.2617,
            "FEATURE_LENGTH_M": 80625.2851,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.9170964514111,
            "lng": -123.93584147983998
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.9753001408,
                "lng": -127.2840191409
            },
            {
                "lat": 49.9755899911,
                "lng": -127.2829746224
            },
            {
                "lat": 49.9761395357,
                "lng": -127.2823103539
            },
            {
                "lat": 49.9807319559,
                "lng": -127.2780992266
            },
            {
                "lat": 49.9883155686,
                "lng": -127.2760313475
            },
            {
                "lat": 50.0024795207,
                "lng": -127.2761842187
            },
            {
                "lat": 50.00730165,
                "lng": -127.2762598218
            },
            {
                "lat": 50.0314984993,
                "lng": -127.2776421148
            },
            {
                "lat": 50.0360873186,
                "lng": -127.2787244487
            },
            {
                "lat": 50.0368584274,
                "lng": -127.2791518242
            },
            {
                "lat": 50.0374334297,
                "lng": -127.2794803487
            },
            {
                "lat": 49.9865261283,
                "lng": -127.2837457469
            },
            {
                "lat": 49.9849697542,
                "lng": -127.2829436834
            },
            {
                "lat": 49.9832873161,
                "lng": -127.2826845968
            },
            {
                "lat": 49.9816016397,
                "lng": -127.2823182471
            },
            {
                "lat": 49.9796563131,
                "lng": -127.2829128691
            },
            {
                "lat": 49.9753001408,
                "lng": -127.2840191409
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 521,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 665,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2328693.0308,
            "FEATURE_LENGTH_M": 14031.6141,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.996416313529416,
            "lng": -127.28055892661175
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.025585876,
                "lng": -127.4125287598
            },
            {
                "lat": 50.026646084,
                "lng": -127.4125066483
            },
            {
                "lat": 50.0288394778,
                "lng": -127.4140848284
            },
            {
                "lat": 50.0313025666,
                "lng": -127.4160153193
            },
            {
                "lat": 50.0345649026,
                "lng": -127.4183268761
            },
            {
                "lat": 50.0345950736,
                "lng": -127.4186018036
            },
            {
                "lat": 50.0340770238,
                "lng": -127.4190289467
            },
            {
                "lat": 50.0328255332,
                "lng": -127.4186322506
            },
            {
                "lat": 50.0326919365,
                "lng": -127.4185329936
            },
            {
                "lat": 50.0302692754,
                "lng": -127.4177857971
            },
            {
                "lat": 50.0300595792,
                "lng": -127.4177478666
            },
            {
                "lat": 50.0275914239,
                "lng": -127.4169539746
            },
            {
                "lat": 50.0262563082,
                "lng": -127.4148021046
            },
            {
                "lat": 50.0261269707,
                "lng": -127.4134981965
            },
            {
                "lat": 50.025585876,
                "lng": -127.4125287598
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 522,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 666,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 162047.485,
            "FEATURE_LENGTH_M": 2315.5552,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.02980119383334,
            "lng": -127.41610500837332
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.0284074284,
                "lng": -127.1761400721
            },
            {
                "lat": 50.0290560401,
                "lng": -127.181106889
            },
            {
                "lat": 50.0276532315,
                "lng": -127.1831590523
            },
            {
                "lat": 50.0257182127,
                "lng": -127.1839061749
            },
            {
                "lat": 50.0210538444,
                "lng": -127.1838077643
            },
            {
                "lat": 50.016555197,
                "lng": -127.1839212538
            },
            {
                "lat": 50.0016752917,
                "lng": -127.1839143966
            },
            {
                "lat": 49.994152589,
                "lng": -127.1813044786
            },
            {
                "lat": 49.9901396113,
                "lng": -127.169265684
            },
            {
                "lat": 49.9901395248,
                "lng": -127.1495293799
            },
            {
                "lat": 49.9884680773,
                "lng": -127.1317975137
            },
            {
                "lat": 49.9898028665,
                "lng": -127.1224289458
            },
            {
                "lat": 49.9938203648,
                "lng": -127.1180871679
            },
            {
                "lat": 49.9981685128,
                "lng": -127.1170804021
            },
            {
                "lat": 50.001846379,
                "lng": -127.1194225319
            },
            {
                "lat": 50.0071981247,
                "lng": -127.1321331391
            },
            {
                "lat": 50.0105435659,
                "lng": -127.1364822506
            },
            {
                "lat": 50.013217702,
                "lng": -127.1354753117
            },
            {
                "lat": 50.0135540734,
                "lng": -127.1221002991
            },
            {
                "lat": 50.0145564552,
                "lng": -127.1047060079
            },
            {
                "lat": 50.0168987109,
                "lng": -127.0913235103
            },
            {
                "lat": 50.0175703425,
                "lng": -127.0802838577
            },
            {
                "lat": 50.0202447014,
                "lng": -127.0742642942
            },
            {
                "lat": 50.0265997228,
                "lng": -127.072258446
            },
            {
                "lat": 50.031952199,
                "lng": -127.0755992973
            },
            {
                "lat": 50.0379756179,
                "lng": -127.084296941
            },
            {
                "lat": 50.0383077358,
                "lng": -127.0903168796
            },
            {
                "lat": 50.0359653492,
                "lng": -127.0980150352
            },
            {
                "lat": 50.0339583905,
                "lng": -127.1130678405
            },
            {
                "lat": 50.0339582462,
                "lng": -127.1284569532
            },
            {
                "lat": 50.034293313,
                "lng": -127.1378176524
            },
            {
                "lat": 50.0336267051,
                "lng": -127.1515349712
            },
            {
                "lat": 50.0312843769,
                "lng": -127.1595617113
            },
            {
                "lat": 50.0279386959,
                "lng": -127.1689298602
            },
            {
                "lat": 50.0284074284,
                "lng": -127.1761400721
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 523,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 26,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 26-3",
            "LABEL": "26-3",
            "FISHERY_AREA_ID": 2492,
            "OBJECTID": 667,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 27407550.5467,
            "FEATURE_LENGTH_M": 25670.7627,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.01727738937143,
            "lng": -127.13478960107142
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.0242574933,
                "lng": -127.4138258361
            },
            {
                "lat": 49.9976727422,
                "lng": -127.455559089
            },
            {
                "lat": 49.8594099931,
                "lng": -127.1521148891
            },
            {
                "lat": 49.863441383,
                "lng": -127.1493226251
            },
            {
                "lat": 49.8745007272,
                "lng": -127.1578681177
            },
            {
                "lat": 49.8890807244,
                "lng": -127.1686714704
            },
            {
                "lat": 49.9064215982,
                "lng": -127.1814888741
            },
            {
                "lat": 49.9209981282,
                "lng": -127.1902845683
            },
            {
                "lat": 49.9333150288,
                "lng": -127.1975779561
            },
            {
                "lat": 49.942360458,
                "lng": -127.200087751
            },
            {
                "lat": 49.948646999,
                "lng": -127.2038574899
            },
            {
                "lat": 49.9539225553,
                "lng": -127.2134092978
            },
            {
                "lat": 49.9614639427,
                "lng": -127.2269824334
            },
            {
                "lat": 49.9647180625,
                "lng": -127.2301408475
            },
            {
                "lat": 49.9653548455,
                "lng": -127.2336428412
            },
            {
                "lat": 49.9661523503,
                "lng": -127.2363887028
            },
            {
                "lat": 49.9665523656,
                "lng": -127.2398383015
            },
            {
                "lat": 49.9660647683,
                "lng": -127.2428978669
            },
            {
                "lat": 49.9661939492,
                "lng": -127.2470162027
            },
            {
                "lat": 49.9660801963,
                "lng": -127.2482223593
            },
            {
                "lat": 49.9659766175,
                "lng": -127.2492745747
            },
            {
                "lat": 49.9659309924,
                "lng": -127.2514043421
            },
            {
                "lat": 49.9667286729,
                "lng": -127.2519306586
            },
            {
                "lat": 49.9677468492,
                "lng": -127.2522434703
            },
            {
                "lat": 49.9686774242,
                "lng": -127.2521060701
            },
            {
                "lat": 49.9693406932,
                "lng": -127.2521965692
            },
            {
                "lat": 49.9698295518,
                "lng": -127.2521062987
            },
            {
                "lat": 49.9700273878,
                "lng": -127.2520071677
            },
            {
                "lat": 49.9701765478,
                "lng": -127.2519380682
            },
            {
                "lat": 49.9702718906,
                "lng": -127.2519066945
            },
            {
                "lat": 49.9703592407,
                "lng": -127.2520824872
            },
            {
                "lat": 49.9715574025,
                "lng": -127.2631220328
            },
            {
                "lat": 49.9739033321,
                "lng": -127.2848050492
            },
            {
                "lat": 50.0242574933,
                "lng": -127.4138258361
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 524,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 26,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 26-1",
            "LABEL": "26-1",
            "FISHERY_AREA_ID": 2284,
            "OBJECTID": 668,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 113317508.5459,
            "FEATURE_LENGTH_M": 59543.0834,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.95474683550294,
            "lng": -127.24471020115588
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.0116997342,
                "lng": -124.9865423273
            },
            {
                "lat": 50.0115928218,
                "lng": -125.1467064559
            },
            {
                "lat": 50.0117379273,
                "lng": -125.1478809777
            },
            {
                "lat": 50.0117078997,
                "lng": -125.155051333
            },
            {
                "lat": 50.0089758115,
                "lng": -125.1635740183
            },
            {
                "lat": 50.006122274,
                "lng": -125.1752385144
            },
            {
                "lat": 50.003029049,
                "lng": -125.184754063
            },
            {
                "lat": 50.0037345657,
                "lng": -125.1905672783
            },
            {
                "lat": 49.9985540087,
                "lng": -125.1956090759
            },
            {
                "lat": 49.9421763898,
                "lng": -125.1851955828
            },
            {
                "lat": 49.9502334243,
                "lng": -125.0122069965
            },
            {
                "lat": 50.0116997342,
                "lng": -124.9865423273
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 525,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 13-1",
            "LABEL": "13-1",
            "FISHERY_AREA_ID": 2107,
            "OBJECTID": 669,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 98208672.8589,
            "FEATURE_LENGTH_M": 41369.3731,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.99760530334999,
            "lng": -125.1274890792
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.9985540087,
                "lng": -125.1956090759
            },
            {
                "lat": 49.9985542852,
                "lng": -125.2299043262
            },
            {
                "lat": 49.9971498642,
                "lng": -125.2369681664
            },
            {
                "lat": 49.9926763576,
                "lng": -125.234963205
            },
            {
                "lat": 49.9786456261,
                "lng": -125.2292555059
            },
            {
                "lat": 49.966460933,
                "lng": -125.2209325957
            },
            {
                "lat": 49.956283912,
                "lng": -125.2116771885
            },
            {
                "lat": 49.9476468582,
                "lng": -125.204894703
            },
            {
                "lat": 49.9382405984,
                "lng": -125.1998991056
            },
            {
                "lat": 49.9421763898,
                "lng": -125.1851955828
            },
            {
                "lat": 49.9985540087,
                "lng": -125.1956090759
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 526,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 13-2",
            "LABEL": "13-2",
            "FISHERY_AREA_ID": 2108,
            "OBJECTID": 670,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 14320125.3708,
            "FEATURE_LENGTH_M": 17554.0538,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.9740857129,
            "lng": -125.21317350280907
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.9930415331,
                "lng": -124.7686685023
            },
            {
                "lat": 49.9881592458,
                "lng": -124.7720096218
            },
            {
                "lat": 49.9862743148,
                "lng": -124.7737198786
            },
            {
                "lat": 49.9856948796,
                "lng": -124.7750777055
            },
            {
                "lat": 49.9618796177,
                "lng": -124.9118720631
            },
            {
                "lat": 49.9482310297,
                "lng": -125.0028905386
            },
            {
                "lat": 49.9491811055,
                "lng": -125.0028610925
            },
            {
                "lat": 49.9495886185,
                "lng": -125.0040435385
            },
            {
                "lat": 49.9497559505,
                "lng": -125.006941617
            },
            {
                "lat": 49.9502334243,
                "lng": -125.0122069965
            },
            {
                "lat": 49.8138890006,
                "lng": -124.6583323127
            },
            {
                "lat": 49.7995492608,
                "lng": -124.6375811702
            },
            {
                "lat": 49.8573037248,
                "lng": -124.5820612318
            },
            {
                "lat": 49.8730011745,
                "lng": -124.5669707653
            },
            {
                "lat": 49.8726008712,
                "lng": -124.5661920649
            },
            {
                "lat": 49.8725893203,
                "lng": -124.5660393965
            },
            {
                "lat": 49.8724636637,
                "lng": -124.5645147737
            },
            {
                "lat": 49.8726006783,
                "lng": -124.5630045167
            },
            {
                "lat": 49.8729899124,
                "lng": -124.5614393682
            },
            {
                "lat": 49.8736226921,
                "lng": -124.5592039594
            },
            {
                "lat": 49.8748124663,
                "lng": -124.5526658506
            },
            {
                "lat": 49.879569543,
                "lng": -124.547309777
            },
            {
                "lat": 49.8884925944,
                "lng": -124.5663445514
            },
            {
                "lat": 49.9027709065,
                "lng": -124.5925207708
            },
            {
                "lat": 49.922400815,
                "lng": -124.6359407235
            },
            {
                "lat": 49.9360811573,
                "lng": -124.6722331208
            },
            {
                "lat": 49.9527400744,
                "lng": -124.7073286609
            },
            {
                "lat": 49.9634479026,
                "lng": -124.7263641026
            },
            {
                "lat": 49.9806971407,
                "lng": -124.7436145545
            },
            {
                "lat": 49.990810296,
                "lng": -124.760268019
            },
            {
                "lat": 49.9930415331,
                "lng": -124.7686685023
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 527,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 15,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 15-2",
            "LABEL": "15-2",
            "FISHERY_AREA_ID": 2165,
            "OBJECTID": 671,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 292951173.0301,
            "FEATURE_LENGTH_M": 82266.7571,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.92024240153226,
            "lng": -124.71383515313548
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.9638283407,
                "lng": -126.8669285745
            },
            {
                "lat": 49.9652210717,
                "lng": -126.8718029212
            },
            {
                "lat": 49.9656863668,
                "lng": -126.8813021229
            },
            {
                "lat": 49.9663814431,
                "lng": -126.8975375166
            },
            {
                "lat": 49.966614601,
                "lng": -126.9109799494
            },
            {
                "lat": 49.9691615061,
                "lng": -126.9167790923
            },
            {
                "lat": 49.9798273384,
                "lng": -126.9207160298
            },
            {
                "lat": 49.9870149713,
                "lng": -126.9269794114
            },
            {
                "lat": 49.9909554822,
                "lng": -126.9348606439
            },
            {
                "lat": 49.992813741,
                "lng": -126.9445952652
            },
            {
                "lat": 49.992115328,
                "lng": -126.9494699803
            },
            {
                "lat": 49.9846961878,
                "lng": -126.953178252
            },
            {
                "lat": 49.9786678041,
                "lng": -126.9541015587
            },
            {
                "lat": 49.9575725405,
                "lng": -126.9538734028
            },
            {
                "lat": 49.9167674782,
                "lng": -126.9529419203
            },
            {
                "lat": 49.8910327681,
                "lng": -126.9485406723
            },
            {
                "lat": 49.8773539509,
                "lng": -126.9520184913
            },
            {
                "lat": 49.8703156523,
                "lng": -126.9479069946
            },
            {
                "lat": 49.8713379554,
                "lng": -126.9394379447
            },
            {
                "lat": 49.8709756258,
                "lng": -126.9373162287
            },
            {
                "lat": 49.8688125417,
                "lng": -126.934920421
            },
            {
                "lat": 49.8688161566,
                "lng": -126.9152679046
            },
            {
                "lat": 49.8688159484,
                "lng": -126.9090192813
            },
            {
                "lat": 49.8731807666,
                "lng": -126.9077375545
            },
            {
                "lat": 49.8838465431,
                "lng": -126.9116753874
            },
            {
                "lat": 49.8998408722,
                "lng": -126.9116749679
            },
            {
                "lat": 49.9123610981,
                "lng": -126.9121395344
            },
            {
                "lat": 49.9209398357,
                "lng": -126.9098207814
            },
            {
                "lat": 49.9265056926,
                "lng": -126.9044871587
            },
            {
                "lat": 49.9329948665,
                "lng": -126.8982311133
            },
            {
                "lat": 49.9427341262,
                "lng": -126.8977660616
            },
            {
                "lat": 49.9487609491,
                "lng": -126.8952171521
            },
            {
                "lat": 49.9515457137,
                "lng": -126.8884971158
            },
            {
                "lat": 49.9510798323,
                "lng": -126.8745802094
            },
            {
                "lat": 49.9531671985,
                "lng": -126.8666992437
            },
            {
                "lat": 49.9544337194,
                "lng": -126.8650136235
            },
            {
                "lat": 49.9552533228,
                "lng": -126.8639143289
            },
            {
                "lat": 49.9601205748,
                "lng": -126.8648460834
            },
            {
                "lat": 49.9638283407,
                "lng": -126.8669285745
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 528,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 25-11",
            "LABEL": "25-11",
            "FISHERY_AREA_ID": 2278,
            "OBJECTID": 672,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 46115046.0185,
            "FEATURE_LENGTH_M": 36944.0556,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.93577892954871,
            "lng": -126.91178726846924
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.9638283407,
                "lng": -126.8669285745
            },
            {
                "lat": 49.9601205748,
                "lng": -126.8648460834
            },
            {
                "lat": 49.9552533228,
                "lng": -126.8639143289
            },
            {
                "lat": 49.9544337194,
                "lng": -126.8650136235
            },
            {
                "lat": 49.9501534294,
                "lng": -126.8609005246
            },
            {
                "lat": 49.9464420985,
                "lng": -126.8490750473
            },
            {
                "lat": 49.942501179,
                "lng": -126.8307649341
            },
            {
                "lat": 49.9420390017,
                "lng": -126.8207931881
            },
            {
                "lat": 49.9442022226,
                "lng": -126.8189704236
            },
            {
                "lat": 49.9443811112,
                "lng": -126.8182683339
            },
            {
                "lat": 49.9441449292,
                "lng": -126.8004681159
            },
            {
                "lat": 49.9405825385,
                "lng": -126.7951347258
            },
            {
                "lat": 49.9420389905,
                "lng": -126.7911226986
            },
            {
                "lat": 49.949920233,
                "lng": -126.7913514941
            },
            {
                "lat": 49.9566421646,
                "lng": -126.7955249175
            },
            {
                "lat": 49.9605863777,
                "lng": -126.8054964969
            },
            {
                "lat": 49.9652208836,
                "lng": -126.826362618
            },
            {
                "lat": 49.9693940863,
                "lng": -126.8388747329
            },
            {
                "lat": 49.9779740699,
                "lng": -126.8374857005
            },
            {
                "lat": 49.9877087467,
                "lng": -126.8421249959
            },
            {
                "lat": 49.9902605271,
                "lng": -126.8523255243
            },
            {
                "lat": 49.9893340544,
                "lng": -126.8604428654
            },
            {
                "lat": 49.9863199778,
                "lng": -126.8648457221
            },
            {
                "lat": 49.9779740374,
                "lng": -126.8653106592
            },
            {
                "lat": 49.9698601372,
                "lng": -126.8657693591
            },
            {
                "lat": 49.9638283407,
                "lng": -126.8669285745
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 529,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 25-10",
            "LABEL": "25-10",
            "FISHERY_AREA_ID": 2277,
            "OBJECTID": 673,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 15907108.2469,
            "FEATURE_LENGTH_M": 18751.4046,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.9605825036423,
            "lng": -126.83688631779229
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.8094210028,
                "lng": -124.1126633332
            },
            {
                "lat": 49.8046608278,
                "lng": -124.0966041433
            },
            {
                "lat": 49.8022800503,
                "lng": -124.0852953692
            },
            {
                "lat": 49.8009866172,
                "lng": -124.0814664911
            },
            {
                "lat": 49.8003699878,
                "lng": -124.0786600333
            },
            {
                "lat": 49.8004220565,
                "lng": -124.0777201259
            },
            {
                "lat": 49.804377861,
                "lng": -123.9698258377
            },
            {
                "lat": 49.8236468563,
                "lng": -123.9734493053
            },
            {
                "lat": 49.8229821336,
                "lng": -123.9815595976
            },
            {
                "lat": 49.8503454943,
                "lng": -123.9803704854
            },
            {
                "lat": 49.8717617,
                "lng": -123.9934543423
            },
            {
                "lat": 49.8902018153,
                "lng": -124.0107046497
            },
            {
                "lat": 49.9139983656,
                "lng": -124.009514106
            },
            {
                "lat": 49.9366038322,
                "lng": -124.0214146535
            },
            {
                "lat": 49.9437409065,
                "lng": -124.0297389079
            },
            {
                "lat": 49.9433746156,
                "lng": -124.0460893161
            },
            {
                "lat": 49.92648731,
                "lng": -124.0571061508
            },
            {
                "lat": 49.9026943184,
                "lng": -124.0779266267
            },
            {
                "lat": 49.8723564279,
                "lng": -124.094581273
            },
            {
                "lat": 49.832500117,
                "lng": -124.1040958144
            },
            {
                "lat": 49.8094210028,
                "lng": -124.1126633332
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 530,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 16-12",
            "LABEL": "16-12",
            "FISHERY_AREA_ID": 2181,
            "OBJECTID": 674,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 102883969.7779,
            "FEATURE_LENGTH_M": 44503.1075,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.855363490423805,
            "lng": -124.04737637598097
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.9420390017,
                "lng": -126.8207931881
            },
            {
                "lat": 49.942501179,
                "lng": -126.8307649341
            },
            {
                "lat": 49.9464420985,
                "lng": -126.8490750473
            },
            {
                "lat": 49.9501534294,
                "lng": -126.8609005246
            },
            {
                "lat": 49.9544337194,
                "lng": -126.8650136235
            },
            {
                "lat": 49.9531671985,
                "lng": -126.8666992437
            },
            {
                "lat": 49.9510798323,
                "lng": -126.8745802094
            },
            {
                "lat": 49.9515457137,
                "lng": -126.8884971158
            },
            {
                "lat": 49.9487609491,
                "lng": -126.8952171521
            },
            {
                "lat": 49.9427341262,
                "lng": -126.8977660616
            },
            {
                "lat": 49.9329948665,
                "lng": -126.8982311133
            },
            {
                "lat": 49.9265056926,
                "lng": -126.9044871587
            },
            {
                "lat": 49.9209398357,
                "lng": -126.9098207814
            },
            {
                "lat": 49.9123610981,
                "lng": -126.9121395344
            },
            {
                "lat": 49.8998408722,
                "lng": -126.9116749679
            },
            {
                "lat": 49.8838465431,
                "lng": -126.9116753874
            },
            {
                "lat": 49.8731807666,
                "lng": -126.9077375545
            },
            {
                "lat": 49.8741069505,
                "lng": -126.90100929
            },
            {
                "lat": 49.8801339362,
                "lng": -126.872031971
            },
            {
                "lat": 49.8856997161,
                "lng": -126.8428186444
            },
            {
                "lat": 49.8880199626,
                "lng": -126.8258968282
            },
            {
                "lat": 49.8891797813,
                "lng": -126.8221885525
            },
            {
                "lat": 49.8935811112,
                "lng": -126.8217240512
            },
            {
                "lat": 49.9160724558,
                "lng": -126.8212589795
            },
            {
                "lat": 49.9362417017,
                "lng": -126.8198698474
            },
            {
                "lat": 49.9420390017,
                "lng": -126.8207931881
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 531,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 675,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 45915142.7033,
            "FEATURE_LENGTH_M": 26991.1138,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.92067698229617,
            "lng": -126.86741019038843
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.9502334243,
                "lng": -125.0122069965
            },
            {
                "lat": 49.9421763898,
                "lng": -125.1851955828
            },
            {
                "lat": 49.9382405984,
                "lng": -125.1998991056
            },
            {
                "lat": 49.9283721312,
                "lng": -125.200218094
            },
            {
                "lat": 49.9105453918,
                "lng": -125.1976708016
            },
            {
                "lat": 49.8834912275,
                "lng": -125.1664734958
            },
            {
                "lat": 49.8618429701,
                "lng": -125.1381450922
            },
            {
                "lat": 49.8265110104,
                "lng": -125.0986709594
            },
            {
                "lat": 49.7778048922,
                "lng": -125.020682492
            },
            {
                "lat": 49.7310141578,
                "lng": -124.9500114294
            },
            {
                "lat": 49.7106403283,
                "lng": -124.90544965
            },
            {
                "lat": 49.6972688791,
                "lng": -124.8803013409
            },
            {
                "lat": 49.697883473,
                "lng": -124.8715299327
            },
            {
                "lat": 49.7013969751,
                "lng": -124.8675146279
            },
            {
                "lat": 49.7016529223,
                "lng": -124.8631513885
            },
            {
                "lat": 49.7009813937,
                "lng": -124.8596807757
            },
            {
                "lat": 49.7360990785,
                "lng": -124.6361320524
            },
            {
                "lat": 49.8138890006,
                "lng": -124.6583323127
            },
            {
                "lat": 49.9502334243,
                "lng": -125.0122069965
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 532,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 14-13",
            "LABEL": "14-13",
            "FISHERY_AREA_ID": 2161,
            "OBJECTID": 676,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 624878804.2333,
            "FEATURE_LENGTH_M": 106498.8571,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.81369882465263,
            "lng": -124.98544595403159
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.8647007854,
                "lng": -127.0073157495
            },
            {
                "lat": 49.8674623963,
                "lng": -127.0040514544
            },
            {
                "lat": 49.8679649502,
                "lng": -127.0012824212
            },
            {
                "lat": 49.8641965876,
                "lng": -126.9967572975
            },
            {
                "lat": 49.8618512614,
                "lng": -126.9940186789
            },
            {
                "lat": 49.8605540678,
                "lng": -126.9922782473
            },
            {
                "lat": 49.8605495376,
                "lng": -126.9787973577
            },
            {
                "lat": 49.8607868972,
                "lng": -126.9781496925
            },
            {
                "lat": 49.8616753847,
                "lng": -126.9776619349
            },
            {
                "lat": 49.8639723074,
                "lng": -126.976112486
            },
            {
                "lat": 49.8659558693,
                "lng": -126.9726333744
            },
            {
                "lat": 49.8689733942,
                "lng": -126.9610676785
            },
            {
                "lat": 49.8732457416,
                "lng": -126.9590603987
            },
            {
                "lat": 49.8797802743,
                "lng": -126.9676057127
            },
            {
                "lat": 49.8863146702,
                "lng": -126.9758986781
            },
            {
                "lat": 49.8920934814,
                "lng": -126.9857024646
            },
            {
                "lat": 49.8963660342,
                "lng": -126.9972608963
            },
            {
                "lat": 49.9147152793,
                "lng": -127.0140992409
            },
            {
                "lat": 49.9408526186,
                "lng": -127.034713323
            },
            {
                "lat": 49.9491459881,
                "lng": -127.0425038015
            },
            {
                "lat": 49.9473871111,
                "lng": -127.0533072472
            },
            {
                "lat": 49.9431145047,
                "lng": -127.060852196
            },
            {
                "lat": 49.9252705668,
                "lng": -127.0535583053
            },
            {
                "lat": 49.8858106271,
                "lng": -127.0339588633
            },
            {
                "lat": 49.8694760039,
                "lng": -127.0168688587
            },
            {
                "lat": 49.8647007854,
                "lng": -127.0073157495
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 533,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 25-12",
            "LABEL": "25-12",
            "FISHERY_AREA_ID": 2279,
            "OBJECTID": 677,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 27301592.2142,
            "FEATURE_LENGTH_M": 26533.2806,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.886035274069236,
            "lng": -127.00164738879234
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.9405825385,
                "lng": -126.7951347258
            },
            {
                "lat": 49.9441449292,
                "lng": -126.8004681159
            },
            {
                "lat": 49.9443811112,
                "lng": -126.8182683339
            },
            {
                "lat": 49.9442022226,
                "lng": -126.8189704236
            },
            {
                "lat": 49.9420390017,
                "lng": -126.8207931881
            },
            {
                "lat": 49.9362417017,
                "lng": -126.8198698474
            },
            {
                "lat": 49.9160724558,
                "lng": -126.8212589795
            },
            {
                "lat": 49.8935811112,
                "lng": -126.8217240512
            },
            {
                "lat": 49.8891797813,
                "lng": -126.8221885525
            },
            {
                "lat": 49.8865008995,
                "lng": -126.8171921869
            },
            {
                "lat": 49.8865007573,
                "lng": -126.8081966928
            },
            {
                "lat": 49.8865007985,
                "lng": -126.797996768
            },
            {
                "lat": 49.8844065295,
                "lng": -126.7974776233
            },
            {
                "lat": 49.8819582065,
                "lng": -126.7949445728
            },
            {
                "lat": 49.8739781892,
                "lng": -126.7803723
            },
            {
                "lat": 49.8626973752,
                "lng": -126.7683948144
            },
            {
                "lat": 49.8460381713,
                "lng": -126.7517390329
            },
            {
                "lat": 49.839443558,
                "lng": -126.7309193907
            },
            {
                "lat": 49.8385775159,
                "lng": -126.7191162705
            },
            {
                "lat": 49.8417018632,
                "lng": -126.706450889
            },
            {
                "lat": 49.8472512965,
                "lng": -126.694992647
            },
            {
                "lat": 49.8517647035,
                "lng": -126.6889193582
            },
            {
                "lat": 49.85405675,
                "lng": -126.6822738289
            },
            {
                "lat": 49.8546985271,
                "lng": -126.6751244688
            },
            {
                "lat": 49.857928899,
                "lng": -126.6762541104
            },
            {
                "lat": 49.8588985262,
                "lng": -126.674232145
            },
            {
                "lat": 49.8592828692,
                "lng": -126.6734243291
            },
            {
                "lat": 49.864994183,
                "lng": -126.6724322769
            },
            {
                "lat": 49.8663868114,
                "lng": -126.6840587515
            },
            {
                "lat": 49.9405825385,
                "lng": -126.7951347258
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 534,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 25-9",
            "LABEL": "25-9",
            "FISHERY_AREA_ID": 2276,
            "OBJECTID": 678,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 58359631.8194,
            "FEATURE_LENGTH_M": 35397.2608,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.884485794056665,
            "lng": -126.75761078002665
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.9055139808,
                "lng": -126.6737893197
            },
            {
                "lat": 49.9041515634,
                "lng": -126.6684877658
            },
            {
                "lat": 49.9015348143,
                "lng": -126.6660990448
            },
            {
                "lat": 49.9015313993,
                "lng": -126.6476371211
            },
            {
                "lat": 49.9002729903,
                "lng": -126.6434552054
            },
            {
                "lat": 49.9037281879,
                "lng": -126.6392280738
            },
            {
                "lat": 49.910541713,
                "lng": -126.6366426368
            },
            {
                "lat": 49.9179269173,
                "lng": -126.6384658923
            },
            {
                "lat": 49.9243577421,
                "lng": -126.6456602327
            },
            {
                "lat": 49.9278109515,
                "lng": -126.6555401786
            },
            {
                "lat": 49.9272346346,
                "lng": -126.6640852204
            },
            {
                "lat": 49.9177359721,
                "lng": -126.6688839446
            },
            {
                "lat": 49.9055139808,
                "lng": -126.6737893197
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 535,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 16,
            "MANAGEMENT_AREA_NAME": "Subarea 25-16",
            "LABEL": "25-16",
            "FISHERY_AREA_ID": 2283,
            "OBJECTID": 679,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 5947621.9145,
            "FEATURE_LENGTH_M": 9328.9636,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.9113734498,
            "lng": -126.65552030428461
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.9002729903,
                "lng": -126.6434552054
            },
            {
                "lat": 49.9015313993,
                "lng": -126.6476371211
            },
            {
                "lat": 49.9015348143,
                "lng": -126.6660990448
            },
            {
                "lat": 49.9041515634,
                "lng": -126.6684877658
            },
            {
                "lat": 49.9055139808,
                "lng": -126.6737893197
            },
            {
                "lat": 49.8956718176,
                "lng": -126.6762154362
            },
            {
                "lat": 49.8755261539,
                "lng": -126.6787876494
            },
            {
                "lat": 49.8685611581,
                "lng": -126.6766658954
            },
            {
                "lat": 49.864994183,
                "lng": -126.6724322769
            },
            {
                "lat": 49.8592828692,
                "lng": -126.6734243291
            },
            {
                "lat": 49.8588985262,
                "lng": -126.674232145
            },
            {
                "lat": 49.857928899,
                "lng": -126.6762541104
            },
            {
                "lat": 49.8546985271,
                "lng": -126.6751244688
            },
            {
                "lat": 49.84609212,
                "lng": -126.6769177396
            },
            {
                "lat": 49.8330881933,
                "lng": -126.6800453682
            },
            {
                "lat": 49.8203242698,
                "lng": -126.6771535922
            },
            {
                "lat": 49.8039470513,
                "lng": -126.6704186404
            },
            {
                "lat": 49.7786642379,
                "lng": -126.659339782
            },
            {
                "lat": 49.7586745558,
                "lng": -126.6487425189
            },
            {
                "lat": 49.7489886,
                "lng": -126.6395193764
            },
            {
                "lat": 49.7485615557,
                "lng": -126.6391067969
            },
            {
                "lat": 49.7464867846,
                "lng": -126.6372763668
            },
            {
                "lat": 49.7445064335,
                "lng": -126.6372757538
            },
            {
                "lat": 49.7243425332,
                "lng": -126.631805355
            },
            {
                "lat": 49.7219082584,
                "lng": -126.6247636641
            },
            {
                "lat": 49.7218128418,
                "lng": -126.6247866278
            },
            {
                "lat": 49.7216530782,
                "lng": -126.6248555064
            },
            {
                "lat": 49.7215379372,
                "lng": -126.6248392026
            },
            {
                "lat": 49.72100479,
                "lng": -126.624831949
            },
            {
                "lat": 49.7204435484,
                "lng": -126.6248711025
            },
            {
                "lat": 49.7200431833,
                "lng": -126.6250380874
            },
            {
                "lat": 49.7196730031,
                "lng": -126.6249914598
            },
            {
                "lat": 49.7193027275,
                "lng": -126.6248625126
            },
            {
                "lat": 49.7190130522,
                "lng": -126.6245492598
            },
            {
                "lat": 49.7186054791,
                "lng": -126.6239623354
            },
            {
                "lat": 49.7177545413,
                "lng": -126.6226580842
            },
            {
                "lat": 49.7173576241,
                "lng": -126.6221233354
            },
            {
                "lat": 49.7169954482,
                "lng": -126.6215587351
            },
            {
                "lat": 49.7161945297,
                "lng": -126.6209414271
            },
            {
                "lat": 49.715137307,
                "lng": -126.6199724005
            },
            {
                "lat": 49.7139361658,
                "lng": -126.6190032118
            },
            {
                "lat": 49.7130163021,
                "lng": -126.6181946564
            },
            {
                "lat": 49.712218802,
                "lng": -126.6175160057
            },
            {
                "lat": 49.7114376906,
                "lng": -126.6168219916
            },
            {
                "lat": 49.7104879435,
                "lng": -126.6158753922
            },
            {
                "lat": 49.709628595,
                "lng": -126.6145480281
            },
            {
                "lat": 49.7089686129,
                "lng": -126.6138531306
            },
            {
                "lat": 49.7081187476,
                "lng": -126.6129833316
            },
            {
                "lat": 49.70708023,
                "lng": -126.6121223191
            },
            {
                "lat": 49.7061082607,
                "lng": -126.6114735824
            },
            {
                "lat": 49.7047310049,
                "lng": -126.6108168933
            },
            {
                "lat": 49.7038730426,
                "lng": -126.6097331911
            },
            {
                "lat": 49.7027205811,
                "lng": -126.6087348327
            },
            {
                "lat": 49.7016716783,
                "lng": -126.6078643602
            },
            {
                "lat": 49.7002224318,
                "lng": -126.60720861
            },
            {
                "lat": 49.6992223832,
                "lng": -126.6063457931
            },
            {
                "lat": 49.6981618773,
                "lng": -126.6061932321
            },
            {
                "lat": 49.6960523603,
                "lng": -126.6053851277
            },
            {
                "lat": 49.69375648,
                "lng": -126.6046292083
            },
            {
                "lat": 49.6909603713,
                "lng": -126.6045612498
            },
            {
                "lat": 49.6884386311,
                "lng": -126.6039808556
            },
            {
                "lat": 49.6873783313,
                "lng": -126.6034318331
            },
            {
                "lat": 49.6861185451,
                "lng": -126.602104448
            },
            {
                "lat": 49.6858102376,
                "lng": -126.6009757284
            },
            {
                "lat": 49.6853676506,
                "lng": -126.5986547873
            },
            {
                "lat": 49.6846505793,
                "lng": -126.594086208
            },
            {
                "lat": 49.6832500594,
                "lng": -126.5900267337
            },
            {
                "lat": 49.6827390204,
                "lng": -126.588523283
            },
            {
                "lat": 49.682536615,
                "lng": -126.5877458546
            },
            {
                "lat": 49.6825026496,
                "lng": -126.5873722766
            },
            {
                "lat": 49.6824759,
                "lng": -126.5871200096
            },
            {
                "lat": 49.6943281773,
                "lng": -126.5857317399
            },
            {
                "lat": 49.6970640415,
                "lng": -126.5865477638
            },
            {
                "lat": 49.8905332233,
                "lng": -126.6443479179
            },
            {
                "lat": 49.9002729903,
                "lng": -126.6434552054
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 536,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 25-8",
            "LABEL": "25-8",
            "FISHERY_AREA_ID": 2275,
            "OBJECTID": 680,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 69654977.2931,
            "FEATURE_LENGTH_M": 54215.4097,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.75088694372802,
            "lng": -126.62810374054139
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.8819582065,
                "lng": -126.7949445728
            },
            {
                "lat": 49.8844065295,
                "lng": -126.7974776233
            },
            {
                "lat": 49.8865007985,
                "lng": -126.797996768
            },
            {
                "lat": 49.8865007573,
                "lng": -126.8081966928
            },
            {
                "lat": 49.8865008995,
                "lng": -126.8171921869
            },
            {
                "lat": 49.8891797813,
                "lng": -126.8221885525
            },
            {
                "lat": 49.8880199626,
                "lng": -126.8258968282
            },
            {
                "lat": 49.8856997161,
                "lng": -126.8428186444
            },
            {
                "lat": 49.8801339362,
                "lng": -126.872031971
            },
            {
                "lat": 49.8741069505,
                "lng": -126.90100929
            },
            {
                "lat": 49.8731807666,
                "lng": -126.9077375545
            },
            {
                "lat": 49.8688159484,
                "lng": -126.9090192813
            },
            {
                "lat": 49.8688161566,
                "lng": -126.9152679046
            },
            {
                "lat": 49.8688125417,
                "lng": -126.934920421
            },
            {
                "lat": 49.8709756258,
                "lng": -126.9373162287
            },
            {
                "lat": 49.8713379554,
                "lng": -126.9394379447
            },
            {
                "lat": 49.8703156523,
                "lng": -126.9479069946
            },
            {
                "lat": 49.8689733942,
                "lng": -126.9610676785
            },
            {
                "lat": 49.8659558693,
                "lng": -126.9726333744
            },
            {
                "lat": 49.8639723074,
                "lng": -126.976112486
            },
            {
                "lat": 49.8616753847,
                "lng": -126.9776619349
            },
            {
                "lat": 49.8607868972,
                "lng": -126.9781496925
            },
            {
                "lat": 49.8605495376,
                "lng": -126.9787973577
            },
            {
                "lat": 49.8605540678,
                "lng": -126.9922782473
            },
            {
                "lat": 49.8618512614,
                "lng": -126.9940186789
            },
            {
                "lat": 49.8641965876,
                "lng": -126.9967572975
            },
            {
                "lat": 49.8679649502,
                "lng": -127.0012824212
            },
            {
                "lat": 49.8674623963,
                "lng": -127.0040514544
            },
            {
                "lat": 49.8647007854,
                "lng": -127.0073157495
            },
            {
                "lat": 49.8629385005,
                "lng": -127.0120924311
            },
            {
                "lat": 49.861683571,
                "lng": -127.0294338225
            },
            {
                "lat": 49.8629384175,
                "lng": -127.0475314386
            },
            {
                "lat": 49.8669626279,
                "lng": -127.065375553
            },
            {
                "lat": 49.8724900876,
                "lng": -127.0804520087
            },
            {
                "lat": 49.8729930734,
                "lng": -127.0962908524
            },
            {
                "lat": 49.8719862329,
                "lng": -127.1108631883
            },
            {
                "lat": 49.8707312098,
                "lng": -127.1279526641
            },
            {
                "lat": 49.8659558515,
                "lng": -127.141021363
            },
            {
                "lat": 49.863441383,
                "lng": -127.1493226251
            },
            {
                "lat": 49.8594099931,
                "lng": -127.1521148891
            },
            {
                "lat": 49.748176225,
                "lng": -126.9820025696
            },
            {
                "lat": 49.7480925432,
                "lng": -126.9817432621
            },
            {
                "lat": 49.7480163702,
                "lng": -126.9814000206
            },
            {
                "lat": 49.7479936269,
                "lng": -126.9811784472
            },
            {
                "lat": 49.7480468507,
                "lng": -126.9806975128
            },
            {
                "lat": 49.7482261477,
                "lng": -126.980262622
            },
            {
                "lat": 49.7481157027,
                "lng": -126.9791643277
            },
            {
                "lat": 49.7477872714,
                "lng": -126.977737124
            },
            {
                "lat": 49.7466884448,
                "lng": -126.9757690056
            },
            {
                "lat": 49.74548352,
                "lng": -126.9734649483
            },
            {
                "lat": 49.742194463,
                "lng": -126.9710536398
            },
            {
                "lat": 49.7372624309,
                "lng": -126.9660109738
            },
            {
                "lat": 49.7318920289,
                "lng": -126.9608614284
            },
            {
                "lat": 49.7273936172,
                "lng": -126.9564739481
            },
            {
                "lat": 49.7266998046,
                "lng": -126.9557800589
            },
            {
                "lat": 49.7261271843,
                "lng": -126.9556580727
            },
            {
                "lat": 49.7258533749,
                "lng": -126.9555977846
            },
            {
                "lat": 49.7254297399,
                "lng": -126.9555057746
            },
            {
                "lat": 49.724967551,
                "lng": -126.9552907672
            },
            {
                "lat": 49.7246323747,
                "lng": -126.955048082
            },
            {
                "lat": 49.7245367838,
                "lng": -126.9543762283
            },
            {
                "lat": 49.7245600501,
                "lng": -126.9542233188
            },
            {
                "lat": 49.7245904397,
                "lng": -126.9539943111
            },
            {
                "lat": 49.7246659849,
                "lng": -126.9537124986
            },
            {
                "lat": 49.7249221056,
                "lng": -126.9532238587
            },
            {
                "lat": 49.7251736657,
                "lng": -126.9527741226
            },
            {
                "lat": 49.7253913999,
                "lng": -126.9508209756
            },
            {
                "lat": 49.7253913967,
                "lng": -126.9471361784
            },
            {
                "lat": 49.7235485428,
                "lng": -126.9408415511
            },
            {
                "lat": 49.721919714,
                "lng": -126.9331428055
            },
            {
                "lat": 49.7221375972,
                "lng": -126.9282608269
            },
            {
                "lat": 49.7248500002,
                "lng": -126.9255529693
            },
            {
                "lat": 49.7375415907,
                "lng": -126.9219661893
            },
            {
                "lat": 49.748169104,
                "lng": -126.9156795699
            },
            {
                "lat": 49.7507746924,
                "lng": -126.9123152746
            },
            {
                "lat": 49.7531623635,
                "lng": -126.9118814824
            },
            {
                "lat": 49.756630385,
                "lng": -126.9147496811
            },
            {
                "lat": 49.7582087488,
                "lng": -126.918915181
            },
            {
                "lat": 49.7598304491,
                "lng": -126.9210576688
            },
            {
                "lat": 49.7604940942,
                "lng": -126.9217526586
            },
            {
                "lat": 49.7921298052,
                "lng": -126.9267427139
            },
            {
                "lat": 49.7922860508,
                "lng": -126.9261479069
            },
            {
                "lat": 49.7925339062,
                "lng": -126.9254221684
            },
            {
                "lat": 49.7954104083,
                "lng": -126.9233399349
            },
            {
                "lat": 49.798698294,
                "lng": -126.917785967
            },
            {
                "lat": 49.8037145046,
                "lng": -126.9203942792
            },
            {
                "lat": 49.8150287046,
                "lng": -126.9327628943
            },
            {
                "lat": 49.8221323588,
                "lng": -126.9293365287
            },
            {
                "lat": 49.8226586959,
                "lng": -126.9196015843
            },
            {
                "lat": 49.8216063832,
                "lng": -126.9032898256
            },
            {
                "lat": 49.8271335047,
                "lng": -126.8853987577
            },
            {
                "lat": 49.8402898023,
                "lng": -126.8680258455
            },
            {
                "lat": 49.8539734368,
                "lng": -126.8435589798
            },
            {
                "lat": 49.8545575069,
                "lng": -126.8415753214
            },
            {
                "lat": 49.8576169558,
                "lng": -126.8288802995
            },
            {
                "lat": 49.8585586384,
                "lng": -126.8110650737
            },
            {
                "lat": 49.8579290556,
                "lng": -126.7998496113
            },
            {
                "lat": 49.8579287373,
                "lng": -126.7927856525
            },
            {
                "lat": 49.8595774118,
                "lng": -126.7901155817
            },
            {
                "lat": 49.8616941131,
                "lng": -126.7896427897
            },
            {
                "lat": 49.8641281294,
                "lng": -126.7922367672
            },
            {
                "lat": 49.8714259921,
                "lng": -126.7950598056
            },
            {
                "lat": 49.8819582065,
                "lng": -126.7949445728
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 537,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 25-13",
            "LABEL": "25-13",
            "FISHERY_AREA_ID": 2280,
            "OBJECTID": 681,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 190564207.4524,
            "FEATURE_LENGTH_M": 78009.7629,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.8109124811136,
            "lng": -126.93736871116695
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.325386444,
                "lng": -125.0500722532
            },
            {
                "lat": 50.3207589128,
                "lng": -125.0415263683
            },
            {
                "lat": 50.300723899,
                "lng": -125.0395280065
            },
            {
                "lat": 50.300781185,
                "lng": -125.0367500477
            },
            {
                "lat": 50.3009112038,
                "lng": -125.0352325312
            },
            {
                "lat": 50.2996903957,
                "lng": -125.0319359871
            },
            {
                "lat": 50.2998661838,
                "lng": -125.0279620171
            },
            {
                "lat": 50.3010175267,
                "lng": -125.0211867209
            },
            {
                "lat": 50.3044932434,
                "lng": -125.015457608
            },
            {
                "lat": 50.3066065041,
                "lng": -125.0119323891
            },
            {
                "lat": 50.3110502552,
                "lng": -125.0040282199
            },
            {
                "lat": 50.3152500434,
                "lng": -125.0000766562
            },
            {
                "lat": 50.315990586,
                "lng": -124.9941484788
            },
            {
                "lat": 50.3140141854,
                "lng": -124.9860000819
            },
            {
                "lat": 50.3110466704,
                "lng": -124.9735874965
            },
            {
                "lat": 50.3109927031,
                "lng": -124.9730988987
            },
            {
                "lat": 50.311091781,
                "lng": -124.9688339726
            },
            {
                "lat": 50.3115540191,
                "lng": -124.9676822755
            },
            {
                "lat": 50.3121840319,
                "lng": -124.9663318464
            },
            {
                "lat": 50.3313631443,
                "lng": -124.9654987956
            },
            {
                "lat": 50.3460768996,
                "lng": -124.9648585359
            },
            {
                "lat": 50.345978164,
                "lng": -124.9518572967
            },
            {
                "lat": 50.3464353474,
                "lng": -124.9516147836
            },
            {
                "lat": 50.3561853944,
                "lng": -124.9418638221
            },
            {
                "lat": 50.3597603609,
                "lng": -124.9343950575
            },
            {
                "lat": 50.3665846393,
                "lng": -124.9262697091
            },
            {
                "lat": 50.3766557118,
                "lng": -124.9204184579
            },
            {
                "lat": 50.3873821382,
                "lng": -124.9197684306
            },
            {
                "lat": 50.4007040716,
                "lng": -124.9207465551
            },
            {
                "lat": 50.4117552108,
                "lng": -124.929519742
            },
            {
                "lat": 50.4315757447,
                "lng": -124.9512864864
            },
            {
                "lat": 50.4393734502,
                "lng": -124.9668876565
            },
            {
                "lat": 50.4474981491,
                "lng": -124.9808579307
            },
            {
                "lat": 50.4530220484,
                "lng": -124.9997113438
            },
            {
                "lat": 50.4507485967,
                "lng": -125.0146566803
            },
            {
                "lat": 50.4442475731,
                "lng": -125.0224527034
            },
            {
                "lat": 50.4354748165,
                "lng": -125.0224542053
            },
            {
                "lat": 50.4195515123,
                "lng": -125.0117346469
            },
            {
                "lat": 50.3955033439,
                "lng": -125.0026321328
            },
            {
                "lat": 50.3799063175,
                "lng": -125.000030958
            },
            {
                "lat": 50.3659326658,
                "lng": -125.02115684
            },
            {
                "lat": 50.3594359168,
                "lng": -125.0471500316
            },
            {
                "lat": 50.3480608128,
                "lng": -125.0513762406
            },
            {
                "lat": 50.3350643222,
                "lng": -125.0484551796
            },
            {
                "lat": 50.3272631486,
                "lng": -125.0507283517
            },
            {
                "lat": 50.325386444,
                "lng": -125.0500722532
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 464,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 19,
            "MANAGEMENT_AREA_NAME": "Subarea 13-19",
            "LABEL": "13-19",
            "FISHERY_AREA_ID": 2124,
            "OBJECTID": 608,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 90147378.7809,
            "FEATURE_LENGTH_M": 46380.8024,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.35348555910218,
            "lng": -124.99377884092387
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4527473622,
                "lng": -125.38243907
            },
            {
                "lat": 50.4430581003,
                "lng": -125.3850707974
            },
            {
                "lat": 50.4368669373,
                "lng": -125.3893351705
            },
            {
                "lat": 50.4343038189,
                "lng": -125.3978729506
            },
            {
                "lat": 50.436652882,
                "lng": -125.4051358233
            },
            {
                "lat": 50.437721539,
                "lng": -125.412605342
            },
            {
                "lat": 50.4360124614,
                "lng": -125.4224239876
            },
            {
                "lat": 50.432170713,
                "lng": -125.43181631
            },
            {
                "lat": 50.431103251,
                "lng": -125.4426952512
            },
            {
                "lat": 50.4300881182,
                "lng": -125.4472356932
            },
            {
                "lat": 50.4110952406,
                "lng": -125.4808883648
            },
            {
                "lat": 50.409819996,
                "lng": -125.4811704302
            },
            {
                "lat": 50.4054839002,
                "lng": -125.4795305198
            },
            {
                "lat": 50.40008587,
                "lng": -125.480674424
            },
            {
                "lat": 50.3952603272,
                "lng": -125.4819864971
            },
            {
                "lat": 50.3931311133,
                "lng": -125.485831618
            },
            {
                "lat": 50.3909225796,
                "lng": -125.4908986206
            },
            {
                "lat": 50.3901063279,
                "lng": -125.4961395304
            },
            {
                "lat": 50.3865894362,
                "lng": -125.5016175205
            },
            {
                "lat": 50.3836444689,
                "lng": -125.5092238931
            },
            {
                "lat": 50.3723369695,
                "lng": -125.4980689595
            },
            {
                "lat": 50.3660804841,
                "lng": -125.4846723136
            },
            {
                "lat": 50.3625102011,
                "lng": -125.4735942021
            },
            {
                "lat": 50.3640028123,
                "lng": -125.4600059095
            },
            {
                "lat": 50.3642960006,
                "lng": -125.453934411
            },
            {
                "lat": 50.3634033529,
                "lng": -125.4392856099
            },
            {
                "lat": 50.3632741061,
                "lng": -125.4340814109
            },
            {
                "lat": 50.3665124768,
                "lng": -125.424140911
            },
            {
                "lat": 50.3684278169,
                "lng": -125.4083865754
            },
            {
                "lat": 50.36975498,
                "lng": -125.3988885854
            },
            {
                "lat": 50.3720363742,
                "lng": -125.3908616825
            },
            {
                "lat": 50.3862831626,
                "lng": -125.3877112182
            },
            {
                "lat": 50.3937726856,
                "lng": -125.3911896685
            },
            {
                "lat": 50.400672367,
                "lng": -125.3911899729
            },
            {
                "lat": 50.405192762,
                "lng": -125.3895264852
            },
            {
                "lat": 50.4052460576,
                "lng": -125.3891070828
            },
            {
                "lat": 50.4061474683,
                "lng": -125.3819120597
            },
            {
                "lat": 50.4025769191,
                "lng": -125.369300857
            },
            {
                "lat": 50.4020993387,
                "lng": -125.3585966423
            },
            {
                "lat": 50.4120937275,
                "lng": -125.3457489703
            },
            {
                "lat": 50.430416195,
                "lng": -125.3331378336
            },
            {
                "lat": 50.4399335623,
                "lng": -125.3333740605
            },
            {
                "lat": 50.4468344115,
                "lng": -125.3524094456
            },
            {
                "lat": 50.4523081006,
                "lng": -125.36953777
            },
            {
                "lat": 50.4546854974,
                "lng": -125.3795314018
            },
            {
                "lat": 50.4527473622,
                "lng": -125.38243907
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 465,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 609,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 67157625.04,
            "FEATURE_LENGTH_M": 38000.5176,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.40566325293697,
            "lng": -125.42228749833694
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4522127665,
                "lng": -125.5879063194
            },
            {
                "lat": 50.4504769705,
                "lng": -125.5918736136
            },
            {
                "lat": 50.4497644966,
                "lng": -125.5931470053
            },
            {
                "lat": 50.4494209975,
                "lng": -125.593505793
            },
            {
                "lat": 50.4495084765,
                "lng": -125.5954674751
            },
            {
                "lat": 50.4504665205,
                "lng": -125.6174779983
            },
            {
                "lat": 50.4517171966,
                "lng": -125.6186287364
            },
            {
                "lat": 50.4549868955,
                "lng": -125.6231156231
            },
            {
                "lat": 50.4529299503,
                "lng": -125.6323630004
            },
            {
                "lat": 50.4468576772,
                "lng": -125.6401139541
            },
            {
                "lat": 50.443054469,
                "lng": -125.6468891206
            },
            {
                "lat": 50.4280809433,
                "lng": -125.7062992245
            },
            {
                "lat": 50.4277993372,
                "lng": -125.713073528
            },
            {
                "lat": 50.4255106748,
                "lng": -125.7165066919
            },
            {
                "lat": 50.414012236,
                "lng": -125.722197652
            },
            {
                "lat": 50.4122160923,
                "lng": -125.7230452896
            },
            {
                "lat": 50.408568634,
                "lng": -125.7204366773
            },
            {
                "lat": 50.4040482946,
                "lng": -125.7123412366
            },
            {
                "lat": 50.4019051982,
                "lng": -125.6990208547
            },
            {
                "lat": 50.4033360775,
                "lng": -125.6818843529
            },
            {
                "lat": 50.4045224546,
                "lng": -125.6676097386
            },
            {
                "lat": 50.4080929105,
                "lng": -125.6507106455
            },
            {
                "lat": 50.4157060205,
                "lng": -125.6373901896
            },
            {
                "lat": 50.4207039421,
                "lng": -125.6292949218
            },
            {
                "lat": 50.4226075317,
                "lng": -125.614074578
            },
            {
                "lat": 50.4242745336,
                "lng": -125.605026025
            },
            {
                "lat": 50.4299845888,
                "lng": -125.5988386454
            },
            {
                "lat": 50.4325559468,
                "lng": -125.5956879397
            },
            {
                "lat": 50.4364097287,
                "lng": -125.577186511
            },
            {
                "lat": 50.436661169,
                "lng": -125.5676729283
            },
            {
                "lat": 50.4399799904,
                "lng": -125.5498276126
            },
            {
                "lat": 50.4392663077,
                "lng": -125.5355459037
            },
            {
                "lat": 50.4429895323,
                "lng": -125.5245064086
            },
            {
                "lat": 50.4462131234,
                "lng": -125.5249100165
            },
            {
                "lat": 50.4509391764,
                "lng": -125.5294571763
            },
            {
                "lat": 50.4519649244,
                "lng": -125.5309838082
            },
            {
                "lat": 50.4528763491,
                "lng": -125.534981501
            },
            {
                "lat": 50.4550474213,
                "lng": -125.5603328007
            },
            {
                "lat": 50.4536786068,
                "lng": -125.5768891969
            },
            {
                "lat": 50.4521909401,
                "lng": -125.5876230034
            },
            {
                "lat": 50.4522127665,
                "lng": -125.5879063194
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 466,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 41,
            "MANAGEMENT_AREA_NAME": "Subarea 13-41",
            "LABEL": "13-41",
            "FISHERY_AREA_ID": 2146,
            "OBJECTID": 610,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 37448402.8187,
            "FEATURE_LENGTH_M": 33184.9351,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.43526224071463,
            "lng": -125.61760365895123
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4405513815,
                "lng": -125.0442344124
            },
            {
                "lat": 50.4442940815,
                "lng": -125.0570987247
            },
            {
                "lat": 50.4452549224,
                "lng": -125.0982359064
            },
            {
                "lat": 50.4460489619,
                "lng": -125.0999527133
            },
            {
                "lat": 50.4433744729,
                "lng": -125.1018900012
            },
            {
                "lat": 50.4404945898,
                "lng": -125.1074756149
            },
            {
                "lat": 50.4314954782,
                "lng": -125.1249462635
            },
            {
                "lat": 50.4292823664,
                "lng": -125.1323466623
            },
            {
                "lat": 50.4252697694,
                "lng": -125.130302904
            },
            {
                "lat": 50.4215854326,
                "lng": -125.1303034279
            },
            {
                "lat": 50.412251075,
                "lng": -125.1311798301
            },
            {
                "lat": 50.4061962878,
                "lng": -125.12344457
            },
            {
                "lat": 50.3873751763,
                "lng": -125.1159504801
            },
            {
                "lat": 50.3793835702,
                "lng": -125.1176152601
            },
            {
                "lat": 50.3762169486,
                "lng": -125.1312707488
            },
            {
                "lat": 50.3731387316,
                "lng": -125.1348265066
            },
            {
                "lat": 50.361472811,
                "lng": -125.1359178023
            },
            {
                "lat": 50.3487744384,
                "lng": -125.0932390791
            },
            {
                "lat": 50.3468662811,
                "lng": -125.0842218232
            },
            {
                "lat": 50.3484912955,
                "lng": -125.0821226557
            },
            {
                "lat": 50.3498871982,
                "lng": -125.0684135381
            },
            {
                "lat": 50.3577918303,
                "lng": -125.0577168576
            },
            {
                "lat": 50.3736000156,
                "lng": -125.0453960059
            },
            {
                "lat": 50.3887093462,
                "lng": -125.0379563575
            },
            {
                "lat": 50.4119564745,
                "lng": -125.0447001766
            },
            {
                "lat": 50.4324151821,
                "lng": -125.0456317537
            },
            {
                "lat": 50.4405513815,
                "lng": -125.0442344124
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 467,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 20,
            "MANAGEMENT_AREA_NAME": "Subarea 13-20",
            "LABEL": "13-20",
            "FISHERY_AREA_ID": 2125,
            "OBJECTID": 611,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 57868561.4491,
            "FEATURE_LENGTH_M": 31654.7347,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.40232331483332,
            "lng": -125.09335646253334
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4027865835,
                "lng": -125.5195000122
            },
            {
                "lat": 50.4429895323,
                "lng": -125.5245064086
            },
            {
                "lat": 50.4392663077,
                "lng": -125.5355459037
            },
            {
                "lat": 50.4399799904,
                "lng": -125.5498276126
            },
            {
                "lat": 50.436661169,
                "lng": -125.5676729283
            },
            {
                "lat": 50.4364097287,
                "lng": -125.577186511
            },
            {
                "lat": 50.4325559468,
                "lng": -125.5956879397
            },
            {
                "lat": 50.4299845888,
                "lng": -125.5988386454
            },
            {
                "lat": 50.4242745336,
                "lng": -125.605026025
            },
            {
                "lat": 50.4226075317,
                "lng": -125.614074578
            },
            {
                "lat": 50.4207039421,
                "lng": -125.6292949218
            },
            {
                "lat": 50.4157060205,
                "lng": -125.6373901896
            },
            {
                "lat": 50.4080929105,
                "lng": -125.6507106455
            },
            {
                "lat": 50.4045224546,
                "lng": -125.6676097386
            },
            {
                "lat": 50.4033360775,
                "lng": -125.6818843529
            },
            {
                "lat": 50.4019051982,
                "lng": -125.6990208547
            },
            {
                "lat": 50.4040482946,
                "lng": -125.7123412366
            },
            {
                "lat": 50.408568634,
                "lng": -125.7204366773
            },
            {
                "lat": 50.4122160923,
                "lng": -125.7230452896
            },
            {
                "lat": 50.4125981768,
                "lng": -125.7242507724
            },
            {
                "lat": 50.4115861495,
                "lng": -125.7258298197
            },
            {
                "lat": 50.4095000604,
                "lng": -125.7264019264
            },
            {
                "lat": 50.4079819333,
                "lng": -125.727729662
            },
            {
                "lat": 50.4065283243,
                "lng": -125.7294993452
            },
            {
                "lat": 50.4063986853,
                "lng": -125.7351224047
            },
            {
                "lat": 50.4073487895,
                "lng": -125.7422107998
            },
            {
                "lat": 50.4078563228,
                "lng": -125.7472612663
            },
            {
                "lat": 50.408015605,
                "lng": -125.7481540615
            },
            {
                "lat": 50.4067958715,
                "lng": -125.753440146
            },
            {
                "lat": 50.4050097287,
                "lng": -125.75409724
            },
            {
                "lat": 50.403114821,
                "lng": -125.7556080031
            },
            {
                "lat": 50.4023557317,
                "lng": -125.7580105006
            },
            {
                "lat": 50.4032405557,
                "lng": -125.7628176171
            },
            {
                "lat": 50.402541837,
                "lng": -125.7664870593
            },
            {
                "lat": 50.4008982703,
                "lng": -125.7747652231
            },
            {
                "lat": 50.4002033691,
                "lng": -125.7797015769
            },
            {
                "lat": 50.3995128666,
                "lng": -125.7832640495
            },
            {
                "lat": 50.3996548038,
                "lng": -125.7843406997
            },
            {
                "lat": 50.3995968838,
                "lng": -125.7845916113
            },
            {
                "lat": 50.3987423586,
                "lng": -125.7873377459
            },
            {
                "lat": 50.3986128776,
                "lng": -125.7857210948
            },
            {
                "lat": 50.3983383767,
                "lng": -125.7831879009
            },
            {
                "lat": 50.397602376,
                "lng": -125.7818978143
            },
            {
                "lat": 50.3959892006,
                "lng": -125.7786712488
            },
            {
                "lat": 50.3946535602,
                "lng": -125.7747119146
            },
            {
                "lat": 50.3936841499,
                "lng": -125.7716672209
            },
            {
                "lat": 50.3928559672,
                "lng": -125.7693253877
            },
            {
                "lat": 50.3919333279,
                "lng": -125.7680287332
            },
            {
                "lat": 50.3902289073,
                "lng": -125.768073534
            },
            {
                "lat": 50.3891714284,
                "lng": -125.76890632
            },
            {
                "lat": 50.3879284067,
                "lng": -125.7723609746
            },
            {
                "lat": 50.3876492229,
                "lng": -125.7753605025
            },
            {
                "lat": 50.3875584236,
                "lng": -125.7793657753
            },
            {
                "lat": 50.387603332,
                "lng": -125.7816233768
            },
            {
                "lat": 50.3868749132,
                "lng": -125.7790763297
            },
            {
                "lat": 50.382870234,
                "lng": -125.7533798099
            },
            {
                "lat": 50.3828198745,
                "lng": -125.7484657933
            },
            {
                "lat": 50.3825000071,
                "lng": -125.7362601671
            },
            {
                "lat": 50.3819690081,
                "lng": -125.7259671716
            },
            {
                "lat": 50.3822903544,
                "lng": -125.7217252704
            },
            {
                "lat": 50.3822901815,
                "lng": -125.7207635219
            },
            {
                "lat": 50.3831369229,
                "lng": -125.7140804254
            },
            {
                "lat": 50.3849411052,
                "lng": -125.7049555935
            },
            {
                "lat": 50.3864288116,
                "lng": -125.6980591502
            },
            {
                "lat": 50.3873823538,
                "lng": -125.6919018072
            },
            {
                "lat": 50.3882331499,
                "lng": -125.6878656196
            },
            {
                "lat": 50.3884432039,
                "lng": -125.6821359642
            },
            {
                "lat": 50.3889737886,
                "lng": -125.6730109449
            },
            {
                "lat": 50.3886573876,
                "lng": -125.6672823501
            },
            {
                "lat": 50.3875965294,
                "lng": -125.6569821886
            },
            {
                "lat": 50.386558406,
                "lng": -125.6518634434
            },
            {
                "lat": 50.3868912941,
                "lng": -125.6491423748
            },
            {
                "lat": 50.3873302288,
                "lng": -125.6429088509
            },
            {
                "lat": 50.3870016559,
                "lng": -125.6390868863
            },
            {
                "lat": 50.3841213919,
                "lng": -125.6335404434
            },
            {
                "lat": 50.3840496057,
                "lng": -125.6334029912
            },
            {
                "lat": 50.3832824477,
                "lng": -125.6290240728
            },
            {
                "lat": 50.3843581916,
                "lng": -125.6264138254
            },
            {
                "lat": 50.3848166736,
                "lng": -125.6253075618
            },
            {
                "lat": 50.3864521873,
                "lng": -125.6239954257
            },
            {
                "lat": 50.3887490661,
                "lng": -125.6244379368
            },
            {
                "lat": 50.3927923589,
                "lng": -125.6249794293
            },
            {
                "lat": 50.396618933,
                "lng": -125.6238888938
            },
            {
                "lat": 50.3992428738,
                "lng": -125.6215928103
            },
            {
                "lat": 50.4000066534,
                "lng": -125.6170070443
            },
            {
                "lat": 50.4000066419,
                "lng": -125.6132842818
            },
            {
                "lat": 50.3991322818,
                "lng": -125.6094617631
            },
            {
                "lat": 50.3966188948,
                "lng": -125.6038852247
            },
            {
                "lat": 50.3953066346,
                "lng": -125.6008259511
            },
            {
                "lat": 50.3933418164,
                "lng": -125.5984216089
            },
            {
                "lat": 50.3908246594,
                "lng": -125.5968885141
            },
            {
                "lat": 50.3880388983,
                "lng": -125.5960947758
            },
            {
                "lat": 50.3902391801,
                "lng": -125.584648945
            },
            {
                "lat": 50.3914756306,
                "lng": -125.5807035988
            },
            {
                "lat": 50.3939900372,
                "lng": -125.5726175196
            },
            {
                "lat": 50.396942253,
                "lng": -125.5656203155
            },
            {
                "lat": 50.4005512866,
                "lng": -125.5599358701
            },
            {
                "lat": 50.4027364459,
                "lng": -125.5541458122
            },
            {
                "lat": 50.4024080855,
                "lng": -125.5450671773
            },
            {
                "lat": 50.4013139976,
                "lng": -125.5383993067
            },
            {
                "lat": 50.4019702267,
                "lng": -125.530966928
            },
            {
                "lat": 50.4025150435,
                "lng": -125.5232083074
            },
            {
                "lat": 50.4022372285,
                "lng": -125.5223387597
            },
            {
                "lat": 50.4027865835,
                "lng": -125.5195000122
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 468,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 612,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 55258434.2839,
            "FEATURE_LENGTH_M": 50421.3723,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.399327133249024,
            "lng": -125.67465329394032
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.438411758,
                "lng": -125.9712826456
            },
            {
                "lat": 50.4383081355,
                "lng": -125.9723662263
            },
            {
                "lat": 50.4381909452,
                "lng": -125.9730836633
            },
            {
                "lat": 50.4381439329,
                "lng": -125.9737552717
            },
            {
                "lat": 50.4279098534,
                "lng": -125.9889217509
            },
            {
                "lat": 50.4194825294,
                "lng": -126.002112464
            },
            {
                "lat": 50.4174534792,
                "lng": -126.0020903815
            },
            {
                "lat": 50.415912467,
                "lng": -125.9992759503
            },
            {
                "lat": 50.4107481429,
                "lng": -125.9906844042
            },
            {
                "lat": 50.4074593061,
                "lng": -125.9848480852
            },
            {
                "lat": 50.4032974391,
                "lng": -125.9763946984
            },
            {
                "lat": 50.4013522322,
                "lng": -125.9724354369
            },
            {
                "lat": 50.3985359923,
                "lng": -125.9671403875
            },
            {
                "lat": 50.396590922,
                "lng": -125.9646522713
            },
            {
                "lat": 50.3954510114,
                "lng": -125.96344755
            },
            {
                "lat": 50.3969610548,
                "lng": -125.9613867942
            },
            {
                "lat": 50.3971063841,
                "lng": -125.9610067717
            },
            {
                "lat": 50.397160256,
                "lng": -125.9608844963
            },
            {
                "lat": 50.3971854862,
                "lng": -125.9607771882
            },
            {
                "lat": 50.3906290612,
                "lng": -125.9435650406
            },
            {
                "lat": 50.3885116987,
                "lng": -125.9423675308
            },
            {
                "lat": 50.3864361464,
                "lng": -125.9406126881
            },
            {
                "lat": 50.3862043054,
                "lng": -125.9397733662
            },
            {
                "lat": 50.3849493331,
                "lng": -125.935241226
            },
            {
                "lat": 50.383086541,
                "lng": -125.9264148638
            },
            {
                "lat": 50.3821299625,
                "lng": -125.9182205449
            },
            {
                "lat": 50.3816417181,
                "lng": -125.9128114823
            },
            {
                "lat": 50.3858794875,
                "lng": -125.9132156387
            },
            {
                "lat": 50.3876605865,
                "lng": -125.9134751117
            },
            {
                "lat": 50.3895268538,
                "lng": -125.9134750576
            },
            {
                "lat": 50.392296354,
                "lng": -125.9144053344
            },
            {
                "lat": 50.4108622628,
                "lng": -125.9206923006
            },
            {
                "lat": 50.4157060787,
                "lng": -125.9206917248
            },
            {
                "lat": 50.4192426883,
                "lng": -125.9202121715
            },
            {
                "lat": 50.420043645,
                "lng": -125.9252170532
            },
            {
                "lat": 50.4214440991,
                "lng": -125.9319307634
            },
            {
                "lat": 50.4217981225,
                "lng": -125.9350969078
            },
            {
                "lat": 50.4288670043,
                "lng": -125.9518201875
            },
            {
                "lat": 50.4292602668,
                "lng": -125.952163863
            },
            {
                "lat": 50.4342688784,
                "lng": -125.9571761039
            },
            {
                "lat": 50.4374738329,
                "lng": -125.9633862649
            },
            {
                "lat": 50.438175582,
                "lng": -125.9661943878
            },
            {
                "lat": 50.43817555,
                "lng": -125.9679952645
            },
            {
                "lat": 50.4375723177,
                "lng": -125.9696574759
            },
            {
                "lat": 50.437866134,
                "lng": -125.9700692064
            },
            {
                "lat": 50.438411758,
                "lng": -125.9712826456
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 469,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 33,
            "MANAGEMENT_AREA_NAME": "Subarea 13-33",
            "LABEL": "13-33",
            "FISHERY_AREA_ID": 2138,
            "OBJECTID": 613,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 21043848.9414,
            "FEATURE_LENGTH_M": 19925.8374,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.410951773834775,
            "lng": -125.95399370963908
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.3359638512,
                "lng": -125.1391835307
            },
            {
                "lat": 50.3396803049,
                "lng": -125.1497566182
            },
            {
                "lat": 50.3453983207,
                "lng": -125.1597601932
            },
            {
                "lat": 50.3576245122,
                "lng": -125.1647332466
            },
            {
                "lat": 50.3622624492,
                "lng": -125.1666178463
            },
            {
                "lat": 50.3765525056,
                "lng": -125.1769102376
            },
            {
                "lat": 50.3868445247,
                "lng": -125.2014914394
            },
            {
                "lat": 50.3931774742,
                "lng": -125.2152947872
            },
            {
                "lat": 50.4011345173,
                "lng": -125.2326504215
            },
            {
                "lat": 50.4242859132,
                "lng": -125.2720935631
            },
            {
                "lat": 50.4349402664,
                "lng": -125.2819822858
            },
            {
                "lat": 50.4244303121,
                "lng": -125.2859261514
            },
            {
                "lat": 50.4080091304,
                "lng": -125.2977529955
            },
            {
                "lat": 50.3935548507,
                "lng": -125.3049772723
            },
            {
                "lat": 50.3679353449,
                "lng": -125.2970954347
            },
            {
                "lat": 50.3475716411,
                "lng": -125.2865835948
            },
            {
                "lat": 50.3337787325,
                "lng": -125.2898641254
            },
            {
                "lat": 50.3278648002,
                "lng": -125.3056343385
            },
            {
                "lat": 50.3324660013,
                "lng": -125.3240283852
            },
            {
                "lat": 50.3390353097,
                "lng": -125.3463595585
            },
            {
                "lat": 50.3449441015,
                "lng": -125.3640973183
            },
            {
                "lat": 50.3490560706,
                "lng": -125.3783335486
            },
            {
                "lat": 50.3487511113,
                "lng": -125.3824534482
            },
            {
                "lat": 50.3466987065,
                "lng": -125.3860778171
            },
            {
                "lat": 50.3422550319,
                "lng": -125.3922347963
            },
            {
                "lat": 50.3379443132,
                "lng": -125.3977054817
            },
            {
                "lat": 50.3367115384,
                "lng": -125.3996891683
            },
            {
                "lat": 50.3365820812,
                "lng": -125.4016107395
            },
            {
                "lat": 50.3316461416,
                "lng": -125.4000698348
            },
            {
                "lat": 50.3228000846,
                "lng": -125.3994293137
            },
            {
                "lat": 50.3167731114,
                "lng": -125.3977657856
            },
            {
                "lat": 50.313949911,
                "lng": -125.3976365365
            },
            {
                "lat": 50.3099287466,
                "lng": -125.3987731286
            },
            {
                "lat": 50.2996982765,
                "lng": -125.3958436485
            },
            {
                "lat": 50.2983279346,
                "lng": -125.3940349448
            },
            {
                "lat": 50.2970813415,
                "lng": -125.3754269635
            },
            {
                "lat": 50.2987437977,
                "lng": -125.3625948954
            },
            {
                "lat": 50.3004108887,
                "lng": -125.3497538932
            },
            {
                "lat": 50.3032224881,
                "lng": -125.346748094
            },
            {
                "lat": 50.309276905,
                "lng": -125.3440627184
            },
            {
                "lat": 50.3124157782,
                "lng": -125.3411486557
            },
            {
                "lat": 50.3139838509,
                "lng": -125.3324047234
            },
            {
                "lat": 50.3166726158,
                "lng": -125.3169320948
            },
            {
                "lat": 50.3186915202,
                "lng": -125.3063965376
            },
            {
                "lat": 50.3222772728,
                "lng": -125.2947381834
            },
            {
                "lat": 50.3254167674,
                "lng": -125.2821894204
            },
            {
                "lat": 50.3303494108,
                "lng": -125.2714242171
            },
            {
                "lat": 50.3319209053,
                "lng": -125.2653746487
            },
            {
                "lat": 50.3310197885,
                "lng": -125.2568518119
            },
            {
                "lat": 50.3290024523,
                "lng": -125.2460945739
            },
            {
                "lat": 50.3267625294,
                "lng": -125.2409353988
            },
            {
                "lat": 50.323711444,
                "lng": -125.2377554274
            },
            {
                "lat": 50.3267554765,
                "lng": -125.2328255792
            },
            {
                "lat": 50.3306076451,
                "lng": -125.2263796387
            },
            {
                "lat": 50.3321304649,
                "lng": -125.2204662666
            },
            {
                "lat": 50.3307884029,
                "lng": -125.2159879252
            },
            {
                "lat": 50.3268475799,
                "lng": -125.2106998434
            },
            {
                "lat": 50.3195911991,
                "lng": -125.2109685319
            },
            {
                "lat": 50.3097381309,
                "lng": -125.2160801401
            },
            {
                "lat": 50.3050956289,
                "lng": -125.2180702614
            },
            {
                "lat": 50.3088116328,
                "lng": -125.2094956092
            },
            {
                "lat": 50.3145299509,
                "lng": -125.1983489922
            },
            {
                "lat": 50.3219604581,
                "lng": -125.1900556504
            },
            {
                "lat": 50.3288198093,
                "lng": -125.1763378015
            },
            {
                "lat": 50.3325343184,
                "lng": -125.1674794444
            },
            {
                "lat": 50.3333934752,
                "lng": -125.1566158945
            },
            {
                "lat": 50.3348234359,
                "lng": -125.1454688853
            },
            {
                "lat": 50.3359638512,
                "lng": -125.1391835307
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 470,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 614,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 105013403.6959,
            "FEATURE_LENGTH_M": 65468.9817,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.33752836970588,
            "lng": -125.28073158512791
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4255106748,
                "lng": -125.7165066919
            },
            {
                "lat": 50.4254535056,
                "lng": -125.734481835
            },
            {
                "lat": 50.4270555074,
                "lng": -125.7376861188
            },
            {
                "lat": 50.426856613,
                "lng": -125.7438964068
            },
            {
                "lat": 50.4266550791,
                "lng": -125.74810031
            },
            {
                "lat": 50.4273573076,
                "lng": -125.7570186042
            },
            {
                "lat": 50.4262545338,
                "lng": -125.7649301549
            },
            {
                "lat": 50.4240495999,
                "lng": -125.7702405252
            },
            {
                "lat": 50.4214444968,
                "lng": -125.7777564367
            },
            {
                "lat": 50.4184420748,
                "lng": -125.7921834352
            },
            {
                "lat": 50.4171372429,
                "lng": -125.8044044069
            },
            {
                "lat": 50.4159357131,
                "lng": -125.8127128187
            },
            {
                "lat": 50.4093326188,
                "lng": -125.8102182593
            },
            {
                "lat": 50.3987887492,
                "lng": -125.7881008737
            },
            {
                "lat": 50.3987423586,
                "lng": -125.7873377459
            },
            {
                "lat": 50.3995968838,
                "lng": -125.7845916113
            },
            {
                "lat": 50.3996548038,
                "lng": -125.7843406997
            },
            {
                "lat": 50.3995128666,
                "lng": -125.7832640495
            },
            {
                "lat": 50.4002033691,
                "lng": -125.7797015769
            },
            {
                "lat": 50.4008982703,
                "lng": -125.7747652231
            },
            {
                "lat": 50.402541837,
                "lng": -125.7664870593
            },
            {
                "lat": 50.4032405557,
                "lng": -125.7628176171
            },
            {
                "lat": 50.4023557317,
                "lng": -125.7580105006
            },
            {
                "lat": 50.403114821,
                "lng": -125.7556080031
            },
            {
                "lat": 50.4050097287,
                "lng": -125.75409724
            },
            {
                "lat": 50.4067958715,
                "lng": -125.753440146
            },
            {
                "lat": 50.408015605,
                "lng": -125.7481540615
            },
            {
                "lat": 50.4078563228,
                "lng": -125.7472612663
            },
            {
                "lat": 50.4073487895,
                "lng": -125.7422107998
            },
            {
                "lat": 50.4063986853,
                "lng": -125.7351224047
            },
            {
                "lat": 50.4065283243,
                "lng": -125.7294993452
            },
            {
                "lat": 50.4079819333,
                "lng": -125.727729662
            },
            {
                "lat": 50.4095000604,
                "lng": -125.7264019264
            },
            {
                "lat": 50.4115861495,
                "lng": -125.7258298197
            },
            {
                "lat": 50.4125981768,
                "lng": -125.7242507724
            },
            {
                "lat": 50.4122160923,
                "lng": -125.7230452896
            },
            {
                "lat": 50.414012236,
                "lng": -125.722197652
            },
            {
                "lat": 50.4255106748,
                "lng": -125.7165066919
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 471,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 40,
            "MANAGEMENT_AREA_NAME": "Subarea 13-40",
            "LABEL": "13-40",
            "FISHERY_AREA_ID": 2145,
            "OBJECTID": 615,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 13358513.0856,
            "FEATURE_LENGTH_M": 16908.3461,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.41161825959473,
            "lng": -125.75712915898161
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.4192426883,
                "lng": -125.9202121715
            },
            {
                "lat": 50.4157060787,
                "lng": -125.9206917248
            },
            {
                "lat": 50.4108622628,
                "lng": -125.9206923006
            },
            {
                "lat": 50.392296354,
                "lng": -125.9144053344
            },
            {
                "lat": 50.3895268538,
                "lng": -125.9134750576
            },
            {
                "lat": 50.3876605865,
                "lng": -125.9134751117
            },
            {
                "lat": 50.3858794875,
                "lng": -125.9132156387
            },
            {
                "lat": 50.3816417181,
                "lng": -125.9128114823
            },
            {
                "lat": 50.3790436265,
                "lng": -125.9046176425
            },
            {
                "lat": 50.3758435083,
                "lng": -125.8918149967
            },
            {
                "lat": 50.3738441856,
                "lng": -125.8758238846
            },
            {
                "lat": 50.3728445475,
                "lng": -125.8572312379
            },
            {
                "lat": 50.3744430873,
                "lng": -125.8412397478
            },
            {
                "lat": 50.37604446,
                "lng": -125.8344421873
            },
            {
                "lat": 50.3762439438,
                "lng": -125.8282392958
            },
            {
                "lat": 50.3823053464,
                "lng": -125.8273617817
            },
            {
                "lat": 50.3833998984,
                "lng": -125.8273233243
            },
            {
                "lat": 50.3844988753,
                "lng": -125.8272479098
            },
            {
                "lat": 50.3849829133,
                "lng": -125.8204800362
            },
            {
                "lat": 50.3876001557,
                "lng": -125.7837291015
            },
            {
                "lat": 50.387603332,
                "lng": -125.7816233768
            },
            {
                "lat": 50.3875584236,
                "lng": -125.7793657753
            },
            {
                "lat": 50.3876492229,
                "lng": -125.7753605025
            },
            {
                "lat": 50.3879284067,
                "lng": -125.7723609746
            },
            {
                "lat": 50.3891714284,
                "lng": -125.76890632
            },
            {
                "lat": 50.3902289073,
                "lng": -125.768073534
            },
            {
                "lat": 50.3919333279,
                "lng": -125.7680287332
            },
            {
                "lat": 50.3928559672,
                "lng": -125.7693253877
            },
            {
                "lat": 50.3936841499,
                "lng": -125.7716672209
            },
            {
                "lat": 50.3946535602,
                "lng": -125.7747119146
            },
            {
                "lat": 50.3959892006,
                "lng": -125.7786712488
            },
            {
                "lat": 50.397602376,
                "lng": -125.7818978143
            },
            {
                "lat": 50.3983383767,
                "lng": -125.7831879009
            },
            {
                "lat": 50.3986128776,
                "lng": -125.7857210948
            },
            {
                "lat": 50.3987423586,
                "lng": -125.7873377459
            },
            {
                "lat": 50.3987887492,
                "lng": -125.7881008737
            },
            {
                "lat": 50.4093326188,
                "lng": -125.8102182593
            },
            {
                "lat": 50.4159357131,
                "lng": -125.8127128187
            },
            {
                "lat": 50.4158368944,
                "lng": -125.8233337105
            },
            {
                "lat": 50.4166376349,
                "lng": -125.8345565329
            },
            {
                "lat": 50.4178390692,
                "lng": -125.8439703857
            },
            {
                "lat": 50.4197424007,
                "lng": -125.8534924455
            },
            {
                "lat": 50.4208648402,
                "lng": -125.855056714
            },
            {
                "lat": 50.4219203216,
                "lng": -125.8565368041
            },
            {
                "lat": 50.4245523853,
                "lng": -125.8640060928
            },
            {
                "lat": 50.424751554,
                "lng": -125.8733213596
            },
            {
                "lat": 50.42425127,
                "lng": -125.8847432867
            },
            {
                "lat": 50.4222487604,
                "lng": -125.8962634517
            },
            {
                "lat": 50.4191391561,
                "lng": -125.9069824389
            },
            {
                "lat": 50.4185412104,
                "lng": -125.9126962966
            },
            {
                "lat": 50.4192426883,
                "lng": -125.9202121715
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 472,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 32,
            "MANAGEMENT_AREA_NAME": "Subarea 13-32",
            "LABEL": "13-32",
            "FISHERY_AREA_ID": 2137,
            "OBJECTID": 616,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 40620226.2226,
            "FEATURE_LENGTH_M": 28273.7257,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.39835466196078,
            "lng": -125.83982300302353
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.3762169486,
                "lng": -125.1312707488
            },
            {
                "lat": 50.3793835702,
                "lng": -125.1176152601
            },
            {
                "lat": 50.3873751763,
                "lng": -125.1159504801
            },
            {
                "lat": 50.4061962878,
                "lng": -125.12344457
            },
            {
                "lat": 50.412251075,
                "lng": -125.1311798301
            },
            {
                "lat": 50.4061965321,
                "lng": -125.1347725388
            },
            {
                "lat": 50.4010308695,
                "lng": -125.1299442486
            },
            {
                "lat": 50.3880422967,
                "lng": -125.1302715479
            },
            {
                "lat": 50.3817142517,
                "lng": -125.134101547
            },
            {
                "lat": 50.3762169486,
                "lng": -125.1312707488
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 473,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 617,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 3113578.1484,
            "FEATURE_LENGTH_M": 9195.5168,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.39146239565,
            "lng": -125.12798215202001
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.3880388983,
                "lng": -125.5960947758
            },
            {
                "lat": 50.3853426473,
                "lng": -125.5970560632
            },
            {
                "lat": 50.3779307326,
                "lng": -125.5895483213
            },
            {
                "lat": 50.3681159176,
                "lng": -125.5796229274
            },
            {
                "lat": 50.3620539748,
                "lng": -125.5797451453
            },
            {
                "lat": 50.3532577763,
                "lng": -125.5801422378
            },
            {
                "lat": 50.3502541967,
                "lng": -125.5699382083
            },
            {
                "lat": 50.3450549966,
                "lng": -125.5585403027
            },
            {
                "lat": 50.3360595691,
                "lng": -125.5425415547
            },
            {
                "lat": 50.3296588197,
                "lng": -125.5299536245
            },
            {
                "lat": 50.3250620165,
                "lng": -125.5153578012
            },
            {
                "lat": 50.3214645088,
                "lng": -125.5019610888
            },
            {
                "lat": 50.3212622,
                "lng": -125.4787680262
            },
            {
                "lat": 50.3230629948,
                "lng": -125.4647742279
            },
            {
                "lat": 50.323971215,
                "lng": -125.4573900546
            },
            {
                "lat": 50.3256449815,
                "lng": -125.4580689129
            },
            {
                "lat": 50.3284261744,
                "lng": -125.4545891462
            },
            {
                "lat": 50.3296773757,
                "lng": -125.4500052571
            },
            {
                "lat": 50.3299711961,
                "lng": -125.4439470776
            },
            {
                "lat": 50.3304866011,
                "lng": -125.4420079825
            },
            {
                "lat": 50.3305203414,
                "lng": -125.4409028812
            },
            {
                "lat": 50.3302424196,
                "lng": -125.4395908845
            },
            {
                "lat": 50.3533905323,
                "lng": -125.4334107756
            },
            {
                "lat": 50.3632741061,
                "lng": -125.4340814109
            },
            {
                "lat": 50.3634033529,
                "lng": -125.4392856099
            },
            {
                "lat": 50.3642960006,
                "lng": -125.453934411
            },
            {
                "lat": 50.3640028123,
                "lng": -125.4600059095
            },
            {
                "lat": 50.3625102011,
                "lng": -125.4735942021
            },
            {
                "lat": 50.3660804841,
                "lng": -125.4846723136
            },
            {
                "lat": 50.3723369695,
                "lng": -125.4980689595
            },
            {
                "lat": 50.3836444689,
                "lng": -125.5092238931
            },
            {
                "lat": 50.391616529,
                "lng": -125.5154033143
            },
            {
                "lat": 50.3992801695,
                "lng": -125.5190658925
            },
            {
                "lat": 50.4027865835,
                "lng": -125.5195000122
            },
            {
                "lat": 50.4022372285,
                "lng": -125.5223387597
            },
            {
                "lat": 50.4025150435,
                "lng": -125.5232083074
            },
            {
                "lat": 50.4019702267,
                "lng": -125.530966928
            },
            {
                "lat": 50.4013139976,
                "lng": -125.5383993067
            },
            {
                "lat": 50.4024080855,
                "lng": -125.5450671773
            },
            {
                "lat": 50.4027364459,
                "lng": -125.5541458122
            },
            {
                "lat": 50.4005512866,
                "lng": -125.5599358701
            },
            {
                "lat": 50.396942253,
                "lng": -125.5656203155
            },
            {
                "lat": 50.3939900372,
                "lng": -125.5726175196
            },
            {
                "lat": 50.3914756306,
                "lng": -125.5807035988
            },
            {
                "lat": 50.3902391801,
                "lng": -125.584648945
            },
            {
                "lat": 50.3880388983,
                "lng": -125.5960947758
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 474,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 28,
            "MANAGEMENT_AREA_NAME": "Subarea 13-28",
            "LABEL": "13-28",
            "FISHERY_AREA_ID": 2133,
            "OBJECTID": 618,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 60583767.2262,
            "FEATURE_LENGTH_M": 34113.9507,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.36470869733042,
            "lng": -125.51488131569563
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.386558406,
                "lng": -125.6518634434
            },
            {
                "lat": 50.383515132,
                "lng": -125.6515686217
            },
            {
                "lat": 50.3624497977,
                "lng": -125.6515656409
            },
            {
                "lat": 50.3623776358,
                "lng": -125.6516593419
            },
            {
                "lat": 50.3622294518,
                "lng": -125.6518652332
            },
            {
                "lat": 50.3620609927,
                "lng": -125.6520563336
            },
            {
                "lat": 50.3618979433,
                "lng": -125.6521788509
            },
            {
                "lat": 50.3617104273,
                "lng": -125.6522022533
            },
            {
                "lat": 50.3615009286,
                "lng": -125.6522021062
            },
            {
                "lat": 50.3613170339,
                "lng": -125.6521253216
            },
            {
                "lat": 50.3611876653,
                "lng": -125.6520648067
            },
            {
                "lat": 50.3609936299,
                "lng": -125.6519810575
            },
            {
                "lat": 50.3607418448,
                "lng": -125.6519276431
            },
            {
                "lat": 50.3602529704,
                "lng": -125.6518505644
            },
            {
                "lat": 50.3599028317,
                "lng": -125.6517750487
            },
            {
                "lat": 50.3530548877,
                "lng": -125.6513164248
            },
            {
                "lat": 50.3538560197,
                "lng": -125.6403229212
            },
            {
                "lat": 50.3542564897,
                "lng": -125.6239273156
            },
            {
                "lat": 50.3532577074,
                "lng": -125.6009315224
            },
            {
                "lat": 50.3532573257,
                "lng": -125.5861383891
            },
            {
                "lat": 50.3532577763,
                "lng": -125.5801422378
            },
            {
                "lat": 50.3620539748,
                "lng": -125.5797451453
            },
            {
                "lat": 50.3681159176,
                "lng": -125.5796229274
            },
            {
                "lat": 50.3779307326,
                "lng": -125.5895483213
            },
            {
                "lat": 50.3853426473,
                "lng": -125.5970560632
            },
            {
                "lat": 50.3880388983,
                "lng": -125.5960947758
            },
            {
                "lat": 50.3908246594,
                "lng": -125.5968885141
            },
            {
                "lat": 50.3933418164,
                "lng": -125.5984216089
            },
            {
                "lat": 50.3953066346,
                "lng": -125.6008259511
            },
            {
                "lat": 50.3966188948,
                "lng": -125.6038852247
            },
            {
                "lat": 50.3991322818,
                "lng": -125.6094617631
            },
            {
                "lat": 50.4000066419,
                "lng": -125.6132842818
            },
            {
                "lat": 50.4000066534,
                "lng": -125.6170070443
            },
            {
                "lat": 50.3992428738,
                "lng": -125.6215928103
            },
            {
                "lat": 50.396618933,
                "lng": -125.6238888938
            },
            {
                "lat": 50.3927923589,
                "lng": -125.6249794293
            },
            {
                "lat": 50.3887490661,
                "lng": -125.6244379368
            },
            {
                "lat": 50.3864521873,
                "lng": -125.6239954257
            },
            {
                "lat": 50.3848166736,
                "lng": -125.6253075618
            },
            {
                "lat": 50.3843581916,
                "lng": -125.6264138254
            },
            {
                "lat": 50.3832824477,
                "lng": -125.6290240728
            },
            {
                "lat": 50.3840496057,
                "lng": -125.6334029912
            },
            {
                "lat": 50.3841213919,
                "lng": -125.6335404434
            },
            {
                "lat": 50.3870016559,
                "lng": -125.6390868863
            },
            {
                "lat": 50.3873302288,
                "lng": -125.6429088509
            },
            {
                "lat": 50.3868912941,
                "lng": -125.6491423748
            },
            {
                "lat": 50.386558406,
                "lng": -125.6518634434
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 475,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 29,
            "MANAGEMENT_AREA_NAME": "Subarea 13-29",
            "LABEL": "13-29",
            "FISHERY_AREA_ID": 2134,
            "OBJECTID": 619,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 19674803.0683,
            "FEATURE_LENGTH_M": 19350.9311,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.37614089287233,
            "lng": -125.62751258818936
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.3862043054,
                "lng": -125.9397733662
            },
            {
                "lat": 50.3864361464,
                "lng": -125.9406126881
            },
            {
                "lat": 50.3885116987,
                "lng": -125.9423675308
            },
            {
                "lat": 50.3906290612,
                "lng": -125.9435650406
            },
            {
                "lat": 50.3971854862,
                "lng": -125.9607771882
            },
            {
                "lat": 50.397160256,
                "lng": -125.9608844963
            },
            {
                "lat": 50.3971063841,
                "lng": -125.9610067717
            },
            {
                "lat": 50.3969610548,
                "lng": -125.9613867942
            },
            {
                "lat": 50.3954510114,
                "lng": -125.96344755
            },
            {
                "lat": 50.3937725685,
                "lng": -125.9624411953
            },
            {
                "lat": 50.3906176723,
                "lng": -125.9615715819
            },
            {
                "lat": 50.3869973324,
                "lng": -125.9611658385
            },
            {
                "lat": 50.3830378487,
                "lng": -125.9610285791
            },
            {
                "lat": 50.3800202494,
                "lng": -125.9594884182
            },
            {
                "lat": 50.3761294334,
                "lng": -125.9568716511
            },
            {
                "lat": 50.3725055532,
                "lng": -125.9548562287
            },
            {
                "lat": 50.369952676,
                "lng": -125.9515691856
            },
            {
                "lat": 50.3682784914,
                "lng": -125.9479519391
            },
            {
                "lat": 50.3668662917,
                "lng": -125.9439927751
            },
            {
                "lat": 50.3665996933,
                "lng": -125.9400332254
            },
            {
                "lat": 50.3665999329,
                "lng": -125.9354704872
            },
            {
                "lat": 50.3672063252,
                "lng": -125.9314420274
            },
            {
                "lat": 50.3678748727,
                "lng": -125.9299698589
            },
            {
                "lat": 50.3700212674,
                "lng": -125.9279548
            },
            {
                "lat": 50.3695335048,
                "lng": -125.9258507172
            },
            {
                "lat": 50.3682218298,
                "lng": -125.9238051856
            },
            {
                "lat": 50.3659097818,
                "lng": -125.9224856241
            },
            {
                "lat": 50.3626019744,
                "lng": -125.9212259281
            },
            {
                "lat": 50.3615498453,
                "lng": -125.9209667778
            },
            {
                "lat": 50.3613658246,
                "lng": -125.9189144556
            },
            {
                "lat": 50.3613171714,
                "lng": -125.918434773
            },
            {
                "lat": 50.3608129856,
                "lng": -125.9156572658
            },
            {
                "lat": 50.3649096546,
                "lng": -125.915763869
            },
            {
                "lat": 50.3697432298,
                "lng": -125.9174493165
            },
            {
                "lat": 50.3725812425,
                "lng": -125.9198602901
            },
            {
                "lat": 50.3738932005,
                "lng": -125.9230124869
            },
            {
                "lat": 50.3745765757,
                "lng": -125.926116685
            },
            {
                "lat": 50.3752405274,
                "lng": -125.9297325532
            },
            {
                "lat": 50.3813592924,
                "lng": -125.9349967053
            },
            {
                "lat": 50.3862043054,
                "lng": -125.9397733662
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 476,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 34,
            "MANAGEMENT_AREA_NAME": "Subarea 13-34",
            "LABEL": "13-34",
            "FISHERY_AREA_ID": 2139,
            "OBJECTID": 620,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 5653254.6075,
            "FEATURE_LENGTH_M": 12319.0782,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.376798663967506,
            "lng": -125.93934188042499
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.3822903544,
                "lng": -125.7217252704
            },
            {
                "lat": 50.3780016986,
                "lng": -125.7221524935
            },
            {
                "lat": 50.3650583761,
                "lng": -125.7228778753
            },
            {
                "lat": 50.357929305,
                "lng": -125.7232738758
            },
            {
                "lat": 50.3568236243,
                "lng": -125.7240292198
            },
            {
                "lat": 50.3553202215,
                "lng": -125.725433587
            },
            {
                "lat": 50.3498534773,
                "lng": -125.7280802295
            },
            {
                "lat": 50.3482551846,
                "lng": -125.7138826362
            },
            {
                "lat": 50.347454347,
                "lng": -125.699287189
            },
            {
                "lat": 50.3494532812,
                "lng": -125.678695596
            },
            {
                "lat": 50.3510514298,
                "lng": -125.6607046871
            },
            {
                "lat": 50.3530548877,
                "lng": -125.6513164248
            },
            {
                "lat": 50.3599028317,
                "lng": -125.6517750487
            },
            {
                "lat": 50.3602529704,
                "lng": -125.6518505644
            },
            {
                "lat": 50.3607418448,
                "lng": -125.6519276431
            },
            {
                "lat": 50.3609936299,
                "lng": -125.6519810575
            },
            {
                "lat": 50.3611876653,
                "lng": -125.6520648067
            },
            {
                "lat": 50.3613170339,
                "lng": -125.6521253216
            },
            {
                "lat": 50.3615009286,
                "lng": -125.6522021062
            },
            {
                "lat": 50.3617104273,
                "lng": -125.6522022533
            },
            {
                "lat": 50.3618979433,
                "lng": -125.6521788509
            },
            {
                "lat": 50.3620609927,
                "lng": -125.6520563336
            },
            {
                "lat": 50.3622294518,
                "lng": -125.6518652332
            },
            {
                "lat": 50.3623776358,
                "lng": -125.6516593419
            },
            {
                "lat": 50.3624497977,
                "lng": -125.6515656409
            },
            {
                "lat": 50.383515132,
                "lng": -125.6515686217
            },
            {
                "lat": 50.386558406,
                "lng": -125.6518634434
            },
            {
                "lat": 50.3875965294,
                "lng": -125.6569821886
            },
            {
                "lat": 50.3886573876,
                "lng": -125.6672823501
            },
            {
                "lat": 50.3889737886,
                "lng": -125.6730109449
            },
            {
                "lat": 50.3884432039,
                "lng": -125.6821359642
            },
            {
                "lat": 50.3882331499,
                "lng": -125.6878656196
            },
            {
                "lat": 50.3873823538,
                "lng": -125.6919018072
            },
            {
                "lat": 50.3864288116,
                "lng": -125.6980591502
            },
            {
                "lat": 50.3849411052,
                "lng": -125.7049555935
            },
            {
                "lat": 50.3831369229,
                "lng": -125.7140804254
            },
            {
                "lat": 50.3822901815,
                "lng": -125.7207635219
            },
            {
                "lat": 50.3822903544,
                "lng": -125.7217252704
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 477,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 30,
            "MANAGEMENT_AREA_NAME": "Subarea 13-30",
            "LABEL": "13-30",
            "FISHERY_AREA_ID": 2135,
            "OBJECTID": 621,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 21217458.0655,
            "FEATURE_LENGTH_M": 18065.9178,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.368726754407874,
            "lng": -125.68076600493423
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.387603332,
                "lng": -125.7816233768
            },
            {
                "lat": 50.3876001557,
                "lng": -125.7837291015
            },
            {
                "lat": 50.3849829133,
                "lng": -125.8204800362
            },
            {
                "lat": 50.3844988753,
                "lng": -125.8272479098
            },
            {
                "lat": 50.3833998984,
                "lng": -125.8273233243
            },
            {
                "lat": 50.3823053464,
                "lng": -125.8273617817
            },
            {
                "lat": 50.3762439438,
                "lng": -125.8282392958
            },
            {
                "lat": 50.3722463025,
                "lng": -125.8194433723
            },
            {
                "lat": 50.3656463488,
                "lng": -125.8044512616
            },
            {
                "lat": 50.3584485589,
                "lng": -125.7886579417
            },
            {
                "lat": 50.3550489956,
                "lng": -125.7652666549
            },
            {
                "lat": 50.3536532186,
                "lng": -125.7468725517
            },
            {
                "lat": 50.3512542846,
                "lng": -125.7338787618
            },
            {
                "lat": 50.3498534773,
                "lng": -125.7280802295
            },
            {
                "lat": 50.3553202215,
                "lng": -125.725433587
            },
            {
                "lat": 50.3568236243,
                "lng": -125.7240292198
            },
            {
                "lat": 50.357929305,
                "lng": -125.7232738758
            },
            {
                "lat": 50.3650583761,
                "lng": -125.7228778753
            },
            {
                "lat": 50.3780016986,
                "lng": -125.7221524935
            },
            {
                "lat": 50.3822903544,
                "lng": -125.7217252704
            },
            {
                "lat": 50.3819690081,
                "lng": -125.7259671716
            },
            {
                "lat": 50.3825000071,
                "lng": -125.7362601671
            },
            {
                "lat": 50.3828198745,
                "lng": -125.7484657933
            },
            {
                "lat": 50.382870234,
                "lng": -125.7533798099
            },
            {
                "lat": 50.3868749132,
                "lng": -125.7790763297
            },
            {
                "lat": 50.387603332,
                "lng": -125.7816233768
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 478,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 31,
            "MANAGEMENT_AREA_NAME": "Subarea 13-31",
            "LABEL": "13-31",
            "FISHERY_AREA_ID": 2136,
            "OBJECTID": 622,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 21225057.8485,
            "FEATURE_LENGTH_M": 20067.1864,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.37280179230769,
            "lng": -125.76718925268459
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.3632741061,
                "lng": -125.4340814109
            },
            {
                "lat": 50.3533905323,
                "lng": -125.4334107756
            },
            {
                "lat": 50.3302424196,
                "lng": -125.4395908845
            },
            {
                "lat": 50.3364830271,
                "lng": -125.4063721516
            },
            {
                "lat": 50.3365820812,
                "lng": -125.4016107395
            },
            {
                "lat": 50.3367115384,
                "lng": -125.3996891683
            },
            {
                "lat": 50.3379443132,
                "lng": -125.3977054817
            },
            {
                "lat": 50.3422550319,
                "lng": -125.3922347963
            },
            {
                "lat": 50.3466987065,
                "lng": -125.3860778171
            },
            {
                "lat": 50.3487511113,
                "lng": -125.3824534482
            },
            {
                "lat": 50.3490560706,
                "lng": -125.3783335486
            },
            {
                "lat": 50.3542097799,
                "lng": -125.3795085986
            },
            {
                "lat": 50.3668445302,
                "lng": -125.3836671046
            },
            {
                "lat": 50.3720363742,
                "lng": -125.3908616825
            },
            {
                "lat": 50.36975498,
                "lng": -125.3988885854
            },
            {
                "lat": 50.3684278169,
                "lng": -125.4083865754
            },
            {
                "lat": 50.3665124768,
                "lng": -125.424140911
            },
            {
                "lat": 50.3632741061,
                "lng": -125.4340814109
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 479,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 27,
            "MANAGEMENT_AREA_NAME": "Subarea 13-27",
            "LABEL": "13-27",
            "FISHERY_AREA_ID": 2132,
            "OBJECTID": 623,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 13222928.2046,
            "FEATURE_LENGTH_M": 14807.8252,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.35235827790555,
            "lng": -125.40394972726114
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.3335229114,
                "lng": -127.970809633
            },
            {
                "lat": 50.3294334246,
                "lng": -127.9712904142
            },
            {
                "lat": 50.3279840246,
                "lng": -127.9719387999
            },
            {
                "lat": 50.326381875,
                "lng": -127.9729915623
            },
            {
                "lat": 50.325374836,
                "lng": -127.9734720544
            },
            {
                "lat": 50.3248486626,
                "lng": -127.974021792
            },
            {
                "lat": 50.3246763451,
                "lng": -127.9745781662
            },
            {
                "lat": 50.3246462996,
                "lng": -127.9748763589
            },
            {
                "lat": 50.2877191076,
                "lng": -127.8648752905
            },
            {
                "lat": 50.2877191696,
                "lng": -127.8647383912
            },
            {
                "lat": 50.2877423086,
                "lng": -127.8645322353
            },
            {
                "lat": 50.2877844288,
                "lng": -127.8643338807
            },
            {
                "lat": 50.2879487289,
                "lng": -127.8641203745
            },
            {
                "lat": 50.2880977218,
                "lng": -127.8638149545
            },
            {
                "lat": 50.2881431463,
                "lng": -127.8635868958
            },
            {
                "lat": 50.2880521665,
                "lng": -127.863350602
            },
            {
                "lat": 50.2879254809,
                "lng": -127.8631663665
            },
            {
                "lat": 50.2878195321,
                "lng": -127.8628846958
            },
            {
                "lat": 50.287735456,
                "lng": -127.8624722891
            },
            {
                "lat": 50.287703913,
                "lng": -127.8622436937
            },
            {
                "lat": 50.287761136,
                "lng": -127.8620152498
            },
            {
                "lat": 50.2879148518,
                "lng": -127.8617626732
            },
            {
                "lat": 50.288356913,
                "lng": -127.860603113
            },
            {
                "lat": 50.2889018186,
                "lng": -127.8589404996
            },
            {
                "lat": 50.288970965,
                "lng": -127.857200426
            },
            {
                "lat": 50.2880523672,
                "lng": -127.854919684
            },
            {
                "lat": 50.2855525236,
                "lng": -127.8500593927
            },
            {
                "lat": 50.2858621839,
                "lng": -127.8420487461
            },
            {
                "lat": 50.2880205394,
                "lng": -127.8337249343
            },
            {
                "lat": 50.2901767237,
                "lng": -127.8238600679
            },
            {
                "lat": 50.2917216958,
                "lng": -127.813385323
            },
            {
                "lat": 50.2957264983,
                "lng": -127.8066028952
            },
            {
                "lat": 50.3003500768,
                "lng": -127.8004381467
            },
            {
                "lat": 50.3049733788,
                "lng": -127.7982788128
            },
            {
                "lat": 50.3089789921,
                "lng": -127.7995151182
            },
            {
                "lat": 50.3114470432,
                "lng": -127.7976604747
            },
            {
                "lat": 50.3114471038,
                "lng": -127.7945777633
            },
            {
                "lat": 50.3095971731,
                "lng": -127.7881092854
            },
            {
                "lat": 50.3068244079,
                "lng": -127.7797851328
            },
            {
                "lat": 50.3015820287,
                "lng": -127.7643745292
            },
            {
                "lat": 50.2957261274,
                "lng": -127.7508160234
            },
            {
                "lat": 50.2938767221,
                "lng": -127.736945163
            },
            {
                "lat": 50.2917218537,
                "lng": -127.7267682697
            },
            {
                "lat": 50.2886353653,
                "lng": -127.7196811606
            },
            {
                "lat": 50.289562052,
                "lng": -127.7141341263
            },
            {
                "lat": 50.2920265795,
                "lng": -127.7138289212
            },
            {
                "lat": 50.2988090207,
                "lng": -127.7159805455
            },
            {
                "lat": 50.3040499455,
                "lng": -127.71999331
            },
            {
                "lat": 50.3086705157,
                "lng": -127.7310868086
            },
            {
                "lat": 50.3132936049,
                "lng": -127.7443391604
            },
            {
                "lat": 50.3209990975,
                "lng": -127.758826593
            },
            {
                "lat": 50.3280906418,
                "lng": -127.7757797731
            },
            {
                "lat": 50.3308640872,
                "lng": -127.7874911551
            },
            {
                "lat": 50.3311723194,
                "lng": -127.8016736079
            },
            {
                "lat": 50.3299410084,
                "lng": -127.8106077598
            },
            {
                "lat": 50.3228486504,
                "lng": -127.8220135412
            },
            {
                "lat": 50.3182256415,
                "lng": -127.8355785632
            },
            {
                "lat": 50.3209990915,
                "lng": -127.8488308272
            },
            {
                "lat": 50.3259315281,
                "lng": -127.854376783
            },
            {
                "lat": 50.3348695369,
                "lng": -127.8571552658
            },
            {
                "lat": 50.3459666952,
                "lng": -127.8522189567
            },
            {
                "lat": 50.3530574879,
                "lng": -127.8525320753
            },
            {
                "lat": 50.3555220354,
                "lng": -127.858077691
            },
            {
                "lat": 50.3536719561,
                "lng": -127.8673246026
            },
            {
                "lat": 50.3512077003,
                "lng": -127.8802714802
            },
            {
                "lat": 50.3493576236,
                "lng": -127.8956839619
            },
            {
                "lat": 50.3425752625,
                "lng": -127.9129412899
            },
            {
                "lat": 50.3361048577,
                "lng": -127.9348226981
            },
            {
                "lat": 50.3327136428,
                "lng": -127.9456094725
            },
            {
                "lat": 50.3339464772,
                "lng": -127.962563086
            },
            {
                "lat": 50.3335229114,
                "lng": -127.970809633
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 480,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 27,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 27-5",
            "LABEL": "27-5",
            "FISHERY_AREA_ID": 2505,
            "OBJECTID": 624,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 63898719.7134,
            "FEATURE_LENGTH_M": 48196.5034,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.31016109858168,
            "lng": -127.84443271864225
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 50.3468662811,
                "lng": -125.0842218232
            },
            {
                "lat": 50.3487744384,
                "lng": -125.0932390791
            },
            {
                "lat": 50.361472811,
                "lng": -125.1359178023
            },
            {
                "lat": 50.3323903074,
                "lng": -125.1288447675
            },
            {
                "lat": 50.3332828172,
                "lng": -125.1320576908
            },
            {
                "lat": 50.3359638512,
                "lng": -125.1391835307
            },
            {
                "lat": 50.3348234359,
                "lng": -125.1454688853
            },
            {
                "lat": 50.3333934752,
                "lng": -125.1566158945
            },
            {
                "lat": 50.3325343184,
                "lng": -125.1674794444
            },
            {
                "lat": 50.3288198093,
                "lng": -125.1763378015
            },
            {
                "lat": 50.3219604581,
                "lng": -125.1900556504
            },
            {
                "lat": 50.3145299509,
                "lng": -125.1983489922
            },
            {
                "lat": 50.3088116328,
                "lng": -125.2094956092
            },
            {
                "lat": 50.3050956289,
                "lng": -125.2180702614
            },
            {
                "lat": 50.298542094,
                "lng": -125.2188643189
            },
            {
                "lat": 50.2939453805,
                "lng": -125.2114027795
            },
            {
                "lat": 50.2764511208,
                "lng": -125.0890422695
            },
            {
                "lat": 50.276031058,
                "lng": -125.0746536721
            },
            {
                "lat": 50.2771798688,
                "lng": -125.0513981689
            },
            {
                "lat": 50.300723899,
                "lng": -125.0395280065
            },
            {
                "lat": 50.3207589128,
                "lng": -125.0415263683
            },
            {
                "lat": 50.325386444,
                "lng": -125.0500722532
            },
            {
                "lat": 50.3373260847,
                "lng": -125.0721132373
            },
            {
                "lat": 50.3389816149,
                "lng": -125.0734776217
            },
            {
                "lat": 50.3444668912,
                "lng": -125.0802453316
            },
            {
                "lat": 50.3468662811,
                "lng": -125.0842218232
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 481,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 13,
            "MANAGEMENT_SUBAREA": 18,
            "MANAGEMENT_AREA_NAME": "Subarea 13-18",
            "LABEL": "13-18",
            "FISHERY_AREA_ID": 2123,
            "OBJECTID": 625,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 71374622.2747,
            "FEATURE_LENGTH_M": 37259.3034,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 50.32212995636922,
            "lng": -125.12545704166155
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7274518043,
                "lng": -123.8621226789
            },
            {
                "lat": 49.725277175,
                "lng": -123.8674705316
            },
            {
                "lat": 49.7252770778,
                "lng": -123.8796697543
            },
            {
                "lat": 49.7252771694,
                "lng": -123.8871529232
            },
            {
                "lat": 49.725567265,
                "lng": -123.8907233954
            },
            {
                "lat": 49.721691846,
                "lng": -123.8927235327
            },
            {
                "lat": 49.717518309,
                "lng": -123.8944024288
            },
            {
                "lat": 49.7096670203,
                "lng": -123.8971871732
            },
            {
                "lat": 49.6949162182,
                "lng": -123.8986125884
            },
            {
                "lat": 49.6739763652,
                "lng": -123.8867107267
            },
            {
                "lat": 49.6506572323,
                "lng": -123.87219975
            },
            {
                "lat": 49.6282915011,
                "lng": -123.8622058884
            },
            {
                "lat": 49.6130634097,
                "lng": -123.8479312324
            },
            {
                "lat": 49.5899804425,
                "lng": -123.8284151886
            },
            {
                "lat": 49.5749898008,
                "lng": -123.8236537748
            },
            {
                "lat": 49.5566671459,
                "lng": -123.8160403105
            },
            {
                "lat": 49.5442969381,
                "lng": -123.8077163836
            },
            {
                "lat": 49.545009234,
                "lng": -123.8017654615
            },
            {
                "lat": 49.5462001015,
                "lng": -123.796059166
            },
            {
                "lat": 49.5467754006,
                "lng": -123.7949832662
            },
            {
                "lat": 49.5468858344,
                "lng": -123.7942577501
            },
            {
                "lat": 49.5468335,
                "lng": -123.7934951144
            },
            {
                "lat": 49.5466157968,
                "lng": -123.7928854807
            },
            {
                "lat": 49.5465588673,
                "lng": -123.7926032236
            },
            {
                "lat": 49.5578078126,
                "lng": -123.7774956211
            },
            {
                "lat": 49.5580559315,
                "lng": -123.7772366072
            },
            {
                "lat": 49.5582385666,
                "lng": -123.7768563606
            },
            {
                "lat": 49.5583796741,
                "lng": -123.7763433599
            },
            {
                "lat": 49.5585706071,
                "lng": -123.775764029
            },
            {
                "lat": 49.5592038362,
                "lng": -123.7745051036
            },
            {
                "lat": 49.5613026922,
                "lng": -123.7714614075
            },
            {
                "lat": 49.5641364009,
                "lng": -123.7724077186
            },
            {
                "lat": 49.5681223644,
                "lng": -123.7762913398
            },
            {
                "lat": 49.5757872666,
                "lng": -123.7778622018
            },
            {
                "lat": 49.5853390177,
                "lng": -123.7800686352
            },
            {
                "lat": 49.594051578,
                "lng": -123.7807015454
            },
            {
                "lat": 49.600662702,
                "lng": -123.7834245427
            },
            {
                "lat": 49.6015696731,
                "lng": -123.7870555056
            },
            {
                "lat": 49.6017035967,
                "lng": -123.7880399231
            },
            {
                "lat": 49.6017534686,
                "lng": -123.7893448335
            },
            {
                "lat": 49.6017653373,
                "lng": -123.7895967329
            },
            {
                "lat": 49.6018024942,
                "lng": -123.7900687613
            },
            {
                "lat": 49.6198352539,
                "lng": -123.8041371759
            },
            {
                "lat": 49.6239854119,
                "lng": -123.8046796005
            },
            {
                "lat": 49.6244043999,
                "lng": -123.8046492309
            },
            {
                "lat": 49.6254650718,
                "lng": -123.8045726744
            },
            {
                "lat": 49.6272624558,
                "lng": -123.8045042166
            },
            {
                "lat": 49.6298980833,
                "lng": -123.8043749891
            },
            {
                "lat": 49.6309702455,
                "lng": -123.8053983563
            },
            {
                "lat": 49.6333697194,
                "lng": -123.8077999201
            },
            {
                "lat": 49.6366235703,
                "lng": -123.8092265725
            },
            {
                "lat": 49.6394314504,
                "lng": -123.8157125403
            },
            {
                "lat": 49.6399379032,
                "lng": -123.8197936033
            },
            {
                "lat": 49.6495817653,
                "lng": -123.827385628
            },
            {
                "lat": 49.6588133654,
                "lng": -123.8320309474
            },
            {
                "lat": 49.663806408,
                "lng": -123.8336032231
            },
            {
                "lat": 49.6653173975,
                "lng": -123.8342125372
            },
            {
                "lat": 49.6862640131,
                "lng": -123.8406987694
            },
            {
                "lat": 49.6899683387,
                "lng": -123.8397207904
            },
            {
                "lat": 49.6979556608,
                "lng": -123.842635807
            },
            {
                "lat": 49.7131840556,
                "lng": -123.8516776576
            },
            {
                "lat": 49.7255587523,
                "lng": -123.8604821881
            },
            {
                "lat": 49.7274518043,
                "lng": -123.8621226789
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 557,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 16-6",
            "LABEL": "16-6",
            "FISHERY_AREA_ID": 2175,
            "OBJECTID": 701,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 68305450.5882,
            "FEATURE_LENGTH_M": 47485.7946,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.62455212065713,
            "lng": -123.8196020803143
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7362516976,
                "lng": -124.6206292034
            },
            {
                "lat": 49.736060699,
                "lng": -124.6281352786
            },
            {
                "lat": 49.7361376836,
                "lng": -124.6321640188
            },
            {
                "lat": 49.7361909893,
                "lng": -124.63489487
            },
            {
                "lat": 49.7362180538,
                "lng": -124.6356205197
            },
            {
                "lat": 49.7360990785,
                "lng": -124.6361320524
            },
            {
                "lat": 49.4966351917,
                "lng": -124.3983381714
            },
            {
                "lat": 49.5323326927,
                "lng": -124.3830261801
            },
            {
                "lat": 49.5260928558,
                "lng": -124.3634405771
            },
            {
                "lat": 49.5161823902,
                "lng": -124.2525854916
            },
            {
                "lat": 49.5175782044,
                "lng": -124.2421193054
            },
            {
                "lat": 49.5103116682,
                "lng": -124.21253957
            },
            {
                "lat": 49.4887237095,
                "lng": -124.141357269
            },
            {
                "lat": 49.4926189099,
                "lng": -124.1412194033
            },
            {
                "lat": 49.4977189385,
                "lng": -124.1492927415
            },
            {
                "lat": 49.5016712895,
                "lng": -124.1544635078
            },
            {
                "lat": 49.5125316995,
                "lng": -124.1698307667
            },
            {
                "lat": 49.5256192636,
                "lng": -124.1872026837
            },
            {
                "lat": 49.5346598399,
                "lng": -124.2017137249
            },
            {
                "lat": 49.5375178221,
                "lng": -124.2169426054
            },
            {
                "lat": 49.5463223856,
                "lng": -124.2328881383
            },
            {
                "lat": 49.5532223636,
                "lng": -124.2457358691
            },
            {
                "lat": 49.5567894154,
                "lng": -124.2597738266
            },
            {
                "lat": 49.5634540726,
                "lng": -124.2759556743
            },
            {
                "lat": 49.5734483713,
                "lng": -124.2945171463
            },
            {
                "lat": 49.5841562423,
                "lng": -124.3173602457
            },
            {
                "lat": 49.5872877528,
                "lng": -124.3384940284
            },
            {
                "lat": 49.631252122,
                "lng": -124.4264301858
            },
            {
                "lat": 49.6422114992,
                "lng": -124.4313363298
            },
            {
                "lat": 49.6579168768,
                "lng": -124.4434658256
            },
            {
                "lat": 49.67195551,
                "lng": -124.4596487979
            },
            {
                "lat": 49.6871871674,
                "lng": -124.4696429588
            },
            {
                "lat": 49.6914667431,
                "lng": -124.4820171162
            },
            {
                "lat": 49.6971781703,
                "lng": -124.5003354379
            },
            {
                "lat": 49.7059825384,
                "lng": -124.5279392866
            },
            {
                "lat": 49.7140734176,
                "lng": -124.546500277
            },
            {
                "lat": 49.7176439212,
                "lng": -124.5664914498
            },
            {
                "lat": 49.7197837001,
                "lng": -124.5838553494
            },
            {
                "lat": 49.7238278831,
                "lng": -124.599562905
            },
            {
                "lat": 49.7236368412,
                "lng": -124.6112062481
            },
            {
                "lat": 49.7274055276,
                "lng": -124.6187437423
            },
            {
                "lat": 49.7362516976,
                "lng": -124.6206292034
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 558,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 21,
            "MANAGEMENT_AREA_NAME": "Subarea 16-21",
            "LABEL": "16-21",
            "FISHERY_AREA_ID": 2190,
            "OBJECTID": 702,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 239820173.9966,
            "FEATURE_LENGTH_M": 101762.7727,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.61951444991667,
            "lng": -124.40128995197855
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7360990785,
                "lng": -124.6361320524
            },
            {
                "lat": 49.7009813937,
                "lng": -124.8596807757
            },
            {
                "lat": 49.5175818236,
                "lng": -124.5762250435
            },
            {
                "lat": 49.7360990785,
                "lng": -124.6361320524
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 559,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 14-12",
            "LABEL": "14-12",
            "FISHERY_AREA_ID": 2160,
            "OBJECTID": 703,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 204355833.0584,
            "FEATURE_LENGTH_M": 70178.0651,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.672690343575,
            "lng": -124.677042481
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7360990785,
                "lng": -124.6361320524
            },
            {
                "lat": 49.5175818236,
                "lng": -124.5762250435
            },
            {
                "lat": 49.4867782169,
                "lng": -124.434776162
            },
            {
                "lat": 49.4966351917,
                "lng": -124.3983381714
            },
            {
                "lat": 49.7360990785,
                "lng": -124.6361320524
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 560,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 14-6",
            "LABEL": "14-6",
            "FISHERY_AREA_ID": 2154,
            "OBJECTID": 704,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 161791097.0905,
            "FEATURE_LENGTH_M": 70033.9414,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.594638677839995,
            "lng": -124.53632069634
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6443523196,
                "lng": -126.2145616099
            },
            {
                "lat": 49.6475826938,
                "lng": -126.2036889859
            },
            {
                "lat": 49.6467015336,
                "lng": -126.1925277136
            },
            {
                "lat": 49.6452334214,
                "lng": -126.1848903753
            },
            {
                "lat": 49.6417085452,
                "lng": -126.1734320229
            },
            {
                "lat": 49.6411170478,
                "lng": -126.1587370544
            },
            {
                "lat": 49.6414150969,
                "lng": -126.1411052983
            },
            {
                "lat": 49.6428831307,
                "lng": -126.1243595926
            },
            {
                "lat": 49.64640818,
                "lng": -126.1120224278
            },
            {
                "lat": 49.6464081749,
                "lng": -126.1043849941
            },
            {
                "lat": 49.637886533,
                "lng": -126.0999746824
            },
            {
                "lat": 49.6287806354,
                "lng": -126.0914531019
            },
            {
                "lat": 49.6237829736,
                "lng": -126.078529311
            },
            {
                "lat": 49.6137920833,
                "lng": -126.0711819432
            },
            {
                "lat": 49.6035113933,
                "lng": -126.0570756097
            },
            {
                "lat": 49.603805892,
                "lng": -126.0479660385
            },
            {
                "lat": 49.6049806846,
                "lng": -126.0394517188
            },
            {
                "lat": 49.610268183,
                "lng": -126.0356288681
            },
            {
                "lat": 49.6170273889,
                "lng": -126.0362167624
            },
            {
                "lat": 49.623489528,
                "lng": -126.0388640861
            },
            {
                "lat": 49.6299552518,
                "lng": -126.0420906529
            },
            {
                "lat": 49.6349481662,
                "lng": -126.0479665061
            },
            {
                "lat": 49.6405292391,
                "lng": -126.0567859344
            },
            {
                "lat": 49.6481706769,
                "lng": -126.0685343823
            },
            {
                "lat": 49.6572800907,
                "lng": -126.0729442462
            },
            {
                "lat": 49.6625666552,
                "lng": -126.0817574943
            },
            {
                "lat": 49.6749076976,
                "lng": -126.0917517169
            },
            {
                "lat": 49.6869544434,
                "lng": -126.1008528277
            },
            {
                "lat": 49.6954759855,
                "lng": -126.1008524779
            },
            {
                "lat": 49.7034073371,
                "lng": -126.0943905133
            },
            {
                "lat": 49.7113424139,
                "lng": -126.0920410517
            },
            {
                "lat": 49.7157474987,
                "lng": -126.0985032839
            },
            {
                "lat": 49.7145728037,
                "lng": -126.1055604176
            },
            {
                "lat": 49.6987078428,
                "lng": -126.1246561596
            },
            {
                "lat": 49.6872478234,
                "lng": -126.1378788641
            },
            {
                "lat": 49.6804922814,
                "lng": -126.1875305391
            },
            {
                "lat": 49.6740265918,
                "lng": -126.2165220871
            },
            {
                "lat": 49.6671672596,
                "lng": -126.2144473692
            },
            {
                "lat": 49.6538587189,
                "lng": -126.214447617
            },
            {
                "lat": 49.6443523196,
                "lng": -126.2145616099
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 561,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 25-1",
            "LABEL": "25-1",
            "FISHERY_AREA_ID": 2268,
            "OBJECTID": 705,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 60754947.1305,
            "FEATURE_LENGTH_M": 43301.5267,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.65232116340751,
            "lng": -126.11425319870247
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7009813937,
                "lng": -124.8596807757
            },
            {
                "lat": 49.7016529223,
                "lng": -124.8631513885
            },
            {
                "lat": 49.7013969751,
                "lng": -124.8675146279
            },
            {
                "lat": 49.697883473,
                "lng": -124.8715299327
            },
            {
                "lat": 49.6831627825,
                "lng": -124.8883353372
            },
            {
                "lat": 49.672530679,
                "lng": -124.9054255424
            },
            {
                "lat": 49.6710277359,
                "lng": -124.9018933007
            },
            {
                "lat": 49.6688914929,
                "lng": -124.8992679929
            },
            {
                "lat": 49.66738507,
                "lng": -124.8994979354
            },
            {
                "lat": 49.6669077042,
                "lng": -124.8996893903
            },
            {
                "lat": 49.6664701425,
                "lng": -124.9000928067
            },
            {
                "lat": 49.6661267135,
                "lng": -124.9004368407
            },
            {
                "lat": 49.6658215148,
                "lng": -124.9007799556
            },
            {
                "lat": 49.6650777187,
                "lng": -124.9015645628
            },
            {
                "lat": 49.6640660447,
                "lng": -124.9026861498
            },
            {
                "lat": 49.6631125633,
                "lng": -124.9037543725
            },
            {
                "lat": 49.6627890073,
                "lng": -124.9041740397
            },
            {
                "lat": 49.6625213537,
                "lng": -124.9046183326
            },
            {
                "lat": 49.6623082114,
                "lng": -124.9053123001
            },
            {
                "lat": 49.6621090096,
                "lng": -124.9064715341
            },
            {
                "lat": 49.661949589,
                "lng": -124.9071970963
            },
            {
                "lat": 49.6619496968,
                "lng": -124.9085540927
            },
            {
                "lat": 49.6620670831,
                "lng": -124.9097213747
            },
            {
                "lat": 49.6622698586,
                "lng": -124.9113609003
            },
            {
                "lat": 49.6620672644,
                "lng": -124.9137646803
            },
            {
                "lat": 49.6614687994,
                "lng": -124.9154888915
            },
            {
                "lat": 49.6611858931,
                "lng": -124.9164111745
            },
            {
                "lat": 49.6606258949,
                "lng": -124.9185030753
            },
            {
                "lat": 49.660465404,
                "lng": -124.9211428665
            },
            {
                "lat": 49.6604233402,
                "lng": -124.9227448463
            },
            {
                "lat": 49.6601254383,
                "lng": -124.925086662
            },
            {
                "lat": 49.6449389652,
                "lng": -124.9256733501
            },
            {
                "lat": 49.6443138977,
                "lng": -124.9266291732
            },
            {
                "lat": 49.6388969911,
                "lng": -124.9309230897
            },
            {
                "lat": 49.6323243463,
                "lng": -124.9267583422
            },
            {
                "lat": 49.614418434,
                "lng": -124.9120792261
            },
            {
                "lat": 49.6001819768,
                "lng": -124.9020702036
            },
            {
                "lat": 49.5898245951,
                "lng": -124.8957898176
            },
            {
                "lat": 49.5935440612,
                "lng": -124.8873373972
            },
            {
                "lat": 49.609005108,
                "lng": -124.8441166417
            },
            {
                "lat": 49.6414721349,
                "lng": -124.8614187616
            },
            {
                "lat": 49.7009813937,
                "lng": -124.8596807757
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 562,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 14-11",
            "LABEL": "14-11",
            "FISHERY_AREA_ID": 2159,
            "OBJECTID": 706,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 42252829.4469,
            "FEATURE_LENGTH_M": 31012.1089,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.65849339699763,
            "lng": -124.90067451327141
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7009813937,
                "lng": -124.8596807757
            },
            {
                "lat": 49.6414721349,
                "lng": -124.8614187616
            },
            {
                "lat": 49.535266575,
                "lng": -124.7127532965
            },
            {
                "lat": 49.5355829742,
                "lng": -124.7112651088
            },
            {
                "lat": 49.5355261317,
                "lng": -124.708183699
            },
            {
                "lat": 49.53495434,
                "lng": -124.7016143246
            },
            {
                "lat": 49.5331839148,
                "lng": -124.6940761383
            },
            {
                "lat": 49.532248961,
                "lng": -124.6915738919
            },
            {
                "lat": 49.5269580121,
                "lng": -124.6544583629
            },
            {
                "lat": 49.5288123339,
                "lng": -124.6463698414
            },
            {
                "lat": 49.5318414518,
                "lng": -124.6379922314
            },
            {
                "lat": 49.5324445513,
                "lng": -124.6312321901
            },
            {
                "lat": 49.5306286137,
                "lng": -124.6253743343
            },
            {
                "lat": 49.5275994155,
                "lng": -124.6179118634
            },
            {
                "lat": 49.5243724008,
                "lng": -124.611755866
            },
            {
                "lat": 49.5216446639,
                "lng": -124.6055977081
            },
            {
                "lat": 49.5205342135,
                "lng": -124.6005473844
            },
            {
                "lat": 49.5197295612,
                "lng": -124.5951998012
            },
            {
                "lat": 49.5174375955,
                "lng": -124.5876390667
            },
            {
                "lat": 49.5170137091,
                "lng": -124.5846926937
            },
            {
                "lat": 49.5175818236,
                "lng": -124.5762250435
            },
            {
                "lat": 49.7009813937,
                "lng": -124.8596807757
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 563,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 14-9",
            "LABEL": "14-9",
            "FISHERY_AREA_ID": 2157,
            "OBJECTID": 707,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 115758403.6798,
            "FEATURE_LENGTH_M": 62106.7287,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.54849073476819,
            "lng": -124.67160196178183
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6799545026,
                "lng": -124.1842040495
            },
            {
                "lat": 49.675906692,
                "lng": -124.1941985718
            },
            {
                "lat": 49.6754339158,
                "lng": -124.1987612035
            },
            {
                "lat": 49.6709439302,
                "lng": -124.2014686697
            },
            {
                "lat": 49.6034426535,
                "lng": -124.0588607272
            },
            {
                "lat": 49.6040725954,
                "lng": -124.0578537482
            },
            {
                "lat": 49.6053542766,
                "lng": -124.0587317107
            },
            {
                "lat": 49.607494107,
                "lng": -124.0585792255
            },
            {
                "lat": 49.6078573738,
                "lng": -124.0574416049
            },
            {
                "lat": 49.60899692,
                "lng": -124.0555881077
            },
            {
                "lat": 49.6111332269,
                "lng": -124.0551606376
            },
            {
                "lat": 49.6136966988,
                "lng": -124.0564497621
            },
            {
                "lat": 49.6164474666,
                "lng": -124.0579611626
            },
            {
                "lat": 49.6228479624,
                "lng": -124.057518082
            },
            {
                "lat": 49.6249470793,
                "lng": -124.0573723886
            },
            {
                "lat": 49.6312938327,
                "lng": -124.0573726009
            },
            {
                "lat": 49.6322475077,
                "lng": -124.0579154109
            },
            {
                "lat": 49.6341590621,
                "lng": -124.0577551291
            },
            {
                "lat": 49.6369668222,
                "lng": -124.0584948562
            },
            {
                "lat": 49.6405757994,
                "lng": -124.0620510126
            },
            {
                "lat": 49.6459307327,
                "lng": -124.0659167794
            },
            {
                "lat": 49.6500123462,
                "lng": -124.0666659516
            },
            {
                "lat": 49.6527177668,
                "lng": -124.0671926723
            },
            {
                "lat": 49.6534617793,
                "lng": -124.0688404582
            },
            {
                "lat": 49.6530346325,
                "lng": -124.0710134501
            },
            {
                "lat": 49.6511415131,
                "lng": -124.0723875635
            },
            {
                "lat": 49.6454245442,
                "lng": -124.0935665606
            },
            {
                "lat": 49.6477237194,
                "lng": -124.09339857
            },
            {
                "lat": 49.6501962699,
                "lng": -124.0933687653
            },
            {
                "lat": 49.6575887092,
                "lng": -124.0956191224
            },
            {
                "lat": 49.667110354,
                "lng": -124.1037054877
            },
            {
                "lat": 49.6742478322,
                "lng": -124.1163176985
            },
            {
                "lat": 49.6761509771,
                "lng": -124.1275016267
            },
            {
                "lat": 49.6718669495,
                "lng": -124.142730959
            },
            {
                "lat": 49.6744839926,
                "lng": -124.1612931795
            },
            {
                "lat": 49.680195498,
                "lng": -124.1743762757
            },
            {
                "lat": 49.6799545026,
                "lng": -124.1842040495
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 564,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 17,
            "MANAGEMENT_AREA_NAME": "Subarea 16-17",
            "LABEL": "16-17",
            "FISHERY_AREA_ID": 2186,
            "OBJECTID": 708,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 34919437.4918,
            "FEATURE_LENGTH_M": 31529.2675,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.64418958227837,
            "lng": -124.0946442657108
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6388969911,
                "lng": -124.9309230897
            },
            {
                "lat": 49.6443138977,
                "lng": -124.9266291732
            },
            {
                "lat": 49.6449389652,
                "lng": -124.9256733501
            },
            {
                "lat": 49.6601254383,
                "lng": -124.925086662
            },
            {
                "lat": 49.6604233402,
                "lng": -124.9227448463
            },
            {
                "lat": 49.660465404,
                "lng": -124.9211428665
            },
            {
                "lat": 49.6606258949,
                "lng": -124.9185030753
            },
            {
                "lat": 49.6611858931,
                "lng": -124.9164111745
            },
            {
                "lat": 49.6614687994,
                "lng": -124.9154888915
            },
            {
                "lat": 49.6620672644,
                "lng": -124.9137646803
            },
            {
                "lat": 49.6622698586,
                "lng": -124.9113609003
            },
            {
                "lat": 49.6620670831,
                "lng": -124.9097213747
            },
            {
                "lat": 49.6619496968,
                "lng": -124.9085540927
            },
            {
                "lat": 49.661949589,
                "lng": -124.9071970963
            },
            {
                "lat": 49.6621090096,
                "lng": -124.9064715341
            },
            {
                "lat": 49.6623082114,
                "lng": -124.9053123001
            },
            {
                "lat": 49.6625213537,
                "lng": -124.9046183326
            },
            {
                "lat": 49.6627890073,
                "lng": -124.9041740397
            },
            {
                "lat": 49.6631125633,
                "lng": -124.9037543725
            },
            {
                "lat": 49.6640660447,
                "lng": -124.9026861498
            },
            {
                "lat": 49.6650777187,
                "lng": -124.9015645628
            },
            {
                "lat": 49.6658215148,
                "lng": -124.9007799556
            },
            {
                "lat": 49.6661267135,
                "lng": -124.9004368407
            },
            {
                "lat": 49.6664701425,
                "lng": -124.9000928067
            },
            {
                "lat": 49.6669077042,
                "lng": -124.8996893903
            },
            {
                "lat": 49.66738507,
                "lng": -124.8994979354
            },
            {
                "lat": 49.6688914929,
                "lng": -124.8992679929
            },
            {
                "lat": 49.6710277359,
                "lng": -124.9018933007
            },
            {
                "lat": 49.672530679,
                "lng": -124.9054255424
            },
            {
                "lat": 49.6741905887,
                "lng": -124.9093178657
            },
            {
                "lat": 49.6777611609,
                "lng": -124.9337696158
            },
            {
                "lat": 49.6803699974,
                "lng": -124.952866932
            },
            {
                "lat": 49.6827088993,
                "lng": -124.9635843821
            },
            {
                "lat": 49.6844933648,
                "lng": -124.9744347581
            },
            {
                "lat": 49.685505058,
                "lng": -124.9803987661
            },
            {
                "lat": 49.6850510007,
                "lng": -124.9823760601
            },
            {
                "lat": 49.6838345321,
                "lng": -124.9849554628
            },
            {
                "lat": 49.6821022161,
                "lng": -124.985603239
            },
            {
                "lat": 49.6790127426,
                "lng": -124.9849545817
            },
            {
                "lat": 49.6739123328,
                "lng": -124.9823300239
            },
            {
                "lat": 49.6699333972,
                "lng": -124.9798508118
            },
            {
                "lat": 49.6670450436,
                "lng": -124.9767678766
            },
            {
                "lat": 49.6622348094,
                "lng": -124.9725114288
            },
            {
                "lat": 49.6586644571,
                "lng": -124.9681172854
            },
            {
                "lat": 49.6512453104,
                "lng": -124.9576709132
            },
            {
                "lat": 49.647812243,
                "lng": -124.9506682438
            },
            {
                "lat": 49.6443791239,
                "lng": -124.9421457712
            },
            {
                "lat": 49.6412161009,
                "lng": -124.9361026837
            },
            {
                "lat": 49.6388969911,
                "lng": -124.9309230897
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 565,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 14-14",
            "LABEL": "14-14",
            "FISHERY_AREA_ID": 2162,
            "OBJECTID": 709,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 16059128.2509,
            "FEATURE_LENGTH_M": 18068.3831,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.6644951519857,
            "lng": -124.93139216573874
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6633151261,
                "lng": -126.4840846109
            },
            {
                "lat": 49.6852373423,
                "lng": -126.5201877769
            },
            {
                "lat": 49.6799240255,
                "lng": -126.5247651573
            },
            {
                "lat": 49.6755748835,
                "lng": -126.5267869278
            },
            {
                "lat": 49.6707272585,
                "lng": -126.5290527117
            },
            {
                "lat": 49.6617121991,
                "lng": -126.5277106821
            },
            {
                "lat": 49.6558495578,
                "lng": -126.533805656
            },
            {
                "lat": 49.6516911297,
                "lng": -126.5381316581
            },
            {
                "lat": 49.6459996268,
                "lng": -126.5440443471
            },
            {
                "lat": 49.6424326792,
                "lng": -126.5427098771
            },
            {
                "lat": 49.6370773469,
                "lng": -126.5309223061
            },
            {
                "lat": 49.642700411,
                "lng": -126.5281595395
            },
            {
                "lat": 49.6475176216,
                "lng": -126.5268177281
            },
            {
                "lat": 49.6527865008,
                "lng": -126.5252991217
            },
            {
                "lat": 49.6559101073,
                "lng": -126.5226508202
            },
            {
                "lat": 49.6571917249,
                "lng": -126.5157242377
            },
            {
                "lat": 49.6548807487,
                "lng": -126.506218109
            },
            {
                "lat": 49.649745845,
                "lng": -126.5057065492
            },
            {
                "lat": 49.6453778841,
                "lng": -126.5067372491
            },
            {
                "lat": 49.6417841432,
                "lng": -126.5103296848
            },
            {
                "lat": 49.6387027243,
                "lng": -126.5162357498
            },
            {
                "lat": 49.6343382319,
                "lng": -126.5180366758
            },
            {
                "lat": 49.6307409668,
                "lng": -126.5195691877
            },
            {
                "lat": 49.627918023,
                "lng": -126.5218815658
            },
            {
                "lat": 49.6256069528,
                "lng": -126.5247113615
            },
            {
                "lat": 49.6217537537,
                "lng": -126.5267640973
            },
            {
                "lat": 49.6163602916,
                "lng": -126.5316464383
            },
            {
                "lat": 49.6122508376,
                "lng": -126.5352399259
            },
            {
                "lat": 49.6026384635,
                "lng": -126.5382005881
            },
            {
                "lat": 49.606785154,
                "lng": -126.5344996413
            },
            {
                "lat": 49.607723294,
                "lng": -126.5343705086
            },
            {
                "lat": 49.6084289045,
                "lng": -126.5337373504
            },
            {
                "lat": 49.6086429948,
                "lng": -126.5328443625
            },
            {
                "lat": 49.6196059077,
                "lng": -126.5230711942
            },
            {
                "lat": 49.6218829181,
                "lng": -126.5232388627
            },
            {
                "lat": 49.6236956165,
                "lng": -126.5229793265
            },
            {
                "lat": 49.6245081854,
                "lng": -126.5222397722
            },
            {
                "lat": 49.6245838157,
                "lng": -126.5196535946
            },
            {
                "lat": 49.6252287094,
                "lng": -126.5180517446
            },
            {
                "lat": 49.6319738584,
                "lng": -126.5120386846
            },
            {
                "lat": 49.6344523134,
                "lng": -126.5122228038
            },
            {
                "lat": 49.6370774228,
                "lng": -126.5117036878
            },
            {
                "lat": 49.639187049,
                "lng": -126.5104522496
            },
            {
                "lat": 49.6411474635,
                "lng": -126.5067140054
            },
            {
                "lat": 49.642509437,
                "lng": -126.5026393494
            },
            {
                "lat": 49.6461217246,
                "lng": -126.4994201636
            },
            {
                "lat": 49.6490555023,
                "lng": -126.4999165698
            },
            {
                "lat": 49.650756238,
                "lng": -126.4985430475
            },
            {
                "lat": 49.6515350909,
                "lng": -126.4969561418
            },
            {
                "lat": 49.6516108169,
                "lng": -126.4945215367
            },
            {
                "lat": 49.653011167,
                "lng": -126.4936670895
            },
            {
                "lat": 49.6542511242,
                "lng": -126.492164556
            },
            {
                "lat": 49.6558947757,
                "lng": -126.494811592
            },
            {
                "lat": 49.6575584628,
                "lng": -126.497917641
            },
            {
                "lat": 49.6590007204,
                "lng": -126.4995799062
            },
            {
                "lat": 49.6603697242,
                "lng": -126.4969174716
            },
            {
                "lat": 49.6607401391,
                "lng": -126.4935916901
            },
            {
                "lat": 49.6605340166,
                "lng": -126.4913716586
            },
            {
                "lat": 49.6602976187,
                "lng": -126.4887463421
            },
            {
                "lat": 49.6604687402,
                "lng": -126.486625531
            },
            {
                "lat": 49.6633151261,
                "lng": -126.4840846109
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 566,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 710,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 10731660.99,
            "FEATURE_LENGTH_M": 29018.751,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.642945908837696,
            "lng": -126.51494136110163
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6492533409,
                "lng": -126.3469012255
            },
            {
                "lat": 49.6446689929,
                "lng": -126.3481365022
            },
            {
                "lat": 49.6404500638,
                "lng": -126.3510211463
            },
            {
                "lat": 49.6358411523,
                "lng": -126.3541643057
            },
            {
                "lat": 49.6357234688,
                "lng": -126.3541339951
            },
            {
                "lat": 49.6356816079,
                "lng": -126.3540419992
            },
            {
                "lat": 49.6356466048,
                "lng": -126.3539128747
            },
            {
                "lat": 49.6356013959,
                "lng": -126.3538130629
            },
            {
                "lat": 49.6354605661,
                "lng": -126.3535533991
            },
            {
                "lat": 49.6352694952,
                "lng": -126.3533556057
            },
            {
                "lat": 49.6350636182,
                "lng": -126.3529579304
            },
            {
                "lat": 49.6347653754,
                "lng": -126.3516540813
            },
            {
                "lat": 49.6342276519,
                "lng": -126.349464567
            },
            {
                "lat": 49.6336897455,
                "lng": -126.3479384182
            },
            {
                "lat": 49.6330984726,
                "lng": -126.3456802846
            },
            {
                "lat": 49.6329842735,
                "lng": -126.3420559334
            },
            {
                "lat": 49.6328924719,
                "lng": -126.3391417682
            },
            {
                "lat": 49.6314244748,
                "lng": -126.331505446
            },
            {
                "lat": 49.6299550959,
                "lng": -126.3091733912
            },
            {
                "lat": 49.6302484929,
                "lng": -126.2771453414
            },
            {
                "lat": 49.6314244581,
                "lng": -126.2577514892
            },
            {
                "lat": 49.6361240628,
                "lng": -126.2354274966
            },
            {
                "lat": 49.6402357475,
                "lng": -126.2230826913
            },
            {
                "lat": 49.6443523196,
                "lng": -126.2145616099
            },
            {
                "lat": 49.6538587189,
                "lng": -126.214447617
            },
            {
                "lat": 49.6671672596,
                "lng": -126.2144473692
            },
            {
                "lat": 49.6740265918,
                "lng": -126.2165220871
            },
            {
                "lat": 49.6719019515,
                "lng": -126.2256467962
            },
            {
                "lat": 49.6689300043,
                "lng": -126.2332915855
            },
            {
                "lat": 49.6600146975,
                "lng": -126.2670371618
            },
            {
                "lat": 49.6525875064,
                "lng": -126.3162840125
            },
            {
                "lat": 49.6517377981,
                "lng": -126.3364482099
            },
            {
                "lat": 49.6492533409,
                "lng": -126.3469012255
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 567,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 25-2",
            "LABEL": "25-2",
            "FISHERY_AREA_ID": 2269,
            "OBJECTID": 711,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 28821585.9067,
            "FEATURE_LENGTH_M": 25351.8325,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.64283517630909,
            "lng": -126.30823032211518
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6612696918,
                "lng": -126.4410325396
            },
            {
                "lat": 49.6529728279,
                "lng": -126.4409400058
            },
            {
                "lat": 49.6461102774,
                "lng": -126.4715272886
            },
            {
                "lat": 49.6453661278,
                "lng": -126.4713518446
            },
            {
                "lat": 49.6422917427,
                "lng": -126.4713592664
            },
            {
                "lat": 49.6352427603,
                "lng": -126.4722366659
            },
            {
                "lat": 49.6302484507,
                "lng": -126.4716492732
            },
            {
                "lat": 49.6234896064,
                "lng": -126.4663620081
            },
            {
                "lat": 49.6193775733,
                "lng": -126.4537273443
            },
            {
                "lat": 49.6208457199,
                "lng": -126.4422689094
            },
            {
                "lat": 49.6273078296,
                "lng": -126.4211191684
            },
            {
                "lat": 49.6252520612,
                "lng": -126.4040753075
            },
            {
                "lat": 49.621433563,
                "lng": -126.3829191994
            },
            {
                "lat": 49.6243706564,
                "lng": -126.3520666384
            },
            {
                "lat": 49.6270146781,
                "lng": -126.3391425506
            },
            {
                "lat": 49.6329842735,
                "lng": -126.3420559334
            },
            {
                "lat": 49.6330984726,
                "lng": -126.3456802846
            },
            {
                "lat": 49.6336897455,
                "lng": -126.3479384182
            },
            {
                "lat": 49.6342276519,
                "lng": -126.349464567
            },
            {
                "lat": 49.6347653754,
                "lng": -126.3516540813
            },
            {
                "lat": 49.6350636182,
                "lng": -126.3529579304
            },
            {
                "lat": 49.6352694952,
                "lng": -126.3533556057
            },
            {
                "lat": 49.6354605661,
                "lng": -126.3535533991
            },
            {
                "lat": 49.6356013959,
                "lng": -126.3538130629
            },
            {
                "lat": 49.6356466048,
                "lng": -126.3539128747
            },
            {
                "lat": 49.6356816079,
                "lng": -126.3540419992
            },
            {
                "lat": 49.6357234688,
                "lng": -126.3541339951
            },
            {
                "lat": 49.6358411523,
                "lng": -126.3541643057
            },
            {
                "lat": 49.6404500638,
                "lng": -126.3510211463
            },
            {
                "lat": 49.6446689929,
                "lng": -126.3481365022
            },
            {
                "lat": 49.6492533409,
                "lng": -126.3469012255
            },
            {
                "lat": 49.6503640606,
                "lng": -126.3503726801
            },
            {
                "lat": 49.6563338552,
                "lng": -126.3570398062
            },
            {
                "lat": 49.6621662856,
                "lng": -126.3609237574
            },
            {
                "lat": 49.6659123628,
                "lng": -126.3637008474
            },
            {
                "lat": 49.6696622401,
                "lng": -126.3732828932
            },
            {
                "lat": 49.6693839873,
                "lng": -126.3909139753
            },
            {
                "lat": 49.6661905093,
                "lng": -126.4286807665
            },
            {
                "lat": 49.6612696918,
                "lng": -126.4410325396
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 568,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 25-3",
            "LABEL": "25-3",
            "FISHERY_AREA_ID": 2270,
            "OBJECTID": 712,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 38083566.7339,
            "FEATURE_LENGTH_M": 26894.1632,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.64080262525385,
            "lng": -126.38924386172305
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6341590621,
                "lng": -124.0577551291
            },
            {
                "lat": 49.6322475077,
                "lng": -124.0579154109
            },
            {
                "lat": 49.6312938327,
                "lng": -124.0573726009
            },
            {
                "lat": 49.6249470793,
                "lng": -124.0573723886
            },
            {
                "lat": 49.6228479624,
                "lng": -124.057518082
            },
            {
                "lat": 49.6230389908,
                "lng": -124.0556331328
            },
            {
                "lat": 49.6235582095,
                "lng": -124.0521168576
            },
            {
                "lat": 49.6242100559,
                "lng": -124.0453409336
            },
            {
                "lat": 49.6236921696,
                "lng": -124.0439063705
            },
            {
                "lat": 49.6205640396,
                "lng": -124.0480731323
            },
            {
                "lat": 49.6175651379,
                "lng": -124.0526351295
            },
            {
                "lat": 49.6118352173,
                "lng": -124.0491177475
            },
            {
                "lat": 49.6099852069,
                "lng": -124.0458152417
            },
            {
                "lat": 49.6131251544,
                "lng": -124.0437933418
            },
            {
                "lat": 49.6151996091,
                "lng": -124.0422125643
            },
            {
                "lat": 49.6169853414,
                "lng": -124.0404813488
            },
            {
                "lat": 49.6177293475,
                "lng": -124.0393219345
            },
            {
                "lat": 49.6178432647,
                "lng": -124.0382619856
            },
            {
                "lat": 49.6173090538,
                "lng": -124.0376119225
            },
            {
                "lat": 49.6170809042,
                "lng": -124.037139081
            },
            {
                "lat": 49.6170807426,
                "lng": -124.0361480051
            },
            {
                "lat": 49.6174241212,
                "lng": -124.0353705772
            },
            {
                "lat": 49.6177291305,
                "lng": -124.0348351992
            },
            {
                "lat": 49.6175569514,
                "lng": -124.0343011679
            },
            {
                "lat": 49.6170807233,
                "lng": -124.0335466279
            },
            {
                "lat": 49.6168329479,
                "lng": -124.0332182062
            },
            {
                "lat": 49.6165766548,
                "lng": -124.0331340287
            },
            {
                "lat": 49.6164208553,
                "lng": -124.033133768
            },
            {
                "lat": 49.6160354,
                "lng": -124.0329513353
            },
            {
                "lat": 49.6146093777,
                "lng": -124.0321578796
            },
            {
                "lat": 49.6131051994,
                "lng": -124.0303871928
            },
            {
                "lat": 49.6124835425,
                "lng": -124.0287932789
            },
            {
                "lat": 49.6167827566,
                "lng": -124.0263139308
            },
            {
                "lat": 49.6193927535,
                "lng": -124.0177159223
            },
            {
                "lat": 49.6205638915,
                "lng": -124.0084682101
            },
            {
                "lat": 49.6175651543,
                "lng": -123.9850085372
            },
            {
                "lat": 49.6209529237,
                "lng": -123.9787591279
            },
            {
                "lat": 49.6354175022,
                "lng": -123.9781028801
            },
            {
                "lat": 49.6444092496,
                "lng": -123.9762797934
            },
            {
                "lat": 49.6480554863,
                "lng": -123.9850072269
            },
            {
                "lat": 49.6437574647,
                "lng": -123.9947817093
            },
            {
                "lat": 49.6369821236,
                "lng": -124.0092473576
            },
            {
                "lat": 49.6359400193,
                "lng": -124.0209723943
            },
            {
                "lat": 49.6372415856,
                "lng": -124.0376511136
            },
            {
                "lat": 49.6355476756,
                "lng": -124.0497664459
            },
            {
                "lat": 49.6341590621,
                "lng": -124.0577551291
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 569,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 16-4",
            "LABEL": "16-4",
            "FISHERY_AREA_ID": 2173,
            "OBJECTID": 713,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 13323637.6523,
            "FEATURE_LENGTH_M": 19607.3554,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.62293305308696,
            "lng": -124.03224350827824
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6461102774,
                "lng": -126.4715272886
            },
            {
                "lat": 49.6627540273,
                "lng": -126.4799647585
            },
            {
                "lat": 49.6624454243,
                "lng": -126.4814222643
            },
            {
                "lat": 49.6633151261,
                "lng": -126.4840846109
            },
            {
                "lat": 49.6604687402,
                "lng": -126.486625531
            },
            {
                "lat": 49.6602976187,
                "lng": -126.4887463421
            },
            {
                "lat": 49.6605340166,
                "lng": -126.4913716586
            },
            {
                "lat": 49.6607401391,
                "lng": -126.4935916901
            },
            {
                "lat": 49.6603697242,
                "lng": -126.4969174716
            },
            {
                "lat": 49.6590007204,
                "lng": -126.4995799062
            },
            {
                "lat": 49.6575584628,
                "lng": -126.497917641
            },
            {
                "lat": 49.6558947757,
                "lng": -126.494811592
            },
            {
                "lat": 49.6542511242,
                "lng": -126.492164556
            },
            {
                "lat": 49.653011167,
                "lng": -126.4936670895
            },
            {
                "lat": 49.6516108169,
                "lng": -126.4945215367
            },
            {
                "lat": 49.6515350909,
                "lng": -126.4969561418
            },
            {
                "lat": 49.650756238,
                "lng": -126.4985430475
            },
            {
                "lat": 49.6490555023,
                "lng": -126.4999165698
            },
            {
                "lat": 49.6461217246,
                "lng": -126.4994201636
            },
            {
                "lat": 49.642509437,
                "lng": -126.5026393494
            },
            {
                "lat": 49.6411474635,
                "lng": -126.5067140054
            },
            {
                "lat": 49.639187049,
                "lng": -126.5104522496
            },
            {
                "lat": 49.6370774228,
                "lng": -126.5117036878
            },
            {
                "lat": 49.6344523134,
                "lng": -126.5122228038
            },
            {
                "lat": 49.6319738584,
                "lng": -126.5120386846
            },
            {
                "lat": 49.6252287094,
                "lng": -126.5180517446
            },
            {
                "lat": 49.6245838157,
                "lng": -126.5196535946
            },
            {
                "lat": 49.6245081854,
                "lng": -126.5222397722
            },
            {
                "lat": 49.6236956165,
                "lng": -126.5229793265
            },
            {
                "lat": 49.6218829181,
                "lng": -126.5232388627
            },
            {
                "lat": 49.6196059077,
                "lng": -126.5230711942
            },
            {
                "lat": 49.6086429948,
                "lng": -126.5328443625
            },
            {
                "lat": 49.6084289045,
                "lng": -126.5337373504
            },
            {
                "lat": 49.607723294,
                "lng": -126.5343705086
            },
            {
                "lat": 49.606785154,
                "lng": -126.5344996413
            },
            {
                "lat": 49.6026384635,
                "lng": -126.5382005881
            },
            {
                "lat": 49.6023904286,
                "lng": -126.538276328
            },
            {
                "lat": 49.5994869662,
                "lng": -126.5315475901
            },
            {
                "lat": 49.5952761675,
                "lng": -126.5217813133
            },
            {
                "lat": 49.5874367699,
                "lng": -126.5176241583
            },
            {
                "lat": 49.5884018858,
                "lng": -126.5137635536
            },
            {
                "lat": 49.5961110536,
                "lng": -126.5031664786
            },
            {
                "lat": 49.6132158871,
                "lng": -126.4801563031
            },
            {
                "lat": 49.6234896064,
                "lng": -126.4663620081
            },
            {
                "lat": 49.6302484507,
                "lng": -126.4716492732
            },
            {
                "lat": 49.6352427603,
                "lng": -126.4722366659
            },
            {
                "lat": 49.6422917427,
                "lng": -126.4713592664
            },
            {
                "lat": 49.6453661278,
                "lng": -126.4713518446
            },
            {
                "lat": 49.6461102774,
                "lng": -126.4715272886
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 570,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 15,
            "MANAGEMENT_AREA_NAME": "Subarea 25-15",
            "LABEL": "25-15",
            "FISHERY_AREA_ID": 2282,
            "OBJECTID": 714,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 20585415.1962,
            "FEATURE_LENGTH_M": 22002.2965,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.63410143568163,
            "lng": -126.50267774812042
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6414721349,
                "lng": -124.8614187616
            },
            {
                "lat": 49.609005108,
                "lng": -124.8441166417
            },
            {
                "lat": 49.607265724,
                "lng": -124.8371273994
            },
            {
                "lat": 49.6072343932,
                "lng": -124.8363796043
            },
            {
                "lat": 49.607140204,
                "lng": -124.8355642463
            },
            {
                "lat": 49.6067013576,
                "lng": -124.8341214086
            },
            {
                "lat": 49.6058887999,
                "lng": -124.8321763507
            },
            {
                "lat": 49.6045079663,
                "lng": -124.8302008496
            },
            {
                "lat": 49.6029096786,
                "lng": -124.8286355101
            },
            {
                "lat": 49.6014370067,
                "lng": -124.827979792
            },
            {
                "lat": 49.5997126916,
                "lng": -124.8276364783
            },
            {
                "lat": 49.5988082302,
                "lng": -124.8275834477
            },
            {
                "lat": 49.592674332,
                "lng": -124.8272625724
            },
            {
                "lat": 49.584347277,
                "lng": -124.8201219798
            },
            {
                "lat": 49.5802994638,
                "lng": -124.8129877413
            },
            {
                "lat": 49.5767709227,
                "lng": -124.8027262296
            },
            {
                "lat": 49.5480465503,
                "lng": -124.7703327667
            },
            {
                "lat": 49.5460966801,
                "lng": -124.7690967441
            },
            {
                "lat": 49.5345040124,
                "lng": -124.7673261372
            },
            {
                "lat": 49.5352664749,
                "lng": -124.7558974982
            },
            {
                "lat": 49.535266575,
                "lng": -124.7127532965
            },
            {
                "lat": 49.6414721349,
                "lng": -124.8614187616
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 571,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 14-10",
            "LABEL": "14-10",
            "FISHERY_AREA_ID": 2158,
            "OBJECTID": 715,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 24859053.5738,
            "FEATURE_LENGTH_M": 34327.2854,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.589401259913636,
            "lng": -124.81467564625909
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6099852069,
                "lng": -124.0458152417
            },
            {
                "lat": 49.6118352173,
                "lng": -124.0491177475
            },
            {
                "lat": 49.6175651379,
                "lng": -124.0526351295
            },
            {
                "lat": 49.6205640396,
                "lng": -124.0480731323
            },
            {
                "lat": 49.6236921696,
                "lng": -124.0439063705
            },
            {
                "lat": 49.6242100559,
                "lng": -124.0453409336
            },
            {
                "lat": 49.6235582095,
                "lng": -124.0521168576
            },
            {
                "lat": 49.6230389908,
                "lng": -124.0556331328
            },
            {
                "lat": 49.6228479624,
                "lng": -124.057518082
            },
            {
                "lat": 49.6164474666,
                "lng": -124.0579611626
            },
            {
                "lat": 49.6136966988,
                "lng": -124.0564497621
            },
            {
                "lat": 49.6111332269,
                "lng": -124.0551606376
            },
            {
                "lat": 49.60899692,
                "lng": -124.0555881077
            },
            {
                "lat": 49.6078573738,
                "lng": -124.0574416049
            },
            {
                "lat": 49.607494107,
                "lng": -124.0585792255
            },
            {
                "lat": 49.6053542766,
                "lng": -124.0587317107
            },
            {
                "lat": 49.6040725954,
                "lng": -124.0578537482
            },
            {
                "lat": 49.6036906849,
                "lng": -124.0559315128
            },
            {
                "lat": 49.6046255079,
                "lng": -124.0542683596
            },
            {
                "lat": 49.6060026982,
                "lng": -124.0533299868
            },
            {
                "lat": 49.6073450148,
                "lng": -124.052078307
            },
            {
                "lat": 49.607941083,
                "lng": -124.0506437183
            },
            {
                "lat": 49.6076278609,
                "lng": -124.0491404076
            },
            {
                "lat": 49.605721019,
                "lng": -124.0479519736
            },
            {
                "lat": 49.6043893544,
                "lng": -124.0475847921
            },
            {
                "lat": 49.6046488066,
                "lng": -124.0445097088
            },
            {
                "lat": 49.6054683248,
                "lng": -124.0437935963
            },
            {
                "lat": 49.6061249624,
                "lng": -124.0424808827
            },
            {
                "lat": 49.606124868,
                "lng": -124.0407568508
            },
            {
                "lat": 49.6061248783,
                "lng": -124.03935272
            },
            {
                "lat": 49.6081842253,
                "lng": -124.042602565
            },
            {
                "lat": 49.6099852069,
                "lng": -124.0458152417
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 572,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 716,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1393152.3755,
            "FEATURE_LENGTH_M": 7761.328,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.6108235672,
            "lng": -124.05056760030936
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6374012284,
                "lng": -126.818000332
            },
            {
                "lat": 49.6274755241,
                "lng": -126.8219755308
            },
            {
                "lat": 49.6184916619,
                "lng": -126.8272316664
            },
            {
                "lat": 49.3829650829,
                "lng": -126.5440365817
            },
            {
                "lat": 49.3837507567,
                "lng": -126.5425267854
            },
            {
                "lat": 49.3869777495,
                "lng": -126.5337224906
            },
            {
                "lat": 49.392871967,
                "lng": -126.5333938549
            },
            {
                "lat": 49.404983681,
                "lng": -126.5369946167
            },
            {
                "lat": 49.4233132082,
                "lng": -126.5419087811
            },
            {
                "lat": 49.4400097377,
                "lng": -126.5402684336
            },
            {
                "lat": 49.453429861,
                "lng": -126.5373232406
            },
            {
                "lat": 49.4760169473,
                "lng": -126.5422366594
            },
            {
                "lat": 49.5025329231,
                "lng": -126.5477982267
            },
            {
                "lat": 49.513988089,
                "lng": -126.5441975056
            },
            {
                "lat": 49.522170984,
                "lng": -126.5464862349
            },
            {
                "lat": 49.5327413227,
                "lng": -126.5608671069
            },
            {
                "lat": 49.5321431073,
                "lng": -126.5650864253
            },
            {
                "lat": 49.5332257671,
                "lng": -126.5687024674
            },
            {
                "lat": 49.5335270819,
                "lng": -126.5691989329
            },
            {
                "lat": 49.5339545892,
                "lng": -126.5695959907
            },
            {
                "lat": 49.5342826506,
                "lng": -126.5699236459
            },
            {
                "lat": 49.5343780874,
                "lng": -126.5701138213
            },
            {
                "lat": 49.5344352863,
                "lng": -126.5703043807
            },
            {
                "lat": 49.534473672,
                "lng": -126.5704351563
            },
            {
                "lat": 49.5344956932,
                "lng": -126.570579551
            },
            {
                "lat": 49.5344805535,
                "lng": -126.570678598
            },
            {
                "lat": 49.5344504732,
                "lng": -126.5708387375
            },
            {
                "lat": 49.537441185,
                "lng": -126.5772931859
            },
            {
                "lat": 49.5751189849,
                "lng": -126.6585241637
            },
            {
                "lat": 49.5806765364,
                "lng": -126.6705021484
            },
            {
                "lat": 49.5810317862,
                "lng": -126.6708373084
            },
            {
                "lat": 49.5816149723,
                "lng": -126.6712725464
            },
            {
                "lat": 49.5828664972,
                "lng": -126.671554272
            },
            {
                "lat": 49.584037491,
                "lng": -126.6717216602
            },
            {
                "lat": 49.5961761135,
                "lng": -126.6719133709
            },
            {
                "lat": 49.6013297341,
                "lng": -126.6784668312
            },
            {
                "lat": 49.6102296895,
                "lng": -126.6901774538
            },
            {
                "lat": 49.6167829069,
                "lng": -126.7117236664
            },
            {
                "lat": 49.6219365609,
                "lng": -126.7360767774
            },
            {
                "lat": 49.6242789145,
                "lng": -126.772140741
            },
            {
                "lat": 49.6374012284,
                "lng": -126.818000332
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 573,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 125,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 125-1",
            "LABEL": "125-1",
            "FISHERY_AREA_ID": 2329,
            "OBJECTID": 717,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 206375550.503,
            "FEATURE_LENGTH_M": 76392.2984,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.5342412265122,
            "lng": -126.62084463931704
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6124835425,
                "lng": -124.0287932789
            },
            {
                "lat": 49.6131051994,
                "lng": -124.0303871928
            },
            {
                "lat": 49.6146093777,
                "lng": -124.0321578796
            },
            {
                "lat": 49.6160354,
                "lng": -124.0329513353
            },
            {
                "lat": 49.6164208553,
                "lng": -124.033133768
            },
            {
                "lat": 49.6165766548,
                "lng": -124.0331340287
            },
            {
                "lat": 49.6168329479,
                "lng": -124.0332182062
            },
            {
                "lat": 49.6170807233,
                "lng": -124.0335466279
            },
            {
                "lat": 49.6175569514,
                "lng": -124.0343011679
            },
            {
                "lat": 49.6177291305,
                "lng": -124.0348351992
            },
            {
                "lat": 49.6174241212,
                "lng": -124.0353705772
            },
            {
                "lat": 49.6170807426,
                "lng": -124.0361480051
            },
            {
                "lat": 49.6170809042,
                "lng": -124.037139081
            },
            {
                "lat": 49.6173090538,
                "lng": -124.0376119225
            },
            {
                "lat": 49.6178432647,
                "lng": -124.0382619856
            },
            {
                "lat": 49.6177293475,
                "lng": -124.0393219345
            },
            {
                "lat": 49.6169853414,
                "lng": -124.0404813488
            },
            {
                "lat": 49.6151996091,
                "lng": -124.0422125643
            },
            {
                "lat": 49.6131251544,
                "lng": -124.0437933418
            },
            {
                "lat": 49.6099852069,
                "lng": -124.0458152417
            },
            {
                "lat": 49.6081842253,
                "lng": -124.042602565
            },
            {
                "lat": 49.6061248783,
                "lng": -124.03935272
            },
            {
                "lat": 49.605937491,
                "lng": -124.0385047373
            },
            {
                "lat": 49.6055639839,
                "lng": -124.037628381
            },
            {
                "lat": 49.6052518393,
                "lng": -124.0373010348
            },
            {
                "lat": 49.6057093987,
                "lng": -124.0361333319
            },
            {
                "lat": 49.6074452295,
                "lng": -124.0316772921
            },
            {
                "lat": 49.6087804334,
                "lng": -124.0282445484
            },
            {
                "lat": 49.6094592076,
                "lng": -124.0279456951
            },
            {
                "lat": 49.610858443,
                "lng": -124.026948411
            },
            {
                "lat": 49.6124835425,
                "lng": -124.0287932789
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 574,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 16-3",
            "LABEL": "16-3",
            "FISHERY_AREA_ID": 2172,
            "OBJECTID": 718,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1142766.2862,
            "FEATURE_LENGTH_M": 4190.8383,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.6130320064871,
            "lng": -124.03541118330646
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.610858443,
                "lng": -124.026948411
            },
            {
                "lat": 49.6094592076,
                "lng": -124.0279456951
            },
            {
                "lat": 49.6087804334,
                "lng": -124.0282445484
            },
            {
                "lat": 49.6074452295,
                "lng": -124.0316772921
            },
            {
                "lat": 49.6057093987,
                "lng": -124.0361333319
            },
            {
                "lat": 49.6052518393,
                "lng": -124.0373010348
            },
            {
                "lat": 49.6055639839,
                "lng": -124.037628381
            },
            {
                "lat": 49.605937491,
                "lng": -124.0385047373
            },
            {
                "lat": 49.6061248783,
                "lng": -124.03935272
            },
            {
                "lat": 49.606124868,
                "lng": -124.0407568508
            },
            {
                "lat": 49.6061249624,
                "lng": -124.0424808827
            },
            {
                "lat": 49.6054683248,
                "lng": -124.0437935963
            },
            {
                "lat": 49.6046488066,
                "lng": -124.0445097088
            },
            {
                "lat": 49.6043893544,
                "lng": -124.0475847921
            },
            {
                "lat": 49.605721019,
                "lng": -124.0479519736
            },
            {
                "lat": 49.6076278609,
                "lng": -124.0491404076
            },
            {
                "lat": 49.607941083,
                "lng": -124.0506437183
            },
            {
                "lat": 49.6073450148,
                "lng": -124.052078307
            },
            {
                "lat": 49.6060026982,
                "lng": -124.0533299868
            },
            {
                "lat": 49.6046255079,
                "lng": -124.0542683596
            },
            {
                "lat": 49.6036906849,
                "lng": -124.0559315128
            },
            {
                "lat": 49.6040725954,
                "lng": -124.0578537482
            },
            {
                "lat": 49.6034426535,
                "lng": -124.0588607272
            },
            {
                "lat": 49.4922720351,
                "lng": -124.1249313515
            },
            {
                "lat": 49.503749372,
                "lng": -124.0190588311
            },
            {
                "lat": 49.537590121,
                "lng": -123.9744724606
            },
            {
                "lat": 49.5377658839,
                "lng": -123.9744716465
            },
            {
                "lat": 49.5385435764,
                "lng": -123.9746395114
            },
            {
                "lat": 49.5390964405,
                "lng": -123.975043462
            },
            {
                "lat": 49.5394969588,
                "lng": -123.9757839246
            },
            {
                "lat": 49.5802808518,
                "lng": -124.006668574
            },
            {
                "lat": 49.5865556286,
                "lng": -124.0087287768
            },
            {
                "lat": 49.5921598097,
                "lng": -124.0119853886
            },
            {
                "lat": 49.6008873655,
                "lng": -124.0158914071
            },
            {
                "lat": 49.6045603044,
                "lng": -124.02138581
            },
            {
                "lat": 49.6078373731,
                "lng": -124.0233391463
            },
            {
                "lat": 49.6103055308,
                "lng": -124.0255505501
            },
            {
                "lat": 49.610858443,
                "lng": -124.026948411
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 575,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 16-2",
            "LABEL": "16-2",
            "FISHERY_AREA_ID": 2171,
            "OBJECTID": 719,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 78473465.0868,
            "FEATURE_LENGTH_M": 38309.0401,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.59011357981842,
            "lng": -124.0305742098684
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.5775064918,
                "lng": -123.2191393897
            },
            {
                "lat": 49.5797884556,
                "lng": -123.2277221894
            },
            {
                "lat": 49.5873716276,
                "lng": -123.2693171864
            },
            {
                "lat": 49.5896800268,
                "lng": -123.2721476448
            },
            {
                "lat": 49.5858686877,
                "lng": -123.2849265395
            },
            {
                "lat": 49.5898129535,
                "lng": -123.3003779362
            },
            {
                "lat": 49.588829208,
                "lng": -123.3187854742
            },
            {
                "lat": 49.5804437827,
                "lng": -123.3393401692
            },
            {
                "lat": 49.5709116631,
                "lng": -123.350845685
            },
            {
                "lat": 49.5702748827,
                "lng": -123.351058604
            },
            {
                "lat": 49.568279596,
                "lng": -123.3603819714
            },
            {
                "lat": 49.5612912262,
                "lng": -123.3590093109
            },
            {
                "lat": 49.5552176269,
                "lng": -123.3578109583
            },
            {
                "lat": 49.5541838927,
                "lng": -123.3583367377
            },
            {
                "lat": 49.5557938843,
                "lng": -123.3235863549
            },
            {
                "lat": 49.5504796154,
                "lng": -123.3165055402
            },
            {
                "lat": 49.5419848164,
                "lng": -123.309523875
            },
            {
                "lat": 49.5309301318,
                "lng": -123.3038256945
            },
            {
                "lat": 49.5196423196,
                "lng": -123.302078744
            },
            {
                "lat": 49.5144084419,
                "lng": -123.3028941626
            },
            {
                "lat": 49.511848493,
                "lng": -123.3033597528
            },
            {
                "lat": 49.5102313698,
                "lng": -123.3035664907
            },
            {
                "lat": 49.5259707457,
                "lng": -123.2612160125
            },
            {
                "lat": 49.5215796311,
                "lng": -123.2502826512
            },
            {
                "lat": 49.5277634398,
                "lng": -123.247428838
            },
            {
                "lat": 49.5391884136,
                "lng": -123.2374344247
            },
            {
                "lat": 49.561554299,
                "lng": -123.2279120475
            },
            {
                "lat": 49.5775064918,
                "lng": -123.2191393897
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 576,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 28,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 28-4",
            "LABEL": "28-4",
            "FISHERY_AREA_ID": 2515,
            "OBJECTID": 720,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 50741953.1408,
            "FEATURE_LENGTH_M": 32238.2948,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.555297936232144,
            "lng": -123.29564120625002
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6184916619,
                "lng": -126.8272316664
            },
            {
                "lat": 49.5833320614,
                "lng": -126.9260020769
            },
            {
                "lat": 49.3829650829,
                "lng": -126.5440365817
            },
            {
                "lat": 49.6184916619,
                "lng": -126.8272316664
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 577,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 125,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 125-2",
            "LABEL": "125-2",
            "FISHERY_AREA_ID": 2330,
            "OBJECTID": 721,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 133556263.883,
            "FEATURE_LENGTH_M": 76947.8939,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.550820117025005,
            "lng": -126.78112549785
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.592674332,
                "lng": -124.8272625724
            },
            {
                "lat": 49.5988082302,
                "lng": -124.8275834477
            },
            {
                "lat": 49.5997126916,
                "lng": -124.8276364783
            },
            {
                "lat": 49.6014370067,
                "lng": -124.827979792
            },
            {
                "lat": 49.6029096786,
                "lng": -124.8286355101
            },
            {
                "lat": 49.6045079663,
                "lng": -124.8302008496
            },
            {
                "lat": 49.6058887999,
                "lng": -124.8321763507
            },
            {
                "lat": 49.6067013576,
                "lng": -124.8341214086
            },
            {
                "lat": 49.607140204,
                "lng": -124.8355642463
            },
            {
                "lat": 49.6072343932,
                "lng": -124.8363796043
            },
            {
                "lat": 49.607265724,
                "lng": -124.8371273994
            },
            {
                "lat": 49.609005108,
                "lng": -124.8441166417
            },
            {
                "lat": 49.5935440612,
                "lng": -124.8873373972
            },
            {
                "lat": 49.5898245951,
                "lng": -124.8957898176
            },
            {
                "lat": 49.5767777008,
                "lng": -124.8912960963
            },
            {
                "lat": 49.5606345387,
                "lng": -124.883598251
            },
            {
                "lat": 49.5537904297,
                "lng": -124.8793260737
            },
            {
                "lat": 49.5552095313,
                "lng": -124.8720767104
            },
            {
                "lat": 49.555209519,
                "lng": -124.8528669439
            },
            {
                "lat": 49.5552096597,
                "lng": -124.8460008617
            },
            {
                "lat": 49.5553168043,
                "lng": -124.8453143325
            },
            {
                "lat": 49.5554690386,
                "lng": -124.8442156696
            },
            {
                "lat": 49.5555915379,
                "lng": -124.8431764598
            },
            {
                "lat": 49.5555307877,
                "lng": -124.8395083298
            },
            {
                "lat": 49.5663226946,
                "lng": -124.8355712583
            },
            {
                "lat": 49.5813099819,
                "lng": -124.8317643877
            },
            {
                "lat": 49.5905917768,
                "lng": -124.8301014776
            },
            {
                "lat": 49.592674332,
                "lng": -124.8272625724
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 578,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 15,
            "MANAGEMENT_AREA_NAME": "Subarea 14-15",
            "LABEL": "14-15",
            "FISHERY_AREA_ID": 2163,
            "OBJECTID": 722,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 21536861.9404,
            "FEATURE_LENGTH_M": 18176.6617,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.58343901719286,
            "lng": -124.84621396216428
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.5833319558,
                "lng": -127.5018690701
            },
            {
                "lat": 49.5833320832,
                "lng": -128.4534304496
            },
            {
                "lat": 49.00000056,
                "lng": -129.1937098109
            },
            {
                "lat": 49.0000004084,
                "lng": -128.2450561145
            },
            {
                "lat": 49.5833319558,
                "lng": -127.5018690701
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 579,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 126,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 126-3",
            "LABEL": "126-3",
            "FISHERY_AREA_ID": 2337,
            "OBJECTID": 723,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4483638307.1855,
            "FEATURE_LENGTH_M": 307080.8886,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.34999939264,
            "lng": -128.17918690304003
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.5961761135,
                "lng": -126.6719133709
            },
            {
                "lat": 49.584037491,
                "lng": -126.6717216602
            },
            {
                "lat": 49.5828664972,
                "lng": -126.671554272
            },
            {
                "lat": 49.5816149723,
                "lng": -126.6712725464
            },
            {
                "lat": 49.5810317862,
                "lng": -126.6708373084
            },
            {
                "lat": 49.5806765364,
                "lng": -126.6705021484
            },
            {
                "lat": 49.5751189849,
                "lng": -126.6585241637
            },
            {
                "lat": 49.537441185,
                "lng": -126.5772931859
            },
            {
                "lat": 49.5344504732,
                "lng": -126.5708387375
            },
            {
                "lat": 49.5344805535,
                "lng": -126.570678598
            },
            {
                "lat": 49.5344956932,
                "lng": -126.570579551
            },
            {
                "lat": 49.534473672,
                "lng": -126.5704351563
            },
            {
                "lat": 49.5344352863,
                "lng": -126.5703043807
            },
            {
                "lat": 49.5343780874,
                "lng": -126.5701138213
            },
            {
                "lat": 49.5342826506,
                "lng": -126.5699236459
            },
            {
                "lat": 49.5339545892,
                "lng": -126.5695959907
            },
            {
                "lat": 49.5335270819,
                "lng": -126.5691989329
            },
            {
                "lat": 49.5332257671,
                "lng": -126.5687024674
            },
            {
                "lat": 49.5321431073,
                "lng": -126.5650864253
            },
            {
                "lat": 49.5327413227,
                "lng": -126.5608671069
            },
            {
                "lat": 49.5365981171,
                "lng": -126.5565333932
            },
            {
                "lat": 49.539249086,
                "lng": -126.5556945537
            },
            {
                "lat": 49.5474399579,
                "lng": -126.5572589889
            },
            {
                "lat": 49.5588835308,
                "lng": -126.5579827207
            },
            {
                "lat": 49.5687638753,
                "lng": -126.5586999546
            },
            {
                "lat": 49.5751492735,
                "lng": -126.5601498403
            },
            {
                "lat": 49.576595209,
                "lng": -126.5627984125
            },
            {
                "lat": 49.5780409054,
                "lng": -126.5641255628
            },
            {
                "lat": 49.578250585,
                "lng": -126.5646213445
            },
            {
                "lat": 49.5795671869,
                "lng": -126.5647117656
            },
            {
                "lat": 49.5804517665,
                "lng": -126.5647355713
            },
            {
                "lat": 49.5889093735,
                "lng": -126.6137772562
            },
            {
                "lat": 49.5897522633,
                "lng": -126.6186753076
            },
            {
                "lat": 49.5986477228,
                "lng": -126.6228253059
            },
            {
                "lat": 49.6005359877,
                "lng": -126.6333466666
            },
            {
                "lat": 49.6005358287,
                "lng": -126.6473693591
            },
            {
                "lat": 49.5995486357,
                "lng": -126.6607288986
            },
            {
                "lat": 49.5961761135,
                "lng": -126.6719133709
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 580,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 25-7",
            "LABEL": "25-7",
            "FISHERY_AREA_ID": 2274,
            "OBJECTID": 724,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 33093168.252,
            "FEATURE_LENGTH_M": 25722.0526,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.56364861235526,
            "lng": -126.59804978270523
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.5541838927,
                "lng": -123.3583367377
            },
            {
                "lat": 49.5552176269,
                "lng": -123.3578109583
            },
            {
                "lat": 49.5612912262,
                "lng": -123.3590093109
            },
            {
                "lat": 49.568279596,
                "lng": -123.3603819714
            },
            {
                "lat": 49.568770861,
                "lng": -123.3745194276
            },
            {
                "lat": 49.5686077697,
                "lng": -123.3851994841
            },
            {
                "lat": 49.5666345144,
                "lng": -123.4034512051
            },
            {
                "lat": 49.5585821333,
                "lng": -123.4271231546
            },
            {
                "lat": 49.5477298495,
                "lng": -123.4484935387
            },
            {
                "lat": 49.5393492723,
                "lng": -123.4654242419
            },
            {
                "lat": 49.5327716385,
                "lng": -123.4770971061
            },
            {
                "lat": 49.5281254252,
                "lng": -123.4832912657
            },
            {
                "lat": 49.5352362223,
                "lng": -123.4872896895
            },
            {
                "lat": 49.5362245566,
                "lng": -123.4932096135
            },
            {
                "lat": 49.5286633068,
                "lng": -123.4943614303
            },
            {
                "lat": 49.5256041074,
                "lng": -123.4953771457
            },
            {
                "lat": 49.5227437293,
                "lng": -123.4963301366
            },
            {
                "lat": 49.5140301316,
                "lng": -123.4989625396
            },
            {
                "lat": 49.5068506916,
                "lng": -123.4989927442
            },
            {
                "lat": 49.4997293837,
                "lng": -123.5009387114
            },
            {
                "lat": 49.4934804722,
                "lng": -123.4997863284
            },
            {
                "lat": 49.4881705334,
                "lng": -123.4939269857
            },
            {
                "lat": 49.4800111121,
                "lng": -123.4917135418
            },
            {
                "lat": 49.4716186682,
                "lng": -123.4920573436
            },
            {
                "lat": 49.4660296625,
                "lng": -123.491233114
            },
            {
                "lat": 49.4623637424,
                "lng": -123.48693019
            },
            {
                "lat": 49.4444613863,
                "lng": -123.4820781699
            },
            {
                "lat": 49.4313426398,
                "lng": -123.482520642
            },
            {
                "lat": 49.4277348009,
                "lng": -123.4794469272
            },
            {
                "lat": 49.4347233437,
                "lng": -123.4475473549
            },
            {
                "lat": 49.4348070198,
                "lng": -123.446967159
            },
            {
                "lat": 49.4348558714,
                "lng": -123.446616358
            },
            {
                "lat": 49.4352226352,
                "lng": -123.4443196227
            },
            {
                "lat": 49.4353519797,
                "lng": -123.4438868146
            },
            {
                "lat": 49.4356765994,
                "lng": -123.4428107453
            },
            {
                "lat": 49.4368396522,
                "lng": -123.4407576307
            },
            {
                "lat": 49.4383589229,
                "lng": -123.4366837787
            },
            {
                "lat": 49.4430421629,
                "lng": -123.4322287305
            },
            {
                "lat": 49.455524295,
                "lng": -123.4285418649
            },
            {
                "lat": 49.4575161339,
                "lng": -123.4279551095
            },
            {
                "lat": 49.4617420235,
                "lng": -123.4294733728
            },
            {
                "lat": 49.530318689,
                "lng": -123.3836131194
            },
            {
                "lat": 49.5315016499,
                "lng": -123.3823630356
            },
            {
                "lat": 49.5316960246,
                "lng": -123.3799826193
            },
            {
                "lat": 49.535137127,
                "lng": -123.3803863675
            },
            {
                "lat": 49.5541838927,
                "lng": -123.3583367377
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 581,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 28,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 28-3",
            "LABEL": "28-3",
            "FISHERY_AREA_ID": 2514,
            "OBJECTID": 725,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 85304193.1953,
            "FEATURE_LENGTH_M": 43465.3718,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.499355151643485,
            "lng": -123.44386443644785
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.592674332,
                "lng": -124.8272625724
            },
            {
                "lat": 49.5905917768,
                "lng": -124.8301014776
            },
            {
                "lat": 49.5813099819,
                "lng": -124.8317643877
            },
            {
                "lat": 49.5663226946,
                "lng": -124.8355712583
            },
            {
                "lat": 49.5555307877,
                "lng": -124.8395083298
            },
            {
                "lat": 49.5498089348,
                "lng": -124.8352113208
            },
            {
                "lat": 49.5336681353,
                "lng": -124.802985552
            },
            {
                "lat": 49.5243339255,
                "lng": -124.7942435178
            },
            {
                "lat": 49.5131533502,
                "lng": -124.7730638879
            },
            {
                "lat": 49.499351254,
                "lng": -124.7547457151
            },
            {
                "lat": 49.4922137957,
                "lng": -124.7437975386
            },
            {
                "lat": 49.4900705404,
                "lng": -124.7335658188
            },
            {
                "lat": 49.4893568424,
                "lng": -124.7228626503
            },
            {
                "lat": 49.4876899691,
                "lng": -124.7171475042
            },
            {
                "lat": 49.483883096,
                "lng": -124.704298814
            },
            {
                "lat": 49.4837794743,
                "lng": -124.703368891
            },
            {
                "lat": 49.4777072489,
                "lng": -124.6912451994
            },
            {
                "lat": 49.4767341092,
                "lng": -124.688041974
            },
            {
                "lat": 49.4776607284,
                "lng": -124.6887897482
            },
            {
                "lat": 49.4790494624,
                "lng": -124.6898951204
            },
            {
                "lat": 49.4814035028,
                "lng": -124.6924887561
            },
            {
                "lat": 49.481689907,
                "lng": -124.6934806814
            },
            {
                "lat": 49.4864267751,
                "lng": -124.6990043133
            },
            {
                "lat": 49.4881672075,
                "lng": -124.7040630504
            },
            {
                "lat": 49.4907842872,
                "lng": -124.7097702174
            },
            {
                "lat": 49.4934000196,
                "lng": -124.7181012142
            },
            {
                "lat": 49.4964942026,
                "lng": -124.7266682988
            },
            {
                "lat": 49.5017284014,
                "lng": -124.7349933055
            },
            {
                "lat": 49.5079158917,
                "lng": -124.7414172536
            },
            {
                "lat": 49.5214806581,
                "lng": -124.7549819151
            },
            {
                "lat": 49.5345040124,
                "lng": -124.7673261372
            },
            {
                "lat": 49.5460966801,
                "lng": -124.7690967441
            },
            {
                "lat": 49.5480465503,
                "lng": -124.7703327667
            },
            {
                "lat": 49.5767709227,
                "lng": -124.8027262296
            },
            {
                "lat": 49.5802994638,
                "lng": -124.8129877413
            },
            {
                "lat": 49.584347277,
                "lng": -124.8201219798
            },
            {
                "lat": 49.592674332,
                "lng": -124.8272625724
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 582,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 726,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 29920937.1082,
            "FEATURE_LENGTH_M": 35630.6228,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.52046271705134,
            "lng": -124.75546741770813
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.5833320614,
                "lng": -126.9260020769
            },
            {
                "lat": 49.5833319558,
                "lng": -127.5018690701
            },
            {
                "lat": 49.0000004084,
                "lng": -128.2450561145
            },
            {
                "lat": 49.0000003786,
                "lng": -127.0291369356
            },
            {
                "lat": 49.3829650829,
                "lng": -126.5440365817
            },
            {
                "lat": 49.5833320614,
                "lng": -126.9260020769
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 583,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 125,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 125-5",
            "LABEL": "125-5",
            "FISHERY_AREA_ID": 2333,
            "OBJECTID": 727,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 5213113276.4867,
            "FEATURE_LENGTH_M": 306066.0641,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.35549365808333,
            "lng": -127.19535047595001
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.5541838927,
                "lng": -123.3583367377
            },
            {
                "lat": 49.535137127,
                "lng": -123.3803863675
            },
            {
                "lat": 49.5316960246,
                "lng": -123.3799826193
            },
            {
                "lat": 49.5297471986,
                "lng": -123.3779986439
            },
            {
                "lat": 49.5283467743,
                "lng": -123.3759387293
            },
            {
                "lat": 49.4807281888,
                "lng": -123.3447343089
            },
            {
                "lat": 49.4782148554,
                "lng": -123.3408648194
            },
            {
                "lat": 49.4665571141,
                "lng": -123.3308714649
            },
            {
                "lat": 49.4613229451,
                "lng": -123.3235005173
            },
            {
                "lat": 49.4584659838,
                "lng": -123.3204037612
            },
            {
                "lat": 49.4532320269,
                "lng": -123.3194505388
            },
            {
                "lat": 49.4491879241,
                "lng": -123.3199300028
            },
            {
                "lat": 49.4455836066,
                "lng": -123.3217077452
            },
            {
                "lat": 49.4198115533,
                "lng": -123.3160322591
            },
            {
                "lat": 49.4195369129,
                "lng": -123.3158276833
            },
            {
                "lat": 49.4193541318,
                "lng": -123.3156281126
            },
            {
                "lat": 49.4191363732,
                "lng": -123.3153909501
            },
            {
                "lat": 49.4180222465,
                "lng": -123.3146821269
            },
            {
                "lat": 49.4170641485,
                "lng": -123.3147739573
            },
            {
                "lat": 49.4156763492,
                "lng": -123.3157951493
            },
            {
                "lat": 49.4129907428,
                "lng": -123.3172464494
            },
            {
                "lat": 49.4075694697,
                "lng": -123.3159953427
            },
            {
                "lat": 49.4037624472,
                "lng": -123.3209903418
            },
            {
                "lat": 49.3997194867,
                "lng": -123.3259895356
            },
            {
                "lat": 49.3992386915,
                "lng": -123.3269506908
            },
            {
                "lat": 49.3918416825,
                "lng": -123.3308716438
            },
            {
                "lat": 49.3897253331,
                "lng": -123.3345490678
            },
            {
                "lat": 49.3837774661,
                "lng": -123.3383553483
            },
            {
                "lat": 49.3763768885,
                "lng": -123.3390719156
            },
            {
                "lat": 49.3444325425,
                "lng": -123.3560246561
            },
            {
                "lat": 49.3438878418,
                "lng": -123.3580320066
            },
            {
                "lat": 49.3425059023,
                "lng": -123.3593595094
            },
            {
                "lat": 49.3398131712,
                "lng": -123.361045403
            },
            {
                "lat": 49.3385047746,
                "lng": -123.3622515639
            },
            {
                "lat": 49.3371461412,
                "lng": -123.3624716771
            },
            {
                "lat": 49.3364791594,
                "lng": -123.3622740609
            },
            {
                "lat": 49.3355791968,
                "lng": -123.3607174291
            },
            {
                "lat": 49.3303870649,
                "lng": -123.2646637274
            },
            {
                "lat": 49.3334499042,
                "lng": -123.2646101053
            },
            {
                "lat": 49.3772735076,
                "lng": -123.263838899
            },
            {
                "lat": 49.3870161185,
                "lng": -123.2469945859
            },
            {
                "lat": 49.4038730289,
                "lng": -123.2325432072
            },
            {
                "lat": 49.416839539,
                "lng": -123.2251358896
            },
            {
                "lat": 49.4286950962,
                "lng": -123.2229076027
            },
            {
                "lat": 49.4435114305,
                "lng": -123.2273556144
            },
            {
                "lat": 49.4879681043,
                "lng": -123.2314297448
            },
            {
                "lat": 49.5087132971,
                "lng": -123.2412481132
            },
            {
                "lat": 49.5215796311,
                "lng": -123.2502826512
            },
            {
                "lat": 49.5259707457,
                "lng": -123.2612160125
            },
            {
                "lat": 49.5102313698,
                "lng": -123.3035664907
            },
            {
                "lat": 49.511848493,
                "lng": -123.3033597528
            },
            {
                "lat": 49.5144084419,
                "lng": -123.3028941626
            },
            {
                "lat": 49.5196423196,
                "lng": -123.302078744
            },
            {
                "lat": 49.5309301318,
                "lng": -123.3038256945
            },
            {
                "lat": 49.5419848164,
                "lng": -123.309523875
            },
            {
                "lat": 49.5504796154,
                "lng": -123.3165055402
            },
            {
                "lat": 49.5557938843,
                "lng": -123.3235863549
            },
            {
                "lat": 49.5541838927,
                "lng": -123.3583367377
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 584,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 28,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 28-2",
            "LABEL": "28-2",
            "FISHERY_AREA_ID": 2513,
            "OBJECTID": 728,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 164039966.1276,
            "FEATURE_LENGTH_M": 68992.2837,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.440329943934465,
            "lng": -123.31535063176378
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.5613026922,
                "lng": -123.7714614075
            },
            {
                "lat": 49.5592038362,
                "lng": -123.7745051036
            },
            {
                "lat": 49.5585706071,
                "lng": -123.775764029
            },
            {
                "lat": 49.5583796741,
                "lng": -123.7763433599
            },
            {
                "lat": 49.5582385666,
                "lng": -123.7768563606
            },
            {
                "lat": 49.5580559315,
                "lng": -123.7772366072
            },
            {
                "lat": 49.5578078126,
                "lng": -123.7774956211
            },
            {
                "lat": 49.5465588673,
                "lng": -123.7926032236
            },
            {
                "lat": 49.5466157968,
                "lng": -123.7928854807
            },
            {
                "lat": 49.5468335,
                "lng": -123.7934951144
            },
            {
                "lat": 49.5468858344,
                "lng": -123.7942577501
            },
            {
                "lat": 49.5467754006,
                "lng": -123.7949832662
            },
            {
                "lat": 49.5462001015,
                "lng": -123.796059166
            },
            {
                "lat": 49.545009234,
                "lng": -123.8017654615
            },
            {
                "lat": 49.5442969381,
                "lng": -123.8077163836
            },
            {
                "lat": 49.5245516455,
                "lng": -123.8089069789
            },
            {
                "lat": 49.5033756697,
                "lng": -123.8067633838
            },
            {
                "lat": 49.4864802698,
                "lng": -123.7893992871
            },
            {
                "lat": 49.475536156,
                "lng": -123.7675027177
            },
            {
                "lat": 49.4750600231,
                "lng": -123.7551333463
            },
            {
                "lat": 49.4862440253,
                "lng": -123.7477569687
            },
            {
                "lat": 49.4952846594,
                "lng": -123.7425229409
            },
            {
                "lat": 49.5086097921,
                "lng": -123.7387162252
            },
            {
                "lat": 49.5207448724,
                "lng": -123.7484666663
            },
            {
                "lat": 49.5343095358,
                "lng": -123.754890875
            },
            {
                "lat": 49.5497740501,
                "lng": -123.7539441978
            },
            {
                "lat": 49.559054698,
                "lng": -123.7627487023
            },
            {
                "lat": 49.5613026922,
                "lng": -123.7714614075
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 585,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 16-5",
            "LABEL": "16-5",
            "FISHERY_AREA_ID": 2174,
            "OBJECTID": 729,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 33195645.8176,
            "FEATURE_LENGTH_M": 23767.144,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.53432367437142,
            "lng": -123.77684435830358
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4807281888,
                "lng": -123.3447343089
            },
            {
                "lat": 49.5283467743,
                "lng": -123.3759387293
            },
            {
                "lat": 49.5297471986,
                "lng": -123.3779986439
            },
            {
                "lat": 49.5316960246,
                "lng": -123.3799826193
            },
            {
                "lat": 49.5315016499,
                "lng": -123.3823630356
            },
            {
                "lat": 49.530318689,
                "lng": -123.3836131194
            },
            {
                "lat": 49.4617420235,
                "lng": -123.4294733728
            },
            {
                "lat": 49.4575161339,
                "lng": -123.4279551095
            },
            {
                "lat": 49.4696500354,
                "lng": -123.4193874769
            },
            {
                "lat": 49.477741005,
                "lng": -123.4034506931
            },
            {
                "lat": 49.4863057263,
                "lng": -123.3825074678
            },
            {
                "lat": 49.4858284338,
                "lng": -123.3706131049
            },
            {
                "lat": 49.4834522272,
                "lng": -123.3594279307
            },
            {
                "lat": 49.4820214883,
                "lng": -123.3537220005
            },
            {
                "lat": 49.4813079667,
                "lng": -123.3456273394
            },
            {
                "lat": 49.4807281888,
                "lng": -123.3447343089
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 586,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 730,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 15837248.7415,
            "FEATURE_LENGTH_M": 22948.3339,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.493664484631246,
            "lng": -123.38009557880625
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.5555307877,
                "lng": -124.8395083298
            },
            {
                "lat": 49.5555915379,
                "lng": -124.8431764598
            },
            {
                "lat": 49.5554690386,
                "lng": -124.8442156696
            },
            {
                "lat": 49.5553168043,
                "lng": -124.8453143325
            },
            {
                "lat": 49.5552096597,
                "lng": -124.8460008617
            },
            {
                "lat": 49.555209519,
                "lng": -124.8528669439
            },
            {
                "lat": 49.5552095313,
                "lng": -124.8720767104
            },
            {
                "lat": 49.5537904297,
                "lng": -124.8793260737
            },
            {
                "lat": 49.5466809742,
                "lng": -124.8781590649
            },
            {
                "lat": 49.5345458107,
                "lng": -124.8686448191
            },
            {
                "lat": 49.5117041135,
                "lng": -124.8507998733
            },
            {
                "lat": 49.4926678412,
                "lng": -124.8274767317
            },
            {
                "lat": 49.4864802995,
                "lng": -124.8151101093
            },
            {
                "lat": 49.476249691,
                "lng": -124.8077303427
            },
            {
                "lat": 49.4595906738,
                "lng": -124.8032074103
            },
            {
                "lat": 49.447932767,
                "lng": -124.7810823814
            },
            {
                "lat": 49.444126005,
                "lng": -124.7558581519
            },
            {
                "lat": 49.4467432022,
                "lng": -124.7389604215
            },
            {
                "lat": 49.4542310451,
                "lng": -124.7321081407
            },
            {
                "lat": 49.4573741675,
                "lng": -124.7280514655
            },
            {
                "lat": 49.4606548476,
                "lng": -124.7242747955
            },
            {
                "lat": 49.4615558107,
                "lng": -124.724151681
            },
            {
                "lat": 49.4639819902,
                "lng": -124.7240002225
            },
            {
                "lat": 49.4653321843,
                "lng": -124.7237940172
            },
            {
                "lat": 49.4662472673,
                "lng": -124.7243944058
            },
            {
                "lat": 49.4668842273,
                "lng": -124.725066269
            },
            {
                "lat": 49.4671484546,
                "lng": -124.7254399009
            },
            {
                "lat": 49.4721949882,
                "lng": -124.6851955499
            },
            {
                "lat": 49.4748155236,
                "lng": -124.6854709082
            },
            {
                "lat": 49.4767341092,
                "lng": -124.688041974
            },
            {
                "lat": 49.4777072489,
                "lng": -124.6912451994
            },
            {
                "lat": 49.4837794743,
                "lng": -124.703368891
            },
            {
                "lat": 49.483883096,
                "lng": -124.704298814
            },
            {
                "lat": 49.4876899691,
                "lng": -124.7171475042
            },
            {
                "lat": 49.4893568424,
                "lng": -124.7228626503
            },
            {
                "lat": 49.4900705404,
                "lng": -124.7335658188
            },
            {
                "lat": 49.4922137957,
                "lng": -124.7437975386
            },
            {
                "lat": 49.499351254,
                "lng": -124.7547457151
            },
            {
                "lat": 49.5131533502,
                "lng": -124.7730638879
            },
            {
                "lat": 49.5243339255,
                "lng": -124.7942435178
            },
            {
                "lat": 49.5336681353,
                "lng": -124.802985552
            },
            {
                "lat": 49.5498089348,
                "lng": -124.8352113208
            },
            {
                "lat": 49.5555307877,
                "lng": -124.8395083298
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 587,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 14-8",
            "LABEL": "14-8",
            "FISHERY_AREA_ID": 2156,
            "OBJECTID": 731,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 66959419.2481,
            "FEATURE_LENGTH_M": 40895.4347,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.49897094549303,
            "lng": -124.77571043621862
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.5390964405,
                "lng": -123.975043462
            },
            {
                "lat": 49.5385435764,
                "lng": -123.9746395114
            },
            {
                "lat": 49.5377658839,
                "lng": -123.9744716465
            },
            {
                "lat": 49.537590121,
                "lng": -123.9744724606
            },
            {
                "lat": 49.503749372,
                "lng": -124.0190588311
            },
            {
                "lat": 49.4922720351,
                "lng": -124.1249313515
            },
            {
                "lat": 49.4387476095,
                "lng": -124.1733694546
            },
            {
                "lat": 49.4674719094,
                "lng": -123.9124750679
            },
            {
                "lat": 49.4734884221,
                "lng": -123.8881155503
            },
            {
                "lat": 49.4787026098,
                "lng": -123.8858644946
            },
            {
                "lat": 49.4808155116,
                "lng": -123.8871302095
            },
            {
                "lat": 49.4928623108,
                "lng": -123.8928373803
            },
            {
                "lat": 49.5044862091,
                "lng": -123.8974917693
            },
            {
                "lat": 49.5129392196,
                "lng": -123.9046794901
            },
            {
                "lat": 49.5177993771,
                "lng": -123.9118563988
            },
            {
                "lat": 49.5203362421,
                "lng": -123.9209520044
            },
            {
                "lat": 49.5220264933,
                "lng": -123.9315192711
            },
            {
                "lat": 49.530903346,
                "lng": -123.9376454806
            },
            {
                "lat": 49.5357669457,
                "lng": -123.9452514307
            },
            {
                "lat": 49.5399927931,
                "lng": -123.957931268
            },
            {
                "lat": 49.5410499327,
                "lng": -123.9682920969
            },
            {
                "lat": 49.5400011707,
                "lng": -123.9728089771
            },
            {
                "lat": 49.5396657438,
                "lng": -123.9739754858
            },
            {
                "lat": 49.5390964405,
                "lng": -123.975043462
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 588,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 16-1",
            "LABEL": "16-1",
            "FISHERY_AREA_ID": 2170,
            "OBJECTID": 732,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 113330111.4336,
            "FEATURE_LENGTH_M": 52011.4606,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.513548738158335,
            "lng": -123.95749402312917
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.5260928558,
                "lng": -124.3634405771
            },
            {
                "lat": 49.5323326927,
                "lng": -124.3830261801
            },
            {
                "lat": 49.4966351917,
                "lng": -124.3983381714
            },
            {
                "lat": 49.4923710331,
                "lng": -124.3759919247
            },
            {
                "lat": 49.4952580914,
                "lng": -124.375992119
            },
            {
                "lat": 49.4991800615,
                "lng": -124.3743964887
            },
            {
                "lat": 49.516177808,
                "lng": -124.3674782728
            },
            {
                "lat": 49.5163384606,
                "lng": -124.3642507846
            },
            {
                "lat": 49.5173034697,
                "lng": -124.3604200388
            },
            {
                "lat": 49.519759861,
                "lng": -124.3580167294
            },
            {
                "lat": 49.5226552276,
                "lng": -124.357642705
            },
            {
                "lat": 49.5260928558,
                "lng": -124.3634405771
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 589,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 20,
            "MANAGEMENT_AREA_NAME": "Subarea 16-20",
            "LABEL": "16-20",
            "FISHERY_AREA_ID": 2189,
            "OBJECTID": 733,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 6766276.0551,
            "FEATURE_LENGTH_M": 11860.3972,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.51334980074167,
            "lng": -124.370202880725
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.535266575,
                "lng": -124.7127532965
            },
            {
                "lat": 49.5352664749,
                "lng": -124.7558974982
            },
            {
                "lat": 49.5345040124,
                "lng": -124.7673261372
            },
            {
                "lat": 49.5214806581,
                "lng": -124.7549819151
            },
            {
                "lat": 49.5079158917,
                "lng": -124.7414172536
            },
            {
                "lat": 49.5017284014,
                "lng": -124.7349933055
            },
            {
                "lat": 49.4964942026,
                "lng": -124.7266682988
            },
            {
                "lat": 49.4934000196,
                "lng": -124.7181012142
            },
            {
                "lat": 49.4907842872,
                "lng": -124.7097702174
            },
            {
                "lat": 49.4881672075,
                "lng": -124.7040630504
            },
            {
                "lat": 49.4864267751,
                "lng": -124.6990043133
            },
            {
                "lat": 49.481689907,
                "lng": -124.6934806814
            },
            {
                "lat": 49.4814035028,
                "lng": -124.6924887561
            },
            {
                "lat": 49.4790494624,
                "lng": -124.6898951204
            },
            {
                "lat": 49.4776607284,
                "lng": -124.6887897482
            },
            {
                "lat": 49.4767341092,
                "lng": -124.688041974
            },
            {
                "lat": 49.4748155236,
                "lng": -124.6854709082
            },
            {
                "lat": 49.4721949882,
                "lng": -124.6851955499
            },
            {
                "lat": 49.4838213225,
                "lng": -124.6477957222
            },
            {
                "lat": 49.5175818236,
                "lng": -124.5762250435
            },
            {
                "lat": 49.5170137091,
                "lng": -124.5846926937
            },
            {
                "lat": 49.5174375955,
                "lng": -124.5876390667
            },
            {
                "lat": 49.5197295612,
                "lng": -124.5951998012
            },
            {
                "lat": 49.5205342135,
                "lng": -124.6005473844
            },
            {
                "lat": 49.5216446639,
                "lng": -124.6055977081
            },
            {
                "lat": 49.5243724008,
                "lng": -124.611755866
            },
            {
                "lat": 49.5275994155,
                "lng": -124.6179118634
            },
            {
                "lat": 49.5306286137,
                "lng": -124.6253743343
            },
            {
                "lat": 49.5324445513,
                "lng": -124.6312321901
            },
            {
                "lat": 49.5318414518,
                "lng": -124.6379922314
            },
            {
                "lat": 49.5288123339,
                "lng": -124.6463698414
            },
            {
                "lat": 49.5269580121,
                "lng": -124.6544583629
            },
            {
                "lat": 49.532248961,
                "lng": -124.6915738919
            },
            {
                "lat": 49.5331839148,
                "lng": -124.6940761383
            },
            {
                "lat": 49.53495434,
                "lng": -124.7016143246
            },
            {
                "lat": 49.5355261317,
                "lng": -124.708183699
            },
            {
                "lat": 49.5355829742,
                "lng": -124.7112651088
            },
            {
                "lat": 49.535266575,
                "lng": -124.7127532965
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 590,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 14-7",
            "LABEL": "14-7",
            "FISHERY_AREA_ID": 2155,
            "OBJECTID": 734,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 51621151.983,
            "FEATURE_LENGTH_M": 33370.9414,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.51163592874211,
            "lng": -124.67606836333687
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.5260928558,
                "lng": -124.3634405771
            },
            {
                "lat": 49.5226552276,
                "lng": -124.357642705
            },
            {
                "lat": 49.5226097714,
                "lng": -124.3558649227
            },
            {
                "lat": 49.5209393126,
                "lng": -124.3522259887
            },
            {
                "lat": 49.5191499077,
                "lng": -124.3520206806
            },
            {
                "lat": 49.513915816,
                "lng": -124.3517762339
            },
            {
                "lat": 49.5086818607,
                "lng": -124.3498771965
            },
            {
                "lat": 49.5060614559,
                "lng": -124.3405991434
            },
            {
                "lat": 49.506061483,
                "lng": -124.3320313489
            },
            {
                "lat": 49.5081485548,
                "lng": -124.3243866975
            },
            {
                "lat": 49.5048751541,
                "lng": -124.3096614356
            },
            {
                "lat": 49.5001149317,
                "lng": -124.2939597854
            },
            {
                "lat": 49.4941673208,
                "lng": -124.2758717701
            },
            {
                "lat": 49.4882161438,
                "lng": -124.2637402643
            },
            {
                "lat": 49.48369552,
                "lng": -124.2523198916
            },
            {
                "lat": 49.487266128,
                "lng": -124.2461312399
            },
            {
                "lat": 49.4894068739,
                "lng": -124.2399443896
            },
            {
                "lat": 49.4863125211,
                "lng": -124.2306666669
            },
            {
                "lat": 49.4796525175,
                "lng": -124.2230518464
            },
            {
                "lat": 49.4717978608,
                "lng": -124.2132940512
            },
            {
                "lat": 49.468467259,
                "lng": -124.2035359679
            },
            {
                "lat": 49.4696583615,
                "lng": -124.1973487071
            },
            {
                "lat": 49.4668003484,
                "lng": -124.1875994835
            },
            {
                "lat": 49.4610902612,
                "lng": -124.1818853614
            },
            {
                "lat": 49.4546664318,
                "lng": -124.1726073806
            },
            {
                "lat": 49.4491923815,
                "lng": -124.1714159605
            },
            {
                "lat": 49.444434502,
                "lng": -124.1733169778
            },
            {
                "lat": 49.4420551286,
                "lng": -124.1747446265
            },
            {
                "lat": 49.4387476095,
                "lng": -124.1733694546
            },
            {
                "lat": 49.4922720351,
                "lng": -124.1249313515
            },
            {
                "lat": 49.4926830358,
                "lng": -124.1271518759
            },
            {
                "lat": 49.4936327864,
                "lng": -124.1281120889
            },
            {
                "lat": 49.4960636204,
                "lng": -124.130706298
            },
            {
                "lat": 49.4956514987,
                "lng": -124.1323013873
            },
            {
                "lat": 49.4942242923,
                "lng": -124.1364973414
            },
            {
                "lat": 49.4929472869,
                "lng": -124.1402436752
            },
            {
                "lat": 49.4926189099,
                "lng": -124.1412194033
            },
            {
                "lat": 49.4887237095,
                "lng": -124.141357269
            },
            {
                "lat": 49.5103116682,
                "lng": -124.21253957
            },
            {
                "lat": 49.5175782044,
                "lng": -124.2421193054
            },
            {
                "lat": 49.5161823902,
                "lng": -124.2525854916
            },
            {
                "lat": 49.5260928558,
                "lng": -124.3634405771
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 591,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 19,
            "MANAGEMENT_AREA_NAME": "Subarea 16-19",
            "LABEL": "16-19",
            "FISHERY_AREA_ID": 2188,
            "OBJECTID": 735,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 49787868.3834,
            "FEATURE_LENGTH_M": 45468.638,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.49152180462619,
            "lng": -124.23660800928093
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4135507912,
                "lng": -126.4080577083
            },
            {
                "lat": 49.3331380984,
                "lng": -126.2600855752
            },
            {
                "lat": 49.2836953789,
                "lng": -126.2305522553
            },
            {
                "lat": 49.2837941262,
                "lng": -126.2297442469
            },
            {
                "lat": 49.2840690058,
                "lng": -126.2287680291
            },
            {
                "lat": 49.2852820184,
                "lng": -126.2268908857
            },
            {
                "lat": 49.2865404729,
                "lng": -126.2253194301
            },
            {
                "lat": 49.2908094238,
                "lng": -126.2141035962
            },
            {
                "lat": 49.2966803499,
                "lng": -126.2077021222
            },
            {
                "lat": 49.3217619382,
                "lng": -126.2119745476
            },
            {
                "lat": 49.3372416221,
                "lng": -126.2178419875
            },
            {
                "lat": 49.3575213372,
                "lng": -126.2151710338
            },
            {
                "lat": 49.3751336128,
                "lng": -126.2189103572
            },
            {
                "lat": 49.3847391794,
                "lng": -126.2247850207
            },
            {
                "lat": 49.3925820146,
                "lng": -126.2285533618
            },
            {
                "lat": 49.3934061364,
                "lng": -126.2291106196
            },
            {
                "lat": 49.3937916377,
                "lng": -126.2297818565
            },
            {
                "lat": 49.3941116515,
                "lng": -126.2308347629
            },
            {
                "lat": 49.4125251164,
                "lng": -126.2308354205
            },
            {
                "lat": 49.4131735738,
                "lng": -126.2302852326
            },
            {
                "lat": 49.4129942119,
                "lng": -126.2262649812
            },
            {
                "lat": 49.4135973615,
                "lng": -126.2203989115
            },
            {
                "lat": 49.4167639002,
                "lng": -126.2178420913
            },
            {
                "lat": 49.4237021706,
                "lng": -126.2055659845
            },
            {
                "lat": 49.4407803533,
                "lng": -126.1986309532
            },
            {
                "lat": 49.4621274606,
                "lng": -126.2023689753
            },
            {
                "lat": 49.4818761427,
                "lng": -126.2157055497
            },
            {
                "lat": 49.4882778375,
                "lng": -126.2343908518
            },
            {
                "lat": 49.4834745496,
                "lng": -126.2541353615
            },
            {
                "lat": 49.4893452541,
                "lng": -126.2685466588
            },
            {
                "lat": 49.5208356161,
                "lng": -126.2845542476
            },
            {
                "lat": 49.5368462684,
                "lng": -126.2968289555
            },
            {
                "lat": 49.5379136808,
                "lng": -126.306968425
            },
            {
                "lat": 49.5331115567,
                "lng": -126.313377311
            },
            {
                "lat": 49.4856105605,
                "lng": -126.3096387128
            },
            {
                "lat": 49.4583928374,
                "lng": -126.3021695381
            },
            {
                "lat": 49.4349093895,
                "lng": -126.3283154102
            },
            {
                "lat": 49.4215660001,
                "lng": -126.3347243265
            },
            {
                "lat": 49.4146277318,
                "lng": -126.3288496871
            },
            {
                "lat": 49.4130245731,
                "lng": -126.3139114873
            },
            {
                "lat": 49.4156952263,
                "lng": -126.297897653
            },
            {
                "lat": 49.4237023002,
                "lng": -126.2813566638
            },
            {
                "lat": 49.4263691068,
                "lng": -126.2680138885
            },
            {
                "lat": 49.4226345239,
                "lng": -126.2637413313
            },
            {
                "lat": 49.412719503,
                "lng": -126.2639240647
            },
            {
                "lat": 49.3986170884,
                "lng": -126.266410223
            },
            {
                "lat": 49.3826064584,
                "lng": -126.2802880322
            },
            {
                "lat": 49.3810045933,
                "lng": -126.308569952
            },
            {
                "lat": 49.407688213,
                "lng": -126.3389965409
            },
            {
                "lat": 49.4132387689,
                "lng": -126.3539813161
            },
            {
                "lat": 49.4135089571,
                "lng": -126.4005813385
            },
            {
                "lat": 49.4135507912,
                "lng": -126.4080577083
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 592,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 24,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 24-2",
            "LABEL": "24-2",
            "FISHERY_AREA_ID": 2255,
            "OBJECTID": 736,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 145276783.9009,
            "FEATURE_LENGTH_M": 90875.0938,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.4060511629327,
            "lng": -126.26527529195961
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.5226552276,
                "lng": -124.357642705
            },
            {
                "lat": 49.519759861,
                "lng": -124.3580167294
            },
            {
                "lat": 49.5173034697,
                "lng": -124.3604200388
            },
            {
                "lat": 49.5163384606,
                "lng": -124.3642507846
            },
            {
                "lat": 49.516177808,
                "lng": -124.3674782728
            },
            {
                "lat": 49.4991800615,
                "lng": -124.3743964887
            },
            {
                "lat": 49.5001148932,
                "lng": -124.37152931
            },
            {
                "lat": 49.5013047148,
                "lng": -124.3681941243
            },
            {
                "lat": 49.5034444554,
                "lng": -124.3651045859
            },
            {
                "lat": 49.5055887029,
                "lng": -124.359154118
            },
            {
                "lat": 49.5036853166,
                "lng": -124.3515395155
            },
            {
                "lat": 49.5008273259,
                "lng": -124.3458332225
            },
            {
                "lat": 49.4917864158,
                "lng": -124.3427352773
            },
            {
                "lat": 49.4851916284,
                "lng": -124.339888575
            },
            {
                "lat": 49.4658316719,
                "lng": -124.2704771887
            },
            {
                "lat": 49.4689447469,
                "lng": -124.2601703717
            },
            {
                "lat": 49.4727513851,
                "lng": -124.2504116933
            },
            {
                "lat": 49.4744184383,
                "lng": -124.245177778
            },
            {
                "lat": 49.4691808839,
                "lng": -124.237327564
            },
            {
                "lat": 49.4634708888,
                "lng": -124.2244793355
            },
            {
                "lat": 49.4589504573,
                "lng": -124.2104419741
            },
            {
                "lat": 49.4532390677,
                "lng": -124.19759467
            },
            {
                "lat": 49.4461014883,
                "lng": -124.1854544056
            },
            {
                "lat": 49.4432448216,
                "lng": -124.1809316002
            },
            {
                "lat": 49.4420551286,
                "lng": -124.1747446265
            },
            {
                "lat": 49.444434502,
                "lng": -124.1733169778
            },
            {
                "lat": 49.4491923815,
                "lng": -124.1714159605
            },
            {
                "lat": 49.4546664318,
                "lng": -124.1726073806
            },
            {
                "lat": 49.4610902612,
                "lng": -124.1818853614
            },
            {
                "lat": 49.4668003484,
                "lng": -124.1875994835
            },
            {
                "lat": 49.4696583615,
                "lng": -124.1973487071
            },
            {
                "lat": 49.468467259,
                "lng": -124.2035359679
            },
            {
                "lat": 49.4717978608,
                "lng": -124.2132940512
            },
            {
                "lat": 49.4796525175,
                "lng": -124.2230518464
            },
            {
                "lat": 49.4863125211,
                "lng": -124.2306666669
            },
            {
                "lat": 49.4894068739,
                "lng": -124.2399443896
            },
            {
                "lat": 49.487266128,
                "lng": -124.2461312399
            },
            {
                "lat": 49.48369552,
                "lng": -124.2523198916
            },
            {
                "lat": 49.4882161438,
                "lng": -124.2637402643
            },
            {
                "lat": 49.4941673208,
                "lng": -124.2758717701
            },
            {
                "lat": 49.5001149317,
                "lng": -124.2939597854
            },
            {
                "lat": 49.5048751541,
                "lng": -124.3096614356
            },
            {
                "lat": 49.5081485548,
                "lng": -124.3243866975
            },
            {
                "lat": 49.506061483,
                "lng": -124.3320313489
            },
            {
                "lat": 49.5060614559,
                "lng": -124.3405991434
            },
            {
                "lat": 49.5086818607,
                "lng": -124.3498771965
            },
            {
                "lat": 49.513915816,
                "lng": -124.3517762339
            },
            {
                "lat": 49.5191499077,
                "lng": -124.3520206806
            },
            {
                "lat": 49.5209393126,
                "lng": -124.3522259887
            },
            {
                "lat": 49.5226097714,
                "lng": -124.3558649227
            },
            {
                "lat": 49.5226552276,
                "lng": -124.357642705
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 593,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 737,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 31060876.0648,
            "FEATURE_LENGTH_M": 40065.182,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.48763892601177,
            "lng": -124.28267002063531
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4867782169,
                "lng": -124.434776162
            },
            {
                "lat": 49.5175818236,
                "lng": -124.5762250435
            },
            {
                "lat": 49.4838213225,
                "lng": -124.6477957222
            },
            {
                "lat": 49.4721949882,
                "lng": -124.6851955499
            },
            {
                "lat": 49.4671484546,
                "lng": -124.7254399009
            },
            {
                "lat": 49.4668842273,
                "lng": -124.725066269
            },
            {
                "lat": 49.4662472673,
                "lng": -124.7243944058
            },
            {
                "lat": 49.4653321843,
                "lng": -124.7237940172
            },
            {
                "lat": 49.4639819902,
                "lng": -124.7240002225
            },
            {
                "lat": 49.4615558107,
                "lng": -124.724151681
            },
            {
                "lat": 49.4606548476,
                "lng": -124.7242747955
            },
            {
                "lat": 49.4462657295,
                "lng": -124.7125481947
            },
            {
                "lat": 49.4184261651,
                "lng": -124.6763846571
            },
            {
                "lat": 49.391540141,
                "lng": -124.6345057536
            },
            {
                "lat": 49.3746448126,
                "lng": -124.5940553717
            },
            {
                "lat": 49.3677449141,
                "lng": -124.5721593864
            },
            {
                "lat": 49.3648379724,
                "lng": -124.5498592579
            },
            {
                "lat": 49.3782689087,
                "lng": -124.5523924247
            },
            {
                "lat": 49.4867782169,
                "lng": -124.434776162
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 594,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 14-5",
            "LABEL": "14-5",
            "FISHERY_AREA_ID": 2153,
            "OBJECTID": 738,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 189224840.1357,
            "FEATURE_LENGTH_M": 57265.0487,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.44424673649999,
            "lng": -124.63904184092632
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4616012367,
                "lng": -122.8684913201
            },
            {
                "lat": 49.4666862141,
                "lng": -122.8699801259
            },
            {
                "lat": 49.4735371382,
                "lng": -122.8742601224
            },
            {
                "lat": 49.4770204226,
                "lng": -122.8824321474
            },
            {
                "lat": 49.4750215318,
                "lng": -122.8874515453
            },
            {
                "lat": 49.4714813662,
                "lng": -122.8920221851
            },
            {
                "lat": 49.4654278944,
                "lng": -122.8931662374
            },
            {
                "lat": 49.4593160264,
                "lng": -122.8899693154
            },
            {
                "lat": 49.4562834361,
                "lng": -122.8870933742
            },
            {
                "lat": 49.4569933596,
                "lng": -122.8850018074
            },
            {
                "lat": 49.4577327219,
                "lng": -122.8707577566
            },
            {
                "lat": 49.4616012367,
                "lng": -122.8684913201
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 595,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 28,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 28-14",
            "LABEL": "28-14",
            "FISHERY_AREA_ID": 2525,
            "OBJECTID": 739,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2975260.0439,
            "FEATURE_LENGTH_M": 6506.6381,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.46522521539166,
            "lng": -122.88075977144166
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4991800615,
                "lng": -124.3743964887
            },
            {
                "lat": 49.4952580914,
                "lng": -124.375992119
            },
            {
                "lat": 49.4923710331,
                "lng": -124.3759919247
            },
            {
                "lat": 49.4966351917,
                "lng": -124.3983381714
            },
            {
                "lat": 49.4867782169,
                "lng": -124.434776162
            },
            {
                "lat": 49.3516120431,
                "lng": -124.3546367823
            },
            {
                "lat": 49.3503873333,
                "lng": -124.1602486264
            },
            {
                "lat": 49.4387476095,
                "lng": -124.1733694546
            },
            {
                "lat": 49.4420551286,
                "lng": -124.1747446265
            },
            {
                "lat": 49.4432448216,
                "lng": -124.1809316002
            },
            {
                "lat": 49.4461014883,
                "lng": -124.1854544056
            },
            {
                "lat": 49.4532390677,
                "lng": -124.19759467
            },
            {
                "lat": 49.4589504573,
                "lng": -124.2104419741
            },
            {
                "lat": 49.4634708888,
                "lng": -124.2244793355
            },
            {
                "lat": 49.4691808839,
                "lng": -124.237327564
            },
            {
                "lat": 49.4744184383,
                "lng": -124.245177778
            },
            {
                "lat": 49.4727513851,
                "lng": -124.2504116933
            },
            {
                "lat": 49.4689447469,
                "lng": -124.2601703717
            },
            {
                "lat": 49.4658316719,
                "lng": -124.2704771887
            },
            {
                "lat": 49.4851916284,
                "lng": -124.339888575
            },
            {
                "lat": 49.4917864158,
                "lng": -124.3427352773
            },
            {
                "lat": 49.5008273259,
                "lng": -124.3458332225
            },
            {
                "lat": 49.5036853166,
                "lng": -124.3515395155
            },
            {
                "lat": 49.5055887029,
                "lng": -124.359154118
            },
            {
                "lat": 49.5034444554,
                "lng": -124.3651045859
            },
            {
                "lat": 49.5013047148,
                "lng": -124.3681941243
            },
            {
                "lat": 49.5001148932,
                "lng": -124.37152931
            },
            {
                "lat": 49.4991800615,
                "lng": -124.3743964887
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 596,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 14-3",
            "LABEL": "14-3",
            "FISHERY_AREA_ID": 2151,
            "OBJECTID": 740,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 227049814.2363,
            "FEATURE_LENGTH_M": 63902.7348,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.470010074049995,
            "lng": -124.29654771978213
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4575161339,
                "lng": -123.4279551095
            },
            {
                "lat": 49.455524295,
                "lng": -123.4285418649
            },
            {
                "lat": 49.4430421629,
                "lng": -123.4322287305
            },
            {
                "lat": 49.4383589229,
                "lng": -123.4366837787
            },
            {
                "lat": 49.4368396522,
                "lng": -123.4407576307
            },
            {
                "lat": 49.4356765994,
                "lng": -123.4428107453
            },
            {
                "lat": 49.4353519797,
                "lng": -123.4438868146
            },
            {
                "lat": 49.4352226352,
                "lng": -123.4443196227
            },
            {
                "lat": 49.4348558714,
                "lng": -123.446616358
            },
            {
                "lat": 49.4348070198,
                "lng": -123.446967159
            },
            {
                "lat": 49.4347233437,
                "lng": -123.4475473549
            },
            {
                "lat": 49.4277348009,
                "lng": -123.4794469272
            },
            {
                "lat": 49.4313426398,
                "lng": -123.482520642
            },
            {
                "lat": 49.4265742385,
                "lng": -123.4853199636
            },
            {
                "lat": 49.4193424872,
                "lng": -123.4935376746
            },
            {
                "lat": 49.4121095737,
                "lng": -123.5007693599
            },
            {
                "lat": 49.4058951398,
                "lng": -123.5065299875
            },
            {
                "lat": 49.3933979654,
                "lng": -123.5231704558
            },
            {
                "lat": 49.3930394342,
                "lng": -123.5364463534
            },
            {
                "lat": 49.3836901172,
                "lng": -123.5360955592
            },
            {
                "lat": 49.3583303895,
                "lng": -123.4859544444
            },
            {
                "lat": 49.3484419714,
                "lng": -123.4650579199
            },
            {
                "lat": 49.3399540271,
                "lng": -123.4329900581
            },
            {
                "lat": 49.3410647254,
                "lng": -123.4218976541
            },
            {
                "lat": 49.3447191357,
                "lng": -123.4207000748
            },
            {
                "lat": 49.3499717897,
                "lng": -123.4150457814
            },
            {
                "lat": 49.3562662682,
                "lng": -123.4091030772
            },
            {
                "lat": 49.4106369943,
                "lng": -123.3294748791
            },
            {
                "lat": 49.4117580257,
                "lng": -123.3245612198
            },
            {
                "lat": 49.4123843163,
                "lng": -123.3198017376
            },
            {
                "lat": 49.4129907428,
                "lng": -123.3172464494
            },
            {
                "lat": 49.4156763492,
                "lng": -123.3157951493
            },
            {
                "lat": 49.4170641485,
                "lng": -123.3147739573
            },
            {
                "lat": 49.4180222465,
                "lng": -123.3146821269
            },
            {
                "lat": 49.4191363732,
                "lng": -123.3153909501
            },
            {
                "lat": 49.4193541318,
                "lng": -123.3156281126
            },
            {
                "lat": 49.4195369129,
                "lng": -123.3158276833
            },
            {
                "lat": 49.4198115533,
                "lng": -123.3160322591
            },
            {
                "lat": 49.4455836066,
                "lng": -123.3217077452
            },
            {
                "lat": 49.4491879241,
                "lng": -123.3199300028
            },
            {
                "lat": 49.4532320269,
                "lng": -123.3194505388
            },
            {
                "lat": 49.4584659838,
                "lng": -123.3204037612
            },
            {
                "lat": 49.4613229451,
                "lng": -123.3235005173
            },
            {
                "lat": 49.4665571141,
                "lng": -123.3308714649
            },
            {
                "lat": 49.4782148554,
                "lng": -123.3408648194
            },
            {
                "lat": 49.4807281888,
                "lng": -123.3447343089
            },
            {
                "lat": 49.4813079667,
                "lng": -123.3456273394
            },
            {
                "lat": 49.4820214883,
                "lng": -123.3537220005
            },
            {
                "lat": 49.4834522272,
                "lng": -123.3594279307
            },
            {
                "lat": 49.4858284338,
                "lng": -123.3706131049
            },
            {
                "lat": 49.4863057263,
                "lng": -123.3825074678
            },
            {
                "lat": 49.477741005,
                "lng": -123.4034506931
            },
            {
                "lat": 49.4696500354,
                "lng": -123.4193874769
            },
            {
                "lat": 49.4575161339,
                "lng": -123.4279551095
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 597,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 28,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 28-1",
            "LABEL": "28-1",
            "FISHERY_AREA_ID": 2512,
            "OBJECTID": 741,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 139871369.0979,
            "FEATURE_LENGTH_M": 50106.1758,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.42717186621667,
            "lng": -123.40030129458707
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4616012367,
                "lng": -122.8684913201
            },
            {
                "lat": 49.4577327219,
                "lng": -122.8707577566
            },
            {
                "lat": 49.4569933596,
                "lng": -122.8850018074
            },
            {
                "lat": 49.4562834361,
                "lng": -122.8870933742
            },
            {
                "lat": 49.4557758066,
                "lng": -122.8869396103
            },
            {
                "lat": 49.449779199,
                "lng": -122.8844830428
            },
            {
                "lat": 49.443786118,
                "lng": -122.8828286026
            },
            {
                "lat": 49.43419225,
                "lng": -122.8812866383
            },
            {
                "lat": 49.4298512866,
                "lng": -122.8800886714
            },
            {
                "lat": 49.429981672,
                "lng": -122.877678822
            },
            {
                "lat": 49.4300805808,
                "lng": -122.8758381675
            },
            {
                "lat": 49.4300805312,
                "lng": -122.8545680897
            },
            {
                "lat": 49.4291073113,
                "lng": -122.8479379642
            },
            {
                "lat": 49.4349327605,
                "lng": -122.8457097823
            },
            {
                "lat": 49.4404717953,
                "lng": -122.8463970551
            },
            {
                "lat": 49.4533228654,
                "lng": -122.8510743054
            },
            {
                "lat": 49.4598318162,
                "lng": -122.8622141118
            },
            {
                "lat": 49.4616012367,
                "lng": -122.8684913201
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 598,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 28,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 28-13",
            "LABEL": "28-13",
            "FISHERY_AREA_ID": 2524,
            "OBJECTID": 742,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 7892355.8431,
            "FEATURE_LENGTH_M": 11335.2307,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.44530033243889,
            "lng": -122.8698266912111
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4787026098,
                "lng": -123.8858644946
            },
            {
                "lat": 49.4734884221,
                "lng": -123.8881155503
            },
            {
                "lat": 49.4183308089,
                "lng": -123.7119439369
            },
            {
                "lat": 49.3836901172,
                "lng": -123.5360955592
            },
            {
                "lat": 49.3930394342,
                "lng": -123.5364463534
            },
            {
                "lat": 49.4033969429,
                "lng": -123.5525517496
            },
            {
                "lat": 49.4112853775,
                "lng": -123.5689938367
            },
            {
                "lat": 49.4173704477,
                "lng": -123.5819785956
            },
            {
                "lat": 49.4341354444,
                "lng": -123.6403351531
            },
            {
                "lat": 49.4408756727,
                "lng": -123.6745298545
            },
            {
                "lat": 49.4444928671,
                "lng": -123.6817620609
            },
            {
                "lat": 49.4431795152,
                "lng": -123.7057640075
            },
            {
                "lat": 49.4502448497,
                "lng": -123.7213825861
            },
            {
                "lat": 49.4655338324,
                "lng": -123.7325596631
            },
            {
                "lat": 49.4745744044,
                "lng": -123.7417678552
            },
            {
                "lat": 49.4734265408,
                "lng": -123.7547540519
            },
            {
                "lat": 49.4727666422,
                "lng": -123.7682349915
            },
            {
                "lat": 49.4750703204,
                "lng": -123.7830268471
            },
            {
                "lat": 49.4786875672,
                "lng": -123.8106455254
            },
            {
                "lat": 49.4836199092,
                "lng": -123.8410559517
            },
            {
                "lat": 49.4827958294,
                "lng": -123.8612825682
            },
            {
                "lat": 49.4760552073,
                "lng": -123.8711397625
            },
            {
                "lat": 49.4757270109,
                "lng": -123.8783786504
            },
            {
                "lat": 49.4787026098,
                "lng": -123.8858644946
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 599,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 29-1",
            "LABEL": "29-1",
            "FISHERY_AREA_ID": 2526,
            "OBJECTID": 743,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 77123399.7107,
            "FEATURE_LENGTH_M": 58556.1757,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.45121634930834,
            "lng": -123.73393642083333
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4867782169,
                "lng": -124.434776162
            },
            {
                "lat": 49.3782689087,
                "lng": -124.5523924247
            },
            {
                "lat": 49.3648379724,
                "lng": -124.5498592579
            },
            {
                "lat": 49.3522068155,
                "lng": -124.5113830352
            },
            {
                "lat": 49.3435931946,
                "lng": -124.4467855506
            },
            {
                "lat": 49.3430169833,
                "lng": -124.4031448507
            },
            {
                "lat": 49.3407213757,
                "lng": -124.3804630703
            },
            {
                "lat": 49.3383907347,
                "lng": -124.3684391703
            },
            {
                "lat": 49.3445699419,
                "lng": -124.3629004128
            },
            {
                "lat": 49.3469694435,
                "lng": -124.3610687433
            },
            {
                "lat": 49.347931051,
                "lng": -124.3603744723
            },
            {
                "lat": 49.3488504997,
                "lng": -124.3597573199
            },
            {
                "lat": 49.3493733538,
                "lng": -124.3586039721
            },
            {
                "lat": 49.3498844729,
                "lng": -124.3581233232
            },
            {
                "lat": 49.350455888,
                "lng": -124.3573533718
            },
            {
                "lat": 49.3505548644,
                "lng": -124.3567583581
            },
            {
                "lat": 49.3511543541,
                "lng": -124.3565221492
            },
            {
                "lat": 49.3514443668,
                "lng": -124.3559117453
            },
            {
                "lat": 49.3516470287,
                "lng": -124.3552545695
            },
            {
                "lat": 49.3516235221,
                "lng": -124.3548736692
            },
            {
                "lat": 49.3516120431,
                "lng": -124.3546367823
            },
            {
                "lat": 49.4867782169,
                "lng": -124.434776162
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 600,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 14-4",
            "LABEL": "14-4",
            "FISHERY_AREA_ID": 2152,
            "OBJECTID": 744,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 126542917.2631,
            "FEATURE_LENGTH_M": 47912.5352,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.362757420395454,
            "lng": -124.39700720785002
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4734884221,
                "lng": -123.8881155503
            },
            {
                "lat": 49.4674719094,
                "lng": -123.9124750679
            },
            {
                "lat": 49.4387476095,
                "lng": -124.1733694546
            },
            {
                "lat": 49.3503873333,
                "lng": -124.1602486264
            },
            {
                "lat": 49.2091940262,
                "lng": -123.8081357528
            },
            {
                "lat": 49.1499444683,
                "lng": -123.6416095021
            },
            {
                "lat": 49.4734884221,
                "lng": -123.8881155503
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 601,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 17-11",
            "LABEL": "17-11",
            "FISHERY_AREA_ID": 2202,
            "OBJECTID": 745,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 589800145.599,
            "FEATURE_LENGTH_M": 115014.5732,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.36610317012857,
            "lng": -123.92458135777143
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3836901172,
                "lng": -123.5360955592
            },
            {
                "lat": 49.4183308089,
                "lng": -123.7119439369
            },
            {
                "lat": 49.4734884221,
                "lng": -123.8881155503
            },
            {
                "lat": 49.1499444683,
                "lng": -123.6416095021
            },
            {
                "lat": 49.3836901172,
                "lng": -123.5360955592
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 602,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 29-2",
            "LABEL": "29-2",
            "FISHERY_AREA_ID": 2527,
            "OBJECTID": 746,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 355372631.8481,
            "FEATURE_LENGTH_M": 94783.618,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.36182878673999,
            "lng": -123.66277202154
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4135507912,
                "lng": -126.4080577083
            },
            {
                "lat": 49.4135089571,
                "lng": -126.4005813385
            },
            {
                "lat": 49.4163249238,
                "lng": -126.3981018195
            },
            {
                "lat": 49.4200967636,
                "lng": -126.3981015764
            },
            {
                "lat": 49.4316439765,
                "lng": -126.3949970917
            },
            {
                "lat": 49.4491844442,
                "lng": -126.3952185011
            },
            {
                "lat": 49.4651680864,
                "lng": -126.3945461296
            },
            {
                "lat": 49.476715416,
                "lng": -126.3976596
            },
            {
                "lat": 49.479377688,
                "lng": -126.4020998831
            },
            {
                "lat": 49.480266005,
                "lng": -126.4085385414
            },
            {
                "lat": 49.4800447561,
                "lng": -126.4280775504
            },
            {
                "lat": 49.4791565004,
                "lng": -126.4438398803
            },
            {
                "lat": 49.4753800903,
                "lng": -126.4627074519
            },
            {
                "lat": 49.4560655405,
                "lng": -126.479583671
            },
            {
                "lat": 49.4414135333,
                "lng": -126.4906843706
            },
            {
                "lat": 49.4298664106,
                "lng": -126.4960180346
            },
            {
                "lat": 49.4172132904,
                "lng": -126.4949036301
            },
            {
                "lat": 49.4081084432,
                "lng": -126.4811398738
            },
            {
                "lat": 49.4012258745,
                "lng": -126.4766996888
            },
            {
                "lat": 49.3854714917,
                "lng": -126.4812546533
            },
            {
                "lat": 49.3788834982,
                "lng": -126.4760275396
            },
            {
                "lat": 49.3779370099,
                "lng": -126.4749909197
            },
            {
                "lat": 49.3776668567,
                "lng": -126.47471551
            },
            {
                "lat": 49.4135507912,
                "lng": -126.4080577083
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 603,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 24,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 24-1",
            "LABEL": "24-1",
            "FISHERY_AREA_ID": 2254,
            "OBJECTID": 747,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 57393345.7103,
            "FEATURE_LENGTH_M": 30900.597,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.431992547449994,
            "lng": -126.44027511133335
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4291073113,
                "lng": -122.8479379642
            },
            {
                "lat": 49.4300805312,
                "lng": -122.8545680897
            },
            {
                "lat": 49.4300805808,
                "lng": -122.8758381675
            },
            {
                "lat": 49.429981672,
                "lng": -122.877678822
            },
            {
                "lat": 49.4298512866,
                "lng": -122.8800886714
            },
            {
                "lat": 49.4247092331,
                "lng": -122.8807753374
            },
            {
                "lat": 49.4194447601,
                "lng": -122.8824316842
            },
            {
                "lat": 49.3427806672,
                "lng": -122.9299306692
            },
            {
                "lat": 49.3415339703,
                "lng": -122.9358520379
            },
            {
                "lat": 49.3365363247,
                "lng": -122.950363281
            },
            {
                "lat": 49.325114677,
                "lng": -122.9551238079
            },
            {
                "lat": 49.3163114967,
                "lng": -122.9517977284
            },
            {
                "lat": 49.3085138476,
                "lng": -122.9511640534
            },
            {
                "lat": 49.3082239239,
                "lng": -122.9563144786
            },
            {
                "lat": 49.300503419,
                "lng": -122.9561322723
            },
            {
                "lat": 49.2981563808,
                "lng": -122.9269329875
            },
            {
                "lat": 49.2981611763,
                "lng": -122.9266662709
            },
            {
                "lat": 49.2982135888,
                "lng": -122.9261935897
            },
            {
                "lat": 49.2985988298,
                "lng": -122.925437586
            },
            {
                "lat": 49.2993054869,
                "lng": -122.9245313367
            },
            {
                "lat": 49.2998234925,
                "lng": -122.9244539946
            },
            {
                "lat": 49.3016128148,
                "lng": -122.9250416933
            },
            {
                "lat": 49.3046990534,
                "lng": -122.923348306
            },
            {
                "lat": 49.3063810994,
                "lng": -122.9176257767
            },
            {
                "lat": 49.30923449,
                "lng": -122.9028697926
            },
            {
                "lat": 49.3211320666,
                "lng": -122.8864519147
            },
            {
                "lat": 49.3332671108,
                "lng": -122.8778906847
            },
            {
                "lat": 49.3454013267,
                "lng": -122.8695606756
            },
            {
                "lat": 49.3584896817,
                "lng": -122.8595661615
            },
            {
                "lat": 49.3846623077,
                "lng": -122.8517158079
            },
            {
                "lat": 49.4051280358,
                "lng": -122.851715927
            },
            {
                "lat": 49.4291073113,
                "lng": -122.8479379642
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 604,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 28,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 28-12",
            "LABEL": "28-12",
            "FISHERY_AREA_ID": 2523,
            "OBJECTID": 748,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 54843429.8564,
            "FEATURE_LENGTH_M": 37639.7548,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.34887962358751,
            "lng": -122.90481054795937
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4235146925,
                "lng": -126.1237179274
            },
            {
                "lat": 49.4236451956,
                "lng": -126.1224510739
            },
            {
                "lat": 49.4165192329,
                "lng": -126.1109241328
            },
            {
                "lat": 49.4164506111,
                "lng": -126.1106640256
            },
            {
                "lat": 49.4163401076,
                "lng": -126.1103823077
            },
            {
                "lat": 49.4161490897,
                "lng": -126.1101985091
            },
            {
                "lat": 49.4155192185,
                "lng": -126.1093827675
            },
            {
                "lat": 49.4155659131,
                "lng": -126.1085281891
            },
            {
                "lat": 49.4151457656,
                "lng": -126.1051704231
            },
            {
                "lat": 49.4155043221,
                "lng": -126.1015777277
            },
            {
                "lat": 49.4159245455,
                "lng": -126.0971381658
            },
            {
                "lat": 49.4163435487,
                "lng": -126.0906069215
            },
            {
                "lat": 49.4163435416,
                "lng": -126.088569569
            },
            {
                "lat": 49.416462244,
                "lng": -126.0805898968
            },
            {
                "lat": 49.416522883,
                "lng": -126.0752568696
            },
            {
                "lat": 49.4167638679,
                "lng": -126.0731889045
            },
            {
                "lat": 49.4177707133,
                "lng": -126.0716092726
            },
            {
                "lat": 49.4184192293,
                "lng": -126.0705868242
            },
            {
                "lat": 49.4198068623,
                "lng": -126.068405088
            },
            {
                "lat": 49.4197614219,
                "lng": -126.0682216777
            },
            {
                "lat": 49.4197161811,
                "lng": -126.0677335319
            },
            {
                "lat": 49.419781356,
                "lng": -126.0673669272
            },
            {
                "lat": 49.4199407347,
                "lng": -126.0665511196
            },
            {
                "lat": 49.4200246767,
                "lng": -126.0658419386
            },
            {
                "lat": 49.4202340675,
                "lng": -126.0645820154
            },
            {
                "lat": 49.4203143554,
                "lng": -126.0624163335
            },
            {
                "lat": 49.4201550583,
                "lng": -126.0596847898
            },
            {
                "lat": 49.419754667,
                "lng": -126.0554276204
            },
            {
                "lat": 49.41919354,
                "lng": -126.046837157
            },
            {
                "lat": 49.4192736116,
                "lng": -126.0436256279
            },
            {
                "lat": 49.4220013039,
                "lng": -126.0369566941
            },
            {
                "lat": 49.4240107949,
                "lng": -126.0329440375
            },
            {
                "lat": 49.4285088449,
                "lng": -126.0309291503
            },
            {
                "lat": 49.4325252759,
                "lng": -126.0306089419
            },
            {
                "lat": 49.4354171799,
                "lng": -126.0315778668
            },
            {
                "lat": 49.439910717,
                "lng": -126.0331801278
            },
            {
                "lat": 49.4460177356,
                "lng": -126.0323792155
            },
            {
                "lat": 49.4502750927,
                "lng": -126.0288462831
            },
            {
                "lat": 49.4546093749,
                "lng": -126.0263516609
            },
            {
                "lat": 49.4571798085,
                "lng": -126.0263514037
            },
            {
                "lat": 49.4593508371,
                "lng": -126.0290828405
            },
            {
                "lat": 49.4599107095,
                "lng": -126.0318151615
            },
            {
                "lat": 49.4584659833,
                "lng": -126.0357513081
            },
            {
                "lat": 49.4557337053,
                "lng": -126.0387264275
            },
            {
                "lat": 49.4534077873,
                "lng": -126.0420151204
            },
            {
                "lat": 49.4501110803,
                "lng": -126.0468374801
            },
            {
                "lat": 49.4464985518,
                "lng": -126.0518956486
            },
            {
                "lat": 49.4428024606,
                "lng": -126.0565567731
            },
            {
                "lat": 49.440795424,
                "lng": -126.0636978797
            },
            {
                "lat": 49.4402355297,
                "lng": -126.0684430748
            },
            {
                "lat": 49.4405557087,
                "lng": -126.0724566729
            },
            {
                "lat": 49.4431273188,
                "lng": -126.0761493596
            },
            {
                "lat": 49.4436067618,
                "lng": -126.0796045994
            },
            {
                "lat": 49.4434474712,
                "lng": -126.084984481
            },
            {
                "lat": 49.4426416596,
                "lng": -126.0870740526
            },
            {
                "lat": 49.4395905425,
                "lng": -126.0886003296
            },
            {
                "lat": 49.4363786168,
                "lng": -126.0920480709
            },
            {
                "lat": 49.4347720846,
                "lng": -126.0958250048
            },
            {
                "lat": 49.4342120227,
                "lng": -126.1091609289
            },
            {
                "lat": 49.4330862981,
                "lng": -126.1207272985
            },
            {
                "lat": 49.4314798904,
                "lng": -126.1247397425
            },
            {
                "lat": 49.4298290916,
                "lng": -126.125954446
            },
            {
                "lat": 49.4267278215,
                "lng": -126.1268086492
            },
            {
                "lat": 49.4250909436,
                "lng": -126.1261972341
            },
            {
                "lat": 49.4241025983,
                "lng": -126.1244882729
            },
            {
                "lat": 49.4235146925,
                "lng": -126.1237179274
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 605,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 24,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 24-13",
            "LABEL": "24-13",
            "FISHERY_AREA_ID": 2266,
            "OBJECTID": 749,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 17751498.8348,
            "FEATURE_LENGTH_M": 20968.5264,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.43025443900455,
            "lng": -126.07470790153184
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4129907428,
                "lng": -123.3172464494
            },
            {
                "lat": 49.4123843163,
                "lng": -123.3198017376
            },
            {
                "lat": 49.4117580257,
                "lng": -123.3245612198
            },
            {
                "lat": 49.4106369943,
                "lng": -123.3294748791
            },
            {
                "lat": 49.3562662682,
                "lng": -123.4091030772
            },
            {
                "lat": 49.3499717897,
                "lng": -123.4150457814
            },
            {
                "lat": 49.3447191357,
                "lng": -123.4207000748
            },
            {
                "lat": 49.3410647254,
                "lng": -123.4218976541
            },
            {
                "lat": 49.3391800485,
                "lng": -123.4163050533
            },
            {
                "lat": 49.3389018232,
                "lng": -123.4155432534
            },
            {
                "lat": 49.3377528807,
                "lng": -123.3965685368
            },
            {
                "lat": 49.3384663209,
                "lng": -123.3892900434
            },
            {
                "lat": 49.3387026773,
                "lng": -123.3738246774
            },
            {
                "lat": 49.3379135097,
                "lng": -123.3690180395
            },
            {
                "lat": 49.3384545947,
                "lng": -123.3666226277
            },
            {
                "lat": 49.3383067771,
                "lng": -123.3644022239
            },
            {
                "lat": 49.3369984838,
                "lng": -123.3631438952
            },
            {
                "lat": 49.3364791594,
                "lng": -123.3622740609
            },
            {
                "lat": 49.3371461412,
                "lng": -123.3624716771
            },
            {
                "lat": 49.3385047746,
                "lng": -123.3622515639
            },
            {
                "lat": 49.3398131712,
                "lng": -123.361045403
            },
            {
                "lat": 49.3425059023,
                "lng": -123.3593595094
            },
            {
                "lat": 49.3438878418,
                "lng": -123.3580320066
            },
            {
                "lat": 49.3444325425,
                "lng": -123.3560246561
            },
            {
                "lat": 49.3763768885,
                "lng": -123.3390719156
            },
            {
                "lat": 49.3837774661,
                "lng": -123.3383553483
            },
            {
                "lat": 49.3897253331,
                "lng": -123.3345490678
            },
            {
                "lat": 49.3918416825,
                "lng": -123.3308716438
            },
            {
                "lat": 49.3992386915,
                "lng": -123.3269506908
            },
            {
                "lat": 49.3997194867,
                "lng": -123.3259895356
            },
            {
                "lat": 49.4037624472,
                "lng": -123.3209903418
            },
            {
                "lat": 49.4075694697,
                "lng": -123.3159953427
            },
            {
                "lat": 49.4129907428,
                "lng": -123.3172464494
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 606,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 750,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 24034989.5264,
            "FEATURE_LENGTH_M": 25193.9236,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.36461335924547,
            "lng": -123.36012207384242
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3033449902,
                "lng": -126.0152433691
            },
            {
                "lat": 49.3032263194,
                "lng": -126.0130083116
            },
            {
                "lat": 49.3026396694,
                "lng": -126.0104142383
            },
            {
                "lat": 49.3011053176,
                "lng": -126.0061724337
            },
            {
                "lat": 49.2981609863,
                "lng": -126.0003966458
            },
            {
                "lat": 49.2955661663,
                "lng": -125.9940266084
            },
            {
                "lat": 49.2930911794,
                "lng": -125.9864803143
            },
            {
                "lat": 49.2904963164,
                "lng": -125.9770507013
            },
            {
                "lat": 49.2902600395,
                "lng": -125.9710463557
            },
            {
                "lat": 49.2916754323,
                "lng": -125.9659730575
            },
            {
                "lat": 49.2920306035,
                "lng": -125.9609063591
            },
            {
                "lat": 49.2928549227,
                "lng": -125.9565430592
            },
            {
                "lat": 49.2948571124,
                "lng": -125.948410529
            },
            {
                "lat": 49.2962728461,
                "lng": -125.9449921007
            },
            {
                "lat": 49.3001632983,
                "lng": -125.9431080521
            },
            {
                "lat": 49.3049968494,
                "lng": -125.9426350068
            },
            {
                "lat": 49.3113591768,
                "lng": -125.9433442276
            },
            {
                "lat": 49.3172532435,
                "lng": -125.9435800846
            },
            {
                "lat": 49.3229143227,
                "lng": -125.944756043
            },
            {
                "lat": 49.3290403375,
                "lng": -125.9451061318
            },
            {
                "lat": 49.3329319308,
                "lng": -125.9441684471
            },
            {
                "lat": 49.3357620422,
                "lng": -125.9408646988
            },
            {
                "lat": 49.3389437692,
                "lng": -125.9374466596
            },
            {
                "lat": 49.343894909,
                "lng": -125.9333260752
            },
            {
                "lat": 49.3484919687,
                "lng": -125.9311982775
            },
            {
                "lat": 49.3526153577,
                "lng": -125.9294360985
            },
            {
                "lat": 49.3575665261,
                "lng": -125.9271926188
            },
            {
                "lat": 49.3622814046,
                "lng": -125.9249576532
            },
            {
                "lat": 49.3687670458,
                "lng": -125.9224776523
            },
            {
                "lat": 49.3739509525,
                "lng": -125.9220054542
            },
            {
                "lat": 49.3774875626,
                "lng": -125.9200059874
            },
            {
                "lat": 49.3823201039,
                "lng": -125.9190601248
            },
            {
                "lat": 49.385268766,
                "lng": -125.9183496399
            },
            {
                "lat": 49.388332957,
                "lng": -125.9143451069
            },
            {
                "lat": 49.3913993156,
                "lng": -125.9080955853
            },
            {
                "lat": 49.3946996941,
                "lng": -125.903266861
            },
            {
                "lat": 49.397762373,
                "lng": -125.8994902323
            },
            {
                "lat": 49.4016531391,
                "lng": -125.8964305828
            },
            {
                "lat": 49.4047160373,
                "lng": -125.893714813
            },
            {
                "lat": 49.4080164422,
                "lng": -125.8918303117
            },
            {
                "lat": 49.4127311361,
                "lng": -125.8915937915
            },
            {
                "lat": 49.4163854801,
                "lng": -125.8919527538
            },
            {
                "lat": 49.4188615108,
                "lng": -125.8930126175
            },
            {
                "lat": 49.4220468439,
                "lng": -125.8951341159
            },
            {
                "lat": 49.4268755934,
                "lng": -125.8990249051
            },
            {
                "lat": 49.4307674265,
                "lng": -125.902442332
            },
            {
                "lat": 49.4332422709,
                "lng": -125.9058606429
            },
            {
                "lat": 49.4330060596,
                "lng": -125.9108130011
            },
            {
                "lat": 49.4319455934,
                "lng": -125.9137571632
            },
            {
                "lat": 49.4274671406,
                "lng": -125.9164662974
            },
            {
                "lat": 49.4207499616,
                "lng": -125.9182353503
            },
            {
                "lat": 49.417213141,
                "lng": -125.9203564747
            },
            {
                "lat": 49.4132039519,
                "lng": -125.9214168456
            },
            {
                "lat": 49.4097857522,
                "lng": -125.9216532965
            },
            {
                "lat": 49.4082528071,
                "lng": -125.9229502112
            },
            {
                "lat": 49.4068369949,
                "lng": -125.926955884
            },
            {
                "lat": 49.4062504013,
                "lng": -125.9334418908
            },
            {
                "lat": 49.405189934,
                "lng": -125.9405143599
            },
            {
                "lat": 49.4014169242,
                "lng": -125.9464030141
            },
            {
                "lat": 49.3962292869,
                "lng": -125.9506523195
            },
            {
                "lat": 49.3889230554,
                "lng": -125.9545362809
            },
            {
                "lat": 49.3838543327,
                "lng": -125.9576033228
            },
            {
                "lat": 49.3780791217,
                "lng": -125.9606702933
            },
            {
                "lat": 49.3719484909,
                "lng": -125.962555262
            },
            {
                "lat": 49.3675875968,
                "lng": -125.9625544962
            },
            {
                "lat": 49.3638159146,
                "lng": -125.9629066078
            },
            {
                "lat": 49.3575667359,
                "lng": -125.9715114701
            },
            {
                "lat": 49.3550917588,
                "lng": -125.9758763013
            },
            {
                "lat": 49.3546212712,
                "lng": -125.9798813561
            },
            {
                "lat": 49.360867007,
                "lng": -125.9838934562
            },
            {
                "lat": 49.3615758766,
                "lng": -125.9877774076
            },
            {
                "lat": 49.3594548228,
                "lng": -125.9916688648
            },
            {
                "lat": 49.3515549891,
                "lng": -125.9941491709
            },
            {
                "lat": 49.3422430026,
                "lng": -125.9960323397
            },
            {
                "lat": 49.3369413066,
                "lng": -125.9981534452
            },
            {
                "lat": 49.3332857542,
                "lng": -126.002159141
            },
            {
                "lat": 49.3278654937,
                "lng": -126.0086433065
            },
            {
                "lat": 49.3217163921,
                "lng": -126.0172353652
            },
            {
                "lat": 49.319778209,
                "lng": -126.0171813902
            },
            {
                "lat": 49.3041762959,
                "lng": -126.0171816269
            },
            {
                "lat": 49.3040773354,
                "lng": -126.0169755142
            },
            {
                "lat": 49.303802665,
                "lng": -126.0163958213
            },
            {
                "lat": 49.3033449902,
                "lng": -126.0152433691
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 607,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 24,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 24-5",
            "LABEL": "24-5",
            "FISHERY_AREA_ID": 2258,
            "OBJECTID": 751,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 56120492.2131,
            "FEATURE_LENGTH_M": 41839.6418,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.358660265731345,
            "lng": -125.95017204079757
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4155192185,
                "lng": -126.1093827675
            },
            {
                "lat": 49.4161490897,
                "lng": -126.1101985091
            },
            {
                "lat": 49.4163401076,
                "lng": -126.1103823077
            },
            {
                "lat": 49.4164506111,
                "lng": -126.1106640256
            },
            {
                "lat": 49.4165192329,
                "lng": -126.1109241328
            },
            {
                "lat": 49.4236451956,
                "lng": -126.1224510739
            },
            {
                "lat": 49.4235146925,
                "lng": -126.1237179274
            },
            {
                "lat": 49.4135973615,
                "lng": -126.2203989115
            },
            {
                "lat": 49.4129942119,
                "lng": -126.2262649812
            },
            {
                "lat": 49.4131735738,
                "lng": -126.2302852326
            },
            {
                "lat": 49.4125251164,
                "lng": -126.2308354205
            },
            {
                "lat": 49.3941116515,
                "lng": -126.2308347629
            },
            {
                "lat": 49.3937916377,
                "lng": -126.2297818565
            },
            {
                "lat": 49.3934061364,
                "lng": -126.2291106196
            },
            {
                "lat": 49.3925820146,
                "lng": -126.2285533618
            },
            {
                "lat": 49.3847391794,
                "lng": -126.2247850207
            },
            {
                "lat": 49.3751336128,
                "lng": -126.2189103572
            },
            {
                "lat": 49.3721428701,
                "lng": -126.2069855087
            },
            {
                "lat": 49.3672522758,
                "lng": -126.1921308127
            },
            {
                "lat": 49.3670729955,
                "lng": -126.1761932762
            },
            {
                "lat": 49.3739544379,
                "lng": -126.1511992734
            },
            {
                "lat": 49.379386419,
                "lng": -126.1271141452
            },
            {
                "lat": 49.3883782016,
                "lng": -126.1166837837
            },
            {
                "lat": 49.3979116594,
                "lng": -126.1287077264
            },
            {
                "lat": 49.3987345129,
                "lng": -126.1283951059
            },
            {
                "lat": 49.4053912552,
                "lng": -126.1258853376
            },
            {
                "lat": 49.4057765683,
                "lng": -126.1240999073
            },
            {
                "lat": 49.4063983128,
                "lng": -126.1235809352
            },
            {
                "lat": 49.4076915287,
                "lng": -126.120299584
            },
            {
                "lat": 49.4087870966,
                "lng": -126.116119075
            },
            {
                "lat": 49.4113655929,
                "lng": -126.113448949
            },
            {
                "lat": 49.4134449314,
                "lng": -126.1107558199
            },
            {
                "lat": 49.4155192185,
                "lng": -126.1093827675
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 608,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 24,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 24-3",
            "LABEL": "24-3",
            "FISHERY_AREA_ID": 2256,
            "OBJECTID": 752,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 39644289.9417,
            "FEATURE_LENGTH_M": 25999.9664,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.40101213698485,
            "lng": -126.15874131140002
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.4203143554,
                "lng": -126.0624163335
            },
            {
                "lat": 49.4202340675,
                "lng": -126.0645820154
            },
            {
                "lat": 49.4200246767,
                "lng": -126.0658419386
            },
            {
                "lat": 49.4199407347,
                "lng": -126.0665511196
            },
            {
                "lat": 49.419781356,
                "lng": -126.0673669272
            },
            {
                "lat": 49.4197161811,
                "lng": -126.0677335319
            },
            {
                "lat": 49.4197614219,
                "lng": -126.0682216777
            },
            {
                "lat": 49.4198068623,
                "lng": -126.068405088
            },
            {
                "lat": 49.4184192293,
                "lng": -126.0705868242
            },
            {
                "lat": 49.4177707133,
                "lng": -126.0716092726
            },
            {
                "lat": 49.4167638679,
                "lng": -126.0731889045
            },
            {
                "lat": 49.416522883,
                "lng": -126.0752568696
            },
            {
                "lat": 49.4161608709,
                "lng": -126.0762487106
            },
            {
                "lat": 49.4149780745,
                "lng": -126.0814506092
            },
            {
                "lat": 49.4134668806,
                "lng": -126.0819703747
            },
            {
                "lat": 49.4089091403,
                "lng": -126.0804743344
            },
            {
                "lat": 49.4069894937,
                "lng": -126.0789185436
            },
            {
                "lat": 49.4057312329,
                "lng": -126.0799946435
            },
            {
                "lat": 49.4039955042,
                "lng": -126.0861657729
            },
            {
                "lat": 49.4033969742,
                "lng": -126.0915066049
            },
            {
                "lat": 49.4014168681,
                "lng": -126.0947423033
            },
            {
                "lat": 49.3994364597,
                "lng": -126.0960004919
            },
            {
                "lat": 49.3982387769,
                "lng": -126.0976792251
            },
            {
                "lat": 49.3981781031,
                "lng": -126.1009752715
            },
            {
                "lat": 49.3994366553,
                "lng": -126.1077503953
            },
            {
                "lat": 49.4028543911,
                "lng": -126.1128455378
            },
            {
                "lat": 49.4052538859,
                "lng": -126.116203144
            },
            {
                "lat": 49.4062119478,
                "lng": -126.1202776582
            },
            {
                "lat": 49.4057765683,
                "lng": -126.1240999073
            },
            {
                "lat": 49.4053912552,
                "lng": -126.1258853376
            },
            {
                "lat": 49.3987345129,
                "lng": -126.1283951059
            },
            {
                "lat": 49.3979116594,
                "lng": -126.1287077264
            },
            {
                "lat": 49.3883782016,
                "lng": -126.1166837837
            },
            {
                "lat": 49.3616713433,
                "lng": -126.0843658654
            },
            {
                "lat": 49.3592838467,
                "lng": -126.0799943711
            },
            {
                "lat": 49.3592836382,
                "lng": -126.0603637061
            },
            {
                "lat": 49.3592836472,
                "lng": -126.0598453295
            },
            {
                "lat": 49.3592755391,
                "lng": -126.0592573022
            },
            {
                "lat": 49.3592488716,
                "lng": -126.0586546302
            },
            {
                "lat": 49.3596574799,
                "lng": -126.0586241992
            },
            {
                "lat": 49.3603978956,
                "lng": -126.0586242836
            },
            {
                "lat": 49.361812435,
                "lng": -126.0585022744
            },
            {
                "lat": 49.3649938625,
                "lng": -126.0587384343
            },
            {
                "lat": 49.3668788165,
                "lng": -126.0581518452
            },
            {
                "lat": 49.367351415,
                "lng": -126.0556787328
            },
            {
                "lat": 49.3682942942,
                "lng": -126.053436534
            },
            {
                "lat": 49.3706517369,
                "lng": -126.0526129121
            },
            {
                "lat": 49.3737146946,
                "lng": -126.0535511428
            },
            {
                "lat": 49.3760720289,
                "lng": -126.0562671312
            },
            {
                "lat": 49.3790208679,
                "lng": -126.0590968085
            },
            {
                "lat": 49.3836168205,
                "lng": -126.0625147889
            },
            {
                "lat": 49.4016530043,
                "lng": -126.0655740116
            },
            {
                "lat": 49.4031828117,
                "lng": -126.0643998836
            },
            {
                "lat": 49.4043655724,
                "lng": -126.060859664
            },
            {
                "lat": 49.4063678369,
                "lng": -126.0568547288
            },
            {
                "lat": 49.4096683734,
                "lng": -126.0563811397
            },
            {
                "lat": 49.4132039407,
                "lng": -126.0575637216
            },
            {
                "lat": 49.4148522376,
                "lng": -126.0586244421
            },
            {
                "lat": 49.4160303591,
                "lng": -126.0607450493
            },
            {
                "lat": 49.4182738145,
                "lng": -126.0619203652
            },
            {
                "lat": 49.4203143554,
                "lng": -126.0624163335
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 609,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 24,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 24-14",
            "LABEL": "24-14",
            "FISHERY_AREA_ID": 2267,
            "OBJECTID": 753,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 19210162.7248,
            "FEATURE_LENGTH_M": 22292.2829,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.3968577925459,
            "lng": -126.07610419041802
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.416522883,
                "lng": -126.0752568696
            },
            {
                "lat": 49.416462244,
                "lng": -126.0805898968
            },
            {
                "lat": 49.4163435416,
                "lng": -126.088569569
            },
            {
                "lat": 49.4163435487,
                "lng": -126.0906069215
            },
            {
                "lat": 49.4159245455,
                "lng": -126.0971381658
            },
            {
                "lat": 49.4155043221,
                "lng": -126.1015777277
            },
            {
                "lat": 49.4151457656,
                "lng": -126.1051704231
            },
            {
                "lat": 49.4155659131,
                "lng": -126.1085281891
            },
            {
                "lat": 49.4155192185,
                "lng": -126.1093827675
            },
            {
                "lat": 49.4134449314,
                "lng": -126.1107558199
            },
            {
                "lat": 49.4113655929,
                "lng": -126.113448949
            },
            {
                "lat": 49.4087870966,
                "lng": -126.116119075
            },
            {
                "lat": 49.4076915287,
                "lng": -126.120299584
            },
            {
                "lat": 49.4063983128,
                "lng": -126.1235809352
            },
            {
                "lat": 49.4057765683,
                "lng": -126.1240999073
            },
            {
                "lat": 49.4062119478,
                "lng": -126.1202776582
            },
            {
                "lat": 49.4052538859,
                "lng": -126.116203144
            },
            {
                "lat": 49.4028543911,
                "lng": -126.1128455378
            },
            {
                "lat": 49.3994366553,
                "lng": -126.1077503953
            },
            {
                "lat": 49.3981781031,
                "lng": -126.1009752715
            },
            {
                "lat": 49.3982387769,
                "lng": -126.0976792251
            },
            {
                "lat": 49.3994364597,
                "lng": -126.0960004919
            },
            {
                "lat": 49.4014168681,
                "lng": -126.0947423033
            },
            {
                "lat": 49.4033969742,
                "lng": -126.0915066049
            },
            {
                "lat": 49.4039955042,
                "lng": -126.0861657729
            },
            {
                "lat": 49.4057312329,
                "lng": -126.0799946435
            },
            {
                "lat": 49.4069894937,
                "lng": -126.0789185436
            },
            {
                "lat": 49.4089091403,
                "lng": -126.0804743344
            },
            {
                "lat": 49.4134668806,
                "lng": -126.0819703747
            },
            {
                "lat": 49.4149780745,
                "lng": -126.0814506092
            },
            {
                "lat": 49.4161608709,
                "lng": -126.0762487106
            },
            {
                "lat": 49.416522883,
                "lng": -126.0752568696
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 610,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 754,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4018785.0623,
            "FEATURE_LENGTH_M": 9511.2596,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.409311692343735,
            "lng": -126.09823704034376
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3869777495,
                "lng": -126.5337224906
            },
            {
                "lat": 49.3829650829,
                "lng": -126.5440365817
            },
            {
                "lat": 49.0000005095,
                "lng": -126.7589646478
            },
            {
                "lat": 49.0000004616,
                "lng": -125.9000011274
            },
            {
                "lat": 49.000000397,
                "lng": -125.6823500859
            },
            {
                "lat": 49.0000003533,
                "lng": -125.6724161326
            },
            {
                "lat": 49.0001947335,
                "lng": -125.6706540316
            },
            {
                "lat": 49.0008922099,
                "lng": -125.6690905224
            },
            {
                "lat": 49.0023079201,
                "lng": -125.6681437759
            },
            {
                "lat": 49.0069235946,
                "lng": -125.6644055563
            },
            {
                "lat": 49.0113183192,
                "lng": -125.6641845386
            },
            {
                "lat": 49.0179062027,
                "lng": -125.6659471872
            },
            {
                "lat": 49.026916792,
                "lng": -125.6694642246
            },
            {
                "lat": 49.035701415,
                "lng": -125.6758347784
            },
            {
                "lat": 49.0462452864,
                "lng": -125.6925272138
            },
            {
                "lat": 49.0572314298,
                "lng": -125.7136162632
            },
            {
                "lat": 49.0673374954,
                "lng": -125.7340470464
            },
            {
                "lat": 49.070854375,
                "lng": -125.7448115625
            },
            {
                "lat": 49.0743676533,
                "lng": -125.7544789405
            },
            {
                "lat": 49.0752488857,
                "lng": -125.7645874459
            },
            {
                "lat": 49.0767821738,
                "lng": -125.7733764366
            },
            {
                "lat": 49.0765645522,
                "lng": -125.7832636307
            },
            {
                "lat": 49.0726088292,
                "lng": -125.793585965
            },
            {
                "lat": 49.0719485812,
                "lng": -125.8006127338
            },
            {
                "lat": 49.0739288128,
                "lng": -125.8155513755
            },
            {
                "lat": 49.0802992044,
                "lng": -125.8399426386
            },
            {
                "lat": 49.0866706166,
                "lng": -125.8570794915
            },
            {
                "lat": 49.0893060951,
                "lng": -125.866523463
            },
            {
                "lat": 49.09282318,
                "lng": -125.8742139131
            },
            {
                "lat": 49.0941384805,
                "lng": -125.8794852172
            },
            {
                "lat": 49.0950198703,
                "lng": -125.8841014199
            },
            {
                "lat": 49.0952374556,
                "lng": -125.8873987059
            },
            {
                "lat": 49.0950045824,
                "lng": -125.8882677434
            },
            {
                "lat": 49.094771719,
                "lng": -125.8891444584
            },
            {
                "lat": 49.0946728539,
                "lng": -125.8895797895
            },
            {
                "lat": 49.0976519673,
                "lng": -125.8960037323
            },
            {
                "lat": 49.1104170076,
                "lng": -125.923530206
            },
            {
                "lat": 49.1593974823,
                "lng": -126.018234083
            },
            {
                "lat": 49.1607327627,
                "lng": -126.0165405666
            },
            {
                "lat": 49.1622310766,
                "lng": -126.0173491935
            },
            {
                "lat": 49.1646885292,
                "lng": -126.0184330545
            },
            {
                "lat": 49.165321715,
                "lng": -126.0199965237
            },
            {
                "lat": 49.1657222609,
                "lng": -126.0215445179
            },
            {
                "lat": 49.1662135049,
                "lng": -126.0223392112
            },
            {
                "lat": 49.1663974026,
                "lng": -126.0226740311
            },
            {
                "lat": 49.1663895007,
                "lng": -126.0227964576
            },
            {
                "lat": 49.1787830419,
                "lng": -126.063911838
            },
            {
                "lat": 49.2836953789,
                "lng": -126.2305522553
            },
            {
                "lat": 49.3331380984,
                "lng": -126.2600855752
            },
            {
                "lat": 49.4135507912,
                "lng": -126.4080577083
            },
            {
                "lat": 49.3776668567,
                "lng": -126.47471551
            },
            {
                "lat": 49.3779370099,
                "lng": -126.4749909197
            },
            {
                "lat": 49.3788834982,
                "lng": -126.4760275396
            },
            {
                "lat": 49.3854714917,
                "lng": -126.4812546533
            },
            {
                "lat": 49.3877449845,
                "lng": -126.4882968419
            },
            {
                "lat": 49.38865313,
                "lng": -126.4970395801
            },
            {
                "lat": 49.3874015979,
                "lng": -126.509422743
            },
            {
                "lat": 49.3859252704,
                "lng": -126.5193098682
            },
            {
                "lat": 49.3869777495,
                "lng": -126.5337224906
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 611,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 124,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 124-3",
            "LABEL": "124-3",
            "FISHERY_AREA_ID": 2327,
            "OBJECTID": 755,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1919484700.0379,
            "FEATURE_LENGTH_M": 212088.7127,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.1509179658034,
            "lng": -125.99969898705083
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3410647254,
                "lng": -123.4218976541
            },
            {
                "lat": 49.3399540271,
                "lng": -123.4329900581
            },
            {
                "lat": 49.3484419714,
                "lng": -123.4650579199
            },
            {
                "lat": 49.3583303895,
                "lng": -123.4859544444
            },
            {
                "lat": 49.3836901172,
                "lng": -123.5360955592
            },
            {
                "lat": 49.1499444683,
                "lng": -123.6416095021
            },
            {
                "lat": 49.1059721246,
                "lng": -123.303138489
            },
            {
                "lat": 49.2573319446,
                "lng": -123.279640898
            },
            {
                "lat": 49.2656054583,
                "lng": -123.2643350021
            },
            {
                "lat": 49.3303870649,
                "lng": -123.2646637274
            },
            {
                "lat": 49.3355791968,
                "lng": -123.3607174291
            },
            {
                "lat": 49.3364791594,
                "lng": -123.3622740609
            },
            {
                "lat": 49.3369984838,
                "lng": -123.3631438952
            },
            {
                "lat": 49.3383067771,
                "lng": -123.3644022239
            },
            {
                "lat": 49.3384545947,
                "lng": -123.3666226277
            },
            {
                "lat": 49.3379135097,
                "lng": -123.3690180395
            },
            {
                "lat": 49.3387026773,
                "lng": -123.3738246774
            },
            {
                "lat": 49.3384663209,
                "lng": -123.3892900434
            },
            {
                "lat": 49.3377528807,
                "lng": -123.3965685368
            },
            {
                "lat": 49.3389018232,
                "lng": -123.4155432534
            },
            {
                "lat": 49.3391800485,
                "lng": -123.4163050533
            },
            {
                "lat": 49.3410647254,
                "lng": -123.4218976541
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 612,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 29-3",
            "LABEL": "29-3",
            "FISHERY_AREA_ID": 2528,
            "OBJECTID": 756,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 568195826.4779,
            "FEATURE_LENGTH_M": 99348.4701,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.31538738585455,
            "lng": -123.39522685222724
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.2119484425,
                "lng": -122.8114773313
            },
            {
                "lat": 49.1897968975,
                "lng": -122.7647709715
            },
            {
                "lat": 49.1665988893,
                "lng": -122.6999283145
            },
            {
                "lat": 49.1725466047,
                "lng": -122.640441867
            },
            {
                "lat": 49.1862255373,
                "lng": -122.6089105053
            },
            {
                "lat": 49.188140472,
                "lng": -122.590332815
            },
            {
                "lat": 49.1881451005,
                "lng": -122.5755233149
            },
            {
                "lat": 49.1909668774,
                "lng": -122.5638890828
            },
            {
                "lat": 49.2058599206,
                "lng": -122.5678639034
            },
            {
                "lat": 49.2260861149,
                "lng": -122.5874941895
            },
            {
                "lat": 49.2361989766,
                "lng": -122.6208112167
            },
            {
                "lat": 49.2540430861,
                "lng": -122.6666105803
            },
            {
                "lat": 49.2968712324,
                "lng": -122.6410377612
            },
            {
                "lat": 49.3391076825,
                "lng": -122.6344913276
            },
            {
                "lat": 49.3474348834,
                "lng": -122.6618581137
            },
            {
                "lat": 49.3379169926,
                "lng": -122.6874306496
            },
            {
                "lat": 49.2938990677,
                "lng": -122.7231304397
            },
            {
                "lat": 49.2552339888,
                "lng": -122.7558442033
            },
            {
                "lat": 49.2474972152,
                "lng": -122.7849885354
            },
            {
                "lat": 49.251067594,
                "lng": -122.8093797545
            },
            {
                "lat": 49.2319102264,
                "lng": -122.8155676447
            },
            {
                "lat": 49.2240639748,
                "lng": -122.814964635
            },
            {
                "lat": 49.2166027423,
                "lng": -122.8112177887
            },
            {
                "lat": 49.2119484425,
                "lng": -122.8114773313
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 613,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 15,
            "MANAGEMENT_AREA_NAME": "Subarea 29-15",
            "LABEL": "29-15",
            "FISHERY_AREA_ID": 2540,
            "OBJECTID": 757,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 178908255.1569,
            "FEATURE_LENGTH_M": 64167.7686,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.23625462341665,
            "lng": -122.69372676153746
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3883782016,
                "lng": -126.1166837837
            },
            {
                "lat": 49.379386419,
                "lng": -126.1271141452
            },
            {
                "lat": 49.3739544379,
                "lng": -126.1511992734
            },
            {
                "lat": 49.3670729955,
                "lng": -126.1761932762
            },
            {
                "lat": 49.3672522758,
                "lng": -126.1921308127
            },
            {
                "lat": 49.3721428701,
                "lng": -126.2069855087
            },
            {
                "lat": 49.3751336128,
                "lng": -126.2189103572
            },
            {
                "lat": 49.3575213372,
                "lng": -126.2151710338
            },
            {
                "lat": 49.3372416221,
                "lng": -126.2178419875
            },
            {
                "lat": 49.3217619382,
                "lng": -126.2119745476
            },
            {
                "lat": 49.2966803499,
                "lng": -126.2077021222
            },
            {
                "lat": 49.2888944938,
                "lng": -126.1703040356
            },
            {
                "lat": 49.2851748937,
                "lng": -126.1354913714
            },
            {
                "lat": 49.2809222736,
                "lng": -126.1094439015
            },
            {
                "lat": 49.2758756877,
                "lng": -126.0932391008
            },
            {
                "lat": 49.2650036539,
                "lng": -126.0786976879
            },
            {
                "lat": 49.2758105025,
                "lng": -126.0838999583
            },
            {
                "lat": 49.300217078,
                "lng": -126.0888981144
            },
            {
                "lat": 49.3270297513,
                "lng": -126.09130084
            },
            {
                "lat": 49.3484385187,
                "lng": -126.0929029703
            },
            {
                "lat": 49.3564421038,
                "lng": -126.0895003157
            },
            {
                "lat": 49.3606410502,
                "lng": -126.0875010251
            },
            {
                "lat": 49.3616713433,
                "lng": -126.0843658654
            },
            {
                "lat": 49.3883782016,
                "lng": -126.1166837837
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 614,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 758,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 88059894.7492,
            "FEATURE_LENGTH_M": 42294.3003,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.33545940050834,
            "lng": -126.14017232576249
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3829650829,
                "lng": -126.5440365817
            },
            {
                "lat": 49.3869777495,
                "lng": -126.5337224906
            },
            {
                "lat": 49.3837507567,
                "lng": -126.5425267854
            },
            {
                "lat": 49.3829650829,
                "lng": -126.5440365817
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 615,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 759,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 8252.8644,
            "FEATURE_LENGTH_M": 1745.682,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.384164668000004,
            "lng": -126.54108060985001
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3829650829,
                "lng": -126.5440365817
            },
            {
                "lat": 49.0000003786,
                "lng": -127.0291369356
            },
            {
                "lat": 49.0000005095,
                "lng": -126.7589646478
            },
            {
                "lat": 49.3829650829,
                "lng": -126.5440365817
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 616,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 124,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 124-4",
            "LABEL": "124-4",
            "FISHERY_AREA_ID": 2328,
            "OBJECTID": 760,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 421627322.5008,
            "FEATURE_LENGTH_M": 120484.8977,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.191482763474994,
            "lng": -126.71904368669999
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3334499042,
                "lng": -123.2646101053
            },
            {
                "lat": 49.3303870649,
                "lng": -123.2646637274
            },
            {
                "lat": 49.3273965817,
                "lng": -123.1692210205
            },
            {
                "lat": 49.3334617387,
                "lng": -123.1676860479
            },
            {
                "lat": 49.3357316687,
                "lng": -123.1774913749
            },
            {
                "lat": 49.3393326159,
                "lng": -123.1891104473
            },
            {
                "lat": 49.3438527739,
                "lng": -123.2026744582
            },
            {
                "lat": 49.3459926115,
                "lng": -123.223608873
            },
            {
                "lat": 49.3445664743,
                "lng": -123.2381296031
            },
            {
                "lat": 49.3423419255,
                "lng": -123.2461778271
            },
            {
                "lat": 49.3419995991,
                "lng": -123.2493905147
            },
            {
                "lat": 49.341049672,
                "lng": -123.2507248732
            },
            {
                "lat": 49.3394198898,
                "lng": -123.250701232
            },
            {
                "lat": 49.3382755959,
                "lng": -123.2524718272
            },
            {
                "lat": 49.3398553303,
                "lng": -123.2546243212
            },
            {
                "lat": 49.3392219149,
                "lng": -123.256522797
            },
            {
                "lat": 49.3371508112,
                "lng": -123.2571184323
            },
            {
                "lat": 49.3355256107,
                "lng": -123.2567211093
            },
            {
                "lat": 49.3343278934,
                "lng": -123.2585753893
            },
            {
                "lat": 49.3346212411,
                "lng": -123.261687917
            },
            {
                "lat": 49.3334499042,
                "lng": -123.2646101053
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 617,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 28,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 28-6",
            "LABEL": "28-6",
            "FISHERY_AREA_ID": 2517,
            "OBJECTID": 761,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 9551556.3839,
            "FEATURE_LENGTH_M": 16234.6781,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.33768622961428,
            "lng": -123.23602485729526
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.2560384012,
                "lng": -125.9621881504
            },
            {
                "lat": 49.2485889702,
                "lng": -125.9506154261
            },
            {
                "lat": 49.2459219411,
                "lng": -125.9444728803
            },
            {
                "lat": 49.2458613644,
                "lng": -125.9421154889
            },
            {
                "lat": 49.246169937,
                "lng": -125.9406280612
            },
            {
                "lat": 49.2463423464,
                "lng": -125.9386210969
            },
            {
                "lat": 49.2454143047,
                "lng": -125.9363703561
            },
            {
                "lat": 49.2355879439,
                "lng": -125.9348379474
            },
            {
                "lat": 49.2243501724,
                "lng": -125.9330905037
            },
            {
                "lat": 49.2239300851,
                "lng": -125.9328305293
            },
            {
                "lat": 49.2238241779,
                "lng": -125.9325872469
            },
            {
                "lat": 49.2236213562,
                "lng": -125.9320912367
            },
            {
                "lat": 49.2235714576,
                "lng": -125.9301601292
            },
            {
                "lat": 49.2224689643,
                "lng": -125.9272766457
            },
            {
                "lat": 49.2208856135,
                "lng": -125.9226687058
            },
            {
                "lat": 49.2204747027,
                "lng": -125.9170227781
            },
            {
                "lat": 49.2206122558,
                "lng": -125.9106979378
            },
            {
                "lat": 49.2206120403,
                "lng": -125.9020308588
            },
            {
                "lat": 49.2222629903,
                "lng": -125.8965228315
            },
            {
                "lat": 49.2268032549,
                "lng": -125.8931506906
            },
            {
                "lat": 49.235065209,
                "lng": -125.8885884455
            },
            {
                "lat": 49.2377472092,
                "lng": -125.8780982836
            },
            {
                "lat": 49.2382351786,
                "lng": -125.8673550672
            },
            {
                "lat": 49.2379918054,
                "lng": -125.8517376679
            },
            {
                "lat": 49.2365259847,
                "lng": -125.8434443986
            },
            {
                "lat": 49.2355004882,
                "lng": -125.8296885471
            },
            {
                "lat": 49.235438701,
                "lng": -125.821906308
            },
            {
                "lat": 49.2354236411,
                "lng": -125.8192741979
            },
            {
                "lat": 49.2367857759,
                "lng": -125.8177641381
            },
            {
                "lat": 49.2453503058,
                "lng": -125.8111881537
            },
            {
                "lat": 49.2506099203,
                "lng": -125.8044735512
            },
            {
                "lat": 49.2562712596,
                "lng": -125.8044737243
            },
            {
                "lat": 49.2651748391,
                "lng": -125.8032599827
            },
            {
                "lat": 49.2890468779,
                "lng": -125.7951655476
            },
            {
                "lat": 49.3084673971,
                "lng": -125.7862622629
            },
            {
                "lat": 49.3193895235,
                "lng": -125.7822195606
            },
            {
                "lat": 49.3307190583,
                "lng": -125.7862627374
            },
            {
                "lat": 49.3412393268,
                "lng": -125.7810065887
            },
            {
                "lat": 49.353783046,
                "lng": -125.7632065006
            },
            {
                "lat": 49.3642999478,
                "lng": -125.7567293617
            },
            {
                "lat": 49.3719905888,
                "lng": -125.7627943465
            },
            {
                "lat": 49.3719905126,
                "lng": -125.7797931285
            },
            {
                "lat": 49.3671336568,
                "lng": -125.795165791
            },
            {
                "lat": 49.3497354505,
                "lng": -125.8113480309
            },
            {
                "lat": 49.3323359324,
                "lng": -125.819847386
            },
            {
                "lat": 49.3193894095,
                "lng": -125.8234863373
            },
            {
                "lat": 49.3076594883,
                "lng": -125.836028787
            },
            {
                "lat": 49.2951167637,
                "lng": -125.8615195271
            },
            {
                "lat": 49.2951168373,
                "lng": -125.8910591515
            },
            {
                "lat": 49.2882390804,
                "lng": -125.9205933759
            },
            {
                "lat": 49.2732656429,
                "lng": -125.9359666257
            },
            {
                "lat": 49.2560384012,
                "lng": -125.9621881504
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 618,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 24,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 24-7",
            "LABEL": "24-7",
            "FISHERY_AREA_ID": 2260,
            "OBJECTID": 762,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 94989877.2146,
            "FEATURE_LENGTH_M": 51991.1721,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.26777729883846,
            "lng": -125.86676683008658
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3334617387,
                "lng": -123.1676860479
            },
            {
                "lat": 49.3273965817,
                "lng": -123.1692210205
            },
            {
                "lat": 49.3011469589,
                "lng": -123.158766707
            },
            {
                "lat": 49.3010748208,
                "lng": -123.1577365476
            },
            {
                "lat": 49.3005337305,
                "lng": -123.1562883331
            },
            {
                "lat": 49.3044662155,
                "lng": -123.1506262556
            },
            {
                "lat": 49.308056245,
                "lng": -123.1465226614
            },
            {
                "lat": 49.3132625042,
                "lng": -123.1406633125
            },
            {
                "lat": 49.3170436352,
                "lng": -123.1371686772
            },
            {
                "lat": 49.3174627179,
                "lng": -123.1368178536
            },
            {
                "lat": 49.3184010018,
                "lng": -123.1363369377
            },
            {
                "lat": 49.319481338,
                "lng": -123.1355446276
            },
            {
                "lat": 49.3202975389,
                "lng": -123.1346207549
            },
            {
                "lat": 49.3214871285,
                "lng": -123.1322398056
            },
            {
                "lat": 49.3231508858,
                "lng": -123.1262890645
            },
            {
                "lat": 49.327434755,
                "lng": -123.1229624435
            },
            {
                "lat": 49.3317189846,
                "lng": -123.1293861207
            },
            {
                "lat": 49.3326688462,
                "lng": -123.1438971114
            },
            {
                "lat": 49.3317190693,
                "lng": -123.1560366975
            },
            {
                "lat": 49.3334617387,
                "lng": -123.1676860479
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 619,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 28,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 28-9",
            "LABEL": "28-9",
            "FISHERY_AREA_ID": 2520,
            "OBJECTID": 763,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 6895227.1606,
            "FEATURE_LENGTH_M": 11321.752,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.31918632176001,
            "lng": -123.14532485138497
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3503873333,
                "lng": -124.1602486264
            },
            {
                "lat": 49.3516120431,
                "lng": -124.3546367823
            },
            {
                "lat": 49.3263125805,
                "lng": -124.218878422
            },
            {
                "lat": 49.3128771663,
                "lng": -124.1856702435
            },
            {
                "lat": 49.3503873333,
                "lng": -124.1602486264
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 620,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 14-2",
            "LABEL": "14-2",
            "FISHERY_AREA_ID": 2150,
            "OBJECTID": 764,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 25642603.1046,
            "FEATURE_LENGTH_M": 31802.7153,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.33831529129999,
            "lng": -124.21593654012
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3503873333,
                "lng": -124.1602486264
            },
            {
                "lat": 49.3128771663,
                "lng": -124.1856702435
            },
            {
                "lat": 49.2952493854,
                "lng": -124.0880892887
            },
            {
                "lat": 49.236149003,
                "lng": -123.9635620318
            },
            {
                "lat": 49.2287366338,
                "lng": -123.9483711032
            },
            {
                "lat": 49.2280966111,
                "lng": -123.9497681162
            },
            {
                "lat": 49.2276529128,
                "lng": -123.9496076087
            },
            {
                "lat": 49.2273444835,
                "lng": -123.9492799044
            },
            {
                "lat": 49.227131354,
                "lng": -123.9490663428
            },
            {
                "lat": 49.2270010501,
                "lng": -123.9487839485
            },
            {
                "lat": 49.226901942,
                "lng": -123.948410989
            },
            {
                "lat": 49.2268369773,
                "lng": -123.9479987889
            },
            {
                "lat": 49.2266879586,
                "lng": -123.9477532446
            },
            {
                "lat": 49.2264898694,
                "lng": -123.9477534222
            },
            {
                "lat": 49.2261813888,
                "lng": -123.9478373744
            },
            {
                "lat": 49.2258381528,
                "lng": -123.9479142855
            },
            {
                "lat": 49.2254595924,
                "lng": -123.9479533085
            },
            {
                "lat": 49.2249182723,
                "lng": -123.9478674597
            },
            {
                "lat": 49.2239531982,
                "lng": -123.9477231592
            },
            {
                "lat": 49.2230835503,
                "lng": -123.9476844095
            },
            {
                "lat": 49.2223816299,
                "lng": -123.947883495
            },
            {
                "lat": 49.221912643,
                "lng": -123.9477841667
            },
            {
                "lat": 49.2192081901,
                "lng": -123.9472039798
            },
            {
                "lat": 49.2143176624,
                "lng": -123.9443661968
            },
            {
                "lat": 49.2130893603,
                "lng": -123.9403533693
            },
            {
                "lat": 49.2105410261,
                "lng": -123.9322501133
            },
            {
                "lat": 49.1918981948,
                "lng": -123.8729778765
            },
            {
                "lat": 49.1918180294,
                "lng": -123.8727655904
            },
            {
                "lat": 49.1915398858,
                "lng": -123.8720327664
            },
            {
                "lat": 49.1911776944,
                "lng": -123.870949022
            },
            {
                "lat": 49.1904640063,
                "lng": -123.8686834008
            },
            {
                "lat": 49.18907166,
                "lng": -123.8647917238
            },
            {
                "lat": 49.1904756338,
                "lng": -123.852981954
            },
            {
                "lat": 49.191901856,
                "lng": -123.8299028202
            },
            {
                "lat": 49.2000544527,
                "lng": -123.8209164834
            },
            {
                "lat": 49.2018971296,
                "lng": -123.8188860116
            },
            {
                "lat": 49.2025724688,
                "lng": -123.8187034474
            },
            {
                "lat": 49.2091940262,
                "lng": -123.8081357528
            },
            {
                "lat": 49.3503873333,
                "lng": -124.1602486264
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 621,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 17-12",
            "LABEL": "17-12",
            "FISHERY_AREA_ID": 2203,
            "OBJECTID": 765,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 103172402.7471,
            "FEATURE_LENGTH_M": 67870.3147,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.22591999277692,
            "lng": -123.93751693467432
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3273965817,
                "lng": -123.1692210205
            },
            {
                "lat": 49.3303870649,
                "lng": -123.2646637274
            },
            {
                "lat": 49.2656054583,
                "lng": -123.2643350021
            },
            {
                "lat": 49.2647742786,
                "lng": -123.257513774
            },
            {
                "lat": 49.2669372828,
                "lng": -123.2567608322
            },
            {
                "lat": 49.2684321307,
                "lng": -123.2562413954
            },
            {
                "lat": 49.2729525374,
                "lng": -123.2488637814
            },
            {
                "lat": 49.2736661433,
                "lng": -123.2421954357
            },
            {
                "lat": 49.2739072069,
                "lng": -123.2329186716
            },
            {
                "lat": 49.2730374867,
                "lng": -123.2220921117
            },
            {
                "lat": 49.2729525687,
                "lng": -123.2210237782
            },
            {
                "lat": 49.2724763367,
                "lng": -123.2114637401
            },
            {
                "lat": 49.2706987993,
                "lng": -123.2040398001
            },
            {
                "lat": 49.2759011683,
                "lng": -123.2018814715
            },
            {
                "lat": 49.3011469589,
                "lng": -123.158766707
            },
            {
                "lat": 49.3273965817,
                "lng": -123.1692210205
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 622,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 28,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 28-7",
            "LABEL": "28-7",
            "FISHERY_AREA_ID": 2518,
            "OBJECTID": 766,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 41780748.9365,
            "FEATURE_LENGTH_M": 26946.4878,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.28360428655625,
            "lng": -123.22382514183752
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3516120431,
                "lng": -124.3546367823
            },
            {
                "lat": 49.3516235221,
                "lng": -124.3548736692
            },
            {
                "lat": 49.3516470287,
                "lng": -124.3552545695
            },
            {
                "lat": 49.3514443668,
                "lng": -124.3559117453
            },
            {
                "lat": 49.3511543541,
                "lng": -124.3565221492
            },
            {
                "lat": 49.3505548644,
                "lng": -124.3567583581
            },
            {
                "lat": 49.350455888,
                "lng": -124.3573533718
            },
            {
                "lat": 49.3498844729,
                "lng": -124.3581233232
            },
            {
                "lat": 49.3493733538,
                "lng": -124.3586039721
            },
            {
                "lat": 49.3488504997,
                "lng": -124.3597573199
            },
            {
                "lat": 49.347931051,
                "lng": -124.3603744723
            },
            {
                "lat": 49.3469694435,
                "lng": -124.3610687433
            },
            {
                "lat": 49.3445699419,
                "lng": -124.3629004128
            },
            {
                "lat": 49.3383907347,
                "lng": -124.3684391703
            },
            {
                "lat": 49.3149492038,
                "lng": -124.3344112498
            },
            {
                "lat": 49.3058735337,
                "lng": -124.2867730465
            },
            {
                "lat": 49.3058734814,
                "lng": -124.2666086216
            },
            {
                "lat": 49.2962950117,
                "lng": -124.2366109435
            },
            {
                "lat": 49.289237809,
                "lng": -124.2106476426
            },
            {
                "lat": 49.2894893394,
                "lng": -124.1940160562
            },
            {
                "lat": 49.3005789267,
                "lng": -124.1841812438
            },
            {
                "lat": 49.3065486254,
                "lng": -124.1826933136
            },
            {
                "lat": 49.3086778716,
                "lng": -124.184814083
            },
            {
                "lat": 49.3107524302,
                "lng": -124.1859595789
            },
            {
                "lat": 49.3117106164,
                "lng": -124.1857760383
            },
            {
                "lat": 49.312625587,
                "lng": -124.1856989907
            },
            {
                "lat": 49.3128771663,
                "lng": -124.1856702435
            },
            {
                "lat": 49.3263125805,
                "lng": -124.218878422
            },
            {
                "lat": 49.3516120431,
                "lng": -124.3546367823
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 623,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 14,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 14-1",
            "LABEL": "14-1",
            "FISHERY_AREA_ID": 2149,
            "OBJECTID": 767,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 44103290.0466,
            "FEATURE_LENGTH_M": 32171.7586,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.3285474410655,
            "lng": -124.29027428674486
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3592488716,
                "lng": -126.0586546302
            },
            {
                "lat": 49.3592755391,
                "lng": -126.0592573022
            },
            {
                "lat": 49.3592836472,
                "lng": -126.0598453295
            },
            {
                "lat": 49.3592836382,
                "lng": -126.0603637061
            },
            {
                "lat": 49.3592838467,
                "lng": -126.0799943711
            },
            {
                "lat": 49.3616713433,
                "lng": -126.0843658654
            },
            {
                "lat": 49.3606410502,
                "lng": -126.0875010251
            },
            {
                "lat": 49.3564421038,
                "lng": -126.0895003157
            },
            {
                "lat": 49.3484385187,
                "lng": -126.0929029703
            },
            {
                "lat": 49.3270297513,
                "lng": -126.09130084
            },
            {
                "lat": 49.300217078,
                "lng": -126.0888981144
            },
            {
                "lat": 49.2758105025,
                "lng": -126.0838999583
            },
            {
                "lat": 49.2650036539,
                "lng": -126.0786976879
            },
            {
                "lat": 49.2638058274,
                "lng": -126.0632859566
            },
            {
                "lat": 49.2638058467,
                "lng": -126.0592881171
            },
            {
                "lat": 49.2660060296,
                "lng": -126.0566862031
            },
            {
                "lat": 49.269207438,
                "lng": -126.0550839276
            },
            {
                "lat": 49.2709314502,
                "lng": -126.0540082792
            },
            {
                "lat": 49.270432125,
                "lng": -126.0470585235
            },
            {
                "lat": 49.2849501321,
                "lng": -126.0324401629
            },
            {
                "lat": 49.2788011838,
                "lng": -126.017494907
            },
            {
                "lat": 49.2782598147,
                "lng": -126.0134046531
            },
            {
                "lat": 49.2780688389,
                "lng": -126.0110326191
            },
            {
                "lat": 49.2783239933,
                "lng": -126.0068662401
            },
            {
                "lat": 49.2799908229,
                "lng": -126.0019833036
            },
            {
                "lat": 49.2828791769,
                "lng": -125.9990996806
            },
            {
                "lat": 49.2883984686,
                "lng": -125.9985201874
            },
            {
                "lat": 49.2928920739,
                "lng": -126.0000618079
            },
            {
                "lat": 49.2957150288,
                "lng": -126.0039756852
            },
            {
                "lat": 49.3002052272,
                "lng": -126.0114216628
            },
            {
                "lat": 49.3020668913,
                "lng": -126.0144959557
            },
            {
                "lat": 49.3033449902,
                "lng": -126.0152433691
            },
            {
                "lat": 49.303802665,
                "lng": -126.0163958213
            },
            {
                "lat": 49.3040773354,
                "lng": -126.0169755142
            },
            {
                "lat": 49.3041762959,
                "lng": -126.0171816269
            },
            {
                "lat": 49.319778209,
                "lng": -126.0171813902
            },
            {
                "lat": 49.3217163921,
                "lng": -126.0172353652
            },
            {
                "lat": 49.3220715386,
                "lng": -126.0174863204
            },
            {
                "lat": 49.3258585924,
                "lng": -126.0229032239
            },
            {
                "lat": 49.3338738157,
                "lng": -126.0311581121
            },
            {
                "lat": 49.345191794,
                "lng": -126.0429456814
            },
            {
                "lat": 49.3558005157,
                "lng": -126.0541459106
            },
            {
                "lat": 49.3592488716,
                "lng": -126.0586546302
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 624,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 24,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 24-4",
            "LABEL": "24-4",
            "FISHERY_AREA_ID": 2257,
            "OBJECTID": 768,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 47193104.7506,
            "FEATURE_LENGTH_M": 30616.5761,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.31012350998604,
            "lng": -126.04160225474885
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3202975389,
                "lng": -123.1346207549
            },
            {
                "lat": 49.319481338,
                "lng": -123.1355446276
            },
            {
                "lat": 49.3184010018,
                "lng": -123.1363369377
            },
            {
                "lat": 49.3174627179,
                "lng": -123.1368178536
            },
            {
                "lat": 49.3170436352,
                "lng": -123.1371686772
            },
            {
                "lat": 49.3132625042,
                "lng": -123.1406633125
            },
            {
                "lat": 49.308056245,
                "lng": -123.1465226614
            },
            {
                "lat": 49.3063929122,
                "lng": -123.1453325494
            },
            {
                "lat": 49.2997280683,
                "lng": -123.1434315861
            },
            {
                "lat": 49.2906872692,
                "lng": -123.1386725122
            },
            {
                "lat": 49.2842635208,
                "lng": -123.1298674457
            },
            {
                "lat": 49.2816428869,
                "lng": -123.1212993547
            },
            {
                "lat": 49.2802167018,
                "lng": -123.1048811133
            },
            {
                "lat": 49.2797395804,
                "lng": -123.0932227061
            },
            {
                "lat": 49.2790258135,
                "lng": -123.0803751517
            },
            {
                "lat": 49.2780758223,
                "lng": -123.0672920337
            },
            {
                "lat": 49.279025868,
                "lng": -123.037071442
            },
            {
                "lat": 49.2804566645,
                "lng": -123.025413152
            },
            {
                "lat": 49.2926791014,
                "lng": -123.0263747761
            },
            {
                "lat": 49.2993206604,
                "lng": -123.0264969309
            },
            {
                "lat": 49.3003159385,
                "lng": -123.0263523016
            },
            {
                "lat": 49.3018153793,
                "lng": -123.0261375052
            },
            {
                "lat": 49.3035092951,
                "lng": -123.0264739799
            },
            {
                "lat": 49.3048899363,
                "lng": -123.0267497911
            },
            {
                "lat": 49.3084602822,
                "lng": -123.0281743713
            },
            {
                "lat": 49.3106001673,
                "lng": -123.0357900607
            },
            {
                "lat": 49.3122669791,
                "lng": -123.0457848965
            },
            {
                "lat": 49.3125035153,
                "lng": -123.057442527
            },
            {
                "lat": 49.3155977256,
                "lng": -123.0686254471
            },
            {
                "lat": 49.3189272183,
                "lng": -123.0798104299
            },
            {
                "lat": 49.3210715501,
                "lng": -123.0883788101
            },
            {
                "lat": 49.3210716722,
                "lng": -123.1052699482
            },
            {
                "lat": 49.3165512517,
                "lng": -123.1143116668
            },
            {
                "lat": 49.3139691124,
                "lng": -123.1179587963
            },
            {
                "lat": 49.3150331373,
                "lng": -123.1246178403
            },
            {
                "lat": 49.3164137988,
                "lng": -123.1259229714
            },
            {
                "lat": 49.3180273282,
                "lng": -123.1274870982
            },
            {
                "lat": 49.3194162703,
                "lng": -123.1302498343
            },
            {
                "lat": 49.3197060864,
                "lng": -123.1312325103
            },
            {
                "lat": 49.3201600011,
                "lng": -123.1330113951
            },
            {
                "lat": 49.3202975389,
                "lng": -123.1346207549
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 625,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 28,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 28-10",
            "LABEL": "28-10",
            "FISHERY_AREA_ID": 2521,
            "OBJECTID": 769,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 33327162.9647,
            "FEATURE_LENGTH_M": 24015.5338,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.305752781343905,
            "lng": -123.09419045158539
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3084602822,
                "lng": -123.0281743713
            },
            {
                "lat": 49.3048899363,
                "lng": -123.0267497911
            },
            {
                "lat": 49.3035092951,
                "lng": -123.0264739799
            },
            {
                "lat": 49.3018153793,
                "lng": -123.0261375052
            },
            {
                "lat": 49.3003159385,
                "lng": -123.0263523016
            },
            {
                "lat": 49.2993206604,
                "lng": -123.0264969309
            },
            {
                "lat": 49.2926791014,
                "lng": -123.0263747761
            },
            {
                "lat": 49.2804566645,
                "lng": -123.025413152
            },
            {
                "lat": 49.2837977756,
                "lng": -123.0072174298
            },
            {
                "lat": 49.2816928764,
                "lng": -122.9846737266
            },
            {
                "lat": 49.2792888899,
                "lng": -122.9624245128
            },
            {
                "lat": 49.2765846969,
                "lng": -122.9188316086
            },
            {
                "lat": 49.2789874933,
                "lng": -122.8914723619
            },
            {
                "lat": 49.2777860077,
                "lng": -122.8716344847
            },
            {
                "lat": 49.2699697981,
                "lng": -122.8569031808
            },
            {
                "lat": 49.2699699571,
                "lng": -122.8370598175
            },
            {
                "lat": 49.2795904539,
                "lng": -122.8280415059
            },
            {
                "lat": 49.2889095153,
                "lng": -122.8313442984
            },
            {
                "lat": 49.2997326125,
                "lng": -122.8556977995
            },
            {
                "lat": 49.3069456459,
                "lng": -122.8692241458
            },
            {
                "lat": 49.3000329787,
                "lng": -122.9050058175
            },
            {
                "lat": 49.2982297717,
                "lng": -122.9155262061
            },
            {
                "lat": 49.3016128148,
                "lng": -122.9250416933
            },
            {
                "lat": 49.2998234925,
                "lng": -122.9244539946
            },
            {
                "lat": 49.2993054869,
                "lng": -122.9245313367
            },
            {
                "lat": 49.2985988298,
                "lng": -122.925437586
            },
            {
                "lat": 49.2982135888,
                "lng": -122.9261935897
            },
            {
                "lat": 49.2981611763,
                "lng": -122.9266662709
            },
            {
                "lat": 49.2981563808,
                "lng": -122.9269329875
            },
            {
                "lat": 49.300503419,
                "lng": -122.9561322723
            },
            {
                "lat": 49.3082239239,
                "lng": -122.9563144786
            },
            {
                "lat": 49.3120271484,
                "lng": -122.9639281801
            },
            {
                "lat": 49.3153615307,
                "lng": -122.9713055302
            },
            {
                "lat": 49.3163114377,
                "lng": -122.9817725585
            },
            {
                "lat": 49.3139341609,
                "lng": -122.9991453928
            },
            {
                "lat": 49.3089375092,
                "lng": -123.0139001145
            },
            {
                "lat": 49.3084602822,
                "lng": -123.0281743713
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 626,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 28,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 28-11",
            "LABEL": "28-11",
            "FISHERY_AREA_ID": 2522,
            "OBJECTID": 770,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 42095880.6727,
            "FEATURE_LENGTH_M": 36454.7777,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.2962323489892,
            "lng": -122.94857189354052
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3005337305,
                "lng": -123.1562883331
            },
            {
                "lat": 49.3010748208,
                "lng": -123.1577365476
            },
            {
                "lat": 49.3011469589,
                "lng": -123.158766707
            },
            {
                "lat": 49.2759011683,
                "lng": -123.2018814715
            },
            {
                "lat": 49.2706987993,
                "lng": -123.2040398001
            },
            {
                "lat": 49.2698859815,
                "lng": -123.1990809241
            },
            {
                "lat": 49.2682459197,
                "lng": -123.1927880844
            },
            {
                "lat": 49.2697487515,
                "lng": -123.184158298
            },
            {
                "lat": 49.2681083499,
                "lng": -123.1759494265
            },
            {
                "lat": 49.2673262142,
                "lng": -123.1606974915
            },
            {
                "lat": 49.2670140366,
                "lng": -123.1545943788
            },
            {
                "lat": 49.2675589601,
                "lng": -123.1450125376
            },
            {
                "lat": 49.2668418238,
                "lng": -123.1416546632
            },
            {
                "lat": 49.2655066633,
                "lng": -123.1354293786
            },
            {
                "lat": 49.264412177,
                "lng": -123.1201018537
            },
            {
                "lat": 49.2660561512,
                "lng": -123.1050419165
            },
            {
                "lat": 49.2716672905,
                "lng": -123.0997091065
            },
            {
                "lat": 49.2757721136,
                "lng": -123.099845753
            },
            {
                "lat": 49.2780991368,
                "lng": -123.1061401719
            },
            {
                "lat": 49.278099333,
                "lng": -123.1142119605
            },
            {
                "lat": 49.277961848,
                "lng": -123.124618091
            },
            {
                "lat": 49.2806777326,
                "lng": -123.1333687484
            },
            {
                "lat": 49.2895962651,
                "lng": -123.1401899778
            },
            {
                "lat": 49.2936023344,
                "lng": -123.1445926005
            },
            {
                "lat": 49.2968141929,
                "lng": -123.1470875312
            },
            {
                "lat": 49.2992321689,
                "lng": -123.1515958825
            },
            {
                "lat": 49.3005337305,
                "lng": -123.1562883331
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 627,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 28,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 28-8",
            "LABEL": "28-8",
            "FISHERY_AREA_ID": 2519,
            "OBJECTID": 771,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 15968996.0721,
            "FEATURE_LENGTH_M": 18998.6318,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.27896728344076,
            "lng": -123.14855073957779
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.3128771663,
                "lng": -124.1856702435
            },
            {
                "lat": 49.312625587,
                "lng": -124.1856989907
            },
            {
                "lat": 49.3117106164,
                "lng": -124.1857760383
            },
            {
                "lat": 49.3107524302,
                "lng": -124.1859595789
            },
            {
                "lat": 49.3086778716,
                "lng": -124.184814083
            },
            {
                "lat": 49.3065486254,
                "lng": -124.1826933136
            },
            {
                "lat": 49.3009527193,
                "lng": -124.1770929025
            },
            {
                "lat": 49.2915615727,
                "lng": -124.1670751563
            },
            {
                "lat": 49.2880132038,
                "lng": -124.15705937
            },
            {
                "lat": 49.2888488832,
                "lng": -124.1478728392
            },
            {
                "lat": 49.2836301394,
                "lng": -124.1374367116
            },
            {
                "lat": 49.2775765029,
                "lng": -124.1270056937
            },
            {
                "lat": 49.2731933441,
                "lng": -124.1219928818
            },
            {
                "lat": 49.2699548969,
                "lng": -124.1190408716
            },
            {
                "lat": 49.2707219852,
                "lng": -124.1107027848
            },
            {
                "lat": 49.2708512209,
                "lng": -124.1092297586
            },
            {
                "lat": 49.2708429232,
                "lng": -124.1050654211
            },
            {
                "lat": 49.2707940179,
                "lng": -124.1032099661
            },
            {
                "lat": 49.2701678521,
                "lng": -124.0780022332
            },
            {
                "lat": 49.2572548974,
                "lng": -124.0832513943
            },
            {
                "lat": 49.2492444423,
                "lng": -124.0851058814
            },
            {
                "lat": 49.2438846619,
                "lng": -124.0684581213
            },
            {
                "lat": 49.2357030365,
                "lng": -124.0286781288
            },
            {
                "lat": 49.2320371477,
                "lng": -124.0018759253
            },
            {
                "lat": 49.2311897025,
                "lng": -123.9795843762
            },
            {
                "lat": 49.2314724198,
                "lng": -123.9705580816
            },
            {
                "lat": 49.2305178903,
                "lng": -123.9674299467
            },
            {
                "lat": 49.2337381057,
                "lng": -123.9653625877
            },
            {
                "lat": 49.236149003,
                "lng": -123.9635620318
            },
            {
                "lat": 49.2952493854,
                "lng": -124.0880892887
            },
            {
                "lat": 49.3128771663,
                "lng": -124.1856702435
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 628,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 18,
            "MANAGEMENT_AREA_NAME": "Subarea 17-18",
            "LABEL": "17-18",
            "FISHERY_AREA_ID": 2209,
            "OBJECTID": 772,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 44441353.0386,
            "FEATURE_LENGTH_M": 40844.6767,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.27353611023549,
            "lng": -124.10190402728387
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1052854489,
                "lng": -124.839226475
            },
            {
                "lat": 49.1064493851,
                "lng": -124.837035721
            },
            {
                "lat": 49.106670554,
                "lng": -124.8337636152
            },
            {
                "lat": 49.1065250945,
                "lng": -124.8317936297
            },
            {
                "lat": 49.1036951119,
                "lng": -124.8309017885
            },
            {
                "lat": 49.1036951705,
                "lng": -124.8144296301
            },
            {
                "lat": 49.1039312968,
                "lng": -124.8126758354
            },
            {
                "lat": 49.1042632334,
                "lng": -124.8101499977
            },
            {
                "lat": 49.1027836706,
                "lng": -124.8080740793
            },
            {
                "lat": 49.1132620198,
                "lng": -124.7978969331
            },
            {
                "lat": 49.1305811526,
                "lng": -124.7810979737
            },
            {
                "lat": 49.1526302016,
                "lng": -124.7753228559
            },
            {
                "lat": 49.1767737486,
                "lng": -124.7800517111
            },
            {
                "lat": 49.2135166159,
                "lng": -124.7826765902
            },
            {
                "lat": 49.2649570094,
                "lng": -124.7842481758
            },
            {
                "lat": 49.2959292765,
                "lng": -124.8162682781
            },
            {
                "lat": 49.3064266382,
                "lng": -124.8488171448
            },
            {
                "lat": 49.308002933,
                "lng": -124.869285255
            },
            {
                "lat": 49.2996022126,
                "lng": -124.8923806383
            },
            {
                "lat": 49.27230864,
                "lng": -124.8813559046
            },
            {
                "lat": 49.2350384004,
                "lng": -124.8635107956
            },
            {
                "lat": 49.2082675027,
                "lng": -124.8551105483
            },
            {
                "lat": 49.184124036,
                "lng": -124.8519602198
            },
            {
                "lat": 49.152630132,
                "lng": -124.8299171235
            },
            {
                "lat": 49.1374055663,
                "lng": -124.8278200447
            },
            {
                "lat": 49.1190340676,
                "lng": -124.8367385365
            },
            {
                "lat": 49.1052854489,
                "lng": -124.839226475
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 629,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 23,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 23-1",
            "LABEL": "23-1",
            "FISHERY_AREA_ID": 2243,
            "OBJECTID": 773,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 111686510.652,
            "FEATURE_LENGTH_M": 53973.3794,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.171076835844445,
            "lng": -124.82710133244072
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.879569543,
                "lng": -124.547309777
            },
            {
                "lat": 49.8748124663,
                "lng": -124.5526658506
            },
            {
                "lat": 49.8736226921,
                "lng": -124.5592039594
            },
            {
                "lat": 49.8729899124,
                "lng": -124.5614393682
            },
            {
                "lat": 49.8726006783,
                "lng": -124.5630045167
            },
            {
                "lat": 49.8724636637,
                "lng": -124.5645147737
            },
            {
                "lat": 49.8725893203,
                "lng": -124.5660393965
            },
            {
                "lat": 49.8726008712,
                "lng": -124.5661920649
            },
            {
                "lat": 49.8730011745,
                "lng": -124.5669707653
            },
            {
                "lat": 49.8573037248,
                "lng": -124.5820612318
            },
            {
                "lat": 49.7995492608,
                "lng": -124.6375811702
            },
            {
                "lat": 49.7970358932,
                "lng": -124.629447672
            },
            {
                "lat": 49.7951320531,
                "lng": -124.6236490172
            },
            {
                "lat": 49.7933883688,
                "lng": -124.6238853769
            },
            {
                "lat": 49.7916759958,
                "lng": -124.6218328831
            },
            {
                "lat": 49.7899586526,
                "lng": -124.6162344072
            },
            {
                "lat": 49.7897912929,
                "lng": -124.6129223119
            },
            {
                "lat": 49.7907374332,
                "lng": -124.6102673396
            },
            {
                "lat": 49.7873045008,
                "lng": -124.5998077537
            },
            {
                "lat": 49.7795104729,
                "lng": -124.595474587
            },
            {
                "lat": 49.7585149427,
                "lng": -124.5835567416
            },
            {
                "lat": 49.7460292136,
                "lng": -124.550644002
            },
            {
                "lat": 49.7352482909,
                "lng": -124.523399737
            },
            {
                "lat": 49.7244645424,
                "lng": -124.4927602528
            },
            {
                "lat": 49.7204889376,
                "lng": -124.4592740209
            },
            {
                "lat": 49.7170863338,
                "lng": -124.4320372542
            },
            {
                "lat": 49.7153854271,
                "lng": -124.4093327302
            },
            {
                "lat": 49.7102770113,
                "lng": -124.3923103857
            },
            {
                "lat": 49.7057344813,
                "lng": -124.3730173884
            },
            {
                "lat": 49.7028964542,
                "lng": -124.3684691716
            },
            {
                "lat": 49.7071489679,
                "lng": -124.3612672625
            },
            {
                "lat": 49.7078475035,
                "lng": -124.3583068865
            },
            {
                "lat": 49.7074052554,
                "lng": -124.3563836461
            },
            {
                "lat": 49.740616015,
                "lng": -124.2687763299
            },
            {
                "lat": 49.7410620104,
                "lng": -124.2687837853
            },
            {
                "lat": 49.741794409,
                "lng": -124.2687682983
            },
            {
                "lat": 49.7422261508,
                "lng": -124.2687988361
            },
            {
                "lat": 49.7430874177,
                "lng": -124.2689675197
            },
            {
                "lat": 49.7461467961,
                "lng": -124.269433128
            },
            {
                "lat": 49.7552869741,
                "lng": -124.2714926722
            },
            {
                "lat": 49.7636150814,
                "lng": -124.2816080059
            },
            {
                "lat": 49.7761074505,
                "lng": -124.2935034359
            },
            {
                "lat": 49.7850309647,
                "lng": -124.3000485827
            },
            {
                "lat": 49.7790799692,
                "lng": -124.316109785
            },
            {
                "lat": 49.7897876392,
                "lng": -124.3321682969
            },
            {
                "lat": 49.7957389191,
                "lng": -124.3559649376
            },
            {
                "lat": 49.7957387491,
                "lng": -124.3785698974
            },
            {
                "lat": 49.803470972,
                "lng": -124.4178317776
            },
            {
                "lat": 49.817749302,
                "lng": -124.4636305701
            },
            {
                "lat": 49.8367845593,
                "lng": -124.4981380163
            },
            {
                "lat": 49.8647425884,
                "lng": -124.5153884073
            },
            {
                "lat": 49.8772355232,
                "lng": -124.5290686656
            },
            {
                "lat": 49.879569543,
                "lng": -124.547309777
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 538,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 15,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 15-1",
            "LABEL": "15-1",
            "FISHERY_AREA_ID": 2164,
            "OBJECTID": 682,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 249331430.5731,
            "FEATURE_LENGTH_M": 71701.5409,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.78809502578491,
            "lng": -124.46369102692827
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.8546985271,
                "lng": -126.6751244688
            },
            {
                "lat": 49.85405675,
                "lng": -126.6822738289
            },
            {
                "lat": 49.8517647035,
                "lng": -126.6889193582
            },
            {
                "lat": 49.8472512965,
                "lng": -126.694992647
            },
            {
                "lat": 49.8417018632,
                "lng": -126.706450889
            },
            {
                "lat": 49.8385775159,
                "lng": -126.7191162705
            },
            {
                "lat": 49.839443558,
                "lng": -126.7309193907
            },
            {
                "lat": 49.8460381713,
                "lng": -126.7517390329
            },
            {
                "lat": 49.8626973752,
                "lng": -126.7683948144
            },
            {
                "lat": 49.8739781892,
                "lng": -126.7803723
            },
            {
                "lat": 49.8819582065,
                "lng": -126.7949445728
            },
            {
                "lat": 49.8714259921,
                "lng": -126.7950598056
            },
            {
                "lat": 49.8641281294,
                "lng": -126.7922367672
            },
            {
                "lat": 49.8616941131,
                "lng": -126.7896427897
            },
            {
                "lat": 49.8595774118,
                "lng": -126.7901155817
            },
            {
                "lat": 49.8579287373,
                "lng": -126.7927856525
            },
            {
                "lat": 49.8579290556,
                "lng": -126.7998496113
            },
            {
                "lat": 49.8585586384,
                "lng": -126.8110650737
            },
            {
                "lat": 49.8576169558,
                "lng": -126.8288802995
            },
            {
                "lat": 49.8545575069,
                "lng": -126.8415753214
            },
            {
                "lat": 49.8539734368,
                "lng": -126.8435589798
            },
            {
                "lat": 49.8402898023,
                "lng": -126.8680258455
            },
            {
                "lat": 49.8271335047,
                "lng": -126.8853987577
            },
            {
                "lat": 49.8216063832,
                "lng": -126.9032898256
            },
            {
                "lat": 49.8226586959,
                "lng": -126.9196015843
            },
            {
                "lat": 49.8221323588,
                "lng": -126.9293365287
            },
            {
                "lat": 49.8150287046,
                "lng": -126.9327628943
            },
            {
                "lat": 49.8037145046,
                "lng": -126.9203942792
            },
            {
                "lat": 49.798698294,
                "lng": -126.917785967
            },
            {
                "lat": 49.7957724079,
                "lng": -126.9050599846
            },
            {
                "lat": 49.7972105283,
                "lng": -126.8792034601
            },
            {
                "lat": 49.8022381446,
                "lng": -126.8501136847
            },
            {
                "lat": 49.8069078727,
                "lng": -126.8321606045
            },
            {
                "lat": 49.8112148241,
                "lng": -126.8098989986
            },
            {
                "lat": 49.8101380693,
                "lng": -126.789428178
            },
            {
                "lat": 49.8083418961,
                "lng": -126.7675251694
            },
            {
                "lat": 49.8058282478,
                "lng": -126.7463371451
            },
            {
                "lat": 49.8036726497,
                "lng": -126.7366414815
            },
            {
                "lat": 49.8015174464,
                "lng": -126.7308875307
            },
            {
                "lat": 49.7982861547,
                "lng": -126.7305296208
            },
            {
                "lat": 49.7921835344,
                "lng": -126.7330471778
            },
            {
                "lat": 49.7893105574,
                "lng": -126.738075151
            },
            {
                "lat": 49.7857167961,
                "lng": -126.7535164051
            },
            {
                "lat": 49.7796138253,
                "lng": -126.7711108458
            },
            {
                "lat": 49.784999429,
                "lng": -126.7847592754
            },
            {
                "lat": 49.7900274968,
                "lng": -126.8005603497
            },
            {
                "lat": 49.7871554851,
                "lng": -126.8084561746
            },
            {
                "lat": 49.7742277265,
                "lng": -126.8145602406
            },
            {
                "lat": 49.7648919683,
                "lng": -126.822822319
            },
            {
                "lat": 49.7623787013,
                "lng": -126.8379061412
            },
            {
                "lat": 49.7573505981,
                "lng": -126.8522721285
            },
            {
                "lat": 49.7537568001,
                "lng": -126.8745347394
            },
            {
                "lat": 49.7523230921,
                "lng": -126.8985903057
            },
            {
                "lat": 49.7541154669,
                "lng": -126.9068528108
            },
            {
                "lat": 49.756630385,
                "lng": -126.9147496811
            },
            {
                "lat": 49.7531623635,
                "lng": -126.9118814824
            },
            {
                "lat": 49.7507746924,
                "lng": -126.9123152746
            },
            {
                "lat": 49.748169104,
                "lng": -126.9156795699
            },
            {
                "lat": 49.7375415907,
                "lng": -126.9219661893
            },
            {
                "lat": 49.7248500002,
                "lng": -126.9255529693
            },
            {
                "lat": 49.7221375972,
                "lng": -126.9282608269
            },
            {
                "lat": 49.7119755222,
                "lng": -126.9203640449
            },
            {
                "lat": 49.7011991583,
                "lng": -126.9093014804
            },
            {
                "lat": 49.6856041825,
                "lng": -126.8894568958
            },
            {
                "lat": 49.682201209,
                "lng": -126.8761290519
            },
            {
                "lat": 49.6782301676,
                "lng": -126.8633734288
            },
            {
                "lat": 49.6688764494,
                "lng": -126.8440853087
            },
            {
                "lat": 49.6609345571,
                "lng": -126.8335948898
            },
            {
                "lat": 49.6478925468,
                "lng": -126.8208384774
            },
            {
                "lat": 49.6374012284,
                "lng": -126.818000332
            },
            {
                "lat": 49.6242789145,
                "lng": -126.772140741
            },
            {
                "lat": 49.6219365609,
                "lng": -126.7360767774
            },
            {
                "lat": 49.6167829069,
                "lng": -126.7117236664
            },
            {
                "lat": 49.6102296895,
                "lng": -126.6901774538
            },
            {
                "lat": 49.6013297341,
                "lng": -126.6784668312
            },
            {
                "lat": 49.5961761135,
                "lng": -126.6719133709
            },
            {
                "lat": 49.5995486357,
                "lng": -126.6607288986
            },
            {
                "lat": 49.6005358287,
                "lng": -126.6473693591
            },
            {
                "lat": 49.6005359877,
                "lng": -126.6333466666
            },
            {
                "lat": 49.5986477228,
                "lng": -126.6228253059
            },
            {
                "lat": 49.6080248742,
                "lng": -126.6271969576
            },
            {
                "lat": 49.6110575898,
                "lng": -126.6323400034
            },
            {
                "lat": 49.6215394444,
                "lng": -126.6334990369
            },
            {
                "lat": 49.6253241819,
                "lng": -126.6319731005
            },
            {
                "lat": 49.6321716933,
                "lng": -126.6293023457
            },
            {
                "lat": 49.638183536,
                "lng": -126.6318054752
            },
            {
                "lat": 49.6483685105,
                "lng": -126.6346436216
            },
            {
                "lat": 49.6565515697,
                "lng": -126.6368106672
            },
            {
                "lat": 49.6607248769,
                "lng": -126.6351464228
            },
            {
                "lat": 49.6633988859,
                "lng": -126.6296310004
            },
            {
                "lat": 49.6682394868,
                "lng": -126.6266248192
            },
            {
                "lat": 49.6744188447,
                "lng": -126.6279676205
            },
            {
                "lat": 49.6846052328,
                "lng": -126.6354746659
            },
            {
                "lat": 49.6969604246,
                "lng": -126.6448288522
            },
            {
                "lat": 49.7114902071,
                "lng": -126.6523435202
            },
            {
                "lat": 49.7225111602,
                "lng": -126.6546784693
            },
            {
                "lat": 49.7320292787,
                "lng": -126.6513448271
            },
            {
                "lat": 49.73937622,
                "lng": -126.6493374806
            },
            {
                "lat": 49.7457351543,
                "lng": -126.6480793452
            },
            {
                "lat": 49.7463261651,
                "lng": -126.6477582464
            },
            {
                "lat": 49.7486418136,
                "lng": -126.6453399107
            },
            {
                "lat": 49.7489886,
                "lng": -126.6395193764
            },
            {
                "lat": 49.7586745558,
                "lng": -126.6487425189
            },
            {
                "lat": 49.7786642379,
                "lng": -126.659339782
            },
            {
                "lat": 49.8039470513,
                "lng": -126.6704186404
            },
            {
                "lat": 49.8203242698,
                "lng": -126.6771535922
            },
            {
                "lat": 49.8330881933,
                "lng": -126.6800453682
            },
            {
                "lat": 49.84609212,
                "lng": -126.6769177396
            },
            {
                "lat": 49.8546985271,
                "lng": -126.6751244688
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 539,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 683,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 397052680.5922,
            "FEATURE_LENGTH_M": 122172.7347,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.754382914266074,
            "lng": -126.7641575221073
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.8594099931,
                "lng": -127.1521148891
            },
            {
                "lat": 49.763283145,
                "lng": -127.270797449
            },
            {
                "lat": 49.5833320614,
                "lng": -126.9260020769
            },
            {
                "lat": 49.6184916619,
                "lng": -126.8272316664
            },
            {
                "lat": 49.6274755241,
                "lng": -126.8219755308
            },
            {
                "lat": 49.6374012284,
                "lng": -126.818000332
            },
            {
                "lat": 49.6478925468,
                "lng": -126.8208384774
            },
            {
                "lat": 49.6609345571,
                "lng": -126.8335948898
            },
            {
                "lat": 49.6688764494,
                "lng": -126.8440853087
            },
            {
                "lat": 49.6782301676,
                "lng": -126.8633734288
            },
            {
                "lat": 49.682201209,
                "lng": -126.8761290519
            },
            {
                "lat": 49.6856041825,
                "lng": -126.8894568958
            },
            {
                "lat": 49.7011991583,
                "lng": -126.9093014804
            },
            {
                "lat": 49.7119755222,
                "lng": -126.9203640449
            },
            {
                "lat": 49.7221375972,
                "lng": -126.9282608269
            },
            {
                "lat": 49.721919714,
                "lng": -126.9331428055
            },
            {
                "lat": 49.7235485428,
                "lng": -126.9408415511
            },
            {
                "lat": 49.7253913967,
                "lng": -126.9471361784
            },
            {
                "lat": 49.7253913999,
                "lng": -126.9508209756
            },
            {
                "lat": 49.7251736657,
                "lng": -126.9527741226
            },
            {
                "lat": 49.7249221056,
                "lng": -126.9532238587
            },
            {
                "lat": 49.7246659849,
                "lng": -126.9537124986
            },
            {
                "lat": 49.7245904397,
                "lng": -126.9539943111
            },
            {
                "lat": 49.7245600501,
                "lng": -126.9542233188
            },
            {
                "lat": 49.7245367838,
                "lng": -126.9543762283
            },
            {
                "lat": 49.7246323747,
                "lng": -126.955048082
            },
            {
                "lat": 49.724967551,
                "lng": -126.9552907672
            },
            {
                "lat": 49.7254297399,
                "lng": -126.9555057746
            },
            {
                "lat": 49.7258533749,
                "lng": -126.9555977846
            },
            {
                "lat": 49.7261271843,
                "lng": -126.9556580727
            },
            {
                "lat": 49.7266998046,
                "lng": -126.9557800589
            },
            {
                "lat": 49.7273936172,
                "lng": -126.9564739481
            },
            {
                "lat": 49.7318920289,
                "lng": -126.9608614284
            },
            {
                "lat": 49.7372624309,
                "lng": -126.9660109738
            },
            {
                "lat": 49.742194463,
                "lng": -126.9710536398
            },
            {
                "lat": 49.74548352,
                "lng": -126.9734649483
            },
            {
                "lat": 49.7466884448,
                "lng": -126.9757690056
            },
            {
                "lat": 49.7477872714,
                "lng": -126.977737124
            },
            {
                "lat": 49.7481157027,
                "lng": -126.9791643277
            },
            {
                "lat": 49.7482261477,
                "lng": -126.980262622
            },
            {
                "lat": 49.7480468507,
                "lng": -126.9806975128
            },
            {
                "lat": 49.7479936269,
                "lng": -126.9811784472
            },
            {
                "lat": 49.7480163702,
                "lng": -126.9814000206
            },
            {
                "lat": 49.7480925432,
                "lng": -126.9817432621
            },
            {
                "lat": 49.748176225,
                "lng": -126.9820025696
            },
            {
                "lat": 49.8594099931,
                "lng": -127.1521148891
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 540,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 125,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 125-3",
            "LABEL": "125-3",
            "FISHERY_AREA_ID": 2331,
            "OBJECTID": 684,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 402319825.0576,
            "FEATURE_LENGTH_M": 91641.2118,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.720035529397826,
            "lng": -126.95062146644781
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6588133654,
                "lng": -123.8320309474
            },
            {
                "lat": 49.6502381375,
                "lng": -123.8231049254
            },
            {
                "lat": 49.6478307114,
                "lng": -123.8150782181
            },
            {
                "lat": 49.6578633016,
                "lng": -123.8086543534
            },
            {
                "lat": 49.6686970575,
                "lng": -123.8066486047
            },
            {
                "lat": 49.6787297975,
                "lng": -123.7793580954
            },
            {
                "lat": 49.6971932333,
                "lng": -123.766113858
            },
            {
                "lat": 49.7056191912,
                "lng": -123.7348101007
            },
            {
                "lat": 49.7284962747,
                "lng": -123.7227705041
            },
            {
                "lat": 49.7525755528,
                "lng": -123.7155462596
            },
            {
                "lat": 49.7838786067,
                "lng": -123.7039114543
            },
            {
                "lat": 49.8023421271,
                "lng": -123.7115334764
            },
            {
                "lat": 49.7987292285,
                "lng": -123.7307969968
            },
            {
                "lat": 49.780670092,
                "lng": -123.7340084235
            },
            {
                "lat": 49.7585939381,
                "lng": -123.7392269873
            },
            {
                "lat": 49.7345157711,
                "lng": -123.7536784847
            },
            {
                "lat": 49.7244830296,
                "lng": -123.7741385066
            },
            {
                "lat": 49.7124448034,
                "lng": -123.7942038265
            },
            {
                "lat": 49.7008056754,
                "lng": -123.8098602873
            },
            {
                "lat": 49.6931803137,
                "lng": -123.8247071018
            },
            {
                "lat": 49.6899683387,
                "lng": -123.8397207904
            },
            {
                "lat": 49.6862640131,
                "lng": -123.8406987694
            },
            {
                "lat": 49.6653173975,
                "lng": -123.8342125372
            },
            {
                "lat": 49.663806408,
                "lng": -123.8336032231
            },
            {
                "lat": 49.6588133654,
                "lng": -123.8320309474
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 541,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 16-8",
            "LABEL": "16-8",
            "FISHERY_AREA_ID": 2177,
            "OBJECTID": 685,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 46819457.7124,
            "FEATURE_LENGTH_M": 42707.9493,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.707994789248005,
            "lng": -123.78241790718002
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.804377861,
                "lng": -123.9698258377
            },
            {
                "lat": 49.8004220565,
                "lng": -124.0777201259
            },
            {
                "lat": 49.8003699878,
                "lng": -124.0786600333
            },
            {
                "lat": 49.8009866172,
                "lng": -124.0814664911
            },
            {
                "lat": 49.8022800503,
                "lng": -124.0852953692
            },
            {
                "lat": 49.8046608278,
                "lng": -124.0966041433
            },
            {
                "lat": 49.8094210028,
                "lng": -124.1126633332
            },
            {
                "lat": 49.8040656991,
                "lng": -124.1251514926
            },
            {
                "lat": 49.7993042964,
                "lng": -124.1543049428
            },
            {
                "lat": 49.7975232428,
                "lng": -124.1810749169
            },
            {
                "lat": 49.7993044596,
                "lng": -124.2399671507
            },
            {
                "lat": 49.7933581046,
                "lng": -124.2845764731
            },
            {
                "lat": 49.7850309647,
                "lng": -124.3000485827
            },
            {
                "lat": 49.7761074505,
                "lng": -124.2935034359
            },
            {
                "lat": 49.7636150814,
                "lng": -124.2816080059
            },
            {
                "lat": 49.7552869741,
                "lng": -124.2714926722
            },
            {
                "lat": 49.7461467961,
                "lng": -124.269433128
            },
            {
                "lat": 49.7430874177,
                "lng": -124.2689675197
            },
            {
                "lat": 49.7422261508,
                "lng": -124.2687988361
            },
            {
                "lat": 49.741794409,
                "lng": -124.2687682983
            },
            {
                "lat": 49.7410620104,
                "lng": -124.2687837853
            },
            {
                "lat": 49.740616015,
                "lng": -124.2687763299
            },
            {
                "lat": 49.7242657913,
                "lng": -124.2232199801
            },
            {
                "lat": 49.7271152946,
                "lng": -124.2226554842
            },
            {
                "lat": 49.7304912248,
                "lng": -124.2206505558
            },
            {
                "lat": 49.7342877485,
                "lng": -124.2142109376
            },
            {
                "lat": 49.7383007106,
                "lng": -124.2070386703
            },
            {
                "lat": 49.7417791887,
                "lng": -124.200387286
            },
            {
                "lat": 49.7434691846,
                "lng": -124.1915281338
            },
            {
                "lat": 49.7447363006,
                "lng": -124.1856161012
            },
            {
                "lat": 49.7471619797,
                "lng": -124.1771775137
            },
            {
                "lat": 49.7495884444,
                "lng": -124.1691594709
            },
            {
                "lat": 49.7512772432,
                "lng": -124.163985395
            },
            {
                "lat": 49.7521214696,
                "lng": -124.1609263939
            },
            {
                "lat": 49.7529641585,
                "lng": -124.1594476308
            },
            {
                "lat": 49.7532807213,
                "lng": -124.1581807508
            },
            {
                "lat": 49.7530715648,
                "lng": -124.1574484369
            },
            {
                "lat": 49.752666058,
                "lng": -124.1564100425
            },
            {
                "lat": 49.7526661795,
                "lng": -124.1521600954
            },
            {
                "lat": 49.7523613669,
                "lng": -124.1512599395
            },
            {
                "lat": 49.7522237405,
                "lng": -124.1507034563
            },
            {
                "lat": 49.7526322548,
                "lng": -124.1486973227
            },
            {
                "lat": 49.7532038426,
                "lng": -124.1458657885
            },
            {
                "lat": 49.755283534,
                "lng": -124.1215450973
            },
            {
                "lat": 49.7503284756,
                "lng": -124.1177826427
            },
            {
                "lat": 49.7441293625,
                "lng": -124.1078110233
            },
            {
                "lat": 49.7419699795,
                "lng": -124.0978393826
            },
            {
                "lat": 49.7433206143,
                "lng": -124.0892104767
            },
            {
                "lat": 49.7473601047,
                "lng": -124.0830148356
            },
            {
                "lat": 49.7530212667,
                "lng": -124.0816646124
            },
            {
                "lat": 49.7586405262,
                "lng": -124.082220775
            },
            {
                "lat": 49.7619167235,
                "lng": -124.0827483392
            },
            {
                "lat": 49.7667701854,
                "lng": -124.0805898383
            },
            {
                "lat": 49.7697340391,
                "lng": -124.0733108872
            },
            {
                "lat": 49.7705417884,
                "lng": -124.0576774044
            },
            {
                "lat": 49.7745858498,
                "lng": -124.0417784039
            },
            {
                "lat": 49.7767409824,
                "lng": -124.0250622154
            },
            {
                "lat": 49.7762031162,
                "lng": -124.0142826968
            },
            {
                "lat": 49.7753938863,
                "lng": -124.0021596719
            },
            {
                "lat": 49.7729679005,
                "lng": -123.9962239385
            },
            {
                "lat": 49.7702719168,
                "lng": -123.9943390281
            },
            {
                "lat": 49.7662693345,
                "lng": -123.9928967609
            },
            {
                "lat": 49.7667652497,
                "lng": -123.9710464861
            },
            {
                "lat": 49.7757301977,
                "lng": -123.9468242295
            },
            {
                "lat": 49.804377861,
                "lng": -123.9698258377
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 542,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 16-11",
            "LABEL": "16-11",
            "FISHERY_AREA_ID": 2180,
            "OBJECTID": 686,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 123029874.2216,
            "FEATURE_LENGTH_M": 62796.4964,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.763585151352316,
            "lng": -124.13837038266463
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.600662702,
                "lng": -123.7834245427
            },
            {
                "lat": 49.5977594842,
                "lng": -123.7645874043
            },
            {
                "lat": 49.6025161122,
                "lng": -123.7443623192
            },
            {
                "lat": 49.6179807626,
                "lng": -123.6932070281
            },
            {
                "lat": 49.6227422123,
                "lng": -123.6408534962
            },
            {
                "lat": 49.642966814,
                "lng": -123.5647124249
            },
            {
                "lat": 49.6679532112,
                "lng": -123.5135505562
            },
            {
                "lat": 49.7060245093,
                "lng": -123.500464951
            },
            {
                "lat": 49.7226785962,
                "lng": -123.4457400914
            },
            {
                "lat": 49.7607501225,
                "lng": -123.396958233
            },
            {
                "lat": 49.784545157,
                "lng": -123.3981474597
            },
            {
                "lat": 49.7940638012,
                "lng": -123.4255140799
            },
            {
                "lat": 49.7738373058,
                "lng": -123.4873820545
            },
            {
                "lat": 49.7571829256,
                "lng": -123.54567787
            },
            {
                "lat": 49.7250592909,
                "lng": -123.5706631558
            },
            {
                "lat": 49.7012629803,
                "lng": -123.5861284683
            },
            {
                "lat": 49.6810381293,
                "lng": -123.643234526
            },
            {
                "lat": 49.6643825593,
                "lng": -123.7074819306
            },
            {
                "lat": 49.6581952519,
                "lng": -123.7336491366
            },
            {
                "lat": 49.6489145415,
                "lng": -123.7764806244
            },
            {
                "lat": 49.6403499565,
                "lng": -123.8002780119
            },
            {
                "lat": 49.6366235703,
                "lng": -123.8092265725
            },
            {
                "lat": 49.6333697194,
                "lng": -123.8077999201
            },
            {
                "lat": 49.6309702455,
                "lng": -123.8053983563
            },
            {
                "lat": 49.6298980833,
                "lng": -123.8043749891
            },
            {
                "lat": 49.6272624558,
                "lng": -123.8045042166
            },
            {
                "lat": 49.6254650718,
                "lng": -123.8045726744
            },
            {
                "lat": 49.6244043999,
                "lng": -123.8046492309
            },
            {
                "lat": 49.6239854119,
                "lng": -123.8046796005
            },
            {
                "lat": 49.6198352539,
                "lng": -123.8041371759
            },
            {
                "lat": 49.6018024942,
                "lng": -123.7900687613
            },
            {
                "lat": 49.6017653373,
                "lng": -123.7895967329
            },
            {
                "lat": 49.6017534686,
                "lng": -123.7893448335
            },
            {
                "lat": 49.6017035967,
                "lng": -123.7880399231
            },
            {
                "lat": 49.6015696731,
                "lng": -123.7870555056
            },
            {
                "lat": 49.600662702,
                "lng": -123.7834245427
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 543,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 16-7",
            "LABEL": "16-7",
            "FISHERY_AREA_ID": 2176,
            "OBJECTID": 687,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 203227205.5637,
            "FEATURE_LENGTH_M": 79366.502,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.65644271970834,
            "lng": -123.68609365000276
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7995492608,
                "lng": -124.6375811702
            },
            {
                "lat": 49.8138890006,
                "lng": -124.6583323127
            },
            {
                "lat": 49.7360990785,
                "lng": -124.6361320524
            },
            {
                "lat": 49.7362180538,
                "lng": -124.6356205197
            },
            {
                "lat": 49.7361909893,
                "lng": -124.63489487
            },
            {
                "lat": 49.7361376836,
                "lng": -124.6321640188
            },
            {
                "lat": 49.736060699,
                "lng": -124.6281352786
            },
            {
                "lat": 49.7362516976,
                "lng": -124.6206292034
            },
            {
                "lat": 49.7460063196,
                "lng": -124.6151890449
            },
            {
                "lat": 49.7608257484,
                "lng": -124.6133123312
            },
            {
                "lat": 49.7741471339,
                "lng": -124.6144417786
            },
            {
                "lat": 49.7854000034,
                "lng": -124.6198814624
            },
            {
                "lat": 49.7938423174,
                "lng": -124.6266336714
            },
            {
                "lat": 49.7970358932,
                "lng": -124.629447672
            },
            {
                "lat": 49.7995492608,
                "lng": -124.6375811702
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 544,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 22,
            "MANAGEMENT_AREA_NAME": "Subarea 16-22",
            "LABEL": "16-22",
            "FISHERY_AREA_ID": 2191,
            "OBJECTID": 688,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 14807572.5983,
            "FEATURE_LENGTH_M": 19819.0601,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.765813542660005,
            "lng": -124.62933177043332
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.798698294,
                "lng": -126.917785967
            },
            {
                "lat": 49.7954104083,
                "lng": -126.9233399349
            },
            {
                "lat": 49.7925339062,
                "lng": -126.9254221684
            },
            {
                "lat": 49.7922860508,
                "lng": -126.9261479069
            },
            {
                "lat": 49.7921298052,
                "lng": -126.9267427139
            },
            {
                "lat": 49.7604940942,
                "lng": -126.9217526586
            },
            {
                "lat": 49.7598304491,
                "lng": -126.9210576688
            },
            {
                "lat": 49.7582087488,
                "lng": -126.918915181
            },
            {
                "lat": 49.756630385,
                "lng": -126.9147496811
            },
            {
                "lat": 49.7541154669,
                "lng": -126.9068528108
            },
            {
                "lat": 49.7523230921,
                "lng": -126.8985903057
            },
            {
                "lat": 49.7537568001,
                "lng": -126.8745347394
            },
            {
                "lat": 49.7573505981,
                "lng": -126.8522721285
            },
            {
                "lat": 49.7623787013,
                "lng": -126.8379061412
            },
            {
                "lat": 49.7648919683,
                "lng": -126.822822319
            },
            {
                "lat": 49.7742277265,
                "lng": -126.8145602406
            },
            {
                "lat": 49.7871554851,
                "lng": -126.8084561746
            },
            {
                "lat": 49.7900274968,
                "lng": -126.8005603497
            },
            {
                "lat": 49.784999429,
                "lng": -126.7847592754
            },
            {
                "lat": 49.7796138253,
                "lng": -126.7711108458
            },
            {
                "lat": 49.7857167961,
                "lng": -126.7535164051
            },
            {
                "lat": 49.7893105574,
                "lng": -126.738075151
            },
            {
                "lat": 49.7921835344,
                "lng": -126.7330471778
            },
            {
                "lat": 49.7982861547,
                "lng": -126.7305296208
            },
            {
                "lat": 49.8015174464,
                "lng": -126.7308875307
            },
            {
                "lat": 49.8036726497,
                "lng": -126.7366414815
            },
            {
                "lat": 49.8058282478,
                "lng": -126.7463371451
            },
            {
                "lat": 49.8083418961,
                "lng": -126.7675251694
            },
            {
                "lat": 49.8101380693,
                "lng": -126.789428178
            },
            {
                "lat": 49.8112148241,
                "lng": -126.8098989986
            },
            {
                "lat": 49.8069078727,
                "lng": -126.8321606045
            },
            {
                "lat": 49.8022381446,
                "lng": -126.8501136847
            },
            {
                "lat": 49.7972105283,
                "lng": -126.8792034601
            },
            {
                "lat": 49.7957724079,
                "lng": -126.9050599846
            },
            {
                "lat": 49.798698294,
                "lng": -126.917785967
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 545,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 25-14",
            "LABEL": "25-14",
            "FISHERY_AREA_ID": 2281,
            "OBJECTID": 689,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 52798956.5387,
            "FEATURE_LENGTH_M": 36014.9388,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.78497429013142,
            "lng": -126.83967285057713
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7481760827,
                "lng": -126.458731932
            },
            {
                "lat": 49.7480736258,
                "lng": -126.4560849103
            },
            {
                "lat": 49.7480737383,
                "lng": -126.4419784184
            },
            {
                "lat": 49.7464869389,
                "lng": -126.4348299542
            },
            {
                "lat": 49.7499349136,
                "lng": -126.431572344
            },
            {
                "lat": 49.7489784167,
                "lng": -126.4227530334
            },
            {
                "lat": 49.7508918273,
                "lng": -126.4152839161
            },
            {
                "lat": 49.752041341,
                "lng": -126.4085764668
            },
            {
                "lat": 49.7505077929,
                "lng": -126.402251828
            },
            {
                "lat": 49.7478258643,
                "lng": -126.3928677215
            },
            {
                "lat": 49.7476348369,
                "lng": -126.3865433652
            },
            {
                "lat": 49.7491677982,
                "lng": -126.3815615565
            },
            {
                "lat": 49.7535745419,
                "lng": -126.3807978965
            },
            {
                "lat": 49.7577895706,
                "lng": -126.3821327252
            },
            {
                "lat": 49.7618141771,
                "lng": -126.385971631
            },
            {
                "lat": 49.7673686104,
                "lng": -126.3924869178
            },
            {
                "lat": 49.7721599611,
                "lng": -126.3965067856
            },
            {
                "lat": 49.7757990952,
                "lng": -126.4032135836
            },
            {
                "lat": 49.7780994962,
                "lng": -126.4112628546
            },
            {
                "lat": 49.7790565711,
                "lng": -126.4179689543
            },
            {
                "lat": 49.7811660928,
                "lng": -126.4252473358
            },
            {
                "lat": 49.7865288792,
                "lng": -126.4304199462
            },
            {
                "lat": 49.7911266658,
                "lng": -126.4304197168
            },
            {
                "lat": 49.7945781047,
                "lng": -126.4323353508
            },
            {
                "lat": 49.7972604846,
                "lng": -126.4380801079
            },
            {
                "lat": 49.7985990366,
                "lng": -126.4459380887
            },
            {
                "lat": 49.7974509846,
                "lng": -126.456092685
            },
            {
                "lat": 49.7982172653,
                "lng": -126.4664389744
            },
            {
                "lat": 49.8041564802,
                "lng": -126.4829174012
            },
            {
                "lat": 49.8089482949,
                "lng": -126.4972911047
            },
            {
                "lat": 49.8091395503,
                "lng": -126.5057140449
            },
            {
                "lat": 49.8053059277,
                "lng": -126.5082099513
            },
            {
                "lat": 49.7940029832,
                "lng": -126.5030364069
            },
            {
                "lat": 49.7840390948,
                "lng": -126.4936449385
            },
            {
                "lat": 49.7719688734,
                "lng": -126.4859849289
            },
            {
                "lat": 49.7587466322,
                "lng": -126.4752584152
            },
            {
                "lat": 49.7503172044,
                "lng": -126.4633797215
            },
            {
                "lat": 49.7481760827,
                "lng": -126.458731932
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 546,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 25-5",
            "LABEL": "25-5",
            "FISHERY_AREA_ID": 2272,
            "OBJECTID": 690,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 33696017.4997,
            "FEATURE_LENGTH_M": 26422.2051,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.77139957467367,
            "lng": -126.4369083643605
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7970358932,
                "lng": -124.629447672
            },
            {
                "lat": 49.7938423174,
                "lng": -124.6266336714
            },
            {
                "lat": 49.7854000034,
                "lng": -124.6198814624
            },
            {
                "lat": 49.7741471339,
                "lng": -124.6144417786
            },
            {
                "lat": 49.7608257484,
                "lng": -124.6133123312
            },
            {
                "lat": 49.7460063196,
                "lng": -124.6151890449
            },
            {
                "lat": 49.7362516976,
                "lng": -124.6206292034
            },
            {
                "lat": 49.7274055276,
                "lng": -124.6187437423
            },
            {
                "lat": 49.7236368412,
                "lng": -124.6112062481
            },
            {
                "lat": 49.7238278831,
                "lng": -124.599562905
            },
            {
                "lat": 49.7197837001,
                "lng": -124.5838553494
            },
            {
                "lat": 49.7176439212,
                "lng": -124.5664914498
            },
            {
                "lat": 49.7140734176,
                "lng": -124.546500277
            },
            {
                "lat": 49.7059825384,
                "lng": -124.5279392866
            },
            {
                "lat": 49.6971781703,
                "lng": -124.5003354379
            },
            {
                "lat": 49.6914667431,
                "lng": -124.4820171162
            },
            {
                "lat": 49.6871871674,
                "lng": -124.4696429588
            },
            {
                "lat": 49.67195551,
                "lng": -124.4596487979
            },
            {
                "lat": 49.6579168768,
                "lng": -124.4434658256
            },
            {
                "lat": 49.6422114992,
                "lng": -124.4313363298
            },
            {
                "lat": 49.631252122,
                "lng": -124.4264301858
            },
            {
                "lat": 49.5872877528,
                "lng": -124.3384940284
            },
            {
                "lat": 49.5841562423,
                "lng": -124.3173602457
            },
            {
                "lat": 49.5734483713,
                "lng": -124.2945171463
            },
            {
                "lat": 49.5634540726,
                "lng": -124.2759556743
            },
            {
                "lat": 49.5567894154,
                "lng": -124.2597738266
            },
            {
                "lat": 49.5532223636,
                "lng": -124.2457358691
            },
            {
                "lat": 49.5463223856,
                "lng": -124.2328881383
            },
            {
                "lat": 49.5375178221,
                "lng": -124.2169426054
            },
            {
                "lat": 49.5346598399,
                "lng": -124.2017137249
            },
            {
                "lat": 49.5256192636,
                "lng": -124.1872026837
            },
            {
                "lat": 49.5125316995,
                "lng": -124.1698307667
            },
            {
                "lat": 49.5016712895,
                "lng": -124.1544635078
            },
            {
                "lat": 49.4977189385,
                "lng": -124.1492927415
            },
            {
                "lat": 49.4926189099,
                "lng": -124.1412194033
            },
            {
                "lat": 49.4929472869,
                "lng": -124.1402436752
            },
            {
                "lat": 49.4942242923,
                "lng": -124.1364973414
            },
            {
                "lat": 49.4956514987,
                "lng": -124.1323013873
            },
            {
                "lat": 49.4960636204,
                "lng": -124.130706298
            },
            {
                "lat": 49.4978179684,
                "lng": -124.1308974621
            },
            {
                "lat": 49.5769886069,
                "lng": -124.2160716862
            },
            {
                "lat": 49.5799106304,
                "lng": -124.2221676888
            },
            {
                "lat": 49.5913309526,
                "lng": -124.2481009746
            },
            {
                "lat": 49.602752551,
                "lng": -124.271659152
            },
            {
                "lat": 49.6177444188,
                "lng": -124.2961654168
            },
            {
                "lat": 49.6391566728,
                "lng": -124.3161550322
            },
            {
                "lat": 49.6712801676,
                "lng": -124.3456570335
            },
            {
                "lat": 49.7028964542,
                "lng": -124.3684691716
            },
            {
                "lat": 49.7057344813,
                "lng": -124.3730173884
            },
            {
                "lat": 49.7102770113,
                "lng": -124.3923103857
            },
            {
                "lat": 49.7153854271,
                "lng": -124.4093327302
            },
            {
                "lat": 49.7170863338,
                "lng": -124.4320372542
            },
            {
                "lat": 49.7204889376,
                "lng": -124.4592740209
            },
            {
                "lat": 49.7244645424,
                "lng": -124.4927602528
            },
            {
                "lat": 49.7352482909,
                "lng": -124.523399737
            },
            {
                "lat": 49.7460292136,
                "lng": -124.550644002
            },
            {
                "lat": 49.7585149427,
                "lng": -124.5835567416
            },
            {
                "lat": 49.7795104729,
                "lng": -124.595474587
            },
            {
                "lat": 49.7873045008,
                "lng": -124.5998077537
            },
            {
                "lat": 49.7907374332,
                "lng": -124.6102673396
            },
            {
                "lat": 49.7897912929,
                "lng": -124.6129223119
            },
            {
                "lat": 49.7899586526,
                "lng": -124.6162344072
            },
            {
                "lat": 49.7916759958,
                "lng": -124.6218328831
            },
            {
                "lat": 49.7933883688,
                "lng": -124.6238853769
            },
            {
                "lat": 49.7951320531,
                "lng": -124.6236490172
            },
            {
                "lat": 49.7970358932,
                "lng": -124.629447672
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 547,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 691,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 191012500.5025,
            "FEATURE_LENGTH_M": 106037.4534,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.66331179338031,
            "lng": -124.41662193359392
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7750252284,
                "lng": -123.9416349348
            },
            {
                "lat": 49.7750252553,
                "lng": -123.9440544419
            },
            {
                "lat": 49.7754672691,
                "lng": -123.9460289139
            },
            {
                "lat": 49.7757301977,
                "lng": -123.9468242295
            },
            {
                "lat": 49.7667652497,
                "lng": -123.9710464861
            },
            {
                "lat": 49.7607033497,
                "lng": -123.9710092291
            },
            {
                "lat": 49.7603762982,
                "lng": -123.9640729357
            },
            {
                "lat": 49.7602125219,
                "lng": -123.9594267062
            },
            {
                "lat": 49.7602124913,
                "lng": -123.9578009308
            },
            {
                "lat": 49.7518953131,
                "lng": -123.9409864076
            },
            {
                "lat": 49.7470586884,
                "lng": -123.9376214791
            },
            {
                "lat": 49.7437290761,
                "lng": -123.9319155092
            },
            {
                "lat": 49.7425380222,
                "lng": -123.9247747236
            },
            {
                "lat": 49.7426066163,
                "lng": -123.9222029059
            },
            {
                "lat": 49.7276346295,
                "lng": -123.8919218993
            },
            {
                "lat": 49.725567265,
                "lng": -123.8907233954
            },
            {
                "lat": 49.7252771694,
                "lng": -123.8871529232
            },
            {
                "lat": 49.7252770778,
                "lng": -123.8796697543
            },
            {
                "lat": 49.725277175,
                "lng": -123.8674705316
            },
            {
                "lat": 49.7274518043,
                "lng": -123.8621226789
            },
            {
                "lat": 49.7340434209,
                "lng": -123.8666678586
            },
            {
                "lat": 49.7372398971,
                "lng": -123.8739003894
            },
            {
                "lat": 49.7485081015,
                "lng": -123.884827303
            },
            {
                "lat": 49.7557374318,
                "lng": -123.8957591353
            },
            {
                "lat": 49.7614556037,
                "lng": -123.9140926729
            },
            {
                "lat": 49.7666699635,
                "lng": -123.9268724574
            },
            {
                "lat": 49.7750252284,
                "lng": -123.9416349348
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 548,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 16-9",
            "LABEL": "16-9",
            "FISHERY_AREA_ID": 2178,
            "OBJECTID": 692,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 16673776.8349,
            "FEATURE_LENGTH_M": 20544.5495,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.750833716492586,
            "lng": -123.92008206546295
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7702719168,
                "lng": -123.9943390281
            },
            {
                "lat": 49.7729679005,
                "lng": -123.9962239385
            },
            {
                "lat": 49.7753938863,
                "lng": -124.0021596719
            },
            {
                "lat": 49.7762031162,
                "lng": -124.0142826968
            },
            {
                "lat": 49.7767409824,
                "lng": -124.0250622154
            },
            {
                "lat": 49.7745858498,
                "lng": -124.0417784039
            },
            {
                "lat": 49.7705417884,
                "lng": -124.0576774044
            },
            {
                "lat": 49.7697340391,
                "lng": -124.0733108872
            },
            {
                "lat": 49.7667701854,
                "lng": -124.0805898383
            },
            {
                "lat": 49.7619167235,
                "lng": -124.0827483392
            },
            {
                "lat": 49.7586405262,
                "lng": -124.082220775
            },
            {
                "lat": 49.7530212667,
                "lng": -124.0816646124
            },
            {
                "lat": 49.7473601047,
                "lng": -124.0830148356
            },
            {
                "lat": 49.7433206143,
                "lng": -124.0892104767
            },
            {
                "lat": 49.7419699795,
                "lng": -124.0978393826
            },
            {
                "lat": 49.7441293625,
                "lng": -124.1078110233
            },
            {
                "lat": 49.7503284756,
                "lng": -124.1177826427
            },
            {
                "lat": 49.755283534,
                "lng": -124.1215450973
            },
            {
                "lat": 49.7532038426,
                "lng": -124.1458657885
            },
            {
                "lat": 49.7526322548,
                "lng": -124.1486973227
            },
            {
                "lat": 49.7522237405,
                "lng": -124.1507034563
            },
            {
                "lat": 49.7515639082,
                "lng": -124.1500240549
            },
            {
                "lat": 49.7504427089,
                "lng": -124.1492609399
            },
            {
                "lat": 49.7480805201,
                "lng": -124.148497633
            },
            {
                "lat": 49.7454680616,
                "lng": -124.1483619529
            },
            {
                "lat": 49.7399141164,
                "lng": -124.1501762731
            },
            {
                "lat": 49.7292060665,
                "lng": -124.1556478582
            },
            {
                "lat": 49.7215915615,
                "lng": -124.1632621236
            },
            {
                "lat": 49.7142176953,
                "lng": -124.1632623959
            },
            {
                "lat": 49.7037472979,
                "lng": -124.1611251527
            },
            {
                "lat": 49.6951827701,
                "lng": -124.1725454951
            },
            {
                "lat": 49.6856646215,
                "lng": -124.1794437281
            },
            {
                "lat": 49.6799545026,
                "lng": -124.1842040495
            },
            {
                "lat": 49.680195498,
                "lng": -124.1743762757
            },
            {
                "lat": 49.6744839926,
                "lng": -124.1612931795
            },
            {
                "lat": 49.6718669495,
                "lng": -124.142730959
            },
            {
                "lat": 49.6761509771,
                "lng": -124.1275016267
            },
            {
                "lat": 49.6742478322,
                "lng": -124.1163176985
            },
            {
                "lat": 49.667110354,
                "lng": -124.1037054877
            },
            {
                "lat": 49.6575887092,
                "lng": -124.0956191224
            },
            {
                "lat": 49.6501962699,
                "lng": -124.0933687653
            },
            {
                "lat": 49.6520648478,
                "lng": -124.091430563
            },
            {
                "lat": 49.6608424037,
                "lng": -124.0900188181
            },
            {
                "lat": 49.6724317181,
                "lng": -124.0902940294
            },
            {
                "lat": 49.6869895352,
                "lng": -124.0897531304
            },
            {
                "lat": 49.7039686352,
                "lng": -124.0884024097
            },
            {
                "lat": 49.7163655601,
                "lng": -124.0840920681
            },
            {
                "lat": 49.7231058928,
                "lng": -124.0757374215
            },
            {
                "lat": 49.7422410984,
                "lng": -124.0638822179
            },
            {
                "lat": 49.7481690618,
                "lng": -124.0592956968
            },
            {
                "lat": 49.7557184866,
                "lng": -124.0436628036
            },
            {
                "lat": 49.7586824105,
                "lng": -124.0326164807
            },
            {
                "lat": 49.7643428566,
                "lng": -124.027489129
            },
            {
                "lat": 49.7694626185,
                "lng": -124.0202180428
            },
            {
                "lat": 49.7716172972,
                "lng": -124.0118572082
            },
            {
                "lat": 49.7716172887,
                "lng": -124.0010746391
            },
            {
                "lat": 49.7702719168,
                "lng": -123.9943390281
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 549,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 693,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 61609892.2858,
            "FEATURE_LENGTH_M": 49135.2792,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.730736949664916,
            "lng": -124.0947266016649
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7667652497,
                "lng": -123.9710464861
            },
            {
                "lat": 49.7662693345,
                "lng": -123.9928967609
            },
            {
                "lat": 49.7702719168,
                "lng": -123.9943390281
            },
            {
                "lat": 49.7716172887,
                "lng": -124.0010746391
            },
            {
                "lat": 49.7716172972,
                "lng": -124.0118572082
            },
            {
                "lat": 49.7694626185,
                "lng": -124.0202180428
            },
            {
                "lat": 49.7643428566,
                "lng": -124.027489129
            },
            {
                "lat": 49.7586824105,
                "lng": -124.0326164807
            },
            {
                "lat": 49.7557184866,
                "lng": -124.0436628036
            },
            {
                "lat": 49.7481690618,
                "lng": -124.0592956968
            },
            {
                "lat": 49.7422410984,
                "lng": -124.0638822179
            },
            {
                "lat": 49.7231058928,
                "lng": -124.0757374215
            },
            {
                "lat": 49.7163655601,
                "lng": -124.0840920681
            },
            {
                "lat": 49.7039686352,
                "lng": -124.0884024097
            },
            {
                "lat": 49.6869895352,
                "lng": -124.0897531304
            },
            {
                "lat": 49.6724317181,
                "lng": -124.0902940294
            },
            {
                "lat": 49.6608424037,
                "lng": -124.0900188181
            },
            {
                "lat": 49.6520648478,
                "lng": -124.091430563
            },
            {
                "lat": 49.6501962699,
                "lng": -124.0933687653
            },
            {
                "lat": 49.6477237194,
                "lng": -124.09339857
            },
            {
                "lat": 49.6454245442,
                "lng": -124.0935665606
            },
            {
                "lat": 49.6511415131,
                "lng": -124.0723875635
            },
            {
                "lat": 49.6530346325,
                "lng": -124.0710134501
            },
            {
                "lat": 49.6557423256,
                "lng": -124.0694959714
            },
            {
                "lat": 49.6681104267,
                "lng": -124.0585163754
            },
            {
                "lat": 49.6817134454,
                "lng": -124.0539389375
            },
            {
                "lat": 49.6995588243,
                "lng": -124.0437014531
            },
            {
                "lat": 49.7226402748,
                "lng": -124.0225300534
            },
            {
                "lat": 49.7350116574,
                "lng": -124.0125358618
            },
            {
                "lat": 49.7421528409,
                "lng": -124.0008768777
            },
            {
                "lat": 49.744414495,
                "lng": -123.9907999814
            },
            {
                "lat": 49.7592318819,
                "lng": -123.9776535797
            },
            {
                "lat": 49.7608142652,
                "lng": -123.9740147221
            },
            {
                "lat": 49.7607033497,
                "lng": -123.9710092291
            },
            {
                "lat": 49.7667652497,
                "lng": -123.9710464861
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 550,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 16-10",
            "LABEL": "16-10",
            "FISHERY_AREA_ID": 2179,
            "OBJECTID": 694,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 45207216.5996,
            "FEATURE_LENGTH_M": 36736.4312,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.71843731222571,
            "lng": -124.03994175347427
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.763283145,
                "lng": -127.270797449
            },
            {
                "lat": 49.5833319558,
                "lng": -127.5018690701
            },
            {
                "lat": 49.5833320614,
                "lng": -126.9260020769
            },
            {
                "lat": 49.763283145,
                "lng": -127.270797449
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 551,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 125,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 125-4",
            "LABEL": "125-4",
            "FISHERY_AREA_ID": 2332,
            "OBJECTID": 695,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 415932328.4152,
            "FEATURE_LENGTH_M": 99655.0078,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.6733075768,
            "lng": -127.24236651125
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7522237405,
                "lng": -124.1507034563
            },
            {
                "lat": 49.7523613669,
                "lng": -124.1512599395
            },
            {
                "lat": 49.7526661795,
                "lng": -124.1521600954
            },
            {
                "lat": 49.752666058,
                "lng": -124.1564100425
            },
            {
                "lat": 49.7530715648,
                "lng": -124.1574484369
            },
            {
                "lat": 49.7532807213,
                "lng": -124.1581807508
            },
            {
                "lat": 49.7529641585,
                "lng": -124.1594476308
            },
            {
                "lat": 49.7521214696,
                "lng": -124.1609263939
            },
            {
                "lat": 49.7512772432,
                "lng": -124.163985395
            },
            {
                "lat": 49.7495884444,
                "lng": -124.1691594709
            },
            {
                "lat": 49.7471619797,
                "lng": -124.1771775137
            },
            {
                "lat": 49.7447363006,
                "lng": -124.1856161012
            },
            {
                "lat": 49.7434691846,
                "lng": -124.1915281338
            },
            {
                "lat": 49.7417791887,
                "lng": -124.200387286
            },
            {
                "lat": 49.7383007106,
                "lng": -124.2070386703
            },
            {
                "lat": 49.7342877485,
                "lng": -124.2142109376
            },
            {
                "lat": 49.7304912248,
                "lng": -124.2206505558
            },
            {
                "lat": 49.7271152946,
                "lng": -124.2226554842
            },
            {
                "lat": 49.7242657913,
                "lng": -124.2232199801
            },
            {
                "lat": 49.6709439302,
                "lng": -124.2014686697
            },
            {
                "lat": 49.6754339158,
                "lng": -124.1987612035
            },
            {
                "lat": 49.675906692,
                "lng": -124.1941985718
            },
            {
                "lat": 49.6799545026,
                "lng": -124.1842040495
            },
            {
                "lat": 49.6856646215,
                "lng": -124.1794437281
            },
            {
                "lat": 49.6951827701,
                "lng": -124.1725454951
            },
            {
                "lat": 49.7037472979,
                "lng": -124.1611251527
            },
            {
                "lat": 49.7142176953,
                "lng": -124.1632623959
            },
            {
                "lat": 49.7215915615,
                "lng": -124.1632621236
            },
            {
                "lat": 49.7292060665,
                "lng": -124.1556478582
            },
            {
                "lat": 49.7399141164,
                "lng": -124.1501762731
            },
            {
                "lat": 49.7454680616,
                "lng": -124.1483619529
            },
            {
                "lat": 49.7480805201,
                "lng": -124.148497633
            },
            {
                "lat": 49.7504427089,
                "lng": -124.1492609399
            },
            {
                "lat": 49.7515639082,
                "lng": -124.1500240549
            },
            {
                "lat": 49.7522237405,
                "lng": -124.1507034563
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 552,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 16,
            "MANAGEMENT_AREA_NAME": "Subarea 16-16",
            "LABEL": "16-16",
            "FISHERY_AREA_ID": 2185,
            "OBJECTID": 696,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 27808382.9417,
            "FEATURE_LENGTH_M": 23207.2862,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.73123915654858,
            "lng": -124.17408885236857
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7464869389,
                "lng": -126.4348299542
            },
            {
                "lat": 49.7480737383,
                "lng": -126.4419784184
            },
            {
                "lat": 49.7480736258,
                "lng": -126.4560849103
            },
            {
                "lat": 49.7481760827,
                "lng": -126.458731932
            },
            {
                "lat": 49.7478441476,
                "lng": -126.4590684657
            },
            {
                "lat": 49.7463649422,
                "lng": -126.4602049672
            },
            {
                "lat": 49.7380480542,
                "lng": -126.4802630343
            },
            {
                "lat": 49.738654354,
                "lng": -126.4806975742
            },
            {
                "lat": 49.7398419497,
                "lng": -126.4848022659
            },
            {
                "lat": 49.7402074085,
                "lng": -126.4908218953
            },
            {
                "lat": 49.7392958328,
                "lng": -126.4971164437
            },
            {
                "lat": 49.7404821854,
                "lng": -126.5040431421
            },
            {
                "lat": 49.7468641895,
                "lng": -126.5097883004
            },
            {
                "lat": 49.7514228308,
                "lng": -126.5082401167
            },
            {
                "lat": 49.7582624438,
                "lng": -126.505233955
            },
            {
                "lat": 49.7622753006,
                "lng": -126.5052339197
            },
            {
                "lat": 49.7630040534,
                "lng": -126.5068741237
            },
            {
                "lat": 49.7621843029,
                "lng": -126.5089724672
            },
            {
                "lat": 49.7577175568,
                "lng": -126.5179977311
            },
            {
                "lat": 49.7546164797,
                "lng": -126.5216444364
            },
            {
                "lat": 49.7481424759,
                "lng": -126.5235588453
            },
            {
                "lat": 49.7415730195,
                "lng": -126.5251994164
            },
            {
                "lat": 49.735920194,
                "lng": -126.5226515245
            },
            {
                "lat": 49.7275309694,
                "lng": -126.5142601753
            },
            {
                "lat": 49.7249872207,
                "lng": -126.5117724471
            },
            {
                "lat": 49.699927926,
                "lng": -126.5722126865
            },
            {
                "lat": 49.6986843398,
                "lng": -126.5780950031
            },
            {
                "lat": 49.6970640415,
                "lng": -126.5865477638
            },
            {
                "lat": 49.6943281773,
                "lng": -126.5857317399
            },
            {
                "lat": 49.6824759,
                "lng": -126.5871200096
            },
            {
                "lat": 49.6859661785,
                "lng": -126.521484344
            },
            {
                "lat": 49.6858287372,
                "lng": -126.5212481451
            },
            {
                "lat": 49.6855656945,
                "lng": -126.5207672416
            },
            {
                "lat": 49.6852373423,
                "lng": -126.5201877769
            },
            {
                "lat": 49.6633151261,
                "lng": -126.4840846109
            },
            {
                "lat": 49.6624454243,
                "lng": -126.4814222643
            },
            {
                "lat": 49.6627540273,
                "lng": -126.4799647585
            },
            {
                "lat": 49.6461102774,
                "lng": -126.4715272886
            },
            {
                "lat": 49.6529728279,
                "lng": -126.4409400058
            },
            {
                "lat": 49.6612696918,
                "lng": -126.4410325396
            },
            {
                "lat": 49.7268673776,
                "lng": -126.4417491838
            },
            {
                "lat": 49.7464869389,
                "lng": -126.4348299542
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 553,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 25-4",
            "LABEL": "25-4",
            "FISHERY_AREA_ID": 2271,
            "OBJECTID": 697,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 66249806.4575,
            "FEATURE_LENGTH_M": 44638.6379,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.72127024584525,
            "lng": -126.49997656614998
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.5896800268,
                "lng": -123.2721476448
            },
            {
                "lat": 49.5873716276,
                "lng": -123.2693171864
            },
            {
                "lat": 49.5797884556,
                "lng": -123.2277221894
            },
            {
                "lat": 49.5775064918,
                "lng": -123.2191393897
            },
            {
                "lat": 49.605739724,
                "lng": -123.2028662008
            },
            {
                "lat": 49.622348484,
                "lng": -123.1955573532
            },
            {
                "lat": 49.6392892973,
                "lng": -123.2005383972
            },
            {
                "lat": 49.6472625492,
                "lng": -123.1955563631
            },
            {
                "lat": 49.6555677873,
                "lng": -123.1686468304
            },
            {
                "lat": 49.6784865215,
                "lng": -123.1444013197
            },
            {
                "lat": 49.7014052943,
                "lng": -123.1347660624
            },
            {
                "lat": 49.7133634961,
                "lng": -123.1384206988
            },
            {
                "lat": 49.71967295,
                "lng": -123.1547003982
            },
            {
                "lat": 49.7233269755,
                "lng": -123.1726378935
            },
            {
                "lat": 49.7136951613,
                "lng": -123.1889114796
            },
            {
                "lat": 49.6950907239,
                "lng": -123.2138218202
            },
            {
                "lat": 49.6774865062,
                "lng": -123.2500309559
            },
            {
                "lat": 49.6641999616,
                "lng": -123.2659760512
            },
            {
                "lat": 49.6316490128,
                "lng": -123.2702941056
            },
            {
                "lat": 49.6117209909,
                "lng": -123.2659762804
            },
            {
                "lat": 49.5934486416,
                "lng": -123.2712862212
            },
            {
                "lat": 49.5896800268,
                "lng": -123.2721476448
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 554,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 28,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 28-5",
            "LABEL": "28-5",
            "FISHERY_AREA_ID": 2516,
            "OBJECTID": 698,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 83257662.9204,
            "FEATURE_LENGTH_M": 42064.18,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.646262759368184,
            "lng": -123.21340284029547
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.7242657913,
                "lng": -124.2232199801
            },
            {
                "lat": 49.740616015,
                "lng": -124.2687763299
            },
            {
                "lat": 49.7074052554,
                "lng": -124.3563836461
            },
            {
                "lat": 49.7078475035,
                "lng": -124.3583068865
            },
            {
                "lat": 49.7071489679,
                "lng": -124.3612672625
            },
            {
                "lat": 49.7028964542,
                "lng": -124.3684691716
            },
            {
                "lat": 49.6712801676,
                "lng": -124.3456570335
            },
            {
                "lat": 49.6391566728,
                "lng": -124.3161550322
            },
            {
                "lat": 49.6177444188,
                "lng": -124.2961654168
            },
            {
                "lat": 49.602752551,
                "lng": -124.271659152
            },
            {
                "lat": 49.5913309526,
                "lng": -124.2481009746
            },
            {
                "lat": 49.5799106304,
                "lng": -124.2221676888
            },
            {
                "lat": 49.5769886069,
                "lng": -124.2160716862
            },
            {
                "lat": 49.4978179684,
                "lng": -124.1308974621
            },
            {
                "lat": 49.4960636204,
                "lng": -124.130706298
            },
            {
                "lat": 49.4936327864,
                "lng": -124.1281120889
            },
            {
                "lat": 49.4926830358,
                "lng": -124.1271518759
            },
            {
                "lat": 49.4922720351,
                "lng": -124.1249313515
            },
            {
                "lat": 49.6034426535,
                "lng": -124.0588607272
            },
            {
                "lat": 49.6709439302,
                "lng": -124.2014686697
            },
            {
                "lat": 49.7242657913,
                "lng": -124.2232199801
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 555,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 16,
            "MANAGEMENT_SUBAREA": 18,
            "MANAGEMENT_AREA_NAME": "Subarea 16-18",
            "LABEL": "16-18",
            "FISHERY_AREA_ID": 2187,
            "OBJECTID": 699,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 250345342.3099,
            "FEATURE_LENGTH_M": 74044.5628,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.62097456230953,
            "lng": -124.23703565305712
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.6852373423,
                "lng": -126.5201877769
            },
            {
                "lat": 49.6855656945,
                "lng": -126.5207672416
            },
            {
                "lat": 49.6858287372,
                "lng": -126.5212481451
            },
            {
                "lat": 49.6859661785,
                "lng": -126.521484344
            },
            {
                "lat": 49.6824759,
                "lng": -126.5871200096
            },
            {
                "lat": 49.6825026496,
                "lng": -126.5873722766
            },
            {
                "lat": 49.682536615,
                "lng": -126.5877458546
            },
            {
                "lat": 49.6827390204,
                "lng": -126.588523283
            },
            {
                "lat": 49.6832500594,
                "lng": -126.5900267337
            },
            {
                "lat": 49.6846505793,
                "lng": -126.594086208
            },
            {
                "lat": 49.6853676506,
                "lng": -126.5986547873
            },
            {
                "lat": 49.6858102376,
                "lng": -126.6009757284
            },
            {
                "lat": 49.6861185451,
                "lng": -126.602104448
            },
            {
                "lat": 49.6873783313,
                "lng": -126.6034318331
            },
            {
                "lat": 49.6884386311,
                "lng": -126.6039808556
            },
            {
                "lat": 49.6909603713,
                "lng": -126.6045612498
            },
            {
                "lat": 49.69375648,
                "lng": -126.6046292083
            },
            {
                "lat": 49.6960523603,
                "lng": -126.6053851277
            },
            {
                "lat": 49.6981618773,
                "lng": -126.6061932321
            },
            {
                "lat": 49.6992223832,
                "lng": -126.6063457931
            },
            {
                "lat": 49.7002224318,
                "lng": -126.60720861
            },
            {
                "lat": 49.7016716783,
                "lng": -126.6078643602
            },
            {
                "lat": 49.7027205811,
                "lng": -126.6087348327
            },
            {
                "lat": 49.7038730426,
                "lng": -126.6097331911
            },
            {
                "lat": 49.7047310049,
                "lng": -126.6108168933
            },
            {
                "lat": 49.7061082607,
                "lng": -126.6114735824
            },
            {
                "lat": 49.70708023,
                "lng": -126.6121223191
            },
            {
                "lat": 49.7081187476,
                "lng": -126.6129833316
            },
            {
                "lat": 49.7089686129,
                "lng": -126.6138531306
            },
            {
                "lat": 49.709628595,
                "lng": -126.6145480281
            },
            {
                "lat": 49.7104879435,
                "lng": -126.6158753922
            },
            {
                "lat": 49.7114376906,
                "lng": -126.6168219916
            },
            {
                "lat": 49.712218802,
                "lng": -126.6175160057
            },
            {
                "lat": 49.7130163021,
                "lng": -126.6181946564
            },
            {
                "lat": 49.7139361658,
                "lng": -126.6190032118
            },
            {
                "lat": 49.715137307,
                "lng": -126.6199724005
            },
            {
                "lat": 49.7161945297,
                "lng": -126.6209414271
            },
            {
                "lat": 49.7169954482,
                "lng": -126.6215587351
            },
            {
                "lat": 49.7173576241,
                "lng": -126.6221233354
            },
            {
                "lat": 49.7177545413,
                "lng": -126.6226580842
            },
            {
                "lat": 49.7186054791,
                "lng": -126.6239623354
            },
            {
                "lat": 49.7190130522,
                "lng": -126.6245492598
            },
            {
                "lat": 49.7193027275,
                "lng": -126.6248625126
            },
            {
                "lat": 49.7196730031,
                "lng": -126.6249914598
            },
            {
                "lat": 49.7200431833,
                "lng": -126.6250380874
            },
            {
                "lat": 49.7204435484,
                "lng": -126.6248711025
            },
            {
                "lat": 49.72100479,
                "lng": -126.624831949
            },
            {
                "lat": 49.7215379372,
                "lng": -126.6248392026
            },
            {
                "lat": 49.7216530782,
                "lng": -126.6248555064
            },
            {
                "lat": 49.7218128418,
                "lng": -126.6247866278
            },
            {
                "lat": 49.7219082584,
                "lng": -126.6247636641
            },
            {
                "lat": 49.7243425332,
                "lng": -126.631805355
            },
            {
                "lat": 49.7445064335,
                "lng": -126.6372757538
            },
            {
                "lat": 49.7464867846,
                "lng": -126.6372763668
            },
            {
                "lat": 49.7485615557,
                "lng": -126.6391067969
            },
            {
                "lat": 49.7489886,
                "lng": -126.6395193764
            },
            {
                "lat": 49.7486418136,
                "lng": -126.6453399107
            },
            {
                "lat": 49.7463261651,
                "lng": -126.6477582464
            },
            {
                "lat": 49.7457351543,
                "lng": -126.6480793452
            },
            {
                "lat": 49.73937622,
                "lng": -126.6493374806
            },
            {
                "lat": 49.7320292787,
                "lng": -126.6513448271
            },
            {
                "lat": 49.7225111602,
                "lng": -126.6546784693
            },
            {
                "lat": 49.7114902071,
                "lng": -126.6523435202
            },
            {
                "lat": 49.6969604246,
                "lng": -126.6448288522
            },
            {
                "lat": 49.6846052328,
                "lng": -126.6354746659
            },
            {
                "lat": 49.6744188447,
                "lng": -126.6279676205
            },
            {
                "lat": 49.6682394868,
                "lng": -126.6266248192
            },
            {
                "lat": 49.6633988859,
                "lng": -126.6296310004
            },
            {
                "lat": 49.6607248769,
                "lng": -126.6351464228
            },
            {
                "lat": 49.6565515697,
                "lng": -126.6368106672
            },
            {
                "lat": 49.6483685105,
                "lng": -126.6346436216
            },
            {
                "lat": 49.638183536,
                "lng": -126.6318054752
            },
            {
                "lat": 49.6321716933,
                "lng": -126.6293023457
            },
            {
                "lat": 49.6253241819,
                "lng": -126.6319731005
            },
            {
                "lat": 49.6215394444,
                "lng": -126.6334990369
            },
            {
                "lat": 49.6110575898,
                "lng": -126.6323400034
            },
            {
                "lat": 49.6080248742,
                "lng": -126.6271969576
            },
            {
                "lat": 49.5986477228,
                "lng": -126.6228253059
            },
            {
                "lat": 49.5897522633,
                "lng": -126.6186753076
            },
            {
                "lat": 49.5889093735,
                "lng": -126.6137772562
            },
            {
                "lat": 49.5804517665,
                "lng": -126.5647355713
            },
            {
                "lat": 49.5795671869,
                "lng": -126.5647117656
            },
            {
                "lat": 49.578250585,
                "lng": -126.5646213445
            },
            {
                "lat": 49.5780409054,
                "lng": -126.5641255628
            },
            {
                "lat": 49.576595209,
                "lng": -126.5627984125
            },
            {
                "lat": 49.5751492735,
                "lng": -126.5601498403
            },
            {
                "lat": 49.5745473324,
                "lng": -126.5534055432
            },
            {
                "lat": 49.5761141954,
                "lng": -126.5481028252
            },
            {
                "lat": 49.5810514956,
                "lng": -126.5418400042
            },
            {
                "lat": 49.583942025,
                "lng": -126.5269010667
            },
            {
                "lat": 49.5874367699,
                "lng": -126.5176241583
            },
            {
                "lat": 49.5952761675,
                "lng": -126.5217813133
            },
            {
                "lat": 49.5994869662,
                "lng": -126.5315475901
            },
            {
                "lat": 49.6023904286,
                "lng": -126.538276328
            },
            {
                "lat": 49.6026384635,
                "lng": -126.5382005881
            },
            {
                "lat": 49.6122508376,
                "lng": -126.5352399259
            },
            {
                "lat": 49.6163602916,
                "lng": -126.5316464383
            },
            {
                "lat": 49.6217537537,
                "lng": -126.5267640973
            },
            {
                "lat": 49.6256069528,
                "lng": -126.5247113615
            },
            {
                "lat": 49.627918023,
                "lng": -126.5218815658
            },
            {
                "lat": 49.6307409668,
                "lng": -126.5195691877
            },
            {
                "lat": 49.6343382319,
                "lng": -126.5180366758
            },
            {
                "lat": 49.6387027243,
                "lng": -126.5162357498
            },
            {
                "lat": 49.6417841432,
                "lng": -126.5103296848
            },
            {
                "lat": 49.6453778841,
                "lng": -126.5067372491
            },
            {
                "lat": 49.649745845,
                "lng": -126.5057065492
            },
            {
                "lat": 49.6548807487,
                "lng": -126.506218109
            },
            {
                "lat": 49.6571917249,
                "lng": -126.5157242377
            },
            {
                "lat": 49.6559101073,
                "lng": -126.5226508202
            },
            {
                "lat": 49.6527865008,
                "lng": -126.5252991217
            },
            {
                "lat": 49.6475176216,
                "lng": -126.5268177281
            },
            {
                "lat": 49.642700411,
                "lng": -126.5281595395
            },
            {
                "lat": 49.6370773469,
                "lng": -126.5309223061
            },
            {
                "lat": 49.6424326792,
                "lng": -126.5427098771
            },
            {
                "lat": 49.6459996268,
                "lng": -126.5440443471
            },
            {
                "lat": 49.6516911297,
                "lng": -126.5381316581
            },
            {
                "lat": 49.6558495578,
                "lng": -126.533805656
            },
            {
                "lat": 49.6617121991,
                "lng": -126.5277106821
            },
            {
                "lat": 49.6707272585,
                "lng": -126.5290527117
            },
            {
                "lat": 49.6755748835,
                "lng": -126.5267869278
            },
            {
                "lat": 49.6799240255,
                "lng": -126.5247651573
            },
            {
                "lat": 49.6852373423,
                "lng": -126.5201877769
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 556,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 25,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 25-6",
            "LABEL": "25-6",
            "FISHERY_AREA_ID": 2273,
            "OBJECTID": 700,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 98909465.6585,
            "FEATURE_LENGTH_M": 59931.4791,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.670764615471306,
            "lng": -126.58718224857293
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.2647742786,
                "lng": -123.257513774
            },
            {
                "lat": 49.2656054583,
                "lng": -123.2643350021
            },
            {
                "lat": 49.2573319446,
                "lng": -123.279640898
            },
            {
                "lat": 49.2571374236,
                "lng": -123.2791595719
            },
            {
                "lat": 49.2570757005,
                "lng": -123.2790670488
            },
            {
                "lat": 49.2568695699,
                "lng": -123.2786722496
            },
            {
                "lat": 49.256721871,
                "lng": -123.2783512832
            },
            {
                "lat": 49.2565764628,
                "lng": -123.2780765382
            },
            {
                "lat": 49.2563899969,
                "lng": -123.2776805938
            },
            {
                "lat": 49.2562828963,
                "lng": -123.2774963124
            },
            {
                "lat": 49.2560198192,
                "lng": -123.2770087279
            },
            {
                "lat": 49.2558521156,
                "lng": -123.2766872296
            },
            {
                "lat": 49.2557521467,
                "lng": -123.2764733487
            },
            {
                "lat": 49.2554436118,
                "lng": -123.2759249233
            },
            {
                "lat": 49.255184064,
                "lng": -123.275444016
            },
            {
                "lat": 49.2548370632,
                "lng": -123.2748103087
            },
            {
                "lat": 49.2547031022,
                "lng": -123.2744988614
            },
            {
                "lat": 49.2545543113,
                "lng": -123.2742243386
            },
            {
                "lat": 49.2542957776,
                "lng": -123.2737279393
            },
            {
                "lat": 49.2540279149,
                "lng": -123.2731857422
            },
            {
                "lat": 49.2538790094,
                "lng": -123.2728957837
            },
            {
                "lat": 49.2537999259,
                "lng": -123.2727733945
            },
            {
                "lat": 49.2536006839,
                "lng": -123.2723627944
            },
            {
                "lat": 49.253349162,
                "lng": -123.2718883201
            },
            {
                "lat": 49.2530977464,
                "lng": -123.2713846607
            },
            {
                "lat": 49.2529558501,
                "lng": -123.2711715521
            },
            {
                "lat": 49.2526890889,
                "lng": -123.2707065702
            },
            {
                "lat": 49.2523539583,
                "lng": -123.2701872487
            },
            {
                "lat": 49.252136289,
                "lng": -123.2698052226
            },
            {
                "lat": 49.2517242396,
                "lng": -123.2691273589
            },
            {
                "lat": 49.2513153929,
                "lng": -123.2685317166
            },
            {
                "lat": 49.2508045266,
                "lng": -123.267708632
            },
            {
                "lat": 49.2502631659,
                "lng": -123.26683758
            },
            {
                "lat": 49.2500268691,
                "lng": -123.2664652661
            },
            {
                "lat": 49.2497521122,
                "lng": -123.2660076766
            },
            {
                "lat": 49.249363084,
                "lng": -123.2653731539
            },
            {
                "lat": 49.2490350023,
                "lng": -123.2648552076
            },
            {
                "lat": 49.248581078,
                "lng": -123.2640923568
            },
            {
                "lat": 49.2483142818,
                "lng": -123.263672089
            },
            {
                "lat": 49.2479021479,
                "lng": -123.2629926217
            },
            {
                "lat": 49.2475891647,
                "lng": -123.2624823997
            },
            {
                "lat": 49.2473597206,
                "lng": -123.262070245
            },
            {
                "lat": 49.2469639833,
                "lng": -123.2614602131
            },
            {
                "lat": 49.2467264555,
                "lng": -123.2610845916
            },
            {
                "lat": 49.2463304781,
                "lng": -123.2604213802
            },
            {
                "lat": 49.2457509047,
                "lng": -123.2594446147
            },
            {
                "lat": 49.2453272135,
                "lng": -123.2587435899
            },
            {
                "lat": 49.2448614439,
                "lng": -123.25800387
            },
            {
                "lat": 49.244022211,
                "lng": -123.2565687923
            },
            {
                "lat": 49.2430301248,
                "lng": -123.2548905038
            },
            {
                "lat": 49.2424354926,
                "lng": -123.2539439418
            },
            {
                "lat": 49.2416729616,
                "lng": -123.2527155719
            },
            {
                "lat": 49.2409208469,
                "lng": -123.2514883408
            },
            {
                "lat": 49.2400813025,
                "lng": -123.2500226235
            },
            {
                "lat": 49.2394179572,
                "lng": -123.248900052
            },
            {
                "lat": 49.2390976603,
                "lng": -123.2484814058
            },
            {
                "lat": 49.2381558628,
                "lng": -123.2469703773
            },
            {
                "lat": 49.2376099892,
                "lng": -123.2460623064
            },
            {
                "lat": 49.2368125639,
                "lng": -123.2447504533
            },
            {
                "lat": 49.2362745853,
                "lng": -123.2437887659
            },
            {
                "lat": 49.2356414648,
                "lng": -123.2424861046
            },
            {
                "lat": 49.2349056842,
                "lng": -123.2418142193
            },
            {
                "lat": 49.2340466118,
                "lng": -123.2407464186
            },
            {
                "lat": 49.2337263535,
                "lng": -123.2399675121
            },
            {
                "lat": 49.2333259994,
                "lng": -123.2391281716
            },
            {
                "lat": 49.2329556369,
                "lng": -123.2385478894
            },
            {
                "lat": 49.2323911899,
                "lng": -123.2376102326
            },
            {
                "lat": 49.2319940038,
                "lng": -123.2369389092
            },
            {
                "lat": 49.2311141106,
                "lng": -123.2353903312
            },
            {
                "lat": 49.2304424016,
                "lng": -123.23429956
            },
            {
                "lat": 49.2296786637,
                "lng": -123.2334510925
            },
            {
                "lat": 49.2282945243,
                "lng": -123.2317577046
            },
            {
                "lat": 49.2278019263,
                "lng": -123.230529398
            },
            {
                "lat": 49.2261733284,
                "lng": -123.2272345296
            },
            {
                "lat": 49.2257041551,
                "lng": -123.2255622686
            },
            {
                "lat": 49.2249752986,
                "lng": -123.2231746792
            },
            {
                "lat": 49.224048839,
                "lng": -123.2206034029
            },
            {
                "lat": 49.2230907683,
                "lng": -123.218751253
            },
            {
                "lat": 49.2227636373,
                "lng": -123.2164153009
            },
            {
                "lat": 49.2220418162,
                "lng": -123.2141339386
            },
            {
                "lat": 49.2190439416,
                "lng": -123.211692516
            },
            {
                "lat": 49.2187728161,
                "lng": -123.2072210994
            },
            {
                "lat": 49.2184106276,
                "lng": -123.202393244
            },
            {
                "lat": 49.2175446978,
                "lng": -123.2001570078
            },
            {
                "lat": 49.2157216506,
                "lng": -123.2010731387
            },
            {
                "lat": 49.214397867,
                "lng": -123.2016606523
            },
            {
                "lat": 49.212528358,
                "lng": -123.2023924964
            },
            {
                "lat": 49.211070821,
                "lng": -123.2026209325
            },
            {
                "lat": 49.2091557562,
                "lng": -123.2027585618
            },
            {
                "lat": 49.2071079672,
                "lng": -123.2025765958
            },
            {
                "lat": 49.2041207139,
                "lng": -123.1984022298
            },
            {
                "lat": 49.2038798375,
                "lng": -123.1774669163
            },
            {
                "lat": 49.2026899187,
                "lng": -123.1608135475
            },
            {
                "lat": 49.1995969549,
                "lng": -123.1489113333
            },
            {
                "lat": 49.197692374,
                "lng": -123.1412963369
            },
            {
                "lat": 49.1973383472,
                "lng": -123.1383207257
            },
            {
                "lat": 49.1979252274,
                "lng": -123.1352991441
            },
            {
                "lat": 49.1961894699,
                "lng": -123.1330347076
            },
            {
                "lat": 49.1957434293,
                "lng": -123.1329027628
            },
            {
                "lat": 49.193153355,
                "lng": -123.1301357408
            },
            {
                "lat": 49.1910589855,
                "lng": -123.1236041121
            },
            {
                "lat": 49.1918377621,
                "lng": -123.0857320914
            },
            {
                "lat": 49.1930775801,
                "lng": -123.0480121464
            },
            {
                "lat": 49.1913685505,
                "lng": -123.0248873615
            },
            {
                "lat": 49.190792437,
                "lng": -123.0206153789
            },
            {
                "lat": 49.1797292913,
                "lng": -123.0056376171
            },
            {
                "lat": 49.1746059384,
                "lng": -122.9839093712
            },
            {
                "lat": 49.1744267161,
                "lng": -122.9698038735
            },
            {
                "lat": 49.1827549557,
                "lng": -122.9498144114
            },
            {
                "lat": 49.1905142306,
                "lng": -122.9395895557
            },
            {
                "lat": 49.1916315921,
                "lng": -122.9355463107
            },
            {
                "lat": 49.1956411283,
                "lng": -122.9243167283
            },
            {
                "lat": 49.19632336,
                "lng": -122.9239045659
            },
            {
                "lat": 49.1982917647,
                "lng": -122.9227366466
            },
            {
                "lat": 49.199660746,
                "lng": -122.9227214253
            },
            {
                "lat": 49.2007144081,
                "lng": -122.9227065098
            },
            {
                "lat": 49.205028722,
                "lng": -122.9294275097
            },
            {
                "lat": 49.2067377936,
                "lng": -122.9443286249
            },
            {
                "lat": 49.2042465445,
                "lng": -122.9529501823
            },
            {
                "lat": 49.2638476052,
                "lng": -123.2553947045
            },
            {
                "lat": 49.2647742786,
                "lng": -123.257513774
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 630,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 29-12",
            "LABEL": "29-12",
            "FISHERY_AREA_ID": 2537,
            "OBJECTID": 774,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 95258127.7721,
            "FEATURE_LENGTH_M": 59276.8648,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.23070318182313,
            "lng": -123.19963584677768
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.2966803499,
                "lng": -126.2077021222
            },
            {
                "lat": 49.2908094238,
                "lng": -126.2141035962
            },
            {
                "lat": 49.2865404729,
                "lng": -126.2253194301
            },
            {
                "lat": 49.2852820184,
                "lng": -126.2268908857
            },
            {
                "lat": 49.2840690058,
                "lng": -126.2287680291
            },
            {
                "lat": 49.2837941262,
                "lng": -126.2297442469
            },
            {
                "lat": 49.2836953789,
                "lng": -126.2305522553
            },
            {
                "lat": 49.1787830419,
                "lng": -126.063911838
            },
            {
                "lat": 49.1663895007,
                "lng": -126.0227964576
            },
            {
                "lat": 49.1663974026,
                "lng": -126.0226740311
            },
            {
                "lat": 49.1662135049,
                "lng": -126.0223392112
            },
            {
                "lat": 49.1657222609,
                "lng": -126.0215445179
            },
            {
                "lat": 49.165321715,
                "lng": -126.0199965237
            },
            {
                "lat": 49.1654091268,
                "lng": -126.0188451127
            },
            {
                "lat": 49.1658179047,
                "lng": -126.016754687
            },
            {
                "lat": 49.1642030689,
                "lng": -126.014442721
            },
            {
                "lat": 49.1646616445,
                "lng": -126.0115655964
            },
            {
                "lat": 49.1671334133,
                "lng": -126.0074302777
            },
            {
                "lat": 49.1704489181,
                "lng": -126.0019837697
            },
            {
                "lat": 49.1741903255,
                "lng": -125.9993132858
            },
            {
                "lat": 49.1842265293,
                "lng": -126.0000385328
            },
            {
                "lat": 49.1927981435,
                "lng": -126.0046007638
            },
            {
                "lat": 49.195537504,
                "lng": -126.0142742733
            },
            {
                "lat": 49.2033802487,
                "lng": -126.0182873219
            },
            {
                "lat": 49.2095830745,
                "lng": -126.0102622843
            },
            {
                "lat": 49.2053873019,
                "lng": -125.9925610296
            },
            {
                "lat": 49.2028355278,
                "lng": -125.9717631015
            },
            {
                "lat": 49.1980892067,
                "lng": -125.9591751834
            },
            {
                "lat": 49.1933476296,
                "lng": -125.947861017
            },
            {
                "lat": 49.1911579829,
                "lng": -125.941482928
            },
            {
                "lat": 49.1911580045,
                "lng": -125.9380112636
            },
            {
                "lat": 49.1887852783,
                "lng": -125.9349129705
            },
            {
                "lat": 49.1873278585,
                "lng": -125.9338152718
            },
            {
                "lat": 49.1861229319,
                "lng": -125.9339445944
            },
            {
                "lat": 49.18525686,
                "lng": -125.9339209943
            },
            {
                "lat": 49.1842649598,
                "lng": -125.9337622431
            },
            {
                "lat": 49.1837538773,
                "lng": -125.933768973
            },
            {
                "lat": 49.1785581699,
                "lng": -125.926215802
            },
            {
                "lat": 49.1783219286,
                "lng": -125.9246439575
            },
            {
                "lat": 49.1784058445,
                "lng": -125.9200821573
            },
            {
                "lat": 49.1790042074,
                "lng": -125.9132689271
            },
            {
                "lat": 49.1815152754,
                "lng": -125.9095612237
            },
            {
                "lat": 49.1863022216,
                "lng": -125.9096752457
            },
            {
                "lat": 49.1916851528,
                "lng": -125.9114687348
            },
            {
                "lat": 49.198142871,
                "lng": -125.9176940314
            },
            {
                "lat": 49.2035257686,
                "lng": -125.9212796031
            },
            {
                "lat": 49.2087854171,
                "lng": -125.9234309069
            },
            {
                "lat": 49.2152478197,
                "lng": -125.9241478658
            },
            {
                "lat": 49.2208856135,
                "lng": -125.9226687058
            },
            {
                "lat": 49.2224689643,
                "lng": -125.9272766457
            },
            {
                "lat": 49.2235714576,
                "lng": -125.9301601292
            },
            {
                "lat": 49.2236213562,
                "lng": -125.9320912367
            },
            {
                "lat": 49.2238241779,
                "lng": -125.9325872469
            },
            {
                "lat": 49.2239300851,
                "lng": -125.9328305293
            },
            {
                "lat": 49.2243501724,
                "lng": -125.9330905037
            },
            {
                "lat": 49.2355879439,
                "lng": -125.9348379474
            },
            {
                "lat": 49.2454143047,
                "lng": -125.9363703561
            },
            {
                "lat": 49.2463423464,
                "lng": -125.9386210969
            },
            {
                "lat": 49.246169937,
                "lng": -125.9406280612
            },
            {
                "lat": 49.2458613644,
                "lng": -125.9421154889
            },
            {
                "lat": 49.2459219411,
                "lng": -125.9444728803
            },
            {
                "lat": 49.2485889702,
                "lng": -125.9506154261
            },
            {
                "lat": 49.2560384012,
                "lng": -125.9621881504
            },
            {
                "lat": 49.2788011838,
                "lng": -126.017494907
            },
            {
                "lat": 49.2849501321,
                "lng": -126.0324401629
            },
            {
                "lat": 49.270432125,
                "lng": -126.0470585235
            },
            {
                "lat": 49.2709314502,
                "lng": -126.0540082792
            },
            {
                "lat": 49.269207438,
                "lng": -126.0550839276
            },
            {
                "lat": 49.2660060296,
                "lng": -126.0566862031
            },
            {
                "lat": 49.2638058467,
                "lng": -126.0592881171
            },
            {
                "lat": 49.2638058274,
                "lng": -126.0632859566
            },
            {
                "lat": 49.2650036539,
                "lng": -126.0786976879
            },
            {
                "lat": 49.2758756877,
                "lng": -126.0932391008
            },
            {
                "lat": 49.2809222736,
                "lng": -126.1094439015
            },
            {
                "lat": 49.2851748937,
                "lng": -126.1354913714
            },
            {
                "lat": 49.2888944938,
                "lng": -126.1703040356
            },
            {
                "lat": 49.2966803499,
                "lng": -126.2077021222
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 631,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 24,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 24-6",
            "LABEL": "24-6",
            "FISHERY_AREA_ID": 2259,
            "OBJECTID": 775,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 159485585.5208,
            "FEATURE_LENGTH_M": 69539.1025,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.22035244962078,
            "lng": -126.0071606298428
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.2041207139,
                "lng": -123.1984022298
            },
            {
                "lat": 49.2071079672,
                "lng": -123.2025765958
            },
            {
                "lat": 49.2091557562,
                "lng": -123.2027585618
            },
            {
                "lat": 49.211070821,
                "lng": -123.2026209325
            },
            {
                "lat": 49.212528358,
                "lng": -123.2023924964
            },
            {
                "lat": 49.214397867,
                "lng": -123.2016606523
            },
            {
                "lat": 49.2157216506,
                "lng": -123.2010731387
            },
            {
                "lat": 49.2175446978,
                "lng": -123.2001570078
            },
            {
                "lat": 49.2184106276,
                "lng": -123.202393244
            },
            {
                "lat": 49.2187728161,
                "lng": -123.2072210994
            },
            {
                "lat": 49.2190439416,
                "lng": -123.211692516
            },
            {
                "lat": 49.2220418162,
                "lng": -123.2141339386
            },
            {
                "lat": 49.2227636373,
                "lng": -123.2164153009
            },
            {
                "lat": 49.2230907683,
                "lng": -123.218751253
            },
            {
                "lat": 49.224048839,
                "lng": -123.2206034029
            },
            {
                "lat": 49.2249752986,
                "lng": -123.2231746792
            },
            {
                "lat": 49.2257041551,
                "lng": -123.2255622686
            },
            {
                "lat": 49.2261733284,
                "lng": -123.2272345296
            },
            {
                "lat": 49.2278019263,
                "lng": -123.230529398
            },
            {
                "lat": 49.2282945243,
                "lng": -123.2317577046
            },
            {
                "lat": 49.2296786637,
                "lng": -123.2334510925
            },
            {
                "lat": 49.2304424016,
                "lng": -123.23429956
            },
            {
                "lat": 49.2311141106,
                "lng": -123.2353903312
            },
            {
                "lat": 49.2319940038,
                "lng": -123.2369389092
            },
            {
                "lat": 49.2323911899,
                "lng": -123.2376102326
            },
            {
                "lat": 49.2329556369,
                "lng": -123.2385478894
            },
            {
                "lat": 49.2333259994,
                "lng": -123.2391281716
            },
            {
                "lat": 49.2337263535,
                "lng": -123.2399675121
            },
            {
                "lat": 49.2340466118,
                "lng": -123.2407464186
            },
            {
                "lat": 49.2349056842,
                "lng": -123.2418142193
            },
            {
                "lat": 49.2356414648,
                "lng": -123.2424861046
            },
            {
                "lat": 49.2362745853,
                "lng": -123.2437887659
            },
            {
                "lat": 49.2368125639,
                "lng": -123.2447504533
            },
            {
                "lat": 49.2376099892,
                "lng": -123.2460623064
            },
            {
                "lat": 49.2381558628,
                "lng": -123.2469703773
            },
            {
                "lat": 49.2390976603,
                "lng": -123.2484814058
            },
            {
                "lat": 49.2394179572,
                "lng": -123.248900052
            },
            {
                "lat": 49.2400813025,
                "lng": -123.2500226235
            },
            {
                "lat": 49.2409208469,
                "lng": -123.2514883408
            },
            {
                "lat": 49.2416729616,
                "lng": -123.2527155719
            },
            {
                "lat": 49.2424354926,
                "lng": -123.2539439418
            },
            {
                "lat": 49.2430301248,
                "lng": -123.2548905038
            },
            {
                "lat": 49.244022211,
                "lng": -123.2565687923
            },
            {
                "lat": 49.2448614439,
                "lng": -123.25800387
            },
            {
                "lat": 49.2453272135,
                "lng": -123.2587435899
            },
            {
                "lat": 49.2457509047,
                "lng": -123.2594446147
            },
            {
                "lat": 49.2463304781,
                "lng": -123.2604213802
            },
            {
                "lat": 49.2467264555,
                "lng": -123.2610845916
            },
            {
                "lat": 49.2469639833,
                "lng": -123.2614602131
            },
            {
                "lat": 49.2473597206,
                "lng": -123.262070245
            },
            {
                "lat": 49.2475891647,
                "lng": -123.2624823997
            },
            {
                "lat": 49.2479021479,
                "lng": -123.2629926217
            },
            {
                "lat": 49.2483142818,
                "lng": -123.263672089
            },
            {
                "lat": 49.248581078,
                "lng": -123.2640923568
            },
            {
                "lat": 49.2490350023,
                "lng": -123.2648552076
            },
            {
                "lat": 49.249363084,
                "lng": -123.2653731539
            },
            {
                "lat": 49.2497521122,
                "lng": -123.2660076766
            },
            {
                "lat": 49.2500268691,
                "lng": -123.2664652661
            },
            {
                "lat": 49.2502631659,
                "lng": -123.26683758
            },
            {
                "lat": 49.2508045266,
                "lng": -123.267708632
            },
            {
                "lat": 49.2513153929,
                "lng": -123.2685317166
            },
            {
                "lat": 49.2517242396,
                "lng": -123.2691273589
            },
            {
                "lat": 49.252136289,
                "lng": -123.2698052226
            },
            {
                "lat": 49.2523539583,
                "lng": -123.2701872487
            },
            {
                "lat": 49.2526890889,
                "lng": -123.2707065702
            },
            {
                "lat": 49.2529558501,
                "lng": -123.2711715521
            },
            {
                "lat": 49.2530977464,
                "lng": -123.2713846607
            },
            {
                "lat": 49.253349162,
                "lng": -123.2718883201
            },
            {
                "lat": 49.2536006839,
                "lng": -123.2723627944
            },
            {
                "lat": 49.2537999259,
                "lng": -123.2727733945
            },
            {
                "lat": 49.2538790094,
                "lng": -123.2728957837
            },
            {
                "lat": 49.2540279149,
                "lng": -123.2731857422
            },
            {
                "lat": 49.2542957776,
                "lng": -123.2737279393
            },
            {
                "lat": 49.2545543113,
                "lng": -123.2742243386
            },
            {
                "lat": 49.2547031022,
                "lng": -123.2744988614
            },
            {
                "lat": 49.2548370632,
                "lng": -123.2748103087
            },
            {
                "lat": 49.255184064,
                "lng": -123.275444016
            },
            {
                "lat": 49.2554436118,
                "lng": -123.2759249233
            },
            {
                "lat": 49.2557521467,
                "lng": -123.2764733487
            },
            {
                "lat": 49.2558521156,
                "lng": -123.2766872296
            },
            {
                "lat": 49.2560198192,
                "lng": -123.2770087279
            },
            {
                "lat": 49.2562828963,
                "lng": -123.2774963124
            },
            {
                "lat": 49.2563899969,
                "lng": -123.2776805938
            },
            {
                "lat": 49.2565764628,
                "lng": -123.2780765382
            },
            {
                "lat": 49.256721871,
                "lng": -123.2783512832
            },
            {
                "lat": 49.2568695699,
                "lng": -123.2786722496
            },
            {
                "lat": 49.2570757005,
                "lng": -123.2790670488
            },
            {
                "lat": 49.2571374236,
                "lng": -123.2791595719
            },
            {
                "lat": 49.2573319446,
                "lng": -123.279640898
            },
            {
                "lat": 49.1059721246,
                "lng": -123.303138489
            },
            {
                "lat": 49.1068651504,
                "lng": -123.3009185828
            },
            {
                "lat": 49.1280294105,
                "lng": -123.248542885
            },
            {
                "lat": 49.1320375336,
                "lng": -123.2375409609
            },
            {
                "lat": 49.1325534019,
                "lng": -123.2345652183
            },
            {
                "lat": 49.1327592665,
                "lng": -123.2292174912
            },
            {
                "lat": 49.1318059798,
                "lng": -123.2225878805
            },
            {
                "lat": 49.1297872667,
                "lng": -123.2139350914
            },
            {
                "lat": 49.1269758792,
                "lng": -123.2034832543
            },
            {
                "lat": 49.126605544,
                "lng": -123.2021033061
            },
            {
                "lat": 49.1259805104,
                "lng": -123.2006839124
            },
            {
                "lat": 49.1257780193,
                "lng": -123.19724286
            },
            {
                "lat": 49.1264075632,
                "lng": -123.1931524279
            },
            {
                "lat": 49.1281773627,
                "lng": -123.1883166969
            },
            {
                "lat": 49.1396977142,
                "lng": -123.1817325852
            },
            {
                "lat": 49.1634335904,
                "lng": -123.1778711332
            },
            {
                "lat": 49.1713870334,
                "lng": -123.194251676
            },
            {
                "lat": 49.174316237,
                "lng": -123.1999671058
            },
            {
                "lat": 49.1872939516,
                "lng": -123.2095191395
            },
            {
                "lat": 49.1896783594,
                "lng": -123.2026743329
            },
            {
                "lat": 49.2041207139,
                "lng": -123.1984022298
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 632,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 29-10",
            "LABEL": "29-10",
            "FISHERY_AREA_ID": 2535,
            "OBJECTID": 776,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 88830521.3753,
            "FEATURE_LENGTH_M": 45273.4968,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.22184631429181,
            "lng": -123.24393274388088
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.2685390899,
                "lng": -124.1374210977
            },
            {
                "lat": 49.2694553734,
                "lng": -124.1419899851
            },
            {
                "lat": 49.2713854527,
                "lng": -124.1512376926
            },
            {
                "lat": 49.2753447329,
                "lng": -124.1663664587
            },
            {
                "lat": 49.2757487427,
                "lng": -124.1729722873
            },
            {
                "lat": 49.2740245291,
                "lng": -124.1808926361
            },
            {
                "lat": 49.272094462,
                "lng": -124.1917559282
            },
            {
                "lat": 49.2710805719,
                "lng": -124.1968389239
            },
            {
                "lat": 49.2663038597,
                "lng": -124.1984629351
            },
            {
                "lat": 49.2627522314,
                "lng": -124.1973496097
            },
            {
                "lat": 49.2586858113,
                "lng": -124.1910478554
            },
            {
                "lat": 49.2532037028,
                "lng": -124.1790608705
            },
            {
                "lat": 49.249649703,
                "lng": -124.1686014292
            },
            {
                "lat": 49.2469056396,
                "lng": -124.1541823055
            },
            {
                "lat": 49.2438579382,
                "lng": -124.1380304271
            },
            {
                "lat": 49.2520338152,
                "lng": -124.1381983797
            },
            {
                "lat": 49.2550163332,
                "lng": -124.1380152479
            },
            {
                "lat": 49.2656438324,
                "lng": -124.1373587987
            },
            {
                "lat": 49.2658535913,
                "lng": -124.1373675436
            },
            {
                "lat": 49.2685390899,
                "lng": -124.1374210977
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 633,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 20,
            "MANAGEMENT_AREA_NAME": "Subarea 17-20",
            "LABEL": "17-20",
            "FISHERY_AREA_ID": 2211,
            "OBJECTID": 777,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 10591513.0942,
            "FEATURE_LENGTH_M": 13116.1706,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.26330592513,
            "lng": -124.16272857548499
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.2685390899,
                "lng": -124.1374210977
            },
            {
                "lat": 49.2658535913,
                "lng": -124.1373675436
            },
            {
                "lat": 49.2656438324,
                "lng": -124.1373587987
            },
            {
                "lat": 49.2550163332,
                "lng": -124.1380152479
            },
            {
                "lat": 49.2520338152,
                "lng": -124.1381983797
            },
            {
                "lat": 49.2438579382,
                "lng": -124.1380304271
            },
            {
                "lat": 49.2459905937,
                "lng": -124.1158906069
            },
            {
                "lat": 49.248837139,
                "lng": -124.0957800224
            },
            {
                "lat": 49.2492444423,
                "lng": -124.0851058814
            },
            {
                "lat": 49.2572548974,
                "lng": -124.0832513943
            },
            {
                "lat": 49.2701678521,
                "lng": -124.0780022332
            },
            {
                "lat": 49.2707940179,
                "lng": -124.1032099661
            },
            {
                "lat": 49.2708429232,
                "lng": -124.1050654211
            },
            {
                "lat": 49.2708512209,
                "lng": -124.1092297586
            },
            {
                "lat": 49.2707219852,
                "lng": -124.1107027848
            },
            {
                "lat": 49.2699548969,
                "lng": -124.1190408716
            },
            {
                "lat": 49.2698592009,
                "lng": -124.1200558519
            },
            {
                "lat": 49.2685390899,
                "lng": -124.1374210977
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 634,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 19,
            "MANAGEMENT_AREA_NAME": "Subarea 17-19",
            "LABEL": "17-19",
            "FISHERY_AREA_ID": 2210,
            "OBJECTID": 778,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 10515008.674,
            "FEATURE_LENGTH_M": 13384.0062,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.26188904775555,
            "lng": -124.11606374359447
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.2119484425,
                "lng": -122.8114773313
            },
            {
                "lat": 49.2166027423,
                "lng": -122.8112177887
            },
            {
                "lat": 49.2240639748,
                "lng": -122.814964635
            },
            {
                "lat": 49.2319102264,
                "lng": -122.8155676447
            },
            {
                "lat": 49.2306016233,
                "lng": -122.8320928096
            },
            {
                "lat": 49.2310940331,
                "lng": -122.8486164595
            },
            {
                "lat": 49.232891725,
                "lng": -122.8633426491
            },
            {
                "lat": 49.2338754271,
                "lng": -122.8782344374
            },
            {
                "lat": 49.2288018798,
                "lng": -122.8929606181
            },
            {
                "lat": 49.218166292,
                "lng": -122.8991781933
            },
            {
                "lat": 49.211776131,
                "lng": -122.901191725
            },
            {
                "lat": 49.2089379695,
                "lng": -122.8977289401
            },
            {
                "lat": 49.2064245175,
                "lng": -122.8916478273
            },
            {
                "lat": 49.2019040698,
                "lng": -122.8878544665
            },
            {
                "lat": 49.2045864621,
                "lng": -122.8801960977
            },
            {
                "lat": 49.2076946335,
                "lng": -122.8558205175
            },
            {
                "lat": 49.2101477085,
                "lng": -122.8381508846
            },
            {
                "lat": 49.2106400112,
                "lng": -122.8216170423
            },
            {
                "lat": 49.2119484425,
                "lng": -122.8114773313
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 635,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 17,
            "MANAGEMENT_AREA_NAME": "Subarea 29-17",
            "LABEL": "29-17",
            "FISHERY_AREA_ID": 2542,
            "OBJECTID": 779,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 16241179.3208,
            "FEATURE_LENGTH_M": 17292.9243,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.217579805889464,
            "lng": -122.85543881047369
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.2453503058,
                "lng": -125.8111881537
            },
            {
                "lat": 49.2367857759,
                "lng": -125.8177641381
            },
            {
                "lat": 49.236484312,
                "lng": -125.8179625237
            },
            {
                "lat": 49.2354236411,
                "lng": -125.8192741979
            },
            {
                "lat": 49.2336726959,
                "lng": -125.8185505474
            },
            {
                "lat": 49.2335586395,
                "lng": -125.8148189444
            },
            {
                "lat": 49.2307588315,
                "lng": -125.8095782997
            },
            {
                "lat": 49.2237658182,
                "lng": -125.8077083404
            },
            {
                "lat": 49.2174677369,
                "lng": -125.805381788
            },
            {
                "lat": 49.2117576721,
                "lng": -125.8035127113
            },
            {
                "lat": 49.2060473042,
                "lng": -125.8049172186
            },
            {
                "lat": 49.2025492854,
                "lng": -125.8031609472
            },
            {
                "lat": 49.1971859753,
                "lng": -125.8021162352
            },
            {
                "lat": 49.1917073021,
                "lng": -125.802001638
            },
            {
                "lat": 49.1865769892,
                "lng": -125.8010633729
            },
            {
                "lat": 49.1824989681,
                "lng": -125.7995521227
            },
            {
                "lat": 49.1771356694,
                "lng": -125.7975689114
            },
            {
                "lat": 49.1722368632,
                "lng": -125.7968675822
            },
            {
                "lat": 49.1685103804,
                "lng": -125.7962871109
            },
            {
                "lat": 49.1635511798,
                "lng": -125.795517827
            },
            {
                "lat": 49.1621098271,
                "lng": -125.7961045526
            },
            {
                "lat": 49.1600377378,
                "lng": -125.7978513034
            },
            {
                "lat": 49.1576116635,
                "lng": -125.7987982535
            },
            {
                "lat": 49.1555791744,
                "lng": -125.7997819339
            },
            {
                "lat": 49.153019101,
                "lng": -125.8000796572
            },
            {
                "lat": 49.1517023202,
                "lng": -125.7999107225
            },
            {
                "lat": 49.1509167245,
                "lng": -125.7990411564
            },
            {
                "lat": 49.151214642,
                "lng": -125.7975532952
            },
            {
                "lat": 49.1512146403,
                "lng": -125.7962113493
            },
            {
                "lat": 49.1510655497,
                "lng": -125.7950215355
            },
            {
                "lat": 49.150916738,
                "lng": -125.7942810444
            },
            {
                "lat": 49.1490177704,
                "lng": -125.793731462
            },
            {
                "lat": 49.1461216112,
                "lng": -125.7923126525
            },
            {
                "lat": 49.1426092527,
                "lng": -125.7830202928
            },
            {
                "lat": 49.1428874918,
                "lng": -125.780952521
            },
            {
                "lat": 49.1437001132,
                "lng": -125.7770843765
            },
            {
                "lat": 49.1446335577,
                "lng": -125.7740250118
            },
            {
                "lat": 49.1456338156,
                "lng": -125.7713391564
            },
            {
                "lat": 49.1467594631,
                "lng": -125.7700962087
            },
            {
                "lat": 49.1480680034,
                "lng": -125.7692801804
            },
            {
                "lat": 49.1498187981,
                "lng": -125.7679663861
            },
            {
                "lat": 49.1514964855,
                "lng": -125.7664183731
            },
            {
                "lat": 49.1534277304,
                "lng": -125.7635192458
            },
            {
                "lat": 49.1556779442,
                "lng": -125.7625577427
            },
            {
                "lat": 49.1617887272,
                "lng": -125.7606278842
            },
            {
                "lat": 49.1662938885,
                "lng": -125.7577290165
            },
            {
                "lat": 49.1736908496,
                "lng": -125.7496871434
            },
            {
                "lat": 49.1798015977,
                "lng": -125.7419666937
            },
            {
                "lat": 49.184951849,
                "lng": -125.7410045774
            },
            {
                "lat": 49.1936340831,
                "lng": -125.7413254177
            },
            {
                "lat": 49.2048949153,
                "lng": -125.7429352655
            },
            {
                "lat": 49.2110066583,
                "lng": -125.7442168477
            },
            {
                "lat": 49.2187273734,
                "lng": -125.7448651503
            },
            {
                "lat": 49.2241929611,
                "lng": -125.7448651428
            },
            {
                "lat": 49.2303083406,
                "lng": -125.7432549869
            },
            {
                "lat": 49.233844981,
                "lng": -125.740684806
            },
            {
                "lat": 49.2415658202,
                "lng": -125.7313541009
            },
            {
                "lat": 49.2492865296,
                "lng": -125.7236335226
            },
            {
                "lat": 49.2557184554,
                "lng": -125.7178413627
            },
            {
                "lat": 49.2615088756,
                "lng": -125.7162328319
            },
            {
                "lat": 49.2663342934,
                "lng": -125.7168811167
            },
            {
                "lat": 49.2711596324,
                "lng": -125.7249224761
            },
            {
                "lat": 49.270835911,
                "lng": -125.7303841196
            },
            {
                "lat": 49.2676206046,
                "lng": -125.7406848506
            },
            {
                "lat": 49.2647242799,
                "lng": -125.7474366163
            },
            {
                "lat": 49.2595785814,
                "lng": -125.7567678115
            },
            {
                "lat": 49.253468173,
                "lng": -125.7660897724
            },
            {
                "lat": 49.2483212429,
                "lng": -125.7786404348
            },
            {
                "lat": 49.2463912211,
                "lng": -125.7870025373
            },
            {
                "lat": 49.2467112465,
                "lng": -125.7940822222
            },
            {
                "lat": 49.247997622,
                "lng": -125.7998654295
            },
            {
                "lat": 49.2506099203,
                "lng": -125.8044735512
            },
            {
                "lat": 49.2463912473,
                "lng": -125.8095930912
            },
            {
                "lat": 49.2453503058,
                "lng": -125.8111881537
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 636,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 24,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 24-10",
            "LABEL": "24-10",
            "FISHERY_AREA_ID": 2263,
            "OBJECTID": 780,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 54068910.4223,
            "FEATURE_LENGTH_M": 36917.3119,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.19925642822972,
            "lng": -125.7781347021311
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1909668774,
                "lng": -122.5638890828
            },
            {
                "lat": 49.1881451005,
                "lng": -122.5755233149
            },
            {
                "lat": 49.188140472,
                "lng": -122.590332815
            },
            {
                "lat": 49.1862255373,
                "lng": -122.6089105053
            },
            {
                "lat": 49.1731413162,
                "lng": -122.6035612002
            },
            {
                "lat": 49.1689761553,
                "lng": -122.5863026044
            },
            {
                "lat": 49.1410179645,
                "lng": -122.5351480001
            },
            {
                "lat": 49.1249583089,
                "lng": -122.4572216227
            },
            {
                "lat": 49.1088940908,
                "lng": -122.4102250551
            },
            {
                "lat": 49.0874820432,
                "lng": -122.3049395725
            },
            {
                "lat": 49.1011618014,
                "lng": -122.2710261392
            },
            {
                "lat": 49.1190071009,
                "lng": -122.2787630893
            },
            {
                "lat": 49.1242366198,
                "lng": -122.2982946308
            },
            {
                "lat": 49.1277848811,
                "lng": -122.3027182653
            },
            {
                "lat": 49.1499410131,
                "lng": -122.3168326767
            },
            {
                "lat": 49.171951697,
                "lng": -122.3638306778
            },
            {
                "lat": 49.2034794265,
                "lng": -122.3929745695
            },
            {
                "lat": 49.2082370784,
                "lng": -122.4304505593
            },
            {
                "lat": 49.1892009587,
                "lng": -122.4756613773
            },
            {
                "lat": 49.1903917765,
                "lng": -122.5470427257
            },
            {
                "lat": 49.1909668774,
                "lng": -122.5638890828
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 637,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 16,
            "MANAGEMENT_AREA_NAME": "Subarea 29-16",
            "LABEL": "29-16",
            "FISHERY_AREA_ID": 2541,
            "OBJECTID": 781,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 157955655.0838,
            "FEATURE_LENGTH_M": 60100.3807,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.15877652842381,
            "lng": -122.4513113127
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.000000358,
                "lng": -129.3562462079
            },
            {
                "lat": 49.0000004876,
                "lng": -130.3969721484
            },
            {
                "lat": 49.0000003251,
                "lng": -131.4376982859
            },
            {
                "lat": 49.0000003189,
                "lng": -132.4784246822
            },
            {
                "lat": 49.0000003335,
                "lng": -133.5191502393
            },
            {
                "lat": 48.9726672645,
                "lng": -133.4438165834
            },
            {
                "lat": 48.9702832301,
                "lng": -133.4362182626
            },
            {
                "lat": 48.9293060343,
                "lng": -133.3058924077
            },
            {
                "lat": 48.9077831117,
                "lng": -133.2823335228
            },
            {
                "lat": 48.8128245124,
                "lng": -133.1650545644
            },
            {
                "lat": 48.7457774659,
                "lng": -133.0822445306
            },
            {
                "lat": 48.5919681529,
                "lng": -132.8684839541
            },
            {
                "lat": 48.4468845577,
                "lng": -132.6419223253
            },
            {
                "lat": 48.3110127263,
                "lng": -132.4033964125
            },
            {
                "lat": 48.1848183191,
                "lng": -132.1538245178
            },
            {
                "lat": 48.1202848656,
                "lng": -132.0094450769
            },
            {
                "lat": 48.0350070257,
                "lng": -131.909027257
            },
            {
                "lat": 47.8788835284,
                "lng": -131.7019651545
            },
            {
                "lat": 47.7313574816,
                "lng": -131.4820100497
            },
            {
                "lat": 47.5929304183,
                "lng": -131.2500302328
            },
            {
                "lat": 47.4640738821,
                "lng": -131.0068665452
            },
            {
                "lat": 47.3452230817,
                "lng": -130.7534026914
            },
            {
                "lat": 47.2367704928,
                "lng": -130.4905697257
            },
            {
                "lat": 47.1990857584,
                "lng": -130.3859097673
            },
            {
                "lat": 47.1602216593,
                "lng": -130.2779695227
            },
            {
                "lat": 47.042079789,
                "lng": -130.1105354191
            },
            {
                "lat": 47.0034333924,
                "lng": -130.0489652174
            },
            {
                "lat": 46.9007108261,
                "lng": -129.8852997418
            },
            {
                "lat": 46.791348043,
                "lng": -129.689193477
            },
            {
                "lat": 47.5275654294,
                "lng": -128.8025076282
            },
            {
                "lat": 48.2637828418,
                "lng": -127.9158226738
            },
            {
                "lat": 49.0000003786,
                "lng": -127.0291369356
            },
            {
                "lat": 49.0000004084,
                "lng": -128.2450561145
            },
            {
                "lat": 49.00000056,
                "lng": -129.1937098109
            },
            {
                "lat": 49.000000358,
                "lng": -129.3562462079
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 638,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 125,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 125-6",
            "LABEL": "125-6",
            "FISHERY_AREA_ID": 2334,
            "OBJECTID": 782,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 60201940729.113,
            "FEATURE_LENGTH_M": 1171719.2081,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.20474535481714,
            "lng": -131.1004385112657
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.2019040698,
                "lng": -122.8878544665
            },
            {
                "lat": 49.2064245175,
                "lng": -122.8916478273
            },
            {
                "lat": 49.2089379695,
                "lng": -122.8977289401
            },
            {
                "lat": 49.211776131,
                "lng": -122.901191725
            },
            {
                "lat": 49.2085072914,
                "lng": -122.9055559672
            },
            {
                "lat": 49.2033804719,
                "lng": -122.9166947665
            },
            {
                "lat": 49.2007144081,
                "lng": -122.9227065098
            },
            {
                "lat": 49.199660746,
                "lng": -122.9227214253
            },
            {
                "lat": 49.1982917647,
                "lng": -122.9227366466
            },
            {
                "lat": 49.19632336,
                "lng": -122.9239045659
            },
            {
                "lat": 49.1956411283,
                "lng": -122.9243167283
            },
            {
                "lat": 49.1916315921,
                "lng": -122.9355463107
            },
            {
                "lat": 49.1905142306,
                "lng": -122.9395895557
            },
            {
                "lat": 49.1827549557,
                "lng": -122.9498144114
            },
            {
                "lat": 49.1744267161,
                "lng": -122.9698038735
            },
            {
                "lat": 49.16752672,
                "lng": -122.9943155865
            },
            {
                "lat": 49.161815421,
                "lng": -123.0138237896
            },
            {
                "lat": 49.1597443744,
                "lng": -123.0289532471
            },
            {
                "lat": 49.1217197221,
                "lng": -123.1381375548
            },
            {
                "lat": 49.1222527334,
                "lng": -123.1536410464
            },
            {
                "lat": 49.1155275562,
                "lng": -123.1559146369
            },
            {
                "lat": 49.1141667381,
                "lng": -123.1572404563
            },
            {
                "lat": 49.1141200155,
                "lng": -123.1578372608
            },
            {
                "lat": 49.11424581,
                "lng": -123.1590500073
            },
            {
                "lat": 49.1145821951,
                "lng": -123.160369731
            },
            {
                "lat": 49.1151271826,
                "lng": -123.161849636
            },
            {
                "lat": 49.1156391759,
                "lng": -123.1643209619
            },
            {
                "lat": 49.1163027993,
                "lng": -123.1659460655
            },
            {
                "lat": 49.1169897292,
                "lng": -123.1680080089
            },
            {
                "lat": 49.1164775286,
                "lng": -123.1683575568
            },
            {
                "lat": 49.1101955991,
                "lng": -123.1683579782
            },
            {
                "lat": 49.1101490575,
                "lng": -123.1682050608
            },
            {
                "lat": 49.1101153692,
                "lng": -123.1680376697
            },
            {
                "lat": 49.1100734051,
                "lng": -123.1678553786
            },
            {
                "lat": 49.1100270227,
                "lng": -123.1676014731
            },
            {
                "lat": 49.1099931533,
                "lng": -123.1674272477
            },
            {
                "lat": 49.1099094213,
                "lng": -123.1670917516
            },
            {
                "lat": 49.1098139221,
                "lng": -123.1667261656
            },
            {
                "lat": 49.1097637237,
                "lng": -123.1664878982
            },
            {
                "lat": 49.1096951749,
                "lng": -123.1662370562
            },
            {
                "lat": 49.1096114132,
                "lng": -123.1659323729
            },
            {
                "lat": 49.1095809261,
                "lng": -123.1658332503
            },
            {
                "lat": 49.1095089005,
                "lng": -123.1655038929
            },
            {
                "lat": 49.1093982022,
                "lng": -123.1651067201
            },
            {
                "lat": 49.1093366175,
                "lng": -123.1647784407
            },
            {
                "lat": 49.1092563488,
                "lng": -123.1644358966
            },
            {
                "lat": 49.1091689424,
                "lng": -123.164025329
            },
            {
                "lat": 49.1090664161,
                "lng": -123.1636499136
            },
            {
                "lat": 49.1090047677,
                "lng": -123.1633832554
            },
            {
                "lat": 49.1088638704,
                "lng": -123.1627808429
            },
            {
                "lat": 49.1087112804,
                "lng": -123.1621089763
            },
            {
                "lat": 49.1085588536,
                "lng": -123.161507274
            },
            {
                "lat": 49.1083983247,
                "lng": -123.1607588838
            },
            {
                "lat": 49.1083027007,
                "lng": -123.1603471195
            },
            {
                "lat": 49.1082153608,
                "lng": -123.1599502546
            },
            {
                "lat": 49.1081014536,
                "lng": -123.1593256774
            },
            {
                "lat": 49.1080246921,
                "lng": -123.1588819612
            },
            {
                "lat": 49.1079103171,
                "lng": -123.1584850396
            },
            {
                "lat": 49.1078068854,
                "lng": -123.157906039
            },
            {
                "lat": 49.1076207193,
                "lng": -123.1570514167
            },
            {
                "lat": 49.1075287476,
                "lng": -123.1563947079
            },
            {
                "lat": 49.1074529537,
                "lng": -123.1558071819
            },
            {
                "lat": 49.1073644119,
                "lng": -123.1552272822
            },
            {
                "lat": 49.1072991466,
                "lng": -123.154846199
            },
            {
                "lat": 49.107215567,
                "lng": -123.154219791
            },
            {
                "lat": 49.1071699937,
                "lng": -123.1538169693
            },
            {
                "lat": 49.1070862501,
                "lng": -123.1530998693
            },
            {
                "lat": 49.1070258156,
                "lng": -123.1526568843
            },
            {
                "lat": 49.1069989427,
                "lng": -123.1522905729
            },
            {
                "lat": 49.1069826878,
                "lng": -123.1516874365
            },
            {
                "lat": 49.1069453827,
                "lng": -123.1508853525
            },
            {
                "lat": 49.1069254763,
                "lng": -123.150527173
            },
            {
                "lat": 49.1068917517,
                "lng": -123.1501150743
            },
            {
                "lat": 49.1068381826,
                "lng": -123.1493687535
            },
            {
                "lat": 49.1067613143,
                "lng": -123.148635841
            },
            {
                "lat": 49.106704341,
                "lng": -123.1479633226
            },
            {
                "lat": 49.1066323638,
                "lng": -123.147308839
            },
            {
                "lat": 49.1065750764,
                "lng": -123.1467202019
            },
            {
                "lat": 49.1065368555,
                "lng": -123.1460123102
            },
            {
                "lat": 49.1065031458,
                "lng": -123.1451877671
            },
            {
                "lat": 49.106467996,
                "lng": -123.1445002257
            },
            {
                "lat": 49.1064610661,
                "lng": -123.1442182687
            },
            {
                "lat": 49.1064110392,
                "lng": -123.1434477821
            },
            {
                "lat": 49.1063611127,
                "lng": -123.1427765521
            },
            {
                "lat": 49.1063610778,
                "lng": -123.1424257163
            },
            {
                "lat": 49.1063608565,
                "lng": -123.1418455636
            },
            {
                "lat": 49.1062819307,
                "lng": -123.1412805117
            },
            {
                "lat": 49.1062817917,
                "lng": -123.1403649194
            },
            {
                "lat": 49.106231701,
                "lng": -123.139466087
            },
            {
                "lat": 49.1062386586,
                "lng": -123.1389761982
            },
            {
                "lat": 49.106193255,
                "lng": -123.138518621
            },
            {
                "lat": 49.1061548565,
                "lng": -123.1380914193
            },
            {
                "lat": 49.1061282129,
                "lng": -123.137725106
            },
            {
                "lat": 49.1061478608,
                "lng": -123.1374055774
            },
            {
                "lat": 49.1061596573,
                "lng": -123.1368862972
            },
            {
                "lat": 49.1061398085,
                "lng": -123.1365743312
            },
            {
                "lat": 49.106124623,
                "lng": -123.1361628173
            },
            {
                "lat": 49.106117713,
                "lng": -123.1359561627
            },
            {
                "lat": 49.1060756942,
                "lng": -123.1357739162
            },
            {
                "lat": 49.1060442985,
                "lng": -123.1355362516
            },
            {
                "lat": 49.1060070994,
                "lng": -123.1352390455
            },
            {
                "lat": 49.1059570502,
                "lng": -123.1345969283
            },
            {
                "lat": 49.1060339821,
                "lng": -123.1341557865
            },
            {
                "lat": 49.1060711989,
                "lng": -123.1338197723
            },
            {
                "lat": 49.1061176959,
                "lng": -123.1333462737
            },
            {
                "lat": 49.1061398126,
                "lng": -123.1329718255
            },
            {
                "lat": 49.1061443744,
                "lng": -123.132636109
            },
            {
                "lat": 49.1061514022,
                "lng": -123.1322557439
            },
            {
                "lat": 49.1061780992,
                "lng": -123.1318211133
            },
            {
                "lat": 49.1062015023,
                "lng": -123.1312104099
            },
            {
                "lat": 49.1062166445,
                "lng": -123.1305009528
            },
            {
                "lat": 49.1062236501,
                "lng": -123.129814245
            },
            {
                "lat": 49.1062468836,
                "lng": -123.1291658986
            },
            {
                "lat": 49.1062653763,
                "lng": -123.1284562327
            },
            {
                "lat": 49.1063040731,
                "lng": -123.1275331039
            },
            {
                "lat": 49.1063388151,
                "lng": -123.1268840442
            },
            {
                "lat": 49.1063656448,
                "lng": -123.1262970828
            },
            {
                "lat": 49.1063539396,
                "lng": -123.1258699502
            },
            {
                "lat": 49.1062853966,
                "lng": -123.125275185
            },
            {
                "lat": 49.1062318393,
                "lng": -123.124946477
            },
            {
                "lat": 49.1061480934,
                "lng": -123.1245734259
            },
            {
                "lat": 49.1060593915,
                "lng": -123.1242674272
            },
            {
                "lat": 49.1058917203,
                "lng": -123.1237249995
            },
            {
                "lat": 49.1057543308,
                "lng": -123.1232833886
            },
            {
                "lat": 49.1055798584,
                "lng": -123.122749945
            },
            {
                "lat": 49.1053504047,
                "lng": -123.1221925213
            },
            {
                "lat": 49.1051258481,
                "lng": -123.1216502027
            },
            {
                "lat": 49.1049151423,
                "lng": -123.1211309918
            },
            {
                "lat": 49.1047137903,
                "lng": -123.1206351662
            },
            {
                "lat": 49.1045379233,
                "lng": -123.1202079341
            },
            {
                "lat": 49.1044353789,
                "lng": -123.1199113666
            },
            {
                "lat": 49.1043666968,
                "lng": -123.1196281076
            },
            {
                "lat": 49.1043354316,
                "lng": -123.1194383703
            },
            {
                "lat": 49.1043133509,
                "lng": -123.1193079623
            },
            {
                "lat": 49.1042666768,
                "lng": -123.1191944781
            },
            {
                "lat": 49.104171098,
                "lng": -123.119063484
            },
            {
                "lat": 49.1040036705,
                "lng": -123.1189198229
            },
            {
                "lat": 49.1035460243,
                "lng": -123.1181797559
            },
            {
                "lat": 49.1044040551,
                "lng": -123.1134940004
            },
            {
                "lat": 49.1056589933,
                "lng": -123.109954018
            },
            {
                "lat": 49.1083445955,
                "lng": -123.1046442112
            },
            {
                "lat": 49.1100582151,
                "lng": -123.0968166914
            },
            {
                "lat": 49.1115415097,
                "lng": -123.0921934707
            },
            {
                "lat": 49.1140665783,
                "lng": -123.0886601886
            },
            {
                "lat": 49.1143226588,
                "lng": -123.0883018224
            },
            {
                "lat": 49.1144983454,
                "lng": -123.0879587521
            },
            {
                "lat": 49.1145589394,
                "lng": -123.0877529738
            },
            {
                "lat": 49.114597511,
                "lng": -123.0875554199
            },
            {
                "lat": 49.1146206414,
                "lng": -123.0874324375
            },
            {
                "lat": 49.1146391335,
                "lng": -123.0873251512
            },
            {
                "lat": 49.1146509259,
                "lng": -123.0872199957
            },
            {
                "lat": 49.114677803,
                "lng": -123.0869752433
            },
            {
                "lat": 49.1146928424,
                "lng": -123.0868236668
            },
            {
                "lat": 49.1147044975,
                "lng": -123.0866928431
            },
            {
                "lat": 49.1147265259,
                "lng": -123.086518576
            },
            {
                "lat": 49.1147381696,
                "lng": -123.0863038762
            },
            {
                "lat": 49.1147650003,
                "lng": -123.0860368726
            },
            {
                "lat": 49.1160922167,
                "lng": -123.0804828073
            },
            {
                "lat": 49.1161654174,
                "lng": -123.0801923411
            },
            {
                "lat": 49.1163099709,
                "lng": -123.0794916934
            },
            {
                "lat": 49.1165042922,
                "lng": -123.078902611
            },
            {
                "lat": 49.1169315979,
                "lng": -123.0776141564
            },
            {
                "lat": 49.1182282432,
                "lng": -123.0742424606
            },
            {
                "lat": 49.1203157354,
                "lng": -123.0677940008
            },
            {
                "lat": 49.1251329752,
                "lng": -123.0597155032
            },
            {
                "lat": 49.1262434602,
                "lng": -123.0572362478
            },
            {
                "lat": 49.1267627777,
                "lng": -123.0560681929
            },
            {
                "lat": 49.1269572695,
                "lng": -123.0545645208
            },
            {
                "lat": 49.127220135,
                "lng": -123.0526798076
            },
            {
                "lat": 49.1273494076,
                "lng": -123.0497436442
            },
            {
                "lat": 49.1290550201,
                "lng": -123.0452193985
            },
            {
                "lat": 49.1326137643,
                "lng": -123.0362923393
            },
            {
                "lat": 49.1364742162,
                "lng": -123.0235682442
            },
            {
                "lat": 49.1368325956,
                "lng": -123.0204848198
            },
            {
                "lat": 49.139972375,
                "lng": -123.0203094174
            },
            {
                "lat": 49.1409874869,
                "lng": -123.0118259655
            },
            {
                "lat": 49.1426546341,
                "lng": -122.9911266257
            },
            {
                "lat": 49.1445581011,
                "lng": -122.9680410972
            },
            {
                "lat": 49.1464612797,
                "lng": -122.9440077566
            },
            {
                "lat": 49.155024825,
                "lng": -122.930450554
            },
            {
                "lat": 49.1676374627,
                "lng": -122.9147412859
            },
            {
                "lat": 49.1835827292,
                "lng": -122.9026105512
            },
            {
                "lat": 49.2019040698,
                "lng": -122.8878544665
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 639,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 29-13",
            "LABEL": "29-13",
            "FISHERY_AREA_ID": 2538,
            "OBJECTID": 783,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 57485106.3101,
            "FEATURE_LENGTH_M": 51002.639,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.12070535422513,
            "lng": -123.10150185576934
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.236149003,
                "lng": -123.9635620318
            },
            {
                "lat": 49.2337381057,
                "lng": -123.9653625877
            },
            {
                "lat": 49.2305178903,
                "lng": -123.9674299467
            },
            {
                "lat": 49.2276797678,
                "lng": -123.9647069563
            },
            {
                "lat": 49.2253037908,
                "lng": -123.9601048475
            },
            {
                "lat": 49.2236516959,
                "lng": -123.9537425721
            },
            {
                "lat": 49.2213931708,
                "lng": -123.9489064107
            },
            {
                "lat": 49.221912643,
                "lng": -123.9477841667
            },
            {
                "lat": 49.2223816299,
                "lng": -123.947883495
            },
            {
                "lat": 49.2230835503,
                "lng": -123.9476844095
            },
            {
                "lat": 49.2239531982,
                "lng": -123.9477231592
            },
            {
                "lat": 49.2249182723,
                "lng": -123.9478674597
            },
            {
                "lat": 49.2254595924,
                "lng": -123.9479533085
            },
            {
                "lat": 49.2258381528,
                "lng": -123.9479142855
            },
            {
                "lat": 49.2261813888,
                "lng": -123.9478373744
            },
            {
                "lat": 49.2264898694,
                "lng": -123.9477534222
            },
            {
                "lat": 49.2266879586,
                "lng": -123.9477532446
            },
            {
                "lat": 49.2268369773,
                "lng": -123.9479987889
            },
            {
                "lat": 49.226901942,
                "lng": -123.948410989
            },
            {
                "lat": 49.2270010501,
                "lng": -123.9487839485
            },
            {
                "lat": 49.227131354,
                "lng": -123.9490663428
            },
            {
                "lat": 49.2273444835,
                "lng": -123.9492799044
            },
            {
                "lat": 49.2276529128,
                "lng": -123.9496076087
            },
            {
                "lat": 49.2280966111,
                "lng": -123.9497681162
            },
            {
                "lat": 49.2287366338,
                "lng": -123.9483711032
            },
            {
                "lat": 49.236149003,
                "lng": -123.9635620318
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 640,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 21,
            "MANAGEMENT_AREA_NAME": "Subarea 17-21",
            "LABEL": "17-21",
            "FISHERY_AREA_ID": 2212,
            "OBJECTID": 784,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1183251.3719,
            "FEATURE_LENGTH_M": 4787.163,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.226968871061544,
            "lng": -123.95218532736924
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.2453503058,
                "lng": -125.8111881537
            },
            {
                "lat": 49.2463912473,
                "lng": -125.8095930912
            },
            {
                "lat": 49.2506099203,
                "lng": -125.8044735512
            },
            {
                "lat": 49.2453503058,
                "lng": -125.8111881537
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 641,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 785,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 5671.6048,
            "FEATURE_LENGTH_M": 1525.2851,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.2469254448,
            "lng": -125.80911073745001
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1498187981,
                "lng": -125.7679663861
            },
            {
                "lat": 49.1480680034,
                "lng": -125.7692801804
            },
            {
                "lat": 49.1467594631,
                "lng": -125.7700962087
            },
            {
                "lat": 49.1456338156,
                "lng": -125.7713391564
            },
            {
                "lat": 49.144008493,
                "lng": -125.771087857
            },
            {
                "lat": 49.1399689119,
                "lng": -125.768806086
            },
            {
                "lat": 49.1371691793,
                "lng": -125.7645566637
            },
            {
                "lat": 49.13501789,
                "lng": -125.7629471581
            },
            {
                "lat": 49.134052724,
                "lng": -125.7603983426
            },
            {
                "lat": 49.1213109344,
                "lng": -125.7238996018
            },
            {
                "lat": 49.1210828934,
                "lng": -125.7221912381
            },
            {
                "lat": 49.120280693,
                "lng": -125.7180103444
            },
            {
                "lat": 49.1218338609,
                "lng": -125.7139810469
            },
            {
                "lat": 49.1223530634,
                "lng": -125.7085945619
            },
            {
                "lat": 49.1246301553,
                "lng": -125.7026903844
            },
            {
                "lat": 49.1268080527,
                "lng": -125.6934665168
            },
            {
                "lat": 49.1297069472,
                "lng": -125.6853789002
            },
            {
                "lat": 49.1314694059,
                "lng": -125.6796797923
            },
            {
                "lat": 49.1312634583,
                "lng": -125.6736757993
            },
            {
                "lat": 49.1276359697,
                "lng": -125.6656946297
            },
            {
                "lat": 49.1228708619,
                "lng": -125.6596825888
            },
            {
                "lat": 49.1210025229,
                "lng": -125.6518096738
            },
            {
                "lat": 49.1206928387,
                "lng": -125.6467282115
            },
            {
                "lat": 49.1243215589,
                "lng": -125.6444477007
            },
            {
                "lat": 49.1292947664,
                "lng": -125.6438297362
            },
            {
                "lat": 49.1348919951,
                "lng": -125.646835195
            },
            {
                "lat": 49.1413158844,
                "lng": -125.6483839748
            },
            {
                "lat": 49.1504358326,
                "lng": -125.6471400122
            },
            {
                "lat": 49.1548922123,
                "lng": -125.642379896
            },
            {
                "lat": 49.1618351133,
                "lng": -125.6378177945
            },
            {
                "lat": 49.1666035838,
                "lng": -125.6348116317
            },
            {
                "lat": 49.176757366,
                "lng": -125.6315986554
            },
            {
                "lat": 49.1822508875,
                "lng": -125.627662748
            },
            {
                "lat": 49.1863942497,
                "lng": -125.6254797829
            },
            {
                "lat": 49.1917842544,
                "lng": -125.6170880533
            },
            {
                "lat": 49.195720187,
                "lng": -125.6111832134
            },
            {
                "lat": 49.1997645751,
                "lng": -125.602584597
            },
            {
                "lat": 49.2039065525,
                "lng": -125.5970921314
            },
            {
                "lat": 49.2080533249,
                "lng": -125.595641463
            },
            {
                "lat": 49.211784486,
                "lng": -125.5954290355
            },
            {
                "lat": 49.215099959,
                "lng": -125.5933613117
            },
            {
                "lat": 49.2187274952,
                "lng": -125.5904540439
            },
            {
                "lat": 49.2269136612,
                "lng": -125.5869374909
            },
            {
                "lat": 49.2328229033,
                "lng": -125.5857926795
            },
            {
                "lat": 49.2360348762,
                "lng": -125.5849683607
            },
            {
                "lat": 49.2377928212,
                "lng": -125.5852741839
            },
            {
                "lat": 49.2393490635,
                "lng": -125.5870361095
            },
            {
                "lat": 49.2401768081,
                "lng": -125.5898361487
            },
            {
                "lat": 49.2399708577,
                "lng": -125.5934599595
            },
            {
                "lat": 49.238625067,
                "lng": -125.5981294302
            },
            {
                "lat": 49.2355157397,
                "lng": -125.6036153689
            },
            {
                "lat": 49.2315784175,
                "lng": -125.6061017776
            },
            {
                "lat": 49.2258799256,
                "lng": -125.6103521776
            },
            {
                "lat": 49.2233921113,
                "lng": -125.6141891716
            },
            {
                "lat": 49.2229764728,
                "lng": -125.6176066998
            },
            {
                "lat": 49.21758653,
                "lng": -125.6324234096
            },
            {
                "lat": 49.2128216629,
                "lng": -125.6436154878
            },
            {
                "lat": 49.2091941263,
                "lng": -125.6518096353
            },
            {
                "lat": 49.2108531764,
                "lng": -125.657195913
            },
            {
                "lat": 49.2135469263,
                "lng": -125.6578145034
            },
            {
                "lat": 49.2161370671,
                "lng": -125.6569906664
            },
            {
                "lat": 49.2195551876,
                "lng": -125.6572954218
            },
            {
                "lat": 49.2220417754,
                "lng": -125.6582336525
            },
            {
                "lat": 49.2237017122,
                "lng": -125.6605152402
            },
            {
                "lat": 49.2248416512,
                "lng": -125.6638259705
            },
            {
                "lat": 49.2246318677,
                "lng": -125.6668319373
            },
            {
                "lat": 49.2230802773,
                "lng": -125.670456011
            },
            {
                "lat": 49.2185214142,
                "lng": -125.6736758119
            },
            {
                "lat": 49.2139589604,
                "lng": -125.6777108769
            },
            {
                "lat": 49.2091941627,
                "lng": -125.6796796638
            },
            {
                "lat": 49.2053607103,
                "lng": -125.6833116453
            },
            {
                "lat": 49.2019390967,
                "lng": -125.6851735898
            },
            {
                "lat": 49.1983103716,
                "lng": -125.686729954
            },
            {
                "lat": 49.1957201174,
                "lng": -125.6884924262
            },
            {
                "lat": 49.19199031,
                "lng": -125.6899419161
            },
            {
                "lat": 49.1883616573,
                "lng": -125.6909785219
            },
            {
                "lat": 49.1846316112,
                "lng": -125.6932609192
            },
            {
                "lat": 49.1835979226,
                "lng": -125.6959535126
            },
            {
                "lat": 49.1804883931,
                "lng": -125.7000964361
            },
            {
                "lat": 49.1792450155,
                "lng": -125.7032095895
            },
            {
                "lat": 49.1789319854,
                "lng": -125.7046588134
            },
            {
                "lat": 49.1801753101,
                "lng": -125.707664489
            },
            {
                "lat": 49.1809040856,
                "lng": -125.710464555
            },
            {
                "lat": 49.1806943899,
                "lng": -125.713676432
            },
            {
                "lat": 49.1792450884,
                "lng": -125.7152250761
            },
            {
                "lat": 49.1761356863,
                "lng": -125.7160567319
            },
            {
                "lat": 49.1737515726,
                "lng": -125.7151259854
            },
            {
                "lat": 49.1710589009,
                "lng": -125.71336329
            },
            {
                "lat": 49.1687770472,
                "lng": -125.7137754717
            },
            {
                "lat": 49.1655650908,
                "lng": -125.7162633641
            },
            {
                "lat": 49.161007503,
                "lng": -125.719993436
            },
            {
                "lat": 49.1555137852,
                "lng": -125.7224812596
            },
            {
                "lat": 49.1525080772,
                "lng": -125.7246550885
            },
            {
                "lat": 49.1521948183,
                "lng": -125.727348609
            },
            {
                "lat": 49.1544765388,
                "lng": -125.7282861327
            },
            {
                "lat": 49.1597629523,
                "lng": -125.7295298406
            },
            {
                "lat": 49.1649435242,
                "lng": -125.7321167756
            },
            {
                "lat": 49.1705395281,
                "lng": -125.7365718515
            },
            {
                "lat": 49.1735454298,
                "lng": -125.7421726503
            },
            {
                "lat": 49.1736490112,
                "lng": -125.7452776935
            },
            {
                "lat": 49.171470797,
                "lng": -125.7496331556
            },
            {
                "lat": 49.169918066,
                "lng": -125.7510833181
            },
            {
                "lat": 49.1664963452,
                "lng": -125.7506710078
            },
            {
                "lat": 49.1633870741,
                "lng": -125.7489096307
            },
            {
                "lat": 49.1594511713,
                "lng": -125.7482828968
            },
            {
                "lat": 49.154788562,
                "lng": -125.7487035455
            },
            {
                "lat": 49.1521949807,
                "lng": -125.751601775
            },
            {
                "lat": 49.1507456065,
                "lng": -125.7558509844
            },
            {
                "lat": 49.1517828567,
                "lng": -125.7604063324
            },
            {
                "lat": 49.1508480321,
                "lng": -125.7649692822
            },
            {
                "lat": 49.1498187981,
                "lng": -125.7679663861
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 642,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 24,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 24-12",
            "LABEL": "24-12",
            "FISHERY_AREA_ID": 2265,
            "OBJECTID": 786,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 74338305.0019,
            "FEATURE_LENGTH_M": 51937.9693,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.177404470844145,
            "lng": -125.68204045438286
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1973383472,
                "lng": -123.1383207257
            },
            {
                "lat": 49.197692374,
                "lng": -123.1412963369
            },
            {
                "lat": 49.1995969549,
                "lng": -123.1489113333
            },
            {
                "lat": 49.2026899187,
                "lng": -123.1608135475
            },
            {
                "lat": 49.2038798375,
                "lng": -123.1774669163
            },
            {
                "lat": 49.2041207139,
                "lng": -123.1984022298
            },
            {
                "lat": 49.1896783594,
                "lng": -123.2026743329
            },
            {
                "lat": 49.1893652453,
                "lng": -123.1948549208
            },
            {
                "lat": 49.1892393898,
                "lng": -123.1917422994
            },
            {
                "lat": 49.1880030531,
                "lng": -123.1734235296
            },
            {
                "lat": 49.18917427,
                "lng": -123.1535334546
            },
            {
                "lat": 49.1973383472,
                "lng": -123.1383207257
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 643,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 787,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 6276569.9389,
            "FEATURE_LENGTH_M": 11166.3742,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.19567640091666,
            "lng": -123.16831336270832
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1319536163,
                "lng": -123.7511830116
            },
            {
                "lat": 49.1297033432,
                "lng": -123.7524711199
            },
            {
                "lat": 49.121731262,
                "lng": -123.7683410436
            },
            {
                "lat": 49.1215473166,
                "lng": -123.7683875129
            },
            {
                "lat": 49.1212727135,
                "lng": -123.7685547034
            },
            {
                "lat": 49.1210293532,
                "lng": -123.7685321406
            },
            {
                "lat": 49.1199489232,
                "lng": -123.7670967567
            },
            {
                "lat": 49.1192132497,
                "lng": -123.7659460872
            },
            {
                "lat": 49.1176113058,
                "lng": -123.7635655647
            },
            {
                "lat": 49.1161118977,
                "lng": -123.7620170612
            },
            {
                "lat": 49.1146661322,
                "lng": -123.760626926
            },
            {
                "lat": 49.1134076688,
                "lng": -123.7596898662
            },
            {
                "lat": 49.1126287193,
                "lng": -123.7592088827
            },
            {
                "lat": 49.1121059684,
                "lng": -123.7591775742
            },
            {
                "lat": 49.111678793,
                "lng": -123.7586677024
            },
            {
                "lat": 49.1008183705,
                "lng": -123.745949771
            },
            {
                "lat": 49.1004331562,
                "lng": -123.7454979825
            },
            {
                "lat": 49.0952189084,
                "lng": -123.7372903603
            },
            {
                "lat": 49.0930628893,
                "lng": -123.735298141
            },
            {
                "lat": 49.091147889,
                "lng": -123.7342607121
            },
            {
                "lat": 49.0907789175,
                "lng": -123.734008428
            },
            {
                "lat": 49.0906450231,
                "lng": -123.7340388852
            },
            {
                "lat": 49.0854261665,
                "lng": -123.7222448928
            },
            {
                "lat": 49.0827183192,
                "lng": -123.7161253969
            },
            {
                "lat": 49.0808414269,
                "lng": -123.712973589
            },
            {
                "lat": 49.080657637,
                "lng": -123.7121276298
            },
            {
                "lat": 49.0806994682,
                "lng": -123.7105626701
            },
            {
                "lat": 49.0807146817,
                "lng": -123.7083577781
            },
            {
                "lat": 49.0805282918,
                "lng": -123.7068638909
            },
            {
                "lat": 49.0799976638,
                "lng": -123.70587259
            },
            {
                "lat": 49.0777704208,
                "lng": -123.7038722741
            },
            {
                "lat": 49.074924334,
                "lng": -123.7014324815
            },
            {
                "lat": 49.0733140894,
                "lng": -123.6994636815
            },
            {
                "lat": 49.0713734587,
                "lng": -123.6967779061
            },
            {
                "lat": 49.0711441725,
                "lng": -123.6966781118
            },
            {
                "lat": 49.069907864,
                "lng": -123.6970435156
            },
            {
                "lat": 49.0689358633,
                "lng": -123.6967393779
            },
            {
                "lat": 49.0667531228,
                "lng": -123.6951446139
            },
            {
                "lat": 49.0657959828,
                "lng": -123.6940773954
            },
            {
                "lat": 49.0637249384,
                "lng": -123.6917884615
            },
            {
                "lat": 49.0626716113,
                "lng": -123.6918269447
            },
            {
                "lat": 49.0589869705,
                "lng": -123.673844121
            },
            {
                "lat": 49.0555118034,
                "lng": -123.6568823228
            },
            {
                "lat": 49.0553663652,
                "lng": -123.6527092262
            },
            {
                "lat": 49.0561557254,
                "lng": -123.6525959914
            },
            {
                "lat": 49.0590089693,
                "lng": -123.6511686987
            },
            {
                "lat": 49.0671010176,
                "lng": -123.6504514863
            },
            {
                "lat": 49.0740010413,
                "lng": -123.6544959876
            },
            {
                "lat": 49.0775679821,
                "lng": -123.6609268817
            },
            {
                "lat": 49.0854226617,
                "lng": -123.6725841092
            },
            {
                "lat": 49.0916670698,
                "lng": -123.6793360464
            },
            {
                "lat": 49.094226999,
                "lng": -123.6820992259
            },
            {
                "lat": 49.1049348161,
                "lng": -123.6951899242
            },
            {
                "lat": 49.1108813003,
                "lng": -123.7006602132
            },
            {
                "lat": 49.1170687417,
                "lng": -123.7018503079
            },
            {
                "lat": 49.1223062976,
                "lng": -123.6983570032
            },
            {
                "lat": 49.1251759288,
                "lng": -123.700142376
            },
            {
                "lat": 49.1283297006,
                "lng": -123.7020953799
            },
            {
                "lat": 49.1295777869,
                "lng": -123.7018899714
            },
            {
                "lat": 49.1299864283,
                "lng": -123.7018886236
            },
            {
                "lat": 49.1321796024,
                "lng": -123.7030796285
            },
            {
                "lat": 49.1362260827,
                "lng": -123.7059324117
            },
            {
                "lat": 49.1407465476,
                "lng": -123.7090294451
            },
            {
                "lat": 49.1409841728,
                "lng": -123.7135464541
            },
            {
                "lat": 49.1400294213,
                "lng": -123.7194985321
            },
            {
                "lat": 49.1376535023,
                "lng": -123.724021776
            },
            {
                "lat": 49.1326558351,
                "lng": -123.727111293
            },
            {
                "lat": 49.1321796476,
                "lng": -123.7337713463
            },
            {
                "lat": 49.1321795061,
                "lng": -123.7451926928
            },
            {
                "lat": 49.1319536163,
                "lng": -123.7511830116
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 662,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 17,
            "MANAGEMENT_AREA_NAME": "Subarea 17-17",
            "LABEL": "17-17",
            "FISHERY_AREA_ID": 2208,
            "OBJECTID": 806,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 32511163.0451,
            "FEATURE_LENGTH_M": 28319.611,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.09949543528287,
            "lng": -123.71544739889715
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1174274405,
                "lng": -125.8869550808
            },
            {
                "lat": 49.1216010524,
                "lng": -125.8900308216
            },
            {
                "lat": 49.1270946436,
                "lng": -125.8913496777
            },
            {
                "lat": 49.135661594,
                "lng": -125.8920064256
            },
            {
                "lat": 49.1409339951,
                "lng": -125.8931048156
            },
            {
                "lat": 49.1453286566,
                "lng": -125.8970558528
            },
            {
                "lat": 49.1486010362,
                "lng": -125.9000934871
            },
            {
                "lat": 49.1517606294,
                "lng": -125.9093939723
            },
            {
                "lat": 49.1488420675,
                "lng": -125.9082648817
            },
            {
                "lat": 49.1454312432,
                "lng": -125.9101636027
            },
            {
                "lat": 49.1395917053,
                "lng": -125.9077911257
            },
            {
                "lat": 49.1367606312,
                "lng": -125.9038696931
            },
            {
                "lat": 49.1332434852,
                "lng": -125.9001384215
            },
            {
                "lat": 49.1277545648,
                "lng": -125.8979424159
            },
            {
                "lat": 49.123798749,
                "lng": -125.8946452427
            },
            {
                "lat": 49.1194041166,
                "lng": -125.8917849801
            },
            {
                "lat": 49.1174274405,
                "lng": -125.8869550808
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 663,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 807,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2300079.2424,
            "FEATURE_LENGTH_M": 8824.4969,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.134156650064696,
            "lng": -125.89773797515882
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1426092527,
                "lng": -125.7830202928
            },
            {
                "lat": 49.1461216112,
                "lng": -125.7923126525
            },
            {
                "lat": 49.1490177704,
                "lng": -125.793731462
            },
            {
                "lat": 49.150916738,
                "lng": -125.7942810444
            },
            {
                "lat": 49.1510655497,
                "lng": -125.7950215355
            },
            {
                "lat": 49.1512146403,
                "lng": -125.7962113493
            },
            {
                "lat": 49.151214642,
                "lng": -125.7975532952
            },
            {
                "lat": 49.1509167245,
                "lng": -125.7990411564
            },
            {
                "lat": 49.1509899345,
                "lng": -125.8010486142
            },
            {
                "lat": 49.1508408362,
                "lng": -125.8038024501
            },
            {
                "lat": 49.1498758931,
                "lng": -125.8065493758
            },
            {
                "lat": 49.1480144675,
                "lng": -125.8109438088
            },
            {
                "lat": 49.1448175979,
                "lng": -125.8165215138
            },
            {
                "lat": 49.1412472568,
                "lng": -125.8203124216
            },
            {
                "lat": 49.1374507438,
                "lng": -125.822990487
            },
            {
                "lat": 49.1362609731,
                "lng": -125.8223952723
            },
            {
                "lat": 49.1353681109,
                "lng": -125.8186796361
            },
            {
                "lat": 49.1360399685,
                "lng": -125.814284609
            },
            {
                "lat": 49.1372297236,
                "lng": -125.8095245752
            },
            {
                "lat": 49.1383438733,
                "lng": -125.8029096823
            },
            {
                "lat": 49.1371539626,
                "lng": -125.7994077284
            },
            {
                "lat": 49.1339571287,
                "lng": -125.796806185
            },
            {
                "lat": 49.1291957522,
                "lng": -125.7959133673
            },
            {
                "lat": 49.1233250675,
                "lng": -125.7960203595
            },
            {
                "lat": 49.1224670853,
                "lng": -125.7960283875
            },
            {
                "lat": 49.1209559476,
                "lng": -125.7960589541
            },
            {
                "lat": 49.1132657168,
                "lng": -125.7960507321
            },
            {
                "lat": 49.108860292,
                "lng": -125.7984162907
            },
            {
                "lat": 49.107540348,
                "lng": -125.7929309969
            },
            {
                "lat": 49.105342353,
                "lng": -125.7898482603
            },
            {
                "lat": 49.100071139,
                "lng": -125.786339616
            },
            {
                "lat": 49.0941385551,
                "lng": -125.7834776456
            },
            {
                "lat": 49.0904038177,
                "lng": -125.7773290728
            },
            {
                "lat": 49.0860105453,
                "lng": -125.7674405613
            },
            {
                "lat": 49.0835948495,
                "lng": -125.75953738
            },
            {
                "lat": 49.081397919,
                "lng": -125.7540436874
            },
            {
                "lat": 49.078541347,
                "lng": -125.7549209439
            },
            {
                "lat": 49.0743676533,
                "lng": -125.7544789405
            },
            {
                "lat": 49.070854375,
                "lng": -125.7448115625
            },
            {
                "lat": 49.0719485291,
                "lng": -125.7342676514
            },
            {
                "lat": 49.0754666692,
                "lng": -125.7285612738
            },
            {
                "lat": 49.0840336877,
                "lng": -125.7206498888
            },
            {
                "lat": 49.0932619349,
                "lng": -125.7193295047
            },
            {
                "lat": 49.0985331647,
                "lng": -125.7224043423
            },
            {
                "lat": 49.1031454949,
                "lng": -125.7373428093
            },
            {
                "lat": 49.106441225,
                "lng": -125.7366872355
            },
            {
                "lat": 49.1075401672,
                "lng": -125.7237238517
            },
            {
                "lat": 49.108639303,
                "lng": -125.7171325706
            },
            {
                "lat": 49.1132550283,
                "lng": -125.7155994945
            },
            {
                "lat": 49.1180841345,
                "lng": -125.7169118492
            },
            {
                "lat": 49.120280693,
                "lng": -125.7180103444
            },
            {
                "lat": 49.1210828934,
                "lng": -125.7221912381
            },
            {
                "lat": 49.1213109344,
                "lng": -125.7238996018
            },
            {
                "lat": 49.134052724,
                "lng": -125.7603983426
            },
            {
                "lat": 49.13501789,
                "lng": -125.7629471581
            },
            {
                "lat": 49.1426092527,
                "lng": -125.7830202928
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 664,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 24,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 24-11",
            "LABEL": "24-11",
            "FISHERY_AREA_ID": 2264,
            "OBJECTID": 808,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 32405120.5529,
            "FEATURE_LENGTH_M": 29406.8702,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.119923283724994,
            "lng": -125.7747155956375
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1251759288,
                "lng": -123.700142376
            },
            {
                "lat": 49.1223062976,
                "lng": -123.6983570032
            },
            {
                "lat": 49.1170687417,
                "lng": -123.7018503079
            },
            {
                "lat": 49.1108813003,
                "lng": -123.7006602132
            },
            {
                "lat": 49.1049348161,
                "lng": -123.6951899242
            },
            {
                "lat": 49.094226999,
                "lng": -123.6820992259
            },
            {
                "lat": 49.0916670698,
                "lng": -123.6793360464
            },
            {
                "lat": 49.0854226617,
                "lng": -123.6725841092
            },
            {
                "lat": 49.0775679821,
                "lng": -123.6609268817
            },
            {
                "lat": 49.0740010413,
                "lng": -123.6544959876
            },
            {
                "lat": 49.0671010176,
                "lng": -123.6504514863
            },
            {
                "lat": 49.0590089693,
                "lng": -123.6511686987
            },
            {
                "lat": 49.0561557254,
                "lng": -123.6525959914
            },
            {
                "lat": 49.0553663652,
                "lng": -123.6527092262
            },
            {
                "lat": 49.0541418317,
                "lng": -123.6512908519
            },
            {
                "lat": 49.0518726386,
                "lng": -123.6465295115
            },
            {
                "lat": 49.0463978781,
                "lng": -123.640579106
            },
            {
                "lat": 49.0440217709,
                "lng": -123.6393887715
            },
            {
                "lat": 49.0420915892,
                "lng": -123.6351312365
            },
            {
                "lat": 49.041145019,
                "lng": -123.6313178565
            },
            {
                "lat": 49.0394595456,
                "lng": -123.626990361
            },
            {
                "lat": 49.0360835012,
                "lng": -123.6233899867
            },
            {
                "lat": 49.0355305297,
                "lng": -123.6229022327
            },
            {
                "lat": 49.0326352026,
                "lng": -123.6203080037
            },
            {
                "lat": 49.0272755135,
                "lng": -123.61450918
            },
            {
                "lat": 49.021327802,
                "lng": -123.6078332204
            },
            {
                "lat": 49.0178027953,
                "lng": -123.6019599859
            },
            {
                "lat": 49.0185396981,
                "lng": -123.5987319918
            },
            {
                "lat": 49.0215488278,
                "lng": -123.597625831
            },
            {
                "lat": 49.025657298,
                "lng": -123.5984339645
            },
            {
                "lat": 49.0292776278,
                "lng": -123.5992434461
            },
            {
                "lat": 49.030216187,
                "lng": -123.5982744214
            },
            {
                "lat": 49.0305210606,
                "lng": -123.5996714172
            },
            {
                "lat": 49.0318983466,
                "lng": -123.6012643925
            },
            {
                "lat": 49.0335315439,
                "lng": -123.6031876811
            },
            {
                "lat": 49.0397947577,
                "lng": -123.61066361
            },
            {
                "lat": 49.0481264823,
                "lng": -123.6165457434
            },
            {
                "lat": 49.0707286518,
                "lng": -123.6406552466
            },
            {
                "lat": 49.0746308291,
                "lng": -123.6469429319
            },
            {
                "lat": 49.0818404676,
                "lng": -123.6578070284
            },
            {
                "lat": 49.0890546086,
                "lng": -123.6672888559
            },
            {
                "lat": 49.0934982983,
                "lng": -123.6733180715
            },
            {
                "lat": 49.1001164487,
                "lng": -123.674797378
            },
            {
                "lat": 49.1050489449,
                "lng": -123.6772611374
            },
            {
                "lat": 49.1235882401,
                "lng": -123.6970373407
            },
            {
                "lat": 49.1251759288,
                "lng": -123.700142376
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 665,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 809,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 13013022.0483,
            "FEATURE_LENGTH_M": 29555.4385,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.06311879958697,
            "lng": -123.64507805755657
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1456338156,
                "lng": -125.7713391564
            },
            {
                "lat": 49.1446335577,
                "lng": -125.7740250118
            },
            {
                "lat": 49.1437001132,
                "lng": -125.7770843765
            },
            {
                "lat": 49.1428874918,
                "lng": -125.780952521
            },
            {
                "lat": 49.1426092527,
                "lng": -125.7830202928
            },
            {
                "lat": 49.13501789,
                "lng": -125.7629471581
            },
            {
                "lat": 49.1371691793,
                "lng": -125.7645566637
            },
            {
                "lat": 49.1399689119,
                "lng": -125.768806086
            },
            {
                "lat": 49.144008493,
                "lng": -125.771087857
            },
            {
                "lat": 49.1456338156,
                "lng": -125.7713391564
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 666,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 810,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 504296.2489,
            "FEATURE_LENGTH_M": 3980.0661,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.14212625207999,
            "lng": -125.77251582797001
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.121731262,
                "lng": -123.7683410436
            },
            {
                "lat": 49.1061094679,
                "lng": -123.7884598437
            },
            {
                "lat": 49.0966531545,
                "lng": -123.8006444547
            },
            {
                "lat": 49.0968359649,
                "lng": -123.80087187
            },
            {
                "lat": 49.0971139799,
                "lng": -123.8011854489
            },
            {
                "lat": 49.0975413691,
                "lng": -123.8018255308
            },
            {
                "lat": 49.0979348022,
                "lng": -123.8024432789
            },
            {
                "lat": 49.1011922837,
                "lng": -123.8082734085
            },
            {
                "lat": 49.097140794,
                "lng": -123.8120338409
            },
            {
                "lat": 49.0904888112,
                "lng": -123.8116843942
            },
            {
                "lat": 49.0727727524,
                "lng": -123.7969811113
            },
            {
                "lat": 49.0533756767,
                "lng": -123.7765124079
            },
            {
                "lat": 49.0404163091,
                "lng": -123.7667309674
            },
            {
                "lat": 49.0401685007,
                "lng": -123.7515787186
            },
            {
                "lat": 49.0399133654,
                "lng": -123.7468330173
            },
            {
                "lat": 49.0389216041,
                "lng": -123.7438126329
            },
            {
                "lat": 49.0210832329,
                "lng": -123.6894696982
            },
            {
                "lat": 49.0085138779,
                "lng": -123.6439592576
            },
            {
                "lat": 49.0410577963,
                "lng": -123.6438204625
            },
            {
                "lat": 49.0440217709,
                "lng": -123.6393887715
            },
            {
                "lat": 49.0463978781,
                "lng": -123.640579106
            },
            {
                "lat": 49.0518726386,
                "lng": -123.6465295115
            },
            {
                "lat": 49.0541418317,
                "lng": -123.6512908519
            },
            {
                "lat": 49.0553663652,
                "lng": -123.6527092262
            },
            {
                "lat": 49.0555118034,
                "lng": -123.6568823228
            },
            {
                "lat": 49.0589869705,
                "lng": -123.673844121
            },
            {
                "lat": 49.0626716113,
                "lng": -123.6918269447
            },
            {
                "lat": 49.0637249384,
                "lng": -123.6917884615
            },
            {
                "lat": 49.0657959828,
                "lng": -123.6940773954
            },
            {
                "lat": 49.0667531228,
                "lng": -123.6951446139
            },
            {
                "lat": 49.0689358633,
                "lng": -123.6967393779
            },
            {
                "lat": 49.069907864,
                "lng": -123.6970435156
            },
            {
                "lat": 49.0711441725,
                "lng": -123.6966781118
            },
            {
                "lat": 49.0713734587,
                "lng": -123.6967779061
            },
            {
                "lat": 49.0733140894,
                "lng": -123.6994636815
            },
            {
                "lat": 49.074924334,
                "lng": -123.7014324815
            },
            {
                "lat": 49.0777704208,
                "lng": -123.7038722741
            },
            {
                "lat": 49.0799976638,
                "lng": -123.70587259
            },
            {
                "lat": 49.0805282918,
                "lng": -123.7068638909
            },
            {
                "lat": 49.0807146817,
                "lng": -123.7083577781
            },
            {
                "lat": 49.0806994682,
                "lng": -123.7105626701
            },
            {
                "lat": 49.080657637,
                "lng": -123.7121276298
            },
            {
                "lat": 49.0808414269,
                "lng": -123.712973589
            },
            {
                "lat": 49.0827183192,
                "lng": -123.7161253969
            },
            {
                "lat": 49.0854261665,
                "lng": -123.7222448928
            },
            {
                "lat": 49.0906450231,
                "lng": -123.7340388852
            },
            {
                "lat": 49.0907789175,
                "lng": -123.734008428
            },
            {
                "lat": 49.091147889,
                "lng": -123.7342607121
            },
            {
                "lat": 49.0930628893,
                "lng": -123.735298141
            },
            {
                "lat": 49.0952189084,
                "lng": -123.7372903603
            },
            {
                "lat": 49.1004331562,
                "lng": -123.7454979825
            },
            {
                "lat": 49.1008183705,
                "lng": -123.745949771
            },
            {
                "lat": 49.111678793,
                "lng": -123.7586677024
            },
            {
                "lat": 49.1121059684,
                "lng": -123.7591775742
            },
            {
                "lat": 49.1126287193,
                "lng": -123.7592088827
            },
            {
                "lat": 49.1134076688,
                "lng": -123.7596898662
            },
            {
                "lat": 49.1146661322,
                "lng": -123.760626926
            },
            {
                "lat": 49.1161118977,
                "lng": -123.7620170612
            },
            {
                "lat": 49.1176113058,
                "lng": -123.7635655647
            },
            {
                "lat": 49.1192132497,
                "lng": -123.7659460872
            },
            {
                "lat": 49.1199489232,
                "lng": -123.7670967567
            },
            {
                "lat": 49.1210293532,
                "lng": -123.7685321406
            },
            {
                "lat": 49.1212727135,
                "lng": -123.7685547034
            },
            {
                "lat": 49.1215473166,
                "lng": -123.7683875129
            },
            {
                "lat": 49.121731262,
                "lng": -123.7683410436
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 667,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 17-4",
            "LABEL": "17-4",
            "FISHERY_AREA_ID": 2195,
            "OBJECTID": 811,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 67128476.8765,
            "FEATURE_LENGTH_M": 39625.9131,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.08197264975076,
            "lng": -123.73188945542002
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.0381661442,
                "lng": -123.2563093946
            },
            {
                "lat": 49.0876924822,
                "lng": -123.3102487842
            },
            {
                "lat": 49.0020785696,
                "lng": -123.3014057475
            },
            {
                "lat": 49.0020598505,
                "lng": -123.1282565279
            },
            {
                "lat": 49.0381661442,
                "lng": -123.2563093946
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 668,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 29-6",
            "LABEL": "29-6",
            "FISHERY_AREA_ID": 2531,
            "OBJECTID": 812,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 42401078.6356,
            "FEATURE_LENGTH_M": 39186.7734,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.03363263814,
            "lng": -123.25050596976
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1106183637,
                "lng": -125.8753133823
            },
            {
                "lat": 49.107982744,
                "lng": -125.8724517831
            },
            {
                "lat": 49.1053422446,
                "lng": -125.8700411067
            },
            {
                "lat": 49.0989721807,
                "lng": -125.8687210083
            },
            {
                "lat": 49.0941384015,
                "lng": -125.8706972071
            },
            {
                "lat": 49.09282318,
                "lng": -125.8742139131
            },
            {
                "lat": 49.0893060951,
                "lng": -125.866523463
            },
            {
                "lat": 49.0866706166,
                "lng": -125.8570794915
            },
            {
                "lat": 49.0802992044,
                "lng": -125.8399426386
            },
            {
                "lat": 49.0739288128,
                "lng": -125.8155513755
            },
            {
                "lat": 49.0719485812,
                "lng": -125.8006127338
            },
            {
                "lat": 49.0726088292,
                "lng": -125.793585965
            },
            {
                "lat": 49.0765645522,
                "lng": -125.7832636307
            },
            {
                "lat": 49.0767821738,
                "lng": -125.7733764366
            },
            {
                "lat": 49.0752488857,
                "lng": -125.7645874459
            },
            {
                "lat": 49.0743676533,
                "lng": -125.7544789405
            },
            {
                "lat": 49.078541347,
                "lng": -125.7549209439
            },
            {
                "lat": 49.081397919,
                "lng": -125.7540436874
            },
            {
                "lat": 49.0835948495,
                "lng": -125.75953738
            },
            {
                "lat": 49.0860105453,
                "lng": -125.7674405613
            },
            {
                "lat": 49.0904038177,
                "lng": -125.7773290728
            },
            {
                "lat": 49.0941385551,
                "lng": -125.7834776456
            },
            {
                "lat": 49.100071139,
                "lng": -125.786339616
            },
            {
                "lat": 49.105342353,
                "lng": -125.7898482603
            },
            {
                "lat": 49.107540348,
                "lng": -125.7929309969
            },
            {
                "lat": 49.108860292,
                "lng": -125.7984162907
            },
            {
                "lat": 49.1123737031,
                "lng": -125.8034744992
            },
            {
                "lat": 49.1156695982,
                "lng": -125.812476754
            },
            {
                "lat": 49.1172063105,
                "lng": -125.8201674317
            },
            {
                "lat": 49.116767373,
                "lng": -125.8261033454
            },
            {
                "lat": 49.1154483598,
                "lng": -125.8263160846
            },
            {
                "lat": 49.1132552373,
                "lng": -125.8230285746
            },
            {
                "lat": 49.1099581597,
                "lng": -125.8170923441
            },
            {
                "lat": 49.104683446,
                "lng": -125.8131408291
            },
            {
                "lat": 49.1002922516,
                "lng": -125.8129190697
            },
            {
                "lat": 49.0963364142,
                "lng": -125.8203881716
            },
            {
                "lat": 49.0923807051,
                "lng": -125.8300552709
            },
            {
                "lat": 49.0934795643,
                "lng": -125.8375245216
            },
            {
                "lat": 49.0919419004,
                "lng": -125.8432387174
            },
            {
                "lat": 49.0941384105,
                "lng": -125.8509218037
            },
            {
                "lat": 49.0974354868,
                "lng": -125.8594887519
            },
            {
                "lat": 49.1022677284,
                "lng": -125.8627861836
            },
            {
                "lat": 49.1092980792,
                "lng": -125.8643259671
            },
            {
                "lat": 49.1108359743,
                "lng": -125.8660812607
            },
            {
                "lat": 49.1106182119,
                "lng": -125.8713611808
            },
            {
                "lat": 49.1106183637,
                "lng": -125.8753133823
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 669,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 813,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 18982879.5099,
            "FEATURE_LENGTH_M": 27835.5388,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.09648932526956,
            "lng": -125.8213245461
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1027836706,
                "lng": -124.8080740793
            },
            {
                "lat": 49.1042632334,
                "lng": -124.8101499977
            },
            {
                "lat": 49.1039312968,
                "lng": -124.8126758354
            },
            {
                "lat": 49.1036951705,
                "lng": -124.8144296301
            },
            {
                "lat": 49.1036951119,
                "lng": -124.8309017885
            },
            {
                "lat": 49.1065250945,
                "lng": -124.8317936297
            },
            {
                "lat": 49.106670554,
                "lng": -124.8337636152
            },
            {
                "lat": 49.1064493851,
                "lng": -124.837035721
            },
            {
                "lat": 49.1052854489,
                "lng": -124.839226475
            },
            {
                "lat": 49.1022993522,
                "lng": -124.84039192
            },
            {
                "lat": 49.0983620612,
                "lng": -124.8400265257
            },
            {
                "lat": 49.0947952222,
                "lng": -124.838638046
            },
            {
                "lat": 49.0926820973,
                "lng": -124.8392258635
            },
            {
                "lat": 49.0888217681,
                "lng": -124.8427959238
            },
            {
                "lat": 49.0842281608,
                "lng": -124.8475271463
            },
            {
                "lat": 49.0816798231,
                "lng": -124.8529967734
            },
            {
                "lat": 49.0804398675,
                "lng": -124.8553997738
            },
            {
                "lat": 49.077313059,
                "lng": -124.8616791244
            },
            {
                "lat": 49.0752373025,
                "lng": -124.8676073711
            },
            {
                "lat": 49.073166263,
                "lng": -124.8770828697
            },
            {
                "lat": 49.0722769473,
                "lng": -124.8827063094
            },
            {
                "lat": 49.0710940941,
                "lng": -124.8868565956
            },
            {
                "lat": 49.0707972989,
                "lng": -124.8909975516
            },
            {
                "lat": 49.0710940514,
                "lng": -124.8972246523
            },
            {
                "lat": 49.0690197063,
                "lng": -124.9016637488
            },
            {
                "lat": 49.0636901677,
                "lng": -124.9037405727
            },
            {
                "lat": 49.0592465163,
                "lng": -124.9010687469
            },
            {
                "lat": 49.0550998029,
                "lng": -124.8883351263
            },
            {
                "lat": 49.0542103697,
                "lng": -124.8761897343
            },
            {
                "lat": 49.0536190099,
                "lng": -124.8702693741
            },
            {
                "lat": 49.0497702006,
                "lng": -124.8676073479
            },
            {
                "lat": 49.0441439365,
                "lng": -124.869385269
            },
            {
                "lat": 49.0334813908,
                "lng": -124.8708645473
            },
            {
                "lat": 49.0266688392,
                "lng": -124.872046942
            },
            {
                "lat": 49.0219307445,
                "lng": -124.8770831182
            },
            {
                "lat": 49.0195582228,
                "lng": -124.8838966782
            },
            {
                "lat": 49.0145267767,
                "lng": -124.892189413
            },
            {
                "lat": 49.0077130677,
                "lng": -124.8963308997
            },
            {
                "lat": 49.0014951834,
                "lng": -124.9019621651
            },
            {
                "lat": 48.9967571462,
                "lng": -124.906105328
            },
            {
                "lat": 48.992313572,
                "lng": -124.912025289
            },
            {
                "lat": 48.9902377595,
                "lng": -124.9161747711
            },
            {
                "lat": 48.9859432766,
                "lng": -124.9187085971
            },
            {
                "lat": 48.9833869088,
                "lng": -124.9177477584
            },
            {
                "lat": 48.9743610329,
                "lng": -124.9127281386
            },
            {
                "lat": 48.9663202253,
                "lng": -124.9097215947
            },
            {
                "lat": 48.9680294544,
                "lng": -124.9016640904
            },
            {
                "lat": 48.9712832415,
                "lng": -124.894256635
            },
            {
                "lat": 48.9742471226,
                "lng": -124.8915943076
            },
            {
                "lat": 48.9798734537,
                "lng": -124.8868557209
            },
            {
                "lat": 48.9831318523,
                "lng": -124.8759007466
            },
            {
                "lat": 48.9887583684,
                "lng": -124.8640524698
            },
            {
                "lat": 48.9934963653,
                "lng": -124.8560567526
            },
            {
                "lat": 49.0056385787,
                "lng": -124.8507232334
            },
            {
                "lat": 49.0165978072,
                "lng": -124.84509187
            },
            {
                "lat": 49.0314069273,
                "lng": -124.838873752
            },
            {
                "lat": 49.0373302571,
                "lng": -124.8335417212
            },
            {
                "lat": 49.0423663952,
                "lng": -124.8320614293
            },
            {
                "lat": 49.0506597294,
                "lng": -124.8311773031
            },
            {
                "lat": 49.0610241589,
                "lng": -124.8279189422
            },
            {
                "lat": 49.0693164569,
                "lng": -124.8231802039
            },
            {
                "lat": 49.0782013582,
                "lng": -124.8169633697
            },
            {
                "lat": 49.0858998705,
                "lng": -124.8113334359
            },
            {
                "lat": 49.0918231068,
                "lng": -124.8089682466
            },
            {
                "lat": 49.0965611596,
                "lng": -124.8080742518
            },
            {
                "lat": 49.1007077355,
                "lng": -124.8065949926
            },
            {
                "lat": 49.1027836706,
                "lng": -124.8080740793
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 670,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 23,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 23-2",
            "LABEL": "23-2",
            "FISHERY_AREA_ID": 2244,
            "OBJECTID": 814,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 47532726.4103,
            "FEATURE_LENGTH_M": 42190.4403,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.05030175015971,
            "lng": -124.86146283481786
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9806476136,
                "lng": -125.0214616003
            },
            {
                "lat": 48.9803308636,
                "lng": -125.0211339452
            },
            {
                "lat": 48.9799153499,
                "lng": -125.0207452596
            },
            {
                "lat": 48.9779280705,
                "lng": -125.0173862007
            },
            {
                "lat": 48.9760666417,
                "lng": -125.0134040012
            },
            {
                "lat": 48.9755357914,
                "lng": -125.0094211912
            },
            {
                "lat": 48.9811168234,
                "lng": -125.0080867606
            },
            {
                "lat": 48.9864310911,
                "lng": -125.0088882107
            },
            {
                "lat": 48.9925382336,
                "lng": -125.015526346
            },
            {
                "lat": 48.9949724141,
                "lng": -125.0196600859
            },
            {
                "lat": 48.9970552596,
                "lng": -125.026954599
            },
            {
                "lat": 48.9991833444,
                "lng": -125.0365215337
            },
            {
                "lat": 49.0015719907,
                "lng": -125.0468825609
            },
            {
                "lat": 49.0007745901,
                "lng": -125.0521934785
            },
            {
                "lat": 48.9986498936,
                "lng": -125.0593634757
            },
            {
                "lat": 48.9951971242,
                "lng": -125.0702598609
            },
            {
                "lat": 48.9880260995,
                "lng": -125.081947615
            },
            {
                "lat": 48.9840386242,
                "lng": -125.0912485636
            },
            {
                "lat": 48.9827081906,
                "lng": -125.1045305426
            },
            {
                "lat": 48.987228563,
                "lng": -125.1151579107
            },
            {
                "lat": 48.9939502858,
                "lng": -125.120247589
            },
            {
                "lat": 49.0011331263,
                "lng": -125.125335208
            },
            {
                "lat": 49.0065733157,
                "lng": -125.1327503577
            },
            {
                "lat": 49.014980814,
                "lng": -125.1381907234
            },
            {
                "lat": 49.0204195951,
                "lng": -125.1391827721
            },
            {
                "lat": 49.0263522806,
                "lng": -125.131768205
            },
            {
                "lat": 49.0387155983,
                "lng": -125.1297843603
            },
            {
                "lat": 49.0486073661,
                "lng": -125.1307749335
            },
            {
                "lat": 49.0634420038,
                "lng": -125.1367107329
            },
            {
                "lat": 49.0748136509,
                "lng": -125.14264563
            },
            {
                "lat": 49.0807494858,
                "lng": -125.1465992019
            },
            {
                "lat": 49.0911328341,
                "lng": -125.1609421299
            },
            {
                "lat": 49.1030002089,
                "lng": -125.1846769923
            },
            {
                "lat": 49.1064610206,
                "lng": -125.2034688363
            },
            {
                "lat": 49.1034958976,
                "lng": -125.2093968492
            },
            {
                "lat": 49.0975610582,
                "lng": -125.2113800399
            },
            {
                "lat": 49.0906366338,
                "lng": -125.2074199731
            },
            {
                "lat": 49.0866809847,
                "lng": -125.2019812814
            },
            {
                "lat": 49.0817380613,
                "lng": -125.190612448
            },
            {
                "lat": 49.0758020142,
                "lng": -125.1767649253
            },
            {
                "lat": 49.0693735431,
                "lng": -125.1703338481
            },
            {
                "lat": 49.0634420788,
                "lng": -125.1668787617
            },
            {
                "lat": 49.05800208,
                "lng": -125.1747899619
            },
            {
                "lat": 49.0515748553,
                "lng": -125.1752777566
            },
            {
                "lat": 49.0426714101,
                "lng": -125.1703339841
            },
            {
                "lat": 49.03426741,
                "lng": -125.1663820381
            },
            {
                "lat": 49.0273440035,
                "lng": -125.1693499634
            },
            {
                "lat": 49.0243754006,
                "lng": -125.1772606866
            },
            {
                "lat": 49.0159680226,
                "lng": -125.1915958956
            },
            {
                "lat": 49.0115209502,
                "lng": -125.1970368955
            },
            {
                "lat": 48.9966849504,
                "lng": -125.200004696
            },
            {
                "lat": 48.982837212,
                "lng": -125.2004993887
            },
            {
                "lat": 48.9709700388,
                "lng": -125.2089076817
            },
            {
                "lat": 48.9625658306,
                "lng": -125.2187965885
            },
            {
                "lat": 48.9551468174,
                "lng": -125.2306668421
            },
            {
                "lat": 48.9505123329,
                "lng": -125.2401582641
            },
            {
                "lat": 48.9477999686,
                "lng": -125.2400063513
            },
            {
                "lat": 48.9458882042,
                "lng": -125.2416070311
            },
            {
                "lat": 48.9449498644,
                "lng": -125.241744954
            },
            {
                "lat": 48.9439883781,
                "lng": -125.241714534
            },
            {
                "lat": 48.9256938302,
                "lng": -125.2221669761
            },
            {
                "lat": 48.866226576,
                "lng": -125.172515513
            },
            {
                "lat": 48.9549592378,
                "lng": -125.1528625472
            },
            {
                "lat": 48.9590269457,
                "lng": -125.0820398924
            },
            {
                "lat": 48.9532854009,
                "lng": -125.0684974441
            },
            {
                "lat": 48.9568825641,
                "lng": -125.0724181382
            },
            {
                "lat": 48.9595949844,
                "lng": -125.07537837
            },
            {
                "lat": 48.9655566882,
                "lng": -125.0818860552
            },
            {
                "lat": 48.9659884768,
                "lng": -125.0820310237
            },
            {
                "lat": 48.966422875,
                "lng": -125.081528031
            },
            {
                "lat": 48.9670445588,
                "lng": -125.0802233797
            },
            {
                "lat": 48.9682890209,
                "lng": -125.0764391346
            },
            {
                "lat": 48.9727860093,
                "lng": -125.0586018001
            },
            {
                "lat": 48.9758722956,
                "lng": -125.0485153502
            },
            {
                "lat": 48.9792901182,
                "lng": -125.0461040703
            },
            {
                "lat": 48.9810410192,
                "lng": -125.0444343745
            },
            {
                "lat": 48.981872159,
                "lng": -125.0421830252
            },
            {
                "lat": 48.9827883892,
                "lng": -125.0391009032
            },
            {
                "lat": 48.9833716286,
                "lng": -125.0357662688
            },
            {
                "lat": 48.9842914636,
                "lng": -125.0341029866
            },
            {
                "lat": 48.9847071123,
                "lng": -125.033012968
            },
            {
                "lat": 48.9850388704,
                "lng": -125.0322641084
            },
            {
                "lat": 48.9850386519,
                "lng": -125.0309302331
            },
            {
                "lat": 48.9840387572,
                "lng": -125.0286789937
            },
            {
                "lat": 48.9823728239,
                "lng": -125.0258481704
            },
            {
                "lat": 48.9819560158,
                "lng": -125.0242621092
            },
            {
                "lat": 48.9815405287,
                "lng": -125.0231024491
            },
            {
                "lat": 48.9815440035,
                "lng": -125.0220943644
            },
            {
                "lat": 48.9813878488,
                "lng": -125.0212635956
            },
            {
                "lat": 48.9806476136,
                "lng": -125.0214616003
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 671,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 23,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 23-6",
            "LABEL": "23-6",
            "FISHERY_AREA_ID": 2248,
            "OBJECTID": 815,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 123050577.8663,
            "FEATURE_LENGTH_M": 83468.5576,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.00049558475332,
            "lng": -125.11049407700662
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.0639765249,
                "lng": -123.1466831937
            },
            {
                "lat": 49.0682338685,
                "lng": -123.1527477989
            },
            {
                "lat": 49.0381661442,
                "lng": -123.2563093946
            },
            {
                "lat": 49.0020598505,
                "lng": -123.1282565279
            },
            {
                "lat": 49.0020563303,
                "lng": -123.0907063158
            },
            {
                "lat": 49.0039480837,
                "lng": -123.0868143578
            },
            {
                "lat": 49.0188363662,
                "lng": -123.0849297484
            },
            {
                "lat": 49.0323184073,
                "lng": -123.0861888137
            },
            {
                "lat": 49.0442277794,
                "lng": -123.0943373856
            },
            {
                "lat": 49.0531614593,
                "lng": -123.1070325194
            },
            {
                "lat": 49.0580219218,
                "lng": -123.1252139405
            },
            {
                "lat": 49.0639765249,
                "lng": -123.1466831937
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 672,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 29-7",
            "LABEL": "29-7",
            "FISHERY_AREA_ID": 2532,
            "OBJECTID": 816,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 52906167.2853,
            "FEATURE_LENGTH_M": 31345.6998,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.03741527175,
            "lng": -123.12549193250003
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.0440217709,
                "lng": -123.6393887715
            },
            {
                "lat": 49.0410577963,
                "lng": -123.6438204625
            },
            {
                "lat": 49.0085138779,
                "lng": -123.6439592576
            },
            {
                "lat": 49.0060273145,
                "lng": -123.6281438322
            },
            {
                "lat": 48.991310031,
                "lng": -123.6168298057
            },
            {
                "lat": 48.9863392315,
                "lng": -123.6082604463
            },
            {
                "lat": 48.9790491667,
                "lng": -123.6005935727
            },
            {
                "lat": 48.9844172297,
                "lng": -123.5755388346
            },
            {
                "lat": 48.9844823677,
                "lng": -123.5754313532
            },
            {
                "lat": 48.9842948605,
                "lng": -123.5752567822
            },
            {
                "lat": 48.9840703091,
                "lng": -123.5750500057
            },
            {
                "lat": 48.9836696331,
                "lng": -123.5745247259
            },
            {
                "lat": 48.98320744,
                "lng": -123.5731971807
            },
            {
                "lat": 48.9829524626,
                "lng": -123.5721814143
            },
            {
                "lat": 48.9826815016,
                "lng": -123.5711067628
            },
            {
                "lat": 48.9869806758,
                "lng": -123.5712125137
            },
            {
                "lat": 48.9908071333,
                "lng": -123.5751882529
            },
            {
                "lat": 48.9933019059,
                "lng": -123.5768508957
            },
            {
                "lat": 48.9954451114,
                "lng": -123.5813757223
            },
            {
                "lat": 48.9992519873,
                "lng": -123.5844646162
            },
            {
                "lat": 49.0018467495,
                "lng": -123.5847622562
            },
            {
                "lat": 49.0050435595,
                "lng": -123.5820171117
            },
            {
                "lat": 49.0070424921,
                "lng": -123.5803615534
            },
            {
                "lat": 49.0084114844,
                "lng": -123.5767664604
            },
            {
                "lat": 49.0100168036,
                "lng": -123.5751873519
            },
            {
                "lat": 49.0112111097,
                "lng": -123.5740205269
            },
            {
                "lat": 49.0123368754,
                "lng": -123.5736700094
            },
            {
                "lat": 49.0124277922,
                "lng": -123.5735696583
            },
            {
                "lat": 49.0125582953,
                "lng": -123.5734399272
            },
            {
                "lat": 49.0126955289,
                "lng": -123.5733337515
            },
            {
                "lat": 49.0127607032,
                "lng": -123.5732654742
            },
            {
                "lat": 49.0128130333,
                "lng": -123.5731893267
            },
            {
                "lat": 49.0130039466,
                "lng": -123.5730666972
            },
            {
                "lat": 49.0131494714,
                "lng": -123.5730283922
            },
            {
                "lat": 49.0135303578,
                "lng": -123.5733637666
            },
            {
                "lat": 49.014052855,
                "lng": -123.5737993643
            },
            {
                "lat": 49.0142405049,
                "lng": -123.5739057274
            },
            {
                "lat": 49.0152892498,
                "lng": -123.5755161862
            },
            {
                "lat": 49.0282521021,
                "lng": -123.5953453136
            },
            {
                "lat": 49.0291671951,
                "lng": -123.5963213344
            },
            {
                "lat": 49.030216187,
                "lng": -123.5982744214
            },
            {
                "lat": 49.0292776278,
                "lng": -123.5992434461
            },
            {
                "lat": 49.025657298,
                "lng": -123.5984339645
            },
            {
                "lat": 49.0215488278,
                "lng": -123.597625831
            },
            {
                "lat": 49.0185396981,
                "lng": -123.5987319918
            },
            {
                "lat": 49.0178027953,
                "lng": -123.6019599859
            },
            {
                "lat": 49.021327802,
                "lng": -123.6078332204
            },
            {
                "lat": 49.0272755135,
                "lng": -123.61450918
            },
            {
                "lat": 49.0326352026,
                "lng": -123.6203080037
            },
            {
                "lat": 49.0355305297,
                "lng": -123.6229022327
            },
            {
                "lat": 49.0360835012,
                "lng": -123.6233899867
            },
            {
                "lat": 49.0394595456,
                "lng": -123.626990361
            },
            {
                "lat": 49.041145019,
                "lng": -123.6313178565
            },
            {
                "lat": 49.0420915892,
                "lng": -123.6351312365
            },
            {
                "lat": 49.0440217709,
                "lng": -123.6393887715
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 673,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 17-3",
            "LABEL": "17-3",
            "FISHERY_AREA_ID": 2194,
            "OBJECTID": 817,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 20372154.4234,
            "FEATURE_LENGTH_M": 23614.5574,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.01116990589639,
            "lng": -123.59320628887455
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.0210832329,
                "lng": -123.6894696982
            },
            {
                "lat": 49.0389216041,
                "lng": -123.7438126329
            },
            {
                "lat": 49.0399133654,
                "lng": -123.7468330173
            },
            {
                "lat": 49.0401685007,
                "lng": -123.7515787186
            },
            {
                "lat": 49.0404163091,
                "lng": -123.7667309674
            },
            {
                "lat": 49.0324557578,
                "lng": -123.7904504373
            },
            {
                "lat": 49.024494238,
                "lng": -123.7952576167
            },
            {
                "lat": 49.0116118357,
                "lng": -123.7985074895
            },
            {
                "lat": 49.0019760696,
                "lng": -123.7811440511
            },
            {
                "lat": 48.9943241522,
                "lng": -123.7756040621
            },
            {
                "lat": 48.9902645349,
                "lng": -123.7745977152
            },
            {
                "lat": 48.9875217872,
                "lng": -123.7744058977
            },
            {
                "lat": 48.9857862586,
                "lng": -123.7729801189
            },
            {
                "lat": 48.9837384637,
                "lng": -123.7719720481
            },
            {
                "lat": 48.9826464186,
                "lng": -123.7713859812
            },
            {
                "lat": 48.9819828115,
                "lng": -123.769919514
            },
            {
                "lat": 48.9815673643,
                "lng": -123.7687449494
            },
            {
                "lat": 48.9814380385,
                "lng": -123.7685634367
            },
            {
                "lat": 48.9813612302,
                "lng": -123.7684169537
            },
            {
                "lat": 48.9814951884,
                "lng": -123.7432870366
            },
            {
                "lat": 48.9817768279,
                "lng": -123.6912540373
            },
            {
                "lat": 48.9838291648,
                "lng": -123.6892311285
            },
            {
                "lat": 48.9894369611,
                "lng": -123.6909025882
            },
            {
                "lat": 48.9901120968,
                "lng": -123.6916115982
            },
            {
                "lat": 48.99677237,
                "lng": -123.6954181036
            },
            {
                "lat": 49.0048643751,
                "lng": -123.7001794286
            },
            {
                "lat": 49.0098607358,
                "lng": -123.7016064519
            },
            {
                "lat": 49.0112880931,
                "lng": -123.7001804592
            },
            {
                "lat": 49.0084347593,
                "lng": -123.694946395
            },
            {
                "lat": 49.0098609656,
                "lng": -123.6913758221
            },
            {
                "lat": 49.0094267363,
                "lng": -123.689995552
            },
            {
                "lat": 49.0084348204,
                "lng": -123.6868519317
            },
            {
                "lat": 49.0034369177,
                "lng": -123.6830434095
            },
            {
                "lat": 49.0015335888,
                "lng": -123.6762088097
            },
            {
                "lat": 49.0032692529,
                "lng": -123.6728514915
            },
            {
                "lat": 49.0081366785,
                "lng": -123.6752841901
            },
            {
                "lat": 49.0131797329,
                "lng": -123.6781235778
            },
            {
                "lat": 49.0194964312,
                "lng": -123.6873931073
            },
            {
                "lat": 49.0210832329,
                "lng": -123.6894696982
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 674,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 17-5",
            "LABEL": "17-5",
            "FISHERY_AREA_ID": 2196,
            "OBJECTID": 818,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 41297034.2337,
            "FEATURE_LENGTH_M": 28901.8061,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.004035920576904,
            "lng": -123.72845102879485
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9813612302,
                "lng": -123.7684169537
            },
            {
                "lat": 48.9814380385,
                "lng": -123.7685634367
            },
            {
                "lat": 48.9815673643,
                "lng": -123.7687449494
            },
            {
                "lat": 48.9819828115,
                "lng": -123.769919514
            },
            {
                "lat": 48.9826464186,
                "lng": -123.7713859812
            },
            {
                "lat": 48.9837384637,
                "lng": -123.7719720481
            },
            {
                "lat": 48.9857862586,
                "lng": -123.7729801189
            },
            {
                "lat": 48.9875217872,
                "lng": -123.7744058977
            },
            {
                "lat": 48.9902645349,
                "lng": -123.7745977152
            },
            {
                "lat": 48.9943241522,
                "lng": -123.7756040621
            },
            {
                "lat": 49.0019760696,
                "lng": -123.7811440511
            },
            {
                "lat": 49.0116118357,
                "lng": -123.7985074895
            },
            {
                "lat": 49.0369714936,
                "lng": -123.8441993582
            },
            {
                "lat": 49.0330846704,
                "lng": -123.8594821051
            },
            {
                "lat": 49.0230868716,
                "lng": -123.8675299464
            },
            {
                "lat": 49.0133627389,
                "lng": -123.8600315366
            },
            {
                "lat": 48.9944764658,
                "lng": -123.8261486531
            },
            {
                "lat": 48.9747546211,
                "lng": -123.8022526333
            },
            {
                "lat": 48.9605869449,
                "lng": -123.7789242431
            },
            {
                "lat": 48.9566951564,
                "lng": -123.7633668607
            },
            {
                "lat": 48.9554937591,
                "lng": -123.7584686332
            },
            {
                "lat": 48.9577824434,
                "lng": -123.7567284543
            },
            {
                "lat": 48.9585532227,
                "lng": -123.7562565587
            },
            {
                "lat": 48.9813612302,
                "lng": -123.7684169537
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 675,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 17-7",
            "LABEL": "17-7",
            "FISHERY_AREA_ID": 2198,
            "OBJECTID": 819,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 26953630.6265,
            "FEATURE_LENGTH_M": 25299.7442,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.98793452429583,
            "lng": -123.78908533975003
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9638173299,
                "lng": -125.4317091495
            },
            {
                "lat": 48.9605716224,
                "lng": -125.4293969295
            },
            {
                "lat": 48.9561269806,
                "lng": -125.4222340349
            },
            {
                "lat": 48.9488604614,
                "lng": -125.4105300307
            },
            {
                "lat": 48.9732201633,
                "lng": -125.3580171156
            },
            {
                "lat": 48.9883343785,
                "lng": -125.3291918045
            },
            {
                "lat": 49.0096432505,
                "lng": -125.3241806908
            },
            {
                "lat": 49.0097538232,
                "lng": -125.3234640459
            },
            {
                "lat": 49.0097806047,
                "lng": -125.3232947093
            },
            {
                "lat": 49.0098189784,
                "lng": -125.3230432899
            },
            {
                "lat": 49.0108141571,
                "lng": -125.3166888797
            },
            {
                "lat": 49.0097921929,
                "lng": -125.311225801
            },
            {
                "lat": 49.0074010843,
                "lng": -125.3050683057
            },
            {
                "lat": 49.0097921701,
                "lng": -125.2982337936
            },
            {
                "lat": 49.0128667684,
                "lng": -125.2968683187
            },
            {
                "lat": 49.0142323443,
                "lng": -125.2924273444
            },
            {
                "lat": 49.0132065431,
                "lng": -125.286276643
            },
            {
                "lat": 49.0132067279,
                "lng": -125.2773882199
            },
            {
                "lat": 49.0162847612,
                "lng": -125.2702112654
            },
            {
                "lat": 49.0190157573,
                "lng": -125.262350954
            },
            {
                "lat": 49.0214080595,
                "lng": -125.252441611
            },
            {
                "lat": 49.0241427479,
                "lng": -125.2322762698
            },
            {
                "lat": 49.0265350053,
                "lng": -125.2230539561
            },
            {
                "lat": 49.0299529123,
                "lng": -125.2087016984
            },
            {
                "lat": 49.0326886015,
                "lng": -125.1998125854
            },
            {
                "lat": 49.0367889907,
                "lng": -125.19605391
            },
            {
                "lat": 49.0405467998,
                "lng": -125.1950294213
            },
            {
                "lat": 49.0443082215,
                "lng": -125.1977619658
            },
            {
                "lat": 49.0446469079,
                "lng": -125.2035748103
            },
            {
                "lat": 49.0432813887,
                "lng": -125.2124565083
            },
            {
                "lat": 49.0419157079,
                "lng": -125.2261279179
            },
            {
                "lat": 49.0402068034,
                "lng": -125.2350157428
            },
            {
                "lat": 49.0381544465,
                "lng": -125.2520981533
            },
            {
                "lat": 49.0378157475,
                "lng": -125.2736294721
            },
            {
                "lat": 49.0384978697,
                "lng": -125.286957005
            },
            {
                "lat": 49.0429378633,
                "lng": -125.3060998457
            },
            {
                "lat": 49.0456736741,
                "lng": -125.3238670662
            },
            {
                "lat": 49.0484083734,
                "lng": -125.3412930438
            },
            {
                "lat": 49.0480648536,
                "lng": -125.3576967266
            },
            {
                "lat": 49.0436213568,
                "lng": -125.3648759351
            },
            {
                "lat": 49.0350797766,
                "lng": -125.3700036169
            },
            {
                "lat": 49.0220948544,
                "lng": -125.3758085426
            },
            {
                "lat": 49.0121845051,
                "lng": -125.3802571096
            },
            {
                "lat": 49.0009039184,
                "lng": -125.3874278922
            },
            {
                "lat": 48.9889443904,
                "lng": -125.4000778291
            },
            {
                "lat": 48.9773251968,
                "lng": -125.4157942933
            },
            {
                "lat": 48.9701457783,
                "lng": -125.4257044665
            },
            {
                "lat": 48.9638173299,
                "lng": -125.4317091495
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 676,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 23,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 23-10",
            "LABEL": "23-10",
            "FISHERY_AREA_ID": 2252,
            "OBJECTID": 820,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 64685517.2311,
            "FEATURE_LENGTH_M": 46489.5391,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.014513170431236,
            "lng": -125.30973766397086
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.0210832329,
                "lng": -123.6894696982
            },
            {
                "lat": 49.0194964312,
                "lng": -123.6873931073
            },
            {
                "lat": 49.0131797329,
                "lng": -123.6781235778
            },
            {
                "lat": 49.0066488746,
                "lng": -123.6701954763
            },
            {
                "lat": 49.0021285743,
                "lng": -123.6637718578
            },
            {
                "lat": 48.99808086,
                "lng": -123.6592478053
            },
            {
                "lat": 48.9935603348,
                "lng": -123.6587746537
            },
            {
                "lat": 48.991184272,
                "lng": -123.6647256747
            },
            {
                "lat": 48.9896360189,
                "lng": -123.6709965888
            },
            {
                "lat": 48.9882237525,
                "lng": -123.6712871825
            },
            {
                "lat": 48.9868886994,
                "lng": -123.6715551361
            },
            {
                "lat": 48.9847674079,
                "lng": -123.6696097161
            },
            {
                "lat": 48.9826463999,
                "lng": -123.6681968555
            },
            {
                "lat": 48.9826127133,
                "lng": -123.6678777332
            },
            {
                "lat": 48.9820247729,
                "lng": -123.666823539
            },
            {
                "lat": 48.979988524,
                "lng": -123.6649101117
            },
            {
                "lat": 48.9763598886,
                "lng": -123.6618583115
            },
            {
                "lat": 48.970695314,
                "lng": -123.6565087068
            },
            {
                "lat": 48.9650957795,
                "lng": -123.6516125562
            },
            {
                "lat": 48.9606405093,
                "lng": -123.6499551898
            },
            {
                "lat": 48.9526216332,
                "lng": -123.6419375303
            },
            {
                "lat": 48.9453015271,
                "lng": -123.6357644952
            },
            {
                "lat": 48.9393841796,
                "lng": -123.6325841641
            },
            {
                "lat": 48.9358218117,
                "lng": -123.6318218498
            },
            {
                "lat": 48.9338497908,
                "lng": -123.6320103834
            },
            {
                "lat": 48.9790491667,
                "lng": -123.6005935727
            },
            {
                "lat": 48.9863392315,
                "lng": -123.6082604463
            },
            {
                "lat": 48.991310031,
                "lng": -123.6168298057
            },
            {
                "lat": 49.0060273145,
                "lng": -123.6281438322
            },
            {
                "lat": 49.0085138779,
                "lng": -123.6439592576
            },
            {
                "lat": 49.0210832329,
                "lng": -123.6894696982
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 677,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 17-8",
            "LABEL": "17-8",
            "FISHERY_AREA_ID": 2199,
            "OBJECTID": 821,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 23783102.4912,
            "FEATURE_LENGTH_M": 25846.6412,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.98368528676773,
            "lng": -123.65497640367094
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9868886994,
                "lng": -123.6715551361
            },
            {
                "lat": 48.9882237525,
                "lng": -123.6712871825
            },
            {
                "lat": 48.9896360189,
                "lng": -123.6709965888
            },
            {
                "lat": 48.991184272,
                "lng": -123.6647256747
            },
            {
                "lat": 48.9935603348,
                "lng": -123.6587746537
            },
            {
                "lat": 48.99808086,
                "lng": -123.6592478053
            },
            {
                "lat": 49.0021285743,
                "lng": -123.6637718578
            },
            {
                "lat": 49.0066488746,
                "lng": -123.6701954763
            },
            {
                "lat": 49.0131797329,
                "lng": -123.6781235778
            },
            {
                "lat": 49.0081366785,
                "lng": -123.6752841901
            },
            {
                "lat": 49.0032692529,
                "lng": -123.6728514915
            },
            {
                "lat": 49.0015335888,
                "lng": -123.6762088097
            },
            {
                "lat": 49.0034369177,
                "lng": -123.6830434095
            },
            {
                "lat": 49.0084348204,
                "lng": -123.6868519317
            },
            {
                "lat": 49.0094267363,
                "lng": -123.689995552
            },
            {
                "lat": 49.0098609656,
                "lng": -123.6913758221
            },
            {
                "lat": 49.0084347593,
                "lng": -123.694946395
            },
            {
                "lat": 49.0112880931,
                "lng": -123.7001804592
            },
            {
                "lat": 49.0098607358,
                "lng": -123.7016064519
            },
            {
                "lat": 49.0048643751,
                "lng": -123.7001794286
            },
            {
                "lat": 48.99677237,
                "lng": -123.6954181036
            },
            {
                "lat": 48.9901120968,
                "lng": -123.6916115982
            },
            {
                "lat": 48.9894369611,
                "lng": -123.6909025882
            },
            {
                "lat": 48.9838291648,
                "lng": -123.6892311285
            },
            {
                "lat": 48.9849969638,
                "lng": -123.6862569712
            },
            {
                "lat": 48.9859469282,
                "lng": -123.6791227394
            },
            {
                "lat": 48.9871364517,
                "lng": -123.6741248188
            },
            {
                "lat": 48.9868886994,
                "lng": -123.6715551361
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 678,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 822,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 5309311.6033,
            "FEATURE_LENGTH_M": 12403.8276,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.99832848852501,
            "lng": -123.68069374922497
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.0100168036,
                "lng": -123.5751873519
            },
            {
                "lat": 49.0084114844,
                "lng": -123.5767664604
            },
            {
                "lat": 49.0070424921,
                "lng": -123.5803615534
            },
            {
                "lat": 49.0050435595,
                "lng": -123.5820171117
            },
            {
                "lat": 49.0018467495,
                "lng": -123.5847622562
            },
            {
                "lat": 48.9992519873,
                "lng": -123.5844646162
            },
            {
                "lat": 48.9954451114,
                "lng": -123.5813757223
            },
            {
                "lat": 48.9933019059,
                "lng": -123.5768508957
            },
            {
                "lat": 48.9908071333,
                "lng": -123.5751882529
            },
            {
                "lat": 48.9869806758,
                "lng": -123.5712125137
            },
            {
                "lat": 48.9826815016,
                "lng": -123.5711067628
            },
            {
                "lat": 48.9819874778,
                "lng": -123.5695268399
            },
            {
                "lat": 48.9812961095,
                "lng": -123.56768062
            },
            {
                "lat": 48.9805638202,
                "lng": -123.5666130042
            },
            {
                "lat": 48.9804613774,
                "lng": -123.5664903613
            },
            {
                "lat": 48.9760479759,
                "lng": -123.5563889534
            },
            {
                "lat": 48.9686429165,
                "lng": -123.5480808889
            },
            {
                "lat": 48.9553179941,
                "lng": -123.5278551204
            },
            {
                "lat": 48.948657752,
                "lng": -123.5128632567
            },
            {
                "lat": 48.9450873352,
                "lng": -123.498352985
            },
            {
                "lat": 48.9377101799,
                "lng": -123.4807438868
            },
            {
                "lat": 48.9291454302,
                "lng": -123.459326595
            },
            {
                "lat": 48.9205785432,
                "lng": -123.439812243
            },
            {
                "lat": 48.9143943962,
                "lng": -123.4250642426
            },
            {
                "lat": 48.9051125944,
                "lng": -123.4050751361
            },
            {
                "lat": 48.8977354902,
                "lng": -123.3912733777
            },
            {
                "lat": 48.8945853537,
                "lng": -123.386696436
            },
            {
                "lat": 48.8943708992,
                "lng": -123.3865976909
            },
            {
                "lat": 48.8902010398,
                "lng": -123.3848036733
            },
            {
                "lat": 48.8834070383,
                "lng": -123.3829949165
            },
            {
                "lat": 48.8779063083,
                "lng": -123.3815298136
            },
            {
                "lat": 48.874382516,
                "lng": -123.3811037322
            },
            {
                "lat": 48.871460573,
                "lng": -123.3828199589
            },
            {
                "lat": 48.8687933256,
                "lng": -123.3778377236
            },
            {
                "lat": 48.8674196003,
                "lng": -123.3681272304
            },
            {
                "lat": 48.8665582789,
                "lng": -123.358321952
            },
            {
                "lat": 48.8685384948,
                "lng": -123.3515312531
            },
            {
                "lat": 48.8723150454,
                "lng": -123.3485337521
            },
            {
                "lat": 48.8725315966,
                "lng": -123.3465344749
            },
            {
                "lat": 48.8712650247,
                "lng": -123.3384932565
            },
            {
                "lat": 48.8684802584,
                "lng": -123.3309629382
            },
            {
                "lat": 48.8683511097,
                "lng": -123.3227317421
            },
            {
                "lat": 48.8718340486,
                "lng": -123.3210835855
            },
            {
                "lat": 48.8764571346,
                "lng": -123.3207696185
            },
            {
                "lat": 48.8765827552,
                "lng": -123.3257670627
            },
            {
                "lat": 48.8808250003,
                "lng": -123.3309640103
            },
            {
                "lat": 48.8867107536,
                "lng": -123.3378600891
            },
            {
                "lat": 48.8911289398,
                "lng": -123.3452840194
            },
            {
                "lat": 48.9013593866,
                "lng": -123.3483732881
            },
            {
                "lat": 48.9024699566,
                "lng": -123.348450047
            },
            {
                "lat": 48.900913418,
                "lng": -123.3543393519
            },
            {
                "lat": 48.9042430266,
                "lng": -123.3702775754
            },
            {
                "lat": 48.9080510682,
                "lng": -123.3878848981
            },
            {
                "lat": 48.9144746959,
                "lng": -123.409064849
            },
            {
                "lat": 48.9254188362,
                "lng": -123.4269099404
            },
            {
                "lat": 48.9318462773,
                "lng": -123.4404754644
            },
            {
                "lat": 48.9396972976,
                "lng": -123.46070189
            },
            {
                "lat": 48.9430280621,
                "lng": -123.4766377905
            },
            {
                "lat": 48.9463575455,
                "lng": -123.4882957756
            },
            {
                "lat": 48.9523086565,
                "lng": -123.4994808297
            },
            {
                "lat": 48.9580186662,
                "lng": -123.5125730627
            },
            {
                "lat": 48.9699174697,
                "lng": -123.5304195168
            },
            {
                "lat": 48.9818118095,
                "lng": -123.5456471546
            },
            {
                "lat": 48.9899026459,
                "lng": -123.5563499763
            },
            {
                "lat": 48.9996605018,
                "lng": -123.5668261637
            },
            {
                "lat": 49.0066792738,
                "lng": -123.5724326228
            },
            {
                "lat": 49.0100168036,
                "lng": -123.5751873519
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 679,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 823,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 30954636.531,
            "FEATURE_LENGTH_M": 54069.3257,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.93259476551493,
            "lng": -123.45606145502839
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.2354236411,
                "lng": -125.8192741979
            },
            {
                "lat": 49.235438701,
                "lng": -125.821906308
            },
            {
                "lat": 49.2355004882,
                "lng": -125.8296885471
            },
            {
                "lat": 49.2365259847,
                "lng": -125.8434443986
            },
            {
                "lat": 49.2379918054,
                "lng": -125.8517376679
            },
            {
                "lat": 49.2382351786,
                "lng": -125.8673550672
            },
            {
                "lat": 49.2377472092,
                "lng": -125.8780982836
            },
            {
                "lat": 49.235065209,
                "lng": -125.8885884455
            },
            {
                "lat": 49.2268032549,
                "lng": -125.8931506906
            },
            {
                "lat": 49.2222629903,
                "lng": -125.8965228315
            },
            {
                "lat": 49.2206120403,
                "lng": -125.9020308588
            },
            {
                "lat": 49.2206122558,
                "lng": -125.9106979378
            },
            {
                "lat": 49.2204747027,
                "lng": -125.9170227781
            },
            {
                "lat": 49.2208856135,
                "lng": -125.9226687058
            },
            {
                "lat": 49.2152478197,
                "lng": -125.9241478658
            },
            {
                "lat": 49.2087854171,
                "lng": -125.9234309069
            },
            {
                "lat": 49.2035257686,
                "lng": -125.9212796031
            },
            {
                "lat": 49.198142871,
                "lng": -125.9176940314
            },
            {
                "lat": 49.1916851528,
                "lng": -125.9114687348
            },
            {
                "lat": 49.1863022216,
                "lng": -125.9096752457
            },
            {
                "lat": 49.1815152754,
                "lng": -125.9095612237
            },
            {
                "lat": 49.1790042074,
                "lng": -125.9132689271
            },
            {
                "lat": 49.1799120658,
                "lng": -125.9054264188
            },
            {
                "lat": 49.1808889573,
                "lng": -125.9010320853
            },
            {
                "lat": 49.1872336551,
                "lng": -125.8966453269
            },
            {
                "lat": 49.1952861045,
                "lng": -125.8966454073
            },
            {
                "lat": 49.2087063798,
                "lng": -125.8944476332
            },
            {
                "lat": 49.2155421631,
                "lng": -125.893959897
            },
            {
                "lat": 49.2218855949,
                "lng": -125.8878554886
            },
            {
                "lat": 49.2218856952,
                "lng": -125.877853619
            },
            {
                "lat": 49.2250592609,
                "lng": -125.8683321693
            },
            {
                "lat": 49.2294502888,
                "lng": -125.8644259928
            },
            {
                "lat": 49.2314037264,
                "lng": -125.8524702624
            },
            {
                "lat": 49.2328683385,
                "lng": -125.8449099179
            },
            {
                "lat": 49.2306705358,
                "lng": -125.8402707154
            },
            {
                "lat": 49.2218857895,
                "lng": -125.8331909894
            },
            {
                "lat": 49.2130999896,
                "lng": -125.8322138789
            },
            {
                "lat": 49.2065118147,
                "lng": -125.8383178697
            },
            {
                "lat": 49.2004094818,
                "lng": -125.8451535831
            },
            {
                "lat": 49.1899179836,
                "lng": -125.8512502775
            },
            {
                "lat": 49.1784476162,
                "lng": -125.8527144781
            },
            {
                "lat": 49.1657559866,
                "lng": -125.8529592615
            },
            {
                "lat": 49.1567269174,
                "lng": -125.8580857287
            },
            {
                "lat": 49.1503834162,
                "lng": -125.8549111687
            },
            {
                "lat": 49.1452566187,
                "lng": -125.8480753943
            },
            {
                "lat": 49.1330561343,
                "lng": -125.8212363146
            },
            {
                "lat": 49.1320806565,
                "lng": -125.8156204162
            },
            {
                "lat": 49.1320806462,
                "lng": -125.811714292
            },
            {
                "lat": 49.1286627962,
                "lng": -125.8058625061
            },
            {
                "lat": 49.1247591647,
                "lng": -125.7997587846
            },
            {
                "lat": 49.1233250675,
                "lng": -125.7960203595
            },
            {
                "lat": 49.1291957522,
                "lng": -125.7959133673
            },
            {
                "lat": 49.1339571287,
                "lng": -125.796806185
            },
            {
                "lat": 49.1371539626,
                "lng": -125.7994077284
            },
            {
                "lat": 49.1383438733,
                "lng": -125.8029096823
            },
            {
                "lat": 49.1372297236,
                "lng": -125.8095245752
            },
            {
                "lat": 49.1360399685,
                "lng": -125.814284609
            },
            {
                "lat": 49.1353681109,
                "lng": -125.8186796361
            },
            {
                "lat": 49.1362609731,
                "lng": -125.8223952723
            },
            {
                "lat": 49.1374507438,
                "lng": -125.822990487
            },
            {
                "lat": 49.1412472568,
                "lng": -125.8203124216
            },
            {
                "lat": 49.1448175979,
                "lng": -125.8165215138
            },
            {
                "lat": 49.1480144675,
                "lng": -125.8109438088
            },
            {
                "lat": 49.1498758931,
                "lng": -125.8065493758
            },
            {
                "lat": 49.1508408362,
                "lng": -125.8038024501
            },
            {
                "lat": 49.1509899345,
                "lng": -125.8010486142
            },
            {
                "lat": 49.1509167245,
                "lng": -125.7990411564
            },
            {
                "lat": 49.1517023202,
                "lng": -125.7999107225
            },
            {
                "lat": 49.153019101,
                "lng": -125.8000796572
            },
            {
                "lat": 49.1555791744,
                "lng": -125.7997819339
            },
            {
                "lat": 49.1576116635,
                "lng": -125.7987982535
            },
            {
                "lat": 49.1600377378,
                "lng": -125.7978513034
            },
            {
                "lat": 49.1621098271,
                "lng": -125.7961045526
            },
            {
                "lat": 49.1635511798,
                "lng": -125.795517827
            },
            {
                "lat": 49.1685103804,
                "lng": -125.7962871109
            },
            {
                "lat": 49.1722368632,
                "lng": -125.7968675822
            },
            {
                "lat": 49.1771356694,
                "lng": -125.7975689114
            },
            {
                "lat": 49.1824989681,
                "lng": -125.7995521227
            },
            {
                "lat": 49.1865769892,
                "lng": -125.8010633729
            },
            {
                "lat": 49.1917073021,
                "lng": -125.802001638
            },
            {
                "lat": 49.1971859753,
                "lng": -125.8021162352
            },
            {
                "lat": 49.2025492854,
                "lng": -125.8031609472
            },
            {
                "lat": 49.2060473042,
                "lng": -125.8049172186
            },
            {
                "lat": 49.2117576721,
                "lng": -125.8035127113
            },
            {
                "lat": 49.2174677369,
                "lng": -125.805381788
            },
            {
                "lat": 49.2237658182,
                "lng": -125.8077083404
            },
            {
                "lat": 49.2307588315,
                "lng": -125.8095782997
            },
            {
                "lat": 49.2335586395,
                "lng": -125.8148189444
            },
            {
                "lat": 49.2336726959,
                "lng": -125.8185505474
            },
            {
                "lat": 49.2354236411,
                "lng": -125.8192741979
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 644,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 788,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 46536061.7116,
            "FEATURE_LENGTH_M": 54148.0338,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.186478981973345,
            "lng": -125.84139565105892
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.2354236411,
                "lng": -125.8192741979
            },
            {
                "lat": 49.236484312,
                "lng": -125.8179625237
            },
            {
                "lat": 49.2367857759,
                "lng": -125.8177641381
            },
            {
                "lat": 49.2354236411,
                "lng": -125.8192741979
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 645,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 789,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 749.2243,
            "FEATURE_LENGTH_M": 375.3821,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.236029342525,
            "lng": -125.8185687644
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.193153355,
                "lng": -123.1301357408
            },
            {
                "lat": 49.1957434293,
                "lng": -123.1329027628
            },
            {
                "lat": 49.1961894699,
                "lng": -123.1330347076
            },
            {
                "lat": 49.1979252274,
                "lng": -123.1352991441
            },
            {
                "lat": 49.1973383472,
                "lng": -123.1383207257
            },
            {
                "lat": 49.18917427,
                "lng": -123.1535334546
            },
            {
                "lat": 49.1880030531,
                "lng": -123.1734235296
            },
            {
                "lat": 49.1892393898,
                "lng": -123.1917422994
            },
            {
                "lat": 49.1893652453,
                "lng": -123.1948549208
            },
            {
                "lat": 49.1896783594,
                "lng": -123.2026743329
            },
            {
                "lat": 49.1872939516,
                "lng": -123.2095191395
            },
            {
                "lat": 49.174316237,
                "lng": -123.1999671058
            },
            {
                "lat": 49.1713870334,
                "lng": -123.194251676
            },
            {
                "lat": 49.1634335904,
                "lng": -123.1778711332
            },
            {
                "lat": 49.1653020113,
                "lng": -123.1558757059
            },
            {
                "lat": 49.1758342192,
                "lng": -123.1359869881
            },
            {
                "lat": 49.1889378634,
                "lng": -123.1298971718
            },
            {
                "lat": 49.193153355,
                "lng": -123.1301357408
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 646,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 29-11",
            "LABEL": "29-11",
            "FISHERY_AREA_ID": 2536,
            "OBJECTID": 790,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 12850186.6749,
            "FEATURE_LENGTH_M": 15576.8484,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.18585935598334,
            "lng": -123.16219034885555
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.18907166,
                "lng": -123.8647917238
            },
            {
                "lat": 49.1904640063,
                "lng": -123.8686834008
            },
            {
                "lat": 49.1911776944,
                "lng": -123.870949022
            },
            {
                "lat": 49.1915398858,
                "lng": -123.8720327664
            },
            {
                "lat": 49.1918180294,
                "lng": -123.8727655904
            },
            {
                "lat": 49.1918981948,
                "lng": -123.8729778765
            },
            {
                "lat": 49.2105410261,
                "lng": -123.9322501133
            },
            {
                "lat": 49.2130893603,
                "lng": -123.9403533693
            },
            {
                "lat": 49.2143176624,
                "lng": -123.9443661968
            },
            {
                "lat": 49.2138436407,
                "lng": -123.9598381793
            },
            {
                "lat": 49.2088238497,
                "lng": -123.9708787394
            },
            {
                "lat": 49.2055783264,
                "lng": -123.9723593898
            },
            {
                "lat": 49.200794638,
                "lng": -123.9714727521
            },
            {
                "lat": 49.1959531284,
                "lng": -123.9671025208
            },
            {
                "lat": 49.191581553,
                "lng": -123.9611975449
            },
            {
                "lat": 49.1902243839,
                "lng": -123.9556426413
            },
            {
                "lat": 49.190460706,
                "lng": -123.953164348
            },
            {
                "lat": 49.191238151,
                "lng": -123.9508369815
            },
            {
                "lat": 49.1928516389,
                "lng": -123.9493261609
            },
            {
                "lat": 49.1933021643,
                "lng": -123.9489275051
            },
            {
                "lat": 49.1946177678,
                "lng": -123.9471434713
            },
            {
                "lat": 49.1947552806,
                "lng": -123.946976283
            },
            {
                "lat": 49.195049663,
                "lng": -123.9463582393
            },
            {
                "lat": 49.1952662783,
                "lng": -123.9455876508
            },
            {
                "lat": 49.1950764508,
                "lng": -123.9448555958
            },
            {
                "lat": 49.1944583036,
                "lng": -123.9439008229
            },
            {
                "lat": 49.1930776557,
                "lng": -123.9428478501
            },
            {
                "lat": 49.1920088265,
                "lng": -123.9420622084
            },
            {
                "lat": 49.1837120122,
                "lng": -123.9258882642
            },
            {
                "lat": 49.1772952825,
                "lng": -123.9258798164
            },
            {
                "lat": 49.1774743497,
                "lng": -123.925124803
            },
            {
                "lat": 49.1781426055,
                "lng": -123.9236454561
            },
            {
                "lat": 49.1794929876,
                "lng": -123.9217003672
            },
            {
                "lat": 49.1802336142,
                "lng": -123.9190747851
            },
            {
                "lat": 49.1777224001,
                "lng": -123.9177085363
            },
            {
                "lat": 49.1742974848,
                "lng": -123.917305107
            },
            {
                "lat": 49.1719553179,
                "lng": -123.9181055542
            },
            {
                "lat": 49.1713254464,
                "lng": -123.9183341914
            },
            {
                "lat": 49.1704370612,
                "lng": -123.9180296256
            },
            {
                "lat": 49.17625085,
                "lng": -123.8698352326
            },
            {
                "lat": 49.1735955511,
                "lng": -123.8687884353
            },
            {
                "lat": 49.1702847238,
                "lng": -123.8654779227
            },
            {
                "lat": 49.1702845438,
                "lng": -123.8598245652
            },
            {
                "lat": 49.173141411,
                "lng": -123.8561706311
            },
            {
                "lat": 49.1761088721,
                "lng": -123.8536522333
            },
            {
                "lat": 49.1793056829,
                "lng": -123.8531413909
            },
            {
                "lat": 49.1830181213,
                "lng": -123.854683563
            },
            {
                "lat": 49.1866152868,
                "lng": -123.8587950606
            },
            {
                "lat": 49.18907166,
                "lng": -123.8647917238
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 647,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 13,
            "MANAGEMENT_AREA_NAME": "Subarea 17-13",
            "LABEL": "17-13",
            "FISHERY_AREA_ID": 2204,
            "OBJECTID": 791,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 21368201.5069,
            "FEATURE_LENGTH_M": 23867.1545,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.188421330428575,
            "lng": -123.91623686140822
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1744267161,
                "lng": -122.9698038735
            },
            {
                "lat": 49.1746059384,
                "lng": -122.9839093712
            },
            {
                "lat": 49.1797292913,
                "lng": -123.0056376171
            },
            {
                "lat": 49.190792437,
                "lng": -123.0206153789
            },
            {
                "lat": 49.1913685505,
                "lng": -123.0248873615
            },
            {
                "lat": 49.1930775801,
                "lng": -123.0480121464
            },
            {
                "lat": 49.1918377621,
                "lng": -123.0857320914
            },
            {
                "lat": 49.1910589855,
                "lng": -123.1236041121
            },
            {
                "lat": 49.193153355,
                "lng": -123.1301357408
            },
            {
                "lat": 49.1889378634,
                "lng": -123.1298971718
            },
            {
                "lat": 49.1758342192,
                "lng": -123.1359869881
            },
            {
                "lat": 49.1653020113,
                "lng": -123.1558757059
            },
            {
                "lat": 49.1634335904,
                "lng": -123.1778711332
            },
            {
                "lat": 49.1396977142,
                "lng": -123.1817325852
            },
            {
                "lat": 49.1281773627,
                "lng": -123.1883166969
            },
            {
                "lat": 49.1294974168,
                "lng": -123.1792067159
            },
            {
                "lat": 49.1251072137,
                "lng": -123.1590187402
            },
            {
                "lat": 49.1222527334,
                "lng": -123.1536410464
            },
            {
                "lat": 49.1217197221,
                "lng": -123.1381375548
            },
            {
                "lat": 49.1597443744,
                "lng": -123.0289532471
            },
            {
                "lat": 49.161815421,
                "lng": -123.0138237896
            },
            {
                "lat": 49.16752672,
                "lng": -122.9943155865
            },
            {
                "lat": 49.1744267161,
                "lng": -122.9698038735
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 648,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 792,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 66099338.0551,
            "FEATURE_LENGTH_M": 39453.8191,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.16537059542174,
            "lng": -123.08690950121738
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1790042074,
                "lng": -125.9132689271
            },
            {
                "lat": 49.1784058445,
                "lng": -125.9200821573
            },
            {
                "lat": 49.1783219286,
                "lng": -125.9246439575
            },
            {
                "lat": 49.1785581699,
                "lng": -125.926215802
            },
            {
                "lat": 49.1530726787,
                "lng": -125.9132607727
            },
            {
                "lat": 49.1531331995,
                "lng": -125.9129645305
            },
            {
                "lat": 49.153034267,
                "lng": -125.9120258402
            },
            {
                "lat": 49.1529155492,
                "lng": -125.9109878981
            },
            {
                "lat": 49.1517606294,
                "lng": -125.9093939723
            },
            {
                "lat": 49.1486010362,
                "lng": -125.9000934871
            },
            {
                "lat": 49.1453286566,
                "lng": -125.8970558528
            },
            {
                "lat": 49.1409339951,
                "lng": -125.8931048156
            },
            {
                "lat": 49.135661594,
                "lng": -125.8920064256
            },
            {
                "lat": 49.1270946436,
                "lng": -125.8913496777
            },
            {
                "lat": 49.1216010524,
                "lng": -125.8900308216
            },
            {
                "lat": 49.1174274405,
                "lng": -125.8869550808
            },
            {
                "lat": 49.1143528233,
                "lng": -125.8823404339
            },
            {
                "lat": 49.1117137993,
                "lng": -125.8792644545
            },
            {
                "lat": 49.1106183637,
                "lng": -125.8753133823
            },
            {
                "lat": 49.1106182119,
                "lng": -125.8713611808
            },
            {
                "lat": 49.1108359743,
                "lng": -125.8660812607
            },
            {
                "lat": 49.1092980792,
                "lng": -125.8643259671
            },
            {
                "lat": 49.1022677284,
                "lng": -125.8627861836
            },
            {
                "lat": 49.0974354868,
                "lng": -125.8594887519
            },
            {
                "lat": 49.0941384105,
                "lng": -125.8509218037
            },
            {
                "lat": 49.0919419004,
                "lng": -125.8432387174
            },
            {
                "lat": 49.0934795643,
                "lng": -125.8375245216
            },
            {
                "lat": 49.0923807051,
                "lng": -125.8300552709
            },
            {
                "lat": 49.0963364142,
                "lng": -125.8203881716
            },
            {
                "lat": 49.1002922516,
                "lng": -125.8129190697
            },
            {
                "lat": 49.104683446,
                "lng": -125.8131408291
            },
            {
                "lat": 49.1099581597,
                "lng": -125.8170923441
            },
            {
                "lat": 49.1132552373,
                "lng": -125.8230285746
            },
            {
                "lat": 49.1154483598,
                "lng": -125.8263160846
            },
            {
                "lat": 49.116767373,
                "lng": -125.8261033454
            },
            {
                "lat": 49.1172063105,
                "lng": -125.8201674317
            },
            {
                "lat": 49.1156695982,
                "lng": -125.812476754
            },
            {
                "lat": 49.1123737031,
                "lng": -125.8034744992
            },
            {
                "lat": 49.108860292,
                "lng": -125.7984162907
            },
            {
                "lat": 49.1132657168,
                "lng": -125.7960507321
            },
            {
                "lat": 49.1209559476,
                "lng": -125.7960589541
            },
            {
                "lat": 49.1224670853,
                "lng": -125.7960283875
            },
            {
                "lat": 49.1233250675,
                "lng": -125.7960203595
            },
            {
                "lat": 49.1247591647,
                "lng": -125.7997587846
            },
            {
                "lat": 49.1286627962,
                "lng": -125.8058625061
            },
            {
                "lat": 49.1320806462,
                "lng": -125.811714292
            },
            {
                "lat": 49.1320806565,
                "lng": -125.8156204162
            },
            {
                "lat": 49.1330561343,
                "lng": -125.8212363146
            },
            {
                "lat": 49.1452566187,
                "lng": -125.8480753943
            },
            {
                "lat": 49.1503834162,
                "lng": -125.8549111687
            },
            {
                "lat": 49.1567269174,
                "lng": -125.8580857287
            },
            {
                "lat": 49.1657559866,
                "lng": -125.8529592615
            },
            {
                "lat": 49.1784476162,
                "lng": -125.8527144781
            },
            {
                "lat": 49.1899179836,
                "lng": -125.8512502775
            },
            {
                "lat": 49.2004094818,
                "lng": -125.8451535831
            },
            {
                "lat": 49.2065118147,
                "lng": -125.8383178697
            },
            {
                "lat": 49.2130999896,
                "lng": -125.8322138789
            },
            {
                "lat": 49.2218857895,
                "lng": -125.8331909894
            },
            {
                "lat": 49.2306705358,
                "lng": -125.8402707154
            },
            {
                "lat": 49.2328683385,
                "lng": -125.8449099179
            },
            {
                "lat": 49.2314037264,
                "lng": -125.8524702624
            },
            {
                "lat": 49.2294502888,
                "lng": -125.8644259928
            },
            {
                "lat": 49.2250592609,
                "lng": -125.8683321693
            },
            {
                "lat": 49.2218856952,
                "lng": -125.877853619
            },
            {
                "lat": 49.2218855949,
                "lng": -125.8878554886
            },
            {
                "lat": 49.2155421631,
                "lng": -125.893959897
            },
            {
                "lat": 49.2087063798,
                "lng": -125.8944476332
            },
            {
                "lat": 49.1952861045,
                "lng": -125.8966454073
            },
            {
                "lat": 49.1872336551,
                "lng": -125.8966453269
            },
            {
                "lat": 49.1808889573,
                "lng": -125.9010320853
            },
            {
                "lat": 49.1799120658,
                "lng": -125.9054264188
            },
            {
                "lat": 49.1790042074,
                "lng": -125.9132689271
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 649,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 24,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 24-9",
            "LABEL": "24-9",
            "FISHERY_AREA_ID": 2262,
            "OBJECTID": 793,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 64861287.1731,
            "FEATURE_LENGTH_M": 46711.9658,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.149494901223605,
            "lng": -125.85878378582773
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.2091940262,
                "lng": -123.8081357528
            },
            {
                "lat": 49.2025724688,
                "lng": -123.8187034474
            },
            {
                "lat": 49.2018971296,
                "lng": -123.8188860116
            },
            {
                "lat": 49.2000544527,
                "lng": -123.8209164834
            },
            {
                "lat": 49.191901856,
                "lng": -123.8299028202
            },
            {
                "lat": 49.1840517793,
                "lng": -123.8287115554
            },
            {
                "lat": 49.1773907643,
                "lng": -123.8218159451
            },
            {
                "lat": 49.17120313,
                "lng": -123.8008722327
            },
            {
                "lat": 49.1593053695,
                "lng": -123.7661357655
            },
            {
                "lat": 49.155956201,
                "lng": -123.744101804
            },
            {
                "lat": 49.152404324,
                "lng": -123.7183078842
            },
            {
                "lat": 49.1514509887,
                "lng": -123.7068856206
            },
            {
                "lat": 49.1405068365,
                "lng": -123.7014169792
            },
            {
                "lat": 49.1362260827,
                "lng": -123.7059324117
            },
            {
                "lat": 49.1321796024,
                "lng": -123.7030796285
            },
            {
                "lat": 49.1299864283,
                "lng": -123.7018886236
            },
            {
                "lat": 49.1295777869,
                "lng": -123.7018899714
            },
            {
                "lat": 49.1283297006,
                "lng": -123.7020953799
            },
            {
                "lat": 49.1257628681,
                "lng": -123.6816174636
            },
            {
                "lat": 49.1499444683,
                "lng": -123.6416095021
            },
            {
                "lat": 49.2091940262,
                "lng": -123.8081357528
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 650,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 17-10",
            "LABEL": "17-10",
            "FISHERY_AREA_ID": 2201,
            "OBJECTID": 794,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 44129230.531,
            "FEATURE_LENGTH_M": 35348.7617,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.16376620429047,
            "lng": -123.75385909693809
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1704370612,
                "lng": -123.9180296256
            },
            {
                "lat": 49.1713254464,
                "lng": -123.9183341914
            },
            {
                "lat": 49.1719553179,
                "lng": -123.9181055542
            },
            {
                "lat": 49.1742974848,
                "lng": -123.917305107
            },
            {
                "lat": 49.1777224001,
                "lng": -123.9177085363
            },
            {
                "lat": 49.1802336142,
                "lng": -123.9190747851
            },
            {
                "lat": 49.1794929876,
                "lng": -123.9217003672
            },
            {
                "lat": 49.1781426055,
                "lng": -123.9236454561
            },
            {
                "lat": 49.1774743497,
                "lng": -123.925124803
            },
            {
                "lat": 49.1772952825,
                "lng": -123.9258798164
            },
            {
                "lat": 49.1837120122,
                "lng": -123.9258882642
            },
            {
                "lat": 49.1920088265,
                "lng": -123.9420622084
            },
            {
                "lat": 49.1930776557,
                "lng": -123.9428478501
            },
            {
                "lat": 49.1944583036,
                "lng": -123.9439008229
            },
            {
                "lat": 49.1950764508,
                "lng": -123.9448555958
            },
            {
                "lat": 49.1952662783,
                "lng": -123.9455876508
            },
            {
                "lat": 49.195049663,
                "lng": -123.9463582393
            },
            {
                "lat": 49.1947552806,
                "lng": -123.946976283
            },
            {
                "lat": 49.1946177678,
                "lng": -123.9471434713
            },
            {
                "lat": 49.1933021643,
                "lng": -123.9489275051
            },
            {
                "lat": 49.1928516389,
                "lng": -123.9493261609
            },
            {
                "lat": 49.191238151,
                "lng": -123.9508369815
            },
            {
                "lat": 49.1839449048,
                "lng": -123.9511790285
            },
            {
                "lat": 49.1698794876,
                "lng": -123.9515297757
            },
            {
                "lat": 49.1474764568,
                "lng": -123.9395441987
            },
            {
                "lat": 49.1280247926,
                "lng": -123.9199215653
            },
            {
                "lat": 49.1228140968,
                "lng": -123.9027259053
            },
            {
                "lat": 49.1227068515,
                "lng": -123.8937067093
            },
            {
                "lat": 49.1222957818,
                "lng": -123.8904942712
            },
            {
                "lat": 49.1207499472,
                "lng": -123.8869705868
            },
            {
                "lat": 49.1183702858,
                "lng": -123.8849874851
            },
            {
                "lat": 49.116218986,
                "lng": -123.8836136325
            },
            {
                "lat": 49.1158605985,
                "lng": -123.8808202558
            },
            {
                "lat": 49.1158604212,
                "lng": -123.880211103
            },
            {
                "lat": 49.1159514056,
                "lng": -123.878844901
            },
            {
                "lat": 49.1176300121,
                "lng": -123.877837536
            },
            {
                "lat": 49.1202808201,
                "lng": -123.8779066556
            },
            {
                "lat": 49.1227988719,
                "lng": -123.8790514482
            },
            {
                "lat": 49.1248778568,
                "lng": -123.8797525527
            },
            {
                "lat": 49.12854733,
                "lng": -123.8754655958
            },
            {
                "lat": 49.1332623447,
                "lng": -123.8692694331
            },
            {
                "lat": 49.1401820077,
                "lng": -123.8771959944
            },
            {
                "lat": 49.1450458175,
                "lng": -123.8837954925
            },
            {
                "lat": 49.1473124333,
                "lng": -123.8840262925
            },
            {
                "lat": 49.149837476,
                "lng": -123.8857796476
            },
            {
                "lat": 49.1536221583,
                "lng": -123.8886113845
            },
            {
                "lat": 49.157748916,
                "lng": -123.891097528
            },
            {
                "lat": 49.1632461869,
                "lng": -123.8935168996
            },
            {
                "lat": 49.1659622069,
                "lng": -123.8941116093
            },
            {
                "lat": 49.1662752715,
                "lng": -123.8943629435
            },
            {
                "lat": 49.1665953736,
                "lng": -123.8946002587
            },
            {
                "lat": 49.1704370612,
                "lng": -123.9180296256
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 651,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 17-14",
            "LABEL": "17-14",
            "FISHERY_AREA_ID": 2205,
            "OBJECTID": 795,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 24707803.1653,
            "FEATURE_LENGTH_M": 24322.6553,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.15860782506346,
            "lng": -123.91054960752692
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.191901856,
                "lng": -123.8299028202
            },
            {
                "lat": 49.1904756338,
                "lng": -123.852981954
            },
            {
                "lat": 49.18907166,
                "lng": -123.8647917238
            },
            {
                "lat": 49.1866152868,
                "lng": -123.8587950606
            },
            {
                "lat": 49.1830181213,
                "lng": -123.854683563
            },
            {
                "lat": 49.1793056829,
                "lng": -123.8531413909
            },
            {
                "lat": 49.1761088721,
                "lng": -123.8536522333
            },
            {
                "lat": 49.173141411,
                "lng": -123.8561706311
            },
            {
                "lat": 49.1702845438,
                "lng": -123.8598245652
            },
            {
                "lat": 49.1702847238,
                "lng": -123.8654779227
            },
            {
                "lat": 49.165493023,
                "lng": -123.8602437702
            },
            {
                "lat": 49.1576385668,
                "lng": -123.8402549719
            },
            {
                "lat": 49.1509783173,
                "lng": -123.8188399712
            },
            {
                "lat": 49.1483730434,
                "lng": -123.799545503
            },
            {
                "lat": 49.1332401611,
                "lng": -123.7613902788
            },
            {
                "lat": 49.1319536163,
                "lng": -123.7511830116
            },
            {
                "lat": 49.1321795061,
                "lng": -123.7451926928
            },
            {
                "lat": 49.1321796476,
                "lng": -123.7337713463
            },
            {
                "lat": 49.1326558351,
                "lng": -123.727111293
            },
            {
                "lat": 49.1376535023,
                "lng": -123.724021776
            },
            {
                "lat": 49.1400294213,
                "lng": -123.7194985321
            },
            {
                "lat": 49.1409841728,
                "lng": -123.7135464541
            },
            {
                "lat": 49.1407465476,
                "lng": -123.7090294451
            },
            {
                "lat": 49.1362260827,
                "lng": -123.7059324117
            },
            {
                "lat": 49.1405068365,
                "lng": -123.7014169792
            },
            {
                "lat": 49.1514509887,
                "lng": -123.7068856206
            },
            {
                "lat": 49.152404324,
                "lng": -123.7183078842
            },
            {
                "lat": 49.155956201,
                "lng": -123.744101804
            },
            {
                "lat": 49.1593053695,
                "lng": -123.7661357655
            },
            {
                "lat": 49.17120313,
                "lng": -123.8008722327
            },
            {
                "lat": 49.1773907643,
                "lng": -123.8218159451
            },
            {
                "lat": 49.1840517793,
                "lng": -123.8287115554
            },
            {
                "lat": 49.191901856,
                "lng": -123.8299028202
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 652,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 796,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 29787827.9696,
            "FEATURE_LENGTH_M": 31806.5123,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.15983971164242,
            "lng": -123.79021617968179
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1873278585,
                "lng": -125.9338152718
            },
            {
                "lat": 49.1887852783,
                "lng": -125.9349129705
            },
            {
                "lat": 49.1911580045,
                "lng": -125.9380112636
            },
            {
                "lat": 49.1911579829,
                "lng": -125.941482928
            },
            {
                "lat": 49.1933476296,
                "lng": -125.947861017
            },
            {
                "lat": 49.1980892067,
                "lng": -125.9591751834
            },
            {
                "lat": 49.2028355278,
                "lng": -125.9717631015
            },
            {
                "lat": 49.2053873019,
                "lng": -125.9925610296
            },
            {
                "lat": 49.2095830745,
                "lng": -126.0102622843
            },
            {
                "lat": 49.2033802487,
                "lng": -126.0182873219
            },
            {
                "lat": 49.195537504,
                "lng": -126.0142742733
            },
            {
                "lat": 49.1927981435,
                "lng": -126.0046007638
            },
            {
                "lat": 49.1842265293,
                "lng": -126.0000385328
            },
            {
                "lat": 49.1741903255,
                "lng": -125.9993132858
            },
            {
                "lat": 49.1704489181,
                "lng": -126.0019837697
            },
            {
                "lat": 49.1671334133,
                "lng": -126.0074302777
            },
            {
                "lat": 49.1646616445,
                "lng": -126.0115655964
            },
            {
                "lat": 49.1642030689,
                "lng": -126.014442721
            },
            {
                "lat": 49.1658179047,
                "lng": -126.016754687
            },
            {
                "lat": 49.1654091268,
                "lng": -126.0188451127
            },
            {
                "lat": 49.165321715,
                "lng": -126.0199965237
            },
            {
                "lat": 49.1646885292,
                "lng": -126.0184330545
            },
            {
                "lat": 49.1622310766,
                "lng": -126.0173491935
            },
            {
                "lat": 49.1607327627,
                "lng": -126.0165405666
            },
            {
                "lat": 49.1605373069,
                "lng": -126.0142815178
            },
            {
                "lat": 49.1611018252,
                "lng": -126.0123145773
            },
            {
                "lat": 49.1626816819,
                "lng": -126.0111619023
            },
            {
                "lat": 49.1636734291,
                "lng": -126.0090254182
            },
            {
                "lat": 49.1638446644,
                "lng": -126.0060044166
            },
            {
                "lat": 49.1636549026,
                "lng": -126.0034713091
            },
            {
                "lat": 49.1729016181,
                "lng": -125.9714045337
            },
            {
                "lat": 49.1781194342,
                "lng": -125.9702607012
            },
            {
                "lat": 49.1859703465,
                "lng": -125.9647896942
            },
            {
                "lat": 49.191204422,
                "lng": -125.9600300784
            },
            {
                "lat": 49.1912044163,
                "lng": -125.9505153477
            },
            {
                "lat": 49.1895410066,
                "lng": -125.9443286595
            },
            {
                "lat": 49.1877214467,
                "lng": -125.935799219
            },
            {
                "lat": 49.1873278585,
                "lng": -125.9338152718
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 653,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 797,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 13192880.9268,
            "FEATURE_LENGTH_M": 21570.7135,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.179682556171045,
            "lng": -125.98676061518158
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.17625085,
                "lng": -123.8698352326
            },
            {
                "lat": 49.1704370612,
                "lng": -123.9180296256
            },
            {
                "lat": 49.1665953736,
                "lng": -123.8946002587
            },
            {
                "lat": 49.1662752715,
                "lng": -123.8943629435
            },
            {
                "lat": 49.1659622069,
                "lng": -123.8941116093
            },
            {
                "lat": 49.1632461869,
                "lng": -123.8935168996
            },
            {
                "lat": 49.157748916,
                "lng": -123.891097528
            },
            {
                "lat": 49.1536221583,
                "lng": -123.8886113845
            },
            {
                "lat": 49.149837476,
                "lng": -123.8857796476
            },
            {
                "lat": 49.1473124333,
                "lng": -123.8840262925
            },
            {
                "lat": 49.1455534431,
                "lng": -123.8799592977
            },
            {
                "lat": 49.1452937176,
                "lng": -123.8765484244
            },
            {
                "lat": 49.1470226037,
                "lng": -123.8762965791
            },
            {
                "lat": 49.17625085,
                "lng": -123.8698352326
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 654,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 15,
            "MANAGEMENT_AREA_NAME": "Subarea 17-15",
            "LABEL": "17-15",
            "FISHERY_AREA_ID": 2206,
            "OBJECTID": 798,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4774179.8559,
            "FEATURE_LENGTH_M": 11709.4849,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.15938632486428,
            "lng": -123.88690078255001
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1702847238,
                "lng": -123.8654779227
            },
            {
                "lat": 49.1735955511,
                "lng": -123.8687884353
            },
            {
                "lat": 49.17625085,
                "lng": -123.8698352326
            },
            {
                "lat": 49.1470226037,
                "lng": -123.8762965791
            },
            {
                "lat": 49.1452937176,
                "lng": -123.8765484244
            },
            {
                "lat": 49.1413646111,
                "lng": -123.8735347182
            },
            {
                "lat": 49.1380922763,
                "lng": -123.8656774519
            },
            {
                "lat": 49.1371457878,
                "lng": -123.8614650254
            },
            {
                "lat": 49.1371761655,
                "lng": -123.8575587286
            },
            {
                "lat": 49.1353413138,
                "lng": -123.8489144126
            },
            {
                "lat": 49.1314158133,
                "lng": -123.8309789155
            },
            {
                "lat": 49.1322016614,
                "lng": -123.8233865575
            },
            {
                "lat": 49.1281433914,
                "lng": -123.8173597234
            },
            {
                "lat": 49.1201550733,
                "lng": -123.8108136167
            },
            {
                "lat": 49.1086310388,
                "lng": -123.8080670123
            },
            {
                "lat": 49.1022141408,
                "lng": -123.8066258359
            },
            {
                "lat": 49.1011922837,
                "lng": -123.8082734085
            },
            {
                "lat": 49.0979348022,
                "lng": -123.8024432789
            },
            {
                "lat": 49.0975413691,
                "lng": -123.8018255308
            },
            {
                "lat": 49.0971139799,
                "lng": -123.8011854489
            },
            {
                "lat": 49.0968359649,
                "lng": -123.80087187
            },
            {
                "lat": 49.0966531545,
                "lng": -123.8006444547
            },
            {
                "lat": 49.1061094679,
                "lng": -123.7884598437
            },
            {
                "lat": 49.121731262,
                "lng": -123.7683410436
            },
            {
                "lat": 49.1297033432,
                "lng": -123.7524711199
            },
            {
                "lat": 49.1319536163,
                "lng": -123.7511830116
            },
            {
                "lat": 49.1332401611,
                "lng": -123.7613902788
            },
            {
                "lat": 49.1483730434,
                "lng": -123.799545503
            },
            {
                "lat": 49.1509783173,
                "lng": -123.8188399712
            },
            {
                "lat": 49.1576385668,
                "lng": -123.8402549719
            },
            {
                "lat": 49.165493023,
                "lng": -123.8602437702
            },
            {
                "lat": 49.1702847238,
                "lng": -123.8654779227
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 655,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 16,
            "MANAGEMENT_AREA_NAME": "Subarea 17-16",
            "LABEL": "17-16",
            "FISHERY_AREA_ID": 2207,
            "OBJECTID": 799,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 26795996.4329,
            "FEATURE_LENGTH_M": 28093.7358,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.1320970562125,
            "lng": -123.82446187564061
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1785581699,
                "lng": -125.926215802
            },
            {
                "lat": 49.1837538773,
                "lng": -125.933768973
            },
            {
                "lat": 49.1842649598,
                "lng": -125.9337622431
            },
            {
                "lat": 49.18525686,
                "lng": -125.9339209943
            },
            {
                "lat": 49.1861229319,
                "lng": -125.9339445944
            },
            {
                "lat": 49.1873278585,
                "lng": -125.9338152718
            },
            {
                "lat": 49.1877214467,
                "lng": -125.935799219
            },
            {
                "lat": 49.1895410066,
                "lng": -125.9443286595
            },
            {
                "lat": 49.1912044163,
                "lng": -125.9505153477
            },
            {
                "lat": 49.191204422,
                "lng": -125.9600300784
            },
            {
                "lat": 49.1859703465,
                "lng": -125.9647896942
            },
            {
                "lat": 49.1781194342,
                "lng": -125.9702607012
            },
            {
                "lat": 49.1729016181,
                "lng": -125.9714045337
            },
            {
                "lat": 49.1636549026,
                "lng": -126.0034713091
            },
            {
                "lat": 49.1638446644,
                "lng": -126.0060044166
            },
            {
                "lat": 49.1636734291,
                "lng": -126.0090254182
            },
            {
                "lat": 49.1626816819,
                "lng": -126.0111619023
            },
            {
                "lat": 49.1611018252,
                "lng": -126.0123145773
            },
            {
                "lat": 49.1605373069,
                "lng": -126.0142815178
            },
            {
                "lat": 49.1607327627,
                "lng": -126.0165405666
            },
            {
                "lat": 49.1593974823,
                "lng": -126.018234083
            },
            {
                "lat": 49.1104170076,
                "lng": -125.923530206
            },
            {
                "lat": 49.0976519673,
                "lng": -125.8960037323
            },
            {
                "lat": 49.0946728539,
                "lng": -125.8895797895
            },
            {
                "lat": 49.094771719,
                "lng": -125.8891444584
            },
            {
                "lat": 49.0950045824,
                "lng": -125.8882677434
            },
            {
                "lat": 49.0952374556,
                "lng": -125.8873987059
            },
            {
                "lat": 49.0950198703,
                "lng": -125.8841014199
            },
            {
                "lat": 49.0941384805,
                "lng": -125.8794852172
            },
            {
                "lat": 49.09282318,
                "lng": -125.8742139131
            },
            {
                "lat": 49.0941384015,
                "lng": -125.8706972071
            },
            {
                "lat": 49.0989721807,
                "lng": -125.8687210083
            },
            {
                "lat": 49.1053422446,
                "lng": -125.8700411067
            },
            {
                "lat": 49.107982744,
                "lng": -125.8724517831
            },
            {
                "lat": 49.1106183637,
                "lng": -125.8753133823
            },
            {
                "lat": 49.1117137993,
                "lng": -125.8792644545
            },
            {
                "lat": 49.1143528233,
                "lng": -125.8823404339
            },
            {
                "lat": 49.1174274405,
                "lng": -125.8869550808
            },
            {
                "lat": 49.1194041166,
                "lng": -125.8917849801
            },
            {
                "lat": 49.123798749,
                "lng": -125.8946452427
            },
            {
                "lat": 49.1277545648,
                "lng": -125.8979424159
            },
            {
                "lat": 49.1332434852,
                "lng": -125.9001384215
            },
            {
                "lat": 49.1367606312,
                "lng": -125.9038696931
            },
            {
                "lat": 49.1395917053,
                "lng": -125.9077911257
            },
            {
                "lat": 49.1454312432,
                "lng": -125.9101636027
            },
            {
                "lat": 49.1488420675,
                "lng": -125.9082648817
            },
            {
                "lat": 49.1517606294,
                "lng": -125.9093939723
            },
            {
                "lat": 49.1529155492,
                "lng": -125.9109878981
            },
            {
                "lat": 49.153034267,
                "lng": -125.9120258402
            },
            {
                "lat": 49.1531331995,
                "lng": -125.9129645305
            },
            {
                "lat": 49.1530726787,
                "lng": -125.9132607727
            },
            {
                "lat": 49.1785581699,
                "lng": -125.926215802
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 656,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 24,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 24-8",
            "LABEL": "24-8",
            "FISHERY_AREA_ID": 2261,
            "OBJECTID": 800,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 46019751.9867,
            "FEATURE_LENGTH_M": 33435.2252,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.14317610718463,
            "lng": -125.92501055240001
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1499444683,
                "lng": -123.6416095021
            },
            {
                "lat": 49.1257628681,
                "lng": -123.6816174636
            },
            {
                "lat": 49.1283297006,
                "lng": -123.7020953799
            },
            {
                "lat": 49.1251759288,
                "lng": -123.700142376
            },
            {
                "lat": 49.1235882401,
                "lng": -123.6970373407
            },
            {
                "lat": 49.1050489449,
                "lng": -123.6772611374
            },
            {
                "lat": 49.1001164487,
                "lng": -123.674797378
            },
            {
                "lat": 49.0934982983,
                "lng": -123.6733180715
            },
            {
                "lat": 49.0890546086,
                "lng": -123.6672888559
            },
            {
                "lat": 49.0818404676,
                "lng": -123.6578070284
            },
            {
                "lat": 49.0746308291,
                "lng": -123.6469429319
            },
            {
                "lat": 49.0707286518,
                "lng": -123.6406552466
            },
            {
                "lat": 49.0481264823,
                "lng": -123.6165457434
            },
            {
                "lat": 49.0397947577,
                "lng": -123.61066361
            },
            {
                "lat": 49.0335315439,
                "lng": -123.6031876811
            },
            {
                "lat": 49.0318983466,
                "lng": -123.6012643925
            },
            {
                "lat": 49.0305210606,
                "lng": -123.5996714172
            },
            {
                "lat": 49.030216187,
                "lng": -123.5982744214
            },
            {
                "lat": 49.0291671951,
                "lng": -123.5963213344
            },
            {
                "lat": 49.0282521021,
                "lng": -123.5953453136
            },
            {
                "lat": 49.0152892498,
                "lng": -123.5755161862
            },
            {
                "lat": 49.0142405049,
                "lng": -123.5739057274
            },
            {
                "lat": 49.014052855,
                "lng": -123.5737993643
            },
            {
                "lat": 49.0135303578,
                "lng": -123.5733637666
            },
            {
                "lat": 49.0131494714,
                "lng": -123.5730283922
            },
            {
                "lat": 49.0130039466,
                "lng": -123.5730666972
            },
            {
                "lat": 49.0128130333,
                "lng": -123.5731893267
            },
            {
                "lat": 49.0127607032,
                "lng": -123.5732654742
            },
            {
                "lat": 49.0126955289,
                "lng": -123.5733337515
            },
            {
                "lat": 49.0125582953,
                "lng": -123.5734399272
            },
            {
                "lat": 49.0124277922,
                "lng": -123.5735696583
            },
            {
                "lat": 49.0123368754,
                "lng": -123.5736700094
            },
            {
                "lat": 49.0112111097,
                "lng": -123.5740205269
            },
            {
                "lat": 49.0100168036,
                "lng": -123.5751873519
            },
            {
                "lat": 49.0066792738,
                "lng": -123.5724326228
            },
            {
                "lat": 48.9996605018,
                "lng": -123.5668261637
            },
            {
                "lat": 48.9899026459,
                "lng": -123.5563499763
            },
            {
                "lat": 48.9818118095,
                "lng": -123.5456471546
            },
            {
                "lat": 48.9699174697,
                "lng": -123.5304195168
            },
            {
                "lat": 48.9580186662,
                "lng": -123.5125730627
            },
            {
                "lat": 48.9523086565,
                "lng": -123.4994808297
            },
            {
                "lat": 48.9463575455,
                "lng": -123.4882957756
            },
            {
                "lat": 48.9430280621,
                "lng": -123.4766377905
            },
            {
                "lat": 48.9396972976,
                "lng": -123.46070189
            },
            {
                "lat": 48.9318462773,
                "lng": -123.4404754644
            },
            {
                "lat": 48.9254188362,
                "lng": -123.4269099404
            },
            {
                "lat": 48.9144746959,
                "lng": -123.409064849
            },
            {
                "lat": 48.9080510682,
                "lng": -123.3878848981
            },
            {
                "lat": 48.9042430266,
                "lng": -123.3702775754
            },
            {
                "lat": 48.900913418,
                "lng": -123.3543393519
            },
            {
                "lat": 48.9024699566,
                "lng": -123.348450047
            },
            {
                "lat": 48.9041791288,
                "lng": -123.3436284764
            },
            {
                "lat": 49.1499444683,
                "lng": -123.6416095021
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 657,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 29-5",
            "LABEL": "29-5",
            "FISHERY_AREA_ID": 2530,
            "OBJECTID": 801,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 174012838.1659,
            "FEATURE_LENGTH_M": 78644.8087,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.0160044615434,
            "lng": -123.56313599383022
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1059721246,
                "lng": -123.303138489
            },
            {
                "lat": 49.1499444683,
                "lng": -123.6416095021
            },
            {
                "lat": 48.9041791288,
                "lng": -123.3436284764
            },
            {
                "lat": 48.9024699566,
                "lng": -123.348450047
            },
            {
                "lat": 48.9013593866,
                "lng": -123.3483732881
            },
            {
                "lat": 48.8911289398,
                "lng": -123.3452840194
            },
            {
                "lat": 48.8867107536,
                "lng": -123.3378600891
            },
            {
                "lat": 48.8808250003,
                "lng": -123.3309640103
            },
            {
                "lat": 48.8765827552,
                "lng": -123.3257670627
            },
            {
                "lat": 48.8764571346,
                "lng": -123.3207696185
            },
            {
                "lat": 48.8790820673,
                "lng": -123.3190152035
            },
            {
                "lat": 48.879874876,
                "lng": -123.3184895567
            },
            {
                "lat": 48.8801917426,
                "lng": -123.3144387621
            },
            {
                "lat": 48.8797759694,
                "lng": -123.3120801758
            },
            {
                "lat": 48.8738364536,
                "lng": -123.2913887912
            },
            {
                "lat": 48.9916186027,
                "lng": -123.3030397399
            },
            {
                "lat": 49.0020831606,
                "lng": -123.3222514024
            },
            {
                "lat": 49.0020785696,
                "lng": -123.3014057475
            },
            {
                "lat": 49.0876924822,
                "lng": -123.3102487842
            },
            {
                "lat": 49.1059721246,
                "lng": -123.303138489
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 658,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 29-4",
            "LABEL": "29-4",
            "FISHERY_AREA_ID": 2529,
            "OBJECTID": 802,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 328047113.6604,
            "FEATURE_LENGTH_M": 95011.1399,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.94789178485001,
            "lng": -123.33706706274502
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9999654175,
                "lng": -123.0549622378
            },
            {
                "lat": 49.0020831353,
                "lng": -123.0399461976
            },
            {
                "lat": 49.0020832125,
                "lng": -122.7578586148
            },
            {
                "lat": 49.0080145412,
                "lng": -122.7398672692
            },
            {
                "lat": 49.0298657043,
                "lng": -122.7490688704
            },
            {
                "lat": 49.031013375,
                "lng": -122.7910377843
            },
            {
                "lat": 49.0419391776,
                "lng": -122.8341673052
            },
            {
                "lat": 49.0528647605,
                "lng": -122.8491139059
            },
            {
                "lat": 49.0672373585,
                "lng": -122.7594158036
            },
            {
                "lat": 49.1040384174,
                "lng": -122.7490692574
            },
            {
                "lat": 49.1235881082,
                "lng": -122.7801129644
            },
            {
                "lat": 49.1155392961,
                "lng": -122.8128898111
            },
            {
                "lat": 49.102889528,
                "lng": -122.8669348157
            },
            {
                "lat": 49.0936892817,
                "lng": -122.9117882572
            },
            {
                "lat": 49.0839115124,
                "lng": -122.9543372519
            },
            {
                "lat": 49.0821874024,
                "lng": -122.9928600277
            },
            {
                "lat": 49.0689659551,
                "lng": -123.0371325169
            },
            {
                "lat": 49.0471150012,
                "lng": -123.0635833064
            },
            {
                "lat": 49.0172160203,
                "lng": -123.0658871724
            },
            {
                "lat": 48.9999654175,
                "lng": -123.0549622378
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 659,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 29-8",
            "LABEL": "29-8",
            "FISHERY_AREA_ID": 2533,
            "OBJECTID": 803,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 214225649.5504,
            "FEATURE_LENGTH_M": 76294.4717,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.053708631135,
            "lng": -122.89324978038496
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1281773627,
                "lng": -123.1883166969
            },
            {
                "lat": 49.1264075632,
                "lng": -123.1931524279
            },
            {
                "lat": 49.1257780193,
                "lng": -123.19724286
            },
            {
                "lat": 49.1259805104,
                "lng": -123.2006839124
            },
            {
                "lat": 49.126605544,
                "lng": -123.2021033061
            },
            {
                "lat": 49.1269758792,
                "lng": -123.2034832543
            },
            {
                "lat": 49.1297872667,
                "lng": -123.2139350914
            },
            {
                "lat": 49.1318059798,
                "lng": -123.2225878805
            },
            {
                "lat": 49.1327592665,
                "lng": -123.2292174912
            },
            {
                "lat": 49.1325534019,
                "lng": -123.2345652183
            },
            {
                "lat": 49.1320375336,
                "lng": -123.2375409609
            },
            {
                "lat": 49.1280294105,
                "lng": -123.248542885
            },
            {
                "lat": 49.1068651504,
                "lng": -123.3009185828
            },
            {
                "lat": 49.1059721246,
                "lng": -123.303138489
            },
            {
                "lat": 49.0876924822,
                "lng": -123.3102487842
            },
            {
                "lat": 49.0381661442,
                "lng": -123.2563093946
            },
            {
                "lat": 49.0682338685,
                "lng": -123.1527477989
            },
            {
                "lat": 49.0724865314,
                "lng": -123.1527478793
            },
            {
                "lat": 49.1064297186,
                "lng": -123.1683576876
            },
            {
                "lat": 49.1101955991,
                "lng": -123.1683579782
            },
            {
                "lat": 49.1164775286,
                "lng": -123.1683575568
            },
            {
                "lat": 49.1169897292,
                "lng": -123.1680080089
            },
            {
                "lat": 49.1163027993,
                "lng": -123.1659460655
            },
            {
                "lat": 49.1156391759,
                "lng": -123.1643209619
            },
            {
                "lat": 49.1151271826,
                "lng": -123.161849636
            },
            {
                "lat": 49.1145821951,
                "lng": -123.160369731
            },
            {
                "lat": 49.11424581,
                "lng": -123.1590500073
            },
            {
                "lat": 49.1141200155,
                "lng": -123.1578372608
            },
            {
                "lat": 49.1141667381,
                "lng": -123.1572404563
            },
            {
                "lat": 49.1155275562,
                "lng": -123.1559146369
            },
            {
                "lat": 49.1222527334,
                "lng": -123.1536410464
            },
            {
                "lat": 49.1251072137,
                "lng": -123.1590187402
            },
            {
                "lat": 49.1294974168,
                "lng": -123.1792067159
            },
            {
                "lat": 49.1281773627,
                "lng": -123.1883166969
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 660,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 29-9",
            "LABEL": "29-9",
            "FISHERY_AREA_ID": 2534,
            "OBJECTID": 804,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 81830419.1887,
            "FEATURE_LENGTH_M": 36689.6562,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.114739788644115,
            "lng": -123.19656694412646
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.1273494076,
                "lng": -123.0497436442
            },
            {
                "lat": 49.127220135,
                "lng": -123.0526798076
            },
            {
                "lat": 49.1269572695,
                "lng": -123.0545645208
            },
            {
                "lat": 49.1267627777,
                "lng": -123.0560681929
            },
            {
                "lat": 49.1262434602,
                "lng": -123.0572362478
            },
            {
                "lat": 49.1251329752,
                "lng": -123.0597155032
            },
            {
                "lat": 49.1203157354,
                "lng": -123.0677940008
            },
            {
                "lat": 49.1182282432,
                "lng": -123.0742424606
            },
            {
                "lat": 49.1169315979,
                "lng": -123.0776141564
            },
            {
                "lat": 49.1165042922,
                "lng": -123.078902611
            },
            {
                "lat": 49.1163099709,
                "lng": -123.0794916934
            },
            {
                "lat": 49.1161654174,
                "lng": -123.0801923411
            },
            {
                "lat": 49.1160922167,
                "lng": -123.0804828073
            },
            {
                "lat": 49.1147650003,
                "lng": -123.0860368726
            },
            {
                "lat": 49.1147381696,
                "lng": -123.0863038762
            },
            {
                "lat": 49.1147265259,
                "lng": -123.086518576
            },
            {
                "lat": 49.1147044975,
                "lng": -123.0866928431
            },
            {
                "lat": 49.1146928424,
                "lng": -123.0868236668
            },
            {
                "lat": 49.114677803,
                "lng": -123.0869752433
            },
            {
                "lat": 49.1146509259,
                "lng": -123.0872199957
            },
            {
                "lat": 49.1146391335,
                "lng": -123.0873251512
            },
            {
                "lat": 49.1146206414,
                "lng": -123.0874324375
            },
            {
                "lat": 49.114597511,
                "lng": -123.0875554199
            },
            {
                "lat": 49.1145589394,
                "lng": -123.0877529738
            },
            {
                "lat": 49.1144983454,
                "lng": -123.0879587521
            },
            {
                "lat": 49.1143226588,
                "lng": -123.0883018224
            },
            {
                "lat": 49.1140665783,
                "lng": -123.0886601886
            },
            {
                "lat": 49.1115415097,
                "lng": -123.0921934707
            },
            {
                "lat": 49.1100582151,
                "lng": -123.0968166914
            },
            {
                "lat": 49.1083445955,
                "lng": -123.1046442112
            },
            {
                "lat": 49.1056589933,
                "lng": -123.109954018
            },
            {
                "lat": 49.1044040551,
                "lng": -123.1134940004
            },
            {
                "lat": 49.1035460243,
                "lng": -123.1181797559
            },
            {
                "lat": 49.1040036705,
                "lng": -123.1189198229
            },
            {
                "lat": 49.104171098,
                "lng": -123.119063484
            },
            {
                "lat": 49.1042666768,
                "lng": -123.1191944781
            },
            {
                "lat": 49.1043133509,
                "lng": -123.1193079623
            },
            {
                "lat": 49.1043354316,
                "lng": -123.1194383703
            },
            {
                "lat": 49.1043666968,
                "lng": -123.1196281076
            },
            {
                "lat": 49.1044353789,
                "lng": -123.1199113666
            },
            {
                "lat": 49.1045379233,
                "lng": -123.1202079341
            },
            {
                "lat": 49.1047137903,
                "lng": -123.1206351662
            },
            {
                "lat": 49.1049151423,
                "lng": -123.1211309918
            },
            {
                "lat": 49.1051258481,
                "lng": -123.1216502027
            },
            {
                "lat": 49.1053504047,
                "lng": -123.1221925213
            },
            {
                "lat": 49.1055798584,
                "lng": -123.122749945
            },
            {
                "lat": 49.1057543308,
                "lng": -123.1232833886
            },
            {
                "lat": 49.1058917203,
                "lng": -123.1237249995
            },
            {
                "lat": 49.1060593915,
                "lng": -123.1242674272
            },
            {
                "lat": 49.1061480934,
                "lng": -123.1245734259
            },
            {
                "lat": 49.1062318393,
                "lng": -123.124946477
            },
            {
                "lat": 49.1062853966,
                "lng": -123.125275185
            },
            {
                "lat": 49.1063539396,
                "lng": -123.1258699502
            },
            {
                "lat": 49.1063656448,
                "lng": -123.1262970828
            },
            {
                "lat": 49.1063388151,
                "lng": -123.1268840442
            },
            {
                "lat": 49.1063040731,
                "lng": -123.1275331039
            },
            {
                "lat": 49.1062653763,
                "lng": -123.1284562327
            },
            {
                "lat": 49.1062468836,
                "lng": -123.1291658986
            },
            {
                "lat": 49.1062236501,
                "lng": -123.129814245
            },
            {
                "lat": 49.1062166445,
                "lng": -123.1305009528
            },
            {
                "lat": 49.1062015023,
                "lng": -123.1312104099
            },
            {
                "lat": 49.1061780992,
                "lng": -123.1318211133
            },
            {
                "lat": 49.1061514022,
                "lng": -123.1322557439
            },
            {
                "lat": 49.1061443744,
                "lng": -123.132636109
            },
            {
                "lat": 49.1061398126,
                "lng": -123.1329718255
            },
            {
                "lat": 49.1061176959,
                "lng": -123.1333462737
            },
            {
                "lat": 49.1060711989,
                "lng": -123.1338197723
            },
            {
                "lat": 49.1060339821,
                "lng": -123.1341557865
            },
            {
                "lat": 49.1059570502,
                "lng": -123.1345969283
            },
            {
                "lat": 49.1060070994,
                "lng": -123.1352390455
            },
            {
                "lat": 49.1060442985,
                "lng": -123.1355362516
            },
            {
                "lat": 49.1060756942,
                "lng": -123.1357739162
            },
            {
                "lat": 49.106117713,
                "lng": -123.1359561627
            },
            {
                "lat": 49.106124623,
                "lng": -123.1361628173
            },
            {
                "lat": 49.1061398085,
                "lng": -123.1365743312
            },
            {
                "lat": 49.1061596573,
                "lng": -123.1368862972
            },
            {
                "lat": 49.1061478608,
                "lng": -123.1374055774
            },
            {
                "lat": 49.1061282129,
                "lng": -123.137725106
            },
            {
                "lat": 49.1061548565,
                "lng": -123.1380914193
            },
            {
                "lat": 49.106193255,
                "lng": -123.138518621
            },
            {
                "lat": 49.1062386586,
                "lng": -123.1389761982
            },
            {
                "lat": 49.106231701,
                "lng": -123.139466087
            },
            {
                "lat": 49.1062817917,
                "lng": -123.1403649194
            },
            {
                "lat": 49.1062819307,
                "lng": -123.1412805117
            },
            {
                "lat": 49.1063608565,
                "lng": -123.1418455636
            },
            {
                "lat": 49.1063610778,
                "lng": -123.1424257163
            },
            {
                "lat": 49.1063611127,
                "lng": -123.1427765521
            },
            {
                "lat": 49.1064110392,
                "lng": -123.1434477821
            },
            {
                "lat": 49.1064610661,
                "lng": -123.1442182687
            },
            {
                "lat": 49.106467996,
                "lng": -123.1445002257
            },
            {
                "lat": 49.1065031458,
                "lng": -123.1451877671
            },
            {
                "lat": 49.1065368555,
                "lng": -123.1460123102
            },
            {
                "lat": 49.1065750764,
                "lng": -123.1467202019
            },
            {
                "lat": 49.1066323638,
                "lng": -123.147308839
            },
            {
                "lat": 49.106704341,
                "lng": -123.1479633226
            },
            {
                "lat": 49.1067613143,
                "lng": -123.148635841
            },
            {
                "lat": 49.1068381826,
                "lng": -123.1493687535
            },
            {
                "lat": 49.1068917517,
                "lng": -123.1501150743
            },
            {
                "lat": 49.1069254763,
                "lng": -123.150527173
            },
            {
                "lat": 49.1069453827,
                "lng": -123.1508853525
            },
            {
                "lat": 49.1069826878,
                "lng": -123.1516874365
            },
            {
                "lat": 49.1069989427,
                "lng": -123.1522905729
            },
            {
                "lat": 49.1070258156,
                "lng": -123.1526568843
            },
            {
                "lat": 49.1070862501,
                "lng": -123.1530998693
            },
            {
                "lat": 49.1071699937,
                "lng": -123.1538169693
            },
            {
                "lat": 49.107215567,
                "lng": -123.154219791
            },
            {
                "lat": 49.1072991466,
                "lng": -123.154846199
            },
            {
                "lat": 49.1073644119,
                "lng": -123.1552272822
            },
            {
                "lat": 49.1074529537,
                "lng": -123.1558071819
            },
            {
                "lat": 49.1075287476,
                "lng": -123.1563947079
            },
            {
                "lat": 49.1076207193,
                "lng": -123.1570514167
            },
            {
                "lat": 49.1078068854,
                "lng": -123.157906039
            },
            {
                "lat": 49.1079103171,
                "lng": -123.1584850396
            },
            {
                "lat": 49.1080246921,
                "lng": -123.1588819612
            },
            {
                "lat": 49.1081014536,
                "lng": -123.1593256774
            },
            {
                "lat": 49.1082153608,
                "lng": -123.1599502546
            },
            {
                "lat": 49.1083027007,
                "lng": -123.1603471195
            },
            {
                "lat": 49.1083983247,
                "lng": -123.1607588838
            },
            {
                "lat": 49.1085588536,
                "lng": -123.161507274
            },
            {
                "lat": 49.1087112804,
                "lng": -123.1621089763
            },
            {
                "lat": 49.1088638704,
                "lng": -123.1627808429
            },
            {
                "lat": 49.1090047677,
                "lng": -123.1633832554
            },
            {
                "lat": 49.1090664161,
                "lng": -123.1636499136
            },
            {
                "lat": 49.1091689424,
                "lng": -123.164025329
            },
            {
                "lat": 49.1092563488,
                "lng": -123.1644358966
            },
            {
                "lat": 49.1093366175,
                "lng": -123.1647784407
            },
            {
                "lat": 49.1093982022,
                "lng": -123.1651067201
            },
            {
                "lat": 49.1095089005,
                "lng": -123.1655038929
            },
            {
                "lat": 49.1095809261,
                "lng": -123.1658332503
            },
            {
                "lat": 49.1096114132,
                "lng": -123.1659323729
            },
            {
                "lat": 49.1096951749,
                "lng": -123.1662370562
            },
            {
                "lat": 49.1097637237,
                "lng": -123.1664878982
            },
            {
                "lat": 49.1098139221,
                "lng": -123.1667261656
            },
            {
                "lat": 49.1099094213,
                "lng": -123.1670917516
            },
            {
                "lat": 49.1099931533,
                "lng": -123.1674272477
            },
            {
                "lat": 49.1100270227,
                "lng": -123.1676014731
            },
            {
                "lat": 49.1100734051,
                "lng": -123.1678553786
            },
            {
                "lat": 49.1101153692,
                "lng": -123.1680376697
            },
            {
                "lat": 49.1101490575,
                "lng": -123.1682050608
            },
            {
                "lat": 49.1101955991,
                "lng": -123.1683579782
            },
            {
                "lat": 49.1064297186,
                "lng": -123.1683576876
            },
            {
                "lat": 49.0724865314,
                "lng": -123.1527478793
            },
            {
                "lat": 49.0682338685,
                "lng": -123.1527477989
            },
            {
                "lat": 49.0639765249,
                "lng": -123.1466831937
            },
            {
                "lat": 49.0665434799,
                "lng": -123.1402967053
            },
            {
                "lat": 49.072017221,
                "lng": -123.1203076558
            },
            {
                "lat": 49.0777285904,
                "lng": -123.0874708804
            },
            {
                "lat": 49.0834352277,
                "lng": -123.0731964395
            },
            {
                "lat": 49.1041373071,
                "lng": -123.0565404221
            },
            {
                "lat": 49.1253167548,
                "lng": -123.0479736835
            },
            {
                "lat": 49.1273494076,
                "lng": -123.0497436442
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 661,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 29,
            "MANAGEMENT_SUBAREA": 14,
            "MANAGEMENT_AREA_NAME": "Subarea 29-14",
            "LABEL": "29-14",
            "FISHERY_AREA_ID": 2539,
            "OBJECTID": 805,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 30222524.6606,
            "FEATURE_LENGTH_M": 26212.9889,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 49.10756191285628,
            "lng": -123.12791254177941
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.842483473,
                "lng": -123.4194648016
            },
            {
                "lat": 48.8428723209,
                "lng": -123.4199283164
            },
            {
                "lat": 48.8438945267,
                "lng": -123.4207074834
            },
            {
                "lat": 48.8469585405,
                "lng": -123.4231950763
            },
            {
                "lat": 48.8495137415,
                "lng": -123.4264137823
            },
            {
                "lat": 48.8536873107,
                "lng": -123.434547621
            },
            {
                "lat": 48.8582995833,
                "lng": -123.442672533
            },
            {
                "lat": 48.860362721,
                "lng": -123.4473428705
            },
            {
                "lat": 48.8615954471,
                "lng": -123.4501336305
            },
            {
                "lat": 48.8666455495,
                "lng": -123.4644086399
            },
            {
                "lat": 48.8706968164,
                "lng": -123.4789123649
            },
            {
                "lat": 48.8708191256,
                "lng": -123.4793467232
            },
            {
                "lat": 48.8712579643,
                "lng": -123.4863748565
            },
            {
                "lat": 48.865108793,
                "lng": -123.4988932139
            },
            {
                "lat": 48.8587373869,
                "lng": -123.5039447184
            },
            {
                "lat": 48.8501740685,
                "lng": -123.5037237716
            },
            {
                "lat": 48.8321645785,
                "lng": -123.4795685359
            },
            {
                "lat": 48.8117407007,
                "lng": -123.4442058145
            },
            {
                "lat": 48.7766039811,
                "lng": -123.386665801
            },
            {
                "lat": 48.7728961838,
                "lng": -123.3808373947
            },
            {
                "lat": 48.7733119269,
                "lng": -123.3805168557
            },
            {
                "lat": 48.7719917969,
                "lng": -123.3748088823
            },
            {
                "lat": 48.7736891874,
                "lng": -123.369438334
            },
            {
                "lat": 48.7751280471,
                "lng": -123.3671946888
            },
            {
                "lat": 48.7753643097,
                "lng": -123.3666903839
            },
            {
                "lat": 48.8092609803,
                "lng": -123.3642644658
            },
            {
                "lat": 48.809501959,
                "lng": -123.3653102436
            },
            {
                "lat": 48.8103062313,
                "lng": -123.366882115
            },
            {
                "lat": 48.8118440874,
                "lng": -123.3697664124
            },
            {
                "lat": 48.8134495511,
                "lng": -123.3728035511
            },
            {
                "lat": 48.8159024155,
                "lng": -123.3762441079
            },
            {
                "lat": 48.8183518552,
                "lng": -123.379676706
            },
            {
                "lat": 48.8194846567,
                "lng": -123.380081072
            },
            {
                "lat": 48.8211632216,
                "lng": -123.3796453869
            },
            {
                "lat": 48.8243519022,
                "lng": -123.3770297264
            },
            {
                "lat": 48.8258967272,
                "lng": -123.3784554033
            },
            {
                "lat": 48.8282705012,
                "lng": -123.3842081681
            },
            {
                "lat": 48.8310013643,
                "lng": -123.3903890021
            },
            {
                "lat": 48.834384458,
                "lng": -123.3948977046
            },
            {
                "lat": 48.8384824472,
                "lng": -123.3993520513
            },
            {
                "lat": 48.8398477762,
                "lng": -123.4017263225
            },
            {
                "lat": 48.8412133987,
                "lng": -123.4043355166
            },
            {
                "lat": 48.8426361361,
                "lng": -123.4068293968
            },
            {
                "lat": 48.8433496269,
                "lng": -123.4083332094
            },
            {
                "lat": 48.842483473,
                "lng": -123.4194648016
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 701,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 18,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 18-3",
            "LABEL": "18-3",
            "FISHERY_AREA_ID": 2215,
            "OBJECTID": 845,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 49190861.0907,
            "FEATURE_LENGTH_M": 32625.1911,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.828826241113326,
            "lng": -123.41265849905778
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.8355940134,
                "lng": -123.3145282198
            },
            {
                "lat": 48.8336674985,
                "lng": -123.3160932366
            },
            {
                "lat": 48.8330388398,
                "lng": -123.3177182235
            },
            {
                "lat": 48.8328279449,
                "lng": -123.3191678533
            },
            {
                "lat": 48.8328514646,
                "lng": -123.3202740217
            },
            {
                "lat": 48.8331411107,
                "lng": -123.3221662696
            },
            {
                "lat": 48.8329583801,
                "lng": -123.3245856844
            },
            {
                "lat": 48.8331145479,
                "lng": -123.3265604302
            },
            {
                "lat": 48.8333239998,
                "lng": -123.3274688615
            },
            {
                "lat": 48.8333472045,
                "lng": -123.3275747174
            },
            {
                "lat": 48.8334997125,
                "lng": -123.3279181812
            },
            {
                "lat": 48.8190235395,
                "lng": -123.3272026072
            },
            {
                "lat": 48.8188361606,
                "lng": -123.3259275362
            },
            {
                "lat": 48.8187335794,
                "lng": -123.3244180353
            },
            {
                "lat": 48.8187335879,
                "lng": -123.3231816008
            },
            {
                "lat": 48.8178522765,
                "lng": -123.321112855
            },
            {
                "lat": 48.8162690046,
                "lng": -123.319176169
            },
            {
                "lat": 48.8129197706,
                "lng": -123.316186018
            },
            {
                "lat": 48.8087917,
                "lng": -123.3140486063
            },
            {
                "lat": 48.7878568903,
                "lng": -123.2871405834
            },
            {
                "lat": 48.7835086899,
                "lng": -123.2845318114
            },
            {
                "lat": 48.7753259746,
                "lng": -123.2819434632
            },
            {
                "lat": 48.7672500706,
                "lng": -123.2795638772
            },
            {
                "lat": 48.7650754676,
                "lng": -123.2764587617
            },
            {
                "lat": 48.7637285614,
                "lng": -123.2687377902
            },
            {
                "lat": 48.7637285953,
                "lng": -123.2608180003
            },
            {
                "lat": 48.7643512645,
                "lng": -123.2579734995
            },
            {
                "lat": 48.7648660036,
                "lng": -123.2575832677
            },
            {
                "lat": 48.765056995,
                "lng": -123.2570029911
            },
            {
                "lat": 48.7652326702,
                "lng": -123.2565612125
            },
            {
                "lat": 48.7653922701,
                "lng": -123.2551112446
            },
            {
                "lat": 48.7648158098,
                "lng": -123.2540441116
            },
            {
                "lat": 48.7627111531,
                "lng": -123.251136653
            },
            {
                "lat": 48.7399066083,
                "lng": -123.1940767293
            },
            {
                "lat": 48.7379147884,
                "lng": -123.1911700433
            },
            {
                "lat": 48.7365373682,
                "lng": -123.1876902771
            },
            {
                "lat": 48.7359507173,
                "lng": -123.1861260233
            },
            {
                "lat": 48.7357295947,
                "lng": -123.1851341417
            },
            {
                "lat": 48.7356374704,
                "lng": -123.1845471125
            },
            {
                "lat": 48.7356375511,
                "lng": -123.1842194214
            },
            {
                "lat": 48.7357096023,
                "lng": -123.1839824943
            },
            {
                "lat": 48.7357864458,
                "lng": -123.1837775383
            },
            {
                "lat": 48.7177084643,
                "lng": -123.1837683124
            },
            {
                "lat": 48.7663454881,
                "lng": -123.011207497
            },
            {
                "lat": 48.783138582,
                "lng": -123.046302524
            },
            {
                "lat": 48.7828183551,
                "lng": -123.0495844071
            },
            {
                "lat": 48.7825772403,
                "lng": -123.0531536135
            },
            {
                "lat": 48.7813876453,
                "lng": -123.0576779993
            },
            {
                "lat": 48.7813876756,
                "lng": -123.0641026051
            },
            {
                "lat": 48.7825774805,
                "lng": -123.0707622179
            },
            {
                "lat": 48.783530892,
                "lng": -123.0798028427
            },
            {
                "lat": 48.7849581078,
                "lng": -123.0921781479
            },
            {
                "lat": 48.7854340793,
                "lng": -123.0995561213
            },
            {
                "lat": 48.7882421698,
                "lng": -123.1158521471
            },
            {
                "lat": 48.7928697838,
                "lng": -123.1326982887
            },
            {
                "lat": 48.796004766,
                "lng": -123.1425251986
            },
            {
                "lat": 48.8001361761,
                "lng": -123.1585462515
            },
            {
                "lat": 48.8055833642,
                "lng": -123.177871068
            },
            {
                "lat": 48.8081667465,
                "lng": -123.1830516719
            },
            {
                "lat": 48.8110165994,
                "lng": -123.1858904509
            },
            {
                "lat": 48.8126370712,
                "lng": -123.1872710239
            },
            {
                "lat": 48.8132970326,
                "lng": -123.1879882179
            },
            {
                "lat": 48.8137814573,
                "lng": -123.1886819461
            },
            {
                "lat": 48.8137896034,
                "lng": -123.1888736344
            },
            {
                "lat": 48.814387985,
                "lng": -123.189177888
            },
            {
                "lat": 48.8144226609,
                "lng": -123.1893611853
            },
            {
                "lat": 48.8145298542,
                "lng": -123.1897204097
            },
            {
                "lat": 48.8146825471,
                "lng": -123.1901177251
            },
            {
                "lat": 48.8149875132,
                "lng": -123.1907355135
            },
            {
                "lat": 48.8155019073,
                "lng": -123.1918952018
            },
            {
                "lat": 48.8158115318,
                "lng": -123.1925195359
            },
            {
                "lat": 48.8212389757,
                "lng": -123.2052389292
            },
            {
                "lat": 48.8215823655,
                "lng": -123.2058869241
            },
            {
                "lat": 48.8226206397,
                "lng": -123.2080226923
            },
            {
                "lat": 48.8233647514,
                "lng": -123.2093578793
            },
            {
                "lat": 48.8243412557,
                "lng": -123.2112882487
            },
            {
                "lat": 48.8258094576,
                "lng": -123.2159503287
            },
            {
                "lat": 48.8333357258,
                "lng": -123.2335885304
            },
            {
                "lat": 48.8466337435,
                "lng": -123.2439347739
            },
            {
                "lat": 48.8467559658,
                "lng": -123.2446661742
            },
            {
                "lat": 48.846931706,
                "lng": -123.245550999
            },
            {
                "lat": 48.8474196495,
                "lng": -123.2470539667
            },
            {
                "lat": 48.8480073406,
                "lng": -123.2485051545
            },
            {
                "lat": 48.8482250106,
                "lng": -123.251853566
            },
            {
                "lat": 48.8458061049,
                "lng": -123.2563860804
            },
            {
                "lat": 48.8447001741,
                "lng": -123.2563011043
            },
            {
                "lat": 48.8393787591,
                "lng": -123.2559049215
            },
            {
                "lat": 48.8336674699,
                "lng": -123.2606662979
            },
            {
                "lat": 48.831291318,
                "lng": -123.2725608011
            },
            {
                "lat": 48.8327174671,
                "lng": -123.2863619513
            },
            {
                "lat": 48.8331914058,
                "lng": -123.2992098461
            },
            {
                "lat": 48.8362508215,
                "lng": -123.3103187874
            },
            {
                "lat": 48.8355940134,
                "lng": -123.3145282198
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 702,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 18,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 18-5",
            "LABEL": "18-5",
            "FISHERY_AREA_ID": 2217,
            "OBJECTID": 846,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 137128858.2651,
            "FEATURE_LENGTH_M": 60651.4859,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.8017936752645,
            "lng": -123.22579193547203
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.8467221246,
                "lng": -123.5638352092
            },
            {
                "lat": 48.8500598293,
                "lng": -123.5675056708
            },
            {
                "lat": 48.8502088987,
                "lng": -123.5692064008
            },
            {
                "lat": 48.8501739073,
                "lng": -123.5704419218
            },
            {
                "lat": 48.8499981638,
                "lng": -123.5707011807
            },
            {
                "lat": 48.8491284711,
                "lng": -123.5782163983
            },
            {
                "lat": 48.8481179664,
                "lng": -123.5869517893
            },
            {
                "lat": 48.8475113955,
                "lng": -123.5921710593
            },
            {
                "lat": 48.8472938014,
                "lng": -123.594863868
            },
            {
                "lat": 48.8318139498,
                "lng": -123.6343381883
            },
            {
                "lat": 48.78985576,
                "lng": -123.6118313117
            },
            {
                "lat": 48.7791139251,
                "lng": -123.5949474516
            },
            {
                "lat": 48.7751465057,
                "lng": -123.5929405111
            },
            {
                "lat": 48.7558065848,
                "lng": -123.5781030205
            },
            {
                "lat": 48.7444653755,
                "lng": -123.5714195994
            },
            {
                "lat": 48.7427365265,
                "lng": -123.5700540275
            },
            {
                "lat": 48.7157514178,
                "lng": -123.554870212
            },
            {
                "lat": 48.7157282883,
                "lng": -123.5555195958
            },
            {
                "lat": 48.7142986757,
                "lng": -123.5620891378
            },
            {
                "lat": 48.7052763371,
                "lng": -123.5617225294
            },
            {
                "lat": 48.6988911574,
                "lng": -123.5523311571
            },
            {
                "lat": 48.695076055,
                "lng": -123.5411067905
            },
            {
                "lat": 48.6935081534,
                "lng": -123.5364077273
            },
            {
                "lat": 48.6938969427,
                "lng": -123.5347676382
            },
            {
                "lat": 48.6893997359,
                "lng": -123.485305278
            },
            {
                "lat": 48.7092356544,
                "lng": -123.4948273476
            },
            {
                "lat": 48.7148051629,
                "lng": -123.4975049217
            },
            {
                "lat": 48.7274897371,
                "lng": -123.5043718326
            },
            {
                "lat": 48.7276305095,
                "lng": -123.5063552604
            },
            {
                "lat": 48.7394908967,
                "lng": -123.5217279602
            },
            {
                "lat": 48.7542035638,
                "lng": -123.544792486
            },
            {
                "lat": 48.7640871868,
                "lng": -123.5533525477
            },
            {
                "lat": 48.7671627715,
                "lng": -123.5458896218
            },
            {
                "lat": 48.7807763777,
                "lng": -123.5371013505
            },
            {
                "lat": 48.7816541971,
                "lng": -123.5182177928
            },
            {
                "lat": 48.7847670838,
                "lng": -123.5148532583
            },
            {
                "lat": 48.7931899169,
                "lng": -123.514785981
            },
            {
                "lat": 48.8434182679,
                "lng": -123.5602111401
            },
            {
                "lat": 48.8467221246,
                "lng": -123.5638352092
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 703,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 18,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 18-7",
            "LABEL": "18-7",
            "FISHERY_AREA_ID": 2219,
            "OBJECTID": 847,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 82361618.417,
            "FEATURE_LENGTH_M": 49824.9739,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.77216957434615,
            "lng": -123.55408908677695
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.8301692318,
                "lng": -123.3580854488
            },
            {
                "lat": 48.829723394,
                "lng": -123.3594748685
            },
            {
                "lat": 48.8296358082,
                "lng": -123.3597485774
            },
            {
                "lat": 48.8304484675,
                "lng": -123.3620690544
            },
            {
                "lat": 48.8320085758,
                "lng": -123.3665165693
            },
            {
                "lat": 48.8341481061,
                "lng": -123.370850198
            },
            {
                "lat": 48.8356323879,
                "lng": -123.3747717768
            },
            {
                "lat": 48.8358117122,
                "lng": -123.3774412878
            },
            {
                "lat": 48.8358117854,
                "lng": -123.378936731
            },
            {
                "lat": 48.8383030697,
                "lng": -123.3832005611
            },
            {
                "lat": 48.8407978902,
                "lng": -123.3878934097
            },
            {
                "lat": 48.8400225537,
                "lng": -123.3906249278
            },
            {
                "lat": 48.8386801423,
                "lng": -123.3915861232
            },
            {
                "lat": 48.8372961199,
                "lng": -123.3925853071
            },
            {
                "lat": 48.834384458,
                "lng": -123.3948977046
            },
            {
                "lat": 48.8310013643,
                "lng": -123.3903890021
            },
            {
                "lat": 48.8282705012,
                "lng": -123.3842081681
            },
            {
                "lat": 48.8258967272,
                "lng": -123.3784554033
            },
            {
                "lat": 48.8243519022,
                "lng": -123.3770297264
            },
            {
                "lat": 48.8257139222,
                "lng": -123.3729851973
            },
            {
                "lat": 48.8273809207,
                "lng": -123.3688195401
            },
            {
                "lat": 48.8278968005,
                "lng": -123.3659362499
            },
            {
                "lat": 48.826995576,
                "lng": -123.3628536688
            },
            {
                "lat": 48.8250317253,
                "lng": -123.3604127851
            },
            {
                "lat": 48.827022438,
                "lng": -123.3596423674
            },
            {
                "lat": 48.8287884002,
                "lng": -123.3588267146
            },
            {
                "lat": 48.8301692318,
                "lng": -123.3580854488
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 704,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 848,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2233643.4589,
            "FEATURE_LENGTH_M": 7299.6425,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.83153308193704,
            "lng": -123.37356765990371
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.8334997125,
                "lng": -123.3279181812
            },
            {
                "lat": 48.8275953365,
                "lng": -123.3518380949
            },
            {
                "lat": 48.8279153922,
                "lng": -123.3525701678
            },
            {
                "lat": 48.8286290841,
                "lng": -123.3535147649
            },
            {
                "lat": 48.8295742539,
                "lng": -123.3547661285
            },
            {
                "lat": 48.8303483892,
                "lng": -123.356185397
            },
            {
                "lat": 48.8301692318,
                "lng": -123.3580854488
            },
            {
                "lat": 48.8287884002,
                "lng": -123.3588267146
            },
            {
                "lat": 48.827022438,
                "lng": -123.3596423674
            },
            {
                "lat": 48.8250317253,
                "lng": -123.3604127851
            },
            {
                "lat": 48.8226545288,
                "lng": -123.3563847022
            },
            {
                "lat": 48.8218385096,
                "lng": -123.3551398094
            },
            {
                "lat": 48.8217663169,
                "lng": -123.3550792524
            },
            {
                "lat": 48.8216090343,
                "lng": -123.3549486619
            },
            {
                "lat": 48.8214227452,
                "lng": -123.3550119002
            },
            {
                "lat": 48.8212319851,
                "lng": -123.3527912187
            },
            {
                "lat": 48.8190235395,
                "lng": -123.3272026072
            },
            {
                "lat": 48.8334997125,
                "lng": -123.3279181812
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 705,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 18,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 18-9",
            "LABEL": "18-9",
            "FISHERY_AREA_ID": 2221,
            "OBJECTID": 849,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2520530.4509,
            "FEATURE_LENGTH_M": 7305.5539,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.82620112975556,
            "lng": -123.35101313241113
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.6840900742,
                "lng": -124.8580094972
            },
            {
                "lat": 48.6709826916,
                "lng": -124.8546440234
            },
            {
                "lat": 48.6693076397,
                "lng": -124.8551558277
            },
            {
                "lat": 48.6688617765,
                "lng": -124.8558206869
            },
            {
                "lat": 48.6670910364,
                "lng": -124.8497237325
            },
            {
                "lat": 48.6672632357,
                "lng": -124.8496093951
            },
            {
                "lat": 48.6679921146,
                "lng": -124.8492662689
            },
            {
                "lat": 48.6761097987,
                "lng": -124.8374546529
            },
            {
                "lat": 48.6750526104,
                "lng": -124.8118657773
            },
            {
                "lat": 48.6864322692,
                "lng": -124.7777328528
            },
            {
                "lat": 48.6977887286,
                "lng": -124.7802282669
            },
            {
                "lat": 48.7274362388,
                "lng": -124.7446520228
            },
            {
                "lat": 48.7437436305,
                "lng": -124.7248847682
            },
            {
                "lat": 48.7946438646,
                "lng": -124.6542203526
            },
            {
                "lat": 48.8450469922,
                "lng": -124.64384543
            },
            {
                "lat": 48.852955209,
                "lng": -124.6517493368
            },
            {
                "lat": 48.8405987789,
                "lng": -124.6739883507
            },
            {
                "lat": 48.808975815,
                "lng": -124.7070922918
            },
            {
                "lat": 48.7694397922,
                "lng": -124.7515704864
            },
            {
                "lat": 48.7313918889,
                "lng": -124.7930763521
            },
            {
                "lat": 48.7017397789,
                "lng": -124.825691503
            },
            {
                "lat": 48.69581177,
                "lng": -124.8504028231
            },
            {
                "lat": 48.6888920395,
                "lng": -124.8563317527
            },
            {
                "lat": 48.6840900742,
                "lng": -124.8580094972
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 706,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 22,
            "MANAGEMENT_SUBAREA": 22,
            "MANAGEMENT_AREA_NAME": "Subarea 22",
            "LABEL": "22",
            "FISHERY_AREA_ID": 2605,
            "OBJECTID": 850,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 78760334.3846,
            "FEATURE_LENGTH_M": 55024.9473,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.7214890770125,
            "lng": -124.78812608120832
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.8243519022,
                "lng": -123.3770297264
            },
            {
                "lat": 48.8211632216,
                "lng": -123.3796453869
            },
            {
                "lat": 48.8194846567,
                "lng": -123.380081072
            },
            {
                "lat": 48.8183518552,
                "lng": -123.379676706
            },
            {
                "lat": 48.8159024155,
                "lng": -123.3762441079
            },
            {
                "lat": 48.8134495511,
                "lng": -123.3728035511
            },
            {
                "lat": 48.8118440874,
                "lng": -123.3697664124
            },
            {
                "lat": 48.8103062313,
                "lng": -123.366882115
            },
            {
                "lat": 48.809501959,
                "lng": -123.3653102436
            },
            {
                "lat": 48.8092609803,
                "lng": -123.3642644658
            },
            {
                "lat": 48.7753643097,
                "lng": -123.3666903839
            },
            {
                "lat": 48.7314490312,
                "lng": -123.3111047022
            },
            {
                "lat": 48.7290576287,
                "lng": -123.3111872926
            },
            {
                "lat": 48.725646716,
                "lng": -123.3111109697
            },
            {
                "lat": 48.7173159867,
                "lng": -123.3083650367
            },
            {
                "lat": 48.7067410436,
                "lng": -123.3039485478
            },
            {
                "lat": 48.7024917617,
                "lng": -123.3022073625
            },
            {
                "lat": 48.7014497613,
                "lng": -123.301429101
            },
            {
                "lat": 48.7010224807,
                "lng": -123.301253449
            },
            {
                "lat": 48.7007140009,
                "lng": -123.3012083931
            },
            {
                "lat": 48.7004359742,
                "lng": -123.3011310524
            },
            {
                "lat": 48.7002264388,
                "lng": -123.3009106689
            },
            {
                "lat": 48.7000807429,
                "lng": -123.3005526001
            },
            {
                "lat": 48.6999014839,
                "lng": -123.3001710147
            },
            {
                "lat": 48.6997339047,
                "lng": -123.2998498333
            },
            {
                "lat": 48.6996500678,
                "lng": -123.2994763418
            },
            {
                "lat": 48.6996000235,
                "lng": -123.2992247502
            },
            {
                "lat": 48.699593109,
                "lng": -123.2989740733
            },
            {
                "lat": 48.6848072798,
                "lng": -123.2936945069
            },
            {
                "lat": 48.6878200232,
                "lng": -123.2658077676
            },
            {
                "lat": 48.6940004124,
                "lng": -123.2678908948
            },
            {
                "lat": 48.7177084643,
                "lng": -123.1837683124
            },
            {
                "lat": 48.7357864458,
                "lng": -123.1837775383
            },
            {
                "lat": 48.7357096023,
                "lng": -123.1839824943
            },
            {
                "lat": 48.7356375511,
                "lng": -123.1842194214
            },
            {
                "lat": 48.7356374704,
                "lng": -123.1845471125
            },
            {
                "lat": 48.7357295947,
                "lng": -123.1851341417
            },
            {
                "lat": 48.7359507173,
                "lng": -123.1861260233
            },
            {
                "lat": 48.7365373682,
                "lng": -123.1876902771
            },
            {
                "lat": 48.7379147884,
                "lng": -123.1911700433
            },
            {
                "lat": 48.7399066083,
                "lng": -123.1940767293
            },
            {
                "lat": 48.7627111531,
                "lng": -123.251136653
            },
            {
                "lat": 48.7648158098,
                "lng": -123.2540441116
            },
            {
                "lat": 48.7653922701,
                "lng": -123.2551112446
            },
            {
                "lat": 48.7652326702,
                "lng": -123.2565612125
            },
            {
                "lat": 48.765056995,
                "lng": -123.2570029911
            },
            {
                "lat": 48.7648660036,
                "lng": -123.2575832677
            },
            {
                "lat": 48.7643512645,
                "lng": -123.2579734995
            },
            {
                "lat": 48.7637285953,
                "lng": -123.2608180003
            },
            {
                "lat": 48.7637285614,
                "lng": -123.2687377902
            },
            {
                "lat": 48.7650754676,
                "lng": -123.2764587617
            },
            {
                "lat": 48.7672500706,
                "lng": -123.2795638772
            },
            {
                "lat": 48.7753259746,
                "lng": -123.2819434632
            },
            {
                "lat": 48.7835086899,
                "lng": -123.2845318114
            },
            {
                "lat": 48.7878568903,
                "lng": -123.2871405834
            },
            {
                "lat": 48.8087917,
                "lng": -123.3140486063
            },
            {
                "lat": 48.8129197706,
                "lng": -123.316186018
            },
            {
                "lat": 48.8162690046,
                "lng": -123.319176169
            },
            {
                "lat": 48.8178522765,
                "lng": -123.321112855
            },
            {
                "lat": 48.8187335879,
                "lng": -123.3231816008
            },
            {
                "lat": 48.8187335794,
                "lng": -123.3244180353
            },
            {
                "lat": 48.8188361606,
                "lng": -123.3259275362
            },
            {
                "lat": 48.8190235395,
                "lng": -123.3272026072
            },
            {
                "lat": 48.8212319851,
                "lng": -123.3527912187
            },
            {
                "lat": 48.8214227452,
                "lng": -123.3550119002
            },
            {
                "lat": 48.8216090343,
                "lng": -123.3549486619
            },
            {
                "lat": 48.8217663169,
                "lng": -123.3550792524
            },
            {
                "lat": 48.8218385096,
                "lng": -123.3551398094
            },
            {
                "lat": 48.8226545288,
                "lng": -123.3563847022
            },
            {
                "lat": 48.8250317253,
                "lng": -123.3604127851
            },
            {
                "lat": 48.826995576,
                "lng": -123.3628536688
            },
            {
                "lat": 48.8278968005,
                "lng": -123.3659362499
            },
            {
                "lat": 48.8273809207,
                "lng": -123.3688195401
            },
            {
                "lat": 48.8257139222,
                "lng": -123.3729851973
            },
            {
                "lat": 48.8243519022,
                "lng": -123.3770297264
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 707,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 18,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 18-4",
            "LABEL": "18-4",
            "FISHERY_AREA_ID": 2216,
            "OBJECTID": 851,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 95269296.7896,
            "FEATURE_LENGTH_M": 48680.5897,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.767135888716005,
            "lng": -123.30106184041067
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.8056485825,
                "lng": -123.0084994257
            },
            {
                "lat": 48.8137814573,
                "lng": -123.1886819461
            },
            {
                "lat": 48.8132970326,
                "lng": -123.1879882179
            },
            {
                "lat": 48.8126370712,
                "lng": -123.1872710239
            },
            {
                "lat": 48.8110165994,
                "lng": -123.1858904509
            },
            {
                "lat": 48.8081667465,
                "lng": -123.1830516719
            },
            {
                "lat": 48.8055833642,
                "lng": -123.177871068
            },
            {
                "lat": 48.8001361761,
                "lng": -123.1585462515
            },
            {
                "lat": 48.796004766,
                "lng": -123.1425251986
            },
            {
                "lat": 48.7928697838,
                "lng": -123.1326982887
            },
            {
                "lat": 48.7882421698,
                "lng": -123.1158521471
            },
            {
                "lat": 48.7854340793,
                "lng": -123.0995561213
            },
            {
                "lat": 48.7849581078,
                "lng": -123.0921781479
            },
            {
                "lat": 48.783530892,
                "lng": -123.0798028427
            },
            {
                "lat": 48.7825774805,
                "lng": -123.0707622179
            },
            {
                "lat": 48.7813876756,
                "lng": -123.0641026051
            },
            {
                "lat": 48.7813876453,
                "lng": -123.0576779993
            },
            {
                "lat": 48.7825772403,
                "lng": -123.0531536135
            },
            {
                "lat": 48.7828183551,
                "lng": -123.0495844071
            },
            {
                "lat": 48.783138582,
                "lng": -123.046302524
            },
            {
                "lat": 48.7663454881,
                "lng": -123.011207497
            },
            {
                "lat": 48.7671093489,
                "lng": -123.0084979457
            },
            {
                "lat": 48.8056485825,
                "lng": -123.0084994257
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 708,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 18,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 18-11",
            "LABEL": "18-11",
            "FISHERY_AREA_ID": 2223,
            "OBJECTID": 852,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 32139218.0133,
            "FEATURE_LENGTH_M": 32379.8626,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.7927955316,
            "lng": -123.10044352336959
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.7883412153,
                "lng": -125.201279593
            },
            {
                "lat": 48.7869151575,
                "lng": -125.2055670374
            },
            {
                "lat": 48.7864726394,
                "lng": -125.2119828078
            },
            {
                "lat": 48.7864725879,
                "lng": -125.2155308829
            },
            {
                "lat": 48.7499997833,
                "lng": -125.3166658268
            },
            {
                "lat": 48.6234517168,
                "lng": -125.2166671794
            },
            {
                "lat": 48.7221113622,
                "lng": -125.0975799829
            },
            {
                "lat": 48.7291451746,
                "lng": -125.0950936591
            },
            {
                "lat": 48.7377133127,
                "lng": -125.1036599663
            },
            {
                "lat": 48.746753994,
                "lng": -125.1100855642
            },
            {
                "lat": 48.7629356745,
                "lng": -125.1107940402
            },
            {
                "lat": 48.7826843495,
                "lng": -125.0986638744
            },
            {
                "lat": 48.806953625,
                "lng": -125.1072238056
            },
            {
                "lat": 48.815757956,
                "lng": -125.1181712121
            },
            {
                "lat": 48.807670636,
                "lng": -125.1257863685
            },
            {
                "lat": 48.8036230763,
                "lng": -125.132690489
            },
            {
                "lat": 48.7952957429,
                "lng": -125.1524349376
            },
            {
                "lat": 48.7931561591,
                "lng": -125.1736140558
            },
            {
                "lat": 48.7900618145,
                "lng": -125.1916957335
            },
            {
                "lat": 48.7883412153,
                "lng": -125.201279593
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 709,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 123,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 123-1",
            "LABEL": "123-1",
            "FISHERY_AREA_ID": 2316,
            "OBJECTID": 853,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 183499941.5136,
            "FEATURE_LENGTH_M": 57865.1824,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.77019285963999,
            "lng": -125.15932333047502
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.7753643097,
                "lng": -123.3666903839
            },
            {
                "lat": 48.7751280471,
                "lng": -123.3671946888
            },
            {
                "lat": 48.7736891874,
                "lng": -123.369438334
            },
            {
                "lat": 48.7719917969,
                "lng": -123.3748088823
            },
            {
                "lat": 48.7733119269,
                "lng": -123.3805168557
            },
            {
                "lat": 48.7728961838,
                "lng": -123.3808373947
            },
            {
                "lat": 48.7605703078,
                "lng": -123.3904036852
            },
            {
                "lat": 48.7550510137,
                "lng": -123.3876942227
            },
            {
                "lat": 48.7536737279,
                "lng": -123.3869947361
            },
            {
                "lat": 48.7499309395,
                "lng": -123.3940739103
            },
            {
                "lat": 48.7333034811,
                "lng": -123.4255510204
            },
            {
                "lat": 48.7353174368,
                "lng": -123.4299308268
            },
            {
                "lat": 48.7405851088,
                "lng": -123.4424524538
            },
            {
                "lat": 48.7289472496,
                "lng": -123.4863739388
            },
            {
                "lat": 48.7274897371,
                "lng": -123.5043718326
            },
            {
                "lat": 48.7148051629,
                "lng": -123.4975049217
            },
            {
                "lat": 48.7092356544,
                "lng": -123.4948273476
            },
            {
                "lat": 48.6893997359,
                "lng": -123.485305278
            },
            {
                "lat": 48.6896174663,
                "lng": -123.4847797667
            },
            {
                "lat": 48.6907535181,
                "lng": -123.4814678178
            },
            {
                "lat": 48.6897617669,
                "lng": -123.472183127
            },
            {
                "lat": 48.686889752,
                "lng": -123.4702380994
            },
            {
                "lat": 48.6799551029,
                "lng": -123.4286585117
            },
            {
                "lat": 48.6845393058,
                "lng": -123.4258352887
            },
            {
                "lat": 48.6850738595,
                "lng": -123.4124989503
            },
            {
                "lat": 48.6786731432,
                "lng": -123.4050293843
            },
            {
                "lat": 48.675071358,
                "lng": -123.3972390734
            },
            {
                "lat": 48.6746824963,
                "lng": -123.396865553
            },
            {
                "lat": 48.6746289811,
                "lng": -123.3967277922
            },
            {
                "lat": 48.6848072798,
                "lng": -123.2936945069
            },
            {
                "lat": 48.699593109,
                "lng": -123.2989740733
            },
            {
                "lat": 48.6996000235,
                "lng": -123.2992247502
            },
            {
                "lat": 48.6996500678,
                "lng": -123.2994763418
            },
            {
                "lat": 48.6997339047,
                "lng": -123.2998498333
            },
            {
                "lat": 48.6999014839,
                "lng": -123.3001710147
            },
            {
                "lat": 48.7000807429,
                "lng": -123.3005526001
            },
            {
                "lat": 48.7002264388,
                "lng": -123.3009106689
            },
            {
                "lat": 48.7004359742,
                "lng": -123.3011310524
            },
            {
                "lat": 48.7007140009,
                "lng": -123.3012083931
            },
            {
                "lat": 48.7010224807,
                "lng": -123.301253449
            },
            {
                "lat": 48.7014497613,
                "lng": -123.301429101
            },
            {
                "lat": 48.7024917617,
                "lng": -123.3022073625
            },
            {
                "lat": 48.7067410436,
                "lng": -123.3039485478
            },
            {
                "lat": 48.7173159867,
                "lng": -123.3083650367
            },
            {
                "lat": 48.725646716,
                "lng": -123.3111109697
            },
            {
                "lat": 48.7290576287,
                "lng": -123.3111872926
            },
            {
                "lat": 48.7314490312,
                "lng": -123.3111047022
            },
            {
                "lat": 48.7753643097,
                "lng": -123.3666903839
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 710,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 18,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 18-6",
            "LABEL": "18-6",
            "FISHERY_AREA_ID": 2218,
            "OBJECTID": 854,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 99868649.3059,
            "FEATURE_LENGTH_M": 45142.4196,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.717200406302084,
            "lng": -123.37810383662502
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.7405851088,
                "lng": -123.4424524538
            },
            {
                "lat": 48.7353174368,
                "lng": -123.4299308268
            },
            {
                "lat": 48.7333034811,
                "lng": -123.4255510204
            },
            {
                "lat": 48.7499309395,
                "lng": -123.3940739103
            },
            {
                "lat": 48.7536737279,
                "lng": -123.3869947361
            },
            {
                "lat": 48.7550510137,
                "lng": -123.3876942227
            },
            {
                "lat": 48.7605703078,
                "lng": -123.3904036852
            },
            {
                "lat": 48.7629891982,
                "lng": -123.4143372874
            },
            {
                "lat": 48.7752873287,
                "lng": -123.4529877629
            },
            {
                "lat": 48.7755051314,
                "lng": -123.4608986114
            },
            {
                "lat": 48.7702327564,
                "lng": -123.4661717346
            },
            {
                "lat": 48.7405851088,
                "lng": -123.4424524538
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 711,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 18,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 18-10",
            "LABEL": "18-10",
            "FISHERY_AREA_ID": 2222,
            "OBJECTID": 855,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 13979801.0449,
            "FEATURE_LENGTH_M": 15872.3394,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.75441929492501,
            "lng": -123.42449572545002
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.7142986757,
                "lng": -123.5620891378
            },
            {
                "lat": 48.7157282883,
                "lng": -123.5555195958
            },
            {
                "lat": 48.7157514178,
                "lng": -123.554870212
            },
            {
                "lat": 48.7427365265,
                "lng": -123.5700540275
            },
            {
                "lat": 48.7444653755,
                "lng": -123.5714195994
            },
            {
                "lat": 48.7558065848,
                "lng": -123.5781030205
            },
            {
                "lat": 48.7751465057,
                "lng": -123.5929405111
            },
            {
                "lat": 48.778438813,
                "lng": -123.6475225364
            },
            {
                "lat": 48.7552184911,
                "lng": -123.6596460653
            },
            {
                "lat": 48.7391055333,
                "lng": -123.651161674
            },
            {
                "lat": 48.7181438816,
                "lng": -123.5686873346
            },
            {
                "lat": 48.7142986757,
                "lng": -123.5620891378
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 712,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 18,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 18-8",
            "LABEL": "18-8",
            "FISHERY_AREA_ID": 2220,
            "OBJECTID": 856,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 31359050.3785,
            "FEATURE_LENGTH_M": 23546.3104,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.73909489741666,
            "lng": -123.58950857101667
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.7499997833,
                "lng": -125.3166658268
            },
            {
                "lat": 48.7999988656,
                "lng": -125.483330383
            },
            {
                "lat": 48.683334416,
                "lng": -125.6999967568
            },
            {
                "lat": 48.6490326864,
                "lng": -125.5833350934
            },
            {
                "lat": 48.7499997833,
                "lng": -125.3166658268
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 713,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 123,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 123-4",
            "LABEL": "123-4",
            "FISHERY_AREA_ID": 2319,
            "OBJECTID": 857,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 209448060.3077,
            "FEATURE_LENGTH_M": 66069.6044,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.726473106920004,
            "lng": -125.47999877736
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.7499997833,
                "lng": -125.3166658268
            },
            {
                "lat": 48.6490326864,
                "lng": -125.5833350934
            },
            {
                "lat": 48.6018834019,
                "lng": -125.4166638653
            },
            {
                "lat": 48.5666654526,
                "lng": -125.2897638503
            },
            {
                "lat": 48.6234517168,
                "lng": -125.2166671794
            },
            {
                "lat": 48.7499997833,
                "lng": -125.3166658268
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 714,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 123,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 123-2",
            "LABEL": "123-2",
            "FISHERY_AREA_ID": 2317,
            "OBJECTID": 858,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 273221943.3656,
            "FEATURE_LENGTH_M": 70339.2068,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.656838804050004,
            "lng": -125.35662694033333
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.683334416,
                "lng": -125.6999967568
            },
            {
                "lat": 48.7499996805,
                "lng": -125.9356536242
            },
            {
                "lat": 48.5999985236,
                "lng": -126.1237030455
            },
            {
                "lat": 48.4000018216,
                "lng": -125.6833338317
            },
            {
                "lat": 48.6018834019,
                "lng": -125.4166638653
            },
            {
                "lat": 48.6490326864,
                "lng": -125.5833350934
            },
            {
                "lat": 48.683334416,
                "lng": -125.6999967568
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 715,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 123,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 123-8",
            "LABEL": "123-8",
            "FISHERY_AREA_ID": 2323,
            "OBJECTID": 859,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 1016266276.5692,
            "FEATURE_LENGTH_M": 132653.5276,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.623940706571425,
            "lng": -125.73466899624286
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.7291451746,
                "lng": -125.0950936591
            },
            {
                "lat": 48.7221113622,
                "lng": -125.0975799829
            },
            {
                "lat": 48.6784859308,
                "lng": -124.9666676996
            },
            {
                "lat": 48.5955282485,
                "lng": -124.7177197565
            },
            {
                "lat": 48.6063421404,
                "lng": -124.7142713155
            },
            {
                "lat": 48.6702388236,
                "lng": -124.7611009963
            },
            {
                "lat": 48.6864322692,
                "lng": -124.7777328528
            },
            {
                "lat": 48.6750526104,
                "lng": -124.8118657773
            },
            {
                "lat": 48.6761097987,
                "lng": -124.8374546529
            },
            {
                "lat": 48.6679921146,
                "lng": -124.8492662689
            },
            {
                "lat": 48.6672632357,
                "lng": -124.8496093951
            },
            {
                "lat": 48.6670910364,
                "lng": -124.8497237325
            },
            {
                "lat": 48.6688617765,
                "lng": -124.8558206869
            },
            {
                "lat": 48.6693076397,
                "lng": -124.8551558277
            },
            {
                "lat": 48.6709826916,
                "lng": -124.8546440234
            },
            {
                "lat": 48.6840900742,
                "lng": -124.8580094972
            },
            {
                "lat": 48.6829827844,
                "lng": -124.8628530995
            },
            {
                "lat": 48.68798063,
                "lng": -124.879509462
            },
            {
                "lat": 48.6977386279,
                "lng": -124.9080657153
            },
            {
                "lat": 48.7077326749,
                "lng": -124.9268644648
            },
            {
                "lat": 48.7148703291,
                "lng": -124.9420922977
            },
            {
                "lat": 48.7182009369,
                "lng": -124.9563677816
            },
            {
                "lat": 48.7198679088,
                "lng": -124.9811179208
            },
            {
                "lat": 48.7236746458,
                "lng": -125.0044318035
            },
            {
                "lat": 48.7262917264,
                "lng": -125.0329885039
            },
            {
                "lat": 48.7293848627,
                "lng": -125.0532142982
            },
            {
                "lat": 48.7308120057,
                "lng": -125.0798644884
            },
            {
                "lat": 48.7291451746,
                "lng": -125.0950936591
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 716,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 21,
            "MANAGEMENT_SUBAREA": 21,
            "MANAGEMENT_AREA_NAME": "Subarea 21",
            "LABEL": "21",
            "FISHERY_AREA_ID": 2604,
            "OBJECTID": 860,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 117644870.8588,
            "FEATURE_LENGTH_M": 70472.4034,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.68941847265358,
            "lng": -124.90979212926429
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.6784859308,
                "lng": -124.9666676996
            },
            {
                "lat": 48.7221113622,
                "lng": -125.0975799829
            },
            {
                "lat": 48.6234517168,
                "lng": -125.2166671794
            },
            {
                "lat": 48.4096223873,
                "lng": -125.2166666093
            },
            {
                "lat": 48.4297830293,
                "lng": -125.1630408652
            },
            {
                "lat": 48.4411732304,
                "lng": -125.1297063148
            },
            {
                "lat": 48.4425619508,
                "lng": -125.1247100591
            },
            {
                "lat": 48.4670067328,
                "lng": -125.0483166212
            },
            {
                "lat": 48.475898675,
                "lng": -125.0174784974
            },
            {
                "lat": 48.4847869742,
                "lng": -124.982200187
            },
            {
                "lat": 48.4879802369,
                "lng": -124.966667806
            },
            {
                "lat": 48.6784859308,
                "lng": -124.9666676996
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 717,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 121,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 121-2",
            "LABEL": "121-2",
            "FISHERY_AREA_ID": 2314,
            "OBJECTID": 861,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 482481918.2613,
            "FEATURE_LENGTH_M": 90221.6955,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.528445679775,
            "lng": -125.074697460125
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.6878200232,
                "lng": -123.2658077676
            },
            {
                "lat": 48.6848072798,
                "lng": -123.2936945069
            },
            {
                "lat": 48.6746289811,
                "lng": -123.3967277922
            },
            {
                "lat": 48.6746824963,
                "lng": -123.396865553
            },
            {
                "lat": 48.675071358,
                "lng": -123.3972390734
            },
            {
                "lat": 48.6786731432,
                "lng": -123.4050293843
            },
            {
                "lat": 48.6850738595,
                "lng": -123.4124989503
            },
            {
                "lat": 48.6845393058,
                "lng": -123.4258352887
            },
            {
                "lat": 48.6799551029,
                "lng": -123.4286585117
            },
            {
                "lat": 48.6706696814,
                "lng": -123.4343707031
            },
            {
                "lat": 48.6247868653,
                "lng": -123.4295664866
            },
            {
                "lat": 48.50954853,
                "lng": -123.3858187703
            },
            {
                "lat": 48.4620626297,
                "lng": -123.3298041758
            },
            {
                "lat": 48.455337482,
                "lng": -123.3137513721
            },
            {
                "lat": 48.4573898443,
                "lng": -123.3133477991
            },
            {
                "lat": 48.4625888815,
                "lng": -123.2889264871
            },
            {
                "lat": 48.457817043,
                "lng": -123.2796642519
            },
            {
                "lat": 48.451023,
                "lng": -123.2673497023
            },
            {
                "lat": 48.4511838332,
                "lng": -123.2660139787
            },
            {
                "lat": 48.4511569299,
                "lng": -123.2658231526
            },
            {
                "lat": 48.4512138901,
                "lng": -123.1565247415
            },
            {
                "lat": 48.4534991601,
                "lng": -123.1598589461
            },
            {
                "lat": 48.5487209962,
                "lng": -123.2189404299
            },
            {
                "lat": 48.6878200232,
                "lng": -123.2658077676
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 718,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 19,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 19-5",
            "LABEL": "19-5",
            "FISHERY_AREA_ID": 2228,
            "OBJECTID": 862,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 335073717.7088,
            "FEATURE_LENGTH_M": 88221.3913,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.57166959748749,
            "lng": -123.3249135663667
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.6893997359,
                "lng": -123.485305278
            },
            {
                "lat": 48.6938969427,
                "lng": -123.5347676382
            },
            {
                "lat": 48.6935081534,
                "lng": -123.5364077273
            },
            {
                "lat": 48.6640128774,
                "lng": -123.5422203799
            },
            {
                "lat": 48.6544261968,
                "lng": -123.5403148169
            },
            {
                "lat": 48.6610560786,
                "lng": -123.499984623
            },
            {
                "lat": 48.661097907,
                "lng": -123.4997264728
            },
            {
                "lat": 48.6611596074,
                "lng": -123.4993672675
            },
            {
                "lat": 48.6654320897,
                "lng": -123.473388926
            },
            {
                "lat": 48.6654391237,
                "lng": -123.4732580284
            },
            {
                "lat": 48.6654741306,
                "lng": -123.4727866154
            },
            {
                "lat": 48.6655311988,
                "lng": -123.4726631996
            },
            {
                "lat": 48.6656032601,
                "lng": -123.472518637
            },
            {
                "lat": 48.6683693152,
                "lng": -123.4683151524
            },
            {
                "lat": 48.6841237151,
                "lng": -123.4683689957
            },
            {
                "lat": 48.686889752,
                "lng": -123.4702380994
            },
            {
                "lat": 48.6897617669,
                "lng": -123.472183127
            },
            {
                "lat": 48.6907535181,
                "lng": -123.4814678178
            },
            {
                "lat": 48.6896174663,
                "lng": -123.4847797667
            },
            {
                "lat": 48.6893997359,
                "lng": -123.485305278
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 719,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 19,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 19-7",
            "LABEL": "19-7",
            "FISHERY_AREA_ID": 2230,
            "OBJECTID": 863,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 18033511.9238,
            "FEATURE_LENGTH_M": 17228.6377,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.675247628579996,
            "lng": -123.49166839235
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.6683693152,
                "lng": -123.4683151524
            },
            {
                "lat": 48.6656032601,
                "lng": -123.472518637
            },
            {
                "lat": 48.6655311988,
                "lng": -123.4726631996
            },
            {
                "lat": 48.6654741306,
                "lng": -123.4727866154
            },
            {
                "lat": 48.6654391237,
                "lng": -123.4732580284
            },
            {
                "lat": 48.6654320897,
                "lng": -123.473388926
            },
            {
                "lat": 48.6611596074,
                "lng": -123.4993672675
            },
            {
                "lat": 48.661097907,
                "lng": -123.4997264728
            },
            {
                "lat": 48.6610560786,
                "lng": -123.499984623
            },
            {
                "lat": 48.6544261968,
                "lng": -123.5403148169
            },
            {
                "lat": 48.6640128774,
                "lng": -123.5422203799
            },
            {
                "lat": 48.6633949316,
                "lng": -123.5608374914
            },
            {
                "lat": 48.6556741972,
                "lng": -123.5647575104
            },
            {
                "lat": 48.6387520201,
                "lng": -123.5551995353
            },
            {
                "lat": 48.6055411469,
                "lng": -123.5315847676
            },
            {
                "lat": 48.5690076135,
                "lng": -123.5267873679
            },
            {
                "lat": 48.5642892042,
                "lng": -123.5271752656
            },
            {
                "lat": 48.5638273192,
                "lng": -123.5248721715
            },
            {
                "lat": 48.5635489841,
                "lng": -123.5234829567
            },
            {
                "lat": 48.5613406629,
                "lng": -123.5208827502
            },
            {
                "lat": 48.5613101732,
                "lng": -123.5207591987
            },
            {
                "lat": 48.5667539542,
                "lng": -123.5093615937
            },
            {
                "lat": 48.5774919953,
                "lng": -123.4868775678
            },
            {
                "lat": 48.5798074406,
                "lng": -123.4703368315
            },
            {
                "lat": 48.5798680038,
                "lng": -123.4701000864
            },
            {
                "lat": 48.5801158954,
                "lng": -123.4687501016
            },
            {
                "lat": 48.5786062155,
                "lng": -123.4591836926
            },
            {
                "lat": 48.5985189485,
                "lng": -123.4471818421
            },
            {
                "lat": 48.6525950319,
                "lng": -123.4410180598
            },
            {
                "lat": 48.6681027886,
                "lng": -123.4451903985
            },
            {
                "lat": 48.6710851359,
                "lng": -123.4581142914
            },
            {
                "lat": 48.6683693152,
                "lng": -123.4683151524
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 720,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 19,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 19-8",
            "LABEL": "19-8",
            "FISHERY_AREA_ID": 2231,
            "OBJECTID": 864,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 70932574.3731,
            "FEATURE_LENGTH_M": 37384.4163,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.62392508634687,
            "lng": -123.49672852350001
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.6233212747,
                "lng": -123.3275376153
            },
            {
                "lat": 48.6234468639,
                "lng": -123.3265600082
            },
            {
                "lat": 48.6240466409,
                "lng": -123.3258975866
            },
            {
                "lat": 48.6258695186,
                "lng": -123.325340115
            },
            {
                "lat": 48.6282049536,
                "lng": -123.3250888549
            },
            {
                "lat": 48.628937208,
                "lng": -123.3250116749
            },
            {
                "lat": 48.6313665922,
                "lng": -123.3250125248
            },
            {
                "lat": 48.6315692565,
                "lng": -123.3250501331
            },
            {
                "lat": 48.633891779,
                "lng": -123.3255076765
            },
            {
                "lat": 48.6364249731,
                "lng": -123.3261870877
            },
            {
                "lat": 48.640140815,
                "lng": -123.3285817794
            },
            {
                "lat": 48.6415134263,
                "lng": -123.3299864001
            },
            {
                "lat": 48.6424482393,
                "lng": -123.331268518
            },
            {
                "lat": 48.6428336338,
                "lng": -123.3319311891
            },
            {
                "lat": 48.6429640732,
                "lng": -123.3322457255
            },
            {
                "lat": 48.6431734139,
                "lng": -123.3325049596
            },
            {
                "lat": 48.643326159,
                "lng": -123.332642019
            },
            {
                "lat": 48.6435740491,
                "lng": -123.332809269
            },
            {
                "lat": 48.643833607,
                "lng": -123.3329928035
            },
            {
                "lat": 48.6439359429,
                "lng": -123.333153056
            },
            {
                "lat": 48.6441037883,
                "lng": -123.3333977066
            },
            {
                "lat": 48.6441990295,
                "lng": -123.3336346118
            },
            {
                "lat": 48.6455191643,
                "lng": -123.3351746367
            },
            {
                "lat": 48.6456905311,
                "lng": -123.3353801302
            },
            {
                "lat": 48.6467172013,
                "lng": -123.3362048323
            },
            {
                "lat": 48.6469918457,
                "lng": -123.3363722927
            },
            {
                "lat": 48.6473003332,
                "lng": -123.3368225203
            },
            {
                "lat": 48.6482957501,
                "lng": -123.3379523764
            },
            {
                "lat": 48.6485706125,
                "lng": -123.338196096
            },
            {
                "lat": 48.6508710493,
                "lng": -123.341179036
            },
            {
                "lat": 48.6526566105,
                "lng": -123.3436507118
            },
            {
                "lat": 48.6532016298,
                "lng": -123.3444368025
            },
            {
                "lat": 48.6378022034,
                "lng": -123.3396996418
            },
            {
                "lat": 48.6375006295,
                "lng": -123.3398522216
            },
            {
                "lat": 48.6372725036,
                "lng": -123.3404072479
            },
            {
                "lat": 48.6370245445,
                "lng": -123.3407211249
            },
            {
                "lat": 48.6363177719,
                "lng": -123.3409270821
            },
            {
                "lat": 48.6354481341,
                "lng": -123.3409965408
            },
            {
                "lat": 48.6344529099,
                "lng": -123.3408884385
            },
            {
                "lat": 48.6333539122,
                "lng": -123.3403931898
            },
            {
                "lat": 48.6314469891,
                "lng": -123.3394168004
            },
            {
                "lat": 48.6302873709,
                "lng": -123.3387285497
            },
            {
                "lat": 48.6298101968,
                "lng": -123.3384387673
            },
            {
                "lat": 48.6292490251,
                "lng": -123.3379674137
            },
            {
                "lat": 48.6285249137,
                "lng": -123.3374019617
            },
            {
                "lat": 48.6279637412,
                "lng": -123.3368069833
            },
            {
                "lat": 48.6272583834,
                "lng": -123.3363268873
            },
            {
                "lat": 48.626556289,
                "lng": -123.3358533959
            },
            {
                "lat": 48.6263085637,
                "lng": -123.335625205
            },
            {
                "lat": 48.6257891611,
                "lng": -123.3350448482
            },
            {
                "lat": 48.6252293032,
                "lng": -123.3344803443
            },
            {
                "lat": 48.6245843133,
                "lng": -123.3336107249
            },
            {
                "lat": 48.6243178322,
                "lng": -123.3328484065
            },
            {
                "lat": 48.6240697689,
                "lng": -123.331786962
            },
            {
                "lat": 48.6238824104,
                "lng": -123.3308101584
            },
            {
                "lat": 48.6237788412,
                "lng": -123.3302757436
            },
            {
                "lat": 48.6235925014,
                "lng": -123.3290482284
            },
            {
                "lat": 48.6234852681,
                "lng": -123.3285140282
            },
            {
                "lat": 48.6233212747,
                "lng": -123.3275376153
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 721,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 19,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 19-6",
            "LABEL": "19-6",
            "FISHERY_AREA_ID": 2229,
            "OBJECTID": 865,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 2363471.4355,
            "FEATURE_LENGTH_M": 7720.5551,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.63521302918812,
            "lng": -123.33393422476267
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.5955282485,
                "lng": -124.7177197565
            },
            {
                "lat": 48.6784859308,
                "lng": -124.9666676996
            },
            {
                "lat": 48.4879802369,
                "lng": -124.966667806
            },
            {
                "lat": 48.4889522536,
                "lng": -124.9619222972
            },
            {
                "lat": 48.4956206059,
                "lng": -124.9241400048
            },
            {
                "lat": 48.5011748438,
                "lng": -124.8849801583
            },
            {
                "lat": 48.5031199894,
                "lng": -124.861641428
            },
            {
                "lat": 48.5045090424,
                "lng": -124.832749238
            },
            {
                "lat": 48.5045089173,
                "lng": -124.80718957
            },
            {
                "lat": 48.4936334135,
                "lng": -124.7258827804
            },
            {
                "lat": 48.5955282485,
                "lng": -124.7177197565
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 722,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 121,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 121-1",
            "LABEL": "121-1",
            "FISHERY_AREA_ID": 2313,
            "OBJECTID": 866,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 278776298.6279,
            "FEATURE_LENGTH_M": 71174.6866,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.53173106641818,
            "lng": -124.85157095411819
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.5552403178,
                "lng": -124.4945304343
            },
            {
                "lat": 48.5428922184,
                "lng": -124.4976723123
            },
            {
                "lat": 48.5411645559,
                "lng": -124.4916842265
            },
            {
                "lat": 48.5314451546,
                "lng": -124.4579991109
            },
            {
                "lat": 48.5329748377,
                "lng": -124.4556799576
            },
            {
                "lat": 48.549389228,
                "lng": -124.3657609556
            },
            {
                "lat": 48.5363007881,
                "lng": -124.329597809
            },
            {
                "lat": 48.5433007579,
                "lng": -124.3039329384
            },
            {
                "lat": 48.5731847839,
                "lng": -124.2419520832
            },
            {
                "lat": 48.5838808607,
                "lng": -124.235678311
            },
            {
                "lat": 48.6001160402,
                "lng": -124.2367862535
            },
            {
                "lat": 48.6676335951,
                "lng": -124.4658954428
            },
            {
                "lat": 48.5552403178,
                "lng": -124.4945304343
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 723,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 20,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 20-2",
            "LABEL": "20-2",
            "FISHERY_AREA_ID": 2237,
            "OBJECTID": 867,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 182167479.59,
            "FEATURE_LENGTH_M": 56716.8475,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.56252026585385,
            "lng": -124.39013078995384
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.6234517168,
                "lng": -125.2166671794
            },
            {
                "lat": 48.5666654526,
                "lng": -125.2897638503
            },
            {
                "lat": 48.3499990498,
                "lng": -125.5627974294
            },
            {
                "lat": 48.24727653,
                "lng": -125.6997298278
            },
            {
                "lat": 48.2522776565,
                "lng": -125.6847225763
            },
            {
                "lat": 48.2708889485,
                "lng": -125.6286164379
            },
            {
                "lat": 48.2811660955,
                "lng": -125.5947269007
            },
            {
                "lat": 48.3020021356,
                "lng": -125.5227737298
            },
            {
                "lat": 48.3083911044,
                "lng": -125.4991599122
            },
            {
                "lat": 48.3389476422,
                "lng": -125.3833233371
            },
            {
                "lat": 48.3406148781,
                "lng": -125.3755489782
            },
            {
                "lat": 48.3531155685,
                "lng": -125.3488838861
            },
            {
                "lat": 48.3986744752,
                "lng": -125.2458200693
            },
            {
                "lat": 48.4028398183,
                "lng": -125.2347106264
            },
            {
                "lat": 48.4096223873,
                "lng": -125.2166666093
            },
            {
                "lat": 48.6234517168,
                "lng": -125.2166671794
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 724,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 121,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 121-3",
            "LABEL": "121-3",
            "FISHERY_AREA_ID": 2315,
            "OBJECTID": 868,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 442159980.8303,
            "FEATURE_LENGTH_M": 119065.4862,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.37933657350625,
            "lng": -125.4200361581
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.6063421404,
                "lng": -124.7142713155
            },
            {
                "lat": 48.5955282485,
                "lng": -124.7177197565
            },
            {
                "lat": 48.4936334135,
                "lng": -124.7258827804
            },
            {
                "lat": 48.4193650026,
                "lng": -124.457999461
            },
            {
                "lat": 48.5314451546,
                "lng": -124.4579991109
            },
            {
                "lat": 48.5411645559,
                "lng": -124.4916842265
            },
            {
                "lat": 48.5428922184,
                "lng": -124.4976723123
            },
            {
                "lat": 48.5552403178,
                "lng": -124.4945304343
            },
            {
                "lat": 48.6063421404,
                "lng": -124.7142713155
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 725,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 20,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 20-1",
            "LABEL": "20-1",
            "FISHERY_AREA_ID": 2236,
            "OBJECTID": 869,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 258201025.6156,
            "FEATURE_LENGTH_M": 68281.6488,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.54355035467778,
            "lng": -124.58578119032222
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.5786062155,
                "lng": -123.4591836926
            },
            {
                "lat": 48.5801158954,
                "lng": -123.4687501016
            },
            {
                "lat": 48.5798680038,
                "lng": -123.4701000864
            },
            {
                "lat": 48.5798074406,
                "lng": -123.4703368315
            },
            {
                "lat": 48.5774919953,
                "lng": -123.4868775678
            },
            {
                "lat": 48.5577014188,
                "lng": -123.4844129959
            },
            {
                "lat": 48.553043499,
                "lng": -123.4600976437
            },
            {
                "lat": 48.5786062155,
                "lng": -123.4591836926
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 726,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 19,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 19-9",
            "LABEL": "19-9",
            "FISHERY_AREA_ID": 2232,
            "OBJECTID": 870,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 5037811.8939,
            "FEATURE_LENGTH_M": 9011.9148,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.5731550854875,
            "lng": -123.46986782651251
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.5774919953,
                "lng": -123.4868775678
            },
            {
                "lat": 48.5667539542,
                "lng": -123.5093615937
            },
            {
                "lat": 48.5613101732,
                "lng": -123.5207591987
            },
            {
                "lat": 48.5495954188,
                "lng": -123.5339515859
            },
            {
                "lat": 48.5465126052,
                "lng": -123.537421538
            },
            {
                "lat": 48.5370330864,
                "lng": -123.5219961514
            },
            {
                "lat": 48.5577014188,
                "lng": -123.4844129959
            },
            {
                "lat": 48.5774919953,
                "lng": -123.4868775678
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 727,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 19,
            "MANAGEMENT_SUBAREA": 10,
            "MANAGEMENT_AREA_NAME": "Subarea 19-10",
            "LABEL": "19-10",
            "FISHERY_AREA_ID": 2233,
            "OBJECTID": 871,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 8140507.5539,
            "FEATURE_LENGTH_M": 12496.5347,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.5592363309,
            "lng": -123.5102072749
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.5666654526,
                "lng": -125.2897638503
            },
            {
                "lat": 48.6018834019,
                "lng": -125.4166638653
            },
            {
                "lat": 48.4000018216,
                "lng": -125.6833338317
            },
            {
                "lat": 48.3499990498,
                "lng": -125.5627974294
            },
            {
                "lat": 48.5666654526,
                "lng": -125.2897638503
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 728,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 123,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 123-7",
            "LABEL": "123-7",
            "FISHERY_AREA_ID": 2322,
            "OBJECTID": 872,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 307320621.8156,
            "FEATURE_LENGTH_M": 81981.4331,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.4970430357,
            "lng": -125.44846456539999
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.4000018216,
                "lng": -125.6833338317
            },
            {
                "lat": 48.5999985236,
                "lng": -126.1237030455
            },
            {
                "lat": 47.4210665415,
                "lng": -127.5827708451
            },
            {
                "lat": 47.456946688,
                "lng": -127.5142287635
            },
            {
                "lat": 47.4902799003,
                "lng": -127.4492263865
            },
            {
                "lat": 47.5086176508,
                "lng": -127.4128333418
            },
            {
                "lat": 47.6225125446,
                "lng": -127.1817167878
            },
            {
                "lat": 47.8066944849,
                "lng": -126.7230918539
            },
            {
                "lat": 47.9947623998,
                "lng": -126.3183597203
            },
            {
                "lat": 47.9967041474,
                "lng": -126.3141927842
            },
            {
                "lat": 48.0817106809,
                "lng": -126.1236338708
            },
            {
                "lat": 48.1228262214,
                "lng": -126.0275191878
            },
            {
                "lat": 48.1644935596,
                "lng": -125.9247359648
            },
            {
                "lat": 48.2253297861,
                "lng": -125.7622300495
            },
            {
                "lat": 48.24727653,
                "lng": -125.6997298278
            },
            {
                "lat": 48.3499990498,
                "lng": -125.5627974294
            },
            {
                "lat": 48.4000018216,
                "lng": -125.6833338317
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 729,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 123,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 123-9",
            "LABEL": "123-9",
            "FISHERY_AREA_ID": 2324,
            "OBJECTID": 873,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4415220662.9142,
            "FEATURE_LENGTH_M": 404168.3961,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 47.993483667758824,
            "lng": -126.41690808953527
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.5613101732,
                "lng": -123.5207591987
            },
            {
                "lat": 48.5613406629,
                "lng": -123.5208827502
            },
            {
                "lat": 48.5635489841,
                "lng": -123.5234829567
            },
            {
                "lat": 48.5638273192,
                "lng": -123.5248721715
            },
            {
                "lat": 48.5642892042,
                "lng": -123.5271752656
            },
            {
                "lat": 48.5649180261,
                "lng": -123.5303356521
            },
            {
                "lat": 48.5638421871,
                "lng": -123.5454941246
            },
            {
                "lat": 48.5593417996,
                "lng": -123.5607528492
            },
            {
                "lat": 48.5548900426,
                "lng": -123.562034927
            },
            {
                "lat": 48.549034322,
                "lng": -123.5637206915
            },
            {
                "lat": 48.548484674,
                "lng": -123.5638809642
            },
            {
                "lat": 48.5344997127,
                "lng": -123.5610433355
            },
            {
                "lat": 48.5154497434,
                "lng": -123.5473787888
            },
            {
                "lat": 48.5147743085,
                "lng": -123.544075251
            },
            {
                "lat": 48.5147743336,
                "lng": -123.5400996324
            },
            {
                "lat": 48.5147743958,
                "lng": -123.535743844
            },
            {
                "lat": 48.5136264441,
                "lng": -123.531280819
            },
            {
                "lat": 48.5245591755,
                "lng": -123.5227289098
            },
            {
                "lat": 48.5370330864,
                "lng": -123.5219961514
            },
            {
                "lat": 48.5465126052,
                "lng": -123.537421538
            },
            {
                "lat": 48.5495954188,
                "lng": -123.5339515859
            },
            {
                "lat": 48.5613101732,
                "lng": -123.5207591987
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 730,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 19,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 19-11",
            "LABEL": "19-11",
            "FISHERY_AREA_ID": 2234,
            "OBJECTID": 874,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 12803845.3226,
            "FEATURE_LENGTH_M": 15934.6611,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.54462439964547,
            "lng": -123.5381759366273
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.5363007881,
                "lng": -124.329597809
            },
            {
                "lat": 48.549389228,
                "lng": -124.3657609556
            },
            {
                "lat": 48.5329748377,
                "lng": -124.4556799576
            },
            {
                "lat": 48.5314451546,
                "lng": -124.4579991109
            },
            {
                "lat": 48.4193650026,
                "lng": -124.457999461
            },
            {
                "lat": 48.367450725,
                "lng": -124.2693251087
            },
            {
                "lat": 48.3898315345,
                "lng": -124.2738102123
            },
            {
                "lat": 48.4849547704,
                "lng": -124.2928697484
            },
            {
                "lat": 48.5167382739,
                "lng": -124.27553508
            },
            {
                "lat": 48.5363007881,
                "lng": -124.329597809
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 731,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 20,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 20-3",
            "LABEL": "20-3",
            "FISHERY_AREA_ID": 2238,
            "OBJECTID": 875,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 206525733.484,
            "FEATURE_LENGTH_M": 59223.2703,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.48647511029,
            "lng": -124.35081752525
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.5136264441,
                "lng": -123.531280819
            },
            {
                "lat": 48.5147743958,
                "lng": -123.535743844
            },
            {
                "lat": 48.5147743336,
                "lng": -123.5400996324
            },
            {
                "lat": 48.5147743085,
                "lng": -123.544075251
            },
            {
                "lat": 48.5154497434,
                "lng": -123.5473787888
            },
            {
                "lat": 48.5144962637,
                "lng": -123.5521473256
            },
            {
                "lat": 48.5026859574,
                "lng": -123.5583811369
            },
            {
                "lat": 48.4858127474,
                "lng": -123.557647573
            },
            {
                "lat": 48.4806786881,
                "lng": -123.5494306658
            },
            {
                "lat": 48.4853749076,
                "lng": -123.5378416976
            },
            {
                "lat": 48.5136264441,
                "lng": -123.531280819
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 732,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 19,
            "MANAGEMENT_SUBAREA": 12,
            "MANAGEMENT_AREA_NAME": "Subarea 19-12",
            "LABEL": "19-12",
            "FISHERY_AREA_ID": 2235,
            "OBJECTID": 876,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 5778795.0024,
            "FEATURE_LENGTH_M": 9868.7158,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.50509765760909,
            "lng": -123.54411886846361
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.5167382739,
                "lng": -124.27553508
            },
            {
                "lat": 48.4849547704,
                "lng": -124.2928697484
            },
            {
                "lat": 48.3898315345,
                "lng": -124.2738102123
            },
            {
                "lat": 48.367450725,
                "lng": -124.2693251087
            },
            {
                "lat": 48.2966656667,
                "lng": -124.0120544263
            },
            {
                "lat": 48.281189457,
                "lng": -123.921112215
            },
            {
                "lat": 48.3766288709,
                "lng": -123.9211118847
            },
            {
                "lat": 48.4235758154,
                "lng": -123.9207230679
            },
            {
                "lat": 48.5167382739,
                "lng": -124.27553508
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 733,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 20,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 20-4",
            "LABEL": "20-4",
            "FISHERY_AREA_ID": 2239,
            "OBJECTID": 877,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 441614334.9168,
            "FEATURE_LENGTH_M": 88603.8936,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.405974820855555,
            "lng": -124.12911964703332
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.4268680034,
                "lng": -123.4077289916
            },
            {
                "lat": 48.4177666249,
                "lng": -123.4093693421
            },
            {
                "lat": 48.4170613491,
                "lng": -123.4082343576
            },
            {
                "lat": 48.4135513334,
                "lng": -123.3938216432
            },
            {
                "lat": 48.4135057715,
                "lng": -123.3936538565
            },
            {
                "lat": 48.4132195449,
                "lng": -123.3925483713
            },
            {
                "lat": 48.4128842595,
                "lng": -123.3912820535
            },
            {
                "lat": 48.4128679997,
                "lng": -123.3911598659
            },
            {
                "lat": 48.4128841214,
                "lng": -123.3910594977
            },
            {
                "lat": 48.4130287143,
                "lng": -123.3904428447
            },
            {
                "lat": 48.4131321351,
                "lng": -123.3899852663
            },
            {
                "lat": 48.4132917874,
                "lng": -123.3892598649
            },
            {
                "lat": 48.413658376,
                "lng": -123.3876580574
            },
            {
                "lat": 48.4138762351,
                "lng": -123.3866883081
            },
            {
                "lat": 48.4141660758,
                "lng": -123.3854380452
            },
            {
                "lat": 48.4142182843,
                "lng": -123.3853154439
            },
            {
                "lat": 48.4144626815,
                "lng": -123.3851468104
            },
            {
                "lat": 48.4148517838,
                "lng": -123.3849718622
            },
            {
                "lat": 48.4156910224,
                "lng": -123.3847197544
            },
            {
                "lat": 48.4167748709,
                "lng": -123.3654788641
            },
            {
                "lat": 48.4170461319,
                "lng": -123.3654620703
            },
            {
                "lat": 48.4322512043,
                "lng": -123.3646233928
            },
            {
                "lat": 48.4469869601,
                "lng": -123.3693616215
            },
            {
                "lat": 48.4677728575,
                "lng": -123.4092795964
            },
            {
                "lat": 48.4682885273,
                "lng": -123.4286350627
            },
            {
                "lat": 48.4567494806,
                "lng": -123.4362327134
            },
            {
                "lat": 48.4428752183,
                "lng": -123.4217841314
            },
            {
                "lat": 48.4268680034,
                "lng": -123.4077289916
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 734,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 19,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 19-1",
            "LABEL": "19-1",
            "FISHERY_AREA_ID": 2224,
            "OBJECTID": 878,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 19746669.2838,
            "FEATURE_LENGTH_M": 18454.6554,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.423449977064294,
            "lng": -123.39346681003929
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.4512138901,
                "lng": -123.1565247415
            },
            {
                "lat": 48.4511569299,
                "lng": -123.2658231526
            },
            {
                "lat": 48.4511838332,
                "lng": -123.2660139787
            },
            {
                "lat": 48.451023,
                "lng": -123.2673497023
            },
            {
                "lat": 48.457817043,
                "lng": -123.2796642519
            },
            {
                "lat": 48.4625888815,
                "lng": -123.2889264871
            },
            {
                "lat": 48.4573898443,
                "lng": -123.3133477991
            },
            {
                "lat": 48.455337482,
                "lng": -123.3137513721
            },
            {
                "lat": 48.4116932416,
                "lng": -123.3223569438
            },
            {
                "lat": 48.4055942157,
                "lng": -123.3213500139
            },
            {
                "lat": 48.395084363,
                "lng": -123.3051671835
            },
            {
                "lat": 48.3325878016,
                "lng": -123.2018044286
            },
            {
                "lat": 48.422831953,
                "lng": -123.1151433923
            },
            {
                "lat": 48.4512138901,
                "lng": -123.1565247415
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 735,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 19,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 19-4",
            "LABEL": "19-4",
            "FISHERY_AREA_ID": 2227,
            "OBJECTID": 879,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 130567572.3168,
            "FEATURE_LENGTH_M": 46279.8557,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.432622597785716,
            "lng": -123.25526772777857
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.4512255839,
                "lng": -123.4714817253
            },
            {
                "lat": 48.4442674011,
                "lng": -123.4609228987
            },
            {
                "lat": 48.4318157797,
                "lng": -123.4495618791
            },
            {
                "lat": 48.4315491592,
                "lng": -123.4491275962
            },
            {
                "lat": 48.4312975941,
                "lng": -123.4487920206
            },
            {
                "lat": 48.4311066087,
                "lng": -123.448608355
            },
            {
                "lat": 48.4309424986,
                "lng": -123.4484637211
            },
            {
                "lat": 48.4306294719,
                "lng": -123.4481972499
            },
            {
                "lat": 48.430419874,
                "lng": -123.448012907
            },
            {
                "lat": 48.4303326183,
                "lng": -123.4478067833
            },
            {
                "lat": 48.4303896918,
                "lng": -123.447555764
            },
            {
                "lat": 48.4305338519,
                "lng": -123.4452372491
            },
            {
                "lat": 48.4306979636,
                "lng": -123.4426730705
            },
            {
                "lat": 48.430892404,
                "lng": -123.4395826698
            },
            {
                "lat": 48.4308436291,
                "lng": -123.4394537492
            },
            {
                "lat": 48.4309391574,
                "lng": -123.4380789859
            },
            {
                "lat": 48.429977611,
                "lng": -123.4342956201
            },
            {
                "lat": 48.4290730011,
                "lng": -123.4278494916
            },
            {
                "lat": 48.4295804514,
                "lng": -123.4189753963
            },
            {
                "lat": 48.4268680034,
                "lng": -123.4077289916
            },
            {
                "lat": 48.4428752183,
                "lng": -123.4217841314
            },
            {
                "lat": 48.4567494806,
                "lng": -123.4362327134
            },
            {
                "lat": 48.4568217974,
                "lng": -123.4388732794
            },
            {
                "lat": 48.4615667392,
                "lng": -123.4503394172
            },
            {
                "lat": 48.4606053043,
                "lng": -123.4640197135
            },
            {
                "lat": 48.4512255839,
                "lng": -123.4714817253
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 736,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 19,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 19-2",
            "LABEL": "19-2",
            "FISHERY_AREA_ID": 2225,
            "OBJECTID": 880,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 9204970.3599,
            "FEATURE_LENGTH_M": 13321.7791,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.43743178761153,
            "lng": -123.44404373478847
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.4268680034,
                "lng": -123.4077289916
            },
            {
                "lat": 48.4295804514,
                "lng": -123.4189753963
            },
            {
                "lat": 48.4290730011,
                "lng": -123.4278494916
            },
            {
                "lat": 48.429977611,
                "lng": -123.4342956201
            },
            {
                "lat": 48.4309391574,
                "lng": -123.4380789859
            },
            {
                "lat": 48.4308436291,
                "lng": -123.4394537492
            },
            {
                "lat": 48.430892404,
                "lng": -123.4395826698
            },
            {
                "lat": 48.4306979636,
                "lng": -123.4426730705
            },
            {
                "lat": 48.4305338519,
                "lng": -123.4452372491
            },
            {
                "lat": 48.4303896918,
                "lng": -123.447555764
            },
            {
                "lat": 48.4303326183,
                "lng": -123.4478067833
            },
            {
                "lat": 48.430419874,
                "lng": -123.448012907
            },
            {
                "lat": 48.4306294719,
                "lng": -123.4481972499
            },
            {
                "lat": 48.4309424986,
                "lng": -123.4484637211
            },
            {
                "lat": 48.4311066087,
                "lng": -123.448608355
            },
            {
                "lat": 48.4312975941,
                "lng": -123.4487920206
            },
            {
                "lat": 48.4315491592,
                "lng": -123.4491275962
            },
            {
                "lat": 48.4318157797,
                "lng": -123.4495618791
            },
            {
                "lat": 48.4442674011,
                "lng": -123.4609228987
            },
            {
                "lat": 48.4512255839,
                "lng": -123.4714817253
            },
            {
                "lat": 48.41299004,
                "lng": -123.5273355654
            },
            {
                "lat": 48.3636439581,
                "lng": -123.5753632164
            },
            {
                "lat": 48.3424529408,
                "lng": -123.5459520314
            },
            {
                "lat": 48.3418196875,
                "lng": -123.5450673573
            },
            {
                "lat": 48.3395344903,
                "lng": -123.5405800948
            },
            {
                "lat": 48.338741688,
                "lng": -123.5370711088
            },
            {
                "lat": 48.3393784428,
                "lng": -123.5352014832
            },
            {
                "lat": 48.3403888488,
                "lng": -123.5323646098
            },
            {
                "lat": 48.3416403508,
                "lng": -123.5293433321
            },
            {
                "lat": 48.3427311649,
                "lng": -123.5277932182
            },
            {
                "lat": 48.3428616755,
                "lng": -123.5272763151
            },
            {
                "lat": 48.2980264472,
                "lng": -123.5314182604
            },
            {
                "lat": 48.2250596197,
                "lng": -123.5387494254
            },
            {
                "lat": 48.2840275744,
                "lng": -123.2484437498
            },
            {
                "lat": 48.3325878016,
                "lng": -123.2018044286
            },
            {
                "lat": 48.395084363,
                "lng": -123.3051671835
            },
            {
                "lat": 48.4055942157,
                "lng": -123.3213500139
            },
            {
                "lat": 48.4116932416,
                "lng": -123.3223569438
            },
            {
                "lat": 48.4153055903,
                "lng": -123.327445553
            },
            {
                "lat": 48.417294135,
                "lng": -123.3493426629
            },
            {
                "lat": 48.4170263491,
                "lng": -123.3651883123
            },
            {
                "lat": 48.4170461319,
                "lng": -123.3654620703
            },
            {
                "lat": 48.4167748709,
                "lng": -123.3654788641
            },
            {
                "lat": 48.4156910224,
                "lng": -123.3847197544
            },
            {
                "lat": 48.4148517838,
                "lng": -123.3849718622
            },
            {
                "lat": 48.4144626815,
                "lng": -123.3851468104
            },
            {
                "lat": 48.4142182843,
                "lng": -123.3853154439
            },
            {
                "lat": 48.4141660758,
                "lng": -123.3854380452
            },
            {
                "lat": 48.4138762351,
                "lng": -123.3866883081
            },
            {
                "lat": 48.413658376,
                "lng": -123.3876580574
            },
            {
                "lat": 48.4132917874,
                "lng": -123.3892598649
            },
            {
                "lat": 48.4131321351,
                "lng": -123.3899852663
            },
            {
                "lat": 48.4130287143,
                "lng": -123.3904428447
            },
            {
                "lat": 48.4128841214,
                "lng": -123.3910594977
            },
            {
                "lat": 48.4128679997,
                "lng": -123.3911598659
            },
            {
                "lat": 48.4128842595,
                "lng": -123.3912820535
            },
            {
                "lat": 48.4132195449,
                "lng": -123.3925483713
            },
            {
                "lat": 48.4135057715,
                "lng": -123.3936538565
            },
            {
                "lat": 48.4135513334,
                "lng": -123.3938216432
            },
            {
                "lat": 48.4170613491,
                "lng": -123.4082343576
            },
            {
                "lat": 48.4177666249,
                "lng": -123.4093693421
            },
            {
                "lat": 48.4268680034,
                "lng": -123.4077289916
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 737,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 19,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 19-3",
            "LABEL": "19-3",
            "FISHERY_AREA_ID": 2226,
            "OBJECTID": 881,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 401115891.6729,
            "FEATURE_LENGTH_M": 86061.0621,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.400097936864526,
            "lng": -123.42703945422096
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.4235758154,
                "lng": -123.9207230679
            },
            {
                "lat": 48.3766288709,
                "lng": -123.9211118847
            },
            {
                "lat": 48.281189457,
                "lng": -123.921112215
            },
            {
                "lat": 48.2400017409,
                "lng": -123.6790539123
            },
            {
                "lat": 48.2245558116,
                "lng": -123.5412222312
            },
            {
                "lat": 48.2250596197,
                "lng": -123.5387494254
            },
            {
                "lat": 48.2980264472,
                "lng": -123.5314182604
            },
            {
                "lat": 48.3428616755,
                "lng": -123.5272763151
            },
            {
                "lat": 48.3427311649,
                "lng": -123.5277932182
            },
            {
                "lat": 48.3416403508,
                "lng": -123.5293433321
            },
            {
                "lat": 48.3403888488,
                "lng": -123.5323646098
            },
            {
                "lat": 48.3393784428,
                "lng": -123.5352014832
            },
            {
                "lat": 48.338741688,
                "lng": -123.5370711088
            },
            {
                "lat": 48.3395344903,
                "lng": -123.5405800948
            },
            {
                "lat": 48.3418196875,
                "lng": -123.5450673573
            },
            {
                "lat": 48.3424529408,
                "lng": -123.5459520314
            },
            {
                "lat": 48.3636439581,
                "lng": -123.5753632164
            },
            {
                "lat": 48.3497813417,
                "lng": -123.6331639254
            },
            {
                "lat": 48.350803374,
                "lng": -123.6848684222
            },
            {
                "lat": 48.3416102385,
                "lng": -123.7090453084
            },
            {
                "lat": 48.3393434134,
                "lng": -123.7125616974
            },
            {
                "lat": 48.3391457141,
                "lng": -123.7133101637
            },
            {
                "lat": 48.338795228,
                "lng": -123.7142258954
            },
            {
                "lat": 48.3573833217,
                "lng": -123.749740232
            },
            {
                "lat": 48.369232044,
                "lng": -123.7478332523
            },
            {
                "lat": 48.4106945173,
                "lng": -123.7119968406
            },
            {
                "lat": 48.4235758154,
                "lng": -123.9207230679
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 738,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 20,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 20-5",
            "LABEL": "20-5",
            "FISHERY_AREA_ID": 2240,
            "OBJECTID": 882,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 425666395.9912,
            "FEATURE_LENGTH_M": 99448.1307,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.337873926603706,
            "lng": -123.65729157664073
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.4109914168,
                "lng": -123.6940223821
            },
            {
                "lat": 48.3809736854,
                "lng": -123.6915219485
            },
            {
                "lat": 48.3780666649,
                "lng": -123.691444675
            },
            {
                "lat": 48.3768047444,
                "lng": -123.685371044
            },
            {
                "lat": 48.3746219338,
                "lng": -123.6847534098
            },
            {
                "lat": 48.3679921247,
                "lng": -123.6888047357
            },
            {
                "lat": 48.350803374,
                "lng": -123.6848684222
            },
            {
                "lat": 48.3497813417,
                "lng": -123.6331639254
            },
            {
                "lat": 48.3702541737,
                "lng": -123.6162722925
            },
            {
                "lat": 48.4122310084,
                "lng": -123.6183232864
            },
            {
                "lat": 48.4109914168,
                "lng": -123.6940223821
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 739,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 20,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 20-7",
            "LABEL": "20-7",
            "FISHERY_AREA_ID": 2242,
            "OBJECTID": 883,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 35532353.6528,
            "FEATURE_LENGTH_M": 23816.4053,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.38031926223636,
            "lng": -123.67114259124547
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.4106945173,
                "lng": -123.7119968406
            },
            {
                "lat": 48.369232044,
                "lng": -123.7478332523
            },
            {
                "lat": 48.3573833217,
                "lng": -123.749740232
            },
            {
                "lat": 48.338795228,
                "lng": -123.7142258954
            },
            {
                "lat": 48.3391457141,
                "lng": -123.7133101637
            },
            {
                "lat": 48.3393434134,
                "lng": -123.7125616974
            },
            {
                "lat": 48.3416102385,
                "lng": -123.7090453084
            },
            {
                "lat": 48.350803374,
                "lng": -123.6848684222
            },
            {
                "lat": 48.3679921247,
                "lng": -123.6888047357
            },
            {
                "lat": 48.3746219338,
                "lng": -123.6847534098
            },
            {
                "lat": 48.3768047444,
                "lng": -123.685371044
            },
            {
                "lat": 48.3780666649,
                "lng": -123.691444675
            },
            {
                "lat": 48.3809736854,
                "lng": -123.6915219485
            },
            {
                "lat": 48.4109914168,
                "lng": -123.6940223821
            },
            {
                "lat": 48.4106945173,
                "lng": -123.7119968406
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 740,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 20,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 20-6",
            "LABEL": "20-6",
            "FISHERY_AREA_ID": 2241,
            "OBJECTID": 884,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 24821491.9164,
            "FEATURE_LENGTH_M": 20986.4937,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.36981019588667,
            "lng": -123.70609978984665
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9663202253,
                "lng": -124.9097215947
            },
            {
                "lat": 48.9743610329,
                "lng": -124.9127281386
            },
            {
                "lat": 48.9833869088,
                "lng": -124.9177477584
            },
            {
                "lat": 48.9859432766,
                "lng": -124.9187085971
            },
            {
                "lat": 48.9855649733,
                "lng": -124.9204026719
            },
            {
                "lat": 48.9854392166,
                "lng": -124.9229813086
            },
            {
                "lat": 48.9861331861,
                "lng": -124.9294507785
            },
            {
                "lat": 48.9872623003,
                "lng": -124.9367361465
            },
            {
                "lat": 48.9888955293,
                "lng": -124.943771632
            },
            {
                "lat": 48.9898374149,
                "lng": -124.9476705666
            },
            {
                "lat": 48.9895893266,
                "lng": -124.9521951459
            },
            {
                "lat": 48.9883962632,
                "lng": -124.9577182477
            },
            {
                "lat": 48.9870760068,
                "lng": -124.9631261822
            },
            {
                "lat": 48.9867594492,
                "lng": -124.9655759355
            },
            {
                "lat": 48.9875149965,
                "lng": -124.9681469637
            },
            {
                "lat": 48.9885219077,
                "lng": -124.9703508886
            },
            {
                "lat": 48.9907186592,
                "lng": -124.9729228085
            },
            {
                "lat": 48.993293737,
                "lng": -124.975752813
            },
            {
                "lat": 48.9960599092,
                "lng": -124.9803396936
            },
            {
                "lat": 48.9992635136,
                "lng": -124.9863047966
            },
            {
                "lat": 49.0025289585,
                "lng": -124.9922710822
            },
            {
                "lat": 49.0041006938,
                "lng": -124.9967950536
            },
            {
                "lat": 49.0064243733,
                "lng": -125.0013185997
            },
            {
                "lat": 49.0098189303,
                "lng": -125.0071629147
            },
            {
                "lat": 49.0106968383,
                "lng": -125.0091103322
            },
            {
                "lat": 49.0128968682,
                "lng": -125.0129464967
            },
            {
                "lat": 49.0150947748,
                "lng": -125.0171500332
            },
            {
                "lat": 49.0167269465,
                "lng": -125.0211712379
            },
            {
                "lat": 49.0174219791,
                "lng": -125.0228721124
            },
            {
                "lat": 49.0199318543,
                "lng": -125.0226830071
            },
            {
                "lat": 49.0239528762,
                "lng": -125.0216137744
            },
            {
                "lat": 49.025901817,
                "lng": -125.0205468722
            },
            {
                "lat": 49.0280995381,
                "lng": -125.0201029419
            },
            {
                "lat": 49.0293579794,
                "lng": -125.0204248156
            },
            {
                "lat": 49.0300483055,
                "lng": -125.0212401092
            },
            {
                "lat": 49.0307387916,
                "lng": -125.0231857236
            },
            {
                "lat": 49.0306734957,
                "lng": -125.0253828057
            },
            {
                "lat": 49.030673532,
                "lng": -125.0279626837
            },
            {
                "lat": 49.0301089446,
                "lng": -125.0299071952
            },
            {
                "lat": 49.0290415133,
                "lng": -125.0315394049
            },
            {
                "lat": 49.0270309367,
                "lng": -125.0342410459
            },
            {
                "lat": 49.0253371619,
                "lng": -125.0386431143
            },
            {
                "lat": 49.0238911104,
                "lng": -125.0409024343
            },
            {
                "lat": 49.0226979496,
                "lng": -125.0442967718
            },
            {
                "lat": 49.0206223826,
                "lng": -125.049385235
            },
            {
                "lat": 49.0184860661,
                "lng": -125.0523988277
            },
            {
                "lat": 49.0160367414,
                "lng": -125.0537798972
            },
            {
                "lat": 49.0140913009,
                "lng": -125.0537186317
            },
            {
                "lat": 49.0117642247,
                "lng": -125.0520864117
            },
            {
                "lat": 49.0103182041,
                "lng": -125.0498271608
            },
            {
                "lat": 49.0082472974,
                "lng": -125.0456165329
            },
            {
                "lat": 49.0068014369,
                "lng": -125.0423507995
            },
            {
                "lat": 49.0051041281,
                "lng": -125.038261102
            },
            {
                "lat": 49.001903796,
                "lng": -125.0294642691
            },
            {
                "lat": 48.9995767629,
                "lng": -125.0223702243
            },
            {
                "lat": 48.997505777,
                "lng": -125.0165852427
            },
            {
                "lat": 48.9959945655,
                "lng": -125.0131979733
            },
            {
                "lat": 48.993732773,
                "lng": -125.0110620173
            },
            {
                "lat": 48.9904672224,
                "lng": -125.0072245726
            },
            {
                "lat": 48.9880178922,
                "lng": -125.0050897386
            },
            {
                "lat": 48.9852528294,
                "lng": -125.0030215679
            },
            {
                "lat": 48.9815439447,
                "lng": -125.0011989662
            },
            {
                "lat": 48.9792213723,
                "lng": -125.0000001766
            },
            {
                "lat": 48.9767067643,
                "lng": -124.99993803
            },
            {
                "lat": 48.9741328914,
                "lng": -125.000129549
            },
            {
                "lat": 48.9716836913,
                "lng": -125.001259678
            },
            {
                "lat": 48.9696731416,
                "lng": -125.0038996361
            },
            {
                "lat": 48.9678500822,
                "lng": -125.0070417971
            },
            {
                "lat": 48.9668464551,
                "lng": -125.0096122059
            },
            {
                "lat": 48.9654005276,
                "lng": -125.0135729169
            },
            {
                "lat": 48.9647709628,
                "lng": -125.0169672093
            },
            {
                "lat": 48.964710194,
                "lng": -125.019919827
            },
            {
                "lat": 48.9657137279,
                "lng": -125.0218050006
            },
            {
                "lat": 48.9655262498,
                "lng": -125.0230638192
            },
            {
                "lat": 48.9643937823,
                "lng": -125.0245060342
            },
            {
                "lat": 48.9629476815,
                "lng": -125.0248192361
            },
            {
                "lat": 48.9600607507,
                "lng": -125.026833194
            },
            {
                "lat": 48.959838391,
                "lng": -125.0271378705
            },
            {
                "lat": 48.9589839315,
                "lng": -125.0273738676
            },
            {
                "lat": 48.9553598909,
                "lng": -125.0283578532
            },
            {
                "lat": 48.9518930818,
                "lng": -125.0258322221
            },
            {
                "lat": 48.9452364959,
                "lng": -125.0209739729
            },
            {
                "lat": 48.9431757377,
                "lng": -125.0194697048
            },
            {
                "lat": 48.9431419849,
                "lng": -125.0193170343
            },
            {
                "lat": 48.9430885264,
                "lng": -125.0192261286
            },
            {
                "lat": 48.9427833647,
                "lng": -125.0187843274
            },
            {
                "lat": 48.9420965259,
                "lng": -125.0171354743
            },
            {
                "lat": 48.9415773961,
                "lng": -125.0148764609
            },
            {
                "lat": 48.941066462,
                "lng": -125.0127792161
            },
            {
                "lat": 48.9394378099,
                "lng": -125.0070725809
            },
            {
                "lat": 48.9341431442,
                "lng": -124.9981152335
            },
            {
                "lat": 48.9317019127,
                "lng": -124.987527322
            },
            {
                "lat": 48.9345517503,
                "lng": -124.9785610227
            },
            {
                "lat": 48.9369965791,
                "lng": -124.9753041099
            },
            {
                "lat": 48.9463655808,
                "lng": -124.9744869097
            },
            {
                "lat": 48.9516600255,
                "lng": -124.9687884233
            },
            {
                "lat": 48.9577672116,
                "lng": -124.9573818313
            },
            {
                "lat": 48.9642865097,
                "lng": -124.9398641437
            },
            {
                "lat": 48.967545038,
                "lng": -124.9260179301
            },
            {
                "lat": 48.9663202253,
                "lng": -124.9097215947
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 680,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 23,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 23-3",
            "LABEL": "23-3",
            "FISHERY_AREA_ID": 2245,
            "OBJECTID": 824,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 40829972.9636,
            "FEATURE_LENGTH_M": 37652.7019,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.986076571982,
            "lng": -125.00069935945601
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9916186027,
                "lng": -123.3030397399
            },
            {
                "lat": 48.8738364536,
                "lng": -123.2913887912
            },
            {
                "lat": 48.8736002034,
                "lng": -123.2909784748
            },
            {
                "lat": 48.8728107823,
                "lng": -123.2899327098
            },
            {
                "lat": 48.8701939365,
                "lng": -123.2877890454
            },
            {
                "lat": 48.8644824167,
                "lng": -123.2806547383
            },
            {
                "lat": 48.8606758953,
                "lng": -123.2768481645
            },
            {
                "lat": 48.8552018028,
                "lng": -123.2720874024
            },
            {
                "lat": 48.8497316896,
                "lng": -123.260429645
            },
            {
                "lat": 48.8482250106,
                "lng": -123.251853566
            },
            {
                "lat": 48.8480073406,
                "lng": -123.2485051545
            },
            {
                "lat": 48.8474196495,
                "lng": -123.2470539667
            },
            {
                "lat": 48.846931706,
                "lng": -123.245550999
            },
            {
                "lat": 48.8467559658,
                "lng": -123.2446661742
            },
            {
                "lat": 48.8466337435,
                "lng": -123.2439347739
            },
            {
                "lat": 48.8333357258,
                "lng": -123.2335885304
            },
            {
                "lat": 48.8258094576,
                "lng": -123.2159503287
            },
            {
                "lat": 48.8243412557,
                "lng": -123.2112882487
            },
            {
                "lat": 48.8233647514,
                "lng": -123.2093578793
            },
            {
                "lat": 48.8226206397,
                "lng": -123.2080226923
            },
            {
                "lat": 48.8215823655,
                "lng": -123.2058869241
            },
            {
                "lat": 48.8212389757,
                "lng": -123.2052389292
            },
            {
                "lat": 48.8158115318,
                "lng": -123.1925195359
            },
            {
                "lat": 48.8155019073,
                "lng": -123.1918952018
            },
            {
                "lat": 48.8149875132,
                "lng": -123.1907355135
            },
            {
                "lat": 48.8146825471,
                "lng": -123.1901177251
            },
            {
                "lat": 48.8145298542,
                "lng": -123.1897204097
            },
            {
                "lat": 48.8144226609,
                "lng": -123.1893611853
            },
            {
                "lat": 48.814387985,
                "lng": -123.189177888
            },
            {
                "lat": 48.8137896034,
                "lng": -123.1888736344
            },
            {
                "lat": 48.8137814573,
                "lng": -123.1886819461
            },
            {
                "lat": 48.8056485825,
                "lng": -123.0084994257
            },
            {
                "lat": 48.8312227424,
                "lng": -123.0084982637
            },
            {
                "lat": 48.9916186027,
                "lng": -123.3030397399
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 681,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 18,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 18-1",
            "LABEL": "18-1",
            "FISHERY_AREA_ID": 2213,
            "OBJECTID": 825,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 207343689.5649,
            "FEATURE_LENGTH_M": 67722.7846,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.84496480464999,
            "lng": -123.22221080433528
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.0097921701,
                "lng": -125.2982337936
            },
            {
                "lat": 49.0074010843,
                "lng": -125.3050683057
            },
            {
                "lat": 49.0097921929,
                "lng": -125.311225801
            },
            {
                "lat": 49.0108141571,
                "lng": -125.3166888797
            },
            {
                "lat": 49.0098189784,
                "lng": -125.3230432899
            },
            {
                "lat": 49.0097806047,
                "lng": -125.3232947093
            },
            {
                "lat": 49.0097538232,
                "lng": -125.3234640459
            },
            {
                "lat": 49.0096432505,
                "lng": -125.3241806908
            },
            {
                "lat": 48.9883343785,
                "lng": -125.3291918045
            },
            {
                "lat": 48.9732201633,
                "lng": -125.3580171156
            },
            {
                "lat": 48.9488604614,
                "lng": -125.4105300307
            },
            {
                "lat": 48.9205668103,
                "lng": -125.3722987061
            },
            {
                "lat": 48.9693947383,
                "lng": -125.3236851891
            },
            {
                "lat": 48.9694333231,
                "lng": -125.3236010819
            },
            {
                "lat": 48.9694554761,
                "lng": -125.3234558296
            },
            {
                "lat": 48.9695625703,
                "lng": -125.3232581946
            },
            {
                "lat": 48.9698488966,
                "lng": -125.3228380054
            },
            {
                "lat": 48.9698490155,
                "lng": -125.3221883634
            },
            {
                "lat": 48.9698488622,
                "lng": -125.3215404303
            },
            {
                "lat": 48.9697684177,
                "lng": -125.320968688
            },
            {
                "lat": 48.9701723949,
                "lng": -125.3192662382
            },
            {
                "lat": 48.9706567893,
                "lng": -125.31716188
            },
            {
                "lat": 48.9713099405,
                "lng": -125.3145673317
            },
            {
                "lat": 48.9711456253,
                "lng": -125.3129430365
            },
            {
                "lat": 48.971309855,
                "lng": -125.308723789
            },
            {
                "lat": 48.9713902441,
                "lng": -125.3029711192
            },
            {
                "lat": 48.971629966,
                "lng": -125.295990016
            },
            {
                "lat": 48.9709816613,
                "lng": -125.2903131384
            },
            {
                "lat": 48.9710654206,
                "lng": -125.2859330534
            },
            {
                "lat": 48.9745521435,
                "lng": -125.2836612065
            },
            {
                "lat": 48.981609123,
                "lng": -125.2822874925
            },
            {
                "lat": 48.9921528493,
                "lng": -125.2830961355
            },
            {
                "lat": 49.0009120756,
                "lng": -125.2843095851
            },
            {
                "lat": 49.00756042,
                "lng": -125.2852008434
            },
            {
                "lat": 49.0096698824,
                "lng": -125.2881243001
            },
            {
                "lat": 49.0101588079,
                "lng": -125.2943731386
            },
            {
                "lat": 49.0097921701,
                "lng": -125.2982337936
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 682,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 23,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 23-9",
            "LABEL": "23-9",
            "FISHERY_AREA_ID": 2251,
            "OBJECTID": 826,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 33455699.3821,
            "FEATURE_LENGTH_M": 30565.489,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.98354077684594,
            "lng": -125.3141602446703
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9838291648,
                "lng": -123.6892311285
            },
            {
                "lat": 48.9817768279,
                "lng": -123.6912540373
            },
            {
                "lat": 48.9814951884,
                "lng": -123.7432870366
            },
            {
                "lat": 48.9813612302,
                "lng": -123.7684169537
            },
            {
                "lat": 48.9585532227,
                "lng": -123.7562565587
            },
            {
                "lat": 48.9577824434,
                "lng": -123.7567284543
            },
            {
                "lat": 48.9554937591,
                "lng": -123.7584686332
            },
            {
                "lat": 48.9510117789,
                "lng": -123.75542329
            },
            {
                "lat": 48.9397659435,
                "lng": -123.7485969251
            },
            {
                "lat": 48.9285166735,
                "lng": -123.7334975173
            },
            {
                "lat": 48.9199754045,
                "lng": -123.7205429829
            },
            {
                "lat": 48.911857695,
                "lng": -123.7108606595
            },
            {
                "lat": 48.9094014808,
                "lng": -123.708098188
            },
            {
                "lat": 48.9149743159,
                "lng": -123.7016296997
            },
            {
                "lat": 48.9167249436,
                "lng": -123.7020480496
            },
            {
                "lat": 48.919201182,
                "lng": -123.7028499847
            },
            {
                "lat": 48.9220963378,
                "lng": -123.7036126876
            },
            {
                "lat": 48.925445746,
                "lng": -123.7046054192
            },
            {
                "lat": 48.9276041314,
                "lng": -123.7054368693
            },
            {
                "lat": 48.9285958485,
                "lng": -123.7056268199
            },
            {
                "lat": 48.9296226189,
                "lng": -123.7060468629
            },
            {
                "lat": 48.9140009139,
                "lng": -123.6268623857
            },
            {
                "lat": 48.8993792881,
                "lng": -123.5926515727
            },
            {
                "lat": 48.8989717089,
                "lng": -123.5916062037
            },
            {
                "lat": 48.897731875,
                "lng": -123.5879890285
            },
            {
                "lat": 48.8976095593,
                "lng": -123.585861008
            },
            {
                "lat": 48.9194107413,
                "lng": -123.5845413519
            },
            {
                "lat": 48.9410780058,
                "lng": -123.590821223
            },
            {
                "lat": 48.9426381,
                "lng": -123.5917740477
            },
            {
                "lat": 48.9450838506,
                "lng": -123.5955816887
            },
            {
                "lat": 48.9456671674,
                "lng": -123.5965832756
            },
            {
                "lat": 48.9338497908,
                "lng": -123.6320103834
            },
            {
                "lat": 48.9358218117,
                "lng": -123.6318218498
            },
            {
                "lat": 48.9393841796,
                "lng": -123.6325841641
            },
            {
                "lat": 48.9453015271,
                "lng": -123.6357644952
            },
            {
                "lat": 48.9526216332,
                "lng": -123.6419375303
            },
            {
                "lat": 48.9606405093,
                "lng": -123.6499551898
            },
            {
                "lat": 48.9650957795,
                "lng": -123.6516125562
            },
            {
                "lat": 48.970695314,
                "lng": -123.6565087068
            },
            {
                "lat": 48.9763598886,
                "lng": -123.6618583115
            },
            {
                "lat": 48.979988524,
                "lng": -123.6649101117
            },
            {
                "lat": 48.9820247729,
                "lng": -123.666823539
            },
            {
                "lat": 48.9826127133,
                "lng": -123.6678777332
            },
            {
                "lat": 48.9826463999,
                "lng": -123.6681968555
            },
            {
                "lat": 48.9847674079,
                "lng": -123.6696097161
            },
            {
                "lat": 48.9868886994,
                "lng": -123.6715551361
            },
            {
                "lat": 48.9871364517,
                "lng": -123.6741248188
            },
            {
                "lat": 48.9859469282,
                "lng": -123.6791227394
            },
            {
                "lat": 48.9849969638,
                "lng": -123.6862569712
            },
            {
                "lat": 48.9838291648,
                "lng": -123.6892311285
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 683,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 17-6",
            "LABEL": "17-6",
            "FISHERY_AREA_ID": 2197,
            "OBJECTID": 827,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 66959568.9868,
            "FEATURE_LENGTH_M": 43915.0102,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.947345312136,
            "lng": -123.67297104960203
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9826815016,
                "lng": -123.5711067628
            },
            {
                "lat": 48.9829524626,
                "lng": -123.5721814143
            },
            {
                "lat": 48.98320744,
                "lng": -123.5731971807
            },
            {
                "lat": 48.9836696331,
                "lng": -123.5745247259
            },
            {
                "lat": 48.9840703091,
                "lng": -123.5750500057
            },
            {
                "lat": 48.9842948605,
                "lng": -123.5752567822
            },
            {
                "lat": 48.9844823677,
                "lng": -123.5754313532
            },
            {
                "lat": 48.9844172297,
                "lng": -123.5755388346
            },
            {
                "lat": 48.9790491667,
                "lng": -123.6005935727
            },
            {
                "lat": 48.9338497908,
                "lng": -123.6320103834
            },
            {
                "lat": 48.9456671674,
                "lng": -123.5965832756
            },
            {
                "lat": 48.9450838506,
                "lng": -123.5955816887
            },
            {
                "lat": 48.9426381,
                "lng": -123.5917740477
            },
            {
                "lat": 48.9374923284,
                "lng": -123.5836036663
            },
            {
                "lat": 48.9157482541,
                "lng": -123.5466756468
            },
            {
                "lat": 48.9059719095,
                "lng": -123.5299535541
            },
            {
                "lat": 48.8862870508,
                "lng": -123.4980475438
            },
            {
                "lat": 48.8747899082,
                "lng": -123.4767304991
            },
            {
                "lat": 48.8780857806,
                "lng": -123.4749675689
            },
            {
                "lat": 48.9039996935,
                "lng": -123.4505841348
            },
            {
                "lat": 48.9029940201,
                "lng": -123.4486643693
            },
            {
                "lat": 48.901362861,
                "lng": -123.4448315735
            },
            {
                "lat": 48.9009707453,
                "lng": -123.4438938539
            },
            {
                "lat": 48.9002684929,
                "lng": -123.4429168816
            },
            {
                "lat": 48.8997458132,
                "lng": -123.4422078021
            },
            {
                "lat": 48.8991544232,
                "lng": -123.4418874983
            },
            {
                "lat": 48.8948972153,
                "lng": -123.4323359141
            },
            {
                "lat": 48.8932265632,
                "lng": -123.4285967648
            },
            {
                "lat": 48.8930623883,
                "lng": -123.4275888917
            },
            {
                "lat": 48.8931196372,
                "lng": -123.4266437948
            },
            {
                "lat": 48.8930810806,
                "lng": -123.426223486
            },
            {
                "lat": 48.8925968209,
                "lng": -123.4252553792
            },
            {
                "lat": 48.8909447725,
                "lng": -123.4235152657
            },
            {
                "lat": 48.8904071304,
                "lng": -123.4229044458
            },
            {
                "lat": 48.8776666999,
                "lng": -123.3972006092
            },
            {
                "lat": 48.8772008314,
                "lng": -123.3973474119
            },
            {
                "lat": 48.8765606857,
                "lng": -123.3975532814
            },
            {
                "lat": 48.8760331771,
                "lng": -123.3974004144
            },
            {
                "lat": 48.8755151316,
                "lng": -123.3972317002
            },
            {
                "lat": 48.8749773428,
                "lng": -123.3965754092
            },
            {
                "lat": 48.8746373805,
                "lng": -123.3960869117
            },
            {
                "lat": 48.8737224562,
                "lng": -123.3953937382
            },
            {
                "lat": 48.8717724114,
                "lng": -123.3921736537
            },
            {
                "lat": 48.8715932764,
                "lng": -123.3919299461
            },
            {
                "lat": 48.8714290579,
                "lng": -123.3916394394
            },
            {
                "lat": 48.8711169289,
                "lng": -123.3909076965
            },
            {
                "lat": 48.8702545291,
                "lng": -123.3865206237
            },
            {
                "lat": 48.871460573,
                "lng": -123.3828199589
            },
            {
                "lat": 48.874382516,
                "lng": -123.3811037322
            },
            {
                "lat": 48.8779063083,
                "lng": -123.3815298136
            },
            {
                "lat": 48.8834070383,
                "lng": -123.3829949165
            },
            {
                "lat": 48.8902010398,
                "lng": -123.3848036733
            },
            {
                "lat": 48.8943708992,
                "lng": -123.3865976909
            },
            {
                "lat": 48.8945853537,
                "lng": -123.386696436
            },
            {
                "lat": 48.8977354902,
                "lng": -123.3912733777
            },
            {
                "lat": 48.9051125944,
                "lng": -123.4050751361
            },
            {
                "lat": 48.9143943962,
                "lng": -123.4250642426
            },
            {
                "lat": 48.9205785432,
                "lng": -123.439812243
            },
            {
                "lat": 48.9291454302,
                "lng": -123.459326595
            },
            {
                "lat": 48.9377101799,
                "lng": -123.4807438868
            },
            {
                "lat": 48.9450873352,
                "lng": -123.498352985
            },
            {
                "lat": 48.948657752,
                "lng": -123.5128632567
            },
            {
                "lat": 48.9553179941,
                "lng": -123.5278551204
            },
            {
                "lat": 48.9686429165,
                "lng": -123.5480808889
            },
            {
                "lat": 48.9760479759,
                "lng": -123.5563889534
            },
            {
                "lat": 48.9804613774,
                "lng": -123.5664903613
            },
            {
                "lat": 48.9805638202,
                "lng": -123.5666130042
            },
            {
                "lat": 48.9812961095,
                "lng": -123.56768062
            },
            {
                "lat": 48.9819874778,
                "lng": -123.5695268399
            },
            {
                "lat": 48.9826815016,
                "lng": -123.5711067628
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 684,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 17-2",
            "LABEL": "17-2",
            "FISHERY_AREA_ID": 2193,
            "OBJECTID": 828,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 78286159.4763,
            "FEATURE_LENGTH_M": 52336.4603,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.91740693286429,
            "lng": -123.47353742704142
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.0069235946,
                "lng": -125.6644055563
            },
            {
                "lat": 49.0023079201,
                "lng": -125.6681437759
            },
            {
                "lat": 49.0008922099,
                "lng": -125.6690905224
            },
            {
                "lat": 49.0001947335,
                "lng": -125.6706540316
            },
            {
                "lat": 49.0000003533,
                "lng": -125.6724161326
            },
            {
                "lat": 49.000000397,
                "lng": -125.6823500859
            },
            {
                "lat": 49.0000004616,
                "lng": -125.9000011274
            },
            {
                "lat": 48.2874441364,
                "lng": -126.7936346739
            },
            {
                "lat": 47.5748874517,
                "lng": -127.687268741
            },
            {
                "lat": 46.8623310331,
                "lng": -128.5809022682
            },
            {
                "lat": 46.8919448201,
                "lng": -128.5313872688
            },
            {
                "lat": 47.0405268969,
                "lng": -128.2772977795
            },
            {
                "lat": 47.2191510733,
                "lng": -127.9592439548
            },
            {
                "lat": 47.2533189524,
                "lng": -127.8972927241
            },
            {
                "lat": 47.3330494967,
                "lng": -127.7509001468
            },
            {
                "lat": 47.4210665415,
                "lng": -127.5827708451
            },
            {
                "lat": 48.5999985236,
                "lng": -126.1237030455
            },
            {
                "lat": 48.7499996805,
                "lng": -125.9356536242
            },
            {
                "lat": 48.9805184479,
                "lng": -125.6450271981
            },
            {
                "lat": 48.9894216556,
                "lng": -125.6339037043
            },
            {
                "lat": 48.994319506,
                "lng": -125.6277853732
            },
            {
                "lat": 49.0005533197,
                "lng": -125.6217888723
            },
            {
                "lat": 49.00692358,
                "lng": -125.6340941016
            },
            {
                "lat": 49.0102193837,
                "lng": -125.6472706992
            },
            {
                "lat": 49.0069235946,
                "lng": -125.6644055563
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 685,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 124,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 124-1",
            "LABEL": "124-1",
            "FISHERY_AREA_ID": 2325,
            "OBJECTID": 829,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 4345834291.2748,
            "FEATURE_LENGTH_M": 658013.9996,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.36931671054799,
            "lng": -126.50085567235998
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.0000005095,
                "lng": -126.7589646478
            },
            {
                "lat": 49.0000003786,
                "lng": -127.0291369356
            },
            {
                "lat": 48.2637828418,
                "lng": -127.9158226738
            },
            {
                "lat": 47.5275654294,
                "lng": -128.8025076282
            },
            {
                "lat": 46.791348043,
                "lng": -129.689193477
            },
            {
                "lat": 46.768791319,
                "lng": -129.648757752
            },
            {
                "lat": 46.6467589971,
                "lng": -129.4017484085
            },
            {
                "lat": 46.5350341002,
                "lng": -129.1451883142
            },
            {
                "lat": 46.5274997107,
                "lng": -129.127227715
            },
            {
                "lat": 46.6080556351,
                "lng": -128.9966733178
            },
            {
                "lat": 46.6522212919,
                "lng": -128.9266656514
            },
            {
                "lat": 46.6808314852,
                "lng": -128.8808284751
            },
            {
                "lat": 46.7658343976,
                "lng": -128.7422175225
            },
            {
                "lat": 46.8623310331,
                "lng": -128.5809022682
            },
            {
                "lat": 47.5748874517,
                "lng": -127.687268741
            },
            {
                "lat": 48.2874441364,
                "lng": -126.7936346739
            },
            {
                "lat": 49.0000004616,
                "lng": -125.9000011274
            },
            {
                "lat": 49.0000005095,
                "lng": -126.7589646478
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 686,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 124,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 124-2",
            "LABEL": "124-2",
            "FISHERY_AREA_ID": 2326,
            "OBJECTID": 830,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 20847928777.1847,
            "FEATURE_LENGTH_M": 817441.6698,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 47.47179931841111,
            "lng": -128.26587244317778
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 49.0005533197,
                "lng": -125.6217888723
            },
            {
                "lat": 48.994319506,
                "lng": -125.6277853732
            },
            {
                "lat": 48.9894216556,
                "lng": -125.6339037043
            },
            {
                "lat": 48.9805184479,
                "lng": -125.6450271981
            },
            {
                "lat": 48.683334416,
                "lng": -125.6999967568
            },
            {
                "lat": 48.7999988656,
                "lng": -125.483330383
            },
            {
                "lat": 48.9211384326,
                "lng": -125.5411603005
            },
            {
                "lat": 48.9235191696,
                "lng": -125.5399326722
            },
            {
                "lat": 48.9271092416,
                "lng": -125.5377654473
            },
            {
                "lat": 48.9287566171,
                "lng": -125.5379101464
            },
            {
                "lat": 48.9314505787,
                "lng": -125.5386578469
            },
            {
                "lat": 48.9342957671,
                "lng": -125.5396341371
            },
            {
                "lat": 48.9363899177,
                "lng": -125.5423277242
            },
            {
                "lat": 48.9375109004,
                "lng": -125.5473397646
            },
            {
                "lat": 48.9393805478,
                "lng": -125.5525817235
            },
            {
                "lat": 48.9427484647,
                "lng": -125.5632015742
            },
            {
                "lat": 48.9451910043,
                "lng": -125.5723572929
            },
            {
                "lat": 48.9502410317,
                "lng": -125.5789486197
            },
            {
                "lat": 48.9572108002,
                "lng": -125.5838700405
            },
            {
                "lat": 48.9621050158,
                "lng": -125.5945442968
            },
            {
                "lat": 48.9724960452,
                "lng": -125.6019592947
            },
            {
                "lat": 48.9757266709,
                "lng": -125.6026769699
            },
            {
                "lat": 48.9856114815,
                "lng": -125.6055299661
            },
            {
                "lat": 48.9943996061,
                "lng": -125.6110222124
            },
            {
                "lat": 49.0005533197,
                "lng": -125.6217888723
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 687,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 123,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 123-5",
            "LABEL": "123-5",
            "FISHERY_AREA_ID": 2320,
            "OBJECTID": 831,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 287486690.4276,
            "FEATURE_LENGTH_M": 82356.3225,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.940559232940004,
            "lng": -125.581001647596
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9806476136,
                "lng": -125.0214616003
            },
            {
                "lat": 48.9813878488,
                "lng": -125.0212635956
            },
            {
                "lat": 48.9815440035,
                "lng": -125.0220943644
            },
            {
                "lat": 48.9815405287,
                "lng": -125.0231024491
            },
            {
                "lat": 48.9819560158,
                "lng": -125.0242621092
            },
            {
                "lat": 48.9823728239,
                "lng": -125.0258481704
            },
            {
                "lat": 48.9840387572,
                "lng": -125.0286789937
            },
            {
                "lat": 48.9850386519,
                "lng": -125.0309302331
            },
            {
                "lat": 48.9850388704,
                "lng": -125.0322641084
            },
            {
                "lat": 48.9847071123,
                "lng": -125.033012968
            },
            {
                "lat": 48.9842914636,
                "lng": -125.0341029866
            },
            {
                "lat": 48.9833716286,
                "lng": -125.0357662688
            },
            {
                "lat": 48.9827883892,
                "lng": -125.0391009032
            },
            {
                "lat": 48.981872159,
                "lng": -125.0421830252
            },
            {
                "lat": 48.9810410192,
                "lng": -125.0444343745
            },
            {
                "lat": 48.9792901182,
                "lng": -125.0461040703
            },
            {
                "lat": 48.9758722956,
                "lng": -125.0485153502
            },
            {
                "lat": 48.9727860093,
                "lng": -125.0586018001
            },
            {
                "lat": 48.9682890209,
                "lng": -125.0764391346
            },
            {
                "lat": 48.9670445588,
                "lng": -125.0802233797
            },
            {
                "lat": 48.966422875,
                "lng": -125.081528031
            },
            {
                "lat": 48.9659884768,
                "lng": -125.0820310237
            },
            {
                "lat": 48.9655566882,
                "lng": -125.0818860552
            },
            {
                "lat": 48.9595949844,
                "lng": -125.07537837
            },
            {
                "lat": 48.9568825641,
                "lng": -125.0724181382
            },
            {
                "lat": 48.9532854009,
                "lng": -125.0684974441
            },
            {
                "lat": 48.9510501869,
                "lng": -125.0618825891
            },
            {
                "lat": 48.9457391827,
                "lng": -125.0577165111
            },
            {
                "lat": 48.9249230135,
                "lng": -125.064796641
            },
            {
                "lat": 48.9033314505,
                "lng": -125.0928498915
            },
            {
                "lat": 48.8974339435,
                "lng": -125.1085438989
            },
            {
                "lat": 48.8934829038,
                "lng": -125.1113820857
            },
            {
                "lat": 48.8931429387,
                "lng": -125.114745582
            },
            {
                "lat": 48.8843801448,
                "lng": -125.1388469368
            },
            {
                "lat": 48.88369002,
                "lng": -125.1396102132
            },
            {
                "lat": 48.8825303115,
                "lng": -125.1408467234
            },
            {
                "lat": 48.8814499933,
                "lng": -125.1427914183
            },
            {
                "lat": 48.8806455404,
                "lng": -125.1445012288
            },
            {
                "lat": 48.8803710345,
                "lng": -125.1462847091
            },
            {
                "lat": 48.8803522474,
                "lng": -125.1489791262
            },
            {
                "lat": 48.8802755656,
                "lng": -125.1501309879
            },
            {
                "lat": 48.8773230715,
                "lng": -125.1582560537
            },
            {
                "lat": 48.8764953133,
                "lng": -125.1592868688
            },
            {
                "lat": 48.8760682754,
                "lng": -125.1601709211
            },
            {
                "lat": 48.8758655504,
                "lng": -125.1610560437
            },
            {
                "lat": 48.8758469194,
                "lng": -125.1615515755
            },
            {
                "lat": 48.875865596,
                "lng": -125.1618881801
            },
            {
                "lat": 48.87585857,
                "lng": -125.1622848046
            },
            {
                "lat": 48.8708959441,
                "lng": -125.1641159375
            },
            {
                "lat": 48.866226576,
                "lng": -125.172515513
            },
            {
                "lat": 48.8510634217,
                "lng": -125.148376654
            },
            {
                "lat": 48.8417059321,
                "lng": -125.133477062
            },
            {
                "lat": 48.8411900764,
                "lng": -125.1324765258
            },
            {
                "lat": 48.8390202379,
                "lng": -125.1291737355
            },
            {
                "lat": 48.8390201334,
                "lng": -125.1244058597
            },
            {
                "lat": 48.8409725267,
                "lng": -125.1190877654
            },
            {
                "lat": 48.8414650353,
                "lng": -125.1131591502
            },
            {
                "lat": 48.8401797161,
                "lng": -125.1039875359
            },
            {
                "lat": 48.8502727793,
                "lng": -125.0897067922
            },
            {
                "lat": 48.8540378719,
                "lng": -125.0736924021
            },
            {
                "lat": 48.8634570597,
                "lng": -125.0445025081
            },
            {
                "lat": 48.8733441108,
                "lng": -125.0139006091
            },
            {
                "lat": 48.8785245565,
                "lng": -124.9884712609
            },
            {
                "lat": 48.8945316959,
                "lng": -124.9837653863
            },
            {
                "lat": 48.9213713271,
                "lng": -124.9988333649
            },
            {
                "lat": 48.9394378099,
                "lng": -125.0070725809
            },
            {
                "lat": 48.941066462,
                "lng": -125.0127792161
            },
            {
                "lat": 48.9415773961,
                "lng": -125.0148764609
            },
            {
                "lat": 48.9420965259,
                "lng": -125.0171354743
            },
            {
                "lat": 48.9427833647,
                "lng": -125.0187843274
            },
            {
                "lat": 48.9430885264,
                "lng": -125.0192261286
            },
            {
                "lat": 48.9431419849,
                "lng": -125.0193170343
            },
            {
                "lat": 48.9431757377,
                "lng": -125.0194697048
            },
            {
                "lat": 48.9452364959,
                "lng": -125.0209739729
            },
            {
                "lat": 48.9518930818,
                "lng": -125.0258322221
            },
            {
                "lat": 48.9553598909,
                "lng": -125.0283578532
            },
            {
                "lat": 48.9589839315,
                "lng": -125.0273738676
            },
            {
                "lat": 48.959838391,
                "lng": -125.0271378705
            },
            {
                "lat": 48.9624133547,
                "lng": -125.0264364057
            },
            {
                "lat": 48.9648512639,
                "lng": -125.0251460876
            },
            {
                "lat": 48.9675450658,
                "lng": -125.0220173141
            },
            {
                "lat": 48.9690782233,
                "lng": -125.0197139673
            },
            {
                "lat": 48.9712213793,
                "lng": -125.0176227309
            },
            {
                "lat": 48.973251644,
                "lng": -125.016144179
            },
            {
                "lat": 48.9758874128,
                "lng": -125.0167469591
            },
            {
                "lat": 48.9773180362,
                "lng": -125.0190499078
            },
            {
                "lat": 48.9780316585,
                "lng": -125.021576084
            },
            {
                "lat": 48.9792868008,
                "lng": -125.0218346192
            },
            {
                "lat": 48.9806476136,
                "lng": -125.0214616003
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 688,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 23,
            "MANAGEMENT_SUBAREA": 4,
            "MANAGEMENT_AREA_NAME": "Subarea 23-4",
            "LABEL": "23-4",
            "FISHERY_AREA_ID": 2246,
            "OBJECTID": 832,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 95223094.4152,
            "FEATURE_LENGTH_M": 50143.4589,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.92790182812923,
            "lng": -125.06896928729549
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9488604614,
                "lng": -125.4105300307
            },
            {
                "lat": 48.9561269806,
                "lng": -125.4222340349
            },
            {
                "lat": 48.9605716224,
                "lng": -125.4293969295
            },
            {
                "lat": 48.9638173299,
                "lng": -125.4317091495
            },
            {
                "lat": 48.9606973936,
                "lng": -125.4364171994
            },
            {
                "lat": 48.9545588605,
                "lng": -125.4517141819
            },
            {
                "lat": 48.9332280015,
                "lng": -125.5069042951
            },
            {
                "lat": 48.9336203733,
                "lng": -125.5076600241
            },
            {
                "lat": 48.9406890924,
                "lng": -125.5155108655
            },
            {
                "lat": 48.9564018903,
                "lng": -125.5327984764
            },
            {
                "lat": 48.9749978148,
                "lng": -125.5500792923
            },
            {
                "lat": 48.9846884187,
                "lng": -125.5610816668
            },
            {
                "lat": 48.9852098861,
                "lng": -125.5783613862
            },
            {
                "lat": 48.9812807888,
                "lng": -125.590935398
            },
            {
                "lat": 48.9708056869,
                "lng": -125.5948643818
            },
            {
                "lat": 48.9621050158,
                "lng": -125.5945442968
            },
            {
                "lat": 48.9572108002,
                "lng": -125.5838700405
            },
            {
                "lat": 48.9502410317,
                "lng": -125.5789486197
            },
            {
                "lat": 48.9451910043,
                "lng": -125.5723572929
            },
            {
                "lat": 48.9427484647,
                "lng": -125.5632015742
            },
            {
                "lat": 48.9393805478,
                "lng": -125.5525817235
            },
            {
                "lat": 48.9375109004,
                "lng": -125.5473397646
            },
            {
                "lat": 48.9363899177,
                "lng": -125.5423277242
            },
            {
                "lat": 48.9342957671,
                "lng": -125.5396341371
            },
            {
                "lat": 48.9314505787,
                "lng": -125.5386578469
            },
            {
                "lat": 48.9287566171,
                "lng": -125.5379101464
            },
            {
                "lat": 48.9271092416,
                "lng": -125.5377654473
            },
            {
                "lat": 48.9235191696,
                "lng": -125.5399326722
            },
            {
                "lat": 48.9211384326,
                "lng": -125.5411603005
            },
            {
                "lat": 48.8697049717,
                "lng": -125.4168007334
            },
            {
                "lat": 48.9205668103,
                "lng": -125.3722987061
            },
            {
                "lat": 48.9488604614,
                "lng": -125.4105300307
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 689,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 23,
            "MANAGEMENT_SUBAREA": 11,
            "MANAGEMENT_AREA_NAME": "Subarea 23-11",
            "LABEL": "23-11",
            "FISHERY_AREA_ID": 2253,
            "OBJECTID": 833,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 84204632.7566,
            "FEATURE_LENGTH_M": 48505.8736,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.94630419793436,
            "lng": -125.5153143240344
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9805184479,
                "lng": -125.6450271981
            },
            {
                "lat": 48.7499996805,
                "lng": -125.9356536242
            },
            {
                "lat": 48.683334416,
                "lng": -125.6999967568
            },
            {
                "lat": 48.9805184479,
                "lng": -125.6450271981
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 690,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 123,
            "MANAGEMENT_SUBAREA": 6,
            "MANAGEMENT_AREA_NAME": "Subarea 123-6",
            "LABEL": "123-6",
            "FISHERY_AREA_ID": 2321,
            "OBJECTID": 834,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 301374972.9461,
            "FEATURE_LENGTH_M": 85461.6815,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.848592748075006,
            "lng": -125.73142619430001
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9710654206,
                "lng": -125.2859330534
            },
            {
                "lat": 48.9709816613,
                "lng": -125.2903131384
            },
            {
                "lat": 48.971629966,
                "lng": -125.295990016
            },
            {
                "lat": 48.9713902441,
                "lng": -125.3029711192
            },
            {
                "lat": 48.971309855,
                "lng": -125.308723789
            },
            {
                "lat": 48.9711456253,
                "lng": -125.3129430365
            },
            {
                "lat": 48.9713099405,
                "lng": -125.3145673317
            },
            {
                "lat": 48.9706567893,
                "lng": -125.31716188
            },
            {
                "lat": 48.9701723949,
                "lng": -125.3192662382
            },
            {
                "lat": 48.9697684177,
                "lng": -125.320968688
            },
            {
                "lat": 48.9698488622,
                "lng": -125.3215404303
            },
            {
                "lat": 48.9698490155,
                "lng": -125.3221883634
            },
            {
                "lat": 48.9698488966,
                "lng": -125.3228380054
            },
            {
                "lat": 48.9695625703,
                "lng": -125.3232581946
            },
            {
                "lat": 48.9694554761,
                "lng": -125.3234558296
            },
            {
                "lat": 48.9694333231,
                "lng": -125.3236010819
            },
            {
                "lat": 48.9693947383,
                "lng": -125.3236851891
            },
            {
                "lat": 48.9205668103,
                "lng": -125.3722987061
            },
            {
                "lat": 48.8697049717,
                "lng": -125.4168007334
            },
            {
                "lat": 48.8317674084,
                "lng": -125.325065772
            },
            {
                "lat": 48.8671834017,
                "lng": -125.2897116306
            },
            {
                "lat": 48.9256938302,
                "lng": -125.2221669761
            },
            {
                "lat": 48.9439883781,
                "lng": -125.241714534
            },
            {
                "lat": 48.9449498644,
                "lng": -125.241744954
            },
            {
                "lat": 48.9458882042,
                "lng": -125.2416070311
            },
            {
                "lat": 48.9477999686,
                "lng": -125.2400063513
            },
            {
                "lat": 48.9505123329,
                "lng": -125.2401582641
            },
            {
                "lat": 48.9548835909,
                "lng": -125.2436207755
            },
            {
                "lat": 48.9585042959,
                "lng": -125.2476960757
            },
            {
                "lat": 48.9615169216,
                "lng": -125.2552339703
            },
            {
                "lat": 48.9664915414,
                "lng": -125.2662353891
            },
            {
                "lat": 48.9696579007,
                "lng": -125.2745287149
            },
            {
                "lat": 48.9698104822,
                "lng": -125.2802588991
            },
            {
                "lat": 48.9710654206,
                "lng": -125.2859330534
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 691,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 23,
            "MANAGEMENT_SUBAREA": 8,
            "MANAGEMENT_AREA_NAME": "Subarea 23-8",
            "LABEL": "23-8",
            "FISHERY_AREA_ID": 2250,
            "OBJECTID": 835,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 120170351.7891,
            "FEATURE_LENGTH_M": 44157.4799,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.95284730942941,
            "lng": -125.29453491810003
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9426381,
                "lng": -123.5917740477
            },
            {
                "lat": 48.9410780058,
                "lng": -123.590821223
            },
            {
                "lat": 48.9194107413,
                "lng": -123.5845413519
            },
            {
                "lat": 48.8976095593,
                "lng": -123.585861008
            },
            {
                "lat": 48.8885305841,
                "lng": -123.5719760514
            },
            {
                "lat": 48.878795889,
                "lng": -123.5636064999
            },
            {
                "lat": 48.8704219314,
                "lng": -123.5492636622
            },
            {
                "lat": 48.8630949161,
                "lng": -123.5283276155
            },
            {
                "lat": 48.8588026327,
                "lng": -123.5297932208
            },
            {
                "lat": 48.86183863,
                "lng": -123.5445554951
            },
            {
                "lat": 48.8549306039,
                "lng": -123.5506222788
            },
            {
                "lat": 48.8474963026,
                "lng": -123.556694283
            },
            {
                "lat": 48.8467221246,
                "lng": -123.5638352092
            },
            {
                "lat": 48.8434182679,
                "lng": -123.5602111401
            },
            {
                "lat": 48.7931899169,
                "lng": -123.514785981
            },
            {
                "lat": 48.7847670838,
                "lng": -123.5148532583
            },
            {
                "lat": 48.7816541971,
                "lng": -123.5182177928
            },
            {
                "lat": 48.7807763777,
                "lng": -123.5371013505
            },
            {
                "lat": 48.7671627715,
                "lng": -123.5458896218
            },
            {
                "lat": 48.7640871868,
                "lng": -123.5533525477
            },
            {
                "lat": 48.7542035638,
                "lng": -123.544792486
            },
            {
                "lat": 48.7394908967,
                "lng": -123.5217279602
            },
            {
                "lat": 48.7276305095,
                "lng": -123.5063552604
            },
            {
                "lat": 48.7274897371,
                "lng": -123.5043718326
            },
            {
                "lat": 48.7289472496,
                "lng": -123.4863739388
            },
            {
                "lat": 48.7405851088,
                "lng": -123.4424524538
            },
            {
                "lat": 48.7702327564,
                "lng": -123.4661717346
            },
            {
                "lat": 48.7755051314,
                "lng": -123.4608986114
            },
            {
                "lat": 48.7752873287,
                "lng": -123.4529877629
            },
            {
                "lat": 48.7629891982,
                "lng": -123.4143372874
            },
            {
                "lat": 48.7605703078,
                "lng": -123.3904036852
            },
            {
                "lat": 48.7728961838,
                "lng": -123.3808373947
            },
            {
                "lat": 48.7766039811,
                "lng": -123.386665801
            },
            {
                "lat": 48.8117407007,
                "lng": -123.4442058145
            },
            {
                "lat": 48.8321645785,
                "lng": -123.4795685359
            },
            {
                "lat": 48.8501740685,
                "lng": -123.5037237716
            },
            {
                "lat": 48.8587373869,
                "lng": -123.5039447184
            },
            {
                "lat": 48.865108793,
                "lng": -123.4988932139
            },
            {
                "lat": 48.8712579643,
                "lng": -123.4863748565
            },
            {
                "lat": 48.8708191256,
                "lng": -123.4793467232
            },
            {
                "lat": 48.8706968164,
                "lng": -123.4789123649
            },
            {
                "lat": 48.8747899082,
                "lng": -123.4767304991
            },
            {
                "lat": 48.8862870508,
                "lng": -123.4980475438
            },
            {
                "lat": 48.9059719095,
                "lng": -123.5299535541
            },
            {
                "lat": 48.9157482541,
                "lng": -123.5466756468
            },
            {
                "lat": 48.9374923284,
                "lng": -123.5836036663
            },
            {
                "lat": 48.9426381,
                "lng": -123.5917740477
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 692,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 836,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 125168991.9914,
            "FEATURE_LENGTH_M": 76410.5603,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.8317549949,
            "lng": -123.51311095328514
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.9510501869,
                "lng": -125.0618825891
            },
            {
                "lat": 48.9532854009,
                "lng": -125.0684974441
            },
            {
                "lat": 48.9590269457,
                "lng": -125.0820398924
            },
            {
                "lat": 48.9549592378,
                "lng": -125.1528625472
            },
            {
                "lat": 48.866226576,
                "lng": -125.172515513
            },
            {
                "lat": 48.8708959441,
                "lng": -125.1641159375
            },
            {
                "lat": 48.87585857,
                "lng": -125.1622848046
            },
            {
                "lat": 48.875865596,
                "lng": -125.1618881801
            },
            {
                "lat": 48.8758469194,
                "lng": -125.1615515755
            },
            {
                "lat": 48.8758655504,
                "lng": -125.1610560437
            },
            {
                "lat": 48.8760682754,
                "lng": -125.1601709211
            },
            {
                "lat": 48.8764953133,
                "lng": -125.1592868688
            },
            {
                "lat": 48.8773230715,
                "lng": -125.1582560537
            },
            {
                "lat": 48.8802755656,
                "lng": -125.1501309879
            },
            {
                "lat": 48.8803522474,
                "lng": -125.1489791262
            },
            {
                "lat": 48.8803710345,
                "lng": -125.1462847091
            },
            {
                "lat": 48.8806455404,
                "lng": -125.1445012288
            },
            {
                "lat": 48.8814499933,
                "lng": -125.1427914183
            },
            {
                "lat": 48.8825303115,
                "lng": -125.1408467234
            },
            {
                "lat": 48.88369002,
                "lng": -125.1396102132
            },
            {
                "lat": 48.8843801448,
                "lng": -125.1388469368
            },
            {
                "lat": 48.8931429387,
                "lng": -125.114745582
            },
            {
                "lat": 48.8934829038,
                "lng": -125.1113820857
            },
            {
                "lat": 48.8974339435,
                "lng": -125.1085438989
            },
            {
                "lat": 48.9293513537,
                "lng": -125.0856548869
            },
            {
                "lat": 48.9510501869,
                "lng": -125.0618825891
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 693,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 23,
            "MANAGEMENT_SUBAREA": 5,
            "MANAGEMENT_AREA_NAME": "Subarea 23-5",
            "LABEL": "23-5",
            "FISHERY_AREA_ID": 2247,
            "OBJECTID": 837,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 42269432.7764,
            "FEATURE_LENGTH_M": 29973.6107,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.89642014505769,
            "lng": -125.13310033681152
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.8974339435,
                "lng": -125.1085438989
            },
            {
                "lat": 48.9033314505,
                "lng": -125.0928498915
            },
            {
                "lat": 48.9249230135,
                "lng": -125.064796641
            },
            {
                "lat": 48.9457391827,
                "lng": -125.0577165111
            },
            {
                "lat": 48.9510501869,
                "lng": -125.0618825891
            },
            {
                "lat": 48.9293513537,
                "lng": -125.0856548869
            },
            {
                "lat": 48.8974339435,
                "lng": -125.1085438989
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 694,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 838,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 6612793.3388,
            "FEATURE_LENGTH_M": 14416.8077,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.92132329632857,
            "lng": -125.08285547391428
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.8976095593,
                "lng": -123.585861008
            },
            {
                "lat": 48.897731875,
                "lng": -123.5879890285
            },
            {
                "lat": 48.8989717089,
                "lng": -123.5916062037
            },
            {
                "lat": 48.8993792881,
                "lng": -123.5926515727
            },
            {
                "lat": 48.9140009139,
                "lng": -123.6268623857
            },
            {
                "lat": 48.9296226189,
                "lng": -123.7060468629
            },
            {
                "lat": 48.9285958485,
                "lng": -123.7056268199
            },
            {
                "lat": 48.9276041314,
                "lng": -123.7054368693
            },
            {
                "lat": 48.925445746,
                "lng": -123.7046054192
            },
            {
                "lat": 48.9220963378,
                "lng": -123.7036126876
            },
            {
                "lat": 48.919201182,
                "lng": -123.7028499847
            },
            {
                "lat": 48.9167249436,
                "lng": -123.7020480496
            },
            {
                "lat": 48.9149743159,
                "lng": -123.7016296997
            },
            {
                "lat": 48.9094014808,
                "lng": -123.708098188
            },
            {
                "lat": 48.8873709758,
                "lng": -123.6920781173
            },
            {
                "lat": 48.874355811,
                "lng": -123.6670467578
            },
            {
                "lat": 48.8593370057,
                "lng": -123.6450197707
            },
            {
                "lat": 48.8436511478,
                "lng": -123.6370088005
            },
            {
                "lat": 48.8318139498,
                "lng": -123.6343381883
            },
            {
                "lat": 48.8472938014,
                "lng": -123.594863868
            },
            {
                "lat": 48.8475113955,
                "lng": -123.5921710593
            },
            {
                "lat": 48.8481179664,
                "lng": -123.5869517893
            },
            {
                "lat": 48.8491284711,
                "lng": -123.5782163983
            },
            {
                "lat": 48.8499981638,
                "lng": -123.5707011807
            },
            {
                "lat": 48.8501739073,
                "lng": -123.5704419218
            },
            {
                "lat": 48.8502088987,
                "lng": -123.5692064008
            },
            {
                "lat": 48.8500598293,
                "lng": -123.5675056708
            },
            {
                "lat": 48.8467221246,
                "lng": -123.5638352092
            },
            {
                "lat": 48.8474963026,
                "lng": -123.556694283
            },
            {
                "lat": 48.8549306039,
                "lng": -123.5506222788
            },
            {
                "lat": 48.86183863,
                "lng": -123.5445554951
            },
            {
                "lat": 48.8588026327,
                "lng": -123.5297932208
            },
            {
                "lat": 48.8630949161,
                "lng": -123.5283276155
            },
            {
                "lat": 48.8704219314,
                "lng": -123.5492636622
            },
            {
                "lat": 48.878795889,
                "lng": -123.5636064999
            },
            {
                "lat": 48.8885305841,
                "lng": -123.5719760514
            },
            {
                "lat": 48.8976095593,
                "lng": -123.585861008
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 695,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 9,
            "MANAGEMENT_AREA_NAME": "Subarea 17-9",
            "LABEL": "17-9",
            "FISHERY_AREA_ID": 2200,
            "OBJECTID": 839,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 64977363.3509,
            "FEATURE_LENGTH_M": 38267.312,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.879962822902684,
            "lng": -123.61554081154058
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.8717724114,
                "lng": -123.3921736537
            },
            {
                "lat": 48.8737224562,
                "lng": -123.3953937382
            },
            {
                "lat": 48.8746373805,
                "lng": -123.3960869117
            },
            {
                "lat": 48.8749773428,
                "lng": -123.3965754092
            },
            {
                "lat": 48.8755151316,
                "lng": -123.3972317002
            },
            {
                "lat": 48.8760331771,
                "lng": -123.3974004144
            },
            {
                "lat": 48.8765606857,
                "lng": -123.3975532814
            },
            {
                "lat": 48.8772008314,
                "lng": -123.3973474119
            },
            {
                "lat": 48.8776666999,
                "lng": -123.3972006092
            },
            {
                "lat": 48.8904071304,
                "lng": -123.4229044458
            },
            {
                "lat": 48.8909447725,
                "lng": -123.4235152657
            },
            {
                "lat": 48.8925968209,
                "lng": -123.4252553792
            },
            {
                "lat": 48.8930810806,
                "lng": -123.426223486
            },
            {
                "lat": 48.8931196372,
                "lng": -123.4266437948
            },
            {
                "lat": 48.8930623883,
                "lng": -123.4275888917
            },
            {
                "lat": 48.8932265632,
                "lng": -123.4285967648
            },
            {
                "lat": 48.8948972153,
                "lng": -123.4323359141
            },
            {
                "lat": 48.8991544232,
                "lng": -123.4418874983
            },
            {
                "lat": 48.8997458132,
                "lng": -123.4422078021
            },
            {
                "lat": 48.9002684929,
                "lng": -123.4429168816
            },
            {
                "lat": 48.9009707453,
                "lng": -123.4438938539
            },
            {
                "lat": 48.901362861,
                "lng": -123.4448315735
            },
            {
                "lat": 48.9029940201,
                "lng": -123.4486643693
            },
            {
                "lat": 48.9039996935,
                "lng": -123.4505841348
            },
            {
                "lat": 48.8780857806,
                "lng": -123.4749675689
            },
            {
                "lat": 48.8747899082,
                "lng": -123.4767304991
            },
            {
                "lat": 48.8706968164,
                "lng": -123.4789123649
            },
            {
                "lat": 48.8666455495,
                "lng": -123.4644086399
            },
            {
                "lat": 48.8615954471,
                "lng": -123.4501336305
            },
            {
                "lat": 48.860362721,
                "lng": -123.4473428705
            },
            {
                "lat": 48.8582995833,
                "lng": -123.442672533
            },
            {
                "lat": 48.8536873107,
                "lng": -123.434547621
            },
            {
                "lat": 48.8495137415,
                "lng": -123.4264137823
            },
            {
                "lat": 48.8469585405,
                "lng": -123.4231950763
            },
            {
                "lat": 48.8438945267,
                "lng": -123.4207074834
            },
            {
                "lat": 48.8428723209,
                "lng": -123.4199283164
            },
            {
                "lat": 48.842483473,
                "lng": -123.4194648016
            },
            {
                "lat": 48.8717724114,
                "lng": -123.3921736537
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 696,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 17,
            "MANAGEMENT_SUBAREA": 1,
            "MANAGEMENT_AREA_NAME": "Subarea 17-1",
            "LABEL": "17-1",
            "FISHERY_AREA_ID": 2192,
            "OBJECTID": 840,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 21363473.9361,
            "FEATURE_LENGTH_M": 19324.3679,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.87762041855264,
            "lng": -123.42806873755265
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.866226576,
                "lng": -125.172515513
            },
            {
                "lat": 48.9256938302,
                "lng": -125.2221669761
            },
            {
                "lat": 48.8671834017,
                "lng": -125.2897116306
            },
            {
                "lat": 48.8317674084,
                "lng": -125.325065772
            },
            {
                "lat": 48.7864725879,
                "lng": -125.2155308829
            },
            {
                "lat": 48.7864726394,
                "lng": -125.2119828078
            },
            {
                "lat": 48.7869151575,
                "lng": -125.2055670374
            },
            {
                "lat": 48.7883412153,
                "lng": -125.201279593
            },
            {
                "lat": 48.7947650035,
                "lng": -125.1998519961
            },
            {
                "lat": 48.7942316672,
                "lng": -125.1968220446
            },
            {
                "lat": 48.7942318488,
                "lng": -125.1884302541
            },
            {
                "lat": 48.7974434874,
                "lng": -125.1818323828
            },
            {
                "lat": 48.8015437181,
                "lng": -125.1775507203
            },
            {
                "lat": 48.8076135776,
                "lng": -125.176125681
            },
            {
                "lat": 48.8093983168,
                "lng": -125.1704101812
            },
            {
                "lat": 48.8085066047,
                "lng": -125.1595302531
            },
            {
                "lat": 48.8126067803,
                "lng": -125.1466822392
            },
            {
                "lat": 48.8179628894,
                "lng": -125.1359774638
            },
            {
                "lat": 48.8254473358,
                "lng": -125.1282580204
            },
            {
                "lat": 48.8252027416,
                "lng": -125.1219644991
            },
            {
                "lat": 48.8270993572,
                "lng": -125.1104746799
            },
            {
                "lat": 48.8309478621,
                "lng": -125.106437026
            },
            {
                "lat": 48.8371238504,
                "lng": -125.1020973844
            },
            {
                "lat": 48.8401797161,
                "lng": -125.1039875359
            },
            {
                "lat": 48.8414650353,
                "lng": -125.1131591502
            },
            {
                "lat": 48.8409725267,
                "lng": -125.1190877654
            },
            {
                "lat": 48.8390201334,
                "lng": -125.1244058597
            },
            {
                "lat": 48.8390202379,
                "lng": -125.1291737355
            },
            {
                "lat": 48.8411900764,
                "lng": -125.1324765258
            },
            {
                "lat": 48.8417059321,
                "lng": -125.133477062
            },
            {
                "lat": 48.8510634217,
                "lng": -125.148376654
            },
            {
                "lat": 48.866226576,
                "lng": -125.172515513
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 697,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 23,
            "MANAGEMENT_SUBAREA": 7,
            "MANAGEMENT_AREA_NAME": "Subarea 23-7",
            "LABEL": "23-7",
            "FISHERY_AREA_ID": 2249,
            "OBJECTID": 841,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 117122277.7931,
            "FEATURE_LENGTH_M": 48034.044,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.825751297278124,
            "lng": -125.16634140125937
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.8697049717,
                "lng": -125.4168007334
            },
            {
                "lat": 48.9211384326,
                "lng": -125.5411603005
            },
            {
                "lat": 48.7999988656,
                "lng": -125.483330383
            },
            {
                "lat": 48.7499997833,
                "lng": -125.3166658268
            },
            {
                "lat": 48.7864725879,
                "lng": -125.2155308829
            },
            {
                "lat": 48.8317674084,
                "lng": -125.325065772
            },
            {
                "lat": 48.8697049717,
                "lng": -125.4168007334
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 698,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 123,
            "MANAGEMENT_SUBAREA": 3,
            "MANAGEMENT_AREA_NAME": "Subarea 123-3",
            "LABEL": "123-3",
            "FISHERY_AREA_ID": 2318,
            "OBJECTID": 842,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 174694866.5195,
            "FEATURE_LENGTH_M": 64270.0634,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.83268386017143,
            "lng": -125.38790780457144
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.8738364536,
                "lng": -123.2913887912
            },
            {
                "lat": 48.8797759694,
                "lng": -123.3120801758
            },
            {
                "lat": 48.8801917426,
                "lng": -123.3144387621
            },
            {
                "lat": 48.879874876,
                "lng": -123.3184895567
            },
            {
                "lat": 48.8790820673,
                "lng": -123.3190152035
            },
            {
                "lat": 48.8764571346,
                "lng": -123.3207696185
            },
            {
                "lat": 48.8718340486,
                "lng": -123.3210835855
            },
            {
                "lat": 48.8683511097,
                "lng": -123.3227317421
            },
            {
                "lat": 48.8684802584,
                "lng": -123.3309629382
            },
            {
                "lat": 48.8712650247,
                "lng": -123.3384932565
            },
            {
                "lat": 48.8725315966,
                "lng": -123.3465344749
            },
            {
                "lat": 48.8723150454,
                "lng": -123.3485337521
            },
            {
                "lat": 48.8685384948,
                "lng": -123.3515312531
            },
            {
                "lat": 48.8665582789,
                "lng": -123.358321952
            },
            {
                "lat": 48.8674196003,
                "lng": -123.3681272304
            },
            {
                "lat": 48.8687933256,
                "lng": -123.3778377236
            },
            {
                "lat": 48.871460573,
                "lng": -123.3828199589
            },
            {
                "lat": 48.8702545291,
                "lng": -123.3865206237
            },
            {
                "lat": 48.8711169289,
                "lng": -123.3909076965
            },
            {
                "lat": 48.8714290579,
                "lng": -123.3916394394
            },
            {
                "lat": 48.8715932764,
                "lng": -123.3919299461
            },
            {
                "lat": 48.8717724114,
                "lng": -123.3921736537
            },
            {
                "lat": 48.842483473,
                "lng": -123.4194648016
            },
            {
                "lat": 48.8433496269,
                "lng": -123.4083332094
            },
            {
                "lat": 48.8426361361,
                "lng": -123.4068293968
            },
            {
                "lat": 48.8412133987,
                "lng": -123.4043355166
            },
            {
                "lat": 48.8398477762,
                "lng": -123.4017263225
            },
            {
                "lat": 48.8384824472,
                "lng": -123.3993520513
            },
            {
                "lat": 48.834384458,
                "lng": -123.3948977046
            },
            {
                "lat": 48.8372961199,
                "lng": -123.3925853071
            },
            {
                "lat": 48.8386801423,
                "lng": -123.3915861232
            },
            {
                "lat": 48.8400225537,
                "lng": -123.3906249278
            },
            {
                "lat": 48.8407978902,
                "lng": -123.3878934097
            },
            {
                "lat": 48.8383030697,
                "lng": -123.3832005611
            },
            {
                "lat": 48.8358117854,
                "lng": -123.378936731
            },
            {
                "lat": 48.8358117122,
                "lng": -123.3774412878
            },
            {
                "lat": 48.8356323879,
                "lng": -123.3747717768
            },
            {
                "lat": 48.8341481061,
                "lng": -123.370850198
            },
            {
                "lat": 48.8320085758,
                "lng": -123.3665165693
            },
            {
                "lat": 48.8304484675,
                "lng": -123.3620690544
            },
            {
                "lat": 48.8296358082,
                "lng": -123.3597485774
            },
            {
                "lat": 48.829723394,
                "lng": -123.3594748685
            },
            {
                "lat": 48.8301692318,
                "lng": -123.3580854488
            },
            {
                "lat": 48.8303483892,
                "lng": -123.356185397
            },
            {
                "lat": 48.8295742539,
                "lng": -123.3547661285
            },
            {
                "lat": 48.8286290841,
                "lng": -123.3535147649
            },
            {
                "lat": 48.8279153922,
                "lng": -123.3525701678
            },
            {
                "lat": 48.8275953365,
                "lng": -123.3518380949
            },
            {
                "lat": 48.8334997125,
                "lng": -123.3279181812
            },
            {
                "lat": 48.8333472045,
                "lng": -123.3275747174
            },
            {
                "lat": 48.8333239998,
                "lng": -123.3274688615
            },
            {
                "lat": 48.8331145479,
                "lng": -123.3265604302
            },
            {
                "lat": 48.8329583801,
                "lng": -123.3245856844
            },
            {
                "lat": 48.8331411107,
                "lng": -123.3221662696
            },
            {
                "lat": 48.8328514646,
                "lng": -123.3202740217
            },
            {
                "lat": 48.8328279449,
                "lng": -123.3191678533
            },
            {
                "lat": 48.8330388398,
                "lng": -123.3177182235
            },
            {
                "lat": 48.8336674985,
                "lng": -123.3160932366
            },
            {
                "lat": 48.8355940134,
                "lng": -123.3145282198
            },
            {
                "lat": 48.8411635046,
                "lng": -123.3171237144
            },
            {
                "lat": 48.8461612208,
                "lng": -123.3133165693
            },
            {
                "lat": 48.8466372264,
                "lng": -123.3052297288
            },
            {
                "lat": 48.8492543378,
                "lng": -123.2964251799
            },
            {
                "lat": 48.8542483965,
                "lng": -123.2911907167
            },
            {
                "lat": 48.8635290406,
                "lng": -123.2914266883
            },
            {
                "lat": 48.8687630791,
                "lng": -123.2902368272
            },
            {
                "lat": 48.8701939365,
                "lng": -123.2877890454
            },
            {
                "lat": 48.8728107823,
                "lng": -123.2899327098
            },
            {
                "lat": 48.8736002034,
                "lng": -123.2909784748
            },
            {
                "lat": 48.8738364536,
                "lng": -123.2913887912
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 699,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 18,
            "MANAGEMENT_SUBAREA": 2,
            "MANAGEMENT_AREA_NAME": "Subarea 18-2",
            "LABEL": "18-2",
            "FISHERY_AREA_ID": 2214,
            "OBJECTID": 843,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 32754360.3199,
            "FEATURE_LENGTH_M": 27950.9631,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.85050633883143,
            "lng": -123.34775748394719
        }
    },
    {
        "type": "Polygon",
        "coordinates": [
            {
                "lat": 48.8482250106,
                "lng": -123.251853566
            },
            {
                "lat": 48.8497316896,
                "lng": -123.260429645
            },
            {
                "lat": 48.8552018028,
                "lng": -123.2720874024
            },
            {
                "lat": 48.8606758953,
                "lng": -123.2768481645
            },
            {
                "lat": 48.8644824167,
                "lng": -123.2806547383
            },
            {
                "lat": 48.8701939365,
                "lng": -123.2877890454
            },
            {
                "lat": 48.8687630791,
                "lng": -123.2902368272
            },
            {
                "lat": 48.8635290406,
                "lng": -123.2914266883
            },
            {
                "lat": 48.8542483965,
                "lng": -123.2911907167
            },
            {
                "lat": 48.8492543378,
                "lng": -123.2964251799
            },
            {
                "lat": 48.8466372264,
                "lng": -123.3052297288
            },
            {
                "lat": 48.8461612208,
                "lng": -123.3133165693
            },
            {
                "lat": 48.8411635046,
                "lng": -123.3171237144
            },
            {
                "lat": 48.8355940134,
                "lng": -123.3145282198
            },
            {
                "lat": 48.8362508215,
                "lng": -123.3103187874
            },
            {
                "lat": 48.8331914058,
                "lng": -123.2992098461
            },
            {
                "lat": 48.8327174671,
                "lng": -123.2863619513
            },
            {
                "lat": 48.831291318,
                "lng": -123.2725608011
            },
            {
                "lat": 48.8336674699,
                "lng": -123.2606662979
            },
            {
                "lat": 48.8393787591,
                "lng": -123.2559049215
            },
            {
                "lat": 48.8447001741,
                "lng": -123.2563011043
            },
            {
                "lat": 48.8458061049,
                "lng": -123.2563860804
            },
            {
                "lat": 48.8482250106,
                "lng": -123.251853566
            }
        ],
        "properties": {
            "DFO_PFMA_SUB_ID": 700,
            "FEATURE_CODE": " ",
            "MANAGEMENT_AREA": 0,
            "MANAGEMENT_SUBAREA": 0,
            "MANAGEMENT_AREA_NAME": " ",
            "LABEL": " ",
            "FISHERY_AREA_ID": 0,
            "OBJECTID": 844,
            "SE_ANNO_CAD_DATA": null,
            "FEATURE_AREA_SQM": 10129887.3844,
            "FEATURE_LENGTH_M": 14797.9497,
            "GEOMETRY.AREA": 0,
            "GEOMETRY.LEN": 0,
            "fme_feature_type": "WHSE_ADMIN_BOUNDARIES.DFO_PFMA_SUBAREAS_SP"
        },
        "centerPoint": {
            "lat": 48.84778652616086,
            "lng": -123.28255232878261
        }
    }
]