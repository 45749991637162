import React, { useState } from 'react';
import { FaFish } from "react-icons/fa";
import { TbFishOff } from "react-icons/tb";
import { FaBrain } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'

const FishingLayerCheckBox = ({ fishingCheckboxStatus, handleSettingsCheckBoxChange }) => {
    
    const [toolTipText, setToolTipText] = useState('');

    const fireIcon = require('../../images/fishing.png');
    const iconSize = 28;
    const iconColor = 'white';

    const layerOptions = [
        {
            layer: 'fishingAreas',
            icon: <FaFish size={iconSize} color={iconColor} />,
            toolTip: 'Fishing Areas'
        },
        {
            layer: 'RCA',
            icon: <TbFishOff size={iconSize} color={iconColor} />,
            toolTip: 'Rockfish Closure'
        },
        {
            layer: 'spongeReef',
            icon: <img src={require('../../images/reef.png')} alt='reef' style={{height: 28}} />,
            toolTip: 'Sponge Reef Closure'
        },
        {
            layer: 'hotSpots',
            icon: <img src={require('../../images/fishing.png')} alt='reef' style={{height: 28}} />,
            toolTip: 'Popular Areas'
        },
        {
            layer: 'activeHotSpots',
            icon: <svg width="20px" height="30px" viewBox="12 10 80 80" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="20" fill="#856ea3" />
                <circle cx="50" cy="50" r="40" fill="rgba(133, 110, 163, .5)" />
            </svg>,
            toolTip: 'Hot Right Now'
        },
    ]

    return (

        <div className="checkbox-container">
            <Tooltip anchorSelect=".my-anchor-element" place="top">
                {toolTipText}
            </Tooltip>
            {layerOptions.map((layerOption, index) => {
                return (
                    <label
                        onMouseOver={() => setToolTipText(layerOption.toolTip)}
                        key={index}
                        // onMouseOut={handleHoverOut}
                        className={`my-anchor-element map-checkbox-item ${fishingCheckboxStatus[layerOption.layer] === 'on' ? 'selected-map-checkbox' : ''}`}
                    >
                        {layerOption.icon}
                        <input
                            name={layerOption.layer}
                            type="checkbox"
                            className="toggle-checkbox"
                            checked={fishingCheckboxStatus[layerOption.layer] === 'on'}
                            onChange={handleSettingsCheckBoxChange}
                        />
                        {/* {hoveredCheckbox === 'webcams' && <span className='icon-description'>Webcams</span>} */}
                    </label>
                )
            }
            )}
        </div>

    )

}

export default FishingLayerCheckBox;