import React from 'react';
import { IoIosArrowUp } from 'react-icons/io';

const WeatherMapUnits = ({ setShowSettingsDropdown, showSettingsDropdown, weatherUnits, handleUnitChange }) => {

    return (
      <div className='weather-map-units-dropdown'>
        <IoIosArrowUp onClick={() => setShowSettingsDropdown(!showSettingsDropdown)} size={18} className='units-hide-arrow' />
        <div className='weather-map-units-container'>
          <h4>Units</h4>
          <div>
            <input
              type='radio'
              id='feet'
              name='waveHeight'
              value='feet'
              checked={weatherUnits.waveHeight === 'feet'}
              onChange={handleUnitChange}
              />
            <label htmlFor='feet'>Feet</label>
            <input
              type='radio'
              id='meters'
              name='waveHeight'
              value='meters'
              checked={weatherUnits.waveHeight === 'meters'}
              onChange={handleUnitChange}
            />
            <label htmlFor='meters'>Meters</label>
          </div>
          <div>
            <input
              type='radio'
              id='miles'
              name='windSpeed'
              value='miles'
              checked={weatherUnits.windSpeed === 'miles'}
              onChange={handleUnitChange}
            />
            <label htmlFor='miles'>Miles</label>
            <input
              type='radio'
              id='km'
              name='windSpeed'
              value='km'
              checked={weatherUnits.windSpeed === 'km'}
              onChange={handleUnitChange}
            />
            <label htmlFor='km'>KM</label>
            <input
              type='radio'
              id='knots'
              name='windSpeed'
              value='knots'
              checked={weatherUnits.windSpeed === 'knots'}
              onChange={handleUnitChange}
            />
            <label htmlFor='knots'>Knots</label>
          </div>
        </div>
      </div>
    )
}

export default WeatherMapUnits;