import { RotatingLines } from 'react-loader-spinner'
import ModalSmall from '../global/modalSmall'
import SimpleCloseBtn from '../global/simpleCloseBtn'
import BigButton from '../global/bigButton'
import { useState } from 'react'

const PhotoPreview = ({ handleSubmit, showLoader, setPreviewImage, previewImage }) => {

    return (

        <ModalSmall className='photo-upload-modal'>
          {showLoader && 
          <div className='loading-spinner'>
            <RotatingLines
                visible={true}
                strokeColor='grey'
                height="36"
                width="36"
                strokeWidth="3"
                animationDuration="0.75"
            />
          </div>
          }
          <SimpleCloseBtn onClick={() => setPreviewImage(null)} />
          <div className='photo-upload-modal-content'>
            <div className='photo-upload-modal-header'>
              <h2>Upload Photo</h2>
            </div>
            <div className='photo-upload-modal-body'>
              <p>Upload a photo of the current conditions</p>
              <div className='weather-image-container'>
                <img src={previewImage} alt="Preview" style={{ maxWidth: '100%', maxHeight: '175px' }} />
              </div>
            </div>
          </div>
          <div className='photo-upload-modal-btns'>
            <BigButton text={'Upload'} onClick={handleSubmit} />
          </div>
        </ModalSmall>
        )
    }

    export default PhotoPreview;