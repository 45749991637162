

const Footer = () => (
    
    <div className='footer'>
        <p>Copyright &copy; 2024 SportyApp.ca</p>
        <p><a href='https://buymeacoffee.com/sportyapp' target='blank'>Support This Project</a></p>
    </div>

)

export default Footer;