import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { transformTimeHours } from '../../functions/transformTime';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(...registerables);


const WeatherLineChartWind = ({ weatherData }) => {

    const validWindSpeeds = weatherData.windSpeed.filter(speed => !isNaN(speed) && speed !== undefined);
    let validGustSpeeds;
    let maxGustSpeed;
    if(weatherData.gustSpeed) {
        validGustSpeeds = weatherData.gustSpeed ? weatherData.gustSpeed.filter(speed => !isNaN(speed) && speed !== undefined) : null;
        maxGustSpeed = Math.max(...validGustSpeeds);

    }
    const maxWindSpeed = Math.max(...validWindSpeeds);
    const windSpeedArray = weatherData.windSpeed;
    const gustSpeedArray = weatherData.gustSpeed ? weatherData.gustSpeed : null;

    const data = {
        labels: weatherData.updateTime.map((time) => transformTimeHours(time)),
        datasets: weatherData.gustSpeed ? 
        [
            {
            label: 'Wind Speed',
            data: windSpeedArray,
            fill: true,
            tension: 0.4,
            backgroundColor: 'rgba(255,165,0,0)',
            borderColor: 'rgba(255,255,255,.6)',
            },
            {
            label: 'Gust Speed',
            data: gustSpeedArray,
            fill: true,
            tension: 0.4,
            backgroundColor: 'rgba(255,165,0,0)',
            borderColor: 'rgba(64,70,128,1)',
            },
        ]:
        [
          {
            label: 'Wind Speed',
            data: windSpeedArray,
            fill: true,
            tension: 0.4,
            backgroundColor: 'rgba(255,165,0,0)',
            borderColor: 'orange',
          },
        ]
      };

    return (

        <div className='weather-div-container'>
            <Line className='wind-table-wind-line-chart line-chart' data={data} plugins={[ChartDataLabels]} options={{
                scales: {
                    x: {
                        grid: {
                            display: false // Disable x-axis grid lines
                        },
                        ticks: {
                            color: 'white' // Set x-axis font color
                        },
                        reverse: true,
                    },
                    y: {
                        beginAtZero: true,
                        suggestedMax: validGustSpeeds ? maxGustSpeed + 2 : maxWindSpeed + 2,
                        grid: {
                            display: true, // Disable y-axis grid lines
                            color: 'rgba(255,255,255,0.1)' // Set grid line color
                        },
                        ticks: {
                            color: 'white' // Set x-axis font color
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: true
                    }
                },
                maintainAspectRatio: false, // Disable aspect ratio
                responsive: true, // Make the chart responsive
                plugins: {
                    datalabels: {
                        color: 'white', // Set color of data labels
                        anchor: 'end', // Position of the anchor point (start, center, end)
                        align: 'end', // Alignment of the text relative to the anchor point (start, center, end, auto)
                        offset: -2, // Offset of the label from the anchor point
                    }
                }
            }} />
        </div>
    )
}

export default WeatherLineChartWind;