import { transformTime } from "../../functions/transformTime";
import WindDirectionIndicator from "../global/windArrow";
import { IoIosArrowRoundUp } from "react-icons/io";
import { IoIosArrowRoundDown } from "react-icons/io";

const WeatherTableWaveTable = ({ weatherTableData }) => {

    // console.log('weatherTableData: ', weatherTableData);

            // Function to render the content of a td element based on the data value
            const renderTableCellContent = (value1, value2, unit) => {

                if(value1 === 'N/A' || value1 === undefined) {
                    return '-';
                }
        
                if (value1 > value2) {
                    return (
                        <>
                            {`${value1} ${unit} `}
                            {<IoIosArrowRoundUp color={'orange'} />}
                        </>
                    );
                } else {
                    return (
                        <>
                            {`${value1} ${unit} `}
                            {<IoIosArrowRoundDown color={'rgb(0, 211, 0)'} />}
                        </>
                    );
                }
            };

    // SHOW PEAK WIND IN LAST FEW HOURS??

    return (
        <div className="weather-div-container">
           <table className='weather-table'>
                <thead>
                    <tr>
                            <th>Wave Height</th>
                            <th>Wave Period</th>
                            <th>Wind Speed</th>
                            <th>Gust Speed</th>
                            <th>Wind Direction</th>
                            <th>Air Temp</th>
                            <th>Water Temp</th>
                            <th>Barometer</th>
                            <th>Update Time</th>
                    </tr>
                </thead>
                <tbody>
                {weatherTableData.waveHeight.map((waveHeight, index) => (
                    <tr key={index}>
                    <td>{waveHeight}</td>
                    <td>{weatherTableData.wavePeriod[index]}</td>
                    <td>{weatherTableData.windSpeed[index]}</td>
                    <td>{weatherTableData.gustSpeed[index]}</td>
                    <td><WindDirectionIndicator direction={weatherTableData.windDirection[index]} /></td>
                    <td>{weatherTableData.airTemp[index]}</td>
                    <td>{weatherTableData.waterTemp[index]}</td>
                    <td>{weatherTableData.barometer[index]}</td>
                    <td>{transformTime(weatherTableData.updateTime[index])}</td>
                    </tr>
                ))}
                {/* {Object.values(weatherTableData).map((data, index) => {

                    return (
                        <tr 
                            key={index}
                        >
                            <td>{data.name}</td>
                            <td>{renderTableCellContent(data.waveHeight[0], data.waveHeight[1], 'ft')}</td>
                            <td>{renderTableCellContent(data.wavePeriod[0], data.wavePeriod[1], 's')}</td>
                            <td>{renderTableCellContent(data.windSpeed[0], null, 'kn')}</td>
                            <td>{data.windDirection[0]}</td>
                            <td>{renderTableCellContent(data.gustSpeed[0], null, 'kn')}</td>
                            <td>{renderTableCellContent(data.airTemp[0], null, 'C')}</td>
                            <td>{renderTableCellContent(data.waterTemp[0], null, 'C')}</td>
                            <td>{data.barometer[0]}</td>
                            <td>{transformTime(data.updateTime[0])}</td>
                        </tr>
                    );
                    })} */}
                </tbody>
            </table>
        </div>
    );
}

export default WeatherTableWaveTable;