import '../../App.css';
import SimpleCloseBtn from './simpleCloseBtn';


const ModalLarge = ({ children, onClick, showCloseBtn }) => {

    return (
        <div className="modal-wrapper-large" >
            {!showCloseBtn && <SimpleCloseBtn onClick={onClick} />}
            <div className='modal-content'>
                {children}
            </div>
        </div>
    )

}

export default ModalLarge;