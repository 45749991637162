import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../../App.css'
import logoImage from '../../images/logos/transparentLogoWhite.png';
import { FaFish, FaWater } from "react-icons/fa";
import { TiWeatherStormy } from "react-icons/ti";
import { VscBook } from "react-icons/vsc";
import { BsQuestion } from "react-icons/bs";
import { GiFishingHook } from "react-icons/gi";
import { TbZoomInArea } from "react-icons/tb";
import { SlMagnifier } from "react-icons/sl";
import { MdInsights } from "react-icons/md";
import { HiOutlineLightBulb } from "react-icons/hi";
import { FiSettings } from "react-icons/fi";
import { IoPerson } from "react-icons/io5";
import { Outlet, Link, NavLink } from "react-router-dom";

const Sidebar = () => {

    const largeIconSize = 25;
    const smallIconSize = 20;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuItems, setMenuItems] = useState([
        {
            name: 'Weather Map',
            url: '/',
            image: <TiWeatherStormy color='white' size={largeIconSize} />
        },
        {
            name: 'Fishing Map',
            url: '/fishingMap',
            image: <FaFish color='white' size={largeIconSize} />
        },
        {
            name: 'Weather Table',
            url: '/weatherTable',
            image: <VscBook color='white' size={largeIconSize} />
        },
        {
            name: 'Weather Forecast',
            url: '/weatherForecast',
            image: <SlMagnifier color='white' size={largeIconSize} />
        },
        // {
        //     name: 'Regs Check',
        //     url: '/',
        //     image: <BsQuestion color='white' size={largeIconSize} />
        // },
        // {
        //     name: 'Catch Log',
        //     url: '/',
        //     image: <GiFishingHook color='white' size={largeIconSize} />
        // },
        {
            name: 'Tides',
            url: '/tides',
            image: <FaWater color='white' size={largeIconSize} />
        },
        {
            name: 'Area Status',
            url: '/areaStatus',
            image: <TbZoomInArea color='white' size={largeIconSize} />
        },
        {
            name: 'Area Map',
            url: '/fishingAreaMap',
            image: <TbZoomInArea color='white' size={largeIconSize} />
        },
        // {
        //     name: 'Insights',
        //     url: '/',
        //     image: <MdInsights color='white' size={largeIconSize} />
        // },
        // {
        //     name: 'Recommender',
        //     url: '/',
        //     image: <HiOutlineLightBulb color='white' size={largeIconSize} />
        // },
        // {
        //     name: 'Settings',
        //     url: '/',
        //     image: <FiSettings color='white' size={largeIconSize} />
        // },
        // {
        //     name: 'Account',
        //     url: '/',
        //     image: <IoPerson color='white' size={largeIconSize} />
        // }
    ]);

    // Function to open the menu
    const openMenu = () => {
        setIsMenuOpen(true);
    };

    // Function to close the menu
    const closeMenu = () => {
        setIsMenuOpen(false);
    };
    
    return (
        <Menu isOpen={isMenuOpen} onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)} customCrossIcon={false}>
            <div className='sidebar-menu-container'>
                <img src={logoImage} alt='logo' className='sidebar-logo' />
                {menuItems.map((item, index) => (
                        <NavLink
                            to={item.url}
                            className={({ isActive }) => (isActive ? 'menu-item-container-active' : 'menu-item-container-inactive')}
                        >
                            {item.name}
                        </NavLink>
                ))}
            </div>
        </Menu>
    );
    }

export default Sidebar;