import React, { useState, useRef } from 'react';
import SidebarPro from '../global/sidebar';
import BottomNav from '../global/bottomNav';
import Collapsible from 'react-collapsible';
import { TiWeatherWindyCloudy } from "react-icons/ti";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaGlobeAmericas,
  FaQuestion
} from 'react-icons/fa';
import { LuTable } from 'react-icons/lu';
import { FaFish, FaWater } from "react-icons/fa";
import { TiWeatherStormy } from "react-icons/ti";
import { TbZoomInArea } from "react-icons/tb";
import Footer from '../global/footer';

const HelpPage = () => {

    const color = 'white';
    const [mobile, setMobile] = useState(window.innerWidth < 600);

    const videoArray = [
        {
            title: 'Weather Map',
            url: require("../../videos/weather map.mp4"),
            image: <TiWeatherStormy className='title-icon' color={color} size={25} />
        },
        {
            title: 'Fishing Map',
            url: require("../../videos/fishing map vid.mp4"),
            image: <FaFish className='title-icon' color={color} size={25} />
        },
        {
            title: 'Weather Table',
            url: require("../../videos/weather table.mp4"),
            image: <LuTable className='title-icon' color={color} size={25} />
        },
        {
            title: 'Weather Forecast',
            url: require("../../videos/weather forecast.mp4"),
            image: <TiWeatherWindyCloudy className='title-icon' color={color} size={25} />
        },
        {
            title: 'Tides',
            url: require("../../videos/tide stations.mp4"),
            image: <FaWater className='title-icon' color={color} size={25} />
        },
        {
            title: 'Area Search',
            url: require("../../videos/area search.mp4"),
            image: <TbZoomInArea className='title-icon' color={color} size={25} />
        },
        {
            title: 'Area Map',
            url: require("../../videos/area map.mp4"),
            image: <FaGlobeAmericas className='title-icon' color={color} size={25} />
        },
    ];

    const vidRef = useRef(null);

    const playVideo = () => {
        vidRef.current.play();
    };

    return (
        <div className="help-page">
            {mobile ? <BottomNav /> : <SidebarPro />}
            <div className='help-page-container'>
                <p className='page-title'>
                    Page Tutorials
                </p>
                {videoArray.map((video, index) => (
                    <Collapsible
                        key={index}
                        className='collapsible-container'
                        openedClassName='open-collapsible-container'
                        trigger={<>{video.image} {video.title}</>}
                    >
                        <video className='video-container' ref={vidRef} src={video.url} controls />
                    </Collapsible>
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default HelpPage;
