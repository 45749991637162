const domettPoint = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
    { lat: 49.550833, lng: -123.323333 }, // 49°33.050'N 123°19.400'W
    { lat: 49.546667, lng: -123.331667 }, // 49°32.800'N 123°19.900'W
    { lat: 49.5575, lng: -123.338333 },   // 49°33.450'N 123°20.300'W
    { lat: 49.565, lng: -123.324167 },    // 49°33.900'N 123°19.450'W
    { lat: 49.561667, lng: -123.3075 },  // 49°33.700'N 123°18.450'W
    { lat: 49.555833, lng: -123.313333 } // 49°33.350'N 123°18.800'W
  ]}

const easternBurrardInlet = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
        { lat: 49.291950, lng: -123.004167 }, // 49°17.517'N 123°00.250'W
        { lat: 49.293333, lng: -123.004167 }, // 49°17.600'N 123°00.250'W
        { lat: 49.300000, lng: -122.987000 }, // 49°18.000'N 122°58.700'W
        { lat: 49.295000, lng: -122.950000 }, // 49°17.700'N 122°57.000'W
        { lat: 49.290833, lng: -122.950000 }  // 49°17.450'N 122°57.000'W          
    ],
    }

const howeSoundAlbertaBay = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
        { lat: 49.468633, lng: -123.250833 },
        { lat: 49.468833, lng: -123.244800 },
        { lat: 49.466033, lng: -123.243333 },
        { lat: 49.461067, lng: -123.242967 },
        { lat: 49.458667, lng: -123.244100 },
        { lat: 49.458717, lng: -123.250133 },
        { lat: 49.465067, lng: -123.252967 },
        { lat: 49.468633, lng: -123.250833 }
      ]
    }
    
const howeSoundAnvilIsland = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
        { lat: 49.547900, lng: -123.290417 },
        { lat: 49.547750, lng: -123.280250 },
        { lat: 49.542217, lng: -123.281150 },
        { lat: 49.542033, lng: -123.285300 },
        { lat: 49.542900, lng: -123.290717 },
        { lat: 49.547900, lng: -123.290417 }
      ]
    }      

const bowyerIsland = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.437161, lng: -123.2755739 },
      { lat: 49.4353749, lng: -123.2772905 },
      { lat: 49.4343702, lng: -123.2796938 },
      { lat: 49.4324724, lng: -123.2810671 },
      { lat: 49.4280066, lng: -123.2824404 },
      { lat: 49.4251037, lng: -123.2819254 },
      { lat: 49.4240988, lng: -123.2824404 },
      { lat: 49.4236521, lng: -123.2824404 },
      { lat: 49.4230938, lng: -123.2817537 },
      { lat: 49.4224797, lng: -123.2821829 },
      { lat: 49.4217539, lng: -123.2823545 },
      { lat: 49.4211956, lng: -123.2820971 },
      { lat: 49.4198556, lng: -123.2790072 },
      { lat: 49.418683, lng: -123.2773764 },
      { lat: 49.4182364, lng: -123.2772905 },
      { lat: 49.417343, lng: -123.2772905 },
      { lat: 49.4144952, lng: -123.2748873 },
      { lat: 49.4141602, lng: -123.2756598 },
      { lat: 49.4135459, lng: -123.2748014 },
      { lat: 49.4119265, lng: -123.2748014 },
      { lat: 49.4107538, lng: -123.274544 },
      { lat: 49.4099161, lng: -123.2735998 },
      { lat: 49.4096369, lng: -123.2725698 },
      { lat: 49.4079615, lng: -123.2676775 },
      { lat: 49.407794, lng: -123.2661325 },
      { lat: 49.4084641, lng: -123.2652742 },
      { lat: 49.4094135, lng: -123.2640726 },
      { lat: 49.411759, lng: -123.2634718 },
      { lat: 49.4122057, lng: -123.2626135 },
      { lat: 49.4130992, lng: -123.2633001 },
      { lat: 49.4182922, lng: -123.2587511 },
      { lat: 49.4189622, lng: -123.2576353 },
      { lat: 49.4196322, lng: -123.2561762 },
      { lat: 49.4205256, lng: -123.2559187 },
      { lat: 49.4215306, lng: -123.2558329 },
      { lat: 49.4225913, lng: -123.2568628 },
      { lat: 49.4234288, lng: -123.2570345 },
      { lat: 49.4244896, lng: -123.2566912 },
      { lat: 49.4252711, lng: -123.2566912 },
      { lat: 49.4261644, lng: -123.2575495 },
      { lat: 49.4265552, lng: -123.2586653 },
      { lat: 49.4282857, lng: -123.2581503 },
      { lat: 49.4334213, lng: -123.2621843 },
      { lat: 49.4342586, lng: -123.2645876 },
      { lat: 49.4345377, lng: -123.2664759 },
      { lat: 49.4353191, lng: -123.2687933 },
      { lat: 49.4379424, lng: -123.274029 },
      { lat: 49.4382633, lng: -123.2749731 },
      { lat: 49.4381238, lng: -123.2753379 },
      { lat: 49.4377331, lng: -123.275531 }
    ]
  }        

const pasleyIsland = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.390599, lng: -123.4398892 },
      { lat: 49.3766861, lng: -123.4463265 },
      { lat: 49.3476743, lng: -123.4538796 },
      { lat: 49.3492958, lng: -123.4563687 },
      { lat: 49.3490722, lng: -123.4581712 },
      { lat: 49.3493517, lng: -123.4616044 },
      { lat: 49.349184, lng: -123.4645226 },
      { lat: 49.348513, lng: -123.4650376 },
      { lat: 49.3583531, lng: -123.4864953 },
      { lat: 49.3610364, lng: -123.4915593 },
      { lat: 49.3628252, lng: -123.4921601 },
      { lat: 49.3665914, lng: -123.49882 },
      { lat: 49.3849366, lng: -123.4785132 },
      { lat: 49.3874229, lng: -123.4839205 }
    ]
  }    

const howeSoundBowyerIsland = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
        { lat: 49.412900, lng: -123.270317 },
        { lat: 49.413667, lng: -123.262717 },
        { lat: 49.401600, lng: -123.267383 },
        { lat: 49.405150, lng: -123.273717 },
        { lat: 49.412900, lng: -123.270317 }
      ]
    }

const howeSoundBrunswickPoint = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.476283, lng: -123.249417 },
      { lat: 49.473283, lng: -123.256283 },
      { lat: 49.469617, lng: -123.250517 },
      { lat: 49.473900, lng: -123.245533 },
      { lat: 49.476283, lng: -123.249417 },
    ]       
    }

const howeSoundCarmeloPoint = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.454217, lng: -123.389967 }, // 49° 27.253' N, 123° 23.398' W
      { lat: 49.454317, lng: -123.384467 }, // 49° 27.259' N, 123° 23.028' W
      { lat: 49.439833, lng: -123.388583 }, // 49° 26.390' N, 123° 23.315' W
      { lat: 49.434050, lng: -123.393500 }, // 49° 26.043' N, 123° 23.610' W
      { lat: 49.435600, lng: -123.399367 }, // 49° 26.136' N, 123° 24.003' W
      { lat: 49.454217, lng: -123.389967 }  // Back to the beginning point
    ]
    }

const howeSoundCollingwoodChannel = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.389117, lng: -123.410417 },
      { lat: 49.391717, lng: -123.410633 },
      { lat: 49.393367, lng: -123.414750 },
      { lat: 49.388000, lng: -123.422433 },
      { lat: 49.382450, lng: -123.427583 },
      { lat: 49.379983, lng: -123.421600 },
      { lat: 49.382017, lng: -123.419083 },
      { lat: 49.384467, lng: -123.417167 },
      { lat: 49.389117, lng: -123.410417 },
    ]    
    }      

const howeSoundDefenceIslands = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.566533, lng: -123.28985 }, // 49 33.992n, 123 17.391w
      { lat: 49.561783, lng: -123.286683 }, // 49 33.707n, 123 17.201w
      { lat: 49.5573, lng: -123.279167 }, // 49 33.438n, 123 16.750w
      { lat: 49.559217, lng: -123.274367 }, // 49 33.553n, 123 16.462w
      { lat: 49.562167, lng: -123.276033 }, // 49 33.730n, 123 16.562w
      { lat: 49.568367, lng: -123.2845 }, // 49 34.102, 123 17.070w
      { lat: 49.566533, lng: -123.28985 }, // 49 33.992n, 123 17.391w
    ]
    
    }      

const howeSoundDormanPoint = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.376283, lng: -123.322983 }, // 49 22.577n, 123 19.379w
      { lat: 49.372517, lng: -123.324233 }, // 49 22.351n, 123 19.454w
      { lat: 49.372117, lng: -123.318533 }, // 49 22.287n, 123 19.152w
      { lat: 49.375715, lng: -123.317517 }, // 49 22.543n, 123 19.051w
      { lat: 49.376283, lng: -123.322983 }, // 49 22.577n, 123 19.379w
    ]    
}      

const howeSoundEastDefenceIslands = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.57885, lng: -123.275917 }, // 49 34.731n, 123 16.555w
      { lat: 49.575583, lng: -123.27565 }, // 49 34.535n, 123 16.539w
      { lat: 49.576333, lng: -123.268067 }, // 49 34.580n, 123 16.084w
      { lat: 49.5808, lng: -123.268667 }, // 49 34.854n, 123 16.120w
      { lat: 49.5808, lng: -123.272617 }, // 49 34848n, 123 16.357w
      { lat: 49.57885, lng: -123.275917 }, // 49 34.731n, 123 16.555w
    ]    
}

const howeSoundHalkettPoint = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.450599, lng: -123.311433 }, // 49 27.036n, 123 18.686w
      { lat: 49.445699, lng: -123.316400 }, // 49 26.742n, 123 18.984w
      { lat: 49.444281, lng: -123.312932 }, // 49 26.657n, 123 18.776w
      { lat:49.444932, lng: -123.309632 }, // 49 26.696n, 123 18.578w
      { lat: 49.448283, lng: -123.307400 }, // 49 26.897n, 123 18.444w
      { lat: 49.450599, lng: -123.311433 }, // 49 27.036n, 123 18.686w
    ]
    }
    
const howeSoundLangdale = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.438050, lng: -123.460067 },
      { lat: 49.427233, lng: -123.471517 },
      { lat: 49.423850, lng: -123.467233 },
      { lat: 49.423300, lng: -123.448167 },
      { lat: 49.436600, lng: -123.448250 },
      { lat: 49.438050, lng: -123.460067 },
    ] 
    }
    
const howeSoundLionsBayKelvinGrove = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
        { "lat": 49.460483, "lng": -123.262683 },
        { "lat": 49.454250, "lng": -123.241933 },
        { "lat": 49.449167, "lng": -123.242583 },
        { "lat": 49.449200, "lng": -123.250767 },
        { "lat": 49.454917, "lng": -123.261083 },
        { "lat": 49.460483, "lng": -123.262683 }
      ]
    }
        
const howeSoundLostReef = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.498917, lng: -123.300117 },
      { lat: 49.496650, lng: -123.303383 },
      { lat: 49.492450, lng: -123.299017 },
      { lat: 49.493183, lng: -123.291983 },
      { lat: 49.498033, lng: -123.297200 },
      { lat: 49.498917, lng: -123.300117 },
    ]        
    }
        
        
const howeSoundMarinersRest = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.462133, lng: -123.455950 },
      { lat: 49.458467, lng: -123.461767 },
      { lat: 49.453533, lng: -123.452317 },
      { lat: 49.457317, lng: -123.447850 },
      { lat: 49.462133, lng: -123.455950 },
    ]     
    }          

const howeSoundQueenCharlotteChannel = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.358100, lng: -123.287567 },
      { lat: 49.342133, lng: -123.294833 },
      { lat: 49.340017, lng: -123.299267 },
      { lat: 49.346083, lng: -123.313233 },
      { lat: 49.349700, lng: -123.309733 },
      { lat: 49.351633, lng: -123.300617 },
      { lat: 49.358350, lng: -123.295617 },
      { lat: 49.358100, lng: -123.287567 },
    ]             
    }
        
const howeSoundQueenCharlotteChannel2 = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.338133, lng: -123.294883 },
      { lat: 49.337067, lng: -123.291683 },
      { lat: 49.333217, lng: -123.289617 },
      { lat: 49.330033, lng: -123.290733 },
      { lat: 49.328667, lng: -123.297333 },
      { lat: 49.332283, lng: -123.301783 },
      { lat: 49.338133, lng: -123.294883 },
    ]             
    }    
        
const howeSoundQueenCharlotteChannel3 = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.331967, lng: -123.330783 },
      { lat: 49.321600, lng: -123.331750 },
      { lat: 49.321783, lng: -123.339067 },
      { lat: 49.327383, lng: -123.340350 },
      { lat: 49.330317, lng: -123.339350 },
      { lat: 49.332450, lng: -123.334950 },
      { lat: 49.331967, lng: -123.330783 },
    ]             
    }
        
const howeSoundQueenCharlotteChannel4 = {
    "type":"sponge reef closure",
    "area": "28",
    "coordinates": [
      { lat: 49.343950, lng: -123.319367 },
      { lat: 49.342950, lng: -123.312000 },
      { lat: 49.340683, lng: -123.310617 },
      { lat: 49.334467, lng: -123.313633 },
      { lat: 49.334600, lng: -123.318917 },
      { lat: 49.328633, lng: -123.319783 },
      { lat: 49.328767, lng: -123.325233 },
      { lat: 49.337650, lng: -123.330467 },
      { lat: 49.343950, lng: -123.319367 },
    ]              
    }

const lionsBay = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.470981, lng: -123.246255 },
      { lat: 49.470833, lng: -123.262500 },
      { lat: 49.442500, lng: -123.260000 },
      { lat: 49.442500, lng: -123.238617 },
    ]        
    }          

const marinersRest = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.471667, lng: -123.459167 },
      { lat: 49.471667, lng: -123.471667 },
      { lat: 49.455000, lng: -123.460000 },
      { lat: 49.455000, lng: -123.448333 },
    ]        
    }          

const indianArmCrokerIslandNorthLine = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      {lat: 49.449975, lng: -122.862013 },
      {lat: 49.450032, lng: -122.881502 },
    ]
    }

const indianArmCrokerIslandSouthLine = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      {lat: 49.389860, lng: -122.870401 },
      {lat: 49.389807, lng: -122.882311 },
    ]
    }
        
const indianArmTwinIslandsNorth = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.355000, lng: -122.885000 },
      { lat: 49.359717, lng: -122.893333 },
    ]
}

const indianArmTwinIslandsSouth = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.342646, lng: -122.919597 },
      { lat: 49.342349, lng: -122.890230 },
    ]
}

const pamRocks = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.519400, lng: -123.319567 },
      { lat: 49.480000, lng: -123.300000 },
      { lat: 49.476667, lng: -123.276667 },
      { lat: 49.503333, lng: -123.298333 },
      { lat: 49.520500, lng: -123.286667 },
    ]
  }        

const passageIslandWestVan = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.372500, lng: -123.293700 },
      { lat: 49.345833, lng: -123.305833 },
      { lat: 49.354417, lng: -123.276583 },
      { lat: 49.355000, lng: -123.276583 },
    ]
  }        

const passageIsland = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.345833, lng: -123.306933 },
      { lat: 49.345000, lng: -123.318333 },
      { lat: 49.335000, lng: -123.318333 },
      { lat: 49.339167, lng: -123.307217 },
    ]
  }    
      
  const westBay = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.460283, lng: -123.416667 },
      { lat: 49.457217, lng: -123.407500 },
    ]
  }

  const upperCentreBay = {
    "type":"rockfish closure",
    "area": "28",
    "coordinates": [
      { lat: 49.467500, lng: -123.389167 },
      { lat: 49.467717, lng: -123.376950 },
    ]
  }

const woolRidgeIsland = {
  "type":"rockfish closure",
  "area": "28",
    "coordinates": [
      { lat: 49.521667, lng: -123.435000 },
      { lat: 49.526667, lng: -123.453333 },
      { lat: 49.508333, lng: -123.476667 },
      { lat: 49.501667, lng: -123.450833 },
    ]
  }

  const foreSlopeHills = {
    "type":"sponge reef closure",
    "area": "29",
    "coordinates": 
    [
      { lat: 49.160767, lng: -123.392383 },
      { lat: 49.153517, lng: -123.392783 },
      { lat: 49.153117, lng: -123.376450 },
      { lat: 49.156483, lng: -123.377033 },
      { lat: 49.160567, lng: -123.384133 },
      { lat: 49.160767, lng: -123.392383 },
    ]
  }

  const halibutBankRca = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates":     [
      { lat: 49.368333, lng: -123.790000 },
      { lat: 49.328333, lng: -123.738333 },
      { lat: 49.285000, lng: -123.648333 },
      { lat: 49.285000, lng: -123.611667 },
      { lat: 49.368333, lng: -123.745000 },
      { lat: 49.368333, lng: -123.790000 },
    ]
    }
  
    const halibutBankSpongeReef = {
      "type":"sponge reef closure",
      "area": "29",
      "coordinates": [
        { lat: 49.362800, lng: -123.691683 },
        { lat: 49.369800, lng: -123.702783 },
        { lat: 49.375917, lng: -123.740933 },
        { lat: 49.369567, lng: -123.745800 },
        { lat: 49.364917, lng: -123.731800 },
        { lat: 49.361217, lng: -123.710717 },
        { lat: 49.360167, lng: -123.697383 },
        { lat: 49.344267, lng: -123.669700 },
        { lat: 49.346717, lng: -123.664333 },
        { lat: 49.349350, lng: -123.668983 },
        { lat: 49.352900, lng: -123.667417 },
        { lat: 49.362800, lng: -123.691683 },
      ]
    }


    const galianoIslandNorth = {
      "type":"rockfish closure",
      "area": "29",
      "coordinates":     [
        { lat: 48.933333, lng: -123.435000 },
        { lat: 48.948000, lng: -123.431167 },
        { lat: 48.973333, lng: -123.495000 },
        { lat: 48.960000, lng: -123.509000 },
      ]
    }

  const gabriolaPassage = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates": [
      {lat: 49.118500, lng: -123.685417 },
      {lat: 49.125767, lng: -123.681617 }
    ]  
  }

  const gabriolaPassage2 = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates": [
      { lat: 49.136667, lng: -123.685667 },
      { lat: 49.136667, lng: -123.692483 },
    ]  
  }

  const gabriolaPassage3 = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates": [
      { lat: 49.129583, lng: -123.717217 },
      { lat: 49.127083, lng: -123.715217 },
    ]  
  }

  const mayneIsland = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates":     [
      { lat: 48.859167, lng: -123.253333 },
      { lat: 48.871667, lng: -123.253333 },
      { lat: 48.899167, lng: -123.318333 },
      { lat: 48.894517, lng: -123.322783 },
      { lat: 48.887650, lng: -123.320833 },
      { lat: 48.886050, lng: -123.320833 },
    ]
  }

  const mayneIsland2 = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates":     [
      { lat: 48.872519, lng: -123.292593 },
      { lat: 48.884564, lng: -123.305065 },
      { lat: 48.878517, lng: -123.313065 }
    ]
  }

  const valdesIslandEast = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates":     [
      { lat: 49.028333, lng: -123.595283 },
      { lat: 49.040000, lng: -123.573333 },
      { lat: 49.078333, lng: -123.608333 },
      { lat: 49.067783, lng: -123.631117 },
    ]
  }
  
  const sechelt = {
    "type":"sponge reef closure",
    "area": "29",
    "coordinates":     [
      { lat: 49.414083, lng: -123.831900 },
      { lat: 49.404217, lng: -123.804717 },
      { lat: 49.415167, lng: -123.799183 },
      { lat: 49.412233, lng: -123.784717 },
      { lat: 49.422883, lng: -123.774900 },
      { lat: 49.431650, lng: -123.787767 },
      { lat: 49.432467, lng: -123.814817 },
      { lat: 49.414083, lng: -123.831900 },
    ]
  }
  
  const outerGulf1 = {
    "type":"sponge reef closure",
    "area": "29",
    "coordinates":     [
      { lat: 48.915600, lng: -123.326483 },
      { lat: 48.904717, lng: -123.308817 },
      { lat: 48.901900, lng: -123.310317 },
      { lat: 48.901083, lng: -123.312850 },
      { lat: 48.913117, lng: -123.332150 },
      { lat: 48.915033, lng: -123.329883 },
      { lat: 48.915600, lng: -123.326483 },
    ]
  }
  
  const outerGulf2 = {
    "type":"sponge reef closure",
    "area": "29",
    "coordinates":     [
      { lat: 48.876467, lng: -123.254350 },
      { lat: 48.875333, lng: -123.242283 },
      { lat: 48.866183, lng: -123.229467 },
      { lat: 48.863250, lng: -123.232450 },
      { lat: 48.869167, lng: -123.240733 },
      { lat: 48.867300, lng: -123.244633 },
      { lat: 48.874650, lng: -123.258683 },
      { lat: 48.876467, lng: -123.254350 },
    ]
  }
  
  const outerGulf3 = {
    "type":"sponge reef closure",
    "area": "29",
    "coordinates":     [
      { lat: 48.860033, lng: -123.220550 },
      { lat: 48.855150, lng: -123.212517 },
      { lat: 48.848550, lng: -123.215633 },
      { lat: 48.847400, lng: -123.217650 },
      { lat: 48.852717, lng: -123.227700 },
      { lat: 48.859650, lng: -123.222967 },
      { lat: 48.860033, lng: -123.220550 },
    ]
  }
  
  const outerGulf4 = {
    "type":"sponge reef closure",
    "area": "29",
    "coordinates":     [
      { lat: 48.849983, lng: -123.206517 },
      { lat: 48.843467, lng: -123.193383 },
      { lat: 48.834950, lng: -123.182600 },
      { lat: 48.832650, lng: -123.186367 },
      { lat: 48.847617, lng: -123.210900 },
      { lat: 48.849317, lng: -123.209433 },
      { lat: 48.849983, lng: -123.206517 },
    ]
  }
  
  const mcCallBank = {
    "type":"rockfish closure",
    "area": "29",
    "coordinates":     [
      { lat: 49.366667, lng: -123.620000 },
      { lat: 49.366667, lng: -123.666667 },
      { lat: 49.331667, lng: -123.598333 },
      { lat: 49.331667, lng: -123.550000 },
      { lat: 49.366667, lng: -123.620000 },
    ]
  }

  const bedwellHarbour = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":       [
      // { lat: 48.754167, lng: -123.241667 },
      // { lat: 48.748333, lng: -123.245833 },
    ],
  }

  const bedwellHarbour2 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":       [
      // { lat: 48.736667, lng: -123.231667 },
      // { lat: 48.732500, lng: -123.208050 },
    ],
  }

  const brethourDomvilleForrestPooch = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":    [
      { lat: 48.689992, lng: -123.266526 },
      { lat: 48.684800, lng: -123.293700 },
      { lat: 48.694167, lng: -123.332967 },
      { lat: 48.681083, lng: -123.340167 },
      { lat: 48.666050, lng: -123.341117 },
      { lat: 48.656667, lng: -123.327417 },
      { lat: 48.649817, lng: -123.301350 },
      { lat: 48.662300, lng: -123.275850 },
      { lat: 48.661854, lng: -123.257141 },
    ],
  }     

  const coalIsland = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":   [
      { lat: 48.6911082, lng: -123.3885423 },
      { lat: 48.6883886, lng: -123.4022753 },
      { lat: 48.6852154, lng: -123.4008161 },
      { lat: 48.6842521, lng: -123.4060518 },
      { lat: 48.6824388, lng: -123.4050218 },
      { lat: 48.6759782, lng: -123.3966963 },
      { lat: 48.674958, lng: -123.3966963 },
      { lat: 48.6710439, lng: -123.4049392 },
      { lat: 48.6694285, lng: -123.4043384 },
      { lat: 48.6682948, lng: -123.4027935 },
      { lat: 48.6684082, lng: -123.4003044 },
      { lat: 48.6673878, lng: -123.3978153 },
      { lat: 48.6699, lng: -123.3776121 },
      { lat: 48.679025, lng: -123.3776979 },
      { lat: 48.6802434, lng: -123.3783846 },
      { lat: 48.6802151, lng: -123.3794145 },
      { lat: 48.679365, lng: -123.3813886 },
      { lat: 48.6793933, lng: -123.3830623 },
      { lat: 48.6787133, lng: -123.3840494 },
      { lat: 48.6786849, lng: -123.3861952 },
      { lat: 48.6812068, lng: -123.3856373 },
      { lat: 48.6818585, lng: -123.3870105 },
      { lat: 48.6860518, lng: -123.3877401 },
      { lat: 48.6866468, lng: -123.3843498 },
      { lat: 48.6863635, lng: -123.3830194 },
      { lat: 48.6867034, lng: -123.3816032 },
      { lat: 48.6879784, lng: -123.3826761 },
      { lat: 48.6911082, lng: -123.3885423 }
    ],   
  }
  const coalIsland2 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":    [
      // { lat: 48.674633, lng: -123.396733 },
      // { lat: 48.669650, lng: -123.404550 },
    ],  
  }
  const coalIsland3 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":      [
      // { lat: 48.667450, lng: -123.397650 },
      // { lat: 48.670533, lng: -123.377817 },
    ],
  }
  const coalIsland4 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":      [
      // { lat: 48.670533, lng: -123.377817 },
      // { lat: 48.679217, lng: -123.377100 },
    ],
  }

  const mapleBay = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":    [
      { lat: 48.8218232, lng: -123.5864548 },
      { lat: 48.821032, lng: -123.588343 },
      { lat: 48.8213146, lng: -123.5912613 },
      { lat: 48.8226709, lng: -123.5940937 },
      { lat: 48.8228404, lng: -123.5971836 },
      { lat: 48.8218232, lng: -123.600531 },
      { lat: 48.8204669, lng: -123.6039642 },
      { lat: 48.8176412, lng: -123.6052517 },
      { lat: 48.8175282, lng: -123.6088566 },
      { lat: 48.8141371, lng: -123.6103157 },
      { lat: 48.8089935, lng: -123.6063675 },
      { lat: 48.8028318, lng: -123.604565 },
      { lat: 48.8010228, lng: -123.6024193 },
      { lat: 48.7979698, lng: -123.6033634 },
      { lat: 48.7950863, lng: -123.6023334 },
      { lat: 48.7940685, lng: -123.6029343 },
      { lat: 48.7877354, lng: -123.5989861 },
      { lat: 48.7897711, lng: -123.5974411 },
      { lat: 48.7922026, lng: -123.5986427 },
      { lat: 48.7972348, lng: -123.5983852 },
      { lat: 48.798309, lng: -123.5966686 },
      { lat: 48.800627, lng: -123.5958103 },
      { lat: 48.8008532, lng: -123.5971836 },
      { lat: 48.8037929, lng: -123.5948662 },
      { lat: 48.8050931, lng: -123.5935787 },
      { lat: 48.8053192, lng: -123.5910896 },
      { lat: 48.8053192, lng: -123.5887722 },
      { lat: 48.8083717, lng: -123.5886005 },
      { lat: 48.8218232, lng: -123.5864548 }
    ],    
  }
  const mapleBay2 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":      [
      { lat: 48.7878421, lng: -123.5539517 },
      { lat: 48.788351, lng: -123.5514626 },
      { lat: 48.7905564, lng: -123.5482869 },
      { lat: 48.7912915, lng: -123.5458837 },
      { lat: 48.7921962, lng: -123.5415063 },
      { lat: 48.7916873, lng: -123.5365281 },
      { lat: 48.7901605, lng: -123.533009 },
      { lat: 48.7899344, lng: -123.5299191 },
      { lat: 48.7891992, lng: -123.5256276 },
      { lat: 48.7876724, lng: -123.5194478 },
      { lat: 48.7880117, lng: -123.5177312 },
      { lat: 48.7898778, lng: -123.5171304 },
      { lat: 48.791348, lng: -123.5186753 },
      { lat: 48.7930444, lng: -123.5209928 },
      { lat: 48.7945145, lng: -123.5215936 },
      { lat: 48.7965499, lng: -123.5271726 },
      { lat: 48.7989811, lng: -123.5339532 },
      { lat: 48.7999422, lng: -123.5336099 },
      { lat: 48.8022036, lng: -123.5380731 },
      { lat: 48.8037865, lng: -123.5395322 },
      { lat: 48.8044083, lng: -123.5431371 },
      { lat: 48.8050867, lng: -123.5436521 },
      { lat: 48.8074044, lng: -123.5473428 },
      { lat: 48.7878421, lng: -123.5539517 }
    ],  
  }

  const navyChannel = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":    [
      { lat: 48.833333, lng: -123.327967 },
      { lat: 48.819017, lng: -123.327200 },
      { lat: 48.803333, lng: -123.275900 },
      { lat: 48.815000, lng: -123.232217 },
    ],   
  }

  const navyChannel2 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":  [
      // { lat: 48.803333, lng: -123.275900 },
      // { lat: 48.815000, lng: -123.232217 },
    ],     
  }

  const pateyRock = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":     [
      { lat: 48.704167, lng: -123.525000 },
      { lat: 48.697500, lng: -123.525000 },
      { lat: 48.697500, lng: -123.508333 },
      { lat: 48.704167, lng: -123.508333 },
      { lat: 48.704167, lng: -123.525000 },
    ],   
  }

  const portlandIsland = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":     [
      { lat: 48.725920, lng: -123.387068 },
      { lat: 48.710401, lng: -123.382457 },
      { lat: 48.722103, lng: -123.350716 },
      { lat: 48.733533, lng: -123.340067 },
      { lat: 48.735000, lng: -123.370000 },
    ],   
  }

  const prevostIslandNorth = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":     [
      { lat: 48.832483, lng: -123.359167 },
      { lat: 48.860383, lng: -123.391083 },
      { lat: 48.850000, lng: -123.423717 },
    ],   
  }

  const prevostIslandNorth2 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":     [
      { lat: 48.842533, lng: -123.419583 },
      { lat: 48.830900, lng: -123.417217 },
      { lat: 48.810300, lng: -123.381700 },
      { lat: 48.809067, lng: -123.365667 },
    ],   
  }

  const russellIsland = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":   [
      { lat: 48.754893, lng: -123.4255742 },
      { lat: 48.7423292, lng: -123.4103821 },
      { lat: 48.7534783, lng: -123.3863495 },
      { lat: 48.7541574, lng: -123.3884953 },
      { lat: 48.7546667, lng: -123.3895253 },
      { lat: 48.7534783, lng: -123.3925293 },
      { lat: 48.7544969, lng: -123.3935593 },
      { lat: 48.7564208, lng: -123.3951901 },
      { lat: 48.7559681, lng: -123.3964775 },
      { lat: 48.7584578, lng: -123.3981942 },
      { lat: 48.7573828, lng: -123.4011982 },
      { lat: 48.755855, lng: -123.4027432 },
      { lat: 48.7557418, lng: -123.4065197 },
      { lat: 48.7595895, lng: -123.4096096 },
      { lat: 48.7577223, lng: -123.4114121 },
      { lat: 48.7584578, lng: -123.4138153 },
      { lat: 48.7573262, lng: -123.4154461 },
      { lat: 48.7576091, lng: -123.4179352 },
      { lat: 48.7570433, lng: -123.4188794 },
      { lat: 48.7566472, lng: -123.4223984 },
      { lat: 48.7556286, lng: -123.4245442 },
      { lat: 48.7545535, lng: -123.4236 },
      { lat: 48.754893, lng: -123.4255742 }
    ],     
  }

  const southSaturna = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":     [
      { lat: 48.774550, lng: -123.202567 },
      { lat: 48.755500, lng: -123.203333 },
      { lat: 48.735650, lng: -123.183817 },
      { lat: 48.724367, lng: -123.160000 },
      { lat: 48.745833, lng: -123.083333 },
      { lat: 48.780700, lng: -123.080767 },
    ],   
  }

  const southSaturna2 = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":    [
      // { lat: 48.735650, lng: -123.183817 },
      // { lat: 48.724367, lng: -123.160000 },
      // { lat: 48.745833, lng: -123.083333 },
      // { lat: 48.780700, lng: -123.080767 },
    ],    
  }

  const bellIsland = {
    "type":"rockfish closure",
    "area": "18",
    "coordinates":       [
      { lat: 48.8333527, lng: -123.2333433 },
      { lat: 48.846741, lng: -123.2440721 },
      { lat: 48.8549869, lng: -123.2463037 },
      { lat: 48.8266867, lng: -123.1574078 },
      { lat: 48.8081494, lng: -123.1677075 },
    ],
  }

  const ballenasIsland = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.350383, lng: -124.160250 }, // Starting point
      { lat: 49.350383, lng: -124.126667 }, // Move true east
      { lat: 49.326667, lng: -124.126667 }, // Move true south
      { lat: 49.326667, lng: -124.160250 }, // Move true west
      { lat: 49.350383, lng: -124.160250 }  // Move true north (back to starting point)
    ]
  }

  const coffinPoint = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.010000, lng: -123.765250 }, // Starting point (North of Coffin Point)
      { lat: 49.010000, lng: -123.746667 }, // Move to
      { lat: 48.996667, lng: -123.733333 }, // Move to
      { lat: 48.992500, lng: -123.733333 }, // Move to
      { lat: 48.992500, lng: -123.759999 }, // Move to (Coffin Point)
      // Continue with coordinates following the shoreline as needed
    ]
  }

  const dangerReef = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.060000, lng: -123.726667 }, // Starting point (in water)
      { lat: 49.065000, lng: -123.716667 }, // Move to
      { lat: 49.050000, lng: -123.705000 }, // Move to
      { lat: 49.046667, lng: -123.713333 }, // Move to
      // Move back to the beginning point
      { lat: 49.060000, lng: -123.726667 }
    ]
  }

  const deCourcyIslandNorth = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.121667, lng: -123.768233 }, // Starting point (Link Island)
      { lat: 49.127200, lng: -123.733333 }, // Move to (Gabriola Island)
      { lat: 49.100000, lng: -123.734583 }, // Move to (Pirates Cove)
      { lat: 49.100000, lng: -123.737583 }, // Move to (De Courcy Island)
      // Continue with coordinates following the easterly shoreline of De Courcy Island as needed
      { lat: 49.111683, lng: -123.759333 }, // Move to (De Courcy Island)
      { lat: 49.111767, lng: -123.759183 }, // Move to (Link Island)
      // Continue with coordinates following the easterly shoreline of Link Island as needed
      { lat: 49.121667, lng: -123.768233 }  // Move back to the beginning point
    ]
  }

  const departureBay = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.215483, lng: -123.941133 }, // Starting point (north of Horswell Bluff)
      { lat: 49.215483, lng: -123.934217 }, // Move to (Horswell Channel Buoy)
      { lat: 49.198783, lng: -123.925217 }, // Move to (McKay Point)
      // Continue with coordinates following the shoreline of Newcastle Island as needed
      { lat: 49.195000, lng: -123.947017 }, // Move to (Shaft Point)
      { lat: 49.207117, lng: -123.959517 }, // Move to (Brandon Islands)
      { lat: 49.210000, lng: -123.955833 }, // Move to (Pacific Biological)
      // Continue with coordinates following the shoreline as needed
    ]
  }

  const gabriolaIsland = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.227867, lng: -123.792950 }, // Starting point
      { lat: 49.220583, lng: -123.790483 }, // Move to
      { lat: 49.219750, lng: -123.798033 }, // Move to
      { lat: 49.223183, lng: -123.801983 }, // Move to
      { lat: 49.227050, lng: -123.803433 }, // Move to
      { lat: 49.227867, lng: -123.792950 }  // Move back to the beginning point
    ]
  }

  const lasquetiSouthYoungPoint = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.438883, lng: -124.136117 }, // Starting point (in water)
      { lat: 49.438883, lng: -124.095550 }, // Move to
      { lat: 49.410550, lng: -124.095550 }, // Move to
      { lat: 49.410550, lng: -124.136117 }, // Move to
      { lat: 49.438883, lng: -124.136117 }  // Move back to the beginning point
    ]
  }

  const nanooseSchoonerCove = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.301667, lng: -124.170250 }, // Starting point (Vancouver Island)
      { lat: 49.304350, lng: -124.154883 }, // Move to (Amelia Island)
      { lat: 49.306667, lng: -124.131950 }, // Move to (Yeo Islands)
      { lat: 49.285000, lng: -124.093333 }, // Move to (Ada Islands)
      { lat: 49.270367, lng: -124.085000 }, // Move to (Maude Island)
      { lat: 49.261967, lng: -124.124500 }, // Move to (Imperieuse Rock Light Buoy)
      { lat: 49.265383, lng: -124.124900 }, // Move to (Nanoose Harbour)
      // Continue with coordinates following the shoreline of Nanoose Harbour as needed
      // ...
      // { lat: 49.301667, lng: -124.170250 }  // Move back to the beginning point
    ]
  }

  const northumberlandChannel = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.199100, lng: -123.854167 }, // Move to (Tinson Point)
      { lat: 49.217400, lng: -123.890533 }, // Starting point (Snake Island Light)
      { lat: 49.166667, lng: -123.893333 }, // Move to (Jack Point Area)
      { lat: 49.140068499445874, lng: -123.85713868726269}
      // Continue with coordinates following the shoreline as needed
      // { lat: 49.155000, lng: -123.857000 }, // Move to (Gabriola Island)
      // Continue with coordinates following the shoreline as needed
    ]
  }

  const northumberlandChannel2 = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      // { lat: 49.140283, lng: -123.857000 }, // Starting point (Snake Island Light)
      // { lat: 49.155840, lng: -123.857002 }, // Move to (Tinson Point)
    ]
  }
  
  const reynoldsPointLinkIsland = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.115283, lng: -123.762500 },
      { lat: 49.115283, lng: -123.805217 },
      { lat: 49.096667, lng: -123.800667 },
      { lat: 49.110621, lng: -123.759120 },

    ]
  }

  const reynoldsPointLinkIsland2 = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      // { lat: 49.096667, lng: -123.800667 },
      // { lat: 49.110621, lng: -123.759120 },
    ]
  }

  const ruxtonPyladesIslands = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 49.092500, lng: -123.719883 }, // Starting point (De Courcy Island)
      { lat: 49.095000, lng: -123.711667 }, // Move to (in water)
      { lat: 49.070833, lng: -123.680000 }, // Move to (in water)
      { lat: 49.058333, lng: -123.676667 }, // Move to (in water)
      { lat: 49.056667, lng: -123.700000 }, // Move to (in water)
      { lat: 49.068333, lng: -123.703333 }, // Move to (in water)
      { lat: 49.087500, lng: -123.737500 }, // Move to (in water)
      { lat: 49.091300, lng: -123.735000 }, // Move to (De Courcy Island)
    ]
  }

  const saltSpringIslandNorth = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 48.9588677, lng: -123.5914901 },
      { lat: 48.9402359, lng: -123.6047472 },
      { lat: 48.9334701, lng: -123.5990824 },
      { lat: 48.9338084, lng: -123.5965075 },
      { lat: 48.9361765, lng: -123.5953058 },
      { lat: 48.9411379, lng: -123.5971941 },
      { lat: 48.9422654, lng: -123.5958208 },
      { lat: 48.9406869, lng: -123.5920443 },
      { lat: 48.9449714, lng: -123.5951342 },
      { lat: 48.9427164, lng: -123.589641 },
      { lat: 48.9396721, lng: -123.5855211 },
      { lat: 48.9382062, lng: -123.5783113 },
      { lat: 48.9311019, lng: -123.5685266 },
      { lat: 48.9274929, lng: -123.5614885 },
      { lat: 48.9272674, lng: -123.5590853 },
      { lat: 48.921515, lng: -123.5510172 },
      { lat: 48.9187864, lng: -123.5467914 },
      { lat: 48.9182224, lng: -123.5437873 },
      { lat: 48.9312496, lng: -123.5345176 },
      { lat: 48.9378466, lng: -123.5455039 },
      { lat: 48.938805, lng: -123.5498813 },
      { lat: 48.9407782, lng: -123.5513404 },
      { lat: 48.9441607, lng: -123.5561469 },
      { lat: 48.9465847, lng: -123.5594085 },
      { lat: 48.9497977, lng: -123.5649875 },
      { lat: 48.9556032, lng: -123.5733989 },
      { lat: 48.956674, lng: -123.5744289 },
      { lat: 48.9571812, lng: -123.5770038 },
      { lat: 48.9555468, lng: -123.5779479 },
      { lat: 48.9571812, lng: -123.5812095 },
      { lat: 48.957463, lng: -123.5853294 },
      { lat: 48.9594919, lng: -123.5891059 },
      { lat: 48.9588677, lng: -123.5914901 }
    ]
  }

  const saltSpringIslandNorth2 = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      // { lat: 48.919717, lng: -123.548400 },
      // { lat: 48.931583, lng: -123.535033 },
    ]
  }

  const thetisKuperIslands = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 48.9299977, lng: -123.7059686 },
      { lat: 48.9337308, lng: -123.6317362 },
      { lat: 48.9352532, lng: -123.6344828 },
      { lat: 48.9359298, lng: -123.6375727 },
      { lat: 48.9379313, lng: -123.6398472 },
      { lat: 48.9388334, lng: -123.6376585 },
      { lat: 48.9405247, lng: -123.6370577 },
      { lat: 48.9427234, lng: -123.6386027 },
      { lat: 48.9430616, lng: -123.6418213 },
      { lat: 48.9420469, lng: -123.6433233 },
      { lat: 48.9433435, lng: -123.6470141 },
      { lat: 48.943879, lng: -123.6489882 },
      { lat: 48.9450347, lng: -123.6509623 },
      { lat: 48.9443018, lng: -123.6519922 },
      { lat: 48.9466501, lng: -123.6539695 },
      { lat: 48.9491303, lng: -123.6573169 },
      { lat: 48.9529632, lng: -123.6585185 },
      { lat: 48.9537523, lng: -123.659806 },
      { lat: 48.954485, lng: -123.659806 },
      { lat: 48.9551613, lng: -123.6598918 },
      { lat: 48.9559222, lng: -123.6610934 },
      { lat: 48.9558658, lng: -123.6604497 },
      { lat: 48.9575002, lng: -123.6622522 },
      { lat: 48.9687372, lng: -123.6733665 },
      { lat: 48.9689344, lng: -123.6739459 },
      { lat: 48.9709065, lng: -123.6755767 },
      { lat: 48.9707656, lng: -123.6748042 },
      { lat: 48.9712586, lng: -123.674654 },
      { lat: 48.9712727, lng: -123.6742248 },
      { lat: 48.9715685, lng: -123.6734524 },
      { lat: 48.9721037, lng: -123.6734524 },
      { lat: 48.9744841, lng: -123.6749544 },
      { lat: 48.975639, lng: -123.6758771 },
      { lat: 48.9764982, lng: -123.6760702 },
      { lat: 48.9768503, lng: -123.6763921 },
      { lat: 48.977301, lng: -123.6767139 },
      { lat: 48.9778502, lng: -123.6767354 },
      { lat: 48.9788924, lng: -123.6776366 },
      { lat: 48.9791319, lng: -123.6769929 },
      { lat: 48.9794276, lng: -123.6768212 },
      { lat: 48.9799064, lng: -123.6771645 },
      { lat: 48.9799909, lng: -123.6778941 },
      { lat: 48.9801881, lng: -123.6776581 },
      { lat: 48.9807937, lng: -123.6781731 },
      { lat: 48.981019, lng: -123.6794176 },
      { lat: 48.9814415, lng: -123.6801042 },
      { lat: 48.9813429, lng: -123.6809196 },
      { lat: 48.9810753, lng: -123.6828294 },
      { lat: 48.9808218, lng: -123.6841597 },
      { lat: 48.9803853, lng: -123.6858549 },
      { lat: 48.9802726, lng: -123.6866703 },
      { lat: 48.9802163, lng: -123.6878719 },
      { lat: 48.9803853, lng: -123.6886229 },
      { lat: 48.9811176, lng: -123.689374 },
      { lat: 48.9811176, lng: -123.6899104 },
      { lat: 48.9812302, lng: -123.690361 },
      { lat: 48.9812725, lng: -123.6908975 },
      { lat: 48.9815682, lng: -123.6915412 },
      { lat: 48.9822019, lng: -123.6917772 },
      { lat: 48.9824132, lng: -123.6920347 },
      { lat: 48.9826667, lng: -123.6919703 },
      { lat: 48.9833004, lng: -123.6923351 },
      { lat: 48.9836947, lng: -123.6922064 },
      { lat: 48.9840608, lng: -123.6922278 },
      { lat: 48.9846945, lng: -123.6920776 },
      { lat: 48.9848353, lng: -123.6924639 },
      { lat: 48.9848494, lng: -123.6928501 },
      { lat: 48.9872151, lng: -123.6939659 },
      { lat: 48.9874263, lng: -123.6942878 },
      { lat: 48.9900735, lng: -123.695425 },
      { lat: 48.9910591, lng: -123.6951675 },
      { lat: 48.9911999, lng: -123.6958327 },
      { lat: 48.9920587, lng: -123.6963262 },
      { lat: 48.9921995, lng: -123.69697 },
      { lat: 48.9920447, lng: -123.6973133 },
      { lat: 48.9938891, lng: -123.6981502 },
      { lat: 48.9938468, lng: -123.6990728 },
      { lat: 48.9958179, lng: -123.699502 },
      { lat: 48.9974651, lng: -123.7002101 },
      { lat: 48.9973665, lng: -123.700768 },
      { lat: 48.9978029, lng: -123.7011971 },
      { lat: 49.0026033, lng: -123.7029138 },
      { lat: 49.0040392, lng: -123.7036219 },
      { lat: 49.0053201, lng: -123.7038579 },
      { lat: 49.0053342, lng: -123.7035575 },
      { lat: 49.0076003, lng: -123.7047377 },
      { lat: 49.00791, lng: -123.7047377 },
      { lat: 49.0093175, lng: -123.705596 },
      { lat: 49.0098101, lng: -123.7054028 },
      { lat: 49.0105702, lng: -123.7057891 },
      { lat: 49.0109079, lng: -123.7063684 },
      { lat: 49.0134553, lng: -123.705596 },
      { lat: 49.013779, lng: -123.7066045 },
      { lat: 49.0177699, lng: -123.7099664 },
      { lat: 49.0181076, lng: -123.7097089 },
      { lat: 49.0188957, lng: -123.7102239 },
      { lat: 49.0195149, lng: -123.7099235 },
      { lat: 49.0199933, lng: -123.7100523 },
      { lat: 49.0201622, lng: -123.7092369 },
      { lat: 49.019543, lng: -123.7079065 },
      { lat: 49.0192897, lng: -123.7064474 },
      { lat: 49.0190927, lng: -123.7047737 },
      { lat: 49.0172633, lng: -123.7003534 },
      { lat: 49.0166441, lng: -123.6994951 },
      { lat: 49.0114087, lng: -123.694989 },
      { lat: 49.0116058, lng: -123.6940877 },
      { lat: 49.0125347, lng: -123.6943452 },
      { lat: 49.0132102, lng: -123.6940877 },
      { lat: 49.0121125, lng: -123.6926715 },
      { lat: 49.012225, lng: -123.6921136 },
      { lat: 49.0136606, lng: -123.6925857 },
      { lat: 49.0138013, lng: -123.6912553 },
      { lat: 49.014702, lng: -123.6914699 },
      { lat: 49.0134073, lng: -123.6901825 },
      { lat: 49.0144768, lng: -123.6875217 },
      { lat: 49.0166722, lng: -123.6895387 },
      { lat: 49.0171226, lng: -123.6891954 },
      { lat: 49.0156308, lng: -123.6867063 },
      { lat: 49.0133791, lng: -123.6855047 },
      { lat: 49.0116621, lng: -123.6851614 },
      { lat: 49.0091005, lng: -123.6825006 },
      { lat: 49.0070737, lng: -123.6809986 },
      { lat: 49.0063417, lng: -123.6806123 },
      { lat: 49.0049342, lng: -123.6781662 },
      { lat: 49.0055254, lng: -123.6779516 },
      { lat: 49.0087909, lng: -123.6797969 },
      { lat: 49.009382, lng: -123.6812561 },
      { lat: 49.0119999, lng: -123.6842601 },
      { lat: 49.0128443, lng: -123.6841743 },
      { lat: 49.013548, lng: -123.6844318 },
      { lat: 49.0140828, lng: -123.684303 },
      { lat: 49.0148428, lng: -123.6851614 },
      { lat: 49.0159123, lng: -123.6850755 },
      { lat: 49.0168693, lng: -123.6866634 },
      { lat: 49.0171507, lng: -123.6864059 },
      { lat: 49.0180232, lng: -123.6877363 },
      { lat: 49.0198526, lng: -123.6892812 },
      { lat: 49.020528, lng: -123.689882 },
      { lat: 49.0213723, lng: -123.6897104 },
      { lat: 49.0210628, lng: -123.6882083 },
      { lat: 49.022301, lng: -123.6897104 },
      { lat: 49.0221322, lng: -123.6886375 },
      { lat: 49.0441201, lng: -123.7153192 },
      { lat: 48.9299977, lng: -123.7059686 }
    ]
  }

  const thetisKuperIslands2 = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      // { lat: 48.957584, lng: -123.662362 },
      // { lat: 48.969267, lng: -123.673667 },
    ]
  }

  const trincomaliChannel = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      { lat: 48.9792424, lng: -123.5686607 },
      { lat: 48.956674, lng: -123.5744289 },
      { lat: 48.9533898, lng: -123.5679857 },
      { lat: 48.9521498, lng: -123.5672132 },
      { lat: 48.9494442, lng: -123.5618059 },
      { lat: 48.9482758, lng: -123.5588077 },
      { lat: 48.9466258, lng: -123.5548536 },
      { lat: 48.9422851, lng: -123.5471288 },
      { lat: 48.939579, lng: -123.5452406 },
      { lat: 48.9387333, lng: -123.5437814 },
      { lat: 48.9392971, lng: -123.5430948 },
      { lat: 48.9380004, lng: -123.5412924 },
      { lat: 48.9365345, lng: -123.5406915 },
      { lat: 48.9339972, lng: -123.5352842 },
      { lat: 48.9328695, lng: -123.5347692 },
      { lat: 48.9343919, lng: -123.5397474 },
      { lat: 48.9312496, lng: -123.5345176 },
      { lat: 48.9232266, lng: -123.4961454 },
      { lat: 48.9065304, lng: -123.4582082 },
      { lat: 48.9049507, lng: -123.4501402 },
      { lat: 48.9011142, lng: -123.4413854 },
      { lat: 48.8987444, lng: -123.4406988 },
      { lat: 48.8941174, lng: -123.4293691 },
      { lat: 48.8946817, lng: -123.4237043 },
      { lat: 48.8927631, lng: -123.4170095 },
      { lat: 48.8940046, lng: -123.4156362 },
      { lat: 48.896826, lng: -123.421301 },
      { lat: 48.8937788, lng: -123.412203 },
      { lat: 48.8951896, lng: -123.4105722 },
      { lat: 48.8996472, lng: -123.4079115 },
      { lat: 48.9025811, lng: -123.4091131 },
      { lat: 48.9063047, lng: -123.4175245 },
      { lat: 48.9112691, lng: -123.4271375 },
      { lat: 48.9118332, lng: -123.4314291 },
      { lat: 48.9175866, lng: -123.4443037 },
      { lat: 48.9226626, lng: -123.4549467 },
      { lat: 48.923565, lng: -123.4592382 },
      { lat: 48.9279637, lng: -123.4731428 },
      { lat: 48.9295427, lng: -123.4752027 },
      { lat: 48.9299938, lng: -123.478121 },
      { lat: 48.9355196, lng: -123.4923689 },
      { lat: 48.9387897, lng: -123.5006086 },
      { lat: 48.9418341, lng: -123.5009519 },
      { lat: 48.9434126, lng: -123.5091917 },
      { lat: 48.9425106, lng: -123.5119383 },
      { lat: 48.945442, lng: -123.5203497 },
      { lat: 48.9491624, lng: -123.5272161 },
      { lat: 48.9581804, lng: -123.5428373 },
      { lat: 48.9648301, lng: -123.552107 },
      { lat: 48.9664078, lng: -123.5550253 },
      { lat: 48.9706901, lng: -123.5600034 },
      { lat: 48.9754227, lng: -123.5658399 },
      { lat: 48.9777888, lng: -123.5668699 }
    ]
  }
  const trincomaliChannel2 = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      // { lat: 48.931667, lng: -123.535150 },
      // { lat: 48.923517, lng: -123.494450 },
      // { lat: 48.903117, lng: -123.447950 },
    ]
  }

  const trincomaliChannel3 = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      // { lat: 48.899150, lng: -123.441883 },
      // { lat: 48.893233, lng: -123.428600 },
    ]
  }
  const trincomaliChannel4 = {
    "type":"rockfish closure",
    "area": "17",
    "coordinates": [
      // { lat: 48.893633, lng: -123.413367 },
      // { lat: 48.895533, lng: -123.410800 },
    ]
  }

  const davieBay = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.602950, lng: -124.397617 }, // Starting point (near Davie Bay)
      { lat: 49.591667, lng: -124.408333 }, // Move to (in water)
      { lat: 49.562500, lng: -124.345000 }, // Move to (in water)
      { lat: 49.575867, lng: -124.331667 }, // Move to (shoreline)
    ]
  }

  const hardyIsland = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.7736852, lng: -124.0907234 },
      { lat: 49.7625808, lng: -124.2311564 },
      { lat: 49.7515422, lng: -124.2207713 },
      { lat: 49.7487694, lng: -124.2159647 },
      { lat: 49.7486585, lng: -124.20927 },
      { lat: 49.7442216, lng: -124.2039485 },
      { lat: 49.7442216, lng: -124.1969103 },
      { lat: 49.7466619, lng: -124.196052 },
      { lat: 49.7526513, lng: -124.1691012 },
      { lat: 49.7545367, lng: -124.1691012 },
      { lat: 49.7526513, lng: -124.1625781 },
      { lat: 49.7548694, lng: -124.1579432 },
      { lat: 49.7516531, lng: -124.1569132 },
      { lat: 49.7461073, lng: -124.1591448 },
      { lat: 49.7446653, lng: -124.1634364 },
      { lat: 49.7420029, lng: -124.1639514 },
      { lat: 49.7392295, lng: -124.1773409 },
      { lat: 49.7404499, lng: -124.1831774 },
      { lat: 49.7403389, lng: -124.1890139 },
      { lat: 49.7373435, lng: -124.1897006 },
      { lat: 49.7397842, lng: -124.1958804 },
      { lat: 49.7334604, lng: -124.1979403 },
      { lat: 49.7317448, lng: -124.2014997 },
      { lat: 49.7135005, lng: -124.2003222 },
      { lat: 49.7123135, lng: -124.2029855 },
      { lat: 49.7016563, lng: -124.2035005 },
      { lat: 49.7011391, lng: -124.202225 },
      { lat: 49.6789447, lng: -124.2019062 },
      { lat: 49.6842206, lng: -124.1852551 },
      { lat: 49.6861364, lng: -124.1848259 },
      { lat: 49.6860254, lng: -124.18783 },
      { lat: 49.6826379, lng: -124.2008334 },
      { lat: 49.6863863, lng: -124.1987734 },
      { lat: 49.689135, lng: -124.1980009 },
      { lat: 49.6926274, lng: -124.1998049 },
      { lat: 49.6974021, lng: -124.1974016 },
      { lat: 49.7046188, lng: -124.1709657 },
      { lat: 49.7063951, lng: -124.1659876 },
      { lat: 49.7116125, lng: -124.1658159 },
      { lat: 49.7141654, lng: -124.1718241 },
      { lat: 49.7026204, lng: -124.1862436 },
      { lat: 49.7010661, lng: -124.1915651 },
      { lat: 49.7028425, lng: -124.1994615 },
      { lat: 49.7023984, lng: -124.2020365 },
      { lat: 49.7105024, lng: -124.2015215 },
      { lat: 49.7142764, lng: -124.1980882 },
      { lat: 49.7140544, lng: -124.1927667 },
      { lat: 49.7163853, lng: -124.1876169 },
      { lat: 49.7168293, lng: -124.183497 },
      { lat: 49.7161633, lng: -124.1792055 },
      { lat: 49.71916, lng: -124.174399 },
      { lat: 49.7203808, lng: -124.1713091 },
      { lat: 49.7267064, lng: -124.1656442 },
      { lat: 49.7282599, lng: -124.1654726 },
      { lat: 49.7311449, lng: -124.1592928 },
      { lat: 49.7364706, lng: -124.1601511 },
      { lat: 49.7389113, lng: -124.1556879 },
      { lat: 49.7461219, lng: -124.150538 },
      { lat: 49.7522223, lng: -124.1524263 },
      { lat: 49.7590982, lng: -124.141955 },
      { lat: 49.7598745, lng: -124.1366335 },
      { lat: 49.7614269, lng: -124.1216989 },
      { lat: 49.7612051, lng: -124.1177507 },
      { lat: 49.7556604, lng: -124.1162058 },
      { lat: 49.7524441, lng: -124.1143175 },
      { lat: 49.7503368, lng: -124.1016146 },
      { lat: 49.7476748, lng: -124.0944048 },
      { lat: 49.7492277, lng: -124.0889116 },
      { lat: 49.7528878, lng: -124.0853067 },
      { lat: 49.7571021, lng: -124.0865084 },
      { lat: 49.7612051, lng: -124.0895983 },
      { lat: 49.7628684, lng: -124.0907999 },
      { lat: 49.7674145, lng: -124.097323 },
      { lat: 49.7736852, lng: -124.0907234 }
    ]
  }

  const hardyIsland2 = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      // { lat: 49.7317448, lng: -124.2014997 },
      // { lat: 49.7135005, lng: -124.2003222 }
    ]
  }

  const hardyIsland3 = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      // { lat: 49.6794741, lng: -124.2017635 },
      // { lat: 49.7010642, lng: -124.2018504 }
    ]
  }

  const hothamSound = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.8610702, lng: -124.0160684 },
      { lat: 49.8616788, lng: -124.0119915 },
      { lat: 49.8670742, lng: -124.0139643 },
      { lat: 49.8706148, lng: -124.01714 },
      { lat: 49.8739338, lng: -124.0228907 },
      { lat: 49.8741551, lng: -124.029328 },
      { lat: 49.8750401, lng: -124.033362 },
      { lat: 49.8835024, lng: -124.0335337 },
      { lat: 49.8860464, lng: -124.028298 },
      { lat: 49.888922, lng: -124.0307871 },
      { lat: 49.8937327, lng: -124.0307013 },
      { lat: 49.8992616, lng: -124.0268389 },
      { lat: 49.9025787, lng: -124.0217749 },
      { lat: 49.9139656, lng: -124.0167109 },
      { lat: 49.9203764, lng: -124.0241781 },
      { lat: 49.9146841, lng: -124.0304438 },
      { lat: 49.9154578, lng: -124.0331904 },
      { lat: 49.9243551, lng: -124.0307871 },
      { lat: 49.9320906, lng: -124.0327612 },
      { lat: 49.9312619, lng: -124.038941 },
      { lat: 49.9268417, lng: -124.0410868 },
      { lat: 49.920487, lng: -124.0400568 },
      { lat: 49.9126944, lng: -124.0475241 },
      { lat: 49.9060061, lng: -124.0502707 },
      { lat: 49.8986535, lng: -124.0543905 },
      { lat: 49.8825069, lng: -124.0655485 },
      { lat: 49.8754273, lng: -124.0670935 },
      { lat: 49.87327, lng: -124.0701834 },
      { lat: 49.8707807, lng: -124.0773932 },
      { lat: 49.866621, lng: -124.0774133 },
      { lat: 49.864408, lng: -124.0758683 },
      { lat: 49.8631908, lng: -124.0660836 },
      { lat: 49.859207, lng: -124.0626504 },
      { lat: 49.8586537, lng: -124.0580155 },
      { lat: 49.8589857, lng: -124.054239 },
      { lat: 49.8547802, lng: -124.0540673 },
      { lat: 49.8548909, lng: -124.0568139 },
      { lat: 49.8515705, lng: -124.0556123 },
      { lat: 49.8418295, lng: -124.0484025 },
      { lat: 49.8368938, lng: -124.0380451 },
      { lat: 49.8610702, lng: -124.0160684 }
    ]
  }

  const malaspinaStrait = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.653333, lng: -124.187500 }, // Starting point (in water)
      { lat: 49.602500, lng: -124.100000 }, // Move to (in water)
      { lat: 49.586667, lng: -124.156667 }, // Move to (in water)
      { lat: 49.643333, lng: -124.225000 }, // Move to (in water)
      { lat: 49.653333, lng: -124.187500 }  // Return to the beginning point
    ]
  }

  const mcNaughtonPoint = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.590000, lng: -124.015833 },
      { lat: 49.588333, lng: -124.021667 },
      { lat: 49.576667, lng: -124.016233 },
      { lat: 49.565000, lng: -124.011667 },
      { lat: 49.545000, lng: -123.995000 },
      { lat: 49.547883, lng: -123.990167 }
    ]
  }

  const princessLouisaInlet = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 50.1659107, lng: -123.853068 },
      { lat: 50.1643162, lng: -123.8495489 },
      { lat: 50.1655258, lng: -123.845944 },
      { lat: 50.16767, lng: -123.8451715 },
      { lat: 50.1695942, lng: -123.8378759 },
      { lat: 50.174212, lng: -123.8286921 },
      { lat: 50.1739371, lng: -123.820109 },
      { lat: 50.1753114, lng: -123.8116117 },
      { lat: 50.179324, lng: -123.7995955 },
      { lat: 50.1795988, lng: -123.7973639 },
      { lat: 50.1815005, lng: -123.7932882 },
      { lat: 50.1821601, lng: -123.7884817 },
      { lat: 50.1838089, lng: -123.7883959 },
      { lat: 50.1854027, lng: -123.789855 },
      { lat: 50.1871064, lng: -123.7849626 },
      { lat: 50.1868865, lng: -123.7780962 },
      { lat: 50.1902387, lng: -123.7696848 },
      { lat: 50.1957336, lng: -123.7656507 },
      { lat: 50.1971073, lng: -123.7666807 },
      { lat: 50.2021619, lng: -123.7648783 },
      { lat: 50.2026563, lng: -123.7679682 },
      { lat: 50.2051285, lng: -123.7684832 },
      { lat: 50.2061722, lng: -123.7711439 },
      { lat: 50.2054581, lng: -123.7768946 },
      { lat: 50.1993599, lng: -123.7785253 },
      { lat: 50.1961732, lng: -123.7812719 },
      { lat: 50.1940852, lng: -123.7865934 },
      { lat: 50.1937006, lng: -123.7905416 },
      { lat: 50.1889748, lng: -123.7966356 },
      { lat: 50.1869964, lng: -123.7998114 },
      { lat: 50.1871613, lng: -123.8037596 },
      { lat: 50.1904585, lng: -123.8077936 },
      { lat: 50.1890298, lng: -123.811141 },
      { lat: 50.1890847, lng: -123.8132868 },
      { lat: 50.1867217, lng: -123.815175 },
      { lat: 50.1840287, lng: -123.8218698 },
      { lat: 50.1827646, lng: -123.8214407 },
      { lat: 50.179247, lng: -123.8245306 },
      { lat: 50.1789722, lng: -123.8279638 },
      { lat: 50.1799066, lng: -123.8300238 },
      { lat: 50.1791371, lng: -123.8363752 },
      { lat: 50.172211, lng: -123.8437567 },
      { lat: 50.1707817, lng: -123.8473616 },
      { lat: 50.167703, lng: -123.8495932 },
      { lat: 50.1659107, lng: -123.853068 }
    ]
  }

  const queensReachEast = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 50.190383, lng: -123.937733 }, // Starting point (shoreline)
      { lat: 50.185833, lng: -123.943333 }, // Move to (in water)
      { lat: 50.158333, lng: -123.866667 }, // Move to (in water)
      { lat: 50.162500, lng: -123.857500 }, // Move to (shoreline)
    ]
  }

  const queensReachWest = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 50.1325061, lng: -123.850907 },
      { lat: 50.1292049, lng: -123.8534819 },
      { lat: 50.1221615, lng: -123.8464438 },
      { lat: 50.1209508, lng: -123.8433539 },
      { lat: 50.1175387, lng: -123.8447272 },
      { lat: 50.1042182, lng: -123.8323676 },
      { lat: 50.1036676, lng: -123.8272178 },
      { lat: 50.0993734, lng: -123.8225829 },
      { lat: 50.1021262, lng: -123.8181197 },
      { lat: 50.1292049, lng: -123.8364875 },
      { lat: 50.1325061, lng: -123.850907 } 
    ]
  }

  const sabineJervisJedediah = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.5400626, lng: -124.2809803 },
      { lat: 49.5146582, lng: -124.2919667 },
      { lat: 49.5040692, lng: -124.2859585 },
      { lat: 49.4992755, lng: -124.2708523 },
      { lat: 49.5016167, lng: -124.2634709 },
      { lat: 49.4918053, lng: -124.2562611 },
      { lat: 49.50262, lng: -124.2572911 },
      { lat: 49.5001674, lng: -124.2507679 },
      { lat: 49.5070789, lng: -124.2526562 },
      { lat: 49.5022855, lng: -124.2342884 },
      { lat: 49.499164, lng: -124.2396099 },
      { lat: 49.4906902, lng: -124.2215855 },
      { lat: 49.4832188, lng: -124.2202122 },
      { lat: 49.4768615, lng: -124.2102558 },
      { lat: 49.4793153, lng: -124.2092259 },
      { lat: 49.4796499, lng: -124.2032177 },
      { lat: 49.4744076, lng: -124.1891415 },
      { lat: 49.4711727, lng: -124.1877682 },
      { lat: 49.4720651, lng: -124.18279 },
      { lat: 49.4705034, lng: -124.1760952 },
      { lat: 49.4668221, lng: -124.1762669 },
      { lat: 49.4654833, lng: -124.1807301 },
      { lat: 49.460909, lng: -124.1695721 },
      { lat: 49.4564458, lng: -124.1668255 },
      { lat: 49.4590122, lng: -124.1374714 },
      { lat: 49.4866759, lng: -124.1695721 },
      { lat: 49.5041807, lng: -124.194978 },
      { lat: 49.5058528, lng: -124.2002995 },
      { lat: 49.5065216, lng: -124.204076 },
      { lat: 49.5096427, lng: -124.2076809 },
      { lat: 49.5106458, lng: -124.2138607 },
      { lat: 49.5400626, lng: -124.2809803 } 
    ]
  }

  const sabineJervisJedediah2 = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      // { lat: 49.501667, lng: -124.193050 },
      // { lat: 49.486667, lng: -124.170000 },
      // { lat: 49.460000, lng: -124.141667 },
      // { lat: 49.458333, lng: -124.167783 }
    ]
  }

  const salmonInlet = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.6486821, lng: -123.7191141 },
      { lat: 49.6367325, lng: -123.7187708 },
      { lat: 49.6366769, lng: -123.7092436 },
      { lat: 49.6408972, lng: -123.6910256 },
      { lat: 49.6408972, lng: -123.6879357 },
      { lat: 49.6370063, lng: -123.6796959 },
      { lat: 49.6380068, lng: -123.6750611 },
      { lat: 49.6368951, lng: -123.6699112 },
      { lat: 49.6377845, lng: -123.6621865 },
      { lat: 49.6425646, lng: -123.645192 },
      { lat: 49.6466774, lng: -123.6409004 },
      { lat: 49.6509009, lng: -123.631974 },
      { lat: 49.6533459, lng: -123.6314591 },
      { lat: 49.6532348, lng: -123.6173828 },
      { lat: 49.6560131, lng: -123.6115463 },
      { lat: 49.6560131, lng: -123.6063107 },
      { lat: 49.6598469, lng: -123.5968693 },
      { lat: 49.6606248, lng: -123.5915478 },
      { lat: 49.6637381, lng: -123.5880268 },
      { lat: 49.6669603, lng: -123.5863102 },
      { lat: 49.6690712, lng: -123.5768688 },
      { lat: 49.66946, lng: -123.5707748 },
      { lat: 49.6715709, lng: -123.5615051 },
      { lat: 49.6743481, lng: -123.557986 },
      { lat: 49.6756812, lng: -123.5572994 },
      { lat: 49.6777362, lng: -123.553437 },
      { lat: 49.6831232, lng: -123.5592735 },
      { lat: 49.6806242, lng: -123.5627067 },
      { lat: 49.6829566, lng: -123.5648525 },
      { lat: 49.6831232, lng: -123.5724914 },
      { lat: 49.6799577, lng: -123.576783 },
      { lat: 49.67968, lng: -123.5715473 },
      { lat: 49.6782916, lng: -123.5745514 },
      { lat: 49.6777362, lng: -123.5805595 },
      { lat: 49.6776806, lng: -123.586396 },
      { lat: 49.6745703, lng: -123.5983265 },
      { lat: 49.672904, lng: -123.5996139 },
      { lat: 49.6701822, lng: -123.6109436 },
      { lat: 49.6671825, lng: -123.6208999 },
      { lat: 49.662627, lng: -123.6292255 },
      { lat: 49.665627, lng: -123.6337745 },
      { lat: 49.6652937, lng: -123.6388386 },
      { lat: 49.663127, lng: -123.6422718 },
      { lat: 49.6630159, lng: -123.6454475 },
      { lat: 49.6634048, lng: -123.6521423 },
      { lat: 49.6602935, lng: -123.6572922 },
      { lat: 49.6539593, lng: -123.6610687 },
      { lat: 49.6495137, lng: -123.6637295 },
      { lat: 49.6486246, lng: -123.668021 },
      { lat: 49.650514, lng: -123.6701668 },
      { lat: 49.6511809, lng: -123.6759174 },
      { lat: 49.6527924, lng: -123.6790073 },
      { lat: 49.6535148, lng: -123.6876762 },
      { lat: 49.6517922, lng: -123.6955726 },
      { lat: 49.6515143, lng: -123.7037266 },
      { lat: 49.648569, lng: -123.715657 },
      { lat: 49.6486821, lng: -123.7191141 } 
    ]
  }

  const salmonInlet2 = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      // { lat: 49.676324, lng: -123.555737 },
      // { lat: 49.682427, lng: -123.560232 }
    ]
  }

  const sinclairBank = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.723333, lng: -124.312500 }, // Starting point (in water)
      { lat: 49.723333, lng: -124.250000 }, // Move to (in water)
      { lat: 49.685000, lng: -124.250000 }, // Move to (in water)
      { lat: 49.685000, lng: -124.312500 }, // Move to (in water)
      { lat: 49.723333, lng: -124.312500 }  // Return to the beginning point
    ]
  }

  const skookumchuckNarrows = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.746667, lng: -123.906950 },
      { lat: 49.69953, lng: -123.89002 },
      { lat: 49.625000, lng: -123.843333 },
      { lat: 49.625000, lng: -123.828333 },
      { lat: 49.725283, lng: -123.879683 },
      { lat: 49.725283, lng: -123.867483 },
      { lat: 49.746667, lng: -123.891217 },
    ]
  }

  const skookumchuckNarrows2 = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      // { lat: 49.746667, lng: -123.906950 },
      // { lat: 49.746667, lng: -123.891217 }
    ]
  }

  const thormanbyIsland = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      { lat: 49.508102, lng: -123.9831386 },
      { lat: 49.5071213, lng: -123.9804744 },
      { lat: 49.5089884, lng: -123.981204 },
      { lat: 49.5062296, lng: -123.978672 },
      { lat: 49.5061181, lng: -123.9776849 },
      { lat: 49.5090441, lng: -123.978629 },
      { lat: 49.5070935, lng: -123.9760541 },
      { lat: 49.5067033, lng: -123.9744663 },
      { lat: 49.507818, lng: -123.9745092 },
      { lat: 49.5056722, lng: -123.9717197 },
      { lat: 49.5026902, lng: -123.9681577 },
      { lat: 49.502523, lng: -123.9686298 },
      { lat: 49.5036378, lng: -123.9711618 },
      { lat: 49.5010738, lng: -123.9679431 },
      { lat: 49.4978128, lng: -123.9611625 },
      { lat: 49.496447, lng: -123.9620637 },
      { lat: 49.4952206, lng: -123.9564847 },
      { lat: 49.4920707, lng: -123.9542531 },
      { lat: 49.5100526, lng: -123.9329276 },
      { lat: 49.5100526, lng: -123.9370475 },
      { lat: 49.5122818, lng: -123.93679 },
      { lat: 49.5116688, lng: -123.9408241 },
      { lat: 49.5091052, lng: -123.9387641 },
      { lat: 49.5073774, lng: -123.9412532 },
      { lat: 49.509774, lng: -123.9475188 },
      { lat: 49.5071545, lng: -123.9461456 },
      { lat: 49.509774, lng: -123.9537845 },
      { lat: 49.5031972, lng: -123.9444289 },
      { lat: 49.5038661, lng: -123.9505229 },
      { lat: 49.5055382, lng: -123.9554153 },
      { lat: 49.5094953, lng: -123.96417 },
      { lat: 49.5126719, lng: -123.9662299 },
      { lat: 49.5122261, lng: -123.9708648 },
      { lat: 49.5184116, lng: -123.9866576 },
      { lat: 49.508102, lng: -123.9831386 } 
    ]
  }

  const thormanbyIsland2 = {
    "type":"rockfish closure",
    "area": "16",
    "coordinates": [
      // { lat: 49.492217, lng: -123.954000 },
      // { lat: 49.509783, lng: -123.933650 }
    ]
  }

  const ajaxAchillesBank = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.658333, lng: -124.723667 }, // Starting point (in water)
      { lat: 49.681667, lng: -124.660000 }, // Move to (in water)
      { lat: 49.550000, lng: -124.475000 }, // Move to (in water)
      { lat: 49.532500, lng: -124.487500 }, // Move to (in water)
      { lat: 49.658333, lng: -124.723667 }  // Return to the beginning point
    ]
  }

  const baynesSound = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.520000, lng: -124.801667 }, // Starting point (in water)
      { lat: 49.503333, lng: -124.775000 }, // Move to (in water)
      { lat: 49.498333, lng: -124.785000 }, // Move to (in water)
      { lat: 49.513333, lng: -124.811667 }, // Move to (in water)
      { lat: 49.520000, lng: -124.801667 }  // Return to the beginning point
    ]
  }

  const chromeIsland = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.474817, lng: -124.685483 }, // Boyle Point
      { lat: 49.472200, lng: -124.685200 }, // Chrome Island Light
      { lat: 49.467783, lng: -124.728333 }, // Mapleguard Point
      { lat: 49.471850, lng: -124.736583 }, // Mapleguard Buoy
      { lat: 49.482967, lng: -124.732433 }  // Repulse Point
    ]
  }

  const eastHornbyIsland = {
    "type":"sponge reef closure",
    "area": "14",
    "coordinates": [
      { lat: 49.558167, lng: -124.487167 }, // Starting point
      { lat: 49.545017, lng: -124.479333 }, // Move to
      { lat: 49.527617, lng: -124.490567 }, // Move to
      { lat: 49.527717, lng: -124.498267 }, // Move to
      { lat: 49.544183, lng: -124.496200 }, // Move to
      { lat: 49.555667, lng: -124.498917 }, // Move to
      { lat: 49.558300, lng: -124.496217 }, // Move to
      { lat: 49.558167, lng: -124.487167 }  // Return to the beginning point
    ]
  }

  const lasquetiIslandSouth = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.455250, lng: -124.221667 }, // Starting point (east of Boat Cove)
      { lat: 49.430000, lng: -124.221667 }, // Move to (in water)
      { lat: 49.430000, lng: -124.304167 }, // Move to (in water)
      { lat: 49.460000, lng: -124.304167 }, // Move to (near Jenkins Creek)
      { lat: 49.46963878691769, lng: -124.24357118102527 }
    ]
  }

  const mitlenatchIsland = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.975000, lng: -125.031667 }, // Starting point (in water)
      { lat: 49.975000, lng: -124.968333 }, // Move to (in water)
      { lat: 49.925000, lng: -124.968333 }, // Move to (in water)
      { lat: 49.925000, lng: -125.031667 }, // Move to (in water)
      { lat: 49.975000, lng: -125.031667 }  // Return to the beginning point
    ]
  }

  const oysterBay = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.926667, lng: -125.182450 }, // Starting point (south of Shelter Point)
      { lat: 49.926667, lng: -125.101667 }, // Move to (in water)
      { lat: 49.898333, lng: -125.150000 }, // Move to (shoreline)
      // Continue with coordinates for crossing Oyster Bay as needed
      // ...
      { lat: 49.926667, lng: -125.182450 }  // Return to the beginning point
    ]
  }

  const parksVille = {
    "type":"sponge reef closure",
    "area": "14",
    "coordinates": [
      { lat: 49.361333, lng: -124.329367 }, // Starting point
      { lat: 49.358567, lng: -124.314883 }, // Move to
      { lat: 49.353183, lng: -124.295383 }, // Move to
      { lat: 49.352733, lng: -124.295400 }, // Move to
      { lat: 49.345417, lng: -124.306333 }, // Move to
      { lat: 49.357200, lng: -124.330183 }, // Move to
      { lat: 49.361333, lng: -124.329367 }  // Return to the beginning point
    ]
  }

  const savoieRocks = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.512949, lng: -124.708270 },
      { lat: 49.510000, lng: -124.711667 },
      { lat: 49.495833, lng: -124.688333 },
      { lat: 49.496667, lng: -124.675367 }
    ]
  }

  const sisterIslets = {
    "type":"rockfish closure",
    "area": "14",
    "coordinates": [
      { lat: 49.498333, lng: -124.462500 }, // Starting point (in water)
      { lat: 49.498333, lng: -124.418333 }, // Move to (in water)
      { lat: 49.468333, lng: -124.418333 }, // Move to (in water)
      { lat: 49.468333, lng: -124.462500 }, // Move to (in water)
      { lat: 49.498333, lng: -124.462500 }  // Return to the beginning point
    ]
  }

  const brentWoodBay = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.579800, lng: -123.470333 }, // Starting point (Sluggett Point)
      { lat: 48.577450, lng: -123.487033 }, // Move to (Willis Point)
      { lat: 48.588333, lng: -123.491667 }, // Move to (in water)
      { lat: 48.598333, lng: -123.491667 }, // Move to (in water)
      { lat: 48.598950, lng: -123.480867 }, // Move to (Henderson Point)
    ]
  }

  const darcyIsland = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.572019, lng: -123.226776 },
      { lat: 48.571784, lng: -123.269090 },
      { lat: 48.570965, lng: -123.284587 },
      { lat: 48.568947, lng: -123.286648 },
      { lat: 48.565367, lng: -123.285000 },
      { lat: 48.540000, lng: -123.285000 },
      { lat: 48.540000, lng: -123.248333 },
      { lat: 48.446667, lng: -123.191667 },
      { lat: 48.400533, lng: -123.136583 },
      { lat: 48.422801, lng: -123.115183 },
      { lat: 48.453475, lng: -123.159847 },
      { lat: 48.548701, lng: -123.218938 },
      { lat: 48.572019, lng: -123.226776 }
    ]
  }

  const discoveryChathamIslands = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.4346465, lng: -123.2590502 },
      { lat: 48.4336784, lng: -123.2577627 },
      { lat: 48.4323116, lng: -123.2576769 },
      { lat: 48.4314574, lng: -123.2560461 },
      { lat: 48.4303753, lng: -123.2554453 },
      { lat: 48.4250786, lng: -123.2492655 },
      { lat: 48.4234268, lng: -123.2470339 },
      { lat: 48.4238824, lng: -123.2451456 },
      { lat: 48.4223445, lng: -123.2448023 },
      { lat: 48.4227433, lng: -123.2423132 },
      { lat: 48.421604, lng: -123.2445448 },
      { lat: 48.4208635, lng: -123.2424849 },
      { lat: 48.4193825, lng: -123.2422274 },
      { lat: 48.4198382, lng: -123.2411116 },
      { lat: 48.4192686, lng: -123.2393949 },
      { lat: 48.4195534, lng: -123.23682 },
      { lat: 48.4219458, lng: -123.227722 },
      { lat: 48.4226293, lng: -123.2252329 },
      { lat: 48.4232559, lng: -123.2218855 },
      { lat: 48.4474578, lng: -123.23785 },
      { lat: 48.4426753, lng: -123.2545011 },
      { lat: 48.439145, lng: -123.2623976 },
      { lat: 48.4346465, lng: -123.2590502 }
    ]
  }


  const discoveryChathamIslands2 = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      // { lat: 48.435000, lng: -123.258867 },
      // { lat: 48.438333, lng: -123.261667 },
      // { lat: 48.442500, lng: -123.255000 },
      // { lat: 48.447681, lng: -123.238446 },
      // { lat: 48.433333, lng: -123.228333 },
      // { lat: 48.423333, lng: -123.222217 },
      // { lat: 48.423450, lng: -123.225000 }
    ]
  }


  const discoveryChathamIslands3 = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      // { lat: 48.421883, lng: -123.227350 },
      // { lat: 48.419600, lng: -123.237417 }
    ]
  }

  const duntzeHead = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.430900, lng: -123.439583 },
      { lat: 48.425833, lng: -123.441667 },
      { lat: 48.419167, lng: -123.423333 },
      { lat: 48.421850, lng: -123.420133 }
    ]
  }

  const midFinlaysonArm = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates":  [
      { lat: 48.515000, lng: -123.535750 },
      { lat: 48.515000, lng: -123.540000 },
      { lat: 48.523333, lng: -123.540000 },
      { lat: 48.533333, lng: -123.545833 },
      { lat: 48.546667, lng: -123.548333 },
      { lat: 48.546972, lng: -123.536985 }
    ]
  }

  const raceRocks = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.3114563, lng: -123.5323947 },
      { lat: 48.3097437, lng: -123.5362571 },
      { lat: 48.3057475, lng: -123.5423511 },
      { lat: 48.3022078, lng: -123.5451835 },
      { lat: 48.2996384, lng: -123.5456127 },
      { lat: 48.2990104, lng: -123.5481018 },
      { lat: 48.2975258, lng: -123.5487026 },
      { lat: 48.2955844, lng: -123.5481018 },
      { lat: 48.294271, lng: -123.5442394 },
      { lat: 48.2933573, lng: -123.5412353 },
      { lat: 48.2919868, lng: -123.5400337 },
      { lat: 48.2890743, lng: -123.5387462 },
      { lat: 48.2866185, lng: -123.5402053 },
      { lat: 48.2853049, lng: -123.5409778 },
      { lat: 48.2842769, lng: -123.5402912 },
      { lat: 48.2838771, lng: -123.5367721 },
      { lat: 48.2858761, lng: -123.5315364 },
      { lat: 48.2847909, lng: -123.5278457 },
      { lat: 48.2847338, lng: -123.5248416 },
      { lat: 48.2834773, lng: -123.5183185 },
      { lat: 48.2842769, lng: -123.5157436 },
      { lat: 48.284848, lng: -123.5151428 },
      { lat: 48.286847, lng: -123.5163444 },
      { lat: 48.2896454, lng: -123.5137695 },
      { lat: 48.2906733, lng: -123.5135978 },
      { lat: 48.291587, lng: -123.5135978 },
      { lat: 48.296498, lng: -123.5154003 },
      { lat: 48.3010659, lng: -123.5205501 },
      { lat: 48.302322, lng: -123.5208934 },
      { lat: 48.3068322, lng: -123.5214084 },
      { lat: 48.3106, lng: -123.5211509 },
      { lat: 48.3119129, lng: -123.525185 },
      { lat: 48.3117417, lng: -123.5299056 },
      { lat: 48.3114563, lng: -123.5323947 }
    ]
  }

  const bentinckIsland = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.3202009, lng: -123.5405085 },
      { lat: 48.3184601, lng: -123.5399506 },
      { lat: 48.31729, lng: -123.5373756 },
      { lat: 48.314065, lng: -123.5374186 },
      { lat: 48.3130376, lng: -123.5378477 },
      { lat: 48.312638, lng: -123.5392639 },
      { lat: 48.3131232, lng: -123.5400364 },
      { lat: 48.3148642, lng: -123.5406801 },
      { lat: 48.3148927, lng: -123.5420534 },
      { lat: 48.3140936, lng: -123.5434267 },
      { lat: 48.31064, lng: -123.5432121 },
      { lat: 48.3097837, lng: -123.54377 },
      { lat: 48.3097267, lng: -123.5452291 },
      { lat: 48.3104688, lng: -123.5463879 },
      { lat: 48.3098123, lng: -123.547332 },
      { lat: 48.3100406, lng: -123.5486624 },
      { lat: 48.3107827, lng: -123.5487911 },
      { lat: 48.311639, lng: -123.5580179 }
    ]
  }

  const trialIsland = {
    "type":"rockfish closure",
    "area": "19",
    "coordinates": [
      { lat: 48.410000, lng: -123.308883 },
      { lat: 48.406167, lng: -123.298333 },
      { lat: 48.396667, lng: -123.300833 },
      { lat: 48.396667, lng: -123.310000 },
      { lat: 48.410000, lng: -123.308883 },
    ]
    }


    const shadwellPassage = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates":
      [
        {
            "lat": 50.9162499,
            "lng": -127.736515
        },
        {
            "lat": 50.9318136,
            "lng": -127.7999747
        },
        {
            "lat": 50.9337021,
            "lng": -127.8378869
        },
        {
            "lat": 50.9331798,
            "lng": -127.8382136
        },
        {
            "lat": 50.932152,
            "lng": -127.841561
        },
        {
            "lat": 50.92988,
            "lng": -127.8429343
        },
        {
            "lat": 50.9277702,
            "lng": -127.8445651
        },
        {
            "lat": 50.9259849,
            "lng": -127.8446509
        },
        {
            "lat": 50.9241454,
            "lng": -127.8459384
        },
        {
            "lat": 50.9212238,
            "lng": -127.8453376
        },
        {
            "lat": 50.9187348,
            "lng": -127.8455092
        },
        {
            "lat": 50.9171115,
            "lng": -127.8472259
        },
        {
            "lat": 50.9144599,
            "lng": -127.8469684
        },
        {
            "lat": 50.9105635,
            "lng": -127.8401877
        },
        {
            "lat": 50.9075869,
            "lng": -127.8355529
        },
        {
            "lat": 50.9073704,
            "lng": -127.8340938
        },
        {
            "lat": 50.905043,
            "lng": -127.832978
        },
        {
            "lat": 50.9037981,
            "lng": -127.8318622
        },
        {
            "lat": 50.9031486,
            "lng": -127.830918
        },
        {
            "lat": 50.9028238,
            "lng": -127.8300168
        },
        {
            "lat": 50.9010917,
            "lng": -127.8264548
        },
        {
            "lat": 50.896007,
            "lng": -127.8165439
        },
        {
            "lat": 50.8958446,
            "lng": -127.8152564
        },
        {
            "lat": 50.896007,
            "lng": -127.8133682
        },
        {
            "lat": 50.8961153,
            "lng": -127.8123382
        },
        {
            "lat": 50.8980641,
            "lng": -127.8092912
        },
        {
            "lat": 50.8972521,
            "lng": -127.8070167
        },
        {
            "lat": 50.8974416,
            "lng": -127.8065875
        },
        {
            "lat": 50.8985242,
            "lng": -127.8056863
        },
        {
            "lat": 50.8991468,
            "lng": -127.8039268
        },
        {
            "lat": 50.899228,
            "lng": -127.8024677
        },
        {
            "lat": 50.9000941,
            "lng": -127.8016523
        },
        {
            "lat": 50.9007978,
            "lng": -127.8019098
        },
        {
            "lat": 50.9015015,
            "lng": -127.7990344
        },
        {
            "lat": 50.9005813,
            "lng": -127.7971033
        },
        {
            "lat": 50.9008519,
            "lng": -127.796202
        },
        {
            "lat": 50.9003377,
            "lng": -127.7945712
        },
        {
            "lat": 50.9013661,
            "lng": -127.7943138
        },
        {
            "lat": 50.9018262,
            "lng": -127.7931121
        },
        {
            "lat": 50.9028817,
            "lng": -127.7921251
        },
        {
            "lat": 50.9031253,
            "lng": -127.7907947
        },
        {
            "lat": 50.9031524,
            "lng": -127.7890352
        },
        {
            "lat": 50.9040184,
            "lng": -127.7887777
        },
        {
            "lat": 50.9036125,
            "lng": -127.7873615
        },
        {
            "lat": 50.9030441,
            "lng": -127.787576
        },
        {
            "lat": 50.9025299,
            "lng": -127.7870181
        },
        {
            "lat": 50.9027735,
            "lng": -127.7861169
        },
        {
            "lat": 50.9035313,
            "lng": -127.784572
        },
        {
            "lat": 50.9032065,
            "lng": -127.7820829
        },
        {
            "lat": 50.9045597,
            "lng": -127.7817396
        },
        {
            "lat": 50.9046138,
            "lng": -127.780495
        },
        {
            "lat": 50.9043432,
            "lng": -127.77998
        },
        {
            "lat": 50.9045868,
            "lng": -127.7791217
        },
        {
            "lat": 50.9052092,
            "lng": -127.7783922
        },
        {
            "lat": 50.9048574,
            "lng": -127.7768472
        },
        {
            "lat": 50.9039643,
            "lng": -127.7757314
        },
        {
            "lat": 50.9036937,
            "lng": -127.773929
        },
        {
            "lat": 50.9045326,
            "lng": -127.7734998
        },
        {
            "lat": 50.9042079,
            "lng": -127.7715686
        },
        {
            "lat": 50.9055339,
            "lng": -127.7722124
        },
        {
            "lat": 50.9060211,
            "lng": -127.7735856
        },
        {
            "lat": 50.9069682,
            "lng": -127.7730707
        },
        {
            "lat": 50.9070765,
            "lng": -127.7716974
        },
        {
            "lat": 50.9068329,
            "lng": -127.7707103
        },
        {
            "lat": 50.9072659,
            "lng": -127.7695945
        },
        {
            "lat": 50.9080236,
            "lng": -127.7703241
        },
        {
            "lat": 50.9071306,
            "lng": -127.7687791
        },
        {
            "lat": 50.9075636,
            "lng": -127.7684358
        },
        {
            "lat": 50.9071577,
            "lng": -127.7675775
        },
        {
            "lat": 50.9083213,
            "lng": -127.76629
        },
        {
            "lat": 50.9078071,
            "lng": -127.7658609
        },
        {
            "lat": 50.9080507,
            "lng": -127.7649597
        },
        {
            "lat": 50.9070224,
            "lng": -127.7627281
        },
        {
            "lat": 50.9077801,
            "lng": -127.7617839
        },
        {
            "lat": 50.9086731,
            "lng": -127.7627281
        },
        {
            "lat": 50.9082672,
            "lng": -127.7611402
        },
        {
            "lat": 50.9095163,
            "lng": -127.7599756
        },
        {
            "lat": 50.9103822,
            "lng": -127.7573149
        },
        {
            "lat": 50.909408,
            "lng": -127.7504484
        },
        {
            "lat": 50.9088668,
            "lng": -127.7485601
        },
        {
            "lat": 50.9092971,
            "lng": -127.7488436
        },
        {
            "lat": 50.9093106,
            "lng": -127.7480711
        },
        {
            "lat": 50.9084312,
            "lng": -127.745818
        },
        {
            "lat": 50.9076585,
            "lng": -127.7421326
        },
        {
            "lat": 50.9054936,
            "lng": -127.7405877
        },
        {
            "lat": 50.9058183,
            "lng": -127.739386
        },
        {
            "lat": 50.9052771,
            "lng": -127.7379269
        },
        {
            "lat": 50.9044111,
            "lng": -127.7377552
        },
        {
            "lat": 50.9045193,
            "lng": -127.7367253
        },
        {
            "lat": 50.906522,
            "lng": -127.7372403
        },
        {
            "lat": 50.9061972,
            "lng": -127.7357811
        },
        {
            "lat": 50.9071173,
            "lng": -127.7357811
        },
        {
            "lat": 50.9063596,
            "lng": -127.7341504
        },
        {
            "lat": 50.9086869,
            "lng": -127.734322
        },
        {
            "lat": 50.9097151,
            "lng": -127.7350945
        },
        {
            "lat": 50.9104728,
            "lng": -127.7344079
        },
        {
            "lat": 50.9109599,
            "lng": -127.7359528
        },
        {
            "lat": 50.9131246,
            "lng": -127.736382
        },
        {
            "lat": 50.9129081,
            "lng": -127.735352
        },
        {
            "lat": 50.9152892,
            "lng": -127.7372403
        },
        {
            "lat": 50.9153433,
            "lng": -127.736382
        },
        {
            "lat": 50.9162499,
            "lng": -127.736515
        }
    ]
    }

    const shadwellPassage2 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        // { lat: 50.933333, lng: -127.837717 },
        // { lat: 50.930000, lng: -127.800000 },
        // { lat: 50.916000, lng: -127.735917 }
      ]
    }

    const goletasChannel = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": 
      [
        {
            "lat": 50.8808794,
            "lng": -127.8443085
        },
        {
            "lat": 50.8542824,
            "lng": -127.8578698
        },
        {
            "lat": 50.8548784,
            "lng": -127.854694
        },
        {
            "lat": 50.8537406,
            "lng": -127.8521191
        },
        {
            "lat": 50.8524943,
            "lng": -127.8507458
        },
        {
            "lat": 50.8520608,
            "lng": -127.849115
        },
        {
            "lat": 50.851844,
            "lng": -127.8474842
        },
        {
            "lat": 50.8517357,
            "lng": -127.8442227
        },
        {
            "lat": 50.8510312,
            "lng": -127.8415619
        },
        {
            "lat": 50.850381,
            "lng": -127.8391587
        },
        {
            "lat": 50.850381,
            "lng": -127.8377854
        },
        {
            "lat": 50.8493514,
            "lng": -127.8377854
        },
        {
            "lat": 50.849243,
            "lng": -127.834953
        },
        {
            "lat": 50.8500558,
            "lng": -127.8345238
        },
        {
            "lat": 50.8480508,
            "lng": -127.8274857
        },
        {
            "lat": 50.8470753,
            "lng": -127.827314
        },
        {
            "lat": 50.8472379,
            "lng": -127.8236233
        },
        {
            "lat": 50.8470211,
            "lng": -127.8199326
        },
        {
            "lat": 50.8468044,
            "lng": -127.8165852
        },
        {
            "lat": 50.8458831,
            "lng": -127.8145253
        },
        {
            "lat": 50.8464792,
            "lng": -127.8133236
        },
        {
            "lat": 50.8452327,
            "lng": -127.8104912
        },
        {
            "lat": 50.8438236,
            "lng": -127.8072296
        },
        {
            "lat": 50.8420893,
            "lng": -127.8045689
        },
        {
            "lat": 50.8426855,
            "lng": -127.8029381
        },
        {
            "lat": 50.8418725,
            "lng": -127.797445
        },
        {
            "lat": 50.8416557,
            "lng": -127.7898918
        },
        {
            "lat": 50.8421435,
            "lng": -127.7880894
        },
        {
            "lat": 50.8411679,
            "lng": -127.7869736
        },
        {
            "lat": 50.8416015,
            "lng": -127.7845703
        },
        {
            "lat": 50.8411137,
            "lng": -127.7810513
        },
        {
            "lat": 50.8398129,
            "lng": -127.7802788
        },
        {
            "lat": 50.8400839,
            "lng": -127.7783905
        },
        {
            "lat": 50.838241,
            "lng": -127.7753865
        },
        {
            "lat": 50.8378074,
            "lng": -127.7716099
        },
        {
            "lat": 50.838241,
            "lng": -127.7687775
        },
        {
            "lat": 50.8372112,
            "lng": -127.7672325
        },
        {
            "lat": 50.8372654,
            "lng": -127.7631985
        },
        {
            "lat": 50.8365607,
            "lng": -127.7584778
        },
        {
            "lat": 50.8354766,
            "lng": -127.7570187
        },
        {
            "lat": 50.8354766,
            "lng": -127.7553021
        },
        {
            "lat": 50.8338504,
            "lng": -127.7529847
        },
        {
            "lat": 50.8332541,
            "lng": -127.7502381
        },
        {
            "lat": 50.8321699,
            "lng": -127.7497231
        },
        {
            "lat": 50.8323325,
            "lng": -127.747749
        },
        {
            "lat": 50.8318446,
            "lng": -127.7466332
        },
        {
            "lat": 50.8320615,
            "lng": -127.7432858
        },
        {
            "lat": 50.8313567,
            "lng": -127.7386509
        },
        {
            "lat": 50.8293509,
            "lng": -127.735561
        },
        {
            "lat": 50.8294593,
            "lng": -127.7291237
        },
        {
            "lat": 50.828375,
            "lng": -127.7256905
        },
        {
            "lat": 50.8283208,
            "lng": -127.7245747
        },
        {
            "lat": 50.8273992,
            "lng": -127.7240597
        },
        {
            "lat": 50.8273992,
            "lng": -127.7213131
        },
        {
            "lat": 50.8250678,
            "lng": -127.7171933
        },
        {
            "lat": 50.8253931,
            "lng": -127.7123009
        },
        {
            "lat": 50.8239835,
            "lng": -127.7111851
        },
        {
            "lat": 50.8230617,
            "lng": -127.7061211
        },
        {
            "lat": 50.8205675,
            "lng": -127.7016579
        },
        {
            "lat": 50.8193203,
            "lng": -127.6992546
        },
        {
            "lat": 50.8196457,
            "lng": -127.6979672
        },
        {
            "lat": 50.8183985,
            "lng": -127.6932465
        },
        {
            "lat": 50.8184527,
            "lng": -127.6916157
        },
        {
            "lat": 50.8180189,
            "lng": -127.6888691
        },
        {
            "lat": 50.817314,
            "lng": -127.6860367
        },
        {
            "lat": 50.817802,
            "lng": -127.6835476
        },
        {
            "lat": 50.8160125,
            "lng": -127.6812302
        },
        {
            "lat": 50.8163379,
            "lng": -127.6784836
        },
        {
            "lat": 50.8146567,
            "lng": -127.6719605
        },
        {
            "lat": 50.8149821,
            "lng": -127.6689564
        },
        {
            "lat": 50.8138433,
            "lng": -127.6656948
        },
        {
            "lat": 50.813084,
            "lng": -127.6654374
        },
        {
            "lat": 50.8129756,
            "lng": -127.6632916
        },
        {
            "lat": 50.8114028,
            "lng": -127.6608025
        },
        {
            "lat": 50.8106977,
            "lng": -127.6567685
        },
        {
            "lat": 50.80983,
            "lng": -127.6559101
        },
        {
            "lat": 50.8094503,
            "lng": -127.6545369
        },
        {
            "lat": 50.8087995,
            "lng": -127.6541077
        },
        {
            "lat": 50.8089351,
            "lng": -127.6533781
        },
        {
            "lat": 50.8318989,
            "lng": -127.6547943
        },
        {
            "lat": 50.8345009,
            "lng": -127.6638924
        },
        {
            "lat": 50.8345551,
            "lng": -127.6664673
        },
        {
            "lat": 50.8354766,
            "lng": -127.6699864
        },
        {
            "lat": 50.8366149,
            "lng": -127.6707589
        },
        {
            "lat": 50.8372654,
            "lng": -127.6723896
        },
        {
            "lat": 50.8386747,
            "lng": -127.6741063
        },
        {
            "lat": 50.8401923,
            "lng": -127.6790844
        },
        {
            "lat": 50.8406259,
            "lng": -127.6814019
        },
        {
            "lat": 50.8403549,
            "lng": -127.6835476
        },
        {
            "lat": 50.8412763,
            "lng": -127.6869809
        },
        {
            "lat": 50.8419809,
            "lng": -127.6880108
        },
        {
            "lat": 50.8420893,
            "lng": -127.6892125
        },
        {
            "lat": 50.8428481,
            "lng": -127.6911866
        },
        {
            "lat": 50.8459373,
            "lng": -127.6971947
        },
        {
            "lat": 50.8457476,
            "lng": -127.6976668
        },
        {
            "lat": 50.8462624,
            "lng": -127.6993405
        },
        {
            "lat": 50.8466689,
            "lng": -127.70213
        },
        {
            "lat": 50.8475089,
            "lng": -127.7035891
        },
        {
            "lat": 50.847536,
            "lng": -127.7043616
        },
        {
            "lat": 50.8477527,
            "lng": -127.7052199
        },
        {
            "lat": 50.8477256,
            "lng": -127.7059065
        },
        {
            "lat": 50.8481863,
            "lng": -127.7068507
        },
        {
            "lat": 50.8485927,
            "lng": -127.7079235
        },
        {
            "lat": 50.8487553,
            "lng": -127.7093397
        },
        {
            "lat": 50.8490533,
            "lng": -127.7113139
        },
        {
            "lat": 50.8496223,
            "lng": -127.7126013
        },
        {
            "lat": 50.8498933,
            "lng": -127.7144038
        },
        {
            "lat": 50.8502997,
            "lng": -127.7155625
        },
        {
            "lat": 50.8504081,
            "lng": -127.7183949
        },
        {
            "lat": 50.8508958,
            "lng": -127.7207552
        },
        {
            "lat": 50.8514647,
            "lng": -127.7217852
        },
        {
            "lat": 50.8519253,
            "lng": -127.7217423
        },
        {
            "lat": 50.8518982,
            "lng": -127.722386
        },
        {
            "lat": 50.8516544,
            "lng": -127.7227293
        },
        {
            "lat": 50.8516263,
            "lng": -127.7240409
        },
        {
            "lat": 50.8520598,
            "lng": -127.7257575
        },
        {
            "lat": 50.8522766,
            "lng": -127.7273454
        },
        {
            "lat": 50.8521411,
            "lng": -127.7287616
        },
        {
            "lat": 50.8522224,
            "lng": -127.7314652
        },
        {
            "lat": 50.852114,
            "lng": -127.7322806
        },
        {
            "lat": 50.8523578,
            "lng": -127.7332248
        },
        {
            "lat": 50.8530623,
            "lng": -127.7340402
        },
        {
            "lat": 50.8529539,
            "lng": -127.7361001
        },
        {
            "lat": 50.8536854,
            "lng": -127.7382029
        },
        {
            "lat": 50.8545253,
            "lng": -127.7390613
        },
        {
            "lat": 50.8547962,
            "lng": -127.7396192
        },
        {
            "lat": 50.855636,
            "lng": -127.7405204
        },
        {
            "lat": 50.8563675,
            "lng": -127.742237
        },
        {
            "lat": 50.856828,
            "lng": -127.7438678
        },
        {
            "lat": 50.8579929,
            "lng": -127.7469577
        },
        {
            "lat": 50.8584535,
            "lng": -127.7467002
        },
        {
            "lat": 50.8582367,
            "lng": -127.7455844
        },
        {
            "lat": 50.8588056,
            "lng": -127.7453698
        },
        {
            "lat": 50.8591849,
            "lng": -127.747301
        },
        {
            "lat": 50.8594829,
            "lng": -127.7488459
        },
        {
            "lat": 50.8598621,
            "lng": -127.7500047
        },
        {
            "lat": 50.8597267,
            "lng": -127.7519359
        },
        {
            "lat": 50.8604039,
            "lng": -127.7527942
        },
        {
            "lat": 50.8607019,
            "lng": -127.755412
        },
        {
            "lat": 50.8624355,
            "lng": -127.7573432
        },
        {
            "lat": 50.8621375,
            "lng": -127.7586736
        },
        {
            "lat": 50.861352,
            "lng": -127.759961
        },
        {
            "lat": 50.8605664,
            "lng": -127.7598752
        },
        {
            "lat": 50.8596996,
            "lng": -127.7580727
        },
        {
            "lat": 50.8584805,
            "lng": -127.7582444
        },
        {
            "lat": 50.8577762,
            "lng": -127.7592315
        },
        {
            "lat": 50.8575053,
            "lng": -127.7601756
        },
        {
            "lat": 50.8576949,
            "lng": -127.7619351
        },
        {
            "lat": 50.857126,
            "lng": -127.7628364
        },
        {
            "lat": 50.857126,
            "lng": -127.7642096
        },
        {
            "lat": 50.8573698,
            "lng": -127.7660121
        },
        {
            "lat": 50.8575053,
            "lng": -127.7688874
        },
        {
            "lat": 50.85802,
            "lng": -127.7684153
        },
        {
            "lat": 50.8582909,
            "lng": -127.7705611
        },
        {
            "lat": 50.8580471,
            "lng": -127.7709903
        },
        {
            "lat": 50.8583451,
            "lng": -127.7721061
        },
        {
            "lat": 50.8578575,
            "lng": -127.7721061
        },
        {
            "lat": 50.8579387,
            "lng": -127.7737798
        },
        {
            "lat": 50.8588327,
            "lng": -127.7744664
        },
        {
            "lat": 50.859212,
            "lng": -127.7737798
        },
        {
            "lat": 50.8592661,
            "lng": -127.7747668
        },
        {
            "lat": 50.8599705,
            "lng": -127.7742089
        },
        {
            "lat": 50.8609186,
            "lng": -127.7752389
        },
        {
            "lat": 50.8628418,
            "lng": -127.7766551
        },
        {
            "lat": 50.8631127,
            "lng": -127.7794875
        },
        {
            "lat": 50.8639524,
            "lng": -127.7810325
        },
        {
            "lat": 50.8641961,
            "lng": -127.7829636
        },
        {
            "lat": 50.8643316,
            "lng": -127.7851952
        },
        {
            "lat": 50.8641691,
            "lng": -127.7860106
        },
        {
            "lat": 50.8644941,
            "lng": -127.7872123
        },
        {
            "lat": 50.8640607,
            "lng": -127.7913321
        },
        {
            "lat": 50.8634648,
            "lng": -127.793435
        },
        {
            "lat": 50.8637898,
            "lng": -127.7955808
        },
        {
            "lat": 50.8637898,
            "lng": -127.796482
        },
        {
            "lat": 50.8634919,
            "lng": -127.7975549
        },
        {
            "lat": 50.8636815,
            "lng": -127.7984561
        },
        {
            "lat": 50.8633294,
            "lng": -127.7986707
        },
        {
            "lat": 50.8642774,
            "lng": -127.8002585
        },
        {
            "lat": 50.8645212,
            "lng": -127.8012456
        },
        {
            "lat": 50.8646295,
            "lng": -127.8024472
        },
        {
            "lat": 50.8654692,
            "lng": -127.8038634
        },
        {
            "lat": 50.8658479,
            "lng": -127.80601
        },
        {
            "lat": 50.8657938,
            "lng": -127.8067825
        },
        {
            "lat": 50.8667688,
            "lng": -127.8068683
        },
        {
            "lat": 50.8677439,
            "lng": -127.8103874
        },
        {
            "lat": 50.8688002,
            "lng": -127.8116748
        },
        {
            "lat": 50.8689627,
            "lng": -127.8107736
        },
        {
            "lat": 50.8697481,
            "lng": -127.8106878
        },
        {
            "lat": 50.8708043,
            "lng": -127.8132627
        },
        {
            "lat": 50.8714272,
            "lng": -127.8159664
        },
        {
            "lat": 50.8717793,
            "lng": -127.8187559
        },
        {
            "lat": 50.8722938,
            "lng": -127.8204295
        },
        {
            "lat": 50.8724834,
            "lng": -127.8215024
        },
        {
            "lat": 50.8728355,
            "lng": -127.8223178
        },
        {
            "lat": 50.8733771,
            "lng": -127.8241632
        },
        {
            "lat": 50.8734042,
            "lng": -127.8253219
        },
        {
            "lat": 50.8736479,
            "lng": -127.826266
        },
        {
            "lat": 50.8734854,
            "lng": -127.8269527
        },
        {
            "lat": 50.8741083,
            "lng": -127.8301713
        },
        {
            "lat": 50.8753269,
            "lng": -127.8309438
        },
        {
            "lat": 50.8765996,
            "lng": -127.8316305
        },
        {
            "lat": 50.8769517,
            "lng": -127.8329179
        },
        {
            "lat": 50.8776286,
            "lng": -127.8345487
        },
        {
            "lat": 50.8771683,
            "lng": -127.8348062
        },
        {
            "lat": 50.8749207,
            "lng": -127.8328321
        },
        {
            "lat": 50.8742979,
            "lng": -127.8335616
        },
        {
            "lat": 50.8747041,
            "lng": -127.8347204
        },
        {
            "lat": 50.8754081,
            "lng": -127.8367374
        },
        {
            "lat": 50.8757873,
            "lng": -127.8367374
        },
        {
            "lat": 50.8758685,
            "lng": -127.837939
        },
        {
            "lat": 50.8764643,
            "lng": -127.8393123
        },
        {
            "lat": 50.8771954,
            "lng": -127.8393123
        },
        {
            "lat": 50.8773579,
            "lng": -127.8378961
        },
        {
            "lat": 50.877737,
            "lng": -127.837939
        },
        {
            "lat": 50.8775474,
            "lng": -127.8391836
        },
        {
            "lat": 50.8778453,
            "lng": -127.8414152
        },
        {
            "lat": 50.8789284,
            "lng": -127.8418872
        },
        {
            "lat": 50.8793617,
            "lng": -127.8433034
        },
        {
            "lat": 50.8808794,
            "lng": -127.8443085
        }
    ]
    }

    const goletasChannel2 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        // { lat: 50.831973, lng: -127.654831 },
        // { lat: 50.809560, lng: -127.654591 }
      ]
    }

    const browningPassageHuntRock = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.901317, lng: -127.696950 },
        { lat: 50.910000, lng: -127.681117 },
        { lat: 50.875550, lng: -127.648083 },
        { lat: 50.867850, lng: -127.645617 }
      ]
    }

    const browningPassageHuntRock2 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.842233, lng: -127.631317 },
        { lat: 50.842233, lng: -127.660683 }
      ]
    }

    const bolivarPassage = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.899600, lng: -127.517583 },
        { lat: 50.902633, lng: -127.519233 },
        { lat: 50.905000, lng: -127.521317 },
        { lat: 50.927517, lng: -127.543383 },
        { lat: 50.918333, lng: -127.582133 },
        { lat: 50.918333, lng: -127.581667 },
        { lat: 50.907283, lng: -127.570967 },
        { lat: 50.902500, lng: -127.581267 },
        { lat: 50.900000, lng: -127.587600 },
        { lat: 50.893333, lng: -127.596667 },
        { lat: 50.871667, lng: -127.556667 },
        { lat: 50.885000, lng: -127.527733 }
      ]
    }

    const shelterBay = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.926850, lng: -127.408017 },
        { lat: 50.927817, lng: -127.410283 },
        { lat: 50.929567, lng: -127.415667 },
        { lat: 50.955517, lng: -127.503717 },
        { lat: 50.968333, lng: -127.503333 },
        { lat: 50.975333, lng: -127.469250 }
      ]
    }

    const browningIslandsRaynorGroup = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.872450, lng: -127.180483 },
        { lat: 50.871667, lng: -127.212217 },
        { lat: 50.879833, lng: -127.237550 },
        { lat: 50.885383, lng: -127.246700 },
        { lat: 50.884900, lng: -127.249450 },
        { lat: 50.898617, lng: -127.313783 },
        { lat: 50.895567, lng: -127.327567 },
        { lat: 50.898467, lng: -127.334750 },
        { lat: 50.916950, lng: -127.365650 }
      ]
    }

    const druryInletMuirheadIslands = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.936233, lng: -127.140467 },
        { lat: 50.934233, lng: -127.137683 }
      ]
    }

    const druryInletMuirheadIslands2 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.920483, lng: -127.083333 },
        { lat: 50.900117, lng: -127.083333 }
      ]
    }

    const hardyBay = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        {"lat": 50.745892, "lng": -127.4871866},
        {"lat": 50.7456204, "lng": -127.4885599},
        {"lat": 50.7450773, "lng": -127.4893752},
        {"lat": 50.7443713, "lng": -127.4898473},
        {"lat": 50.7434751, "lng": -127.4898473},
        {"lat": 50.742769, "lng": -127.4895469},
        {"lat": 50.7422259, "lng": -127.4885599},
        {"lat": 50.7419, "lng": -127.4873153},
        {"lat": 50.7417914, "lng": -127.4861566},
        {"lat": 50.7419, "lng": -127.4848691},
        {"lat": 50.7422802, "lng": -127.4837104},
        {"lat": 50.7428777, "lng": -127.4830667},
        {"lat": 50.7435566, "lng": -127.4826804},
        {"lat": 50.7443441, "lng": -127.4825946},
        {"lat": 50.7449687, "lng": -127.4830667},
        {"lat": 50.7455118, "lng": -127.4837962},
        {"lat": 50.7458377, "lng": -127.484912},
        {"lat": 50.7460277, "lng": -127.4862424},
        {"lat": 50.745892, "lng": -127.4871866}
      ]
    }

    const numasIslands = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.798333, lng: -127.126667 },
        { lat: 50.776667, lng: -127.040000 },
        { lat: 50.738333, lng: -127.066667 },
        { lat: 50.761667, lng: -127.155000 },
        { lat: 50.798333, lng: -127.126667 },
      ]
    }

    const susquash = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.658933, lng: -127.282450 },
        { lat: 50.665000, lng: -127.266667 },
        { lat: 50.630000, lng: -127.196667 },
        { lat: 50.621667, lng: -127.206667 }
      ]
    }


    const haddingtonPassage = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.602583, lng: -127.038283 },
        { lat: 50.610000, lng: -127.006667 },
        { lat: 50.582817, lng: -127.023517 },
        { lat: 50.602583, lng: -127.038283 },
      ]
    }

    const burleyBay = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.934267, lng: -126.775000 },
        { lat: 50.925683, lng: -126.775000 }
      ]
    }

    const burleyBay2 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.920400, lng: -126.801667 },
        { lat: 50.921450, lng: -126.801667 }
      ]
    }

    const burleyBay3 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.947633, lng: -126.822200 },
        { lat: 50.956950, lng: -126.823450 }
      ]
    }

    const mackenzieNimmo = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.939383, lng: -126.685000 },
        { lat: 50.937683, lng: -126.685000 }
      ]
    }

    const mackenzieNimmo2 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.934083, lng: -126.685000 },
        { lat: 50.928767, lng: -126.685000 }
      ]
    }

    const mackenzieNimmo3 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.928950, lng: -126.734467 },
        { lat: 50.939333, lng: -126.734467 }
      ]
    }

    const wakemanSound = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.996519, lng: -126.503092 },
        { lat: 50.995731, lng: -126.542812 }
    ]
    }

    const sicksonPolkinghorne = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.838333, lng: -126.947500 },
        { lat: 50.834167, lng: -126.968333 },
        { lat: 50.820333, lng: -126.956367 },
        { lat: 50.813667, lng: -126.940750 },
        { lat: 50.803600, lng: -126.942350 }
      ]
    }

    const sicksonPolkinghorne2 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.786667, lng: -126.914833 },
        { lat: 50.786383, lng: -126.910000 },
        { lat: 50.805000, lng: -126.883333 }
      ]
    }

    const sicksonPolkinghorne3 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.845000, lng: -126.926233 },
        { lat: 50.842500, lng: -126.933883 }
      ]
    }

    const sicksonPolkinghorne4 = {
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.837083, lng: -126.905000 },
        { lat: 50.836583, lng: -126.905000 }
      ]
    }

    const norwellChannel = {  
      "type":"rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.782883, lng: -126.848183 },
        { lat: 50.750000, lng: -126.786667 },
        { lat: 50.763033, lng: -126.757850 }
      ]
    }

    const norwellChannel2 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.767633, lng: -126.746233 },
        { lat: 50.768050, lng: -126.741750 }
      ]
    };
    
    const norwellChannel3 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.766833, lng: -126.750800 },
        { lat: 50.766933, lng: -126.750250 }
      ]
    };
    
    const greenwaySound = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.862583, lng: -126.733933 },
        { lat: 50.859783, lng: -126.706167 }
      ]
    };
    
    const greenwaySound2 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.837450, lng: -126.814500 },
        { lat: 50.838750, lng: -126.814500 }
      ]
    };
    
    const belleisleSound = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.903333, lng: -126.420800 },
        { lat: 50.903333, lng: -126.417383 }
      ]
    };
    
    const kwatsiBay = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.846200, lng: -126.280167 },
        { lat: 50.843900, lng: -126.261350 }
      ]
    };
    
    const bondSound = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.832733, lng: -126.215683 },
        { lat: 50.825683, lng: -126.192117 },
        { lat: 50.873383, lng: -126.179267 }
      ]
    };
    
    const thompsonSound = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.769467, lng: -126.138883 },
        { lat: 50.743283, lng: -126.123933 }
      ]
    };
    
    const salmonChannel = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.708333, lng: -126.871667 },
        { lat: 50.736667, lng: -126.815000 },
        { lat: 50.735000, lng: -126.804167 },
        { lat: 50.688750, lng: -126.808400 },
        { lat: 50.696667, lng: -126.848333 },
        { lat: 50.708333, lng: -126.871667 },
      ]
    };
    
    const weyntonPassage = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.583883, lng: -126.885550 },
        { lat: 50.618700, lng: -126.830000 },
        { lat: 50.586667, lng: -126.805400 },
        { lat: 50.571567, lng: -126.787500 },
        { lat: 50.564450, lng: -126.775550 },
        { lat: 50.574450, lng: -126.863883 }
      ]
    };
    
    const edenBonwickMisummer = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.703933, lng: -126.677683 },
        { lat: 50.701583, lng: -126.689767 },
        { lat: 50.715550, lng: -126.694267 },
        { lat: 50.726883, lng: -126.686167 },
        { lat: 50.740033, lng: -126.686300 }
      ]
    };
    
    const edenBonwickMisummer2 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.758500, lng: -126.713850 },
        { lat: 50.750000, lng: -126.750000 },
        { lat: 50.725000, lng: -126.750000 },
        { lat: 50.725000, lng: -126.718333 },
        { lat: 50.720000, lng: -126.724450 },
        { lat: 50.678333, lng: -126.701667 },
        { lat: 50.638500, lng: -126.721217 },
        { lat: 50.634583, lng: -126.722317 },
        { lat: 50.628183, lng: -126.726200 }
      ]
    };
    
    const edenBonwickMisummer3 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.602833, lng: -126.687033 },
        { lat: 50.595617, lng: -126.670033 },
        { lat: 50.592583, lng: -126.670100 }
      ]
    };
    
    const edenBonwickMisummer4 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.588850, lng: -126.605617 },
        { lat: 50.604083, lng: -126.604583 },
        { lat: 50.606433, lng: -126.577133 },
        { lat: 50.611917, lng: -126.564383 }
      ]
    };
    
    const edenBonwickMisummer5 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.631117, lng: -126.572500 },
        { lat: 50.660000, lng: -126.618333 },
        { lat: 50.661667, lng: -126.631667 },
        { lat: 50.659150, lng: -126.671533 },
        { lat: 50.666667, lng: -126.693333 },
        { lat: 50.677783, lng: -126.692800 },
        { lat: 50.679267, lng: -126.689217 },
        { lat: 50.685583, lng: -126.669200 }
      ]
    };
    
    const portElizabeth = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.672833, lng: -126.460967 },
        { lat: 50.669067, lng: -126.469467 }
      ]
    };
    
    const lowerClioChannel = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.589950, lng: -126.533033 },
        { lat: 50.582383, lng: -126.534667 }
      ]
    };
    
    const lowerClioChannel2 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.556617, lng: -126.553183 },
        { lat: 50.551900, lng: -126.553183 }
      ]
    };
    
    const lowerClioChannel3 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.589267, lng: -126.459050 },
        { lat: 50.571433, lng: -126.459050 }
      ]
    };    

    const viscountIsland = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.650083, lng: -126.300850 },
        { lat: 50.649950, lng: -126.266417 }
      ]
    };
    
    const viscountIsland2 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.656700, lng: -126.238533 },
        { lat: 50.664100, lng: -126.208217 }
      ]
    };
    
    const viscountIsland3 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.666550, lng: -126.196567 },
        { lat: 50.668483, lng: -126.184817 }
      ]
    };
    
    const viscountIsland4 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.688333, lng: -126.201283 },
        { lat: 50.688333, lng: -126.197217 }
      ]
    };
    
    const viscountIsland5 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.725867, lng: -126.202117 },
        { lat: 50.713283, lng: -126.198383 }
      ]
    };
    
    const havannahChannel = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.520000, lng: -126.415250 },
        { lat: 50.516667, lng: -126.409517 },
        { lat: 50.512317, lng: -126.349883 },
        { lat: 50.511667, lng: -126.277483 }
      ]
    };
    
    const havannahChannel2 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.575550, lng: -126.172450 },
        { lat: 50.585000, lng: -126.180350 }
      ]
    };
    
    const havannahChannel3 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.585000, lng: -126.210367 },
        { lat: 50.578950, lng: -126.216667 }
      ]
    };
    
    const havannahChannel4 = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.554167, lng: -126.279283 },
        { lat: 50.554167, lng: -126.269200 }
      ]
    };
    
    const upperCallInlet = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.597900, lng: -126.130917 },
        { lat: 50.593017, lng: -126.126117 }
      ]
    };
    
    const cracroftPointSouth = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.548150, lng: -126.677217 },
        { lat: 50.530133, lng: -126.625433 },
        { lat: 50.533883, lng: -126.619800 }
      ]
    };
    
    const westCracroftIsland = {
      "type": "rockfish closure",
      "area": "12",
      "coordinates": [
        { lat: 50.520050, lng: -126.520217 },
        { lat: 50.511667, lng: -126.520000 },
        { lat: 50.513333, lng: -126.561667 },
        { lat: 50.525000, lng: -126.559267 }
      ]
    };
    
    const wellborneChannel = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.474607, lng: -125.912676 },
        { lat: 50.460063, lng: -125.902425 }
      ]
    };
    
    const wellborneChannel2 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.496414, lng: -125.846662 },
        { lat: 50.489920, lng: -125.817061 }
      ]
    };
    
    const wellborneChannel3 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.478522, lng: -125.782402 },
        { lat: 50.475615, lng: -125.794278 }
      ]
    };
    
    const forwardHarbour = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.473284, lng: -125.770568 },
        { lat: 50.466447, lng: -125.769355 }
      ]
    };
    
    const loughboroughInlet = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.550343, lng: -125.549977 },
        { lat: 50.559553, lng: -125.535768 }
      ]
    };
    
    const buteInletNorth = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.836667, lng: -124.912833 },
        { lat: 50.831667, lng: -124.860483 }
      ]
    };
    
    const chancellorChannel = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.441017, lng: -125.736667 },
        { lat: 50.438217, lng: -125.743517 }
      ]
    };
    
    const chancellorChannel2 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.423333, lng: -125.739783 },
        { lat: 50.413433, lng: -125.726183 }
      ]
    };
    
    const chancellorChannel3 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.440000, lng: -125.632600 },
        { lat: 50.427533, lng: -125.625000 }
      ]
    };
    
    const chancellorChannelEast = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.449350, lng: -125.593200 },
        { lat: 50.438333, lng: -125.590817 }
      ]
    };
    
    const chancellorChannelEast2 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.450804, lng: -125.529488 },
        { lat: 50.446121, lng: -125.525103 }
      ]
    };
    
    const walkemIslandsHemmingBay = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.375567, lng: -125.548817 },
        { lat: 50.357617, lng: -125.525183 },
        { lat: 50.345117, lng: -125.465500 },
        { lat: 50.343333, lng: -125.458333 },
        { lat: 50.343333, lng: -125.451667 },
        { lat: 50.365000, lng: -125.375000 },
        { lat: 50.388333, lng: -125.351667 },
        { lat: 50.391767, lng: -125.355817 }
      ]
    };
    
    const thurstonBay = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.375400, lng: -125.331200 },
        { lat: 50.349800, lng: -125.350000 }
      ]
    };
    
    const kanishBay = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.271584, lng: -125.363318 },
        { lat: 50.248343, lng: -125.363168 }
      ]
    };
    
    const octopusIslandsHoskynChannel = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.293050, lng: -125.224800 },
        { lat: 50.295833, lng: -125.210917 }
      ]
    };
    
    const octopusIslandsHoskynChannel2 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.165067, lng: -125.160983 },
        { lat: 50.143400, lng: -125.152400 }
      ]
    };
    
    const octopusIslandsHoskynChannel3 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.276450, lng: -125.089050 },
        { lat: 50.275911, lng: -125.075169 }
      ]
    };

    const readCortesIslands = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.178217, lng: -125.080133 },
        { lat: 50.178650, lng: -125.011667 },
        { lat: 50.179683, lng: -124.985850 }
      ]
    };
    
    const readCortesIslands2 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.148617, lng: -125.101933 },
        { lat: 50.130000, lng: -125.054467 }
      ]
    };
    
    const deepWaterBay = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.181017, lng: -125.348083 },
        { lat: 50.190567, lng: -125.337800 }
      ]
    };
    
    const menziesBay = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.136467, lng: -125.381233 },
        { lat: 50.127900, lng: -125.390000 }
      ]
    };
    
    const menziesBay2 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.116367, lng: -125.377717 },
        { lat: 50.113767, lng: -125.366667 }
      ]
    };
    
    const menziesBay3 = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.115883, lng: -125.350050 },
        { lat: 50.128583, lng: -125.361033 }
      ]
    };
    
    const maudIsland = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.128883, lng: -125.338800 },
        { lat: 50.114450, lng: -125.297217 }
      ]
    };
    
    const heriotBay = {
      "type": "rockfish closure",
      "area": "13",
      "coordinates": [
        { lat: 50.108333, lng: -125.161667 },
        { lat: 50.108333, lng: -125.113333 },
        { lat: 50.095000, lng: -125.113333 },
        { lat: 50.095000, lng: -125.161667 },
        { lat: 50.108333, lng: -125.161667 },
      ]
    };
    
    const pendrellSound = {
      "type": "rockfish closure",
      "area": "15",
      "coordinates": [
        { lat: 50.209833, lng: -124.758333 },
        { lat: 50.205000, lng: -124.746667 }
      ]
    };
    
    const teakerneArm = {
      "type": "rockfish closure",
      "area": "15",
      "coordinates": [
        { lat: 50.173284, lng: -124.899731 },
        { lat: 50.186940, lng: -124.899873 }
      ]
    };
    
    const desolationSound = {
      "type": "rockfish closure",
      "area": "15",
      "coordinates": [
        { lat: 50.141667, lng: -124.781917 },
        { lat: 50.155431, lng: -124.646835 }
      ]
    };

    const desolationSound2 = {
      "type": "rockfish closure",
      "area": "15",
      "coordinates": [
        { lat: 50.079167, lng: -124.840217 },
        { lat: 50.075000, lng: -124.805533 }
      ]
    };
    
    const desolationSound3 = {
      "type": "rockfish closure",
      "area": "15",
      "coordinates": [
        { lat: 50.115800, lng: -124.849700 },
        { lat: 50.087950, lng: -124.849600 }
      ]
    };
    
    const copelandIslands = {
      "type": "rockfish closure",
      "area": "15",
      "coordinates": [
        { lat: 50.045000, lng: -124.876667 },
        { lat: 50.050000, lng: -124.863333 },
        { lat: 50.033833, lng: -124.828333 },
        { lat: 49.995000, lng: -124.791667 },
        { lat: 49.981667, lng: -124.816667 },
        { lat: 50.045000, lng: -124.876667 }
      ]
    }
    
    const dinnerRock = {
      "type": "rockfish closure",
      "area": "15",
      "coordinates": [
        { lat: 49.948283, lng: -124.718850 },
        { lat: 49.938333, lng: -124.733333 },
        { lat: 49.915000, lng: -124.686667 },
        { lat: 49.926667, lng: -124.669567 }
      ]
    }
    
    const sookeBay = {
      "type": "rockfish closure",
      "area": "20",
      "coordinates": [
        { lat: 48.362798, lng: -123.8005792 },
        { lat: 48.3593763, lng: -123.7981759 },
        { lat: 48.3659914, lng: -123.7653886 },
        { lat: 48.3543573, lng: -123.7580072 },
        { lat: 48.3485393, lng: -123.7351762 },
        { lat: 48.3536729, lng: -123.7341462 },
        { lat: 48.3551558, lng: -123.7382661 },
        { lat: 48.3549277, lng: -123.7406694 },
        { lat: 48.3581216, lng: -123.7475358 },
        { lat: 48.3575513, lng: -123.7497674 },
        { lat: 48.3596044, lng: -123.7542306 },
        { lat: 48.3631402, lng: -123.7542306 },
        { lat: 48.3707811, lng: -123.7635003 },
        { lat: 48.3712373, lng: -123.7681352 },
        { lat: 48.3690706, lng: -123.7724267 },
        { lat: 48.3687284, lng: -123.7789499 },
        { lat: 48.3658774, lng: -123.7846147 },
        { lat: 48.3646228, lng: -123.7873613 },
        { lat: 48.3643947, lng: -123.7962877 },
        { lat: 48.362798, lng: -123.8005792 }
      ]
    }
    
    const becherBayEast = {
      "type": "rockfish closure",
      "area": "20",
      "coordinates": [
        { lat: 48.327217, lng: -123.594683 },
        { lat: 48.328333, lng: -123.604450 },
        { lat: 48.326050, lng: -123.606217 },
        { lat: 48.316117, lng: -123.607783 },
        { lat: 48.312433, lng: -123.604450 },
        { lat: 48.315000, lng: -123.590550 }
      ]
    }
    
    const frederickIsland = {
      "type": "rockfish closure",
      "area": "1",
      "coordinates": [
        { lat: 53.945617, lng: -133.133933 },
        { lat: 53.937433, lng: -133.197700 },
        { lat: 53.937433, lng: -133.291667 },
        { lat: 53.783333, lng: -133.166667 },
        { lat: 53.782500, lng: -133.121680 }
      ]
    }
    
    const dunira = {
      "type": "rockfish closure",
      "area": "4",
      "coordinates": [
        { lat: 54.425833, lng: -130.835000 },
        { lat: 54.402467, lng: -130.921083 },
        { lat: 54.326667, lng: -130.970000 },
        { lat: 54.371667, lng: -130.783333 }
      ]
    }
    
    const dunira2 = {
      "type": "rockfish closure",
      "area": "4",
      "coordinates": [
        { lat: 54.412000, lng: -130.718917 },
        { lat: 54.419450, lng: -130.730883 }
      ]
    }
    
    const hodgsonReegs = {
      "type": "rockfish closure",
      "area": "4",
      "coordinates": [
        { lat: 54.398274, lng: -130.485116 },
        { lat: 54.398333, lng: -130.543333 },
        { lat: 54.374167, lng: -130.543333 },
        { lat: 54.374608, lng: -130.483987 }
      ]
    }
    
    const gullRocksNorth = {
      "type": "rockfish closure",
      "area": "4",
      "coordinates": [
        { lat: 54.188333, lng: -130.576667 },
        { lat: 54.188333, lng: -130.538333 },
        { lat: 54.171667, lng: -130.506667 },
        { lat: 54.171667, lng: -130.565000 },
        { lat: 54.188333, lng: -130.576667 }
      ]
    }
    
    const gullRocksSouth = {
      "type": "rockfish closure",
      "area": "4",
      "coordinates": [
        { lat: 54.163333, lng: -130.558333 },
        { lat: 54.163333, lng: -130.488333 },
        { lat: 54.140000, lng: -130.440000 },
        { lat: 54.128333, lng: -130.481667 },
        { lat: 54.128333, lng: -130.531667 },
        { lat: 54.163333, lng: -130.558333 }
      ]
    }
    
    const stephensIsland = {
      "type": "rockfish closure",
      "area": "4",
      "coordinates": [
        { lat: 54.042417, lng: -130.710967 },
        { lat: 54.095000, lng: -130.750000 },
        { lat: 54.095000, lng: -130.911667 },
        { lat: 54.000000, lng: -130.866667 },
        { lat: 54.000000, lng: -130.748333 },
        { lat: 54.025417, lng: -130.714450 }
      ]
    }
    
    const porcherPeninsula = {
      "type": "rockfish closure",
      "area": "4",
      "coordinates": [
        { lat: 53.910000, lng: -130.731667 },
        { lat: 53.906667, lng: -130.858333 },
        { lat: 53.818333, lng: -130.693333 },
        { lat: 53.837250, lng: -130.659617 }
      ]
    }
    
    const goschen = {
      "type": "rockfish closure",
      "area": "5",
      "coordinates": [
        { lat: 53.800000, lng: -130.625000 },
        { lat: 53.786667, lng: -130.653333 },
        { lat: 53.760000, lng: -130.601667 },
        { lat: 53.788183, lng: -130.552700 }
      ]
    }
    
    const westBanksIsland = {
      "type": "rockfish closure",
      "area": "5",
      "coordinates": [
        { lat: 53.465000, lng: -130.663333 },
        { lat: 53.466417, lng: -130.587433 },
        { lat: 53.395000, lng: -130.378333 },
        { lat: 53.311667, lng: -130.358333 },
        { lat: 53.405000, lng: -130.633333 },
        { lat: 53.465000, lng: -130.663333 }
      ]
    }
    
    const northDangerRocks = {
      "type": "rockfish closure",
      "area": "105",
      "coordinates": [
        { lat: 53.265000, lng: -130.370000 },
        { lat: 53.268333, lng: -130.278333 },
        { lat: 53.166667, lng: -130.103333 },
        { lat: 53.166667, lng: -130.355000 },
        { lat: 53.265000, lng: -130.370000 },
      ]
    }
    
    const otterPassage = {
      "type": "rockfish closure",
      "area": "6",
      "coordinates": [
        { lat: 53.166667, lng: -129.952117 },
        { lat: 53.166667, lng: -130.148333 },
        { lat: 53.143333, lng: -130.148333 },
        { lat: 53.083333, lng: -129.835000 },
        { lat: 53.085000, lng: -129.776667 },
        { lat: 53.163917, lng: -129.792983 }
      ]
    }
    
    const westAristazabalIsland = {
      "type": "rockfish closure",
      "area": "106",
      "coordinates": [
        { lat: 52.391667, lng: -129.421667 },
        { lat: 52.391667, lng: -129.091667 },
        { lat: 52.649000, lng: -129.413333 },
        { lat: 52.755000, lng: -129.418333 },
        { lat: 52.713333, lng: -129.541667 },
        { lat: 52.391667, lng: -129.421667 }
      ]
    }
    
    const kitasuBay = {
      "type": "rockfish closure",
      "area": "6",
      "coordinates": [
        { lat: 52.655433, lng: -128.858733 },
        { lat: 52.523333, lng: -128.743150 }
      ]
    }
    
    const kitasuBay2 = {
      "type": "rockfish closure",
      "area": "6",
      "coordinates": [
        { lat: 52.669100, lng: -128.762597 },
        { lat: 52.669791, lng: -128.740389 }
      ]
    }
    
    const mcmullinGroup = {
      "type": "rockfish closure",
      "area": "7",
      "coordinates": [
        { lat: 52.091667, lng: -128.495000 },
        { lat: 52.091667, lng: -128.388333 },
        { lat: 52.006667, lng: -128.388333 },
        { lat: 52.006667, lng: -128.495000 },
        { lat: 52.091667, lng: -128.495000 },
      ]
    }
    
    const gooseIsland = {
      "type": "rockfish closure",
      "area": "7",
      "coordinates": [
        { lat: 52.006667, lng: -128.495000 },
        { lat: 51.935000, lng: -128.541667 },
        { lat: 51.843333, lng: -128.485000 },
        { lat: 51.843333, lng: -128.435000 },
        { lat: 52.006667, lng: -128.388333 },
        { lat: 52.006667, lng: -128.495000 }
      ]
    }

    // MULTI AREA
    const westCalvertIsland = {
      "type": "rockfish closure",
      "area": "8",
      "coordinates": [
        { lat: 51.597000, lng: -128.148933 },
        { lat: 51.597000, lng: -128.167467 },
        { lat: 51.480250, lng: -128.180000 },
        { lat: 51.480250, lng: -128.086567 }
      ]
    }
    
    const fishEggInlet = {
      "type": "rockfish closure",
      "area": "9",
      "coordinates": [
        { lat: 51.623333, lng: -127.791667 },
        { lat: 51.610417, lng: -127.782500 }
      ]
    }
    
    const fishEggInlet2 = {
      "type": "rockfish closure",
      "area": "9",
      "coordinates": [
        { lat: 51.685414, lng: -127.808930 },
        { lat: 51.679743, lng: -127.808378 }
      ]
    }
    
    const smithSound = {
      "type": "rockfish closure",
      "area": "10",
      "coordinates": [
        { lat: 51.374167, lng: -127.777600 },
        { lat: 51.366917, lng: -127.808133 },
        { lat: 51.309333, lng: -127.844333 },
        { lat: 51.248250, lng: -127.833750 },
        { lat: 51.257950, lng: -127.803967 },
        { lat: 51.304717, lng: -127.684450 },
        { lat: 51.311383, lng: -127.687500 },
        { lat: 51.325833, lng: -127.700067 }
      ]
    }
    
    const stormIslands = {
      "type": "rockfish closure",
      "area": "11",
      "coordinates": [
        { lat: 51.033333, lng: -127.745000 },
        { lat: 51.058783, lng: -127.631717 },
        { lat: 51.000217, lng: -127.564200 },
        { lat: 51.018333, lng: -127.701667 },
        { lat: 51.033333, lng: -127.745000 }
      ]
    }
    
    // MULTI AREA
    const scottIslands = {
      "type": "rockfish closure",
      "area": "111",
      "coordinates": [
        { lat: 50.866667, lng: -128.941667 },
        { lat: 50.866667, lng: -128.550000 },
        { lat: 50.750000, lng: -128.550000 },
        { lat: 50.750000, lng: -128.941667 },
        { lat: 50.866667, lng: -128.941667 }
      ]
    }
    
    const topKnot = {
      "type": "rockfish closure",
      "area": "127",
      "coordinates": [
        {lat: 50.4811669, lng: -128.1183643},
        {lat: 50.482986, lng: -128.1198216},
        {lat: 50.4868635, lng: -128.1201649},
        {lat: 50.4877373, lng: -128.1217957},
        {lat: 50.4902492, lng: -128.1224823},
        {lat: 50.4925426, lng: -128.1288338},
        {lat: 50.4935255, lng: -128.1306363},
        {lat: 50.4952727, lng: -128.1306363},
        {lat: 50.4963101, lng: -128.1326104},
        {lat: 50.4994222, lng: -128.1347561},
        {lat: 50.4999681, lng: -128.1379319},
        {lat: 50.5011146, lng: -128.1387902},
        {lat: 50.5036259, lng: -128.1387043},
        {lat: 50.5057003, lng: -128.1409359},
        {lat: 50.5044993, lng: -128.1429959},
        {lat: 50.5069012, lng: -128.1456566},
        {lat: 50.5073379, lng: -128.1501198},
        {lat: 50.5062462, lng: -128.1526947},
        {lat: 50.5064099, lng: -128.1559563},
        {lat: 50.5081567, lng: -128.1623078},
        {lat: 50.5126871, lng: -128.1642819},
        {lat: 50.5131237, lng: -128.1671143},
        {lat: 50.5157435, lng: -128.1730366},
        {lat: 50.5182539, lng: -128.1755257},
        {lat: 50.5227288, lng: -128.1772423},
        {lat: 50.5250752, lng: -128.1867695},
        {lat: 50.5243113, lng: -128.1889153},
        {lat: 50.526603, lng: -128.1953526},
        {lat: 50.5270941, lng: -128.2000733},
        {lat: 50.5290584, lng: -128.2065106},
        {lat: 50.5344051, lng: -128.2114888},
        {lat: 50.5362599, lng: -128.215437},
        {lat: 50.5381147, lng: -128.2186985},
        {lat: 50.5425876, lng: -128.2162953},
        {lat: 50.5426967, lng: -128.213892},
        {lat: 50.5437876, lng: -128.2106305},
        {lat: 50.5477147, lng: -128.2099438},
        {lat: 50.5489145, lng: -128.2142353},
        {lat: 50.5562221, lng: -128.2190419},
        {lat: 50.5575308, lng: -128.2247067},
        {lat: 50.5597119, lng: -128.226595},
        {lat: 50.56222, lng: -128.2338047},
        {lat: 50.5661455, lng: -128.2360363},
        {lat: 50.567454, lng: -128.2411862},
        {lat: 50.5715971, lng: -128.2466793},
        {lat: 50.5730144, lng: -128.2466793},
        {lat: 50.5722512, lng: -128.2392979},
        {lat: 50.5761759, lng: -128.237238},
        {lat: 50.5814083, lng: -128.2355214},
        {lat: 50.5809722, lng: -128.2315731},
        {lat: 50.5828253, lng: -128.2300282},
        {lat: 50.5912174, lng: -128.2391262},
        {lat: 50.592611, lng: -128.2571951},
        {lat: 50.5941911, lng: -128.2607142},
        {lat: 50.5952808, lng: -128.2601992},
        {lat: 50.5969698, lng: -128.2639757},
        {lat: 50.5980595, lng: -128.2628599},
        {lat: 50.6019275, lng: -128.2659498},
        {lat: 50.6026357, lng: -128.2713572},
        {lat: 50.6049781, lng: -128.2741038},
        {lat: 50.6044878, lng: -128.2797686},
        {lat: 50.6055228, lng: -128.2839743},
        {lat: 50.6060131, lng: -128.2877508},
        {lat: 50.6065033, lng: -128.2887808},
        {lat: 50.6071025, lng: -128.2951323},
        {lat: 50.6081375, lng: -128.2966772},
        {lat: 50.6085188, lng: -128.3031145},
        {lat: 50.480584, lng: -128.2704314},
        {lat: 50.4811669, lng: -128.1183643}
      ]
    }
    
    const holbergInlet = {
      "type": "rockfish closure",
      "area": "27",
      "coordinates": 
      [
        {
            "lat": 50.5936464,
            "lng": -127.7601406
        },
        {
            "lat": 50.5921119,
            "lng": -127.7599405
        },
        {
            "lat": 50.5908042,
            "lng": -127.7609705
        },
        {
            "lat": 50.5885156,
            "lng": -127.7642321
        },
        {
            "lat": 50.5867718,
            "lng": -127.7635454
        },
        {
            "lat": 50.5867173,
            "lng": -127.7616572
        },
        {
            "lat": 50.5860633,
            "lng": -127.7607988
        },
        {
            "lat": 50.5847554,
            "lng": -127.7559923
        },
        {
            "lat": 50.5855729,
            "lng": -127.7523874
        },
        {
            "lat": 50.5862813,
            "lng": -127.751615
        },
        {
            "lat": 50.5875347,
            "lng": -127.7503275
        },
        {
            "lat": 50.5883521,
            "lng": -127.7462935
        },
        {
            "lat": 50.5878071,
            "lng": -127.7447485
        },
        {
            "lat": 50.5869352,
            "lng": -127.7432036
        },
        {
            "lat": 50.5870442,
            "lng": -127.7379679
        },
        {
            "lat": 50.5869352,
            "lng": -127.7363371
        },
        {
            "lat": 50.5874257,
            "lng": -127.7335047
        },
        {
            "lat": 50.5865538,
            "lng": -127.7309298
        },
        {
            "lat": 50.5863903,
            "lng": -127.728784
        },
        {
            "lat": 50.5854094,
            "lng": -127.7255224
        },
        {
            "lat": 50.5851369,
            "lng": -127.7212309
        },
        {
            "lat": 50.5855729,
            "lng": -127.7195143
        },
        {
            "lat": 50.5857908,
            "lng": -127.7167677
        },
        {
            "lat": 50.5851914,
            "lng": -127.7153944
        },
        {
            "lat": 50.5842649,
            "lng": -127.7089571
        },
        {
            "lat": 50.5838834,
            "lng": -127.7054381
        },
        {
            "lat": 50.5851914,
            "lng": -127.7038073
        },
        {
            "lat": 50.5858998,
            "lng": -127.7013182
        },
        {
            "lat": 50.5853004,
            "lng": -127.7004599
        },
        {
            "lat": 50.5836655,
            "lng": -127.6976275
        },
        {
            "lat": 50.5839924,
            "lng": -127.6935076
        },
        {
            "lat": 50.582957,
            "lng": -127.6892161
        },
        {
            "lat": 50.58263,
            "lng": -127.6856112
        },
        {
            "lat": 50.583284,
            "lng": -127.6832937
        },
        {
            "lat": 50.583611,
            "lng": -127.6807188
        },
        {
            "lat": 50.5825755,
            "lng": -127.6788305
        },
        {
            "lat": 50.5826845,
            "lng": -127.6767706
        },
        {
            "lat": 50.5833385,
            "lng": -127.6759123
        },
        {
            "lat": 50.5831205,
            "lng": -127.6723074
        },
        {
            "lat": 50.5837745,
            "lng": -127.6702475
        },
        {
            "lat": 50.582085,
            "lng": -127.6572012
        },
        {
            "lat": 50.5814855,
            "lng": -127.6485323
        },
        {
            "lat": 50.580668,
            "lng": -127.6451849
        },
        {
            "lat": 50.5793054,
            "lng": -127.6407217
        },
        {
            "lat": 50.5791964,
            "lng": -127.6381468
        },
        {
            "lat": 50.5799049,
            "lng": -127.6372885
        },
        {
            "lat": 50.5799049,
            "lng": -127.6264738
        },
        {
            "lat": 50.5796869,
            "lng": -127.6204657
        },
        {
            "lat": 50.5802319,
            "lng": -127.619264
        },
        {
            "lat": 50.5951087,
            "lng": -127.6176333
        },
        {
            "lat": 50.5954901,
            "lng": -127.6201224
        },
        {
            "lat": 50.5954901,
            "lng": -127.6223539
        },
        {
            "lat": 50.5952722,
            "lng": -127.624843
        },
        {
            "lat": 50.5960894,
            "lng": -127.6278471
        },
        {
            "lat": 50.5973425,
            "lng": -127.6287913
        },
        {
            "lat": 50.5971246,
            "lng": -127.6325678
        },
        {
            "lat": 50.5976149,
            "lng": -127.6366877
        },
        {
            "lat": 50.5984866,
            "lng": -127.6394343
        },
        {
            "lat": 50.5996307,
            "lng": -127.6427817
        },
        {
            "lat": 50.6000754,
            "lng": -127.6468441
        },
        {
            "lat": 50.5993127,
            "lng": -127.651479
        },
        {
            "lat": 50.6005113,
            "lng": -127.6523373
        },
        {
            "lat": 50.601165,
            "lng": -127.6485607
        },
        {
            "lat": 50.6029083,
            "lng": -127.6490757
        },
        {
            "lat": 50.603562,
            "lng": -127.651994
        },
        {
            "lat": 50.607484,
            "lng": -127.6523373
        },
        {
            "lat": 50.6067214,
            "lng": -127.6561138
        },
        {
            "lat": 50.6059588,
            "lng": -127.67122
        },
        {
            "lat": 50.6068304,
            "lng": -127.6744816
        },
        {
            "lat": 50.6054141,
            "lng": -127.6830647
        },
        {
            "lat": 50.6069393,
            "lng": -127.6871845
        },
        {
            "lat": 50.609336,
            "lng": -127.6947376
        },
        {
            "lat": 50.6100986,
            "lng": -127.7010891
        },
        {
            "lat": 50.6063946,
            "lng": -127.7031491
        },
        {
            "lat": 50.6037799,
            "lng": -127.7077839
        },
        {
            "lat": 50.603453,
            "lng": -127.714822
        },
        {
            "lat": 50.6070483,
            "lng": -127.7211735
        },
        {
            "lat": 50.6063946,
            "lng": -127.7230618
        },
        {
            "lat": 50.6066125,
            "lng": -127.7256367
        },
        {
            "lat": 50.6063946,
            "lng": -127.7294132
        },
        {
            "lat": 50.6059588,
            "lng": -127.7364514
        },
        {
            "lat": 50.6053052,
            "lng": -127.7476093
        },
        {
            "lat": 50.6022546,
            "lng": -127.7512142
        },
        {
            "lat": 50.5936464,
            "lng": -127.7601406
        }
    ]
    }
    
    const holbergInlet2 = {
      "type": "rockfish closure",
      "area": "27",
      "coordinates": [
        // { lat: 50.580893, lng: -127.619028 },
        // { lat: 50.595192, lng: -127.618429 }
      ]
    }
    
    const brooksBay = {
      "type": "rockfish closure",
      "area": "27",
      "coordinates": [
          {
              "lat": 50.3242614,
              "lng": -127.9778344
          },
          {
              "lat": 50.2883136,
              "lng": -127.8642583
          },
          {
              "lat": 50.2321849,
              "lng": -127.8107047
          },
          {
              "lat": 50.1295268,
              "lng": -127.9206673
          },
          {
              "lat": 50.1295818,
              "lng": -127.9192511
          },
          {
              "lat": 50.1305722,
              "lng": -127.9181782
          },
          {
              "lat": 50.1307097,
              "lng": -127.9155603
          },
          {
              "lat": 50.1314525,
              "lng": -127.9134146
          },
          {
              "lat": 50.1321127,
              "lng": -127.9097239
          },
          {
              "lat": 50.1318652,
              "lng": -127.9088226
          },
          {
              "lat": 50.1324979,
              "lng": -127.906634
          },
          {
              "lat": 50.1343685,
              "lng": -127.9077498
          },
          {
              "lat": 50.1357439,
              "lng": -127.9079643
          },
          {
              "lat": 50.1359914,
              "lng": -127.9048315
          },
          {
              "lat": 50.1367891,
              "lng": -127.904574
          },
          {
              "lat": 50.1372568,
              "lng": -127.9024283
          },
          {
              "lat": 50.1381095,
              "lng": -127.9023853
          },
          {
              "lat": 50.1379719,
              "lng": -127.904059
          },
          {
              "lat": 50.1388796,
              "lng": -127.9033724
          },
          {
              "lat": 50.1390171,
              "lng": -127.9025999
          },
          {
              "lat": 50.1398698,
              "lng": -127.9018274
          },
          {
              "lat": 50.1403649,
              "lng": -127.9001537
          },
          {
              "lat": 50.1402824,
              "lng": -127.8993383
          },
          {
              "lat": 50.1408325,
              "lng": -127.8980938
          },
          {
              "lat": 50.1422902,
              "lng": -127.8983942
          },
          {
              "lat": 50.1423727,
              "lng": -127.8978792
          },
          {
              "lat": 50.1418501,
              "lng": -127.8965489
          },
          {
              "lat": 50.1419877,
              "lng": -127.8955618
          },
          {
              "lat": 50.1440229,
              "lng": -127.8936306
          },
          {
              "lat": 50.1438854,
              "lng": -127.8921286
          },
          {
              "lat": 50.144683,
              "lng": -127.8896395
          },
          {
              "lat": 50.1455355,
              "lng": -127.8892962
          },
          {
              "lat": 50.1459756,
              "lng": -127.8881374
          },
          {
              "lat": 50.1470206,
              "lng": -127.8882662
          },
          {
              "lat": 50.1463881,
              "lng": -127.8869787
          },
          {
              "lat": 50.1464431,
              "lng": -127.8856913
          },
          {
              "lat": 50.1473781,
              "lng": -127.8842321
          },
          {
              "lat": 50.1490831,
              "lng": -127.8841463
          },
          {
              "lat": 50.1490281,
              "lng": -127.8834168
          },
          {
              "lat": 50.1486981,
              "lng": -127.8827301
          },
          {
              "lat": 50.1489181,
              "lng": -127.8820435
          },
          {
              "lat": 50.1492481,
              "lng": -127.8815714
          },
          {
              "lat": 50.1492756,
              "lng": -127.8804985
          },
          {
              "lat": 50.1491381,
              "lng": -127.8798548
          },
          {
              "lat": 50.1504581,
              "lng": -127.8770224
          },
          {
              "lat": 50.1501006,
              "lng": -127.8762499
          },
          {
              "lat": 50.1508706,
              "lng": -127.8727308
          },
          {
              "lat": 50.1518605,
              "lng": -127.8718296
          },
          {
              "lat": 50.1523005,
              "lng": -127.8705851
          },
          {
              "lat": 50.1531529,
              "lng": -127.8697268
          },
          {
              "lat": 50.1539779,
              "lng": -127.8693405
          },
          {
              "lat": 50.1545553,
              "lng": -127.867066
          },
          {
              "lat": 50.1548303,
              "lng": -127.8654352
          },
          {
              "lat": 50.1559301,
              "lng": -127.8653494
          },
          {
              "lat": 50.1569474,
              "lng": -127.8649632
          },
          {
              "lat": 50.1576623,
              "lng": -127.8639332
          },
          {
              "lat": 50.1581022,
              "lng": -127.8623024
          },
          {
              "lat": 50.1576898,
              "lng": -127.860972
          },
          {
              "lat": 50.1579373,
              "lng": -127.8596846
          },
          {
              "lat": 50.1585971,
              "lng": -127.8593842
          },
          {
              "lat": 50.1581297,
              "lng": -127.85844
          },
          {
              "lat": 50.1584322,
              "lng": -127.8568522
          },
          {
              "lat": 50.1590645,
              "lng": -127.8565088
          },
          {
              "lat": 50.1591195,
              "lng": -127.8556076
          },
          {
              "lat": 50.1594494,
              "lng": -127.8550497
          },
          {
              "lat": 50.1600268,
              "lng": -127.855393
          },
          {
              "lat": 50.1604209,
              "lng": -127.8553727
          },
          {
              "lat": 50.1613831,
              "lng": -127.8533128
          },
          {
              "lat": 50.161658,
              "lng": -127.8516391
          },
          {
              "lat": 50.1620154,
              "lng": -127.8526691
          },
          {
              "lat": 50.1629502,
              "lng": -127.8526262
          },
          {
              "lat": 50.1630052,
              "lng": -127.8507379
          },
          {
              "lat": 50.1645722,
              "lng": -127.8485921
          },
          {
              "lat": 50.1661941,
              "lng": -127.84812
          },
          {
              "lat": 50.166579,
              "lng": -127.847133
          },
          {
              "lat": 50.1684757,
              "lng": -127.8464463
          },
          {
              "lat": 50.1691904,
              "lng": -127.8459743
          },
          {
              "lat": 50.1695752,
              "lng": -127.8447726
          },
          {
              "lat": 50.168943,
              "lng": -127.8419402
          },
          {
              "lat": 50.1691079,
              "lng": -127.8401807
          },
          {
              "lat": 50.170125,
              "lng": -127.8383353
          },
          {
              "lat": 50.1747152,
              "lng": -127.8350309
          },
          {
              "lat": 50.1758146,
              "lng": -127.8352454
          },
          {
              "lat": 50.1764742,
              "lng": -127.834473
          },
          {
              "lat": 50.1773537,
              "lng": -127.8346017
          },
          {
              "lat": 50.1778209,
              "lng": -127.8328422
          },
          {
              "lat": 50.1781782,
              "lng": -127.8304389
          },
          {
              "lat": 50.1805142,
              "lng": -127.8280357
          },
          {
              "lat": 50.1827402,
              "lng": -127.8277782
          },
          {
              "lat": 50.1841966,
              "lng": -127.8291515
          },
          {
              "lat": 50.1849935,
              "lng": -127.8304818
          },
          {
              "lat": 50.1853783,
              "lng": -127.8301814
          },
          {
              "lat": 50.1860103,
              "lng": -127.8286365
          },
          {
              "lat": 50.1868896,
              "lng": -127.8281644
          },
          {
              "lat": 50.1876315,
              "lng": -127.8227142
          },
          {
              "lat": 50.1888954,
              "lng": -127.8218559
          },
          {
              "lat": 50.1894724,
              "lng": -127.8206542
          },
          {
              "lat": 50.1904616,
              "lng": -127.8198388
          },
          {
              "lat": 50.1933465,
              "lng": -127.8191522
          },
          {
              "lat": 50.1942806,
              "lng": -127.8196672
          },
          {
              "lat": 50.1952971,
              "lng": -127.8195384
          },
          {
              "lat": 50.1959015,
              "lng": -127.8183368
          },
          {
              "lat": 50.1957642,
              "lng": -127.8165773
          },
          {
              "lat": 50.1955169,
              "lng": -127.8156331
          },
          {
              "lat": 50.1959565,
              "lng": -127.8147748
          },
          {
              "lat": 50.1965334,
              "lng": -127.8145173
          },
          {
              "lat": 50.1973301,
              "lng": -127.813187
          },
          {
              "lat": 50.1975499,
              "lng": -127.814174
          },
          {
              "lat": 50.1982367,
              "lng": -127.8139594
          },
          {
              "lat": 50.198896,
              "lng": -127.8150323
          },
          {
              "lat": 50.1991432,
              "lng": -127.8139594
          },
          {
              "lat": 50.1995553,
              "lng": -127.8136161
          },
          {
              "lat": 50.200242,
              "lng": -127.8112558
          },
          {
              "lat": 50.1975499,
              "lng": -127.8056768
          },
          {
              "lat": 50.1973301,
              "lng": -127.804561
          },
          {
              "lat": 50.1975773,
              "lng": -127.8024581
          },
          {
              "lat": 50.1966982,
              "lng": -127.8022865
          },
          {
              "lat": 50.1964785,
              "lng": -127.8031018
          },
          {
              "lat": 50.1962037,
              "lng": -127.8012136
          },
          {
              "lat": 50.1952971,
              "lng": -127.8002694
          },
          {
              "lat": 50.195462,
              "lng": -127.7965787
          },
          {
              "lat": 50.1972752,
              "lng": -127.7950338
          },
          {
              "lat": 50.1976323,
              "lng": -127.7913001
          },
          {
              "lat": 50.1984512,
              "lng": -127.7888671
          },
          {
              "lat": 50.1991105,
              "lng": -127.7887812
          },
          {
              "lat": 50.1999071,
              "lng": -127.7889958
          },
          {
              "lat": 50.2006763,
              "lng": -127.789897
          },
          {
              "lat": 50.2006763,
              "lng": -127.7932444
          },
          {
              "lat": 50.2006214,
              "lng": -127.7964202
          },
          {
              "lat": 50.1997698,
              "lng": -127.7973643
          },
          {
              "lat": 50.1985336,
              "lng": -127.7977076
          },
          {
              "lat": 50.1988633,
              "lng": -127.7991238
          },
          {
              "lat": 50.1995775,
              "lng": -127.7993384
          },
          {
              "lat": 50.1996874,
              "lng": -127.8008834
          },
          {
              "lat": 50.1996324,
              "lng": -127.8038016
          },
          {
              "lat": 50.2006488,
              "lng": -127.804617
          },
          {
              "lat": 50.2011433,
              "lng": -127.8037158
          },
          {
              "lat": 50.2011982,
              "lng": -127.8026429
          },
          {
              "lat": 50.2017751,
              "lng": -127.8022567
          },
          {
              "lat": 50.2022696,
              "lng": -127.8013125
          },
          {
              "lat": 50.2016378,
              "lng": -127.7999392
          },
          {
              "lat": 50.2041374,
              "lng": -127.7969781
          },
          {
              "lat": 50.2094934,
              "lng": -127.7933732
          },
          {
              "lat": 50.2103723,
              "lng": -127.7944461
          },
          {
              "lat": 50.2112512,
              "lng": -127.7962914
          },
          {
              "lat": 50.2123772,
              "lng": -127.7962914
          },
          {
              "lat": 50.2123497,
              "lng": -127.7973214
          },
          {
              "lat": 50.2130363,
              "lng": -127.7973643
          },
          {
              "lat": 50.2126793,
              "lng": -127.7980939
          },
          {
              "lat": 50.2140799,
              "lng": -127.8002826
          },
          {
              "lat": 50.2150136,
              "lng": -127.7995101
          },
          {
              "lat": 50.2148213,
              "lng": -127.8006688
          },
          {
              "lat": 50.2153431,
              "lng": -127.8013125
          },
          {
              "lat": 50.2179519,
              "lng": -127.7998963
          },
          {
              "lat": 50.2176224,
              "lng": -127.8016988
          },
          {
              "lat": 50.2186659,
              "lng": -127.8017846
          },
          {
              "lat": 50.2189679,
              "lng": -127.8011838
          },
          {
              "lat": 50.2204782,
              "lng": -127.8012267
          },
          {
              "lat": 50.220588,
              "lng": -127.7987805
          },
          {
              "lat": 50.2199839,
              "lng": -127.7992097
          },
          {
              "lat": 50.2191601,
              "lng": -127.798008
          },
          {
              "lat": 50.2191876,
              "lng": -127.7954331
          },
          {
              "lat": 50.21927,
              "lng": -127.7942744
          },
          {
              "lat": 50.2205331,
              "lng": -127.7931157
          },
          {
              "lat": 50.2210548,
              "lng": -127.7922574
          },
          {
              "lat": 50.2210548,
              "lng": -127.7911416
          },
          {
              "lat": 50.2221531,
              "lng": -127.7911416
          },
          {
              "lat": 50.2234436,
              "lng": -127.787408
          },
          {
              "lat": 50.2230043,
              "lng": -127.7829877
          },
          {
              "lat": 50.2213019,
              "lng": -127.7781382
          },
          {
              "lat": 50.2214118,
              "lng": -127.7773228
          },
          {
              "lat": 50.2211646,
              "lng": -127.7751342
          },
          {
              "lat": 50.2216314,
              "lng": -127.7745763
          },
          {
              "lat": 50.2224826,
              "lng": -127.7746621
          },
          {
              "lat": 50.2237182,
              "lng": -127.7729455
          },
          {
              "lat": 50.2247066,
              "lng": -127.7727738
          },
          {
              "lat": 50.224789,
              "lng": -127.771658
          },
          {
              "lat": 50.2262167,
              "lng": -127.7725592
          },
          {
              "lat": 50.226656,
              "lng": -127.7713147
          },
          {
              "lat": 50.226569,
              "lng": -127.7668491
          },
          {
              "lat": 50.2281065,
              "lng": -127.7641884
          },
          {
              "lat": 50.2283261,
              "lng": -127.760841
          },
          {
              "lat": 50.2314009,
              "lng": -127.759811
          },
          {
              "lat": 50.2324989,
              "lng": -127.7586952
          },
          {
              "lat": 50.2348048,
              "lng": -127.7615276
          },
          {
              "lat": 50.2351892,
              "lng": -127.7627293
          },
          {
              "lat": 50.2361774,
              "lng": -127.760841
          },
          {
              "lat": 50.23744,
              "lng": -127.7593819
          },
          {
              "lat": 50.2383184,
              "lng": -127.7561203
          },
          {
              "lat": 50.2377145,
              "lng": -127.7532879
          },
          {
              "lat": 50.2371655,
              "lng": -127.7513138
          },
          {
              "lat": 50.2387027,
              "lng": -127.7508846
          },
          {
              "lat": 50.2393241,
              "lng": -127.7464716
          },
          {
              "lat": 50.23883,
              "lng": -127.7455274
          },
          {
              "lat": 50.2391594,
              "lng": -127.7443258
          },
          {
              "lat": 50.2397084,
              "lng": -127.7375452
          },
          {
              "lat": 50.2380615,
              "lng": -127.7363435
          },
          {
              "lat": 50.2382262,
              "lng": -127.7352277
          },
          {
              "lat": 50.2403122,
              "lng": -127.7333395
          },
          {
              "lat": 50.2422884,
              "lng": -127.7222673
          },
          {
              "lat": 50.2443194,
              "lng": -127.7199499
          },
          {
              "lat": 50.244484,
              "lng": -127.7209799
          },
          {
              "lat": 50.2428922,
              "lng": -127.7232973
          },
          {
              "lat": 50.2420688,
              "lng": -127.7290479
          },
          {
              "lat": 50.2446487,
              "lng": -127.733082
          },
          {
              "lat": 50.246954,
              "lng": -127.7306787
          },
          {
              "lat": 50.2478456,
              "lng": -127.7331114
          },
          {
              "lat": 50.2473517,
              "lng": -127.7346564
          },
          {
              "lat": 50.2522363,
              "lng": -127.7328539
          },
          {
              "lat": 50.2556937,
              "lng": -127.7298499
          },
          {
              "lat": 50.2578887,
              "lng": -127.7283907
          },
          {
              "lat": 50.2627723,
              "lng": -127.7220393
          },
          {
              "lat": 50.2636502,
              "lng": -127.7197218
          },
          {
              "lat": 50.2640343,
              "lng": -127.7213526
          },
          {
              "lat": 50.263321,
              "lng": -127.7247
          },
          {
              "lat": 50.2604677,
              "lng": -127.7286482
          },
          {
              "lat": 50.2594252,
              "lng": -127.7323389
          },
          {
              "lat": 50.2576143,
              "lng": -127.7332831
          },
          {
              "lat": 50.2566266,
              "lng": -127.7356005
          },
          {
              "lat": 50.2535534,
              "lng": -127.7362872
          },
          {
              "lat": 50.251468,
              "lng": -127.7349139
          },
          {
              "lat": 50.2463637,
              "lng": -127.7380896
          },
          {
              "lat": 50.2432899,
              "lng": -127.7437544
          },
          {
              "lat": 50.2443877,
              "lng": -127.7447844
          },
          {
              "lat": 50.2463637,
              "lng": -127.7431536
          },
          {
              "lat": 50.2476261,
              "lng": -127.7405787
          },
          {
              "lat": 50.2500959,
              "lng": -127.7418662
          },
          {
              "lat": 50.249108,
              "lng": -127.747016
          },
          {
              "lat": 50.2504252,
              "lng": -127.7472735
          },
          {
              "lat": 50.2518522,
              "lng": -127.7445269
          },
          {
              "lat": 50.2517973,
              "lng": -127.7467585
          },
          {
              "lat": 50.2528949,
              "lng": -127.7481318
          },
          {
              "lat": 50.2527851,
              "lng": -127.7501059
          },
          {
              "lat": 50.2518522,
              "lng": -127.7537108
          },
          {
              "lat": 50.2505899,
              "lng": -127.7546549
          },
          {
              "lat": 50.2501508,
              "lng": -127.7579165
          },
          {
              "lat": 50.2483945,
              "lng": -127.761693
          },
          {
              "lat": 50.2477359,
              "lng": -127.7678729
          },
          {
              "lat": 50.2459246,
              "lng": -127.7702761
          },
          {
              "lat": 50.2451013,
              "lng": -127.7739668
          },
          {
              "lat": 50.2458697,
              "lng": -127.7786017
          },
          {
              "lat": 50.2451562,
              "lng": -127.78049
          },
          {
              "lat": 50.2460893,
              "lng": -127.7834082
          },
          {
              "lat": 50.2460344,
              "lng": -127.7873564
          },
          {
              "lat": 50.2472968,
              "lng": -127.789588
          },
          {
              "lat": 50.2467479,
              "lng": -127.7931929
          },
          {
              "lat": 50.2476261,
              "lng": -127.796197
          },
          {
              "lat": 50.2464186,
              "lng": -127.7971411
          },
          {
              "lat": 50.2461442,
              "lng": -127.8001452
          },
          {
              "lat": 50.2474066,
              "lng": -127.8004027
          },
          {
              "lat": 50.2520717,
              "lng": -127.806325
          },
          {
              "lat": 50.2553644,
              "lng": -127.8119898
          },
          {
              "lat": 50.2567363,
              "lng": -127.815938
          },
          {
              "lat": 50.2577241,
              "lng": -127.8168822
          },
          {
              "lat": 50.2570107,
              "lng": -127.8201437
          },
          {
              "lat": 50.2584923,
              "lng": -127.8246069
          },
          {
              "lat": 50.2601934,
              "lng": -127.8246069
          },
          {
              "lat": 50.2606872,
              "lng": -127.8267527
          },
          {
              "lat": 50.2620041,
              "lng": -127.8266669
          },
          {
              "lat": 50.2621687,
              "lng": -127.8299284
          },
          {
              "lat": 50.2629918,
              "lng": -127.8302718
          },
          {
              "lat": 50.2630466,
              "lng": -127.8317309
          },
          {
              "lat": 50.2647475,
              "lng": -127.8346491
          },
          {
              "lat": 50.2657351,
              "lng": -127.8336192
          },
          {
              "lat": 50.2663386,
              "lng": -127.8350783
          },
          {
              "lat": 50.2663386,
              "lng": -127.8367949
          },
          {
              "lat": 50.2686429,
              "lng": -127.8393698
          },
          {
              "lat": 50.2712762,
              "lng": -127.8426314
          },
          {
              "lat": 50.2707825,
              "lng": -127.8437472
          },
          {
              "lat": 50.2724282,
              "lng": -127.8461504
          },
          {
              "lat": 50.2739438,
              "lng": -127.8467513
          },
          {
              "lat": 50.2736695,
              "lng": -127.8489829
          },
          {
              "lat": 50.2758363,
              "lng": -127.8494121
          },
          {
              "lat": 50.2762202,
              "lng": -127.8487254
          },
          {
              "lat": 50.2775093,
              "lng": -127.8498412
          },
          {
              "lat": 50.277043,
              "lng": -127.8507425
          },
          {
              "lat": 50.2780304,
              "lng": -127.8515149
          },
          {
              "lat": 50.2784417,
              "lng": -127.8526736
          },
          {
              "lat": 50.2800598,
              "lng": -127.8534032
          },
          {
              "lat": 50.2805534,
              "lng": -127.8541757
          },
          {
              "lat": 50.2801695,
              "lng": -127.8553344
          },
          {
              "lat": 50.28091,
              "lng": -127.8549052
          },
          {
              "lat": 50.2814036,
              "lng": -127.8538324
          },
          {
              "lat": 50.2831587,
              "lng": -127.8542615
          },
          {
              "lat": 50.2841733,
              "lng": -127.8562356
          },
          {
              "lat": 50.2849959,
              "lng": -127.8574802
          },
          {
              "lat": 50.2858186,
              "lng": -127.8569652
          },
          {
              "lat": 50.2871074,
              "lng": -127.8582956
          },
          {
              "lat": 50.2875461,
              "lng": -127.8620721
          },
          {
              "lat": 50.288259,
              "lng": -127.8637887
          },
          {
              "lat": 50.2889993,
              "lng": -127.8639604
          },
          {
              "lat": 50.2911106,
              "lng": -127.8597118
          },
          {
              "lat": 50.2921524,
              "lng": -127.8591109
          },
          {
              "lat": 50.2915218,
              "lng": -127.8580381
          },
          {
              "lat": 50.2911654,
              "lng": -127.8566648
          },
          {
              "lat": 50.2916315,
              "lng": -127.8558065
          },
          {
              "lat": 50.2907815,
              "lng": -127.8552057
          },
          {
              "lat": 50.2898767,
              "lng": -127.851472
          },
          {
              "lat": 50.2901509,
              "lng": -127.8500129
          },
          {
              "lat": 50.2892187,
              "lng": -127.8497554
          },
          {
              "lat": 50.2887251,
              "lng": -127.8476955
          },
          {
              "lat": 50.2905348,
              "lng": -127.8424598
          },
          {
              "lat": 50.2904251,
              "lng": -127.8416873
          },
          {
              "lat": 50.2916863,
              "lng": -127.8385116
          },
          {
              "lat": 50.2918508,
              "lng": -127.8372241
          },
          {
              "lat": 50.2938797,
              "lng": -127.8343059
          },
          {
              "lat": 50.2936055,
              "lng": -127.8334476
          },
          {
              "lat": 50.2950312,
              "lng": -127.8330184
          },
          {
              "lat": 50.2961278,
              "lng": -127.8279544
          },
          {
              "lat": 50.296073,
              "lng": -127.8252937
          },
          {
              "lat": 50.2946474,
              "lng": -127.821088
          },
          {
              "lat": 50.2943184,
              "lng": -127.8189422
          },
          {
              "lat": 50.2970599,
              "lng": -127.8150798
          },
          {
              "lat": 50.2970599,
              "lng": -127.8135349
          },
          {
              "lat": 50.2997465,
              "lng": -127.8091575
          },
          {
              "lat": 50.3024329,
              "lng": -127.80993
          },
          {
              "lat": 50.303639,
              "lng": -127.8052951
          },
          {
              "lat": 50.3124644,
              "lng": -127.8039218
          },
          {
              "lat": 50.3141636,
              "lng": -127.8056384
          },
          {
              "lat": 50.3175068,
              "lng": -127.8032352
          },
          {
              "lat": 50.3181645,
              "lng": -127.7989436
          },
          {
              "lat": 50.3167395,
              "lng": -127.7950813
          },
          {
              "lat": 50.3158078,
              "lng": -127.7928497
          },
          {
              "lat": 50.3150953,
              "lng": -127.787614
          },
          {
              "lat": 50.3144924,
              "lng": -127.7869273
          },
          {
              "lat": 50.3146569,
              "lng": -127.7833224
          },
          {
              "lat": 50.3129577,
              "lng": -127.7802325
          },
          {
              "lat": 50.3133962,
              "lng": -127.7779151
          },
          {
              "lat": 50.3108201,
              "lng": -127.7716495
          },
          {
              "lat": 50.3095045,
              "lng": -127.7702762
          },
          {
              "lat": 50.309066,
              "lng": -127.7678729
          },
          {
              "lat": 50.3074763,
              "lng": -127.7660705
          },
          {
              "lat": 50.3066541,
              "lng": -127.7637531
          },
          {
              "lat": 50.304242,
              "lng": -127.7584315
          },
          {
              "lat": 50.3025973,
              "lng": -127.7542258
          },
          {
              "lat": 50.3016105,
              "lng": -127.7513934
          },
          {
              "lat": 50.3018298,
              "lng": -127.7477027
          },
          {
              "lat": 50.3025425,
              "lng": -127.7468444
          },
          {
              "lat": 50.3016653,
              "lng": -127.7464153
          },
          {
              "lat": 50.301775,
              "lng": -127.7444411
          },
          {
              "lat": 50.3002399,
              "lng": -127.7413512
          },
          {
              "lat": 50.2989241,
              "lng": -127.7367164
          },
          {
              "lat": 50.2985403,
              "lng": -127.7331973
          },
          {
              "lat": 50.2986499,
              "lng": -127.7294208
          },
          {
              "lat": 50.294757,
              "lng": -127.7210094
          },
          {
              "lat": 50.2946474,
              "lng": -127.7180911
          },
          {
              "lat": 50.296073,
              "lng": -127.717147
          },
          {
              "lat": 50.2977179,
              "lng": -127.7223827
          },
          {
              "lat": 50.3000206,
              "lng": -127.7234126
          },
          {
              "lat": 50.3017202,
              "lng": -127.7246143
          },
          {
              "lat": 50.3020491,
              "lng": -127.7321674
          },
          {
              "lat": 50.3038034,
              "lng": -127.7366306
          },
          {
              "lat": 50.3065992,
              "lng": -127.7408363
          },
          {
              "lat": 50.3087919,
              "lng": -127.7471877
          },
          {
              "lat": 50.3106556,
              "lng": -127.750106
          },
          {
              "lat": 50.3120259,
              "lng": -127.7542258
          },
          {
              "lat": 50.3128481,
              "lng": -127.7583457
          },
          {
              "lat": 50.3130674,
              "lng": -127.7610923
          },
          {
              "lat": 50.3147117,
              "lng": -127.7633239
          },
          {
              "lat": 50.3175068,
              "lng": -127.7658988
          },
          {
              "lat": 50.3184933,
              "lng": -127.769332
          },
          {
              "lat": 50.3196442,
              "lng": -127.7736236
          },
          {
              "lat": 50.3209595,
              "lng": -127.7755977
          },
          {
              "lat": 50.3243023,
              "lng": -127.7861549
          },
          {
              "lat": 50.324631,
              "lng": -127.7894164
          },
          {
              "lat": 50.3252886,
              "lng": -127.7925922
          },
          {
              "lat": 50.3255078,
              "lng": -127.7959396
          },
          {
              "lat": 50.3244119,
              "lng": -127.7973129
          },
          {
              "lat": 50.3251242,
              "lng": -127.8031493
          },
          {
              "lat": 50.3221103,
              "lng": -127.8113033
          },
          {
              "lat": 50.3185481,
              "lng": -127.8167106
          },
          {
              "lat": 50.3155338,
              "lng": -127.8173114
          },
          {
              "lat": 50.314602,
              "lng": -127.8187705
          },
          {
              "lat": 50.3141636,
              "lng": -127.8209163
          },
          {
              "lat": 50.3116423,
              "lng": -127.8246928
          },
          {
              "lat": 50.3106008,
              "lng": -127.8254653
          },
          {
              "lat": 50.3096142,
              "lng": -127.8319026
          },
          {
              "lat": 50.3073119,
              "lng": -127.8384258
          },
          {
              "lat": 50.3100527,
              "lng": -127.8396274
          },
          {
              "lat": 50.3109297,
              "lng": -127.8432323
          },
          {
              "lat": 50.3113134,
              "lng": -127.8456355
          },
          {
              "lat": 50.3112038,
              "lng": -127.8486396
          },
          {
              "lat": 50.3121356,
              "lng": -127.8515578
          },
          {
              "lat": 50.3128481,
              "lng": -127.857566
          },
          {
              "lat": 50.3150953,
              "lng": -127.8609134
          },
          {
              "lat": 50.3172876,
              "lng": -127.8612567
          },
          {
              "lat": 50.3170136,
              "lng": -127.8634025
          },
          {
              "lat": 50.3169588,
              "lng": -127.8651191
          },
          {
              "lat": 50.3187125,
              "lng": -127.8648616
          },
          {
              "lat": 50.3261653,
              "lng": -127.8630592
          },
          {
              "lat": 50.3270421,
              "lng": -127.8642608
          },
          {
              "lat": 50.3258914,
              "lng": -127.8663207
          },
          {
              "lat": 50.3272065,
              "lng": -127.8676082
          },
          {
              "lat": 50.3279188,
              "lng": -127.8657199
          },
          {
              "lat": 50.3302748,
              "lng": -127.8676082
          },
          {
              "lat": 50.3292338,
              "lng": -127.8688098
          },
          {
              "lat": 50.330768,
              "lng": -127.8696681
          },
          {
              "lat": 50.3299461,
              "lng": -127.8710414
          },
          {
              "lat": 50.3312063,
              "lng": -127.8729297
          },
          {
              "lat": 50.3321925,
              "lng": -127.8720714
          },
          {
              "lat": 50.3339457,
              "lng": -127.8741313
          },
          {
              "lat": 50.3365754,
              "lng": -127.8724147
          },
          {
              "lat": 50.3373971,
              "lng": -127.8638316
          },
          {
              "lat": 50.3407936,
              "lng": -127.8610851
          },
          {
              "lat": 50.3426012,
              "lng": -127.8638316
          },
          {
              "lat": 50.3437515,
              "lng": -127.8657199
          },
          {
              "lat": 50.3476404,
              "lng": -127.8632308
          },
          {
              "lat": 50.3494478,
              "lng": -127.8591968
          },
          {
              "lat": 50.3499407,
              "lng": -127.8605701
          },
          {
              "lat": 50.3480786,
              "lng": -127.8646041
          },
          {
              "lat": 50.3441349,
              "lng": -127.8668357
          },
          {
              "lat": 50.342656,
              "lng": -127.8665782
          },
          {
              "lat": 50.3413413,
              "lng": -127.8634883
          },
          {
              "lat": 50.3387667,
              "lng": -127.8659774
          },
          {
              "lat": 50.3381093,
              "lng": -127.8712131
          },
          {
              "lat": 50.3421082,
              "lng": -127.8721572
          },
          {
              "lat": 50.3440802,
              "lng": -127.8747321
          },
          {
              "lat": 50.3427108,
              "lng": -127.879882
          },
          {
              "lat": 50.3433681,
              "lng": -127.8813411
          },
          {
              "lat": 50.3408483,
              "lng": -127.8961898
          },
          {
              "lat": 50.3398623,
              "lng": -127.8968764
          },
          {
              "lat": 50.3390406,
              "lng": -127.9033996
          },
          {
              "lat": 50.3362467,
              "lng": -127.9043437
          },
          {
              "lat": 50.3358632,
              "lng": -127.9055453
          },
          {
              "lat": 50.3338361,
              "lng": -127.912326
          },
          {
              "lat": 50.3292338,
              "lng": -127.9133559
          },
          {
              "lat": 50.325453,
              "lng": -127.9222823
          },
          {
              "lat": 50.3263845,
              "lng": -127.9250289
          },
          {
              "lat": 50.3258914,
              "lng": -127.9303504
          },
          {
              "lat": 50.3238639,
              "lng": -127.9317237
          },
          {
              "lat": 50.3242475,
              "lng": -127.9367019
          },
          {
              "lat": 50.3252886,
              "lng": -127.938676
          },
          {
              "lat": 50.3251242,
              "lng": -127.9433109
          },
          {
              "lat": 50.3221651,
              "lng": -127.9445983
          },
          {
              "lat": 50.3206855,
              "lng": -127.9406501
          },
          {
              "lat": 50.3190962,
              "lng": -127.949834
          },
          {
              "lat": 50.3215075,
              "lng": -127.9523231
          },
          {
              "lat": 50.3221103,
              "lng": -127.955413
          },
          {
              "lat": 50.3211239,
              "lng": -127.9579021
          },
          {
              "lat": 50.3219459,
              "lng": -127.9587604
          },
          {
              "lat": 50.3230967,
              "lng": -127.9575587
          },
          {
              "lat": 50.3243571,
              "lng": -127.9591037
          },
          {
              "lat": 50.324631,
              "lng": -127.9641677
          },
          {
              "lat": 50.3264941,
              "lng": -127.9702617
          },
          {
              "lat": 50.3244119,
              "lng": -127.9733516
          },
          {
              "lat": 50.3242614,
              "lng": -127.9778344
          }
      ]
    }
    
    const checlesetBay = {
      "type": "rockfish closure",
      "area": "26",
      "coordinates": [
          {
              "lat": 50.1079946,
              "lng": -127.6085847
          },
          {
              "lat": 50.1089303,
              "lng": -127.6084989
          },
          {
              "lat": 50.1090955,
              "lng": -127.6101297
          },
          {
              "lat": 50.1107468,
              "lng": -127.6106447
          },
          {
              "lat": 50.1120678,
              "lng": -127.6097864
          },
          {
              "lat": 50.1129485,
              "lng": -127.6072973
          },
          {
              "lat": 50.1148199,
              "lng": -127.6080698
          },
          {
              "lat": 50.1148749,
              "lng": -127.6056665
          },
          {
              "lat": 50.1163059,
              "lng": -127.59983
          },
          {
              "lat": 50.1176818,
              "lng": -127.6012033
          },
          {
              "lat": 50.1182872,
              "lng": -127.5994867
          },
          {
              "lat": 50.1209288,
              "lng": -127.5990575
          },
          {
              "lat": 50.1209288,
              "lng": -127.6027482
          },
          {
              "lat": 50.1201033,
              "lng": -127.6047224
          },
          {
              "lat": 50.121204,
              "lng": -127.6047224
          },
          {
              "lat": 50.1201584,
              "lng": -127.6071256
          },
          {
              "lat": 50.1193329,
              "lng": -127.6080698
          },
          {
              "lat": 50.1192228,
              "lng": -127.6106447
          },
          {
              "lat": 50.1192228,
              "lng": -127.6123613
          },
          {
              "lat": 50.1177369,
              "lng": -127.6143354
          },
          {
              "lat": 50.1191127,
              "lng": -127.6159662
          },
          {
              "lat": 50.1173516,
              "lng": -127.6214593
          },
          {
              "lat": 50.1174066,
              "lng": -127.6252359
          },
          {
              "lat": 50.1193879,
              "lng": -127.6245492
          },
          {
              "lat": 50.1216993,
              "lng": -127.6260084
          },
          {
              "lat": 50.1228549,
              "lng": -127.6258367
          },
          {
              "lat": 50.1242306,
              "lng": -127.6260942
          },
          {
              "lat": 50.1248359,
              "lng": -127.62824
          },
          {
              "lat": 50.1245608,
              "lng": -127.6318449
          },
          {
              "lat": 50.1262116,
              "lng": -127.6302141
          },
          {
              "lat": 50.1290178,
              "lng": -127.6314157
          },
          {
              "lat": 50.1315488,
              "lng": -127.6307291
          },
          {
              "lat": 50.1340246,
              "lng": -127.633819
          },
          {
              "lat": 50.1366104,
              "lng": -127.6385396
          },
          {
              "lat": 50.1390859,
              "lng": -127.6371664
          },
          {
              "lat": 50.1405162,
              "lng": -127.6381105
          },
          {
              "lat": 50.1410113,
              "lng": -127.6392263
          },
          {
              "lat": 50.1422215,
              "lng": -127.6382822
          },
          {
              "lat": 50.1448068,
              "lng": -127.6389688
          },
          {
              "lat": 50.1440367,
              "lng": -127.6419729
          },
          {
              "lat": 50.1459068,
              "lng": -127.6438611
          },
          {
              "lat": 50.1467319,
              "lng": -127.6425737
          },
          {
              "lat": 50.1479419,
              "lng": -127.6430028
          },
          {
              "lat": 50.1483819,
              "lng": -127.6395696
          },
          {
              "lat": 50.1478319,
              "lng": -127.6380247
          },
          {
              "lat": 50.1494819,
              "lng": -127.6356214
          },
          {
              "lat": 50.1519018,
              "lng": -127.6350206
          },
          {
              "lat": 50.1537167,
              "lng": -127.6320165
          },
          {
              "lat": 50.1548165,
              "lng": -127.6300424
          },
          {
              "lat": 50.158116,
              "lng": -127.6316732
          },
          {
              "lat": 50.1593807,
              "lng": -127.631244
          },
          {
              "lat": 50.1610303,
              "lng": -127.6320165
          },
          {
              "lat": 50.1627899,
              "lng": -127.6310724
          },
          {
              "lat": 50.162295,
              "lng": -127.6302999
          },
          {
              "lat": 50.1635596,
              "lng": -127.6293558
          },
          {
              "lat": 50.1629548,
              "lng": -127.6286691
          },
          {
              "lat": 50.1632297,
              "lng": -127.6278108
          },
          {
              "lat": 50.1646043,
              "lng": -127.62618
          },
          {
              "lat": 50.1647143,
              "lng": -127.6248926
          },
          {
              "lat": 50.1671334,
              "lng": -127.6213735
          },
          {
              "lat": 50.1688377,
              "lng": -127.6214593
          },
          {
              "lat": 50.1708718,
              "lng": -127.6236909
          },
          {
              "lat": 50.172576,
              "lng": -127.6219743
          },
          {
              "lat": 50.1727959,
              "lng": -127.620086
          },
          {
              "lat": 50.1735105,
              "lng": -127.620086
          },
          {
              "lat": 50.1747748,
              "lng": -127.617597
          },
          {
              "lat": 50.1765888,
              "lng": -127.6181978
          },
          {
              "lat": 50.1863721,
              "lng": -127.6136487
          },
          {
              "lat": 50.1881307,
              "lng": -127.610473
          },
          {
              "lat": 50.1904937,
              "lng": -127.6062673
          },
          {
              "lat": 50.1920323,
              "lng": -127.6072114
          },
          {
              "lat": 50.1926268,
              "lng": -127.6082384
          },
          {
              "lat": 50.1906761,
              "lng": -127.6096116
          },
          {
              "lat": 50.1903738,
              "lng": -127.6109849
          },
          {
              "lat": 50.1911157,
              "lng": -127.6112424
          },
          {
              "lat": 50.1928466,
              "lng": -127.6099979
          },
          {
              "lat": 50.193506,
              "lng": -127.6102983
          },
          {
              "lat": 50.1944401,
              "lng": -127.6100408
          },
          {
              "lat": 50.1949621,
              "lng": -127.6118862
          },
          {
              "lat": 50.1940555,
              "lng": -127.6126157
          },
          {
              "lat": 50.1922697,
              "lng": -127.6127445
          },
          {
              "lat": 50.1916102,
              "lng": -127.6126157
          },
          {
              "lat": 50.1899892,
              "lng": -127.613989
          },
          {
              "lat": 50.190209,
              "lng": -127.6157056
          },
          {
              "lat": 50.1910882,
              "lng": -127.6181518
          },
          {
              "lat": 50.1907585,
              "lng": -127.6193534
          },
          {
              "lat": 50.189632,
              "lng": -127.6205121
          },
          {
              "lat": 50.1888077,
              "lng": -127.6205121
          },
          {
              "lat": 50.1881482,
              "lng": -127.6219713
          },
          {
              "lat": 50.18595,
              "lng": -127.6231729
          },
          {
              "lat": 50.1843837,
              "lng": -127.6239454
          },
          {
              "lat": 50.1836692,
              "lng": -127.6237737
          },
          {
              "lat": 50.1827624,
              "lng": -127.6252328
          },
          {
              "lat": 50.1808387,
              "lng": -127.628709
          },
          {
              "lat": 50.1804814,
              "lng": -127.6282369
          },
          {
              "lat": 50.1814982,
              "lng": -127.6260482
          },
          {
              "lat": 50.181086,
              "lng": -127.6256191
          },
          {
              "lat": 50.1802891,
              "lng": -127.625662
          },
          {
              "lat": 50.1797669,
              "lng": -127.626692
          },
          {
              "lat": 50.179547,
              "lng": -127.6290094
          },
          {
              "lat": 50.1790798,
              "lng": -127.629181
          },
          {
              "lat": 50.1786951,
              "lng": -127.6284086
          },
          {
              "lat": 50.1790249,
              "lng": -127.6268636
          },
          {
              "lat": 50.1779256,
              "lng": -127.6253187
          },
          {
              "lat": 50.1774309,
              "lng": -127.6255762
          },
          {
              "lat": 50.1771285,
              "lng": -127.626177
          },
          {
              "lat": 50.1762491,
              "lng": -127.6262199
          },
          {
              "lat": 50.1752871,
              "lng": -127.6274644
          },
          {
              "lat": 50.1747374,
              "lng": -127.6295673
          },
          {
              "lat": 50.1750122,
              "lng": -127.6299964
          },
          {
              "lat": 50.1757269,
              "lng": -127.6293527
          },
          {
              "lat": 50.1758368,
              "lng": -127.6300393
          },
          {
              "lat": 50.1749573,
              "lng": -127.6306402
          },
          {
              "lat": 50.175397,
              "lng": -127.6321851
          },
          {
              "lat": 50.1758093,
              "lng": -127.6325714
          },
          {
              "lat": 50.1753696,
              "lng": -127.6340734
          },
          {
              "lat": 50.1732807,
              "lng": -127.6342451
          },
          {
              "lat": 50.1722637,
              "lng": -127.6345455
          },
          {
              "lat": 50.1713016,
              "lng": -127.6353609
          },
          {
              "lat": 50.1706419,
              "lng": -127.63476
          },
          {
              "lat": 50.16935,
              "lng": -127.6355325
          },
          {
              "lat": 50.1686903,
              "lng": -127.6353609
          },
          {
              "lat": 50.1684979,
              "lng": -127.6359187
          },
          {
              "lat": 50.1681955,
              "lng": -127.6366912
          },
          {
              "lat": 50.1679756,
              "lng": -127.6379358
          },
          {
              "lat": 50.1686903,
              "lng": -127.6390516
          },
          {
              "lat": 50.1692126,
              "lng": -127.6400815
          },
          {
              "lat": 50.1692126,
              "lng": -127.6410686
          },
          {
              "lat": 50.168113,
              "lng": -127.6411544
          },
          {
              "lat": 50.168223,
              "lng": -127.6420986
          },
          {
              "lat": 50.1677007,
              "lng": -127.6423131
          },
          {
              "lat": 50.1683329,
              "lng": -127.6436006
          },
          {
              "lat": 50.1681955,
              "lng": -127.6442872
          },
          {
              "lat": 50.1687178,
              "lng": -127.6446735
          },
          {
              "lat": 50.1686903,
              "lng": -127.6460897
          },
          {
              "lat": 50.1692401,
              "lng": -127.6461326
          },
          {
              "lat": 50.1708344,
              "lng": -127.6469909
          },
          {
              "lat": 50.1708615,
              "lng": -127.6495272
          },
          {
              "lat": 50.1722358,
              "lng": -127.6520163
          },
          {
              "lat": 50.180591,
              "lng": -127.6565653
          },
          {
              "lat": 50.1813605,
              "lng": -127.6578528
          },
          {
              "lat": 50.1824047,
              "lng": -127.660771
          },
          {
              "lat": 50.1855374,
              "lng": -127.6599127
          },
          {
              "lat": 50.1875708,
              "lng": -127.6587111
          },
          {
              "lat": 50.1891645,
              "lng": -127.6587111
          },
          {
              "lat": 50.1895492,
              "lng": -127.6570803
          },
          {
              "lat": 50.1904834,
              "lng": -127.6558787
          },
          {
              "lat": 50.1955936,
              "lng": -127.6492697
          },
          {
              "lat": 50.1978464,
              "lng": -127.6484114
          },
          {
              "lat": 50.1986156,
              "lng": -127.6476389
          },
          {
              "lat": 50.199165,
              "lng": -127.6436907
          },
          {
              "lat": 50.1991101,
              "lng": -127.6403433
          },
          {
              "lat": 50.1998243,
              "lng": -127.6382834
          },
          {
              "lat": 50.2018571,
              "lng": -127.6316744
          },
          {
              "lat": 50.2048786,
              "lng": -127.6286703
          },
          {
              "lat": 50.2059773,
              "lng": -127.6290995
          },
          {
              "lat": 50.2068563,
              "lng": -127.6281554
          },
          {
              "lat": 50.2086141,
              "lng": -127.629872
          },
          {
              "lat": 50.2087789,
              "lng": -127.6313311
          },
          {
              "lat": 50.207845,
              "lng": -127.6321894
          },
          {
              "lat": 50.2042194,
              "lng": -127.6393992
          },
          {
              "lat": 50.2042743,
              "lng": -127.6408583
          },
          {
              "lat": 50.2050984,
              "lng": -127.640515
          },
          {
              "lat": 50.2044391,
              "lng": -127.6448923
          },
          {
              "lat": 50.201912,
              "lng": -127.6451498
          },
          {
              "lat": 50.2017472,
              "lng": -127.6471239
          },
          {
              "lat": 50.2015824,
              "lng": -127.6504713
          },
          {
              "lat": 50.1995496,
              "lng": -127.6525313
          },
          {
              "lat": 50.1948793,
              "lng": -127.6538187
          },
          {
              "lat": 50.195209,
              "lng": -127.6558787
          },
          {
              "lat": 50.1950991,
              "lng": -127.6581961
          },
          {
              "lat": 50.1885051,
              "lng": -127.6611143
          },
          {
              "lat": 50.1888348,
              "lng": -127.6630026
          },
          {
              "lat": 50.1875159,
              "lng": -127.6652342
          },
          {
              "lat": 50.1858672,
              "lng": -127.6649767
          },
          {
              "lat": 50.1843283,
              "lng": -127.6657492
          },
          {
              "lat": 50.1825147,
              "lng": -127.6671225
          },
          {
              "lat": 50.1799314,
              "lng": -127.6657492
          },
          {
              "lat": 50.1750394,
              "lng": -127.6590544
          },
          {
              "lat": 50.1721259,
              "lng": -127.6540762
          },
          {
              "lat": 50.1710264,
              "lng": -127.651158
          },
          {
              "lat": 50.1695421,
              "lng": -127.6533038
          },
          {
              "lat": 50.1689373,
              "lng": -127.6543337
          },
          {
              "lat": 50.1675629,
              "lng": -127.6540762
          },
          {
              "lat": 50.1650888,
              "lng": -127.6548487
          },
          {
              "lat": 50.1633843,
              "lng": -127.6553637
          },
          {
              "lat": 50.1629995,
              "lng": -127.656737
          },
          {
              "lat": 50.1636593,
              "lng": -127.6585394
          },
          {
              "lat": 50.1629445,
              "lng": -127.6599986
          },
          {
              "lat": 50.1631644,
              "lng": -127.6617152
          },
          {
              "lat": 50.1605251,
              "lng": -127.6608569
          },
          {
              "lat": 50.1600303,
              "lng": -127.6599127
          },
          {
              "lat": 50.1590955,
              "lng": -127.6604277
          },
          {
              "lat": 50.1583256,
              "lng": -127.6591402
          },
          {
              "lat": 50.1582156,
              "lng": -127.6563078
          },
          {
              "lat": 50.1570609,
              "lng": -127.6557928
          },
          {
              "lat": 50.1568409,
              "lng": -127.656737
          },
          {
              "lat": 50.155906,
              "lng": -127.6570803
          },
          {
              "lat": 50.1551362,
              "lng": -127.656222
          },
          {
              "lat": 50.1545312,
              "lng": -127.6574236
          },
          {
              "lat": 50.1554661,
              "lng": -127.6605994
          },
          {
              "lat": 50.1535963,
              "lng": -127.6613718
          },
          {
              "lat": 50.1512315,
              "lng": -127.6589686
          },
          {
              "lat": 50.1502416,
              "lng": -127.6594836
          },
          {
              "lat": 50.1503516,
              "lng": -127.6621443
          },
          {
              "lat": 50.1484816,
              "lng": -127.6621443
          },
          {
              "lat": 50.1487566,
              "lng": -127.6637751
          },
          {
              "lat": 50.1477666,
              "lng": -127.6645476
          },
          {
              "lat": 50.1462265,
              "lng": -127.6645476
          },
          {
              "lat": 50.1461165,
              "lng": -127.6676375
          },
          {
              "lat": 50.1471616,
              "lng": -127.6699549
          },
          {
              "lat": 50.1491416,
              "lng": -127.6706416
          },
          {
              "lat": 50.1501866,
              "lng": -127.6731306
          },
          {
              "lat": 50.1517265,
              "lng": -127.6715857
          },
          {
              "lat": 50.1529914,
              "lng": -127.6738173
          },
          {
              "lat": 50.1529914,
              "lng": -127.6756197
          },
          {
              "lat": 50.1506815,
              "lng": -127.6753622
          },
          {
              "lat": 50.1502966,
              "lng": -127.6775938
          },
          {
              "lat": 50.1484266,
              "lng": -127.6767355
          },
          {
              "lat": 50.1466665,
              "lng": -127.678538
          },
          {
              "lat": 50.1471616,
              "lng": -127.6809412
          },
          {
              "lat": 50.1485916,
              "lng": -127.684117
          },
          {
              "lat": 50.1488116,
              "lng": -127.6858336
          },
          {
              "lat": 50.1497466,
              "lng": -127.6835162
          },
          {
              "lat": 50.1499666,
              "lng": -127.6847178
          },
          {
              "lat": 50.1484816,
              "lng": -127.6872069
          },
          {
              "lat": 50.1471066,
              "lng": -127.6884943
          },
          {
              "lat": 50.1445764,
              "lng": -127.6872069
          },
          {
              "lat": 50.1429262,
              "lng": -127.6872927
          },
          {
              "lat": 50.1420461,
              "lng": -127.6854903
          },
          {
              "lat": 50.1408359,
              "lng": -127.6861769
          },
          {
              "lat": 50.1406709,
              "lng": -127.6877219
          },
          {
              "lat": 50.141331,
              "lng": -127.6885802
          },
          {
              "lat": 50.141056,
              "lng": -127.6909834
          },
          {
              "lat": 50.1400658,
              "lng": -127.6927
          },
          {
              "lat": 50.1418261,
              "lng": -127.6955325
          },
          {
              "lat": 50.141276,
              "lng": -127.6978499
          },
          {
              "lat": 50.1398457,
              "lng": -127.6995665
          },
          {
              "lat": 50.1354997,
              "lng": -127.7006823
          },
          {
              "lat": 50.1366,
              "lng": -127.6964766
          },
          {
              "lat": 50.1375903,
              "lng": -127.6992232
          },
          {
              "lat": 50.1384155,
              "lng": -127.6955325
          },
          {
              "lat": 50.1349496,
              "lng": -127.6900393
          },
          {
              "lat": 50.1340143,
              "lng": -127.6909834
          },
          {
              "lat": 50.1332441,
              "lng": -127.6893526
          },
          {
              "lat": 50.133024,
              "lng": -127.6837737
          },
          {
              "lat": 50.1309883,
              "lng": -127.6852328
          },
          {
              "lat": 50.1307132,
              "lng": -127.6884085
          },
          {
              "lat": 50.1314835,
              "lng": -127.6898676
          },
          {
              "lat": 50.1323087,
              "lng": -127.6909834
          },
          {
              "lat": 50.1317035,
              "lng": -127.6916701
          },
          {
              "lat": 50.1319236,
              "lng": -127.6931292
          },
          {
              "lat": 50.1307682,
              "lng": -127.6944167
          },
          {
              "lat": 50.1307682,
              "lng": -127.6960474
          },
          {
              "lat": 50.1314835,
              "lng": -127.6969916
          },
          {
              "lat": 50.1312634,
              "lng": -127.7006823
          },
          {
              "lat": 50.1317035,
              "lng": -127.7020556
          },
          {
              "lat": 50.1314835,
              "lng": -127.7086646
          },
          {
              "lat": 50.1327489,
              "lng": -127.7092654
          },
          {
              "lat": 50.1334641,
              "lng": -127.7149302
          },
          {
              "lat": 50.1320887,
              "lng": -127.7105528
          },
          {
              "lat": 50.1297778,
              "lng": -127.7120978
          },
          {
              "lat": 50.1291175,
              "lng": -127.7106387
          },
          {
              "lat": 50.1288424,
              "lng": -127.7127844
          },
          {
              "lat": 50.1273018,
              "lng": -127.7129561
          },
          {
              "lat": 50.1269166,
              "lng": -127.7157885
          },
          {
              "lat": 50.1276319,
              "lng": -127.7175909
          },
          {
              "lat": 50.1264764,
              "lng": -127.7188784
          },
          {
              "lat": 50.1246605,
              "lng": -127.7161318
          },
          {
              "lat": 50.12367,
              "lng": -127.7190501
          },
          {
              "lat": 50.1241653,
              "lng": -127.7228266
          },
          {
              "lat": 50.1237801,
              "lng": -127.7253157
          },
          {
              "lat": 50.1232848,
              "lng": -127.7272898
          },
          {
              "lat": 50.123615,
              "lng": -127.730723
          },
          {
              "lat": 50.1221842,
              "lng": -127.7341563
          },
          {
              "lat": 50.1145894,
              "lng": -127.7431685
          },
          {
              "lat": 50.1129932,
              "lng": -127.7449709
          },
          {
              "lat": 50.1116172,
              "lng": -127.7450568
          },
          {
              "lat": 50.1108466,
              "lng": -127.7482325
          },
          {
              "lat": 50.1109016,
              "lng": -127.7502066
          },
          {
              "lat": 50.1079292,
              "lng": -127.7526957
          },
          {
              "lat": 50.1068282,
              "lng": -127.7565581
          },
          {
              "lat": 50.1038005,
              "lng": -127.7571589
          },
          {
              "lat": 50.1035803,
              "lng": -127.758618
          },
          {
              "lat": 50.1047363,
              "lng": -127.7614504
          },
          {
              "lat": 50.1042959,
              "lng": -127.7639395
          },
          {
              "lat": 50.0978122,
              "lng": -127.772913
          },
          {
              "lat": 50.093187,
              "lng": -127.7773762
          },
          {
              "lat": 50.0901033,
              "lng": -127.7816677
          },
          {
              "lat": 50.0875702,
              "lng": -127.7863026
          },
          {
              "lat": 50.0849267,
              "lng": -127.7871609
          },
          {
              "lat": 50.0847064,
              "lng": -127.7905941
          },
          {
              "lat": 50.0810714,
              "lng": -127.794714
          },
          {
              "lat": 50.0801902,
              "lng": -127.8005505
          },
          {
              "lat": 50.08008,
              "lng": -127.8113651
          },
          {
              "lat": 50.0809613,
              "lng": -127.815485
          },
          {
              "lat": 50.071703,
              "lng": -127.8256877
          },
          {
              "lat": 50.0081989,
              "lng": -127.7096446
          },
          {
              "lat": 50.1079946,
              "lng": -127.6085847
          }
      ]
    }
    
    const westOfBajoReef = {
      "type": "rockfish closure",
      "area": "125",
      "coordinates": [
        { lat: 49.631667, lng: -127.018517 },
        { lat: 49.631667, lng: -126.876667 },
        { lat: 49.558333, lng: -126.876667 },
        { lat: 49.631667, lng: -127.018517 }
      ]
    }
    
    const estevanPoint = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        { lat: 49.392100, lng: -126.463633 },
        { lat: 49.325000, lng: -126.398333 },
        { lat: 49.283333, lng: -126.520000 },
        { lat: 49.368333, lng: -126.745000 },
        { lat: 49.417783, lng: -126.576633 }
      ]
    }
    
    const bedwellSound1 = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        { lat: 48.7538481, lng: -123.2422537 },
        { lat: 48.7486701, lng: -123.2459873 },
        { lat: 48.7459818, lng: -123.2398075 },
        { lat: 48.7453875, lng: -123.2396358 },
        { lat: 48.7450762, lng: -123.2411808 },
        { lat: 48.7442273, lng: -123.2413954 },
        { lat: 48.7436613, lng: -123.2407946 },
        { lat: 48.7409161, lng: -123.2362455 },
        { lat: 48.7404067, lng: -123.2348293 },
        { lat: 48.7409161, lng: -123.2340998 },
        { lat: 48.7403501, lng: -123.232469 },
        { lat: 48.7393596, lng: -123.2318682 },
        { lat: 48.7398124, lng: -123.2339281 },
        { lat: 48.7384539, lng: -123.235516 },
        { lat: 48.7376614, lng: -123.2353014 },
        { lat: 48.7365859, lng: -123.230924 },
        { lat: 48.7321817, lng: -123.2079798 },
        { lat: 48.7327762, lng: -123.2076794 },
        { lat: 48.7332574, lng: -123.207336 },
        { lat: 48.7353802, lng: -123.2081085 },
        { lat: 48.7354368, lng: -123.2100826 },
        { lat: 48.738154, lng: -123.2130867 },
        { lat: 48.7393427, lng: -123.2139021 },
        { lat: 48.7405879, lng: -123.2192665 },
        { lat: 48.7415785, lng: -123.220511 },
        { lat: 48.7422294, lng: -123.2239014 },
        { lat: 48.7424841, lng: -123.225103 },
        { lat: 48.7432765, lng: -123.2255751 },
        { lat: 48.7440406, lng: -123.2283216 },
        { lat: 48.7454272, lng: -123.2294804 },
        { lat: 48.7473232, lng: -123.2266909 },
        { lat: 48.7485966, lng: -123.2290941 },
        { lat: 48.7492757, lng: -123.2283646 },
        { lat: 48.750351, lng: -123.2292229 },
        { lat: 48.751596, lng: -123.229652 },
        { lat: 48.7526995, lng: -123.2298666 },
        { lat: 48.7530956, lng: -123.2317978 },
        { lat: 48.7537746, lng: -123.2336002 },
        { lat: 48.7534634, lng: -123.2363039 },
        { lat: 48.7543122, lng: -123.2396942 },
        { lat: 48.7540576, lng: -123.2408529 },
        { lat: 48.7533785, lng: -123.2407671 },
        { lat: 48.7532936, lng: -123.2414108 },
        { lat: 48.7540859, lng: -123.2417112 },
        { lat: 48.7538481, lng: -123.2422537 }
      ]
    }
    
    const bedwellSound2 = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        // { lat: 49.333099, lng: -125.808775 },
        // { lat: 49.332914, lng: -125.802155 }
      ]
    }
    
    const saranacIslands1 = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        { lat: 49.241297, lng: -125.8556435 },
        { lat: 49.2550241, lng: -125.8578751 },
        { lat: 49.2554722, lng: -125.8619092 },
        { lat: 49.2568727, lng: -125.8642266 },
        { lat: 49.2570408, lng: -125.8679173 },
        { lat: 49.2582732, lng: -125.8672307 },
        { lat: 49.2584388, lng: -125.926942 },
        { lat: 49.2576826, lng: -125.9270708 },
        { lat: 49.2574025, lng: -125.9281436 },
        { lat: 49.2575706, lng: -125.928959 },
        { lat: 49.2573745, lng: -125.9307186 },
        { lat: 49.2580747, lng: -125.9309331 },
        { lat: 49.259027, lng: -125.9325639 },
        { lat: 49.259027, lng: -125.9347097 },
        { lat: 49.2561701, lng: -125.9373704 },
        { lat: 49.2554978, lng: -125.9391729 },
        { lat: 49.2515762, lng: -125.9424344 },
        { lat: 49.2507919, lng: -125.9444086 },
        { lat: 49.2465898, lng: -125.9434644 },
        { lat: 49.2479905, lng: -125.9412328 },
        { lat: 49.2479905, lng: -125.9380571 },
        { lat: 49.2456372, lng: -125.9361688 },
        { lat: 49.23499, lng: -125.8994333 },
        { lat: 49.2363911, lng: -125.9000341 },
        { lat: 49.2387448, lng: -125.896 },
        { lat: 49.2396975, lng: -125.8944551 },
        { lat: 49.2395854, lng: -125.8916227 },
        { lat: 49.2410984, lng: -125.8926526 },
        { lat: 49.2414347, lng: -125.8893911 },
        { lat: 49.2413786, lng: -125.8871595 },
        { lat: 49.2423312, lng: -125.8857862 },
        { lat: 49.2411545, lng: -125.8819238 },
        { lat: 49.2417709, lng: -125.8763448 },
        { lat: 49.2405381, lng: -125.8755723 },
        { lat: 49.2402018, lng: -125.8705942 },
        { lat: 49.2393052, lng: -125.869135 },
        { lat: 49.2402018, lng: -125.8662168 },
        { lat: 49.241995, lng: -125.8666459 },
        { lat: 49.2426675, lng: -125.8634702 },
        { lat: 49.2420511, lng: -125.8627836 },
        { lat: 49.2427795, lng: -125.8617536 },
        { lat: 49.2422752, lng: -125.8594362 },
        { lat: 49.2414347, lng: -125.8589212 },
        { lat: 49.241297, lng: -125.8556435 }
      ]
    }
    
    const saranacIslands2 = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        // { lat: 49.235000, lng: -125.898767 },
        // { lat: 49.245417, lng: -125.936367 }
      ]
    }
    
    const saranacIslands3 = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        // { lat: 49.254833, lng: -125.857970 },
        // { lat: 49.241408, lng: -125.855426 }
      ]
    }
    
    const vargasIslandDunlapIsland1 = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        { lat: 49.2020895, lng: -125.9505532 },
        { lat: 49.2152107, lng: -125.9398243 },
        { lat: 49.2155471, lng: -125.9426567 },
        { lat: 49.2184625, lng: -125.94609 },
        { lat: 49.2190792, lng: -125.9475491 },
        { lat: 49.2201443, lng: -125.9463475 },
        { lat: 49.2209852, lng: -125.9466908 },
        { lat: 49.221714, lng: -125.945575 },
        { lat: 49.2242366, lng: -125.9563038 },
        { lat: 49.2212095, lng: -125.955703 },
        { lat: 49.2197519, lng: -125.9545872 },
        { lat: 49.2186867, lng: -125.9549305 },
        { lat: 49.2178458, lng: -125.9547589 },
        { lat: 49.2175094, lng: -125.9560463 },
        { lat: 49.2185746, lng: -125.9581063 },
        { lat: 49.2182943, lng: -125.9593079 },
        { lat: 49.218967, lng: -125.9610245 },
        { lat: 49.2206489, lng: -125.9613678 },
        { lat: 49.2208731, lng: -125.9626553 },
        { lat: 49.222723, lng: -125.9629986 },
        { lat: 49.2241245, lng: -125.9605954 },
        { lat: 49.2249092, lng: -125.9604237 },
        { lat: 49.2260303, lng: -125.9643719 },
        { lat: 49.225638, lng: -125.9651015 },
        { lat: 49.210363, lng: -125.9706828 },
        { lat: 49.2093537, lng: -125.9702536 },
        { lat: 49.2078958, lng: -125.9713694 },
        { lat: 49.2069986, lng: -125.9694811 },
        { lat: 49.2069425, lng: -125.9661337 },
        { lat: 49.2085126, lng: -125.9632155 },
        { lat: 49.2084005, lng: -125.9615847 },
        { lat: 49.2076715, lng: -125.9602972 },
        { lat: 49.2077836, lng: -125.9575507 },
        { lat: 49.208793, lng: -125.9572073 },
        { lat: 49.2079519, lng: -125.9548899 },
        { lat: 49.2082883, lng: -125.9537741 },
        { lat: 49.2067182, lng: -125.9516283 },
        { lat: 49.2050359, lng: -125.9524867 },
        { lat: 49.2040826, lng: -125.9540316 },
        { lat: 49.2032975, lng: -125.9552332 },
        { lat: 49.2028488, lng: -125.9572932 },
        { lat: 49.2021198, lng: -125.956864 },
        { lat: 49.2030731, lng: -125.9541174 },
        { lat: 49.2020895, lng: -125.9505532 }
      ]
    }
    
    const vargasIslandDunlapIsland2 = {
      "type": "rockfish closure",
      "area": "24",
      "coordinates": [
        // { lat: 49.215683, lng: -125.939550 },
        // { lat: 49.201667, lng: -125.950800 }
      ]
    }    

      const brokenIslandsGroup = {
        "type":"rockfish closure",
        "area": "23",
        "coordinates": [
          { lat: 48.9229007, lng: -125.3440797 },
          { lat: 48.9074455, lng: -125.3744638 },
          { lat: 48.905753, lng: -125.3700006 },
          { lat: 48.9038348, lng: -125.3725755 },
          { lat: 48.895597, lng: -125.3720606 },
          { lat: 48.888543, lng: -125.372833 },
          { lat: 48.8809236, lng: -125.3773821 },
          { lat: 48.8737547, lng: -125.3734338 },
          { lat: 48.8640402, lng: -125.3684539 },
          { lat: 48.8631932, lng: -125.3615016 },
          { lat: 48.8638708, lng: -125.3593559 },
          { lat: 48.8644919, lng: -125.3518028 },
          { lat: 48.8620639, lng: -125.3529186 },
          { lat: 48.8614428, lng: -125.3493995 },
          { lat: 48.8625721, lng: -125.345108 },
          { lat: 48.8604264, lng: -125.3420181 },
          { lat: 48.8589582, lng: -125.3432197 },
          { lat: 48.8568688, lng: -125.3404731 },
          { lat: 48.8542146, lng: -125.3377265 },
          { lat: 48.8639837, lng: -125.3180713 },
          { lat: 48.8650001, lng: -125.316183 },
          { lat: 48.8670327, lng: -125.3173847 },
          { lat: 48.8733558, lng: -125.3179855 },
          { lat: 48.8747671, lng: -125.3181571 },
          { lat: 48.8739768, lng: -125.3136939 },
          { lat: 48.8749365, lng: -125.3113765 },
          { lat: 48.8736381, lng: -125.3097457 },
          { lat: 48.8735816, lng: -125.3022785 },
          { lat: 48.8756703, lng: -125.30657 },
          { lat: 48.8767993, lng: -125.3063125 },
          { lat: 48.8762913, lng: -125.3039951 },
          { lat: 48.8791701, lng: -125.3062267 },
          { lat: 48.8790572, lng: -125.3085441 },
          { lat: 48.8806942, lng: -125.311119 },
          { lat: 48.8907968, lng: -125.2774734 },
          { lat: 48.9049031, lng: -125.2625389 },
          { lat: 48.913365, lng: -125.2628822 },
          { lat: 48.916975, lng: -125.2603073 },
          { lat: 48.9172006, lng: -125.2525825 },
          { lat: 48.9259988, lng: -125.2546424 },
          { lat: 48.9279161, lng: -125.2484626 },
          { lat: 48.9317506, lng: -125.2544708 },
          { lat: 48.9293823, lng: -125.2658004 },
          { lat: 48.9306228, lng: -125.269577 },
          { lat: 48.9380656, lng: -125.2609939 },
          { lat: 48.9399824, lng: -125.2645988 },
          { lat: 48.9370507, lng: -125.2676887 },
          { lat: 48.9372762, lng: -125.2709503 },
          { lat: 48.9421247, lng: -125.2752418 },
          { lat: 48.9293823, lng: -125.2941246 },
          { lat: 48.9222767, lng: -125.321762 },
          { lat: 48.9195695, lng: -125.3269119 },
          { lat: 48.918103, lng: -125.3312034 },
          { lat: 48.917539, lng: -125.3353233 },
          { lat: 48.9229007, lng: -125.3440797 }
        ]
      }

      const folgerPassage = {
        "type": "rockfish closure",
        "area": "23",
        "coordinates": [
          { lat: 48.846667, lng: -125.315000 },
          { lat: 48.808333, lng: -125.248333 },
          { lat: 48.790000, lng: -125.271667 },
          { lat: 48.830000, lng: -125.338333 },
          { lat: 48.846667, lng: -125.315000 }
        ]
      }
      
      const pachenaPoint = {
        "type": "rockfish closure",
        "area": "21",
        "coordinates": [
          { lat: 48.722117, lng: -125.097583 },
          { lat: 48.713333, lng: -125.097583 },
          { lat: 48.676333, lng: -124.992133 },
          { lat: 48.706380, lng: -124.993170 }
        ]
      }
      
      const darePoint = {
        "type": "rockfish closure",
        "area": "21",
        "coordinates": [
          { lat: 48.661667, lng: -124.819683 },
          { lat: 48.643333, lng: -124.831667 },
          { lat: 48.640550, lng: -124.796117 }
        ]
      }
      
      const carmanah = {
        "type": "rockfish closure",
        "area": "20",
        "coordinates": [
          { lat: 48.622500, lng: -124.764117 },
          { lat: 48.616667, lng: -124.780000 },
          { lat: 48.575000, lng: -124.691667 },
          { lat: 48.585000, lng: -124.679567 }
        ]
      }

    export const closureAreas = [
        domettPoint, 
        easternBurrardInlet,
        howeSoundAlbertaBay,
        howeSoundAnvilIsland,
        howeSoundBowyerIsland,
        howeSoundBrunswickPoint,
        howeSoundCarmeloPoint,
        howeSoundCollingwoodChannel,
        howeSoundDefenceIslands,
        howeSoundDormanPoint,
        howeSoundEastDefenceIslands,
        howeSoundHalkettPoint,
        howeSoundLangdale,
        howeSoundLionsBayKelvinGrove,
        howeSoundLostReef,
        howeSoundMarinersRest,
        howeSoundQueenCharlotteChannel,
        howeSoundQueenCharlotteChannel2,
        howeSoundQueenCharlotteChannel3,
        howeSoundQueenCharlotteChannel4,
        lionsBay,
        marinersRest,
        indianArmCrokerIslandNorthLine,
        indianArmCrokerIslandSouthLine,
        indianArmTwinIslandsNorth,
        indianArmTwinIslandsSouth,
        pamRocks,
        passageIslandWestVan,
        passageIsland,
        westBay,
        upperCentreBay,
        woolRidgeIsland,
        pasleyIsland,
        bowyerIsland,
        foreSlopeHills,
        halibutBankRca,
        halibutBankSpongeReef,
        galianoIslandNorth,
        gabriolaPassage,
        gabriolaPassage2,
        gabriolaPassage3,
        mayneIsland,
        mayneIsland2,
        valdesIslandEast,
        sechelt,
        outerGulf1,
        outerGulf2,
        outerGulf3,
        outerGulf4,
        mcCallBank,
        bedwellHarbour,
        bedwellHarbour2,
        brethourDomvilleForrestPooch,
        coalIsland,
        coalIsland2,
        coalIsland3,
        coalIsland4,
        mapleBay,
        mapleBay2,
        navyChannel,
        navyChannel2,
        pateyRock,
        portlandIsland,
        prevostIslandNorth,
        prevostIslandNorth2,
        russellIsland,
        southSaturna,
        southSaturna2,
        bellIsland,
        ballenasIsland,
        coffinPoint,
        dangerReef,
        deCourcyIslandNorth,
        departureBay,
        gabriolaIsland,
        lasquetiSouthYoungPoint,
        nanooseSchoonerCove,
        northumberlandChannel,
        northumberlandChannel2,
        reynoldsPointLinkIsland,
        reynoldsPointLinkIsland2,
        ruxtonPyladesIslands,
        saltSpringIslandNorth,
        saltSpringIslandNorth2,
        thetisKuperIslands,
        thetisKuperIslands2,
        trincomaliChannel,
        trincomaliChannel2,
        trincomaliChannel3,
        trincomaliChannel4,
        davieBay,
        hardyIsland,
        hardyIsland2,
        hardyIsland3,
        hothamSound,
        malaspinaStrait,
        mcNaughtonPoint,
        princessLouisaInlet,
        queensReachEast,
        queensReachWest,
        sabineJervisJedediah,
        sabineJervisJedediah2,
        salmonInlet,
        salmonInlet2,
        sinclairBank,
        skookumchuckNarrows,
        skookumchuckNarrows2,
        thormanbyIsland,
        thormanbyIsland2,
        ajaxAchillesBank,
        baynesSound,
        chromeIsland,
        eastHornbyIsland,
        lasquetiIslandSouth,
        mitlenatchIsland,
        oysterBay,
        parksVille,
        savoieRocks,
        sisterIslets,
        brentWoodBay,
        darcyIsland,
        discoveryChathamIslands,
        discoveryChathamIslands2,
        discoveryChathamIslands3,
        duntzeHead,
        midFinlaysonArm,
        raceRocks,
        bentinckIsland,
        trialIsland,
        brokenIslandsGroup,
        shadwellPassage,
        shadwellPassage2,
        goletasChannel,
        goletasChannel2,
        browningPassageHuntRock,
        browningPassageHuntRock2,
        bolivarPassage,
        shelterBay,
        browningIslandsRaynorGroup, 
        druryInletMuirheadIslands,
        druryInletMuirheadIslands2,
        hardyBay, 
        numasIslands,
        susquash,
        haddingtonPassage,
        burleyBay,
        burleyBay2,
        burleyBay3,
        mackenzieNimmo,
        mackenzieNimmo2,
        mackenzieNimmo3,
        wakemanSound,
        sicksonPolkinghorne,
        sicksonPolkinghorne2,
        sicksonPolkinghorne3,
        sicksonPolkinghorne4,
        norwellChannel,
        norwellChannel2,
        norwellChannel3,
        greenwaySound,
        greenwaySound2,
        belleisleSound,
        kwatsiBay,
        bondSound,
        thompsonSound,
        salmonChannel,
        weyntonPassage,
        edenBonwickMisummer,
        edenBonwickMisummer2,
        edenBonwickMisummer3,
        edenBonwickMisummer4,
        edenBonwickMisummer5,
        portElizabeth,
        lowerClioChannel,
        lowerClioChannel2,
        lowerClioChannel3,
        viscountIsland,
        viscountIsland2,
        viscountIsland3,
        viscountIsland4,
        viscountIsland5,
        havannahChannel,
        havannahChannel2,
        havannahChannel3,
        havannahChannel4,
        upperCallInlet,
        cracroftPointSouth,
        westCracroftIsland,
        wellborneChannel,
        wellborneChannel2,
        wellborneChannel3,
        forwardHarbour,
        loughboroughInlet,
        buteInletNorth,
        chancellorChannel,
        chancellorChannel2,
        chancellorChannel3,
        chancellorChannelEast,
        chancellorChannelEast2,
        walkemIslandsHemmingBay,
        thurstonBay, 
        kanishBay, 
        octopusIslandsHoskynChannel,
        octopusIslandsHoskynChannel2,
        octopusIslandsHoskynChannel3,
        readCortesIslands,
        readCortesIslands2,
        deepWaterBay,
        menziesBay,
        menziesBay2, 
        menziesBay3,
        maudIsland,
        heriotBay, 
        pendrellSound, 
        teakerneArm,
        desolationSound,
        desolationSound2,
        desolationSound3,
        copelandIslands,
        dinnerRock,
        sookeBay,
        becherBayEast,
        frederickIsland,
        dunira,
        dunira2,
        hodgsonReegs,
        gullRocksNorth,
        gullRocksSouth,
        stephensIsland,
        porcherPeninsula,
        goschen,
        westBanksIsland,
        northDangerRocks,
        otterPassage,
        westAristazabalIsland,
        kitasuBay,
        kitasuBay2,
        mcmullinGroup,
        gooseIsland,
        topKnot,
        holbergInlet,
        holbergInlet2,
        brooksBay,
        checlesetBay,
        westOfBajoReef,
        estevanPoint,
        bedwellSound1,
        bedwellSound2,
        saranacIslands1,
        saranacIslands2,
        saranacIslands3,
        vargasIslandDunlapIsland1,
        vargasIslandDunlapIsland2,
        carmanah,
        folgerPassage,
        pachenaPoint,
        darePoint,
        westCalvertIsland,
        fishEggInlet,
        fishEggInlet2,
        smithSound,
        stormIslands,
        scottIslands,
    ]

