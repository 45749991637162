import { transformTime } from "../../functions/transformTime";
import { IoIosArrowRoundUp } from "react-icons/io";
import { IoIosArrowRoundDown } from "react-icons/io";
import WindDirectionIndicator from "../global/windArrow";
import { BsDash } from "react-icons/bs";

const WeatherTableWave = ({ waveBuoy, setSelectedStation }) => {

    console.log('waveBuoy', waveBuoy)

        // Function to render the content of a td element based on the data value
        const renderTableCellContent = (value1, value2, unit) => {

            parseFloat(value1);
            parseFloat(value2);

            console.log('value1', value1, 'value2', value2, 'unit', unit)

            if(value1 === 'N/A' || value1 === undefined) {
                return '-';
            }
    
            if (value1 > value2) {
                return (
                    <>
                        {`${value1} ${unit} `}
                        {<IoIosArrowRoundUp color={'orange'} />}
                    </>
                );
            } else if (value1 === value2) {
                return (
                    <>
                        {`${value1} ${unit} `}
                        <BsDash color={'grey'} />
                    </>
                );
            } else {
                return (
                    <>
                        {`${value1} ${unit} `}
                        {<IoIosArrowRoundDown color={'rgb(0, 211, 0)'} />}
                    </>
                );
            }
        };

    return (

        <div className='weather-wind-container'>
            <table className='weather-table'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Wave Height</th>
                        <th>Wave Period</th>
                        <th>Wind Speed</th>
                        <th>Wind Direction</th>
                        <th>Gust Speed</th>
                        <th>Air Temp</th>
                        <th>Water Temp</th>
                        <th>Barometer</th>
                        <th>Update Time</th>
                    </tr>
                </thead>
                <tbody>
                {Object.values(waveBuoy).map((data, index) => {

                    console.log('data', data)

                    return (
                        <tr 
                            key={index}
                            onClick={() => setSelectedStation(data)}
                        >
                            <td>{data.name}</td>
                            <td>{renderTableCellContent(data.waveHeight[0], data.waveHeight[1], 'ft')}</td>
                            <td>{renderTableCellContent(data.wavePeriod[0], data.wavePeriod[1], 's')}</td>
                            <td>{renderTableCellContent(data.windSpeed[0], data.windSpeed[1], 'kn')}</td>
                            <td><WindDirectionIndicator direction={data.windDirection[0]} /></td>
                            <td>{renderTableCellContent(data.gustSpeed[0], data.gustSpeed[1], 'kn')}</td>
                            <td>{renderTableCellContent(data.airTemp[0], data.airTemp[1], 'C')}</td>
                            <td>{renderTableCellContent(data.waterTemp[0], data.waterTemp[1], 'C')}</td>
                            <td>{renderTableCellContent(data.barometer[0], data.barometer[1], '')}</td>
                            {data.updateTime[0] ? <td>{transformTime(data.updateTime[0])}</td> : <td>N/A</td>}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>

    )

}

export default WeatherTableWave;