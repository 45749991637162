export const BCPolygon = [
  { latitude: 54.7089499, longitude: -130.6216919 },
  { latitude: 54.2829249, longitude: -134.848018 },
  { latitude: 51.3220939, longitude: -133.1561235 },
  { latitude: 48.3544917, longitude: -126.5313676 },
  { latitude: 46.1171083, longitude: -125.8062699 },
  { latitude: 46.0942569, longitude: -123.9825395 },
  { latitude: 46.6625755, longitude: -123.9904707 },
  { latitude: 47.7374204, longitude: -124.402458 },
  { latitude: 47.9218085, longitude: -124.6166914 },
  { latitude: 48.1715255, longitude: -124.7100752 },
  { latitude: 48.368968, longitude: -124.6469038 },
  { latitude: 48.1971629, longitude: -124.11956 },
  { latitude: 48.1495403, longitude: -123.8751142 },
  { latitude: 48.1440426, longitude: -123.6965864 },
  { latitude: 48.1128775, longitude: -123.3697431 },
  { latitude: 48.1092097, longitude: -123.2488935 },
  { latitude: 48.1458752, longitude: -123.1555097 },
  { latitude: 48.032127, longitude: -123.0236738 },
  { latitude: 47.9861896, longitude: -122.8753584 },
  { latitude: 48.0431459, longitude: -122.7709882 },
  { latitude: 47.8868252, longitude: -122.6776044 },
  { latitude: 47.7134018, longitude: -122.7929609 },
  { latitude: 47.281036, longitude: -122.5347822 },
  { latitude: 47.2698551, longitude: -122.4359052 },
  { latitude: 47.3703983, longitude: -122.3315351 },
  { latitude: 47.5894381, longitude: -122.4139326 },
  { latitude: 47.6949185, longitude: -122.4139326 },
  { latitude: 47.8075651, longitude: -122.378227 },
  { latitude: 48.0119196, longitude: -122.2024458 },
  { latitude: 48.1183786, longitude: -122.3480146 },
  { latitude: 48.3068924, longitude: -122.3754804 },
  { latitude: 48.4145635, longitude: -122.5595014 },
  { latitude: 48.4637609, longitude: -122.4880903 },
  { latitude: 48.607435, longitude: -122.4413984 },
  { latitude: 48.7760481, longitude: -122.49633 },
  { latitude: 48.8899491, longitude: -122.779228 },
  { latitude: 48.9747543, longitude: -122.7297895 },
  { latitude: 49.1025912, longitude: -122.864372 },
  { latitude: 49.0540159, longitude: -123.0978315 },
  { latitude: 49.1376113, longitude: -123.1499643 },
  { latitude: 49.1798538, longitude: -123.180229 },
  { latitude: 49.2302346, longitude: -123.1970351 },
  { latitude: 49.2649729, longitude: -123.2567733 },
  { latitude: 49.2770695, longitude: -123.2430403 },
  { latitude: 49.2687814, longitude: -123.1630461 },
  { latitude: 49.2649729, longitude: -123.113951 },
  { latitude: 49.2739336, longitude: -123.1036513 },
  { latitude: 49.2781894, longitude: -123.1094878 },
  { latitude: 49.2737097, longitude: -123.1197875 },
  { latitude: 49.2734857, longitude: -123.1321471 },
  { latitude: 49.2922981, longitude: -123.1465666 },
  { latitude: 49.3012539, longitude: -123.155493 },
  { latitude: 49.3117749, longitude: -123.1499999 },
  { latitude: 49.3119987, longitude: -123.1424468 },
  { latitude: 49.2996868, longitude: -123.125624 },
  { latitude: 49.2965523, longitude: -123.136267 },
  { latitude: 49.2907307, longitude: -123.1311171 },
  { latitude: 49.2844605, longitude: -123.101248 },
  { latitude: 49.2842365, longitude: -123.0827086 },
  { latitude: 49.2869239, longitude: -123.0744689 },
  { latitude: 49.2853563, longitude: -123.0634825 },
  { latitude: 49.2920742, longitude: -123.0514662 },
  { latitude: 49.2896111, longitude: -123.0312102 },
  { latitude: 49.289835, longitude: -122.9920714 },
  { latitude: 49.286252, longitude: -122.9615157 },
  { latitude: 49.2896111, longitude: -122.9313033 },
  { latitude: 49.2871478, longitude: -122.8997176 },
  { latitude: 49.2905068, longitude: -122.8887313 },
  { latitude: 49.2795333, longitude: -122.8585189 },
  { latitude: 49.2804292, longitude: -122.8362029 },
  { latitude: 49.286252, longitude: -122.833113 },
  { latitude: 49.3032687, longitude: -122.8712218 },
  { latitude: 49.2956567, longitude: -122.9155104 },
  { latitude: 49.3155798, longitude: -122.9254668 },
  { latitude: 49.3137893, longitude: -122.9107039 },
  { latitude: 49.3444428, longitude: -122.876715 },
  { latitude: 49.3940743, longitude: -122.8547423 },
  { latitude: 49.4503368, longitude: -122.8468432 },
  { latitude: 49.4675199, longitude: -122.8756823 },
  { latitude: 49.4639499, longitude: -122.8962817 },
  { latitude: 49.3712615, longitude: -122.9055514 },
  { latitude: 49.3245161, longitude: -122.9587664 },
  { latitude: 49.3075067, longitude: -122.9573932 },
  { latitude: 49.3115358, longitude: -123.0013385 },
  { latitude: 49.3032534, longitude: -123.016788 },
  { latitude: 49.3061636, longitude: -123.0511203 },
  { latitude: 49.3072828, longitude: -123.0717196 },
  { latitude: 49.3182501, longitude: -123.0936923 },
  { latitude: 49.3128787, longitude: -123.1170382 },
  { latitude: 49.3249636, longitude: -123.1448474 },
  { latitude: 49.3419669, longitude: -123.2107653 },
  { latitude: 49.3410722, longitude: -123.264667 },
  { latitude: 49.3580699, longitude: -123.265697 },
  { latitude: 49.364778, longitude: -123.2808032 },
  { latitude: 49.3728264, longitude: -123.2783999 },
  { latitude: 49.3748383, longitude: -123.2650103 },
  { latitude: 49.418856, longitude: -123.2296481 },
  { latitude: 49.4579249, longitude: -123.2361712 },
  { latitude: 49.5259827, longitude: -123.2457576 },
  { latitude: 49.6114829, longitude: -123.2045589 },
  { latitude: 49.6488422, longitude: -123.2086788 },
  { latitude: 49.657733, longitude: -123.1688533 },
  { latitude: 49.692836, longitude: -123.1400142 },
  { latitude: 49.7052715, longitude: -123.1839595 },
  { latitude: 49.6732881, longitude: -123.266357 },
  { latitude: 49.6128177, longitude: -123.287643 },
  { latitude: 49.5348959, longitude: -123.5066829 },
  { latitude: 49.433206, longitude: -123.4919595 },
  { latitude: 49.3983628, longitude: -123.5194253 },
  { latitude: 49.4394573, longitude: -123.680787 },
  { latitude: 49.4747174, longitude: -123.7391519 },
  { latitude: 49.7578003, longitude: -123.3829338 },
  { latitude: 50.230996, longitude: -123.7619621 },
  { latitude: 50.5112834, longitude: -124.3387444 },
  { latitude: 50.9632237, longitude: -124.8221428 },
  { latitude: 51.1393335, longitude: -125.6241448 },
  { latitude: 51.4655974, longitude: -126.135009 },
  { latitude: 51.7249406, longitude: -126.5140373 },
  { latitude: 52.3903067, longitude: -126.7062981 },
  { latitude: 52.8969293, longitude: -126.9644768 },
  { latitude: 53.1381589, longitude: -127.3929436 },
  { latitude: 53.4533423, longitude: -127.8983147 },
  { latitude: 53.4815829, longitude: -127.9168964 },
  { latitude: 53.5029924, longitude: -127.9072411 },
  { latitude: 53.5338528, longitude: -127.9223895 },
  { latitude: 53.5664887, longitude: -127.9883075 },
  { latitude: 53.5762745, longitude: -128.0487323 },
  { latitude: 53.5982845, longitude: -128.1050372 },
  { latitude: 54.7089499, longitude: -130.6216919 }
  ];
  