import React from 'react';

const WindDirectionIndicatorLarge = ({ direction }) => {
  // Calculate the rotation angle based on wind direction
  const getRotationAngle = (direction) => {
    const directionMap = {
      N: 0,
      NNE: 22.5,
      NE: 45,
      ENE: 67.5,
      E: 90,
      ESE: 112.5,
      SE: 135,
      SSE: 157.5,
      S: 180,
      SSW: 202.5,
      SW: 225,
      WSW: 247.5,
      W: 270,
      WNW: 292.5,
      NW: 315,
      NNW: 337.5,
    };
    return directionMap[direction?.toUpperCase()] || 0;
  };

  const rotation = getRotationAngle(direction);

  return (
    <svg className='weather-forecast-wind-svg' width="100" height="100" >
      <circle cx="50" cy="50" r="28" fill="rgba(30,30,30,1)" stroke="rgba(50,71,94,1)" strokeWidth="2" />
      <polygon
        points="42,32 50,14 58,32"
        fill="orange"
        transform={`rotate(${rotation} 50 50)`}
      />
      <text x="50" y="50" textAnchor="middle" dy="5" fill="white" fontSize="10">
        {direction}
      </text>
    </svg>
  );
};

export default WindDirectionIndicatorLarge;
