import React, { useState, useRef, useEffect, useContext } from 'react';
import { GoogleMap, Marker, InfoWindowF, InfoWindow, LoadScript, Polyline, Polygon } from '@react-google-maps/api';
import * as geolib from 'geolib';
import getTideStationInfo from '../../functions/getTideStations'
import SidebarPro from '../global/sidebar';
import BottomNav from '../global/bottomNav';
import ModalSmall from '../global/modalSmall';
import { Chart as ChartJS, registerables, scales } from 'chart.js';
import { Line } from 'react-chartjs-2';
import SimpleCloseBtn from '../global/simpleCloseBtn';
import { RotatingLines } from 'react-loader-spinner'
import { mainBlue, mainBlueTwenty } from '../../styling/colors';
import { Context } from '../context/context';
import Footer from '../global/footer';
ChartJS.register(...registerables);

export default function Tides() {

    const userLocation = useContext(Context).userLocation;

    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const mapId = '153fb55674dee1df';
    const libraries = ['places'];
    const mapRef = useRef(null); // Reference to Google Map component

    const [tideStations, setTideStations] = useState([]);
    const [tideData, setTideData] = useState([]);
    const [tideTableHeader, setTideTableHeader] = useState('');
    const [tideTableLabels, setTideTableLabels] = useState([]);
    const [selectedStation, setSelectedStation] = useState(null);
    const [mobile, setMobile] = useState(window.innerWidth < 600);
    const [hoveredStation, setHoveredStation] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: 49.3, lng: -123.33 });
    const [render, setRender] = useState(false);
  
    const handleMouseOver = (tideStation, position) => {
      setHoveredStation(tideStation);
    };
  
    const handleMouseOut = () => {
      setHoveredStation(null);
    };

    // NEED TO SORT BY DATE AND THEN EXTRACT TIME, DATA ARRAY FOR LINE IS SORTING TIME INCORRECTLY

    useEffect(() => {
        const date = new Date(tideData[2]?.eventDate);

        const formattedDay = date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });

        setTideTableHeader(formattedDay);

        tideData.map((data) => {
        const date = new Date(data?.eventDate);
        const formattedTime = date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'America/Los_Angeles',
        });

        setTideTableLabels((prev) => [...prev, formattedTime]);

    });
    }, [tideData]);
      
    const data = {
        labels: tideTableLabels,
        datasets: [
          {
            label: tideTableHeader,
            data: tideData.map((data) => data.value),
            tension: .5,
            fill: true,
            backgroundColor: "rgba(255,255,255,0.1)",
            borderColor: "orange",          
          },
        ]
      };

    useEffect(() => {
        getTideStationInfo(setTideStations);
    }

    , []);

    setTimeout(() => {
        setRender(true);
    }
    , 500);

    const setStartOfDay = (selectedDate) => {
        const startOfDay = new Date(selectedDate);
        startOfDay.setHours(0, 0, 0, 0);
        return startOfDay;
      };
      
      const setEndOfDay = (selectedDate) => {
        const endOfDay = new Date(selectedDate);
        endOfDay.setHours(23, 59, 59, 999);
        return endOfDay;
      };
  
      const [dateTime, setDateTime] = useState({
        start: setStartOfDay(new Date()),
        end: setEndOfDay(new Date()),
      });

    const getTideData = () => {

        setTideTableLabels([]);

        // Find data for nearest station
        fetch(`https://api-iwls.dfo-mpo.gc.ca/api/v1/stations/${selectedStation?.id}/data?time-series-code=wlp-hilo&from=${dateTime?.start.toISOString()}&to=${dateTime?.end.toISOString()}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          // Update the state variable you intended to use
          setTideData(data);
          // Process the JSON data here
        })
        .catch((error) => {
          console.error('Error fetching data:', error.response.data);
        });
      }

      useEffect(() => {
        if (selectedStation?.id) {
          getTideData();
        }
        }
        , [selectedStation, dateTime]);


        return (
            <div className='tide-page'>
                {mobile ? <BottomNav /> : <SidebarPro />}
                <LoadScript googleMapsApiKey={apiKey} libraries={libraries} mapIds={[mapId]}>
                {hoveredStation && <div className='hovered-fishing-area'>Station: {hoveredStation.officialName}</div>}
                <GoogleMap
                  ref={mapRef}
                  mapContainerStyle={{ height: '100vh', width: '100vw' }}
                  center={mapCenter}
                  zoom={8}
                  options={
                      {
                        fullscreenControl: false,
                        mapTypeControl: false, 
                        mapId: mapId 
                      }
                      }
                    >
                    {render && userLocation.lat !== 0 && userLocation.lng !== 0 &&
                      <Marker
                        position={{ lat: userLocation.lat, lng: userLocation.lng }}
                        icon={{
                          url: require('../../images/userDot.png'),
                          scaledSize: new window.google.maps.Size(15, 15)
                          }}
                      />          
                    }
                    {tideStations.length > 0 &&
                        tideStations.map((tideStation, index) => {
                        return (
                            <Marker
                              key={index}
                              position={{ lat: tideStation.latitude, lng: tideStation.longitude }}
                              icon={{
                                  url: require('../../images/orangeDot.png'),
                                  scaledSize: new window.google.maps.Size(10, 10)
                              }}
                              onClick={() => {
                                  setSelectedStation(tideStation);
                              }}
                              onMouseOver={(e) => handleMouseOver(tideStation, e)}
                              onMouseOut={handleMouseOut}
                            />
                        )
                    }
                    )}
                    {selectedStation && (
                    <ModalSmall>
                        {tideTableLabels.length === 0 && (
                        <div className='loading-spinner'>
                            <RotatingLines
                              visible={true}
                              strokeColor='grey'
                              height="36"
                              width="36"
                              strokeWidth="3"
                              animationDuration="0.75"
                            />
                        </div>
                        )}
                        <SimpleCloseBtn onClick={() => setSelectedStation(null)} />
                        <p style={{textAlign: 'center', marginTop: 0, fontSize:'20px', color: 'white'}}>{selectedStation?.officialName}</p>
                        <input 
                            type='date' 
                            value={dateTime.start.toISOString().split('T')[0]} 
                            onChange={(e) => {
                              const selectedDate = new Date(e.target.value); // Create Date object from selected date
                              selectedDate.setDate(selectedDate.getDate() + 1); // Add 1 day to the selected date
                              setDateTime({ 
                              start: setStartOfDay(selectedDate), // Update start date
                              end: setEndOfDay(selectedDate) // Update end date with the modified selected date
                            });
                        }} 
                        />
                        <Line data={data} className='tide-line-chart' options={{
                          scales: {
                            y: {
                              grid: {
                                display: true, // Disable y-axis grid lines
                                color: 'rgba(255,255,255,0.1)' // Set grid line color
                              },
                              ticks: {
                                color: 'white' // Set x-axis font color
                              }
                            }, 
                            x: {
                              grid: {
                                display: true, // Disable y-axis grid lines
                                color: 'rgba(255,255,255,0.1)' // Set grid line color
                              },
                              ticks: {
                                color: 'white' // Set x-axis font color
                              }
                            }, 
                          },
                          plugins: {
                            legend: {
                              display: true
                              }
                            },
                          }}                           
                        />
                    </ModalSmall>
                    )}
                </GoogleMap>
                </LoadScript>
                <Footer />
            </div>
            );

}
