import { transformTime } from "../../functions/transformTime";
import WindDirectionIndicator from "../global/windArrow";

const WeatherTableWindTable = ({ weatherTableData }) => {

    // SHOW PEAK WIND IN LAST FEW HOURS??

    return (
        <div>
           <table className='weather-table'>
                <thead>
                    <tr>
                        <th>Wind Speed</th>
                        <th>Wind Direction</th>
                        <th>Time</th>
                    </tr>
                </thead>
                <tbody>
                {weatherTableData.windDirection.map((direction, index) => {
                return (
                    <tr key={index}>
                    <td>{weatherTableData.windSpeed[index]}</td>
                    <td><WindDirectionIndicator direction={direction} /></td>
                    <td>{transformTime(weatherTableData.updateTime[index])}</td>
                    </tr>
                );
                })}
                </tbody>
            </table>
        </div>
    );
}

export default WeatherTableWindTable;