

const SimpleCloseBtn = ({ onClick }) => {
    return (
        <button
            className="simple-close-btn"
            onClick={onClick}
        >
            X
        </button>
    );
}

export default SimpleCloseBtn;