
const dallasRoad = {
    name: 'Dallas Road',
    url: 'https://bigwavedave.ca/dallas-road-webcam-live.html',
    coordinates: { lat: 48.40649079, lng: -123.364773 },
    generalLocation: 'Victoria, BC',
    directionFacing: 'south',
    className: ['webcam-window6', 'webcam-iframe6', ''],
    zoomLevel: 'high'
    };
const rossBay = {
    name: 'Dallas Road',
    url: 'https://bigwavedave.ca/ross-bay-webcam-live.html',
    coordinates: { lat: 48.4103430, lng: -123.3389693 },
    generalLocation: 'Victoria, BC',
    directionFacing: 'south',
    className: ['webcam-window5', 'webcam-iframe5', ''],
    zoomLevel: 'off'
    };
const islandView = {
    name: 'Island View',
    url: 'https://bigwavedave.ca/island-view.html',
    coordinates: { lat: 48.572853670, lng: -123.3666118 },
    generalLocation: 'Victoria, BC',
    directionFacing: 'east',
    className: ['webcam-window6', 'webcam-iframe6', '6'],
    zoomLevel: 'medium'
    };
const willowsBeach = {
    // issue with it bringing up env forecast
    name: 'Willows Beach',
    url: 'https://bigwavedave.ca/willows-beach.html',
    coordinates: { lat: 48.433708, lng: -123.30360879 },
    generalLocation: 'Victoria, BC',
    directionFacing: 'east',
    className: ['webcam-window6', 'webcam-iframe6', ''],
    zoomLevel: 'medium'
    };
const gooseSpit = {
    name: 'Goose Spit',
    url: 'https://bigwavedave.ca/goose-spit.html',
    coordinates: { lat: 49.66908317, lng: -124.90470638 },
    generalLocation: 'Comox, BC',
    directionFacing: 'south',
    className: ['webcam-window2', 'webcam-iframe2', '2'],
    zoomLevel: 'off'
    };
const pipersLagoon = {
    name: 'Pipers Lagoon',
    url: 'https://bigwavedave.ca/pipers-lagoon.html',
    coordinates: { lat: 49.22857498, lng: -123.9485483 },
    generalLocation: 'Nanaimo, BC',
    directionFacing: 'northEast',
    className: ['webcam-window2', 'webcam-iframe2', '2'],
    zoomLevel: 'off'
    };
const sanPareil = {
    name: 'San Pareil',
    url: 'https://bigwavedave.ca/san-pareil.html',
    coordinates: { lat: 49.329923519, lng: -124.280907 },
    generalLocation: 'Parksville, BC',
    directionFacing: 'north',
    className: ['webcam-window2', 'webcam-iframe2', '2'],
    zoomLevel: 'off'
    };
const deepBay = {
    name: 'Deep Bay',
    url: 'https://bigwavedave.ca/deep-bay.html',
    coordinates: { lat: 49.46710661, lng: -124.72511025 },
    generalLocation: 'Deep Bay, BC',
    directionFacing: 'north',
    className: ['webcam-window2', 'webcam-iframe2', '2'],
    zoomLevel: 'off'
    };
const lyallHarbour = {
    name: 'Lyall Harbour',
    url: 'https://www.coastwebcams.ca/Reolink/LyallHarbour.jpg',
    coordinates: { lat:  48.79752516, lng: -123.2013087 },
    generalLocation: 'Saturna Island, BC',
    directionFacing: 'northWest',
    className: ['webcam-window', 'webcam-iframe', '', 'webcam-img'],
    zoomLevel: 'low',
    img: true
    };
const activePass = {
    name: 'Active Pass',
    url: 'https://www.coastwebcams.ca/Reolink/ActivePass.jpg',
    coordinates: { lat:  48.877811146, lng: -123.3166893 },
    generalLocation: 'Galiano Island, BC',
    directionFacing: 'southEast',
    className: ['webcam-window', 'webcam-iframe', '', 'webcam-img'],
    zoomLevel: 'low',
    img: true
    };
const lionsBay = {
    name: 'Lions Bay',
    url: 'https://www.coastwebcams.ca/Reolink/LionsBay.jpg',
    coordinates: { lat:  49.4589954, lng: -123.2417156 },
    generalLocation: 'Lions Bay, BC',
    directionFacing: 'west',
    className: ['webcam-window4', 'webcam-iframe4', '4', 'webcam-img'],
    zoomLevel: 'off',
    img: true
    };
const keatsIsland = {
    name: 'Keats Island',
    url: 'https://www.coastwebcams.ca/Reolink/KeatsIsland.jpg',
    coordinates: { lat:  49.405142243, lng: -123.43543066 },
    generalLocation: 'Keats Island, BC',
    directionFacing: 'north',
    className: ['webcam-window2', 'webcam-iframe2', '6', 'webcam-img'],
    zoomLevel: 'off',
    img: true
    };
const harlingPoint = {
    name: 'Harling Point',
    url: 'https://namroc.com/dev/pic0.jpg',
    coordinates: { lat:  48.406438788, lng: -123.32205581 },
    generalLocation: 'Victoria, BC',
    directionFacing: 'south',
    className: ['webcam-window', 'webcam-iframe', '5', 'webcam-img'],
    zoomLevel: 'low',
    img: true
    };
const patBay = {
    name: 'Pat Bay',
    url: 'https://patbaywebcam.com/webcam/pb.jpg',
    coordinates: { lat: 48.65904263, lng: -123.44726951 },
    generalLocation: 'Saanich, BC',
    directionFacing: 'west',
    className: ['webcam-window', 'webcam-iframe', '5', 'webcam-img'],
    zoomLevel: 'off',
    img: true
    };
const saturnaSW = {
    name: 'Saturna SW',
    url: 'https://www.metcam.navcanada.ca/dawc_images/wxcam/TM20/TM20_SW-full-e.jpeg',
    coordinates: { lat: 48.77458626, lng: -123.2014796 },
    generalLocation: 'Saturna Island, BC',
    directionFacing: 'southWest',
    className: ['webcam-window', 'webcam-iframe', '6', 'webcam-img'],
    zoomLevel: 'low',
    img: true
    };
const saturnaE = {
    name: 'Saturna E',
    url: 'https://www.metcam.navcanada.ca/dawc_images/wxcam/TM20/TM20_E-full-e.jpeg',
    coordinates: { lat: 48.7830143, lng: -123.0463244 },
    generalLocation: 'Saturna Island, BC',
    directionFacing: 'East',
    className: ['webcam-window', 'webcam-iframe', '6', 'webcam-img'],
    zoomLevel: 'off',
    img: true
    };
const saturnaN = {
    name: 'Saturna N',
    url: 'https://www.metcam.navcanada.ca/dawc_images/wxcam/TM20/TM20_N-full-e.jpeg',
    coordinates: { lat: 48.8326294, lng: -123.22707 },
    generalLocation: 'Saturna Island, BC',
    directionFacing: 'North',
    className: ['webcam-window', 'webcam-iframe', '6', 'webcam-img'],
    zoomLevel: 'low',
    img: true
    };
const gibsons = {
    name: 'Gibsons',
    url: 'https://www.coastwebcams.ca/Reolink/Gibsons.jpg',
    coordinates: { lat: 49.399479719, lng: -123.507137 },
    generalLocation: 'Gibsons, BC',
    directionFacing: 'North',
    className: ['webcam-window5', 'webcam-iframe5', '5', 'webcam-img'],
    zoomLevel: 'low',
    img: true
    };
const penderHarbourEntrance = {
    name: 'Pender Harbour',
    url: 'https://cameraftpapi.drivehq.com/api/Camera/GetCameraThumbnail.ashx?parentID=323295735&shareID=14973166&width=960&height=540&t=',
    coordinates: { lat: 49.62982283, lng: -124.053495 },
    generalLocation: 'Pender Harbour, BC',
    directionFacing: 'West',
    className: ['webcam-window5', 'webcam-iframe5', '5', 'webcam-img'],
    zoomLevel: 'low',
    img: true
    };
const penderHarbourSouth = {
    name: 'Pender Harbour South',
    url: 'https://cameraftpapi.drivehq.com/api/Camera/GetCameraThumbnail.ashx?parentID=327956563&shareID=16795532&width=960&height=540&t=',
    coordinates: { lat: 49.60288813, lng: -124.0573174 },
    generalLocation: 'Pender Harbour, BC',
    directionFacing: 'South',
    className: ['webcam-window5', 'webcam-iframe5', '5', 'webcam-img'],
    zoomLevel: 'off',
    img: true
    };
const penderHarbourNorth = {
    name: 'Pender Harbour North',
    url: 'https://cameraftpapi.drivehq.com/api/Camera/GetCameraThumbnail.ashx?parentID=320813398&shareID=14918524&width=960&height=540&t=',
    coordinates: { lat: 49.64835968, lng: -124.072367 },
    generalLocation: 'Pender Harbour, BC',
    directionFacing: 'North',
    className: ['webcam-window5', 'webcam-iframe5', '5', 'webcam-img'],
    zoomLevel: 'low',
    img: true
    };
const savaryIsland = {
    name: 'Savary Island',
    url: 'https://cameraftpapi.drivehq.com/api/Camera/GetCameraThumbnail.ashx?parentID=250217760&shareID=14003575&width=960&height=540&t=',
    coordinates: { lat: 49.948818979, lng: -124.86184629 },
    generalLocation: 'Savary Island, BC',
    directionFacing: 'North',
    className: ['webcam-window5', 'webcam-iframe5', '5', 'webcam-img'],
    zoomLevel: 'medium',
    img: true
    };
const campbellRiver = {
    name: 'Campbell River',
    url: 'https://cameraftpapi.drivehq.com/api/Camera/GetCameraThumbnail.ashx?parentID=378263996&shareID=17572896&width=960&height=540&t=',
    coordinates: { lat: 50.04574354, lng: -125.2519006 },
    generalLocation: 'Campbell River, BC',
    directionFacing: 'East',
    className: ['webcam-window5', 'webcam-iframe5', '5', 'webcam-img'],
    zoomLevel: 'off',
    img: true
    };
const englishBay = {
    name: 'English Bay',
    url: 'https://images.webcamgalore.com/135-current-webcam-Vancouver.jpg',
    coordinates: { lat: 49.2878639, lng: -123.143473 },
    generalLocation: 'Vancouver, BC',
    directionFacing: 'NorthWest',
    className: ['webcam-window5', 'webcam-iframe5', '5', 'webcam-img'],
    zoomLevel: 'off',
    img: true
    };
// const grottoSpa = {
//     name: 'Campbell River',
//     url: 'https://www.webcamtaxi.com/en/canada/british-columbia/georgia-strait-live-view.html',
//     coordinates: { lat: 49.3133982, lng: -124.262911 },
//     generalLocation: 'Parksville, BC',
//     directionFacing: 'NorthEast',
//     className: ['webcam-window5', 'webcam-iframe5', '5', 'webcam-img'],
//     img: true
//     };
// const jerichoBeachCam = {
//     name: 'Jericho Beach',
//     url: 'https://www.earthtv.com/en/webcam/vancouver-jericho-sailing-centre',
//     coordinates: { lat: 49.275695, lng: -123.200575 },
//     generalLocation: 'Vancouver, BC',
//     directionFacing: 'north',
//     className: ['webcam-window2', 'webcam-iframe2', '2']
//     };


    export const newWebCamArray = [
        dallasRoad,
        rossBay,
        islandView,
        willowsBeach,
        gooseSpit,
        pipersLagoon,
        sanPareil,
        deepBay,
        lyallHarbour,
        activePass,
        lionsBay,
        keatsIsland,
        harlingPoint,
        patBay,
        saturnaSW,
        saturnaE,
        saturnaN,
        gibsons,
        penderHarbourEntrance,
        penderHarbourSouth,
        penderHarbourNorth,
        savaryIsland,
        campbellRiver,
        englishBay,
        // grottoSpa,
        // jerichoBeachCam,
    ]