import React from 'react';

const WeatherSVGMarker = () => (
    <svg style={{zIndex: 999999}} width="200px" height="200px" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="30" fill="rgba(255,0,0,.2)" />
    <circle cx="50" cy="50" r="50" fill="none" stroke="rgba(255,0,0,.3)" strokeWidth="32">
      <animate
        attributeName="r"
        from="10"
        to="45"
        dur="2s"
        begin="0s"
        repeatCount="indefinite"
        fill="freeze"
      />
      <animate
        attributeName="opacity"
        from="1"
        to="0"
        dur="2s"
        begin="0s"
        repeatCount="indefinite"
        fill="freeze"
      />
    </circle>
    <circle cx="50" cy="50" r="60" fill="none" stroke="rgba(255,0,0,.1)" strokeWidth="50">
      <animate
        attributeName="r"
        from="10"
        to="45"
        dur="2s"
        begin="0.75s"
        repeatCount="indefinite"
        fill="freeze"
      />
      <animate
        attributeName="opacity"
        from="1"
        to="0"
        dur="2s"
        begin="0.75s"
        repeatCount="indefinite"
        fill="freeze"
      />
    </circle>
  </svg>
);

export default WeatherSVGMarker;
