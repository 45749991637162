import '../../App.css';


const ModalLarge = ({ children }) => {

    return (
        <div className="modal-wrapper-small" >
            <div className='modal-content'>
                {children}
            </div>
        </div>
    )

}

export default ModalLarge;