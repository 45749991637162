

const WindSpeedChart = () => {

    return (

        <div className="wind-speed-bar">
        <div className="wind-speed-labels">
          <span>0</span>
          <span>5</span>
          <span>10</span>
          <span>15</span>
          <span>20</span>
          <span>25+</span>
        </div>
        <div className="gradient-bar"></div>
      </div>

    )

}

export default WindSpeedChart;