import React from 'react';
import { VscSettings } from 'react-icons/vsc';

const WeatherSettingsBtn = ({ setWeatherSettingsOpen }) => {
    return (
        <div 
            className='weather-map-settings-btn'
            onClick={setWeatherSettingsOpen}
        >
        <VscSettings size={20} color='whitesmoke' />
      </div>
    )
}

export default WeatherSettingsBtn;