import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner'
import { transformKey } from '../../functions/transformKey';
import { mainBlue } from '../../styling/colors';
import { IoIosArrowBack } from "react-icons/io";
import { FaMapMarkedAlt } from "react-icons/fa";
import SimpleCloseBtn from '../global/simpleCloseBtn';
import { click } from '@testing-library/user-event/dist/click';

const MapRegsSpeciesSelection = ({ clickedFishingArea, returnToMap }) => {

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [areaRegsData, setAreaRegsData] = useState([]);
    const [subAreaRegsData, setSubAreaRegsData] = useState([]);
    const [uniqueSpecies, setUniqueSpecies] = useState([]);
    const [selectedSpecies, setSelectedSpecies] = useState('');
    const [speciesRegs, setSpeciesRegs] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const rearrangedSelectedReg = {
        'Min Size': speciesRegs.min_size ? speciesRegs.min_size : '-',
        'Max Size': speciesRegs.max_size ? speciesRegs.max_size : '-',
        'Daily Limit': speciesRegs.daily_limit ? speciesRegs.daily_limit : '-',
        'Possession Limit': speciesRegs.possession_limit ? speciesRegs.possession_limit : '-',
        'Annual Limit': speciesRegs.annual_limit ? speciesRegs.annual_limit : '-',
        'Gear': speciesRegs.gear ? speciesRegs.gear : '-',
      }
    const [rearrangedPartialOpeningReg, setRearrangedPartialOpeningReg] = useState({
        'Min Size': '-',
        'Max Size': '-',
        'Daily Limit': '-',
        'Possession Limit': '-',
        'Annual Limit': '-',
        'Gear': '-',
      });
    let updatedStatus = null;
    let mapLinkToShow = null;

    useEffect(() => {

        const fetchFishingAreaData = async () => {
          try {
            const response = await axios.get(API_BASE_URL + `/getFishingAreaRegulations?currentFishingArea=${clickedFishingArea}`);
            const results = response.data.rows;
            setAreaRegsData(results);
            setUniqueSpecies([...new Set(results.map(item => item.species_type))],
            )
          } catch (error) {
            console.log(`Error fetching data`);
          }
        };
        fetchFishingAreaData();
      
      }, [clickedFishingArea]);

    useEffect(() => {

        const fetchFishingSubAreaData = async () => {
            try {
                const response = await axios.get(API_BASE_URL + `/getFishingSubAreaRegulations`);
                const results = response.data.rows;
                console.log("^^^", results)
                setSubAreaRegsData(results);
            } catch (error) {
                console.log(`Error fetching data`);
            }
        };
        fetchFishingSubAreaData();

    }, [clickedFishingArea]);

    useEffect(() => {
        const matchingSubAreaReg = subAreaRegsData.find(subAreaReg =>
            subAreaReg.sub_area_id === speciesRegs.area_id && subAreaReg.species_id === speciesRegs.species_id
        );
    
        if (matchingSubAreaReg) {
            setRearrangedPartialOpeningReg(prevState => ({
                [matchingSubAreaReg.sub_area_id]: {
                    'Min Size': matchingSubAreaReg.min_size ? matchingSubAreaReg.min_size : '-',
                    'Max Size': matchingSubAreaReg.max_size ? matchingSubAreaReg.max_size : '-',
                    'Daily Limit': matchingSubAreaReg.daily_limit ? matchingSubAreaReg.daily_limit : '-',
                    'Possession Limit': matchingSubAreaReg.possession_limit ? matchingSubAreaReg.possession_limit : '-',
                    'Annual Limit': matchingSubAreaReg.annual_limit ? matchingSubAreaReg.annual_limit : '-',
                    'Gear': matchingSubAreaReg.gear ? matchingSubAreaReg.gear : '-',
                }
            }));
            updatedStatus = 'Partial Opening';
            mapLinkToShow = matchingSubAreaReg.map_link;
        }
    }, [subAreaRegsData, speciesRegs]);
    
    console.log("&&", rearrangedPartialOpeningReg)

      const filteredSpecies = uniqueSpecies.filter(species => species.toLowerCase().includes(searchQuery.toLowerCase()));
      const filteredSpeciesName = areaRegsData.filter(item => item.species_name.toLowerCase().includes(searchQuery.toLowerCase()));

      let modalText;
      let returnText = 'Map';

      if(!selectedSpecies) {
            modalText = 'Select Species Type';
      } else if(selectedSpecies && speciesRegs.length === 0) {
            modalText = 'Select Species Name';
            returnText = 'Species Type';
        } else {
            modalText = speciesRegs.species_name;
            returnText = 'Species Name';
        }
    
    return (
        <>
            {areaRegsData.length === 0 ? (
                <div className='loading-spinner'>
                    <RotatingLines
                        visible={true}
                        strokeColor='grey'
                        height="36"
                        width="36"
                        strokeWidth="3"
                        animationDuration="0.75"
                    />
                </div>
            ) : 
            <>
            <div className='fishing-regs-tile-container-header'>
            {
                <p
                    className='modal-return-to-btn'
                    onClick={() => {
                        if (returnText === 'Map') {
                            returnToMap();
                            setSelectedSpecies('');
                            setSpeciesRegs([]);
                        } else if (modalText === 'Select Species Name') {
                            setSelectedSpecies('');
                        } else {
                            setSpeciesRegs([]);
                        }
                    }}
                >
                    <IoIosArrowBack size={12} />
                    Return To {returnText}
                </p>
                }
                <p className='fishing-area-text'>Area: {clickedFishingArea}</p>
                <h3 style={modalText === speciesRegs.species_name ? {textAlign: 'center', padding: 8, fontSize: 18, marginTop: 30, color: 'white'} : {textAlign: 'center', padding: 8, marginTop: 30, color: 'white'}}>{transformKey(modalText)}</h3>
                {speciesRegs.length == 0 && 
                <input
                    type="text"
                    className='species-search-input'
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />}
            </div>
            <div style={{overflow: 'scroll', marginBottom: 50}}>
                <div className={speciesRegs.length !== 0 ? 'fishing-regs-tile-container-limits' : 'fishing-regs-tile-container'}>
                    {uniqueSpecies && selectedSpecies === '' && speciesRegs.length === 0 && (
                        filteredSpecies
                        .sort()
                        .map((speciesType, index) => (
                            <div 
                                className='fishing-regs-tile' 
                                key={index}
                                onClick={() => {
                                    setSelectedSpecies(speciesType);
                                    setSearchQuery('');
                                }
                                }
                            >
                                <p className='tile-text'>{speciesType}</p>
                            </div>
                        )))            
                    }
                    {selectedSpecies.speciesName !== '' && speciesRegs.length === 0 &&
                        filteredSpeciesName
                            .filter(item => selectedSpecies === item.species_type)
                            .sort((a, b) => a.species_name.localeCompare(b.species_name))
                            .map((item, index) => (
                                <div
                                    className='fishing-regs-tile' 
                                    key={index}
                                    onClick={() => {
                                        setSpeciesRegs(item);
                                        setSearchQuery('');
                                        console.log(item)
                                    }
                                    }
                                >
                                    <p style={item?.species_name?.length > 25 ? {fontSize: '14px'} : {}} className='tile-text'>{transformKey(item.species_name)}</p>
                                </div>    
                            ))
                    }
                    {speciesRegs.length !== 0 && (
                        <div className='fishing-reg-card'>
                            <div className='fishing-reg-card-status'>
                            {/* add partial opening text if there is a match with species ID and area ID in fishing_regulations_sub_area table */}   
                            {subAreaRegsData.map((subAreaReg, index) => {
                                // Check if both area_id and species_id match
                                if (subAreaReg.sub_area_id === speciesRegs.area_id && subAreaReg.species_id === speciesRegs.species_id) {
                                    updatedStatus = subAreaReg.status;
                                    mapLinkToShow = subAreaReg.map_link;
                                    return;                   
                                } else {
                                    return null;                   
                                }
                            })}                      
                                <span>{updatedStatus ? transformKey(updatedStatus) : speciesRegs.status}</span>                                
                            </div>
                            {updatedStatus && <div className='fishing-reg-card-map-link'>  
                                <a href={mapLinkToShow} target='_blank' rel='noreferrer'>
                                <span>View Open Area</span>                                
                                </a>                
                            </div>}
                            <div className='fishing-reg-card-details'>
                            {!updatedStatus && rearrangedSelectedReg && Object.entries(rearrangedSelectedReg).map(([key, value], index) => (
                                <div key={index}>
                                    <h5>{transformKey(key)}:</h5>
                                    <h4 style={value.length > 5 ? { fontSize: '15px'} : {}}>{value}</h4>
                                </div>
                            ))}
                            {updatedStatus && rearrangedPartialOpeningReg && Object.entries(rearrangedPartialOpeningReg).map(([areaId, areaDetails], index) => (
                                Object.entries(areaDetails).map(([key, value], index) => (
                                    <div key={index}>
                                        <h5>{transformKey(key)}:</h5>
                                        <h4 style={value.length > 5 ? { fontSize: '15px'} : {}}>{value}</h4>
                                    </div>
                                ))
                            ))}
                            </div>
                        </div> 
                    )               
                    }
                </div>
            </div>
            </>
            }
        </>

    );
    }

    export default MapRegsSpeciesSelection;