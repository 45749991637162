import '../../App.css';
import SimpleCloseBtn from './simpleCloseBtn';


const ModalCustom = ({ children, onClick, showCloseBtn }) => {

    return (
        <div className="modal-wrapper-custom" >
            {!showCloseBtn && <SimpleCloseBtn onClick={onClick} />}
            <div className='modal-content'>
                {children}
            </div>
        </div>
    )

}

export default ModalCustom;