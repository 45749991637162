import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
        "type": "service_account",
        "project_id": "sportyapp-68594",
        "private_key_id": "9e3eedcee7eac8a0a53cae915cc534e1fab9c519",
        "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCXSuPEJLoty8vd\nxpFkqAlOQx8jJ7Vsm/PoIwbpWuXFz1My9sZ0Ts9QnluPy0llbQMqXSbXlq9PDNau\nwz4ltQtLLRDfDKYxivb6KbPUzUDIVBgF8obKFO7sTgRb+zCM/bn71FoQNIL8HgRy\nOI1dKB/hnT/SyBHQX+qrZEFssoFLJl+W0wVif/+GjKaXepLVSXfOvKv53LGzJi+Q\nNttJbEuHdndiNKXYeMcG/Abg7B90aHz8bGRoEO2fyhW2AH8j8IDntsPzy84blr1K\nT22JGfz3qFHSXTZ9xmgaaykqVE9pkGJbrX09pojPRXhpTHnOc6C+j2GAxOiYRrfg\nvwqVDUDZAgMBAAECggEABU5i5Wcr2/gUOlRQ7F9hNIGxvYCyp2COCgfeIdBWiMzp\naZB35qOjzVuBJtk4hb0FwcY9WuLt2wdeXrKvPZuR+IpNYjijeg9E3lfY1wIhZJKg\nJTzrtGSjpL2k6mnJkBevM+lvWgw7cAFTWC21VeLMFnRr+lYFG5eyR3NXVkB+/M4/\nOTjtmsKLA7F+WQ4svSxilm+RM8EbUEATSe8AItQQAYJtmQdRLlFJM0RA+SQdkja1\nRLi+wAJ3Dp0y7CzS3eKWZtz+tYmZRXC2RcUcSuKPwqmYINAuYSSOd0d1IKye2FeP\nkox+Yd4lZ9MzYJMiaW7oHCOA7tiedqDdcVwr37ncQQKBgQDFNSiJ/c+mjJtNU7N4\nQHPgAwdHkMqNdXovwHeZQn965UYmdl7003uZKqyNKh7vRaunagO7oZlyh6rfbugW\nKq+Z/hFfMTHzx9Wb5h03pjR6v7W5jRSiU+gXBFSYBl5zoqVcekLw7ifH9H/Jog+i\nxJn5bqCa6yDedU1HkNo6umzVmQKBgQDEZYAyhhuthn9BHmivdwEobBI+uamkHrQV\nYVB/PfRknEGO2IxqeL5mGC37Bdg7gsMcQaWD0kpPlF6LDYBA1jsvbrtqIWEYmskh\nX/VWTTOc285BEwhqI5xXwl2r9AcQU7TczH5ZF+4GC3t2FeiqS5rXselWlMy3oZqG\ngG5ogAxNQQKBgEDBg95MCbOW2Cg8I+KIx4P3epx1Xw20Vbr4aE1bGKEySCa5F9Us\n3Jke+iogeqf3KLaAiVlDZy/EfMeBk5Qz0BWw5+NqNAzowau/lDiByxnDedjECtKC\n2uMz+NMyxDEUZ1hGjVSv30A5ePysypWYGTderD4yl0h1HDszxXUjYjmBAoGAQQam\nWpQip+KnUaVC3g3V+U9G/zOz4l40G/wbjfWybHl8D1LNsxcFW4JX/wwhWxrhhQKL\nTckbx5uFbh7rPwxvjjXooawpnN7rBzzhW1C2bRIuCUfG+nWN91SodR2YFSTR9mVS\n1CbjLWcx2phIb9IWk6IZcwxTd91u72eoprr218ECgYEAlHMgmT3+JwpUw1tPlXHr\nMh52Wl4prwA0XorX/Krup+PzzflIU5fScq+ZYKajMYNQufgKwX56HKb46UAGDe2I\nKFtBAuP+pOUf2kSFfWHb40T1VZG+1wx4521+GvqD2YYBwmyOJhLRqOQxbI0mZ/98\n46VZuN4l+crAxaloGvuox60=\n-----END PRIVATE KEY-----\n",
        "client_email": "firebase-adminsdk-m5txn@sportyapp-68594.iam.gserviceaccount.com",
        "client_id": "100789908631026473084",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-m5txn%40sportyapp-68594.iam.gserviceaccount.com",
        "universe_domain": "googleapis.com",
        "storageBucket": "gs://sportyapp-68594.appspot.com"
      }
      
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
