import { FaPlayCircle, FaPauseCircle } from "react-icons/fa";

const ForecastSlider = ({ forecastTimeArray, currentTime, playForecast, handlePlayButtonClick, handlePauseButtonClick, handleSliderChange, forecastTimes, hour }) => {

return (


<div>
{currentTime && 
    <div className="current-time-display">
    {currentTime}
    </div>
    }
    <div className="slider-container">
    {playForecast ? <FaPauseCircle color='blue' size={30} className='play-button' onClick={handlePauseButtonClick} /> : <FaPlayCircle color='blue' size={30} className='play-button' onClick={handlePlayButtonClick} />}
    <div className="slider-wrapper">
        <input 
            type="range" 
            min="0" 
            max="47" 
            value={hour} 
            className="slider" 
            onChange={handleSliderChange} 
        />
        <div className="slider-labels">
            <span>{forecastTimeArray[7]}</span>
            <span>{forecastTimeArray[23]}</span>
            <span>{forecastTimeArray[39]}</span>
        </div>
    </div>
    </div>
</div>

)

}

export default ForecastSlider;