

const WindSpeedWindow = ({ windSpeed }) => {
    return (
        <div className="wind-speed-window">
            <div>Wind Speed:</div>
            <div>{windSpeed} mph</div>
        </div>
    );
}

export default WindSpeedWindow;