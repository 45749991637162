// FUNCTION TO CONVERT UNITS 
export const convertUnitsUserSettings = (value, unit) => {
    if (value === 'N/A' || value === '-' || value === ' - ' || value === 'NA' || value === 'calm') {
      return value;
    } else if (unit === 'feet') {
        if(value.length > 3){
          return parseFloat(value).toFixed(0);
        } else {
            return parseFloat(value).toFixed(1);
        }
    } else if (unit === 'meters') {
      return (value * 0.3048).toFixed(1);
    } else if (unit === 'celsius') {
      return value;
    } else if (unit === 'fahrenheit') {
      return ((value * 1.8) + 32).toFixed(0);
    } else if (unit === 'knots') {
      return value;
    } else if (unit === 'km') {
      return (value * 1.852).toFixed(0);
    } else if (unit === 'miles') {
      return (value * 1.15078).toFixed(0);
    } else {
      return value;
    }
  };