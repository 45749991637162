import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import '../../App.css'; // Import your CSS file
import { mainBlue, mainBlueFifty } from '../../styling/colors';
import SimpleCloseBtn from '../global/simpleCloseBtn';
import { transformTime } from '../../functions/transformTime';
import SidebarPro from '../global/sidebar';
import BottomNav from '../global/bottomNav';
import WeatherLineChartWind from '../weatherTable/weatherLineChartWind';
import WeatherTableWind from '../weatherTable/weatherTableWind';
import WeatherTableWave from '../weatherTable/weatherTableWave';
import WeatherLineChartWave from '../weatherTable/weatherLineChartWave';
import WeatherTableWindTable from '../weatherTable/weatherTableWindTable';
import WeatherTableWaveTable from '../weatherTable/weatherTableWaveTable';
import * as geolib from 'geolib';
import { Context } from '../context/context';
import Footer from '../global/footer';
import { useLocation } from 'react-router-dom';

const WeatherPage = () => {

  const { state } = useLocation();

  const weatherData = state;

    const userLocation = useContext(Context).userLocation;
    const weatherStation = useContext(Context).weatherStation;
    const waveBuoy = useContext(Context).waveBuoy;
    const [mobile, setMobile] = useState(window.innerWidth < 600);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [selectedStation, setSelectedStation] = useState(false);
    const [selectedWeatherPage, setSelectedWeatherPage] = useState(weatherData ? weatherData : 'wind');

        // find distance between user and weather stations
        const calculateDistances = () => {
          
          // Convert the weatherStation object into an array of its values
          const stationsWithDistances = Object.values(weatherStation).map((station) => {
            const distance = geolib.getDistance(userLocation, station.coordinates);
            // add to the station object the distance from the user
            station.distance = distance;
            return { ...station, distance };
          });
        
          // // Sort the stations based on distance in ascending order
          // stationsWithDistances.sort((a, b) => a.distance - b.distance);
        
          // // Update the state with sorted stations
          // // console.log(",,,,", stationsWithDistances)
          // // setWeatherStation(stationsWithDistances);
        };

        useEffect(() => {
          if (userLocation) {
            calculateDistances();
          }
        }, [userLocation]);

      return (
        <div className="weather-page">
          {mobile ? <BottomNav /> : <SidebarPro />}
          {/* show different wind chart if the user selected the wave height object that has wind and wind gusts */}
          {selectedStation ? (
            selectedStation.waveHeight ? (
              <div className='wind-table-container'>
                <p className='page-title'>
                {selectedStation.name}
                </p>
                <SimpleCloseBtn onClick={() => setSelectedStation(false)} />
                <div>
                  <h3>Wave Height (ft)</h3>
                  <WeatherLineChartWave title={'Wave Height (ft)'} weatherData={selectedStation} />
                </div>
                <div>
                  <h3>Wind Speed (knots)</h3>
                  <WeatherLineChartWind weatherData={selectedStation} />
                </div>
                <div>
                  <WeatherTableWaveTable weatherTableData={selectedStation} />
                </div>
              </div>
            ) : (
              <div className='wind-table-container'>
                <p className='page-title'>
                {selectedStation.name}
                </p>
                <SimpleCloseBtn onClick={() => setSelectedStation(false)} />
                <div>
                    <WeatherLineChartWind weatherData={selectedStation} />
                </div>
                <div>
                    <WeatherTableWindTable weatherTableData={selectedStation} />
                </div>
              </div>
            )
          ) : (
            <div className='weather-table-wind-wave-container'>
              <div className='weather-data-button-container'>
                <div 
                  className={selectedWeatherPage === 'wind' ? 'weather-data-button active-button' : 'weather-data-button'}
                  onClick={() => setSelectedWeatherPage('wind')}
                >
                  <h3>Wind Data</h3>
                </div>
                <div 
                  className={selectedWeatherPage === 'wave' ? 'weather-data-button active-button' : 'weather-data-button'}
                  onClick={() => setSelectedWeatherPage('wave')}
                >
                  <h3>Wave Data</h3>
                </div>
              </div>
              <div className="weather-table-container">
                <div className="weather-table-tile-container">
                  {selectedWeatherPage === 'wind' ? <WeatherTableWind weatherStation={weatherStation} setSelectedStation={setSelectedStation} /> : <WeatherTableWave waveBuoy={waveBuoy} weatherStation={weatherStation} setSelectedStation={setSelectedStation} />}
                </div>
              </div>
            </div>
          )}
          <Footer />
        </div>
      );
      
};

export default WeatherPage;
