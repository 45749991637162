import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { transformTimeHours } from '../../functions/transformTime';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(...registerables);

const WeatherLineChartWave = ({ weatherData, title }) => {

    const validWaveHeights = weatherData.waveHeight.filter(height => !isNaN(height) && height !== undefined);
    const maxWaveHeight = Math.max(...validWaveHeights);

    const data = {
        labels: weatherData.updateTime.map((time) => transformTimeHours(time)),
        datasets: [
            {
                label: 'Wave Height',
                data: validWaveHeights,
                fill: true,
                tension: 0.4,
                backgroundColor: 'rgba(255,165,0,0)',
                borderColor: 'orange',
            },
        ],
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: false // Disable x-axis grid lines
                },
                ticks: {
                    color: 'white' // Set x-axis font color
                },
                reverse: true,
            },
            y: {
                beginAtZero: true,
                suggestedMax: maxWaveHeight + 1.5,
                grid: {
                    display: true, // Disable y-axis grid lines
                    color: 'rgba(255,255,255,0.1)' // Set grid line color
                },
                ticks: {
                    color: 'white' // Set x-axis font color
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        maintainAspectRatio: false, // Disable aspect ratio
        responsive: true, // Make the chart responsive
        plugins: {
            datalabels: {
                color: 'white', // Set color of data labels
                anchor: 'end', // Position of the anchor point (start, center, end)
                align: 'end', // Alignment of the text relative to the anchor point (start, center, end, auto)
                offset: -2, // Offset of the label from the anchor point
            }
        }
    };

    return (
        <div className='weather-div-container'>
            <Line className='line-chart' data={data} options={options} plugins={[ChartDataLabels]} />
        </div>
    );
};

export default WeatherLineChartWave;
