const getTideStationInfo = ( setState ) => {
      
    fetch('https://api-iwls.dfo-mpo.gc.ca/api/v1/stations')
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
        setState(data);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
        
  }

  export default getTideStationInfo;