import { useState } from 'react';
import BigButton from '../global/bigButton';
import SimpleCloseBtn from '../global/simpleCloseBtn';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Feedback = () => {

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [feedback, setFeedback] = useState("");

    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
    }

    const navigate = useNavigate();

    const handleClose = () => {
        navigate(-1); // This will navigate back to the previous page in the history stack
      };

    const submitFeedback = () => {
        if(feedback.length > 0) {
            axios.post(`${API_BASE_URL}/submitFeedback`, {
                feedback: feedback
            })
            .then(response => {
                if(response.status === 201) {
                setFeedback("");
                alert("Thank you for your feedback! We appreciate your input.");
                handleClose();   
                }             
            })
            .catch(error => {
                console.log(error);
                alert("There was an error submitting your feedback. Please try again later.");
            });
        } else {
            alert("Please enter some feedback before submitting.");
        }
    }


    return (
        <>
            <SimpleCloseBtn onClick={handleClose} />
            <div className="feedback-container">
                <div className="feedback-page">
                    <img src={require('../../images/logos/transparentLogoWhite.png')} alt="Feedback" />
                    <h1>Nobody does everything perfect... We want your feedback and suggestions!</h1>
                    <div className="feedback-input-container">
                        <textarea 
                            maxLength={250} 
                            placeholder="Enter your feedback here..."
                            value={feedback}
                            onChange={handleFeedbackChange}
                        /> 
                        <BigButton text="Submit" onClick={submitFeedback} />
                    </div>
                </div>
            </div>
        </>

    );
    }

export default Feedback;