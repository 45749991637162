import React, { useEffect } from 'react';

const WeatherTableWind = ({ weatherStation, setSelectedStation }) => {

    // Step 1: Convert object to array
    const dataArray = Object.entries(weatherStation).map(([key, value]) => ({ key, ...value }));
    // Step 2: Sort the array based on distance
    dataArray.sort((a, b) => a.distance - b.distance);

    // Step 3: Convert array back to object
    const reorderedData = {};
    dataArray.forEach(item => {
        reorderedData[item.key] = { ...item };
        delete reorderedData[item.key].key;
    });

    const weatherTableWind = Object.values(reorderedData).map((data, index) => (
        <div 
            className='weather-table-tile' 
            key={index}
            onClick={() => {
                setSelectedStation(data)
                console.log('data', data)
            }}
        >
            <span className={data.windSpeed[0] === 'calm' || parseFloat(data.windSpeed[0]) > 0 ? 'weather-table-active-dot active-dot-on' : 'weather-table-active-dot active-dot-off'}></span>
            <h4 className='weather-table-tile-text'>{data.name}</h4>
            {data.windSpeed[0] === 'calm' && (
                <p className='weather-table-tile-value'>{data.windSpeed[0]}</p>
            )}
            {parseFloat(data.windSpeed[0]) > 0 && (
                <p className='weather-table-tile-value'>
                    {data.windSpeed[0]} {data.windDirection[0]}
                </p>
            )}
        </div>
    ));

    return weatherTableWind;
};

export default WeatherTableWind;
